import React, { useContext, useState } from "react";
import { AppTextField } from "../../components/AppTextField";
import ReactFlagsSelect from "react-flags-select";
import { customLabels } from "../../util/util";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { useTranslation } from "react-i18next";

export const CheckoutSomeoneElse = ({ errors }) => {
  const { recipientDetails, updateRecipientDetails } =
    useContext(CheckoutContext);
  const { t } = useTranslation();

  const [selected, setSelected] = useState("SA");

  const handleCountrySelection = (code) => {
    setSelected(code);
    const countryCode = customLabels[code].secondary;
    updateRecipientDetails({ countryCode: countryCode });
  };

  return (
    <div className="flex flex-col w-full rounded-lg border border-text py-4">
      <div className="flex flex-col w-full px-4">
        <div className="flex flex-row space-x-1 rtl:space-x-reverse">
          <div className="text-text">{t("recipientName")}</div>
          <div className="text-red-500">*</div>
        </div>
        <AppTextField
          placeholder={t("recipientName")}
          type={"text"}
          value={recipientDetails.recipientName}
          onChange={(e) => {
            updateRecipientDetails({ recipientName: e.target.value });
          }}
          error={errors.name}
        />
      </div>
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row w-full px-2 pt-4 md:space-x-2 rtl:space-x-reverse">
        <div className="flex flex-col w-full">
          <div className="flex flex-row space-x-1 rtl:space-x-reverse">
            <div className="text-text">{t("recipientPhoneNumber")}</div>
            <div className="text-red-500">*</div>
          </div>
          <div className="flex flex-row space-x-1 rtl:space-x-reverse">
            <ReactFlagsSelect
              selected={selected}
              onSelect={handleCountrySelection}
              customLabels={customLabels}
              searchable={true}
            />
            <AppTextField
              placeholder={t("recipientPhoneNumber")}
              type={"tel"}
              pattern={"[0-9]*"}
              value={recipientDetails.phone}
              onChange={(e) => {
                const phone = e.target.value.replace(/\D/g, "");
                updateRecipientDetails({ phone: phone });
              }}
              error={errors.phone}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="text-text">{`${t("recipientEmail")} (${t(
            "optional"
          )})`}</div>
          <AppTextField
            placeholder={t("recipientEmail")}
            type={"email"}
            value={recipientDetails.email}
            onChange={(e) => {
              updateRecipientDetails({ email: e.target.value });
            }}
            error={errors.email}
          />
        </div>
      </div>
    </div>
  );
};
