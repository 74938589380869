import React, { useContext } from "react";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { ItemCheckoutPaymentMethod } from "./items/ItemCheckoutPaymentMethod";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";

export const CheckoutAddressStage2 = () => {
  const { showError } = useSnackbar();

  const { config, selectedPaymentMethod, setStage } =
    useContext(CheckoutContext);
  const { t } = useTranslation();
  const {primary, primaryDark} = useContext(AppContext);

  const handlePaymentMethodConfirmation = () => {
    if (!selectedPaymentMethod) {
      showError(t("paymentMethodNotSelectedError"));
      return;
    }
    setStage(3);
  };

  let paymentMethods = [];
  if (config.data.cash_on_delivery) {
    paymentMethods.push({
      gateway: "cash_on_delivery",
      gateway_title: t("cashOnDelivery"),
    });
  }
  if (config.data.digital_payment_status === 1) {
    paymentMethods.push(...config.data.active_payment_method_list);
  }

  return (
    <div>
      <div className="flex flex-row px-4 space-x-2 rtl:space-x-reverse">
        <div
          className="flex flex-row items-center justify-center w-[60px] h-[60px] rounded-full"
          style={{ backgroundColor: primary }}
        >
          <div className="text-white font-bold text-xl">2</div>
        </div>
        <div className="flex flex-col mt-1">
          <p className="text-text font-bold text-lg">{t("paymentMethod")}</p>
          <div className="text-text text-sm">
            {selectedPaymentMethod
              ? selectedPaymentMethod.gateway_title
              : t("pleaseSelectPaymentMethod")}
          </div>
        </div>
      </div>
      <div className="mt-4 space-y-2 px-4">
        {paymentMethods.map((p, index) => (
          <ItemCheckoutPaymentMethod paymentMethod={p} key={index} />
        ))}
      </div>
      <div className="px-4 pt-4">
        <ButtonWithIcon
          text={t("confirmPaymentMethod")}
          textColor="#ffffff"
          textHoverColor="#ffffff"
          bgColor={primary}
          bgHoverColor={primaryDark}
          useBorder={false}
          onClick={handlePaymentMethodConfirmation}
        />
      </div>
    </div>
  );
};
