import React, { createContext, useContext, useReducer } from "react";
import ProductReducer from "./ProductReducer";
import {
  addReviewApi,
  addToWishlistApi,
  getProductApi,
  getProductReviewsApi,
} from "../../api/api";
import { AppContext } from "../app/AppState";
import { useSnackbar } from "../general/SnackbarContext";

// Initial state
const initialState = {
  product: {
    data: null,
    loading: true,
    error: null,
  },
  reviews: {
    data: [],
    loading: true,
    error: null,
  },
  addToWishlistProduct: {
    data: null,
    loading: false,
    error: null,
  },
  addedReview: {
    data: null,
    loading: false,
    error: null,
  },
  selectedVariation: null,
};

// Create context
export const ProductContext = createContext(initialState);

// Provider component
export const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductReducer, initialState);
  const { isAuthenticated, token, setShowLoginDialog } = useContext(AppContext);
  const { showSuccess, showError } = useSnackbar();

  // Actions
  async function getProduct({ productId }) {
    dispatch({
      type: "GET_PRODUCT_REQUEST",
    });
    try {
      const res = await getProductApi({ productId });
      dispatch({
        type: "GET_PRODUCT_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_PRODUCT_FAILURE",
        payload: error.message || "Failed to fetch product",
      });
    }
  }

  async function getProductReviews({ productId }) {
    dispatch({
      type: "GET_PRODUCT_REVIEWS_REQUEST",
    });
    try {
      const res = await getProductReviewsApi({ productId });
      dispatch({
        type: "GET_PRODUCT_REVIEWS_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_PRODUCT_REVIEWS_FAILURE",
        payload: error.message || "Failed to fetch product reviews",
      });
    }
  }

  async function addReview({ productId, rating, comment }) {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
      return;
    }
    const tokenString = token.data.token;
    dispatch({
      type: "ADD_REVIEW_REQUEST",
    });
    try {
      const data = await addReviewApi({
        token: tokenString,
        productId,
        rating,
        comment,
      });
      dispatch({
        type: "ADD_REVIEW_SUCCESS",
        payload: data,
      });
      showSuccess(data.message);
      getProductReviews({ productId });
    } catch (error) {
      dispatch({
        type: "ADD_REVIEW_FAILURE",
        payload: error.message || "Failed to add review",
      });
      showError(error.message || "Failed to add review");
    }
  }

  async function addProductToWishlist({ productId }) {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
      return;
    }
    const tokenString = token.data.token;
    dispatch({
      type: "ADD_TO_WISHLIST_REQUEST",
    });
    try {
      const data = await addToWishlistApi({
        token: tokenString,
        productIds: [productId],
      });
      dispatch({
        type: "ADD_TO_WISHLIST_SUCCESS",
        payload: data,
      });
      showSuccess(data.message);
    } catch (error) {
      dispatch({
        type: "ADD_TO_WISHLIST_FAILURE",
        payload: error.message || "Failed to add product to wishlist",
      });
      showError(error.message || "Failed to add product to wishlist");
    }
  }

  function setSelectedVariation(value) {
    dispatch({
      type: "SET_SELECTED_VARIATION",
      payload: value,
    });
  }

  return (
    <ProductContext.Provider
      value={{
        product: state.product,
        reviews: state.reviews,
        addedReview: state.addedReview,
        addToWishlistProduct: state.addToWishlistProduct,
        selectedVariation: state.selectedVariation,
        getProduct,
        getProductReviews,
        addReview,
        addProductToWishlist,
        setSelectedVariation,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
