import React from "react";
import { getFormattedPrice, iconCopy } from "../../../util/ui_util";
import { copyToClipboard, formatDateYYYYMMDD } from "../../../util/util";
import colors from "../../../util/colors";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../../context/general/SnackbarContext";
import { Link } from "react-router-dom";

export const ItemOrder = ({ order }) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useSnackbar();

  return (
    <Link to={`/orders/${order.id}`}>
      <div>
        <div className="w-full hidden md:flex flex-row justify-between items-center cursor-pointer p-8 relative">
          <div className="absolute inset-0 bg-white shadow-lg transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100"></div>
          <div className="grid grid-cols-3 gap-12 relative z-10">
            <div className="text-text font-bold w-32">{order.id}</div>
            <div className="text-text w-32">
              {getFormattedPrice(order.order_amount)}
            </div>
            <div className="text-text w-32">
              {formatDateYYYYMMDD(order.created_at)}
            </div>
          </div>
          <div className="text-text relative z-10">{order.order_status}</div>
        </div>
        {/* Phone layout */}
        <div className="w-full flex md:hidden flex-col px-4 cursor-pointer">
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">{t("orderNumber")}</div>
            <div className="flex flex-row">
              <div className="text-text font-bold text-sm">{`#${order.id}`}</div>
              <div
                className="cursor-pointer"
                onClick={async (e) => {
                  e.stopPropagation();
                  copyToClipboard(
                    order.id,
                    () => {
                      showSuccess(t("orderIdCopied"));
                    },
                    (e) => {
                      showError(e);
                    }
                  );
                }}
              >
                {iconCopy({ fillColor: colors.text })}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">{t("total")}</div>
            <div className="text-text text-sm">
              {getFormattedPrice(order.order_amount)}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">{t("orderDate")}</div>
            <div className="text-text text-sm">
              {formatDateYYYYMMDD(order.created_at)}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">{t("orderStatus")}</div>
            <div className="text-text text-sm">{order.order_status}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};
