import React, { useContext, useState } from "react";
import { AppTextField } from "./AppTextField";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { emailPattern } from "../util/util";
import { AppContext } from "../context/app/AppState";
import { useTranslation } from "react-i18next";

export const FooterNewsletter = () => {
  const { primary, primaryDark, newsletter, subscribeToNewsletter } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const handleNewsletterSubmit = () => {
    if (!emailPattern.test(email)) {
      setEmailError(t("emailInvalid"));
      return;
    }
    subscribeToNewsletter({ email });
  };

  return (
    <div className="flex flex-col lg:w-[20vw] px-4 lg:px-0 space-y-2">
      <div className="text-text font-bold text-xl">{t("newsLetter")}</div>
      <AppTextField
        placeholder={t("subscribeToNewsLetter")}
        type={"email"}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        error={emailError}
      />
      <ButtonWithIcon
        text={t("subscribe")}
        textColor="#ffffff"
        textHoverColor="#ffffff"
        bgColor={primary}
        bgHoverColor={primaryDark}
        useBorder={false}
        isLoading={newsletter.loading}
        onClick={handleNewsletterSubmit}
      />
    </div>
  );
};
