import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getDiscountedPrice } from "../../../util/util";
import { AppContext } from "../../../context/app/AppState";
import { getFormattedPrice } from "../../../util/ui_util";
import { useTranslation } from "react-i18next";

export const ItemOrderDetail = ({ detail }) => {
  const { language } = useContext(AppContext);
  const navigate = useNavigate();
  const discountedPrice = getDiscountedPrice(detail.product_details);
  const hasDiscount = detail.product_details.discount !== 0;
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/product/${detail.product_details.id}`);
  };

  return (
    <div
      className="flex flex-col w-full cursor-pointer select-none mt-6 space-y-2"
      onClick={handleClick}
    >
      <div className="flex flex-row w-full space-x-2 rtl:space-x-reverse">
        <img
          src={detail.product_details.image_fullpath[0]}
          alt=""
          className="w-[96px] rounded-md"
        />
        <div className="flex flex-col">
          <div className="text-text font-bold">
            {detail.product_details.name}
          </div>
          <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
            <div
              className={`text-text ${hasDiscount ? "font-bold" : ""} text-sm`}
            >
              {getFormattedPrice(discountedPrice, language)}
            </div>
            <div
              className={`${hasDiscount ? "block" : "hidden"} ${
                hasDiscount ? "line-through" : ""
              } text-sm text-gray-300`}
            >
              {getFormattedPrice(detail.product_details.price, language)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-text">{t("quantity")}</div>
        <div className="text-text font-bold">{detail.quantity}</div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-text">{t("subtotal")}</div>
        <div className="text-text font-bold">
          {getFormattedPrice(detail.price, language)}
        </div>
      </div>
    </div>
  );
};
