import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import "../../assets/styles/photo_slider.css";
import ImageViewer from "react-simple-image-viewer";
import { AppContext } from "../../context/app/AppState";

export const PhotoSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalIdRef = useRef(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { setStopScroll, primary } = useContext(AppContext);

  useEffect(() => {
    const startInterval = () => {
      intervalIdRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4000);
    };

    startInterval();

    return () => clearInterval(intervalIdRef.current);
  }, [images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
  };

  const openImageViewer = useCallback(
    (index) => {
      setCurrentIndex(index);
      setIsViewerOpen(true);
      setStopScroll(true);
    },
    [setStopScroll]
  );

  const closeImageViewer = () => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
    setStopScroll(false);
  };

  return (
    <div>
      <div className="flex flex-row h-[400px] md:h-[700px]">
        <div className="flex flex-col space-y-2 px-2 overflow-y-auto">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Preview ${index}`}
              className={`w-[70px] md:w-[100px] lg:w-[150px] object-cover cursor-pointer`}
              style={{
                borderWidth: index === currentIndex ? "2px" : "0px",
                borderColor: primary,
              }}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
        <div className="relative w-full lg:w-[40vw] pt-[30px] overflow-hidden">
          {images.map((image, index) => (
            <div
              key={index}
              className={`absolute transition-transform transform w-full cursor-pointer ${
                index === currentIndex
                  ? "animate-slide-in"
                  : index === (currentIndex - 1 + images.length) % images.length
                  ? "animate-slide-out"
                  : "opacity-0 pointer-events-none"
              }`}
              onClick={() => openImageViewer(index)}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                className="w-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      {isViewerOpen && (
        <div className="fixed w-screen h-screen z-50" dir="ltr">
          <ImageViewer
            src={images}
            currentIndex={currentIndex}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </div>
      )}
    </div>
  );
};
