import React, { useContext } from "react";
import { ItemCheckoutDeliveryOrBranch } from "./items/ItemCheckoutDeliveryOrBranch";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { ItemCheckoutBranch } from "./items/ItemCheckoutBranch";

export const CheckoutAddressDeliveryOrPickup = () => {
  const { t } = useTranslation();
  const { isPickup, config } = useContext(CheckoutContext);

  return (
    <div className="flex flex-col mt-4 px-4">
      <div className="flex flex-row w-full space-x-2 rtl:space-x-reverse">
        <ItemCheckoutDeliveryOrBranch id={1} text={t("delivery")} />
        <ItemCheckoutDeliveryOrBranch id={2} text={t("pickup")} />
      </div>
      {isPickup && (
        <div className="flex flex-col space-y-2 mt-4">
            <div className="text-text sm:text-sm">{t("selectPickupBranch")}</div>
          {config.data.branches.map((branch, index) => (
            <ItemCheckoutBranch branch={branch} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};
