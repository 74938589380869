import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../context/app/AppState";
import {
  getFormattedPrice,
  iconBag,
  iconBarcode,
  iconCheck,
  iconClose,
  iosArrowRight,
} from "../util/ui_util";
import { Rating } from "react-simple-star-rating";
import { getDiscountedPrice, getDiscountString } from "../util/util";
import { useTranslation } from "react-i18next";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { useSnackbar } from "../context/general/SnackbarContext";
import useCart from "../hooks/useCart";
import { useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import colors from "../util/colors";

export const DialogProductQuickView = () => {
  const {
    language,
    showProductQuickViewDialog,
    setShowProductQuickViewDialog,
    primary,
    primaryDark
  } = useContext(AppContext);
  const isRtl = language === "ar";
  const dialogRef = useRef(null);
  const product = showProductQuickViewDialog.product;
  const { t } = useTranslation();
  const { showSuccess, showError } = useSnackbar();
  const { getProductCartQuantity, addProductToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    if (!product) {
      setShowProductQuickViewDialog({ show: false, product: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setShowProductQuickViewDialog({ show: false, product: null });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowProductQuickViewDialog]);

  if (!product) {
    return <></>;
  }

  const price = product.price;
  const discountedPrice = getDiscountedPrice({
    price,
    discount_type: product.discount_type,
    discount: product.discount,
  });
  const hasDiscount = product.discount !== 0;

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (product.choice_options.length > 0) {
      navigate(`/product/${product.id}`);
      return;
    }
    const cartQuantity = getProductCartQuantity(product.id);
    if (cartQuantity >= product.total_stock) {
      showError(t("productMaxQuantityMessage"));
      return;
    }
    addProductToCart(product, 1);
    showSuccess(t("productAddedToCartMessage"));
  };

  return (
    <div
      className="w-screen h-screen flex justify-center items-center fixed bg-black bg-opacity-50 z-40 px-4 md:px-0"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div
        className="flex flex-col bg-white min-w-full md:min-w-[60vw] rounded-md p-2"
        ref={dialogRef}
      >
        <div className="flex flex-row w-full">
          <div
            className="w-[30px] h-[30px] flex flex-row items-center justify-center bg-white rounded-full shadow-md cursor-pointer select-none"
            onClick={() =>
              setShowProductQuickViewDialog({ show: false, product: null })
            }
          >
            {iconClose({ fillColor: colors.text })}
          </div>
          <div className="flex flex-row w-full">
            <div className="flex flex-row w-1/2">
              <ProductQuickViewSlider images={product.image_fullpath} />
            </div>
            <div className="w-1/2 flex flex-col space-y-2">
              <div className="flex flex-col">
                <div className="text-text font-bold text-2xl">
                  {product.name}
                </div>
                <div className="flex flex-row">
                  <Rating
                    initialValue={product.rating[0]?.average}
                    readonly={true}
                    SVGclassName={"inline-block"}
                    rtl={isRtl}
                    size={20}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
                    <div
                      className={`${
                        hasDiscount ? "text-red-500" : "text-text"
                      } ${hasDiscount ? "font-bold" : ""} text-xl`}
                    >
                      {getFormattedPrice(discountedPrice, language)}
                    </div>
                    <div
                      className={`${hasDiscount ? "block" : "hidden"} ${
                        hasDiscount ? "line-through" : ""
                      } text-xl text-text`}
                    >
                      {getFormattedPrice(price, language)}
                    </div>
                  </div>
                  <div
                    className={`${
                      hasDiscount ? "block" : "hidden"
                    } text-red-500 font-bold`}
                  >
                    {getDiscountString(product, t, language)}
                  </div>
                </div>
                <div className="flex flex-row space-x-1 rtl:space-x-reverse items-center">
                  <div className="icon-container">
                    <div
                      className={
                        product.total_stock > 0
                          ? "pulsing-circle"
                          : "pulsing-circle-red"
                      }
                    ></div>
                    <div className="z-20">
                      {product.total_stock > 0
                        ? iconCheck({
                            fillColor: "#22c55e",
                            width: "24px",
                          })
                        : iconClose({
                            fillColor: colors.danger,
                            width: "24px",
                          })}
                    </div>
                  </div>
                  <div
                    className={`${
                      product.total_stock > 0
                        ? "text-green-500"
                        : "text-red-500"
                    } pt-1 font-bold`}
                  >
                    {product.total_stock > 0
                      ? t("availableNow")
                      : t("notAvailable")}
                  </div>
                </div>
              </div>
              <div
                className="h-full"
                dangerouslySetInnerHTML={{
                  __html: product.description,
                }}
              ></div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-1 rtl:space-x-reverse">
                  {iconBarcode({ fillColor: colors.text })}
                  <div className="font-bold text-text text-l">{t("model")}</div>
                </div>
                <div className="font-bold text-text text-l">{product.id}</div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="font-bold text-text text-l">{t("price")}</div>
                <div className="font-bold text-text text-l">
                  {getFormattedPrice(price, language)}
                </div>
              </div>
              <ButtonWithIcon
                text={t("addToCart")}
                textColor="#ffffff"
                textHoverColor="#ffffff"
                bgColor={primary}
                bgHoverColor={primaryDark}
                useBorder={false}
                getIcon={(color) => {
                  return iconBag({
                    fillColor: color,
                    width: "1.5rem",
                    height: "1.5rem",
                  });
                }}
                onClick={handleAddToCart}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductQuickViewSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentIndex(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="relative h-[600px] w-full overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute transition-transform transform w-full cursor-pointer ${
            index === currentIndex
              ? "animate-slide-in"
              : index === (currentIndex - 1 + images.length) % images.length
              ? "animate-slide-out"
              : "opacity-0 pointer-events-none"
          }`}
          onClick={() => openImageViewer(index)}
        >
          <img
            src={image}
            alt={`Slide ${index}`}
            className="w-full h-[600px] object-scale-down"
          />
        </div>
      ))}
      <div
        className="absolute flex flex-row items-center justify-center top-1/2 left-0 w-[40px] h-[40px] bg-white shadow-lg border-[0.5px] border-gray-100 cursor-pointer select-none"
        onClick={() => {
          if (currentIndex === images.length - 1) {
            return;
          }
          setCurrentIndex((prev) => prev + 1);
        }}
      >
        <div className="rotate-180">
          {iosArrowRight({ fillColor: colors.text })}
        </div>
      </div>
      <div
        className="absolute flex flex-row items-center justify-center top-1/2 right-0 w-[40px] h-[40px] bg-white shadow-lg border-[0.5px] border-gray-100 cursor-pointer select-none"
        onClick={() => {
          if (currentIndex === 0) {
            return;
          }
          setCurrentIndex((prev) => prev - 1);
        }}
      >
        {iosArrowRight({ fillColor: colors.text })}
      </div>
      {isViewerOpen && (
        <div className="fixed w-screen h-screen z-50" dir="ltr">
          <ImageViewer
            src={images}
            currentIndex={currentIndex}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </div>
      )}
    </div>
  );
};
