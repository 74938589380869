const OrdersReducer = (state, action) => {
  switch (action.type) {
    case "GET_ORDERS_REQUEST":
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
          error: null,
        },
      };

    case "GET_ORDERS_SUCCESS":
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_ORDERS_FAILURE":
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OrdersReducer;
