import React from "react";
import { Link } from "react-router-dom";

export const ItemHomeCategory = ({ category }) => {
  return (
    <Link to={`category/${category.id}`}>
      <div className="flex flex-col items-center space-y-2 w-32">
        <img
          src={category.image_fullpath}
          alt=""
          className="w-32 h-32 flex flex-row justify-center items-center rounded-full"
        />
        <span className="text-text">{category.name}</span>
      </div>
    </Link>
  );
};
