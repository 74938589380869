const HomeReducer = (state, action) => {
  switch (action.type) {
    case "GET_BANNERS_REQUEST":
      return {
        ...state,
        banners: {
          ...state.banners,
          loading: true,
          error: null,
        },
      };

    case "GET_BANNERS_SUCCESS":
      return {
        ...state,
        banners: {
          ...state.banners,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_BANNERS_FAILURE":
      return {
        ...state,
        banners: {
          ...state.banners,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_ADS_REQUEST":
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: true,
          error: null,
        },
      };

    case "GET_ADS_SUCCESS":
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_ADS_FAILURE":
      return {
        ...state,
        ads: {
          ...state.ads,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_NEW_ARRIVALS_REQUEST":
      return {
        ...state,
        newArrivals: {
          ...state.newArrivals,
          loading: true,
          error: null,
        },
      };

    case "GET_NEW_ARRIVALS_SUCCESS":
      return {
        ...state,
        newArrivals: {
          ...state.newArrivals,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_NEW_ARRIVALS_FAILURE":
      return {
        ...state,
        newArrivals: {
          ...state.newArrivals,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_FEATURED_CATEGORIES_REQUEST":
      return {
        ...state,
        featuredCategories: {
          ...state.featuredCategories,
          loading: true,
          error: null,
        },
      };

    case "GET_FEATURED_CATEGORIES_SUCCESS":
      return {
        ...state,
        featuredCategories: {
          ...state.featuredCategories,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_FEATURED_CATEGORIES_FAILURE":
      return {
        ...state,
        featuredCategories: {
          ...state.featuredCategories,
          loading: false,
          error: action.payload,
        },
      };

    case "ADD_TO_WISHLIST_REQUEST":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: true,
          error: null,
        },
      };

    case "ADD_TO_WISHLIST_SUCCESS":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "ADD_TO_WISHLIST_FAILURE":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default HomeReducer;
