import React, { useState, useEffect, useContext, useRef } from "react";
import { ItemNavigation } from "./ItemNavigation";
import { iconBurgerMenu } from "../util/ui_util";
import iconSearch from "../assets/images/icon_search.svg";
import iconUser from "../assets/images/icon_user.svg";
import iconCart from "../assets/images/icon_cart.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../context/app/AppState";
import { AccountNavigation } from "./AccountNavigation";
import { useTranslation } from "react-i18next";
import useCart from "../hooks/useCart";

export const NavigationBar = ({
  isDesktop,
  isRtl,
  isOpen,
  categories,
  onBurgerMenuClick,
  showAccountNavigationScroll,
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [shouldShowUser, setShouldShowUser] = useState(isDesktop);
  const {
    language,
    isAuthenticated,
    setLanguage,
    setShowLoginDialog,
    setShowSearchDialog,
    showAccountNavigation,
    setShowAccountNavigation,
    config,
  } = useContext(AppContext);
  const accountNavRef = useRef(null);
  const userButtonRef = useRef(null);
  const { cart } = useCart();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setShouldShowUser(true);
      } else {
        setShouldShowUser(false);
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountNavRef.current &&
        !accountNavRef.current.contains(event.target) &&
        userButtonRef.current &&
        !userButtonRef.current.contains(event.target)
      ) {
        setShowAccountNavigation(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowAccountNavigation]);

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "ar" : "en");
    i18n.changeLanguage(language === "en" ? "ar" : "en");
  };

  const buttonSearch = (
    <img
      src={iconSearch}
      alt=""
      className="w-[20px] cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        setShowSearchDialog(true);
      }}
    />
  );

  const buttonUser = (
    <img
      ref={userButtonRef}
      src={iconUser}
      alt=""
      className="w-[20px] cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        if (!isAuthenticated) {
          setShowLoginDialog(true);
        } else {
          setShowAccountNavigation(!showAccountNavigation);
        }
      }}
    />
  );

  const buttonCart = () => {
    return (
      <div
        className="relative w-[20px] md:w-[40px] lg:w-[40px] cursor-pointer"
        onClick={() => {
          navigate("/cart");
        }}
      >
        <img src={iconCart} alt="" className="w-[20px] mt-1" />
        <div
          className={`absolute ${
            !cart || cart.length === 0 ? "hidden" : "flex"
          } flex-row pt-1 -right-1 -top-2 bg-red-500 bg-opacity-80 rounded-full text-white text-sm w-[18px] h-[18px] justify-center items-center`}
        >
          {cart.length}
        </div>
      </div>
    );
  };

  return (
    <nav className={`container mx-auto py-4 bg-white`}>
      <div
        className="flex-row flex justify-between items-center"
        dir={isRtl ? "rtl" : "ltr"}
      >
        {/* START: This right layout appears on md screens */}
        <div className="flex flex-row lg:hidden space-x-4 ps-2">
          <div className={`${isOpen ? "hidden" : "block"}`}>
            <button
              onClick={onBurgerMenuClick}
              className="text-black focus:outline-none"
            >
              {iconBurgerMenu}
            </button>
          </div>
          <div className="relative">
            <div className="w-[20px]">{buttonUser}</div>
            {showAccountNavigationScroll && showAccountNavigation && (
              <div className="absolute translate-y-2 z-20" ref={accountNavRef}>
                <AccountNavigation />
              </div>
            )}
          </div>
          {config?.data?.is_en && (<div></div>)}
          {config?.data?.is_en && (<div></div>)}
          {config?.data?.is_en && (<div></div>)}
        </div>
        {/* END: This right layout appears on md screens */}
        {config?.data && (
          <NavLink to={"/"}>
            <img
              src={`${config.data.base_urls.ecommerce_image_url}/${config.data.app_logo}`}
              alt="logo"
              className="h-[50px] object-scale-down"
            />
          </NavLink>
        )}
        <div
          className={`flex flex-wrap gap-y-2 gap-x-3 ${
            isRtl ? "space-x-reverse" : ""
          } px-4 items-center w-full hidden lg:inline-flex`}
        >
          {categories.map((category) => {
            return (
              <ItemNavigation
                key={category.id}
                category={category}
                isRtl={isRtl}
              />
            );
          })}
        </div>
        <div
          className={`flex flex-row items-center space-x-4 rtl:space-x-reverse pe-2`}
        >
          {buttonSearch}
          {shouldShowUser && (
            <div className="relative">
              <div className="w-[20px]">{buttonUser}</div>
              {showAccountNavigationScroll && showAccountNavigation && (
                <div
                  className={`absolute translate-y-2 z-20 ${
                    isRtl ? "translate-x-1/2" : "-translate-x-1/2"
                  }`}
                  ref={accountNavRef}
                >
                  <AccountNavigation />
                </div>
              )}
            </div>
          )}
          {buttonCart()}
          {config?.data?.is_en && (
            <button
              onClick={toggleLanguage}
              className="px-4 py-2 border rounded text-text"
            >
              {language === "ar" ? "عربي" : "EN"}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};
