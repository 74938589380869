import { useEffect, useRef, useSyncExternalStore } from "react";
import {
  getCart,
  addToCartUtil,
  removeFromCartUtil,
  getProductCartQuantityUtil,
  editProductQuantityUtil,
  getCartSubtotalUtil,
  getCartTaxUtil,
  clearCartUtil,
  addToCartWithVariationUtil,
} from "../util/cartUtils";

const useCart = () => {
  const cartRef = useRef(getCart());
  const cart = useSyncExternalStore(subscribe, getSnapshot, () => getCart());

  useEffect(() => {
    if (JSON.stringify(cart) !== JSON.stringify(cartRef.current)) {
      cartRef.current = cart;
    }
  }, [cart]);

  function subscribe(callback) {
    window.addEventListener("custom-storage-event-name", callback);
    return () => {
      window.removeEventListener("custom-storage-event-name", callback);
    };
  }

  function getSnapshot() {
    return JSON.stringify(getCart());
  }

  const addProductToCart = (product, quantity) => {
    addToCartUtil(product, quantity);
  };

  const addProductToCartWithVariation = (product, quantity, variation) => {
    addToCartWithVariationUtil(product, quantity, variation);
  };

  const removeCartItem = (itemId) => {
    removeFromCartUtil(itemId);
  };

  const editCartItemQuantity = (itemId, quantity) => {
    editProductQuantityUtil(itemId, quantity);
  };

  const getProductCartQuantity = (productId) => {
    return getProductCartQuantityUtil(productId);
  };

  const getCartSubtotal = () => {
    return getCartSubtotalUtil();
  };

  const getCartTax = () => {
    return getCartTaxUtil();
  };

  const clearCart = () => {
    clearCartUtil();
  };

  return {
    cart: JSON.parse(cart),
    addProductToCart,
    addProductToCartWithVariation,
    clearCart,
    removeCartItem,
    getProductCartQuantity,
    editCartItemQuantity,
    getCartSubtotal,
    getCartTax,
  };
};

export default useCart;
