import React, { useContext, useEffect, useRef } from "react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import { ItemCategoryProduct } from "./ItemCategoryProduct";
import Dropdown from "../../components/Dropdown";
import { CategoryContext } from "../../context/category/CategoryState";
import { defaultLoader } from "../../util/ui_util";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { AppContext } from "../../context/app/AppState";

export const CategoryPage = () => {
  const { categoryId } = useParams();
  const { products, getCategoryProducts } = useContext(CategoryContext);
  const { showError } = useSnackbar();
  const { language } = useContext(AppContext);
  const isRtl = language === "ar";
  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (categoryId) {
      getCategoryProducts({ categoryId, page: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (products.error) {
      showError(products.error);
    }
  }, [products.error, showError]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && products.hasMore && !products.loading) {
        getCategoryProducts({ categoryId, page: products.page + 1 });
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, products.currentPage, products.hasMore, products.loading]);

  return (
    <Layout>
      <div className="flex flex-col px-4 py-8 space-y-4">
        <div
          className="flex flex-row justify-between items-center"
          dir={isRtl ? "rtl" : "ltr"}
        >
          <div className="text-2xl font-bold text-text">
            {products.data && products.data.length > 0
              ? products.data[0].category_name
              : ""}
          </div>
          <div className="hidden flex-row items-center space-x-2">
            <div className="text-text text-sm lg:text-lg">Sort</div>
            <Dropdown
              items={[
                "Our recommendations",
                "Best sellers",
                "Highest rated",
                "Price high to low",
                "Price low to high",
              ]}
              onItemSelected={(item) => {}}
            />
          </div>
        </div>
        <div
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full"
          dir={isRtl ? "rtl" : "ltr"}
        >
          {products.data &&
            products.data.length > 0 &&
            products.data.map((product, index) => (
              <ItemCategoryProduct
                key={index}
                product={product}
                onHoverChange={() => {}}
              />
            ))}
        </div>
        <div ref={loadMoreRef} className="h-1" /> {/* Observer target */}
      </div>
      {products.loading && products.page === 1 ? (
        defaultLoader() // Initial loading spinner for the first page
      ) : (
        <></>
      )}
    </Layout>
  );
};
