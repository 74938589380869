import React, { useContext, useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import { PhotoSlider } from "./PhotoSlider";
import { useNavigate, useParams } from "react-router-dom";
import {
  defaultLoader,
  getFormattedPrice,
  iconBag,
  iconBarcode,
  iconCheck,
  iconClose,
  iconHeartEmpty,
  iconShare,
  iconWallet,
  spinner,
} from "../../util/ui_util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import "../../assets/styles/product_page.css";
import { ProductQuantity } from "./ProductQuantity";
import { ItemHomeProduct } from "../home/items/ItemHomeProduct";
import { useDraggable } from "react-use-draggable-scroll";
import { ProductContext } from "../../context/product/ProductState";
import { Rating } from "react-simple-star-rating";
import { ProductReviews } from "./ProductReviews";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";
import {
  copyToClipboard,
  getDiscountedPrice,
  getDiscountString,
  getLatestConfig,
} from "../../util/util";
import useCart from "../../hooks/useCart";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { ProductOptions } from "./ProductOptions";
import colors from "../../util/colors";
import iconTabby from "../../assets/images/icon_tabby.webp";

export const ProductPage = () => {
  const { productId } = useParams();
  const ref = useRef(null);
  const { events } = useDraggable(ref);
  const {
    product,
    getProduct,
    reviews,
    getProductReviews,
    addToWishlistProduct,
    addProductToWishlist,
    selectedVariation,
  } = useContext(ProductContext);
  const { language, primary, primaryDark } = useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const {
    getProductCartQuantity,
    addProductToCart,
    addProductToCartWithVariation,
  } = useCart();
  const { showSuccess, showError } = useSnackbar();
  const navigate = useNavigate();

  const handleAddToCart = ({ navigateToCheckout = false }) => {
    if (selectedVariation) {
      if (quantity > selectedVariation.stock) {
        showError(t("productMaxQuantityMessage"));
        return;
      }
      addProductToCartWithVariation(
        product.data.product,
        quantity,
        selectedVariation
      );
      showSuccess(t("productAddedToCartMessage"));
      if (navigateToCheckout) {
        navigate("/checkout");
      }
    } else {
      const cartQuantity = getProductCartQuantity(product.data.product.id);
      if (cartQuantity + quantity > product.data.product.total_stock) {
        showError(t("productMaxQuantityMessage"));
        return;
      }
      addProductToCart(product.data.product, quantity);
      showSuccess(t("productAddedToCartMessage"));
      if (navigateToCheckout) {
        navigate("/checkout");
      }
    }
  };

  useEffect(() => {
    if (productId) {
      getProduct({ productId });
      getProductReviews({ productId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const renderPage = () => {
    const price = selectedVariation
      ? selectedVariation.price
      : product.data.product.price;
    const discountedPrice = getDiscountedPrice({
      price,
      discount_type: product.data.product.discount_type,
      discount: product.data.product.discount,
    });
    const hasDiscount = product.data.product.discount !== 0;
    const hasTabby = getLatestConfig()?.active_payment_method_list.some(
      (method) => method.gateway === "tabby"
    );

    return (
      <div
        className="flex flex-col px-4 py-8 space-y-16"
        dir={isRtl ? "rtl" : "ltr"}
      >
        <div className="flex flex-col lg:flex-row w-full lg:space-x-4 rtl:space-x-reverse">
          <PhotoSlider images={product.data.product.image_fullpath} />
          <div className="flex flex-col w-full space-y-2">
            <div className="flex flex-row justify-end items-center lg:space-x-2 rtl:space-x-reverse">
              <div
                className="cursor-pointer"
                onClick={async () => {
                  copyToClipboard(
                    window.location.href,
                    () => {
                      showSuccess(t("urlCopied"));
                    },
                    (e) => {
                      showError(e);
                    }
                  );
                }}
              >
                {iconShare({
                  fillColor: colors.text,
                  width: "1.5em",
                  height: "1.5em",
                })}
              </div>
              {addToWishlistProduct.loading ? (
                spinner(primary)
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    addProductToWishlist({
                      productId: product.data.product.id,
                    });
                  }}
                >
                  {iconHeartEmpty({
                    fillColor: colors.text,
                    width: "1.5em",
                    height: "1.5em",
                  })}
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-text font-bold text-2xl">
                {product.data.product.name}
              </div>
              <div className="flex flex-row">
                <Rating
                  initialValue={product.data.product.rating[0]?.average}
                  readonly={true}
                  SVGclassName={"inline-block"}
                  rtl={isRtl}
                  size={20}
                />
              </div>
              <div className="flex flex-col mt-">
                <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
                  <div
                    className={`${hasDiscount ? "text-red-500" : "text-text"} ${
                      hasDiscount ? "font-bold" : ""
                    } text-xl`}
                  >
                    {getFormattedPrice(discountedPrice, language)}
                  </div>
                  <div
                    className={`${hasDiscount ? "block" : "hidden"} ${
                      hasDiscount ? "line-through" : ""
                    } text-xl text-text`}
                  >
                    {getFormattedPrice(price, language)}
                  </div>
                </div>
                <div
                  className={`${
                    hasDiscount ? "block" : "hidden"
                  } text-red-500 font-bold`}
                >
                  {getDiscountString(product.data.product, t, language)}
                </div>
              </div>
              <div className="flex flex-row space-x-1 rtl:space-x-reverse items-center">
                <div className="icon-container">
                  <div
                    className={
                      product.data.product.total_stock > 0
                        ? "pulsing-circle"
                        : "pulsing-circle-red"
                    }
                  ></div>
                  <div className="z-20">
                    {product.data.product.total_stock > 0
                      ? iconCheck({
                          fillColor: "#22c55e",
                          width: "24px",
                        })
                      : iconClose({
                          fillColor: colors.danger,
                          width: "24px",
                        })}
                  </div>
                </div>
                <div
                  className={`${
                    product.data.product.total_stock > 0
                      ? "text-green-500"
                      : "text-red-500"
                  } pt-1 font-bold`}
                >
                  {product.data.product.total_stock > 0
                    ? t("availableNow")
                    : t("notAvailable")}
                </div>
              </div>
            </div>
            <div className="h-full">
              <div
                dangerouslySetInnerHTML={{
                  __html: product.data.product.description,
                }}
              ></div>
              {product.data.product.choice_options.length > 0 && (
                <ProductOptions setQuantity={setQuantity} />
              )}
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row space-x-1 rtl:space-x-reverse">
                {iconBarcode({ fillColor: colors.text })}
                <div className="font-bold text-text text-l">{t("model")}</div>
              </div>
              <div className="font-bold text-text text-l">
                {product.data.product.id}
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="font-bold text-text text-l">{t("price")}</div>
              <div className="font-bold text-text text-l">
                {getFormattedPrice(price, language)}
              </div>
            </div>
            <ProductQuantity
              minQty={product.data.product?.min_order_qty}
              maxQty={
                selectedVariation
                  ? selectedVariation.stock
                  : product.data.product?.total_stock
              }
              quantity={quantity}
              setQuantity={setQuantity}
            />
            <div className="flex flex-row w-full space-x-2 rtl:space-x-reverse">
              <ButtonWithIcon
                text={t("addToCart")}
                textColor="#ffffff"
                textHoverColor="#ffffff"
                bgColor={primary}
                bgHoverColor={primaryDark}
                useBorder={false}
                getIcon={(color) => {
                  return iconBag({
                    fillColor: color,
                    width: "1.5rem",
                    height: "1.5rem",
                  });
                }}
                onClick={handleAddToCart}
              />
              <ButtonWithIcon
                text={t("buyNow")}
                textColor={primary}
                textHoverColor="#ffffff"
                bgColor="transparent"
                bgHoverColor={primary}
                getIcon={(color) => {
                  return iconWallet({
                    fillColor: color,
                    width: "1.5rem",
                    height: "1.5rem",
                  });
                }}
                onClick={() => {
                  handleAddToCart({ navigateToCheckout: true });
                }}
              />
            </div>
            {hasTabby && (
              <div className="flex flex-row w-full p-2 bg-white rounded-md shadow-md space-x-2 rtl:space-x-reverse">
                <div className="text-black font-bold mt-1 w-full">{`${t(
                  "tabbyMessagePart1"
                )} ${getFormattedPrice(discountedPrice / 4, language)} ${t(
                  "tabbyMessagePart2"
                )}`}</div>
                <img src={iconTabby} alt="" className="w-[80px]" />
              </div>
            )}
          </div>
        </div>
        {reviews.loading || reviews.error || !reviews.data ? (
          <></>
        ) : (
          <ProductReviews />
        )}
        <div className="flex flex-col space-y-4">
          <div className="font-bold text-text text-lg lg:text-2xl">
            {t("relatedProducts")}
          </div>
          <div>
            <div
              className="border w-[180px] lg:w-[240px]"
              style={{ borderColor: primary }}
            ></div>
            <div className="border border-gray-300 w-full"></div>
          </div>
          <div
            className="flex w-full overflow-x-scroll overflow-y-visible scrollbar-hide space-x-4 rtl:space-x-reverse"
            {...events}
            ref={ref}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          >
            {product.data.related_products.map((product, index) => (
              <ItemHomeProduct
                key={index}
                product={product}
                mobileWidth={144}
                onHoverChange={() => {}}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      {product.loading ? (
        <div ref={ref}>{defaultLoader()}</div>
      ) : product.error ? (
        <div className="text-red-500" ref={ref}>
          {product.error}
        </div>
      ) : (
        renderPage()
      )}
    </Layout>
  );
};
