import React, { useContext, useEffect, useRef } from "react";
import iconUser from "../assets/images/icon_user.svg";
import colors from "../util/colors";
import {
  iconAccount,
  iconLogout,
  iconMessages,
  iconNotifications,
  iconOrders,
  iconStar,
} from "../util/ui_util";
import { useDraggable } from "react-use-draggable-scroll";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/app/AppState";

export const AccountTop = () => {
  const ref = useRef(null);
  const { events } = useDraggable(ref);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout, user, getUser, language, config } = useContext(AppContext);
  const isRtl = language === "ar";

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navItem = ({ text, icon, to }) => {
    return (
      <div
        className="flex flex-row space-x-1 rtl:space-x-reverse items-center py-1 border-b border-transparent hover:border-gray-300 cursor-pointer"
        onClick={() => {
          if (to === "/logout") {
            logout();
            navigate("/");
          } else {
            navigate(to);
          }
        }}
      >
        {icon(to === "/logout" ? colors.danger : colors.text, "24px")}
        <div className="text-text mt-1 whitespace-nowrap">{text}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex flex-col h-32 lg:h-48 justify-center items-center relative">
        {config?.data && (
          <img
            src={`${config.data.base_urls.ecommerce_image_url}/${config.data.profile_background_image}`}
            alt=""
            className="absolute w-full h-32 lg:h-48 top-0"
          />
        )}
        <div className="p-2 bg-white z-10">
          {config?.data && (
            <img
              src={`${config.data.base_urls.ecommerce_image_url}/${config.data.app_logo}`}
              alt="logo"
              className="h-[64px] object-scale-down"
            />
          )}
        </div>
        <div
          className={`absolute flex flex-col ${
            isRtl ? "right-4 md:right-10" : "left-4 md:left-10"
          } -bottom-14  md:-bottom-24`}
        >
          <div className="w-16 h-16  md:w-32 md:h-32 bg-white flex flex-row justify-center items-center rounded-full shadow-sm overflow-clip">
            <img
              src={user?.data ? user.data.image_fullpath : iconUser}
              alt=""
              className="h-14 md:h-28 object-scale-down"
            />
          </div>
          <div className="text-text font-bold text-xl md:text-2xl mt-2">
            {user?.data ? `${user.data.f_name} ${user.data.l_name}` : ""}
          </div>
        </div>
      </div>
      <div
        className="flex flex-row space-x-8 rtl:space-x-reverse w-full overflow-x-scroll items-center mt-16 md:mt-28"
        {...events}
        ref={ref}
      >
        {navItem({
          text: t("notifications"),
          icon: (color, size) => {
            return iconNotifications({
              fillColor: color,
              width: size,
              height: size,
            });
          },
          to: "/notifications",
        })}
        {navItem({
          text: t("orders"),
          icon: (color, size) => {
            return iconOrders({ fillColor: color, width: size, height: size });
          },
          to: "/orders",
        })}
        {navItem({
          text: t("wishlist"),
          icon: (color, size) => {
            return iconStar({ fillColor: color, width: size, height: size });
          },
          to: "/wishlist",
        })}
        {navItem({
          text: t("profile"),
          icon: (color, size) => {
            return iconAccount({ fillColor: color, width: size, height: size });
          },
          to: "/profile",
        })}
        {navItem({
          text: t("messages"),
          icon: (color, size) => {
            return iconMessages({ fillColor: color, width: size, height: size });
          },
          to: "/chat",
        })}
        {navItem({
          text: t("logout"),
          icon: (color, size) => {
            return iconLogout({ fillColor: color, width: size, height: size });
          },
          to: "/logout",
        })}
      </div>
      <div className="w-full bg-gray-300 h-[1px]"></div>
    </div>
  );
};
