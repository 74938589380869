const CategoryReducer = (state, action) => {
  switch (action.type) {
    case "GET_CATEGORY_PRODUCTS_REQUEST":
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          error: null,
        },
      };

    case "GET_CATEGORY_PRODUCTS_SUCCESS":
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data: action.append
            ? [...state.products.data, ...action.payload.products]
            : action.payload.products,
          error: null,
          page: action.payload.page,
          hasMore: action.payload.hasMore,
        },
      };

    case "GET_CATEGORY_PRODUCTS_FAILURE":
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CategoryReducer;
