import React, { useContext, useEffect, useRef } from "react";
import { Layout } from "../../components/Layout";
import { AccountTop } from "../../components/AccountTop";
import { defaultLoader, iconStar } from "../../util/ui_util";
import colors from "../../util/colors";
import { ItemWishlist } from "./items/ItemWishlist";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { WishlistContext } from "../../context/wishlist/WishlistState";
import { AppContext } from "../../context/app/AppState";

export const WishlistPage = () => {
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { t } = useTranslation();
  const { wishlist, getWishlist } = useContext(WishlistContext);
  const { isAuthenticated, language } = useContext(AppContext);
  const isRtl = language === "ar";
  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      showError(t("notLoggedInError"));
      navigate("/");
    }
  }, [showError, t, navigate, isAuthenticated]);

  useEffect(() => {
    getWishlist({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wishlist?.error) {
      showError(wishlist.error);
    }
  }, [wishlist, showError]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && wishlist.hasMore && !wishlist.loading) {
        getWishlist({ page: wishlist.page + 1 });
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist.currentPage, wishlist.hasMore, wishlist.loading]);

  return (
    <Layout>
      <div className="flex flex-col mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {t("wishlist")}
        </div>
        {wishlist.data && wishlist.data.length > 0 ? (
          <ul className="space-y-4 mt-10">
            {wishlist.data.map((product, index) => (
              <li>
                <ItemWishlist product={product} key={index} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex flex-col items-center space-y-2 mt-8">
            <div className="flex flex-row w-[100px] h-[100px] justify-center items-center bg-gray-300 rounded-full">
              {iconStar({
                fillColor: colors.text,
                width: "40px",
                height: "40px",
              })}
            </div>
            <div>{t("emptyWishlistMessage")}</div>
          </div>
        )}
        <div ref={loadMoreRef} className="h-1" />
        {wishlist.loading ? defaultLoader() : <></>}
      </div>
    </Layout>
  );
};
