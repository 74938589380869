import React, { useContext, useState } from "react";
import { getFormattedPrice, iconClose } from "../../util/ui_util";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { getDiscountedPrice } from "../../util/util";
import useCart from "../../hooks/useCart";
import { useSnackbar } from "../../context/general/SnackbarContext";

export const ItemCart = ({ item, onDelete }) => {
  const { language, primary } = useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();
  const variation = item.variation;
  const oldPrice = variation ? variation.price : item.product.price;
  const price = getDiscountedPrice({
    price: oldPrice,
    discount_type: item.product.discount_type,
    discount: item.product.discount,
  });
  const { getProductCartQuantity, editCartItemQuantity } = useCart();
  const [quantity, setQuantity] = useState(item.quantity);
  const { showError } = useSnackbar();

  const handlePlusClick = () => {
    if (variation) {
      if (item.quantity === variation.stock) {
        showError(t("productMaxQuantityMessage"));
        return;
      }
      setQuantity((prev) => {
        const newQuantity = prev + 1;
        editCartItemQuantity(item.id, newQuantity);
        return newQuantity;
      });
    } else {
      const cartQuantity = getProductCartQuantity(item.product.id);
      if (cartQuantity >= item.product.total_stock) {
        showError(t("productMaxQuantityMessage"));
        return;
      }
      setQuantity((prev) => {
        const newQuantity = prev + 1;
        editCartItemQuantity(item.id, newQuantity);
        return newQuantity;
      });
    }
  };

  const handleMinusClick = () => {
    const minQty = item.product.min_order_qty ? item.product.min_order_qty : 1;
    if (quantity === minQty) {
      onDelete();
      return;
    }
    setQuantity((prev) => {
      const newQuantity = prev - 1;
      editCartItemQuantity(item.id, newQuantity);
      return newQuantity;
    });
  };

  const productInfo = () => {
    return (
      <div className="flex flex-row w-[240px] space-x-2 rtl:space-x-reverse mt-4">
        <img
          src={item.product.image_fullpath[0]}
          alt=""
          className="w-[96px] rounded-md"
        />
        <div className="flex flex-col">
          <div className="text-text font-bold">{item.product.name}</div>
          <div className="text-text font-normal text-sm">
            {getFormattedPrice(price, language)}
          </div>
        </div>
      </div>
    );
  };

  const quantityControl = () => {
    return (
      <div className="flex flex-row h-[40px] border rounded-md border-gray-300 divide-x rtl:divide-x-reverse divide-gray-300 mt-4">
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl cursor-pointer"
          onClick={handleMinusClick}
          draggable={false}
        >
          -
        </div>
        <div className="flex items-center justify-center pt-1 w-[50px] text-text font-bold">
          {quantity}
        </div>
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl cursor-pointer"
          onClick={handlePlusClick}
          draggable={false}
        >
          +
        </div>
      </div>
    );
  };

  const subtotal = () => {
    return (
      <div className="font-bold mt-4" style={{ color: primary }}>{`${t(
        "subtotal"
      )}: ${getFormattedPrice(price * item.quantity, language)}`}</div>
    );
  };

  const deleteButton = () => {
    return (
      <div
        className="flex flex-row justify-center items-center h-[24px] w-[24px] md:h-[30px] md:w-[30px] p-1 md:p-2 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer"
        onClick={onDelete}
      >
        {iconClose({ fillColor: "white" })}
      </div>
    );
  };

  return (
    <div
      className="flex flex-col md:flex-row justify-between rounded-xl border px-6 pt-4 pb-4 md:pb-16"
      dir={isRtl ? "rtl" : "ltr"}
      style={{ borderColor: primary }}
    >
      <div className="sm:display-block md:hidden lg:hidden flex-row justify-end">
        {deleteButton()}
      </div>
      {productInfo()}
      <div className="hidden md:flex">{quantityControl()}</div>
      <div className="flex md:hidden flex-row justify-between items-center">
        {quantityControl()}
        {subtotal()}
      </div>
      <div className="hidden md:flex flex-row space-x-8 rtl:space-x-reverse">
        {subtotal()}
        {deleteButton()}
      </div>
    </div>
  );
};
