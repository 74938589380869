import React, { useContext } from "react";
import { CheckoutContext } from "../../../context/checkout/CheckoutState";
import {
  iconRadioChecked,
  iconRadioUnchecked,
  iconTrashcan,
  spinner,
} from "../../../util/ui_util";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import colors from "../../../util/colors";

export const getAddressString = (address) => {
  let string = `${address.address} - ${address.road}`;
  if (address.postal_code) {
    string += ` - ${address.postal_code}`;
  }
  if (address.house) {
    string += ` - ${address.house}`;
  }
  return string;
};

export const ItemCheckoutAddress = ({ address }) => {
  const {
    selectedAddressId,
    setSelectedAddressId,
    deletedAddress,
    deleteAddress,
  } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const isSelected = selectedAddressId === address.id;
  const { primary } = useContext(AppContext);

  return (
    <div
      className={`flex flex-row justify-between items-center rounded-md p-2 border cursor-pointer`}
      style={{ borderColor: isSelected ? primary : colors.text }}
      onClick={() => {
        setSelectedAddressId(address.id);
      }}
    >
      <div className="flex flex-row items-center">
        {isSelected
          ? iconRadioChecked({ fillColor: primary, width: "2em" })
          : iconRadioUnchecked({ fillColor: colors.text, width: "2em" })}
        <div className="text-text pt-1">{getAddressString(address)}</div>
      </div>
      {deletedAddress.loading ? (
        spinner(primary)
      ) : (
        <div
          className="flex flex-row items-center space-x-1 rtl:space-x-reverse cursor-pointer select-none"
          onClick={(e) => {
            e.stopPropagation();
            deleteAddress(address.id);
          }}
        >
          {iconTrashcan({ fillColor: colors.danger })}
          <div className="text-red-500 pt-2">{t("delete")}</div>
        </div>
      )}
    </div>
  );
};
