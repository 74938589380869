import React, { useState, useEffect, useContext, useCallback } from "react";
import { DropdownWithSearch } from "../../components/DropdownWithSearch";
import { AppTextField } from "../../components/AppTextField";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { useTranslation } from "react-i18next";
import { AppCheckbox } from "../../components/AppCheckbox";
import { CheckoutSomeoneElse } from "./CheckoutSomeoneElse";
import { emailPattern } from "../../util/util";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { countries } from "../../util/countries";
import { AppContext } from "../../context/app/AppState";

const allCountries = countries;
const countriesList = Object.keys(allCountries);

const cities = (selectedCountry) => {
  return allCountries[selectedCountry];
};

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 21.557207,
  lng: 39.164753,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

export const CheckoutAddAddress = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const onMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setMarker({
      lat: lat,
      lng: lng,
    });

    console.log("Lat:", lat, "Lng:", lng);
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const [district, setDistrict] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [houseDescription, setHouseDescription] = useState("");

  const [dropdownCountries] = useState(countriesList);
  const [selectedCountry, setSelectedCountry] = useState(countriesList[0]);
  const [dropdownCities, setDropdownCities] = useState(cities(selectedCountry));
  const [selectedCity, setSelectedCity] = useState(cities(selectedCountry)[0]);

  const [errors, setErrors] = useState({});

  const {
    user,
    addedAddress,
    addAddress,
    isSomeoneElse,
    setIsSomeoneElse,
    recipientDetails,
  } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const { showError } = useSnackbar();
  const { primary, primaryDark } = useContext(AppContext);

  useEffect(() => {
    setDropdownCities(cities(selectedCountry));
  }, [selectedCountry]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!marker) {
      showError("Please pick a location on the map");
      return;
    }
    setErrors({});
    let formErrors = {};
    if (!district) {
      formErrors.district = t("districtRequired");
    }
    if (!street) {
      formErrors.street = t("streetRequired");
    }
    if (isSomeoneElse) {
      let sErrors = {};
      if (recipientDetails.recipientName.length === 0) {
        formErrors.name = t("someoneElseNameError");
      }
      if (recipientDetails.phone.length === 0) {
        formErrors.phone = t("someoneElsePhoneError");
      }
      if (
        recipientDetails.email.length > 0 &&
        !emailPattern.test(recipientDetails.email)
      ) {
        sErrors.email = t("someoneElseEmailError");
      }
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const address = {
      address: `${selectedCountry} ${selectedCity} ${district}`,
      addressType: "Workplace",
      contactPersonName: isSomeoneElse
        ? recipientDetails.recipientName
        : `${user.data.f_name} ${user.data.l_name}`,
      contactPersonPhone: isSomeoneElse
        ? `${recipientDetails.countryCode}${recipientDetails.phone}`
        : user.data.phone,
      street: street,
      house: houseDescription,
      lat: marker.lat,
      lng: marker.lng,
    };
    addAddress(address);
  };

  return (
    <form
      className="rounded-md border border-text p-2 m-4 space-y-2"
      onSubmit={handleSubmit}
    >
      {isLoaded ? (
        <div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={options}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onMapClick}
          >
            {marker && (
              <Marker position={{ lat: marker.lat, lng: marker.lng }} />
            )}
          </GoogleMap>
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col md:flex-row w-full space-y-2 md:space-x-2 rtl:space-x-reverse md:space-y-0">
        <div className="flex flex-col w-full">
          <div className="flex flex-row space-x-1 rtl:space-x-reverse ">
            <div className="text-text">{t("chooseYourCountry")}</div>
            <div className="text-red-500">*</div>
          </div>
          <DropdownWithSearch
            items={dropdownCountries}
            onItemSelected={(countryName) => {
              const country = countriesList.find((c) => c === countryName);
              setSelectedCountry(country);
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row space-x-1 rtl:space-x-reverse ">
            <div className="text-text">{t("chooseYourCity")}</div>
            <div className="text-red-500">*</div>
          </div>
          <DropdownWithSearch
            items={dropdownCities}
            onItemSelected={(cityName) => {
              const city = cities(selectedCountry).find((c) => c === cityName);
              setSelectedCity(city);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full space-y-2 md:space-x-2 rtl:space-x-reverse  md:space-y-0">
        <div className="flex flex-col w-full">
          <div className="flex flex-row space-x-1 rtl:space-x-reverse ">
            <div className="text-text">{t("district")}</div>
            <div className="text-red-500">*</div>
          </div>
          <AppTextField
            placeholder={t("district")}
            type={"text"}
            value={district}
            onChange={(e) => {
              setDistrict(e.target.value);
            }}
            error={errors.district}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row space-x-1 rtl:space-x-reverse ">
            <div className="text-text">{t("street")}</div>
            <div className="text-red-500">*</div>
          </div>
          <AppTextField
            placeholder={t("street")}
            type={"text"}
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
            }}
            error={errors.street}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full space-y-2 md:space-x-2 rtl:space-x-reverse  md:space-y-0">
        <div className="flex flex-col w-full">
          <div className="text-text">{`${t("zipCode")} (${t(
            "optional"
          )})`}</div>
          <AppTextField
            placeholder={t("zipCode")}
            type={"text"}
            value={zipCode}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="text-text">{`${t("houseDescription")} (${t(
            "optional"
          )})`}</div>
          <AppTextField
            placeholder={t("houseDescription")}
            type={"text"}
            value={houseDescription}
            onChange={(e) => {
              setHouseDescription(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col pt-4 space-y-2">
        <AppCheckbox
          label={t("letSomeoneElseReceiveOrder")}
          isChecked={isSomeoneElse}
          setIsChecked={setIsSomeoneElse}
        />
        {isSomeoneElse && <CheckoutSomeoneElse errors={errors} />}
      </div>
      <div className="flex pt-4">
        <ButtonWithIcon
          type="submit"
          text={t("save")}
          textColor="#ffffff"
          textHoverColor="#ffffff"
          bgColor={primary}
          bgHoverColor={primaryDark}
          useBorder={false}
          isLoading={addedAddress.loading}
        />
      </div>
    </form>
  );
};
