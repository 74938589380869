import React, { useContext } from "react";
import { Rating } from "react-simple-star-rating";
import { formatDateYYYYMMDD } from "../../../util/util";
import { AppContext } from "../../../context/app/AppState";

export const ItemProductReview = ({ review }) => {
  const { language } = useContext(AppContext);
  const isRtl = language === "ar";

  return (
    <div className="flex flex-row justify-between w-full border-b border-gray-300 py-4">
      <div className="flex flex-row space-x-2 rtl:space-x-reverse">
        <img
          src={review.customer?.image_fullpath}
          alt=""
          className="rounded-full w-[80px] h-[80px]"
        />
        <div className="flex flex-col">
          <div className="text-text font-bold">{`${review.customer?.f_name ?? ''} ${review.customer?.l_name ?? ''}`}</div>
          <Rating
            initialValue={review.rating}
            readonly={true}
            SVGclassName={"inline-block"}
            rtl={isRtl}
            size={20}
          />
          <div className="text-text text-sm">{review.comment}</div>
        </div>
      </div>
      <div className="text-text text-sm">
        {formatDateYYYYMMDD(review.created_at)}
      </div>
    </div>
  );
};
