import React, { useContext, useState } from "react";
import { iosArrowDown } from "../util/ui_util";
import { ItemNavigationSubcategory } from "./ItemNavigationSubcategory";
import { Link } from "react-router-dom";
import { AppContext } from "../context/app/AppState";

export const ItemNavigation = ({ category, isRtl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubcategories =
    category?.categories && category.categories.length > 0;
  const { primary } = useContext(AppContext);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <Link to={`/category/${category.id}`} draggable={false}>
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`flex items-center space-x-1 cursor-pointer font-bold`}
          style={{ color: isOpen ? primary : "black" }}
        >
          {category?.name}

          {hasSubcategories && (
            <span
              className={`ml-2 transform transition-transform ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
            >
              {iosArrowDown()}
            </span>
          )}
        </div>
        {isOpen && hasSubcategories && (
          <div
            className={`absolute w-[250px] bg-white shadow-lg z-50 ${
              isRtl ? "right-0" : "left-0"
            }`}
          >
            {category.categories?.map((sub, index) => (
              <ItemNavigationSubcategory
                key={index}
                category={sub}
                isRtl={isRtl}
              />
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};
