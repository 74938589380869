import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { spinner } from "../../util/ui_util";
import { AppContext } from "../../context/app/AppState";

export const CheckoutCoupon = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const { couponCode, checkCouponCode } = useContext(CheckoutContext);
  const { showError } = useSnackbar();
  const { primary } = useContext(AppContext);

  const handleClick = () => {
    if (code.length === 0) {
      showError(t("couponCodeEmptyError"));
      return;
    }
    if (couponCode.loading) {
      return;
    }
    checkCouponCode(code);
  };

  return (
    <div className="flex flex-row w-full">
      <div className="w-full">
        <input
          type="text"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          className="lg:px-4 px-2 py-2 outline-none w-full h-12 sm:text-sm rounded-s-md border border-gray-300 focus-border-primary"
          placeholder={t("enterCouponCode")}
        />
      </div>
      <div
        className="flex flex-row items-center bg-hvr-primary h-12 px-8 rounded-e-md cursor-pointer select-none"
        onClick={handleClick}
      >
        {couponCode.loading ? (
          spinner(primary)
        ) : (
          <div className="text-white">{t("save")}</div>
        )}
      </div>
    </div>
  );
};
