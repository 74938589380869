import React, { createContext, useContext, useReducer } from "react";
import CheckoutReducer from "./CheckoutReducer";
import {
  addAddressApi,
  checkCouponCodeApi,
  deleteAddressesApi,
  getAddressesApi,
  getConfigAPi,
  getUserApi,
  onlinePaymentApi,
  placeOrderApi,
} from "../../api/api";
import { AppContext } from "../app/AppState";
import { useSnackbar } from "../general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCart";

// Initial state
export const initialState = {
  config: {
    data: null,
    loading: false,
    error: null,
  },
  user: {
    data: null,
    loading: false,
    error: null,
  },
  addedAddress: {
    data: null,
    loading: false,
    error: null,
  },
  addresses: {
    data: [],
    loading: false,
    error: null,
  },
  deletedAddress: {
    data: null,
    loading: false,
    error: null,
  },
  couponCode: {
    data: null,
    loading: false,
    error: null,
  },
  order: {
    data: null,
    loading: false,
    error: null,
  },
  onlinePayment: {
    data: null,
    loading: false,
    error: null,
  },
  stage: 1,
  isPickup: false,
  selectedBranch: null,
  showAddAddress: false,
  isSomeoneElse: false,
  selectedAddressId: null,
  selectedPaymentMethod: null,
  recipientDetails: {
    recipientName: "",
    countryCode: "+966",
    phone: "",
    email: "",
  },
  paymentInProgress: false,
};

// Create context
export const CheckoutContext = createContext(initialState);

// Provider component
export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CheckoutReducer, initialState);
  const { token } = useContext(AppContext);
  const { showSuccess, showError } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clearCart } = useCart();

  // Actions
  function setStage(stage) {
    dispatch({
      type: "SET_STAGE",
      payload: stage,
    });
  }

  function setIsPickup(value) {
    dispatch({
      type: "SET_IS_PICKUP",
      payload: value,
    });
  }

  function setSelectedBranch(value) {
    dispatch({
      type: "SET_SELECTED_BRANCH",
      payload: value,
    });
  }

  function setShowAddAddress(data) {
    dispatch({
      type: "SET_SHOW_ADD_ADDRESS",
      payload: data,
    });
  }

  function setIsSomeoneElse(data) {
    dispatch({
      type: "SET_IS_SOMEONE_ELSE",
      payload: data,
    });
  }

  function setSelectedAddressId(id) {
    dispatch({
      type: "SET_SELECTED_ADDRESS_ID",
      payload: id,
    });
  }

  function setSelectedPaymentMethod(method) {
    dispatch({
      type: "SET_SELECTED_PAYMENT_METHOD",
      payload: method,
    });
  }

  function updateRecipientDetails(data) {
    dispatch({ type: "UPDATE_RECIPIENT_DETAILS", payload: data });
  }

  function resetState() {
    dispatch({
      type: "RESET_STATE",
    });
  }

  async function getConfig() {
    dispatch({
      type: "GET_CONFIG_REQUEST",
    });
    try {
      const res = await getConfigAPi();
      dispatch({
        type: "GET_CONFIG_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_CONFIG_FAILURE",
        payload: error.message || "Failed to fetch config",
      });
    }
  }

  async function getUser() {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_USER_REQUEST",
    });
    try {
      const user = await getUserApi({ token: tokenString });
      dispatch({
        type: "GET_USER_SUCCESS",
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_FAILURE",
        payload: error.message || "Failed to fetch user",
      });
    }
  }

  async function addAddress(addressData) {
    const tokenString = token.data.token;
    dispatch({
      type: "ADD_ADDRESS_REQUEST",
    });
    try {
      const data = await addAddressApi({ token: tokenString, ...addressData });
      dispatch({
        type: "ADD_ADDRESS_SUCCESS",
        payload: data,
      });
      getAddresses();
      showSuccess(t("addAddressSuccessMessage"));
    } catch (error) {
      dispatch({
        type: "ADD_ADDRESS_FAILURE",
        payload: error.message || "Failed to add address",
      });
      showError(error.message || "Failed to add address");
    }
  }

  async function getAddresses() {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_ADDRESSES_REQUEST",
    });
    try {
      const user = await getAddressesApi({ token: tokenString });
      dispatch({
        type: "GET_ADDRESSES_SUCCESS",
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: "GET_ADDRESSES_FAILURE",
        payload: error.message || "Failed to fetch addresses",
      });
    }
  }

  async function deleteAddress(addressId) {
    const tokenString = token.data.token;
    dispatch({
      type: "DELETE_ADDRESS_REQUEST",
    });
    try {
      const data = await deleteAddressesApi({ token: tokenString, addressId });
      dispatch({
        type: "DELETE_ADDRESS_SUCCESS",
        payload: data,
      });
      getAddresses();
      showSuccess(t("deleteAddressSuccessMessage"));
    } catch (error) {
      dispatch({
        type: "DELETE_ADDRESS_FAILURE",
        payload: error.message || "Failed to delete address",
      });
      showError(error.message || "Failed to delete address");
    }
  }

  async function checkCouponCode(code) {
    const tokenString = token.data.token;
    dispatch({
      type: "CHECK_COUPON_CODE_REQUEST",
    });
    try {
      const data = await checkCouponCodeApi({ token: tokenString, code });
      dispatch({
        type: "CHECK_COUPON_CODE_SUCCESS",
        payload: data,
      });
      showSuccess(t("couponCodeApplied"));
    } catch (error) {
      dispatch({
        type: "CHECK_COUPON_CODE_FAILURE",
        payload: error.message || "Failed to check coupon code",
      });
      showError(error.message || "Failed to check coupon code");
    }
  }

  async function placeOrder(orderData) {
    const tokenString = token.data.token;
    dispatch({
      type: "SET_PAYMENT_IN_PROGRESS",
      payload: true,
    });
    dispatch({
      type: "PLACE_ORDER_REQUEST",
    });
    try {
      const data = await placeOrderApi({ token: tokenString, ...orderData });
      dispatch({
        type: "PLACE_ORDER_SUCCESS",
        payload: data,
      });
      if (orderData.paymentMethod !== "cash_on_delivery") {
        submitOnlinePayment({
          customerId: state.user.data.id,
          orderAmount: orderData.orderAmount,
          paymentMethod: orderData.paymentMethod,
          orderId: data.order_id,
        });
      } else {
        showSuccess(t("successfulOrderMessage"));
        navigate(`/thank-you/${data.order_id}`);
        resetState();
        clearCart();
      }
    } catch (error) {
      dispatch({
        type: "PLACE_ORDER_FAILURE",
        payload: error.message || "Failed to place order",
      });
      showError(error.message || "Failed to place order");
    }
  }

  async function submitOnlinePayment(onlinePaymentData) {
    const tokenString = token.data.token;
    dispatch({
      type: "SET_PAYMENT_IN_PROGRESS",
      payload: true,
    });
    dispatch({
      type: "ONLINE_PAYMENT_REQUEST",
    });
    try {
      const data = await onlinePaymentApi({
        token: tokenString,
        ...onlinePaymentData,
      });
      dispatch({
        type: "ONLINE_PAYMENT_SUCCESS",
        payload: data,
      });
      clearCart();
      showSuccess(t("successfulOrderMessage"));
      window.location.href = data.redirect_link;
    } catch (error) {
      dispatch({
        type: "ONLINE_PAYMENT_FAILURE",
        payload: error.message || "Failed to do online payment",
      });
      showError(error.message || "Failed to do online payment");
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        user: state.user,
        addedAddress: state.addedAddress,
        deletedAddress: state.deletedAddress,
        stage: state.stage,
        isPickup: state.isPickup,
        selectedBranch: state.selectedBranch,
        showAddAddress: state.showAddAddress,
        isSomeoneElse: state.isSomeoneElse,
        config: state.config,
        addresses: state.addresses,
        selectedAddressId: state.selectedAddressId,
        selectedPaymentMethod: state.selectedPaymentMethod,
        recipientDetails: state.recipientDetails,
        couponCode: state.couponCode,
        order: state.order,
        onlinePayment: state.onlinePayment,
        paymentInProgress: state.paymentInProgress,
        setStage,
        setIsPickup,
        setSelectedBranch,
        setShowAddAddress,
        setIsSomeoneElse,
        setSelectedAddressId,
        setSelectedPaymentMethod,
        updateRecipientDetails,
        resetState,
        getConfig,
        getUser,
        addAddress,
        getAddresses,
        deleteAddress,
        checkCouponCode,
        placeOrder,
        submitOnlinePayment,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
