import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedPrice } from "../../util/ui_util";
import { CheckoutCoupon } from "./CheckoutCoupon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";
import useCart from "../../hooks/useCart";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { applyCoupon, getCouponDiscountAmount } from "../../util/util";

export const CheckoutTop = () => {
  const navigate = useNavigate();
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const { user, couponCode, isPickup, config } = useContext(CheckoutContext);
  const { getCartSubtotal, getCartTax } = useCart();

  const calculateTotal = () => {
    let total = getCartSubtotal() + getCartTax();
    if (couponCode.data) {
      total = applyCoupon(total, couponCode.data);
    }
    if (!isPickup) {
      total += config.data.delivery_charge;
    }
    return total;
  };

  const leftCutout = () => {
    return (
      <div
        className="absolute top-1/2 -translate-y-1/2 left-[-15px] flex w-[30px] h-[30px] rounded-full bg-background"
        style={{
          boxShadow: "1px 0 2px rgba(0, 0, 0, 0.1)", // Shadow on the right side
        }}
      ></div>
    );
  };

  const rightCutout = () => {
    return (
      <div
        className="absolute top-1/2 -translate-y-1/2 right-[-15px] flex w-[30px] h-[30px] rounded-full bg-background"
        style={{
          boxShadow: "-1px 0 2px rgba(0, 0, 0, 0.1)", // Shadow on the left side
        }}
      ></div>
    );
  };

  const bottomCutout = () => {
    return (
      <div className="absolute bottom-[-50px] left-0 right-0 flex justify-center">
        <div
          className="rounded-full bg-background p-3"
          style={{
            boxShadow: "0px 2 40px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="bg-white rounded-full px-4 py-2 shadow-lg flex cursor-pointer"
            draggable={false}
            style={{ userSelect: "none" }}
            onClick={() => {
              setShowInvoiceDetails(!showInvoiceDetails);
            }}
          >
            <div className="text-text text-sm">{t("invoiceDetails")}</div>
          </div>
        </div>
      </div>
    );
  };

  const linkText = ({ text, to }) => {
    return to ? (
      <div
        className="text-gray-300 txt-hvr-primary cursor-pointer"
        onClick={() => {
          navigate(to);
        }}
      >
        {text}
      </div>
    ) : (
      <div className="text-text">{text}</div>
    );
  };

  return (
    <div className="relative w-full md:w-[699px] lg:w-[929px]">
      {leftCutout()}
      {rightCutout()}
      {bottomCutout()}
      <div className="flex flex-col bg-white rounded-t-lg shadow-md w-full py-4">
        <div className="flex flex-row px-8 space-x-4 rtl:space-x-reverse items-center">
          <img
            src={`${config.data.base_urls.ecommerce_image_url}/${config.data.app_logo}`}
            alt="logo"
            className="w-[80px] h-[80px] border border-gray-300 rounded-lg p-2"
          />
          <div className="flex flex-col">
            <div className="font-bold text-text text-lg">
              {`${t("welcome")} ${user.data.f_name} ${user.data.l_name}`}
            </div>
            <div className="flex flex-row space-x-2 rtl:space-x-reverse">
              {linkText({ text: "Toot mart", to: "/" })}
              <div className="text-text">/</div>
              {linkText({ text: t("cart"), to: "/cart" })}
              <div className="text-text">/</div>
              {linkText({ text: t("completeOrder") })}
            </div>
          </div>
        </div>
        <div
          className={`flex-col w-full px-8 py-4 ${
            showInvoiceDetails ? "flex" : "hidden"
          }`}
        >
          <div className={`flex flex-row w-full justify-between`}>
            <div className="text-text">{t("subtotal")}</div>
            <div className="text-text">
              {getFormattedPrice(getCartSubtotal(), language)}
            </div>
          </div>
          <div className={`flex flex-row w-full justify-between`}>
            <div className="text-text">{t("tax")}</div>
            <div className="text-text">
              {getFormattedPrice(getCartTax(), language)}
            </div>
          </div>
          {!isPickup && (
            <div className={`flex flex-row w-full justify-between`}>
              <div className="text-text">{t("delivery")}</div>
              <div className="text-text">
                {getFormattedPrice(config.data.delivery_charge, language)}
              </div>
            </div>
          )}
          {couponCode.data && (
            <div className={`flex flex-row w-full justify-between`}>
              <div className="text-text">{t("discount")}</div>
              <div className="text-text">
                {getFormattedPrice(
                  getCouponDiscountAmount(
                    getCartSubtotal() + getCartTax(),
                    couponCode.data
                  ),
                  language
                )}
              </div>
            </div>
          )}
        </div>
        <div className="h-[40px]"></div>
        <div className="absolute translate-x-[15px] rtl:-translate-x-[15px] top-1/2 w-full md:w-[669px] lg:w-[899px] h-[3px] bg-gray-100"></div>
        <div
          className={`flex flex-row w-full px-8 justify-between ${
            showInvoiceDetails ? "mt-4" : "mt-14"
          }`}
        >
          <div className="text-text font-bold text-xl">{t("orderTotal")}</div>
          <div className="text-text font-bold text-xl">
            {getFormattedPrice(calculateTotal(), language)}
          </div>
        </div>
        <div className="flex flex-row w-full px-8 justify-end">
          <div className="text-red-600 cursor-pointer">
            {t("doYouHaveCoupon")}
          </div>
        </div>
        <div className="flex flex-row w-full px-8 items-center mt-4">
          <CheckoutCoupon />
        </div>
      </div>
    </div>
  );
};
