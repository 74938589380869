import { getFormattedPrice } from "./ui_util";

export function findParentCategory(categories, targetId, parent = null) {
  for (let category of categories) {
    if (category.id === targetId) {
      return parent;
    }
    if (category.categories && category.categories.length > 0) {
      const result = findParentCategory(
        category.categories,
        targetId,
        category
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export const emailPattern = new RegExp(
  "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
);

export async function copyToClipboard(text, onComplete, onError) {
  try {
    await navigator.clipboard.writeText(text);
    onComplete();
  } catch (err) {
    console.error("Failed to copy: ", err);
    if (onError) onError(err);
  }
}

export const formatDateYYYYMMDD = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(dateString).toLocaleDateString("en-CA", options);
  return date.replace(/\//g, "-");
};

export function timeAgo(dateString, t, language) {
  const now = new Date();
  const createdAt = new Date(dateString);
  const diffInMs = now - createdAt;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(diffInMs / (1000 * 60));
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const years = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));

  const fromT = `${t("fromT")} `;

  if (years > 1) {
    return t("moreThanYearAgo");
  } else if (days >= 1) {
    return `${language === "ar" ? fromT : ""}${days} ${t("daysAgo")}`;
  } else if (hours >= 1) {
    return `${language === "ar" ? fromT : ""}${hours} ${t("hoursAgo")}`;
  } else if (minutes >= 1) {
    return `${language === "ar" ? fromT : ""}${minutes} ${t("minutesAgo")}`;
  } else {
    return `${language === "ar" ? fromT : ""}${seconds} ${t("secondsAgo")}`;
  }
}

export const getDiscountedPrice = (product) => {
  let discountedPrice = product.price;
  if (product.discount_type === "percent") {
    discountedPrice -= (product.price * product.discount) / 100;
    discountedPrice = Math.round(discountedPrice);
  } else if (product.discount_type === "amount") {
    discountedPrice -= product.discount;
    discountedPrice = Math.round(discountedPrice);
  }
  return discountedPrice;
};

export const applyCoupon = (total, couponCode) => {
  let newTotal = total;
  if (couponCode.discount_type === "percent") {
    newTotal -= (newTotal * couponCode.discount) / 100;
    newTotal = Math.round(newTotal);
  } else if (couponCode.discount_type === "amount") {
    newTotal -= couponCode.discount;
    newTotal = Math.round(newTotal);
  }
  return newTotal;
};

export const getCouponDiscountAmount = (total, couponCode) => {
  let discount;
  if (couponCode.discount_type === "percent") {
    discount = (total * couponCode.discount) / 100;
    discount = Math.round(discount);
  } else if (couponCode.discount_type === "amount") {
    discount = couponCode.discount;
  }
  return discount;
};

export const getDiscountString = (product, t, language) => {
  return `${
    product.discount_type === "percent"
      ? product.discount
      : getFormattedPrice(product.discount, language)
  }${product.discount_type === "percent" ? "%" : ""} ${t("discount")}`;
};

export const getLatestConfig = () => {
  const latestConfigString = localStorage.getItem("latestConfig");
  if (latestConfigString) {
    const latestConfig = JSON.parse(latestConfigString);
    return latestConfig;
  }
  return null;
};

export const customLabels = {
  AD: { primary: "AD", secondary: "+376" },
  AE: { primary: "AE", secondary: "+971" },
  AF: { primary: "AF", secondary: "+93" },
  AG: { primary: "AG", secondary: "+1-268" },
  AI: { primary: "AI", secondary: "+1-264" },
  AL: { primary: "AL", secondary: "+355" },
  AM: { primary: "AM", secondary: "+374" },
  AO: { primary: "AO", secondary: "+244" },
  AR: { primary: "AR", secondary: "+54" },
  AS: { primary: "AS", secondary: "+1-684" },
  AT: { primary: "AT", secondary: "+43" },
  AU: { primary: "AU", secondary: "+61" },
  AW: { primary: "AW", secondary: "+297" },
  AX: { primary: "AX", secondary: "+358" },
  AZ: { primary: "AZ", secondary: "+994" },
  BA: { primary: "BA", secondary: "+387" },
  BB: { primary: "BB", secondary: "+1-246" },
  BD: { primary: "BD", secondary: "+880" },
  BE: { primary: "BE", secondary: "+32" },
  BF: { primary: "BF", secondary: "+226" },
  BG: { primary: "BG", secondary: "+359" },
  BH: { primary: "BH", secondary: "+973" },
  BI: { primary: "BI", secondary: "+257" },
  BJ: { primary: "BJ", secondary: "+229" },
  BL: { primary: "BL", secondary: "+590" },
  BM: { primary: "BM", secondary: "+1-441" },
  BN: { primary: "BN", secondary: "+673" },
  BO: { primary: "BO", secondary: "+591" },
  BQ: { primary: "BQ", secondary: "+599" },
  BR: { primary: "BR", secondary: "+55" },
  BS: { primary: "BS", secondary: "+1-242" },
  BT: { primary: "BT", secondary: "+975" },
  BW: { primary: "BW", secondary: "+267" },
  BY: { primary: "BY", secondary: "+375" },
  BZ: { primary: "BZ", secondary: "+501" },
  CA: { primary: "CA", secondary: "+1" },
  CC: { primary: "CC", secondary: "+61" },
  CD: { primary: "CD", secondary: "+243" },
  CF: { primary: "CF", secondary: "+236" },
  CG: { primary: "CG", secondary: "+242" },
  CH: { primary: "CH", secondary: "+41" },
  CI: { primary: "CI", secondary: "+225" },
  CK: { primary: "CK", secondary: "+682" },
  CL: { primary: "CL", secondary: "+56" },
  CM: { primary: "CM", secondary: "+237" },
  CN: { primary: "CN", secondary: "+86" },
  CO: { primary: "CO", secondary: "+57" },
  CR: { primary: "CR", secondary: "+506" },
  CU: { primary: "CU", secondary: "+53" },
  CV: { primary: "CV", secondary: "+238" },
  CW: { primary: "CW", secondary: "+599" },
  CX: { primary: "CX", secondary: "+61" },
  CY: { primary: "CY", secondary: "+357" },
  CZ: { primary: "CZ", secondary: "+420" },
  DE: { primary: "DE", secondary: "+49" },
  DJ: { primary: "DJ", secondary: "+253" },
  DK: { primary: "DK", secondary: "+45" },
  DM: { primary: "DM", secondary: "+1-767" },
  DO: { primary: "DO", secondary: "+1-809" },
  DZ: { primary: "DZ", secondary: "+213" },
  EC: { primary: "EC", secondary: "+593" },
  EE: { primary: "EE", secondary: "+372" },
  EG: { primary: "EG", secondary: "+20" },
  EH: { primary: "EH", secondary: "+212" },
  ER: { primary: "ER", secondary: "+291" },
  ES: { primary: "ES", secondary: "+34" },
  ET: { primary: "ET", secondary: "+251" },
  FI: { primary: "FI", secondary: "+358" },
  FJ: { primary: "FJ", secondary: "+679" },
  FM: { primary: "FM", secondary: "+691" },
  FO: { primary: "FO", secondary: "+298" },
  FR: { primary: "FR", secondary: "+33" },
  GA: { primary: "GA", secondary: "+241" },
  GB: { primary: "GB", secondary: "+44" },
  GD: { primary: "GD", secondary: "+1-473" },
  GE: { primary: "GE", secondary: "+995" },
  GF: { primary: "GF", secondary: "+594" },
  GG: { primary: "GG", secondary: "+44" },
  GH: { primary: "GH", secondary: "+233" },
  GI: { primary: "GI", secondary: "+350" },
  GL: { primary: "GL", secondary: "+299" },
  GM: { primary: "GM", secondary: "+220" },
  GN: { primary: "GN", secondary: "+224" },
  GQ: { primary: "GQ", secondary: "+240" },
  GR: { primary: "GR", secondary: "+30" },
  GT: { primary: "GT", secondary: "+502" },
  GU: { primary: "GU", secondary: "+1-671" },
  GW: { primary: "GW", secondary: "+245" },
  GY: { primary: "GY", secondary: "+592" },
  HK: { primary: "HK", secondary: "+852" },
  HN: { primary: "HN", secondary: "+504" },
  HR: { primary: "HR", secondary: "+385" },
  HT: { primary: "HT", secondary: "+509" },
  HU: { primary: "HU", secondary: "+36" },
  ID: { primary: "ID", secondary: "+62" },
  IE: { primary: "IE", secondary: "+353" },
  IL: { primary: "IL", secondary: "+972" },
  IM: { primary: "IM", secondary: "+44" },
  IN: { primary: "IN", secondary: "+91" },
  IO: { primary: "IO", secondary: "+246" },
  IQ: { primary: "IQ", secondary: "+964" },
  IR: { primary: "IR", secondary: "+98" },
  IS: { primary: "IS", secondary: "+354" },
  IT: { primary: "IT", secondary: "+39" },
  JE: { primary: "JE", secondary: "+44" },
  JM: { primary: "JM", secondary: "+1-876" },
  JO: { primary: "JO", secondary: "+962" },
  JP: { primary: "JP", secondary: "+81" },
  KE: { primary: "KE", secondary: "+254" },
  KG: { primary: "KG", secondary: "+996" },
  KH: { primary: "KH", secondary: "+855" },
  KI: { primary: "KI", secondary: "+686" },
  KM: { primary: "KM", secondary: "+269" },
  KN: { primary: "KN", secondary: "+1-869" },
  KP: { primary: "KP", secondary: "+850" },
  KR: { primary: "KR", secondary: "+82" },
  KW: { primary: "KW", secondary: "+965" },
  KY: { primary: "KY", secondary: "+1-345" },
  KZ: { primary: "KZ", secondary: "+7" },
  LA: { primary: "LA", secondary: "+856" },
  LB: { primary: "LB", secondary: "+961" },
  LC: { primary: "LC", secondary: "+1-758" },
  LI: { primary: "LI", secondary: "+423" },
  LK: { primary: "LK", secondary: "+94" },
  LR: { primary: "LR", secondary: "+231" },
  LS: { primary: "LS", secondary: "+266" },
  LT: { primary: "LT", secondary: "+370" },
  LU: { primary: "LU", secondary: "+352" },
  LV: { primary: "LV", secondary: "+371" },
  LY: { primary: "LY", secondary: "+218" },
  MA: { primary: "MA", secondary: "+212" },
  MC: { primary: "MC", secondary: "+377" },
  MD: { primary: "MD", secondary: "+373" },
  ME: { primary: "ME", secondary: "+382" },
  MF: { primary: "MF", secondary: "+590" },
  MG: { primary: "MG", secondary: "+261" },
  MH: { primary: "MH", secondary: "+692" },
  MK: { primary: "MK", secondary: "+389" },
  ML: { primary: "ML", secondary: "+223" },
  MM: { primary: "MM", secondary: "+95" },
  MN: { primary: "MN", secondary: "+976" },
  MO: { primary: "MO", secondary: "+853" },
  MP: { primary: "MP", secondary: "+1-670" },
  MR: { primary: "MR", secondary: "+222" },
  MS: { primary: "MS", secondary: "+1-664" },
  MT: { primary: "MT", secondary: "+356" },
  MU: { primary: "MU", secondary: "+230" },
  MV: { primary: "MV", secondary: "+960" },
  MW: { primary: "MW", secondary: "+265" },
  MX: { primary: "MX", secondary: "+52" },
  MY: { primary: "MY", secondary: "+60" },
  MZ: { primary: "MZ", secondary: "+258" },
  NA: { primary: "NA", secondary: "+264" },
  NC: { primary: "NC", secondary: "+687" },
  NE: { primary: "NE", secondary: "+227" },
  NF: { primary: "NF", secondary: "+672" },
  NG: { primary: "NG", secondary: "+234" },
  NI: { primary: "NI", secondary: "+505" },
  NL: { primary: "NL", secondary: "+31" },
  NO: { primary: "NO", secondary: "+47" },
  NP: { primary: "NP", secondary: "+977" },
  NR: { primary: "NR", secondary: "+674" },
  NU: { primary: "NU", secondary: "+683" },
  NZ: { primary: "NZ", secondary: "+64" },
  OM: { primary: "OM", secondary: "+968" },
  PA: { primary: "PA", secondary: "+507" },
  PE: { primary: "PE", secondary: "+51" },
  PF: { primary: "PF", secondary: "+689" },
  PG: { primary: "PG", secondary: "+675" },
  PH: { primary: "PH", secondary: "+63" },
  PK: { primary: "PK", secondary: "+92" },
  PL: { primary: "PL", secondary: "+48" },
  PM: { primary: "PM", secondary: "+508" },
  PR: { primary: "PR", secondary: "+1-787" },
  PT: { primary: "PT", secondary: "+351" },
  PW: { primary: "PW", secondary: "+680" },
  PY: { primary: "PY", secondary: "+595" },
  QA: { primary: "QA", secondary: "+974" },
  RE: { primary: "RE", secondary: "+262" },
  RO: { primary: "RO", secondary: "+40" },
  RS: { primary: "RS", secondary: "+381" },
  RU: { primary: "RU", secondary: "+7" },
  RW: { primary: "RW", secondary: "+250" },
  SA: { primary: "SA", secondary: "+966" },
  SB: { primary: "SB", secondary: "+677" },
  SC: { primary: "SC", secondary: "+248" },
  SD: { primary: "SD", secondary: "+249" },
  SE: { primary: "SE", secondary: "+46" },
  SG: { primary: "SG", secondary: "+65" },
  SH: { primary: "SH", secondary: "+290" },
  SI: { primary: "SI", secondary: "+386" },
  SJ: { primary: "SJ", secondary: "+47" },
  SK: { primary: "SK", secondary: "+421" },
  SL: { primary: "SL", secondary: "+232" },
  SM: { primary: "SM", secondary: "+378" },
  SN: { primary: "SN", secondary: "+221" },
  SO: { primary: "SO", secondary: "+252" },
  SR: { primary: "SR", secondary: "+597" },
  SS: { primary: "SS", secondary: "+211" },
  ST: { primary: "ST", secondary: "+239" },
  SV: { primary: "SV", secondary: "+503" },
  SX: { primary: "SX", secondary: "+1-721" },
  SY: { primary: "SY", secondary: "+963" },
  SZ: { primary: "SZ", secondary: "+268" },
  TC: { primary: "TC", secondary: "+1-649" },
  TD: { primary: "TD", secondary: "+235" },
  TF: { primary: "TF", secondary: "+262" },
  TG: { primary: "TG", secondary: "+228" },
  TH: { primary: "TH", secondary: "+66" },
  TJ: { primary: "TJ", secondary: "+992" },
  TK: { primary: "TK", secondary: "+690" },
  TL: { primary: "TL", secondary: "+670" },
  TM: { primary: "TM", secondary: "+993" },
  TN: { primary: "TN", secondary: "+216" },
  TO: { primary: "TO", secondary: "+676" },
  TR: { primary: "TR", secondary: "+90" },
  TT: { primary: "TT", secondary: "+1-868" },
  TV: { primary: "TV", secondary: "+688" },
  TZ: { primary: "TZ", secondary: "+255" },
  UA: { primary: "UA", secondary: "+380" },
  UG: { primary: "UG", secondary: "+256" },
  US: { primary: "US", secondary: "+1" },
  UY: { primary: "UY", secondary: "+598" },
  UZ: { primary: "UZ", secondary: "+998" },
  VA: { primary: "VA", secondary: "+39" },
  VC: { primary: "VC", secondary: "+1-784" },
  VE: { primary: "VE", secondary: "+58" },
  VG: { primary: "VG", secondary: "+1-284" },
  VI: { primary: "VI", secondary: "+1-340" },
  VN: { primary: "VN", secondary: "+84" },
  VU: { primary: "VU", secondary: "+678" },
  WF: { primary: "WF", secondary: "+681" },
  WS: { primary: "WS", secondary: "+685" },
  YE: { primary: "YE", secondary: "+967" },
  YT: { primary: "YT", secondary: "+262" },
  ZA: { primary: "ZA", secondary: "+27" },
  ZM: { primary: "ZM", secondary: "+260" },
  ZW: { primary: "ZW", secondary: "+263" },
};
