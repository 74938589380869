import { getLatestConfig } from "./util";

const colors = {
  secondary: "#333333",
  accent: "#F59E0B",
  background: "#FCFCFC",
  text: "#555555",
  success: "#10B981",
  warning: "#FBBF24",
  danger: "#EF4444",
  aboutBackground: "#F7F7F7",
  extendedBurgerMenuBackground: "#0E0E0F",
  navbarBackground: "#151936",
  loginDialogBackground: "#F8F9FB",
  disabledButton: "#94989A",
};

export function setPrimaryColor({ colorPrimary, colorPrimaryDark }) {
  document.documentElement.style.setProperty("--primary-color", colorPrimary);
  document.documentElement.style.setProperty(
    "--primary-dark-color",
    colorPrimaryDark
  );
}

export function getPrimaryColor() {
  const latestConfig = getLatestConfig();
  if (latestConfig?.primary_color) {
    return latestConfig.primary_color;
  }
  return "#BB8713";
}

export function getPrimaryColorDark() {
  const latestConfig = getLatestConfig();
  if (latestConfig?.primary_color_dark) {
    return latestConfig.primary_color_dark;
  }
  return "#9a7010";
}

export default colors;
