import React, { useContext, useEffect } from "react";
import { Layout } from "../../components/Layout";
import { ItemOrder } from "./items/ItemOrder";
import { AccountTop } from "../../components/AccountTop";
import { iconOrders, spinner } from "../../util/ui_util";
import colors from "../../util/colors";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { OrdersContext } from "../../context/orders/OrdersState";
import { AppContext } from "../../context/app/AppState";

export const OrdersPage = () => {
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { t } = useTranslation();
  const { orders, getOrders } = useContext(OrdersContext);
  const { isAuthenticated, language, primary } = useContext(AppContext);
  const isRtl = language === "ar";

  useEffect(() => {
    if (!isAuthenticated) {
      showError(t("notLoggedInError"));
      navigate("/");
    }
  }, [showError, t, navigate, isAuthenticated]);

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orders?.error) {
      showError(orders.error);
    }
  }, [orders, showError]);

  return (
    <Layout>
      <div className="flex flex-col mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {t("orders")}
        </div>
        <div className="w-full hidden md:flex flex-row justify-between px-8">
          <div className="grid grid-cols-3 gap-12">
            <div className="text-text w-32">{t("orderNumber")}</div>
            <div className="text-text w-32">{t("total")}</div>
            <div className="text-text w-32">{t("orderDate")}</div>
          </div>
          <div className="text-text">{t("orderStatus")}</div>
        </div>
        {orders.loading ? (
          <div className="flex flex-row w-full justify-center">
            {spinner(primary)}
          </div>
        ) : orders.data.length > 0 ? (
          <ul className="space-y-4 mt-10">
            {orders.data.map((order, index) => (
              <li>
                <ItemOrder order={order} key={index} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex flex-col items-center space-y-2 mt-8">
            <div className="flex flex-row w-[100px] h-[100px] justify-center items-center bg-gray-300 rounded-full">
              {iconOrders({
                fillColor: colors.text,
                width: "40px",
                height: "40px",
              })}
            </div>
            <div>{t("noOrders")}</div>
          </div>
        )}
      </div>
    </Layout>
  );
};
