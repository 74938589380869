import React, { useContext, useEffect } from "react";
import { Layout } from "../../components/Layout";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/app/AppState";
import { AccountTop } from "../../components/AccountTop";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../context/order/OrderState";
import {
  defaultLoader,
  getFormattedPrice,
  iconClock,
} from "../../util/ui_util";
import { formatDateYYYYMMDD } from "../../util/util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { ItemOrderDetail } from "./items/ItemOrderDetail";
import colors from "../../util/colors";

export const OrderPage = () => {
  const { orderId } = useParams();
  const { order, trackOrder, details, getOrderDetails } =
    useContext(OrderContext);
  const { language, primary } = useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();

  useEffect(() => {
    if (orderId) {
      trackOrder({ orderId });
      getOrderDetails({ orderId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <Layout>
      <div className="flex flex-col px-4 lg:px-0 mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {`${t("orderDetails")} #${orderId}`}
        </div>
        {order.loading ? (
          defaultLoader()
        ) : order?.data ? (
          <div className="mt-8">
            <div className="hidden lg:flex flex-row justify-between items-center">
              <div className="flex flex-col items-center">
                <div className="text-text">{t("orderDate")}</div>
                <div className="text-text font-bold">
                  {formatDateYYYYMMDD(order.data.created_at)}
                </div>
              </div>
              <div className="flex flex-row space-x-2 rtl:space-x-reverse items-center">
                {iconClock({
                  fillColor: colors.text,
                  width: "1.5em",
                  height: "1.5em",
                })}
                <div className="text-text font-bold mt-1">
                  {order.data.order_status}
                </div>
              </div>
              <div>
                <ButtonWithIcon
                  text={t("print")}
                  textColor={primary}
                  textHoverColor="#ffffff"
                  bgColor="transparent"
                  bgHoverColor={primary}
                  onClick={() => {
                    window.print();
                  }}
                />
              </div>
            </div>
            <div className="flex lg:hidden flex-col w-full items-center">
              <div className="flex flex-row w-full justify-between items-center">
                <div className="text-text">{t("orderDate")}</div>
                <div className="text-text font-bold">
                  {formatDateYYYYMMDD(order.data.created_at)}
                </div>
              </div>
              <div className="flex flex-row w-full justify-between items-center">
                <div className="flex flex-row space-x-2 rtl:space-x-reverse items-center">
                  {iconClock({
                    fillColor: colors.text,
                    width: "1.5em",
                    height: "1.5em",
                  })}
                  <div className="text-text font-bold mt-1">
                    {order.data.order_status}
                  </div>
                </div>
                <div>
                  <ButtonWithIcon
                    text={t("print")}
                    textColor={primary}
                    textHoverColor="#ffffff"
                    bgColor="transparent"
                    bgHoverColor={primary}
                    onClick={() => {
                      window.print();
                    }}
                  />
                </div>
              </div>
            </div>
            {details?.data && details.data.length > 0 && (
              <div className="mt-8">
                {details.data.map((detail, index) => (
                  <ItemOrderDetail detail={detail} key={index} />
                ))}
              </div>
            )}
            <div className="space-y-4 mt-8">
              <div className="flex flex-row justify-between">
                <div className="text-text">{t("subtotal")}</div>
                <div className="text-text font-bold">
                  {getFormattedPrice(order.data.order_amount, language)}
                </div>
              </div>
              <div className="h-[1px] w-full bg-gray-300"></div>
              <div className="flex flex-row justify-between">
                <div className="text-text">{t("delivery")}</div>
                <div className="text-text font-bold">
                  {getFormattedPrice(order.data.delivery_charge, language)}
                </div>
              </div>
              <div className="h-[1px] w-full bg-gray-300"></div>
              <div className="flex flex-row justify-between">
                <div className="text-text">{t("tax")}</div>
                <div className="text-text font-bold">
                  {getFormattedPrice(order.data.total_tax_amount, language)}
                </div>
              </div>
              {order.data.coupon_discount_amount ? (
                <div className="space-y-4">
                  <div className="h-[1px] w-full bg-gray-300"></div>
                  <div className="flex flex-row justify-between">
                    <div className="text-text">{t("discount")}</div>
                    <div className="text-text font-bold">
                      {getFormattedPrice(
                        order.data.coupon_discount_amount,
                        language
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="h-[1px] w-full bg-gray-300"></div>
              <div className="flex flex-row justify-between">
                <div className="text-text text-lg">{t("total")}</div>
                <div className="text-text text-lg font-bold">
                  {getFormattedPrice(
                    order.data.order_amount +
                      order.data.delivery_charge +
                      order.data.total_tax_amount -
                      order.data.coupon_discount_amount,
                    language
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};
