import React, { useContext } from "react";
import { iconRadioChecked, iconRadioUnchecked } from "../../../util/ui_util";
import { CheckoutContext } from "../../../context/checkout/CheckoutState";
import { AppContext } from "../../../context/app/AppState";
import colors from "../../../util/colors";

export const ItemCheckoutDeliveryOrBranch = ({ id, text }) => {
  const { isPickup, setIsPickup } = useContext(CheckoutContext);
  const isSelected = isPickup ? id === 2 : id === 1;
  const { primary } = useContext(AppContext);

  return (
    <div
      className={`flex flex-row w-full justify-between items-center rounded-md p-2 border cursor-pointer select-none`}
      style={{ borderColor: isSelected ? primary : colors.text }}
      onClick={() => {
        setIsPickup(id === 2);
      }}
    >
      <div className="flex flex-row items-center">
        {isSelected
          ? iconRadioChecked({ fillColor: primary, width: "2em" })
          : iconRadioUnchecked({ fillColor: colors.text, width: "2em" })}
        <div className="text-text pt-1">{text}</div>
      </div>
    </div>
  );
};
