import React, { useContext } from "react";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";

export const ProductQuantity = ({ minQty, maxQty, quantity, setQuantity }) => {
  const { showError } = useSnackbar();
  const { t } = useTranslation();
  const { primary } = useContext(AppContext);

  if (!minQty) {
    minQty = 1;
  }

  const handlePlusClick = () => {
    if (quantity >= maxQty) {
      showError(t("productMaxQuantityMessage"));
      return;
    }
    setQuantity((prev) => prev + 1);
  };

  const handleMinusClick = () => {
    if (quantity === minQty) {
      return;
    }
    setQuantity((prev) => prev - 1);
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="font-bold text-xl" style={{ color: primary }}>
        {t("quantity")}
      </div>
      <div className="flex flex-row">
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl border border-gray-300 cursor-pointer select-none"
          onClick={handlePlusClick}
          draggable={false}
        >
          +
        </div>
        <div className="flex items-center justify-center pt-1 w-[50px] text-text font-bold border border-gray-300">
          {quantity}
        </div>
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl border border-gray-300 cursor-pointer select-none"
          onClick={handleMinusClick}
          draggable={false}
        >
          -
        </div>
      </div>
    </div>
  );
};
