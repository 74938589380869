import React, { createContext, useContext, useReducer } from "react";
import { AppContext } from "../app/AppState";
import NotificationsReducer from "./NotificationsReducer";
import { getNotificationsApi } from "../../api/api";

// Initial state
const initialState = {
  notifications: {
    data: [],
    loading: false,
    error: null,
  },
};

// Create context
export const NotificationsContext = createContext(initialState);

// Provider component
export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationsReducer, initialState);
  const { token } = useContext(AppContext);

  // Actions
  async function getNotifications() {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_NOTIFICATIONS_REQUEST",
    });
    try {
      const data = await getNotificationsApi({ token: tokenString });
      dispatch({
        type: "GET_NOTIFICATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_NOTIFICATIONS_FAILURE",
        payload: error.message || "Failed to fetch notifications",
      });
    }
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications: state.notifications,
        getNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
