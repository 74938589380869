import React, { useContext, useState, useEffect } from "react";
import "../assets/styles/banner.css";
import { AppContext } from "../context/app/AppState";

export const Banner = () => {
  const [isPaused, setIsPaused] = useState(false);
  const { newsBanner, getNewsBanner, language, primary } = useContext(AppContext);

  useEffect(() => {
    getNewsBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="top-0 w-screen z-20" style={{background: primary}}>
      <div
        className={`overflow-hidden whitespace-nowrap ${
          isPaused ? "paused" : ""
        }`}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {newsBanner?.loading || newsBanner?.error ? (
          <></>
        ) : (
          <div className="animate-marquee py-2">
            {[
              newsBanner?.data,
              newsBanner?.data,
              newsBanner?.data,
              newsBanner?.data,
              newsBanner?.data,
            ].map((banner, index) => (
              <div className="px-8 text-white" key={index}>
                {language === "ar" ? banner?.top_text_ar : banner?.top_text_en}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
