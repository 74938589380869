import React, { useContext } from "react";
import colors from "../util/colors";
import { useNavigate } from "react-router-dom";
import {
  iconAccount,
  iconLogout,
  iconMessages,
  iconNotifications,
  iconOrders,
  iconStar,
} from "../util/ui_util";
import { AppContext } from "../context/app/AppState";
import { useTranslation } from "react-i18next";

export const AccountNavigation = () => {
  const { logout, setShowAccountNavigation } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navItem = ({ text, icon, to }) => {
    return (
      <div
        className="flex flex-row space-x-1 rtl:space-x-reverse items-center py-1 px-2 hover:bg-gray-100 w-[200px] cursor-pointer"
        onClick={() => {
          if (to === "/logout") {
            logout();
          } else {
            navigate(to);
          }
          setShowAccountNavigation(false);
        }}
      >
        {icon(to === "/logout" ? colors.danger : colors.text, "24px")}
        <div className="text-text mt-1">{text}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-white shadow-lg py-2 z-20">
      {navItem({
        text: t("notifications"),
        icon: (color, size) => {
          return iconNotifications({
            fillColor: color,
            width: size,
            height: size,
          });
        },
        to: "/notifications",
      })}
      {navItem({
        text: t("orders"),
        icon: (color, size) => {
          return iconOrders({ fillColor: color, width: size, height: size });
        },
        to: "/orders",
      })}
      {navItem({
        text: t("wishlist"),
        icon: (color, size) => {
          return iconStar({ fillColor: color, width: size, height: size });
        },
        to: "/wishlist",
      })}
      {navItem({
        text: t("profile"),
        icon: (color, size) => {
          return iconAccount({ fillColor: color, width: size, height: size });
        },
        to: "/profile",
      })}
       {navItem({
        text: t("messages"),
        icon: (color, size) => {
          return iconMessages({ fillColor: color, width: size, height: size });
        },
        to: "/chat",
      })}
      {navItem({
        text: t("logout"),
        icon: (color, size) => {
          return iconLogout({ fillColor: color, width: size, height: size });
        },
        to: "/logout",
      })}
    </div>
  );
};
