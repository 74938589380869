import { AppProvider } from "./app/AppState";
import { CategoryProvider } from "./category/CategoryState";
import { ChatProvider } from "./chat/ChatState";
import { CheckoutProvider } from "./checkout/CheckoutState";
import { SnackbarProvider } from "./general/SnackbarContext";
import { HomeProvider } from "./home/HomeState";
import { NotificationsProvider } from "./notifications/NotificationsState";
import { OrderProvider } from "./order/OrderState";
import { OrdersProvider } from "./orders/OrdersState";
import { ProductProvider } from "./product/ProductState";
import { WishlistProvider } from "./wishlist/WishlistState";

const CombinedProviders = ({ children }) => {
  return (
    <SnackbarProvider>
      <AppProvider>
        <HomeProvider>
          <CategoryProvider>
            <ProductProvider>
              <CheckoutProvider>
                <OrdersProvider>
                  <NotificationsProvider>
                    <WishlistProvider>
                      <ChatProvider>
                        <OrderProvider>{children}</OrderProvider>
                      </ChatProvider>
                    </WishlistProvider>
                  </NotificationsProvider>
                </OrdersProvider>
              </CheckoutProvider>
            </ProductProvider>
          </CategoryProvider>
        </HomeProvider>
      </AppProvider>
    </SnackbarProvider>
  );
};
export default CombinedProviders;
