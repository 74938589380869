import { initialState } from "./CheckoutState";

const CheckoutReducer = (state, action) => {
  switch (action.type) {
    case "SET_STAGE":
      return {
        ...state,
        stage: +action.payload,
      };

    case "SET_IS_PICKUP":
      return {
        ...state,
        isPickup: action.payload,
      };

    case "SET_SELECTED_BRANCH":
      return {
        ...state,
        selectedBranch: action.payload,
      };

    case "SET_SHOW_ADD_ADDRESS":
      return {
        ...state,
        showAddAddress: action.payload,
      };

    case "SET_IS_SOMEONE_ELSE":
      return {
        ...state,
        isSomeoneElse: action.payload,
      };

    case "SET_SELECTED_ADDRESS_ID":
      return {
        ...state,
        selectedAddressId: action.payload,
      };

    case "SET_SELECTED_PAYMENT_METHOD":
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };

    case "UPDATE_RECIPIENT_DETAILS":
      return {
        ...state,
        recipientDetails: {
          ...state.recipientDetails,
          ...action.payload,
        },
      };

    case "GET_CONFIG_REQUEST":
      return {
        ...state,
        config: {
          ...state.config,
          loading: true,
          error: null,
        },
      };

    case "GET_CONFIG_SUCCESS":
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          data: action.payload,
          error: null,
        },
        selectedBranch: action.payload.branches[0],
      };

    case "GET_CONFIG_FAILURE":
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_USER_REQUEST":
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          error: null,
        },
      };

    case "GET_USER_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_USER_FAILURE":
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: action.payload,
        },
      };

    case "ADD_ADDRESS_REQUEST":
      return {
        ...state,
        addedAddress: {
          ...state.addedAddress,
          loading: true,
          error: null,
        },
      };

    case "ADD_ADDRESS_SUCCESS":
      return {
        ...state,
        addedAddress: {
          ...state.addedAddress,
          loading: false,
          data: action.payload,
          error: null,
        },
        recipientDetails: {
          recipientName: "",
          countryCode: "+966",
          phone: "",
          email: "",
        },
        showAddAddress: false,
      };

    case "ADD_ADDRESS_FAILURE":
      return {
        ...state,
        addedAddress: {
          ...state.addedAddress,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_ADDRESSES_REQUEST":
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
          error: null,
        },
      };

    case "GET_ADDRESSES_SUCCESS":
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_ADDRESSES_FAILURE":
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: action.payload,
        },
      };

    case "DELETE_ADDRESS_REQUEST":
      return {
        ...state,
        deletedAddress: {
          ...state.deletedAddress,
          loading: true,
          error: null,
        },
      };

    case "DELETE_ADDRESS_SUCCESS":
      return {
        ...state,
        deletedAddress: {
          ...state.deletedAddress,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "DELETE_ADDRESS_FAILURE":
      return {
        ...state,
        deletedAddress: {
          ...state.deletedAddress,
          loading: false,
          error: action.payload,
        },
      };

    case "CHECK_COUPON_CODE_REQUEST":
      return {
        ...state,
        couponCode: {
          ...state.couponCode,
          loading: true,
          error: null,
        },
      };

    case "CHECK_COUPON_CODE_SUCCESS":
      return {
        ...state,
        couponCode: {
          ...state.couponCode,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "CHECK_COUPON_CODE_FAILURE":
      return {
        ...state,
        couponCode: {
          ...state.couponCode,
          data: null,
          loading: false,
          error: action.payload,
        },
      };

    case "PLACE_ORDER_REQUEST":
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
          error: null,
        },
      };

    case "PLACE_ORDER_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "PLACE_ORDER_FAILURE":
      return {
        ...state,
        order: {
          ...state.order,
          data: null,
          loading: false,
          error: action.payload,
        },
      };

    case "ONLINE_PAYMENT_REQUEST":
      return {
        ...state,
        onlinePayment: {
          ...state.onlinePayment,
          loading: true,
          error: null,
        },
      };

    case "ONLINE_PAYMENT_SUCCESS":
      return {
        ...state,
        onlinePayment: {
          ...state.onlinePayment,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "ONLINE_PAYMENT_FAILURE":
      return {
        ...state,
        onlinePayment: {
          ...state.onlinePayment,
          data: null,
          loading: false,
          error: action.payload,
        },
      };

    case "SET_PAYMENT_IN_PROGRESS":
      return {
        ...state,
        paymentInProgress: action.payload,
      };

    case "RESET_STATE":
      return initialState;

    default:
      return state;
  }
};

export default CheckoutReducer;
