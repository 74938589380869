import React, { createContext, useContext, useReducer } from "react";
import { AppContext } from "../app/AppState";
import { getChatApi, sendMessageApi } from "../../api/api";
import { useSnackbar } from "../general/SnackbarContext";
import ChatReducer from "./ChatReducer";

// Initial state
const initialState = {
  chat: {
    data: [],
    loading: true,
    error: null,
    page: 1,
    hasMore: true,
  },
  sentMessage: {
    data: null,
    loading: false,
    error: null,
  },
};

// Create context
export const ChatContext = createContext(initialState);

// Provider component
export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChatReducer, initialState);
  const { token } = useContext(AppContext);
  const { showSuccess, showError } = useSnackbar();

  // Actions
  async function getChat({ page = 1 }) {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_CHAT_REQUEST",
    });
    try {
      const res = await getChatApi({
        token: tokenString,
        offset: page,
        limit: 10,
      });
      dispatch({
        type: "GET_CHAT_SUCCESS",
        payload: {
          chat: res.messages.reverse(),
          hasMore:
            state.chat.data.length + res.messages.length < res.total_size,
          page: page,
        },
        append: page > 1,
      });
    } catch (error) {
      dispatch({
        type: "GET_CHAT_FAILURE",
        payload: error.message || "Failed to fetch chat",
      });
      showError(error.message || "Failed to fetch chat");
    }
  }

  async function sendMessage({ message, setMessage }) {
    const tokenString = token.data.token;
    dispatch({
      type: "SEND_MESSAGE_REQUEST",
    });
    try {
      const data = await sendMessageApi({
        token: tokenString,
        message,
      });
      dispatch({
        type: "SEND_MESSAGE_SUCCESS",
        payload: data,
      });
      showSuccess(data.message);
      getChat({ page: 1 });
      if (setMessage) {
        setMessage("")
      }
    } catch (error) {
      dispatch({
        type: "SEND_MESSAGE_FAILURE",
        payload: error.message || "Failed to send message",
      });
      showError(error.message || "Failed to send message");
    }
  }

  return (
    <ChatContext.Provider
      value={{
        chat: state.chat,
        sentMessage: state.sentMessage,
        getChat,
        sendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
