import React, { useContext } from "react";
import { AppContext } from "../../../context/app/AppState";
import { timeAgo } from "../../../util/util";
import { useTranslation } from "react-i18next";

export const ItemMessage = ({ isMe, image, name, message, created_at }) => {
  const { language, primary } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div class={`flex flex-row w-full gap-2.5`} dir={isMe ? "rtl" : "ltr"}>
      <img class="w-12 h-12 rounded-full" src={image} alt="" />
      <div
        class={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 shadow-md rounded-es-xl rounded-e-xl`}
        style={{ backgroundColor: isMe ? primary : "white" }}
      >
        <div
          class={`flex items-center ${
            isMe ? "space-x-2 rtl:space-x-reverse" : "space-x-2"
          }`}
        >
          <span
            class="text-sm font-semibold"
            style={{ color: isMe ? "white" : primary }}
          >
            {name}
          </span>
          <span class="text-sm font-normal text-gray-300">
            {timeAgo(created_at, t, language)}
          </span>
        </div>
        <p
          class="text-sm font-normal py-2.5 text-white"
          style={{ color: isMe ? "white" : primary }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};
