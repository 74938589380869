export const countries = {
  "Saudi Arabia": [
    "Abha",
    "Abqaiq",
    "Al Bahah",
    "Al Faruq",
    "Al Hufuf",
    "Al Qatif",
    "Al Yamamah",
    "At Tuwal",
    "Buraidah",
    "Dammam",
    "Dhahran",
    "Hayil",
    "Jazirah",
    "Jazirah",
    "Jeddah",
    "Jizan",
    "Jubail",
    "Khamis Mushait",
    "Khobar",
    "Khulays",
    "Linah",
    "Madinat Yanbu` as Sina`iyah",
    "Mecca",
    "Medina",
    "Mina",
    "Najran",
    "Rabigh",
    "Rahimah",
    "Rahman",
    "Ramdah",
    "Ras Tanura",
    "Riyadh",
    "Sabya",
    "Safwa",
    "Sakaka",
    "Sambah",
    "Sayhat",
    "Tabuk",
    "Yanbu` al Bahr",
  ],
  Afghanistan: [
    "Herat",
    "Kabul",
    "Kandahar",
    "Molah",
    "Rana",
    "Shar",
    "Sharif",
    "Wazir Akbar Khan",
  ],
  Albania: [
    "Elbasan",
    "Petran",
    "Pogradec",
    "Shkoder",
    "Tirana",
    "Ura Vajgurore",
  ],
  Algeria: [
    "Algiers",
    "Annaba",
    "Azazga",
    "Batna City",
    "Blida",
    "Bordj",
    "Bordj Bou Arreridj",
    "Bougara",
    "Cheraga",
    "Chlef",
    "Constantine",
    "Djelfa",
    "Draria",
    "El Tarf",
    "Hussein Dey",
    "Illizi",
    "Jijel",
    "Kouba",
    "Laghouat",
    "Oran",
    "Ouargla",
    "Oued Smar",
    "Relizane",
    "Rouiba",
    "Saida",
    "Souk Ahras",
    "Tamanghasset",
    "Tiaret",
    "Tissemsilt",
    "Tizi",
    "Tizi Ouzou",
    "Tlemcen",
  ],
  Andorra: [
    "Andorra la Vella",
    "Canillo",
    "Encamp",
    "Engordany",
    "Escaldes-Engordany",
    "La Massana",
    "Llorts",
    "Ordino",
    "Santa Coloma",
    "Sispony",
    "Soldeu",
  ],
  Angola: [
    "Ambriz",
    "Benguela",
    "Cabinda",
    "Cacole",
    "Camabatela",
    "Cazeta",
    "Huambo",
    "Kuito",
    "Lobito",
    "Luanda",
    "Lubango",
    "Lucapa",
    "Lumeje",
    "Malanje",
    "Menongue",
    "Muxaluando",
    "Namibe",
    "Ondjiva",
    "Piri",
    "Saurimo",
    "Talatona",
  ],
  "Antigua and Barbuda": [
    "All Saints",
    "Cassada Gardens",
    "Codrington",
    "Old Road",
    "Parham",
    "Woods",
  ],
  Argentina: [
    "28 de Noviembre",
    "Abasto",
    "Acassuso",
    "Acebal",
    "Acevedo",
    "Adelia Maria",
    "Agua de Oro",
    "Albardon",
    "Albarellos",
    "Alberdi",
    "Alberti",
    "Aldo Bonzi",
    "Alejandro Korn",
    "Alicia",
    "Allen",
    "Almafuerte",
    "Almagro",
    "Almirante Brown",
    "Alta Gracia",
    "Alta Italia",
    "Alvarez",
    "Alvear",
    "Anatuya",
    "Angelica",
    "Antonio Carboni",
    "Apostoles",
    "Arequito",
    "Armstrong",
    "Arrecifes",
    "Arroyito",
    "Arroyito",
    "Arroyito Challaco",
    "Arroyo Seco",
    "Arteaga",
    "Asamblea",
    "Ascension",
    "Avellaneda",
    "Avellaneda",
    "Ayacucho",
    "Azara",
    "Azul",
    "Balcarce",
    "Balnearia",
    "Banda del Rio Sali",
    "Bandera",
    "Banfield",
    "Baradero",
    "Bariloche",
    "Barrio Fisherton",
    "Batan",
    "Beccar",
    "Belen de Escobar",
    "Belgrano",
    "Belgrano",
    "Bell Ville",
    "Bella Vista",
    "Bella Vista",
    "Benavidez",
    "Berazategui",
    "Berisso",
    "Bernal",
    "Bernardo Larroude",
    "Bernasconi",
    "Bigand",
    "Bombal",
    "Bordenave",
    "Bosch",
    "Bosques",
    "Boulogne",
    "Bovril",
    "Bragado",
    "Brandsen",
    "Brinkmann",
    "Buenos Aires",
    "Burzaco",
    "Bustinza",
    "Caballito",
    "Calderon",
    "Caleta Olivia",
    "Caleufu",
    "Camilo Aldao",
    "Campana",
    "Canada de Gomez",
    "Canada del Ucle",
    "Canada Rosquin",
    "Canals",
    "Canning",
    "Canuelas",
    "Capilla del Monte",
    "Capilla del Senor",
    "Capitan Bermudez",
    "Carhue",
    "Carlos Casares",
    "Carlos Pellegrini",
    "Carlos Tejedor",
    "Caseros",
    "Casilda",
    "Castelar",
    "Castelli",
    "Castillo",
    "Catriel",
    "Catrilo",
    "Cavanagh",
    "Centenario",
    "Ceres",
    "Cervantes",
    "Chacabuco",
    "Chacarita",
    "Chajari",
    "Charata",
    "Chateaubriand",
    "Chilecito",
    "Chivilcoy",
    "Choele Choel",
    "Chorroarin",
    "Cinco Saltos",
    "Cipolletti",
    "City Bell",
    "Ciudad General Belgrano",
    "Ciudadela",
    "Claypole",
    "Clorinda",
    "Colon",
    "Colon",
    "Colonia Baron",
    "Colonia Caroya",
    "Colonia San Miguel Arcangel",
    "Comodoro Rivadavia",
    "Concepcion",
    "Concepcion",
    "Concordia",
    "Constituyentes",
    "Coronel Dorrego",
    "Coronel Martinez de Hoz",
    "Coronel Pringles",
    "Corral de Bustos",
    "Corralitos",
    "Corrientes",
    "Cosquin",
    "Coy Aike",
    "Cramer",
    "Crespo",
    "Cruz del Eje",
    "Curuzu Cuatia",
    "Cutral-Co",
    "Darregueira",
    "De Mayo",
    "Del Campillo",
    "Del Viso",
    "Despenaderos",
    "Devoto",
    "Diaz",
    "Diego de Alvear",
    "Doblas",
    "Dock Sud",
    "Dolores",
    "Don Bosco",
    "Don Torcuato",
    "Drabble",
    "Eduardo Castex",
    "El Calafate",
    "El Dorado",
    "El Hoyo",
    "El Palomar",
    "El Palomar",
    "El Talar",
    "El Trebol",
    "Eldorado",
    "Embalse",
    "Empalme Lobos",
    "Ensenada",
    "Esperanza",
    "Esquel",
    "Esteban Echeverria",
    "Ezeiza",
    "Ezpeleta",
    "Famailla",
    "Fatima",
    "Federal",
    "Fernandez",
    "Firmat",
    "Florencio Varela",
    "Florentino Ameghino",
    "Flores",
    "Floresta",
    "Florida",
    "Formosa",
    "Francisco Alvarez",
    "Franck",
    "Fray Luis A. Beltran",
    "Freyre",
    "Frias",
    "Funes",
    "Gaiman",
    "Galvez",
    "Garin",
    "Garupa",
    "General Acha",
    "General Alvear",
    "General Cabrera",
    "General Deheza",
    "General Guido",
    "General Juan Madariaga",
    "General Lagos",
    "General Las Heras",
    "General Lavalle",
    "General Mansilla",
    "General Martin Miguel de Guemes",
    "General Pacheco",
    "General Paz",
    "General Pico",
    "General Roca",
    "General Roca",
    "General Rodriguez",
    "General San Martin",
    "General San Martin",
    "General Viamonte",
    "General Villegas",
    "Germania",
    "Glew",
    "Gobernador Crespo",
    "Gobernador Galvez",
    "Godoy",
    "Godoy Cruz",
    "Gonzalez Catan",
    "Gonzalez Moreno",
    "Goya",
    "Granadero Baigorria",
    "Grand Bourg",
    "Gualeguay",
    "Guatrache",
    "Guernica",
    "Henderson",
    "Hernando",
    "Hersilia",
    "Hilario",
    "Hilario Ascasubi",
    "Hipatia",
    "Hipolito Yrigoyen",
    "Huanchillas",
    "Huanguelen",
    "Huinca Renanco",
    "Humahuaca",
    "Hurlingham",
    "Ibarlucea",
    "Ibicuy",
    "Independencia",
    "Ingeniero Beaugey",
    "Ingeniero Luiggi",
    "Ingeniero Maschwitz",
    "Intendente Alvear",
    "Isidro Casanova",
    "Ituzaingo",
    "James Craik",
    "Jauregui",
    "Jeppener",
    "Jesus Maria",
    "Jose Leon Suarez",
    "Jose Marmol",
    "Juan Pujol",
    "Justiniano Posse",
    "La Banda",
    "La Boca",
    "La Calera",
    "La Cumbre",
    "La Falda",
    "La Leonesa",
    "La Lucila",
    "La Madrid",
    "La Pampa",
    "La Para",
    "La Paz",
    "La Paz",
    "La Plata",
    "La Punta",
    "La Rioja",
    "La Tablada",
    "La Union",
    "La Violeta",
    "Laborde",
    "Laboulaye",
    "Laferrere",
    "Laguna Alsina",
    "Lanus",
    "Larroque",
    "Las Catitas",
    "Las Flores",
    "Las Flores",
    "Las Heras",
    "Las Perdices",
    "Las Rosas",
    "Las Talitas",
    "Las Varillas",
    "Lavalle",
    "Leandro N. Alem",
    "Leones",
    "Libertad",
    "Lima",
    "Liniers",
    "Llavallol",
    "Lobos",
    "Lomas de Zamora",
    "Lomas del Mirador",
    "Longchamps",
    "Los Antiguos",
    "Los Cardales",
    "Los Molinos",
    "Los Polvorines",
    "Luis Guillon",
    "Lujan de Cuyo",
    "Luque",
    "Luzuriaga",
    "Lynch",
    "Macachin",
    "Magdalena",
    "Magdalena",
    "Maggiolo",
    "Maipu",
    "Maipu",
    "Manantial",
    "Manfredi",
    "Manuel J. Cobo",
    "Maquinista Savio",
    "Mar de Ajo",
    "Mar del Plata",
    "Mar del Tuyu",
    "Marcos Juarez",
    "Marcos Paz",
    "Margarita",
    "Maria Ignacia",
    "Maria Juana",
    "Mariano Acosta",
    "Mariano J. Haedo",
    "Mariano Moreno",
    "Martinez",
    "Matheu",
    "Mayor Buratovich",
    "Melincue",
    "Mendiolaza",
    "Mendoza",
    "Mercedes",
    "Mercedes",
    "Merlo",
    "Merlo",
    "Minacar",
    "Miramar",
    "Miramar",
    "Monje",
    "Monte Hermoso",
    "Monteros",
    "Montserrat",
    "Moreno",
    "Moron",
    "Morteros",
    "Muniz",
    "Munro",
    "Navarro",
    "Navarro",
    "Necochea",
    "Nogoya",
    "Nordelta",
    "Nunez",
    "Obera",
    "Oliva",
    "Oliveros",
    "Olivos",
    "Oncativo",
    "Open Door",
    "Ordonez",
    "Palermo",
    "Palmira",
    "Palpala",
    "Partido de Jose C. Paz",
    "Pasco",
    "Paso del Rey",
    "Paternal",
    "Pavon",
    "Pedernales",
    "Pedro Luro",
    "Pellegrini",
    "Perez",
    "Pergamino",
    "Perico",
    "Perito Moreno",
    "Piamonte",
    "Pico de Salamanca",
    "Pico Truncado",
    "Pigue",
    "Pilar",
    "Pilar",
    "Pilar",
    "Pinamar",
    "Piquete Cabado",
    "Platanos",
    "Plaza Huincul",
    "Plottier",
    "Pontevedra",
    "Portena",
    "Posadas",
    "Pozo del Molle",
    "Presidente Derqui",
    "Puan",
    "Pueblo San Jose",
    "Puerto Madryn",
    "Puerto Rico",
    "Pueyrredon",
    "Punta Alta",
    "Quilmes",
    "Rada Tilly",
    "Rafael Calzada",
    "Rafael Castillo",
    "Rafael Obligado",
    "Rafaela",
    "Ramallo",
    "Ramos Mejia",
    "Ranchos",
    "Rancul",
    "Ranelagh",
    "Rawson",
    "Rawson",
    "Realico",
    "Recoleta",
    "Reconquista",
    "Remedios de Escalada",
    "Resistencia",
    "Retiro",
    "Rio Ceballos",
    "Rio Colorado",
    "Rio Grande",
    "Rio Piedras",
    "Rio Segundo",
    "Rio Tercero",
    "Rivadavia",
    "Rivadavia",
    "Rivadavia",
    "Rocamora",
    "Rodriguez Pena",
    "Rojas",
    "Roldan",
    "Roque Perez",
    "Rosario",
    "Rosas",
    "Rufino",
    "Sacanta",
    "Saenz Pena",
    "Saladillo",
    "Saladillo",
    "Salguero",
    "Salsipuedes",
    "Salta",
    "Salto",
    "Salto Grande",
    "Sampacho",
    "San Andres",
    "San Andres de Giles",
    "San Antonio de Areco",
    "San Antonio de Arredondo",
    "San Antonio de Obligado",
    "San Antonio de Padua",
    "San Antonio Oeste",
    "San Benito",
    "San Bernardo",
    "San Carlos de Bolivar",
    "San Cayetano",
    "San Clemente",
    "San Cristobal",
    "San Fernando",
    "San Fernando del Valle de Catamarca",
    "San Francisco",
    "San Francisco de Santa Fe",
    "San Francisco Solano",
    "San Genaro",
    "San Gregorio",
    "San Guillermo",
    "San Isidro",
    "San Isidro",
    "San Isidro de Lules",
    "San Javier",
    "San Jeronimo Norte",
    "San Jorge",
    "San Jose",
    "San Jose de la Esquina",
    "San Juan",
    "San Justo",
    "San Lorenzo",
    "San Luis",
    "San Manuel",
    "San Martin",
    "San Martin de las Escobas",
    "San Miguel",
    "San Miguel",
    "San Miguel",
    "San Miguel del Monte",
    "San Nicolas",
    "San Pedro",
    "San Pedro",
    "San Rafael",
    "San Salvador",
    "San Salvador",
    "San Salvador de Jujuy",
    "San Telmo",
    "San Vicente",
    "Sanchez",
    "Santa Clara de Saguier",
    "Santa Elena",
    "Santa Fe",
    "Santa Lucia",
    "Santa Lucia",
    "Santa Rita",
    "Santa Rosa",
    "Santa Teresita",
    "Santiago del Estero",
    "Santo Tome",
    "Santos Lugares",
    "Sarandi",
    "Sarmiento",
    "Sarmiento",
    "Segui",
    "Sierra de la Ventana",
    "Sierra de los Padres",
    "Sinsacate",
    "Suipacha",
    "Sunchales",
    "Tablada",
    "Tacuari",
    "Tafi Viejo",
    "Tandil",
    "Tapalque",
    "Tapiales",
    "Temperley",
    "Teodelina",
    "Thames",
    "Tigre",
    "Tio Pujio",
    "Todd",
    "Tornquist",
    "Tortuguitas",
    "Tostado",
    "Totoras",
    "Trelew",
    "Trenque Lauquen",
    "Tres Arroyos",
    "Trevelin",
    "Tristan Suarez",
    "Tunuyan",
    "Tupungato",
    "Turdera",
    "Ucacha",
    "Uriburu",
    "Ushuaia",
    "Valle Hermoso",
    "Vedia",
    "Veinticinco de Mayo",
    "Veinticinco de Mayo",
    "Venado Tuerto",
    "Vera",
    "Veronica",
    "Viale",
    "Viamonte",
    "Vicente Lopez",
    "Victoria",
    "Victoria",
    "Vicuna Mackenna",
    "Viedma",
    "Villa Aberastain",
    "Villa Adelina",
    "Villa Allende",
    "Villa Alsina",
    "Villa Amelia",
    "Villa Angela",
    "Villa Ballester",
    "Villa Bosch",
    "Villa Canas",
    "Villa Carlos Paz",
    "Villa Constitucion",
    "Villa de Maria",
    "Villa de Mayo",
    "Villa del Parque",
    "Villa Dolores",
    "Villa Dominico",
    "Villa Elisa",
    "Villa General Belgrano",
    "Villa Gesell",
    "Villa Giardino",
    "Villa Huidobro",
    "Villa Insuperable",
    "Villa La Angostura",
    "Villa Las Rosas",
    "Villa Lugano",
    "Villa Luzuriaga",
    "Villa Madero",
    "Villa Maria Grande",
    "Villa Media Agua",
    "Villa Mercedes",
    "Villa Mercedes",
    "Villa Nueva",
    "Villa Nueva",
    "Villa Ocampo",
    "Villa Paranacito",
    "Villa Regina",
    "Villa Robles",
    "Villa Rosa",
    "Villa Trinidad",
    "Villa Urquiza",
    "Villaguay",
    "Vuelta de Obligado",
    "Warnes",
    "Wheelwright",
    "Wilde",
    "Winifreda",
    "Yerba Buena",
    "Zenon Pereyra",
  ],
  Armenia: [
    "Abovyan",
    "Agarak",
    "Apaga",
    "Aparan",
    "Arabkir",
    "Ashtarak",
    "Erebuni Fortress",
    "Hrazdan",
    "Ijevan",
    "Jermuk",
    "Kapan",
    "Tsaghkadzor",
    "Vanadzor",
    "Yerevan",
  ],
  Aruba: [
    "Noord",
    "Oranjestad",
    "Palm Beach",
    "Paradera",
    "Ponton",
    "Sabaneta",
    "San Barbola",
    "Santa Cruz",
    "Sero Blanco",
    "Sint Nicolaas",
    "Tanki Lender",
  ],
  Australia: [
    "Abbotsford",
    "Abbotsford",
    "Abbotsham",
    "Aberdeen",
    "Aberfoyle",
    "Aberglasslyn",
    "Abermain",
    "Acacia Ridge",
    "Adamstown",
    "Adelaide",
    "Adelong",
    "Advancetown",
    "Agnes Banks",
    "Agnes Water",
    "Airlie Beach",
    "Aitkenvale",
    "Albany",
    "Albany Creek",
    "Albert Park",
    "Alberton",
    "Alberton",
    "Albion",
    "Albion Park",
    "Albury",
    "Aldgate",
    "Aldinga",
    "Aldinga Beach",
    "Alexandra",
    "Alexandra",
    "Alexandria",
    "Alfredton",
    "Alice Springs",
    "Allambee",
    "Allansford",
    "Allanson",
    "Allora",
    "Alma",
    "Alphington",
    "Alpine",
    "Alstonville",
    "Alton Downs",
    "Altona",
    "Amamoor",
    "Amaroo",
    "Angaston",
    "Anglesea",
    "Anna Bay",
    "Annandale",
    "Apollo Bay",
    "Applecross",
    "Applethorpe",
    "Ararat",
    "Arcadia",
    "Argents Hill",
    "Armadale",
    "Armadale",
    "Armidale",
    "Armstrong Creek",
    "Arno Bay",
    "Arrawarra",
    "Artarmon",
    "Ascot",
    "Ascot Vale",
    "Ashburton",
    "Ashfield",
    "Ashgrove",
    "Ashton",
    "Aspendale",
    "Aspley",
    "Asquith",
    "Atherton",
    "Attadale",
    "Auburn",
    "Augusta",
    "Austinmer",
    "Austins Ferry",
    "Australind",
    "Avalon",
    "Avenue",
    "Avoca Beach",
    "Avondale",
    "Axedale",
    "Aylmerton",
    "Ayr",
    "Babinda",
    "Bacchus Marsh",
    "Bagdad",
    "Bahrs Scrub",
    "Bairnsdale",
    "Baker",
    "Balaclava",
    "Balaklava",
    "Bald Hills",
    "Balgowlah",
    "Balgownie",
    "Balhannah",
    "Ballan",
    "Ballarat",
    "Ballina",
    "Balmain",
    "Balmoral",
    "Balnarring",
    "Balwyn",
    "Balwyn North",
    "Bangalow",
    "Bangor",
    "Banksmeadow",
    "Bankstown",
    "Bannockburn",
    "Barcoo",
    "Barellan",
    "Bargara",
    "Bargo",
    "Baringhup",
    "Barkstead",
    "Barnsley",
    "Barry",
    "Barwon Downs",
    "Barwon Heads",
    "Barwon Heads",
    "Basin View",
    "Bass Hill",
    "Bassendean",
    "Batchelor",
    "Bateau Bay",
    "Batesford",
    "Bathurst",
    "Batlow",
    "Baulkham Hills",
    "Baxter",
    "Bayles",
    "Baynton",
    "Bayswater",
    "Beachmere",
    "Beacon",
    "Beaconsfield",
    "Beaconsfield Upper",
    "Beaudesert",
    "Beaumaris",
    "Beaumont",
    "Beaumont",
    "Beauty Point",
    "Bedford Park",
    "Bedfordale",
    "Beeac",
    "Beechwood",
    "Beechworth",
    "Beenak",
    "Beenleigh",
    "Beerwah",
    "Bega",
    "Belair",
    "Belconnen",
    "Belgian Gardens",
    "Belgrave",
    "Belgrave Heights",
    "Bell",
    "Bellambi",
    "Bellara",
    "Bellbird",
    "Bellbrae",
    "Bellerive",
    "Bellevue Hill",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belrose",
    "Benalla",
    "Benambra",
    "Bendigo",
    "Benowa",
    "Bensville",
    "Bentleigh",
    "Bentleigh East",
    "Bentley",
    "Bentley",
    "Beresfield",
    "Berkeley",
    "Berkeley Vale",
    "Berowra",
    "Berridale",
    "Berrima",
    "Berry",
    "Berwick",
    "Bethania Junction",
    "Beveridge",
    "Beverley",
    "Beverley",
    "Bexley",
    "Bicheno",
    "Bicton",
    "Big Pats Creek",
    "Bilinga",
    "Biloela",
    "Bilpin",
    "Binda",
    "Binnaway",
    "Birdwood",
    "Birkdale",
    "Birkenhead",
    "Black Hill",
    "Black Rock",
    "Blackburn",
    "Blackbutt",
    "Blackheath",
    "Blackmans Bay",
    "Blacktown",
    "Blackwall",
    "Blackwarry",
    "Blackwater",
    "Blackwood",
    "Blackwood",
    "Blair Athol",
    "Blair Athol",
    "Blakehurst",
    "Blaxland",
    "Blayney",
    "Bli Bli",
    "Boambee",
    "Boat Harbour",
    "Bobin",
    "Boddington",
    "Bogangar",
    "Bohle Plains",
    "Bolton",
    "Bolwarra",
    "Bomaderry",
    "Bombala",
    "Bonbeach",
    "Bondi",
    "Boneo",
    "Bongaree",
    "Bonnells Bay",
    "Bonnet Bay",
    "Bonython",
    "Booker Bay",
    "Bool Lagoon",
    "Boolarra",
    "Boonah",
    "Boondall",
    "Booral",
    "Bordertown",
    "Boronia",
    "Botany",
    "Boulder",
    "Bourke",
    "Bowen",
    "Bowenfels",
    "Bowral",
    "Bowraville",
    "Box Hill",
    "Box Hill South",
    "Boyup Brook",
    "Bradbury",
    "Braddon",
    "Braeside",
    "Braidwood",
    "Brandon",
    "Brandy Hill",
    "Branxton",
    "Brewarrina",
    "Briar Hill",
    "Bridgetown",
    "Bridgewater",
    "Bridgewater",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brighton East",
    "Brighton-Le-Sands",
    "Bringelly",
    "Brisbane",
    "Broadbeach",
    "Broadford",
    "Broadmeadows",
    "Broadwater",
    "Broadwater",
    "Broke",
    "Broken Hill",
    "Bronte",
    "Brookdale",
    "Brookfield",
    "Brookfield",
    "Brooklyn",
    "Brookvale",
    "Broome",
    "Brown Hill",
    "Browns Plains",
    "Bruce",
    "Brunswick",
    "Buchan",
    "Buckland",
    "Buderim",
    "Budgewoi",
    "Bulahdelah",
    "Bulga",
    "Bulimba",
    "Bulla",
    "Bulleen",
    "Bulli",
    "Bullsbrook",
    "Bullyard",
    "Buln Buln",
    "Bunbury",
    "Bundaberg",
    "Bundalong",
    "Bundamba",
    "Bundanoon",
    "Bundeena",
    "Bundook",
    "Bundoora",
    "Bungendore",
    "Bungonia",
    "Buninyong",
    "Burekup",
    "Burleigh Heads",
    "Burnett Heads",
    "Burnie",
    "Burnley",
    "Burnside",
    "Burpengary",
    "Burradoo",
    "Burraneer",
    "Burrum",
    "Burrum Heads",
    "Burwood",
    "Burwood",
    "Bushland Beach",
    "Busselton",
    "Butchers Ridge",
    "Buxton",
    "Byford",
    "Byron Bay",
    "Caboolture",
    "Cairns",
    "Calen",
    "Calingiri",
    "Callala Bay",
    "Callington",
    "Caloundra",
    "Caltowie",
    "Camberwell",
    "Cambewarra",
    "Cambridge",
    "Camden",
    "Camden Haven",
    "Cammeray",
    "Campbell",
    "Campbellfield",
    "Campbelltown",
    "Campbelltown",
    "Campbelltown",
    "Camperdown",
    "Camperdown",
    "Canadian",
    "Canberra",
    "Cannington",
    "Cannonvale",
    "Canterbury",
    "Canterbury",
    "Canungra",
    "Capalaba",
    "Caragabal",
    "Caravonica",
    "Carbrook",
    "Cardiff",
    "Cardigan",
    "Cardinia",
    "Cardup",
    "Caringbah",
    "Carlingford",
    "Carlsruhe",
    "Carlton",
    "Carlton North",
    "Carlton South",
    "Carnarvon",
    "Carnegie",
    "Caroline Springs",
    "Carrick",
    "Carrington",
    "Carrum",
    "Carrum Downs",
    "Casino",
    "Casterton",
    "Castle Hill",
    "Castlecrag",
    "Castlemaine",
    "Caulfield",
    "Caulfield North",
    "Caulfield South",
    "Caversham",
    "Cawarral",
    "Cawongla",
    "Cedar Grove",
    "Ceduna",
    "Central Coast",
    "Cessnock",
    "Charlestown",
    "Chatswood",
    "Chatsworth Island",
    "Chelsea",
    "Cheltenham",
    "Chinchilla",
    "Chippendale",
    "Chipping Norton",
    "Christies",
    "Chullora",
    "Churchill",
    "City of Parramatta",
    "City of West Torrens",
    "Clare",
    "Clare",
    "Claremont",
    "Claremont",
    "Clarence Town",
    "Clareville",
    "Clarinda",
    "Clarkefield",
    "Clayton North",
    "Cleveland",
    "Clifton",
    "Clifton Hill",
    "Clifton Springs",
    "Clontarf",
    "Clontarf",
    "Closeburn",
    "Clovelly",
    "Clunes",
    "Clyde",
    "Clyde",
    "Clyde North",
    "Cobbitty",
    "Coburg",
    "Cockatoo",
    "Cockburn",
    "Coffs Harbour",
    "Colac",
    "Coleambally",
    "Coledale",
    "Coleraine",
    "Collaroy",
    "Collie",
    "Collingwood",
    "Collinsvale",
    "Colo",
    "Colo Vale",
    "Como",
    "Concord",
    "Condamine",
    "Condobolin",
    "Coniston",
    "Connells Point",
    "Coober Pedy",
    "Cooee",
    "Coogee",
    "Coogee",
    "Cook",
    "Cooktown",
    "Coolah",
    "Coolamon",
    "Coolangatta",
    "Cooloolabin",
    "Coolum",
    "Coolum Beach",
    "Coolup",
    "Cooma",
    "Coomera",
    "Coonawarra",
    "Coondoo",
    "Cooranbong",
    "Cooroy",
    "Coorparoo",
    "Cootamundra",
    "Copacabana",
    "Copmanhurst",
    "Corinda",
    "Corinthia",
    "Corio",
    "Corlette",
    "Coromandel Valley",
    "Corrimal",
    "Cotham",
    "Cottesloe",
    "Coutts Crossing",
    "Cowan",
    "Cowes",
    "Cowra",
    "Crafers",
    "Craigieburn",
    "Cranbourne",
    "Crawford",
    "Cremorne",
    "Cremorne",
    "Crescent Head",
    "Creswick",
    "Crib Point",
    "Cringila",
    "Cronulla",
    "Crookwell",
    "Crossley",
    "Crows Nest",
    "Crows Nest",
    "Croydon",
    "Croydon",
    "Croydon North",
    "Crystal Creek",
    "Cudlee Creek",
    "Cummins",
    "Cundletown",
    "Curl Curl",
    "Currumbin",
    "Cygnet",
    "Daceyville",
    "Daisy Hill",
    "Dakabin",
    "Dalby",
    "Dalkeith",
    "Dandenong",
    "Dapto",
    "Dardanup",
    "Darkan",
    "Darley",
    "Darling",
    "Darling Point",
    "Darlinghurst",
    "Darlington",
    "Darlington",
    "Darra",
    "Darwin",
    "Davidson",
    "Davistown",
    "Dawson",
    "Dayboro",
    "Daylesford",
    "Deagon",
    "Deakin",
    "Deception Bay",
    "Deepdene",
    "Deer Park",
    "Deloraine",
    "Denham",
    "Denman",
    "Dereel",
    "Derrimut",
    "Derwent",
    "Devonport",
    "Diamond Creek",
    "Diggers Rest",
    "Dilston",
    "Dimboola",
    "Dimbulah",
    "Dingley",
    "Dirranbandi",
    "Dodges Ferry",
    "Dolans Bay",
    "Don",
    "Doncaster",
    "Doncaster East",
    "Donnybrook",
    "Donvale",
    "Doonside",
    "Doreen",
    "Dorrigo",
    "Doubtful Creek",
    "Douglas",
    "Douglas Park",
    "Dover Heights",
    "Drayton",
    "Dromana",
    "Drouin",
    "Drouin South",
    "Drouin West",
    "Drummond Cove",
    "Drummoyne",
    "Drysdale",
    "Dubbo",
    "Dumbalk",
    "Dumbleyung",
    "Dundas",
    "Dundowran",
    "Dungog",
    "Dunkeld",
    "Dunmore",
    "Dunolly",
    "Dunsborough",
    "Dural",
    "Eagle Farm",
    "Eagle Heights",
    "Eagleby",
    "Eaglehawk",
    "Eaglemont",
    "Earlwood",
    "East Fremantle",
    "East Gosford",
    "East Maitland",
    "East Malvern",
    "Eastwood",
    "Eaton",
    "Ebbw Vale",
    "Ebenezer",
    "Echuca",
    "Echunga",
    "Eden",
    "Eden Park",
    "Edge Hill",
    "Edgecliff",
    "Edith",
    "Edithvale",
    "Edmonton",
    "Edwardstown",
    "Eight Mile Plains",
    "Elderslie",
    "Elizabeth",
    "Ellalong",
    "Ellangowan",
    "Ellendale",
    "Ellerslie",
    "Elsternwick",
    "Elsternwick",
    "Eltham",
    "Eltham North",
    "Elwood",
    "Emerald",
    "Emerald",
    "Empire Bay",
    "Emu Park",
    "Emu Plains",
    "Emu Vale",
    "Encounter Bay",
    "Endeavour",
    "Endeavour Hills",
    "Enfield",
    "Engadine",
    "Enmore",
    "Enoggera",
    "Epping",
    "Epping",
    "Erina",
    "Erskine",
    "Eschol",
    "Esk",
    "Esperance",
    "Essendon",
    "Ettalong Beach",
    "Eudlo",
    "Eudunda",
    "Eumundi",
    "Euroa",
    "Evandale",
    "Everton",
    "Exeter",
    "Exmouth",
    "Failford",
    "Fairfield",
    "Fairfield",
    "Fairview",
    "Fairy Meadow",
    "Fassifern",
    "Faulconbridge",
    "Fawkner",
    "Fern Tree",
    "Fern Tree Gully",
    "Ferndale",
    "Ferntree Gully",
    "Fernvale",
    "Ferny Creek",
    "Figtree",
    "Findon",
    "Fingal",
    "Fingal Bay",
    "Fitzroy",
    "Flemington",
    "Flinders",
    "Flinders",
    "Flynn",
    "Footscray",
    "Forbes",
    "Forest",
    "Forest Hill",
    "Forestville",
    "Forrest",
    "Forrest",
    "Forsayth",
    "Forster",
    "Foster",
    "Franklin",
    "Frankston",
    "Freeling",
    "Freemans Reach",
    "Fremantle",
    "Freshwater",
    "Fulham",
    "Fullerton",
    "Fyshwick",
    "Galston",
    "Garfield",
    "Gatton",
    "Gawler",
    "Gayndah",
    "Gaythorne",
    "Geelong",
    "Geelong West",
    "Gellibrand",
    "George Town",
    "Gepps Cross",
    "Geraldton",
    "Gerringong",
    "Gheerulla",
    "Gilberton",
    "Gilmore",
    "Gilston",
    "Gin Gin",
    "Girraween",
    "Gisborne",
    "Gladesville",
    "Gladstone",
    "Glass House Mountains",
    "Glebe",
    "Glen Huntly",
    "Glen Huon",
    "Glen Innes",
    "Glen Iris",
    "Glen Osmond",
    "Glen Waverley",
    "Glenbrook",
    "Glenburn",
    "Glenelg",
    "Glenfield",
    "Glengarry",
    "Glenhaven",
    "Glenorchy",
    "Glenorchy",
    "Glenore Grove",
    "Glenorie",
    "Glenreagh",
    "Glenroy",
    "Glenvale",
    "Glossodia",
    "Gloucester",
    "Golconda",
    "Gold Coast",
    "Golden Grove",
    "Goodna",
    "Goodwood",
    "Goolwa",
    "Goomeri",
    "Goonellabah",
    "Goornong",
    "Gooseberry Hill",
    "Gordon",
    "Gordon",
    "Gordonvale",
    "Gore Hill",
    "Gorokan",
    "Gosford",
    "Gosforth",
    "Gosnells",
    "Goulburn",
    "Gould",
    "Gowrie",
    "Gracemere",
    "Grafton",
    "Grandchester",
    "Grange",
    "Grant",
    "Grantham",
    "Granville",
    "Grassmere",
    "Gravesend",
    "Green Hill",
    "Green Point",
    "Greenbank",
    "Greendale",
    "Greensborough",
    "Greenvale",
    "Greenwich",
    "Gregadoo",
    "Grenfell",
    "Greta",
    "Griffith",
    "Grove",
    "Grovedale",
    "Guildford",
    "Guildford",
    "Gundaroo",
    "Gunnedah",
    "Gymea",
    "Gymea Bay",
    "Gympie",
    "Haberfield",
    "Hackham",
    "Haddon",
    "Hadspen",
    "Hagley",
    "Hahndorf",
    "Hall",
    "Hallam",
    "Hallett",
    "Halls Gap",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hammond",
    "Hampton",
    "Hampton Park",
    "Happy Valley",
    "Harrington",
    "Harristown",
    "Harvey",
    "Hastings",
    "Hastings Point",
    "Hat Head",
    "Hawker",
    "Hawkesbury Heights",
    "Hawksburn",
    "Hawthorn",
    "Hawthorne",
    "Haymarket",
    "Hazelbrook",
    "Hazelwood",
    "Healesville",
    "Heathcote",
    "Heathcote",
    "Heathcote Junction",
    "Heatherton",
    "Heathmont",
    "Heddon Greta",
    "Heidelberg",
    "Helensburgh",
    "Helensvale",
    "Hendon",
    "Hepburn Springs",
    "Hervey Bay",
    "Hexham",
    "Highbury",
    "Highett",
    "Highfields",
    "Hill Top",
    "Hillcrest",
    "Hindmarsh",
    "Hobart",
    "Hoddles Creek",
    "Hollow Tree",
    "Holroyd",
    "Holsworthy",
    "Homebush",
    "Homebush",
    "Hope Island",
    "Hope Valley",
    "Hopetoun",
    "Hoppers Crossing",
    "Hornsby",
    "Horsham",
    "Hove",
    "Howard",
    "Howard Springs",
    "Howden",
    "Howe",
    "Howlong",
    "Hughesdale",
    "Hunter",
    "Hunters Hill",
    "Huntly",
    "Huonville",
    "Hurstbridge",
    "Hurstville",
    "Hurstville Grove",
    "Hyland Park",
    "Illawarra",
    "Illawong",
    "Iluka",
    "Indented Head",
    "Ingham",
    "Ingle Farm",
    "Ingleburn",
    "Inglewood",
    "Innisfail",
    "Inverell",
    "Inverloch",
    "Invermay",
    "Ipswich",
    "Isabella",
    "Ivanhoe",
    "Ivanhoe East",
    "Ives",
    "Jacobs Well",
    "Jannali",
    "Jarrahdale",
    "Jensen",
    "Jilliby",
    "Jimboomba",
    "Jindabyne",
    "Jindivick",
    "Judbury",
    "Junee",
    "Kadina",
    "Kalgoorlie",
    "Kallista",
    "Kalorama",
    "Kambalda",
    "Kangaroo Flat",
    "Kangaroo Point",
    "Kanwal",
    "Kapunda",
    "Karalee",
    "Karana Downs",
    "Kardella",
    "Kardella South",
    "Kareela",
    "Karnup",
    "Karoonda",
    "Karratha",
    "Katherine",
    "Katoomba",
    "Kawungan",
    "Keilor",
    "Keiraville",
    "Keith",
    "Kellyville",
    "Kelmscott",
    "Kelso",
    "Kelvin Grove",
    "Kempsey",
    "Kempton",
    "Kendall",
    "Kenilworth",
    "Kenmore",
    "Kennington",
    "Kensington",
    "Kensington",
    "Kensington and Norwood",
    "Kenthurst",
    "Kenwick",
    "Kerang",
    "Kerry",
    "Keswick",
    "Kettering",
    "Kew",
    "Kew",
    "Kew East",
    "Keysborough",
    "Keysbrook",
    "Kiama",
    "Kilcoy",
    "Kilcunda",
    "Killara",
    "Killarney",
    "Killarney",
    "Killcare",
    "Killingworth",
    "Kilmore",
    "Kilsyth",
    "Kincumber",
    "Kindred",
    "Kingaroy",
    "Kinglake",
    "Kings Cross",
    "Kings Park",
    "Kingscliff",
    "Kingsthorpe",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingswood",
    "Kinross",
    "Kirrawee",
    "Kirribilli",
    "Kirwan",
    "Koah",
    "Kobble",
    "Koetong",
    "Kogarah",
    "Kojonup",
    "Koo-Wee-Rup",
    "Koolewong",
    "Koorawatha",
    "Koornalla",
    "Kootingal",
    "Kooyong",
    "Koroit",
    "Korumburra",
    "Kotara",
    "Kununurra",
    "Kuranda",
    "Kurnell",
    "Kurrajong Heights",
    "Kurri Kurri",
    "Kyabram",
    "Kyneton",
    "Kyogle",
    "Laang",
    "Laguna",
    "Laidley",
    "Lake Cathie",
    "Lake Heights",
    "Lake Illawarra",
    "Lake Munmorah",
    "Lakemba",
    "Lakes Entrance",
    "Lalor",
    "Lambton",
    "Lamington",
    "Lancefield",
    "Lancelin",
    "Landsborough",
    "Lane Cove",
    "Lang Lang",
    "Langhorne Creek",
    "Langley",
    "Langwarrin",
    "Lara",
    "Largs North",
    "Latrobe",
    "Lauderdale",
    "Launceston",
    "Laverton",
    "Laverton",
    "Lavington",
    "Lawgi",
    "Lawnton",
    "Lawson",
    "Lebrina",
    "Leeton",
    "Legana",
    "Leichhardt",
    "Lemon Tree Passage",
    "Lennox Head",
    "Leongatha",
    "Leopold",
    "Lethbridge",
    "Leumeah",
    "Leura",
    "Lewiston",
    "Lidcombe",
    "Lilli Pilli",
    "Lilydale",
    "Limestone",
    "Linden",
    "Lindenow",
    "Lindfield",
    "Lindisfarne",
    "Lisarow",
    "Lismore",
    "Lithgow",
    "Little Bay",
    "Little Hampton",
    "Little River",
    "Liverpool",
    "Loch",
    "Lockhart",
    "Lockridge",
    "Loftus",
    "Logan",
    "Logan City",
    "Logan Village",
    "Loganholme",
    "Loganlea",
    "Long Jetty",
    "Long Plains",
    "Long Point",
    "Longford",
    "Longreach",
    "Longueville",
    "Longwarry",
    "Lovely Banks",
    "Lowanna",
    "Lower Chittering",
    "Lower Plenty",
    "Lowood",
    "Loxton",
    "Luddenham",
    "Ludlow",
    "Lugarno",
    "Lulworth",
    "Lutana",
    "Lyndhurst",
    "Lyndoch",
    "Lyneham",
    "Lyons",
    "Lysterfield",
    "Macarthur",
    "Macedon",
    "Mackay",
    "Macksville",
    "Maclean",
    "Macleod",
    "Macquarie Park",
    "Maddington",
    "Maffra",
    "Magill",
    "Maidenwell",
    "Maitland",
    "Malabar",
    "Maleny",
    "Mallala",
    "Mallanganee",
    "Malmsbury",
    "Malvern",
    "Mandalong",
    "Mandurah",
    "Mangalore",
    "Mangerton",
    "Manildra",
    "Manjimup",
    "Manly",
    "Manly",
    "Mannering Park",
    "Manning",
    "Manning Point",
    "Mannum",
    "Mansfield",
    "Mapleton",
    "Mareeba",
    "Margaret River",
    "Margate",
    "Margate",
    "Marian",
    "Maribyrnong",
    "Marino",
    "Marion",
    "Marlee",
    "Marmor",
    "Marong",
    "Maroochydore",
    "Maroota",
    "Maroubra",
    "Marrickville",
    "Marsden",
    "Marsden Park",
    "Marshalltown",
    "Martins Creek",
    "Marulan",
    "Maryborough",
    "Maryborough",
    "Maryland",
    "Marysville",
    "Mascot",
    "Maslin Beach",
    "Matcham",
    "Matraville",
    "Maudsland",
    "Maybole",
    "Mayfield",
    "Maylands",
    "McCrae",
    "McGraths Hill",
    "McKinnon",
    "McLachlan",
    "McLaren Flat",
    "McLaren Vale",
    "Meadows",
    "Medlow Bath",
    "Medowie",
    "Melbourne",
    "Melrose",
    "Melton",
    "Melton South",
    "Melville",
    "Menai",
    "Menangle",
    "Mentone",
    "Meredith",
    "Merewether",
    "Meringandan",
    "Merlynston",
    "Mernda",
    "Merredin",
    "Merriwa",
    "Merrylands",
    "Middlemount",
    "Middleton",
    "Middleton",
    "Midway Point",
    "Mildura",
    "Mile End",
    "Miles",
    "Milton",
    "Miners Rest",
    "Minlaton",
    "Minmi",
    "Minto",
    "Miranda",
    "Mirani",
    "Mirboo",
    "Mirboo North",
    "Mirrabooka",
    "Mission Beach",
    "Mitcham",
    "Mitcham",
    "Mitchell",
    "Mitchelton",
    "Mitiamo",
    "Mittagong",
    "Moama",
    "Moana",
    "Modbury",
    "Moggill",
    "Mona Vale",
    "Monash",
    "Monbulk",
    "Mont Albert North",
    "Monteagle",
    "Monterey",
    "Montmorency",
    "Montrose",
    "Montville",
    "Mooloolaba",
    "Mooloolah Valley",
    "Moonah",
    "Moonee Ponds",
    "Mooney Mooney",
    "Moorabbin",
    "Mooralla",
    "Moore",
    "Moore Park",
    "Moorooduc",
    "Mooroolbark",
    "Mooroopna",
    "Mooroopna",
    "Moranbah",
    "Morang",
    "Morayfield",
    "Mordialloc",
    "Moree",
    "Moriac",
    "Morisset",
    "Mornington",
    "Morphett Vale",
    "Mortdale",
    "Mortlake",
    "Moruya",
    "Morwell",
    "Mosman",
    "Mosman Park",
    "Moss Vale",
    "Mossman",
    "Mount Barker",
    "Mount Colah",
    "Mount Cotton",
    "Mount Dandenong",
    "Mount Direction",
    "Mount Druitt",
    "Mount Egerton",
    "Mount Eliza",
    "Mount Evelyn",
    "Mount Gambier",
    "Mount Gravatt",
    "Mount Helen",
    "Mount Helena",
    "Mount Isa",
    "Mount Julian",
    "Mount Keira",
    "Mount Larcom",
    "Mount Lofty",
    "Mount Macedon",
    "Mount Martha",
    "Mount Molloy",
    "Mount Morgan",
    "Mount Ousley",
    "Mount Perry",
    "Mount Pleasant",
    "Mount Saint Thomas",
    "Mount Waverley",
    "Mount White",
    "Mourilyan",
    "Muchea",
    "Mudgee",
    "Mudgeeraba",
    "Mudjimba",
    "Mulgoa",
    "Mulgrave",
    "Mullewa",
    "Mullumbimby",
    "Mundaring",
    "Mundijong",
    "Murray Bridge",
    "Murrumba",
    "Murrumbateman",
    "Murrumbeena",
    "Murrurundi",
    "Murtoa",
    "Murwillumbah",
    "Muswellbrook",
    "Mylor",
    "Myrrhee",
    "Myrtleford",
    "Nagambie",
    "Nairne",
    "Nambour",
    "Nambucca Heads",
    "Nana Glen",
    "Nanango",
    "Nannup",
    "Napoleons",
    "Nar Nar Goon",
    "Narangba",
    "Narara",
    "Narellan",
    "Narooma",
    "Narrabeen",
    "Narrabri",
    "Narrandera",
    "Narrawong",
    "Narre Warren",
    "Narre Warren North",
    "Narrogin",
    "Narromine",
    "Neath",
    "Nebo",
    "Nedlands",
    "Neerim South",
    "Nelson Bay",
    "Nerang",
    "Nerrigundah",
    "Nerrina",
    "Neutral Bay",
    "New Farm",
    "New Lambton",
    "New Town",
    "Newcastle",
    "Newmarket",
    "Newnham",
    "Newport",
    "Newport",
    "Newry",
    "Newstead",
    "Newstead",
    "Newstead",
    "Newtown",
    "Newtown",
    "Nhill",
    "Nindaroo",
    "Ningi",
    "Nirranda",
    "Noarlunga",
    "Noble Park",
    "Noosa",
    "Noosaville",
    "Norah",
    "Nords Wharf",
    "Norlane",
    "Norman Park",
    "Normanhurst",
    "Normanton",
    "Normanville",
    "North Beach",
    "North Fitzroy",
    "North Mackay",
    "North Melbourne",
    "North Tamborine",
    "North Turramurra",
    "Northam",
    "Northcote",
    "Northgate",
    "Northmead",
    "Norton Summit",
    "Norwood",
    "Notting Hill",
    "Nowra",
    "Nullawarre",
    "Nunawading",
    "Nuriootpa",
    "Nyora",
    "Oak Park",
    "Oakdale",
    "Oakey",
    "Oakhurst",
    "Oaklands",
    "Oakleigh",
    "Oakleigh South",
    "Oatlands",
    "Oatley",
    "Oberon",
    "Obi Obi",
    "Ocean Shores",
    "Officer",
    "Old Bar",
    "Old Beach",
    "Olinda",
    "Oman-Ama",
    "One Tree Hill",
    "Orange",
    "Orchard Hills",
    "Orford",
    "Orient Point",
    "Ormeau",
    "Ormiston",
    "Ormond",
    "Orroroo",
    "Osborne",
    "Ourimbah",
    "Oxenford",
    "Oxley",
    "Oxley",
    "Oyster Bay",
    "Paddington",
    "Padstow",
    "Pakenham",
    "Pallarenda",
    "Palm Beach",
    "Palm Cove",
    "Palm Island",
    "Palmer",
    "Palmwoods",
    "Pambula",
    "Paradise",
    "Paramatta",
    "Parap",
    "Park Ridge",
    "Parkdale",
    "Parkerville",
    "Parkes",
    "Parkhurst",
    "Parkville",
    "Parkwood",
    "Pearce",
    "Pearcedale",
    "Pechey",
    "Pelican",
    "Pendle Hills",
    "Penfield",
    "Penguin",
    "Pennant Hills",
    "Penrith",
    "Penshurst",
    "Perenjori",
    "Perth",
    "Perth",
    "Peterborough",
    "Petrie",
    "Phillip",
    "Pialba",
    "Piawaning",
    "Picnic Bay",
    "Picton",
    "Piggabeen",
    "Pimpama",
    "Pine Mountain",
    "Pinery",
    "Pinjarra",
    "Pinkenba",
    "Pioneer",
    "Pipers Brook",
    "Pitt Town",
    "Pittsworth",
    "Plympton",
    "Point Clare",
    "Point Cook",
    "Point Lonsdale",
    "Point Lookout",
    "Point Pass",
    "Point Vernon",
    "Pokolbin",
    "Pomona",
    "Pontville",
    "Poowong",
    "Port Adelaide",
    "Port Augusta",
    "Port Douglas",
    "Port Fairy",
    "Port Hacking",
    "Port Hedland",
    "Port Kembla",
    "Port Lincoln",
    "Port MacDonnell",
    "Port Macquarie",
    "Port Melbourne",
    "Port Noarlunga",
    "Port Pirie",
    "Port Sorell",
    "Portarlington",
    "Portland",
    "Portsea",
    "Potts Point",
    "Prahran",
    "Preston",
    "Proserpine",
    "Prospect",
    "Purnim",
    "Pymble",
    "Pyrmont",
    "Quakers Hill",
    "Queanbeyan",
    "Queenscliff",
    "Queensferry",
    "Queenstown",
    "Railton",
    "Ramsgate",
    "Randwick",
    "Rathmines",
    "Ravenswood",
    "Raworth",
    "Raymond Terrace",
    "Red Hill",
    "Redan",
    "Redbank",
    "Redcliffe",
    "Redfern",
    "Redhead",
    "Redland Bay",
    "Redlynch",
    "Reedy Creek",
    "Regents Park",
    "Research",
    "Reservoir",
    "Retreat",
    "Revesby",
    "Reynella",
    "Rhodes",
    "Riana",
    "Richmond",
    "Richmond",
    "Riddell",
    "Ridgley",
    "Ringwood",
    "Ringwood East",
    "Ringwood North",
    "Ripley",
    "Risdon",
    "River Heads",
    "Riverside",
    "Riverstone",
    "Riverton",
    "Riverview",
    "Riverwood",
    "Robertson",
    "Robertstown",
    "Robinvale",
    "Rochedale",
    "Rochester",
    "Rockbank",
    "Rockdale",
    "Rockhampton",
    "Rockingham",
    "Rocklea",
    "Rodborough",
    "Rokeby",
    "Roleystone",
    "Roma",
    "Romsey",
    "Rooty Hill",
    "Rosanna",
    "Rose Bay",
    "Rosebery",
    "Rosebery",
    "Rosebud",
    "Rosehill",
    "Roseville",
    "Rosewood",
    "Roseworthy",
    "Ross",
    "Ross Creek",
    "Ross River",
    "Rowville",
    "Royal Park",
    "Rozelle",
    "Rudall",
    "Rushworth",
    "Rutherford",
    "Rydalmere",
    "Ryde",
    "Rye",
    "Rylstone",
    "Safety Bay",
    "Saint Albans",
    "Saint Andrews",
    "Saint George",
    "Saint Georges Basin",
    "Saint Helena",
    "Saint Ives",
    "Saint James",
    "Saint Leonards",
    "Saint Lucia",
    "Saint Marys",
    "Saint Peters",
    "Saints",
    "Salamander Bay",
    "Sale",
    "Salisbury",
    "Salt Ash",
    "Samford",
    "San Remo",
    "Sanctuary Point",
    "Sandford",
    "Sandgate",
    "Sandgate",
    "Sandringham",
    "Sandstone Point",
    "Sandy Bay",
    "Sandy Hollow",
    "Sans Souci",
    "Sapphire",
    "Sarina",
    "Sarina Beach",
    "Sassafras",
    "Scarborough",
    "Scarborough",
    "Scarness",
    "Scarsdale",
    "Schofields",
    "Scone",
    "Scoresby",
    "Scottsdale",
    "Seacliff",
    "Seacombe",
    "Seaford",
    "Seaford",
    "Seaforth",
    "Seaton",
    "Seaview",
    "Sebastopol",
    "Selbourne",
    "Selby",
    "Sellicks Beach",
    "Sellicks Hill",
    "Semaphore",
    "Serpentine",
    "Seven Hills",
    "Seven Mile Beach",
    "Seville",
    "Seville East",
    "Seymour",
    "Sheffield",
    "Shellharbour",
    "Shenton Park",
    "Sherbrook",
    "Shoal Point",
    "Shorncliffe",
    "Silvan",
    "Silverdale",
    "Silverwater",
    "Singleton",
    "Sippy Downs",
    "Slacks Creek",
    "Smithfield",
    "Smithfield",
    "Smithton",
    "Smythes Creek",
    "Smythesdale",
    "Snowtown",
    "Somersby",
    "Somerset",
    "Somerset",
    "Somerton",
    "Somerville",
    "Sorrento",
    "South Melbourne",
    "South Perth",
    "South West Rocks",
    "South Yarra",
    "Southbank",
    "Southern Brook",
    "Southport",
    "Spearwood",
    "Spencer",
    "Spotswood",
    "Spreyton",
    "Spring Field",
    "Spring Hill",
    "Spring Hill",
    "Springfield",
    "Springvale",
    "Springvale South",
    "Springwood",
    "St Helens",
    "St Kilda",
    "Stanhope",
    "Stanley",
    "Stanmore",
    "Stannum",
    "Stanthorpe",
    "Stapylton",
    "Stawell",
    "Stieglitz",
    "Stirling",
    "Stirling",
    "Stockton",
    "Stockyard Creek",
    "Stoneville",
    "Stradbroke",
    "Stratford",
    "Stratford",
    "Strathalbyn",
    "Strathfield",
    "Strathfieldsaye",
    "Strathmore",
    "Strathpine",
    "Streaky Bay",
    "Stroud",
    "Stuart Park",
    "Sturt",
    "Subiaco",
    "Success",
    "Suffolk Park",
    "Sulphur Creek",
    "Summerholm",
    "Summertown",
    "Sunbury",
    "Sunnybank",
    "Sunnyside",
    "Sunshine",
    "Surfers Paradise",
    "Surrey Hills",
    "Sutherland",
    "Swan Hill",
    "Swan View",
    "Swansea",
    "Swansea",
    "Sydenham",
    "Sydney",
    "Sylvania",
    "Sylvania Waters",
    "Tahmoor",
    "Tallangatta",
    "Tallarook",
    "Tallebudgera",
    "Talmalmo",
    "Tambo Upper",
    "Tamborine",
    "Tammin",
    "Tamworth",
    "Tannum Sands",
    "Tanunda",
    "Taree",
    "Taren Point",
    "Tarlee",
    "Tarneit",
    "Taroona",
    "Tarragindi",
    "Tarrawanna",
    "Tarro",
    "Tarzali",
    "Tatura",
    "Tawonga",
    "Teesdale",
    "Telegraph Point",
    "Temora",
    "Tempe",
    "Templestowe",
    "Templestowe Lower",
    "Tenambit",
    "Tennyson",
    "Tenterfield",
    "Teralba",
    "Terrigal",
    "Terry Hills",
    "Tewantin",
    "Thagoona",
    "Thargomindah",
    "The Basin",
    "The Entrance",
    "The Oaks",
    "The Patch",
    "The Rocks",
    "Thebarton",
    "Thirlmere",
    "Thirroul",
    "Thomastown",
    "Thompson",
    "Thornbury",
    "Thornlands",
    "Thornleigh",
    "Thornton",
    "Thuringowa",
    "Thursday Island",
    "Tiaro",
    "Tieri",
    "Tinamba",
    "Tincurrin",
    "Tingoora",
    "Tolga",
    "Tom Price",
    "Tomago",
    "Tongala",
    "Toogoolawah",
    "Toogoom",
    "Toolangi",
    "Toolern Vale",
    "Toongabbie",
    "Toora",
    "Tooradin",
    "Toorak",
    "Toorbul",
    "Tooronga",
    "Toowong",
    "Toowoomba",
    "Torbanlea",
    "Toronto",
    "Torquay",
    "Torquay",
    "Tottenham",
    "Toukley",
    "Townsville",
    "Towradgi",
    "Trafalgar",
    "Traralgon",
    "Traralgon South",
    "Trentham",
    "Trinity Beach",
    "Truganina",
    "Trunkey",
    "Tuggerah",
    "Tuggeranong",
    "Tugun",
    "Tullamarine",
    "Tully",
    "Tumut",
    "Tuncurry",
    "Tunnack",
    "Turners Beach",
    "Turramurra",
    "Tweed Heads",
    "Two Rocks",
    "Two Wells",
    "Tyabb",
    "Tyalgum",
    "Tynong",
    "Uki",
    "Ulladulla",
    "Ulmarra",
    "Ultimo",
    "Ulverstone",
    "Umina",
    "Unanderra",
    "Underwood",
    "Unley",
    "Upper Brookfield",
    "Upper Burringbar",
    "Upper Caboolture",
    "Upper Coomera",
    "Upwey",
    "Uraidla",
    "Urangan",
    "Valla Beach",
    "Valley Heights",
    "Vaucluse",
    "Venus Bay",
    "Verdun",
    "Vermont",
    "Victor Harbor",
    "Victoria Park",
    "Victoria Point",
    "Villawood",
    "Vineyard",
    "Virginia",
    "Virginia",
    "Waaia",
    "Wacol",
    "Wagga Wagga",
    "Wahgunyah",
    "Wahroonga",
    "Waikerie",
    "Waitara",
    "Walgett",
    "Walhalla",
    "Wallacia",
    "Wallan",
    "Wallaroo",
    "Wallington",
    "Walliston",
    "Wallsend",
    "Walmer",
    "Wamberal",
    "Wamuran",
    "Wanbi",
    "Wandin North",
    "Wandoan",
    "Wangaratta",
    "Wangi Wangi",
    "Wanneroo",
    "Wantirna",
    "Wantirna South",
    "Waratah",
    "Warburton",
    "Warnbro",
    "Warnervale",
    "Warragul",
    "Warrandyte",
    "Warrane",
    "Warrawong",
    "Warren",
    "Warren Shire",
    "Warrigal",
    "Warrimoo",
    "Warrnambool",
    "Warwick",
    "Waterford",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo Corner",
    "Watsonia",
    "Wattle Grove",
    "Wattle Grove",
    "Waubra",
    "Wauchope",
    "Waurn Ponds",
    "Waverley",
    "Waverton",
    "Wayville",
    "Wedderburn",
    "Wedderburn",
    "Weetah",
    "Weethalle",
    "Wellard",
    "Wellington",
    "Wellington Point",
    "Welshpool",
    "Wembley",
    "Wendouree",
    "Wentworth",
    "Wentworth Falls",
    "Wentworthville",
    "Werribee",
    "Werribee South",
    "West Burleigh",
    "West End",
    "West Hoxton",
    "West Pymble",
    "West Wyalong",
    "Westbrook",
    "Westfield",
    "Westmead",
    "Weston",
    "Weston Creek",
    "Wheelers Hill",
    "White Rock",
    "Whitfield",
    "Whittlesea",
    "Whyalla",
    "Wickham",
    "Wickham",
    "Wilberforce",
    "Willaston",
    "Willaura",
    "Williams",
    "Williamstown",
    "Williamstown",
    "Willow Grove",
    "Willow Vale",
    "Willunga",
    "Wilmot",
    "Wilson",
    "Wilton",
    "Wiluna",
    "Wimbledon",
    "Winchelsea",
    "Windang",
    "Windaroo",
    "Windellama",
    "Windermere Park",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor Downs",
    "Wingham",
    "Winkleigh",
    "Wodonga",
    "Wollar",
    "Wollert",
    "Wollongbar",
    "Wollongong",
    "Womboota",
    "Won Wron",
    "Wondai",
    "Wonga Park",
    "Wongan Hills",
    "Wonthaggi",
    "Woodburn",
    "Woodend",
    "Woodford",
    "Woodford",
    "Woodgate",
    "Woodleigh",
    "Woodridge",
    "Woodside",
    "Woodstock",
    "Woodvale",
    "Woodville",
    "Woolamai",
    "Woolgoolga",
    "Woolloomooloo",
    "Woolooware",
    "Wooloweyah",
    "Woolwich",
    "Woombah",
    "Woombye",
    "Woonona",
    "Woori Yallock",
    "Woorim",
    "Wooroolin",
    "Worongary",
    "Woronora",
    "Woy Woy",
    "Wulkuraka",
    "Wyalkatchem",
    "Wyalong",
    "Wyee",
    "Wyndham",
    "Wynnum",
    "Wynyard",
    "Wyong",
    "Wyongah",
    "Wyreema",
    "Yaamba",
    "Yackandandah",
    "Yallingup",
    "Yallourn",
    "Yallourn North",
    "Yamba",
    "Yanchep",
    "Yandina",
    "Yangan",
    "Yankalilla",
    "Yapeen",
    "Yaroomba",
    "Yarpturk",
    "Yarra Glen",
    "Yarra Junction",
    "Yarragon",
    "Yarram",
    "Yarraman",
    "Yarrambat",
    "Yarraville",
    "Yarrawarrah",
    "Yass",
    "Yatala",
    "Yea",
    "Yellow Rock",
    "Yenda",
    "Yeoval",
    "Yeppoon",
    "Yerrinbool",
    "Yinnar",
    "Yolla",
    "York",
    "Yorkeys Knob",
    "Young",
    "Yowie Bay",
    "Zeehan",
  ],
  Austria: [
    "Absam",
    "Absdorf",
    "Abtenau",
    "Abtsdorf",
    "Ach",
    "Achenkirch",
    "Achensee",
    "Admont",
    "Adnet",
    "Afritz",
    "Aggsbach",
    "Aichbach",
    "Aichdorf",
    "Aifersdorf",
    "Aigen",
    "Aigen im Muehlkreis",
    "Ainet",
    "Aistersheim",
    "Alberndorf in der Riedmark",
    "Alberschwende",
    "Aldrans",
    "Alkoven",
    "Alland",
    "Allhartsberg",
    "Alpbach",
    "Altach",
    "Altenberg",
    "Altenberg bei Linz",
    "Altenburg",
    "Altendorf",
    "Altendorf",
    "Altenhof am Hausruck",
    "Altenmarkt an der Triesting",
    "Altenmarkt im Pongau",
    "Altheim",
    "Althofen",
    "Altlengbach",
    "Altlichtenwarth",
    "Altmunster",
    "Altwaidhofen",
    "Am See",
    "Amaliendorf",
    "Ampass",
    "Ampfelwang",
    "Amstetten",
    "Andorf",
    "Anger",
    "Anif",
    "Annenheim",
    "Ansfelden",
    "Anthering",
    "Apfelberg",
    "Arbesthal",
    "Ardagger Markt",
    "Ardning",
    "Arnoldstein",
    "Arriach",
    "Asberg",
    "Aschach an der Donau",
    "Aschau",
    "Aspang Markt",
    "Asparn",
    "Asperhofen",
    "Asten",
    "Attersee",
    "Attnang-Puchheim",
    "Atzbach",
    "Au",
    "Auersthal",
    "Aufhausen",
    "Augsdorf",
    "Aurolzmunster",
    "Axams",
    "Bach",
    "Bad Blumau",
    "Bad Deutsch-Altenburg",
    "Bad Erlach",
    "Bad Fischau",
    "Bad Gams",
    "Bad Gastein",
    "Bad Gleichenberg",
    "Bad Goisern",
    "Bad Hall",
    "Bad Hofgastein",
    "Bad Ischl",
    "Bad Kreuzen",
    "Bad Pirawarth",
    "Bad Radkersburg",
    "Bad Schallerbach",
    "Bad Tatzmannsdorf",
    "Bad Voslau",
    "Baden bei Wien",
    "Baernbach",
    "Baldramsdorf",
    "Bartholomaberg",
    "Barwies",
    "Bauer",
    "Baumgarten im Burgenland",
    "Baumgartenberg",
    "Baumkirchen",
    "Berg",
    "Bergau",
    "Bergheim",
    "Berndorf",
    "Bernhardsthal",
    "Bernstein",
    "Berwang",
    "Bezau",
    "Biberbach",
    "Biberwier",
    "Bichlbach",
    "Biedermann",
    "Biedermannsdorf",
    "Birgitz",
    "Bisamberg",
    "Bizau",
    "Bleiberg ob Villach",
    "Bleiburg",
    "Blindenmarkt",
    "Bludenz",
    "Bludesch",
    "Blumau",
    "Bockfliess",
    "Bodensdorf",
    "Boheimkirchen",
    "Bosendurnbach",
    "Bramberg am Wildkogel",
    "Brandberg",
    "Braunau am Inn",
    "Bregenz",
    "Breitenbrunn",
    "Breitenfurt bei Wien",
    "Breitenwang",
    "Brixen im Thale",
    "Bruck am Ziller",
    "Bruck an der Grossglocknerstrasse",
    "Bruck an der Leitha",
    "Bruck an der Mur",
    "Bruckl",
    "Bruckneudorf",
    "Bruendl",
    "Bruggen",
    "Brunn am Gebirge",
    "Buch",
    "Buch bei Jenbach",
    "Buchkirchen",
    "Burgkirchen",
    "Burgschleinitz",
    "Burmoos",
    "Dalaas",
    "Dechantskirchen",
    "Dellach",
    "Desselbrunn",
    "Deutsch Goritz",
    "Deutsch-Wagram",
    "Deutschfeistritz",
    "Deutschkreutz",
    "Deutschlandsberg",
    "Diepoltsham",
    "Dietach",
    "Dietersdorf am Gnasbach",
    "Dobersberg",
    "Dobersdorf",
    "Doblbach",
    "Dobldorf",
    "Doebriach",
    "Donnerskirchen",
    "Doren",
    "Dorf",
    "Dorfgastein",
    "Dorfla",
    "Dornbirn",
    "Drassburg",
    "Drassmarkt",
    "Dross",
    "Durnstein",
    "Ebbs",
    "Eben im Pongau",
    "Ebenau",
    "Ebendorf",
    "Ebenfurth",
    "Ebensee",
    "Ebenthal",
    "Ebergassing",
    "Eberndorf",
    "Eberschwang",
    "Ebersdorf",
    "Eberstein",
    "Ebreichsdorf",
    "Eckartsau",
    "Edelschrott",
    "Edelstal",
    "Edlitz",
    "Edt bei Lambach",
    "Eferding",
    "Egg",
    "Egg am Faaker See",
    "Eggenburg",
    "Eggendorf",
    "Eggendorf",
    "Eggendorf im Thale",
    "Eggersdorf bei Graz",
    "Ehrenhausen",
    "Ehrwald",
    "Eibiswald",
    "Eichgraben",
    "Eisbach",
    "Eisenberg",
    "Eisenerz",
    "Eisenstadt",
    "Eisentratten",
    "Eisgarn",
    "Elixhausen",
    "Ellmau",
    "Elsbethen",
    "Emberg",
    "Emmersdorf an der Donau",
    "Engelhartszell",
    "Engersdorf",
    "Engerwitzdorf",
    "Enns",
    "Ennsdorf",
    "Enzenkirchen",
    "Enzenreith",
    "Enzersdorf an der Fischa",
    "Enzersfeld",
    "Enzesfeld",
    "Erlauf",
    "Ernst",
    "Ernstbrunn",
    "Ernsthofen",
    "Erpersdorf",
    "Erpfendorf",
    "Esternberg",
    "Eugendorf",
    "Faak am See",
    "Falkenburg",
    "Fehring",
    "Feistritz",
    "Feistritz an der Drau",
    "Feld am See",
    "Feldbach",
    "Feldkirch",
    "Feldkirchen an der Donau",
    "Feldkirchen bei Graz",
    "Feldkirchen bei Mattighofen",
    "Feldkirchen in Karnten",
    "Felixdorf",
    "Ferndorf",
    "Fernitz",
    "Feuchten",
    "Feuersbrunn",
    "Fieberbrunn",
    "Filzmoos",
    "Finkenberg",
    "Finsing",
    "Fischamend Dorf",
    "Fischbach",
    "Fischerndorf",
    "Fiss",
    "Flachau",
    "Fladnitz im Raabtal",
    "Flattach",
    "Fliess",
    "Flinsdorf",
    "Foederlach",
    "Fohnsdorf",
    "Forst",
    "Frankenburg",
    "Frankenmarkt",
    "Frattingsdorf",
    "Frauenkirchen",
    "Freienstein",
    "Freilassing",
    "Freinberg",
    "Freistadt",
    "Friedburg",
    "Friesach",
    "Frohnleiten",
    "Fuernitz",
    "Fuerstenfeld",
    "Fugen",
    "Fulpmes",
    "Furthof",
    "Fusch an der Glocknerstrasse",
    "Fuschl am See",
    "Fussach",
    "Gaaden",
    "Gaal",
    "Gablitz",
    "Gaflenz",
    "Gailitz",
    "Gaisberg",
    "Gallbrunn",
    "Gallneukirchen",
    "Gallspach",
    "Gallzein",
    "Galtur",
    "Gaming",
    "Gampern",
    "Gansbach",
    "Ganserndorf",
    "Gargellen",
    "Gars am Kamp",
    "Garsten",
    "Gaschurn",
    "Gaspoltshofen",
    "Gasthof",
    "Gattendorf",
    "Gaweinstal",
    "Geinberg",
    "Geras",
    "Gerasdorf am Steinfelde",
    "Gerasdorf bei Wien",
    "Gerersdorf",
    "Gerlos",
    "Getzersdorf",
    "Gfohl",
    "Giesshubl",
    "Glan",
    "Glaubendorf",
    "Gleisdorf",
    "Glinzendorf",
    "Gloggnitz",
    "Gmund",
    "Gmunden",
    "Gnadenwald",
    "Gnas",
    "Gobelsburg",
    "Goedersdorf",
    "Goesselsdorf",
    "Gofis",
    "Going",
    "Goldegg",
    "Gollersdorf",
    "Golling an der Salzach",
    "Gols",
    "Gortipohl",
    "Gosau",
    "Gosdorf",
    "Gosing",
    "Gostling an der Ybbs",
    "Gotzendorf",
    "Gotzendorf an der Leitha",
    "Gotzens",
    "Gotzis",
    "Grabelsdorf",
    "Graen",
    "Grafenbach",
    "Grafendorf",
    "Grafendorf bei Hartberg",
    "Grafendorf bei Stainz",
    "Grafenschlag",
    "Grafenstein",
    "Grafenweg",
    "Grafenworth",
    "Gramastetten",
    "Gramatneusiedl",
    "Grambach",
    "Gratkorn",
    "Gratwein",
    "Graz",
    "Grein",
    "Gresten",
    "Gries",
    "Gries am Brenner",
    "Grieselstein",
    "Grieskirchen",
    "Griffen",
    "Grimmenstein",
    "Grobming",
    "Grodig",
    "Gross",
    "Gross-Enzersdorf",
    "Gross-Schweinbarth",
    "Gross-Siegharts",
    "Grossarl",
    "Grossebersdorf",
    "Grossklein",
    "Grosspesendorf",
    "Grossraming",
    "Grossrussbach",
    "Grossweikersdorf",
    "Grunbach am Schneeberg",
    "Gruner Baum",
    "Gschnitz",
    "Guessing",
    "Gugging",
    "Guglwald",
    "Gumpoldskirchen",
    "Gunselsdorf",
    "Gunskirchen",
    "Guntersdorf",
    "Guntramsdorf",
    "Gurten",
    "Gurtis",
    "Gutau",
    "Gutenstein",
    "Guttaring",
    "Haag",
    "Haag",
    "Haag am Hausruck",
    "Hadersdorf am Kamp",
    "Hadersfeld",
    "Hadres",
    "Hagenberg im Muhlkreis",
    "Haibach ob der Donau",
    "Haid",
    "Haidershofen",
    "Haiming",
    "Haimingerberg",
    "Hainburg an der Donau",
    "Hainfeld",
    "Halbturn",
    "Haldensee",
    "Hall",
    "Hall in Tirol",
    "Hallein",
    "Haller",
    "Hallstatt",
    "Harbach",
    "Hard",
    "Hart",
    "Hartberg",
    "Hartkirchen",
    "Haslau an der Donau",
    "Hatting",
    "Hatzendorf",
    "Haus",
    "Hausbrunn",
    "Hausleiten",
    "Hausmannstatten",
    "Hautzendorf",
    "Heidenreichstein",
    "Heiligenkreuz am Waasen",
    "Heimschuh",
    "Heissberg",
    "Heissingfelding",
    "Heiterwang",
    "Hellmonsodt",
    "Henndorf am Wallersee",
    "Hennersdorf",
    "Hermagor",
    "Hernstein",
    "Herrnbaumgarten",
    "Herzogenburg",
    "Herzogsdorf",
    "Himberg",
    "Himmelreich",
    "Hinterbruehl",
    "Hinterburg",
    "Hinterglemm",
    "Hintersdorf",
    "Hinterstoder",
    "Hinterthiersee",
    "Hintertux",
    "Hinterwaldberg",
    "Hipping",
    "Hirm",
    "Hirschegg",
    "Hirtenberg",
    "Hochburg",
    "Hochgurgl",
    "Hochleithen",
    "Hochneukirchen",
    "Hochsoelden",
    "Hochst",
    "Hoehe",
    "Hof am Leithaberge",
    "Hof bei Salzburg",
    "Hofen",
    "Hofkirchen im Traunkreis",
    "Hoflein",
    "Hofstatten an der Raab",
    "Hofstetten",
    "Hohenau",
    "Hohenberg",
    "Hohenburg",
    "Hoheneich",
    "Hohenems",
    "Hohentauern",
    "Hohenwarth",
    "Hohenzell",
    "Hohnhart",
    "Hollabrunn",
    "Hollenthon",
    "Hollersbach im Pinzgau",
    "Holzhausen",
    "Hopfgarten im Brixental",
    "Horbranz",
    "Horersdorf",
    "Horn",
    "Hornstein",
    "Horsching",
    "Huetten",
    "Huttschlag",
    "Igls",
    "Im Brand",
    "Imst",
    "Innerkrems",
    "Innermanzing",
    "Innsbruck",
    "Inzersdorf im Kremstal",
    "Inzing",
    "Ischgl",
    "Isper",
    "Jagenbach",
    "Jagerberg",
    "Japons",
    "Jeging",
    "Jenbach",
    "Jennersdorf",
    "Jerzens",
    "Jochberg",
    "Jois",
    "Judenau",
    "Judenburg",
    "Judendorf",
    "Jungholz",
    "Kainach bei Voitsberg",
    "Kaindorf",
    "Kalsdorf bei Graz",
    "Kaltenbrunn",
    "Kaltenleutgeben",
    "Kanzelhoehe",
    "Kapelln",
    "Kapfenberg",
    "Kapfing",
    "Kappern",
    "Kappl",
    "Kaprun",
    "Karl",
    "Karlstetten",
    "Karteis",
    "Katsdorf",
    "Katzelsdorf",
    "Kaumberg",
    "Kefermarkt",
    "Kematen in Tirol",
    "Kennelbach",
    "Keutschach am See",
    "Kienberg",
    "Kierling",
    "Kilb",
    "Kindberg",
    "Kirchbach in Steiermark",
    "Kirchberg am Wagram",
    "Kirchberg in Tirol",
    "Kirchbichl",
    "Kirchdorf an der Krems",
    "Kirchdorf in Tirol",
    "Kirchham",
    "Kirchschlag",
    "Kirchschlag bei Linz",
    "Kittsee",
    "Klagenfurt",
    "Klam",
    "Klaus",
    "Klein-Harras",
    "Kleinbergl",
    "Kleinkirchheim",
    "Kleinstubing",
    "Klingfurth",
    "Klosterle",
    "Klostermarienberg",
    "Klosterneuburg",
    "Knappenberg",
    "Knittelfeld",
    "Kobenz",
    "Kobersdorf",
    "Koblach",
    "Koestenberg",
    "Koetschach",
    "Koflach",
    "Kollmitzberg",
    "Konigstetten",
    "Konigswiesen",
    "Kopfing im Innkreis",
    "Koppl",
    "Korneuburg",
    "Kossen",
    "Kottingbrunn",
    "Kottmannsdorf",
    "Kraig",
    "Kramsach",
    "Krems",
    "Kremsbruecke",
    "Kremsdorf",
    "Kremsmunster",
    "Krenglbach",
    "Kreuz",
    "Krieglach",
    "Krispl",
    "Kritzendorf",
    "Kroatisch Minihof",
    "Krottendorf bei Ligist",
    "Krumbach",
    "Krumbach Markt",
    "Krumegg",
    "Krumpendorf",
    "Kuchl",
    "Kuehnsdorf",
    "Kuehtai",
    "Kufstein",
    "Kumberg",
    "Kundl",
    "Laa an der Thaya",
    "Laab im Walde",
    "Laaben",
    "Laakirchen",
    "Labach",
    "Lambrechten",
    "Lamprechtshausen",
    "Landeck",
    "Landegg",
    "Landskron",
    "Lanersbach",
    "Langeck im Burgenland",
    "Langenlois",
    "Langenrohr",
    "Langenstein",
    "Langenzersdorf",
    "Lannach",
    "Lanzenkirchen",
    "Lassee",
    "Lassnitzhohe",
    "Latschach",
    "Launsdorf",
    "Lauterach",
    "Lavamund",
    "Lavant",
    "Laxenburg",
    "Lebring",
    "Lech",
    "Lechaschau",
    "Leibnitz, Styria",
    "Leisach",
    "Leiten",
    "Leitring",
    "Lembach im Muhlkreis",
    "Lend",
    "Lendorf",
    "Lenzing",
    "Leoben",
    "Leobendorf",
    "Leobersdorf",
    "Leogang",
    "Leonding",
    "Leonfelden",
    "Leopoldsdorf",
    "Lermoos",
    "Leutasch",
    "Leutschach",
    "Lichtenau",
    "Lichtenberg",
    "Lichtenegg",
    "Lichtenworth",
    "Liebenfels",
    "Lieboch",
    "Lienz",
    "Liesfeld",
    "Liezen",
    "Ligist",
    "Lilienfeld",
    "Lindenberg",
    "Linz",
    "Litschau",
    "Lobenstein",
    "Lochau",
    "Lochen",
    "Lofer",
    "Lohnsburg",
    "Loimersdorf",
    "Loipersbach",
    "Loosdorf",
    "Loretto",
    "Ludesch",
    "Luftenberg an der Donau",
    "Lungotz",
    "Lustenau",
    "Lutzmannsburg",
    "Mader",
    "Madseit",
    "Maglern",
    "Maiersdorf",
    "Maildorf",
    "Mairhof",
    "Maissau",
    "Mallnitz",
    "Mandling",
    "Mank",
    "Mannersdorf am Leithagebirge",
    "Mannersdorf an der Rabnitz",
    "Mannsworth",
    "Marbach",
    "Marchegg",
    "Marchtrenk",
    "Margarethen am Moos",
    "Maria Alm am Steinernen Meer",
    "Maria Buch",
    "Maria Elend",
    "Maria Ellend",
    "Maria Enzersdorf",
    "Maria Lankowitz",
    "Maria Lanzendorf",
    "Maria Saal",
    "Maria Schmolln",
    "Maria Taferl",
    "Maria Worth",
    "Maria-Anzbach",
    "Mariazell",
    "Markgrafneusiedl",
    "Markt Allhau",
    "Markt Neuhodis",
    "Markt Piesting",
    "Marktl",
    "Marz",
    "Mathon",
    "Matrei am Brenner",
    "Matrei in Osttirol",
    "Mattersburg",
    "Mattsee",
    "Mauerbach",
    "Mauerkirchen",
    "Maurach",
    "Mauterndorf",
    "Mauthausen",
    "Mayrhofen",
    "Meggenhofen",
    "Mehrnbach",
    "Meiningen",
    "Meisten",
    "Melk",
    "Mellach",
    "Mettmach",
    "Michelbach Markt",
    "Micheldorf in Oberoesterreich",
    "Mieming",
    "Miesenbach",
    "Miesenbach",
    "Millstatt",
    "Mistelbach",
    "Mittelberg",
    "Mitterdorf im Muerztal",
    "Mitterkirchen im Machland",
    "Mitterndorf an der Fischa",
    "Mittersill",
    "Moellbruecke",
    "Mollersdorf",
    "Molln",
    "Mollram",
    "Monchhof",
    "Mondsee",
    "Moos",
    "Moosburg",
    "Moosdorf",
    "Mooskirchen",
    "Mosern",
    "Motz",
    "Muckendorf an der Donau",
    "Muerzhofen",
    "Muerzsteg",
    "Muerzzuschlag",
    "Muhlbach am Manhartsberg",
    "Munchendorf",
    "Muntlix",
    "Munzbach",
    "Munzkirchen",
    "Murau",
    "Murfeld",
    "Mutters",
    "Naarn im Machlande",
    "Nassereith",
    "Natternbach",
    "Nauders",
    "Neckenmarkt",
    "Neidling",
    "Nenzing",
    "Nesselwangle",
    "Nestelbach bei Graz",
    "Neudau",
    "Neudorf bei Landsee",
    "Neudorf bei Staatz",
    "Neudorfl",
    "Neufeld an der Leitha",
    "Neuhofen",
    "Neuhofen an der Krems",
    "Neuhofen an der Ybbs",
    "Neukirchen",
    "Neukirchen am Walde",
    "Neukirchen an der Enknach",
    "Neukirchen an der Vockla",
    "Neulengbach",
    "Neumarkt am Wallersee",
    "Neumarkt an der Ybbs",
    "Neumarkt im Hausruckkreis",
    "Neumarkt im Muhlkreis",
    "Neumarkt in Steiermark",
    "Neunkirchen",
    "Neusiedl am See",
    "Neusiedl bei Guessing",
    "Neustift",
    "Neutal",
    "Neuzeug",
    "Nickelsdorf",
    "Niederachen",
    "Niederalm",
    "Niederdorf",
    "Niederkreuzstetten",
    "Niederland",
    "Niederleis",
    "Niederndorf",
    "Niederneukirchen",
    "Niederwaldkirchen",
    "Niederwolz",
    "Nochling",
    "Nussdorf",
    "Nussdorf am Haunsberg",
    "Nuziders",
    "Obdach",
    "Ober-Grafendorf",
    "Oberaich",
    "Oberaichwald",
    "Oberalm",
    "Oberau",
    "Obergruenburg",
    "Obergurgl",
    "Oberhausen",
    "Oberhofen im Inntal",
    "Oberkappel",
    "Oberkirchbach",
    "Oberkohlstaetten",
    "Obernberg",
    "Obernberg am Brenner",
    "Oberndorf an der Melk",
    "Oberndorf bei Salzburg",
    "Oberneukirchen",
    "Oberort",
    "Oberperfuss",
    "Oberpullendorf",
    "Oberrohrbach",
    "Oberschutzen",
    "Obersdorf",
    "Obersiebenbrunn",
    "Obertauern",
    "Obertrum am See",
    "Obervellach",
    "Oberwaltersdorf",
    "Oberwart",
    "Oberweis",
    "Oberwolbling",
    "Obritzberg",
    "Obsteig",
    "Oed",
    "Oehling",
    "Oepping",
    "Oetz",
    "OEtztal-Bahnhof",
    "Offenhausen",
    "Oftering",
    "Oggau",
    "Ohlsdorf",
    "Ollern",
    "Opponitz",
    "Ort im Innkreis",
    "Orth an der Donau",
    "Oslip",
    "Ossiach",
    "Ostermiething",
    "Ottendorf bei Gleisdorf",
    "Ottenheim",
    "Ottensheim",
    "Pabneukirchen",
    "Pachfurth",
    "Paldau",
    "Panzendorf",
    "Parndorf",
    "Parschlug",
    "Pasching",
    "Passail",
    "Passthurn",
    "Patsch",
    "Pattergassen",
    "Payerbach",
    "Peggau",
    "Perchtoldsdorf",
    "Perg",
    "Pernegg an der Mur",
    "Pernitz",
    "Pertisau",
    "Perwang am Grabensee",
    "Petersdorf",
    "Petronell-Carnuntum",
    "Pettenbach",
    "Pettnau",
    "Pettneu",
    "Petzenkirchen",
    "Peuerbach",
    "Pfaffstatten",
    "Pfons",
    "Pfunds",
    "Piberbach",
    "Pichl",
    "Pichl bei Wels",
    "Pichling bei Stainz",
    "Piesendorf",
    "Pillberg",
    "Pillichsdorf",
    "Pinggau",
    "Pinkafeld",
    "Pinsdorf",
    "Piringsdorf",
    "Pirka",
    "Pischeldorf",
    "Pischelsdorf am Engelbach",
    "Pischelsdorf in Steiermark",
    "Pitten",
    "Plangeross",
    "Plankenau",
    "Plescherken",
    "Pochlarn",
    "Poelten",
    "Poertschach am Woerthersee",
    "Poetting",
    "Poggstall",
    "Polfing",
    "Pottelsdorf",
    "Pottenbrunn",
    "Pottendorf",
    "Pottenstein",
    "Pottsching",
    "Potzneusiedl",
    "Poysbrunn",
    "Poysdorf",
    "Prabach",
    "Pram",
    "Preding",
    "Pregarten",
    "Pressbaum",
    "Prinzersdorf",
    "Puch",
    "Puch bei Hallein",
    "Puchberg am Schneeberg",
    "Puchenau",
    "Pucking",
    "Purbach",
    "Purbach am Neusiedlersee",
    "Purgg",
    "Purgstall",
    "Purgstall",
    "Purkersdorf",
    "Pyhra",
    "Raab",
    "Raaba",
    "Raabs an der Thaya",
    "Radfeld",
    "Radstadt",
    "Ramsau im Zillertal",
    "Randegg",
    "Rangersdorf",
    "Rankweil",
    "Rannersdorf",
    "Rappoltenkirchen",
    "Rauchenwarth",
    "Rauris",
    "Redlham",
    "Regau",
    "Regelsbrunn",
    "Reichenau an der Rax",
    "Reichenau im Muhlkreis",
    "Reichendorf",
    "Reichraming",
    "Reifnitz",
    "Reisenberg",
    "Reitdorf",
    "Reith",
    "Reith bei Seefeld",
    "Reith im Alpbachtal",
    "Rekawinkel",
    "Reutte",
    "Ried im Innkreis",
    "Ried im Traunkreis",
    "Ried im Zillertal",
    "Ried in der Riedmark",
    "Riedenthal",
    "Rieding",
    "Riedlingsdorf",
    "Riefensberg",
    "Riegersburg",
    "Riezlern",
    "Ringelsdorf",
    "Rinn",
    "Rohrau",
    "Rohrbach am Kulm",
    "Rohrbach am Steinfelde",
    "Rohrbach in Oberosterreich",
    "Rohrmoos",
    "Roppen",
    "Rosegg",
    "Rosenau am Hengstpass",
    "Rosenbach",
    "Rosental",
    "Rosental",
    "Rotenbach",
    "Rothis",
    "Rotholz",
    "Rottenbach",
    "Rottenmann",
    "Rubring",
    "Ruden",
    "Rudersdorf",
    "Rum",
    "Rutzenmoos",
    "Saalbach",
    "Saalfelden am Steinernen Meer",
    "Sachendorf",
    "Sachsenburg",
    "Saint Anton",
    "Salzburg",
    "Sankt Agatha",
    "Sankt Andra am Zicksee",
    "Sankt Andrae",
    "Sankt Florian",
    "Sankt Gallenkirch",
    "Sankt Georgen am Ybbsfelde",
    "Sankt Georgen im Attergau",
    "Sankt Johann im Pongau",
    "Sankt Kanzian",
    "Sankt Kathrein am Hauenstein",
    "Sankt Lorenzen",
    "Sankt Lorenzen im Muerztal",
    "Sankt Margarethen im Burgenland",
    "Sankt Marienkirchen am Hausruck",
    "Sankt Martin am Techelsberg",
    "Sankt Martin am Ybbsfelde",
    "Sankt Michael im Burgenland",
    "Sankt Michael in Obersteiermark",
    "Sankt Oswald",
    "Sankt Pankraz",
    "Sankt Peter",
    "Sankt Polten",
    "Sankt Ruprecht an der Raab",
    "Sankt Stefan im Rosental",
    "Sankt Ulrich bei Steyr",
    "Sankt Valentin",
    "Sankt Veit an der Glan",
    "Sankt Veit im Pongau",
    "Sankt Wolfgang",
    "Sankt Wolfgang",
    "Sarleinsbach",
    "Satteins",
    "Sauerbrunn",
    "Sausenstein",
    "Schachendorf",
    "Schalchen",
    "Schardenberg",
    "Scharding",
    "Scharnstein",
    "Scharten",
    "Schattendorf",
    "Scheffau am Wilden Kaiser",
    "Scheifling",
    "Schiefling am See",
    "Schladming",
    "Schleinbach",
    "Schlierbach",
    "Schlins",
    "Schlitters",
    "Schmirn",
    "Schneeberg",
    "Schoenau",
    "Schoenberg im Stubaital",
    "Schoerfling",
    "Schonbuhel an der Donau",
    "Schongrabern",
    "Schonkirchen",
    "Schoppernau",
    "Schrems",
    "Schruns",
    "Schutzen am Gebirge",
    "Schwadorf",
    "Schwaigs",
    "Schwanberg",
    "Schwanenstadt",
    "Schwarzach",
    "Schwarzach im Pongau",
    "Schwarzau am Steinfelde",
    "Schwarzau im Gebirge",
    "Schwarzenau",
    "Schwarzenberg am Boehmerwald",
    "Schwaz",
    "Schwechat",
    "Schweiggers",
    "Schwendau",
    "Schwertberg",
    "Schwoich",
    "Sebersdorf",
    "Seckau",
    "Seeboden",
    "Seefeld in Tirol",
    "Seeham",
    "Seekirchen am Wallersee",
    "Seewalchen",
    "Seiersberg",
    "Seitenstetten Markt",
    "Semmering",
    "Semriach",
    "Serfaus",
    "Sernau",
    "Sibratsgfall",
    "Siebenhirten",
    "Siebenhirten",
    "Siegendorf im Burgenland",
    "Sieghartskirchen",
    "Sierndorf",
    "Sierning",
    "Siezenheim",
    "Sillian",
    "Silz",
    "Sinabelkirchen",
    "Sinnersdorf",
    "Sipbachzell",
    "Sirnitz",
    "Sistrans",
    "Sitzenberg",
    "Solden",
    "Soll",
    "Sollenau",
    "Sommerein",
    "Sooss",
    "Spielberg bei Knittelfeld",
    "Spillern",
    "Spital am Pyhrn",
    "Spittal an der Drau",
    "Spitz",
    "Spratzern",
    "Staatz",
    "Stadelbach",
    "Stadl-Paura",
    "Stadtschlaining",
    "Stainz",
    "Stainz bei Straden",
    "Stams",
    "Stans",
    "Stattegg",
    "Statzendorf",
    "Steg",
    "Stegersbach",
    "Stein an der Donau",
    "Stein an der Enns",
    "Stein im Jauntal",
    "Steinabruckl",
    "Steinach am Brenner",
    "Steinakirchen am Forst",
    "Steinberg bei Ligist",
    "Steindorf am Ossiacher See",
    "Steinerberg",
    "Steinerkirchen an der Traun",
    "Steinfeld",
    "Steinhaus",
    "Steinhaus am Semmering",
    "Stettenhof",
    "Steyr",
    "Steyregg",
    "Stiegl",
    "Stockerau",
    "Stoob",
    "Straden",
    "Strass",
    "Strassen",
    "Strassengel",
    "Strasshof an der Nordbahn",
    "Strasswalchen",
    "Strau",
    "Strobl",
    "Stubach",
    "Stubenberg",
    "Studenzen",
    "Stuhlfelden",
    "Stuhlsdorf",
    "Stumm",
    "Stummerberg",
    "Sulz",
    "Sulz im Wienerwald",
    "Sulzau",
    "Sulzberg",
    "Tadten",
    "Tal",
    "Tamsweg",
    "Tannheim",
    "Tarrenz",
    "Tattendorf",
    "Taufkirchen an der Pram",
    "Taufkirchen an der Trattnach",
    "Tauplitz",
    "Taxenbach",
    "Telfes im Stubai",
    "Telfs",
    "Ternberg",
    "Ternitz",
    "Teufenbach",
    "Thal",
    "Thalgau",
    "Thalheim bei Wels",
    "Thalling",
    "Thaur",
    "Thaya",
    "Theresienfeld",
    "Thoerl",
    "Thuringen",
    "Tiefenbach",
    "Tiefgraben",
    "Tieschen",
    "Tirol",
    "Tobaj",
    "Toeschling",
    "Toesenssteinach",
    "Traboch",
    "Traisen",
    "Traiskirchen",
    "Traismauer",
    "Trasdorf",
    "Traun",
    "Traunkirchen",
    "Traunstein",
    "Trausdorf an der Wulka",
    "Trautmannsdorf an der Leitha",
    "Tribuswinkel",
    "Trieben",
    "Tristach",
    "Troepolach",
    "Trofaiach",
    "Trumau",
    "Tschagguns",
    "Tschoeran",
    "Tulln",
    "Tullnerbach-Lawies",
    "Tumeltsham",
    "Turnitz",
    "Turrach",
    "Tweng",
    "Uderns",
    "Ulmerfeld",
    "Ulrichsberg",
    "Ulrichskirchen",
    "Ungenach",
    "Unterberg",
    "Untergrub",
    "Unterjoch",
    "Unterlamm",
    "Unterparschenbrunn",
    "Unterpremstatten",
    "Unterpullendorf",
    "Unterpurkla",
    "Unterradlberg",
    "Unterrohr",
    "Unterrohr",
    "Untersiebenbrunn",
    "Unterwaltersdorf",
    "Unterweitersdorf",
    "Unzmarkt",
    "Uttendorf",
    "Utzenaich",
    "Veitsch",
    "Velden am Woerthersee",
    "Vent",
    "Vienna",
    "Vigaun",
    "Viktorsberg",
    "Viktring",
    "Villach",
    "Virgen",
    "Vitis",
    "Vocklabruck",
    "Vocklamarkt",
    "Voelkermarkt",
    "Voitsberg",
    "Volders",
    "Vols",
    "Vorau",
    "Vorchdorf",
    "Vorderlanersbach",
    "Vordernberg",
    "Vosendorf",
    "Wagna",
    "Wagrain",
    "Waidhofen an der Thaya",
    "Waidhofen an der Ybbs",
    "Waidmannsfeld",
    "Waidring",
    "Waizenkirchen",
    "Walbersdorf",
    "Walchsee",
    "Wald am Arlberg",
    "Waldenstein",
    "Walding",
    "Waldkirchen am Wesen",
    "Waldzell",
    "Wallern an der Trattnach",
    "Wallern im Burgenland",
    "Wallsee",
    "Wals",
    "Waltersdorf an der March",
    "Waltersdorf in Oststeiermark",
    "Wampersdorf",
    "Wang",
    "Wangle",
    "Wartberg",
    "Wartberg an der Krems",
    "Warth",
    "Warth",
    "Wattens",
    "Watzelsdorf",
    "Watzendorf",
    "Waxenberg",
    "Weibern",
    "Weiden am See",
    "Weigelsdorf",
    "Weikendorf",
    "Weikersdorf am Steinfelde",
    "Weilbach",
    "Weiler",
    "Weinburg am Sassbach",
    "Weissbriach",
    "Weissenbach am Lech",
    "Weissenkirchen in der Wachau",
    "Weisskirchen an der Traun",
    "Weisskirchen in Steiermark",
    "Weiten",
    "Weitersfeld",
    "Weitra",
    "Weiz",
    "Wels",
    "Weng bei Admont",
    "Weng im Innkreis",
    "Wenns",
    "Weppersdorf",
    "Werfen",
    "Werfenweng",
    "Werndorf",
    "Wernstein am Inn",
    "Westendorf",
    "Wettmannstatten",
    "Wiener Neudorf",
    "Wiener Neustadt",
    "Wienerherberg",
    "Wies",
    "Wieselburg",
    "Wiesing",
    "Wiesmath",
    "Wieting",
    "Wildon",
    "Wilfleinsdorf",
    "Wilhelmsburg",
    "Wilhering",
    "Wimpassing an der Leitha",
    "Wimpassing im Schwarzatale",
    "Winden am See",
    "Windischgarsten",
    "Winkl",
    "Winzendorf",
    "Woelfnitz",
    "Wolfau",
    "Wolfpassing",
    "Wolfsberg",
    "Wolfsgraben",
    "Wolfurt",
    "Wolkersdorf im Weinviertel",
    "Wollersdorf",
    "Wordern",
    "Worgl",
    "Wulkaprodersdorf",
    "Wundschuh",
    "Wurflach",
    "Wurmbrand",
    "Ybbs an der Donau",
    "Ybbsitz",
    "Zams",
    "Zeillern",
    "Zeiselmauer",
    "Zell am Moos",
    "Zell am See",
    "Zell am Ziller",
    "Zell an der Ybbs",
    "Zellerndorf",
    "Zeltweg",
    "Zemendorf",
    "Zettling",
    "Ziersdorf",
    "Zillingdorf",
    "Zillingtal",
    "Zipf",
    "Zirl",
    "Zistersdorf",
    "Zobing",
    "Zuers",
    "Zurndorf",
    "Zwentendorf",
    "Zwettl Stadt",
    "Zwolfaxing",
  ],
  Azerbaijan: [
    "Baku",
    "Nakhchivan",
    "Quba",
    "Qusar",
    "Sulutapa",
    "Sumqayit",
    "Xirdalan",
    "Zurges",
  ],
  Bahamas: [
    "Andros Town",
    "Dunmore Town",
    "Freeport",
    "Marsh Harbour",
    "Nassau",
    "Palmetto Point",
    "Spanish Wells",
  ],
  Bahrain: [
    "Al Budayyi`",
    "Al Hadd",
    "Al Hamalah",
    "Al Janabiyah",
    "Al Markh",
    "Al Muharraq",
    "Bani Jamrah",
    "Barbar",
    "Jurdab",
    "Madinat `Isa",
    "Madinat Hamad",
    "Manama",
    "Oil City",
    "Sanabis",
    "Sanad",
    "Sitrah",
    "Tubli",
  ],
  Bangladesh: [
    "Agrabad",
    "Bangla",
    "Barisal",
    "Bazar",
    "Chittagong",
    "Comilla",
    "Delta",
    "Dhaka",
    "Hossain",
    "Jessore",
    "Kabi",
    "Kazipur",
    "Mymensingh",
    "Narayanganj",
    "Rajshahi",
    "Tejgaon",
    "Uttara",
  ],
  Barbados: [
    "Atlantic Shores",
    "Bagatelle",
    "Bloomsbury",
    "Bridgetown",
    "Bruce Vale",
    "Cave Hill",
    "Clapham",
    "Hastings",
    "Holetown",
    "Husbands",
    "Jackmans",
    "Oistins",
    "Pine Housing Estate",
    "Porters",
    "Rendezvous",
    "Rockley",
    "The Garden",
    "Wanstead",
    "Warners",
    "Warrens",
    "White Hill",
    "Wildey",
    "Worthing",
  ],
  Belarus: [
    "Baranovichi",
    "Borisov",
    "Brest",
    "Dzyarzhynsk",
    "Horki",
    "Hrodna",
    "Lahoysk",
    "Lida",
    "Lyakhovichi",
    "Lyaskavichy",
    "Mazyr",
    "Minsk",
    "Mogilev",
    "Navapolatsk",
    "Orsha",
    "Pinsk",
    "Polatsk",
    "Radoshkovichi",
    "Shklow",
    "Vitebsk",
    "Zaslawye",
    "Zhodzina",
  ],
  Belgium: [
    "Aalbeke",
    "Aalst",
    "Aalter",
    "Aarschot",
    "Aarsele",
    "Aartrijke",
    "Aartselaar",
    "Achel",
    "Adegem",
    "Adinkerke",
    "Afsnee",
    "Agimont",
    "Alken",
    "Alleur",
    "Alsemberg",
    "Alveringem",
    "Amay",
    "Ampsin",
    "Andenne",
    "Anderlecht",
    "Anderlues",
    "Andrimont",
    "Angleur",
    "Ans",
    "Anseroeul",
    "Anthisnes",
    "Antwerp",
    "Anzegem",
    "Appels",
    "Appelterre-Eichem",
    "Arbrefontaine",
    "Ardooie",
    "Arendonk",
    "Argenteau",
    "Arlon",
    "Arquennes",
    "Arsimont",
    "As",
    "Asse",
    "Assebroek",
    "Assenede",
    "Assent",
    "Assesse",
    "Astene",
    "Astenet",
    "Ath",
    "Attenhoven",
    "Attre",
    "Aubange",
    "Aubel",
    "Auderghem",
    "Auvelais",
    "Avekapelle",
    "Avelgem",
    "Averbode",
    "Awans",
    "Awirs",
    "Aywaille",
    "Baal",
    "Baarle-Hertog",
    "Baasrode",
    "Baelen",
    "Baisy-Thy",
    "Balen",
    "Banneux",
    "Barchon",
    "Barry",
    "Basecles",
    "Bassenge",
    "Bassevelde",
    "Bastogne",
    "Battice",
    "Baudour",
    "Bavegem",
    "Bavikhove",
    "Bazel",
    "Beaufays",
    "Beauvechain",
    "Beernem",
    "Beerse",
    "Beersel",
    "Beervelde",
    "Beerzel",
    "Beez",
    "Begijnendijk",
    "Beigem",
    "Bekkevoort",
    "Belgrade",
    "Belle Fontaine",
    "Bellem",
    "Beloeil",
    "Belsele",
    "Berchem",
    "Berchem",
    "Berendrecht",
    "Beringen",
    "Berlaar",
    "Berlare",
    "Bernissart",
    "Bertem",
    "Bertrix",
    "Betekom",
    "Bevel",
    "Beveren",
    "Beveren",
    "Beverlo",
    "Beyne-Heusay",
    "Bierbeek",
    "Biercee",
    "Bierges",
    "Bierset",
    "Bilstain",
    "Bilzen",
    "Binche",
    "Bissegem",
    "Blaasveld",
    "Blanden",
    "Blankenberge",
    "Blegny",
    "Bocholt",
    "Boechout",
    "Boezinge",
    "Boncelles",
    "Bonheiden",
    "Bonlez",
    "Booischot",
    "Boom",
    "Boorsem",
    "Boortmeerbeek",
    "Borchtlombeek",
    "Borgerhout",
    "Borgloon",
    "Bornem",
    "Borsbeek",
    "Borsbeke",
    "Bouge",
    "Bouillon",
    "Boussu",
    "Bousval",
    "Boutersem",
    "Bouwel",
    "Bovekerke",
    "Bovesse",
    "Braine-le-Chateau",
    "Braine-le-Comte",
    "Braives",
    "Brasmenil",
    "Brasschaat",
    "Brecht",
    "Bredene",
    "Bree",
    "Bressoux",
    "Broechem",
    "Brugelette",
    "Bruges",
    "Brussegem",
    "Brussels",
    "Budingen",
    "Buggenhout",
    "Buizingen",
    "Bunsbeek",
    "Burcht",
    "Burdinne",
    "Burst",
    "Butgenbach",
    "Buvrinnes",
    "Carnieres",
    "Casteau",
    "Celles",
    "Cerexhe-Heuseux",
    "Chapelle-lez-Herlaimont",
    "Charleroi",
    "Charneux",
    "Chastre",
    "Chatelet",
    "Chatelineau",
    "Chaudfontaine",
    "Chaumont-Gistoux",
    "Chaussee-Notre-Dame-Louvignies",
    "Chenee",
    "Chercq",
    "Chevron",
    "Chievres",
    "Chimay",
    "Chiny",
    "Ciney",
    "Ciplet",
    "Clavier",
    "Colfontaine",
    "Comblain-au-Pont",
    "Comines",
    "Coret",
    "Corroy-le-Grand",
    "Couillet",
    "Cour-sur-Heure",
    "Courcelles",
    "Court-Saint-Etienne",
    "Couthuin",
    "Couvin",
    "Crisnee",
    "Cuesmes",
    "Dadizele",
    "Dalhem",
    "Damme",
    "Dampremy",
    "Dave",
    "De Haan",
    "De Klinge",
    "De Panne",
    "De Pinte",
    "Deerlijk",
    "Deftinge",
    "Deinze",
    "Denderbelle",
    "Denderhoutem",
    "Denderleeuw",
    "Dendermonde",
    "Dentergem",
    "Dessel",
    "Desselgem",
    "Destelbergen",
    "Deurle",
    "Deurne",
    "Diegem",
    "Diepenbeek",
    "Diest",
    "Diksmuide",
    "Dilbeek",
    "Dilsen",
    "Dinant",
    "Dison",
    "Donceel",
    "Dongelberg",
    "Dorinne",
    "Dottignies",
    "Dour",
    "Dranouter",
    "Drogenbos",
    "Drongen",
    "Dudzele",
    "Duffel",
    "Duisburg",
    "Dworp",
    "Ecaussinnes-Lalaing",
    "Edegem",
    "Eeklo",
    "Eernegem",
    "Eghezee",
    "Eigenbilzen",
    "Eke",
    "Ekeren",
    "Eksaarde",
    "Eksel",
    "Elen",
    "Elewijt",
    "Elingen",
    "Ellezelles",
    "Ellignies-Sainte-Anne",
    "Elverdinge",
    "Elversele",
    "Emblem",
    "Embourg",
    "Emptinne",
    "Enghien",
    "Engis",
    "Ensival",
    "Eppegem",
    "Erbaut",
    "Erembodegem",
    "Erezee",
    "Erondegem",
    "Erpe",
    "Erpent",
    "Erps-Kwerps",
    "Erquelinnes",
    "Ertvelde",
    "Esneux",
    "Essen",
    "Essene",
    "Estaimpuis",
    "Estinnes-au-Val",
    "Etalle",
    "Etikhove",
    "Etterbeek",
    "Eupen",
    "Everbeek",
    "Everberg",
    "Evere",
    "Evergem",
    "Faimes",
    "Falisolle",
    "Fallais",
    "Farciennes",
    "Fayt-lez-Manage",
    "Feluy",
    "Ferrieres",
    "Fexhe-le-Haut-Clocher",
    "Fexhe-Slins",
    "Filot",
    "Flawinne",
    "Flemalle-Grande",
    "Flemalle-Haute",
    "Flenu",
    "Fleron",
    "Fleurus",
    "Flobecq",
    "Floreffe",
    "Florennes",
    "Florenville",
    "Fontaine",
    "Fontaine-Valmont",
    "Forchies-la-Marche",
    "Forest",
    "Forrieres",
    "Fosse des Loups",
    "Frameries",
    "Franiere",
    "Frasnes-lez-Buissenal",
    "Freylange",
    "Gages",
    "Galmaarden",
    "Ganshoren",
    "Gavere",
    "Geel",
    "Geer",
    "Geetbets",
    "Gellik",
    "Gelrode",
    "Geluwe",
    "Gembloux",
    "Genk",
    "Gentbrugge",
    "Gentinnes",
    "Genval",
    "Geraardsbergen",
    "Gerpinnes",
    "Gesves",
    "Ghent",
    "Ghislenghien",
    "Ghlin",
    "Gierle",
    "Gijzegem",
    "Gilly",
    "Gingelom",
    "Gistel",
    "Gits",
    "Glabbeek",
    "Glimes",
    "Gobard",
    "Godarville",
    "Goe",
    "Gooik",
    "Goor",
    "Gosselies",
    "Gourdinne",
    "Goutroux",
    "Gouy-lez-Pieton",
    "Grand",
    "Grand-Leez",
    "Grand-Rechain",
    "Grand-Reng",
    "Grembergen",
    "Grez-Doiceau",
    "Grimbergen",
    "Grimminge",
    "Grivegnee",
    "Grobbendonk",
    "Groot-Bijgaarden",
    "Gruitrode",
    "Gullegem",
    "Haacht",
    "Haaltert",
    "Haasdonk",
    "Haasrode",
    "Haccourt",
    "Haine-Saint-Paul",
    "Halen",
    "Hallaar",
    "Halle",
    "Ham",
    "Ham",
    "Ham-sur-Heure",
    "Ham-sur-Sambre",
    "Hamme",
    "Hamme-Mille",
    "Hamoir",
    "Hamont",
    "Hampteau",
    "Haneffe",
    "Hannut",
    "Hansbeke",
    "Harelbeke",
    "Haren",
    "Hasselt",
    "Haut-Ittre",
    "Hautrage",
    "Havay",
    "Havre",
    "Hechtel",
    "Heers",
    "Heestert",
    "Heffen",
    "Heindonk",
    "Heist",
    "Heist-op-den-Berg",
    "Hekelgem",
    "Helchteren",
    "Hemiksem",
    "Henri-Chapelle",
    "Hensies",
    "Heppen",
    "Herdersem",
    "Herent",
    "Herentals",
    "Herenthout",
    "Herinnes",
    "Herk-de-Stad",
    "Herlaimont",
    "Hermalle-sous-Argenteau",
    "Hermee",
    "Herne",
    "Herseaux",
    "Herselt",
    "Herstal",
    "Herve",
    "Herzele",
    "Heule",
    "Heure-le-Romain",
    "Heusden",
    "Heusden",
    "Heusy",
    "Hever",
    "Heverlee",
    "Hingene",
    "Hoboken",
    "Hodeige",
    "Hoegaarden",
    "Hoeilaart",
    "Hoeleden",
    "Hoeselt",
    "Hoevenen",
    "Hofstade",
    "Hofstade",
    "Hognoul",
    "Hollain",
    "Holland",
    "Hollogne",
    "Holsbeek",
    "Hombeek",
    "Hooglede",
    "Hoogstraten",
    "Horion-Hozemont",
    "Hornu",
    "Hotton",
    "Houdeng-Goegnies",
    "Houffalize",
    "Housse",
    "Houtain-le-Val",
    "Houtaing",
    "Houthalen",
    "Houthem",
    "Houthulst",
    "Houtvenne",
    "Hove",
    "Huizingen",
    "Huldenberg",
    "Hulshout",
    "Humbeek",
    "Humbeek",
    "Huy",
    "Hyon",
    "Ichtegem",
    "Iddergem",
    "Idegem",
    "Impe",
    "Incourt",
    "Ingelmunster",
    "Ingooigem",
    "Isnes",
    "Itegem",
    "Itterbeek",
    "Ittre",
    "Ixelles-Elsene",
    "Izegem",
    "Izel",
    "Jabbeke",
    "Jambes",
    "Jamioulx",
    "Jauche",
    "Jemappes",
    "Jemelle",
    "Jemeppe-sur-Meuse",
    "Jemeppe-sur-Sambre",
    "Jette",
    "Jodoigne",
    "Jumet",
    "Jupille-sur-Meuse",
    "Juprelle",
    "Jurbise",
    "Kachtem",
    "Kain",
    "Kalken",
    "Kalmthout",
    "Kampenhout",
    "Kanegem",
    "Kapelle-op-den-Bos",
    "Kapellen",
    "Kaprijke",
    "Kasterlee",
    "Kaulille",
    "Keerbergen",
    "Kemzeke",
    "Kerkhove",
    "Kerksken",
    "Kermt",
    "Kersbeek-Miskom",
    "Kessel",
    "Kessel-Lo",
    "Kettenis",
    "Kinrooi",
    "Klein-Gelmen",
    "Klemskerke",
    "Knesselare",
    "Knokke",
    "Knokke-Heist",
    "Koekelare",
    "Koersel",
    "Koksijde",
    "Koningshooikt",
    "Kontich",
    "Korbeek-Dijle",
    "Korbeek-Lo",
    "Kortemark",
    "Kortenaken",
    "Kortenberg",
    "Kortessem",
    "Kortrijk",
    "Kortrijk-Dutsel",
    "Kozen",
    "Kraainem",
    "Kruibeke",
    "Kruishoutem",
    "Kuringen",
    "Kuurne",
    "Kwaadmechelen",
    "La Calamine",
    "La Hestre",
    "La Hulpe",
    "Laarne",
    "Labuissiere",
    "Laken",
    "Lambermont",
    "Lambermont",
    "Lanaken",
    "Landegem",
    "Landelies",
    "Landen",
    "Langdorp",
    "Langemark",
    "Lanklaar",
    "Latinne",
    "Lauwe",
    "Le Tilleul",
    "Lebbeke",
    "Lede",
    "Ledeberg",
    "Ledegem",
    "Leefdaal",
    "Leerbeek",
    "Leernes",
    "Leest",
    "Leffinge",
    "Leisele",
    "Lembeek",
    "Lendelede",
    "Lennik",
    "Leopoldsburg",
    "Lessines",
    "Letterhoutem",
    "Leuven",
    "Leuze",
    "Leval-Trahegnies",
    "Libramont",
    "Lichtaart",
    "Lichtervelde",
    "Liedekerke",
    "Lier",
    "Ligny",
    "Lille",
    "Lillois-Witterzee",
    "Limal",
    "Limbourg",
    "Limelette",
    "Lincent",
    "Linden",
    "Linkebeek",
    "Lint",
    "Lippelo",
    "Lisogne",
    "Lissewege",
    "Lives-sur-Meuse",
    "Lixhe",
    "Lobbes",
    "Lochristi",
    "Lodelinsart",
    "Loenhout",
    "Loker",
    "Lokeren",
    "Lommel",
    "Loncin",
    "Londerzeel",
    "Lontzen",
    "Loppem",
    "Lot",
    "Lotenhulle",
    "Loupoigne",
    "Louvain-la-Neuve",
    "Louveigne",
    "Lovendegem",
    "Loverval",
    "Lubbeek",
    "Lummen",
    "Luttre",
    "Maaseik",
    "Maasmechelen",
    "Machelen",
    "Magnee",
    "Maisieres",
    "Maldegem",
    "Malderen",
    "Malmedy",
    "Malonne",
    "Manage",
    "Manhay",
    "Marais",
    "Marche-en-Famenne",
    "Marche-lez-Ecaussinnes",
    "Marchienne-au-Pont",
    "Marchin",
    "Marcinelle",
    "Mariakerke",
    "Mariekerke",
    "Mariembourg",
    "Marke",
    "Markegem",
    "Massemen",
    "Maurage",
    "Mechelen",
    "Meensel-Kiezegem",
    "Meerbeke",
    "Meerhout",
    "Meerlaar",
    "Meerle",
    "Meersel",
    "Meeuwen",
    "Meigem",
    "Meise",
    "Meldert",
    "Melen",
    "Melkwezer",
    "Melle",
    "Melsbroek",
    "Melsele",
    "Membach",
    "Menen",
    "Merchtem",
    "Mere",
    "Merelbeke",
    "Merendree",
    "Merksem",
    "Merksplas",
    "Mespelare",
    "Messancy",
    "Mettet",
    "Meulebeke",
    "Middelburg",
    "Middelkerke",
    "Minderhout",
    "Modave",
    "Moen",
    "Moerbeke",
    "Moerzeke",
    "Moha",
    "Mol",
    "Molenbeek",
    "Molenbeek-Saint-Jean",
    "Molenstede",
    "Momalle",
    "Monceau-sur-Sambre",
    "Mons",
    "Mont-Saint-Guibert",
    "Mont-Sainte-Aldegonde",
    "Mont-sur-Marchienne",
    "Montegnee",
    "Montignies-lez-Lens",
    "Montignies-sur-Sambre",
    "Montzen",
    "Moorsel",
    "Moorslede",
    "Morialme",
    "Morkhoven",
    "Morlanwelz-Mariemont",
    "Mortsel",
    "Mouscron",
    "Muizen",
    "Muizen",
    "Munsterbilzen",
    "Naast",
    "Nalinnes",
    "Nameche",
    "Namur",
    "Nandrin",
    "Natoye",
    "Nazareth",
    "Neder-Over-Heembeek",
    "Nederbrakel",
    "Nederhasselt",
    "Neerharen",
    "Neerijse",
    "Neerlinter",
    "Neerpelt",
    "Nethen",
    "Neuville-en-Condroz",
    "Nevele",
    "Niel",
    "Nieuwenhove",
    "Nieuwerkerken",
    "Nieuwerkerken",
    "Nieuwpoort",
    "Nieuwpoort",
    "Nieuwrode",
    "Nijlen",
    "Nil Saint-Vincent",
    "Nimy",
    "Ninove",
    "Nivelles",
    "Nodebais",
    "Nokere",
    "Nossegem",
    "Nouvelles",
    "Nukerke",
    "Obaix",
    "Obourg",
    "Oedelem",
    "Oelegem",
    "Oeselgem",
    "Ohain",
    "Ohey",
    "Okegem",
    "Olen",
    "Olne",
    "Onze-Lieve-Vrouw-Waver",
    "Ooigem",
    "Oordegem",
    "Oostakker",
    "Oosteeklo",
    "Oostende",
    "Oosterzele",
    "Oostham",
    "Oostkamp",
    "Oostmalle",
    "Oostrozebeke",
    "Oostvleteren",
    "Opbrakel",
    "Opglabbeek",
    "Opgrimbie",
    "Ophain",
    "Opitter",
    "Opoeteren",
    "Oppem",
    "Oppuurs",
    "Opwijk",
    "Orbais",
    "Orp-le-Grand",
    "Ostend",
    "Otegem",
    "Othee",
    "Ottignies",
    "Oud-Heverlee",
    "Oud-Turnhout",
    "Oudegem",
    "Oudenaarde",
    "Oudenburg",
    "Ougree",
    "Oupeye",
    "Outer",
    "Outrijve",
    "Overheide",
    "Overijse",
    "Overmere",
    "Overpelt",
    "Paal",
    "Pamel",
    "Parike",
    "Park",
    "Passendale",
    "Peer",
    "Pellenberg",
    "Pepinster",
    "Peronnes",
    "Pervijze",
    "Perwez",
    "Petegem",
    "Peutie",
    "Pipaix",
    "Pittem",
    "Plainevaux",
    "Plancenoit",
    "Ploegsteert",
    "Poederlee",
    "Poelkapelle",
    "Pollare",
    "Pont-a-Celles",
    "Pont-de-Loup",
    "Poperinge",
    "Poppel",
    "Post",
    "Presles",
    "Profondeville",
    "Pulderbos",
    "Pulle",
    "Putte",
    "Puurs",
    "Quaregnon",
    "Quenast",
    "Quevaucamps",
    "Quievrain",
    "Ramet",
    "Ramsdonk",
    "Ramsel",
    "Ramskapelle",
    "Rance",
    "Ransart",
    "Ranst",
    "Ravels",
    "Rebaix",
    "Recht",
    "Reet",
    "Rekem",
    "Rekkem",
    "Relegem",
    "Remersdaal",
    "Remicourt",
    "Reninge",
    "Renlies",
    "Ressaix",
    "Ressegem",
    "Retie",
    "Retinne",
    "Rhisnes",
    "Richelle",
    "Riemst",
    "Rijkevorsel",
    "Rijmenam",
    "Rillaar",
    "Rixensart",
    "Rochefort",
    "Rocourt",
    "Rode",
    "Roeselare",
    "Roeulx",
    "Romsee",
    "Ronse",
    "Rosieres",
    "Rosmeer",
    "Rotselaar",
    "Roux",
    "Ruddervoorde",
    "Ruien",
    "Ruisbroek",
    "Ruisbroek",
    "Ruiselede",
    "Rumbeke",
    "Rummen",
    "Rumst",
    "Sablon",
    "Saint-Georges-sur-Meuse",
    "Saint-Ghislain",
    "Saint-Gilles",
    "Saint-Jean-Geest",
    "Saint-Josse-ten-Noode",
    "Saint-Leger",
    "Saint-Mard",
    "Saint-Nicolas",
    "Saint-Remy",
    "Saint-Sauveur",
    "Saint-Servais",
    "Saint-Symphorien",
    "Sainte-Ode",
    "Saintes",
    "Saive",
    "Sankt Vith",
    "Sart Melin",
    "Sart-Dames-Avelines",
    "Sart-en-Fagne",
    "Sart-lez-Spa",
    "Schaarbeek",
    "Schaffen",
    "Schalkhoven",
    "Scheldewindeke",
    "Schelle",
    "Schellebelle",
    "Schendelbeke",
    "Schepdaal",
    "Scherpenheuvel",
    "Schilde",
    "Schoonaarde",
    "Schorisse",
    "Schoten",
    "Schriek",
    "Schulen",
    "Sclayn",
    "Seilles",
    "Seneffe",
    "Seraing",
    "Serskamp",
    "Sijsele",
    "Sinaai",
    "Sint-Agatha-Berchem",
    "Sint-Agatha-Rode",
    "Sint-Amands",
    "Sint-Amandsberg",
    "Sint-Andries",
    "Sint-Baafs-Vijve",
    "Sint-Denijs-Westrem",
    "Sint-Genesius-Rode",
    "Sint-Gillis-bij-Dendermonde",
    "Sint-Gillis-Waas",
    "Sint-Katelijne-Waver",
    "Sint-Kornelis-Horebeke",
    "Sint-Lambrechts-Herk",
    "Sint-Laureins",
    "Sint-Lievens-Houtem",
    "Sint-Maria-Latem",
    "Sint-Martens-Latem",
    "Sint-Michiels",
    "Sint-Niklaas",
    "Sint-Pauwels",
    "Sint-Pieters op de Dijk",
    "Sint-Pieters-Leeuw",
    "Sint-Stevens-Woluwe",
    "Sint-Truiden",
    "Sirault",
    "Sleidinge",
    "Soignies",
    "Sombreffe",
    "Soumagne",
    "Souvret",
    "Spa",
    "Sprimont",
    "Stabroek",
    "Staden",
    "Stavelot",
    "Steendorp",
    "Steenokkerzeel",
    "Stekene",
    "Stembert",
    "Stene",
    "Ster",
    "Sterrebeek",
    "Stevoort",
    "Stokkem",
    "Stoumont",
    "Stree",
    "Strepy-Bracquegnies",
    "Strombeek-Bever",
    "Tamines",
    "Temse",
    "Teralfene",
    "Terhagen",
    "Ternat",
    "Tertre",
    "Tervuren",
    "Tessenderlo",
    "Testelt",
    "Theux",
    "Thimeon",
    "Thimister",
    "Thisnes",
    "Thuin",
    "Thulin",
    "Thy-le-Chateau",
    "Thynes",
    "Tiegem",
    "Tielt",
    "Tielt",
    "Tienen",
    "Tildonk",
    "Tilff",
    "Tilleur",
    "Tisselt",
    "Toernich",
    "Tohogne",
    "Tongeren",
    "Tongerlo",
    "Tongre-Notre-Dame",
    "Torhout",
    "Tournai",
    "Transinne",
    "Trazegnies",
    "Tremelo",
    "Trooz",
    "Tubize",
    "Turnhout",
    "Uccle",
    "Ulbeek",
    "Ursel",
    "Vance",
    "Varsenare",
    "Vaux",
    "Vaux-sous-Chevremont",
    "Vedrin",
    "Veerle",
    "Velaine-sur-Sambre",
    "Velaines",
    "Veldegem",
    "Velm",
    "Veltem-Beisem",
    "Verlaine",
    "Verrebroek",
    "Verviers",
    "Veurne",
    "Viane",
    "Vichte",
    "Vielsalm",
    "Viesville",
    "Villers-la-Ville",
    "Villers-le-Bouillet",
    "Villers-sur-Semois",
    "Vilvoorde",
    "Vinderhoute",
    "Virton",
    "Vise",
    "Vivegnis",
    "Vladslo",
    "Vlekkem",
    "Vlezenbeek",
    "Vliermaal",
    "Vliermaalroot",
    "Vlierzele",
    "Vlissegem",
    "Vollezele",
    "Voormezele",
    "Voroux-Goreux",
    "Vorselaar",
    "Vosselaar",
    "Vossem",
    "Vottem",
    "Vremde",
    "Waarloos",
    "Waarschoot",
    "Waasmunster",
    "Wachtebeke",
    "Wagnelee",
    "Wakken",
    "Waleffe Saint-Georges",
    "Walhain",
    "Walhorn",
    "Wanfercee-Baulet",
    "Wangenies",
    "Wanne",
    "Wanze",
    "Warchin",
    "Waregem",
    "Waremme",
    "Warneton",
    "Warquignies",
    "Warsage",
    "Wasmes",
    "Waterloo",
    "Watermael-Boitsfort",
    "Watervliet",
    "Waudrez",
    "Wavre",
    "Ways",
    "Wechelderzande",
    "Weelde",
    "Welkenraedt",
    "Welle",
    "Wellen",
    "Wemmel",
    "Wenduine",
    "Wepion",
    "Werchter",
    "Werken",
    "Wervik",
    "Wespelaar",
    "Westende",
    "Westerlo",
    "Westmalle",
    "Westouter",
    "Wetteren",
    "Wevelgem",
    "Wezemaal",
    "Wezembeek-Oppem",
    "Wichelen",
    "Wiekevorst",
    "Wielsbeke",
    "Wierde",
    "Wieze",
    "Wijgmaal",
    "Wijnegem",
    "Willebroek",
    "Wilrijk",
    "Wilsele",
    "Wingene",
    "Winksele",
    "Woesten",
    "Wolfsdonk",
    "Wolkrange",
    "Woluwe-Saint-Lambert",
    "Woluwe-Saint-Pierre - Sint-Pieters-Woluwe",
    "Wolvertem",
    "Wommelgem",
    "Wondelgem",
    "Wortegem",
    "Wuustwezel",
    "Xhendremael",
    "Xhoris",
    "Ypres",
    "Yvoir",
    "Zaffelare",
    "Zandbergen",
    "Zandhoven",
    "Zarren",
    "Zaventem",
    "Zedelgem",
    "Zeebrugge",
    "Zele",
    "Zellik",
    "Zelzate",
    "Zemst",
    "Zeveneken",
    "Zichem",
    "Zingem",
    "Zoersel",
    "Zolder",
    "Zomergem",
    "Zonhoven",
    "Zonnebeke",
    "Zottegem",
    "Zoutleeuw",
    "Zuienkerke",
    "Zulte",
    "Zutendaal",
    "Zwartenbroek",
    "Zwevegem",
    "Zwevezele",
    "Zwijnaarde",
    "Zwijndrecht",
  ],
  Belize: [
    "Belize City",
    "Belmopan",
    "Benque Viejo del Carmen",
    "Freetown Sibun",
    "Ladyville",
    "San Ignacio",
    "San Pedro Town",
  ],
  Bolivia: [
    "Anillo",
    "Aroma",
    "Bermejo",
    "Caracasa",
    "Cobija",
    "Cochabamba",
    "Cotoca",
    "Cruz",
    "Guayaramerin",
    "La Paz",
    "Oruro",
    "Riberalta",
    "Santa Cruz",
    "Santa Cruz",
    "Sucre",
    "Tarija",
    "Trinidad",
  ],
  "Bosnia and Herzegovina": [
    "Banja",
    "Banja Luka",
    "Bijeljina",
    "Bosanska Dubica",
    "Bosanska Krupa",
    "Brcko",
    "Breza",
    "Bugojno",
    "Cazin",
    "Core",
    "Doboj",
    "Donja Mahala",
    "Gracanica",
    "Ilidza",
    "Jajce",
    "Jelah",
    "Kiseljak",
    "Livno",
    "Ljubuski",
    "Lopare",
    "Lukavac",
    "Modrica",
    "Mostar",
    "Novi Travnik",
    "Odzak",
    "Prijedor",
    "Sanski Most",
    "Sarajevo",
    "Siroki Brijeg",
    "Srpska",
    "Stup",
    "Tesanj",
    "Travnik",
    "Tuzla",
    "Visoko",
    "Vitez",
    "Vogosca",
    "Zenica",
    "Zepce",
    "Zivinice",
  ],
  Botswana: ["Francistown", "Gaborone", "Orapa", "Serowe", "Village"],
  Brazil: [
    "Abadiania",
    "Abaetetuba",
    "Abelardo Luz",
    "Abidos",
    "Abrantes",
    "Abreu",
    "Abreu e Lima",
    "Acarau",
    "Acopiara",
    "Acorizal",
    "Acu",
    "Acucena",
    "Adamantina",
    "Adustina",
    "Afogados da Ingazeira",
    "Afonso Claudio",
    "Agrestina",
    "Agua Boa",
    "Agua Clara",
    "Aguai",
    "Aguanil",
    "Aguas Claras",
    "Aguas da Prata",
    "Aguas de Chapeco",
    "Aguas de Lindoia",
    "Aguas de Sao Pedro",
    "Aguas Lindas",
    "Agudo",
    "Agudos",
    "Agulha",
    "Alagoa Grande",
    "Alagoa Nova",
    "Alagoinha",
    "Alagoinhas",
    "Alambari",
    "Aldeia",
    "Alegre",
    "Alegres",
    "Alegrete",
    "Alem Paraiba",
    "Alfenas",
    "Alfredo Chaves",
    "Almeirim",
    "Almirante Tamandare",
    "Aloandia",
    "Alta Floresta",
    "Altamira",
    "Altaneira",
    "Alterosa",
    "Altinho",
    "Altinopolis",
    "Alto",
    "Alto Alegre",
    "Alto do Rodrigues",
    "Alto Paraiso de Goias",
    "Alto Piquiri",
    "Alto Santo",
    "Altos",
    "Alvares Machado",
    "Alvorada",
    "Alvorada do Sul",
    "Amambai",
    "Amaraji",
    "Amarante",
    "Amarante do Maranhao",
    "Amazonas",
    "America de Cima",
    "America Dourada",
    "Americana",
    "Americo Brasiliense",
    "Amparo",
    "Anadia",
    "Anajas",
    "Ananindeua",
    "Anchieta",
    "Andarai",
    "Andradas",
    "Andradina",
    "Andre",
    "Andre da Rocha",
    "Angatuba",
    "Angical do Piaui",
    "Angicos",
    "Angra dos Reis",
    "Anhembi",
    "Anhumas",
    "Antas",
    "Antonina",
    "Antonio Carlos",
    "Antonio Carlos",
    "Antonio Prado",
    "Aparecida",
    "Aparecida do Rio Negro",
    "Aperibe",
    "Apiai",
    "Apiuna",
    "Apodi",
    "Apora",
    "Apucarana",
    "Apuiares",
    "Aquidaba",
    "Aquidauana",
    "Aquiraz",
    "Aracai",
    "Aracaju",
    "Aracariguama",
    "Aracas",
    "Aracati",
    "Aracatuba",
    "Araci",
    "Aracoiaba da Serra",
    "Aracruz",
    "Aracuai",
    "Aragarcas",
    "Araguari",
    "Arandu",
    "Arapiraca",
    "Arapongas",
    "Arapoti",
    "Arapua",
    "Araquari",
    "Ararangua",
    "Araraquara",
    "Araras",
    "Ararica",
    "Araripina",
    "Araruama",
    "Araruna",
    "Araujos",
    "Arceburgo",
    "Arcos",
    "Arcoverde",
    "Areado",
    "Areal",
    "Arealva",
    "Areia Branca",
    "Areial",
    "Aricanduva",
    "Ariquemes",
    "Armacao de Buzios",
    "Armazem",
    "Aroeiras",
    "Arraial",
    "Arraial do Cabo",
    "Arroio do Meio",
    "Arroio do Silva",
    "Arroio do Tigre",
    "Arroio dos Ratos",
    "Arroio Grande",
    "Artur Nogueira",
    "Ascurra",
    "Assis",
    "Assis Chateaubriand",
    "Atibaia",
    "Auriflama",
    "Avanhandava",
    "Avare",
    "Azurita",
    "Bady Bassitt",
    "Baia da Traicao",
    "Baiao",
    "Bairro da Velha Central",
    "Baixo Guandu",
    "Balsa Nova",
    "Balsamo",
    "Balsas",
    "Bambui",
    "Banabuiu",
    "Bananal",
    "Bandeirantes",
    "Bangu",
    "Barao de Cocais",
    "Barao de Cotegipe",
    "Barao de Grajau",
    "Barauna",
    "Barbacena",
    "Barbalha",
    "Barbosa",
    "Barcarena",
    "Barra",
    "Barra",
    "Barra Bonita",
    "Barra da Tijuca",
    "Barra de Sao Miguel",
    "Barra do Bugres",
    "Barra do Corda",
    "Barra do Jacare",
    "Barra do Ribeiro",
    "Barra Mansa",
    "Barra Velha",
    "Barracao",
    "Barras",
    "Barreiras",
    "Barretos",
    "Barrinha",
    "Barro",
    "Barrocas",
    "Barueri",
    "Batatais",
    "Batista",
    "Baturite",
    "Bauru",
    "Bayeux",
    "Bebedouro",
    "Beberibe",
    "Bela Vista",
    "Bela Vista",
    "Bela Vista",
    "Bela Vista de Goias",
    "Bela Vista do Paraiso",
    "Belem",
    "Belem",
    "Belem do Brejo do Cruz",
    "Belford Roxo",
    "Belo Horizonte",
    "Belo Horizonte",
    "Belo Jardim",
    "Benedito Novo",
    "Benevides",
    "Bento",
    "Berilo",
    "Bernardo",
    "Bertioga",
    "Betania",
    "Betim",
    "Bezerros",
    "Bicas",
    "Biguacu",
    "Birigui",
    "Biritiba Mirim",
    "Biritinga",
    "Bituruna",
    "Blumenau",
    "Boa Esperanca",
    "Boa Esperanca",
    "Boa Ventura",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista do Burica",
    "Bocaiuva",
    "Bocaiuva do Sul",
    "Boituva",
    "Bom Conselho",
    "Bom Despacho",
    "Bom Jardim",
    "Bom Jardim de Minas",
    "Bom Jesus",
    "Bom Jesus",
    "Bom Jesus",
    "Bom Jesus da Lapa",
    "Bom Jesus do Amparo",
    "Bom Jesus do Itabapoana",
    "Bom Jesus dos Perdoes",
    "Bom Principio",
    "Bom Retiro do Sul",
    "Bombinhas",
    "Bonfim",
    "Bonito",
    "Bonito",
    "Bonito",
    "Bonito",
    "Boqueirao",
    "Boquim",
    "Borda da Mata",
    "Borrazopolis",
    "Botafogo",
    "Botafogo",
    "Botucatu",
    "Botupora",
    "Botuvera",
    "Braco do Norte",
    "Braganca",
    "Braganca Paulista",
    "Branco",
    "Bras",
    "Brasa",
    "Brasil",
    "Brasilandia",
    "Brasopolis",
    "Brejetuba",
    "Brejinho de Nazare",
    "Brejo da Madre de Deus",
    "Brejo Santo",
    "Breves",
    "Brochier",
    "Brodosqui",
    "Brotas",
    "Brumadinho",
    "Brumado",
    "Brusque",
    "Bueno Brandao",
    "Buique",
    "Buritama",
    "Buriti",
    "Buriti",
    "Buritizeiro",
    "Butia",
    "Buzios",
    "Caapora",
    "Caarapo",
    "Cabedelo",
    "Cabo de Santo Agostinho",
    "Cabo Frio",
    "Cabralia",
    "Cabralia Paulista",
    "Cabreuva",
    "Cabrobo",
    "Cacapava",
    "Cacapava do Sul",
    "Cacequi",
    "Caceres",
    "Cachoeira",
    "Cachoeira do Sul",
    "Cachoeira Grande",
    "Cachoeira Paulista",
    "Cachoeiras",
    "Cachoeiras de Macacu",
    "Cachoeirinha",
    "Cachoeiro de Itapemirim",
    "Cacimba de Dentro",
    "Cacique Doble",
    "Cacoal",
    "Caete",
    "Caetite",
    "Cafarnaum",
    "Cafelandia",
    "Caiana",
    "Caiaponia",
    "Caibate",
    "Caico",
    "Caieiras",
    "Caiua",
    "Cajamar",
    "Cajati",
    "Cajazeiras",
    "Cajobi",
    "Cajuru",
    "Calcado",
    "Calcoene",
    "Caldas",
    "Caldas Novas",
    "California",
    "Camacari",
    "Camamu",
    "Camanducaia",
    "Camaqua",
    "Camaragibe",
    "Cambe",
    "Camboriu",
    "Cambui",
    "Cambuquira",
    "Cameta",
    "Campestre",
    "Campina",
    "Campina Grande",
    "Campina Grande do Sul",
    "Campinas",
    "Campinas do Sul",
    "Campinorte",
    "Campo",
    "Campo Alegre",
    "Campo Alegre",
    "Campo Bom",
    "Campo Ere",
    "Campo Formoso",
    "Campo Grande",
    "Campo Grande",
    "Campo Largo",
    "Campo Magro",
    "Campo Maior",
    "Campo Mourao",
    "Campo Novo",
    "Campo Verde",
    "Campos",
    "Campos",
    "Campos do Jordao",
    "Campos dos Goytacazes",
    "Campos Gerais",
    "Campos Novos",
    "Campos Sales",
    "Canarana",
    "Canas",
    "Canavieiras",
    "Candeal",
    "Candeias",
    "Candeias",
    "Candelaria",
    "Candido Mota",
    "Candoi",
    "Canela",
    "Canelinha",
    "Cangucu",
    "Canhotinho",
    "Caninde",
    "Caninde de Sao Francisco",
    "Canoas",
    "Canoinhas",
    "Cansancao",
    "Cantagalo",
    "Cantanhede",
    "Capanema",
    "Capao Alto",
    "Capao Bonito",
    "Capao da Canoa",
    "Capao do Leao",
    "Caparao",
    "Capela",
    "Capela",
    "Capela do Alto",
    "Capelinha",
    "Capetinga",
    "Capinopolis",
    "Capistrano",
    "Capitao Poco",
    "Capivari",
    "Caputira",
    "Caraa",
    "Caraguatatuba",
    "Carambei",
    "Carangola",
    "Carapebus",
    "Carapicuiba",
    "Caratinga",
    "Caraubas",
    "Carazinho",
    "Cardeal da Silva",
    "Cardoso",
    "Cariacica",
    "Carinhanha",
    "Caripi",
    "Carius",
    "Carlos Barbosa",
    "Carmo da Mata",
    "Carmo do Cajuru",
    "Carmo do Rio Claro",
    "Carmopolis",
    "Carmopolis de Minas",
    "Carnaiba",
    "Carolina",
    "Carpina",
    "Caruaru",
    "Casa Nova",
    "Casca",
    "Cascavel",
    "Casimiro de Abreu",
    "Cassia",
    "Cassilandia",
    "Cassino",
    "Castanhal",
    "Castelo",
    "Castro",
    "Cataguases",
    "Catalao",
    "Catanduva",
    "Catanduvas",
    "Catarina",
    "Catende",
    "Catu",
    "Caucaia",
    "Caxambu",
    "Caxambu",
    "Caxias",
    "Caxias do Sul",
    "Ceara Mirim",
    "Cedral",
    "Cedro do Abaete",
    "Ceilandia",
    "Centenario do Sul",
    "Central",
    "Ceres",
    "Cerqueira Cesar",
    "Cerquilho",
    "Cerro Azul",
    "Cerro Branco",
    "Cerro Grande",
    "Cerro Largo",
    "Cesario Lange",
    "Ceu Azul",
    "Cha Grande",
    "Chapada",
    "Chapadao",
    "Chapadao",
    "Chapadinha",
    "Charqueada",
    "Charqueadas",
    "Chaval",
    "Chavantes",
    "Chopinzinho",
    "Cianorte",
    "Cicero Dantas",
    "Cidade",
    "Cidade",
    "Cidade de Deus",
    "Cidade Nova",
    "Cipotanea",
    "Claro dos Pocoes",
    "Claudio",
    "Clementina",
    "Clevelandia",
    "Coari",
    "Cocal",
    "Cocalinho",
    "Cocos",
    "Coelho",
    "Colatina",
    "Colina",
    "Colinas",
    "Colinas",
    "Colombo",
    "Colorado",
    "Comodoro",
    "Conceicao",
    "Conceicao",
    "Conceicao da Barra",
    "Conceicao das Alagoas",
    "Conceicao de Macabu",
    "Conceicao do Araguaia",
    "Conceicao do Coite",
    "Conceicao do Mato Dentro",
    "Conchal",
    "Conchas",
    "Concordia",
    "Condado",
    "Conde",
    "Conde",
    "Congonhas",
    "Conselheiro Lafaiete",
    "Contagem",
    "Contenda",
    "Convento da Penha",
    "Cordeiro",
    "Cordeiropolis",
    "Cordilheira",
    "Coreau",
    "Coremas",
    "Corguinho",
    "Cornelio Procopio",
    "Coroados",
    "Coroas",
    "Coroata",
    "Coromandel",
    "Coronel",
    "Coronel Fabriciano",
    "Correia Pinto",
    "Correntina",
    "Cortes",
    "Corumba de Goias",
    "Coruripe",
    "Cosmopolis",
    "Cosmorama",
    "Cotia",
    "Crateus",
    "Crato",
    "Cravinhos",
    "Cristais",
    "Cristalina",
    "Cristiano Otoni",
    "Cruz",
    "Cruz Alta",
    "Cruz das Almas",
    "Cruzeiro",
    "Cruzeiro da Fortaleza",
    "Cruzeiro do Oeste",
    "Cruzeiro do Sul",
    "Cruzeiro do Sul",
    "Cruzilia",
    "Cubatao",
    "Cuite",
    "Cunha Pora",
    "Cupira",
    "Curimata",
    "Curitiba",
    "Curitibanos",
    "Currais Novos",
    "Cururupu",
    "Curvelo",
    "Delfinopolis",
    "Descalvado",
    "Descanso",
    "Diadema",
    "Diamantina",
    "Diamantino",
    "Dias",
    "Dionisio Cerqueira",
    "Divinopolis",
    "Dix-sept Rosado",
    "Dobrada",
    "Dois Corregos",
    "Dois Irmaos",
    "Dois Vizinhos",
    "Dom Feliciano",
    "Dom Pedrito",
    "Dom Silverio",
    "Domingos Martins",
    "Dores do Indaia",
    "Dourado",
    "Dourados",
    "Doutor Camargo",
    "Doutor Mauricio Cardoso",
    "Dracena",
    "Duartina",
    "Duas Barras",
    "Dumont",
    "Duque de Caxias",
    "Eldorado",
    "Elias Fausto",
    "Elisiario",
    "Eloi Mendes",
    "Embu",
    "Embu Guacu",
    "Encantado",
    "Encruzilhada do Sul",
    "Engenheiro Beltrao",
    "Engenheiro Schmidt",
    "Entre Rios",
    "Entre Rios de Minas",
    "Erechim",
    "Erval Grande",
    "Erval Velho",
    "Ervalia",
    "Escada",
    "Esmeraldas",
    "Espera Feliz",
    "Esperanca",
    "Esperanca",
    "Esperantina",
    "Espinosa",
    "Espirito Santo",
    "Espirito Santo do Pinhal",
    "Espumoso",
    "Estancia",
    "Estancia Nova",
    "Estancia Velha",
    "Esteio",
    "Estiva",
    "Estreito",
    "Estrela",
    "Eugenio",
    "Eugenopolis",
    "Eusebio",
    "Extrema",
    "Extremoz",
    "Exu",
    "Farroupilha",
    "Fartura",
    "Fatima",
    "Faxinal",
    "Faxinal do Soturno",
    "Faxinal dos Guedes",
    "Fazenda Rio Grande",
    "Feira de Santana",
    "Feira Nova",
    "Feliz",
    "Fenix",
    "Fernando Prestes",
    "Fernandopolis",
    "Ferraz de Vasconcelos",
    "Flexeiras",
    "Florania",
    "Flores",
    "Flores da Cunha",
    "Floresta Azul",
    "Floriano",
    "Florinia",
    "Formiga",
    "Formosa",
    "Forquilha",
    "Forquilha",
    "Forquilhinha",
    "Fortaleza",
    "Fortaleza",
    "Fortaleza",
    "Fortuna",
    "Foz do Jordao",
    "Fragoso",
    "Franca",
    "Francisco Beltrao",
    "Francisco Morato",
    "Francisco Sa",
    "Franco da Rocha",
    "Frederico Westphalen",
    "Frei Paulo",
    "Freitas",
    "Fronteira",
    "Frutal",
    "Fundao",
    "Gameleira",
    "Gandu",
    "Garanhuns",
    "Garca",
    "Gardenia Azul",
    "Garibaldi",
    "Garimpo Novo",
    "Garopaba",
    "Garuva",
    "Gaspar",
    "Gaviao Peixoto",
    "General Carneiro",
    "General Salgado",
    "Getulio Vargas",
    "Girau do Ponciano",
    "Gloria",
    "Gloria do Goita",
    "Glorinha",
    "Goiana",
    "Goianapolis",
    "Goianesia",
    "Goianinha",
    "Goianira",
    "Goias",
    "Gomes",
    "Goncalves",
    "Governador Valadares",
    "Graca Aranha",
    "Grajau",
    "Gramado",
    "Granito",
    "Granja",
    "Grao Mogol",
    "Groairas",
    "Guacui",
    "Guai",
    "Guaianases",
    "Guaicara",
    "Guaira",
    "Guaira",
    "Guajeru",
    "Guamare",
    "Guanambi",
    "Guanhaes",
    "Guapiacu",
    "Guapimirim",
    "Guapo",
    "Guapore",
    "Guara",
    "Guara",
    "Guarabira",
    "Guaraci",
    "Guaraciaba",
    "Guaragi",
    "Guaramirim",
    "Guaranesia",
    "Guarani dOeste",
    "Guaranta",
    "Guarapari",
    "Guarapuava",
    "Guararapes",
    "Guararapes",
    "Guararema",
    "Guaratingueta",
    "Guaratuba",
    "Guarei",
    "Guariba",
    "Guaruja",
    "Guarulhos",
    "Guaxupe",
    "Gurjao",
    "Gurupi",
    "Heliodora",
    "Heliopolis",
    "Horizontina",
    "Iacanga",
    "Iacu",
    "Ibaiti",
    "Ibate",
    "Ibatiba",
    "Ibiaca",
    "Ibicoara",
    "Ibipeba",
    "Ibipora",
    "Ibirama",
    "Ibirarema",
    "Ibirataia",
    "Ibirite",
    "Ibiruba",
    "Ibitinga",
    "Ibiuna",
    "Icapui",
    "Icara",
    "Icarai",
    "Icem",
    "Ico",
    "Icoraci",
    "Igaracu do Tiete",
    "Igarapava",
    "Igarape",
    "Igarassu",
    "Igarata",
    "Igreja Nova",
    "Igrejinha",
    "Iguaba Grande",
    "Iguai",
    "Iguape",
    "Iguaracu",
    "Iguatu",
    "Ijui",
    "Ilha Solteira",
    "Ilhabela",
    "Ilhota",
    "Imbituba",
    "Imbituva",
    "Imperatriz",
    "Imperatriz",
    "Indaial",
    "Indaiatuba",
    "Indianopolis",
    "Inga",
    "Inhacora",
    "Inhangapi",
    "Inhauma",
    "Inhumas",
    "Inocencia",
    "Inubia Paulista",
    "Ipaba",
    "Ipameri",
    "Ipatinga",
    "Ipaucu",
    "Ipero",
    "Ipeuna",
    "Ipiau",
    "Ipira",
    "Ipiranga",
    "Ipojuca",
    "Ipora",
    "Ipu",
    "Ipua",
    "Ipubi",
    "Ipumirim",
    "Iracema",
    "Iracemapolis",
    "Iraceminha",
    "Irai",
    "Iranduba",
    "Irati",
    "Irece",
    "Iretama",
    "Irupi",
    "Itabaiana",
    "Itabaiana",
    "Itabaianinha",
    "Itabapoana",
    "Itaberaba",
    "Itabira",
    "Itabirinha de Mantena",
    "Itabirito",
    "Itaborai",
    "Itabuna",
    "Itacare",
    "Itaete",
    "Itagi",
    "Itagiba",
    "Itaguacu",
    "Itaguai",
    "Itaguatins",
    "Itaipava",
    "Itaipu",
    "Itaitinga",
    "Itaituba",
    "Itajobi",
    "Itajuba",
    "Itajuipe",
    "Italva",
    "Itamaraca",
    "Itamarati",
    "Itambaraca",
    "Itambe",
    "Itamogi",
    "Itamonte",
    "Itanhaem",
    "Itanhandu",
    "Itanhomi",
    "Itaocara",
    "Itapaci",
    "Itapagipe",
    "Itaparica",
    "Itape",
    "Itapecerica",
    "Itapecerica da Serra",
    "Itapecuru Mirim",
    "Itapejara",
    "Itapema",
    "Itaperuna",
    "Itapetinga",
    "Itapetininga",
    "Itapeva",
    "Itapevi",
    "Itapicuru",
    "Itapipoca",
    "Itapira",
    "Itapiranga",
    "Itapissuma",
    "Itapixuna",
    "Itapo",
    "Itapolis",
    "Itaporanga",
    "Itapua",
    "Itapuranga",
    "Itaquaquecetuba",
    "Itaqui",
    "Itarantim",
    "Itarare",
    "Itatiaia",
    "Itatiba",
    "Itatinga",
    "Itatira",
    "Itau de Minas",
    "Itauna",
    "Itinga da Serra",
    "Itirapina",
    "Itiuba",
    "Itobi",
    "Itororo",
    "Itu",
    "Itubera",
    "Ituiutaba",
    "Itumbiara",
    "Ituporanga",
    "Iturama",
    "Ituverava",
    "Iuna",
    "Ivaipora",
    "Ivoti",
    "Jaboatao dos Guararapes",
    "Jabora",
    "Jaborandi",
    "Jaboticabal",
    "Jaboticatubas",
    "Jacarau",
    "Jacarepagua",
    "Jacarezinho",
    "Jaci",
    "Jaciara",
    "Jacobina",
    "Jacuizinho",
    "Jacupiranga",
    "Jacutinga",
    "Jaguapita",
    "Jaguaquara",
    "Jaguarao",
    "Jaguaras",
    "Jaguariaiva",
    "Jaguaribe",
    "Jaguaripe",
    "Jaguariuna",
    "Jaguaruana",
    "Jaguaruna",
    "Jaiba",
    "Jales",
    "Jampruca",
    "Janauba",
    "Jandaia do Sul",
    "Jandira",
    "Japaratinga",
    "Japeri",
    "Japira",
    "Jardim Alegre",
    "Jardim de Piranhas",
    "Jardinopolis",
    "Jarinu",
    "Jaru",
    "Jatai",
    "Jataizinho",
    "Jequitinhonha",
    "Jerico",
    "Jeronimo Monteiro",
    "Jesuania",
    "Jetiba",
    "Ji Parana",
    "Jiquirica",
    "Joacaba",
    "Joanopolis",
    "Joao Camara",
    "Joao Lisboa",
    "Joao Monlevade",
    "Joao Neiva",
    "Joao Pinheiro",
    "Joaquim",
    "Joaquim Tavora",
    "Joinville",
    "Jose Boiteux",
    "Jose Bonifacio",
    "Jose de Freitas",
    "Juatuba",
    "Juazeiro",
    "Juazeiro do Norte",
    "Jucara",
    "Jucurutu",
    "Juiz de Fora",
    "Julio de Castilhos",
    "Julio Mesquita",
    "Jumirim",
    "Jundiai do Sul",
    "Junqueiro",
    "Junqueiropolis",
    "Juquia",
    "Juquitiba",
    "Juripiranga",
    "Juru",
    "Juruaia",
    "Juruena",
    "Juruti",
    "Jussara",
    "Jussari",
    "Ladario",
    "Lagarto",
    "Lages",
    "Lagoa",
    "Lagoa da Canoa",
    "Lagoa da Prata",
    "Lagoa do Carro",
    "Lagoa dos Gatos",
    "Lagoa Salgada",
    "Lagoa Santa",
    "Lagoa Seca",
    "Lagoa Vermelha",
    "Laguna",
    "Laguna Carapa",
    "Lajeado",
    "Lajinha",
    "Lambari",
    "Lambari",
    "Lapa",
    "Lapa",
    "Laranjal Paulista",
    "Laranjeiras",
    "Laranjeiras do Sul",
    "Lauro",
    "Lavinia",
    "Lavras",
    "Lavras da Mangabeira",
    "Lavras do Sul",
    "Lavrinhas",
    "Lebon Regis",
    "Leme",
    "Lencois",
    "Lencois Paulista",
    "Leopoldina",
    "Liberdade",
    "Lima Campos",
    "Limeira",
    "Limoeiro",
    "Limoeiro do Norte",
    "Linhares",
    "Lins",
    "Loanda",
    "Londrina",
    "Lontra",
    "Lorena",
    "Louveira",
    "Lucena",
    "Luis Antonio",
    "Luis Antonio",
    "Luis Correia",
    "Lumiar",
    "Luminarias",
    "Luz",
    "Luzerna",
    "Luziania",
    "Macaiba",
    "Macaparana",
    "Macatuba",
    "Macau",
    "Macaubas",
    "Macedonia",
    "Machadinho",
    "Machado",
    "Machados",
    "Macuco",
    "Madre de Deus",
    "Mafra",
    "Mage",
    "Mairi",
    "Mairinque",
    "Malacacheta",
    "Mamanguape",
    "Mambore",
    "Mamonas",
    "Manacapuru",
    "Manaus",
    "Mandaguacu",
    "Mandaguari",
    "Mandirituba",
    "Manga",
    "Mangabeira",
    "Mangaratiba",
    "Mangueirinha",
    "Manhuacu",
    "Manhumirim",
    "Mantena",
    "Mantenopolis",
    "Mar de Espanha",
    "Maracaju",
    "Maracana",
    "Maracana",
    "Maracanau",
    "Maragogipe",
    "Maraial",
    "Maranguape",
    "Marataizes",
    "Marau",
    "Maravilha",
    "Marcelino Ramos",
    "Marcelino Vieira",
    "Marco",
    "Marcolandia",
    "Marechal Candido Rondon",
    "Marechal Deodoro",
    "Maria da Fe",
    "Marialva",
    "Mariana",
    "Marica",
    "Marilandia",
    "Mariluz",
    "Marituba",
    "Marques",
    "Martinopolis",
    "Maruim",
    "Marumbi",
    "Marzagao",
    "Massape",
    "Massaranduba",
    "Matao",
    "Matelandia",
    "Mateus Leme",
    "Matina",
    "Matinhos",
    "Matipo",
    "Mato Grosso",
    "Matozinhos",
    "Matutina",
    "Maua",
    "Maxaranguape",
    "Medeiros Neto",
    "Medianeira",
    "Meier",
    "Mendes",
    "Mendes Pimentel",
    "Mendonca",
    "Mesquita",
    "Messias",
    "Miguel Pereira",
    "Miguelopolis",
    "Milagres",
    "Minas",
    "Mineiros",
    "Mineiros do Tiete",
    "Miracatu",
    "Miracema",
    "Miracema do Tocantins",
    "Miraima",
    "Miranda",
    "Miranda",
    "Mirandopolis",
    "Mirassol",
    "Mirim Doce",
    "Mirinzal",
    "Missao Velha",
    "Mococa",
    "Moema",
    "Mogeiro",
    "Mogi das Cruzes",
    "Mogi Mirim",
    "Mogi-Gaucu",
    "Mongagua",
    "Monte Alegre",
    "Monte Alegre",
    "Monte Alto",
    "Monte Aprazivel",
    "Monte Azul",
    "Monte Azul Paulista",
    "Monte Belo",
    "Monte Carmelo",
    "Monte Dourado",
    "Monte Mor",
    "Monte Santo",
    "Monte Siao",
    "Monteiro",
    "Monteiro Lobato",
    "Montenegro",
    "Montes Altos",
    "Montes Claros",
    "Montividiu",
    "Morada Nova",
    "Moreno",
    "Morretes",
    "Morrinhos",
    "Morrinhos",
    "Morro Agudo",
    "Morro da Fumaca",
    "Morro de Sao Paulo",
    "Morro do Chapeu",
    "Morro Reuter",
    "Morungaba",
    "Mossoro",
    "Mostardas",
    "Mucuri",
    "Muliterno",
    "Munhoz",
    "Muniz Ferreira",
    "Muniz Freire",
    "Muqui",
    "Muritiba",
    "Mutum",
    "Mutum",
    "Muzambinho",
    "Nanuque",
    "Nao Me Toque",
    "Narandiba",
    "Natal",
    "Natercia",
    "Navegantes",
    "Navirai",
    "Nazare",
    "Nazare da Mata",
    "Nazare Paulista",
    "Neopolis",
    "Neropolis",
    "Nilo Pecanha",
    "Nilopolis",
    "Niquelandia",
    "Niteroi",
    "Niteroi",
    "Nobres",
    "Norte",
    "Nossa Senhora",
    "Nossa Senhora da Gloria",
    "Nossa Senhora Das Gracas",
    "Nossa Senhora do Socorro",
    "Nova",
    "Nova",
    "Nova Alianca",
    "Nova Araca",
    "Nova Aurora",
    "Nova Bassano",
    "Nova Brescia",
    "Nova Canaa",
    "Nova Cruz",
    "Nova Esperanca",
    "Nova Esperanca",
    "Nova Friburgo",
    "Nova Independencia",
    "Nova Itarana",
    "Nova Lima",
    "Nova Londrina",
    "Nova Odessa",
    "Nova Palma",
    "Nova Petropolis",
    "Nova Ponte",
    "Nova Prata",
    "Nova Resende",
    "Nova Serrana",
    "Nova Timboteua",
    "Nova Trento",
    "Nova Venecia",
    "Novo Hamburgo",
    "Novo Horizonte",
    "Novo Mundo",
    "Novo Oriente",
    "Novo Recreio",
    "Nucleo Bandeirante",
    "Nunes Freire",
    "Nuporanga",
    "Ocara",
    "Oeiras",
    "Olimpia",
    "Olinda",
    "Oliveira",
    "Oratorios",
    "Orlandia",
    "Orleans",
    "Orobo",
    "Osasco",
    "Osorio",
    "Otacilio Costa",
    "Ouricangas",
    "Ouricuri",
    "Ourinhos",
    "Ouro Branco",
    "Ouro Fino",
    "Ouro Preto",
    "Ouro Preto do Oeste",
    "Ouro Verde",
    "Ouvidor",
    "Pacaembu",
    "Pacatuba",
    "Pachecos",
    "Padre",
    "Paicandu",
    "Palestina",
    "Palestina",
    "Palhoca",
    "Palma",
    "Palmares",
    "Palmares do Sul",
    "Palmas",
    "Palmas",
    "Palmeira",
    "Palmeira",
    "Palmeira das Missoes",
    "Palmeira dos Indios",
    "Palmeiras de Goias",
    "Palmelo",
    "Palmital",
    "Palmital",
    "Palmitinho",
    "Palmitos",
    "Palotina",
    "Panambi",
    "Pancas",
    "Panorama",
    "Pao de Acucar",
    "Papagaios",
    "Para de Minas",
    "Paracambi",
    "Paracuru",
    "Paragominas",
    "Paraguacu",
    "Paraguacu Paulista",
    "Parai",
    "Paraiba do Sul",
    "Paraibano",
    "Paraibuna",
    "Paraipaba",
    "Paraiso",
    "Paraiso",
    "Paraiso do Tocantins",
    "Paraisopolis",
    "Paraitinga",
    "Parana",
    "Parana",
    "Parana",
    "Paranaiba",
    "Paranapanema",
    "Paranavai",
    "Paranoa",
    "Paraopeba",
    "Parapeuna",
    "Paraty",
    "Parelhas",
    "Pariquera Acu",
    "Parnaiba",
    "Parnamirim",
    "Parnamirim",
    "Parobe",
    "Passo Fundo",
    "Passos",
    "Pastos Bons",
    "Pato Branco",
    "Patos",
    "Patos de Minas",
    "Patrocinio",
    "Patrocinio Paulista",
    "Pau Brasil",
    "Pau dos Ferros",
    "Pauini",
    "Pauliceia",
    "Paulinia",
    "Paulista",
    "Paulista",
    "Paulo Afonso",
    "Paulo Jacinto",
    "Paulo Lopes",
    "Paverama",
    "Peabiru",
    "Pecem",
    "Pederneiras",
    "Pedra Bonita",
    "Pedra Grande",
    "Pedranopolis",
    "Pedreira",
    "Pedreira",
    "Pedro Afonso",
    "Pedro Avelino",
    "Pedro de Toledo",
    "Pedro Leopoldo",
    "Pelotas",
    "Pena",
    "Penapolis",
    "Pendencias",
    "Penedo",
    "Penha",
    "Perdigao",
    "Perdoes",
    "Pereira Barreto",
    "Pereiro",
    "Peruibe",
    "Pesqueira",
    "Petrolina",
    "Piabeta",
    "Picada",
    "Picos",
    "Picui",
    "Piedade",
    "Piedade do Rio Grande",
    "Piedade dos Gerais",
    "Pien",
    "Pilao Arcado",
    "Pilar do Sul",
    "Pimenta",
    "Pimenta Bueno",
    "Pindamonhangaba",
    "Pindobacu",
    "Pindoretama",
    "Pinhais",
    "Pinhalao",
    "Pinhalzinho",
    "Pinheiral",
    "Pinhoes",
    "Piquet Carneiro",
    "Piracaia",
    "Piracanjuba",
    "Piracema",
    "Piracicaba",
    "Pirai",
    "Pirai do Sul",
    "Piraju",
    "Pirajuba",
    "Pirajui",
    "Pirangi",
    "Pirapora do Bom Jesus",
    "Pirapozinho",
    "Piraquara",
    "Pirassununga",
    "Piratininga",
    "Piritiba",
    "Pitanga",
    "Pitangueiras",
    "Pitangueiras",
    "Pitangui",
    "Piuma",
    "Planalto",
    "Planalto",
    "Platina",
    "Poa",
    "Poco Branco",
    "Poco das Antas",
    "Poco Verde",
    "Pojuca",
    "Pombal",
    "Pomerode",
    "Pompeia",
    "Pompeu",
    "Pongai",
    "Ponta Grossa",
    "Ponta Pora",
    "Pontal",
    "Pontal do Parana",
    "Pontalina",
    "Ponte de Itabapoana",
    "Ponte Nova",
    "Ponte Serrada",
    "Pontes e Lacerda",
    "Porangatu",
    "Porecatu",
    "Portao",
    "Porteira do Pinhal",
    "Porto",
    "Porto Alegre",
    "Porto Amazonas",
    "Porto Belo",
    "Porto Esperidiao",
    "Porto Feliz",
    "Porto Ferreira",
    "Porto Grande",
    "Porto Nacional",
    "Porto Real",
    "Porto Sao Jose Velho",
    "Porto Seguro",
    "Porto Uniao",
    "Porto Velho",
    "Porto Velho",
    "Posse",
    "Posto Fiscal Rolim de Moura",
    "Pote",
    "Potirendaba",
    "Pouso Alegre",
    "Pradopolis",
    "Praia Grande",
    "Prata",
    "Pratania",
    "Pratapolis",
    "Presidente Bernardes",
    "Presidente Dutra",
    "Presidente Epitacio",
    "Presidente Getulio",
    "Presidente Kennedy",
    "Presidente Medici",
    "Presidente Olegario",
    "Presidente Prudente",
    "Presidente Venceslau",
    "Primavera",
    "Primeiro de Maio",
    "Progresso",
    "Progresso",
    "Promissao",
    "Propria",
    "Prudentopolis",
    "Putinga",
    "Puxinana",
    "Quarai",
    "Quata",
    "Quatigua",
    "Quatis",
    "Quatro Barras",
    "Quatro Pontes",
    "Quebrangulo",
    "Queimadas",
    "Queimados",
    "Querencia",
    "Querencia do Norte",
    "Quilombo",
    "Quipapa",
    "Quirinopolis",
    "Quissama",
    "Quitandinha",
    "Quixada",
    "Quixeramobim",
    "Quixere",
    "Rafard",
    "Rancharia",
    "Rancho Queimado",
    "Raposa (1)",
    "Raposos",
    "Raul Soares",
    "Realeza",
    "Reboucas",
    "Recanto",
    "Recife",
    "Redencao",
    "Redencao",
    "Reduto",
    "Registro",
    "Remigio",
    "Renascenca",
    "Repartimento",
    "Resende",
    "Resende Costa",
    "Restinga",
    "Restinga Seca",
    "Riachao do Jacuipe",
    "Riacho das Almas",
    "Riacho Fundo",
    "Riacho Fundo",
    "Rialma",
    "Ribas do Rio Pardo",
    "Ribeira do Pombal",
    "Ribeirao",
    "Ribeirao Bonito",
    "Ribeirao Corrente",
    "Ribeirao do Pinhal",
    "Ribeirao Pires",
    "Rio Acima",
    "Rio Azul",
    "Rio Bananal",
    "Rio Bonito",
    "Rio Bonito",
    "Rio Branco",
    "Rio Branco",
    "Rio Branco do Sul",
    "Rio Brilhante",
    "Rio Casca",
    "Rio Claro",
    "Rio Claro",
    "Rio das Ostras",
    "Rio das Pedras",
    "Rio de Janeiro",
    "Rio do Pires",
    "Rio do Sul",
    "Rio dos Cedros",
    "Rio Formoso",
    "Rio Grande",
    "Rio Grande da Serra",
    "Rio Largo",
    "Rio Manso",
    "Rio Negrinho",
    "Rio Negro",
    "Rio Novo",
    "Rio Novo do Sul",
    "Rio Paranaiba",
    "Rio Pardo",
    "Rio Pardo de Minas",
    "Rio Piracicaba",
    "Rio Tinto",
    "Rio Verde de Mato Grosso",
    "Roca Sales",
    "Rodeio Bonito",
    "Rodeiro",
    "Rodrigues",
    "Rolandia",
    "Rolante",
    "Ronda Alta",
    "Rondon",
    "Rosalia",
    "Rosario",
    "Rosario do Sul",
    "Roseira",
    "Rubiataba",
    "Rubim",
    "Russas",
    "Sabara",
    "Sabaudia",
    "Sabinopolis",
    "Sacramento",
    "Sales",
    "Sales Oliveira",
    "Salesopolis",
    "Salgado",
    "Salgueiro",
    "Salinas da Margarida",
    "Salto",
    "Salto",
    "Salto de Pirapora",
    "Salto Grande",
    "Salvador",
    "Salvador",
    "Salvador",
    "Samambaia",
    "Sampaio Correia",
    "Sananduva",
    "Sangao",
    "Sanharo",
    "Santa",
    "Santa Adelia",
    "Santa Barbara",
    "Santa Barbara do Sul",
    "Santa Branca",
    "Santa Cruz",
    "Santa Cruz Cabralia",
    "Santa Cruz da Baixa Verde",
    "Santa Cruz do Capibaribe",
    "Santa Cruz do Rio Pardo",
    "Santa Cruz do Sul",
    "Santa Fe",
    "Santa Fe do Sul",
    "Santa Gertrudes",
    "Santa Helena",
    "Santa Helena",
    "Santa Ines",
    "Santa Isabel",
    "Santa Isabel do Ivai",
    "Santa Isabel do Para",
    "Santa Leopoldina",
    "Santa Lucia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria da Vitoria",
    "Santa Maria de Itabira",
    "Santa Rita",
    "Santa Rita do Passa Quatro",
    "Santa Rita do Sapucai",
    "Santa Rosa",
    "Santa Teresa",
    "Santa Teresa",
    "Santa Teresinha",
    "Santa Terezinha",
    "Santana",
    "Santana da Vargem",
    "Santana de Parnaiba",
    "Santana do Cariri",
    "Santana do Ipanema",
    "Santana do Livramento",
    "Santana do Matos",
    "Santana do Paraiso",
    "Santarem Novo",
    "Santiago",
    "Santo",
    "Santo Aleixo",
    "Santo Amaro",
    "Santo Amaro",
    "Santo Amaro da Imperatriz",
    "Santo Anastacio",
    "Santo Antonio",
    "Santo Antonio da Patrulha",
    "Santo Antonio da Platina",
    "Santo Antonio de Jesus",
    "Santo Antonio de Lisboa",
    "Santo Antonio de Padua",
    "Santo Antonio de Posse",
    "Santo Antonio do Amparo",
    "Santo Antonio do Caiua",
    "Santo Antonio do Grama",
    "Santo Antonio do Jardim",
    "Santo Antonio do Leverger",
    "Santo Antonio do Monte",
    "Santo Antonio do Pinhal",
    "Santo Antonio do Retiro",
    "Santo Estevao",
    "Santo Expedito",
    "Santo Inacio",
    "Santos",
    "Santos Dumont",
    "Sao Bento",
    "Sao Bento",
    "Sao Bento do Sapucai",
    "Sao Bernardo",
    "Sao Caetano",
    "Sao Caetano do Sul",
    "Sao Carlos",
    "Sao Cristovao",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Felipe",
    "Sao Felix",
    "Sao Fidelis",
    "Sao Francisco",
    "Sao Francisco",
    "Sao Francisco de Paula",
    "Sao Francisco do Conde",
    "Sao Francisco do Sul",
    "Sao Gabriel",
    "Sao Gabriel",
    "Sao Gabriel",
    "Sao Gabriel da Cachoeira",
    "Sao Geraldo do Araguaia",
    "Sao Goncalo",
    "Sao Goncalo do Amarante",
    "Sao Goncalo do Para",
    "Sao Goncalo do Sapucai",
    "Sao Goncalo dos Campos",
    "Sao Gotardo",
    "Sao Jeronimo",
    "Sao Joao",
    "Sao Joao Batista",
    "Sao Joao da Barra",
    "Sao Joao da Boa Vista",
    "Sao Joao de Meriti",
    "Sao Joao de Pirabas",
    "Sao Joao do Paraiso",
    "Sao Joao Evangelista",
    "Sao Joao Nepomuceno",
    "Sao Joaquim",
    "Sao Joaquim da Barra",
    "Sao Joaquim de Bicas",
    "Sao Joaquim do Monte",
    "Sao Jose",
    "Sao Jose",
    "Sao Jose",
    "Sao Jose da Barra",
    "Sao Jose da Laje",
    "Sao Jose de Mipibu",
    "Sao Jose de Piranhas",
    "Sao Jose de Ribamar",
    "Sao Jose do Calcado",
    "Sao Jose do Cedro",
    "Sao Jose do Egito",
    "Sao Jose do Goiabal",
    "Sao Jose do Inhacora",
    "Sao Jose do Ouro",
    "Sao Jose do Rio Pardo",
    "Sao Jose do Rio Preto",
    "Sao Lourenco da Mata",
    "Sao Lourenco da Serra",
    "Sao Lourenco do Sul",
    "Sao Lourenco dOeste",
    "Sao Luis",
    "Sao Luis de Montes Belos",
    "Sao Luis do Paraitinga",
    "Sao Luiz Gonzaga",
    "Sao Manoel",
    "Sao Manuel",
    "Sao Marcos",
    "Sao Miguel",
    "Sao Miguel",
    "Sao Miguel Arcanjo",
    "Sao Miguel do Araguaia",
    "Sao Miguel do Iguacu",
    "Sao Miguel dos Campos",
    "Sao Paulo",
    "Sao Paulo",
    "Sao Paulo do Potengi",
    "Sao Pedro",
    "Sao Pedro da Aldeia",
    "Sao Pedro do Ivai",
    "Sao Pedro do Sul",
    "Sao Pedro dos Ferros",
    "Sao Roque",
    "Sao Roque",
    "Sao Salvador",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao da Amoreira",
    "Sao Sebastiao da Grama",
    "Sao Sebastiao do Cai",
    "Sao Sebastiao do Maranhao",
    "Sao Sebastiao do Paraiso",
    "Sao Sepe",
    "Sao Simao",
    "Sao Tome",
    "Sao Vicente",
    "Sao Vicente",
    "Sao Vicente de Paula",
    "Sape",
    "Sapeacu",
    "Sapiranga",
    "Sapucaia",
    "Sapucaia",
    "Sapucaia do Sul",
    "Saquarema",
    "Sarandi",
    "Sarandi",
    "Sarapui",
    "Sardoa",
    "Sarzedo",
    "Satuba",
    "Saudades",
    "Schroeder",
    "Seabra",
    "Seara",
    "Sebastianopolis do Sul",
    "Seberi",
    "Sede Nova",
    "Sem Peixe",
    "Senador Canedo",
    "Senhor do Bonfim",
    "Serafina",
    "Seropedica",
    "Serra",
    "Serra",
    "Serra",
    "Serra",
    "Serra Azul",
    "Serra Negra",
    "Serra Preta",
    "Serra Talhada",
    "Serrana",
    "Serrania",
    "Serrinha",
    "Serro",
    "Sertania",
    "Sertanopolis",
    "Sertao",
    "Sertaozinho",
    "Sete Lagoas",
    "Sidrolandia",
    "Silva Jardim",
    "Silveiras",
    "Simao Dias",
    "Simao Pereira",
    "Simoes",
    "Simplicio Mendes",
    "Siqueira Campos",
    "Sitio Figueira",
    "Sitio Novo",
    "Sobradinho",
    "Sobral",
    "Socorro",
    "Soledade",
    "Sombrio",
    "Sorocaba",
    "Sorriso",
    "Sousa",
    "Stio Ouro Fino",
    "Sulina",
    "Sumare",
    "Suzano",
    "Tabatinga",
    "Tabira",
    "Taboao da Serra",
    "Tabuleiro do Norte",
    "Taguatinga",
    "Taguatinga",
    "Taiacu",
    "Taiobeiras",
    "Taiuva",
    "Tamandare",
    "Tambau",
    "Tamboril",
    "Tamoios",
    "Tanabi",
    "Tangua",
    "Tanque Novo",
    "Tapejara",
    "Tapira",
    "Tapirai",
    "Taquara",
    "Taquaral",
    "Taquarana",
    "Taquari",
    "Taquaritinga",
    "Tarumirim",
    "Tatuamunha",
    "Taua",
    "Taubate",
    "Teixeira",
    "Teixeira",
    "Telemaco Borba",
    "Tenente Ananias",
    "Tenorio",
    "Teodoro Sampaio",
    "Terenos",
    "Teresina",
    "Teresopolis",
    "Terra Boa",
    "Terra Nova",
    "Terra Nova",
    "Terra Rica",
    "Terra Roxa",
    "Terra Roxa",
    "Teutonia",
    "Tiangua",
    "Tibagi",
    "Tibau",
    "Tiete",
    "Tijuca",
    "Tijucas",
    "Timbauba",
    "Timbe",
    "Timbo",
    "Timon",
    "Timoteo",
    "Tiradentes",
    "Tobias Barreto",
    "Tocantins",
    "Toledo",
    "Tomar do Geru",
    "Tome Acu",
    "Toritama",
    "Torres",
    "Touros",
    "Tramandai",
    "Tremembe",
    "Tres Barras",
    "Tres Cachoeiras",
    "Tres Coroas",
    "Tres Marias",
    "Tres Passos",
    "Tres Pontas",
    "Tres Rios",
    "Trindade",
    "Trindade",
    "Triunfo",
    "Tubarao",
    "Tucum",
    "Tucunduva",
    "Tucurui",
    "Tuiuti",
    "Tumiritinga",
    "Tupa",
    "Tupaciguara",
    "Tupanatinga",
    "Tupancireta",
    "Tuparendi",
    "Tupi Paulista",
    "Turmalina",
    "Turvo",
    "Turvo",
    "Uaua",
    "Uba",
    "Ubaira",
    "Ubaitaba",
    "Ubarana",
    "Ubata",
    "Ubatuba",
    "Uberaba",
    "Uberlandia",
    "Uchoa",
    "Uibai",
    "Uirauna",
    "Umbauba",
    "Umbuzeiro",
    "Umirim",
    "Umuarama",
    "Unai",
    "Uniao da Vitoria",
    "Urai",
    "Urania",
    "Urubici",
    "Uruburetama",
    "Urucania",
    "Urucuca",
    "Uruguaiana",
    "Urupes",
    "Urussanga",
    "Vacaria",
    "Valenca",
    "Valente",
    "Valentim Gentil",
    "Valerio",
    "Valinhos",
    "Valparaiso",
    "Vargem Alta",
    "Vargem Grande do Sul",
    "Vargem Grande Paulista",
    "Varginha",
    "Varjota",
    "Varzea",
    "Varzea Paulista",
    "Varzelandia",
    "Vassouras",
    "Velha",
    "Venancio Aires",
    "Venda Nova",
    "Vera Cruz",
    "Vera Cruz",
    "Vera Cruz",
    "Vera Cruz",
    "Veranopolis",
    "Vespasiano",
    "Vespasiano Correa",
    "Viana",
    "Vicencia",
    "Vicente",
    "Vicosa",
    "Vicosa",
    "Vicosa do Ceara",
    "Videira",
    "Vila Maria",
    "Vila Muriqui",
    "Vila Rica",
    "Vila Velha",
    "Vilar dos Teles",
    "Vilhena",
    "Vinhedo",
    "Virgem da Lapa",
    "Virginia",
    "Virginopolis",
    "Virmond",
    "Visconde do Rio Branco",
    "Vista Alegre do Alto",
    "Vitoria de Santo Antao",
    "Vitorino Freire",
    "Volta Grande",
    "Volta Redonda",
    "Votorantim",
    "Votuporanga",
    "Wenceslau Braz",
    "Witmarsum",
    "Xanxere",
    "Xavantina",
    "Xaxim",
  ],
  Brunei: [
    "Bandar Seri Begawan",
    "Bangar",
    "Kampong Jerudong",
    "Kuala Balai",
    "Kuala Belait",
    "Panaga",
    "Seria",
    "Tutong",
  ],
  Bulgaria: [
    "Akhtopol",
    "Aksakovo",
    "Aleksandriya",
    "Alfatar",
    "Anton",
    "Antonovo",
    "Ardino",
    "Asenovgrad",
    "Aytos",
    "Babovo",
    "Baltchik",
    "Banite",
    "Bankya",
    "Bansko",
    "Barutin",
    "Batak",
    "Belene",
    "Belogradchik",
    "Beloslav",
    "Belovo",
    "Berkovitsa",
    "Bistritsa",
    "Blagoevgrad",
    "Boboshevo",
    "Bobovdol",
    "Borovan",
    "Borovo",
    "Botevgrad",
    "Bratsigovo",
    "Bregare",
    "Bukovo",
    "Bukovo",
    "Burgas",
    "Byala",
    "Byala",
    "Byala Slatina",
    "Chakalarovo",
    "Chernolik",
    "Chernomorets",
    "Cherven",
    "Cherven Bryag",
    "Chiprovtsi",
    "Chirpan",
    "Choba",
    "Debelets",
    "Devin",
    "Dimitrovgrad",
    "Dobrich",
    "Dolna Banya",
    "Dolna Mitropoliya",
    "Dolna Oryakhovitsa",
    "Dospat",
    "Dragash Voyvoda",
    "Draginovo",
    "Dragizhevo",
    "Dryanovo",
    "Dulgopol",
    "Dulovo",
    "Dupnitsa",
    "Elena",
    "Elin Pelin",
    "Elkhovo",
    "Etropole",
    "Gabrovo",
    "Ganchovo",
    "General-Toshevo",
    "Glogovo",
    "Godech",
    "Gorna Oryakhovitsa",
    "Gotse Delchev",
    "Gulubovo",
    "Gurkovo",
    "Gurmazovo",
    "Haskovo",
    "Ikhtiman",
    "Iskrets",
    "Isperikh",
    "Isperikhovo",
    "Ivaylovgrad",
    "Kableshkovo",
    "Kamenovo",
    "Kardzhali",
    "Karlovo",
    "Karnobat",
    "Kavarna",
    "Kazanlak",
    "Kazashka Reka",
    "Kharmanli",
    "Khisarya",
    "Knezha",
    "Konush",
    "Koprivets",
    "Koprivlen",
    "Kostenets",
    "Kostievo",
    "Kostinbrod",
    "Kovachite",
    "Kozloduy",
    "Kran",
    "Krichim",
    "Krivodol",
    "Krumovgrad",
    "Krupnik",
    "Kula",
    "Kyustendil",
    "Lom",
    "Lovech",
    "Lozno",
    "Lukovit",
    "Lyaskovets",
    "Lyulyakovo",
    "Madan",
    "Malo Konare",
    "Marikostinovo",
    "Markovo",
    "Mesta",
    "Mezdra",
    "Mikrevo",
    "Moderno Predgradie",
    "Momchilgrad",
    "Montana",
    "Moravka",
    "Muglizh",
    "Nesebar",
    "Nikopol",
    "Nova Zagora",
    "Novi Iskur",
    "Novi Pazar",
    "Obzor",
    "Omurtag",
    "Orizovo",
    "Orlovets",
    "Oryakhovets",
    "Oryakhovitsa",
    "Ovoshtnik",
    "Panagyurishte",
    "Pavel Banya",
    "Pavlikeni",
    "Pazardzhik",
    "Pchelishte",
    "Pernik",
    "Peshtera",
    "Petrich",
    "Pleven",
    "Plovdiv",
    "Poleto",
    "Polski Trumbesh",
    "Pomorie",
    "Popovo",
    "Pravets",
    "Prespa",
    "Provadiya",
    "Radnevo",
    "Raduil",
    "Rakitovo",
    "Rakovski",
    "Ravda",
    "Razgrad",
    "Razlog",
    "Rila",
    "Rodopi",
    "Rousse",
    "Rozino",
    "Rudozem",
    "Ruen",
    "Samokov",
    "Samovodene",
    "Samuil",
    "Sandanski",
    "Sandrovo",
    "Septemvri",
    "Sevlievo",
    "Shabla",
    "Shivachevo",
    "Shumen",
    "Silistra",
    "Simeonovgrad",
    "Simitli",
    "Skravena",
    "Slatina",
    "Sliven",
    "Slivnitsa",
    "Smolyan",
    "Smyadovo",
    "Sofia",
    "Sofronievo",
    "Sokolets",
    "Sopot",
    "Sozopol",
    "Sredets",
    "Stamboliyski",
    "Stara Zagora",
    "Strazhitsa",
    "Strelcha",
    "Suedinenie",
    "Svilengrad",
    "Svishtov",
    "Svoge",
    "Targovishte",
    "Tervel",
    "Tochilari",
    "Topoli",
    "Topolovo",
    "Troyan",
    "Trustenik",
    "Tryavna",
    "Tutrakan",
    "Varna",
    "Veliko",
    "Veliko Tarnovo",
    "Velingrad",
    "Vetren",
    "Vidin",
    "Vievo",
    "Vlado Trichkov",
    "Vrachesh",
    "Vratsa",
    "Yakoruda",
    "Yambol",
    "Zavet",
    "Zlatitrap",
    "Zlatograd",
  ],
  Cambodia: [
    "Moung Roessei",
    "Phnom Penh",
    "Phumi Boeng (1)",
    "Phumi Chhuk",
    "Phumi Preah Haoh",
    "Phumi Prei",
    "Phumi Prek Mrinh",
    "Phumi Siem Reab",
    "Phumi Thmei",
    "Phumi Thnal",
    "Phumi Vott Phnum",
    "Sihanoukville",
    "Tuol Kok",
    "Vott Kampong Svay",
  ],
  Cameroon: [
    "Bafia",
    "Bafoussam",
    "Bamenda",
    "Buea",
    "Douala",
    "Kribi",
    "Kumba",
    "Ringo",
    "Tibati",
  ],
  Canada: [
    "100 Mile House",
    "Abbey",
    "Abbotsford",
    "Acadia Valley",
    "Acme",
    "Acton",
    "Acton Vale",
    "Agassiz",
    "Agincourt",
    "Ailsa Craig",
    "Airdrie",
    "Ajax",
    "Aklavik",
    "Alameda",
    "Albanel",
    "Alberton",
    "Aldergrove",
    "Alert Bay",
    "Alexander",
    "Alexandria",
    "Alfred",
    "Allan",
    "Allardville",
    "Allenford",
    "Alliston",
    "Alma",
    "Alma",
    "Almonte",
    "Alton",
    "Altona",
    "Alvinston",
    "Amherst",
    "Amherstburg",
    "Amos",
    "Amqui",
    "Ancaster",
    "Andrew",
    "Ange-Gardien",
    "Angliers",
    "Angus",
    "Annapolis Royal",
    "Antigonish",
    "Apohaqui",
    "Apple Hill",
    "Apsley",
    "Arborfield",
    "Arborg",
    "Arcadia",
    "Arcola",
    "Arctic Bay",
    "Ardmore",
    "Ardrossan",
    "Arelee",
    "Arichat",
    "Arkona",
    "Armagh",
    "Armstrong",
    "Arnprior",
    "Arthabaska",
    "Arthur",
    "Arundel",
    "Arva",
    "Arviat",
    "Asbestos",
    "Ascot Corner",
    "Ashcroft",
    "Ashmont",
    "Ashville",
    "Assiniboia",
    "Aston-Jonction",
    "Athabasca",
    "Athens",
    "Atikameg",
    "Atikokan",
    "Atlin",
    "Attawapiskat",
    "Atwood",
    "Audet",
    "Aurora",
    "Austin",
    "Authier",
    "Avonmore",
    "Aylesford",
    "Aylmer",
    "Aylmer",
    "Aylsham",
    "Ayr",
    "Ayton",
    "Baddeck",
    "Baden",
    "Baie Verte",
    "Baie-Comeau",
    "Baie-Des-Sables",
    "Baie-du-Febvre",
    "Baie-Saint-Paul",
    "Baie-Sainte-Catherine",
    "Bala",
    "Balcarres",
    "Baldonnel",
    "Baldur",
    "Baltimore",
    "Bamfield",
    "Bancroft",
    "Banff",
    "Barkerville",
    "Barnaby River",
    "Barons",
    "Barraute",
    "Barrhead",
    "Barrie",
    "Barriere",
    "Barrington",
    "Barrington Passage",
    "Bashaw",
    "Bassano",
    "Bath",
    "Bathurst",
    "Batiscan",
    "Battleford",
    "Bay Bulls",
    "Bay de Verde",
    "Bay Roberts",
    "Bayfield",
    "Beachburg",
    "Beaconsfield",
    "Beamsville",
    "Bear River",
    "Bearn",
    "Bearskin Lake",
    "Beauceville",
    "Beauharnois",
    "Beaumont",
    "Beauport",
    "Beaupre",
    "Beausejour",
    "Beauval",
    "Beaux-Rivages--Lac-des-Ecorces--Val-Barrette",
    "Beaver Bank",
    "Beaver Crossing",
    "Beaverlodge",
    "Beaverton",
    "Becancour",
    "Bedford",
    "Bedford",
    "Beeton",
    "Begin",
    "Beiseker",
    "Bell Island",
    "Bella Bella",
    "Belle River",
    "Belleville",
    "Bellevue",
    "Bellevue",
    "Beloeil",
    "Belwood",
    "Bentley",
    "Berry Mills",
    "Berthier-Sur-Mer",
    "Berthierville",
    "Berwick",
    "Berwyn",
    "Bethune",
    "Betsiamites",
    "Bic",
    "Biencourt",
    "Bienfait",
    "Big River",
    "Big Valley",
    "Biggar",
    "Binscarth",
    "Birch Hills",
    "Birchy Bay",
    "Birtle",
    "Black Creek",
    "Black Diamond",
    "Blackfalds",
    "Blackie",
    "Blacks Harbour",
    "Blackville",
    "Blaine Lake",
    "Blainville",
    "Blairmore",
    "Blandford",
    "Blenheim",
    "Blind River",
    "Bloomfield",
    "Blue River",
    "Bluewater",
    "Bobcaygeon",
    "Bois-des-Filion",
    "Boisbriand",
    "Boischatel",
    "Boissevain",
    "Bolton",
    "Bonaventure",
    "Bonne Bay",
    "Bonnyville",
    "Bonsecours",
    "Bonshaw",
    "Borden",
    "Borden-Carleton",
    "Boswell",
    "Boucherville",
    "Bouctouche",
    "Bourget",
    "Bow Island",
    "Bowden",
    "Bowmanville",
    "Bowser",
    "Boyle",
    "Bracebridge",
    "Brackendale",
    "Bradford",
    "Bramalea",
    "Brampton",
    "Brandon",
    "Brantford",
    "Brechin",
    "Bredenbury",
    "Brentwood Bay",
    "Breslau",
    "Breton",
    "Bridge Lake",
    "Bridgetown",
    "Bridgewater",
    "Brigham",
    "Brighton",
    "Brigus",
    "Britt",
    "Broadview",
    "Brochet",
    "Brockville",
    "Brome",
    "Bromhead",
    "Bromont",
    "Brookfield",
    "Brooklyn",
    "Brooks",
    "Brossard",
    "Brossard",
    "Bruno",
    "Bryson",
    "Buckhorn",
    "Buckingham",
    "Buffalo Narrows",
    "Burgeo",
    "Burgessville",
    "Burin",
    "Burlington",
    "Burnaby",
    "Burns Lake",
    "Burnside",
    "Cabano",
    "Cabri",
    "Cache Creek",
    "Calder",
    "Caledon",
    "Caledon",
    "Caledonia",
    "Caledonia",
    "Calgary",
    "Calixa-Lavallee",
    "Callander",
    "Calmar",
    "Cambridge",
    "Cambridge",
    "Cambridge Bay",
    "Camlachie",
    "Campbell River",
    "Campbellford",
    "Campbellton",
    "Campbellville",
    "Camrose",
    "Canal Flats",
    "Candiac",
    "Canfield",
    "Canim Lake",
    "Canmore",
    "Canning",
    "Cannington",
    "Canoe Narrows",
    "Canora",
    "Canso",
    "Canterbury",
    "Canwood",
    "Canyon",
    "Cap-aux-Meules",
    "Cap-Chat",
    "Cap-Saint-Ignace",
    "Cap-Sante",
    "Cape Dorset",
    "Caraquet",
    "Carberry",
    "Carbonear",
    "Cardigan",
    "Cardinal",
    "Cardston",
    "Carignan",
    "Carleton Place",
    "Carleton-sur-Mer",
    "Carlisle",
    "Carlyle",
    "Carmacks",
    "Carman",
    "Carmel",
    "Carnduff",
    "Caroline",
    "Caronport",
    "Carp",
    "Carrot River",
    "Carrying Place",
    "Carseland",
    "Carstairs",
    "Carters Cove",
    "Cartier",
    "Cartwright",
    "Casimir",
    "Casselman",
    "Castle Bay",
    "Castlegar",
    "Cat Lake",
    "Causapscal",
    "Cavendish",
    "Cayley",
    "Cayuga",
    "Cedar Valley",
    "Central Bedeque",
    "Centreville",
    "Ceylon",
    "Chalk River",
    "Chambly",
    "Champlain",
    "Chandler",
    "Chapais",
    "Chapel Arm",
    "Chapleau",
    "Charlemagne",
    "Charlesbourg",
    "Charlie Lake",
    "Charlottetown",
    "Charny",
    "Chase",
    "Chateau-Richer",
    "Chateauguay",
    "Chatham",
    "Chatsworth",
    "Cheapside",
    "Chelmsford",
    "Chelsea",
    "Chemainus",
    "Cheneville",
    "Cherry Grove",
    "Chertsey",
    "Chesley",
    "Chester",
    "Chestermere",
    "Chesterville",
    "Cheticamp",
    "Chibougamau",
    "Chicoutimi",
    "Chicoutimi",
    "Chilliwack",
    "Chipman",
    "Church Point",
    "Churchill",
    "Churchill",
    "Churchville",
    "Chute-aux-Outardes",
    "Circonscription electorale de Jacques-Cartier",
    "Clair",
    "Clairmont",
    "Claremont",
    "Clarence",
    "Clarence-Rockland",
    "Clarenville",
    "Clarenville-Shoal Harbour",
    "Claresholm",
    "Clarke City",
    "Clarkson",
    "Clearwater",
    "Clementsvale",
    "Clermont",
    "Cleveland",
    "Clifford",
    "Clinton",
    "Clinton",
    "Clive",
    "Clyde River",
    "Coal Harbour",
    "Coaldale",
    "Coaticook",
    "Cobble Hill",
    "Cobden",
    "Coboconk",
    "Cobourg",
    "Coburg",
    "Cochrane",
    "Cochrane",
    "Colborne",
    "Cold Lake",
    "Coldstream",
    "Coldwater",
    "Coleman",
    "Coleman",
    "Coleraine",
    "Coleville",
    "Colinet",
    "Colliers",
    "Collingwood",
    "Colonsay",
    "Colville Lake",
    "Comber",
    "Comox",
    "Compton",
    "Conception Bay South",
    "Concord",
    "Conestogo",
    "Coniston",
    "Conquest",
    "Consort",
    "Contrecoeur",
    "Cookshire-Eaton",
    "Copper Cliff",
    "Coquitlam",
    "Corbyville",
    "Corner Brook",
    "Cornwall",
    "Cornwall",
    "Cornwallis",
    "Cote-Saint-Luc",
    "Cottam",
    "Courtenay",
    "Courtland",
    "Courtright",
    "Coutts",
    "Cow Head",
    "Cowansville",
    "Cowichan Bay",
    "Cowley",
    "Crabtree",
    "Craik",
    "Cranberry",
    "Cranberry Portage",
    "Cranbrook",
    "Crapaud",
    "Creemore",
    "Creston",
    "Crofton",
    "Cromer",
    "Cross Lake",
    "Crossfield",
    "Cudworth",
    "Cumberland",
    "Cumberland",
    "Cumberland Bay",
    "Cumberland House",
    "Cupar",
    "Cut Knife",
    "Czar",
    "Dalhousie",
    "Dalmeny",
    "Danville",
    "Dartmouth",
    "Dashwood",
    "Dauphin",
    "Daveluyville",
    "Davidson",
    "Dawson City",
    "Dawson Creek",
    "Dawson Settlement",
    "Daysland",
    "Debden",
    "Debert",
    "DeBolt",
    "Deep River",
    "Deer Lake",
    "Deer Lake",
    "Delaware",
    "Delaware West",
    "Delburne",
    "Delhi",
    "Deline",
    "Delisle",
    "Delson",
    "Delta",
    "Denfield",
    "Denman Island",
    "Deroche",
    "Desbiens",
    "Deschaillons-sur-Saint-Laurent",
    "Deschambault",
    "Deseronto",
    "Deux-Montagnes",
    "Devon",
    "Didsbury",
    "Dieppe",
    "Digby",
    "Digby",
    "Dinsmore",
    "Disraeli",
    "Dixville",
    "Doaktown",
    "Dog Creek",
    "Dolbeau-Mistassini",
    "Dollard-Des Ormeaux",
    "Domain",
    "Dominion City",
    "Domremy",
    "Don Mills",
    "Donnacona",
    "Donnelly",
    "Dorchester",
    "Dorion-Gardens",
    "Dorset",
    "Dorval",
    "Dover",
    "Downsview",
    "Drayton",
    "Drayton Valley",
    "Dresden",
    "Drumbo",
    "Drumheller",
    "Drummondville",
    "Dryden",
    "Dublin",
    "Duchess",
    "Duck Lake",
    "Dudswell",
    "Dufresne",
    "Dugald",
    "Duhamel",
    "Duncan",
    "Dundalk",
    "Dundas",
    "Dundurn",
    "Dunham",
    "Dunnville",
    "Dunsford",
    "Dupuy",
    "Durham",
    "Eaglesham",
    "Earlton",
    "East Angus",
    "East Broughton",
    "East Chezzetcook",
    "East Coulee",
    "East Margaree",
    "Eastern Passage",
    "Eastman",
    "Eastport",
    "Eatonville",
    "Echo Bay",
    "Eckville",
    "Economy",
    "Edam",
    "Eden",
    "Edgerton",
    "Edgewood",
    "Edmonton",
    "Edmundston",
    "Edson",
    "Eganville",
    "Elbow",
    "Elgin",
    "Elgin",
    "Elk Point",
    "Elliot Lake",
    "Elliston",
    "Elma",
    "Elmira",
    "Elmsdale",
    "Elmvale",
    "Elora",
    "Elrose",
    "Embrun",
    "Emerald",
    "Emerald Junction",
    "Emo",
    "Empress",
    "Emsdale",
    "Enderby",
    "Enfield",
    "Englehart",
    "Enterprise",
    "Entwistle",
    "Eriksdale",
    "Erin",
    "Espanola",
    "Esprit-Saint",
    "Essex",
    "Esterhazy",
    "Estevan",
    "Eston",
    "Etobicoke",
    "Eureka",
    "Everett",
    "Exeter",
    "Fabreville",
    "Fairmont Hot Springs",
    "Fairview, Alberta",
    "Farnham",
    "Fenelon Falls",
    "Fergus",
    "Ferintosh",
    "Ferme-Neuve",
    "Fermeuse",
    "Fermont",
    "Fernie",
    "Field",
    "Field",
    "Fillmore",
    "Finch",
    "Flat River",
    "Flesherton",
    "Flin Flon",
    "Florenceville-Bristol",
    "Foam Lake",
    "Fogo",
    "Fonthill",
    "Foremost",
    "Forest",
    "Forestburg",
    "Forestville",
    "Fort Albany",
    "Fort Erie",
    "Fort Frances",
    "Fort Good Hope",
    "Fort Hope",
    "Fort Kent",
    "Fort Macleod",
    "Fort McMurray",
    "Fort McPherson",
    "Fort Nelson",
    "Fort Saskatchewan",
    "Fort Severn",
    "Fort Simpson",
    "Fort Smith",
    "Fort St. James",
    "Fort St. John",
    "Fort Vermilion",
    "Fort-Coulonge",
    "Fortierville",
    "Fortune",
    "Fox Creek",
    "Foxtrap",
    "Foymount",
    "Francis",
    "Frankford",
    "Fraser Lake",
    "Fredericton",
    "Fredericton Junction",
    "Frelighsburg",
    "Frobisher",
    "Fruitvale",
    "Gabriola",
    "Gadsby",
    "Galahad",
    "Gambo",
    "Gananoque",
    "Gander",
    "Gander Bay",
    "Ganges",
    "Garden River",
    "Garibaldi",
    "Garibaldi Highlands",
    "Garnish",
    "Garrick",
    "Garson",
    "Gatineau",
    "Gem",
    "Georgetown",
    "Geraldton",
    "Gibsons",
    "Gilbert Plains",
    "Gillam",
    "Gimli",
    "Girouxville",
    "Glace Bay",
    "Gladstone",
    "Glaslyn",
    "Gleichen",
    "Glen Robertson",
    "Glencoe",
    "Glendon",
    "Glentworth",
    "Glenwood",
    "Glenwood",
    "Gloucester",
    "Glovertown",
    "Goderich",
    "Gogama",
    "Gold Bridge",
    "Golden",
    "Golden Lake",
    "Gore",
    "Gore Bay",
    "Granby",
    "Grand Bend",
    "Grand Falls",
    "Grand Falls-Windsor",
    "Grand Forks",
    "Grand Rapids",
    "Grand Valley",
    "Grand-Remous",
    "Grand-Saint-Esprit",
    "Grande Cache",
    "Grande Prairie",
    "Grande-Anse",
    "Grande-Riviere",
    "Grandora",
    "Granton",
    "Granum",
    "Granville Ferry",
    "Grasmere",
    "Gravelbourg",
    "Gravenhurst",
    "Gray Creek",
    "Great Village",
    "Greater Sudbury",
    "Greely",
    "Greenfield Park",
    "Greenspond",
    "Greenwood",
    "Greenwood",
    "Grenfell",
    "Grenville",
    "Gretna",
    "Grimsby",
    "Grimshaw",
    "Grindrod",
    "Griswold",
    "Gronlid",
    "Grono Road",
    "Guelph",
    "Guigues",
    "Gull Lake",
    "Guysborough",
    "Hafford",
    "Hagensborg",
    "Hagersville",
    "Haileybury",
    "Halfmoon Bay",
    "Haliburton",
    "Halifax",
    "Halifax",
    "Ham-Nord",
    "Hamilton",
    "Hamiota",
    "Hampden",
    "Hampton",
    "Hampton",
    "Hanley",
    "Hanna",
    "Hanover",
    "Hantsport",
    "Happy Valley-Goose Bay",
    "Harbour Grace",
    "Harbour Main",
    "Hardisty",
    "Hare Bay",
    "Harriston",
    "Harrow",
    "Hartland",
    "Hartley Bay",
    "Hartney",
    "Harvey",
    "Hastings",
    "Havelock",
    "Havre Boucher",
    "Havre-Aubert",
    "Havre-Saint-Pierre",
    "Hawkesbury",
    "Hawkestone Beach",
    "Hay Lakes",
    "Hay River",
    "Hazelton",
    "Head of Jeddore",
    "Hearst",
    "Hebertville",
    "Hebron",
    "Hemmingford",
    "Henryville",
    "Hensall",
    "Herbert",
    "Heron Bay",
    "Hespeler",
    "High Level",
    "High Prairie",
    "High River",
    "Hillsburgh",
    "Hinton",
    "Hobbema",
    "Holden",
    "Holman",
    "Holyrood",
    "Honeymoon Bay",
    "Hope",
    "Hornepayne",
    "Houston",
    "Howick",
    "Howley",
    "Hubbards",
    "Hudson",
    "Hudson Bay",
    "Hull",
    "Humboldt",
    "Hunter River",
    "Huntingdon",
    "Huntsville",
    "Huron East",
    "Hythe",
    "Ignace",
    "Ilderton",
    "Ile-a-la-Crosse",
    "Indian Head",
    "Ingersoll",
    "Ingleside",
    "Inglewood",
    "Inglis",
    "Ingonish",
    "Innisfail",
    "Innisfil",
    "Inukjuak",
    "Inuvik",
    "Invermere",
    "Inverness",
    "Inwood",
    "Iona",
    "Iqaluit",
    "Irma",
    "Iroquois",
    "Iroquois Falls",
    "Irricana",
    "Islington",
    "Italy Cross",
    "Ituna",
    "Ivujivik",
    "Jacksons",
    "Jasper",
    "Jenner",
    "Jerseyville",
    "Joliette",
    "Jonquiere",
    "Jordan",
    "Jordan Falls",
    "Kagawong",
    "Kamloops",
    "Kamouraska",
    "Kamsack",
    "Kanata",
    "Kangiqsujuaq",
    "Kangirsuk",
    "Kapuskasing",
    "Kars",
    "Kaslo",
    "Kazabazua",
    "Kedgwick",
    "Keene",
    "Keewatin",
    "Kelowna",
    "Kelvington",
    "Kemptville",
    "Kenora",
    "Kensington",
    "Kent Bridge",
    "Kentville",
    "Kerrobert",
    "Kerwood",
    "Keswick",
    "Keswick Beach",
    "Kettleby",
    "Killarney",
    "Kilworthy",
    "Kimberley",
    "Kimmirut",
    "Kincardine",
    "Kindersley",
    "King City",
    "Kingsey Falls",
    "Kingston",
    "Kingston",
    "Kingsville",
    "Kingsville",
    "Kinistino",
    "Kinuso",
    "Kippen",
    "Kirkland",
    "Kirkland Lake",
    "Kirkton",
    "Kisbey",
    "Kispiox",
    "Kitchener",
    "Kitchener",
    "Kitimat",
    "Kitscoty",
    "Kitwanga",
    "Klemtu",
    "Knowlton",
    "Kohler",
    "Komoka",
    "Kugaaruk",
    "Kugluktuk",
    "Kuujjuaq",
    "Kuujjuarapik",
    "La Baie",
    "La Conception",
    "La Corey",
    "La Durantaye",
    "La Guadeloupe",
    "La Loche",
    "La Malbaie",
    "La Morandiere",
    "La Patrie",
    "La Pocatiere",
    "La Prairie",
    "La Presentation",
    "La Ronge",
    "La Salle",
    "La Sarre",
    "La Tabatiere",
    "La Tuque",
    "La Visitation",
    "Labelle",
    "Labrador City",
    "Lac du Bonnet",
    "Lac La Biche",
    "Lac-aux-Sables",
    "Lac-Drolet",
    "Lac-Megantic",
    "Lachenaie",
    "Lachine",
    "Lachute",
    "Lacolle",
    "Lacombe",
    "Ladysmith",
    "Ladysmith",
    "Lafleche",
    "LaHave",
    "Lake Cowichan",
    "Lake Lenore",
    "Lake Louise",
    "Lakeburn",
    "Lakefield",
    "Lambton",
    "Lamont",
    "Lampman",
    "Lanark",
    "Lancaster",
    "Lancaster Park",
    "Landrienne",
    "Lang",
    "Langdon",
    "Langenburg",
    "Langham",
    "Langley",
    "Lanigan",
    "Lanoraie",
    "Lantzville",
    "Lasalle",
    "Lashburn",
    "Laurentia Beach",
    "Laurier-Station",
    "Laval",
    "Lavaltrie",
    "Laverlochere",
    "Lavillette",
    "Lawrencetown",
    "Leader",
    "Leamington",
    "Leduc",
    "Lefroy",
    "Legal",
    "Lemberg",
    "Leonard",
    "Lery",
    "Les Cedres",
    "Les Coteaux",
    "Les Escoumins",
    "Les Mechins",
    "Leslieville",
    "Lethbridge",
    "Lethbridge",
    "Levack",
    "Levis",
    "Lewisporte",
    "Lillooet",
    "Limoges",
    "Lindsay",
    "Lipton",
    "Listowel",
    "Little Current",
    "Little Narrows",
    "Lively",
    "Liverpool",
    "Lloydminster",
    "Logan Lake",
    "London",
    "Londonderry",
    "Long Sault",
    "Longueuil",
    "Lorraine",
    "Lotbiniere",
    "Lougheed",
    "Louisbourg",
    "Louiseville",
    "Lourdes",
    "Lower Island Cove",
    "Lucan",
    "Lucknow",
    "Lucky Lake",
    "Lumby",
    "Lumsden",
    "Lumsden",
    "Lunenburg",
    "Luseland",
    "Luskville",
    "Lyn",
    "Lyster",
    "Lytton",
    "Maberly",
    "Mabou",
    "Macamic",
    "MacGregor",
    "Mackenzie",
    "Macklin",
    "MacTier",
    "Madoc",
    "Magog",
    "Magrath",
    "Mahone Bay",
    "Maidstone",
    "Maidstone",
    "Malagawatch",
    "Malakwa",
    "Malartic",
    "Mallaig",
    "Malton",
    "Mandeville",
    "Manitou",
    "Manitouwadge",
    "Maniwaki",
    "Mankota",
    "Mannville",
    "Manor",
    "Manotick Station",
    "Manseau",
    "Mansonville",
    "Maple",
    "Maple Creek",
    "Maple Ridge",
    "Mar",
    "Marathon",
    "Marbleton",
    "Marchand",
    "Margaree Valley",
    "Margaretsville",
    "Maria",
    "Marieville",
    "Markdale",
    "Markham",
    "Markstay",
    "Marmora",
    "Martinville",
    "Marwayne",
    "Maryfield",
    "Marystown",
    "Marysville",
    "Mascouche",
    "Maskinonge",
    "Masset",
    "Massey",
    "Masson-Angers",
    "Massueville",
    "Matagami",
    "Matane",
    "Matheson",
    "Maxville",
    "Mayne",
    "McBride",
    "McCreary",
    "McGregor",
    "McLennan",
    "McMasterville",
    "Meadow Lake",
    "Meaford",
    "Medicine Hat",
    "Melbourne Ridge",
    "Melfort",
    "Melita",
    "Melocheville",
    "Melville",
    "Memphremagog",
    "Memramcook",
    "Mercier",
    "Merlin",
    "Merrickville",
    "Merritt",
    "Merville",
    "Messines",
    "Metabetchouan-Lac-a-la-Croix",
    "Meteghan",
    "Meteghan River",
    "Metis-sur-Mer",
    "Miami",
    "Midale",
    "Middle Arm",
    "Middle Musquodoboit",
    "Middleton",
    "Midhurst",
    "Midland",
    "Milden",
    "Mildmay",
    "Milford",
    "Milford Station",
    "Milk River",
    "Mill Village",
    "Millarville",
    "Millbrook",
    "Millet",
    "Millville",
    "Milton",
    "Milton",
    "Milverton",
    "Minden",
    "Minnedosa",
    "Minto",
    "Mira Gut",
    "Mirabel",
    "Miramichi",
    "Miscouche",
    "Mission",
    "Mississauga",
    "Mistatim",
    "Mitchell",
    "Moberly Lake",
    "Mobile",
    "Moffat",
    "Moncton",
    "Mont-Joli",
    "Mont-Laurier",
    "Mont-Royal",
    "Mont-Saint-Gregoire",
    "Mont-Saint-Hilaire",
    "Mont-Saint-Michel",
    "Mont-Tremblant",
    "Montague",
    "Montebello",
    "Montmagny",
    "Montmartre",
    "Montreal",
    "Montreal East",
    "Moonbeam",
    "Mooretown",
    "Moose Creek",
    "Moose Jaw",
    "Moose Lake",
    "Moosehorn",
    "Moosomin",
    "Moosonee",
    "Moraviantown",
    "Morden",
    "Morell",
    "Morewood",
    "Morinville",
    "Morrin",
    "Morris",
    "Morrisburg",
    "Mortlach",
    "Mount Brydges",
    "Mount Elgin",
    "Mount Forest",
    "Mount Pearl",
    "Mount Stewart",
    "Mount Uniacke",
    "Mountain View",
    "Muirkirk",
    "Mulgrave",
    "Mundare",
    "Munson",
    "Munster",
    "Murdochville",
    "Murray Harbour",
    "Murray River",
    "Musquodoboit Harbour",
    "Myrnam",
    "Nackawic",
    "Naicam",
    "Nakina",
    "Nakusp",
    "Nampa",
    "Namur",
    "Nanaimo",
    "Nanoose Bay",
    "Nantes",
    "Nanton",
    "Napanee",
    "Napanee",
    "Napierville",
    "Nauwigewauk",
    "Neepawa",
    "Neguac",
    "Neilburg",
    "Nelson",
    "Nepean",
    "Neudorf",
    "Neuville",
    "New Aiyansh",
    "New Carlisle",
    "New Denver",
    "New Dundee",
    "New Glasgow",
    "New Hamburg",
    "New Harbour",
    "New Liskeard",
    "New Ross",
    "New Waterford",
    "New Westminster",
    "New-Richmond",
    "New-Wes-Valley",
    "Newburgh",
    "Newcastle Creek",
    "Newcastle Village",
    "Newdale",
    "Newmarket",
    "Newport",
    "Newton",
    "Newtown",
    "Niagara",
    "Niagara Falls",
    "Niagara-on-the-Lake",
    "Nicolet",
    "Nipawin",
    "Nipigon",
    "Nisku",
    "Niverville",
    "Nobleford",
    "Nobleton",
    "Nokomis",
    "Nominingue",
    "Norfolk County",
    "Norman Wells",
    "Normandin",
    "Norquay",
    "North Battleford",
    "North Bay",
    "North Delta",
    "North Hatley",
    "North Lunenburg",
    "North Perth",
    "North Portal",
    "North Russell",
    "North Rustico",
    "North Saanich",
    "North Star",
    "North Sydney",
    "North Vancouver",
    "North York",
    "Norval",
    "Norway House",
    "Norwich",
    "Norwood",
    "Notre-Dame-de-Lourdes",
    "Notre-Dame-du-Bon-Conseil",
    "Notre-Dame-du-Mont-Carmel",
    "Notre-Dame-du-Nord",
    "Notre-Dame-du-Portage",
    "Nouvelle",
    "Noyan",
    "Oak Lake",
    "Oak Point",
    "Oakville",
    "Obedjiwan",
    "Odessa",
    "Ogoki",
    "Ohsweken",
    "Oil Springs",
    "Oka",
    "Okanagan Centre",
    "Okotoks",
    "Old Perlican",
    "Oldcastle",
    "Olds",
    "Oliver",
    "Omemee",
    "Omerville",
    "Onanole",
    "Onion Lake",
    "Onoway",
    "Orangeville",
    "Orillia",
    "Orleans",
    "Ormstown",
    "Oromocto",
    "Orono",
    "Orton",
    "Oshawa",
    "Osoyoos",
    "Ottawa",
    "Otter Lake",
    "Otterburn Park",
    "Otterburne",
    "Outlook",
    "Owen Sound",
    "Oxbow",
    "Oxdrift",
    "Oxford",
    "Oxford House",
    "Oyama",
    "Pain Court",
    "Paisley",
    "Pakenham",
    "Palmarolle",
    "Palmerston",
    "Papineauville",
    "Paradise Cove",
    "Paradise Valley",
    "Parham",
    "Paris",
    "Parisville",
    "Parkhill",
    "Parksville",
    "Parry Sound",
    "Parson",
    "Pasadena",
    "Pascal",
    "Paspebiac",
    "Pass Lake",
    "Paulatuk",
    "Peace River",
    "Peachland",
    "Pefferlaw",
    "Pelly",
    "Pemberton",
    "Pembroke",
    "Penetanguishene",
    "Penhold",
    "Pennfield",
    "Penobsquis",
    "Penticton",
    "Penzance",
    "Perth",
    "Perth",
    "Perth-Andover",
    "Petawawa",
    "Peterborough",
    "Petherton",
    "Petit-de-Grat",
    "Petitcodiac",
    "Petite-Riviere-Saint-Francois",
    "Petrolia",
    "Pickering",
    "Picton",
    "Pictou",
    "Pierreville",
    "Pierson",
    "Pikangikum",
    "Pilot Butte",
    "Pilot Mound",
    "Pinantan Lake",
    "Pinawa",
    "Pincher Creek",
    "Pincourt",
    "Pine Falls",
    "Piney",
    "Pitt Meadows",
    "Placentia",
    "Plamondon",
    "Plantagenet Station",
    "Plaster Rock",
    "Plessisville",
    "Plum Coulee",
    "Plumas",
    "Plympton",
    "Point Edward",
    "Pointe-a-la-Croix",
    "Pointe-aux-Trembles",
    "Pointe-Calumet",
    "Pointe-Claire",
    "Pointe-Fortune",
    "Pointe-Lebel",
    "Pointe-Verte",
    "Ponoka",
    "Pont-Rouge",
    "Pont-Viau",
    "Pontypool",
    "Poplar Hill",
    "Port Alberni",
    "Port Alice",
    "Port au Choix",
    "Port au Port",
    "Port Burwell",
    "Port Clements",
    "Port Colborne",
    "Port Coquitlam",
    "Port Credit",
    "Port Dover",
    "Port Edward",
    "Port Elgin",
    "Port Elgin",
    "Port Hardy",
    "Port Hawkesbury",
    "Port Hope",
    "Port Lorne",
    "Port McNeill",
    "Port McNicoll",
    "Port Medway",
    "Port Moody",
    "Port Morien",
    "Port Perry",
    "Port Renfrew",
    "Port Royal",
    "Port Saunders",
    "Port Stanley",
    "Port-Cartier",
    "Port-Daniel",
    "Portage",
    "Portage la Prairie",
    "Portage-du-Fort",
    "Porters Lake",
    "Portneuf",
    "Portugal Cove",
    "Potter",
    "Pouce Coupe",
    "Pouch Cove",
    "Powassan",
    "Powell River",
    "Preeceville",
    "Prescott",
    "Price",
    "Priceville",
    "Prince",
    "Prince Albert",
    "Prince George",
    "Prince Rupert",
    "Princeton",
    "Princeville",
    "Pritchard",
    "Prospect",
    "Provost",
    "Pugwash",
    "Pukatawagan",
    "Puslinch",
    "Puvirnituq",
    "Pynns",
    "Qualicum Beach",
    "Quaqtaq",
    "Quathiaski Cove",
    "Quebec",
    "Queen Charlotte",
    "Quesnel",
    "Quill Lake",
    "Quispamsis",
    "Quyon",
    "Racine",
    "Radisson",
    "Radville",
    "Rainbow Lake",
    "Raleigh",
    "Ramea",
    "Rankin Inlet",
    "Rapid City",
    "Rawdon",
    "Raymond",
    "Raymore",
    "Red Deer",
    "Red Lake",
    "Red Rock",
    "Redcliff",
    "Redvers",
    "Redwater",
    "Regina",
    "Rencontre East",
    "Renfrew",
    "Renous",
    "Repentigny",
    "Revelstoke",
    "Rexdale",
    "Rhein",
    "Richibucto",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond Hill",
    "Ridgetown",
    "Ridgeway",
    "Rigaud",
    "Rimbey",
    "Rimouski",
    "Ripley",
    "Ripon",
    "Ripples",
    "River Hebert",
    "River John",
    "Rivers",
    "Riverside-Albert",
    "Riverton",
    "Riverview",
    "Riviere-Beaudette",
    "Riviere-Bleue",
    "Riviere-Ouelle",
    "Riviere-Rouge",
    "Riviere-Saint-Paul",
    "Roberts Creek",
    "Roberval",
    "Roblin",
    "Roblin",
    "Rocanville",
    "Rockland",
    "Rockville",
    "Rockwood",
    "Rocky Mountain House",
    "Roddickton",
    "Rogersville",
    "Roland",
    "Rosalind",
    "Rosedale",
    "Rosemary",
    "Rosemere",
    "Roseneath",
    "Rosetown",
    "Rossburn",
    "Rossland",
    "Rossport",
    "Rosthern",
    "Rothesay",
    "Rougemont",
    "Rouleau",
    "Rouyn-Noranda",
    "Roxboro",
    "Roxton Falls",
    "Roxton Pond",
    "Rumsey",
    "Russell",
    "Rycroft",
    "Saanichton",
    "Sable River",
    "Sabrevois",
    "Sackville",
    "Saguenay",
    "Saint Andrews",
    "Saint Etienne-De-Lauzon",
    "Saint John",
    "Saint Romuald",
    "Saint-Adalbert",
    "Saint-Adelphe",
    "Saint-Agapit",
    "Saint-Aime-du-Lac-des-Iles",
    "Saint-Alban",
    "Saint-Alexis-des-Monts",
    "Saint-Ambroise",
    "Saint-Anaclet-de-Lessard",
    "Saint-Andre",
    "Saint-Andre-Avellin",
    "Saint-Anicet",
    "Saint-Anselme",
    "Saint-Antoine-de-Tilly",
    "Saint-Antoine-sur-Richelieu",
    "Saint-Antonin",
    "Saint-Apollinaire",
    "Saint-Arsene",
    "Saint-Aubert",
    "Saint-Augustin",
    "Saint-Augustin-de-Desmaures",
    "Saint-Barnabe-Sud",
    "Saint-Barthelemy",
    "Saint-Basile",
    "Saint-Basile-le-Grand",
    "Saint-Benjamin",
    "Saint-Bruno",
    "Saint-Bruno-de-Montarville",
    "Saint-Camille",
    "Saint-Camille",
    "Saint-Casimir",
    "Saint-Cesaire",
    "Saint-Charles-de-Bellechasse",
    "Saint-Charles-sur-Richelieu",
    "Saint-Clement",
    "Saint-Colomban",
    "Saint-Come",
    "Saint-Come--Liniere",
    "Saint-Cyprien",
    "Saint-Cyrille-de-Wendover",
    "Saint-Damase",
    "Saint-Denis",
    "Saint-Denis",
    "Saint-Dominique",
    "Saint-Donat",
    "Saint-Elie",
    "Saint-Elzear",
    "Saint-Etienne-des-Gres",
    "Saint-Eusebe",
    "Saint-Eustache",
    "Saint-Fabien",
    "Saint-Fabien-de-Panet",
    "Saint-Faustin--Lac-Carre",
    "Saint-Felicien",
    "Saint-Felix-de-Kingsey",
    "Saint-Felix-de-Valois",
    "Saint-Ferdinand",
    "Saint-Ferreol-les-Neiges",
    "Saint-Flavien",
    "Saint-Francois-du-Lac",
    "Saint-Frederic",
    "Saint-Gabriel",
    "Saint-Gabriel-de-Valcartier",
    "Saint-Gedeon",
    "Saint-Georges",
    "Saint-Georges-de-Windsor",
    "Saint-Germain-de-Grantham",
    "Saint-Gilles",
    "Saint-Godefroi",
    "Saint-Guillaume",
    "Saint-Henri",
    "Saint-Hilaire",
    "Saint-Honore-de-Temiscouata",
    "Saint-Hubert-de-Riviere-du-Loup",
    "Saint-Hugues",
    "Saint-Hyacinthe",
    "Saint-Irenee",
    "Saint-Isidore",
    "Saint-Jacques",
    "Saint-Jean-de-Dieu",
    "Saint-Jean-de-Matha",
    "Saint-Jean-Port-Joli",
    "Saint-Jean-sur-Richelieu",
    "Saint-Jerome",
    "Saint-Joachim",
    "Saint-Joachim-de-Shefford",
    "Saint-Joseph-de-Beauce",
    "Saint-Jude",
    "Saint-Lambert",
    "Saint-Lambert",
    "Saint-Lazare-de-Bellechasse",
    "Saint-Leon-de-Standon",
    "Saint-Leonard",
    "Saint-Liboire",
    "Saint-Lin",
    "Saint-Lin-Laurentides",
    "Saint-Louis de Kent",
    "Saint-Louis-de-Gonzague",
    "Saint-Luc",
    "Saint-Ludger",
    "Saint-Malachie",
    "Saint-Marc-de-Figuery",
    "Saint-Marc-des-Carrieres",
    "Saint-Marc-sur-Richelieu",
    "Saint-Mathieu",
    "Saint-Maurice",
    "Saint-Michel",
    "Saint-Michel-de-Bellechasse",
    "Saint-Michel-des-Saints",
    "Saint-Nazaire",
    "Saint-Odilon-de-Cranbourne",
    "Saint-Pacome",
    "Saint-Pamphile",
    "Saint-Pascal",
    "Saint-Paul",
    "Saint-Paul",
    "Saint-Paul-de-la-Croix",
    "Saint-Paulin",
    "Saint-Philemon",
    "Saint-Philippe",
    "Saint-Philippe-de-Neri",
    "Saint-Pie",
    "Saint-Pierre",
    "Saint-Pierre-de-Broughton",
    "Saint-Pierre-les-Becquets",
    "Saint-Placide",
    "Saint-Polycarpe",
    "Saint-Prosper",
    "Saint-Quentin",
    "Saint-Raphael",
    "Saint-Raymond",
    "Saint-Remi",
    "Saint-Roch",
    "Saint-Roch-de-Richelieu",
    "Saint-Roch-des-Aulnaies",
    "Saint-Sauveur",
    "Saint-Sebastien",
    "Saint-Severe",
    "Saint-Simon-de-Rimouski",
    "Saint-Stanislas",
    "Saint-Stanislas-de-Kostka",
    "Saint-Sylvere",
    "Saint-Sylvestre",
    "Saint-Telesphore",
    "Saint-Thomas",
    "Saint-Tite",
    "Saint-Ubalde",
    "Saint-Ulric",
    "Saint-Urbain",
    "Saint-Urbain-Premier",
    "Saint-Vallier",
    "Saint-Venant-de-Paquette",
    "Saint-Victor",
    "Saint-Wenceslas",
    "Saint-Zacharie",
    "Saint-Zenon",
    "Saint-Zotique",
    "Sainte-Adele",
    "Sainte-Agathe-des-Monts",
    "Sainte-Angele-de-Premont",
    "Sainte-Anne-de-Beaupre",
    "Sainte-Anne-de-Bellevue",
    "Sainte-Anne-de-la-Perade",
    "Sainte-Anne-des-Monts",
    "Sainte-Anne-du-Lac",
    "Sainte-Brigitte-des-Saults",
    "Sainte-Catherine",
    "Sainte-Cecile-de-Masham",
    "Sainte-Cecile-de-Whitton",
    "Sainte-Claire",
    "Sainte-Clotilde-de-Horton",
    "Sainte-Croix",
    "Sainte-Famille",
    "Sainte-Foy",
    "Sainte-Gertrude",
    "Sainte-Helene-de-Bagot",
    "Sainte-Helene-de-Breakeyville",
    "Sainte-Julie",
    "Sainte-Julienne",
    "Sainte-Justine",
    "Sainte-Marcelline-de-Kildare",
    "Sainte-Marguerite",
    "Sainte-Marie",
    "Sainte-Marthe",
    "Sainte-Marthe-sur-le-Lac",
    "Sainte-Martine",
    "Sainte-Melanie",
    "Sainte-Monique",
    "Sainte-Perpetue",
    "Sainte-Thecle",
    "Sainte-Therese",
    "Salaberry-de-Valleyfield",
    "Salisbury",
    "Salmo",
    "Salmon Arm",
    "Salmon River",
    "Sandy Bay",
    "Sandy Lake",
    "Sanikiluaq",
    "Sardis",
    "Sarnia",
    "Saskatoon",
    "Saulnierville",
    "Sault Ste. Marie",
    "Savona",
    "Sawyerville",
    "Sayabec",
    "Sayward",
    "Scarborough",
    "Schefferville",
    "Schomberg",
    "Schreiber",
    "Scotland",
    "Scotsburn",
    "Scotstown",
    "Scott",
    "Scoudouc",
    "Seaforth",
    "Searchmont",
    "Sechelt",
    "Seldom-Little Seldom",
    "Selkirk",
    "Senneterre",
    "Senneville",
    "Sept-Iles",
    "Seton Portage",
    "Severn Bridge",
    "Sexsmith",
    "Shamattawa",
    "Shanklin",
    "Shannon",
    "Shannonville",
    "Sharbot Lake",
    "Shaunavon",
    "Shawinigan",
    "Shawinigan-Sud",
    "Shawnigan Lake",
    "Shawville",
    "Shediac",
    "Sheho",
    "Shelburne",
    "Shelburne",
    "Shellbrook",
    "Sherbrooke",
    "Sherbrooke",
    "Sherrington",
    "Sherwood Park",
    "Shigawake",
    "Shoal Lake",
    "Shubenacadie",
    "Sicamous",
    "Sidney",
    "Sillery",
    "Silton",
    "Simcoe",
    "Sioux Lookout",
    "Skidegate",
    "Slave Lake",
    "Slocan",
    "Smithers",
    "Smiths Falls",
    "Smithville",
    "Smoky Lake",
    "Smooth Rock Falls",
    "Snow Lake",
    "Sointula",
    "Sombra",
    "Sooke",
    "Sorel",
    "Sorel-Tracy",
    "Sorrento",
    "Souris",
    "Souris",
    "South Augusta",
    "South Gower",
    "South Indian Lake",
    "South Lancaster",
    "South Mountain",
    "South Porcupine",
    "South River",
    "South Slocan",
    "Southend Reindeer",
    "Southey",
    "Spanish",
    "Sparta",
    "Spencerville",
    "Sperling",
    "Spillimacheen",
    "Spirit River",
    "Spiritwood",
    "Spring Hill",
    "Springdale",
    "Springfield",
    "Springhill",
    "Spruce Grove",
    "Spy Hill",
    "Squamish",
    "St-Hubert",
    "St-Lazare",
    "St-Timothee",
    "St. Albert",
    "St. Andrews",
    "St. Anthony",
    "St. Brieux",
    "St. Catharines",
    "St. Jacobs",
    "St. Louis",
    "St. Marys",
    "St. Paul",
    "St. Peters Bay",
    "St. Stephen",
    "St. Thomas",
    "St. Walburg",
    "Stanbridge East",
    "Standard",
    "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico",
    "Star City",
    "Stayner",
    "Steinbach",
    "Stella",
    "Stellarton",
    "Stephenville",
    "Stephenville Crossing",
    "Stettler",
    "Stevensville",
    "Stewiacke",
    "Stirling",
    "Stirling",
    "Stittsville",
    "Stoneham",
    "Stonewall",
    "Stoney Creek",
    "Stony Mountain",
    "Stony Plain",
    "Stouffville",
    "Stoughton",
    "Strasbourg",
    "Stratford",
    "Stratford",
    "Strathmore",
    "Strathroy",
    "Streetsville",
    "Sturgeon Falls",
    "Sturgis",
    "Summerland",
    "Summerside",
    "Sunderland",
    "Sundridge",
    "Surrey",
    "Sussex",
    "Sutton",
    "Sutton",
    "Swan River",
    "Swastika",
    "Swift Current",
    "Sydenham",
    "Sydney",
    "Sydney",
    "Sydney Mines",
    "Sylvan Lake",
    "Taber",
    "Tadoussac",
    "Tahsis",
    "Takla Landing",
    "Tappen",
    "Taschereau",
    "Tasiujaq",
    "Tatamagouche",
    "Tavistock",
    "Taylor",
    "Tecumseh",
    "Tecumseh",
    "Teeswater",
    "Temiscaming",
    "Tennion",
    "Terrace",
    "Terrace Bay",
    "Terre Noire",
    "Terrebonne",
    "Teulon",
    "Thamesford",
    "Thamesville",
    "The Pas",
    "Thedford",
    "Theodore",
    "Theresa",
    "Thetford-Mines",
    "Thompson",
    "Thorhild",
    "Thornbury",
    "Thorndale",
    "Thornhill",
    "Thorold",
    "Three Hills",
    "Three Mile Plains",
    "Thunder Bay",
    "Thurso",
    "Tilbury",
    "Tilley",
    "Tillsonburg",
    "Timberlea",
    "Timmins",
    "Tisdale",
    "Tlell",
    "Tofield",
    "Tofino",
    "Tompkins",
    "Tompkins",
    "Torbay",
    "Toronto",
    "Torrington",
    "Tottenham",
    "Tracadie",
    "Trail",
    "Treherne",
    "Trenton",
    "Trenton",
    "Tring-Jonction",
    "Trochu",
    "Trois Pistoles",
    "Trout Creek",
    "Trout River",
    "Troy",
    "Truro",
    "Tsawwassen",
    "Tuktoyaktuk",
    "Tumbler Ridge",
    "Turner Valley",
    "Turtleford",
    "Tusket",
    "Tweed",
    "Twillingate",
    "Two Hills",
    "Tyndall",
    "Ucluelet",
    "Union Bay",
    "Unionville",
    "Unity",
    "Upper Island Cove",
    "Upton",
    "Utterson",
    "Uxbridge",
    "Val-Brillant",
    "Val-David",
    "Val-des-Monts",
    "Val-Morin",
    "Valcourt",
    "Valemount",
    "Vallee-Jonction",
    "Valley",
    "Valleyview",
    "Van Anda",
    "Vancouver",
    "Vanderhoof",
    "Vankleek Hill",
    "Varennes",
    "Vaudreuil-Dorion",
    "Vaughan",
    "Vauxhall",
    "Vegreville",
    "Vercheres",
    "Verdun",
    "Vermilion",
    "Verner",
    "Vernon",
    "Vernon Bridge",
    "Victoria",
    "Victoria",
    "Victoria Cove",
    "Victoria Harbour",
    "Victoriaville",
    "Vieux-Saint-Laurent",
    "Viking",
    "Villa Marie",
    "Ville-Marie",
    "Vineland Station",
    "Virden",
    "Viscount",
    "Vonda",
    "Vulcan",
    "Wabana",
    "Wabigoon",
    "Wabush",
    "Wadena",
    "Wainfleet",
    "Wainwright",
    "Wakaw",
    "Wakefield",
    "Waldeck",
    "Waldheim",
    "Walkerton",
    "Wallace",
    "Wallaceburg",
    "Warkworth",
    "Warman",
    "Warner",
    "Warsaw",
    "Warwick",
    "Wasaga Beach",
    "Washago",
    "Waterdown",
    "Waterford",
    "Waterloo",
    "Waterloo",
    "Waterville",
    "Watford",
    "Watrous",
    "Watson",
    "Watson Lake",
    "Waubaushene",
    "Wawa",
    "Waweig",
    "Wawota",
    "Weedon",
    "Weir",
    "Weldon",
    "Welland",
    "Wellesley",
    "Wellington",
    "Wellington",
    "Wembley",
    "Wemindji",
    "West Bay",
    "West Chezzetcook",
    "West Hill",
    "West Lake",
    "West Osgoode",
    "Westbank",
    "Western Bay",
    "Westlock",
    "Westmount",
    "Weston",
    "Westport",
    "Westree",
    "Westville",
    "Wetaskiwin",
    "Weyburn",
    "Wheatley",
    "Whistler Village",
    "Whitby",
    "White City",
    "White Fox",
    "White River",
    "White Rock",
    "Whitecourt",
    "Whitehorse",
    "Whitemouth",
    "Whites Lake",
    "Whitney",
    "Whycocomagh",
    "Wiarton",
    "Wickham",
    "Wilcox",
    "Wildwood",
    "Wilkie",
    "Williams Lake",
    "Williamsburg",
    "Willingdon",
    "Willow Bunch",
    "Willowdale",
    "Willowvale",
    "Winchester",
    "Windermere",
    "Windham Centre",
    "Windsor",
    "Windsor",
    "Windsor",
    "Winfield",
    "Winfield",
    "Wingham",
    "Winkler",
    "Winnipeg",
    "Winnipegosis",
    "Wiseton",
    "Wishart",
    "Witless Bay",
    "Wittenburg",
    "Wolfville",
    "Wolseley",
    "Wood Mountain",
    "Woodbridge",
    "Woodlands",
    "Woodridge",
    "Woodstock",
    "Woodstock",
    "Worsley",
    "Wrentham",
    "Wright-Gracefield-Northfield",
    "Wymark",
    "Wynyard",
    "Wyoming",
    "Yahk",
    "Yamachiche",
    "Yamaska",
    "Yarker",
    "Yarmouth",
    "Yellow Grass",
    "Yellowknife",
    "York",
    "Yorkton",
    "Youbou",
    "Young",
    "Youngstown",
  ],
  "Cayman Islands": [
    "Bodden Town",
    "Coral Gables",
    "George Town",
    "Newlands",
    "Savannah",
    "Spot Bay",
    "West Bay",
  ],
  Chile: [
    "Algarrobo",
    "Angol",
    "Antofagasta",
    "Apoquindo",
    "Arauco",
    "Arica",
    "Buin",
    "Bulnes",
    "Calama",
    "Caldera",
    "Castro",
    "Catemu",
    "Centro",
    "Cerrillos de Tamaya",
    "Chicureo Abajo",
    "Chiguayante",
    "Chillan",
    "Chillan Viejo",
    "Chimbarongo",
    "Chonchi",
    "Chuquicamata",
    "Coihaique",
    "Colina",
    "Collipulli",
    "Concon",
    "Constitucion",
    "Coquimbo",
    "Coronel",
    "Cunco",
    "Curacavi",
    "Dalcahue",
    "El Arco",
    "El Bosque",
    "El Monte",
    "El Quisco",
    "El Salto",
    "El Salvador",
    "El Valle",
    "Fresia",
    "Galvarino",
    "Graneros",
    "Hanga Roa",
    "Iquique",
    "La Calera",
    "La Cisterna",
    "La Granja",
    "La Laja",
    "La Ligua",
    "La Reina",
    "La Serena",
    "La Union",
    "Lampa",
    "Las Condes",
    "Lautaro",
    "Llanquihue",
    "Lo Barnechea",
    "Lolol",
    "Los Andes",
    "Los Angeles",
    "Machali",
    "Macul",
    "Maipu",
    "Maquina Atacama",
    "Maule",
    "Mejillones",
    "Melipilla",
    "Miraflores",
    "Molina",
    "Monte Aguila",
    "Nacimiento",
    "Nogales",
    "Nunoa",
    "Olmue",
    "Osorno",
    "Ovalle",
    "Paillaco",
    "Penablanca",
    "Penaflor",
    "Port Montt",
    "Providencia",
    "Puchuncavi",
    "Pucon",
    "Puente Alto",
    "Puerto Aisen",
    "Puerto Natales",
    "Puerto Varas",
    "Punta Arenas",
    "Puren",
    "Purranque",
    "Quellon",
    "Quemchi",
    "Quilicura",
    "Quillota",
    "Rancagua",
    "Recoleta",
    "Renaca",
    "Renca",
    "Rengo",
    "Rinconada",
    "Rio Bueno",
    "San Antonio",
    "San Bernardo",
    "San Carlos",
    "San Felipe",
    "San Fernando",
    "San Javier",
    "San Martin",
    "San Pedro",
    "Santa Barbara",
    "Santiago",
    "Talagante",
    "Talca",
    "Talcahuano",
    "Tarapaca",
    "Temuco",
    "Tome",
    "Valdivia",
    "Vallenar",
    "Villa Alemana",
    "Villarrica",
    "Vitacura",
  ],
  China: [
    "Aishang",
    "Aizhou",
    "Aksu",
    "Anbang",
    "Anbu",
    "Ancheng",
    "Anda",
    "Andong",
    "Angu",
    "Anguo",
    "Anhu",
    "Anji",
    "Anjiang",
    "Ankang",
    "Anke",
    "Anqing",
    "Anqiu",
    "Anshan",
    "Anshun",
    "Ansu",
    "Anxi",
    "Anyang",
    "Anying",
    "Anzhou",
    "Anzi",
    "Aojiang",
    "Aoma",
    "Bagong",
    "Baicheng",
    "Baidu",
    "Baihua",
    "Baile",
    "Bailicun",
    "Baipo",
    "Baise City",
    "Baisha",
    "Baishan",
    "Baishi",
    "Baitang",
    "Baixiang",
    "Baixin",
    "Baiyashi",
    "Baiyi",
    "Baiyin",
    "Baiyu",
    "Baiyun",
    "Baizhang",
    "Baliqiao",
    "Bamao",
    "Bangke",
    "Bangtou",
    "Bantou",
    "Banxi",
    "Banyou",
    "Baocheng",
    "Baodi",
    "Baodian",
    "Baoding",
    "Baojiang",
    "Baoluo",
    "Baoqing",
    "Baoshan",
    "Baotao",
    "Baotian",
    "Baotou",
    "Baotou",
    "Baoxing",
    "Baoyu",
    "Base",
    "Baying",
    "Bazhou",
    "Baziqiang",
    "Beichan",
    "Beidou",
    "Beihai",
    "Beijie",
    "Beijing",
    "Beilin",
    "Beilun",
    "Beishan",
    "Beixi",
    "Beixiao",
    "Beiying",
    "Beiyuan",
    "Beiyuan",
    "Bengbu",
    "Benxi",
    "Bianzhuang",
    "Bibo",
    "Bijie",
    "Binhe",
    "Binjiang",
    "Binzhou",
    "Biyang",
    "Bobai",
    "Bohai",
    "Boji",
    "Bole",
    "Bole",
    "Boli",
    "Boshan",
    "Boxing",
    "Bozhou",
    "Buxin",
    "Caijing",
    "Caishen",
    "Caiyuan",
    "Caiyuan",
    "Cangnan",
    "Cangshang",
    "Cangzhou",
    "Caopu",
    "Caoxi",
    "Caozhai",
    "Cengang",
    "Cenyang",
    "Cezi",
    "Chadianzi",
    "Chaicun",
    "Chaigoubu",
    "Chamdo",
    "Changchao",
    "Changchun",
    "Changde",
    "Changfeng",
    "Changhe",
    "Changjian",
    "Changjiang",
    "Changle",
    "Changle",
    "Changning",
    "Changping",
    "Changqing",
    "Changsha",
    "Changshan",
    "Changsheng",
    "Changsheng",
    "Changshu City",
    "Changting",
    "Changtu",
    "Changxiacun",
    "Changxin",
    "Changzheng",
    "Changzhi",
    "Changzhong",
    "Changzhou",
    "Chaohu",
    "Chaoyang",
    "Chaoyang",
    "Chaozhou",
    "Chen Xian",
    "Chendu",
    "Chengbei",
    "Chengdong",
    "Chengdong",
    "Chengdu",
    "Chengfeng",
    "Chengguan",
    "Chenghua",
    "Chengjian",
    "Chengmai",
    "Chengnan",
    "Chengqiao",
    "Chenguang",
    "Chengxi",
    "Chengxian Chengguanzhen",
    "Chengxiang",
    "Chengxiang",
    "Chengyang",
    "Chengzhong",
    "Chenyang",
    "Chenzhou",
    "Chifeng",
    "Chiping",
    "Chizhou",
    "Chongqing",
    "Chongwen",
    "Chongxian",
    "Choucheng",
    "Chuansha",
    "Chum",
    "Chunhua",
    "Chunjiang",
    "Chuxiong",
    "Chuzhou",
    "Cicheng",
    "Cili",
    "Citai",
    "Cixi",
    "Cuibei",
    "Cuiyuan",
    "Cuizhu",
    "Cuntou",
    "Dabaizhuang",
    "Dabao",
    "Dabu",
    "Dachen",
    "Dachuan",
    "Dagang",
    "Dahai",
    "Dahuaishu",
    "Daidong",
    "Daishan",
    "Daixi",
    "Daizhong",
    "Daji",
    "Dajie",
    "Daju",
    "Dali",
    "Dalian",
    "Dampu",
    "Dancheng",
    "Dandong",
    "Danfeng",
    "Dangshan",
    "Dangyang",
    "Danjiang",
    "Danjiangkou",
    "Danyang",
    "Danzao",
    "Danzhou",
    "Daojiang",
    "Daotou",
    "Daoxu",
    "Daqiao",
    "Daqing",
    "Daqing",
    "Daqiuzhuang",
    "Dasha",
    "Dashiqiao",
    "Dashun",
    "Datang",
    "Datong",
    "Dawa",
    "Dawang",
    "Dawukou",
    "Daxie",
    "Daxing",
    "Daxing",
    "Dayan",
    "Dayang",
    "Daye",
    "Dayun",
    "Dazhong",
    "Dazhou",
    "Dazu",
    "Defeng",
    "Dehui",
    "Dengbu",
    "Dengzhou",
    "Deqing",
    "Deyang",
    "Deyuan",
    "Deyue",
    "Dezhou",
    "Diankou",
    "Dianzhong",
    "Dianzi",
    "Digang",
    "Dingcheng",
    "Dinghai",
    "Dingqiao",
    "Dingtao",
    "Dingxi",
    "Dingzha",
    "Dingzhou",
    "Dipu",
    "Dongchang",
    "Dongcheng",
    "Dongcheng",
    "Dongcun",
    "Dongdu",
    "Dongfang",
    "Dongfeng",
    "Dongguan",
    "Donghai",
    "Donghe",
    "Donghong",
    "Donghuxu",
    "Dongjiaying",
    "Dongli",
    "Donglin",
    "Dongliu",
    "Dongning",
    "Dongshen",
    "Dongsheng",
    "Dongshi",
    "Dongtou",
    "Dongxi",
    "Dongxiao",
    "Dongxin",
    "Dongxing",
    "Dongyang",
    "Dongying",
    "Doujiang",
    "Doumen",
    "Dujiacun",
    "Dungdo",
    "Duoba",
    "Dushu",
    "Duwei",
    "Duyun",
    "Edong",
    "Ejia",
    "Enshi",
    "Erqi",
    "Eshan",
    "Ezhou",
    "Fangqiao",
    "Fangshan",
    "Fangxian Chengguanzhen",
    "Fangzhuang",
    "Fangzi",
    "Fanjing",
    "Fanzhong",
    "Feihong",
    "Feilong",
    "Feixian",
    "Feiyun",
    "Feiyun",
    "Fendou",
    "Fendou",
    "Fengcheng",
    "Fengcheng",
    "Fenggang",
    "Fengjiang",
    "Fenglin",
    "Fengling",
    "Fengming",
    "Fengnan",
    "Fengqi",
    "Fengqiao",
    "Fengqing",
    "Fengshan",
    "Fengshang",
    "Fengshun",
    "Fengtai",
    "Fengting",
    "Fengxian",
    "Fengxiang",
    "Fengzhou",
    "Fenshang",
    "Fenyang",
    "Foshan",
    "Fotang",
    "Fuchun",
    "Fuchunjiang",
    "Fudao",
    "Fuding",
    "Fugu",
    "Fuhao",
    "Fuling",
    "Fumin",
    "Fuqiang",
    "Furong",
    "Fusheng",
    "Fushun",
    "Futian",
    "Fuxin",
    "Fuyang",
    "Fuyang",
    "Fuzhou",
    "Fuzhou",
    "Gaiwei",
    "Gaiyu",
    "Gamlung",
    "Gangcun",
    "Ganjiang",
    "Gannan",
    "Ganyao",
    "Ganzhou",
    "Gaobeidian",
    "Gaobu",
    "Gaocheng",
    "Gaoji",
    "Gaoping",
    "Gaoqiao",
    "Gaotang",
    "Gaoting",
    "Gaoxin",
    "Gaozhao",
    "Gaozhou",
    "Gejiu",
    "Gongchang",
    "Gongjiang",
    "Gongjiao",
    "Gongli",
    "Gongyi",
    "Gongyuan",
    "Goushan",
    "Gouzhuang",
    "Guali",
    "Guancheng",
    "Guangchang",
    "Guangdong",
    "Guangming",
    "Guangtai",
    "Guangtong",
    "Guangxi",
    "Guangyuan",
    "Guangzhou",
    "Guanli",
    "Guanqiao",
    "Guantian",
    "Gucheng",
    "Gucheng",
    "Guigang",
    "Guigang",
    "Guili",
    "Guilin",
    "Guixi",
    "Guiyang",
    "Guiyuan",
    "Guizhoumanzuxiang",
    "Gujiang",
    "Gujiao",
    "Guli",
    "Gunan",
    "Guodian",
    "Guohe",
    "Guoji",
    "Guoshang",
    "Guozhen",
    "Gushan",
    "Gushi",
    "Guxiang",
    "Guyong",
    "Guyuan",
    "Gyari",
    "Haibei",
    "Haicheng",
    "Haidian",
    "Haifu",
    "Haihong",
    "Haijing",
    "Haikou",
    "Hailar",
    "Hailin",
    "Hainan",
    "Haitang",
    "Haixing",
    "Haiyang",
    "Haiyu",
    "Haizhou",
    "Handan",
    "Hangbu",
    "Hangong",
    "Hangu",
    "Hangzhou",
    "Hanjiang",
    "Hanshan",
    "Hanshang",
    "Hanting",
    "Hanzhong",
    "Haocheng",
    "Haozhuang",
    "Harbin",
    "Hebei",
    "Hebei",
    "Hebi",
    "Hecheng",
    "Hechi",
    "Hechi",
    "Hechun",
    "Hecun",
    "Hedong",
    "Hefei",
    "Hegang",
    "Heibei",
    "Heihe",
    "Hejiang",
    "Hejin",
    "Hekou",
    "Heliang",
    "Hengdian",
    "Henghe",
    "Hengjie",
    "Hengshui",
    "Hengyang",
    "Hengzhou",
    "Heping",
    "Heshun",
    "Hetao",
    "Hetian",
    "Hexi",
    "Hexun",
    "Heyi",
    "Heyuan",
    "Heyuan",
    "Heze",
    "Hezhou",
    "Hezhu",
    "Hezuo",
    "Hohhot",
    "Hongchang",
    "Honglin",
    "Hongling",
    "Hongpan",
    "Hongqiao",
    "Hongshan",
    "Hongshun",
    "Hongtang",
    "Hongwei",
    "Hongxi",
    "Hongyan",
    "Hongyangshu",
    "Hongyuan",
    "Hongyun",
    "Houxiangcheng",
    "Houzhai",
    "Huabei",
    "Huacheng",
    "Huadian",
    "Huadong",
    "Huafeng",
    "Huagong",
    "Huagongchang",
    "Huaibei",
    "Huaihe",
    "Huaihua",
    "Huainan",
    "Huairou",
    "Huajiashan",
    "Huajie",
    "Huali",
    "Hualin",
    "Hualong",
    "Huamei",
    "Huanan",
    "Huangbao",
    "Huangdao",
    "Huangdu",
    "Huanggang",
    "Huanghou",
    "Huanghu",
    "Huanghua",
    "Huangjia",
    "Huangjue",
    "Huangshan",
    "Huangshi",
    "Huangshi",
    "Huangxuan",
    "Huangyan",
    "Huangyuan Chengguanzhen",
    "Huangze",
    "Huangzhai",
    "Huangzhou",
    "Huanzhen",
    "Huaqiang",
    "Huaqiao",
    "Huashan",
    "Huatan",
    "Huating",
    "Huawei",
    "Huaxia",
    "Huaxin",
    "Huaxing",
    "Huayang",
    "Huaying",
    "Huayuan",
    "Huayue",
    "Huayuji",
    "Huazhong",
    "Huazhou",
    "Huge",
    "Huicheng",
    "Huigang",
    "Huilongba",
    "Huimin",
    "Huimin",
    "Huixin",
    "Huiyang",
    "Huizhou",
    "Hulan",
    "Huling",
    "Hulu",
    "Huludao",
    "Huoshan",
    "Huozhou",
    "Hupi",
    "Huquan",
    "Hushan",
    "Huzhen",
    "Huzhou",
    "Jiadi",
    "Jiading",
    "Jiafu",
    "Jiahao",
    "Jiahe Chengguanzhen",
    "Jiahu",
    "Jialing",
    "Jiamusi",
    "Jianci",
    "Jiangbei",
    "Jiangchuanlu",
    "Jiangdong",
    "Jiangguantun",
    "Jiangjia",
    "Jiangjin Qu",
    "Jiangkou",
    "Jiangmen",
    "Jiangnan",
    "Jiangnan",
    "Jiangshan",
    "Jiangtian",
    "Jiangwan",
    "Jiangwang",
    "Jiangyan",
    "Jiangyin",
    "Jiangyou",
    "Jianning",
    "Jianxing",
    "Jianyang",
    "Jianyuan",
    "Jianzhong",
    "Jiaojiang",
    "Jiaokou",
    "Jiaotong",
    "Jiaotou",
    "Jiaowei",
    "Jiaoyu",
    "Jiaoyuan",
    "Jiaoyun",
    "Jiaozhou",
    "Jiaozuo",
    "Jiaqin",
    "Jiaxin",
    "Jiaxing",
    "Jiaying",
    "Jiayuan",
    "Jiayue",
    "Jiazhuang",
    "Jicheng",
    "Jiedong",
    "Jiefang",
    "Jiehe",
    "Jielong",
    "Jielong",
    "Jielong",
    "Jieshou",
    "Jiexi",
    "Jiexiu",
    "Jieyang",
    "Jifeng",
    "Jiguang",
    "Jilin City",
    "Jimo",
    "Jin Jiang",
    "Jinan",
    "Jinbi",
    "Jincheng",
    "Jinchuan",
    "Jinci",
    "Jinda",
    "Jinde",
    "Jindi",
    "Jindian",
    "Jinding",
    "Jindou",
    "Jindu",
    "Jingbei",
    "Jingcheng",
    "Jingdezhen",
    "Jingdong",
    "Jingdu",
    "Jingfeng",
    "Jinghai",
    "Jinghe",
    "Jinghong",
    "Jinghu",
    "Jinghua",
    "Jingjiang",
    "Jinglian",
    "Jinglong",
    "Jingmen",
    "Jingning Chengguanzhen",
    "Jingshan",
    "Jingtian",
    "Jinguang",
    "Jingxi",
    "Jingyuan",
    "Jingzhong",
    "Jingzhou",
    "Jinhai",
    "Jinhe",
    "Jinhong",
    "Jinhu",
    "Jinhua",
    "Jining",
    "Jinjiang",
    "Jinli",
    "Jinling",
    "Jinlong",
    "Jinlu",
    "Jinlun",
    "Jinma",
    "Jinmao",
    "Jinmen",
    "Jinming",
    "Jinnan",
    "Jinpan",
    "Jinpu",
    "Jinqiao",
    "Jinqingzhen",
    "Jinsha",
    "Jinshadi",
    "Jinshan",
    "Jinshi",
    "Jintan",
    "Jintian",
    "Jinwo",
    "Jinxiangzhen",
    "Jinxing",
    "Jinyang",
    "Jinyi",
    "Jinyin",
    "Jinying",
    "Jinyuan",
    "Jinyun",
    "Jinzao",
    "Jinzhong",
    "Jinzhou",
    "Jishou",
    "Jishu",
    "Jiuba",
    "Jiuguan",
    "Jiuzong",
    "Jixi",
    "Jiya",
    "Jiyang",
    "Jiyang",
    "Jizhou",
    "Jondag",
    "Juchuan",
    "Juhua",
    "Julong",
    "Jundu",
    "Junlin",
    "Junping",
    "Junpu",
    "Kaifeng",
    "Kaihua",
    "Kaili",
    "Kaiyuan",
    "Kaiyun",
    "Kandun",
    "Kang Xian",
    "Kangcun",
    "Kangda",
    "Karamay",
    "Kashgar",
    "Kecheng",
    "Keqiao",
    "Keshan",
    "Korla",
    "Kouqian",
    "Kouquan",
    "Kuangyan",
    "Kuicheng",
    "Kuiju",
    "Kuishan",
    "Kumul",
    "Kunlun",
    "Kunming",
    "Kunshan",
    "Kunshan",
    "Kunyang",
    "Kuytun",
    "Laibin",
    "Laiwu",
    "Laiyi",
    "Lake",
    "Langfang",
    "Langli",
    "Langzhong",
    "Lanhai",
    "Lanjiang",
    "Lanping",
    "Lantang",
    "Lanxi",
    "Lanzhou",
    "Laohekou",
    "Laonian",
    "Lecheng",
    "Ledong",
    "Lengshuijiang",
    "Leshan",
    "Lhasa",
    "Lianghu",
    "Liangping",
    "Liangzhu",
    "Lianhua",
    "Lianjiang",
    "Liannan",
    "Lianping",
    "Lianshan",
    "Lianshi",
    "Lianshui",
    "Lianyuan",
    "Lianyungang",
    "Liaocheng",
    "Liaoyang",
    "Liaoyang",
    "Liaoyuan",
    "Libang",
    "Licheng",
    "Licheng",
    "Lida",
    "Lidong",
    "Lidu",
    "Lihou",
    "Lijiang",
    "Lijing",
    "Linbei",
    "Lincang",
    "Lincheng",
    "Lincheng",
    "Lindian",
    "Linfen",
    "Lingang",
    "Lingcheng",
    "Lingcheng",
    "Lingdong",
    "Linghu",
    "Lingnga",
    "Lingshi",
    "Lingshui",
    "Lingxi",
    "Lingzhi",
    "Linhai",
    "Linjiang",
    "Linjiang",
    "Linkou",
    "Linnan",
    "Linping",
    "Linqi",
    "Linqing",
    "Linqu",
    "Lintong",
    "Linxia Chengguanzhen",
    "Linyi",
    "Linze",
    "Linzhou",
    "Lishang",
    "Lishu",
    "Lishui",
    "Liu`an",
    "Liucheng",
    "Liudao",
    "Liudong",
    "Liuhe",
    "Liuheng",
    "Liujian",
    "Liujiang",
    "Liulin",
    "Liushi",
    "Liuzhou",
    "Liwu",
    "Liyuan",
    "Lize",
    "Longchuan",
    "Longdong",
    "Longfeng",
    "Longgang",
    "Longgong",
    "Longhu",
    "Longhua",
    "Longhua",
    "Longjian",
    "Longjiang",
    "Longjin",
    "Longlin",
    "Longmao",
    "Longmen",
    "Longnan",
    "Longquan",
    "Longshan",
    "Longtai",
    "Longteng",
    "Longxiang",
    "Longxin",
    "Longxing",
    "Longxun",
    "Longyan",
    "Longzhou",
    "Loufan",
    "Lucheng",
    "Lugouqiao",
    "Luhua",
    "Lujiazhi",
    "Lung",
    "Luobu",
    "Luocheng",
    "Luofang",
    "Luohe",
    "Luohu",
    "Luohu",
    "Luoling",
    "Luomen",
    "Luorong",
    "Luoxi",
    "Luoyang",
    "Luoyang",
    "Luoyang",
    "Luoyuan",
    "Luozhuang",
    "Luqiao",
    "Luqiao",
    "Luquan",
    "Lushang",
    "Luwan",
    "Luzhou",
    "Luzhou",
    "Macao",
    "Maji",
    "Majin",
    "Maoba",
    "Maodian",
    "Maodun",
    "Maoming",
    "Maotai",
    "Mapai",
    "Mawei",
    "Mayao",
    "Mayu",
    "Meicheng",
    "Meijiang",
    "Meilian",
    "Meilin",
    "Meishan",
    "Meixi",
    "Meiyu",
    "Meiyuan",
    "Meizhou",
    "Meizhou",
    "Mengshan",
    "Mengyin",
    "Mengzhou",
    "Mengzi",
    "Mianyang",
    "Miaogao",
    "Mingde",
    "Mingdu",
    "Mingjia",
    "Mingjing",
    "Mingshui",
    "Mingxi",
    "Mingxing",
    "Mingzhou",
    "Mingzhou",
    "Mingzhu",
    "Minhang",
    "Minjiang",
    "Minjie",
    "Minzhu",
    "Minzu",
    "Mishan",
    "Miyun",
    "Moushan",
    "Mudanjiang",
    "Muling",
    "Nagqu",
    "Name",
    "Nanchang",
    "Nanchong",
    "Nanda",
    "Nandu",
    "Nanfang",
    "Nanguo",
    "Nangxian",
    "Nanhai",
    "Nanhan",
    "Nanhe",
    "Nanhu",
    "Nanjiang",
    "Nanjin",
    "Nanjing",
    "Nankai",
    "Nanlong",
    "Nanma",
    "Nanmen",
    "Nanning",
    "Nanning",
    "Nanping",
    "Nanshan",
    "Nantang",
    "Nantong",
    "Nanxi",
    "Nanxianglong",
    "Nanxun",
    "Nanyang",
    "Nanying",
    "Nanyuan",
    "Nanzhao",
    "Nanzhuang",
    "Napo",
    "Naxi Yaozuxiang",
    "Neijiang",
    "Nenjiang",
    "Niaojiang",
    "Ningbo",
    "Ningde",
    "Ninghai",
    "Ninghe",
    "Ningyi",
    "Niulanshan",
    "Niunaichang",
    "Nongkeng",
    "Nyingchi County",
    "Oucheng",
    "Oujiang",
    "Panjin Shi",
    "Panxi",
    "Panzhihua",
    "Pengcheng",
    "Penglai",
    "Pengshan",
    "Pengyu",
    "Pingchuan",
    "Pingdingshan",
    "Pinghu",
    "Pinghu",
    "Pingliang",
    "Pinglu",
    "Pingwang",
    "Pingxiang",
    "Pingyang",
    "Pingyao County",
    "Pingyi",
    "Pingyin",
    "Pingyuan",
    "Pizhou",
    "Potang",
    "Pucheng",
    "Pudong",
    "Puning",
    "Puning",
    "Puqing",
    "Putian",
    "Putian",
    "Putuo",
    "Putuoshan",
    "Puyang",
    "Puyang",
    "Puyuan",
    "Qiandao",
    "Qiandaohu",
    "Qianhai",
    "Qianjiang",
    "Qianjin",
    "Qianling",
    "Qianqing",
    "Qianshan",
    "Qiantan",
    "Qiantang",
    "Qianyu",
    "Qiaodou",
    "Qiaolian",
    "Qiaoting",
    "Qiaoxia",
    "Qiaoyuan",
    "Qiaozhen",
    "Qidu",
    "Qijiashan",
    "Qike",
    "Qingdao",
    "Qingfeng Chengguanzhen",
    "Qinggang",
    "Qinghai",
    "Qinghu",
    "Qingma",
    "Qingnian",
    "Qingquan",
    "Qingshan",
    "Qingshuiwan",
    "Qingtian",
    "Qingtian",
    "Qingtong",
    "Qingxin",
    "Qingyang",
    "Qingyang",
    "Qingyuan",
    "Qingyuan",
    "Qingzhen",
    "Qingzhou",
    "Qinhuangdao",
    "Qinjiang",
    "Qinzhou",
    "Qionghai",
    "Qiongshan",
    "Qiqihar",
    "Qitai",
    "Quanzhou",
    "Quanzhou",
    "Qujiang",
    "Qujing",
    "Qushan",
    "Quzhou",
    "Renhe",
    "Renhou",
    "Renmin",
    "Renqiu",
    "Renrong",
    "Rizhao",
    "Rongjiang",
    "Rongjiang",
    "Rongjin",
    "Rongle",
    "Rongshan",
    "Rongsheng",
    "Rongxian",
    "Rongxing",
    "Ruicheng",
    "Ruifeng",
    "Ruihu",
    "Ruili",
    "Ruiting",
    "Ruixing",
    "Ruiying",
    "Rujia",
    "Sailing",
    "Sandu",
    "Sanguan",
    "Sanhe",
    "Sanjiang",
    "Sanjiangkou",
    "Sanjiaocheng",
    "Sanjiu",
    "Sanlian",
    "Sanmao",
    "Sanmen",
    "Sanmenxia",
    "Sanming",
    "Sanpailou",
    "Santai",
    "Santiaolou",
    "Sanya",
    "Sanyi",
    "Sanyou",
    "Sanyuanpu",
    "Sanzhang",
    "Shamen",
    "Shancheng",
    "Shancheng",
    "Shangbao",
    "Shangbu",
    "Shangbu",
    "Shangdong",
    "Shangfang",
    "Shanghai",
    "Shanghu",
    "Shangjiang",
    "Shangluo",
    "Shangma",
    "Shangnan",
    "Shangqiu",
    "Shangrao",
    "Shangsha",
    "Shangsi",
    "Shangtang",
    "Shangwang",
    "Shangwu",
    "Shangxi",
    "Shangye",
    "Shangying",
    "Shangzhang",
    "Shangzhi",
    "Shangzhou",
    "Shanhe",
    "Shanhu",
    "Shanrui",
    "Shanshui",
    "Shantou",
    "Shantou",
    "Shanwei",
    "Shanzhuang",
    "Shaoguan",
    "Shaoguang",
    "Shaowu",
    "Shaoxing",
    "Shashan",
    "Shashi",
    "Shecheng",
    "Shejiazhuang",
    "Shekou",
    "Shengdu",
    "Shengfang",
    "Shengli",
    "Shengping",
    "Shengshan",
    "Shenjiamen",
    "Shenlong",
    "Shennan",
    "Shensha",
    "Shenwan",
    "Shenyan",
    "Shenyang",
    "Shenzhen",
    "Shenzhou",
    "Shibei",
    "Shifan",
    "Shifang",
    "Shihezi",
    "Shiji",
    "Shijiazhuang",
    "Shijiazhuang",
    "Shijing",
    "Shijingxiang",
    "Shiling",
    "Shilou",
    "Shima",
    "Shinian",
    "Shipu",
    "Shiqi",
    "Shiqiaopu",
    "Shishi",
    "Shishi",
    "Shixia",
    "Shiyan",
    "Shizheng",
    "Shizhong",
    "Shizhu",
    "Shouchang",
    "Shouchun",
    "Shouguang",
    "Shouyang",
    "Shuangcheng",
    "Shuangcheng",
    "Shuanglin",
    "Shuanglong",
    "Shuangqiao",
    "Shuangshipu",
    "Shuangtang",
    "Shuangxi",
    "Shuangyashan",
    "Shuguang",
    "Shuhong",
    "Shuigang",
    "Shuiku",
    "Shuitian",
    "Shuitou",
    "Shuiwu",
    "Shule",
    "Shuliang",
    "Shunde",
    "Shunshui",
    "Shuozhou",
    "Shuyi",
    "Sichuan",
    "Side",
    "Siduxu",
    "Sijiqing",
    "Sili",
    "Simao",
    "Simcun",
    "Simen",
    "Siting",
    "Siyang",
    "Sizhou",
    "Songling",
    "Songyang",
    "Songyuan",
    "Suihua",
    "Suining",
    "Suixi",
    "Suji",
    "Sundun",
    "Sungang",
    "Suqian",
    "Suqian",
    "Suxi",
    "Suzhou",
    "Suzhou",
    "Suzhuang",
    "Taicang",
    "Taihe",
    "Taikang",
    "Tailai",
    "Taili",
    "Taimen",
    "Taiping",
    "Taiping",
    "Taipingyan",
    "Taishan",
    "Taishan",
    "Taixing",
    "Taiyuan",
    "Taizhou",
    "Tancheng",
    "Tancheng",
    "Tang Xian",
    "Tangbian",
    "Tangchang",
    "Tangcheng",
    "Tangdukou",
    "Tangtou",
    "Tangxi",
    "Tangxia",
    "Tangyuan",
    "Tanshi",
    "Taocheng",
    "Taohua",
    "Taoshan",
    "Taoshan",
    "Taoxi",
    "Taoyuan",
    "Taozhuang",
    "Tara",
    "Tashi",
    "Taxi",
    "Tengjiao",
    "Tengzhou",
    "Tianbao",
    "Tianchang",
    "Tianchang",
    "Tiancheng",
    "Tianchi",
    "Tiandong",
    "Tianfu",
    "Tiangang",
    "Tianhai",
    "Tianhong",
    "Tianjin",
    "Tianle",
    "Tianli",
    "Tianma",
    "Tianning",
    "Tianshan",
    "Tianshi",
    "Tianshui",
    "Tiantai Chengguanzhen",
    "Tianyang",
    "Tianyi",
    "Tianyuan",
    "Tianzhen",
    "Tianzhu",
    "Tieli",
    "Tieling",
    "Tieshan",
    "Tingpang",
    "Tongbai",
    "Tongchuan",
    "Tongcun",
    "Tongji",
    "Tongliao",
    "Tongling",
    "Tonglu",
    "Tongmin",
    "Tongren",
    "Tongwang",
    "Tongxiang",
    "Tongyu",
    "Tongyuan",
    "Tumu",
    "Turpan Diqu",
    "Wanbao",
    "Wangfu",
    "Wangluo",
    "Wangshang",
    "Wangsu",
    "Wanjia",
    "Wanning",
    "Wanquan",
    "Wansong",
    "Wanxi",
    "Weibo",
    "Weidun",
    "Weifang",
    "Weihai",
    "Weinan",
    "Weitang",
    "Weitang",
    "Weiyang",
    "Wenchang",
    "Wenhou",
    "Wenlan",
    "Wenlin",
    "Wenling",
    "Wenquan",
    "Wenshan",
    "Wenshang",
    "Wenxi",
    "Wenxing",
    "Wenzhou",
    "Wuhai",
    "Wuhai",
    "Wuhan",
    "Wuhou",
    "Wuhu",
    "Wuling",
    "Wuning",
    "Wushan",
    "Wutai",
    "Wutong",
    "Wuwei",
    "Wuxi",
    "Wuxia",
    "Wuxing",
    "Wuyang",
    "Wuyi",
    "Wuyishan",
    "Wuzhen",
    "Wuzhishan",
    "Wuzhong",
    "Wuzhou",
    "Xiabancheng",
    "Xiadianmiao",
    "Xiakou",
    "Xiamen",
    "Xianchang",
    "Xiandu",
    "Xiangcheng",
    "Xiangfan",
    "Xiangfeng",
    "Xiangjiang",
    "Xiangnan",
    "Xiangru",
    "Xiangshan",
    "Xiangsheng",
    "Xiangsi",
    "Xiangtan",
    "Xiangtong",
    "Xiangxi",
    "Xiangyi",
    "Xiangyuan",
    "Xianjiang",
    "Xianlin",
    "Xianning",
    "Xianqiao",
    "Xianren",
    "Xianrenqiao",
    "Xiantao",
    "Xianyang",
    "Xianyou",
    "Xiaogan",
    "Xiaogang",
    "Xiaoheshan",
    "Xiaojiahe",
    "Xiaojiang",
    "Xiaomi",
    "Xiaoqu",
    "Xiaoshan",
    "Xiaoshun",
    "Xiaosi",
    "Xiaoxi",
    "Xiaozheng",
    "Xiapu",
    "Xiasha",
    "Xiashi",
    "Xiatangyunxiang",
    "Xiayuan",
    "Xiazhi",
    "Xiazhuang",
    "Xibei",
    "Xibu",
    "Xichang",
    "Xichong",
    "Xidian",
    "Xiedian",
    "Xieqiao",
    "Xiezhou",
    "Xigu",
    "Xiguan",
    "Xihu",
    "Xijiao",
    "Xijiao",
    "Xijing",
    "Xikou",
    "Xiling",
    "Ximeng",
    "Xinao",
    "Xincao",
    "Xinchang",
    "Xinchen",
    "Xincheng",
    "Xindi",
    "Xindu",
    "Xingang",
    "Xingchang",
    "Xingchen",
    "Xingcheng",
    "Xingfeng",
    "Xingguang",
    "Xinghua",
    "Xingqiao",
    "Xingtai",
    "Xinguang",
    "Xingwang",
    "Xingxian",
    "Xingxiangcun",
    "Xingxing",
    "Xingye",
    "Xingyi",
    "Xingyuan",
    "Xinhua",
    "Xinhua",
    "Xinhuang",
    "Xinhui",
    "Xining",
    "Xinjian",
    "Xinjiang",
    "Xinmin",
    "Xinning",
    "Xinqiao",
    "Xinrong",
    "Xinsha",
    "Xinshijie",
    "Xintai",
    "Xintangbian",
    "Xintian",
    "Xinxiang",
    "Xinxing",
    "Xinyang",
    "Xinyi",
    "Xinyou",
    "Xinzha",
    "Xinzheng",
    "Xinzhou",
    "Xiping",
    "Xiqu",
    "Xishan",
    "Xitang",
    "Xiushan",
    "Xiushui",
    "Xiuying",
    "Xiuyu",
    "Xixi",
    "Xiyang",
    "Xiyue",
    "Xizhang",
    "Xizhou",
    "Xuancheng",
    "Xuanwu",
    "Xuanzhou",
    "Xuchang",
    "Xucheng",
    "Xucun",
    "Xuefeng",
    "Xuexi",
    "Xuexiao",
    "Xuezhuang",
    "Xuhui",
    "Xunxian",
    "Xunyi Chengguanzhen",
    "Xushan",
    "Xuzhou",
    "Yahao",
    "Yajin",
    "Yalan",
    "Yalongwan",
    "Yanchang",
    "Yancheng",
    "Yancheng",
    "Yandang",
    "Yandao",
    "Yangce",
    "Yangcun",
    "Yangda",
    "Yangfen",
    "Yanggu",
    "Yangjiang",
    "Yangling",
    "Yanglou",
    "Yangmiao",
    "Yangpu",
    "Yangqitan",
    "Yangqu",
    "Yangquan",
    "Yangshan",
    "Yanguan",
    "Yangxunqiao",
    "Yangzhong",
    "Yangzhou",
    "Yangzhou",
    "Yanhai",
    "Yanji",
    "Yanjiang",
    "Yanjiao",
    "Yanjiao",
    "Yanjing",
    "Yanming",
    "Yanshi",
    "Yanta",
    "Yanta",
    "Yantai",
    "Yantan",
    "Yaocheng",
    "Yaofeng",
    "Yaohua",
    "Yaoluoping",
    "Yaozhuang",
    "Yayu",
    "Yibin",
    "Yichang",
    "Yicheng",
    "Yicheng",
    "Yichun",
    "Yidong",
    "Yifeng",
    "Yifu",
    "Yijin",
    "Yijing",
    "Yili",
    "Yinchuan",
    "Yindian",
    "Yinfang",
    "Yingcai",
    "Yingchuan",
    "Yingdu",
    "Yinggen",
    "Yingjia",
    "Yingjie",
    "Yingqian",
    "Yingtai",
    "Yinhai",
    "Yining",
    "Yinjiang",
    "Yinliang",
    "Yinshan",
    "Yintian",
    "Yinxin",
    "Yinzhou",
    "Yiqiao",
    "Yisa",
    "Yishan",
    "Yishui",
    "Yitian",
    "Yiting",
    "Yiwu",
    "Yiwu",
    "Yiyang",
    "Yizheng",
    "Yizhong",
    "Yizhou",
    "Yongchuan",
    "Yongjia",
    "Yongjiawan",
    "Yongjin",
    "Yongjiu",
    "Yongkang",
    "Yongning",
    "Yongqiang",
    "Yongxing",
    "Yongzhou",
    "Youchegang",
    "Youjia",
    "Youjiang",
    "Youshi",
    "Youyi",
    "Yuanchang",
    "Yuanheng",
    "Yuanli",
    "Yuanling",
    "Yuanping",
    "Yuanpu",
    "Yuanqu",
    "Yuantong",
    "Yuanyang",
    "Yucai",
    "Yucheng",
    "Yucheng",
    "Yuchi",
    "Yuecheng",
    "Yuehu",
    "Yuehua",
    "Yuele",
    "Yueliangwan",
    "Yueqing",
    "Yuetai",
    "Yuhai",
    "Yuhan",
    "Yuhang",
    "Yuhong",
    "Yuhuan",
    "Yuhuayuan",
    "Yuhui",
    "Yujing",
    "Yulin",
    "Yulong",
    "Yuncheng",
    "Yunfeng",
    "Yunfu",
    "Yungang",
    "Yungcun",
    "Yunling",
    "Yunnan",
    "Yunting",
    "Yunyang",
    "Yuting",
    "Yutou",
    "Yuxi",
    "Yuxi",
    "Yuxiang",
    "Yuyao",
    "Yuyuan",
    "Yuzhen",
    "Yuzhong Chengguanzhen",
    "Yuzi",
    "Zaoyang",
    "Zaozhuang",
    "Zhancheng",
    "Zhanghe",
    "Zhanghou",
    "Zhangjiagang",
    "Zhangjiagang",
    "Zhangjiajie",
    "Zhangjiakou",
    "Zhangjiang",
    "Zhangqi",
    "Zhangqiu",
    "Zhangshan",
    "Zhangshu",
    "Zhangye",
    "Zhangzhai",
    "Zhangzhou",
    "Zhangzhuang",
    "Zhanjiang",
    "Zhanmao",
    "Zhaobaoshan",
    "Zhaodong",
    "Zhaoqing",
    "Zhaotong",
    "Zhaoyu",
    "Zhaozhou",
    "Zhapu",
    "Zhedong",
    "Zhengda",
    "Zhengdi",
    "Zhengding",
    "Zhenghai",
    "Zhengjiang",
    "Zhengyuan",
    "Zhengzhou",
    "Zhenhua",
    "Zhenjiang",
    "Zhenxi",
    "Zhenxing",
    "Zhenzhou",
    "Zhenzhuquan",
    "Zheshang",
    "Zhicheng",
    "Zhijiang",
    "Zhili",
    "Zhima",
    "Zhixing",
    "Zhiye",
    "Zhiyuan",
    "Zhizhong",
    "Zhongdian",
    "Zhongfang",
    "Zhongfu",
    "Zhonggongmiao",
    "Zhongji",
    "Zhongjian",
    "Zhonglin",
    "Zhongnan",
    "Zhongqiao",
    "Zhongshan",
    "Zhongshan",
    "Zhongtang",
    "Zhongtian",
    "Zhongtu",
    "Zhongwei",
    "Zhongxiang",
    "Zhongxiang",
    "Zhongxin",
    "Zhongxing",
    "Zhongyang",
    "Zhongyicun",
    "Zhongyin",
    "Zhongyuan",
    "Zhongzhou",
    "Zhongzhou",
    "Zhoukou",
    "Zhoushan",
    "Zhoushan",
    "Zhouwangmiao",
    "Zhouxiang",
    "Zhuhai",
    "Zhuji",
    "Zhujiajian",
    "Zhujiajiao",
    "Zhulin",
    "Zhumadian",
    "Zhuozhou",
    "Zhuxi Chengguanzhen",
    "Zhuxiang",
    "Zhuyuan",
    "Zibo",
    "Zicheng",
    "Zigong",
    "Zijin",
    "Ziyang",
    "Ziyu",
    "Zongxoi",
    "Zoucheng",
    "Zunyi",
    "Zuxi",
  ],
  Colombia: [
    "Acacias",
    "Acevedo",
    "Aguachica",
    "Antioquia",
    "Arauca",
    "Armenia",
    "Atlantico",
    "Barrancabermeja",
    "Barranquilla",
    "Bello",
    "Bermudez",
    "Boyaca",
    "Bucaramanga",
    "Buenaventura",
    "Buga",
    "Cajica",
    "Calamar",
    "Caldas",
    "Candelaria",
    "Cartagena",
    "Cartago",
    "Cauca",
    "Chia",
    "Chiquinquira",
    "Chocho",
    "Columbia",
    "Corozal",
    "Cota",
    "Cumaribo",
    "Cundinamarca",
    "Dosquebradas",
    "Duitama",
    "El Colegio",
    "Engativa",
    "Envigado",
    "Espinal",
    "Florencia",
    "Floridablanca",
    "Fonseca",
    "Fundacion",
    "Fusagasuga",
    "Galapa",
    "Ginebra",
    "Giron",
    "Guarne",
    "Ibague",
    "Inirida",
    "Ipiales",
    "Itagui",
    "La Ceja",
    "La Estrella",
    "La Mesa",
    "La Union",
    "Los Patios",
    "Madrid",
    "Magdalena",
    "Manizales",
    "Miami",
    "Mitu",
    "Montenegro",
    "Mosquera",
    "Municipio de Copacabana",
    "Neiva",
    "Obando",
    "Palmira",
    "Pamplona",
    "Pasto",
    "Pereira",
    "Piedecuesta",
    "Pitalito",
    "Planadas",
    "Popayan",
    "Puerta Roja",
    "Puerto Tejada",
    "Purificacion",
    "Riohacha",
    "Rionegro",
    "Risaralda",
    "Sabana de Torres",
    "Sabaneta",
    "Sachica",
    "San Clemente",
    "San Diego",
    "San Gil",
    "San Martin",
    "Santa Marta",
    "Santa Rosa de Cabal",
    "Santa Rosa del Sur",
    "Santiago de Cali",
    "Silvania",
    "Sincelejo",
    "Soacha",
    "Sogamoso",
    "Soledad",
    "Sopo",
    "Tarapaca",
    "Tauramena",
    "Tenjo",
    "Tocancipa",
    "Tunja",
    "Turbaco",
    "Ubaque",
    "Urumita",
    "Valledupar",
    "Velez",
    "Villa del Rosario",
    "Villamaria",
    "Villavicencio",
    "Yopal",
    "Yotoco",
    "Yumbo",
  ],
  Congo: ["Banana", "Goma", "Kinshasa", "Likasi", "Lubumbashi"],
  "Costa Rica": [
    "Alajuela",
    "Alajuelita",
    "Alfaro",
    "Aserri",
    "Atenas",
    "Barva",
    "Cartago",
    "Colon",
    "Corazon de Jesus",
    "Coronado",
    "Coyol",
    "Curridabat",
    "Desamparados",
    "Escazu",
    "Esparza",
    "Filadelfia",
    "Grecia",
    "Guachipelin",
    "Guacima",
    "Guadalupe",
    "Guapiles",
    "Heredia",
    "Isla de Tibas",
    "Jaco",
    "La Garita",
    "Liberia",
    "Llorente",
    "Llorente",
    "Mercedes",
    "Mexico",
    "Miramar",
    "Moravia",
    "Palmares",
    "Paraiso",
    "Pavas",
    "Perez",
    "Poas",
    "Puerto Jimenez",
    "Puntarenas",
    "Quepos",
    "Quesada",
    "Sabanilla",
    "San Diego",
    "San Francisco",
    "San Francisco",
    "San Isidro",
    "San Joaquin",
    "San Jose de la Montana",
    "San Josecito",
    "San Juan",
    "San Marcos",
    "San Pablo",
    "San Pedro",
    "San Rafael",
    "San Rafael",
    "San Ramon",
    "Santa Ana",
    "Santa Cecilia",
    "Santa Cruz",
    "Santo Domingo",
    "Sarchi",
    "Siquirres",
    "Tres Rios",
    "Turrialba",
    "Turrucares",
    "Zapote",
    "Zarcero",
  ],
  Croatia: [
    "Antunovac",
    "Baska",
    "Baska Voda",
    "Bedekovcina",
    "Bestovje",
    "Betina",
    "Bibinje",
    "Bizovac",
    "Bjelovar",
    "Bracevci",
    "Brdovec",
    "Bregana",
    "Brela",
    "Brsadin",
    "Buje",
    "Cabuna",
    "Cavtat",
    "Cepin",
    "Cestica",
    "Core",
    "Crikvenica",
    "Dalj",
    "Darda",
    "Delnice",
    "Desinic",
    "Donja Pusca",
    "Donja Zelina",
    "Donji Kraljevec",
    "Dubrovnik",
    "Dugo Selo",
    "Fazana",
    "Fuzine",
    "Galgovo",
    "Glina",
    "Gornja Lomnica",
    "Gornji Kriz",
    "Gornji Stupnik",
    "Hreljin",
    "Icici",
    "Ivanec",
    "Ivanic-Grad",
    "Karlovac",
    "Karojba",
    "Kastel Gomilica",
    "Kastel Luksic",
    "Kastel Sucurac",
    "Koprivnica",
    "Kostrena",
    "Kraljevec na Sutli",
    "Krapina",
    "Kriz",
    "Krk",
    "Krsan",
    "Kutjevo",
    "Labin",
    "Lapad",
    "Lekenik",
    "Lepoglava",
    "Lovran",
    "Lukavec",
    "Lupoglav",
    "Lupoglav",
    "Makarska",
    "Matulji",
    "Medulin",
    "Mlini",
    "Mursko Sredisce",
    "Nedelisce",
    "Nova Gradiska",
    "Novi Marof",
    "Novi Vinodolski",
    "Novo Cice",
    "Nustar",
    "Okrug Gornji",
    "Opatija",
    "Orahovica",
    "Orebic",
    "Orle",
    "Oroslavje",
    "Osijek",
    "Pakrac",
    "Pazin",
    "Petrcane",
    "Petrinja",
    "Piskorevci",
    "Podastrana",
    "Podgajci Posavski",
    "Popovaca",
    "Postira",
    "Pregrada",
    "Prelog",
    "Primosten",
    "Privlaka",
    "Pula",
    "Rab",
    "Rakitje",
    "Rijeka",
    "Rijeka",
    "Rovinj",
    "Samobor",
    "Sapjane",
    "Senkovec",
    "Sesvete",
    "Sesvetski Kraljevec",
    "Sice",
    "Silas",
    "Simuni",
    "Sinj",
    "Sisak",
    "Slatina",
    "Slavonski Brod",
    "Sokolovac",
    "Solin",
    "Split",
    "Splitska",
    "Strmec",
    "Strmec Samoborski",
    "Sveti Ivan Zelina",
    "Tar",
    "Torcec",
    "Trogir",
    "Tuzno",
    "Umag",
    "Varazdinske Toplice",
    "Vardarac",
    "Velika Gorica",
    "Vetovo",
    "Vinica",
    "Vinkovci",
    "Virje",
    "Virovitica",
    "Viskovci",
    "Vojnic",
    "Vrata",
    "Vratisinec",
    "Vrbnik",
    "Vrbovec",
    "Vukovar",
    "Zabok",
    "Zadar",
    "Zagreb",
    "Zapresic",
    "Zlatar",
  ],
  Cuba: [
    "Bayamo",
    "Cienfuegos",
    "Habana",
    "Havana",
    "La Habana",
    "Las Tunas",
    "Matanzas",
    "Santiago de Cuba",
    "Varadero",
    "Villa",
  ],
  Cyprus: [
    "Agia Anna",
    "Aradippou",
    "Ayia Marina",
    "Chlorakas",
    "Deryneia",
    "Famagusta",
    "Geroskipou",
    "Kato Lakatamia",
    "Kato Polemidia",
    "Kiti",
    "Kyrenia",
    "Larnaca",
    "Laxia",
    "Limassol",
    "Livadia",
    "Morfou",
    "Mouttagiaka",
    "Nicosia",
    "Paphos",
    "Paralimni",
    "Parekklisha",
    "Prodromi",
    "Sotira",
    "Strovolos",
    "Tera",
  ],
  "Czech Republic": [
    "Adamov",
    "Albrechtice",
    "Arnoltice",
    "As",
    "Babice",
    "Banov",
    "Baska",
    "Batelov",
    "Bavory",
    "Bechyne",
    "Bela pod Bezdezem",
    "Belotin",
    "Benatky nad Jizerou",
    "Benesov",
    "Benesovice",
    "Bernartice",
    "Bernartice nad Odrou",
    "Beroun",
    "Bezdekov",
    "Bilina",
    "Bilina",
    "Bilovice",
    "Bilovice nad Svitavou",
    "Bitovany",
    "Blansko",
    "Blatna",
    "Blizevedly",
    "Blovice",
    "Blucina",
    "Bludov",
    "Bludovice",
    "Bochov",
    "Bohumin",
    "Bohunovice",
    "Bojanov",
    "Bojkovice",
    "Bolatice",
    "Bolebor",
    "Bor",
    "Boretice",
    "Borohradek",
    "Borotin",
    "Borovany",
    "Borsice",
    "Bosin",
    "Boskovice",
    "Bradlec",
    "Brandys nad Labem-Stara Boleslav",
    "Branky",
    "Branov",
    "Bratrikov",
    "Brezi",
    "Brezno",
    "Brezolupy",
    "Brezova",
    "Brno",
    "Brod",
    "Brodce",
    "Brodek",
    "Broumov",
    "Brtnice",
    "Bruntal",
    "Brusperk",
    "Bucovice",
    "Budyne nad Ohri",
    "Bukovec",
    "Bustehrad",
    "Bylnice",
    "Bystrice",
    "Bystrice",
    "Bystrice",
    "Bystrice nad Pernstejnem",
    "Bystrice pod Hostynem",
    "Cakovice",
    "Caslav",
    "Cebin",
    "Cechtice",
    "Cejc",
    "Cejkovice",
    "Celadna",
    "Celakovice",
    "Cepi",
    "Cercany",
    "Cerekvice nad Loucnou",
    "Cerhenice",
    "Cerna Hora",
    "Cerna za Bory",
    "Cerncice",
    "Cernosice",
    "Cernotin",
    "Cervene Pecky",
    "Cerveny Kostelec",
    "Ceska",
    "Ceska Skalice",
    "Ceska Trebova",
    "Ceska Ves",
    "Ceske Mezirici",
    "Cesky Brod",
    "Cesky Dub",
    "Cestice",
    "Cheb",
    "Cheznovice",
    "Chlumcany",
    "Chlumec",
    "Chlumec nad Cidlinou",
    "Chlupice",
    "Chocen",
    "Chocerady",
    "Chodov",
    "Chodov",
    "Chodova Plana",
    "Chomutov",
    "Chotebor",
    "Chotesov",
    "Chotetov",
    "Chotoviny",
    "Choustnik",
    "Choustnikovo Hradiste",
    "Chrast",
    "Chrast",
    "Chrastna",
    "Chribska",
    "Chroustovice",
    "Chrudim",
    "Chvalec",
    "Chyne",
    "Cista",
    "Citoliby",
    "Citov",
    "Citov",
    "Ckyne",
    "Ctidruzice",
    "Cvikov",
    "Dalovice",
    "Damnice",
    "Darkovicky",
    "Davle",
    "Deblin",
    "Dehylov",
    "Dejvice",
    "Desna",
    "Detmarovice",
    "Dily",
    "Divisov",
    "Dlouha Trebova",
    "Dlouhe",
    "Dobra",
    "Dobra Voda",
    "Dobrany",
    "Dobre",
    "Dobrichovice",
    "Dobris",
    "Dobriv",
    "Dobronin",
    "Dobroslavice",
    "Dobruska",
    "Doksy",
    "Dolany",
    "Dolni Becva",
    "Dolni Benesov",
    "Dolni Berkovice",
    "Dolni Bojanovice",
    "Dolni Brezany",
    "Dolni Dunajovice",
    "Dolni Jircany",
    "Dolni Kounice",
    "Dolni Nemci",
    "Dolni Poustevna",
    "Dolni Radechova",
    "Dolni Sucha",
    "Dolni Sytova",
    "Dolni Terlicko",
    "Dolni Zandov",
    "Domasov",
    "Domazelice",
    "Domazlice",
    "Doubravnik",
    "Doubravy",
    "Doudleby nad Orlici",
    "Drahotuse",
    "Drisy",
    "Drnovice",
    "Drzkov",
    "Drzovice",
    "Dubec",
    "Dubenec",
    "Dubi",
    "Dubicko",
    "Dubnany",
    "Dubne",
    "Duchcov",
    "Dukovany",
    "Dusejov",
    "Dvorce",
    "Dvur Kralove nad Labem",
    "Dymokury",
    "Ejpovice",
    "Filipova",
    "Francova Lhota",
    "Frantiskovy Lazne",
    "Frenstat pod Radhostem",
    "Frydek-Mistek",
    "Frydlant",
    "Frydlant nad Ostravici",
    "Frystak",
    "Fulnek",
    "Golcuv Jenikov",
    "Grucovice",
    "Habartov",
    "Haj ve Slezsku",
    "Haje",
    "Hajnice",
    "Halenkov",
    "Hamr",
    "Harrachov",
    "Havrice",
    "Hermanuv Mestec",
    "Hlasna Treban",
    "Hlinsko",
    "Hlubocepy",
    "Hlubocky",
    "Hluboka nad Vltavou",
    "Hlucin",
    "Hodice",
    "Hodslavice",
    "Holasice",
    "Holesov",
    "Holice",
    "Holoubkov",
    "Holubice",
    "Holysov",
    "Horesedly",
    "Horice",
    "Horin",
    "Horni Becva",
    "Horni Benesov",
    "Horni Bojanovice",
    "Horni Briza",
    "Horni Lutyne",
    "Horni Nemci",
    "Horni Pocernice",
    "Horni Podluzi",
    "Horni Redice",
    "Horni Slavkov",
    "Horomerice",
    "Horousanky",
    "Horovice",
    "Horsovsky Tyn",
    "Hospozin",
    "Hosteradice",
    "Hostice",
    "Hostin",
    "Hostinne",
    "Hostivice",
    "Hostomice",
    "Hostomice",
    "Hovezi",
    "Hovorany",
    "Hovorcovice",
    "Hrabetice",
    "Hradcovice",
    "Hradec",
    "Hradec nad Moravici",
    "Hradek",
    "Hradek nad Nisou",
    "Hradiste",
    "Hradiste",
    "Hranice",
    "Hranice",
    "Hrdejovice",
    "Hronov",
    "Hrusovany nad Jevisovkou",
    "Hrusovany u Brna",
    "Hudlice",
    "Hukvaldy",
    "Hulin",
    "Humpolec",
    "Hustopece",
    "Hvozd",
    "Hysly",
    "Ivancice",
    "Ivanovice na Hane",
    "Jablonec nad Nisou",
    "Jablonne nad Orlici",
    "Jablonne v Podjestedi",
    "Jablunka",
    "Jablunkov",
    "Jalubi",
    "Janovice",
    "Janovice nad Uhlavou",
    "Jaromer",
    "Jaroslav",
    "Jaroslavice",
    "Jedovnice",
    "Jenec",
    "Jesenice",
    "Jesenik",
    "Jevicko",
    "Jevisovice",
    "Jezov",
    "Jihlava",
    "Jilemnice",
    "Jilove u Prahy",
    "Jilovec",
    "Jiloviste",
    "Jince",
    "Jindrichuv Hradec",
    "Jinocany",
    "Jirice",
    "Jirickov",
    "Jirkov",
    "Jirny",
    "Jistebnik",
    "Kacerov",
    "Kacice",
    "Kadan",
    "Kamenice",
    "Kamenice",
    "Kamenicky Senov",
    "Kamenne Zehrovice",
    "Kamenny Privoz",
    "Kamyk",
    "Kaplice",
    "Karlin",
    "Karlovy Vary",
    "Karvina",
    "Kaznejov",
    "Kbely",
    "Kdyne",
    "Kladno",
    "Klasterec nad Ohri",
    "Klatovy",
    "Klecany",
    "Klicany",
    "Klimkovice",
    "Klobouky",
    "Knezmost",
    "Kochovice",
    "Koci",
    "Kolodeje",
    "Komarice",
    "Komorni Lhotka",
    "Konice",
    "Kopidlno",
    "Koprivnice",
    "Korycany",
    "Kosmonosy",
    "Kostelec na Hane",
    "Kostelec nad Cernymi Lesy",
    "Kostelec nad Labem",
    "Kostelec nad Orlici",
    "Kostelni Hlavno",
    "Kostice",
    "Kotencice",
    "Kourim",
    "Kouty",
    "Kozmice",
    "Kralice na Hane",
    "Kraliky",
    "Kralovice",
    "Kralupy nad Vltavou",
    "Kraluv Dvur",
    "Kraslice",
    "Krasna Hora nad Vltavou",
    "Krasna Lipa",
    "Kratonohy",
    "Kravare",
    "Krchleby",
    "Krenovice",
    "Krepenice",
    "Kresice",
    "Kretin",
    "Krhova",
    "Krivoklat",
    "Krizanov",
    "Krmelin",
    "Krnov",
    "Krnsko",
    "Krouna",
    "Krtiny",
    "Krumvir",
    "Krupka",
    "Ktis",
    "Kuncice",
    "Kuncina",
    "Kunovice",
    "Kunratice",
    "Kunstat",
    "Kurim",
    "Kvetnice",
    "Kyjov",
    "Kyjov",
    "Kylesovice",
    "Lachov",
    "Ladvi",
    "Lampertice",
    "Lanskroun",
    "Lanzhot",
    "Lasenice",
    "Lazne Bohdanec",
    "Lazne Libverda",
    "Ledec nad Sazavou",
    "Lednice",
    "Leskovec",
    "Lesonice",
    "Letohrad",
    "Letovice",
    "Lety",
    "Lhota",
    "Lhota",
    "Lhota",
    "Lhota pod Libcany",
    "Lhotka",
    "Liban",
    "Libcany",
    "Liberec",
    "Libesice",
    "Libeznice",
    "Libice nad Cidlinou",
    "Libis",
    "Libocany",
    "Libochovicky",
    "Libos",
    "Lichkov",
    "Lichnov (o. Novy Jicin)",
    "Line",
    "Lipence",
    "Lipnik nad Becvou",
    "Lipova",
    "Lisov",
    "Litava",
    "Liten",
    "Litovel",
    "Litvinov",
    "Lodenice",
    "Loket",
    "Lomnice",
    "Lomnice nad Luznici",
    "Lomnice nad Popelkou",
    "Loucany",
    "Loucen",
    "Loukov",
    "Lounovice pod Blanikem",
    "Louny",
    "Lovosice",
    "Lubenec",
    "Luby",
    "Luhacovice",
    "Luka",
    "Lukov",
    "Lustenice",
    "Lutin",
    "Luze",
    "Luzna",
    "Lysa nad Labem",
    "Lysice",
    "Mala Skala",
    "Malcice",
    "Malenice",
    "Malenovice",
    "Malesov",
    "Maly Ujezd",
    "Marianska",
    "Markvartovice",
    "Marov u Upice",
    "Mcely",
    "Mechenice",
    "Menany",
    "Merklin",
    "Merklin",
    "Mesice",
    "Mestec",
    "Mesto Touskov",
    "Mezibori",
    "Mezilesi",
    "Michle",
    "Mikulov",
    "Mikulovice",
    "Milevsko",
    "Milikov",
    "Milin",
    "Milonice",
    "Milotice",
    "Milovice",
    "Mimon",
    "Miroslav",
    "Mirosov",
    "Mirosovice",
    "Mlada Vozice",
    "Mlade Buky",
    "Mladkov",
    "Mlazovice",
    "Mnichovice",
    "Mnisek pod Brdy",
    "Modrice",
    "Mohelnice",
    "Mokra Horakov",
    "Mokrany",
    "Moran",
    "Moravicany",
    "Moravska Nova Ves",
    "Moravska Trebova",
    "Moravske Budejovice",
    "Moravsky Beroun",
    "Moravsky Krumlov",
    "Moravsky Pisek",
    "Moravsky Zizkov",
    "Most",
    "Mostek",
    "Mosty u Jablunkova",
    "Mratin",
    "Mrlinek",
    "Msec",
    "Mseno",
    "Mukarov",
    "Mutenice",
    "Nachod",
    "Naklo",
    "Napajedla",
    "Nasavrky",
    "Navsi u Jablunkova",
    "Nechanice",
    "Nedakonice",
    "Nedomice",
    "Nedvedice",
    "Nehvizdy",
    "Nejdek",
    "Nelahozeves",
    "Nenkovice",
    "Nepomysl",
    "Neratovice",
    "Nesovice",
    "Nezamyslice",
    "Nezvestice",
    "Nikolcice",
    "Nivnice",
    "Nova Cerekev",
    "Nova Lhota",
    "Nova Paka",
    "Nova Vcelnice",
    "Nova Ves",
    "Nove Dvory",
    "Nove Mesto na Morave",
    "Nove Mesto nad Metuji",
    "Nove Straseci",
    "Nove Veseli",
    "Novosedly",
    "Novy Bor",
    "Novy Bydzov",
    "Novy Hrozenkov",
    "Novy Jicin",
    "Novy Knin",
    "Novy Oldrichov",
    "Nucice",
    "Nupaky",
    "Nydek",
    "Nymburk",
    "Nyrany",
    "Nyrsko",
    "Obrany",
    "Obristvi",
    "Ochoz",
    "Odolena Voda",
    "Odry",
    "Ohrobec",
    "Okrisky",
    "Olomouc",
    "Olomucany",
    "Olovi",
    "Olsany",
    "Ondrejov",
    "Oparany",
    "Opatovice nad Labem",
    "Opava",
    "Opocno",
    "Orlova",
    "Osecna",
    "Osek",
    "Osik",
    "Oslavany",
    "Ostopovice",
    "Ostrava",
    "Ostromer",
    "Ostrov",
    "Ostrov u Macochy",
    "Ostrozska Lhota",
    "Ostrozska Nova Ves",
    "Otnice",
    "Otrokovice",
    "Pacov",
    "Palkovice",
    "Pardubice",
    "Pariz",
    "Pavlice",
    "Pchery",
    "Pecin",
    "Pecky",
    "Pelhrimov",
    "Perstejn",
    "Petrovice",
    "Petrovice u Karvine",
    "Petrvald",
    "Pilsen",
    "Pisecna",
    "Pisecna",
    "Pistovice",
    "Plana",
    "Planany",
    "Plouznice",
    "Pocatky",
    "Pocenice",
    "Podborany",
    "Podebrady",
    "Podivin",
    "Polepy",
    "Polesovice",
    "Police nad Metuji",
    "Policka",
    "Polna",
    "Poricany",
    "Poritsch",
    "Postoloprty",
    "Postrelmov",
    "Potstejn",
    "Prachatice",
    "Prackovice nad Labem",
    "Prague",
    "Prazmo",
    "Preckov",
    "Predklasteri",
    "Predmerice nad Jizerou",
    "Predmerice nad Labem",
    "Predmesti",
    "Prelouc",
    "Prepere",
    "Prerov nad Labem",
    "Prestice",
    "Prezletice",
    "Pribor",
    "Pribyslav",
    "Prikazy",
    "Primda",
    "Prlov",
    "Prosetin",
    "Protivin",
    "Provodov",
    "Pruhonice",
    "Prusanky",
    "Psary",
    "Pustejov",
    "Pysely",
    "Radim",
    "Radnice",
    "Radostice",
    "Radostin",
    "Radun",
    "Raj",
    "Rajec-Jestrebi",
    "Rajecko",
    "Rakovnik",
    "Rapotice",
    "Rapotin",
    "Raspenava",
    "Rataje",
    "Ratibor",
    "Ratiskovice",
    "Rebesovice",
    "Repy",
    "Ricany",
    "Ricany",
    "Rikovice",
    "Ritka",
    "Rokycany",
    "Rokytnice",
    "Rokytnice nad Jizerou",
    "Ronov nad Doubravou",
    "Rosice",
    "Rosice",
    "Roudnice nad Labem",
    "Roudnicek",
    "Rousinov",
    "Rovensko pod Troskami",
    "Rovina",
    "Rovna",
    "Rozdrojovice",
    "Rozmital pod Tremsinem",
    "Roznov pod Radhostem",
    "Rozsochatec",
    "Roztoky",
    "Rtyne",
    "Ruda nad Moravou",
    "Rudikov",
    "Rudna",
    "Rudolfov",
    "Rudoltice",
    "Rybna",
    "Rychnov",
    "Rychnov nad Kneznou",
    "Rychvald",
    "Rymarov",
    "Sadska",
    "Sanov",
    "Sany",
    "Satov",
    "Seberov",
    "Sebrov",
    "Sedlcany",
    "Sedlec",
    "Sedliste",
    "Seibersdorf",
    "Semily",
    "Senec",
    "Senice na Hane",
    "Senohraby",
    "Senov",
    "Sestajovice",
    "Sezemice",
    "Sibrina",
    "Silherovice",
    "Siluvky",
    "Sivice",
    "Skalice",
    "Skalsko",
    "Skripov",
    "Skuhrov nad Belou",
    "Skutec",
    "Slany",
    "Slapanice",
    "Slapy",
    "Slavicin",
    "Slavkov u Brna",
    "Slavonice",
    "Sloup",
    "Sluknov",
    "Slusovice",
    "Smirice",
    "Smrzovka",
    "Snedovice",
    "Snezne",
    "Sobeslav",
    "Sobotka",
    "Sokolov",
    "Spalene Porici",
    "Spytihnev",
    "Stalkov",
    "Stankov",
    "Stara Paka",
    "Stara Voda",
    "Stare Mesto",
    "Stare Mesto",
    "Stare Sedliste",
    "Starec",
    "Starovice",
    "Starovicky",
    "Stary Bydzov",
    "Statenice",
    "Stenovice",
    "Stepanov",
    "Sterboholy",
    "Sternberk",
    "Steti",
    "Stezery",
    "Stitina",
    "Stity",
    "Stochov",
    "Stod",
    "Stoky",
    "Strachotice",
    "Strachotin",
    "Strakonice",
    "Strancice",
    "Strasice",
    "Straz nad Nisou",
    "Straz pod Ralskem",
    "Strazek",
    "Straznice",
    "Strelice",
    "Stribrec",
    "Stribrna Skalice",
    "Stribro",
    "Stritez nad Ludinou",
    "Strunkovice nad Blanici",
    "Studenka",
    "Sucha Loz",
    "Sudkov",
    "Sumice",
    "Sumperk",
    "Susice",
    "Svatava",
    "Svaty Jan nad Malsi",
    "Svetla nad Sazavou",
    "Sviadnov",
    "Svihov",
    "Svitavka",
    "Svitavy",
    "Svojek",
    "Svojkov",
    "Syrovice",
    "Tachlovice",
    "Tachov",
    "Tehov",
    "Telc",
    "Telnice",
    "Temice",
    "Teplice",
    "Tesetice",
    "Tetin",
    "Tisice",
    "Tisnov",
    "Tlumacov",
    "Tman",
    "Tosovice",
    "Trebechovice pod Orebem",
    "Trebestovice",
    "Trebon",
    "Tremesna",
    "Tremosna",
    "Trhovy Stepanov",
    "Trinec",
    "Trmice",
    "Troja",
    "Trstenice",
    "Trutnov",
    "Tucapy",
    "Tuchlovice",
    "Tuchomerice",
    "Tupesy",
    "Turnov",
    "Tursko",
    "Tvarozna",
    "Tvrdonice",
    "Tynec nad Sazavou",
    "Tyniste nad Orlici",
    "Uhersky Brod",
    "Uhersky Ostroh",
    "Uhonice",
    "Uhrice",
    "Ujezd",
    "Ujezd",
    "Ujezd nad Lesy",
    "Unetice",
    "Unicov",
    "Usti nad Orlici",
    "Ustin",
    "Uvaly",
    "Uzice",
    "Vacenovice",
    "Valasske Klobouky",
    "Valasske Mezirici",
    "Valtice",
    "Vamberk",
    "Varnsdorf",
    "Vcelna",
    "Velehrad",
    "Velesin",
    "Velesovice",
    "Velka Bites",
    "Velka Bystrice",
    "Velka Dobra",
    "Velka Jesenice",
    "Velka Polom",
    "Velke Albrechtice",
    "Velke Brezno",
    "Velke Heraltice",
    "Velke Hydcice",
    "Velke Losiny",
    "Velke Mezirici",
    "Velke Opatovice",
    "Velke Popovice",
    "Velke Prilepy",
    "Velke Svatonovice",
    "Velky",
    "Velky Borek",
    "Velky Osek",
    "Veltrusy",
    "Velvary",
    "Vendryne",
    "Vernovice",
    "Veseli nad Luznici",
    "Veseli nad Moravou",
    "Veverska Bityska",
    "Veverske Kninice",
    "Vidce",
    "Vimperk",
    "Vinarice",
    "Vinicne Sumice",
    "Visnove",
    "Vitkov",
    "Vizovice",
    "Vlachovice",
    "Vladislav",
    "Vlasim",
    "Vlastislav",
    "Vlcovice",
    "Vochov",
    "Vodnany",
    "Vojkovice",
    "Volyne",
    "Votice",
    "Vracov",
    "Vrane nad Vltavou",
    "Vratimov",
    "Vratkov",
    "Vrbice",
    "Vrbno pod Pradedem",
    "Vrchlabi",
    "Vresina",
    "Vsechovice",
    "Vsechovice",
    "Vselibice",
    "Vsenory",
    "Vsestary",
    "Vsetaty",
    "Vsetin",
    "Vtelno",
    "Vyprachtice",
    "Vysker",
    "Vysoka",
    "Vysoka nad Labem",
    "Vysoke Myto",
    "Vysoke Veseli",
    "Zabcice",
    "Zabreh",
    "Zadni Treban",
    "Zajeci",
    "Zamberk",
    "Zampach",
    "Zandov",
    "Zasova",
    "Zatcany",
    "Zborovice",
    "Zbuch",
    "Zbysov",
    "Zdanice",
    "Zdeslav",
    "Zdiby",
    "Zdice",
    "Zdirec",
    "Zdounky",
    "Zehun",
    "Zelenec",
    "Zelenka",
    "Zelesice",
    "Zeletava",
    "Zeleznice",
    "Zelezny Brod",
    "Zeliv",
    "Zeravice",
    "Zidlochovice",
    "Zilina",
    "Zipotin",
    "Zizkov",
    "Zlata",
    "Zlate Hory",
    "Zlicin",
    "Zliv",
    "Znojmo",
    "Zubri",
    "Zulova",
    "Zvole",
  ],
  Denmark: [
    "Aabenraa",
    "Aabybro",
    "Aalborg",
    "Aarhus",
    "Aars",
    "Abyhoj",
    "Agedrup",
    "Agerbaek",
    "Agerskov",
    "Akirkeby",
    "Albaek",
    "Albertslund",
    "Ale",
    "Alestrup",
    "Aller",
    "Allerod",
    "Allingabro",
    "Allinge",
    "Almind",
    "Alsgarde",
    "Alslev",
    "Alslev",
    "Anholt",
    "Ans",
    "Ansager",
    "Arden",
    "Arre",
    "Arslev",
    "Arslev",
    "Arup",
    "Asa",
    "Askeby",
    "Asnaes",
    "Asperup",
    "Assens",
    "Astrup",
    "Augustenborg",
    "Auning",
    "Avlum",
    "Baekke",
    "Baekmarksbro",
    "Baelum",
    "Bagsvaerd",
    "Balle",
    "Ballerup",
    "Bandholm",
    "Baring",
    "Barrit",
    "Beder",
    "Bedsted",
    "Bevtoft",
    "Billund",
    "Bjaert",
    "Bjaeverskov",
    "Bjerringbro",
    "Blavand",
    "Blokhus",
    "Blommenslyst",
    "Boeslunde",
    "Bogense",
    "Bogo By",
    "Bolderslev",
    "Bording Stationsby",
    "Borkop",
    "Borre",
    "Borum",
    "Borup",
    "Bovlingbjerg",
    "Bovrup",
    "Brabrand",
    "Braedstrup",
    "Bramming",
    "Brande",
    "Branderup",
    "Bredballe",
    "Bredebro",
    "Bredsten",
    "Brejning",
    "Brenderup",
    "Broager",
    "Brobyvaerk",
    "Brondby Strand",
    "Bronshoj",
    "Brorup",
    "Brovst",
    "Bruunshab",
    "Bryrup",
    "Bylderup-Bov",
    "Charlottenlund",
    "Christiansfeld",
    "Copenhagen",
    "Dalmose",
    "Dannemare",
    "Daugard",
    "Dianalund",
    "Dong",
    "Dronninglund",
    "Dronningmolle",
    "Dybvad",
    "Ebberup",
    "Ebeltoft",
    "Ega",
    "Egernsund",
    "Egtved",
    "Ejby",
    "Ejby",
    "Ejstrup",
    "Elsinore",
    "Engesvang",
    "Errindlev",
    "Erslev",
    "Esbjerg",
    "Eskebjerg",
    "Eskilstrup",
    "Espergaerde",
    "Faaborg",
    "Fakse",
    "Farevejle",
    "Farum",
    "Farup",
    "Farvang",
    "Fensmark",
    "Ferritslev",
    "Ferslev",
    "Fjenneslev",
    "Fjerritslev",
    "Flauenskjold",
    "Flemming",
    "Follenslev",
    "Fovling",
    "Fredensborg",
    "Fredericia",
    "Frederiksberg",
    "Frederiksberg",
    "Frederiksdal",
    "Frederikshavn",
    "Frederikssund",
    "Frorup",
    "Frostrup",
    "Fuglebjerg",
    "Gadbjerg",
    "Gadstrup",
    "Galten",
    "Gammel Svebolle",
    "Gandrup",
    "Ganlose",
    "Gedved",
    "Gelsted",
    "Gentofte",
    "Gesten",
    "Gilleleje",
    "Gislev",
    "Gislinge",
    "Gistrup",
    "Give",
    "Gjerlev",
    "Gjern",
    "Gjol",
    "Gladsaxe",
    "Glamsbjerg",
    "Glejbjerg",
    "Glesborg",
    "Glostrup",
    "Glumso",
    "Glyngore",
    "Gording",
    "Gorlose",
    "Graested",
    "Gram",
    "Gredstedbro",
    "Greve",
    "Greve Strand",
    "Grevinge",
    "Grindsted",
    "Gudbjerg",
    "Gudhjem",
    "Gudme",
    "Guldborg",
    "Haderslev",
    "Haderup",
    "Hadsten",
    "Hadsund",
    "Hals",
    "Hammel",
    "Hammershoj",
    "Hampen",
    "Hanstholm",
    "Harboore",
    "Harby",
    "Harlev",
    "Harlev",
    "Harndrup",
    "Harpelunde",
    "Hasle",
    "Haslev",
    "Hasselager",
    "Havdrup",
    "Havndal",
    "Hedehusene",
    "Hedensted",
    "Hejls",
    "Hejnsvig",
    "Hellebaek",
    "Hellerup",
    "Helsinge",
    "Hemmet",
    "Henne",
    "Henne Strand",
    "Herfolge",
    "Herlev",
    "Herlufmagle",
    "Herning",
    "Hesselager",
    "Himmelev",
    "Hinnerup",
    "Hirtshals",
    "Hjallerup",
    "Hjerm",
    "Hjortshoj",
    "Hobro",
    "Hodsager",
    "Hojbjerg",
    "Hojby",
    "Hoje Tastrup",
    "Hojslev",
    "Holbaek",
    "Holeby",
    "Holme",
    "Holme-Olstrup",
    "Holstebro",
    "Holsted",
    "Holte",
    "Hoptrup",
    "Horbelev",
    "Hornbaek",
    "Horning",
    "Hornslet",
    "Hornsyld",
    "Horsens",
    "Horslunde",
    "Horve",
    "Hosterkob",
    "Hou",
    "Hovedgard",
    "Humble",
    "Humlebaek",
    "Hundested",
    "Hundslund",
    "Hurup",
    "Hvide Sande",
    "Hvidovre",
    "Hyllinge",
    "Idestrup",
    "Ikast",
    "Janderup",
    "Jelling",
    "Jerslev",
    "Jerslev",
    "Jerup",
    "Jordrup",
    "Jorlunde",
    "Juelsminde",
    "Jyderup",
    "Jyllinge",
    "Jystrup",
    "Kaldred",
    "Kalundborg",
    "Kalvehave",
    "Karise",
    "Karlslunde",
    "Karlstrup",
    "Karrebaeksminde",
    "Karup",
    "Kastrup",
    "Kastrup",
    "Kejlstrup",
    "Kerteminde",
    "Kettinge",
    "Kibaek",
    "Kirke Eskilstrup",
    "Kirke Hvalso",
    "Kirke Saby",
    "Kirke-Hyllinge",
    "Kjargaard",
    "Kjellerup",
    "Klampenborg",
    "Klarup",
    "Klemensker",
    "Klippinge",
    "Klokkerholm",
    "Klovborg",
    "Knebel",
    "Kokkedal",
    "Koldby",
    "Kolding",
    "Kolind",
    "Kolsen",
    "Kongens Lyngby",
    "Kongerslev",
    "Krusa",
    "Kvaerndrup",
    "Kvistgard",
    "Kvols",
    "Laeso",
    "Lambjerg",
    "Langa",
    "Langeskov",
    "Lasby",
    "Laurbjerg",
    "Ledoje",
    "Lejre",
    "Lem",
    "Lemming",
    "Lemvig",
    "Lille Skensved",
    "Liseleje",
    "Logstrup",
    "Lokken",
    "Losning",
    "Lundby Stationsby",
    "Lunderskov",
    "Lyngby",
    "Lynge",
    "Lystrup",
    "Malling",
    "Malov",
    "Mariager",
    "Maribo",
    "Marslet",
    "Marslev",
    "Marstal",
    "Martofte",
    "Melby",
    "Mern",
    "Middelfart",
    "Millinge",
    "Moldrup",
    "Morke",
    "Morkov",
    "Morud",
    "Munke Bjergby",
    "Munkebo",
    "Naerum",
    "Naesby",
    "Naestved",
    "Nakskov",
    "Nexo",
    "Nibe",
    "Nim",
    "Nimtofte",
    "Niva",
    "No",
    "Nodebo",
    "Norager",
    "Nordborg",
    "Nordby",
    "Nordenskov",
    "Norre Aby",
    "Norre Asmindrup",
    "Norre Bindslev",
    "Norre Nebel",
    "Norre Snede",
    "Norreballe",
    "Norresundby",
    "Nyborg",
    "Nygard",
    "Nykobing Falster",
    "Nykobing Mors",
    "Nykobing Sjaelland",
    "Nysted",
    "Odder",
    "Oddesund Syd",
    "Odense",
    "Odsted",
    "Oksbol",
    "Olsted",
    "Olstykke",
    "Omme",
    "Orbaek",
    "Ornhoj",
    "Orsted",
    "Orum",
    "Osby",
    "Ostbirk",
    "Oster Assels",
    "Ostermarie",
    "Ostervra",
    "Otterup",
    "Oure",
    "Padborg",
    "Pandrup",
    "Ramlose",
    "Randbol",
    "Randers",
    "Ranum",
    "Rebaek",
    "Refsvindinge",
    "Regstrup",
    "Ribe",
    "Ringe",
    "Ringsted",
    "Risskov",
    "Rodding",
    "Rodkaersbro",
    "Rodvig",
    "Ronde",
    "Roskilde",
    "Roslev",
    "Rude",
    "Ruds-Vedby",
    "Rungsted",
    "Ry",
    "Rynkeby",
    "Ryomgard",
    "Ryslinge",
    "Sabro",
    "Saeby",
    "Salten",
    "Saltum",
    "Sandager",
    "Sanderum",
    "Sandved",
    "Sherrebek",
    "Silkeborg",
    "Sindal",
    "Sjolund",
    "Skaelskor",
    "Skaering",
    "Skagen",
    "Skals",
    "Skamby",
    "Skanderborg",
    "Skarup",
    "Skibby",
    "Skive",
    "Skjern",
    "Skodsborg",
    "Skodstrup",
    "Skovlunde",
    "Slagelse",
    "Slangerup",
    "Slet",
    "Snedsted",
    "Snekkersten",
    "Snertinge",
    "Soborg",
    "Soby",
    "Soesmarke",
    "Solbjerg",
    "Sollested",
    "Solrod",
    "Sommersted",
    "Sonder Omme",
    "Sonder Stenderup",
    "Sonderso",
    "Soro",
    "Sorring",
    "Sorvad",
    "Spentrup",
    "Spjald",
    "Spottrup",
    "Stakroge",
    "Stege",
    "Stenderup",
    "Stenlille",
    "Stenlose",
    "Stenstrup",
    "Stensved",
    "Stoholm",
    "Stokkemarke",
    "Store Fuglede",
    "Store Heddinge",
    "Store Merlose",
    "Storvorde",
    "Stouby",
    "Stovring",
    "Strandby",
    "Strib",
    "Stroby",
    "Struer",
    "Suldrup",
    "Sulsted",
    "Sundby",
    "Sunds",
    "Svaneke",
    "Svanninge",
    "Svendborg",
    "Svenstrup",
    "Svinninge",
    "Taastrup",
    "Tagerup",
    "Tappernoje",
    "Tarbaek",
    "Tarm",
    "Tars",
    "Tejn",
    "Terndrup",
    "Them",
    "Thisted",
    "Thorso",
    "Tikob",
    "Tilst",
    "Tim",
    "Tinglev",
    "Tistrup",
    "Tisvildeleje",
    "Tjaereborg",
    "Tjebberup",
    "Toftlund",
    "Tollose",
    "Tommerup",
    "Toreby",
    "Tornby",
    "Torrig",
    "Torring",
    "Torring",
    "Tranbjerg",
    "Tranebjerg",
    "Tranekaer",
    "Trelde",
    "Trige",
    "TRUE",
    "Trustrup",
    "Tullebolle",
    "Tune",
    "Tureby",
    "Tylstrup",
    "Ugerlose",
    "Uldum",
    "Ulfborg",
    "Ullerslev",
    "Ulstrup",
    "Vadum",
    "Vaeggerlose",
    "Vaerlose",
    "Vamdrup",
    "Vandel",
    "Vantinge",
    "Varde",
    "Vasby",
    "Vebbestrup",
    "Vedbaek",
    "Vederso",
    "Veflinge",
    "Vejby",
    "Vejen",
    "Vejle",
    "Vejstrup",
    "Vekso",
    "Vemb",
    "Vemmelev",
    "Verninge",
    "Vestbjerg",
    "Vester Hassing",
    "Vester-Skerninge",
    "Vesterborg",
    "Vestervig",
    "Viborg",
    "Viby",
    "Viby",
    "Vig",
    "Vildbjerg",
    "Vils",
    "Vinderup",
    "Vinkel",
    "Vipperod",
    "Virring",
    "Virum",
    "Vissenbjerg",
    "Viuf",
    "Vivild",
    "Vodskov",
    "Vojens",
    "Vorbasse",
    "Vordingborg",
    "Vra",
  ],
  "Dominican Republic": [
    "Arenazo",
    "Bavaro",
    "Boca Chica",
    "Cabarete",
    "Cotui",
    "Dominica",
    "Guaricano",
    "Hato Mayor del Rey",
    "Jimani",
    "La Romana",
    "Los Alcarrizos",
    "Los Prados",
    "Moca",
    "Pedernales",
    "Puerto Plata",
    "Punta Cana",
    "Sabaneta",
    "San Cristobal",
    "San Fernando de Monte Cristi",
    "San Jose de Ocoa",
    "Santa Cruz de Barahona",
    "Santiago de los Caballeros",
    "Santo Domingo",
  ],
  Ecuador: [
    "Ambato",
    "Atacames",
    "Atuntaqui",
    "Bahia de Caraquez",
    "Banos",
    "Calderon",
    "Cayambe",
    "Cuenca",
    "Daule",
    "El Carmen",
    "El Naranjal",
    "Esmeraldas",
    "Florida",
    "General Leonidas Plaza Gutierrez",
    "Guabo",
    "Gualaceo",
    "Guamote",
    "Guayaquil",
    "Guayas",
    "Guayllabamba",
    "Hacienda Bolivia",
    "Hacienda Duran",
    "Hacienda Ibarra",
    "Hacienda Santa Catalina",
    "Huaquillas",
    "Ibarra",
    "Jipijapa",
    "La Esperanza",
    "La Libertad",
    "La Troncal",
    "Latacunga",
    "Loja",
    "Loreto",
    "Macara",
    "Macas",
    "Machachi",
    "Machala",
    "Manta",
    "Milagro",
    "Montecristi",
    "Nabon",
    "Olmedo",
    "Otavalo",
    "Paccha",
    "Pasaje",
    "Pelileo",
    "Pichincha",
    "Playas",
    "Portoviejo",
    "Puerto Francisco de Orellana",
    "Puyo",
    "Quevedo",
    "Quito",
    "Riobamba",
    "Salinas",
    "Samborondon",
    "San Miguel",
    "San Rafael",
    "Santa Cruz",
    "Santa Elena",
    "Santo Domingo de los Colorados",
    "Sucre",
    "Tababela",
    "Tarapoa",
    "Tena",
    "Vinces",
    "Zamora",
    "Zaruma",
    "Zumba",
  ],
  Egypt: [
    "Abu Hammad",
    "Al Mahallah al Kubra",
    "Al Mansurah",
    "Al Marj",
    "Alexandria",
    "Almazah",
    "Ar Rawdah",
    "Assiut",
    "Az Zamalik",
    "Badr",
    "Banha",
    "Bani Suwayf",
    "Cairo",
    "Damietta",
    "Faraskur",
    "Flaminj",
    "Giza",
    "Heliopolis",
    "Helwan",
    "Hurghada",
    "Ismailia",
    "Kafr ash Shaykh",
    "Luxor",
    "Madinat an Nasr",
    "Madinat as Sadis min Uktubar",
    "Minya",
    "Nasr",
    "New Cairo",
    "Port Said",
    "Rafah",
    "Ramsis",
    "Sadat",
    "Shirbin",
    "Shubra",
    "Sohag",
    "Suez",
    "Tanta",
    "Toukh",
    "Zagazig",
  ],
  "El Salvador": [
    "Ahuachapan",
    "Antiguo Cuscatlan",
    "Apaneca",
    "Apopa",
    "Ayutuxtepeque",
    "Botoncillal El Botoncillo",
    "Coatepeque",
    "Colon",
    "Colonia Escalon",
    "Cuscatancingo",
    "Delgado",
    "Gigante",
    "Guazapa",
    "Ilopango",
    "La Libertad",
    "Lourdes",
    "Mejicanos",
    "Nuevo Cuscatlan",
    "Ozatlan",
    "San Jose Villanueva",
    "San Marcos",
    "San Miguel",
    "San Salvador",
    "Santa Ana",
    "Santa Tecla",
    "Santiago Texacuangos",
    "Sonsonate",
    "Soyapango",
    "Usulutan",
    "Zaragoza",
  ],
  Estonia: [
    "Aasmae",
    "Aaviku",
    "Aespa",
    "Ahtma",
    "Alliku",
    "Ambla",
    "Antsla",
    "Ardu",
    "Avinurme",
    "Elva",
    "Emmaste",
    "Haabneeme",
    "Haage",
    "Haapsalu",
    "Halinga",
    "Harju",
    "Harku",
    "Kaarma",
    "Kadrina",
    "Kehra",
    "Keila",
    "Kiili",
    "Kohila",
    "Kohtla",
    "Kose",
    "Kulna",
    "Kuressaare",
    "Kuusalu",
    "Laagri",
    "Lagedi",
    "Lahtse",
    "Lepna",
    "Loksa",
    "Loo",
    "Lubja",
    "Maardu",
    "Muraste",
    "Narva",
    "Otepaeae",
    "Paide",
    "Paikuse",
    "Paldiski",
    "Palivere",
    "Peetri",
    "Puhja",
    "Rakke",
    "Rakvere",
    "Rapla",
    "Saku",
    "Saue",
    "Sindi",
    "Somerpalu",
    "Syare",
    "Tabasalu",
    "Tabivere",
    "Tallinn",
    "Tamsalu",
    "Tapa",
    "Tartu",
    "Toila",
    "Vaida",
    "Valga",
    "Vara",
    "Venevere",
    "Viimsi",
    "Viljandi",
    "Voka",
  ],
  "Faroe Islands": [
    "Argir",
    "Glyvrar",
    "Hvalba",
    "Innan Glyvur",
    "Leirvik",
    "Saltangara",
    "Signabour",
    "Strendur",
  ],
  Finland: [
    "Aapajoki",
    "Aavasaksa",
    "Aitoo",
    "Akaa",
    "Alastaro",
    "Alaveteli",
    "Alavieska",
    "Alavus",
    "Alvettula",
    "Angelniemi",
    "Anjala",
    "Anttila",
    "Askola",
    "Aura",
    "Bergoe",
    "Box",
    "Broby",
    "Degerby",
    "Eno",
    "Erajarvi",
    "Espoo",
    "Eura",
    "Eurajoki",
    "Evijaervi",
    "Evitskog",
    "Fiskars",
    "Forsby",
    "Forssa",
    "Gammelby",
    "Haapavesi",
    "Haeme",
    "Haermae",
    "Haijaa",
    "Halikko",
    "Halkia",
    "Halli",
    "Halsua",
    "Hamina",
    "Hammaslahti",
    "Hanko",
    "Harjavalta",
    "Harviala",
    "Hattula",
    "Hauho",
    "Haukipudas",
    "Haukkala",
    "Hausjaervi",
    "Heimari",
    "Heinola",
    "Helsingby",
    "Helsinki",
    "Hervanta",
    "Hiltulanlahti",
    "Himanka",
    "Hinthaara",
    "Hirvensalmi",
    "Hirvihaara",
    "Hoegsara",
    "Hoeytiae",
    "Hollola",
    "Hollola",
    "Huittinen",
    "Humppila",
    "Huuvari",
    "Hyvinkaeae",
    "Ihode",
    "Ii",
    "Iisalmi",
    "Iittala",
    "Ilkka",
    "Ilmajoki",
    "Ilmarinen",
    "Ilveskylae",
    "Imatra",
    "Inkeroinen",
    "Isojoki",
    "Itaekylae",
    "Ivalo",
    "Jaervelae",
    "Jakobstad",
    "Janakkala",
    "Joensuu",
    "Jokela",
    "Jokikunta",
    "Jokimaa",
    "Jokioinen",
    "Jormua",
    "Joroinen",
    "Jorvas",
    "Joutseno",
    "Jurva",
    "Juuka",
    "Juupajoki",
    "Jyskae",
    "Kaakamo",
    "Kaarina",
    "Kaemmenniemi",
    "Kaerrby",
    "Kaipiainen",
    "Kaipola",
    "Kaivanto",
    "Kajaani",
    "Kalajoki",
    "Kalanti",
    "Kangasala",
    "Kangasniemi",
    "Kankaanpaeae",
    "Kannonkoski",
    "Kannus",
    "Kantomaanpaeae",
    "Kantti",
    "Kantvik",
    "Karhula",
    "Karinkanta",
    "Karjaa",
    "Karjalohja",
    "Karkkila",
    "Karkku",
    "Karstula",
    "Karttula",
    "Karunki",
    "Karvia",
    "Kaskinen",
    "Kauhajoki",
    "Kauhakorpi",
    "Kauhava",
    "Kauniainen",
    "Kausala",
    "Kaustinen",
    "Kauttua",
    "Kauvatsa",
    "Kello",
    "Kellokoski",
    "Kemi",
    "Keminmaa",
    "Kempele",
    "Kerava",
    "Kerkkoo",
    "Keuruu",
    "Kihnioe",
    "Kiiminki",
    "Kiistala",
    "Kiljava",
    "Kimito",
    "Kinnula",
    "Kirjala",
    "Kirkkonummi",
    "Kisko",
    "Kitee",
    "Kiuruvesi",
    "Kivijaervi",
    "Klaukkala",
    "Kokkola",
    "Kokkola",
    "Kolari",
    "Kolinkylae",
    "Koljonvirta",
    "Kontiolahti",
    "Kontiomaeki",
    "Kopsa",
    "Koria",
    "Kormu",
    "Korplax",
    "Korppoo",
    "Korsholm",
    "Koskenkorva",
    "Koskioinen",
    "Kotka",
    "Kouvola",
    "Kronoby",
    "Kruusila",
    "Kuhmalahti",
    "Kuhmo",
    "Kuivakangas",
    "Kuivaniemi",
    "Kulho",
    "Kuni",
    "Kuohu",
    "Kuopio",
    "Kuortane",
    "Kurikka",
    "Kuusamo",
    "Kuusankoski",
    "Kuusisto",
    "Kvevlax",
    "Kylmaekoski",
    "Kyroeskoski",
    "Kyynaeroe",
    "Laeyliaeinen",
    "Lahela",
    "Lahti",
    "Laihia",
    "Laikko",
    "Laitikkala",
    "Laitila",
    "Lammi",
    "Lampinsaari",
    "Lapinlahti",
    "Lappajaervi",
    "Lappeenranta",
    "Lapua",
    "Larsmo",
    "Latovainio",
    "Laukaa",
    "Laukkoski",
    "Launonen",
    "Lautiosaari",
    "Lehmo",
    "Lehtimaeki",
    "Lemi",
    "Lepaa",
    "Lepplax",
    "Levanto",
    "Lieksa",
    "Lielax",
    "Lieto",
    "Lievestuore",
    "Liljendal",
    "Liminka",
    "Linna",
    "Linnavuori",
    "Liperi",
    "Littoinen",
    "Lohja",
    "Lohtaja",
    "Loimaa",
    "Loppi",
    "Louhi",
    "Loviisa",
    "Lumijoki",
    "Luohua",
    "Luoma-aho",
    "Maenttae",
    "Maentyharju",
    "Majvik",
    "Mankila",
    "Mantila",
    "Martensby",
    "Marttila",
    "Masala",
    "Masku",
    "Mattila",
    "Mehtaekylae",
    "Merimasku",
    "Metsaekansa",
    "Mieto",
    "Mikkeli",
    "Mouhijaervi",
    "Muhos",
    "Multia",
    "Munsala",
    "Muonio",
    "Muurame",
    "Muurla",
    "Myllykoski",
    "Mynaemaeki",
    "Naantali",
    "Naervijoki",
    "Nahkela",
    "Nakkila",
    "Narva",
    "Nastola",
    "Neuvoton",
    "Niinijoki",
    "Niittylahti",
    "Nivala",
    "Nokia",
    "Noormarkku",
    "Nousiainen",
    "Nukari",
    "Nummela",
    "Nummenkylae",
    "Nummenkylae",
    "Nummi",
    "Nunnanlahti",
    "Nuppulinna",
    "Nurmes",
    "Nurmo",
    "Nuutajaervi",
    "Nykarleby",
    "OEvermalax",
    "Oitti",
    "Oja",
    "Okskoski",
    "Orimattila",
    "Orivesi",
    "Otalampi",
    "Oulainen",
    "Oulu",
    "Oulunsalo",
    "Outokumpu",
    "Paalijaervi",
    "Paattinen",
    "Padasjoki",
    "Paelkaene",
    "Paimio",
    "Paippinen",
    "Palojoki",
    "Palokka",
    "Pankakoski",
    "Pargas",
    "Parkano",
    "Parkkuu",
    "Pattijoki",
    "Peipohja",
    "Pellinki",
    "Pello",
    "Peraelae",
    "Peraseinajoki",
    "Pernioe",
    "Pertteli",
    "Perttula",
    "Pihtipudas",
    "Piispanristi",
    "Pilkanmaa",
    "Pirkkala",
    "Pjelax",
    "Pockar",
    "Poeytyae",
    "Pohjola",
    "Poikelus",
    "Poitsila",
    "Pori",
    "Porlammi",
    "Pornainen",
    "Porvoo",
    "Pukkila",
    "Punkaharju",
    "Punkalaidun",
    "Puolanka",
    "Purmo",
    "Purola",
    "Pusula",
    "Puumala",
    "Pyhaejoki",
    "Pyhaentae",
    "Pyhamaa",
    "Raahe",
    "Raisio",
    "Rantasalmi",
    "Raseborg",
    "Rauma",
    "Rautalampi",
    "Rautjaervi",
    "Rautjaervi",
    "Ravijoki",
    "Revonlahti",
    "Riihikoski",
    "Riistavesi",
    "Ristiina",
    "Roeykkae",
    "Roismala",
    "Rovaniemi",
    "Ruokolahti",
    "Ruovesi",
    "Rusko",
    "Ruto",
    "Ruutana",
    "Ryttylae",
    "Saaksmaki",
    "Saarijaervi",
    "Saekylae",
    "Saeviae",
    "Sahalahti",
    "Sajaniemi",
    "Salo",
    "Saloinen",
    "Salomaa",
    "Sammaljoki",
    "Sappee",
    "Saukkola",
    "Saunalahti",
    "Sauvo",
    "Savitaipale",
    "Savonlinna",
    "Saynatsalo",
    "Seinaejoki",
    "Sievi",
    "Siikainen",
    "Siivikkala",
    "Simoniemi",
    "Simpele",
    "Sipoo",
    "Siuntio",
    "Siuro",
    "Skinnarby",
    "Skuru",
    "Soederkulla",
    "Soeoermarkku",
    "Solberg",
    "Somerniemi",
    "Somero",
    "Sotkamo",
    "Sotkuma",
    "Suhmura",
    "Sundby",
    "Sundom",
    "Suolahti",
    "Suomusjaervi",
    "Suomussalmi",
    "Suonenjoki",
    "Svarta",
    "Sykaeraeinen",
    "Taavetti",
    "Taipalsaari",
    "Tammela",
    "Tampella",
    "Tampere",
    "Tarttila",
    "Tarvasjoki",
    "Tenala",
    "Terjaerv",
    "Tervajoki",
    "Tervakoski",
    "Tervalampi",
    "Teuva",
    "Tikkakoski",
    "Toelby",
    "Toholampi",
    "Toijala",
    "Toivala",
    "Tolkis",
    "Tornio",
    "Tortinmaeki",
    "Tottijarvi",
    "Tuomikylae",
    "Turenki",
    "Turku",
    "Tuulos",
    "Tuuri",
    "Tuusula",
    "Tyrnaevae",
    "Uimaharju",
    "Ulvila",
    "Utsjoki",
    "Utti",
    "Uurainen",
    "Uusikaupunki",
    "Vaajakoski",
    "Vaasa",
    "Vaeaeksy",
    "Vaesterskog",
    "Vahto",
    "Vakkola",
    "Valkama",
    "Valkeakoski",
    "Valkeala",
    "Valkola",
    "Vammala",
    "Vanhalinna",
    "Vantaa",
    "Varkaus",
    "Vasarainen",
    "Vaskio",
    "Vaulammi",
    "Vehniae",
    "Veikkola",
    "Venetheitto",
    "Vesanka",
    "Veteli",
    "Viantie",
    "Vihtavuori",
    "Vihti",
    "Viiala",
    "Viitasaari",
    "Viljakkala",
    "Vilppula",
    "Vimpeli",
    "Virrat",
    "Vuokatti",
    "Ylaemylly",
    "Ylihaermae",
    "Ylitornio",
    "Ylivieska",
    "Ypaejae",
  ],
  France: [
    "Aast",
    "Abancourt",
    "Abbans-Dessus",
    "Abbaretz",
    "Abbecourt",
    "Abbeville",
    "Abbeville-les-Conflans",
    "Abeilhan",
    "Abere",
    "Abergement-Saint-Jean",
    "Abilly",
    "Ablain-Saint-Nazaire",
    "Ablaincourt-Pressoir",
    "Ablainzevelle",
    "Ablis",
    "Ablon-sur-Seine",
    "Abondance",
    "Abondant",
    "Abreschviller",
    "Abrest",
    "Abscon",
    "Abzac",
    "Abzac",
    "Achatel",
    "Achenheim",
    "Acheres",
    "Acheville",
    "Achicourt",
    "Achiet-le-Grand",
    "Achiet-le-Petit",
    "Acigne",
    "Acq",
    "Acquigny",
    "Acy-en-Multien",
    "Adam-les-Vercel",
    "Adamswiller",
    "Adelans-et-le-Val-de-Bithaine",
    "Adissan",
    "Adriers",
    "Afa",
    "Agay",
    "Agde",
    "Agen",
    "Agneaux",
    "Agnetz",
    "Agnin",
    "Agnos",
    "Agny",
    "Aguilcourt",
    "Ahaxe-Alciette-Bascassan",
    "Ahuille",
    "Ahun",
    "Ahuy",
    "Aiffres",
    "Aigaliers",
    "Aiglemont",
    "Aignan",
    "Aigne",
    "Aigny",
    "Aigonnay",
    "Aigre",
    "Aigrefeuille",
    "Aigrefeuille-sur-Maine",
    "Aigremont",
    "Aigremont",
    "Aiguebelette-le-Lac",
    "Aiguebelle",
    "Aigueblanche",
    "Aiguefonde",
    "Aigueperse",
    "Aigueperse",
    "Aigues-Mortes",
    "Aigues-Vives",
    "Aigues-Vives",
    "Aiguillon",
    "Ailette",
    "Aillas",
    "Aillevillers-et-Lyaumont",
    "Aillon-le-Vieux",
    "Ailly-le-Haut-Clocher",
    "Ailly-sur-Noye",
    "Ailly-sur-Somme",
    "Aimargues",
    "Aime",
    "Ainay-le-Chateau",
    "Aincourt",
    "Ainhoa",
    "Airaines",
    "Aire-sur-la-Lys",
    "Airel",
    "Airvault",
    "Aiserey",
    "Aissey",
    "Aiton",
    "Aix-en-Ergny",
    "Aix-en-Issart",
    "Aix-en-Othe",
    "Aix-en-Provence",
    "Aix-les-Bains",
    "Aix-Noulette",
    "Aixe-sur-Vienne",
    "Aizenay",
    "Ajaccio",
    "Alairac",
    "Alata",
    "Alban",
    "Albas",
    "Albefeuille-Lagarde",
    "Albens",
    "Albert",
    "Albertville",
    "Albi",
    "Albias",
    "Albigny-sur-Saone",
    "Albiosc",
    "Alboussiere",
    "Albussac",
    "Alby-sur-Cheran",
    "Alenya",
    "Alfortville",
    "Algolsheim",
    "Algrange",
    "Alignan-du-Vent",
    "Alissas",
    "Alix",
    "Alixan",
    "Alizay",
    "Allain",
    "Allaire",
    "Allan",
    "Allassac",
    "Allauch",
    "Allegre",
    "Alleins",
    "Allemans-du-Dropt",
    "Allennes-les-Marais",
    "Alleriot",
    "Allery",
    "Allevard",
    "Alliancelles",
    "Allier",
    "Allieres",
    "Allinges",
    "Allogny",
    "Allondrelle-la-Malmaison",
    "Allonne",
    "Allonne",
    "Allonnes",
    "Allonnes",
    "Allonville",
    "Allonzier-la-Caille",
    "Allos",
    "Allouagne",
    "Alloue",
    "Allouis",
    "Allouville-Bellefosse",
    "Alluy",
    "Alluyes",
    "Ally",
    "Alos-Sibas-Abense",
    "Alteckendorf",
    "Altkirch",
    "Altorf",
    "Altviller",
    "Alvimare",
    "Alzonne",
    "Amagney",
    "Amailloux",
    "Amancey",
    "Amancy",
    "Amange",
    "Amanlis",
    "Amanvillers",
    "Amathay-Vesigneux",
    "Amaye-sur-Orne",
    "Ambazac",
    "Amberac",
    "Amberieux",
    "Amberieux-en-Dombes",
    "Ambert",
    "Ambes",
    "Ambierle",
    "Ambleny",
    "Ambleteuse",
    "Amboise",
    "Ambon",
    "Ambonnay",
    "Ambres",
    "Ambrieres",
    "Ambronay",
    "Ambrus",
    "Ambutrix",
    "Amelecourt",
    "Amelie-les-Bains-Palalda",
    "Amen",
    "Ames",
    "Amfreville",
    "Amfreville-la-Mi-Voie",
    "Amfreville-sous-les-Monts",
    "Amiens",
    "Amigny",
    "Amigny-Rouy",
    "Amillis",
    "Amilly",
    "Ammerschwihr",
    "Ammerzwiller",
    "Amneville",
    "Amou",
    "Amplepuis",
    "Amponville",
    "Ampuis",
    "Ampus",
    "Amure",
    "Anais",
    "Ance",
    "Anceaumeville",
    "Ancemont",
    "Ancenis",
    "Ancerville",
    "Anche",
    "Anchenoncourt-et-Chazel",
    "Ancinnes",
    "Ancizan",
    "Ancone",
    "Ancy",
    "Ancy-le-Franc",
    "Ancy-sur-Moselle",
    "Andance",
    "Andancette",
    "Andard",
    "Ande",
    "Andechy",
    "Andel",
    "Andelot-Morval",
    "Andernos-les-Bains",
    "Andeville",
    "Andigne",
    "Andilly",
    "Andilly",
    "Andilly",
    "Andoins",
    "Andolsheim",
    "Andon",
    "Andonville",
    "Andouille",
    "Andouille-Neuville",
    "Andres",
    "Andrest",
    "Andresy",
    "Andreze",
    "Andrezieux-Boutheon",
    "Anduze",
    "Anet",
    "Anetz",
    "Ange",
    "Angeac-Champagne",
    "Angeac-Charente",
    "Angely",
    "Angers",
    "Angerville-la-Martel",
    "Angervilliers",
    "Angevillers",
    "Angicourt",
    "Angiens",
    "Anglade",
    "Anglefort",
    "Angles",
    "Anglet",
    "Angliers",
    "Anglure",
    "Angoulins",
    "Angoume",
    "Angoville-sur-Ay",
    "Angres",
    "Anguerny",
    "Angy",
    "Anhaux",
    "Anhiers",
    "Aniane",
    "Aniche",
    "Anizy-le-Chateau",
    "Anjou",
    "Anjoutey",
    "Anlhiac",
    "Annay",
    "Annay-sur-Serein",
    "Annebault",
    "Annecy",
    "Annecy-le-Vieux",
    "Annemasse",
    "Annepont",
    "Annequin",
    "Annesse-et-Beaulieu",
    "Annet-sur-Marne",
    "Anneville-Ambourville",
    "Anneyron",
    "Annezay",
    "Annezin",
    "Annoeullin",
    "Annois",
    "Annonay",
    "Annot",
    "Annouville-Vilmesnil",
    "Anor",
    "Anould",
    "Ansac-sur-Vienne",
    "Ansauvillers",
    "Anse",
    "Ansouis",
    "Antagnac",
    "Anteuil",
    "Anthe",
    "Antheuil-Portes",
    "Anthon",
    "Anthy-sur-Leman",
    "Antibes",
    "Antignac",
    "Antignac",
    "Antigny",
    "Antilly",
    "Antilly",
    "Antogny le Tillac",
    "Antony",
    "Antrain",
    "Anzat-le-Luguet",
    "Anzin",
    "Anzin-Saint-Aubin",
    "Anzy-le-Duc",
    "Aoste",
    "Aougny",
    "Aouste-sur-Sye",
    "Apach",
    "Apcher",
    "Apcher",
    "Appenwihr",
    "Appeville",
    "Appeville-Annebault",
    "Appietto",
    "Appoigny",
    "Apprieu",
    "Apremont",
    "Apremont",
    "Apt",
    "Araches-la-Frasse",
    "Aragon",
    "Aramits",
    "Aramon",
    "Arancou",
    "Arbent",
    "Arberats-Sillegue",
    "Arbin",
    "Arbois",
    "Arbonne",
    "Arboucave",
    "Arbus",
    "Arbusigny",
    "Arc-et-Senans",
    "Arc-sur-Tille",
    "Arcachon",
    "Arcay",
    "Arcens",
    "Arces",
    "Archamps",
    "Arche",
    "Arches",
    "Archettes",
    "Archiac",
    "Archingeay",
    "Arcine",
    "Arcis-sur-Aube",
    "Arcon",
    "Arcon",
    "Arcueil",
    "Arcy-Sainte-Restitue",
    "Ardenay-sur-Merize",
    "Ardentes",
    "Ardes",
    "Ardiege",
    "Ardillieres",
    "Ardoix",
    "Ardon",
    "Ardres",
    "Areines",
    "Arengosse",
    "Arenthon",
    "Ares",
    "Aressy",
    "Arette",
    "Argancy",
    "Argelers",
    "Argeles-Gazost",
    "Argeliers",
    "Argelliers",
    "Argences",
    "Argens",
    "Argent-sur-Sauldre",
    "Argentan",
    "Argentat",
    "Argenteuil",
    "Argentiere",
    "Argentine",
    "Argenton-les-Vallees",
    "Argenton-sur-Creuse",
    "Argentre",
    "Argentre-du-Plessis",
    "Argeville",
    "Argol",
    "Argueil",
    "Arinthod",
    "Arlanc",
    "Arlay",
    "Arlebosc",
    "Arles",
    "Arles",
    "Arleux",
    "Arleux-en-Gohelle",
    "Armaucourt",
    "Armbouts-Cappel",
    "Armenonville-les-Gatineaux",
    "Armes",
    "Armoy",
    "Arnac-Pompadour",
    "Arnage",
    "Arnas",
    "Arnay-le-Duc",
    "Arnieres-sur-Iton",
    "Arnouville",
    "Arnouville-les-Mantes",
    "Aron",
    "Arpaillargues-et-Aureillac",
    "Arpajon",
    "Arpajon-sur-Cere",
    "Arpenans",
    "Arpheuilles-Saint-Priest",
    "Arques",
    "Arques-la-Bataille",
    "Arquettes-en-Val",
    "Arrabloy",
    "Arradon",
    "Arras",
    "Arraute-Charritte",
    "Arreau",
    "Arrenes",
    "Arrigas",
    "Arrigny",
    "Arrou",
    "Ars",
    "Ars-en-Re",
    "Ars-Laquenexy",
    "Ars-sur-Formans",
    "Ars-sur-Moselle",
    "Arsac",
    "Arsac-en-Velay",
    "Arsague",
    "Artannes-sur-Indre",
    "Artas",
    "Artenay",
    "Arthaz-Pont-Notre-Dame",
    "Arthes",
    "Arthez-de-Bearn",
    "Arthon",
    "Arthon-en-Retz",
    "Artigat",
    "Artigueloutan",
    "Artiguelouve",
    "Artigues",
    "Artigues-pres-Bordeaux",
    "Artix",
    "Artolsheim",
    "Arudy",
    "Arvert",
    "Arveyres",
    "Arvillard",
    "Arville",
    "Arvillers",
    "Arx",
    "Arzacq-Arraziguet",
    "Arzal",
    "Arzano",
    "Arzon",
    "Asasp-Arros",
    "Ascain",
    "Ascheres-le-Marche",
    "Ascoux",
    "Ascq",
    "Aslonnes",
    "Asnans-Beauvoisin",
    "Asnieres",
    "Asnieres-sur-Oise",
    "Asnieres-sur-Seine",
    "Aspach-le-Bas",
    "Aspach-le-Haut",
    "Asperes",
    "Aspet",
    "Aspremont",
    "Asque",
    "Assas",
    "Assat",
    "Assay",
    "Assevent",
    "Assier",
    "Assignan",
    "Asson",
    "Astaffort",
    "Astille",
    "Astis",
    "Athee",
    "Athesans-Etroitefontaine",
    "Athies",
    "Athies-sous-Laon",
    "Athis-Mons",
    "Attenschwiller",
    "Attiches",
    "Attichy",
    "Attignat-Oncin",
    "Attigny",
    "Atton",
    "Atur",
    "Aubagne",
    "Aubais",
    "Aubas",
    "Aubenas",
    "Auberchicourt",
    "Aubergenville",
    "Auberives-sur-Vareze",
    "Aubers",
    "Auberville-la-Campagne",
    "Auberville-la-Renault",
    "Aubervilliers",
    "Aubevoye",
    "Aubiac",
    "Aubie-et-Espessas",
    "Aubiere",
    "Aubiet",
    "Aubignan",
    "Aubigne",
    "Aubigne-Racan",
    "Aubigney",
    "Aubigny",
    "Aubigny-au-Bac",
    "Aubigny-en-Artois",
    "Aubigny-en-Laonnois",
    "Aubigny-en-Plaine",
    "Aubin",
    "Aubin",
    "Aubin-Saint-Vaast",
    "Aubinges",
    "Aubord",
    "Auboue",
    "Aubrac",
    "Aubrives",
    "Aubry-du-Hainaut",
    "Aubusson",
    "Auby",
    "Aucamville",
    "Auch",
    "Auchel",
    "Auchy-les-Hesdin",
    "Auchy-les-Mines",
    "Auchy-lez-Orchies",
    "Audelange",
    "Audenge",
    "Audes",
    "Audierne",
    "Audignies",
    "Audincourt",
    "Audincthun",
    "Audruicq",
    "Audun-le-Roman",
    "Audun-le-Tiche",
    "Auffargis",
    "Auffay",
    "Auffreville-Brasseuil",
    "Auga",
    "Augignac",
    "Augny",
    "Aulhat-Saint-Privat",
    "Aulnat",
    "Aulnay",
    "Aulnay-sous-Bois",
    "Aulnay-sur-Marne",
    "Aulnay-sur-Mauldre",
    "Aulnoye-Aymeries",
    "Ault",
    "Aumagne",
    "Aumale",
    "Aumenancourt",
    "Aumetz",
    "Aumont",
    "Aumont-Aubrac",
    "Aumont-en-Halatte",
    "Aumontzey",
    "Aumur",
    "Aunay-les-Bois",
    "Aunay-sous-Auneau",
    "Aunay-sur-Odon",
    "Auneau",
    "Auneuil",
    "Aups",
    "Auray",
    "Aurec-sur-Loire",
    "Aureil",
    "Aureilhan",
    "Aureilhan",
    "Auriac",
    "Auriac",
    "Auriac-sur-Vendinelle",
    "Auribeau-sur-Siagne",
    "Aurignac",
    "Aurillac",
    "Auriol",
    "Aurons",
    "Aussevielle",
    "Aussillon",
    "Aussonce",
    "Aussonne",
    "Autechaux",
    "Auterive",
    "Auterive",
    "Auteuil",
    "Auteuil",
    "Autevielle-Saint-Martin-Bideren",
    "Authevernes",
    "Authie",
    "Authie",
    "Authon",
    "Authou",
    "Authouillet",
    "Autoreille",
    "Autrans",
    "Autreche",
    "Autremencourt",
    "Autreville",
    "Autry-le-Chatel",
    "Autun",
    "Auvergny",
    "Auvers",
    "Auvers-Saint-Georges",
    "Auvers-sur-Oise",
    "Auvillar",
    "Auvillars",
    "Auvillers-les-Forges",
    "Auvilliers-en-Gatinais",
    "Auxais",
    "Auxances",
    "Auxange",
    "Auxerre",
    "Auxey-Duresses",
    "Auxi-le-Chateau",
    "Auxon",
    "Auxon-Dessous",
    "Auxon-Dessus",
    "Auxonne",
    "Auxy",
    "Auzances",
    "Auzeville-Tolosane",
    "Auzielle",
    "Auzon",
    "Auzouer-en-Touraine",
    "Availles-Limouzine",
    "Avallon",
    "Avancon",
    "Avanne-Aveney",
    "Avanton",
    "Avaray",
    "Aveize",
    "Aveizieux",
    "Avelin",
    "Aveluy",
    "Avenay",
    "Avensan",
    "Aventignan",
    "Averdoingt",
    "Averdon",
    "Averton",
    "Avesnelles",
    "Avesnes-en-Bray",
    "Avesnes-le-Comte",
    "Avesnes-le-Sec",
    "Avesnes-les-Aubert",
    "Avesnes-sur-Helpe",
    "Aveze",
    "Aveze",
    "Aviernoz",
    "Avignon",
    "Avignonet-Lauragais",
    "Avion",
    "Aviron",
    "Avize",
    "Avoine",
    "Avon",
    "Avon-la-Peze",
    "Avord",
    "Avot",
    "Avrainville",
    "Avranches",
    "Avremesnil",
    "Avressieux",
    "Avril",
    "Avrille",
    "Ax-les-Thermes",
    "Aydat",
    "Ayencourt",
    "Ayette",
    "Ayguemorte-les-Graves",
    "Ayguesvives",
    "Aymeries",
    "Aynac",
    "Ayrens",
    "Ayse",
    "Aytre",
    "Azas",
    "Azay-le-Brule",
    "Azay-le-Rideau",
    "Azay-sur-Cher",
    "Azay-sur-Indre",
    "Aze",
    "Aze",
    "Azerables",
    "Azerailles",
    "Azet",
    "Azille",
    "Azur",
    "Azy-sur-Marne",
    "Babeau-Bouldoux",
    "Baccarat",
    "Bach",
    "Bachant",
    "Bachy",
    "Bacilly",
    "Baconnes",
    "Bacqueville",
    "Bacqueville-en-Caux",
    "Baden",
    "Badevel",
    "Bagard",
    "Bagas",
    "Bage-la-Ville",
    "Bages",
    "Bagnac-sur-Cele",
    "Bagneaux",
    "Bagneaux-sur-Loing",
    "Bagneux",
    "Bagnolet",
    "Bagnols",
    "Bagnols",
    "Bagnols-en-Foret",
    "Bagnols-sur-Ceze",
    "Baguer-Morvan",
    "Baguer-Pican",
    "Baho",
    "Baignes-Sainte-Radegonde",
    "Baigneux-les-Juifs",
    "Baillargues",
    "Bailleau-le-Pin",
    "Baillet-en-France",
    "Bailleul",
    "Bailleul-le-Soc",
    "Bailleul-Sir-Berthoult",
    "Bailleul-sur-Therain",
    "Bailly",
    "Bailly",
    "Bailly-Carrois",
    "Bailly-le-Franc",
    "Bailly-Romainvilliers",
    "Bain-de-Bretagne",
    "Bains",
    "Bains-les-Bains",
    "Bains-sur-Oust",
    "Bainville-sur-Madon",
    "Baisieux",
    "Baixas",
    "Bajus",
    "Balagny-sur-Therain",
    "Balan",
    "Balanod",
    "Balaruc-le-Vieux",
    "Balaruc-les-Bains",
    "Balaze",
    "Balbigny",
    "Baldenheim",
    "Baldersheim",
    "Balgau",
    "Ballainvilliers",
    "Ballaison",
    "Ballan-Mire",
    "Ballancourt-sur-Essonne",
    "Balleroy",
    "Ballon",
    "Ballon",
    "Balma",
    "Balschwiller",
    "Balzac",
    "Ban-de-Laveline",
    "Banassac",
    "Bandol AOC",
    "Bangor",
    "Banize",
    "Bannalec",
    "Banne",
    "Bannes",
    "Bannieres",
    "Banon",
    "Bantigny",
    "Bantzenheim",
    "Banville",
    "Banvou",
    "Banyuls de la Marenda",
    "Banyuls-dels-Aspres",
    "Bapaume",
    "Bar-le-Duc",
    "Bar-sur-Aube",
    "Bar-sur-Seine",
    "Baraqueville",
    "Barastre",
    "Barbaira",
    "Barbaste",
    "Barbatre",
    "Barbazan-Debat",
    "Barbechat",
    "Barbentane",
    "Barberaz",
    "Barbery",
    "Barbeville",
    "Barbezieux-Saint-Hilaire",
    "Barbieres",
    "Barbizon",
    "Barby",
    "Barc",
    "Barcelonnette",
    "Bardos",
    "Barembach",
    "Barentin",
    "Barenton-Bugny",
    "Barfleur",
    "Barges",
    "Barinque",
    "Barisey-au-Plain",
    "Barisey-la-Cote",
    "Barjac",
    "Barjols",
    "Barjon",
    "Barjouville",
    "Barlin",
    "Barneville-Carteret",
    "Baron",
    "Baron",
    "Baronville",
    "Barr",
    "Barraux",
    "Barreme",
    "Barro",
    "Barrou",
    "Bartenheim",
    "Barzy-en-Thierache",
    "Bas-en-Basset",
    "Bascons",
    "Bassan",
    "Basse-Goulaine",
    "Basse-Ham",
    "Basse-Rentgen",
    "Basse-sur-le-Rupt",
    "Bassemberg",
    "Bassens",
    "Bassens",
    "Bassigney",
    "Bassillac",
    "Bassoles-Aulers",
    "Bassou",
    "Bassu",
    "Bassussarry",
    "Bastennes",
    "Bastia",
    "Batilly",
    "Batilly-en-Gatinais",
    "Battenheim",
    "Battrans",
    "Batz-sur-Mer",
    "Batzendorf",
    "Baud",
    "Baudemont",
    "Baudreix",
    "Baulay",
    "Baule",
    "Baulne",
    "Baulon",
    "Baume-les-Dames",
    "Baume-les-Messieurs",
    "Baune",
    "Baupte",
    "Baurech",
    "Bauvin",
    "Bavans",
    "Bavay",
    "Bavent",
    "Bavilliers",
    "Bavincourt",
    "Baye",
    "Bayet",
    "Bayeux",
    "Bayon",
    "Bayonne",
    "Bazailles",
    "Bazancourt",
    "Bazarnes",
    "Bazas",
    "Bazeilles",
    "Bazemont",
    "Bazens",
    "Bazet",
    "Bazian",
    "Bazicourt",
    "Baziege",
    "Bazien",
    "Bazincourt-sur-Epte",
    "Bazoches",
    "Bazoches-les-Gallerandes",
    "Bazoches-sur-Guyonne",
    "Bazoches-sur-Hoene",
    "Bazoches-sur-le-Betz",
    "Bazoges-en-Paillers",
    "Bazoilles-sur-Meuse",
    "Bazoncourt",
    "Bazouges-la-Perouse",
    "Bazouges-sous-Hede",
    "Bazouges-sur-le-Loir",
    "Bazuel",
    "Beaucaire",
    "Beaucamps-le-Vieux",
    "Beaucamps-Ligny",
    "Beauce",
    "Beauchamp",
    "Beauchamps",
    "Beauchamps",
    "Beauchamps-sur-Huillard",
    "Beauchastel",
    "Beauchene",
    "Beaucourt",
    "Beaucouze",
    "Beaucroissant",
    "Beaudricourt",
    "Beaufort",
    "Beaufort",
    "Beaufort",
    "Beaufort-en-Vallee",
    "Beaufou",
    "Beaugency",
    "Beaujeu",
    "Beaulieu-sous-Parthenay",
    "Beaulieu-sur-Dordogne",
    "Beaulieu-sur-Loire",
    "Beaulieu-sur-Mer",
    "Beaumetz-les-Loges",
    "Beaumont",
    "Beaumont",
    "Beaumont",
    "Beaumont-de-Lomagne",
    "Beaumont-de-Pertuis",
    "Beaumont-du-Gatinais",
    "Beaumont-du-Perigord",
    "Beaumont-en-Veron",
    "Beaumont-Hague",
    "Beaumont-le-Roger",
    "Beaumont-les-Autels",
    "Beaumont-les-Nonains",
    "Beaumont-les-Valence",
    "Beaumont-Pied-de-Boeuf",
    "Beaumont-sur-Oise",
    "Beaumont-sur-Sarthe",
    "Beaune",
    "Beaune-la-Rolande",
    "Beaupont",
    "Beaupreau",
    "Beaupuy",
    "Beauquesne",
    "Beaurains",
    "Beaurainville",
    "Beauregard-Baret",
    "Beauregard-Vendon",
    "Beaurepaire",
    "Beaurepaire",
    "Beaurepaire",
    "Beaurevoir",
    "Beaurieux",
    "Beausemblant",
    "Beausoleil",
    "Beaussault",
    "Beautheil",
    "Beautiran",
    "Beautor",
    "Beautot",
    "Beauvais",
    "Beauvais-sur-Matha",
    "Beauval",
    "Beauvallon",
    "Beauvene",
    "Beauvernois",
    "Beauvezer",
    "Beauville",
    "Beauvoir",
    "Beauvoir",
    "Beauvoir-de-Marc",
    "Beauvoir-sur-Mer",
    "Beauvoir-sur-Niort",
    "Beauvoisin",
    "Beauvoisin",
    "Beauvoisin",
    "Beauzac",
    "Beauzelle",
    "Bechy",
    "Becon-les-Granits",
    "Bedarrides",
    "Bedee",
    "Bedous",
    "Beganne",
    "Begard",
    "Begles",
    "Beguey",
    "Behren-les-Forbach",
    "Beignon",
    "Beine-Nauroy",
    "Beinheim",
    "Beire-le-Chatel",
    "Bel Air",
    "Belarga",
    "Belaye",
    "Belbeuf",
    "Belcodene",
    "Belfort",
    "Belfort-du-Quercy",
    "Belgentier",
    "Belgodere",
    "Belhade",
    "Beliet",
    "Beligneux",
    "Bellac",
    "Bellaing",
    "Bellancourt",
    "Belle-Isle-en-Terre",
    "Belleau",
    "Bellebrune",
    "Bellechaume",
    "Bellefond",
    "Bellefontaine",
    "Bellefosse",
    "Bellegarde",
    "Bellegarde-du-Razes",
    "Bellegarde-en-Diois",
    "Bellegarde-Sainte-Marie",
    "Bellegarde-sur-Valserine",
    "Bellemagny",
    "Bellenaves",
    "Bellencombre",
    "Belleneuve",
    "Bellengreville",
    "Bellentre",
    "Belleray",
    "Bellerive-sur-Allier",
    "Bellesserre",
    "Belleu",
    "Bellevaux",
    "Bellevesvre",
    "Belleville",
    "Belleville",
    "Belleville-en-Caux",
    "Belleville-sur-Loire",
    "Belleville-sur-Mer",
    "Belleville-sur-Meuse",
    "Belleville-sur-Vie",
    "Bellevue-la-Montagne",
    "Belley",
    "Bellignat",
    "Belloy-en-France",
    "Belmont",
    "Belmont",
    "Belmont-sur-Rance",
    "Belmontet",
    "Belpech",
    "Belrupt-en-Verdunois",
    "Belveze",
    "Belz",
    "Benamenil",
    "Bene",
    "Benerville-sur-Mer",
    "Benesse-Maremne",
    "Benestroff",
    "Benfeld",
    "Benifontaine",
    "Benney",
    "Bennwihr",
    "Benon",
    "Benouville",
    "Beon",
    "Berat",
    "Bercenay-en-Othe",
    "Berck",
    "Berck-Plage",
    "Berdoues",
    "Berenx",
    "Berg-sur-Moselle",
    "Bergerac",
    "Bergheim",
    "Bergholtz",
    "Bergonne",
    "Bergues",
    "Berjou",
    "Berlaimont",
    "Berles-au-Bois",
    "Bermeries",
    "Bermicourt",
    "Bernadets",
    "Bernard",
    "Bernaville",
    "Bernay",
    "Bernay-Saint-Martin",
    "Berne",
    "Bernes-sur-Oise",
    "Berneuil-sur-Aisne",
    "Berneval-le-Grand",
    "Bernex",
    "Bernieres-le-Patry",
    "Bernieres-sur-Mer",
    "Bernin",
    "Bernis",
    "Bernos-Beaulac",
    "Berriac",
    "Berric",
    "Berry-Bouy",
    "Bersaillin",
    "Bersee",
    "Berson",
    "Berthecourt",
    "Berthelange",
    "Berthen",
    "Bertholene",
    "Bertin",
    "Bertincourt",
    "Bertrange",
    "Bertrimoutier",
    "Bertry",
    "Berville-en-Roumois",
    "Berville-la-Campagne",
    "Berville-sur-Seine",
    "Berviller-en-Moselle",
    "Berzeme",
    "Berzet",
    "Besayes",
    "Besne",
    "Bessais-le-Fromental",
    "Bessan",
    "Bessancourt",
    "Bessay",
    "Besse",
    "Besse",
    "Besse-sur-Braye",
    "Besse-sur-Issole",
    "Besseges",
    "Bessenay",
    "Bessens",
    "Bessey-les-Citeaux",
    "Bessieres",
    "Bessines",
    "Bessines-sur-Gartempe",
    "Besson",
    "Bessoncourt",
    "Bessonies",
    "Betaille",
    "Bethancourt-en-Valois",
    "Bethencourt-sur-Mer",
    "Betheniville",
    "Betheny",
    "Bethisy-Saint-Pierre",
    "Bethoncourt",
    "Betschdorf",
    "Bettancourt-la-Ferree",
    "Bettignies",
    "Betting",
    "Betton",
    "Bettviller",
    "Bettwiller",
    "Betz",
    "Beugin",
    "Beugneux",
    "Beure",
    "Beurlay",
    "Beuste",
    "Beutal",
    "Beuvillers",
    "Beuvrages",
    "Beuvraignes",
    "Beuvron-en-Auge",
    "Beuvry-la-Foret",
    "Beuzec-Cap-Sizun",
    "Beuzeville",
    "Bevenais",
    "Beychac-et-Caillau",
    "Beynes",
    "Beynost",
    "Beyrede-Jumet",
    "Beyrie-en-Bearn",
    "Beyssenac",
    "Bezac",
    "Bezannes",
    "Bezons",
    "Bezouce",
    "Bezu-Saint-Eloi",
    "Biache-Saint-Vaast",
    "Biard",
    "Biarritz",
    "Biars-sur-Cere",
    "Biaudos",
    "Bichancourt",
    "Bicqueley",
    "Bidache",
    "Bidarray",
    "Bidart",
    "Bief-du-Fourg",
    "Bielle",
    "Bienville",
    "Bienvillers-au-Bois",
    "Biermont",
    "Bierne",
    "Bierry-les-Belles-Fontaines",
    "Biesheim",
    "Biesles",
    "Bietlenheim",
    "Bieville-Beuville",
    "Bievres",
    "Biganos",
    "Bignac",
    "Bignan",
    "Bignoux",
    "Biguglia",
    "Bihorel",
    "Bilieu",
    "Billere",
    "Billey",
    "Billiers",
    "Billom",
    "Billy",
    "Billy",
    "Billy-Berclau",
    "Billy-Montigny",
    "Billy-sous-les-Cotes",
    "Billy-sur-Ourcq",
    "Bindernheim",
    "Binges",
    "Binic",
    "Bining",
    "Bionville-sur-Nied",
    "Biot",
    "Biot",
    "Bioule",
    "Birac-sur-Trec",
    "Biriatou",
    "Birieux",
    "Biscarrosse",
    "Bischheim",
    "Bischoffsheim",
    "Bischwihr",
    "Bischwiller",
    "Bisel",
    "Bitche",
    "Biviers",
    "Biville-sur-Mer",
    "Bizanos",
    "Blacourt",
    "Blacqueville",
    "Blacy",
    "Blaesheim",
    "Blagnac",
    "Blagny",
    "Blaignan",
    "Blain",
    "Blainville",
    "Blainville-sur-Mer",
    "Blainville-sur-Orne",
    "Blaise",
    "Blaise-sous-Arzillieres",
    "Blaison-Gohier",
    "Blamont",
    "Blamont",
    "Blan",
    "Blancafort",
    "Blanchefosse-et-Bay",
    "Blangy-sur-Bresle",
    "Blanot",
    "Blanquefort",
    "Blanzat",
    "Blanzay-sur-Boutonne",
    "Blanzy",
    "Blanzy-la-Salonnaise",
    "Blaringhem",
    "Blaudeix",
    "Blausasc",
    "Blauzac",
    "Blavozy",
    "Blaye",
    "Blaye-les-Mines",
    "Bleigny-le-Carreau",
    "Blendecques",
    "Blenod-les-Pont-a-Mousson",
    "Blenod-les-Toul",
    "Blerancourdelle",
    "Blere",
    "Bleriot-Plage",
    "Blesle",
    "Bletterans",
    "Bleury",
    "Bligny-les-Beaune",
    "Blincourt",
    "Blingel",
    "Blodelsheim",
    "Blois",
    "Blomard",
    "Blondefontaine",
    "Blonville-sur-Mer",
    "Blotzheim",
    "Blou",
    "Bloye",
    "Blyes",
    "Bobigny",
    "Bocquegney",
    "Boe",
    "Boege",
    "Boeil-Bezing",
    "Boen-sur-Lignon",
    "Boersch",
    "Boeschepe",
    "Boeseghem",
    "Boesenbiesen",
    "Bohain-en-Vermandois",
    "Bohal",
    "Boigneville",
    "Boigny",
    "Boigny-sur-Bionne",
    "Bois",
    "Bois-Anzeray",
    "Bois-Arnault",
    "Bois-Colombes",
    "Bois-de-Cene",
    "Bois-de-Gand",
    "Bois-de-la-Pierre",
    "Bois-Grenier",
    "Bois-Guillaume",
    "Bois-Jerome-Saint-Ouen",
    "Bois-le-Roi",
    "Bois-le-Roi",
    "Boisbergues",
    "Boiscommun",
    "Boisemont",
    "Boisemont",
    "Boisjean",
    "Boisleux-au-Mont",
    "Boismont",
    "Boismorand",
    "Boisse-Penchot",
    "Boisseron",
    "Boisset-et-Gaujac",
    "Boisseuil",
    "Boissey-le-Chatel",
    "Boissezon",
    "Boissieres",
    "Boissise-la-Bertrand",
    "Boissise-le-Roi",
    "Boissy-Fresnoy",
    "Boissy-le-Bois",
    "Boissy-le-Chatel",
    "Boissy-le-Girard",
    "Boissy-Maugis",
    "Boissy-Mauvoisin",
    "Boissy-sous-Saint-Yon",
    "Boisville-la-Saint-Pere",
    "Boisyvon",
    "Bolandoz",
    "Bolbec",
    "Bollene",
    "Bolleville",
    "Bollezeele",
    "Bollwiller",
    "Bolsenheim",
    "Bommes",
    "Bompas",
    "Bompas",
    "Bon-Encontre",
    "Bonchamp-les-Laval",
    "Boncourt-le-Bois",
    "Boncourt-sur-Meuse",
    "Bondeville",
    "Bondigoux",
    "Bondoufle",
    "Bondues",
    "Bondy",
    "Bongheat",
    "Bonifacio",
    "Bonlier",
    "Bonnard",
    "Bonnat",
    "Bonnebosq",
    "Bonnee",
    "Bonnegarde",
    "Bonneguete",
    "Bonneil",
    "Bonnelles",
    "Bonnemain",
    "Bonnes",
    "Bonnet",
    "Bonnet",
    "Bonnet",
    "Bonnetable",
    "Bonnetage",
    "Bonnetan",
    "Bonneuil-en-France",
    "Bonneuil-en-Valois",
    "Bonneuil-les-Eaux",
    "Bonneuil-sur-Marne",
    "Bonneval",
    "Bonnevaux-le-Prieure",
    "Bonnevent-Velloreille",
    "Bonneville",
    "Bonneville",
    "Bonneville-Aptot",
    "Bonneville-la-Louvet",
    "Bonnieres-sur-Seine",
    "Bonnieux",
    "Bonny-sur-Loire",
    "Bonrepos",
    "Bonrepos-Riquet",
    "Bons-en-Chablais",
    "Bonsecours",
    "Bonson",
    "Bonzac",
    "Boos",
    "Boos",
    "Boqueho",
    "Bor-et-Bar",
    "Boran-sur-Oise",
    "Bord",
    "Bord",
    "Bordeaux",
    "Borderes",
    "Borderes-et-Lamensans",
    "Bordes",
    "Bords",
    "Borgo",
    "Bormes-les-Mimosas",
    "Bornay",
    "Bornel",
    "Bosc Renoult",
    "Bosc-Guerard-Saint-Adrien",
    "Bosc-le-Hard",
    "Bosc-Mesnil",
    "Bosgouet",
    "Bosguerard-de-Marcouville",
    "Bosjean",
    "Bosmont-sur-Serre",
    "Bosrobert",
    "Bossancourt",
    "Bossay-sur-Claise",
    "Bossendorf",
    "Bossey",
    "Botsorhel",
    "Bouafles",
    "Bouan",
    "Bouaye",
    "Boubers-sur-Canche",
    "Boubiers",
    "Bouc-Bel-Air",
    "Boucau",
    "Bouchain",
    "Bouchemaine",
    "Boucheporn",
    "Bouchet",
    "Boucoiran-et-Nozieres",
    "Bouee",
    "Bouer",
    "Bouere",
    "Bouex",
    "Bouffemont",
    "Bouffere",
    "Bougarber",
    "Bouge-Chambalud",
    "Bougival",
    "Bougnon",
    "Bougue",
    "Bouguenais",
    "Bougy",
    "Bouhet",
    "Bouillargues",
    "Bouilly",
    "Bouilly",
    "Bouilly-en-Gatinais",
    "Bouin",
    "Boujan-sur-Libron",
    "Boulages",
    "Boulancourt",
    "Boulange",
    "Boulay-les-Barres",
    "Boulay-Moselle",
    "Boulazac",
    "Bouleurs",
    "Bouliac",
    "Boulieu-les-Annonay",
    "Bouligney",
    "Bouligny",
    "Boullay-les-Troux",
    "Boulleret",
    "Boulleville",
    "Bouloc",
    "Boulogne-Billancourt",
    "Boulogne-la-Grasse",
    "Boulogne-sur-Gesse",
    "Boulogne-sur-Mer",
    "Bouloire",
    "Boult-sur-Suippe",
    "Boulzicourt",
    "Bouniagues",
    "Bouquet",
    "Bouquetot",
    "Bouray-sur-Juine",
    "Bourbon-Lancy",
    "Bourbonne-les-Bains",
    "Bourbourg",
    "Bourbriac",
    "Bourcefranc-le-Chapus",
    "Bourdenay",
    "Bourdon",
    "Bouresse",
    "Bourg",
    "Bourg-Achard",
    "Bourg-Argental",
    "Bourg-Blanc",
    "Bourg-de-Thizy",
    "Bourg-en-Bresse",
    "Bourg-la-Reine",
    "Bourg-le-Comte",
    "Bourg-le-Roi",
    "Bourg-les-Valence",
    "Bourg-Saint-Andeol",
    "Bourg-Saint-Maurice",
    "Bourg-Sainte-Marie",
    "Bourg-sous-Chatelet",
    "Bourganeuf",
    "Bourgbarre",
    "Bourges",
    "Bourget",
    "Bourghelles",
    "Bourgneuf",
    "Bourgneuf",
    "Bourgneuf-en-Retz",
    "Bourgogne",
    "Bourgogne",
    "Bourgoin",
    "Bourguebus",
    "Bourgueil",
    "Bourguignon-sous-Coucy",
    "Bourlon",
    "Bourneville",
    "Bournezeau",
    "Bourogne",
    "Bourran",
    "Bourron-Marlotte",
    "Bours",
    "Bours",
    "Boursault",
    "Bourseville",
    "Boursieres",
    "Bourth",
    "Boury-en-Vexin",
    "Bousbach",
    "Bousbecque",
    "Bousies",
    "Boussac",
    "Boussac",
    "Boussay",
    "Boussenac",
    "Boussens",
    "Boussieres",
    "Boussois",
    "Boussy-Saint-Antoine",
    "Boust",
    "Boutancourt",
    "Boutiers-Saint-Trojan",
    "Boutigny-Prouais",
    "Boutigny-sur-Essonne",
    "Bouvaincourt-sur-Bresle",
    "Bouvante",
    "Bouvelinghem",
    "Bouvesse-Quirieu",
    "Bouvigny-Boyeffles",
    "Bouville",
    "Bouvincourt-en-Vermandois",
    "Bouvron",
    "Bouxieres-aux-Chenes",
    "Bouxieres-aux-Dames",
    "Bouxwiller",
    "Bouzel",
    "Bouzigues",
    "Bouzincourt",
    "Bouzonville",
    "Bouzonville-en-Beauce",
    "Bouzy",
    "Bouzy-la-Foret",
    "Boves",
    "Boyer",
    "Boynes",
    "Boz",
    "Bozel",
    "Bozouls",
    "Brachy",
    "Bracieux",
    "Brain-sur-Allonnes",
    "Brain-sur-Vilaine",
    "Braine",
    "Brains",
    "Bram",
    "Bramans",
    "Branches",
    "Brancourt-en-Laonnois",
    "Brandivy",
    "Brando",
    "Brandonnet",
    "Branges",
    "Branne",
    "Branoux-les-Taillades",
    "Bransles",
    "Braquis",
    "Bras",
    "Brasles",
    "Brasparts",
    "Brassac",
    "Brassac-les-Mines",
    "Brassempouy",
    "Braucourt",
    "Braud-et-Saint-Louis",
    "Brax",
    "Brax",
    "Bray",
    "Bray-Dunes",
    "Bray-en-Val",
    "Bray-et-Lu",
    "Bray-Saint-Christophe",
    "Bray-sur-Seine",
    "Bray-sur-Somme",
    "Braye-sous-Faye",
    "Brazey-en-Plaine",
    "Breal-sous-Montfort",
    "Brebieres",
    "Brece",
    "Brecey",
    "Brech",
    "Breconchaux",
    "Bree",
    "Bregy",
    "Brehal",
    "Brehand",
    "Breil-sur-Roya",
    "Breitenbach-Haut-Rhin",
    "Breles",
    "Bremenil",
    "Bremontier-Merval",
    "Brenouille",
    "Brens",
    "Bresles",
    "Bresse-sur-Grosne",
    "Bressolles",
    "Bressolles",
    "Bressols",
    "Bresson",
    "Bressuire",
    "Brest",
    "Bretagne",
    "Breteil",
    "Breteniere",
    "Bretenoux",
    "Breteuil",
    "Breteuil",
    "Bretignolles",
    "Bretignolles-sur-Mer",
    "Bretigny",
    "Bretigny",
    "Bretteville-sur-Laize",
    "Bretteville-sur-Odon",
    "Breuil",
    "Breuil",
    "Breuil-le-Sec",
    "Breuil-le-Vert",
    "Breuil-Magne",
    "Breuillet",
    "Breuillet",
    "Breuilpont",
    "Breuschwickersheim",
    "Breuvannes-en-Bassigny",
    "Breux-sur-Avre",
    "Breval",
    "Breviandes",
    "Brexent-Enocq",
    "Breze",
    "Brezins",
    "Brezolles",
    "Brianconnet",
    "Briare",
    "Briarres-sur-Essonne",
    "Briatexte",
    "Bricon",
    "Bricquebec",
    "Bricqueville-la-Blouette",
    "Bricqueville-sur-Mer",
    "Bricy",
    "Brides-les-Bains",
    "Bridore",
    "Brie",
    "Brie",
    "Brie-Comte-Robert",
    "Brie-et-Angonnes",
    "Briec",
    "Brienne-la-Vieille",
    "Briennon",
    "Brienon-sur-Armancon",
    "Brieres-les-Scelles",
    "Brieuil-sur-Chize",
    "Brieulles-sur-Meuse",
    "Briey",
    "Brignac-la-Plaine",
    "Brignais",
    "Brigne",
    "Brignoles",
    "Brignon",
    "Brignoud",
    "Brigueuil",
    "Briis-sous-Forges",
    "Brimeux",
    "Brin-sur-Seille",
    "Brinay",
    "Brindas",
    "Bringolo",
    "Brinon-sur-Beuvron",
    "Briollay",
    "Brion",
    "Brion",
    "Brionne",
    "Briord",
    "Brioude",
    "Brioux-sur-Boutonne",
    "Briouze",
    "Briscous",
    "Brison-Saint-Innocent",
    "Brissac",
    "Brissac-Quince",
    "Brissy-Hamegicourt",
    "Brive",
    "Brive-la-Gaillarde",
    "Brives-Charensac",
    "Brives-sur-Charente",
    "Brix",
    "Brochon",
    "Broglie",
    "Brognon",
    "Broin",
    "Brombos",
    "Bron",
    "Broons",
    "Brossac",
    "Brosville",
    "Brou",
    "Brou-sur-Chantereine",
    "Brouay",
    "Brouckerque",
    "Brouderdorff",
    "Broue",
    "Brouennes",
    "Brouilla",
    "Brouqueyran",
    "Brousse",
    "Brousse",
    "Brousseval",
    "Brouviller",
    "Bru",
    "Bruch",
    "Brue-Auriac",
    "Bruebach",
    "Bruges",
    "Brugheas",
    "Brugny-Vaudancourt",
    "Bruguieres",
    "Bruille-Saint-Amand",
    "Bruley",
    "Brullioles",
    "Brulon",
    "Brumath",
    "Brunoy",
    "Brunstatt",
    "Brussey",
    "Brussieu",
    "Brusvily",
    "Brux",
    "Bruyeres-et-Montberault",
    "Bruyeres-le-Chatel",
    "Bruyeres-sur-Oise",
    "Bruz",
    "Bry-sur-Marne",
    "Bu",
    "Buanes",
    "Bubry",
    "Buc",
    "Buceels",
    "Bucey-les-Gy",
    "Bucheres",
    "Buchy",
    "Bucquoy",
    "Bucy-le-Long",
    "Bucy-les-Cerny",
    "Budos",
    "Bueil",
    "Buellas",
    "Buffard",
    "Bugeat",
    "Bugnicourt",
    "Buhl",
    "Buigny-les-Gamaches",
    "Buire-le-Sec",
    "Buironfosse",
    "Buis-les-Baronnies",
    "Bulat-Pestivien",
    "Bulgneville",
    "Bulhon",
    "Bulles",
    "Bully",
    "Bully-les-Mines",
    "Buno-Bonnevaux",
    "Bunzac",
    "Burbure",
    "Bures-sur-Yvette",
    "Burie",
    "Burlats",
    "Burnhaupt-le-Haut",
    "Buros",
    "Bury",
    "Busigny",
    "Busloup",
    "Busque",
    "Bussac-Foret",
    "Bussang",
    "Bussiere-Galant",
    "Bussiere-Poitevine",
    "Bussieres-les-Belmont",
    "Bussunarits-Sarrasquette",
    "Bussus-Bussuel",
    "Bussy-le-Chateau",
    "Bussy-le-Grand",
    "Bussy-les-Daours",
    "Bussy-Lettree",
    "Bussy-Saint-Georges",
    "Busy",
    "Buthiers",
    "Butry-sur-Oise",
    "Buxerolles",
    "Buxeuil",
    "Buxieres-les-Mines",
    "Buxy",
    "Buzan",
    "Buzancais",
    "Buzet-sur-Baise",
    "Buzet-sur-Tarn",
    "Buzy",
    "Cabanac",
    "Cabanac-et-Villagrains",
    "Cabannes",
    "Cabara",
    "Cabariot",
    "Cabasse",
    "Cabestany",
    "Cabourg",
    "Cabries",
    "Cabris",
    "Cachan",
    "Cadalen",
    "Cadaujac",
    "Caden",
    "Cadenet",
    "Cadillac",
    "Cadillac-en-Fronsadais",
    "Cadolive",
    "Cadours",
    "Caen",
    "Caestre",
    "Cagnac-les-Mines",
    "Cagnes-sur-Mer",
    "Cagnicourt",
    "Cagny",
    "Cagny",
    "Cahagnes",
    "Cahors",
    "Cahuzac",
    "Cahuzac",
    "Cahuzac-sur-Vere",
    "Caille",
    "Caillouel-Crepigny",
    "Cailloux-sur-Fontaines",
    "Cairanne",
    "Cairon",
    "Caissargues",
    "Cajarc",
    "Calais",
    "Calas",
    "Calavante",
    "Calcatoggio",
    "Calenzana",
    "Callac",
    "Callac",
    "Callas",
    "Calleville",
    "Callian",
    "Calmels-et-le-Viala",
    "Calmont",
    "Calonne-Ricouart",
    "Calonne-sur-la-Lys",
    "Caluire-et-Cuire",
    "Calvi",
    "Calvisson",
    "Camaret-sur-Aigues",
    "Camaret-sur-Mer",
    "Camarsac",
    "Cambes",
    "Cambieure",
    "Camblain-Chatelain",
    "Camblanes-et-Meynac",
    "Cambo-les-Bains",
    "Cambounet-sur-le-Sor",
    "Cambrai",
    "Cambrin",
    "Cambronne-les-Ribecourt",
    "Came",
    "Cameyrac",
    "Camiac-et-Saint-Denis",
    "Camiers",
    "Camlez",
    "Camoel",
    "Camon",
    "Camon",
    "Camors",
    "Campagnan",
    "Campagne",
    "Campagne",
    "Campagne-les-Hesdin",
    "Campagne-les-Wardrecques",
    "Campagnolles",
    "Campeaux",
    "Campeneac",
    "Campet-et-Lamolere",
    "Camphin-en-Carembault",
    "Camphin-en-Pevele",
    "Campigneulles-les-Petites",
    "Campremy",
    "Camprond",
    "Camps-la-Source",
    "Campuac",
    "Campugnan",
    "Canaples",
    "Cancale",
    "Cancon",
    "Cande",
    "Cande-sur-Beuvron",
    "Candillargues",
    "Candresse",
    "Canejan",
    "Canet-de-Salars",
    "Canet-Plage",
    "Cangey",
    "Canisy",
    "Canlers",
    "Canly",
    "Cannes",
    "Cannes-Ecluse",
    "Cannet",
    "Canohes",
    "Canouville",
    "Canteleu",
    "Cantin",
    "Cany-Barville",
    "Capbreton",
    "Capdenac-Gare",
    "Capelle",
    "Capelle-Fermont",
    "Capendu",
    "Capens",
    "Capestang",
    "Capinghem",
    "Capoulet-et-Junac",
    "Cappel",
    "Cappelle-Brouck",
    "Cappelle-en-Pevele",
    "Cappelle-la-Grande",
    "Captieux",
    "Capvern",
    "Caragoudes",
    "Caraman",
    "Carantec",
    "Carantilly",
    "Carbon-Blanc",
    "Carbonne",
    "Carcans",
    "Carcassonne",
    "Carces",
    "Carcopino",
    "Cardaillac",
    "Cardesse",
    "Cardonville",
    "Cardroc",
    "Carency",
    "Carentan",
    "Carentoir",
    "Carhaix-Plouguer",
    "Carignan",
    "Carling",
    "Carlipa",
    "Carlucet",
    "Carlus",
    "Carmaux",
    "Carnac",
    "Carneville",
    "Carnieres",
    "Carnin",
    "Carnon-Plage",
    "Carnoules",
    "Carolles",
    "Caromb",
    "Carpentras",
    "Carpiquet",
    "Carquefou",
    "Carqueiranne",
    "Carresse-Cassaber",
    "Carrieres-sous-Poissy",
    "Carrieres-sur-Seine",
    "Carros",
    "Carrouges",
    "Carry-le-Rouet",
    "Carsac-Aillac",
    "Carspach",
    "Cartelegue",
    "Cartignies",
    "Carville-la-Folletiere",
    "Carvin",
    "Casanova",
    "Cases-de-Pene",
    "Cassagne",
    "Cassagnes",
    "Cassagnes-Begonhes",
    "Cassagnoles",
    "Cassel",
    "Casseneuil",
    "Cassis",
    "Casson",
    "Cast",
    "Castagnede",
    "Castanet-le-Haut",
    "Castanet-Tolosan",
    "Casteide-Cami",
    "Castelginest",
    "Casteljaloux",
    "Castellane",
    "Castellar",
    "Castellare-di-Mercurio",
    "Castello-di-Rostino",
    "Castelmoron-sur-Lot",
    "Castelnau-de-Brassac",
    "Castelnau-de-Levis",
    "Castelnau-de-Montmiral",
    "Castelnau-Durban",
    "Castelnau-le-Lez",
    "Castelnau-Montratier",
    "Castelnau-sur-Gupie",
    "Castelnau-Valence",
    "Castelnaud-de-Gratecambe",
    "Castelnaudary",
    "Castels",
    "Castelsagrat",
    "Castelsarrasin",
    "Castera-Loubix",
    "Castet",
    "Castetner",
    "Castets-en-Dorthe",
    "Castiglione",
    "Castillon-Debats",
    "Castillon-du-Gard",
    "Castillon-la-Bataille",
    "Castillon-Massas",
    "Castillonnes",
    "Castilly",
    "Castres",
    "Castres-Gironde",
    "Castries",
    "Catenay",
    "Catenoy",
    "Cattenom",
    "Catus",
    "Caucalieres",
    "Caudan",
    "Caudebec-en-Caux",
    "Caudecoste",
    "Caudrot",
    "Caudry",
    "Cauge",
    "Caullery",
    "Caulnes",
    "Caumont",
    "Caumont",
    "Caumont-sur-Durance",
    "Caumont-sur-Garonne",
    "Cauneille",
    "Caunes-Minervois",
    "Caurel",
    "Cauroir",
    "Cauroy-les-Hermonville",
    "Caussade-Riviere",
    "Causse-de-la-Selle",
    "Cauvigny",
    "Cauville-sur-Mer",
    "Caux",
    "Caux-et-Sauzens",
    "Cauzac",
    "Cavaillon",
    "Cavalaire-sur-Mer",
    "Cavan",
    "Caveirac",
    "Cavignac",
    "Cavillargues",
    "Cavillon",
    "Cayeux-sur-Mer",
    "Caylus",
    "Cayrac",
    "Cazals-des-Bayles",
    "Cazaux",
    "Cazaux-Layrisse",
    "Cazavet",
    "Cazeres",
    "Cazes-Mondenard",
    "Cazevieille",
    "Cazilhac",
    "Cazouls-les-Beziers",
    "Ceauce",
    "Ceaux",
    "Cebazat",
    "Ceignes",
    "Ceintrey",
    "Celle-Levescault",
    "Celles-en-Bassigny",
    "Celles-sur-Belle",
    "Celles-sur-Durolle",
    "Cellettes",
    "Cellieu",
    "Celon",
    "Cenac",
    "Cendras",
    "Cenon",
    "Cenon-sur-Vienne",
    "Centres",
    "Cepet",
    "Cepie",
    "Cepoy",
    "Cerans-Foulletourte",
    "Cercier",
    "Cercoux",
    "Cerdon",
    "Cere",
    "Cerelles",
    "Cereste",
    "Ceret",
    "Cergy",
    "Cerilly",
    "Cerise",
    "Cerisiers",
    "Cerisy-la-Foret",
    "Cerisy-la-Salle",
    "Cerizay",
    "Cernay",
    "Cernay-la-Ville",
    "Cerneux",
    "Cerny",
    "Cerons",
    "Cers",
    "Certines",
    "Cervens",
    "Cervera de la Marenda",
    "Cervieres",
    "Cervione",
    "Cesar",
    "Cesarches",
    "Cescau",
    "Cesny-aux-Vignes",
    "Cesny-Bois-Halbout",
    "Cessales",
    "Cessenon-sur-Orb",
    "Cesseras",
    "Cessey",
    "Cessieu",
    "Cesson",
    "Cesson",
    "Cessy",
    "Cestas",
    "Ceton",
    "Ceyrat",
    "Ceyreste",
    "Ceyssat",
    "Ceyzeriat",
    "Cezay",
    "Chaban",
    "Chabanais",
    "Chabestan",
    "Chabeuil",
    "Chablis",
    "Chabons",
    "Chabottes",
    "Chabrillan",
    "Chabris",
    "Chacenay",
    "Chadeleuf",
    "Chadrac",
    "Chadron",
    "Chaffois",
    "Chagey",
    "Chagny",
    "Chaignay",
    "Chail",
    "Chaillac",
    "Chaillac-sur-Vienne",
    "Chaille-les-Marais",
    "Chailles",
    "Chaillevette",
    "Chailloue",
    "Chailly-en-Biere",
    "Chailly-en-Gatinais",
    "Chainaz-les-Frasses",
    "Chaingy",
    "Chaix",
    "Chalais",
    "Chalamont",
    "Chalampe",
    "Chalandry-Elaire",
    "Chaleins",
    "Chalencon",
    "Chalette-sur-Loing",
    "Chalette-sur-Voire",
    "Chaleze",
    "Chalezeule",
    "Chaligny",
    "Chalindrey",
    "Challain-la-Potherie",
    "Challans",
    "Challes-les-Eaux",
    "Challet",
    "Challex",
    "Chalmaison",
    "Chalo-Saint-Mars",
    "Chalon",
    "Chalonnes-sur-Loire",
    "Chalons-en-Champagne",
    "Chalonvillars",
    "Chalou-Moulineux",
    "Chamagne",
    "Chamagnieu",
    "Chambalud",
    "Chamberet",
    "Chambilly",
    "Chamblac",
    "Chamblanc",
    "Chambles",
    "Chamblet",
    "Chambley-Bussieres",
    "Chambly",
    "Chamboeuf",
    "Chambon",
    "Chambon-sur-Lac",
    "Chambon-sur-Voueize",
    "Chambonas",
    "Chambord",
    "Chamboret",
    "Chamborigaud",
    "Chambors",
    "Chambost-Longessaigne",
    "Chamboulive",
    "Chambourcy",
    "Chambourg-sur-Indre",
    "Chambrecy",
    "Chambretaud",
    "Chambry",
    "Chamelet",
    "Chameyrat",
    "Chamigny",
    "Chamonix",
    "Chamouilley",
    "Chamoux",
    "Chamoux-sur-Gelon",
    "Champ",
    "Champ-le-Duc",
    "Champ-sur-Drac",
    "Champagnac",
    "Champagnac-le-Vieux",
    "Champagnat",
    "Champagne",
    "Champagne",
    "Champagne-les-Marais",
    "Champagne-Mouton",
    "Champagne-sur-Oise",
    "Champagne-sur-Seine",
    "Champagney",
    "Champagnole",
    "Champanges",
    "Champcenest",
    "Champcerie",
    "Champcevinel",
    "Champcueil",
    "Champdieu",
    "Champdotre",
    "Champdray",
    "Champeaux",
    "Champeaux",
    "Champeix",
    "Champenay",
    "Champenoux",
    "Champeon",
    "Champey-sur-Moselle",
    "Champforgeuil",
    "Champfromier",
    "Champhol",
    "Champien",
    "Champier",
    "Champigne",
    "Champigneulles",
    "Champigny",
    "Champigny-sur-Marne",
    "Champlan",
    "Champlemy",
    "Champlitte",
    "Champniers",
    "Champoly",
    "Champoulet",
    "Champs",
    "Champs-sur-Marne",
    "Champs-sur-Tarentaine-Marchal",
    "Champs-sur-Yonne",
    "Champsac",
    "Champtercier",
    "Champtoce-sur-Loire",
    "Champtoceaux",
    "Champvans",
    "Chanas",
    "Chanceaux-sur-Choisille",
    "Chancelade",
    "Chancenay",
    "Change",
    "Changis-sur-Marne",
    "Chaniers",
    "Chanonat",
    "Chanoz-Chatenay",
    "Chanteau",
    "Chantecorps",
    "Chanteheux",
    "Chanteloup",
    "Chanteloup-en-Brie",
    "Chanteloup-les-Bois",
    "Chanteloup-les-Vignes",
    "Chantemerle-sur-la-Soie",
    "Chantepie",
    "Chanteuges",
    "Chantilly",
    "Chantome",
    "Chantonnay",
    "Chantraine",
    "Chantrans",
    "Chanveaux",
    "Chaon",
    "Chapareillan",
    "Chapdes-Beaufort",
    "Chapeiry",
    "Chapelle-Vallon",
    "Chapelle-Voland",
    "Chaponnay",
    "Chaponost",
    "Chaptelat",
    "Chaptuzat",
    "Charancieu",
    "Charantonnay",
    "Charavines",
    "Charbonnieres-les-Bains",
    "Charbonnieres-les-Varennes",
    "Charbuy",
    "Charentay",
    "Charenton-le-Pont",
    "Charge",
    "Chargey-les-Gray",
    "Chargey-les-Port",
    "Charleval",
    "Charleval",
    "Charleville",
    "Charlieu",
    "Charly",
    "Charly-sur-Marne",
    "Charmant",
    "Charmes",
    "Charmes",
    "Charmes-sur-Rhone",
    "Charmont-en-Beauce",
    "Charmont-sous-Barbuise",
    "Charnas",
    "Charnay",
    "Charnay",
    "Charnay-les-Macon",
    "Charnecles",
    "Charny",
    "Charolles",
    "Charonville",
    "Charost",
    "Charquemont",
    "Charrecey",
    "Charron",
    "Charroux",
    "Charsonville",
    "Charteves",
    "Chartres",
    "Chartres-de-Bretagne",
    "Chartrettes",
    "Charvieu-Chavagneux",
    "Charvonnex",
    "Chas",
    "Chasnais",
    "Chasne-sur-Illet",
    "Chaspinhac",
    "Chassagnes",
    "Chassagny",
    "Chasse-sur-Rhone",
    "Chasselay",
    "Chassemy",
    "Chassenard",
    "Chasseneuil",
    "Chasseneuil-du-Poitou",
    "Chasseneuil-sur-Bonnieure",
    "Chassey-le-Camp",
    "Chassiers",
    "Chassieu",
    "Chataincourt",
    "Chateau",
    "Chateau-Arnoux-Saint-Auban",
    "Chateau-Chervix",
    "Chateau-Chinon(Ville)",
    "Chateau-du-Loir",
    "Chateau-Gontier",
    "Chateau-Landon",
    "Chateau-Porcien",
    "Chateau-Renault",
    "Chateau-Salins",
    "Chateau-sur-Epte",
    "Chateaubleau",
    "Chateaubourg",
    "Chateaubriand",
    "Chateaubriant",
    "Chateaudouble",
    "Chateaufort",
    "Chateaugay",
    "Chateaugiron",
    "Chateaumeillant",
    "Chateauneuf",
    "Chateauneuf-de-Bordette",
    "Chateauneuf-de-Gadagne",
    "Chateauneuf-de-Galaure",
    "Chateauneuf-du-Faou",
    "Chateauneuf-du-Rhone",
    "Chateauneuf-en-Thymerais",
    "Chateauneuf-Grasse",
    "Chateauneuf-la-Foret",
    "Chateauneuf-le-Rouge",
    "Chateauneuf-les-Martigues",
    "Chateauneuf-sur-Charente",
    "Chateauneuf-sur-Loire",
    "Chateauneuf-sur-Sarthe",
    "Chateaurenard",
    "Chateauvillain",
    "Chatel",
    "Chatel-de-Neuvre",
    "Chatel-Guyon",
    "Chatel-Saint-Germain",
    "Chatel-sur-Moselle",
    "Chatelaillon-Plage",
    "Chatelaines",
    "Chatelais",
    "Chatelaudren",
    "Chateldon",
    "Chatelet",
    "Chatelus",
    "Chatelus-le-Marcheix",
    "Chatenay-sur-Seine",
    "Chatenet",
    "Chatenois",
    "Chatenois",
    "Chatenois-les-Forges",
    "Chatenoy",
    "Chatenoy-le-Royal",
    "Chatignonville",
    "Chatillon",
    "Chatillon-Coligny",
    "Chatillon-la-Palud",
    "Chatillon-le-Duc",
    "Chatillon-le-Roi",
    "Chatillon-les-Sons",
    "Chatillon-sur-Chalaronne",
    "Chatillon-sur-Cher",
    "Chatillon-sur-Indre",
    "Chatillon-sur-Loire",
    "Chatillon-sur-Saone",
    "Chatillon-sur-Seiche",
    "Chatillon-sur-Thouet",
    "Chatonnay",
    "Chatonrupt-Sommermont",
    "Chatou",
    "Chatres",
    "Chatres-sur-Cher",
    "Chatte",
    "Chatuzange-le-Goubet",
    "Chauche",
    "Chaudefonds-sur-Layon",
    "Chaudefontaine",
    "Chaudon",
    "Chaudron-en-Mauges",
    "Chauffailles",
    "Chauffry",
    "Chaufour-les-Bonnieres",
    "Chaufour-Notre-Dame",
    "Chaulnes",
    "Chaumes",
    "Chaumes-en-Brie",
    "Chaumont",
    "Chaumont",
    "Chaumont-en-Vexin",
    "Chaumontel",
    "Chaumousey",
    "Chauny",
    "Chauray",
    "Chauriat",
    "Chaussin",
    "Chaussoy-Epagny",
    "Chaussy",
    "Chauve",
    "Chauvigny",
    "Chauvincourt-Provemont",
    "Chauvry",
    "Chaux",
    "Chavagne",
    "Chavagnes-en-Paillers",
    "Chavagnes-les-Redoux",
    "Chavaignes",
    "Chavanay",
    "Chavannes-sur-Reyssouze",
    "Chavanod",
    "Chavanoz",
    "Chavaroux",
    "Chavenay",
    "Chavigny",
    "Chaville",
    "Chazelles",
    "Chazelles-sur-Lyon",
    "Checy",
    "Chedigny",
    "Chef-Boutonne",
    "Cheffes",
    "Chehery",
    "Chelan",
    "Chelles",
    "Chemaze",
    "Chemere",
    "Chemere-le-Roi",
    "Chemery",
    "Chemery-les-Deux",
    "Chemille-Melay",
    "Chemilly-sur-Serein",
    "Cheminot",
    "Chemy",
    "Chenailler-Mascheix",
    "Chenaud",
    "Chenay-le-Chatel",
    "Chenerailles",
    "Chenevelles",
    "Chenex",
    "Chenimenil",
    "Chennevieres-sur-Marne",
    "Chenonceaux",
    "Chenou",
    "Chenove",
    "Chens-sur-Leman",
    "Cheny",
    "Chepoix",
    "Cheptainville",
    "Chepy",
    "Cherac",
    "Cherbourg-Octeville",
    "Chereng",
    "Cheret",
    "Cherier",
    "Cherisey",
    "Cherisy",
    "Cheron",
    "Cheroy",
    "Cherre",
    "Cherreau",
    "Cherves-Chatelars",
    "Cherves-Richemont",
    "Chervettes",
    "Cherveux",
    "Chesley",
    "Chessy",
    "Chessy",
    "Chessy-les-Pres",
    "Cheux",
    "Chevaigne",
    "Cheval-Blanc",
    "Chevalet-le-Haut",
    "Chevanceaux",
    "Chevannes",
    "Chevannes",
    "Cheverny",
    "Chevigny",
    "Chevigny-Saint-Sauveur",
    "Chevillon",
    "Chevillon",
    "Chevillon-sur-Huillard",
    "Chevilly",
    "Chevilly-Larue",
    "Chevincourt",
    "Chevire-le-Rouge",
    "Chevrainvilliers",
    "Chevremont",
    "Chevreuse",
    "Chevrier",
    "Chevrieres",
    "Chevrieres",
    "Chevrotaine",
    "Chevru",
    "Chevry",
    "Chevry-Cossigny",
    "Chevry-en-Sereine",
    "Cheyssieu",
    "Chezery-Forens",
    "Chezy",
    "Chezy-sur-Marne",
    "Chiche",
    "Chichery",
    "Chierry",
    "Chieulles",
    "Chignin",
    "Chigny-les-Roses",
    "Chigy",
    "Chilhac",
    "Chille",
    "Chilly",
    "Chilly-Mazarin",
    "Chimilin",
    "Chinon",
    "Chire-en-Montreuil",
    "Chirens",
    "Chiry-Ourscamp",
    "Chissay-en-Touraine",
    "Chisseaux",
    "Chissey-en-Morvan",
    "Chitenay",
    "Chivres-en-Laonnois",
    "Chize",
    "Chocques",
    "Choisel",
    "Choisey",
    "Choisy",
    "Choisy-au-Bac",
    "Choisy-en-Brie",
    "Choisy-le-Roi",
    "Cholet",
    "Chomerac",
    "Chorges",
    "Chourgnac",
    "Chouy",
    "Choye",
    "Chozeau",
    "Chuelles",
    "Chuzelles",
    "Ciboure",
    "Ciel",
    "Cierrey",
    "Cieurac",
    "Cieux",
    "Cinq-Mars-la-Pile",
    "Cinqueux",
    "Cintegabelle",
    "Cintheaux",
    "Cintre",
    "Cires-les-Mello",
    "Cirey",
    "Cirey-sur-Vezouze",
    "Ciron",
    "Ciry-le-Noble",
    "Cissac-Medoc",
    "Cisse",
    "Cistrieres",
    "Citerne",
    "Citry",
    "Civaux",
    "Civens",
    "Civrac-de-Blaye",
    "Civrac-en-Medoc",
    "Civray",
    "Civray",
    "Civray-de-Touraine",
    "Civrieux",
    "Civry-en-Montagne",
    "Cize",
    "Cizely",
    "Claira",
    "Clairac",
    "Clairfontaine",
    "Clairmarais",
    "Clairoix",
    "Clairvaux-dAveyron",
    "Clairvaux-les-Lacs",
    "Claix",
    "Claix",
    "Clamart",
    "Clamecy",
    "Clamecy",
    "Claouey",
    "Clapiers",
    "Clarac",
    "Claracq",
    "Clarafond",
    "Clarensac",
    "Clarques",
    "Clary",
    "Classun",
    "Clasville",
    "Clavel",
    "Clavette",
    "Claviers",
    "Claville",
    "Claye-Souilly",
    "Cleder",
    "Cleguer",
    "Cleguerec",
    "Clelles",
    "Clement",
    "Clemery",
    "Clemont",
    "Clenay",
    "Cleon",
    "Clerieux",
    "Clermont",
    "Clermont",
    "Clermont",
    "Clermont",
    "Clermont-Ferrand",
    "Clerval",
    "Clery-Saint-Andre",
    "Clesse",
    "Clety",
    "Clevilliers",
    "Clichy",
    "Clichy-sous-Bois",
    "Clinchamps-sur-Orne",
    "Cliron",
    "Clisson",
    "Clohars-Carnoet",
    "Clohars-Fouesnant",
    "Clouange",
    "Clouzeau",
    "Cloyes-sur-le-Loir",
    "Cluny",
    "Cluses",
    "Coaraze",
    "Coarraze",
    "Cobrieux",
    "Cocheren",
    "Cocumont",
    "Codognan",
    "Codolet",
    "Coesmes",
    "Coeuvres-et-Valsery",
    "Coex",
    "Cogles",
    "Cogna",
    "Cognac",
    "Cognin",
    "Cogolin",
    "Coignet",
    "Coignieres",
    "Coimeres",
    "Coincy",
    "Coirac",
    "Coise-Saint-Jean-Pied-Gauthier",
    "Coivrel",
    "Colayrac-Saint-Cirq",
    "Colembert",
    "Coligny",
    "Collandres-Quincarnon",
    "Collegien",
    "Colleret",
    "Colleville",
    "Colleville-Montgomery",
    "Collias",
    "Collioure",
    "Collobrieres",
    "Collonges-les-Premieres",
    "Collonges-sous-Saleve",
    "Collorgues",
    "Colmar",
    "Colmars",
    "Cologne",
    "Colomars",
    "Colombe",
    "Colombelles",
    "Colombes",
    "Colombier",
    "Colombier",
    "Colombier-Fontaine",
    "Colombier-le-Cardinal",
    "Colombier-le-Vieux",
    "Colombier-Saugnieu",
    "Colombieres-sur-Orb",
    "Colombiers",
    "Colomby-sur-Thaon",
    "Colomiers",
    "Colomieu",
    "Colondannes",
    "Colonzelle",
    "Colpo",
    "Coltainville",
    "Combas",
    "Comblanchien",
    "Combloux",
    "Combon",
    "Combourg",
    "Combrand",
    "Combree",
    "Combrit",
    "Combronde",
    "Combs-la-Ville",
    "Comines",
    "Commelle-Vernay",
    "Commenchon",
    "Commensacq",
    "Commentry",
    "Commeny",
    "Commequiers",
    "Commer",
    "Commercy",
    "Commes",
    "Commissey",
    "Communay",
    "Compans",
    "Compertrix",
    "Compolibat",
    "Compreignac",
    "Comps",
    "Concarneau",
    "Conceze",
    "Conches-en-Ouche",
    "Conches-sur-Gondoire",
    "Conchy-les-Pots",
    "Condac",
    "Condat",
    "Condat-sur-Vienne",
    "Conde-en-Brie",
    "Conde-Northen",
    "Conde-Sainte-Libiaire",
    "Conde-sur-Iton",
    "Conde-sur-Marne",
    "Conde-sur-Noireau",
    "Conde-sur-Sarthe",
    "Conde-sur-Suippe",
    "Conde-sur-Vesgre",
    "Conde-sur-Vire",
    "Condecourt",
    "Condeon",
    "Condette",
    "Condezaygues",
    "Condillac",
    "Condom",
    "Condrieu",
    "Conflans-en-Jarnisy",
    "Conflans-Sainte-Honorine",
    "Conflans-sur-Loing",
    "Conflans-sur-Seine",
    "Confolens",
    "Congenies",
    "Conliege",
    "Connantray-Vaurefroy",
    "Conne-de-Labarde",
    "Connerre",
    "Conquereuil",
    "Conques-sur-Orbiel",
    "Cons-Sainte-Colombe",
    "Contamine-sur-Arve",
    "Contes",
    "Contigne",
    "Contoire",
    "Contre",
    "Contres",
    "Contrexeville",
    "Contrisson",
    "Contz-les-Bains",
    "Copponex",
    "Coquelles",
    "Corancez",
    "Coray",
    "Corbara",
    "Corbarieu",
    "Corbas",
    "Corbehem",
    "Corbeil-Cerf",
    "Corbeil-Essonnes",
    "Corbeilles",
    "Corbelin",
    "Corbeny",
    "Corbere-Aberes",
    "Corbere-les-Cabanes",
    "Corbie",
    "Corbieres",
    "Corbieres",
    "Corbigny",
    "Corbonod",
    "Corbreuse",
    "Corcelles-les-Citeaux",
    "Corcelles-les-Monts",
    "Corcieux",
    "Corconne",
    "Corcy",
    "Cordelle",
    "Cordemais",
    "Cordes-sur-Ciel",
    "Cordes-Tolosannes",
    "Cordon",
    "Corenc",
    "Corgnac-sur-lIsle",
    "Corgoloin",
    "Corignac",
    "Corlay",
    "Cormainville",
    "Corme-Ecluse",
    "Corme-Royal",
    "Cormeilles",
    "Cormeilles-en-Parisis",
    "Cormeilles-en-Vexin",
    "Cormelles-le-Royal",
    "Cormeray",
    "Cormery",
    "Cormes",
    "Cormicy",
    "Cormolain",
    "Cormontreuil",
    "Cormoranche-sur-Saone",
    "Cornac",
    "Cornas",
    "Corne",
    "Cornebarrieu",
    "Corneilla-de-Conflent",
    "Corneilla-del-Vercol",
    "Corneilla-la-Riviere",
    "Cornier",
    "Cornille",
    "Cornillon-Confoux",
    "Cornillon-en-Trieves",
    "Cornimont",
    "Cornus",
    "Corny-sur-Moselle",
    "Coron",
    "Corpe",
    "Corps-Nuds",
    "Correze",
    "Corroy",
    "Cors",
    "Corsept",
    "Corseul",
    "Corte",
    "Corveissiat",
    "Corze",
    "Cosges",
    "Cosmes",
    "Cosne-Cours-sur-Loire",
    "Cosqueville",
    "Cosse-le-Vivien",
    "Cossesseville",
    "Costaros",
    "Cotdoussan",
    "Cotignac",
    "Cottenchy",
    "Cottevrard",
    "Cottun",
    "Couargues",
    "Coublevie",
    "Coubon",
    "Coubron",
    "Couches",
    "Couchey",
    "Coucy-le-Chateau-Auffrique",
    "Couddes",
    "Coudehard",
    "Coudekerque-Branche",
    "Coudes",
    "Coudoux",
    "Coudray",
    "Coudray-au-Perche",
    "Coudray-Rabut",
    "Coudroy",
    "Coudun",
    "Coueron",
    "Couesmes-Vauce",
    "Coufouleux",
    "Couhe",
    "Couilly-Pont-aux-Dames",
    "Couiza",
    "Coulaines",
    "Coulandon",
    "Coulans-sur-Gee",
    "Coulaures",
    "Coullons",
    "Coulogne",
    "Couloisy",
    "Coulombiers",
    "Coulombiers",
    "Coulomby",
    "Coulommes",
    "Coulommiers",
    "Coulommiers-la-Tour",
    "Coulon",
    "Coulonces",
    "Coulonges",
    "Coulonges-Thouarsais",
    "Coulounieix-Chamiers",
    "Coulouvray-Boisbenatre",
    "Coulvain",
    "Coulx",
    "Coume",
    "Coupelle-Vieille",
    "Coupesarte",
    "Coupeville",
    "Coupigny",
    "Coupru",
    "Coupvray",
    "Cour-Cheverny",
    "Cour-et-Buis",
    "Cour-sur-Loire",
    "Courances",
    "Courbeveille",
    "Courbevoie",
    "Courcais",
    "Courcay",
    "Courcelles",
    "Courcelles",
    "Courcelles-Chaussy",
    "Courcelles-en-Bassee",
    "Courcelles-Epayelles",
    "Courcelles-les-Gisors",
    "Courcelles-les-Lens",
    "Courcelles-sur-Nied",
    "Courcelles-sur-Seine",
    "Courcelles-sur-Viosne",
    "Courcemont",
    "Courcerac",
    "Courchaton",
    "Courchelettes",
    "Courchevel",
    "Courcon",
    "Courcouronnes",
    "Courcy",
    "Courcy",
    "Courcy-aux-Loges",
    "Courdemanche",
    "Courdemanche",
    "Courdimanche-sur-Essonne",
    "Courgis",
    "Courlans",
    "Courlaoux",
    "Courlay",
    "Courmelles",
    "Courmemin",
    "Cournon",
    "Cournon-dAuvergne",
    "Cournonsec",
    "Cournonterral",
    "Courpalay",
    "Courpiere",
    "Courquetaine",
    "Cours",
    "Cours-de-Monsegur",
    "Cours-la-Ville",
    "Cours-les-Bains",
    "Cours-les-Barres",
    "Coursac",
    "Coursan",
    "Courseulles-sur-Mer",
    "Courson",
    "Courson-les-Carrieres",
    "Courson-Monteloup",
    "Courtelevant",
    "Courtenay",
    "Courtenay",
    "Courtenot",
    "Courteranges",
    "Courthezon",
    "Courtisols",
    "Courtomer",
    "Courtonne-la-Meurdrac",
    "Courtry",
    "Courville-sur-Eure",
    "Courzieu",
    "Cousance",
    "Cousances-les-Forges",
    "Cousolre",
    "Coussay-les-Bois",
    "Coutances",
    "Coutarnoux",
    "Couterne",
    "Couternon",
    "Couteuges",
    "Coutevroult",
    "Coutiches",
    "Coutras",
    "Couvains",
    "Couville",
    "Couvron-et-Aumencourt",
    "Coux",
    "Couy",
    "Couze-et-Saint-Front",
    "Couzeix",
    "Couzon",
    "Coye-la-Foret",
    "Coyriere",
    "Cozes",
    "Crach",
    "Craincourt",
    "Cramant",
    "Cran-Gevrier",
    "Crancey",
    "Crancot",
    "Cransac",
    "Cranves-Sales",
    "Craon",
    "Craonne",
    "Craponne",
    "Cras-sur-Reyssouze",
    "Cravanche",
    "Cravans",
    "Cravant",
    "Cravant-les-Coteaux",
    "Cravent",
    "Crayssac",
    "Crazannes",
    "Creances",
    "Creches-sur-Saone",
    "Crecy-sur-Serre",
    "Credin",
    "Cregy-les-Meaux",
    "Crehange",
    "Crehen",
    "Creil",
    "Creissan",
    "Creissels",
    "Cremeaux",
    "Cremieu",
    "Crepey",
    "Cresancey",
    "Crespieres",
    "Crespin",
    "Crespin",
    "Cressat",
    "Cresse",
    "Cresserons",
    "Crest",
    "Creully",
    "Creusot",
    "Creutzwald",
    "Creuzier-le-Neuf",
    "Creuzier-le-Vieux",
    "Crevant-Laveine",
    "Crevechamps",
    "Crevecoeur-le-Grand",
    "Crevin",
    "Crezancy",
    "Criel-sur-Mer",
    "Crillon-le-Brave",
    "Crimolois",
    "Crisenoy",
    "Crisolles",
    "Crissey",
    "Crissey",
    "Crochte",
    "Crocq",
    "Croisette",
    "Croisilles",
    "Croisilles",
    "Croisilles",
    "Croissanville",
    "Croissy-Beaubourg",
    "Croissy-sur-Celle",
    "Croissy-sur-Seine",
    "Croix",
    "Croix",
    "Croix-Chapeau",
    "Croix-de-Vie",
    "Croix-Mare",
    "Croixanvec",
    "Crolles",
    "Cros-de-Ronesque",
    "Crosne",
    "Crottet",
    "Crouay",
    "Croutelle",
    "Crouttes",
    "Crouy-sur-Cosson",
    "Crouy-sur-Ourcq",
    "Croze",
    "Crozes-Hermitage",
    "Crozon",
    "Cruas",
    "Cruet",
    "Crugny",
    "Cruscades",
    "Cruseilles",
    "Crusnes",
    "Cruzilles-les-Mepillat",
    "Cruzy",
    "Cublac",
    "Cubry-les-Soing",
    "Cubzac-les-Ponts",
    "Cucq",
    "Cucuron",
    "Cuers",
    "Cuffies",
    "Cuffy",
    "Cugand",
    "Cuges-les-Pins",
    "Cugnaux",
    "Cuigy-en-Bray",
    "Cuinchy",
    "Cuincy",
    "Cuinzier",
    "Cuise-la-Motte",
    "Cuiseaux",
    "Cuisery",
    "Culhat",
    "Culmont",
    "Culoz",
    "Cumieres",
    "Cunfin",
    "Cunlhat",
    "Cuon",
    "Cuperly",
    "Cuqueron",
    "Curchy",
    "Curdin",
    "Curel",
    "Curgies",
    "Curtafond",
    "Curzay-sur-Vonne",
    "Cussay",
    "Cusset",
    "Cussey-les-Forges",
    "Cussy-les-Forges",
    "Custines",
    "Cusy",
    "Cuttoli-Corticchiato",
    "Cuvergnon",
    "Cuverville",
    "Cuvilly",
    "Cuvry",
    "Cuxac-Cabardes",
    "Cuy",
    "Cuy-Saint-Fiacre",
    "Cuzieu",
    "Cysoing",
    "Dabo",
    "Dachstein",
    "Dadonville",
    "Dagneux",
    "Dainville",
    "Dalhunden",
    "Dallet",
    "Dalmas",
    "Damazan",
    "Dambach-la-Ville",
    "Dambenois",
    "Dame-Marie",
    "Damelevieres",
    "Damerey",
    "Damery",
    "Damgan",
    "Damiatte",
    "Damigny",
    "Dammarie-les-Lys",
    "Dammarie-sur-Saulx",
    "Dammartin-en-Goele",
    "Dammartin-en-Serve",
    "Damparis",
    "Dampierre",
    "Dampierre-au-Temple",
    "Dampierre-en-Burly",
    "Dampierre-en-Crot",
    "Dampierre-les-Bois",
    "Dampierre-les-Conflans",
    "Dampierre-sur-Blevy",
    "Dampierre-sur-Boutonne",
    "Dampmart",
    "Damprichard",
    "Dampsmesnil",
    "Dampvalley-les-Colombe",
    "Damville",
    "Damvix",
    "Dancevoir",
    "Dangeau",
    "Dangeul",
    "Danjoutin",
    "Danne-et-Quatre-Vents",
    "Dannemoine",
    "Daoulas",
    "Dardilly",
    "Dareize",
    "Dargnies",
    "Dargoire",
    "Darmont",
    "Darnetal",
    "Darney",
    "Darnieulles",
    "Darvoy",
    "Dasle",
    "Daubensand",
    "Daubeuf-la-Campagne",
    "Dauendorf",
    "Daumeray",
    "Daux",
    "Dauzat-sur-Vodable",
    "Davenescourt",
    "Davezieux",
    "Dax",
    "Deauville",
    "Decazeville",
    "Dechy",
    "Decines-Charpieu",
    "Decize",
    "Delettes",
    "Delle",
    "Delme",
    "Demouville",
    "Demuin",
    "Denain",
    "Denat",
    "Denee",
    "Denevre",
    "Denice",
    "Denier",
    "Dennebroeucq",
    "Denneville",
    "Denney",
    "Denting",
    "Dercy",
    "Dernancourt",
    "Derval",
    "Desaignes",
    "Desertines",
    "Desvres",
    "Detrier",
    "Dettwiller",
    "Deuil-la-Barre",
    "Deulemont",
    "Devecey",
    "Devillac",
    "Deville-les-Rouen",
    "Devrouze",
    "Deyme",
    "Deyvillers",
    "Dezize-les-Maranges",
    "Dhuisy",
    "Diarville",
    "Didenheim",
    "Die",
    "Diebling",
    "Dieffenbach-au-Val",
    "Diemeringen",
    "Diemoz",
    "Dienville",
    "Dieppe",
    "Dieppe-sous-Douaumont",
    "Dierre",
    "Dietwiller",
    "Dieue-sur-Meuse",
    "Dieulefit",
    "Dieulouard",
    "Dieupentale",
    "Dieuze",
    "Dieval",
    "Diffembach-les-Hellimer",
    "Diges",
    "Digne-les-Bains",
    "Digoin",
    "Dijon",
    "Dimbsthal",
    "Dimechaux",
    "Dinan",
    "Dinard",
    "Dinge",
    "Dingsheim",
    "Dingy-en-Vuache",
    "Dingy-Saint-Clair",
    "Dinoze",
    "Dionay",
    "Dissay",
    "Disse-sous-Ballon",
    "Distre",
    "Distroff",
    "Dive",
    "Dives-sur-Mer",
    "Divion",
    "Divonne-les-Bains",
    "Dixmont",
    "Dizy",
    "Doazit",
    "Docelles",
    "Doeuil-sur-le-Mignon",
    "Dogneville",
    "Doingt",
    "Doissin",
    "Doix",
    "Dol-de-Bretagne",
    "Dole",
    "Dollon",
    "Dolo",
    "Dolomieu",
    "Domagne",
    "Domaize",
    "Domalain",
    "Domancy",
    "Domart-sur-la-Luce",
    "Domats",
    "Dombasle-sur-Meurthe",
    "Domene",
    "Domerat",
    "Domessargues",
    "Domessin",
    "Domevre-sur-Durbion",
    "Domezain-Berraute",
    "Domfront",
    "Domgermain",
    "Domjevin",
    "Dommartin",
    "Dommartin",
    "Dommartin-les-Remiremont",
    "Dommartin-les-Toul",
    "Domont",
    "Dompierre-les-Ormes",
    "Dompierre-sur-Besbre",
    "Dompierre-sur-Mer",
    "Dompierre-sur-Veyle",
    "Dompierre-sur-Yon",
    "Donchery",
    "Doncourt-les-Conflans",
    "Donges",
    "Donjeux",
    "Donnemarie-Dontilly",
    "Donnery",
    "Donneville",
    "Donnezac",
    "Dontrien",
    "Donville-les-Bains",
    "Donzacq",
    "Donzere",
    "Donzy",
    "Donzy-le-Pertuis",
    "Dordives",
    "Dorlisheim",
    "Dormans",
    "Dormelles",
    "Dornes",
    "Dornot",
    "Dortan",
    "Dorval",
    "Douai",
    "Douarnenez",
    "Doubs",
    "Douchy",
    "Douchy-les-Mines",
    "Doudeville",
    "Doue-la-Fontaine",
    "Douilly",
    "Doulezon",
    "Doullens",
    "Doumy",
    "Dounoux",
    "Dourdain",
    "Dourdan",
    "Dourges",
    "Dourgne",
    "Doussard",
    "Douvaine",
    "Douville",
    "Douvres",
    "Douvres-la-Delivrande",
    "Douvrin",
    "Doux",
    "Douy-la-Ramee",
    "Douzy",
    "Doville",
    "Doyet",
    "Dozule",
    "Drace",
    "Drachenbronn-Birlenbach",
    "Dracy-le-Fort",
    "Draguignan",
    "Draillant",
    "Drain",
    "Drancy",
    "Drap",
    "Draveil",
    "Dreffeac",
    "Dremil-Lafage",
    "Dreslincourt",
    "Dreuil-les-Amiens",
    "Dreux",
    "Dreville",
    "Drocourt",
    "Drom",
    "Droupt-Saint-Basle",
    "Drouville",
    "Druillat",
    "Drulingen",
    "Drusenheim",
    "Druye",
    "Druyes-les-Belles-Fontaines",
    "Dry",
    "Ducey",
    "Duclair",
    "Duerne",
    "Dugny",
    "Dugny-sur-Meuse",
    "Duingt",
    "Duisans",
    "Dullin",
    "Dun-le-Poelier",
    "Dun-sur-Auron",
    "Duneau",
    "Dunes",
    "Duniere",
    "Dunkirk",
    "Duppigheim",
    "Durance",
    "Duranus",
    "Durban-Corbieres",
    "Durcet",
    "Durette",
    "Durfort",
    "Durlinsdorf",
    "Durmenach",
    "Durrenbach",
    "Durrenentzen",
    "Durstel",
    "Durtal",
    "Durtol",
    "Dury",
    "Duttlenheim",
    "Eance",
    "Eaubonne",
    "Eaucourt-sur-Somme",
    "Eaunes",
    "Eaux-Bonnes",
    "Eaux-Puiseaux",
    "Eauze",
    "Ebersviller",
    "Eblange",
    "Ecaillon",
    "Ecalles-Alix",
    "Ecaquelon",
    "Ecardenville-la-Campagne",
    "Ecardenville-sur-Eure",
    "Ecault",
    "Eccles",
    "Echalas",
    "Echarcon",
    "Echauffour",
    "Echavanne",
    "Echemire",
    "Echenans",
    "Echenevex",
    "Echenon",
    "Echenoz-la-Meline",
    "Echevannes",
    "Echevis",
    "Echillais",
    "Echinghen",
    "Echire",
    "Eckbolsheim",
    "Eckwersheim",
    "Eclaron-Braucourt-Sainte-Liviere",
    "Ecleux",
    "Ecluzelles",
    "Ecoche",
    "Ecole",
    "Ecole-Valentin",
    "Ecommoy",
    "Ecorcei",
    "Ecos",
    "Ecouen",
    "Ecouflant",
    "Ecourt-Saint-Quentin",
    "Ecouviez",
    "Ecoyeux",
    "Ecques",
    "Ecquevilly",
    "Ecretteville-les-Baons",
    "Ecrosnes",
    "Ecrouves",
    "Ectot-les-Baons",
    "Ecueil",
    "Ecueille",
    "Ecuelles",
    "Ecuille",
    "Ecuires",
    "Ecuisses",
    "Ecully",
    "Ecury-sur-Coole",
    "Edern",
    "Effiat",
    "Effry",
    "Egletons",
    "Egligny",
    "Egly",
    "Egreville",
    "Eguilles",
    "Eichhoffen",
    "Einville-au-Jard",
    "el Volo",
    "Elbeuf",
    "Eletot",
    "Eleu-dit-Leauwette",
    "Elincourt",
    "Elincourt-Sainte-Marguerite",
    "Elliant",
    "Ellon",
    "Elna",
    "Elnes",
    "Eloise",
    "Eloyes",
    "els Banys de la Presta",
    "Elven",
    "Emance",
    "Embrun",
    "Embry",
    "Emerainville",
    "Emieville",
    "Emmerin",
    "Enchenberg",
    "Enencourt-le-Sec",
    "Enghien-les-Bains",
    "Engins",
    "Englefontaine",
    "Englos",
    "Ennery",
    "Ennery",
    "Ennevelin",
    "Ennezat",
    "Ennordres",
    "Enquin-les-Mines",
    "Ensisheim",
    "Ensues-la-Redonne",
    "Entraigues-sur-la-Sorgue",
    "Entrains-sur-Nohain",
    "Entrammes",
    "Entrange",
    "Entraygues-sur-Truyere",
    "Entre-deux-Guiers",
    "Entrecasteaux",
    "Entremont-le-Vieux",
    "Entressen",
    "Entzheim",
    "Enveig",
    "Envermeu",
    "Epagne-Epagnette",
    "Epagny",
    "Epaignes",
    "Epegard",
    "Epenancourt",
    "Epenede",
    "Epercieux-Saint-Paul",
    "Eperlecques",
    "Epernon",
    "Epertully",
    "Epervans",
    "Epfig",
    "Epiais-Rhus",
    "Epieds-en-Beauce",
    "Epierre",
    "Epinac",
    "Epinay",
    "Epinay-sous-Senart",
    "Epineuil-le-Fleuriel",
    "Epinouze",
    "Epinoy",
    "Episy",
    "Epoisses",
    "Epone",
    "Epouville",
    "Eppe-Sauvage",
    "Eppeville",
    "Epping",
    "Epuisay",
    "Equeurdreville-Hainneville",
    "Equihen-Plage",
    "Er",
    "Eragny",
    "Erbray",
    "Erbree",
    "Erce-en-Lamee",
    "Erce-pres-Liffre",
    "Erceville",
    "Ercheu",
    "Erdeven",
    "Ergersheim",
    "Ergny",
    "Ergue-Gaberic",
    "Erize-Saint-Dizier",
    "Ermenonville",
    "Ermont",
    "Ernee",
    "Ernemont-sur-Buchy",
    "Ernestviller",
    "Ernolsheim-Bruche",
    "Erome",
    "Eroudeville",
    "Erquinghem-Lys",
    "Erquinvillers",
    "Erquy",
    "Erre",
    "Errouville",
    "Erstein",
    "Ervauville",
    "Esbarres",
    "Esbly",
    "Escalquens",
    "Escames",
    "Escassefort",
    "Escaudain",
    "Escaudoeuvres",
    "Escautpont",
    "Escazeaux",
    "Eschau",
    "Eschbach-au-Val",
    "Eschentzwiller",
    "Esches",
    "Esclainvillers",
    "Escolives-Sainte-Camille",
    "Escombres-et-le-Chesnois",
    "Escondeaux",
    "Escorneboeuf",
    "Escou",
    "Escout",
    "Escoutoux",
    "Escurolles",
    "Esery",
    "Eslettes",
    "Esmery-Hallon",
    "Esnandes",
    "Esnouveaux",
    "Espagnac",
    "Espalais",
    "Espalion",
    "Espaly-Saint-Marcel",
    "Esparron-de-Verdon",
    "Espedaillac",
    "Espelette",
    "Espeluche",
    "Espezel",
    "Espiet",
    "Espinasses",
    "Espira-de-Conflent",
    "Espirat",
    "Espondeilhan",
    "Esquay-Notre-Dame",
    "Esquay-sur-Seulles",
    "Esquelbecq",
    "Esquerchin",
    "Esquerdes",
    "Esquibien",
    "Esquieze-Sere",
    "Essegney",
    "Essert",
    "Essertaux",
    "Esserts-Saleve",
    "Essey",
    "Essey-les-Nancy",
    "Essia",
    "Essigny-le-Grand",
    "Esson",
    "Essoyes",
    "Estadens",
    "Estagel",
    "Estaing",
    "Estaires",
    "Esternay",
    "Estevelles",
    "Estillac",
    "Estivareilles",
    "Estouches",
    "Estouteville-Ecalles",
    "Estouy",
    "Estrablin",
    "Estree-Blanche",
    "Estrees",
    "Estrees-les-Crecy",
    "Estrees-Saint-Denis",
    "Estrees-sur-Noye",
    "Esvres",
    "Etable",
    "Etables",
    "Etables-sur-Mer",
    "Etaimpuis",
    "Etaing",
    "Etainhus",
    "Etalondes",
    "Etampes-sur-Marne",
    "Etang-sur-Arroux",
    "Etaules",
    "Etauliers",
    "Etaux",
    "Etaves-et-Bocquiaux",
    "Eteimbes",
    "Etel",
    "Etelfay",
    "Etercy",
    "Eternoz",
    "Eterville",
    "Etevaux",
    "Eth",
    "Etigny",
    "Etiolles",
    "Etival",
    "Etival-Clairefontaine",
    "Etival-les-le-Mans",
    "Etoges",
    "Etoile-sur-Rhone",
    "Etourvy",
    "Etouvans",
    "Etouvy",
    "Etrechy",
    "Etrechy",
    "Etreillers",
    "Etrelles",
    "Etrembieres",
    "Etrepagny",
    "Etrepilly",
    "Etreux",
    "Etrez",
    "Etriac",
    "Etriche",
    "Etricourt-Manancourt",
    "Etroeungt",
    "Etroussat",
    "Ettendorf",
    "Etting",
    "Etupes",
    "Etuz",
    "Etzling",
    "Eu",
    "Eulmont",
    "Euville",
    "Evans",
    "Evenos",
    "Everly",
    "Eveux",
    "Evin-Malmaison",
    "Evran",
    "Evrecy",
    "Evron",
    "Excenevex",
    "Excideuil",
    "Exideuil",
    "Exincourt",
    "Exireuil",
    "Exoudun",
    "Eybens",
    "Eybouleuf",
    "Eygalieres",
    "Eyguieres",
    "Eygurande-et-Gardedeuil",
    "Eyjeaux",
    "Eyliac",
    "Eymet",
    "Eymoutiers",
    "Eyragues",
    "Eyrans",
    "Eysines",
    "Eyzin-Pinet",
    "Ezanville",
    "Eze",
    "Ezy-sur-Eure",
    "Fabregues",
    "Fabrezan",
    "Faches",
    "Faches-Thumesnil",
    "Fagnieres",
    "Faimbe",
    "Fains-Veel",
    "Falaise",
    "Falaise",
    "Falck",
    "Falicon",
    "Fallerans",
    "Falleron",
    "Falletans",
    "Famars",
    "Fameck",
    "Fampoux",
    "Fanjeaux",
    "Faramans",
    "Farbus",
    "Farebersviller",
    "Fareins",
    "Faremoutiers",
    "Farges-les-Chalon",
    "Fargues",
    "Fargues",
    "Fargues-Saint-Hilaire",
    "Farincourt",
    "Fatouville-Grestain",
    "Fauch",
    "Faucigny",
    "Faucogney-et-la-Mer",
    "Faucompierre",
    "Faulquemont",
    "Faulx",
    "Faumont",
    "Fauquembergues",
    "Fauville-en-Caux",
    "Favars",
    "Faverges",
    "Faverges-de-la-Tour",
    "Faverolles-la-Campagne",
    "Faverolles-sur-Cher",
    "Favieres",
    "Favieres",
    "Fay-aux-Loges",
    "Fay-de-Bretagne",
    "Fay-les-Nemours",
    "Fayence",
    "Fayet-le-Chateau",
    "Fayl-Billot",
    "Fays-la-Chapelle",
    "Fayssac",
    "Feas",
    "Fechain",
    "Fegersheim",
    "Fegreac",
    "Feigeres",
    "Feignies",
    "Feillens",
    "Fein",
    "Feissons-sur-Isere",
    "Feldkirch",
    "Felines",
    "Felines",
    "Felix",
    "Fellering",
    "Felletin",
    "Fenain",
    "Fenay",
    "Fendeille",
    "Fenetrange",
    "Feneu",
    "Feneyrols",
    "Fenioux",
    "Fenouillet",
    "Fenouillet",
    "Fere-Champenoise",
    "Fere-en-Tardenois",
    "Ferel",
    "Ferfay",
    "Fericy",
    "Ferin",
    "Fermanville",
    "Ferney-Voltaire",
    "Ferolles-Attilly",
    "Ferques",
    "Ferrals-les-Corbieres",
    "Ferreol",
    "Ferrette",
    "Ferreux-Quincey",
    "Ferriere-la-Grande",
    "Ferriere-la-Petite",
    "Ferriere-Larcon",
    "Ferrieres",
    "Ferrieres",
    "Ferrieres-en-Brie",
    "Ferrieres-en-Gatinais",
    "Ferrieres-Haut-Clocher",
    "Ferrieres-les-Scey",
    "Ferrieres-sur-Ariege",
    "Fesches-le-Chatel",
    "Fesques",
    "Fessenheim",
    "Festieux",
    "Festubert",
    "Feternes",
    "Feucherolles",
    "Feuges",
    "Feuguerolles-sur-Seulles",
    "Feuilla",
    "Feuquieres",
    "Feuquieres-en-Vimeu",
    "Feurs",
    "Feves",
    "Fey",
    "Feytiat",
    "Feyzin",
    "Fiefs",
    "Fiennes",
    "Fierville-Bray",
    "Fierville-les-Mines",
    "Figanieres",
    "Figeac",
    "Fignieres",
    "Filain",
    "Fille",
    "Fillievres",
    "Fillinges",
    "Filstroff",
    "Finhan",
    "Firmi",
    "Firminy",
    "Fislis",
    "Fismes",
    "Fitilieu",
    "Fitz-James",
    "Flagnac",
    "Flamanville",
    "Flamarens",
    "Flancourt-Catelon",
    "Flangebouche",
    "Flaucourt",
    "Flaux",
    "Flavacourt",
    "Flavigny",
    "Flavigny-sur-Moselle",
    "Flavin",
    "Flavy-le-Martel",
    "Flaxlanden",
    "Flayosc",
    "Fleac",
    "Flechin",
    "Flee",
    "Flers",
    "Flers-en-Escrebieux",
    "Flers-sur-Noye",
    "Fletrange",
    "Fletre",
    "Fleurance",
    "Fleurbaix",
    "Fleure",
    "Fleurey-sur-Ouche",
    "Fleurie",
    "Fleuriel",
    "Fleury",
    "Fleury-en-Biere",
    "Fleury-les-Aubrais",
    "Fleury-Merogis",
    "Fleury-sur-Andelle",
    "Fleury-sur-Orne",
    "Fleville-devant-Nancy",
    "Flevy",
    "Flines-les-Mortagne",
    "Flines-lez-Raches",
    "Flins-sur-Seine",
    "Flixecourt",
    "Floing",
    "Floirac",
    "Florac",
    "Florange",
    "Florensac",
    "Florent-en-Argonne",
    "Florentin",
    "Florentin",
    "Floyon",
    "Flumet",
    "Foameix-Ornel",
    "Foecy",
    "Foissiat",
    "Foix",
    "Folelli",
    "Folembray",
    "Folgensbourg",
    "Follainville-Dennemont",
    "Folles",
    "Folleville",
    "Folschviller",
    "Fonbeauzard",
    "Foncine-le-Haut",
    "Fondettes",
    "Fons",
    "Fons",
    "Fonsorbes",
    "Font-Romeu-Odeillo-Via",
    "Fontain",
    "Fontaine",
    "Fontaine",
    "Fontaine-au-Bois",
    "Fontaine-Bonneleau",
    "Fontaine-en-Dormois",
    "Fontaine-Etoupefour",
    "Fontaine-Guerin",
    "Fontaine-Heudebourg",
    "Fontaine-la-Mallet",
    "Fontaine-le-Comte",
    "Fontaine-le-Dun",
    "Fontaine-le-Pin",
    "Fontaine-les-Bassets",
    "Fontaine-les-Dijon",
    "Fontaine-les-Gres",
    "Fontaine-les-Luxeuil",
    "Fontaine-Milon",
    "Fontaine-Notre-Dame",
    "Fontaine-Notre-Dame",
    "Fontaine-Simon",
    "Fontaine-sous-Jouy",
    "Fontaine-sous-Preaux",
    "Fontaine-sur-Somme",
    "Fontaine-Uterte",
    "Fontainebleau",
    "Fontaines",
    "Fontaines",
    "Fontaines-Saint-Martin",
    "Fontaines-sur-Saone",
    "Fontanes",
    "Fontanil-Cornillon",
    "Fontannes",
    "Fontcouverte",
    "Fontenai-les-Louvets",
    "Fontenay",
    "Fontenay-aux-Roses",
    "Fontenay-en-Parisis",
    "Fontenay-le-Comte",
    "Fontenay-le-Fleury",
    "Fontenay-le-Vicomte",
    "Fontenay-sous-Bois",
    "Fontenay-sur-Loing",
    "Fontenay-sur-Vegre",
    "Fontenay-Tresigny",
    "Fontenelle-en-Brie",
    "Fontenilles",
    "Fontenoy",
    "Fontenoy-la-Joute",
    "Fontenoy-le-Chateau",
    "Fontes",
    "Fontjoncouse",
    "Fontpedrosa",
    "Fontrabiouse",
    "Fontvannes",
    "Fontvieille",
    "Forbach",
    "Forcalqueiret",
    "Forcalquier",
    "Force",
    "Forest-sur-Marque",
    "Foret-la-Folie",
    "Forfry",
    "Forges",
    "Forges-les-Bains",
    "Forges-les-Eaux",
    "Forleans",
    "Formerie",
    "Fors",
    "Fort-Mahon-Plage",
    "Fort-Mardyck",
    "Fortschwihr",
    "Fos-sur-Mer",
    "Fosse",
    "Fossemanant",
    "Fosses",
    "Fosses-et-Baleyssac",
    "Fouchy",
    "Fouday",
    "Fouencamps",
    "Fouesnant",
    "Foug",
    "Fougax-et-Barrineuf",
    "Fougere",
    "Fougeres",
    "Fougeres",
    "Fougeres-sur-Bievre",
    "Fougerolles",
    "Fouillard",
    "Fouilleuse",
    "Foulangues",
    "Foulayronnes",
    "Foulcrey",
    "Fouquebrune",
    "Fouquieres-les-Lens",
    "Four",
    "Fouras",
    "Fourchambault",
    "Fourches",
    "Fourdrinoy",
    "Fourg",
    "Fourmies",
    "Fournes-en-Weppes",
    "Fournier",
    "Fournival",
    "Fournols",
    "Fouronnes",
    "Fourques",
    "Fourques-sur-Garonne",
    "Fourqueux",
    "Fours-Saint-Laurent",
    "Foussemagne",
    "Fragnes",
    "Fraisans",
    "Fraisses",
    "Fraize",
    "Fralignes",
    "Frambouhans",
    "Framerville-Rainecourt",
    "Francaltroff",
    "Francardo",
    "Francheleins",
    "Francheville",
    "Francieres",
    "Franclens",
    "Francois",
    "Franconville",
    "Francourville",
    "Frangy",
    "Franois",
    "Franqueville",
    "Franvillers",
    "Franxault",
    "Frauenberg",
    "Frebuans",
    "Frechede",
    "Frechencourt",
    "Frechou",
    "Fredille",
    "Fregimont",
    "Fregouville",
    "Freistroff",
    "Frejairolles",
    "Freland",
    "Frelinghien",
    "Fremainville",
    "Fremifontaine",
    "Fremonville",
    "Frencq",
    "Frenes",
    "Freneuse",
    "Frepillon",
    "Fresnay-en-Retz",
    "Fresnay-le-Gilmert",
    "Fresnay-sur-Sarthe",
    "Fresne",
    "Fresne-Cauverville",
    "Fresne-la-Mere",
    "Fresne-Saint-Mames",
    "Fresneaux-Montchevreuil",
    "Fresnes",
    "Fresnes-au-Mont",
    "Fresnes-en-Woevre",
    "Fresnes-les-Montauban",
    "Fresnes-sur-Escaut",
    "Fresnes-sur-Marne",
    "Fresnicourt-le-Dolmen",
    "Fresnoy-la-Riviere",
    "Fresnoy-le-Grand",
    "Frespech",
    "Fressac",
    "Fressain",
    "Fresse",
    "Fresse-sur-Moselle",
    "Fressenneville",
    "Fressies",
    "Fressines",
    "Frethun",
    "Fretigney-et-Velloreille",
    "Fretin",
    "Frevent",
    "Frevillers",
    "Frevin-Capelle",
    "Friauville",
    "Frieres-Faillouel",
    "Friesen",
    "Friville-Escarbotin",
    "Frocourt",
    "Froges",
    "Froidestrees",
    "Froidfond",
    "Froissy",
    "Fromelles",
    "Fromont",
    "Fromonville",
    "Froncles",
    "Fronsac",
    "Frontenac",
    "Frontenas",
    "Frontenay-Rohan-Rohan",
    "Frontenay-sur-Dive",
    "Frontenex",
    "Frontignan",
    "Frontignan-Saves",
    "Fronton",
    "Frontonas",
    "Frossay",
    "Frouard",
    "Frouzins",
    "Frozes",
    "Frucourt",
    "Frugeres-les-Mines",
    "Fruges",
    "Fuisse",
    "Fultot",
    "Fumay",
    "Fumel",
    "Furchhausen",
    "Furiani",
    "Fussy",
    "Fuveau",
    "Fye",
    "Gaas",
    "Gabaston",
    "Gabat",
    "Gace",
    "Gagnac-sur-Garonne",
    "Gagnieres",
    "Gagny",
    "Gahard",
    "Gailhan",
    "Gaillac",
    "Gaillac-Toulza",
    "Gaillan-en-Medoc",
    "Gaillard",
    "Gaillefontaine",
    "Gaillon",
    "Galametz",
    "Galapian",
    "Galargues",
    "Galfingue",
    "Galgon",
    "Galie",
    "Gallardon",
    "Gallician",
    "Galluis",
    "Gamaches",
    "Gamarde-les-Bains",
    "Gambais",
    "Gambsheim",
    "Gan",
    "Ganac",
    "Gandrange",
    "Ganges",
    "Gannat",
    "Gap",
    "Garancieres-en-Drouais",
    "Garanou",
    "Garat",
    "Garche",
    "Garches",
    "Garchizy",
    "Gardanne",
    "Gardonne",
    "Garencieres",
    "Garennes-sur-Eure",
    "Gareoult",
    "Gargenville",
    "Garlan",
    "Garlin",
    "Garons",
    "Garrevaques",
    "Garrigues",
    "Garris",
    "Garrosse",
    "Gas",
    "Gasny",
    "Gassin",
    "Gasville-Oiseme",
    "Gatelles",
    "Gatey",
    "Gattieres",
    "Gauchy",
    "Gauciel",
    "Gauriac",
    "Gauriaguet",
    "Gauville-la-Campagne",
    "Gavarnie",
    "Gavray",
    "Gaye",
    "Geanges",
    "Geaune",
    "Geay",
    "Gee",
    "Geispolsheim",
    "Gelannes",
    "Gelles",
    "Gelos",
    "Geloux",
    "Gemeaux",
    "Gemenos",
    "Gemil",
    "Gemozac",
    "Genac",
    "Genas",
    "Genay",
    "Gencay",
    "Gendrey",
    "Gene",
    "Genebrieres",
    "Genech",
    "Genelard",
    "Generac",
    "Generargues",
    "Genet",
    "Geneuille",
    "Geney",
    "Genille",
    "Genis",
    "Genissac",
    "Genissiat",
    "Genissieux",
    "Genlis",
    "Gennes",
    "Gennes-sur-Seiche",
    "Gennetines",
    "Gennevilliers",
    "Gensac",
    "Gensac-la-Pallue",
    "Gente",
    "Gentilly",
    "Georges",
    "Geovreisset",
    "Ger",
    "Ger",
    "Gerard",
    "Gerbaix",
    "Gerbecourt-et-Haplemont",
    "Gerbeviller",
    "Gergy",
    "Gerland",
    "Germaine",
    "Germainville",
    "Germenay",
    "Germigny-sur-Loire",
    "Germond-Rouvre",
    "Gerstheim",
    "Gervais",
    "Gervans",
    "Gerzat",
    "Gesnes-le-Gandelin",
    "Geste",
    "Gestel",
    "Gesvres",
    "Getigne",
    "Geudertheim",
    "Geveze",
    "Gevrey-Chambertin",
    "Gevrieux",
    "Gex",
    "Geyssans",
    "Gezaincourt",
    "Gezoncourt",
    "Ghisonaccia",
    "Ghyvelde",
    "Giat",
    "Giberville",
    "Gidy",
    "Gien",
    "Gieres",
    "Gievres",
    "Gif-sur-Yvette",
    "Gigean",
    "Gignac",
    "Gignac",
    "Gignac-la-Nerthe",
    "Gigny-sur-Saone",
    "Gilette",
    "Gilles",
    "Gilley",
    "Gilly-les-Citeaux",
    "Gilly-sur-Isere",
    "Gimbrett",
    "Gimeux",
    "Gimont",
    "Ginasservis",
    "Ginestas",
    "Ginestet",
    "Girancourt",
    "Giraumont",
    "Girolles",
    "Giromagny",
    "Giron",
    "Gironcourt-sur-Vraine",
    "Gironde-sur-Dropt",
    "Giroussens",
    "Gisors",
    "Givenchy-en-Gohelle",
    "Givet",
    "Givors",
    "Givraines",
    "Givrand",
    "Givrauval",
    "Givron",
    "Givry",
    "Glanon",
    "Glatigny",
    "Gleize",
    "Glenic",
    "Glomel",
    "Glos-sur-Risle",
    "Goderville",
    "Godewaersvelde",
    "Goersdorf",
    "Goes",
    "Goetzenbruck",
    "Goeulzin",
    "Goin",
    "Goincourt",
    "Golbey",
    "Golfe-Juan",
    "Gombergean",
    "Gomelange",
    "Gomene",
    "Gomer",
    "Gometz-la-Ville",
    "Gometz-le-Chatel",
    "Gommegnies",
    "Gommerville",
    "Gommerville",
    "Goncelin",
    "Gondecourt",
    "Gondeville",
    "Gondrecourt-le-Chateau",
    "Gondreville",
    "Gonesse",
    "Gonfaron",
    "Gonfreville-Caillot",
    "Gonfreville-lOrcher",
    "Gonnehem",
    "Gonneville-la-Mallet",
    "Gonneville-sur-Mer",
    "Gontier",
    "Gorbio",
    "Gorcy",
    "Gordes",
    "Gorges",
    "Gornac",
    "Gorre",
    "Gorron",
    "Gorze",
    "Gosnay",
    "Gosselming",
    "Gottenhouse",
    "Gouaix",
    "Gouaux",
    "Goudelin",
    "Goudourville",
    "Gouesnach",
    "Gouesnou",
    "Gouezec",
    "Gouillons",
    "Gouise",
    "Goulet",
    "Goult",
    "Goupillieres",
    "Goupillieres",
    "Gourdan-Polignan",
    "Gourdon",
    "Gourge",
    "Gourhel",
    "Gourin",
    "Gourlizon",
    "Gournay-en-Bray",
    "Gournay-sur-Marne",
    "Gourvillette",
    "Goury",
    "Goussainville",
    "Goussainville",
    "Gousse",
    "Goutrens",
    "Gouts",
    "Gouttieres",
    "Gouvernes",
    "Gouves",
    "Gouvieux",
    "Gouville-sur-Mer",
    "Goux-les-Dambelin",
    "Goux-les-Usiers",
    "Gouy-en-Artois",
    "Gouy-en-Ternois",
    "Gouy-Saint-Andre",
    "Gouy-sous-Bellonne",
    "Gouze",
    "Gouzeaucourt",
    "Goven",
    "Goxwiller",
    "Goyrans",
    "Grabels",
    "Gracay",
    "Graces",
    "Gradignan",
    "Gragnague",
    "Graincourt-les-Havrincourt",
    "Grainville",
    "Grainville-la-Teinturiere",
    "Grainville-sur-Odon",
    "Graissessac",
    "Gramat",
    "Grammond",
    "Grand",
    "Grand Champ",
    "Grand-Auverne",
    "Grand-Charmont",
    "Grand-Fort-Philippe",
    "Grand-Fougeray",
    "Grand-Laviers",
    "Grand-Rozoy",
    "Grandchamp",
    "Grandchamps-des-Fontaines",
    "Grande-Synthe",
    "Grandfontaine",
    "Grandfontaine-Fournets",
    "Grandfresnoy",
    "Grandpre",
    "Grandris",
    "Grandsaigne",
    "Grandvaux",
    "Grandvillars",
    "Grandville-Gaudreville",
    "Grandvillers",
    "Grandvilliers",
    "Grane",
    "Granges",
    "Granges-le-Bourg",
    "Granges-sur-Vologne",
    "Grangues",
    "Grans",
    "Granville",
    "Grasse",
    "Grassendorf",
    "Gratentour",
    "Gratot",
    "Graulhet",
    "Grauves",
    "Gravelines",
    "Graveron-Semerville",
    "Graveson",
    "Gravigny",
    "Gray",
    "Grazac",
    "Grazay",
    "Greasque",
    "Gremecey",
    "Gremevillers",
    "Gremonville",
    "Grenade",
    "Grenay",
    "Greneville-en-Beauce",
    "Grenoble",
    "Grentheville",
    "Grentzingen",
    "Greolieres",
    "Greoux-les-Bains",
    "Grepiac",
    "Gresse-en-Vercors",
    "Gresswiller",
    "Gressy",
    "Gresy-sur-Aix",
    "Gresy-sur-Isere",
    "Gretz-Armainvilliers",
    "Grez-en-Bouere",
    "Grez-sur-Loing",
    "Grezieu-la-Varenne",
    "Grezillac",
    "Grezolles",
    "Gries",
    "Griesbach-au-Val",
    "Griesheim-pres-Molsheim",
    "Grignan",
    "Grignols",
    "Grigny",
    "Grigny",
    "Grillon",
    "Grilly",
    "Grimaud",
    "Gripport",
    "Griselles",
    "Griselles",
    "Grisolles",
    "Grisy-Suisnes",
    "Grisy-sur-Seine",
    "Grivillers",
    "Groffliers",
    "Groissiat",
    "Groisy",
    "Groix",
    "Gron",
    "Gros-Rederching",
    "Grosbliederstroff",
    "Grosbreuil",
    "Groslay",
    "Grosrouvre",
    "Grosseto-Prugna",
    "Grossoeuvre",
    "Grozon",
    "Gruchet-le-Valasse",
    "Gruey-les-Surance",
    "Gruffy",
    "Grugies",
    "Gruissan",
    "Grun-Bordas",
    "Grundviller",
    "Gruson",
    "Grussenheim",
    "Guebwiller",
    "Guecelard",
    "Guegon",
    "Guemar",
    "Guemene-Penfao",
    "Guemene-sur-Scorff",
    "Guenange",
    "Guengat",
    "Guenin",
    "Guenrouet",
    "Guer",
    "Guerande",
    "Guerard",
    "Guercheville",
    "Gueret",
    "Guerigny",
    "Guerlesquin",
    "Guernes",
    "Guerting",
    "Guerville",
    "Guerville",
    "Guesnain",
    "Guessling-Hemering",
    "Guethary",
    "Gueugnon",
    "Gueures",
    "Gueux",
    "Guewenheim",
    "Guiche",
    "Guichen",
    "Guiclan",
    "Guidel",
    "Guignen",
    "Guignes",
    "Guigneville",
    "Guignicourt",
    "Guignicourt-sur-Vence",
    "Guiler-sur-Goyen",
    "Guilers",
    "Guilherand-Granges",
    "Guillestre",
    "Guilliers",
    "Guilly",
    "Guilvinec",
    "Guines",
    "Guingamp",
    "Guipavas",
    "Guipel",
    "Guipronvel",
    "Guipry",
    "Guiscard",
    "Guise",
    "Guiseniers",
    "Guitalens",
    "Guivry",
    "Gujan-Mestras",
    "Gumbrechtshoffen",
    "Gundershoffen",
    "Gundolsheim",
    "Guntzviller",
    "Guny",
    "Gurs",
    "Guyancourt",
    "Gy-en-Sologne",
    "Habas",
    "Habere-Lullin",
    "Habsheim",
    "Hadol",
    "Hagenbach",
    "Hagenthal-le-Haut",
    "Hagetmau",
    "Hageville",
    "Hagondange",
    "Haguenau",
    "Haillicourt",
    "Haironville",
    "Haisnes",
    "Hallencourt",
    "Hallennes-lez-Haubourdin",
    "Hallering",
    "Hallines",
    "Halloy-les-Pernois",
    "Halluin",
    "Ham",
    "Ham-en-Artois",
    "Ham-sous-Varsberg",
    "Ham-sur-Meuse",
    "Hamars",
    "Hambach",
    "Hambers",
    "Hamel",
    "Hammeville",
    "Han-sur-Nied",
    "Hanches",
    "Hangenbieten",
    "Hangest-en-Santerre",
    "Hannogne-Saint-Martin",
    "Hantay",
    "Hanvec",
    "Happonvilliers",
    "Haramont",
    "Haraucourt",
    "Haraucourt",
    "Harbonnieres",
    "Harcanville",
    "Harcigny",
    "Harcourt",
    "Hardelot-Plage",
    "Hardencourt",
    "Hardifort",
    "Hardinvast",
    "Harfleur",
    "Hargicourt",
    "Hargnies",
    "Harnes",
    "Haroue",
    "Harprich",
    "Harreberg",
    "Harreville-les-Chanteurs",
    "Harskirchen",
    "Hartmannswiller",
    "Hary",
    "Hasnon",
    "Hasparren",
    "Haspres",
    "Hastingues",
    "Hatten",
    "Hattenville",
    "Hattstatt",
    "Haubourdin",
    "Hauconcourt",
    "Haucourt-Moulaine",
    "Haudiomont",
    "Haudivillers",
    "Haulchin",
    "Haussignemont",
    "Haussimont",
    "Haussy",
    "Haut-Clocher",
    "Haute-Goulaine",
    "Haute-Isle",
    "Haute-Rivoire",
    "Haute-Vigneulles",
    "Hautecloque",
    "Hautefage-la-Tour",
    "Hautefort",
    "Hauteluce",
    "Hauterive",
    "Hauterive",
    "Hauterives",
    "Hauteville-les-Dijon",
    "Hauteville-Lompnes",
    "Hauteville-sur-Mer",
    "Hautmont",
    "Hautot-sur-Mer",
    "Hautvillers",
    "Hautvillers-Ouville",
    "Haveluy",
    "Haverskerque",
    "Havrincourt",
    "Hayange",
    "Haybes",
    "Hazebrouck",
    "Hebecourt",
    "Hebecrevon",
    "Hebuterne",
    "Hede-Bazouges",
    "Hegenheim",
    "Heidwiller",
    "Heiligenberg",
    "Heillecourt",
    "Heilly",
    "Heimersdorf",
    "Heimsbrunn",
    "Heiteren",
    "Helesmes",
    "Helette",
    "Helfaut",
    "Hellemmes-Lille",
    "Hellimer",
    "Hem",
    "Hemevillers",
    "Heming",
    "Hemonstoir",
    "Henansal",
    "Hendaye",
    "Henin-Beaumont",
    "Henin-sur-Cojeul",
    "Hennebont",
    "Henon",
    "Henonville",
    "Henouville",
    "Henrichemont",
    "Henridorff",
    "Henvic",
    "Herbeys",
    "Herbignac",
    "Herblay",
    "Herchies",
    "Herepian",
    "Heres",
    "Hergnies",
    "Heric",
    "Hericourt",
    "Hericy",
    "Herimoncourt",
    "Herin",
    "Herisson",
    "Herlies",
    "Hermanville-sur-Mer",
    "Hermelange",
    "Hermeray",
    "Hermes",
    "Hermies",
    "Hermillon",
    "Herny",
    "Herouvillette",
    "Herrlisheim-pres-Colmar",
    "Herserange",
    "Hersin-Coupigny",
    "Hery-sur-Alby",
    "Herzeele",
    "Hesdin",
    "Hesingue",
    "Hestrus",
    "Hettange-Grande",
    "Heuchin",
    "Heudebouville",
    "Heudicourt",
    "Heudicourt-sous-les-Cotes",
    "Heudreville-sur-Eure",
    "Heugas",
    "Heuqueville",
    "Heurtevent",
    "Heutregiville",
    "Heyrieux",
    "Higueres-Souye",
    "Hilbesheim",
    "Hillion",
    "Hindlingen",
    "Hinges",
    "Hipsheim",
    "Hirel",
    "Hirsingue",
    "Hirson",
    "Hochfelden",
    "Hochstatt",
    "Hocquigny",
    "Hodenc-en-Bray",
    "Hodeng-Hodenger",
    "Hoenheim",
    "Hoerdt",
    "Hohfrankenheim",
    "Hohwiller",
    "Holnon",
    "Holtzheim",
    "Holtzwihr",
    "Holving",
    "Hombleux",
    "Homblieres",
    "Hombourg",
    "Hombourg-Budange",
    "Hombourg-Haut",
    "Homecourt",
    "Homps",
    "Hon-Hergies",
    "Hondeghem",
    "Hondouville",
    "Hondschoote",
    "Honfleur",
    "Honnecourt-sur-Escaut",
    "Hopital-Camfrout",
    "Horbourg-Wihr",
    "Hordain",
    "Horgues",
    "Hornaing",
    "Hossegor",
    "Houchin",
    "Houdain-lez-Bavay",
    "Houdan",
    "Houdelmont",
    "Houdemont",
    "Houeilles",
    "Houilles",
    "Houlbec-Cocherel",
    "Houlette",
    "Houlgate",
    "Houlle",
    "Houplin-Ancoisne",
    "Houplines",
    "Houppeville",
    "Hourtin",
    "Houry",
    "Houssen",
    "Housseville",
    "Houville-la-Branche",
    "Houx",
    "Hoymille",
    "Hubersent",
    "Huberville",
    "Huchenneville",
    "Hucqueliers",
    "Hudimesnil",
    "Huelgoat",
    "Huest",
    "Huez",
    "Hugier",
    "Huiron",
    "Huisseau-sur-Cosson",
    "Huisseau-sur-Mauves",
    "Hulluch",
    "Hultehouse",
    "Hundling",
    "Hundsbach",
    "Huningue",
    "Hunspach",
    "Huppy",
    "Hure",
    "Huriel",
    "Hurigny",
    "Husseren-les-Chateaux",
    "Husseren-Wesserling",
    "Hussigny-Godbrange",
    "Huttenheim",
    "Hyemondans",
    "Idrac-Respailles",
    "Idron",
    "Iffendic",
    "Ifs",
    "Igney",
    "Igny",
    "Igon",
    "Igoville",
    "Illange",
    "Ille-sur-Tet",
    "Illfurth",
    "Illhaeusern",
    "Illiat",
    "Illiers-Combray",
    "Illifaut",
    "Illkirch-Graffenstaden",
    "Illois",
    "Illzach",
    "Imling",
    "Imphy",
    "Incheville",
    "Inchy",
    "Indre",
    "Ingersheim",
    "Ingolsheim",
    "Ingrandes",
    "Ingre",
    "Inguiniel",
    "Ingwiller",
    "Innenheim",
    "Inxent",
    "Ippling",
    "Irai",
    "Irancy",
    "Irigny",
    "Irissarry",
    "Irodouer",
    "Irouleguy",
    "Irreville",
    "Is-sur-Tille",
    "Isbergues",
    "Isches",
    "Isigny-le-Buat",
    "Isigny-sur-Mer",
    "Isle",
    "Isle-et-Bardais",
    "Isles-les-Villenoy",
    "Isneauville",
    "Isola",
    "Ispoure",
    "Isques",
    "Issancourt-et-Rumel",
    "Issenhausen",
    "Issenheim",
    "Issoire",
    "Issou",
    "Issoudun",
    "Issus",
    "Issy-les-Moulineaux",
    "Istres",
    "Itancourt",
    "Iteuil",
    "Ittenheim",
    "Itteville",
    "Itxassou",
    "Iville",
    "Ivoy-le-Pre",
    "Ivry-la-Bataille",
    "Ivry-le-Temple",
    "Ivry-sur-Seine",
    "Iwuy",
    "Izeaux",
    "Izernore",
    "Izeron",
    "Izier",
    "Izon",
    "Izotges",
    "Jabreilles-les-Bordes",
    "Jacob-Bellecombette",
    "Jacou",
    "Jainvillotte",
    "Jallais",
    "Jallans",
    "Jallerange",
    "Jalognes",
    "Jalogny",
    "Jalons",
    "Jambles",
    "Jametz",
    "Jandun",
    "Janneyrias",
    "Janville",
    "Janville",
    "Janville-sur-Juine",
    "Janvilliers",
    "Janvry",
    "Janze",
    "Jarcieu",
    "Jard-sur-Mer",
    "Jardin",
    "Jardres",
    "Jargeau",
    "Jarmenil",
    "Jarnac-Champagne",
    "Jarnages",
    "Jarny",
    "Jarrie",
    "Jarrier",
    "Jars",
    "Jarville-la-Malgrange",
    "Jarze",
    "Jassans-Riottier",
    "Jasseron",
    "Jaucourt",
    "Jaujac",
    "Jaunay-Clan",
    "Jaure",
    "Jausiers",
    "Jaux",
    "Javene",
    "Javrezac",
    "Jayat",
    "Jazeneuil",
    "Jeandelaincourt",
    "Jeanmenil",
    "Jebsheim",
    "Jegun",
    "Jenlain",
    "Jesonville",
    "Jettingen",
    "Jeu-les-Bois",
    "Jeufosse",
    "Jeumont",
    "Jeune",
    "Jeurre",
    "Jeux-les-Bard",
    "Jeuxey",
    "Jezainville",
    "Job",
    "Jobourg",
    "Joch",
    "Joeuf",
    "Joigny",
    "Joigny-sur-Meuse",
    "Joinville",
    "Joinville-le-Pont",
    "Jolimetz",
    "Jolivet",
    "Jonage",
    "Jonchery-sur-Vesle",
    "Joncourt",
    "Jonquerets-de-Livet",
    "Jonquerettes",
    "Jonquieres",
    "Jonquieres",
    "Jonquieres",
    "Jons",
    "Jonvelle",
    "Jonvilliers",
    "Jonzac",
    "Jonzier-Epagny",
    "Jonzieux",
    "Josnes",
    "Josse",
    "Josselin",
    "Jossigny",
    "Jouarre",
    "Jouars-Pontchartrain",
    "Jouaville",
    "Joudreville",
    "Joue-sur-Erdre",
    "Jougne",
    "Jouhet",
    "Jouques",
    "Journans",
    "Journiac",
    "Journy",
    "Joux",
    "Jouy-en-Josas",
    "Jouy-le-Chatel",
    "Jouy-le-Moutier",
    "Jouy-le-Potier",
    "Jouy-sous-Thelle",
    "Jouy-sur-Eure",
    "Jouy-sur-Morin",
    "Juan-les-Pins",
    "Jugeals-Nazareth",
    "Jugon-les-Lacs",
    "Juigne-sur-Loire",
    "Juigne-sur-Sarthe",
    "Juillac",
    "Juillan",
    "Juilles",
    "Juilly",
    "Juilly",
    "Jujurieux",
    "Julienas",
    "Julienne",
    "Jully-sur-Sarce",
    "Jumeauville",
    "Jumelles",
    "Jumelles",
    "Jumilhac-le-Grand",
    "Junay",
    "Jungholtz",
    "Juniville",
    "Jupilles",
    "Jurancon",
    "Jury",
    "Jussey",
    "Jussy",
    "Jussy",
    "Juvaincourt",
    "Juvignac",
    "Juvigny",
    "Juvigny",
    "Juvigny-en-Perthois",
    "Juville",
    "Juvisy-sur-Orge",
    "Juziers",
    "Kaltenhouse",
    "Kappelen",
    "Katzenthal",
    "Kaysersberg",
    "Kedange-sur-Canner",
    "Kembs",
    "Kerbach",
    "Kerfot",
    "Kerfourn",
    "Kergloff",
    "Kerien",
    "Kerity",
    "Kerlaz",
    "Kerlouan",
    "Kernascleden",
    "Kerpert",
    "Kervignac",
    "Kesseldorf",
    "Kienheim",
    "Kientzheim",
    "Killem",
    "Kilstett",
    "Kingersheim",
    "Kintzheim",
    "Knutange",
    "Koenigsmacker",
    "Koeur-la-Petite",
    "Krafft",
    "Krautergersheim",
    "Kriegsheim",
    "Kruth",
    "Kunheim",
    "Kuntzig",
    "Kurtzenhouse",
    "Kutzenhausen",
    "La Baconniere",
    "La Barben",
    "La Barre-de-Monts",
    "La Barre-en-Ouche",
    "La Barthe",
    "La Bassee",
    "La Bastide",
    "La Bastide",
    "La Bastide",
    "La Bathie",
    "La Batie",
    "La Baule-Escoublac",
    "La Baume-de-Transit",
    "La Baussaine",
    "La Bazoche-Gouet",
    "La Bazoge",
    "La Begue",
    "La Bernerie-en-Retz",
    "La Berthenoux",
    "La Bigne",
    "La Biolle",
    "La Bohalle",
    "La Boissiere-des-Landes",
    "La Bosse",
    "La Bosse-de-Bretagne",
    "La Bouexiere",
    "La Bouilladisse",
    "La Bourboule",
    "La Bourgonce",
    "La Boussac",
    "La Bresse",
    "La Bretonne",
    "La Bridoire",
    "La Brigue",
    "La Brillanne",
    "La Broque",
    "La Brosse",
    "La Bruffiere",
    "La Brulatte",
    "La Buisse",
    "La Buissiere",
    "La Bussiere",
    "La Calmette",
    "La Calotterie",
    "La Canourgue",
    "La Capelle",
    "La Caunette",
    "La Cavalerie",
    "La Celle",
    "La Celle",
    "La Celle",
    "La Celle-Saint-Cloud",
    "La Celle-sous-Montmirail",
    "La Celle-sur-Seine",
    "La Cerlangue",
    "La Chaise",
    "La Chaise",
    "La Chaise-Baudouin",
    "La Chaize-le-Vicomte",
    "La Chapelaude",
    "La Chapelle",
    "La Chapelle-aux-Bois",
    "La Chapelle-aux-Lys",
    "La Chapelle-Baton",
    "La Chapelle-Blanche",
    "La Chapelle-de-Brain",
    "La Chapelle-de-Guinchay",
    "La Chapelle-en-Vercors",
    "La Chapelle-Gaudin",
    "La Chapelle-Gauthier",
    "La Chapelle-Heulin",
    "La Chapelle-Laurent",
    "La Chapelle-Montligeon",
    "La Chapelle-Orthemale",
    "La Chapelle-Saint-Luc",
    "La Chapelle-sur-Chezy",
    "La Chapelle-Yvon",
    "La Charme",
    "La Charmee",
    "La Chartre-sur-le-Loir",
    "La Chataigneraie",
    "La Chatre",
    "La Chatre-Langlin",
    "La Chaumusse",
    "La Chaussaire",
    "La Chaussee",
    "La Chaux-du-Dombief",
    "La Cheneviere",
    "La Chevallerais",
    "La Chevroliere",
    "La Ciotat",
    "La Clayette",
    "La Clisse",
    "La Clotte",
    "La Clusaz",
    "La Cluse-et-Mijoux",
    "La Colle-sur-Loup",
    "La Combelle",
    "La Copechagniere",
    "La Coquille",
    "La Cornuaille",
    "La Cote",
    "La Cote",
    "La Couarde-sur-Mer",
    "La Coucourde",
    "La Coudre",
    "La Coulonche",
    "La Courneuve",
    "La Couronne",
    "La Couronne",
    "La Couture-Boussey",
    "La Crau",
    "La Croix-Valmer",
    "La Cropte",
    "La Dagueniere",
    "La Defense",
    "La Destrousse",
    "La Dominelais",
    "La Fare-en-Champsaur",
    "La Farlede",
    "La Faute-sur-Mer",
    "La Faye",
    "La Fere",
    "La Ferriere",
    "La Ferriere-Bochard",
    "La Ferte",
    "La Ferte-Alais",
    "La Ferte-Mace",
    "La Ferte-Milon",
    "La Ferte-Saint-Cyr",
    "La Ferte-Saint-Samson",
    "La Ferte-sous-Jouarre",
    "La Feuillade",
    "La Feuillie",
    "La Feuillie",
    "La Flachere",
    "La Fleche",
    "La Flocelliere",
    "La Flotte",
    "La Force",
    "La Forest-Landerneau",
    "La Foret",
    "La Foret",
    "La Foret-Fouesnant",
    "La Forie",
    "La Fouillade",
    "La Fouillouse",
    "La Foux",
    "La Foye-Monjault",
    "La Frasse",
    "La Frenaye",
    "La Fresnais",
    "La Frette",
    "La Frette",
    "La Frette-sur-Seine",
    "La Gacilly",
    "La Gaillarde",
    "La Gaillarde",
    "La Garde",
    "La Garde",
    "La Garenne",
    "La Garenne-Colombes",
    "La Garnache",
    "La Gaubretiere",
    "La Gaude",
    "La Gavotte",
    "La Glacerie",
    "La Gorgue",
    "La Gouesniere",
    "La Grand-Croix",
    "La Grande-Motte",
    "La Grandville",
    "La Grave",
    "La Graverie",
    "La Gree-Saint-Laurent",
    "La Greve-sur-Mignon",
    "La Grigonnais",
    "La Groise",
    "La Guerche",
    "La Guerche-de-Bretagne",
    "La Gueroulde",
    "La Guierche",
    "La Guyonniere",
    "La Haie",
    "La Halle",
    "La Hallotiere",
    "La Haye",
    "La Haye-Saint-Sylvestre",
    "La Houblonniere",
    "La Houssaye-en-Brie",
    "La Houssoye",
    "La Jaille-Yvon",
    "La Jarne",
    "La Jarrie",
    "La Jubaudiere",
    "La Jumelliere",
    "La Laigne",
    "La Lande",
    "La Lande",
    "La Landec",
    "La Laupie",
    "La Lechere",
    "La Limouziniere",
    "La Liviniere",
    "La Londe",
    "La Londe-les-Maures",
    "La Loubiere",
    "La Loupe",
    "La Lucerne-dOutremer",
    "La Machine",
    "La Madeleine",
    "La Madeleine",
    "La Madeleine-sur-Loing",
    "La Magdelaine-sur-Tarn",
    "La Mailleraye-sur-Seine",
    "La Malhoure",
    "La Marche",
    "La Marolle-en-Sologne",
    "La Martiniere",
    "La Maxe",
    "La Meauffe",
    "La Meaugon",
    "La Meignanne",
    "La Meilleraie-Tillay",
    "La Meilleraye-de-Bretagne",
    "La Membrolle-sur-Choisille",
    "La Membrolle-sur-Longuenee",
    "La Menitre",
    "La Meziere",
    "La Milesse",
    "La Montagne",
    "La Montagne",
    "La Montagne",
    "La Mothe-Saint-Heray",
    "La Motte",
    "La Motte-Chalancon",
    "La Motte-du-Caire",
    "La Motte-Fouquet",
    "La Motte-Saint-Jean",
    "La Motte-Saint-Martin",
    "La Motte-Servolex",
    "La Mulatiere",
    "La Muraz",
    "La Mure",
    "La Napoule",
    "La Nerthe",
    "La Neuveville-devant-Lepanges",
    "La Neuville-du-Bosc",
    "La Noe",
    "La Norville",
    "La Palud",
    "La Paute",
    "La Penne-sur-Huveaune",
    "La Perche",
    "La Place",
    "La Plagne",
    "La Plaine",
    "La Plaine",
    "La Plaine-Saint-Denis",
    "La Planche",
    "La Plane",
    "La Pointe",
    "La Poiteviniere",
    "La Pommeraye",
    "La Porcherie",
    "La Porte",
    "La Possonniere",
    "La Poste",
    "La Poterie",
    "La Poterie-Mathieu",
    "La Poueze",
    "La Puye",
    "La Queue-en-Brie",
    "La Queue-les-Yvelines",
    "La Ravoire",
    "La Remuee",
    "La Renaudiere",
    "La Reole",
    "La Reorthe",
    "La Ricamarie",
    "La Richardais",
    "La Riche",
    "La Riviere",
    "La Riviere",
    "La Riviere",
    "La Riviere",
    "La Riviere-de-Corps",
    "La Rixouse",
    "La Roche",
    "La Roche",
    "La Roche",
    "La Roche",
    "La Roche-Blanche",
    "La Roche-des-Arnauds",
    "La Roche-sur-le-Buis",
    "La Roche-sur-Yon",
    "La Rochefoucauld",
    "La Rochegiron",
    "La Rochelle",
    "La Rochenard",
    "La Rochette",
    "La Rochette",
    "La Rochette-sur-Crest",
    "La Romagne",
    "La Ronde",
    "La Roque-Baignard",
    "La Roquebrussanne",
    "La Roquette-sur-Siagne",
    "La Rouine",
    "La Rouquette",
    "La Rouviere",
    "La Salette-Fallavaux",
    "La Salle-de-Vihiers",
    "La Salvetat-Lauragais",
    "La Salvetat-Saint-Gilles",
    "La Salvetat-sur-Agout",
    "La Saulce",
    "La Sauve",
    "La Sauvetat-de-Saveres",
    "La Seauve-sur-Semene",
    "La Seguiniere",
    "La Selle-en-Hermoy",
    "La Selve",
    "La Sentinelle",
    "La Seyne-sur-Mer",
    "La Sone",
    "La Souche",
    "La Souterraine",
    "La Suze-sur-Sarthe",
    "La Tagniere",
    "La Taillee",
    "La Talaudiere",
    "La Tardiere",
    "La Terrasse",
    "La Tessoualle",
    "La Teste-de-Buch",
    "La Tour",
    "La Tour",
    "La Tour-dAuvergne",
    "La Tour-de-Scay",
    "La Tour-du-Crieu",
    "La Tour-Saint-Gelin",
    "La Tour-sur-Orb",
    "La Tourette",
    "La Tranche-sur-Mer",
    "La Treille",
    "La Tremblade",
    "La Trimouille",
    "La Trinite",
    "La Trinite-de-Reville",
    "La Tronche",
    "La Tuiliere",
    "La Turballe",
    "La Turbie",
    "La Vacherie",
    "La Valette-du-Var",
    "La Vallee de Dormelles",
    "La Varenne",
    "La Varenne",
    "La Vaupaliere",
    "La Verpilliere",
    "La Verrie",
    "La Verriere",
    "La Vespiere",
    "La Veze",
    "La Ville",
    "La Ville aux Chiens",
    "La Ville-aux-Bois",
    "La Villedieu",
    "La Villedieu-les-Quenoche",
    "La Villeneuve",
    "La Villetelle",
    "La Voulte-sur-Rhone",
    "La Vraie-Croix",
    "La Walck",
    "La Wantzenau",
    "Laas",
    "Labaroche",
    "Labarthe-sur-Leze",
    "Labastide-Beauvoir",
    "Labastide-Cezeracq",
    "Labastide-dArmagnac",
    "Labastide-de-Virac",
    "Labastide-Gabausse",
    "Labastide-Rouairoux",
    "Labastide-Saint-Pierre",
    "Labastidette",
    "Labatut",
    "Labege",
    "Labenne",
    "Labessiere-Candeil",
    "Labeuvriere",
    "Lablachere",
    "Labouheyre",
    "Labouquerie",
    "Labourse",
    "Labretonie",
    "Labruguiere",
    "Labruyere",
    "Labry",
    "Labuissiere",
    "Lacabarede",
    "Lacadee",
    "Lacanau",
    "Lacapelle-Cabanac",
    "Lacapelle-Marival",
    "Lacapelle-Viescamp",
    "Lacaune",
    "Lacaussade",
    "Lachapelle",
    "Lachapelle-sous-Chaux",
    "Lachapelle-sous-Rougemont",
    "Lachaux",
    "Lacour",
    "Lacq",
    "Lacroix-Falgarde",
    "Lacroix-Saint-Ouen",
    "Lacroix-sur-Meuse",
    "Lacrost",
    "Lacs",
    "Ladoix-Serrigny",
    "Ladon",
    "Laduz",
    "Lafare",
    "Lafeuillade-en-Vezie",
    "Lafitole",
    "Lafox",
    "Lagardelle-sur-Leze",
    "Lagardere",
    "Lagarrigue",
    "Lagnes",
    "Lagnieu",
    "Lagny-le-Sec",
    "Lagor",
    "Lagord",
    "Lagrave",
    "Laguenne",
    "Laguiole",
    "Lagupie",
    "Lahitte-Toupiere",
    "Lahonce",
    "Lahontan",
    "Lahosse",
    "Lahourcade",
    "Laifour",
    "Laigne",
    "Laigne-en-Belin",
    "Laignelet",
    "Laignes",
    "Laigneville",
    "LAiguillon-sur-Mer",
    "Laille",
    "Lailly-en-Val",
    "Lainsecq",
    "Laissac",
    "Laissaud",
    "Laissey",
    "Laiz",
    "Laize",
    "Lalanne-Arque",
    "Lalbenque",
    "Laleu",
    "Lalinde",
    "Lallaing",
    "Lalonquette",
    "Laloubere",
    "Lamagdelaine",
    "Lamagistere",
    "Lamaire",
    "Lamalou-les-Bains",
    "Lamanon",
    "Lamarche",
    "Lamarche-sur-Saone",
    "Lamastre",
    "Lamativie",
    "Lamaziere-Basse",
    "Lamballe",
    "Lambersart",
    "Lambert",
    "Lambesc",
    "Lambres-lez-Douai",
    "Lamnay",
    "Lamorlaye",
    "Lamorville",
    "Lamothe-Landerron",
    "Lamothe-Montravel",
    "Lamotte-Beuvron",
    "Lampaul-Guimiliau",
    "Lampaul-Plouarzel",
    "Lampertheim",
    "Lampertsloch",
    "Lancey",
    "Lancie",
    "Lancieux",
    "Lancon-Provence",
    "Lancrans",
    "Landas",
    "Landebia",
    "Landeda",
    "Landelles",
    "Landelles-et-Coupigny",
    "Landemont",
    "Landepereuse",
    "Landerneau",
    "Landeronde",
    "Landes",
    "Landevant",
    "Landevieille",
    "Landivisiau",
    "Landogne",
    "Landos",
    "Landrecies",
    "Landres",
    "Landrethun-le-Nord",
    "Landrethun-les-Ardres",
    "Landrevarzec",
    "Landry",
    "Landser",
    "Landujan",
    "Landunvez",
    "Lanester",
    "Laneuvelotte",
    "Laneuveville-aux-Bois",
    "Laneuveville-devant-Bayon",
    "Laneuveville-devant-Nancy",
    "Langan",
    "Langeac",
    "Langeais",
    "Langensoultzbach",
    "Langlade",
    "Langoat",
    "Langoelan",
    "Langoiran",
    "Langolen",
    "Langon",
    "Langon",
    "Langonnet",
    "Langourla",
    "Langres",
    "Langrune-sur-Mer",
    "Langueux",
    "Languidic",
    "Lanhelin",
    "Laning",
    "Lanleff",
    "Lanmerin",
    "Lanmeur",
    "Lanne",
    "Lanne-en-Baretous",
    "Lannebert",
    "Lannemezan",
    "Lannilis",
    "Lannion",
    "Lannoy",
    "Lannoy-Cuillere",
    "Lanobre",
    "Lanouee",
    "Lanrelas",
    "Lanrodec",
    "Lans",
    "Lans-en-Vercors",
    "Lansac",
    "Lansargues",
    "Lanta",
    "Lantenne-Vertiere",
    "Lanthenans",
    "Lantignie",
    "Lantilly",
    "Lanton",
    "Lantriac",
    "Lanvallay",
    "Lanvaudan",
    "Lanveoc",
    "Lanvollon",
    "Lanzac",
    "Laon",
    "Lapalisse",
    "Lapalme",
    "Lapalud",
    "Laparrouquial",
    "Lapenche",
    "Laperriere-sur-Saone",
    "Lapeyrouse-Fossat",
    "Lapeyrouse-Mornay",
    "Lapoutroie",
    "Lapouyade",
    "Lapte",
    "Lapugnoy",
    "Laquenexy",
    "Laqueuille",
    "Laragne-Monteglin",
    "LArbresle",
    "Larcay",
    "Larchant",
    "Larche",
    "Lardy",
    "Largeasse",
    "Largillay-Marsonnay",
    "Lariviere-Arnoncourt",
    "Larmor-Baden",
    "Larmor-Plage",
    "Laroche-Saint-Cydroine",
    "Laroin",
    "Laroque-de-Fa",
    "Larrazet",
    "Larressore",
    "Larriviere-Saint-Savin",
    "Laruns",
    "Larzicourt",
    "Lasalle",
    "Lasbordes",
    "Lassay-les-Chateaux",
    "Lassay-sur-Croisne",
    "Lasseran",
    "Lasserre",
    "Lasseube",
    "Lassigny",
    "Lasson",
    "Lassy",
    "Lastic",
    "Lathuile",
    "Latille",
    "Latour",
    "Latour-Bas-Elne",
    "Latresne",
    "Latronquiere",
    "Lattainville",
    "Lattes",
    "Lattre-Saint-Quentin",
    "Laudun-lArdoise",
    "Laugnac",
    "Launaguet",
    "Launay",
    "Laurabuc",
    "Laure",
    "Laurede",
    "Laurenan",
    "Laurens",
    "Laurent",
    "Lauret",
    "Lauris",
    "Laussonne",
    "Lautenbach",
    "Lauterbourg",
    "Lautrec",
    "Lauzerte",
    "Lauzerville",
    "Lauzun",
    "Laval",
    "Laval-sur-Doulon",
    "Laval-sur-Vologne",
    "Lavannes",
    "Lavans-les-Saint-Claude",
    "Lavans-Vuillafans",
    "Lavardac",
    "Lavardens",
    "Lavardin",
    "Lavardin",
    "Lavau",
    "Lavaur",
    "Lavaur",
    "Lavaurette",
    "Lavelanet",
    "Laventie",
    "Lavergne",
    "Lavernose-Lacasse",
    "Laversine",
    "Laversines",
    "Laverune",
    "Lavit",
    "Lavours",
    "Lavoute-Chilhac",
    "Laxou",
    "Laye",
    "Layrac",
    "Layrisse",
    "Le Baizil",
    "Le Ban-Saint-Martin",
    "Le Bar-sur-Loup",
    "Le Barboux",
    "Le Barcares",
    "Le Bardon",
    "Le Barp",
    "Le Beausset",
    "Le Bernard",
    "Le Bessat",
    "Le Bez",
    "Le Bignon",
    "Le Blanc",
    "Le Blanc-Mesnil",
    "Le Bleymard",
    "Le Bocasse",
    "Le Bois",
    "Le Bois-Plage-en-Re",
    "Le Bonhomme",
    "Le Bono",
    "Le Bosc",
    "Le Bosc-Roger-en-Roumois",
    "Le Bouchage",
    "Le Boulay-Morin",
    "Le Boupere",
    "Le Bourg",
    "Le Bourget",
    "Le Bourget",
    "Le Bourgneuf-la-Foret",
    "Le Bouscat",
    "Le Bousquet",
    "Le Breil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil-sur-Couze",
    "Le Broc",
    "Le Broc",
    "Le Brugeron",
    "Le Buisson-de-Cadouin",
    "Le Cailar",
    "Le Cannet",
    "Le Castellet",
    "Le Castera",
    "Le Cateau-Cambresis",
    "Le Caylar",
    "Le Cellier",
    "Le Cendre",
    "Le Cergne",
    "Le Chambon",
    "Le Chambon",
    "Le Chambon",
    "Le Champ-pres-Froges",
    "Le Champ-Saint-Pere",
    "Le Chateau",
    "Le Chateau Gaillard",
    "Le Chatel",
    "Le Chatelard",
    "Le Chatelet-en-Brie",
    "Le Chatelet-sur-Retourne",
    "Le Chaylard",
    "Le Chesnay",
    "Le Chesne",
    "Le Cheylas",
    "Le Collet-de-Deze",
    "Le Comte",
    "Le Conquet",
    "Le Coteau",
    "Le Coudray",
    "Le Coudray-Baillet",
    "Le Coudray-Montceaux",
    "Le Cres",
    "Le Crest",
    "Le Creusot",
    "Le Croisic",
    "Le Crotoy",
    "Le Deluge",
    "Le Deschaux",
    "Le Dezert",
    "Le Donjon",
    "Le Dorat",
    "Le Doulieu",
    "Le Falgoux",
    "Le Faou",
    "Le Faouet",
    "Le Fayet",
    "Le Fenouiller",
    "Le Ferre",
    "Le Fidelaire",
    "Le Fief-Sauvin",
    "Le Foeil",
    "Le Folgoet",
    "Le Fossat",
    "Le Fousseret",
    "Le Fraysse",
    "Le Fresne-Camilly",
    "Le Fuilet",
    "Le Garric",
    "Le Gavre",
    "Le Gond",
    "Le Grand Village",
    "Le Grand-Bornand",
    "Le Grand-Pressigny",
    "Le Grand-Quevilly",
    "Le Gratteris",
    "Le Grau-du-Roi",
    "Le Gua",
    "Le Guerno",
    "Le Guildo",
    "Le Haillan",
    "Le Havre",
    "Le Herie-la-Vieville",
    "Le Hohwald",
    "Le Houlme",
    "Le Kremlin-Bicetre",
    "Le Landin",
    "Le Landreau",
    "Le Lardin-Saint-Lazare",
    "Le Lavandou",
    "Le Lez",
    "Le Longeron",
    "Le Loroux",
    "Le Loroux-Bottereau",
    "Le Louroux-Beconnais",
    "Le Luart",
    "Le Luc",
    "Le Lude",
    "Le Manoir",
    "Le Mans",
    "Le Marillais",
    "Le Martinet",
    "Le Mas",
    "Le May-sur-Evre",
    "Le Mayet",
    "Le Mazeau",
    "Le Mee-sur-Seine",
    "Le Meix-Saint-Epoing",
    "Le Mele-sur-Sarthe",
    "Le Menil",
    "Le Meriot",
    "Le Merlerault",
    "Le Mesnil-Amelot",
    "Le Mesnil-en-Thelle",
    "Le Mesnil-en-Vallee",
    "Le Mesnil-Esnard",
    "Le Mesnil-Fuguet",
    "Le Mesnil-le-Roi",
    "Le Mesnil-Ozenne",
    "Le Mesnil-Vigot",
    "Le Mesnil-Villeman",
    "Le Meux",
    "Le Molard",
    "Le Molay",
    "Le Monastier-Pin-Mories",
    "Le Monastier-sur-Gazeille",
    "Le Mont-Dore",
    "Le Monteil",
    "Le Montel",
    "Le Muy",
    "Le Neubourg",
    "Le Noyer",
    "Le Palais",
    "Le Pallet",
    "Le Parc",
    "Le Parcq",
    "Le Passage",
    "Le Passage",
    "Le Pecq",
    "Le Pellerin",
    "Le Perray-en-Yvelines",
    "Le Perreux-sur-Marne",
    "Le Perrier",
    "Le Petit-Quevilly",
    "Le Pian-sur-Garonne",
    "Le Pin",
    "Le Pin",
    "Le Pin",
    "Le Pin",
    "Le Pin-la-Garenne",
    "Le Pizou",
    "Le Plan-de-la-Tour",
    "Le Planois",
    "Le Plessier-sur-Bulles",
    "Le Plessis",
    "Le Plessis-Belleville",
    "Le Plessis-Bouchard",
    "Le Plessis-Grammoire",
    "Le Plessis-Hebert",
    "Le Plessis-Pate",
    "Le Plessis-Robinson",
    "Le Plessis-Trevise",
    "Le Poet",
    "Le Poet-Laval",
    "Le Poinconnet",
    "Le Poire-sur-Velluire",
    "Le Pont",
    "Le Pont",
    "Le Pont-de-Claix",
    "Le Pontet",
    "Le Porge",
    "Le Port",
    "Le Port",
    "Le Port-Marly",
    "Le Portel",
    "Le Pouget",
    "Le Pouliguen",
    "Le Pout",
    "Le Pouzin",
    "Le Pradet",
    "Le Pre-Saint-Gervais",
    "Le Puy",
    "Le Puy-en-Velay",
    "Le Puy-Sainte-Reparade",
    "Le Quesnel",
    "Le Quesnoy",
    "Le Raincy",
    "Le Relecq-Kerhuon",
    "Le Renouard",
    "Le Revest-les-Eaux",
    "Le Rheu",
    "Le Roncenay-Authenay",
    "Le Rouget",
    "Le Rouret",
    "Le Rove",
    "Le Sacq",
    "Le Sap",
    "Le Segur",
    "Le Soler",
    "Le Sourn",
    "Le Syndicat",
    "Le Taillan-Medoc",
    "Le Tallud",
    "Le Teich",
    "Le Teil",
    "Le Teilleul",
    "Le Temple-de-Bretagne",
    "Le Theil",
    "Le Theil-de-Bretagne",
    "Le Thieulin",
    "Le Thillay",
    "Le Thillot",
    "Le Tholonet",
    "Le Tholy",
    "Le Thor",
    "Le Thou",
    "Le Thoureil",
    "Le Thuit-Signol",
    "Le Thuit-Simer",
    "Le Tignet",
    "Le Torquesne",
    "Le Touquet-Paris-Plage",
    "Le Tourne",
    "Le Tourneur",
    "Le Touvet",
    "Le Trait",
    "Le Treport",
    "Le Trevoux",
    "Le Tronchet",
    "Le Tronquay",
    "Le Val",
    "Le Val-dAjol",
    "Le Vaudioux",
    "Le Vaudreuil",
    "Le Vaumain",
    "Le Verger",
    "Le Vernet",
    "Le Vernet",
    "Le Versoud",
    "Le Vesinet",
    "Le Vieux",
    "Le Vieux Bourg",
    "Le Vieux Bourg",
    "Le Vigan",
    "Le Vigean",
    "Le Vigeant",
    "Le Vigen",
    "Le Vivier-sur-Mer",
    "Le Voide",
    "Lealvillers",
    "Leaz",
    "Lecelles",
    "Lechatelet",
    "Lechelle",
    "Lecousse",
    "Lectoure",
    "Ledenon",
    "Lederzeele",
    "Leers",
    "Leffincourt",
    "Leffrinckoucke",
    "Leforest",
    "Lege",
    "Lege-Cap-Ferret",
    "Leguevin",
    "Lehon",
    "Leigneux",
    "Leintrey",
    "Lelling",
    "Lemainville",
    "Lembach",
    "Lemberg",
    "Leme",
    "Lemmes",
    "Lempdes",
    "Lempdes-sur-Allagnon",
    "Lemps",
    "Lencloitre",
    "Lennon",
    "Lenoncourt",
    "Lens",
    "Lens-Lestang",
    "Lent",
    "Lentigny",
    "Lentilly",
    "Lentiol",
    "Leojac",
    "Leon",
    "Leouville",
    "Lepin-le-Lac",
    "Lepuix",
    "Lepuy",
    "Lere",
    "Lery",
    "Les Abrets",
    "Les Ageux",
    "Les Alleuds",
    "Les Allies",
    "Les Allues",
    "Les Ancizes-Comps",
    "Les Andelys",
    "Les Angles",
    "Les Arcs",
    "Les Ardillats",
    "Les Artigues-de-Lussac",
    "Les Assions",
    "Les Aubiers",
    "Les Avanchers-Valmorel",
    "Les Avenieres",
    "Les Bains",
    "Les Baux de Provence",
    "Les Baux-de-Breteuil",
    "Les Billanges",
    "Les Bordes",
    "Les Bordes",
    "Les Bordes-sur-Lez",
    "Les Breviaires",
    "Les Brouzils",
    "Les Cabanes",
    "Les Cabannes",
    "Les Cars",
    "Les Champs",
    "Les Chapelles",
    "Les Charmontois",
    "Les Cheres",
    "Les Clayes-sous-Bois",
    "Les Clouzeaux",
    "Les Combes",
    "Les Deux-Fays",
    "Les Echelles",
    "Les Ecrennes",
    "Les Eglisottes-et-Chalaures",
    "Les Eparres",
    "Les Epesses",
    "Les Essards",
    "Les Essards-Taignevaux",
    "Les Essarts",
    "Les Essarts",
    "Les Essarts",
    "Les Etangs",
    "Les Eyzies-de-Tayac-Sireuil",
    "Les Fins",
    "Les Fontaines",
    "Les Fonts",
    "Les Forges",
    "Les Forges",
    "Les Fourgs",
    "Les Gets",
    "Les Gours",
    "Les Grands-Chezeaux",
    "Les Granges",
    "Les Granges",
    "Les Gras",
    "Les Halles",
    "Les Hays",
    "Les Herbiers",
    "Les Hogues",
    "Les Hopitaux-Vieux",
    "Les Hotelleries",
    "Les Houches",
    "Les Jumeaux",
    "Les Landes",
    "Les Landes-Genusson",
    "Les Laumes",
    "Les Lilas",
    "Les Loges",
    "Les Loges",
    "Les Loges-en-Josas",
    "Les Lucs-sur-Boulogne",
    "Les Mages",
    "Les Marches",
    "Les Martres",
    "Les Mathes",
    "Les Maures",
    "Les Mees",
    "Les Mesneux",
    "Les Mesnuls",
    "Les Milles",
    "Les Moitiers-en-Bauptois",
    "Les Molieres",
    "Les Montils",
    "Les Mureaux",
    "Les Nouillers",
    "Les Ollieres-sur-Eyrieux",
    "Les Olmes",
    "Les Ormes",
    "Les Ormes",
    "Les Ormes-sur-Voulzie",
    "Les Palais",
    "Les Pavillons-sous-Bois",
    "Les Peintures",
    "Les Pennes-Mirabeau",
    "Les Petites Loges",
    "Les Pieux",
    "Les Pineaux",
    "Les Pins",
    "Les Pins",
    "Les Pins",
    "Les Places",
    "Les Rives",
    "Les Rivieres-Henruel",
    "Les Roches",
    "Les Rosiers",
    "Les Rosiers-sur-Loire",
    "Les Rousses",
    "Les Sables",
    "Les Sauvages",
    "Les Sorinieres",
    "Les Taillades",
    "Les Thilliers-en-Vexin",
    "Les Touches",
    "Les Vallees",
    "Les Vans",
    "Les Varennes",
    "Les Vigneaux",
    "Les Villedieu",
    "Lescar",
    "Lescheraines",
    "Lescherolles",
    "Lesches",
    "Lesconil",
    "Lescout",
    "Lescure",
    "Lesdain",
    "Lesigny",
    "Lesneven",
    "Lespesses",
    "Lespielle",
    "Lespignan",
    "Lespinasse",
    "Lesquin",
    "Lessay",
    "Lesse",
    "Lessy",
    "Lestelle-Betharram",
    "Lestiou",
    "Lestrem",
    "Letanne",
    "Letricourt",
    "Leuc",
    "Leucate",
    "Leudeville",
    "Leugny",
    "Leuilly-sous-Coucy",
    "Leuville-sur-Orge",
    "Leuvrigny",
    "Levainville",
    "Leval",
    "Leval",
    "Levallois-Perret",
    "Levens",
    "Levernois",
    "Leves",
    "Levet",
    "Levie",
    "Levier",
    "Levignacq",
    "Levis",
    "Levroux",
    "Lewarde",
    "Lexy",
    "Leyme",
    "Leyr",
    "Leyrieu",
    "Leysse",
    "Lezan",
    "Lezardrieux",
    "Lezat-sur-Leze",
    "Lezay",
    "Lezennes",
    "Lezignan-Corbieres",
    "Lezigne",
    "Lezigneux",
    "Lezoux",
    "Lheraule",
    "Lherm",
    "Lhommaize",
    "Lhuis",
    "Liancourt",
    "Liancourt-Saint-Pierre",
    "Liart",
    "Libercourt",
    "Libourne",
    "Licques",
    "Liepvre",
    "Lieramont",
    "Liergues",
    "Liernais",
    "Lieu-Saint-Amand",
    "Lieuran-les-Beziers",
    "Lieurey",
    "Lieuron",
    "Lieusaint",
    "Lieuvillers",
    "Liffol-le-Grand",
    "Liffre",
    "Ligescourt",
    "Lignan-sur-Orb",
    "Ligne",
    "Ligne",
    "Lignerolles",
    "Lignerolles",
    "Lignieres-Chatelain",
    "Lignieres-la-Carelle",
    "Lignol",
    "Ligny-en-Barrois",
    "Ligny-en-Cambresis",
    "Ligny-le-Chatel",
    "Ligny-le-Ribault",
    "Ligsdorf",
    "Ligueil",
    "Liguge",
    "Lihons",
    "Lihus",
    "Lille",
    "Lillebonne",
    "Lillers",
    "Limas",
    "Limay",
    "Limeil-Brevannes",
    "Limeray",
    "Limersheim",
    "Limerzel",
    "Limesy",
    "Limetz-Villez",
    "Limoges",
    "Limoges-Fourches",
    "Limogne-en-Quercy",
    "Limonest",
    "Limony",
    "Limours",
    "Limoux",
    "Linars",
    "Linas",
    "Lincel",
    "Lingolsheim",
    "Lingreville",
    "Linselles",
    "Linxe",
    "Liocourt",
    "Lion-en-Sullias",
    "Lion-sur-Mer",
    "Liouc",
    "Lipsheim",
    "Lire",
    "Lisieux",
    "Lisle",
    "LIsle-dEspagnac",
    "LIsle-sur-le-Doubs",
    "Lisle-sur-Tarn",
    "Lisors",
    "Lisses",
    "Lissieu",
    "Lissy",
    "Lit-et-Mixe",
    "Livarot",
    "Liverdun",
    "Liverdy-en-Brie",
    "Livernon",
    "Livet-et-Gavet",
    "Livet-sur-Authou",
    "Livinhac-le-Haut",
    "Livre-sur-Changeon",
    "Livron",
    "Livron-sur-Drome",
    "Livry-Gargan",
    "Livry-sur-Seine",
    "Lixhausen",
    "Lixheim",
    "Lizac",
    "Lizy-sur-Ourcq",
    "Llupia",
    "Loche",
    "Loche-sur-Indrois",
    "Locmaria-Grand-Champ",
    "Locmaria-Plouzane",
    "Locmariaquer",
    "Locmine",
    "Locmiquelic",
    "Locoal-Mendon",
    "Locon",
    "Locquemeau",
    "Locquirec",
    "Loctudy",
    "Loge-Fougereuse",
    "Logelbach",
    "Logelheim",
    "Lognes",
    "Logonna-Daoulas",
    "Logonna-Quimerch",
    "Logrian-Florian",
    "Lohr",
    "Loire",
    "Loire-les-Marais",
    "Loire-sur-Rhone",
    "Loiron",
    "Loisin",
    "Loison-sous-Lens",
    "Loisy",
    "Loisy-sur-Marne",
    "Loix",
    "Lokmaria",
    "Lombers",
    "Lombez",
    "Lombron",
    "Lomme",
    "Lommerange",
    "Lompret",
    "Londigny",
    "Londinieres",
    "Longages",
    "Longchamp",
    "Longchamp-sous-Chatenois",
    "Longeault",
    "Longecourt-en-Plaine",
    "Longes",
    "Longessaigne",
    "Longevelle-sur-Doubs",
    "Longeves",
    "Longeville-en-Barrois",
    "Longeville-les-Metz",
    "Longeville-les-Saint-Avold",
    "Longeville-sur-Mer",
    "Longfosse",
    "Longjumeau",
    "Longlaville",
    "Longnes",
    "Longpont-sur-Orge",
    "Longpre-les-Corps-Saints",
    "Longue-Jumelles",
    "Longueau",
    "Longueil-Annel",
    "Longueil-Sainte-Marie",
    "Longuenesse",
    "Longueville",
    "Longueville-sur-Scie",
    "Longuyon",
    "Longvic",
    "Longwy",
    "Lons",
    "Lons-le-Saunier",
    "Loon-Plage",
    "Loos",
    "Loos-en-Gohelle",
    "Loperhet",
    "Lorcieres",
    "Lorette",
    "Lorey",
    "Lorgies",
    "Lorgues",
    "Lorient",
    "Lorignac",
    "Loriol-du-Comtat",
    "Loriol-sur-Drome",
    "Lorlanges",
    "Lormaison",
    "Lormaye",
    "Lormont",
    "Lorquin",
    "Lorrez-le-Bocage-Preaux",
    "Lorris",
    "Lorry-Mardigny",
    "Los Masos",
    "Losne",
    "Louailles",
    "Louannec",
    "Louargat",
    "Louatre",
    "Loubaresse",
    "Loubes-Bernac",
    "Loubeyrat",
    "Loubieng",
    "Loubigne",
    "Loubille",
    "Loudeac",
    "Loudes",
    "Loudrefing",
    "Loudun",
    "Loue",
    "Louer",
    "Lougres",
    "Louhans",
    "Loupershouse",
    "Loupes",
    "Loupiac-de-la-Reole",
    "Loupian",
    "Louplande",
    "Lourches",
    "Lourdes",
    "Lourenties",
    "Loures-Barousse",
    "Louresse-Rochemenier",
    "Lourmarin",
    "Lourties-Monbrun",
    "Loury",
    "Louveciennes",
    "Louvemont",
    "Louverne",
    "Louvie-Juzon",
    "Louviers",
    "Louvigne-de-Bais",
    "Louvignies-Quesnoy",
    "Louvigny",
    "Louvigny",
    "Louvigny",
    "Louvil",
    "Louvrechy",
    "Louvres",
    "Louvroil",
    "Louzouer",
    "Louzy",
    "Lovagny",
    "Loyat",
    "Loyes",
    "Loyettes",
    "Lozanne",
    "Lozinghem",
    "Lozon",
    "Luant",
    "Lubersac",
    "Luc",
    "Luc-sur-Mer",
    "Luc-sur-Orbieu",
    "Lucciana",
    "Luce",
    "Luce",
    "Luceau",
    "Lucenay",
    "Lucey",
    "Luchy",
    "Lucon",
    "Lucq-de-Bearn",
    "Lucy-sur-Cure",
    "Ludon-Medoc",
    "Ludres",
    "Luemschwiller",
    "Lugny-Champagne",
    "Lugny-les-Charolles",
    "Lugrin",
    "Luisant",
    "Luitre",
    "Lullin",
    "Lully",
    "Lumbin",
    "Lumbres",
    "Lumeau",
    "Lumio",
    "Lunel",
    "Lunel-Viel",
    "Luneray",
    "Lupe",
    "Luppy",
    "Lupstein",
    "Luquet",
    "Lurais",
    "Luray",
    "Lurcy",
    "Lurcy-Levis",
    "Lure",
    "Lury-sur-Arnon",
    "Lus-la-Croix-Haute",
    "Lusanger",
    "Lusignan",
    "Lusignan-Grand",
    "Lusigny",
    "Lusigny-sur-Barse",
    "Lussac",
    "Lussac-les-Chateaux",
    "Lussac-les-Eglises",
    "Lussant",
    "Lussas",
    "Lussat",
    "Luttange",
    "Lutter",
    "Lutterbach",
    "Lutz-en-Dunois",
    "Lux",
    "Lux",
    "Luxe",
    "Luxeuil-les-Bains",
    "Luxey",
    "Luynes",
    "Luynes",
    "Luz-Saint-Sauveur",
    "Luzarches",
    "Luze",
    "Luzille",
    "Luzinay",
    "Luzy",
    "Lyaud",
    "Lynde",
    "Lyon",
    "Mably",
    "Macau",
    "Mache",
    "Machecoul",
    "Machemont",
    "Macheren",
    "Machezal",
    "Maclas",
    "Macot-la-Plagne",
    "Madirac",
    "Madre",
    "Madriat",
    "Mael-Carhaix",
    "Maennolsheim",
    "Maffliers",
    "Magagnosc",
    "Magalas",
    "Magenta",
    "Magescq",
    "Magland",
    "Magnan",
    "Magnanville",
    "Magne",
    "Magneux-Haute-Rive",
    "Magnieres",
    "Magny",
    "Magny-Cours",
    "Magny-en-Vexin",
    "Magny-le-Desert",
    "Magny-le-Hongre",
    "Magny-les-Hameaux",
    "Magny-les-Villers",
    "Magny-sur-Tille",
    "Magrie",
    "Magstatt-le-Haut",
    "Maiche",
    "Maidieres",
    "Maigne",
    "Maignelay-Montigny",
    "Maillane",
    "Maille",
    "Maillebois",
    "Mailly-Champagne",
    "Mailly-la-Ville",
    "Mailly-le-Camp",
    "Mailly-Maillet",
    "Mailly-Raineval",
    "Maincy",
    "Maing",
    "Maintenon",
    "Mainvilliers",
    "Mainxe",
    "Mairieux",
    "Mairy-sur-Marne",
    "Maisdon-sur-Sevre",
    "Maisnil",
    "Maisnil-les-Ruitz",
    "Maison-Ponthieu",
    "Maisons-Alfort",
    "Maisons-Laffitte",
    "Maisonsgoutte",
    "Maisontiers",
    "Maisse",
    "Maizeroy",
    "Maizieres-les-Metz",
    "Malabry",
    "Malafretaz",
    "Malain",
    "Malakoff",
    "Malancourt-la-Montagne",
    "Malange",
    "Malansac",
    "Malataverne",
    "Malaucene",
    "Malaumont",
    "Malaunay",
    "Malause",
    "Malaussanne",
    "Malay-le-Grand",
    "Malegoude",
    "Malemort-du-Comtat",
    "Malemort-sur-Correze",
    "Malesherbes",
    "Malestroit",
    "Maleville",
    "Malguenac",
    "Malicorne",
    "Malicorne-sur-Sarthe",
    "Malijai",
    "Malincourt",
    "Malintrat",
    "Malissard",
    "Malleloy",
    "Mallemoisson",
    "Mallemort",
    "Malling",
    "Malmerspach",
    "Malmont",
    "Malpas",
    "Malras",
    "Malrevers",
    "Malves-en-Minervois",
    "Malville",
    "Malzeville",
    "Mamers",
    "Mametz",
    "Mamey",
    "Mamirolle",
    "Manchecourt",
    "Mancieulles",
    "Mandagout",
    "Mandelieu-la-Napoule",
    "Mandeure",
    "Mandres-la-Cote",
    "Mandres-les-Roses",
    "Manduel",
    "Mane",
    "Maneglise",
    "Mangiennes",
    "Mangonville",
    "Manicamp",
    "Maninghen-Henne",
    "Manneville-es-Plains",
    "Mannevillette",
    "Manom",
    "Manoncourt-sur-Seille",
    "Manonviller",
    "Manosque",
    "Manot",
    "Mansigne",
    "Mansle",
    "Manspach",
    "Mantes-la-Jolie",
    "Mantes-la-Ville",
    "Manthelan",
    "Mantoche",
    "Mantry",
    "Manzat",
    "Manziat",
    "Marange-Silvange",
    "Marange-Zondrange",
    "Marans",
    "Maraussan",
    "Marbache",
    "Marc",
    "Marcamps",
    "Marcay",
    "Marce-sur-Esves",
    "Marcelcave",
    "Marcellaz-Albanais",
    "Marchainville",
    "Marchaux",
    "Marche-Allouarde",
    "Marcheprime",
    "Marchesieux",
    "Marcheville",
    "Marchiennes",
    "Marciac",
    "Marcigny",
    "Marcilhac-sur-Cele",
    "Marcillac",
    "Marcillac-la-Croisille",
    "Marcillat-en-Combraille",
    "Marcilloles",
    "Marcilly-en-Bassigny",
    "Marcilly-en-Beauce",
    "Marcilly-en-Villette",
    "Marcilly-sur-Eure",
    "Marcilly-sur-Seine",
    "Marcilly-sur-Tille",
    "Marcilly-sur-Vienne",
    "Marck",
    "Marckolsheim",
    "Marcoing",
    "Marcollin",
    "Marconnelle",
    "Marcoussis",
    "Marcouville",
    "Marcoux",
    "Marcq",
    "Marcq-en-Baroeul",
    "Marcq-en-Ostrevent",
    "Mardeuil",
    "Mardie",
    "Mardyck",
    "Mareau-aux-Bois",
    "Mareau-aux-Pres",
    "Mareil-en-France",
    "Mareil-Marly",
    "Mareil-sur-Loir",
    "Mareil-sur-Mauldre",
    "Marenla",
    "Marennes",
    "Marennes",
    "Marest",
    "Marestaing",
    "Mareuil",
    "Mareuil",
    "Mareuil-Caubert",
    "Mareuil-en-Brie",
    "Mareuil-le-Port",
    "Mareuil-les-Meaux",
    "Mareuil-sur-Cher",
    "Mareuil-sur-Lay-Dissais",
    "Mareuil-sur-Ourcq",
    "Margaux",
    "Margencel",
    "Margency",
    "Margerie-Chantagret",
    "Margny",
    "Margny-les-Compiegne",
    "Margon",
    "Marguerittes",
    "Margut",
    "Marie",
    "Marienthal",
    "Marignac",
    "Marignac-Lasclares",
    "Marignane",
    "Marigne",
    "Marignier",
    "Marigny",
    "Marigny",
    "Marigny-Brizay",
    "Marigny-Chemereau",
    "Marigny-en-Orxois",
    "Marigny-le-Chatel",
    "Marigny-les-Usages",
    "Marigny-Marmande",
    "Marin",
    "Marines",
    "Maringes",
    "Maringues",
    "Marle",
    "Marlenheim",
    "Marles-en-Brie",
    "Marles-les-Mines",
    "Marliens",
    "Marlioz",
    "Marlotte",
    "Marly",
    "Marly",
    "Marly-la-Ville",
    "Marly-le-Roi",
    "Marmagne",
    "Marmande",
    "Marmanhac",
    "Marmoutier",
    "Marnand",
    "Marnaz",
    "Marnes",
    "Marnes-la-Coquette",
    "Maroeuil",
    "Marolles",
    "Marolles-en-Brie",
    "Marolles-en-Hurepoix",
    "Marols",
    "Maromme",
    "Maron",
    "Maron",
    "Marpent",
    "Marquay",
    "Marquefave",
    "Marqueglise",
    "Marques",
    "Marquillies",
    "Marquion",
    "Marquise",
    "Marquixanes",
    "Mars",
    "Mars",
    "Mars-sur-Allier",
    "Marsac",
    "Marsac-en-Livradois",
    "Marsac-sur-lIsle",
    "Marsainvilliers",
    "Marsais",
    "Marsais-Sainte-Radegonde",
    "Marsaneix",
    "Marsangy",
    "Marsannay-le-Bois",
    "Marsanne",
    "Marsat",
    "Marsaz",
    "Marseillan",
    "Marseille",
    "Marseille-en-Beauvaisis",
    "Marseilles-les-Aubigny",
    "Marsillargues",
    "Marsilly",
    "Marsilly",
    "Marssac-sur-Tarn",
    "Martainville-Epreville",
    "Martel",
    "Marthod",
    "Martiel",
    "Martignas-sur-Jalle",
    "Martigne-Briand",
    "Martigne-Ferchaud",
    "Martigny",
    "Martigues",
    "Martillac",
    "Martin-Eglise",
    "Martinet",
    "Martinvast",
    "Martot",
    "Martres-Tolosane",
    "Marval",
    "Marvejols",
    "Marville-les-Bois",
    "Marzan",
    "Marzy",
    "Mas-Grenier",
    "Mas-Saintes-Puelles",
    "Masevaux",
    "Maslacq",
    "Maslives",
    "Masnieres",
    "Masny",
    "Masparraute",
    "Massat",
    "Masserac",
    "Masseret",
    "Massiac",
    "Massieu",
    "Massieux",
    "Massingy-les-Semur",
    "Massugas",
    "Massy",
    "Mastaing",
    "Matha",
    "Mathay",
    "Mathenay",
    "Mathieu",
    "Mathieu",
    "Matignon",
    "Matigny",
    "Matour",
    "Mattaincourt",
    "Mattexey",
    "Matzenheim",
    "Maubeuge",
    "Maubourguet",
    "Mauchamps",
    "Maucor",
    "Mauguio",
    "Maule",
    "Mauleon-Licharre",
    "Maulevrier",
    "Mauperthuis",
    "Mauprevoir",
    "Mauran",
    "Maure",
    "Maure-de-Bretagne",
    "Maurecourt",
    "Maureilhan",
    "Maureillas-las-Illas",
    "Mauremont",
    "Maurens",
    "Maurepas",
    "Mauressargues",
    "Maureville",
    "Mauriac",
    "Maurois",
    "Mauron",
    "Maurrin",
    "Maurs",
    "Maussane-les-Alpilles",
    "Mauves",
    "Mauves-sur-Huisne",
    "Mauves-sur-Loire",
    "Mauvezin-de-Prat",
    "Maux",
    "Mauzac",
    "Mauze-sur-le-Mignon",
    "Mauzens-et-Miremont",
    "Maxent",
    "Maxeville",
    "Maxilly-sur-Saone",
    "May-en-Multien",
    "May-sur-Orne",
    "Mayenne",
    "Mayet",
    "Maysel",
    "Mazamet",
    "Mazan",
    "Mazange",
    "Maze",
    "Mazeray",
    "Mazeres-de-Neste",
    "Mazeres-sur-Salat",
    "Mazerolles",
    "Mazerolles-le-Salin",
    "Mazet-Saint-Voy",
    "Mazeuil",
    "Mazieres-en-Gatine",
    "Mazieres-en-Mauges",
    "Mazingarbe",
    "Mazion",
    "Meaudre",
    "Meaulte",
    "Meaux",
    "Meauzac",
    "Medan",
    "Medis",
    "Medreac",
    "Mees",
    "Megrit",
    "Meharicourt",
    "Mehun-sur-Yevre",
    "Meigneux",
    "Meilhac",
    "Meilhan",
    "Meilhan",
    "Meilhan-sur-Garonne",
    "Meillant",
    "Meillerie",
    "Meillon",
    "Meillonnas",
    "Meisenthal",
    "Mejannes-le-Clap",
    "Mejannes-les-Ales",
    "Melay",
    "Melay",
    "Melesse",
    "Melgven",
    "Mellac",
    "Melle",
    "Mellecey",
    "Melleran",
    "Melleray",
    "Mellionnec",
    "Mello",
    "Melrand",
    "Melsheim",
    "Melun",
    "Membrey",
    "Menat",
    "Mende",
    "Mendionde",
    "Meneac",
    "Menerbes",
    "Menesplet",
    "Menestreau-en-Villette",
    "Menetou-Salon",
    "Menetreol-sur-Sauldre",
    "Menetrol",
    "Menil-Erreux",
    "Menil-Hermei",
    "Menil-la-Horgne",
    "Menil-sur-Belvitte",
    "Menilles",
    "Mennecy",
    "Mennevret",
    "Mens",
    "Mensignac",
    "Menthon-Saint-Bernard",
    "Menton",
    "Menucourt",
    "Menville",
    "Meounes-les-Montrieux",
    "Mer",
    "Mercenac",
    "Merckeghem",
    "Mercues",
    "Mercurol",
    "Mercy-le-Bas",
    "Merdrignac",
    "Mere",
    "Mereau",
    "Merenvielle",
    "Mereville",
    "Merey-sous-Montrond",
    "Mergey",
    "Mericourt",
    "Meriel",
    "Merignac",
    "Merignas",
    "Merignat",
    "Merignies",
    "Merigny",
    "Merindol",
    "Merlas",
    "Merlebach",
    "Merlevenez",
    "Merlimont",
    "Merlines",
    "Mernel",
    "Meroux",
    "Merry-la-Vallee",
    "Merry-sur-Yonne",
    "Mers-les-Bains",
    "Mertzen",
    "Mertzwiller",
    "Meru",
    "Mervans",
    "Merville",
    "Merville",
    "Merville-Franceville-Plage",
    "Merxheim",
    "Mery",
    "Mery-es-Bois",
    "Mery-sur-Oise",
    "Mery-sur-Seine",
    "Mesanger",
    "Mesangueville",
    "Meschers-sur-Gironde",
    "Mescoules",
    "Mesland",
    "Meslay",
    "Meslay-du-Maine",
    "Meslay-le-Vidame",
    "Meslin",
    "Mesnil-Raoul",
    "Mesnil-Saint-Georges",
    "Mesnil-Saint-Nicaise",
    "Mesnil-Sellieres",
    "Mesnil-sous-Vienne",
    "Mesplede",
    "Messac",
    "Messanges",
    "Messei",
    "Messein",
    "Messia-sur-Sorne",
    "Messigny-et-Vantoux",
    "Messimy",
    "Messon",
    "Messy",
    "Metabief",
    "Meteren",
    "Mettray",
    "Metz",
    "Metz-Tessy",
    "Metzeral",
    "Metzeresche",
    "Metzervisse",
    "Meucon",
    "Meudon",
    "Meulan-en-Yvelines",
    "Meung-sur-Loire",
    "Meurchin",
    "Meursault",
    "Meuse",
    "Meusnes",
    "Meuzac",
    "Mevoisins",
    "Meximieux",
    "Mexy",
    "Meyenheim",
    "Meylan",
    "Meymac",
    "Meynes",
    "Meyrargues",
    "Meyras",
    "Meyreuil",
    "Meyssac",
    "Meythet",
    "Meyzieu",
    "Meze",
    "Mezel",
    "Mezel",
    "Mezeres",
    "Mezeriat",
    "Mezidon-Canon",
    "Mezieres-en-Drouais",
    "Mezieres-lez-Clery",
    "Mezieres-sous-Lavardin",
    "Mezieres-sur-Couesnon",
    "Mezieres-sur-Seine",
    "Mezzavia",
    "Michelbach-le-Bas",
    "Mielan",
    "Miellin",
    "Mietesheim",
    "Mieussy",
    "Migennes",
    "Miglos",
    "Mignaloux-Beauvoir",
    "Migne-Auxances",
    "Mignieres",
    "Migron",
    "Milhaud",
    "Milizac",
    "Millac",
    "Millas",
    "Millau",
    "Millencourt",
    "Millery",
    "Millery",
    "Milly",
    "Milly-la-Foret",
    "Milly-Lamartine",
    "Milly-sur-Therain",
    "Milon-la-Chapelle",
    "Mimbaste",
    "Mimet",
    "Mimizan",
    "Mine de Bert",
    "Mingot",
    "Miniac-Morvan",
    "Miniac-sous-Becherel",
    "Minihy-Treguier",
    "Minversheim",
    "Minzier",
    "Mionnay",
    "Mions",
    "Mios",
    "Mirabel-aux-Baronnies",
    "Miradoux",
    "Miramas",
    "Mirambeau",
    "Miramont-de-Guyenne",
    "Miramont-Sensacq",
    "Mirande",
    "Mirebeau",
    "Mirebeau-sur-Beze",
    "Mirecourt",
    "Miremont",
    "Mirepeix",
    "Mirepoix",
    "Mirepoix-sur-Tarn",
    "Mireval-Lauragais",
    "Miribel",
    "Miserey",
    "Miserey-Salines",
    "Mison",
    "Misse",
    "Missillac",
    "Missiriac",
    "Misson",
    "Missy-sur-Aisne",
    "Mitry-Mory",
    "Mittelbronn",
    "Mittelhausbergen",
    "Mittelwihr",
    "Mitterand",
    "Mittersheim",
    "Modane",
    "Moelan-sur-Mer",
    "Moens",
    "Moernach",
    "Moeslains",
    "Mogneville",
    "Mogues",
    "Mohon",
    "Moineville",
    "Moinville-la-Jeulin",
    "Moirans",
    "Moirans-en-Montagne",
    "Moire",
    "Moisdon-la-Riviere",
    "Moislains",
    "Moissac",
    "Moissat",
    "Moisselles",
    "Moissieu-sur-Dolon",
    "Moissy-Cramayel",
    "Moisville",
    "Molac",
    "Molandier",
    "Molesme",
    "Moliens",
    "Molieres-sur-Ceze",
    "Moliets-et-Maa",
    "Molinet",
    "Molineuf",
    "Molleges",
    "Molliens-au-Bois",
    "Mollkirch",
    "Moloy",
    "Molsheim",
    "Moltifao",
    "Momas",
    "Mombrier",
    "Momeres",
    "Mommenheim",
    "Momy",
    "Monbahus",
    "Monbalen",
    "Monbrun",
    "Moncale",
    "Monce-en-Belin",
    "Monceau-le-Neuf-et-Faucouzy",
    "Monceau-le-Waast",
    "Monceau-Saint-Waast",
    "Monceaux",
    "Moncel-sur-Seille",
    "Moncetz-Longevas",
    "Moncheaux-les-Frevent",
    "Monchecourt",
    "Monchy-Breton",
    "Monchy-Humieres",
    "Monchy-Saint-Eloi",
    "Monclar-de-Quercy",
    "Moncontour",
    "Moncoutant",
    "Mondelange",
    "Mondescourt",
    "Mondetour",
    "Mondeville",
    "Mondeville",
    "Mondon",
    "Mondonville",
    "Mondragon",
    "Mondrainville",
    "Mondrepuis",
    "Monein",
    "Monestier-de-Clermont",
    "Monesties",
    "Monetay-sur-Loire",
    "Moneteau",
    "Monferran-Plaves",
    "Monferran-Saves",
    "Monflanquin",
    "Monistrol-sur-Loire",
    "Monnaie",
    "Monnetier-Mornex",
    "Monneville",
    "Monnieres",
    "Monpazier",
    "Mons",
    "Mons",
    "Mons",
    "Mons",
    "Mons-en-Baroeul",
    "Mons-en-Montois",
    "Mons-en-Pevele",
    "Monsac",
    "Monsegur",
    "Monsempron-Libos",
    "Monsireigne",
    "Monsteroux-Milieu",
    "Mont",
    "Mont",
    "Mont",
    "Mont-Bonvillers",
    "Mont-Cauvaire",
    "Mont-de-Marsan",
    "Mont-Notre-Dame",
    "Mont-pres-Chambord",
    "Mont-Saint-Aignan",
    "Mont-Saint-Martin",
    "Mont-Saint-Martin",
    "Mont-Saint-Pere",
    "Mont-Saxonnex",
    "Mont-sur-Meurthe",
    "Mont-sur-Monnet",
    "Montabard",
    "Montady",
    "Montagnac",
    "Montagnat",
    "Montagne",
    "Montagney",
    "Montagnieu",
    "Montagnole",
    "Montagny-en-Vexin",
    "Montagny-les-Lanches",
    "Montagny-les-Seurre",
    "Montagny-Sainte-Felicite",
    "Montagny-sur-Grosne",
    "Montagrier",
    "Montagut",
    "Montaigu",
    "Montaigu",
    "Montaigu",
    "Montaigu-de-Quercy",
    "Montaigut-sur-Save",
    "Montainville",
    "Montalet-le-Bois",
    "Montalieu-Vercieu",
    "Montamise",
    "Montanay",
    "Montardon",
    "Montargis",
    "Montarlot-les-Rioz",
    "Montarnaud",
    "Montastruc-de-Salies",
    "Montastruc-la-Conseillere",
    "Montataire",
    "Montauban",
    "Montauban-de-Bretagne",
    "Montaud",
    "Montaudin",
    "Montaulieu",
    "Montaure",
    "Montauroux",
    "Montaut",
    "Montaut",
    "Montayral",
    "Montbard",
    "Montbartier",
    "Montbazin",
    "Montbazon",
    "Montbel",
    "Montbellet",
    "Montberon",
    "Montbert",
    "Montbeton",
    "Montbeugny",
    "Montbizot",
    "Montblanc",
    "Montboillon",
    "Montbonnot-Saint-Martin",
    "Montboucher-sur-Jabron",
    "Montbouton",
    "Montbrison",
    "Montbron",
    "Montbronn",
    "Montbrun-Bocage",
    "Montcaret",
    "Montcarra",
    "Montceau",
    "Montceau-les-Mines",
    "Montceaux",
    "Montceaux-les-Meaux",
    "Montcel",
    "Montcenis",
    "Montcey",
    "Montchamp",
    "Montchanin",
    "Montchaude",
    "Montclar",
    "Montcornet",
    "Montcourt-Fromonville",
    "Montcoy",
    "Montcuq",
    "Montcy-Notre-Dame",
    "Montdidier",
    "Montdoumerc",
    "Montdragon",
    "Monteaux",
    "Montebourg",
    "Montech",
    "Montecheroux",
    "Monteglin",
    "Monteils",
    "Montel-de-Gelat",
    "Montelier",
    "Montemboeuf",
    "Montendre",
    "Montenescourt",
    "Montenois",
    "Montenoison",
    "Monterblanc",
    "Montereau-Fault-Yonne",
    "Monterfil",
    "Montescot",
    "Montesquieu",
    "Montesquieu-des-Alberes",
    "Montesquieu-Volvestre",
    "Montessaux",
    "Montesson",
    "Montestruc-sur-Gers",
    "Montestrucq",
    "Monteux",
    "Montevrain",
    "Monteynard",
    "Montfarville",
    "Montfaucon",
    "Montfaucon",
    "Montfaucon",
    "Montfaucon-en-Velay",
    "Montfaucon-Montigne",
    "Montfavet",
    "Montfermeil",
    "Montfermy",
    "Montferrand-du-Perigord",
    "Montferrand-le-Chateau",
    "Montferrat",
    "Montferrier-sur-Lez",
    "Montfleur",
    "Montfort",
    "Montfort-en-Chalosse",
    "Montfort-sur-Meu",
    "Montfrin",
    "Montfroc",
    "Montfuron",
    "Montgaillard",
    "Montgaroult",
    "Montgermont",
    "Montgeron",
    "Montgesty",
    "Montgirod",
    "Montgiscard",
    "Montgivray",
    "Montherme",
    "Monthieux",
    "Monthodon",
    "Monthou-sur-Bievre",
    "Monthou-sur-Cher",
    "Monthureux-sur-Saone",
    "Monthyon",
    "Monticello",
    "Montier-en-Der",
    "Montierchaume",
    "Montiers",
    "Montignac-de-Lauzun",
    "Montignac-le-Coq",
    "Montigne-le-Brillant",
    "Montigne-les-Rairies",
    "Montigny",
    "Montigny",
    "Montigny-en-Cambresis",
    "Montigny-en-Gohelle",
    "Montigny-le-Bretonneux",
    "Montigny-le-Chartif",
    "Montigny-Lengrain",
    "Montigny-les-Cormeilles",
    "Montigny-sur-Loing",
    "Montilly",
    "Montilly-sur-Noireau",
    "Montiron",
    "Montivilliers",
    "Montjavoult",
    "Montjean-sur-Loire",
    "Montlhery",
    "Montliard",
    "Montlieu-la-Garde",
    "Montlignon",
    "Montlivault",
    "Montlouis",
    "Montlouis-sur-Loire",
    "Montluel",
    "Montmacq",
    "Montmagny",
    "Montmalin",
    "Montmarault",
    "Montmartin",
    "Montmartin-en-Graignes",
    "Montmartin-sur-Mer",
    "Montmelian",
    "Montmerle-sur-Saone",
    "Montmeyran",
    "Montmirail",
    "Montmiral",
    "Montmirat",
    "Montmoreau-Saint-Cybard",
    "Montmorency",
    "Montmorency-Beaufort",
    "Montmorillon",
    "Montmorot",
    "Montoir-de-Bretagne",
    "Montoire-sur-le-Loir",
    "Montois-la-Montagne",
    "Montoison",
    "Montolivet",
    "Montournais",
    "Montpellier",
    "Montpeyroux",
    "Montpeyroux",
    "Montpezat",
    "Montpezat-de-Quercy",
    "Montpezat-sous-Bauzon",
    "Montpincon",
    "Montpinier",
    "Montpitol",
    "Montpon-Menesterol",
    "Montpothier",
    "Montrabe",
    "Montreal",
    "Montreal",
    "Montreal-la-Cluse",
    "Montredon-des-Corbieres",
    "Montredon-Labessonnie",
    "Montrejeau",
    "Montrelais",
    "Montrem",
    "Montrequienne",
    "Montresor",
    "Montret",
    "Montreuil",
    "Montreuil",
    "Montreuil-aux-Lions",
    "Montreuil-Bonnin",
    "Montreuil-en-Caux",
    "Montreuil-sous-Perouse",
    "Montreuil-sur-Blaise",
    "Montreuil-sur-Breche",
    "Montreuil-sur-Lozon",
    "Montreuil-sur-Mer",
    "Montreux-Jeune",
    "Montrevel",
    "Montrevel-en-Bresse",
    "Montrichard",
    "Montricoux",
    "Montrodat",
    "Montrond",
    "Montrond",
    "Montrond-les-Bains",
    "Montrouge",
    "Montrouveau",
    "Montry",
    "Monts",
    "Monts-en-Bessin",
    "Monts-sur-Guesnes",
    "Montsalvy",
    "Montsauche-les-Settons",
    "Montsegur-sur-Lauzon",
    "Montseveroux",
    "Montsoreau",
    "Montsoult",
    "Montussan",
    "Montvalen",
    "Montvalent",
    "Montvendre",
    "Montverdun",
    "Montville",
    "Moon-sur-Elle",
    "Moosch",
    "Moragne",
    "Morainvilliers",
    "Morance",
    "Morancez",
    "Morangis",
    "Morangis",
    "Morbecque",
    "Morbier",
    "Morcenx",
    "Mordelles",
    "Moreac",
    "Moree",
    "Morelmaison",
    "Morestel",
    "Moret-sur-Loing",
    "Moreuil",
    "Morez",
    "Morgny",
    "Morgny-la-Pommeraye",
    "Morhange",
    "Morienval",
    "Morieres-les-Avignon",
    "Morieux",
    "Morigny-Champigny",
    "Morisel",
    "Moriville",
    "Morlaas",
    "Morlaix",
    "Mormant",
    "Mornac",
    "Mornac-sur-Seudre",
    "Mornant",
    "Mornay",
    "Mornay-sur-Allier",
    "Moroges",
    "Morogues",
    "Morre",
    "Morsain",
    "Morsang-sur-Orge",
    "Morsbach",
    "Morsbronn-les-Bains",
    "Morschwiller-le-Bas",
    "Mortagne",
    "Mortagne-au-Perche",
    "Mortagne-du-Nord",
    "Mortagne-sur-Sevre",
    "Mortain",
    "Mortcerf",
    "Morteau",
    "Morteaux-Couliboeuf",
    "Mortefontaine",
    "Mortefontaine-en-Thelle",
    "Mortree",
    "Mortzwiller",
    "Morvillers-Saint-Saturnin",
    "Morvilliers",
    "Morzine",
    "Moslins",
    "Mosnac",
    "Mosson",
    "Motteville",
    "Mouans-Sartoux",
    "Mouaze",
    "Mouchin",
    "Mouflers",
    "Mougins",
    "Mougon",
    "Mouguerre",
    "Mouilleron-en-Pareds",
    "Mouilleron-le-Captif",
    "Mouledous",
    "Moules",
    "Mouliets-et-Villemartin",
    "Moulin",
    "Moulin-Mage",
    "Moulin-Neuf",
    "Moulin-sous-Touvent",
    "Moulineaux",
    "Moulines",
    "Moulinet",
    "Moulinet",
    "Moulins",
    "Moulins-Engilbert",
    "Moulins-les-Metz",
    "Moulins-sur-Cephons",
    "Moulins-sur-Yevre",
    "Moulle",
    "Moult",
    "Moumour",
    "Mourenx",
    "Moureze",
    "Mouries",
    "Mourioux-Vieilleville",
    "Mourmelon-le-Grand",
    "Mourmelon-le-Petit",
    "Mouroux",
    "Mours",
    "Mours-Saint-Eusebe",
    "Moussan",
    "Mousseaux-Neuville",
    "Moussey",
    "Mousson",
    "Moussoulens",
    "Moussy",
    "Moussy-le-Neuf",
    "Moussy-le-Vieux",
    "Mousteru",
    "Moustier-en-Fagne",
    "Mouthe",
    "Mouthier-en-Bresse",
    "Mouthiers-sur-Boeme",
    "Moutiers",
    "Moutiers",
    "Moutiers-les-Mauxfaits",
    "Moutiers-sur-le-Lay",
    "Mouvaux",
    "Mouxy",
    "Mouy",
    "Mouzeil",
    "Mouzieys-Teulet",
    "Mouzillon",
    "Mouzon",
    "Moyaux",
    "Moyencourt",
    "Moyenmoutier",
    "Moyenneville",
    "Moyenneville",
    "Moyeuvre-Grande",
    "Moyon",
    "Moyrazes",
    "Moyvillers",
    "Mozac",
    "Muespach-le-Haut",
    "Muides-sur-Loire",
    "Muidorge",
    "Muirancourt",
    "Muizon",
    "Mulcent",
    "Mulcey",
    "Mulhouse",
    "Mulsanne",
    "Munchhouse",
    "Mundolsheim",
    "Munster",
    "Munster",
    "Mur-de-Bretagne",
    "Mur-de-Sologne",
    "Murat",
    "Mures",
    "Muret",
    "Murianette",
    "Muro",
    "Murol",
    "Muron",
    "Murs-Erigne",
    "Murviel-les-Beziers",
    "Murviel-les-Montpellier",
    "Mus",
    "Mussidan",
    "Mussig",
    "Mutzig",
    "Muzillac",
    "Nadillac",
    "Nages-et-Solorgues",
    "Nailloux",
    "Naintre",
    "Naizin",
    "Najac",
    "Nalliers",
    "Nancy",
    "Nandy",
    "Nangis",
    "Nans-les-Pins",
    "Nanterre",
    "Nantes",
    "Nanteuil-en-Vallee",
    "Nanteuil-le-Haudouin",
    "Nanteuil-les-Meaux",
    "Nantiat",
    "Nantoin",
    "Nanton",
    "Nantua",
    "Naours",
    "Narbonne",
    "Narbonne-Plage",
    "Nargis",
    "Narrosse",
    "Nattages",
    "Naucelle",
    "Naujan-et-Postiac",
    "Naux",
    "Navailles-Angos",
    "Navarrenx",
    "Naves",
    "Nay",
    "Nayemont-les-Fosses",
    "Nazelles-Negron",
    "Neauphle-le-Chateau",
    "Neauphlette",
    "Nebian",
    "Nebouzat",
    "Nedonchel",
    "Neewiller-pres-Lauterbourg",
    "Neffes",
    "Neffies",
    "Nefiach",
    "Negrepelisse",
    "Negron",
    "Nehou",
    "Nemours",
    "Neoules",
    "Nercillac",
    "Nere",
    "Neris-les-Bains",
    "Neron",
    "Neronde-sur-Dore",
    "Ners",
    "Nersac",
    "Nerville-la-Foret",
    "Nery",
    "Nesle",
    "Nesles",
    "Nesles-la-Montagne",
    "Nesles-la-Vallee",
    "Nesmy",
    "Nessa",
    "Neuf Eglise",
    "Neuf-Brisach",
    "Neufchateau",
    "Neufchatel-en-Bray",
    "Neufchef",
    "Neufgrange",
    "Neuflize",
    "Neufmanil",
    "Neufmesnil",
    "Neufmoutiers-en-Brie",
    "Neufvy-sur-Aronde",
    "Neuil",
    "Neuille",
    "Neuilly",
    "Neuilly-en-Thelle",
    "Neuilly-le-Bisson",
    "Neuilly-le-Real",
    "Neuilly-les-Dijon",
    "Neuilly-Plaisance",
    "Neuilly-Saint-Front",
    "Neuilly-sous-Clermont",
    "Neuilly-sur-Marne",
    "Neuilly-sur-Seine",
    "Neulliac",
    "Neung-sur-Beuvron",
    "Neure",
    "Neussargues-Moissac",
    "Neuve-Maison",
    "Neuvecelle",
    "Neuves-Maisons",
    "Neuvic",
    "Neuvic-Entier",
    "Neuvicq-le-Chateau",
    "Neuville",
    "Neuville",
    "Neuville-aux-Bois",
    "Neuville-Bosc",
    "Neuville-de-Poitou",
    "Neuville-en-Ferrain",
    "Neuville-Ferrieres",
    "Neuville-les-Dames",
    "Neuville-les-Dieppe",
    "Neuville-Saint-Remy",
    "Neuville-Saint-Vaast",
    "Neuville-sur-Ain",
    "Neuville-sur-Brenne",
    "Neuville-sur-Escaut",
    "Neuville-sur-Oise",
    "Neuville-sur-Saone",
    "Neuville-sur-Sarthe",
    "Neuville-sur-Seine",
    "Neuviller-sur-Moselle",
    "Neuvireuil",
    "Neuvy",
    "Neuvy",
    "Neuvy-Bouin",
    "Neuvy-en-Beauce",
    "Neuvy-en-Sullias",
    "Neuvy-le-Roi",
    "Neuvy-Saint-Sepulchre",
    "Nevers",
    "Nevez",
    "Nevian",
    "Neville",
    "Nexon",
    "Neydens",
    "Nibas",
    "Nibelle",
    "Nice",
    "Nicole",
    "Nicorps",
    "Niderhoff",
    "Niderviller",
    "Niederbronn-les-Bains",
    "Niederbruck",
    "Niederentzen",
    "Niederhaslach",
    "Niederhausbergen",
    "Niedermodern",
    "Niederschaeffolsheim",
    "Nieppe",
    "Niergnies",
    "Nieuil",
    "Nieul-le-Virouil",
    "Nieul-les-Saintes",
    "Nieul-sur-Mer",
    "Nievroz",
    "Niffer",
    "Niherne",
    "Nilvange",
    "Niort",
    "Nissan-lez-Enserune",
    "Nitting",
    "Nivillac",
    "Nivolas-Vermelle",
    "Nizas",
    "Noailhac",
    "Noaillac",
    "Noaillan",
    "Noailles",
    "Noailles",
    "Noe",
    "Noeux-les-Auxi",
    "Noeux-les-Mines",
    "Nogent",
    "Nogent-le-Phaye",
    "Nogent-le-Roi",
    "Nogent-le-Rotrou",
    "Nogent-sur-Eure",
    "Nogent-sur-Marne",
    "Nogent-sur-Oise",
    "Nogent-sur-Seine",
    "Nogent-sur-Vernisson",
    "Nohanent",
    "Nohic",
    "Noidans-le-Ferroux",
    "Noidans-les-Vesoul",
    "Noilhan",
    "Nointel",
    "Nointot",
    "Noiron",
    "Noiron-sous-Gevrey",
    "Noirterre",
    "Noiseau",
    "Noisiel",
    "Noisseville",
    "Noisy-le-Grand",
    "Noisy-le-Roi",
    "Noisy-le-Sec",
    "Noisy-Rudignon",
    "Noisy-sur-Ecole",
    "Noisy-sur-Oise",
    "Nolay",
    "Nomain",
    "Nomeny",
    "Nomexy",
    "Nommay",
    "Nonancourt",
    "Nonette",
    "Nonneville",
    "Nontron",
    "Nonville",
    "Nonvilliers-Grandhoux",
    "Noordpeene",
    "Nordausques",
    "Nordhouse",
    "Norges-la-Ville",
    "Normanville",
    "Normier",
    "Norrent-Fontes",
    "Norrey-en-Auge",
    "Norroy-le-Sec",
    "Norroy-le-Veneur",
    "Norroy-les-Pont-a-Mousson",
    "Nort-sur-Erdre",
    "Nostang",
    "Nothalten",
    "Notre-Dame-de-Boisset",
    "Notre-Dame-de-Bondeville",
    "Notre-Dame-de-Cenilly",
    "Notre-Dame-de-Commiers",
    "Notre-Dame-de-Gravenchon",
    "Notre-Dame-de-la-Rouviere",
    "Notre-Dame-de-Livaye",
    "Notre-Dame-de-Livoye",
    "Notre-Dame-de-Mesage",
    "Notre-Dame-de-Riez",
    "Notre-Dame-de-Sanilhac",
    "Notre-Dame-des-Landes",
    "Notre-Dame-du-Pre",
    "Nouaille-Maupertuis",
    "Nouainville",
    "Nouan-le-Fuzelier",
    "Noueilles",
    "Nouilly",
    "Nousseviller-les-Bitche",
    "Nousseviller-Saint-Nabor",
    "Nouvelle-Eglise",
    "Nouvion-le-Comte",
    "Nouvion-le-Vineux",
    "Nouvion-sur-Meuse",
    "Nouvoitou",
    "Nouzonville",
    "Novalaise",
    "Noveant-sur-Moselle",
    "Noves",
    "Novillard",
    "Novillars",
    "Novion-Porcien",
    "Noyal-Muzillac",
    "Noyal-Pontivy",
    "Noyal-sur-Vilaine",
    "Noyant-de-Touraine",
    "Noyant-la-Gravoyere",
    "Noyant-la-Plaine",
    "Noyarey",
    "Noyelles-Godault",
    "Noyelles-sous-Lens",
    "Noyelles-sur-Escaut",
    "Noyen-sur-Sarthe",
    "Noyers",
    "Noyers",
    "Noyers-Bocage",
    "Noyers-sur-Cher",
    "Noyon",
    "Nozay",
    "Nozay",
    "Nuaille",
    "Nuaille-sur-Boutonne",
    "Nueil-sur-Layon",
    "Nuelles",
    "Nuits-Saint-Georges",
    "Nyoiseau",
    "Nyons",
    "Obenheim",
    "Oberbronn",
    "Oberentzen",
    "Oberhaslach",
    "Oberhausbergen",
    "Oberhergheim",
    "Oberhoffen-sur-Moder",
    "Oberlauterbach",
    "Obermodern-Zutzendorf",
    "Obermorschwihr",
    "Obermorschwiller",
    "Obernai",
    "Obersaasheim",
    "Oberschaeffolsheim",
    "Objat",
    "Oblinghem",
    "Obtree",
    "Ochey",
    "Octeville",
    "Octeville-sur-Mer",
    "Oderen",
    "Odomez",
    "Odos",
    "Oelleville",
    "OEting",
    "Oeyreluy",
    "Offekerque",
    "Offemont",
    "Offendorf",
    "Offoy",
    "Offranville",
    "Ogenne-Camptort",
    "Oger",
    "Ogeu-les-Bains",
    "Ogeviller",
    "Ogy",
    "Oignies",
    "Oingt",
    "Oinville-sur-Montcient",
    "Oiron",
    "Oiselay-et-Grachaux",
    "Oisemont",
    "Oisly",
    "Oisseau",
    "Oissel",
    "Oisy",
    "Oisy-le-Verger",
    "Oizon",
    "Olargues",
    "Olby",
    "Olemps",
    "Oletta",
    "Olivet",
    "Ollainville",
    "Olle",
    "Ollezy",
    "Ollieres",
    "Olliergues",
    "Ollioules",
    "Olloix",
    "Olmet",
    "Olonne-sur-Mer",
    "Olonzac",
    "Oloron-Sainte-Marie",
    "Olwisheim",
    "Omessa",
    "Omonville-la-Rogue",
    "Ondes",
    "Ondres",
    "Ondreville-sur-Essonne",
    "Onet-le-Chateau",
    "Onnaing",
    "Onnion",
    "Ons-en-Bray",
    "Onzain",
    "Opio",
    "Oppede",
    "Optevoz",
    "Oradour",
    "Oradour-Fanais",
    "Oradour-sur-Glane",
    "Oraison",
    "Orangis",
    "Orban",
    "Orbec",
    "Orbeil",
    "Orbey",
    "Orcet",
    "Orchaise",
    "Orchamps-Vennes",
    "Orches",
    "Orchies",
    "Orcier",
    "Orcines",
    "Ordan-Larroque",
    "Ordiarp",
    "Oregue",
    "Oresmaux",
    "Orgelet",
    "Orgeres",
    "Orgerus",
    "Orgeval",
    "Orgon",
    "Orgueil",
    "Orignolles",
    "Origny-en-Thierache",
    "Origny-le-Sec",
    "Origny-Sainte-Benoite",
    "Oriol-en-Royans",
    "Orion",
    "Orleat",
    "Orlienas",
    "Orlu",
    "Orly",
    "Ormersviller",
    "Ormes",
    "Ormes-et-Ville",
    "Ormesson-sur-Marne",
    "Ormoy",
    "Ormoy",
    "Ormoy-Villers",
    "Ornacieux",
    "Ornans",
    "Ornex",
    "Orny",
    "Orphin",
    "Orrouer",
    "Orrouy",
    "Orry-la-Ville",
    "Orsan",
    "Orsanco",
    "Orsonville",
    "Orthez",
    "Ortoncourt",
    "Orval",
    "Orval",
    "Orvault",
    "Orvaux",
    "Orveau-Bellesauve",
    "Orvillers-Sorel",
    "Osne-le-Val",
    "Osny",
    "Ossages",
    "Osse",
    "Osses",
    "Ossun",
    "Ostheim",
    "Ostricourt",
    "Ostwald",
    "Ota",
    "Othis",
    "Ottange",
    "Ottmarsheim",
    "Ottonville",
    "Ottrott",
    "Ouanne",
    "Ouarville",
    "Ouchamps",
    "Oucques",
    "Oudon",
    "Ouerre",
    "Ouezy",
    "Ouffieres",
    "Ougney",
    "Ouhans",
    "Ouilly-le-Tesson",
    "Ouilly-le-Vicomte",
    "Ouistreham",
    "Oulchy-le-Chateau",
    "Oullins",
    "Oupia",
    "Ourches",
    "Ouroux",
    "Ouroux-sur-Saone",
    "Oursbelille",
    "Ousse",
    "Ousson-sur-Loire",
    "Outarville",
    "Outille",
    "Outines",
    "Outreau",
    "Ouveillan",
    "Ouville",
    "Ouville",
    "Ouzilly",
    "Ouzouer-le-Marche",
    "Ouzouer-sur-Loire",
    "Ouzouer-sur-Trezee",
    "Ovanches",
    "Ovillers-la-Boisselle",
    "Oye-Plage",
    "Oyeu",
    "Oyonnax",
    "Oytier-Saint-Oblas",
    "Ozan",
    "Ozoir-la-Ferriere",
    "Ozolles",
    "Ozouer-le-Voulgis",
    "Pabu",
    "Pace",
    "Pact",
    "Pacy-sur-Eure",
    "Pageas",
    "Pagny-la-Ville",
    "Pagny-sur-Meuse",
    "Pagny-sur-Moselle",
    "Paillart",
    "Paillencourt",
    "Paillet",
    "Paimboeuf",
    "Paimpol",
    "Paimpont",
    "Painblanc",
    "Pair-et-Grandrupt",
    "Paix",
    "Paizay-le-Chapt",
    "Paizay-le-Sec",
    "Paizay-le-Tort",
    "Palaiseau",
    "Palaja",
    "Palalda",
    "Palaminy",
    "Palau-del-Vidre",
    "Palavas-les-Flots",
    "Paleyrac",
    "Palinges",
    "Palleau",
    "Palluau",
    "Palluau-sur-Indre",
    "Pallud",
    "Pamfou",
    "Pamiers",
    "Pamproux",
    "Panassac",
    "Panazol",
    "Panissieres",
    "Pannece",
    "Pannes",
    "Panossas",
    "Pantin",
    "Panzoult",
    "Paradou",
    "Paray-le-Monial",
    "Paray-sous-Briailles",
    "Paray-Vieille-Poste",
    "Paraza",
    "Parcay-Meslay",
    "Parce-sur-Sarthe",
    "Parcieux",
    "Pareds",
    "Parempuyre",
    "Parent",
    "Parentis-en-Born",
    "Pargny-Filain",
    "Pargny-la-Dhuys",
    "Pargny-les-Bois",
    "Pargny-sur-Saulx",
    "Parigne",
    "Parigne-sur-Braye",
    "Parigny",
    "Paris",
    "Paris 17 Batignolles-Monceau",
    "Parisot",
    "Parly",
    "Parmain",
    "Parnans",
    "Parnay",
    "Parne-sur-Roc",
    "Paron",
    "Parsac",
    "Parthenay",
    "Parthenay-de-Bretagne",
    "Parzac",
    "Pas-en-Artois",
    "Paslieres",
    "Pasly",
    "Pasques",
    "Passel",
    "Passy",
    "Patay",
    "Pau",
    "Pauilhac",
    "Pauillac Haut",
    "Paul",
    "Paulhac",
    "Paulhaguet",
    "Paulhan",
    "Paulx",
    "Pavie",
    "Pavilly",
    "Payrac",
    "Payrin-Augmontel",
    "Payroux",
    "Payzac",
    "Payzac",
    "Peaugres",
    "Peaule",
    "Pebrac",
    "Pechabou",
    "Pechbonnieu",
    "Pecquencourt",
    "Pecqueuse",
    "Pegomas",
    "Peillac",
    "Peille",
    "Peillon",
    "Peillonnex",
    "Peipin",
    "Peisey-Nancroix",
    "Pelissanne",
    "Pellegrue",
    "Pellevoisin",
    "Pellouailles-les-Vignes",
    "Pelousey",
    "Peltre",
    "Pelussin",
    "Penchard",
    "Pencran",
    "Pende",
    "Penestin",
    "Penguily",
    "Penmarch",
    "Pennautier",
    "Penne-dAgenais",
    "Pennedepie",
    "Penol",
    "Penta-di-Casinca",
    "Penvenan",
    "Pepieux",
    "Peray",
    "Percy",
    "Pere",
    "Perenchies",
    "Peret",
    "Peri",
    "Periers",
    "Perignac",
    "Perignat-les-Sarlieve",
    "Perigne",
    "Perigneux",
    "Perigny",
    "Perigny",
    "Perillos",
    "Pern",
    "Pernay",
    "Pernes",
    "Pernes-les-Boulogne",
    "Pernes-les-Fontaines",
    "Perols",
    "Perols",
    "Peron",
    "Peronnas",
    "Peronne",
    "Peronne",
    "Peronne-en-Melantois",
    "Perouse",
    "Peroy-les-Gombries",
    "Perpezac-le-Noir",
    "Perpignan",
    "Perrecy-les-Forges",
    "Perreux",
    "Perreux",
    "Perrex",
    "Perrier",
    "Perriers-sur-Andelle",
    "Perrignier",
    "Perrigny",
    "Perrigny-les-Dijon",
    "Perrogney-les-Fontaines",
    "Perros-Guirec",
    "Perrou",
    "Perruel",
    "Pers",
    "Pers-Jussy",
    "Persan",
    "Persquen",
    "Perthes-les-Brienne",
    "Pertheville-Ners",
    "Pertuis",
    "Pessac",
    "Pessac-sur-Dordogne",
    "Pessan",
    "Pessans",
    "Pessat-Villeneuve",
    "Petit Chaumont",
    "Petit-Couronne",
    "Petit-Landau",
    "Petit-Mars",
    "Petit-Palais-et-Cornemps",
    "Petite-Foret",
    "Petite-Rosselle",
    "Petiville",
    "Petreto-Bicchisano",
    "Peumerit",
    "Pexonne",
    "Pey",
    "Peymeinade",
    "Peynier",
    "Peypin",
    "Peyrat-le-Chateau",
    "Peyrehorade",
    "Peyriac-de-Mer",
    "Peyriac-Minervois",
    "Peyrignac",
    "Peyrilhac",
    "Peyrilles",
    "Peyrins",
    "Peyrissas",
    "Peyrolles-en-Provence",
    "Peyruis",
    "Peyrus",
    "Peyrusse-Massas",
    "Pezenas",
    "Pezens",
    "Pezilla-la-Riviere",
    "Pezou",
    "Pfaffenheim",
    "Pfaffenhoffen",
    "Pfastatt",
    "Pfettisheim",
    "Pfulgriesheim",
    "Phaffans",
    "Phalempin",
    "Phalsbourg",
    "Pia",
    "Piazza",
    "Piblange",
    "Pibrac",
    "Picauville",
    "Picquigny",
    "Piegros-la-Clastre",
    "Piegut-Pluviers",
    "Piennes",
    "Pierre",
    "Pierre-Benite",
    "Pierre-de-Bresse",
    "Pierre-la-Treiche",
    "Pierre-Levee",
    "Pierrefeu-du-Var",
    "Pierrefitte-Nestalas",
    "Pierrefitte-sur-Seine",
    "Pierrefonds",
    "Pierrefort",
    "Pierregot",
    "Pierrelatte",
    "Pierrelaye",
    "Pierremande",
    "Pierrepont-sur-Avre",
    "Pierrerue",
    "Pierres",
    "Pierrevert",
    "Pierrevillers",
    "Pierric",
    "Pierry",
    "Pietralba",
    "Pieusse",
    "Pignan",
    "Pignans",
    "Pignols",
    "Pihem",
    "Pimbo",
    "Pimprez",
    "Pin",
    "Pinet",
    "Pineuilh",
    "Piney",
    "Pinon",
    "Pinsaguel",
    "Piolenc",
    "Pionsat",
    "Pipriac",
    "Piquecos",
    "Pire-sur-Seiche",
    "Pirey",
    "Pirou",
    "Piscop",
    "Piseux",
    "Pissos",
    "Pissotte",
    "Pissy-Poville",
    "Pithiviers",
    "Pitres",
    "Pizay",
    "Plabennec",
    "Place",
    "Plailly",
    "Plaimpied-Givaudins",
    "Plaine-de-Walsch",
    "Plaine-Haute",
    "Plaintel",
    "Plaisance",
    "Plaisance-du-Touch",
    "Plaisir",
    "Plan-de-Cuques",
    "Planay",
    "Plancher-Bas",
    "Planches",
    "Planguenoual",
    "Planzolles",
    "Plappeville",
    "Plassay",
    "Plateau",
    "Plaudren",
    "Plauzat",
    "Pleboulle",
    "Plechatel",
    "Pledran",
    "Plehedel",
    "Plelan-le-Grand",
    "Plelan-le-Petit",
    "Plelauff",
    "Plelo",
    "Plemet",
    "Plenee-Jugon",
    "Pleneuf-Val-Andre",
    "Plerguer",
    "Plerin",
    "Plerneuf",
    "Plescop",
    "Plesidy",
    "Plesnois",
    "Plessala",
    "Plesse",
    "Plessis-Barbuise",
    "Plessis-de-Roye",
    "Plestan",
    "Plestin-les-Greves",
    "Pleucadeuc",
    "Pleudihen-sur-Rance",
    "Pleugriffet",
    "Pleugueneuc",
    "Pleumartin",
    "Pleumeleuc",
    "Pleumeur-Bodou",
    "Pleure",
    "Pleurs",
    "Pleurtuit",
    "Pleuven",
    "Pleyben",
    "Pleyber-Christ",
    "Plobannalec-Lesconil",
    "Plobsheim",
    "Ploemel",
    "Ploemeur",
    "Ploerdut",
    "Ploeren",
    "Ploermel",
    "Ploeuc-sur-Lie",
    "Ploeven",
    "Plogonnec",
    "Plomb",
    "Plombieres-les-Bains",
    "Plombieres-les-Dijon",
    "Plomelin",
    "Plomeur",
    "Plonevez-du-Faou",
    "Plorec-sur-Arguenon",
    "Plottes",
    "Plouagat",
    "Plouaret",
    "Plouarzel",
    "Plouasne",
    "Plouay",
    "Ploubalay",
    "Ploubazlanec",
    "Ploubezre",
    "Ploudalmezeau",
    "Ploudaniel",
    "Plouec-du-Trieux",
    "Plouedern",
    "Plouer-sur-Rance",
    "Plouescat",
    "Plouezec",
    "Ploufragan",
    "Plougar",
    "Plougasnou",
    "Plougastel-Daoulas",
    "Plougonvelin",
    "Plougonven",
    "Plougoulm",
    "Plougoumelen",
    "Plougourvest",
    "Plougrescant",
    "Plouguerneau",
    "Plouguin",
    "Plouha",
    "Plouharnel",
    "Plouigneau",
    "Ploumagoar",
    "Ploumilliau",
    "Ploumoguer",
    "Plouneour-Menez",
    "Plouneour-Trez",
    "Plounerin",
    "Plounevez-Lochrist",
    "Plounevezel",
    "Plourin",
    "Plourivo",
    "Plouvain",
    "Plouvara",
    "Plouvien",
    "Plouvorn",
    "Plouzane",
    "Plozevet",
    "Pludual",
    "Pluduno",
    "Pluguffan",
    "Pluherlin",
    "Plumaugat",
    "Plumeliau",
    "Plumelin",
    "Plumergat",
    "Plumoison",
    "Pluneret",
    "Plurien",
    "Plusquellec",
    "Plussulien",
    "Pluvault",
    "Pluvigner",
    "Pluzunet",
    "Poce-sur-Cisse",
    "Podensac",
    "Poeuilly",
    "Poey-de-Lescar",
    "Poggio-dOletta",
    "Pogny",
    "Poigny-la-Foret",
    "Poilly-lez-Gien",
    "Poilly-sur-Tholon",
    "Poinsenot",
    "Pointis-Inard",
    "Poisat",
    "Poiseul-les-Saulx",
    "Poisieux",
    "Poisson",
    "Poissons",
    "Poissy",
    "Poisy",
    "Poitiers",
    "Poix",
    "Poix-de-Picardie",
    "Poix-du-Nord",
    "Poligny",
    "Poligny",
    "Pollestres",
    "Polliat",
    "Pomacle",
    "Pomarez",
    "Pomerols",
    "Pomeys",
    "Pommard",
    "Pommeret",
    "Pommerit-Jaudy",
    "Pommerit-le-Vicomte",
    "Pommeuse",
    "Pommevic",
    "Pommiers",
    "Pommiers-la-Placette",
    "Pompadour",
    "Pompaire",
    "Pompertuzat",
    "Pompey",
    "Pompidou",
    "Pompignan",
    "Pomponne",
    "Pomport",
    "Poncey-les-Athee",
    "Ponchon",
    "Poncin",
    "Poncins",
    "Pons",
    "Pons",
    "Pont",
    "Pont-a-Marcq",
    "Pont-a-Vendin",
    "Pont-Audemer",
    "Pont-Aven",
    "Pont-de-Barret",
    "Pont-de-Beauvoisin",
    "Pont-de-Briques",
    "Pont-de-Buis-les-Quimerch",
    "Pont-de-Cheruy",
    "Pont-de-Labeaume",
    "Pont-de-Larn",
    "Pont-de-Metz",
    "Pont-de-Roide",
    "Pont-de-Salars",
    "Pont-de-Vaux",
    "Pont-de-Veyle",
    "Pont-du-Casse",
    "Pont-du-Chateau",
    "Pont-en-Royans",
    "Pont-Eveque",
    "Pont-Hebert",
    "Pont-Noyelles",
    "Pont-Remy",
    "Pont-Saint-Esprit",
    "Pont-Saint-Mard",
    "Pont-Saint-Martin",
    "Pont-Saint-Pierre",
    "Pont-Saint-Vincent",
    "Pont-Sainte-Marie",
    "Pont-Sainte-Maxence",
    "Pont-Salomon",
    "Pont-Scorff",
    "Pont-sur-Sambre",
    "Pont-sur-Seine",
    "Pont-sur-Yonne",
    "Pontacq",
    "Pontailler-sur-Saone",
    "Pontarlier",
    "Pontarme",
    "Pontaubault",
    "Pontault-Combault",
    "Pontaumur",
    "Pontaut",
    "Pontavert",
    "Pontcarre",
    "Pontcharra",
    "Pontcharra-sur-Turdine",
    "Pontchateau",
    "Pontcirq",
    "Ponte-Leccia",
    "Ponteilla",
    "Pontenx-les-Forges",
    "Pontgibaud",
    "Pontgouin",
    "Ponthevrard",
    "Ponthierry",
    "Pontigne",
    "Pontigny",
    "Pontivy",
    "Pontlevoy",
    "Pontoise",
    "Pontorson",
    "Pontours",
    "Pontpoint",
    "Pontruet",
    "Ponts",
    "Pontvallain",
    "Popian",
    "Porcelette",
    "Porcheres",
    "Porcheresse",
    "Porcheville",
    "Porcieu-Amblagnieu",
    "Pordic",
    "Pornic",
    "Pornichet",
    "Port-Brillet",
    "Port-Cros",
    "Port-de-Bouc",
    "Port-des-Barques",
    "Port-en-Bessin-Huppain",
    "Port-Joinville",
    "Port-la-Nouvelle",
    "Port-Launay",
    "Port-Louis",
    "Port-Mort",
    "Port-Saint-Louis-du-Rhone",
    "Port-Saint-Pere",
    "Port-Sainte-Foy-et-Ponchapt",
    "Port-Sainte-Marie",
    "Port-sur-Saone",
    "Portbail",
    "Portel-des-Corbieres",
    "Portes-les-Valence",
    "Portet-sur-Garonne",
    "Portets",
    "Porticcio",
    "Portieux",
    "Portiragnes",
    "Porto",
    "Porto-Vecchio",
    "Portrieux",
    "Ports",
    "Portvendres",
    "Poses",
    "Potigny",
    "Pouan-les-Vallees",
    "Pouance",
    "Pouffonds",
    "Pougny",
    "Pougues-les-Eaux",
    "Pougy",
    "Pouille",
    "Pouille",
    "Pouillenay",
    "Pouilley-Francais",
    "Pouilley-les-Vignes",
    "Pouillon",
    "Pouilloux",
    "Pouilly",
    "Pouilly",
    "Pouilly-en-Auxois",
    "Pouilly-le-Monial",
    "Pouilly-les-Nonains",
    "Pouilly-sous-Charlieu",
    "Pouilly-sur-Loire",
    "Pouilly-sur-Serre",
    "Pouilly-sur-Vingeanne",
    "Poulainville",
    "Poulan-Pouzols",
    "Pouldergat",
    "Pouldreuzic",
    "Poule-les-Echarmeaux",
    "Pouligney-Lusans",
    "Poullan-sur-Mer",
    "Poullaouen",
    "Poulx",
    "Pournoy-la-Grasse",
    "Pourrain",
    "Pourrieres",
    "Pouru-Saint-Remy",
    "Poussan",
    "Pouxeux",
    "Pouydesseaux",
    "Pouzauges",
    "Pouzay",
    "Pouzilhac",
    "Pouzolles",
    "Pouzols-Minervois",
    "Poville",
    "Pradelles",
    "Prades",
    "Prades-le-Lez",
    "Pradieres",
    "Prahecq",
    "Pralognan-la-Vanoise",
    "Pranzac",
    "Prasville",
    "Prat",
    "Prats-de-Carlux",
    "Pray",
    "Prayssac",
    "Prayssas",
    "Praz",
    "Praz-sur-Arly",
    "Pre-en-Pail",
    "Preaux",
    "Precey",
    "Prechac",
    "Prechac",
    "Precieux",
    "Precigne",
    "Precorbin",
    "Precy-sous-Thil",
    "Precy-sur-Marne",
    "Precy-sur-Oise",
    "Precy-sur-Vrin",
    "Prefailles",
    "Prefontaines",
    "Preguillac",
    "Preignac",
    "Preignan",
    "Premanon",
    "Premery",
    "Premian",
    "Premontre",
    "Preseau",
    "Presles-en-Brie",
    "Presles-et-Thierny",
    "Pressagny-le-Val",
    "Pressignac-Vicq",
    "Pressigny",
    "Pressigny-les-Pins",
    "Pretot-Vicquemare",
    "Preuschdorf",
    "Prevessin-Moens",
    "Prey",
    "Prigonrieux",
    "Primarette",
    "Primat",
    "Prin-Deyrancon",
    "Princay",
    "Pringy",
    "Pringy",
    "Prinquiau",
    "Prisse",
    "Privas",
    "Prix",
    "Prix-les-Mezieres",
    "Proissans",
    "Prompsat",
    "Pronville",
    "Propriano",
    "Prouais",
    "Prouilly",
    "Prouvy",
    "Prouzel",
    "Provencheres-sur-Fave",
    "Provencheres-sur-Meuse",
    "Proveysieux",
    "Proville",
    "Provin",
    "Provins",
    "Proyart",
    "Prudhomat",
    "Prunay-Cassereau",
    "Prunay-le-Gillon",
    "Prunay-sur-Essonne",
    "Prunelli-di-Fiumorbo",
    "Prunieres",
    "Pruniers",
    "Pruzilly",
    "Publier",
    "Puceul",
    "Puchevillers",
    "Puget-sur-Argens",
    "Puget-Ville",
    "Pugey",
    "Pugieu",
    "Pugnac",
    "Pugny-Chatenod",
    "Puicheric",
    "Puihardy",
    "Puilacher",
    "Puilboreau",
    "Puimichel",
    "Puiseaux",
    "Puiseux-en-France",
    "Puiseux-le-Hauberger",
    "Puisieulx",
    "Puisieux",
    "Puissalicon",
    "Puisseguin",
    "Puisserguier",
    "Pujaudran",
    "Pujaut",
    "Pujo-le-Plan",
    "Pujols",
    "Puligny-Montrachet",
    "Pulligny",
    "Pulnoy",
    "Pulversheim",
    "Pusignan",
    "Pussay",
    "Puteaux",
    "Putot-en-Bessin",
    "Puttelange-les-Thionville",
    "Puy-Guillaume",
    "Puy-Saint-Andre",
    "Puy-Saint-Martin",
    "Puybegon",
    "Puybrun",
    "Puycelsi",
    "Puycornet",
    "Puygouzon",
    "Puygros",
    "Puylaurens",
    "Puyloubier",
    "Puymiclan",
    "Puyravault",
    "Puyravault",
    "Puyreaux",
    "Puyricard",
    "Quaedypre",
    "Quarouble",
    "Quarre-les-Tombes",
    "Quatremare",
    "Queaux",
    "Quebriac",
    "Quedillac",
    "Queige",
    "Quelaines-Saint-Gault",
    "Quelneuc",
    "Quenoche",
    "Querenaing",
    "Quernes",
    "Querqueville",
    "Querrien",
    "Querrieu",
    "Quers",
    "Quesnoy-sur-Deule",
    "Quessoy",
    "Questembert",
    "Quetigny",
    "Quettehou",
    "Quettreville-sur-Sienne",
    "Quevauvillers",
    "Queven",
    "Quevert",
    "Quevillon",
    "Queyrac",
    "Queyrieres",
    "Quezac",
    "Quiberon",
    "Quiberville",
    "Quibou",
    "Quiers",
    "Quievrechain",
    "Quievrecourt",
    "Quievy",
    "Quillan",
    "Quillebeuf-sur-Seine",
    "Quilly",
    "Quilly",
    "Quily",
    "Quimper",
    "Quincampoix",
    "Quincie-en-Beaujolais",
    "Quincieu",
    "Quincy",
    "Quincy-sous-Senart",
    "Quincy-Voisins",
    "Quineville",
    "Quingey",
    "Quinsac",
    "Quint-Fonsegrives",
    "Quintenic",
    "Quintin",
    "Quissac",
    "Rabastens-de-Bigorre",
    "Rabat-les-Trois-Seigneurs",
    "Rablay-sur-Layon",
    "Rabou",
    "Raches",
    "Racquinghem",
    "Racrange",
    "Radinghem",
    "Radinghem-en-Weppes",
    "Radon",
    "Radonvilliers",
    "Raedersheim",
    "Raffetot",
    "Rahon",
    "Rai",
    "Raids",
    "Raimbeaucourt",
    "Rainfreville",
    "Rainneville",
    "Rainville",
    "Rainvillers",
    "Raismes",
    "Raizeux",
    "Ramatuelle",
    "Rambert",
    "Rambervillers",
    "Rambouillet",
    "Rammersmatt",
    "Ramonchamp",
    "Ramonville-Saint-Agne",
    "Rance",
    "Rancogne",
    "Randan",
    "Randan",
    "Randens",
    "Ranes",
    "Rang",
    "Rang-du-Fliers",
    "Rans",
    "Ranspach",
    "Rantigny",
    "Ranville",
    "Raon-aux-Bois",
    "Raon-sur-Plaine",
    "Rarecourt",
    "Ratieres",
    "Ratte",
    "Raucourt-et-Flaba",
    "Rauville-la-Place",
    "Rauwiller",
    "Rauzan",
    "Ravenel",
    "Ravieres",
    "Ray-sur-Saone",
    "Rayssac",
    "Razac-sur-lIsle",
    "Real",
    "Realmont",
    "Realville",
    "Reaumont",
    "Reaumur",
    "Rebais",
    "Rebenacq",
    "Rebrechien",
    "Rebreuve-sur-Canche",
    "Rebreuviette",
    "Recey-sur-Ource",
    "Rechesy",
    "Rechicourt-le-Chateau",
    "Recloses",
    "Recologne",
    "Recologne-les-Rioz",
    "Recques-sur-Course",
    "Recquignies",
    "Redene",
    "Redessan",
    "Reding",
    "Redon",
    "Reffuveille",
    "Regniowez",
    "Reguiny",
    "Reguisheim",
    "Regusse",
    "Rehainviller",
    "Rehon",
    "Reichshoffen",
    "Reichstett",
    "Reignac-sur-Indre",
    "Reignier-Esery",
    "Reillanne",
    "Reilly",
    "Reims",
    "Reims-la-Brulee",
    "Reiners",
    "Reiningue",
    "Reipertswiller",
    "Reitwiller",
    "Relanges",
    "Remaucourt",
    "Remecourt",
    "Remelfang",
    "Remelfing",
    "Remenoville",
    "Remerangles",
    "Remereville",
    "Remering-les-Puttelange",
    "Remigny",
    "Remilly",
    "Reminiac",
    "Remiremont",
    "Remoulins",
    "Remungol",
    "Remuzat",
    "Remy",
    "Renage",
    "Renaison",
    "Renault",
    "Renaze",
    "Rene",
    "Renescure",
    "Rennes",
    "Renneville",
    "Repaix",
    "Reparsac",
    "Replonges",
    "Requeil",
    "Requista",
    "Ressons-le-Long",
    "Ressons-sur-Matz",
    "Restinclieres",
    "Rethel",
    "Rethondes",
    "Retiers",
    "Retonfey",
    "Retournac",
    "Retschwiller",
    "Rettel",
    "Rety",
    "Reuil-en-Brie",
    "Reuil-sur-Breche",
    "Reuilly",
    "Reuilly",
    "Reuilly-Sauvigny",
    "Reumont",
    "Reuves",
    "Revel",
    "Revel-Tourdan",
    "Reventin-Vaugris",
    "Revest-des-Brousses",
    "Reviers",
    "Revigny",
    "Revigny-sur-Ornain",
    "Reville",
    "Revin",
    "Reynies",
    "Reyrieux",
    "Reyssouze",
    "Rezonville",
    "Rhinau",
    "Riaille",
    "Rians",
    "Rians",
    "Riantec",
    "Ribaute-les-Tavernes",
    "Ribecourt-Dreslincourt",
    "Ribemont",
    "Ribemont-sur-Ancre",
    "Riberac",
    "Ribiers",
    "Ricarville",
    "Richardmenil",
    "Richebourg",
    "Richebourg",
    "Richelieu",
    "Richeling",
    "Richemont",
    "Richemont",
    "Richwiller",
    "Ricquebourg",
    "Riec-sur-Belon",
    "Riedisheim",
    "Riedseltz",
    "Riencourt-les-Bapaume",
    "Rieulay",
    "Rieumes",
    "Rieupeyroux",
    "Rieux",
    "Rieux",
    "Rieux",
    "Rieux-de-Pelleport",
    "Rieux-en-Cambresis",
    "Rieux-Minervois",
    "Riez",
    "Rignac",
    "Rignieux-le-Franc",
    "Rilhac-Rancon",
    "Rillieux-la-Pape",
    "Rimbaud",
    "Rimogne",
    "Ringendorf",
    "Rinxent",
    "Riocaud",
    "Riom",
    "Riom-es-Montagnes",
    "Rion-des-Landes",
    "Rions",
    "Riorges",
    "Riotord",
    "Rioz",
    "Ris-Orangis",
    "Rittershoffen",
    "Rivarennes",
    "Rive-de-Gier",
    "Rivecourt",
    "Rivedoux-Plage",
    "Rivery",
    "Rivesaltes",
    "Riviere",
    "Rivieres",
    "Rivieres",
    "Riville",
    "Rixheim",
    "Roaillan",
    "Roanne",
    "Roannes-Saint-Mary",
    "Robert",
    "Robion",
    "Rocbaron",
    "Roche",
    "Roche",
    "Roche-la-Moliere",
    "Roche-le-Peyroux",
    "Roche-lez-Beaupre",
    "Rochechouart",
    "Rochecorbon",
    "Rochefort",
    "Rochefort-du-Gard",
    "Rochefort-en-Terre",
    "Rochefort-sur-Loire",
    "Rochefort-sur-Nenon",
    "Rochegude",
    "Rochemaure",
    "Rocher",
    "Roches",
    "Roches-les-Blamont",
    "Roches-Premarie-Andille",
    "Roches-sur-Marne",
    "Rocheserviere",
    "Rochesson",
    "Rochetoirin",
    "Rochetrejoux",
    "Rochy-Conde",
    "Rocquemont",
    "Rocquencourt",
    "Rocroi",
    "Rodelinghem",
    "Roderen",
    "Rodern",
    "Rodez",
    "Roeschwoog",
    "Roeulx",
    "Roeux",
    "Roeze-sur-Sarthe",
    "Roffey",
    "Roffiac",
    "Rogerville",
    "Rognac",
    "Rognaix",
    "Rognes",
    "Rognonas",
    "Rohan",
    "Rohan",
    "Rohr",
    "Rohrbach-les-Bitche",
    "Roiffieux",
    "Roisel",
    "Roissy-en-Brie",
    "Roissy-en-France",
    "Rolampont",
    "Rollancourt",
    "Rolleville",
    "Rollot",
    "Romagnat",
    "Romagne",
    "Romagne",
    "Romagne",
    "Romagnieu",
    "Romain",
    "Romain-sur-Meuse",
    "Romaine",
    "Romainville",
    "Romaneche-Thorins",
    "Romange",
    "Romans",
    "Rombas",
    "Romegoux",
    "Romeny-sur-Marne",
    "Romeries",
    "Romille",
    "Romilly-sur-Andelle",
    "Romilly-sur-Seine",
    "Romorantin-Lanthenay",
    "Rompon",
    "Roncey",
    "Ronchin",
    "Roncq",
    "Ronnet",
    "Ronno",
    "Ronsenac",
    "Ronthon",
    "Roost-Warendin",
    "Roppe",
    "Roppenheim",
    "Roppentzwiller",
    "Roquebilliere",
    "Roquebrune",
    "Roquebrune-Cap-Martin",
    "Roquebrune-sur-Argens",
    "Roquecor",
    "Roquecourbe",
    "Roquefort",
    "Roquefort",
    "Roquefort-de-Sault",
    "Roquefort-des-Corbieres",
    "Roquefort-la-Bedoule",
    "Roquefort-sur-Garonne",
    "Roquefort-sur-Soulzon",
    "Roquelaure-Saint-Aubin",
    "Roquemaure",
    "Roques",
    "Roqueseriere",
    "Roquesteron",
    "Roquetoire",
    "Roquettes",
    "Roquevaire",
    "Rosay",
    "Rosay-sur-Lieure",
    "Roscoff",
    "Rosel",
    "Rosenwiller",
    "Rosheim",
    "Rosieres",
    "Rosieres-aux-Salines",
    "Rosieres-en-Haye",
    "Rosieres-en-Santerre",
    "Rosieres-pres-Troyes",
    "Rosieres-sur-Mance",
    "Rosny-sous-Bois",
    "Rosny-sur-Seine",
    "Rosoy-en-Multien",
    "Rospez",
    "Rosporden",
    "Rosselange",
    "Rossfeld",
    "Rosteig",
    "Rostrenen",
    "Rosult",
    "Rothau",
    "Rotherens",
    "Rots",
    "Rott",
    "Rouans",
    "Roubaix",
    "Roubia",
    "Roubion",
    "Roucy",
    "Rouen",
    "Rouffach",
    "Rouffiac-Tolosan",
    "Rouffignac",
    "Rouffignac-Saint-Cernin-de-Reilhac",
    "Rouge-Perriers",
    "Rougegoutte",
    "Rougemont",
    "Rougiers",
    "Rouillac",
    "Rouille",
    "Rouillon",
    "Rouilly-Sacey",
    "Roujan",
    "Roulans",
    "Roullens",
    "Roullet-Saint-Estephe",
    "Roumare",
    "Roumazieres",
    "Roumegoux",
    "Roumoules",
    "Rountzenheim",
    "Rouperroux",
    "Rousies",
    "Roussay",
    "Rousseloy",
    "Rousset",
    "Roussillon",
    "Roussillon",
    "Rousson",
    "Roussy",
    "Roussy-le-Village",
    "Routot",
    "Rouville",
    "Rouvillers",
    "Rouvre",
    "Rouvres-la-Chetive",
    "Rouvroy",
    "Rouvroy",
    "Rouvroy-sur-Audry",
    "Rouvroy-sur-Marne",
    "Rouxmesnil-Bouteilles",
    "Rouy-le-Grand",
    "Rouziers-de-Touraine",
    "Roville-aux-Chenes",
    "Royan",
    "Royas",
    "Royat",
    "Roybon",
    "Roye",
    "Roye",
    "Roye-sur-Matz",
    "Rozay-en-Brie",
    "Rozerieulles",
    "Rozier-en-Donzy",
    "Rozieres-en-Beauce",
    "Rozoy-Bellevalle",
    "Rozoy-sur-Serre",
    "Ruaudin",
    "Rubelles",
    "Rubempre",
    "Ruch",
    "Rucqueville",
    "Rue",
    "Rueil-Malmaison",
    "Ruelisheim",
    "Ruelle",
    "Ruelle-sur-Touvre",
    "Ruesnes",
    "Ruffec",
    "Ruffey-les-Beaune",
    "Ruffey-les-Echirey",
    "Ruffey-sur-Seille",
    "Ruffiac",
    "Rugles",
    "Ruille-Froid-Fonds",
    "Ruille-le-Gravelais",
    "Ruitz",
    "Rully",
    "Rully",
    "Rumaucourt",
    "Rumegies",
    "Rumersheim-le-Haut",
    "Rumigny",
    "Rumilly",
    "Rumilly-en-Cambresis",
    "Rungis",
    "Ruoms",
    "Rupt",
    "Rupt-sur-Moselle",
    "Rurange-les-Thionville",
    "Russ",
    "Ruy",
    "Ry",
    "Saacy-sur-Marne",
    "Saales",
    "Sabatier",
    "Sable-sur-Sarthe",
    "Sablonceaux",
    "Sablons",
    "Sabres",
    "Sacey",
    "Sachin",
    "Saclas",
    "Saclay",
    "Sacquenville",
    "Sacy",
    "Sadirac",
    "Saessolsheim",
    "Saffre",
    "Sagy",
    "Sahurs",
    "Saignes",
    "Sail-sous-Couzan",
    "Saillans",
    "Sailly-en-Ostrevent",
    "Sailly-Flibeaucourt",
    "Sailly-Labourse",
    "Sailly-le-Sec",
    "Sailly-lez-Cambrai",
    "Sailly-sur-la-Lys",
    "Sain-Bel",
    "Sainghin-en-Melantois",
    "Sainghin-en-Weppes",
    "Sainneville",
    "Sainpuits",
    "Sains-du-Nord",
    "Sains-en-Amienois",
    "Sains-en-Gohelle",
    "Saint Nicolas Les Arras",
    "Saint-Abit",
    "Saint-Adrien",
    "Saint-Affrique",
    "Saint-Agathon",
    "Saint-Agnant",
    "Saint-Agoulin",
    "Saint-Agreve",
    "Saint-Aignan-des-Noyers",
    "Saint-Aignan-sur-Roe",
    "Saint-Aigulin",
    "Saint-Albain",
    "Saint-Alban-de-Roche",
    "Saint-Alban-du-Rhone",
    "Saint-Alban-Leysse",
    "Saint-Alexandre",
    "Saint-Amancet",
    "Saint-Amand-de-Belves",
    "Saint-Amand-de-Coly",
    "Saint-Amand-en-Puisaye",
    "Saint-Amand-les-Eaux",
    "Saint-Amand-Magnazeix",
    "Saint-Amand-Montrond",
    "Saint-Amand-sur-Fion",
    "Saint-Amans-Soult",
    "Saint-Amans-Valtoret",
    "Saint-Amant-de-Boixe",
    "Saint-Amant-Tallende",
    "Saint-Amarin",
    "Saint-Ambroix",
    "Saint-Amour",
    "Saint-Andeol-de-Vals",
    "Saint-Andeux",
    "Saint-Andiol",
    "Saint-Andre",
    "Saint-Andre-de-Chalencon",
    "Saint-Andre-de-Corcy",
    "Saint-Andre-de-Messei",
    "Saint-Andre-de-Roquelongue",
    "Saint-Andre-de-Rosans",
    "Saint-Andre-de-Sangonis",
    "Saint-Andre-des-Eaux",
    "Saint-Andre-en-Vivarais",
    "Saint-Andre-Farivillers",
    "Saint-Andre-le-Gaz",
    "Saint-Andre-le-Puy",
    "Saint-Andre-les-Alpes",
    "Saint-Andre-les-Vergers",
    "Saint-Andre-lez-Lille",
    "Saint-Andre-sur-Orne",
    "Saint-Andre-Treize-Voies",
    "Saint-Androny",
    "Saint-Angeau",
    "Saint-Angel",
    "Saint-Antoine-Cumond",
    "Saint-Antoine-du-Rocher",
    "Saint-Antoine-la-Foret",
    "Saint-Antonin-sur-Bayon",
    "Saint-Apollinaire",
    "Saint-Appolinard",
    "Saint-Aquilin-de-Pacy",
    "Saint-Armel",
    "Saint-Arnoult",
    "Saint-Arnoult",
    "Saint-Arnoult-des-Bois",
    "Saint-Arnoult-en-Yvelines",
    "Saint-Astier",
    "Saint-Auban",
    "Saint-Aubert",
    "Saint-Aubin",
    "Saint-Aubin-de-Baubigne",
    "Saint-Aubin-de-Lanquais",
    "Saint-Aubin-de-Medoc",
    "Saint-Aubin-des-Chateaux",
    "Saint-Aubin-du-Cormier",
    "Saint-Aubin-du-Plain",
    "Saint-Aubin-en-Bray",
    "Saint-Aubin-en-Charollais",
    "Saint-Aubin-les-Elbeuf",
    "Saint-Aubin-les-Forges",
    "Saint-Aubin-sous-Erquery",
    "Saint-Aubin-sur-Gaillon",
    "Saint-Aubin-sur-Mer",
    "Saint-Aubin-sur-Scie",
    "Saint-Augustin",
    "Saint-Augustin",
    "Saint-Augustin",
    "Saint-Aunes",
    "Saint-Avaugourd-des-Landes",
    "Saint-Ave",
    "Saint-Avertin",
    "Saint-Avit",
    "Saint-Avit-les-Guespieres",
    "Saint-Avold",
    "Saint-Avre",
    "Saint-Ay",
    "Saint-Aygulf",
    "Saint-Baldoph",
    "Saint-Bardoux",
    "Saint-Barnabe",
    "Saint-Barnabe",
    "Saint-Barthelemy-de-Bussiere",
    "Saint-Barthelemy-de-Vals",
    "Saint-Barthelemy-Lestra",
    "Saint-Baudille-de-la-Tour",
    "Saint-Bauzille-de-Montmel",
    "Saint-Bauzille-de-Putois",
    "Saint-Beat",
    "Saint-Benezet",
    "Saint-Benin-dAzy",
    "Saint-Benoit",
    "Saint-Benoit-des-Ondes",
    "Saint-Benoit-sur-Loire",
    "Saint-Berain-sur-Dheune",
    "Saint-Bernard",
    "Saint-Beron",
    "Saint-Berthevin",
    "Saint-Bihy",
    "Saint-Blaise-la-Roche",
    "Saint-Bohaire",
    "Saint-Boil",
    "Saint-Boingt",
    "Saint-Bonnet-de-Mure",
    "Saint-Bonnet-le-Chateau",
    "Saint-Bonnet-le-Troncy",
    "Saint-Bonnet-les-Allier",
    "Saint-Branchs",
    "Saint-Brandan",
    "Saint-Bres",
    "Saint-Brevin-les-Pins",
    "Saint-Briac-sur-Mer",
    "Saint-Brice",
    "Saint-Brice-de-Landelles",
    "Saint-Brice-sous-Foret",
    "Saint-Brieuc",
    "Saint-Bris-le-Vineux",
    "Saint-Brisson-sur-Loire",
    "Saint-Calais",
    "Saint-Cannat",
    "Saint-Caprais-de-Bordeaux",
    "Saint-Carne",
    "Saint-Carreuc",
    "Saint-Cassin",
    "Saint-Cast-le-Guildo",
    "Saint-Cere",
    "Saint-Cergues",
    "Saint-Cesaire",
    "Saint-Cezaire-sur-Siagne",
    "Saint-Cezert",
    "Saint-Chamassy",
    "Saint-Chamond",
    "Saint-Chaptes",
    "Saint-Chartres",
    "Saint-Chef",
    "Saint-Cheron-du-Chemin",
    "Saint-Chinian",
    "Saint-Christo-en-Jarez",
    "Saint-Christol",
    "Saint-Christol",
    "Saint-Christol-les-Ales",
    "Saint-Christophe-de-Valains",
    "Saint-Christophe-du-Bois",
    "Saint-Christophe-du-Ligneron",
    "Saint-Christophe-en-Boucherie",
    "Saint-Christophe-en-Brionnais",
    "Saint-Christophe-sur-Avre",
    "Saint-Christophe-sur-Guiers",
    "Saint-Christophe-sur-le-Nais",
    "Saint-Christophe-sur-Roc",
    "Saint-Ciers-sur-Gironde",
    "Saint-Cirgues",
    "Saint-Clair",
    "Saint-Clair-de-la-Tour",
    "Saint-Clair-du-Rhone",
    "Saint-Clair-sur-Galaure",
    "Saint-Clar-de-Riviere",
    "Saint-Claud",
    "Saint-Claude",
    "Saint-Claude-de-Diray",
    "Saint-Clement",
    "Saint-Clement",
    "Saint-Clement",
    "Saint-Clement-de-la-Place",
    "Saint-Clement-des-Levees",
    "Saint-Clet",
    "Saint-Cloud",
    "Saint-Colomban",
    "Saint-Congard",
    "Saint-Contest",
    "Saint-Corneille",
    "Saint-Cornier-des-Landes",
    "Saint-Coulitz",
    "Saint-Coulomb",
    "Saint-Creac",
    "Saint-Crepin-Ibouvillers",
    "Saint-Crespin-sur-Moine",
    "Saint-Cyprien",
    "Saint-Cyprien",
    "Saint-Cyprien-Plage",
    "Saint-Cyr-du-Gault",
    "Saint-Cyr-en-Pail",
    "Saint-Cyr-en-Val",
    "Saint-Cyr-la-Campagne",
    "Saint-Cyr-le-Chatoux",
    "Saint-Cyr-les-Champagnes",
    "Saint-Cyr-sur-le-Rhone",
    "Saint-Cyr-sur-Loire",
    "Saint-Cyr-sur-Menthon",
    "Saint-Cyr-sur-Mer",
    "Saint-Cyr-sur-Morin",
    "Saint-Denis",
    "Saint-Denis-dAnjou",
    "Saint-Denis-de-Cabanne",
    "Saint-Denis-de-Palin",
    "Saint-Denis-de-Pile",
    "Saint-Denis-de-Vaux",
    "Saint-Denis-des-Murs",
    "Saint-Denis-en-Bugey",
    "Saint-Denis-en-Val",
    "Saint-Denis-le-Gast",
    "Saint-Denis-le-Thiboult",
    "Saint-Denis-les-Rebais",
    "Saint-Denis-sur-Coise",
    "Saint-Denis-sur-Sarthon",
    "Saint-Derrien",
    "Saint-Desir",
    "Saint-Desirat",
    "Saint-Desire",
    "Saint-Dezery",
    "Saint-Didier-de-Formans",
    "Saint-Didier-de-la-Tour",
    "Saint-Didier-en-Donjon",
    "Saint-Didier-en-Velay",
    "Saint-Didier-sous-Riverie",
    "Saint-Didier-sur-Chalaronne",
    "Saint-Didier-sur-Rochefort",
    "Saint-Die-des-Vosges",
    "Saint-Dionisy",
    "Saint-Disdier",
    "Saint-Divy",
    "Saint-Dizier",
    "Saint-Dizier-en-Diois",
    "Saint-Dolay",
    "Saint-Domet",
    "Saint-Domineuc",
    "Saint-Donan",
    "Saint-Doulchard",
    "Saint-Drezery",
    "Saint-Ebremond-de-Bonfosse",
    "Saint-Elier",
    "Saint-Eliph",
    "Saint-Elix-Seglan",
    "Saint-Eloi",
    "Saint-Eloi",
    "Saint-Eloy-de-Gy",
    "Saint-Eloy-la-Glaciere",
    "Saint-Eloy-les-Mines",
    "Saint-Emilion",
    "Saint-Erblon",
    "Saint-Erme-Outre-et-Ramecourt",
    "Saint-Esteve",
    "Saint-Esteve-Janson",
    "Saint-Etienne",
    "Saint-Etienne-au-Mont",
    "Saint-Etienne-au-Temple",
    "Saint-Etienne-de-Baigorry",
    "Saint-Etienne-de-Brillouet",
    "Saint-Etienne-de-Crossey",
    "Saint-Etienne-de-Cuines",
    "Saint-Etienne-de-Fontbellon",
    "Saint-Etienne-de-Montluc",
    "Saint-Etienne-de-Saint-Geoirs",
    "Saint-Etienne-de-Tinee",
    "Saint-Etienne-de-Tulmont",
    "Saint-Etienne-de-Valoux",
    "Saint-Etienne-des-Oullieres",
    "Saint-Etienne-du-Bois",
    "Saint-Etienne-du-Gres",
    "Saint-Etienne-du-Valdonnez",
    "Saint-Etienne-du-Vauvray",
    "Saint-Etienne-en-Cogles",
    "Saint-Etienne-la-Varenne",
    "Saint-Etienne-le-Molard",
    "Saint-Etienne-les-Orgues",
    "Saint-Etienne-sous-Bailleul",
    "Saint-Etienne-sur-Chalaronne",
    "Saint-Etienne-sur-Suippe",
    "Saint-Eulien",
    "Saint-Eustache-la-Foret",
    "Saint-Evarzec",
    "Saint-Faust",
    "Saint-Felicien",
    "Saint-Felix-de-Rieutord",
    "Saint-Felix-de-Villadeix",
    "Saint-Fiacre",
    "Saint-Fiacre-sur-Maine",
    "Saint-Firmin",
    "Saint-Firmin-des-Pres",
    "Saint-Florent",
    "Saint-Florent-le-Vieil",
    "Saint-Florent-sur-Auzonnet",
    "Saint-Florent-sur-Cher",
    "Saint-Florentin",
    "Saint-Floret",
    "Saint-Floris",
    "Saint-Flour",
    "Saint-Flovier",
    "Saint-Folquin",
    "Saint-Fons",
    "Saint-Forgeux",
    "Saint-Fort",
    "Saint-Fregant",
    "Saint-Fromond",
    "Saint-Front",
    "Saint-Front",
    "Saint-Fulgent",
    "Saint-Fuscien",
    "Saint-Galmier",
    "Saint-Gatien-des-Bois",
    "Saint-Gaudens",
    "Saint-Gaultier",
    "Saint-Gein",
    "Saint-Gelais",
    "Saint-Gely-du-Fesc",
    "Saint-Gence",
    "Saint-Generoux",
    "Saint-Genes-du-Retz",
    "Saint-Genest-Lerpt",
    "Saint-Genest-Malifaux",
    "Saint-Gengoux-de-Scisse",
    "Saint-Genies-Bellevue",
    "Saint-Genies-de-Comolas",
    "Saint-Genies-de-Malgoires",
    "Saint-Genies-des-Mourgues",
    "Saint-Genis-Laval",
    "Saint-Genis-les-Ollieres",
    "Saint-Genis-Pouilly",
    "Saint-Genix-sur-Guiers",
    "Saint-Genou",
    "Saint-Geoire-en-Valdaine",
    "Saint-Geoirs",
    "Saint-Georges-de-Baroille",
    "Saint-Georges-de-Didonne",
    "Saint-Georges-de-Luzencon",
    "Saint-Georges-de-Montaigu",
    "Saint-Georges-de-Pointindoux",
    "Saint-Georges-de-Poisieux",
    "Saint-Georges-de-Reneins",
    "Saint-Georges-des-Coteaux",
    "Saint-Georges-du-Bois",
    "Saint-Georges-du-Rosay",
    "Saint-Georges-du-Vievre",
    "Saint-Georges-Haute-Ville",
    "Saint-Georges-le-Flechard",
    "Saint-Georges-les-Baillargeaux",
    "Saint-Georges-Montcocq",
    "Saint-Georges-sur-Allier",
    "Saint-Georges-sur-Arnon",
    "Saint-Georges-sur-Baulche",
    "Saint-Georges-sur-Cher",
    "Saint-Georges-sur-Eure",
    "Saint-Georges-sur-Fontaine",
    "Saint-Georges-sur-Loire",
    "Saint-Georges-sur-Renon",
    "Saint-Gerand",
    "Saint-Gerand-de-Vaux",
    "Saint-Gereon",
    "Saint-Germain",
    "Saint-Germain-de-Joux",
    "Saint-Germain-de-la-Grange",
    "Saint-Germain-de-Princay",
    "Saint-Germain-de-Tournebut",
    "Saint-Germain-des-Fosses",
    "Saint-Germain-des-Pres",
    "Saint-Germain-du-Corbeis",
    "Saint-Germain-du-Plain",
    "Saint-Germain-du-Puch",
    "Saint-Germain-du-Puy",
    "Saint-Germain-en-Laye",
    "Saint-Germain-la-Blanche-Herbe",
    "Saint-Germain-la-Ville",
    "Saint-Germain-Langot",
    "Saint-Germain-Laprade",
    "Saint-Germain-Laxis",
    "Saint-Germain-Lembron",
    "Saint-Germain-les-Arpajon",
    "Saint-Germain-les-Belles",
    "Saint-Germain-les-Corbeil",
    "Saint-Germain-les-Paroisses",
    "Saint-Germain-les-Vergnes",
    "Saint-Germain-Nuelles",
    "Saint-Germain-sous-Doue",
    "Saint-Germain-sur-Avre",
    "Saint-Germain-sur-Moine",
    "Saint-Germain-sur-Morin",
    "Saint-Germain-sur-Rhone",
    "Saint-Germain-Village",
    "Saint-Germainmont",
    "Saint-Germer-de-Fly",
    "Saint-Gervais-en-Belin",
    "Saint-Gervais-la-Foret",
    "Saint-Gervais-les-Bains",
    "Saint-Gervais-sous-Meymont",
    "Saint-Gervais-sur-Roubion",
    "Saint-Gervazy",
    "Saint-Geyrac",
    "Saint-Gildas-des-Bois",
    "Saint-Gilles",
    "Saint-Gilles-des-Marais",
    "Saint-Gilles-les-Bois",
    "Saint-Gingolph",
    "Saint-Girons",
    "Saint-Goazec",
    "Saint-Gobain",
    "Saint-Gondon",
    "Saint-Gondran",
    "Saint-Gonnery",
    "Saint-Gratien",
    "Saint-Gratien",
    "Saint-Grave",
    "Saint-Gregoire",
    "Saint-Gregoire",
    "Saint-Guilhem-le-Desert",
    "Saint-Guinoux",
    "Saint-Heand",
    "Saint-Helen",
    "Saint-Herblain",
    "Saint-Herve",
    "Saint-Hilaire-Cusson-la-Valmitte",
    "Saint-Hilaire-de-Brethmas",
    "Saint-Hilaire-de-Chaleons",
    "Saint-Hilaire-de-Court",
    "Saint-Hilaire-de-la-Cote",
    "Saint-Hilaire-de-Loulay",
    "Saint-Hilaire-de-Riez",
    "Saint-Hilaire-de-Talmont",
    "Saint-Hilaire-de-Voust",
    "Saint-Hilaire-du-Harcouet",
    "Saint-Hilaire-le-Vouhis",
    "Saint-Hilaire-les-Andresis",
    "Saint-Hilaire-les-Courbes",
    "Saint-Hilaire-lez-Cambrai",
    "Saint-Hilaire-Peyroux",
    "Saint-Hilaire-Saint-Mesmin",
    "Saint-Hilarion",
    "Saint-Hilliers",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte-de-Montaigu",
    "Saint-Hippolyte-du-Fort",
    "Saint-Honore-les-Bains",
    "Saint-Hostien",
    "Saint-Igneuc",
    "Saint-Illide",
    "Saint-Imoges",
    "Saint-Ismier",
    "Saint-Jacques-de-la-Lande",
    "Saint-Jean-aux-Amognes",
    "Saint-Jean-Bonnefonds",
    "Saint-Jean-Brevelay",
    "Saint-Jean-de-Boiseau",
    "Saint-Jean-de-Bournay",
    "Saint-Jean-de-Braye",
    "Saint-Jean-de-Chevelu",
    "Saint-Jean-de-Cornies",
    "Saint-Jean-de-Gonville",
    "Saint-Jean-de-la-Ruelle",
    "Saint-Jean-de-Laur",
    "Saint-Jean-de-Lier",
    "Saint-Jean-de-Liversay",
    "Saint-Jean-de-Losne",
    "Saint-Jean-de-Luz",
    "Saint-Jean-de-Marcel",
    "Saint-Jean-de-Marsacq",
    "Saint-Jean-de-Maurienne",
    "Saint-Jean-de-Moirans",
    "Saint-Jean-de-Monts",
    "Saint-Jean-de-Muzols",
    "Saint-Jean-de-Sauves",
    "Saint-Jean-de-Serres",
    "Saint-Jean-de-Sixt",
    "Saint-Jean-de-Soudain",
    "Saint-Jean-de-Vedas",
    "Saint-Jean-des-Baisants",
    "Saint-Jean-des-Champs",
    "Saint-Jean-des-Mauvrets",
    "Saint-Jean-des-Ollieres",
    "Saint-Jean-des-Vignes",
    "Saint-Jean-du-Bruel",
    "Saint-Jean-du-Cardonnay",
    "Saint-Jean-du-Falga",
    "Saint-Jean-en-Royans",
    "Saint-Jean-Kerdaniel",
    "Saint-Jean-Kourtzerode",
    "Saint-Jean-la-Poterie",
    "Saint-Jean-Lasseille",
    "Saint-Jean-le-Blanc",
    "Saint-Jean-le-Blanc",
    "Saint-Jean-le-Centenier",
    "Saint-Jean-le-Thomas",
    "Saint-Jean-les-Deux-Jumeaux",
    "Saint-Jean-Ligoure",
    "Saint-Jean-Pied-de-Port",
    "Saint-Jean-Rohrbach",
    "Saint-Jean-Saint-Germain",
    "Saint-Jean-sur-Erve",
    "Saint-Jean-sur-Vilaine",
    "Saint-Jean-Trolimon",
    "Saint-Jeannet",
    "Saint-Jeoire",
    "Saint-Jeoire-Prieure",
    "Saint-Jeures",
    "Saint-Joachim",
    "Saint-Jorioz",
    "Saint-Jory",
    "Saint-Joseph-de-Riviere",
    "Saint-Jouan-des-Guerets",
    "Saint-Jouvent",
    "Saint-Judoce",
    "Saint-Julien",
    "Saint-Julien-de-Cassagnas",
    "Saint-Julien-de-Chedon",
    "Saint-Julien-de-Concelles",
    "Saint-Julien-de-Coppel",
    "Saint-Julien-de-Lampon",
    "Saint-Julien-de-Vouvantes",
    "Saint-Julien-des-Landes",
    "Saint-Julien-du-Puy",
    "Saint-Julien-du-Sault",
    "Saint-Julien-en-Born",
    "Saint-Julien-en-Champsaur",
    "Saint-Julien-en-Genevois",
    "Saint-Julien-le-Roux",
    "Saint-Julien-le-Vendomois",
    "Saint-Julien-les-Metz",
    "Saint-Julien-les-Villas",
    "Saint-Julien-sur-Bibost",
    "Saint-Julien-sur-Sarthe",
    "Saint-Junien",
    "Saint-Just-Chaleyssin",
    "Saint-Just-de-Claix",
    "Saint-Just-en-Bas",
    "Saint-Just-en-Chaussee",
    "Saint-Just-en-Chevalet",
    "Saint-Just-le-Martel",
    "Saint-Just-Malmont",
    "Saint-Just-pres-Brioude",
    "Saint-Lactencin",
    "Saint-Lager",
    "Saint-Lager-Bressac",
    "Saint-Lambert-du-Lattay",
    "Saint-Lambert-la-Potherie",
    "Saint-Lambert-sur-Dive",
    "Saint-Lanne",
    "Saint-Lary-Boujean",
    "Saint-Lary-Soulan",
    "Saint-Launeuc",
    "Saint-Laurent-Blangy",
    "Saint-Laurent-Bretagne",
    "Saint-Laurent-de-la-Plaine",
    "Saint-Laurent-de-la-Pree",
    "Saint-Laurent-de-la-Salanque",
    "Saint-Laurent-de-Levezou",
    "Saint-Laurent-de-Mure",
    "Saint-Laurent-de-Neste",
    "Saint-Laurent-des-Autels",
    "Saint-Laurent-des-Combes",
    "Saint-Laurent-du-Bois",
    "Saint-Laurent-du-Mottay",
    "Saint-Laurent-du-Pont",
    "Saint-Laurent-du-Var",
    "Saint-Laurent-en-Beaumont",
    "Saint-Laurent-en-Gatines",
    "Saint-Laurent-la-Roche",
    "Saint-Laurent-Rochefort",
    "Saint-Laurent-sur-Gorre",
    "Saint-Laurent-sur-Sevre",
    "Saint-Laurs",
    "Saint-Leger-aux-Bois",
    "Saint-Leger-de-Rotes",
    "Saint-Leger-des-Vignes",
    "Saint-Leger-du-Bourg-Denis",
    "Saint-Leger-en-Bray",
    "Saint-Leger-les-Domart",
    "Saint-Leger-sous-Brienne",
    "Saint-Leger-sous-Cholet",
    "Saint-Leger-sur-Dheune",
    "Saint-Leonard",
    "Saint-Leonard-de-Noblat",
    "Saint-Leu-la-Foret",
    "Saint-Lezin",
    "Saint-Lieux-les-Lavaur",
    "Saint-Lormel",
    "Saint-Loubes",
    "Saint-Louis",
    "Saint-Louis-de-Montferrand",
    "Saint-Loup",
    "Saint-Loup-Cammas",
    "Saint-Loup-Hors",
    "Saint-Lubin-des-Joncherets",
    "Saint-Lumier-en-Champagne",
    "Saint-Lumine-de-Clisson",
    "Saint-Luperce",
    "Saint-Lupicin",
    "Saint-Lye",
    "Saint-Lye-la-Foret",
    "Saint-Lyphard",
    "Saint-Lys",
    "Saint-Macaire",
    "Saint-Macaire-du-Bois",
    "Saint-Macaire-en-Mauges",
    "Saint-Magne-de-Castillon",
    "Saint-Maigner",
    "Saint-Maigrin",
    "Saint-Maime",
    "Saint-Maixant",
    "Saint-Malo-de-Guersac",
    "Saint-Malo-de-la-Lande",
    "Saint-Malo-de-Phily",
    "Saint-Malo-du-Bois",
    "Saint-Malo-en-Donziois",
    "Saint-Mamert-du-Gard",
    "Saint-Mamet-la-Salvetat",
    "Saint-Mammes",
    "Saint-Mande",
    "Saint-Mandrier-sur-Mer",
    "Saint-Marc-Jaumegarde",
    "Saint-Marceau",
    "Saint-Marcel-Bel-Accueil",
    "Saint-Marcel-les-Valence",
    "Saint-Marcel-sur-Aude",
    "Saint-Marcellin",
    "Saint-Marcellin-en-Forez",
    "Saint-Mard",
    "Saint-Mard-de-Vaux",
    "Saint-Mariens",
    "Saint-Mars-de-Coutais",
    "Saint-Mars-de-Locquenay",
    "Saint-Mars-du-Desert",
    "Saint-Mars-la-Jaille",
    "Saint-Martial",
    "Saint-Martial-de-Vitaterne",
    "Saint-Martin-au-Laert",
    "Saint-Martin-Belle-Roche",
    "Saint-Martin-Bellevue",
    "Saint-Martin-Boulogne",
    "Saint-Martin-Choquel",
    "Saint-Martin-de-Belleville",
    "Saint-Martin-de-Bernegoue",
    "Saint-Martin-de-Blagny",
    "Saint-Martin-de-Crau",
    "Saint-Martin-de-Fontenay",
    "Saint-Martin-de-la-Brasque",
    "Saint-Martin-de-Lenne",
    "Saint-Martin-de-Londres",
    "Saint-Martin-de-Nigelles",
    "Saint-Martin-de-Re",
    "Saint-Martin-de-Riberac",
    "Saint-Martin-de-Seignanx",
    "Saint-Martin-de-Valamas",
    "Saint-Martin-de-Valgalgues",
    "Saint-Martin-des-Bois",
    "Saint-Martin-des-Champs",
    "Saint-Martin-des-Champs",
    "Saint-Martin-des-Noyers",
    "Saint-Martin-des-Olmes",
    "Saint-Martin-du-Bois",
    "Saint-Martin-du-Bois",
    "Saint-Martin-du-Bosc",
    "Saint-Martin-du-Manoir",
    "Saint-Martin-du-Mont",
    "Saint-Martin-du-Puy",
    "Saint-Martin-du-Tertre",
    "Saint-Martin-du-Tilleul",
    "Saint-Martin-du-Var",
    "Saint-Martin-en-Biere",
    "Saint-Martin-en-Bresse",
    "Saint-Martin-en-Campagne",
    "Saint-Martin-en-Gatinois",
    "Saint-Martin-en-Haut",
    "Saint-Martin-la-Campagne",
    "Saint-Martin-la-Plaine",
    "Saint-Martin-Lacaussade",
    "Saint-Martin-Lalande",
    "Saint-Martin-le-Beau",
    "Saint-Martin-le-Noeud",
    "Saint-Martin-le-Pin",
    "Saint-Martin-le-Vieil",
    "Saint-Martin-le-Vinoux",
    "Saint-Martin-les-Melle",
    "Saint-Martin-Longueau",
    "Saint-Martin-Osmonville",
    "Saint-Martin-Sainte-Catherine",
    "Saint-Martin-sur-Arve",
    "Saint-Martin-sur-le-Pre",
    "Saint-Martin-sur-Nohain",
    "Saint-Martin-sur-Ocre",
    "Saint-Martin-Terressus",
    "Saint-Martin-Valmeroux",
    "Saint-Mary",
    "Saint-Mathieu-de-Treviers",
    "Saint-Mathurin",
    "Saint-Maudan",
    "Saint-Maulvis",
    "Saint-Maurice",
    "Saint-Maurice-de-Cazevieille",
    "Saint-Maurice-de-Gourdans",
    "Saint-Maurice-de-Lignon",
    "Saint-Maurice-de-Remens",
    "Saint-Maurice-la-Clouere",
    "Saint-Maurice-la-Souterraine",
    "Saint-Maurice-les-Brousses",
    "Saint-Maurice-Montcouronne",
    "Saint-Maurice-sur-Aveyron",
    "Saint-Maurice-sur-Dargoire",
    "Saint-Maurice-sur-Eygues",
    "Saint-Maurice-sur-Fessard",
    "Saint-Maurice-sur-Moselle",
    "Saint-Max",
    "Saint-Maxent",
    "Saint-Maximin",
    "Saint-Maximin",
    "Saint-Maximin",
    "Saint-Maximin-la-Sainte-Baume",
    "Saint-Maxire",
    "Saint-Meard-de-Gurcon",
    "Saint-Medard-de-Guizieres",
    "Saint-Medard-en-Forez",
    "Saint-Meen-le-Grand",
    "Saint-Melaine-sur-Aubance",
    "Saint-Meloir-des-Ondes",
    "Saint-Meme-les-Carrieres",
    "Saint-Memmie",
    "Saint-Michel",
    "Saint-Michel",
    "Saint-Michel-Chef-Chef",
    "Saint-Michel-de-Chabrillanoux",
    "Saint-Michel-de-Maurienne",
    "Saint-Michel-de-Rieufret",
    "Saint-Michel-de-Volangis",
    "Saint-Michel-des-Andaines",
    "Saint-Michel-le-Cloucq",
    "Saint-Michel-lObservatoire",
    "Saint-Michel-Mont-Mercure",
    "Saint-Michel-sous-Bois",
    "Saint-Michel-sur-Meurthe",
    "Saint-Michel-sur-Orge",
    "Saint-Michel-sur-Savasse",
    "Saint-Michel-sur-Ternoise",
    "Saint-Michel-Treve",
    "Saint-Mihiel",
    "Saint-Mitre-les-Remparts",
    "Saint-Morillon",
    "Saint-Nabord",
    "Saint-Nauphary",
    "Saint-Nazaire",
    "Saint-Nazaire",
    "Saint-Nazaire-de-Valentane",
    "Saint-Nectaire",
    "Saint-Nicolas-de-Bliquetuit",
    "Saint-Nicolas-de-la-Grave",
    "Saint-Nicolas-de-Port",
    "Saint-Nicolas-de-Redon",
    "Saint-Nicolas-du-Pelem",
    "Saint-Nolff",
    "Saint-Nom-la-Breteche",
    "Saint-Offenge-Dessous",
    "Saint-Offenge-Dessus",
    "Saint-Omer",
    "Saint-Omer-en-Chaussee",
    "Saint-Orens-de-Gameville",
    "Saint-Orens-Pouy-Petit",
    "Saint-Ouen",
    "Saint-Ouen",
    "Saint-Ouen-de-Thouberville",
    "Saint-Ouen-des-Besaces",
    "Saint-Ouen-des-Toits",
    "Saint-Ouen-du-Breuil",
    "Saint-Ouen-du-Mesnil-Oger",
    "Saint-Ouen-du-Tilleul",
    "Saint-Ouen-en-Brie",
    "Saint-Ouen-les-Parey",
    "Saint-Ouen-sur-Iton",
    "Saint-Ouen-sur-Morin",
    "Saint-Oulph",
    "Saint-Ours",
    "Saint-Pabu",
    "Saint-Pair-du-Mont",
    "Saint-Pair-sur-Mer",
    "Saint-Pal-de-Mons",
    "Saint-Palais",
    "Saint-Pancre",
    "Saint-Pandelon",
    "Saint-Pantaleon-de-Larche",
    "Saint-Papoul",
    "Saint-Pardoux-du-Breuil",
    "Saint-Pargoire",
    "Saint-Parize-le-Chatel",
    "Saint-Parres-aux-Tertres",
    "Saint-Parres-les-Vaudes",
    "Saint-Paterne",
    "Saint-Paterne-Racan",
    "Saint-Pathus",
    "Saint-Patrice-de-Claids",
    "Saint-Paul",
    "Saint-Paul",
    "Saint-Paul-de-Jarrat",
    "Saint-Paul-de-Varces",
    "Saint-Paul-de-Vence",
    "Saint-Paul-de-Vezelin",
    "Saint-Paul-du-Vernay",
    "Saint-Paul-en-Chablais",
    "Saint-Paul-en-Cornillon",
    "Saint-Paul-en-Foret",
    "Saint-Paul-en-Jarez",
    "Saint-Paul-en-Pareds",
    "Saint-Paul-les-Dax",
    "Saint-Paul-les-Durance",
    "Saint-Paul-les-Romans",
    "Saint-Paul-Trois-Chateaux",
    "Saint-Paulet-de-Caisson",
    "Saint-Pee-sur-Nivelle",
    "Saint-Pellerin",
    "Saint-Peray",
    "Saint-Perdon",
    "Saint-Pere",
    "Saint-Pere-en-Retz",
    "Saint-Pern",
    "Saint-Philbert-de-Bouaine",
    "Saint-Philbert-de-Grand-Lieu",
    "Saint-Philbert-du-Pont-Charrault",
    "Saint-Philibert",
    "Saint-Piat",
    "Saint-Pierre-Bois",
    "Saint-Pierre-Brouck",
    "Saint-Pierre-dAllevard",
    "Saint-Pierre-de-Boeuf",
    "Saint-Pierre-de-Bressieux",
    "Saint-Pierre-de-Chandieu",
    "Saint-Pierre-de-Jards",
    "Saint-Pierre-de-Lages",
    "Saint-Pierre-de-Maille",
    "Saint-Pierre-de-Mons",
    "Saint-Pierre-de-Plesguen",
    "Saint-Pierre-de-Trivisy",
    "Saint-Pierre-de-Varengeville",
    "Saint-Pierre-de-Varennes",
    "Saint-Pierre-des-Corps",
    "Saint-Pierre-des-Echaubrognes",
    "Saint-Pierre-des-Fleurs",
    "Saint-Pierre-du-Chemin",
    "Saint-Pierre-du-Mont",
    "Saint-Pierre-du-Palais",
    "Saint-Pierre-du-Perray",
    "Saint-Pierre-du-Regard",
    "Saint-Pierre-du-Vauvray",
    "Saint-Pierre-en-Port",
    "Saint-Pierre-en-Val",
    "Saint-Pierre-la-Bourlhonne",
    "Saint-Pierre-la-Cour",
    "Saint-Pierre-la-Garenne",
    "Saint-Pierre-la-Palud",
    "Saint-Pierre-Lavis",
    "Saint-Pierre-le-Chastel",
    "Saint-Pierre-le-Moutier",
    "Saint-Pierre-le-Vieux",
    "Saint-Pierre-le-Vieux",
    "Saint-Pierre-les-Elbeuf",
    "Saint-Pierre-Montlimart",
    "Saint-Pierre-sur-Dives",
    "Saint-Pierre-Tarentaine",
    "Saint-Pierreville",
    "Saint-Pol-sur-Mer",
    "Saint-Pol-sur-Ternoise",
    "Saint-Pompont",
    "Saint-Pont",
    "Saint-Porchaire",
    "Saint-Porquier",
    "Saint-Pouange",
    "Saint-Pourcain-sur-Sioule",
    "Saint-Prest",
    "Saint-Priest-des-Champs",
    "Saint-Priest-en-Jarez",
    "Saint-Priest-la-Roche",
    "Saint-Priest-la-Vetre",
    "Saint-Priest-Taurion",
    "Saint-Prim",
    "Saint-Privat",
    "Saint-Privat",
    "Saint-Privat-de-Vallongue",
    "Saint-Privat-la-Montagne",
    "Saint-Prix",
    "Saint-Projet",
    "Saint-Prouant",
    "Saint-Pryve-Saint-Mesmin",
    "Saint-Python",
    "Saint-Quay-Perros",
    "Saint-Quay-Portrieux",
    "Saint-Quentin",
    "Saint-Quentin-de-Baron",
    "Saint-Quentin-du-Dropt",
    "Saint-Quentin-en-Mauges",
    "Saint-Quentin-Fallavier",
    "Saint-Quentin-la-Poterie",
    "Saint-Quentin-le-Verger",
    "Saint-Quentin-les-Chardonnets",
    "Saint-Quentin-sur-Indrois",
    "Saint-Quentin-sur-le-Homme",
    "Saint-Rambert-en-Bugey",
    "Saint-Regle",
    "Saint-Remeze",
    "Saint-Remimont",
    "Saint-Remy",
    "Saint-Remy",
    "Saint-Remy",
    "Saint-Remy-du-Nord",
    "Saint-Remy-en-Mauges",
    "Saint-Remy-en-Rollat",
    "Saint-Remy-la-Vanne",
    "Saint-Remy-la-Varenne",
    "Saint-Remy-les-Chevreuse",
    "Saint-Remy-sur-Avre",
    "Saint-Remy-sur-Durolle",
    "Saint-Renan",
    "Saint-Reverien",
    "Saint-Riquier",
    "Saint-Rirand",
    "Saint-Robert",
    "Saint-Roch",
    "Saint-Rogatien",
    "Saint-Romain-de-Colbosc",
    "Saint-Romain-de-Lerps",
    "Saint-Romain-en-Gal",
    "Saint-Romain-en-Gier",
    "Saint-Romain-la-Motte",
    "Saint-Romain-la-Virvee",
    "Saint-Romain-Lachalm",
    "Saint-Romain-le-Preux",
    "Saint-Romain-le-Puy",
    "Saint-Romain-les-Atheux",
    "Saint-Rome-de-Tarn",
    "Saint-Saens",
    "Saint-Saire",
    "Saint-Samson-sur-Rance",
    "Saint-Sandoux",
    "Saint-Sardos",
    "Saint-Saturnin-du-Limet",
    "Saint-Saturnin-les-Apt",
    "Saint-Saturnin-les-Avignon",
    "Saint-Saturnin-sur-Loire",
    "Saint-Saulge",
    "Saint-Saulve",
    "Saint-Sauvant",
    "Saint-Sauveur-dAunis",
    "Saint-Sauveur-de-Montagut",
    "Saint-Sauveur-Gouvernet",
    "Saint-Sauveur-la-Sagne",
    "Saint-Sauveur-le-Vicomte",
    "Saint-Sauveur-Lendelin",
    "Saint-Savin",
    "Saint-Savin",
    "Saint-Savin",
    "Saint-Savinien",
    "Saint-Saviol",
    "Saint-Savournin",
    "Saint-Sebastien",
    "Saint-Sebastien",
    "Saint-Sebastien-de-Morsent",
    "Saint-Secondin",
    "Saint-Segal",
    "Saint-Seglin",
    "Saint-Seine-sur-Vingeanne",
    "Saint-Selve",
    "Saint-Senoch",
    "Saint-Senoux",
    "Saint-Series",
    "Saint-Sernin",
    "Saint-Sernin-du-Bois",
    "Saint-Servant",
    "Saint-Seurin-de-Cadourne",
    "Saint-Seurin-de-Cursac",
    "Saint-Seurin-de-Prats",
    "Saint-Sever",
    "Saint-Sever-Calvados",
    "Saint-Siffret",
    "Saint-Sigismond",
    "Saint-Simeon-de-Bressieux",
    "Saint-Simon",
    "Saint-Simon-de-Pellouaille",
    "Saint-Sixt",
    "Saint-Sixte",
    "Saint-Sorlin-en-Valloire",
    "Saint-Souplet",
    "Saint-Soupplets",
    "Saint-Suliac",
    "Saint-Sulpice-de-Faleyrens",
    "Saint-Sulpice-de-Royan",
    "Saint-Sulpice-et-Cameyrac",
    "Saint-Sulpice-la-Foret",
    "Saint-Sulpice-le-Vieux",
    "Saint-Sulpice-les-Feuilles",
    "Saint-Sulpice-sur-Leze",
    "Saint-Sulpice-sur-Risle",
    "Saint-Sylvain",
    "Saint-Sylvestre-sur-Lot",
    "Saint-Symphorien-de-Lay",
    "Saint-Symphorien-dOzon",
    "Saint-Symphorien-sur-Coise",
    "Saint-Symphorien-sur-Saone",
    "Saint-Theodorit",
    "Saint-Thibaud-de-Couz",
    "Saint-Thibault",
    "Saint-Thibault-des-Vignes",
    "Saint-Thibery",
    "Saint-Thierry",
    "Saint-Thurial",
    "Saint-Trivier-de-Courtes",
    "Saint-Trivier-sur-Moignans",
    "Saint-Trojan-les-Bains",
    "Saint-Tropez",
    "Saint-Tugdual",
    "Saint-Urbain",
    "Saint-Urbain",
    "Saint-Urbain-Maconcourt",
    "Saint-Usage",
    "Saint-Uze",
    "Saint-Vaast-du-Val",
    "Saint-Vaast-en-Auge",
    "Saint-Vaast-la-Hougue",
    "Saint-Vaast-les-Mello",
    "Saint-Valery-en-Caux",
    "Saint-Valery-sur-Somme",
    "Saint-Vallier",
    "Saint-Vallier",
    "Saint-Vallier-de-Thiey",
    "Saint-Varent",
    "Saint-Vaury",
    "Saint-Venant",
    "Saint-Verand",
    "Saint-Viance",
    "Saint-Viatre",
    "Saint-Viaud",
    "Saint-Victor-de-Cessieu",
    "Saint-Victor-la-Coste",
    "Saint-Victor-sur-Arlanc",
    "Saint-Victor-sur-Loire",
    "Saint-Victor-sur-Rhins",
    "Saint-Victoret",
    "Saint-Victurnien",
    "Saint-Vigor",
    "Saint-Vincent-de-Connezac",
    "Saint-Vincent-de-Durfort",
    "Saint-Vincent-de-Paul",
    "Saint-Vincent-de-Tyrosse",
    "Saint-Vincent-des-Landes",
    "Saint-Vincent-sur-Graon",
    "Saint-Vit",
    "Saint-Vital",
    "Saint-Vite",
    "Saint-Vivien",
    "Saint-Vivien-de-Medoc",
    "Saint-Vrain",
    "Saint-Vulbas",
    "Saint-Witz",
    "Saint-Xandre",
    "Saint-Ybars",
    "Saint-Yorre",
    "Saint-Yrieix-la-Perche",
    "Saint-Yrieix-sur-Charente",
    "Saint-Yvi",
    "Saint-Yzan-de-Soudiac",
    "Saint-Zacharie",
    "Sainte-Adresse",
    "Sainte-Anastasie-sur-Issole",
    "Sainte-Anne-Saint-Priest",
    "Sainte-Anne-sur-Vilaine",
    "Sainte-Austreberthe",
    "Sainte-Bazeille",
    "Sainte-Blandine",
    "Sainte-Catherine",
    "Sainte-Catherine",
    "Sainte-Cecile",
    "Sainte-Cecile",
    "Sainte-Colombe-de-Duras",
    "Sainte-Colombe-sur-Gand",
    "Sainte-Colombe-sur-Guette",
    "Sainte-Consorce",
    "Sainte-Croix",
    "Sainte-Croix-aux-Mines",
    "Sainte-Croix-en-Jarez",
    "Sainte-Croix-en-Plaine",
    "Sainte-Croix-Grand-Tonne",
    "Sainte-Croix-Hague",
    "Sainte-Croix-Volvestre",
    "Sainte-Eanne",
    "Sainte-Enimie",
    "Sainte-Eulalie-en-Born",
    "Sainte-Eusoye",
    "Sainte-Fauste",
    "Sainte-Fereole",
    "Sainte-Flaive-des-Loups",
    "Sainte-Florence",
    "Sainte-Florine",
    "Sainte-Fortunade",
    "Sainte-Foy-de-Longas",
    "Sainte-Foy-de-Peyrolieres",
    "Sainte-Foy-la-Grande",
    "Sainte-Foy-les-Lyon",
    "Sainte-Gemme-la-Plaine",
    "Sainte-Gemme-Martaillac",
    "Sainte-Genevieve",
    "Sainte-Genevieve",
    "Sainte-Helene",
    "Sainte-Hermine",
    "Sainte-Honorine-du-Fay",
    "Sainte-Livrade-sur-Lot",
    "Sainte-Luce-sur-Loire",
    "Sainte-Lucie de Porto-Vecchio",
    "Sainte-Lucie-de-Tallano",
    "Sainte-Marguerite",
    "Sainte-Marguerite-des-Loges",
    "Sainte-Marie",
    "Sainte-Marie-aux-Chenes",
    "Sainte-Marie-aux-Mines",
    "Sainte-Marie-Cappel",
    "Sainte-Marie-de-Chignac",
    "Sainte-Marie-de-Cuines",
    "Sainte-Marie-de-Re",
    "Sainte-Marie-du-Mont",
    "Sainte-Marie-Kerque",
    "Sainte-Marie-Laumont",
    "Sainte-Maure-de-Touraine",
    "Sainte-Maxime",
    "Sainte-Menehould",
    "Sainte-Mere-Eglise",
    "Sainte-Neomaye",
    "Sainte-Opportune-du-Bosc",
    "Sainte-Ouenne",
    "Sainte-Pazanne",
    "Sainte-Preuve",
    "Sainte-Radegonde",
    "Sainte-Reine-de-Bretagne",
    "Sainte-Sabine-sur-Longeve",
    "Sainte-Savine",
    "Sainte-Seve",
    "Sainte-Sigolene",
    "Sainte-Solange",
    "Sainte-Soulle",
    "Sainte-Suzanne",
    "Sainte-Terre",
    "Sainte-Tulle",
    "Sainte-Valiere",
    "Sainte-Verge",
    "Saintes",
    "Saintes-Maries-de-la-Mer",
    "Saintry-sur-Seine",
    "Saints",
    "Saints-Geosmes",
    "Sainville",
    "Saissac",
    "Saisseval",
    "Saivres",
    "Saix",
    "Salagnon",
    "Salaise-sur-Sanne",
    "Salans",
    "Salbert",
    "Salbris",
    "Saleich",
    "Saleilles",
    "Salernes",
    "Salers",
    "Sales",
    "Saleux",
    "Salies-de-Bearn",
    "Salies-du-Salat",
    "Saligny",
    "Saligny-le-Vif",
    "Saligny-sur-Roudon",
    "Salin-de-Giraud",
    "Salindres",
    "Salins",
    "Salins-les-Bains",
    "Salins-les-Thermes",
    "Sallanches",
    "Sallaumines",
    "Salleboeuf",
    "Sallertaine",
    "Salles-de-Barbezieux",
    "Salles-la-Source",
    "Salles-Mongiscard",
    "Salles-sur-Mer",
    "Salome",
    "Salon-de-Provence",
    "Salouel",
    "Salses-le-Chateau",
    "Salvagnac",
    "Salviac",
    "Salvizinet",
    "Salzuit",
    "Samadet",
    "Samatan",
    "Samazan",
    "Sambin",
    "Sameon",
    "Samer",
    "Samognat",
    "Samois-sur-Seine",
    "Samoreau",
    "Sampigny-les-Maranges",
    "Samson",
    "San-Giuliano",
    "San-Nicolao",
    "Sanary-sur-Mer",
    "Sancerre",
    "Sanchey",
    "Sancourt",
    "Sand",
    "Sandillon",
    "Sandouville",
    "Sandrans",
    "Sangatte",
    "Sanguinet",
    "Sannerville",
    "Sannois",
    "Sansais",
    "Sant Andreu de Sureda",
    "Sant Genis de Fontanes",
    "Sant Joan de Pladecorts",
    "Santa-Maria-Poggio",
    "Santeny",
    "Santes",
    "Santeuil",
    "Santranges",
    "Sanvignes-les-Mines",
    "Sanxay",
    "Saone",
    "Sapogne-et-Feucheres",
    "Sapogne-sur-Marche",
    "Saramon",
    "Saran",
    "Sarcelles",
    "Sarcey",
    "Sarcicourt",
    "Sardan",
    "Sardieu",
    "Sardon",
    "Sare",
    "Sarge-sur-Braye",
    "Sarliac-sur-lIsle",
    "Sarralbe",
    "Sarraltroff",
    "Sarrant",
    "Sarras",
    "Sarrazac",
    "Sarre",
    "Sarre-Union",
    "Sarrebourg",
    "Sarreguemines",
    "Sarrey",
    "Sarrians",
    "Sarrola-Carcopino",
    "Sarry",
    "Sars-Poteries",
    "Sartrouville",
    "Sarzeau",
    "Sassay",
    "Sassenage",
    "Sassenay",
    "Sassey-sur-Meuse",
    "Sathonay-Camp",
    "Sathonay-Village",
    "Satillieu",
    "Satolas-et-Bonce",
    "Saubens",
    "Saubion",
    "Saubrigues",
    "Saubusse",
    "Saucats",
    "Saudoy",
    "Saugon",
    "Saugues",
    "Saujon",
    "Saulces-Monclin",
    "Saulcy-sur-Meurthe",
    "Saules",
    "Saulge",
    "Saulieu",
    "Saulnes",
    "Saulnieres",
    "Saulnot",
    "Saulny",
    "Saulon-la-Chapelle",
    "Saulon-la-Rue",
    "Sault",
    "Sault-Brenaz",
    "Sault-les-Rethel",
    "Sault-Saint-Remy",
    "Saultain",
    "Saulx-les-Chartreux",
    "Saulx-Marchais",
    "Saulxures",
    "Saulxures-les-Nancy",
    "Saulxures-sur-Moselotte",
    "Saulzoir",
    "Saumane-de-Vaucluse",
    "Saumeray",
    "Saumur",
    "Saurat",
    "Sauret-Besserve",
    "Sausheim",
    "Saussan",
    "Saussay-la-Campagne",
    "Saussemesnil",
    "Saussenac",
    "Sausset-les-Pins",
    "Saussey",
    "Saussines",
    "Sautel",
    "Sauteyrargues",
    "Sautron",
    "Sauvage",
    "Sauvagney",
    "Sauvagnon",
    "Sauvat",
    "Sauve",
    "Sauverny",
    "Sauvessanges",
    "Sauveterre",
    "Sauveterre-de-Bearn",
    "Sauveterre-de-Comminges",
    "Sauveterre-de-Guyenne",
    "Sauveterre-la-Lemance",
    "Sauvian",
    "Sauvigney-les-Pesmes",
    "Sauvigny-les-Bois",
    "Sauxillanges",
    "Sauze",
    "Sauze-Vaussais",
    "Sauzelles",
    "Sauzet",
    "Sauzet",
    "Sauzon",
    "Savasse",
    "Savenay",
    "Savennieres",
    "Saverdun",
    "Saverne",
    "Savieres",
    "Savignac-les-Eglises",
    "Savignac-sur-Leyze",
    "Savigne",
    "Savigne-sous-le-Lude",
    "Savigneux",
    "Savigny",
    "Savigny-en-Revermont",
    "Savigny-en-Septaine",
    "Savigny-le-Sec",
    "Savigny-le-Temple",
    "Savigny-les-Beaune",
    "Savigny-sur-Braye",
    "Savigny-sur-Clairis",
    "Savigny-sur-Orge",
    "Savoie",
    "Savonnieres",
    "Savouges",
    "Savoyeux",
    "Savy",
    "Savy-Berlette",
    "Sayat",
    "Scaer",
    "Sceaux",
    "Sceaux-du-Gatinais",
    "Sceaux-sur-Huisne",
    "Scharrachbergheim-Irmstett",
    "Scherwiller",
    "Schillersdorf",
    "Schiltigheim",
    "Schirmeck",
    "Schirrhein",
    "Schmittviller",
    "Schnersheim",
    "Schoeneck",
    "Schopperten",
    "Schorbach",
    "Schweighouse-Thann",
    "Schwenheim",
    "Scientrier",
    "Sciez",
    "Scionzier",
    "Scorbe-Clairvaux",
    "Scrignac",
    "Scy-Chazelles",
    "Sebazac-Concoures",
    "Sebecourt",
    "Sebourg",
    "Secheval",
    "Seclin",
    "Secondigny",
    "Sedan",
    "Sedzere",
    "Sees",
    "Seez",
    "Segalas",
    "Segny",
    "Segonzac",
    "Segonzat",
    "Segre",
    "Segrie",
    "Segry",
    "Seichamps",
    "Seiches-sur-le-Loir",
    "Seignalens",
    "Seignelay",
    "Seignosse",
    "Seilh",
    "Seilhac",
    "Seine-Port",
    "Seingbouse",
    "Seissan",
    "Selle",
    "Selles",
    "Selles-Saint-Denis",
    "Selles-sur-Cher",
    "Selles-sur-Nahon",
    "Sellieres",
    "Selommes",
    "Seloncourt",
    "Selongey",
    "Seltz",
    "Semalens",
    "Semblancay",
    "Semeac",
    "Semeacq-Blachon",
    "Semecourt",
    "Semoine",
    "Semoutiers-Montsaon",
    "Semoy",
    "Sempigny",
    "Semur-en-Brionnais",
    "Senan",
    "Senas",
    "Sendets",
    "Sene",
    "Senlecques",
    "Senlis",
    "Sennece-les-Macon",
    "Sennecey-le-Grand",
    "Sennely",
    "Senneville-sur-Fecamp",
    "Senonches",
    "Senones",
    "Senonges",
    "Senozan",
    "Sens",
    "Sens-de-Bretagne",
    "Sentheim",
    "Senuc",
    "Sepmeries",
    "Seppois-le-Bas",
    "Seppois-le-Haut",
    "Sept-Forges",
    "Sept-Saulx",
    "Septeme",
    "Septemes-les-Vallons",
    "Septeuil",
    "Septfonds",
    "Septvaux",
    "Sequedin",
    "Seraincourt",
    "Serans",
    "Seraucourt-le-Grand",
    "Serbannes",
    "Serdinya",
    "Sereilhac",
    "Serein",
    "Seremange-Erzange",
    "Serent",
    "Serezin-de-la-Tour",
    "Serezin-du-Rhone",
    "Sergeac",
    "Sergy",
    "Serifontaine",
    "Serignan",
    "Serignan-du-Comtat",
    "Serigne",
    "Sermaises",
    "Sermamagny",
    "Sermerieu",
    "Sermoise",
    "Sermoyer",
    "Sernhac",
    "Serocourt",
    "Seronville",
    "Serques",
    "Serquigny",
    "Serre-les-Sapins",
    "Serres",
    "Serres-Castet",
    "Serres-Gaston",
    "Serres-Morlaas",
    "Serres-Sainte-Marie",
    "Serres-sur-Arget",
    "Serrieres",
    "Serrieres-de-Briord",
    "Serrieres-en-Chautagne",
    "Serris",
    "Serrouville",
    "Sers",
    "Servais",
    "Servance",
    "Servant",
    "Servas",
    "Servaville-Salmonville",
    "Servian",
    "Servon",
    "Servon-sur-Vilaine",
    "Sery",
    "Sery-les-Mezieres",
    "Sessenheim",
    "Seugy",
    "Seuil",
    "Seuillet",
    "Seurre",
    "Sevelinges",
    "Severac",
    "Severac-le-Chateau",
    "Seveux",
    "Sevran",
    "Sevres-Anxaumont",
    "Sevrey",
    "Sevrier",
    "Sewen",
    "Sexey-aux-Forges",
    "Seyches",
    "Seyne-les-Alpes",
    "Seynod",
    "Seyre",
    "Seyssel",
    "Seysses",
    "Seyssinet",
    "Seyssinet-Pariset",
    "Seyssins",
    "Seyssuel",
    "Sezanne",
    "Sibiril",
    "Siccieu-Saint-Julien-et-Carisieu",
    "Siegen",
    "Sierck-les-Bains",
    "Sierentz",
    "Sierville",
    "Sigean",
    "Sigloy",
    "Signes",
    "Signy",
    "Signy-le-Petit",
    "Signy-Signets",
    "Sigogne",
    "Sigoules",
    "Sigournais",
    "Sigy-en-Bray",
    "Silfiac",
    "Sillans",
    "Sille-le-Guillaume",
    "Sillery",
    "Sillingy",
    "Silly-la-Poterie",
    "Silly-le-Long",
    "Silly-sur-Nied",
    "Silly-Tillard",
    "Simandre",
    "Simandres",
    "Simiane-Collongue",
    "Simorre",
    "Sin-le-Noble",
    "Sinard",
    "Sinceny",
    "Sion-les-Mines",
    "Siran",
    "Sireuil",
    "Siros",
    "Sisco",
    "Sissonne",
    "Sissy",
    "Sisteron",
    "Sivry-Courtry",
    "Sivry-sur-Meuse",
    "Six-Fours-les-Plages",
    "Sizun",
    "Smarves",
    "Sochaux",
    "Soignolles-en-Brie",
    "Soing-Cubry-Charentenay",
    "Soissons",
    "Soissons-sur-Nacey",
    "Soisy-sous-Montmorency",
    "Soisy-sur-Ecole",
    "Soisy-sur-Seine",
    "Soize",
    "Solaize",
    "Solaro",
    "Solers",
    "Solesmes",
    "Solesmes",
    "Soleymieux",
    "Solferino",
    "Solgne",
    "Soliers",
    "Solignac",
    "Solignac-sous-Roche",
    "Solignac-sur-Loire",
    "Soligny-la-Trappe",
    "Sollies-Pont",
    "Sollies-Toucas",
    "Sollies-Ville",
    "Sologny",
    "Somain",
    "Sombacour",
    "Sombernon",
    "Somloire",
    "Sommaing",
    "Somme",
    "Sommecaise",
    "Sommedieue",
    "Sommelonne",
    "Sommerance",
    "Sommervieu",
    "Sommerviller",
    "Sommieres",
    "Sommieres-du-Clain",
    "Sonchamp",
    "Sondernach",
    "Songeons",
    "Sonnay",
    "Sonnaz",
    "Sonzay",
    "Soorts-Hossegor",
    "Sorbey",
    "Sorbiers",
    "Sorcy-Saint-Martin",
    "Sore",
    "Sorede",
    "Sorel-en-Vimeu",
    "Sorel-Moussel",
    "Soreze",
    "Sorges",
    "Sorgues",
    "Sorigny",
    "Sormonne",
    "Sornay",
    "Sorquainville",
    "Sorrus",
    "Sort-en-Chalosse",
    "Sospel",
    "Sottevast",
    "Sotteville",
    "Sotteville-sur-Mer",
    "Souastre",
    "Soubise",
    "Soublecause",
    "Souchez",
    "Soucht",
    "Soucieu-en-Jarrest",
    "Soucy",
    "Soudan",
    "Soues",
    "Souesmes",
    "Souffelweyersheim",
    "Soufflenheim",
    "Sougy",
    "Sougy-sur-Loire",
    "Souilhanels",
    "Souillac",
    "Soulac-sur-Mer",
    "Soulaincourt",
    "Soulaines-Dhuys",
    "Soulaines-sur-Aubance",
    "Soulaire-et-Bourg",
    "Soulanges",
    "Soulangis",
    "Soulge-sur-Ouette",
    "Soulieres",
    "Souligne-sous-Ballon",
    "Soulitre",
    "Soullans",
    "Soultz-Haut-Rhin",
    "Soultz-sous-Forets",
    "Soultzeren",
    "Soultzmatt",
    "Soulvache",
    "Soumeras",
    "Soumoulou",
    "Souppes-sur-Loing",
    "Souprosse",
    "Souraide",
    "Sourcieux-les-Mines",
    "Sourdeval",
    "Sourdon",
    "Sourdun",
    "Sourribes",
    "Sours",
    "Souspierre",
    "Soustons",
    "Souvans",
    "Souvigne",
    "Souvigne-sur-Sarthe",
    "Souvigny",
    "Souvigny-en-Sologne",
    "Souzay-Champigny",
    "Souzy",
    "Souzy-la-Briche",
    "Soyaux",
    "Soyers",
    "Soyons",
    "Spay",
    "Spechbach-le-Bas",
    "Spechbach-le-Haut",
    "Speracedes",
    "Spezet",
    "Spicheren",
    "Spincourt",
    "Sponville",
    "Spycker",
    "Squiffiec",
    "St Chamas",
    "St Laurent des Arbres",
    "St-Malo",
    "Staffelfelden",
    "Stains",
    "Steenbecque",
    "Steenvoorde",
    "Steenwerck",
    "Steige",
    "Steinbach",
    "Steinbourg",
    "Stella-Plage",
    "Stenay",
    "Still",
    "Stiring-Wendel",
    "Stosswihr",
    "Strasbourg",
    "Strazeele",
    "Strueth",
    "Succieu",
    "Suce-sur-Erdre",
    "Sucy-en-Brie",
    "Suhescun",
    "Suin",
    "Suippes",
    "Sully-la-Chapelle",
    "Sully-sur-Loire",
    "Sulniac",
    "Sundhoffen",
    "Supt",
    "Surat",
    "Surba",
    "Suresnes",
    "Surgeres",
    "Surgy",
    "Surin",
    "Surques",
    "Surtainville",
    "Surville",
    "Surville",
    "Survilliers",
    "Sury-en-Vaux",
    "Sury-le-Comtal",
    "Surzur",
    "Sus",
    "Sussargues",
    "Suzanne",
    "Suze-la-Rousse",
    "Tabanac",
    "Tacoignieres",
    "Taden",
    "Taglio-Isolaccio",
    "Tagnon",
    "Taillades",
    "Taillebourg",
    "Taillis",
    "Tailly",
    "Taintrux",
    "Taissy",
    "Taize-Aizie",
    "Tajan",
    "Talange",
    "Talant",
    "Talence",
    "Talensac",
    "Tallard",
    "Tallende",
    "Taller",
    "Talloires",
    "Tallud-Sainte-Gemme",
    "Talon",
    "Taluyers",
    "Tamnay-en-Bazois",
    "Tancrou",
    "Taninges",
    "Tanneron",
    "Taradeau",
    "Tarare",
    "Tarascon",
    "Tarascon-sur-Ariege",
    "Tarbes",
    "Tarcenay",
    "Tarentaise",
    "Targassonne",
    "Targon",
    "Tarnes",
    "Tarnos",
    "Tartaras",
    "Tartas",
    "Tartigny",
    "Tassille",
    "Tassin-la-Demi-Lune",
    "Tatinghem",
    "Taule",
    "Taulignan",
    "Taupont",
    "Tauriac",
    "Tauriac-de-Camares",
    "Tautavel",
    "Tauxieres-Mutry",
    "Tauxigny",
    "Tavaux",
    "Tavel",
    "Tavera",
    "Tavernes",
    "Taverny",
    "Tavers",
    "Tavey",
    "Tayrac",
    "Tecou",
    "Teillet-Argenty",
    "Teissieres-de-Cornet",
    "Telgruc-sur-Mer",
    "Teloche",
    "Templemars",
    "Templeuve",
    "Tence",
    "Tende",
    "Tendon",
    "Terce",
    "Tercis-les-Bains",
    "Terdeghem",
    "Tergnier",
    "Ternand",
    "Ternay",
    "Ternay",
    "Terrasson-Lavilledieu",
    "Terraube",
    "Terrebasse",
    "Terssac",
    "Terves",
    "Terville",
    "Tessy-sur-Vire",
    "Teteghem",
    "Teuillac",
    "Teurtheville-Hague",
    "Teyjat",
    "Teyran",
    "Thaims",
    "Thaire",
    "Thal-Drulingen",
    "Thann",
    "Thaon",
    "Thaon-les-Vosges",
    "Thauron",
    "Theding",
    "Thegra",
    "Theil-sur-Vanne",
    "Theillay",
    "Theix",
    "Theize",
    "Thelus",
    "Themericourt",
    "Thenay",
    "Thenelles",
    "Thenezay",
    "Thenissey",
    "Thennelieres",
    "Thennes",
    "Thenon",
    "Theoule-sur-Mer",
    "Therdonne",
    "Therouanne",
    "Theuville",
    "Theuville-aux-Maillots",
    "Theys",
    "Thezan-les-Beziers",
    "Theze",
    "Theziers",
    "Thezy-Glimont",
    "Thiais",
    "Thiant",
    "Thiberville",
    "Thiefosse",
    "Thiergeville",
    "Thiers",
    "Thiers-sur-Theve",
    "Thierville",
    "Thierville-sur-Meuse",
    "Thietreville",
    "Thieuloy-Saint-Antoine",
    "Thieville",
    "Thil",
    "Thil",
    "Thilay",
    "Thilouze",
    "Thimert-Gatelles",
    "Thimory",
    "Thin-le-Moutier",
    "Thionville",
    "Thise",
    "Thivars",
    "Thivencelle",
    "Thiverval-Grignon",
    "Thiviers",
    "Thizy-les-Bourgs",
    "Thodure",
    "Thoirette",
    "Thoiry",
    "Thoiry",
    "Thoissey",
    "Thomery",
    "Thones",
    "Thonnance-les-Joinville",
    "Thonon-les-Bains",
    "Thoraise",
    "Thorens-Glieres",
    "Thorigny",
    "Thorigny-sur-Marne",
    "Thou",
    "Thouare-sur-Loire",
    "Thouars",
    "Thourotte",
    "Thoux",
    "Thuir",
    "Thuit-Hebert",
    "Thulay",
    "Thumeries",
    "Thun-Saint-Amand",
    "Thurageau",
    "Thure",
    "Thuret",
    "Thurins",
    "Thury-Harcourt",
    "Thusy",
    "Thyez",
    "Tierce",
    "Tiercelet",
    "Tigery",
    "Tignes",
    "Tignieu-Jameyzieu",
    "Tigy",
    "Til-Chatel",
    "Tillay-le-Peneux",
    "Tille",
    "Tillenay",
    "Tillieres",
    "Tillieres-sur-Avre",
    "Tilloy-et-Bellay",
    "Tilloy-lez-Cambrai",
    "Tilloy-lez-Marchiennes",
    "Tilly-sur-Meuse",
    "Tilques",
    "Tinchebray",
    "Tincques",
    "Tincry",
    "Tinqueux",
    "Tinteniac",
    "Tiranges",
    "Tirepied",
    "Tivernon",
    "Tocqueville",
    "Tocqueville",
    "Tocqueville-en-Caux",
    "Tollevast",
    "Tombeboeuf",
    "Tomblaine",
    "Tonnay-Boutonne",
    "Tonnay-Charente",
    "Tonneins",
    "Tonnerre",
    "Tonnoy",
    "Torce-en-Vallee",
    "Torcieu",
    "Torcy",
    "Torcy",
    "Torcy-le-Grand",
    "Torderes",
    "Torfou",
    "Torigni-sur-Vire",
    "Torpes",
    "Torreilles",
    "Torsac",
    "Torvilliers",
    "Torxe",
    "Tosse",
    "Tossiat",
    "Totes",
    "Touchay",
    "Toucy",
    "Toudon",
    "Toufflers",
    "Toul",
    "Toulaud",
    "Toulenne",
    "Toulon",
    "Toulon-sur-Arroux",
    "Toulouges",
    "Toulouse",
    "Toulouzette",
    "Toulx-Sainte-Croix",
    "Touques",
    "Touquin",
    "Tour-en-Sologne",
    "Tourbes",
    "Tourcoing",
    "Tourgeville",
    "Tourlaville",
    "Tourly",
    "Tournan-en-Brie",
    "Tournay-sur-Odon",
    "Tournecoupe",
    "Tournedos-Bois-Hubert",
    "Tournefeuille",
    "Tournehem-sur-la-Hem",
    "Tournieres",
    "Tournoisis",
    "Tournon-Saint-Martin",
    "Tournus",
    "Tourouvre",
    "Tourrette-Levens",
    "Tourrettes-sur-Loup",
    "Tourriers",
    "Tours",
    "Tours-en-Savoie",
    "Tours-sur-Marne",
    "Tours-sur-Meymont",
    "Tourtrol",
    "Tourves",
    "Tourville-en-Auge",
    "Tourville-la-Campagne",
    "Tourville-la-Chapelle",
    "Tourville-la-Riviere",
    "Tourville-les-Ifs",
    "Tourville-sur-Odon",
    "Tourville-sur-Sienne",
    "Toury",
    "Toussaint",
    "Toussieu",
    "Toussieux",
    "Toussus-le-Noble",
    "Toutlemonde",
    "Touville",
    "Touvois",
    "Touvre",
    "Touzac",
    "Tracy-le-Mont",
    "Tracy-le-Val",
    "Tracy-sur-Loire",
    "Traenheim",
    "Trainel",
    "Trainou",
    "Tramole",
    "Tramoyes",
    "Trange",
    "Trans-en-Provence",
    "Trappes",
    "Traubach-le-Bas",
    "Trausse",
    "Travecy",
    "Treauville",
    "Trebes",
    "Trebeurden",
    "Trebry",
    "Treclun",
    "Tredarzec",
    "Treduder",
    "Treffendel",
    "Treffieux",
    "Trefflean",
    "Treffort",
    "Treffrin",
    "Treflez",
    "Tregarantec",
    "Tregastel",
    "Treglamus",
    "Tregourez",
    "Treguidel",
    "Tregunc",
    "Treignac",
    "Treillieres",
    "Treize-Septiers",
    "Trelaze",
    "Trelevern",
    "Trelissac",
    "Trelivan",
    "Trelly",
    "Trelon",
    "Tremblay",
    "Tremblay",
    "Tremel",
    "Trementines",
    "Tremery",
    "Tremeven",
    "Tremilly",
    "Tremons",
    "Tremont-sur-Saulx",
    "Tremuson",
    "Treon",
    "Trepied",
    "Trept",
    "Tresilley",
    "Tresques",
    "Tressan",
    "Tressange",
    "Tresserve",
    "Tresses",
    "Trets",
    "Treuzy-Levelay",
    "Treve",
    "Treveneuc",
    "Treveray",
    "Treverien",
    "Treves",
    "Trevien",
    "Trevignin",
    "Trevoux",
    "Trezioux",
    "Triaize",
    "Tricot",
    "Trie-Chateau",
    "Trie-sur-Baise",
    "Triel-sur-Seine",
    "Trieux",
    "Trigavou",
    "Trignac",
    "Trigueres",
    "Trilbardou",
    "Trilport",
    "Triors",
    "Trith-Saint-Leger",
    "Trizac",
    "Trizay",
    "Troarn",
    "Troche",
    "Trogues",
    "Troissereux",
    "Troissy",
    "Troisvilles",
    "Tronville-en-Barrois",
    "Trosly-Breuil",
    "Trouhans",
    "Trouillas",
    "Trouley-Labarthe",
    "Troussencourt",
    "Troussey",
    "Trouville",
    "Trouville-la-Haule",
    "Trouville-sur-Mer",
    "Trouy",
    "Troyes",
    "Troyon",
    "Truchtersheim",
    "Trumilly",
    "Truyes",
    "Tubersent",
    "Tuchan",
    "Tucquegnieux",
    "Tuffe",
    "Tulle",
    "Tullins",
    "Tupigny",
    "Turckheim",
    "Turquant",
    "Tursac",
    "Tuzaguet",
    "Uberach",
    "Uchacq-et-Parentis",
    "Uchaud",
    "Uchaux",
    "Uckange",
    "Ueberstrass",
    "Ugine",
    "Ugny-sur-Meuse",
    "Uhrwiller",
    "Ully-Saint-Georges",
    "Umpeau",
    "Undurein",
    "Ungersheim",
    "Unieux",
    "Ur",
    "Urbeis",
    "Urcay",
    "Urcel",
    "Urcuit",
    "Urdos",
    "Uriage-les-Bains",
    "Urmatt",
    "Urou-et-Crennes",
    "Urrugne",
    "Urt",
    "Uruffe",
    "Urval",
    "Urville-Nacqueville",
    "Ury",
    "Urzy",
    "Us",
    "Ussac",
    "Usseau",
    "Ussel",
    "Usson-du-Poitou",
    "Ussy",
    "Ustaritz",
    "Utelle",
    "Uvernet-Fours",
    "Uxegney",
    "Uxem",
    "Uzein",
    "Uzerche",
    "Uzos",
    "Vaas",
    "Vacheresse",
    "Vacognes-Neuilly",
    "Vacon",
    "Vacquieres",
    "Vacquiers",
    "Vadenay",
    "Vadencourt",
    "Vagney",
    "Vahl-les-Benestroff",
    "Vailhauques",
    "Vaillant",
    "Vailly",
    "Vailly",
    "Vailly-sur-Aisne",
    "Vailly-sur-Sauldre",
    "Vaire",
    "Vaire-sous-Corbie",
    "Vaires-sur-Marne",
    "Vaison-la-Romaine",
    "Vaissac",
    "Vaivre-et-Montoille",
    "Val-de-Fier",
    "Val-de-la-Haye",
    "Val-et-Chatillon",
    "Valaire",
    "Valay",
    "Valberg",
    "Valbonne",
    "Valcourt",
    "Valdahon",
    "Valdampierre",
    "Valdoie",
    "Valdurenque",
    "Valencay",
    "Valence",
    "Valence",
    "Valence-sur-Baise",
    "Valenciennes",
    "Valencin",
    "Valencogne",
    "Valensole",
    "Valentigney",
    "Valenton",
    "Valescourt",
    "Valff",
    "Valflaunes",
    "Valines",
    "Vallangoujard",
    "Vallans",
    "Vallant-Saint-Georges",
    "Vallauris",
    "Valle-di-Mezzana",
    "Vallegue",
    "Valleiry",
    "Valleraugue",
    "Valleres",
    "Vallerois-Lorioz",
    "Valleroy-le-Sec",
    "Vallesvilles",
    "Vallet",
    "Vallier",
    "Valliguieres",
    "Valliquerville",
    "Vallon-en-Sully",
    "Vallouise",
    "Valmestroff",
    "Valmondois",
    "Valmont",
    "Valmont",
    "Valmy",
    "Valognes",
    "Valpuiseaux",
    "Valras-Plage",
    "Valreas",
    "Valros",
    "Vals-les-Bains",
    "Vals-pres-le-Puy",
    "Vanclans",
    "Vandieres",
    "Vandoeuvre-les-Nancy",
    "Vandoncourt",
    "Vandre",
    "Vandrimare",
    "Vandy",
    "Vannes",
    "Vanosc",
    "Vantoux",
    "Vanves",
    "Vanville",
    "Vanzac",
    "Varades",
    "Varages",
    "Varaignes",
    "Varaize",
    "Varanges",
    "Varangeville",
    "Varaville",
    "Varces-Allieres-et-Risset",
    "Varen",
    "Varennes",
    "Varennes",
    "Varennes-en-Argonne",
    "Varennes-Jarcy",
    "Varennes-le-Grand",
    "Varennes-les-Macon",
    "Varennes-sous-Dun",
    "Varennes-sur-Allier",
    "Varennes-sur-Loire",
    "Varennes-sur-Seine",
    "Varennes-Vauzelles",
    "Varesnes",
    "Varetz",
    "Varilhes",
    "Varinfroy",
    "Varois-et-Chaignot",
    "Varrains",
    "Varreddes",
    "Vars",
    "Vars",
    "Varsberg",
    "Varzay",
    "Varzy",
    "Vasles",
    "Vassel",
    "Vasselay",
    "Vassy",
    "Vasteville",
    "Vatan",
    "Vathimenil",
    "Vatimesnil",
    "Vauban",
    "Vaubecourt",
    "Vauchamps",
    "Vauchelles",
    "Vauchelles-les-Domart",
    "Vauchelles-les-Quesnoy",
    "Vauchonvilliers",
    "Vaucouleurs",
    "Vaucresson",
    "Vaudebarrier",
    "Vaudemange",
    "Vaudesson",
    "Vaudeville",
    "Vaudoy-en-Brie",
    "Vaudreching",
    "Vaudrey",
    "Vaugneray",
    "Vaugrigneuse",
    "Vauhallan",
    "Vaujours",
    "Vaulry",
    "Vault-de-Lugny",
    "Vaulx-en-Velin",
    "Vaulx-Milieu",
    "Vaumas",
    "Vaumoise",
    "Vaumort",
    "Vaureal",
    "Vaureilles",
    "Vauvert",
    "Vauville",
    "Vauvillers",
    "Vaux-en-Bugey",
    "Vaux-la-Petite",
    "Vaux-le-Penil",
    "Vaux-les-Pres",
    "Vaux-les-Saint-Claude",
    "Vaux-sur-Aure",
    "Vaux-sur-Blaise",
    "Vaux-sur-Eure",
    "Vaux-sur-Mer",
    "Vaux-sur-Poligny",
    "Vaux-sur-Seine",
    "Vauxbuin",
    "Vauxtin",
    "Vaxoncourt",
    "Vay",
    "Vayrac",
    "Vayres",
    "Veauche",
    "Veaugues",
    "Vecoux",
    "Vecquemont",
    "Vecqueville",
    "Vedene",
    "Veho",
    "Veigne",
    "Veigy-Foncenex",
    "Velaine-en-Haye",
    "Velaines",
    "Velars-sur-Ouche",
    "Velaux",
    "Velines",
    "Velle-le-Chatel",
    "Velleches",
    "Velleminfroy",
    "Velleron",
    "Velotte-et-Tatignecourt",
    "Velye",
    "Velzic",
    "Vemars",
    "Venables",
    "Venansault",
    "Venant",
    "Venarey-les-Laumes",
    "Venasque",
    "Vence",
    "Vendargues",
    "Vendat",
    "Vendays-Montalivet",
    "Vendegies-sur-Ecaillon",
    "Vendemian",
    "Vendenheim",
    "Vendeuil-Caply",
    "Vendeuvre-du-Poitou",
    "Vendeville",
    "Vendin-le-Vieil",
    "Vendin-les-Bethune",
    "Vendome",
    "Vendrennes",
    "Vendres",
    "Venejan",
    "Venelles",
    "Venerand",
    "Venerque",
    "Venette",
    "Veneux-les-Sablons",
    "Venizel",
    "Venizy",
    "Vennecy",
    "Venon",
    "Venoy",
    "Ventabren",
    "Ventes-Saint-Remy",
    "Venteuil",
    "Venthon",
    "Ventiseri",
    "Ver-sur-Launette",
    "Verac",
    "Verargues",
    "Verberie",
    "Verchaix",
    "Verchamp",
    "Verchin",
    "Verdelais",
    "Verderonne",
    "Verdon",
    "Verdun",
    "Verdun-en-Lauragais",
    "Verdun-sur-Garonne",
    "Verdun-sur-le-Doubs",
    "Veretz",
    "Verfeil",
    "Vergeze",
    "Vergongheon",
    "Vergt",
    "Veria",
    "Verigny",
    "Verines",
    "Verjux",
    "Verlinghem",
    "Vermand",
    "Vermelles",
    "Vermenton",
    "Vern-sur-Seiche",
    "Vernaison",
    "Verne",
    "Verne",
    "Vernegues",
    "Verneil-le-Chetif",
    "Vernet-les-Bains",
    "Verneugheol",
    "Verneuil",
    "Verneuil-en-Bourbonnais",
    "Verneuil-en-Halatte",
    "Verneuil-sous-Coucy",
    "Verneuil-sur-Avre",
    "Verneuil-sur-Seine",
    "Verneuil-sur-Vienne",
    "Vernierfontaine",
    "Vernines",
    "Verniolle",
    "Vernioz",
    "Vernon",
    "Vernon",
    "Vernosc-les-Annonay",
    "Vernou-sur-Brenne",
    "Vernouillet",
    "Vernouillet",
    "Vernoux-en-Vivarais",
    "Verny",
    "Veron",
    "Verpillieres",
    "Verquieres",
    "Verquigneul",
    "Verquin",
    "Verrey-sous-Salmaise",
    "Verrieres",
    "Verrieres",
    "Verrieres-le-Buisson",
    "Vers",
    "Vers-en-Montagne",
    "Vers-Pont-du-Gard",
    "Vers-sur-Selles",
    "Versailles",
    "Versailleux",
    "Versigny",
    "Verson",
    "Versonnex",
    "Vert",
    "Vert-en-Drouais",
    "Vert-le-Grand",
    "Vert-le-Petit",
    "Vert-Saint-Denis",
    "Vertaizon",
    "Verthemex",
    "Vertolaye",
    "Verton",
    "Vertou",
    "Vertrieu",
    "Vertus",
    "Vervezelle",
    "Vervins",
    "Very",
    "Verze",
    "Verzeille",
    "Verzenay",
    "Vescovato",
    "Vesigneul-sur-Coole",
    "Vesly",
    "Vesoul",
    "Vestric-et-Candiac",
    "Vetheuil",
    "Vetraz-Monthoux",
    "Veuil",
    "Veurey-Voroize",
    "Veynes",
    "Veyrac",
    "Veyras",
    "Veyre-Monton",
    "Veyrier-du-Lac",
    "Veyrignac",
    "Vezac",
    "Vezelise",
    "Vezenobres",
    "Vezezoux",
    "Vezin-le-Coquet",
    "Vezins",
    "Viabon",
    "Vialas",
    "Vianne",
    "Viarmes",
    "Vias",
    "Vibersviller",
    "Vibraye",
    "Vic-en-Bigorre",
    "Vic-Fezensac",
    "Vic-la-Gardiole",
    "Vic-le-Comte",
    "Vic-le-Fesq",
    "Vic-sur-Aisne",
    "Vic-sur-Cere",
    "Vic-sur-Seille",
    "Vichy",
    "Vico",
    "Vicq",
    "Vidauban",
    "Videix",
    "Vieille-Brioude",
    "Vieille-Eglise",
    "Vieille-Eglise-en-Yvelines",
    "Vieillevigne",
    "Vieillevigne",
    "Vieilley",
    "Vielle-Saint-Girons",
    "Vielle-Tursan",
    "Viellespeze",
    "Vielmur-sur-Agout",
    "Viels-Maisons",
    "Vienne",
    "Vienville",
    "Vierville",
    "Vierzon",
    "Viesly",
    "Viessoix",
    "Vieux",
    "Vieux",
    "Vieux Conde",
    "Vieux-Berquin",
    "Vieux-Boucau-les-Bains",
    "Vieux-Charmont",
    "Vieux-Conde",
    "Vieux-Ferrette",
    "Vieux-Fume",
    "Vieux-Manoir",
    "Vieux-Moulin",
    "Vieux-Rouen-sur-Bresle",
    "Vieux-Thann",
    "Vif",
    "Vigeois",
    "Vignacourt",
    "Vignes",
    "Vignes",
    "Vigneulles",
    "Vigneulles-les-Hattonchatel",
    "Vigneux-de-Bretagne",
    "Vigneux-sur-Seine",
    "Vignoc",
    "Vignolles",
    "Vignols",
    "Vignonet",
    "Vignot",
    "Vigny",
    "Vigny",
    "Vigny",
    "Vigoulet-Auzil",
    "Vigouroux",
    "Vigueron",
    "Vigy",
    "Vihiers",
    "Vilallonga dels Monts",
    "Villabe",
    "Villabon",
    "Villacourt",
    "Village-Neuf",
    "Villaines-la-Juhel",
    "Villaines-les-Rochers",
    "Villaines-sous-Bois",
    "Villaines-sous-Luce",
    "Villaines-sous-Malicorne",
    "Villamblard",
    "Villamee",
    "Villard",
    "Villard-Bonnot",
    "Villard-de-Lans",
    "Villard-Saint-Christophe",
    "Villard-sur-Doron",
    "Villards",
    "Villargondran",
    "Villaries",
    "Villarlurin",
    "Villars",
    "Villars-Brandis",
    "Villars-Colmars",
    "Villars-en-Pons",
    "Villars-et-Villenotte",
    "Villars-les-Dombes",
    "Villars-sur-Var",
    "Villarzel-Cabardes",
    "Villasavary",
    "Villaz",
    "Ville",
    "Ville",
    "Ville-du-Pont",
    "Ville-en-Tardenois",
    "Ville-la-Grand",
    "Ville-sous-Anjou",
    "Ville-sur-Cousances",
    "Ville-sur-Jarnioux",
    "Ville-sur-Lumes",
    "Ville-sur-Tourbe",
    "Villebarou",
    "Villebernier",
    "Villeblevin",
    "Villebois-Lavalette",
    "Villebon-sur-Yvette",
    "Villebret",
    "Villebrumier",
    "Villecerf",
    "Villeconin",
    "Villecresnes",
    "Villecroze",
    "Villedieu",
    "Villedieu",
    "Villedieu-les-Poeles",
    "Villedomer",
    "Villedoux",
    "Villefagnan",
    "Villefontaine",
    "Villefranche-de-Lauragais",
    "Villefranche-de-Rouergue",
    "Villefranche-du-Queyran",
    "Villefranche-le-Chateau",
    "Villefranche-sur-Cher",
    "Villefranche-sur-Mer",
    "Villefranque",
    "Villegailhenc",
    "Villegats",
    "Villegouge",
    "Villejesus",
    "Villejoubert",
    "Villejuif",
    "Villejust",
    "Villelaure",
    "Villelongue-de-la-Salanque",
    "Villemandeur",
    "Villemarechal",
    "Villembray",
    "Villemeux-sur-Eure",
    "Villemoirieu",
    "Villemoisson-sur-Orge",
    "Villemolaque",
    "Villemomble",
    "Villemort",
    "Villemotier",
    "Villemoustaussou",
    "Villemoyenne",
    "Villemur",
    "Villemur-sur-Tarn",
    "Villemurlin",
    "Villenauxe-la-Grande",
    "Villenave",
    "Villeneuve",
    "Villeneuve",
    "Villeneuve les beziers",
    "Villeneuve-au-Chemin",
    "Villeneuve-de-Berg",
    "Villeneuve-de-Duras",
    "Villeneuve-de-la-Raho",
    "Villeneuve-de-Marc",
    "Villeneuve-de-Marsan",
    "Villeneuve-des-Escaldes",
    "Villeneuve-Frouville",
    "Villeneuve-la-Garenne",
    "Villeneuve-la-Guyard",
    "Villeneuve-la-Riviere",
    "Villeneuve-le-Comte",
    "Villeneuve-le-Roi",
    "Villeneuve-Lecussan",
    "Villeneuve-les-Avignon",
    "Villeneuve-les-Bouloc",
    "Villeneuve-les-Genets",
    "Villeneuve-les-Maguelone",
    "Villeneuve-les-Sablons",
    "Villeneuve-Loubet",
    "Villeneuve-Minervois",
    "Villeneuve-Saint-Georges",
    "Villeneuve-Saint-Germain",
    "Villeneuve-sur-Allier",
    "Villeneuve-sur-Fere",
    "Villeneuve-sur-Lot",
    "Villeneuve-sur-Yonne",
    "Villeneuve-Tolosane",
    "Villennes-sur-Seine",
    "Villenouvelle",
    "Villenoy",
    "Villeny",
    "Villeparisis",
    "Villeperdue",
    "Villepinte",
    "Villepinte",
    "Villepreux",
    "Villequier-Aumont",
    "Villerable",
    "Villerest",
    "Villermain",
    "Villeron",
    "Villers",
    "Villers",
    "Villers-Allerand",
    "Villers-au-Bois",
    "Villers-au-Tertre",
    "Villers-aux-Vents",
    "Villers-Bocage",
    "Villers-Bocage",
    "Villers-Bretonneux",
    "Villers-Canivet",
    "Villers-Farlay",
    "Villers-Helon",
    "Villers-la-Montagne",
    "Villers-le-Lac",
    "Villers-le-Sec",
    "Villers-les-Nancy",
    "Villers-les-Ormes",
    "Villers-les-Pots",
    "Villers-les-Roye",
    "Villers-Outreaux",
    "Villers-Plouich",
    "Villers-Pol",
    "Villers-Saint-Frambourg",
    "Villers-Saint-Genest",
    "Villers-Saint-Paul",
    "Villers-Saint-Sepulcre",
    "Villers-Semeuse",
    "Villers-Sire-Nicole",
    "Villers-sous-Preny",
    "Villers-sous-Saint-Leu",
    "Villers-sur-Fere",
    "Villers-sur-le-Mont",
    "Villers-sur-Mer",
    "Villers-sur-Meuse",
    "Villers-sur-Port",
    "Villers-sur-Saulnot",
    "Villersexel",
    "Villerupt",
    "Villerville",
    "Villes-sur-Auzon",
    "Villesequelande",
    "Villesiscle",
    "Villetaneuse",
    "Villetelle",
    "Villeton",
    "Villetrun",
    "Villette",
    "Villettes",
    "Villeurbanne",
    "Villevallier",
    "Villevaude",
    "Villeve",
    "Villeveque",
    "Villeveyrac",
    "Villevieux",
    "Villevillon",
    "Villevocance",
    "Villexavier",
    "Villey-Saint-Etienne",
    "Villey-sur-Tille",
    "Villez-sous-Bailleul",
    "Villie-Morgon",
    "Villiers-Adam",
    "Villiers-au-Bouin",
    "Villiers-Couture",
    "Villiers-en-Desoeuvre",
    "Villiers-en-Plaine",
    "Villiers-le-Bacle",
    "Villiers-le-Bel",
    "Villiers-le-Mahieu",
    "Villiers-Saint-Georges",
    "Villiers-sous-Grez",
    "Villiers-sur-Chize",
    "Villiers-sur-Marne",
    "Villiers-sur-Morin",
    "Villiers-sur-Orge",
    "Villiers-sur-Seine",
    "Villiers-sur-Tholon",
    "Villieu-Loyes-Mollon",
    "Villing",
    "Villons-les-Buissons",
    "Villorceau",
    "Villotte-sur-Aire",
    "Villy-Bocage",
    "Villy-le-Bouveret",
    "Villy-le-Pelloux",
    "Vimines",
    "Vimory",
    "Vimoutiers",
    "Vimy",
    "Vinassan",
    "Vinay",
    "Vinca",
    "Vincelles",
    "Vincelles",
    "Vincennes",
    "Vincent",
    "Vincey",
    "Vincly",
    "Vindelle",
    "Vineuil",
    "Vineuil",
    "Vineuil-Saint-Firmin",
    "Vinneuf",
    "Vinon-sur-Verdon",
    "Vinzelles",
    "Vinzier",
    "Viodos-Abense-de-Bas",
    "Violaines",
    "Violay",
    "Viomenil",
    "Vion",
    "Vion",
    "Viplaix",
    "Vire",
    "Vire",
    "Virelade",
    "Vireux-Molhain",
    "Vireux-Wallerand",
    "Virey-le-Grand",
    "Viriat",
    "Virieu-le-Grand",
    "Virignin",
    "Viriville",
    "Viroflay",
    "Virville",
    "Viry",
    "Viry",
    "Viry-Noureuil",
    "Vitrac",
    "Vitrac-Saint-Vincent",
    "Vitreux",
    "Vitrimont",
    "Vitrolles",
    "Vitry-aux-Loges",
    "Vitry-en-Artois",
    "Vitry-en-Perthois",
    "Vitry-la-Ville",
    "Vitry-sur-Orne",
    "Vitry-sur-Seine",
    "Vitteaux",
    "Vittel",
    "Vittersbourg",
    "Viuz-en-Sallaz",
    "Viven",
    "Vivier",
    "Vivier-au-Court",
    "Vivieres",
    "Viviers",
    "Viviers-du-Lac",
    "Viviers-les-Montagnes",
    "Viviez",
    "Vivonne",
    "Vivy",
    "Vix",
    "Vizille",
    "Vocance",
    "Voglans",
    "Voillecomte",
    "Voiron",
    "Voisin",
    "Voisins-le-Bretonneux",
    "Voissant",
    "Vollore-Ville",
    "Volmerange-les-Boulay",
    "Volmerange-les-Mines",
    "Volonne",
    "Volvic",
    "Volx",
    "Vonnas",
    "Voreppe",
    "Vorey",
    "Vorges",
    "Vouel",
    "Vouhe",
    "Vouille",
    "Vouille-les-Marais",
    "Vouillers",
    "Voujeaucourt",
    "Voulangis",
    "Voultegon",
    "Voulton",
    "Voulx",
    "Vouneuil-sous-Biard",
    "Vouneuil-sur-Vienne",
    "Vourey",
    "Vourles",
    "Voutezac",
    "Voutre",
    "Vouvant",
    "Vouvray",
    "Vouzan",
    "Vouzeron",
    "Vouziers",
    "Vouzon",
    "Vouzy",
    "Voves",
    "Vraiville",
    "Vraux",
    "Vred",
    "Vriange",
    "Vrigne-aux-Bois",
    "Vrigny",
    "Vritz",
    "Vue",
    "Vulaines-sur-Seine",
    "Vulbens",
    "Wahagnies",
    "Wahlenheim",
    "Wail",
    "Wailly",
    "Wailly-Beaucamp",
    "Waldhouse",
    "Waldighofen",
    "Waldweistroff",
    "Waldwisse",
    "Walheim",
    "Wallers",
    "Walscheid",
    "Wambaix",
    "Wambercourt",
    "Wambrechies",
    "Wandignies-Hamage",
    "Wangen",
    "Wangenbourg-Engenthal",
    "Wanquetin",
    "Warcq",
    "Warendin",
    "Wargnies-le-Petit",
    "Warhem",
    "Warlaing",
    "Warlus",
    "Warluzel",
    "Warmeriville",
    "Warnecourt",
    "Wasnes-au-Bac",
    "Wasquehal",
    "Wasselonne",
    "Wassy",
    "Watten",
    "Wattignies",
    "Wattrelos",
    "Wattwiller",
    "Wavignies",
    "Waville",
    "Wavrechain-sous-Denain",
    "Wavrechain-sous-Faulx",
    "Wavrin",
    "Waziers",
    "Weislingen",
    "Weitbruch",
    "Wentzwiller",
    "Werentzhouse",
    "Wervicq-Sud",
    "Westbecourt",
    "Westhoffen",
    "Westhouse",
    "Weyersheim",
    "Wickerschwihr",
    "Widensolen",
    "Wierre-Effroy",
    "Wignehies",
    "Wihr-au-Val",
    "Wildersbach",
    "Willems",
    "Willencourt",
    "Willer-sur-Thur",
    "Willerwald",
    "Willgottheim",
    "Wimereux",
    "Wimille",
    "Wingen-sur-Moder",
    "Wingersheim",
    "Wingles",
    "Winnezeele",
    "Wintersbourg",
    "Wintzenbach",
    "Wintzenheim",
    "Wintzfelden",
    "Wirwignes",
    "Wissant",
    "Wissembourg",
    "Wissignicourt",
    "Wissous",
    "Witry-les-Reims",
    "Wittelsheim",
    "Wittenheim",
    "Witternheim",
    "Wittersdorf",
    "Wittersheim",
    "Wittes",
    "Wittisheim",
    "Wiwersheim",
    "Wizernes",
    "Woerth",
    "Woincourt",
    "Woippy",
    "Wolfgantzen",
    "Wolfisheim",
    "Wolschheim",
    "Wolxheim",
    "Wormhout",
    "Woustviller",
    "Wuenheim",
    "Xaintray",
    "Xanton-Chassenon",
    "Xertigny",
    "Xures",
    "Yaucourt-Bussus",
    "Ychoux",
    "Ydes",
    "Yebleron",
    "Yebles",
    "Yenne",
    "Yermenonville",
    "Yerres",
    "Yerville",
    "Yevre-la-Ville",
    "Yffiniac",
    "Ygos-Saint-Saturnin",
    "Ygrande",
    "Ymare",
    "Youx",
    "Yport",
    "Ypreville-Biville",
    "Yronde-et-Buron",
    "Yssac-la-Tourette",
    "Yssandon",
    "Yssingeaux",
    "Ytrac",
    "Yversay",
    "Yves",
    "Yvetot",
    "Yvette",
    "Yvias",
    "Yvoire",
    "Yvrac",
    "Yvrac-et-Malleyrand",
    "Yzernay",
    "Yzeure",
    "Yzeures-sur-Creuse",
    "Yzosse",
    "Zellwiller",
    "Zillisheim",
    "Zimmersheim",
    "Zimming",
    "Zinswiller",
    "Zittersheim",
    "Zouafques",
    "Zutzendorf",
  ],
  "French Polynesia": [
    "Arue",
    "Faaa",
    "Mahina",
    "Paeau",
    "Papeete",
    "Pirae",
    "Punaauia",
  ],
  Gabon: ["Gamba", "Libreville", "Mamagnia", "Moanda", "Port-Gentil"],
  Georgia: [
    "Gogolesubani",
    "Kutaisi",
    "Lentekhi",
    "Qazbegi",
    "Samtredia",
    "Sukhumi",
    "Tbilisi",
    "Zemo-Avchala",
    "Zugdidi",
  ],
  Germany: [
    "Aach",
    "Aach",
    "Aachen",
    "Aalen",
    "Abbensen",
    "Abberode",
    "Abenberg",
    "Abensberg",
    "Abstatt",
    "Abtsbessingen",
    "Abtsgmuend",
    "Abtswind",
    "Abtweiler",
    "Achern",
    "Achim",
    "Achstetten",
    "Adelebsen",
    "Adelheidsdorf",
    "Adelmannsfelden",
    "Adelschlag",
    "Adelsdorf",
    "Adelsdorf",
    "Adelsheim",
    "Adelshofen",
    "Adelsried",
    "Adenau",
    "Adenbuettel",
    "Adendorf",
    "Adlkofen",
    "Adlum",
    "Adorf",
    "Aerzen",
    "Affalterbach",
    "Affaltern",
    "Affing",
    "Affinghausen",
    "Aftholderberg",
    "Aglasterhausen",
    "Ahaus",
    "Ahausen",
    "Ahlen",
    "Ahlhorn",
    "Aholfing",
    "Aholming",
    "Ahorn",
    "Ahrbruck",
    "Ahrensboek",
    "Ahrensbok",
    "Ahrensburg",
    "Ahrensfelde",
    "Ahrenshagen",
    "Ahrensmoor",
    "Ahstedt",
    "Aicha vorm Wald",
    "Aichach",
    "Aichelberg",
    "Aichhalden",
    "Aichstetten",
    "Aichtal",
    "Aichwald",
    "Aidenbach",
    "Aidlingen",
    "Aindling",
    "Aindorf",
    "Ainring",
    "Aislingen",
    "Aitrach",
    "Aitrang",
    "Aken",
    "Albaching",
    "Albbruck",
    "Albeck",
    "Albersdorf",
    "Albershausen",
    "Albersweiler",
    "Albertshausen",
    "Albig",
    "Albisheim",
    "Albrechts",
    "Albstadt",
    "Aldekerk",
    "Aldenhoven",
    "Aldersbach",
    "Aldingen",
    "Alesheim",
    "Aletshausen",
    "Alexander",
    "Alfdorf",
    "Alfeld",
    "Alfeld",
    "Alfhausen",
    "Alfstedt",
    "Alfter",
    "Algermissen",
    "Alheim",
    "Allee",
    "Allendorf",
    "Allendorf",
    "Allensbach",
    "Allersberg",
    "Allershausen",
    "Alleshausen",
    "Alling",
    "Allmannshofen",
    "Allmendingen",
    "Allmersbach im Tal",
    "Allstedt",
    "Almstedt",
    "Alpen",
    "Alpenrod",
    "Alperstedt",
    "Alpirsbach",
    "Alsbach",
    "Alsbach-Hahnlein",
    "Alsdorf",
    "Alsdorf",
    "Alsfeld",
    "Alsheim",
    "Alt Buchhorst",
    "Alt Bukow",
    "Alt Golm",
    "Alt Ruppin",
    "Alt Schwerin",
    "Altbach",
    "Altbelgern",
    "Altdobern",
    "Altdorf",
    "Altdorf",
    "Altdorf",
    "Alteglofsheim",
    "Alten",
    "Altena",
    "Altenahr",
    "Altenau",
    "Altenbeken",
    "Altenberg",
    "Altenberge",
    "Altenburg",
    "Altendiez",
    "Altenglan",
    "Altengottern",
    "Altengronau",
    "Altenhasslau",
    "Altenholz",
    "Altenkirchen",
    "Altenkirchen",
    "Altenkirchen",
    "Altenkrempe",
    "Altenkunstadt",
    "Altenmarkt",
    "Altenmedingen",
    "Altenmunster",
    "Altenriet",
    "Altenstadt",
    "Altenstadt",
    "Altenstadt",
    "Altenstadt",
    "Altensteig",
    "Altenthann",
    "Altentreptow",
    "Alterhofen",
    "Altersbach",
    "Altfraunhofen",
    "Althegnenberg",
    "Altheim",
    "Altheim",
    "Althengstett",
    "Althutte",
    "Altingen",
    "Altkirchen",
    "Altlandsberg",
    "Altleiningen",
    "Altlussheim",
    "Altmannstein",
    "Altoetting",
    "Altomunster",
    "Altrip",
    "Altshausen",
    "Altstadt",
    "Altstadt",
    "Alttechau",
    "Altusried",
    "Altwittenbek",
    "Alveslohe",
    "Alzenau in Unterfranken",
    "Alzey",
    "Am Mellensee",
    "Am See",
    "Amberg",
    "Amelinghausen",
    "Amerang",
    "Ammern",
    "Ammerndorf",
    "Amoneburg",
    "Amorbach",
    "Ampermoching",
    "Ampfing",
    "Amstetten",
    "Amtzell",
    "Andechs",
    "Anderlingen",
    "Andernach",
    "Andreasberg",
    "Anger",
    "Angermunde",
    "Angern",
    "Anker",
    "Ankershagen",
    "Anklam",
    "Ankum",
    "Annaberg-Buchholz",
    "Annaburg",
    "Annerod",
    "Annweiler am Trifels",
    "Anrath",
    "Anrochte",
    "Ansbach",
    "Antdorf",
    "Anzing",
    "Anzing",
    "Apen",
    "Apenburg",
    "Apensen",
    "Apfeldorf",
    "Apfelstadt",
    "Apolda",
    "Appel",
    "Appen",
    "Appenweier",
    "Arberg",
    "Arenrath",
    "Arensdorf",
    "Arenshausen",
    "Aresing",
    "Arft",
    "Argenthal",
    "Arlaching",
    "Arlewatt",
    "Armsheim",
    "Armstorf",
    "Arnbach",
    "Arnbruck",
    "Arnsberg",
    "Arnschwang",
    "Arnsdorf",
    "Arnsdorf",
    "Arnstadt",
    "Arnstein",
    "Arnstorf",
    "Arrach",
    "Artern",
    "Artlenburg",
    "Arzbach",
    "Arzbach",
    "Arzberg",
    "Asbach",
    "Asbach",
    "Asbach",
    "Asbach-Baumenheim",
    "Ascha",
    "Aschaffenburg",
    "Aschau",
    "Aschau",
    "Ascheberg",
    "Ascheberg",
    "Ascheffel",
    "Aschendorf",
    "Aschersleben",
    "Aschheim",
    "Asendorf",
    "Aspach",
    "Aspach",
    "Asperg",
    "Aspisheim",
    "Assel",
    "Assenheim",
    "Asslar",
    "Assling",
    "Astfeld",
    "Atteln",
    "Attendorn",
    "Attenkirchen",
    "Attenweiler",
    "Atting",
    "Atzendorf",
    "Au",
    "Au am Rhein",
    "Au in der Hallertau",
    "Aub",
    "Aue",
    "Auen",
    "Auenstein",
    "Auerbach",
    "Auerbach",
    "Auerbach",
    "Aufhausen",
    "Auggen",
    "Augsburg",
    "Augustdorf",
    "Augustfehn",
    "Augustusburg",
    "Aukrug",
    "Aulendorf",
    "Aull",
    "Auma",
    "Aumuhle",
    "Aurach",
    "Aurachtal",
    "Aurich",
    "Autenzell",
    "Auw",
    "Axstedt",
    "Aying",
    "Ayl",
    "Baar-Ebenhausen",
    "Baasdorf",
    "Baasem",
    "Babenhausen",
    "Babenhausen",
    "Babensham",
    "Babow",
    "Bach",
    "Bach",
    "Bach an der Donau",
    "Bacharach",
    "Bachhagel",
    "Bachingen an der Brenz",
    "Backnang",
    "Bad Abbach",
    "Bad Aibling",
    "Bad Alexandersbad",
    "Bad Arolsen",
    "Bad Bellingen",
    "Bad Bentheim",
    "Bad Bergzabern",
    "Bad Berka",
    "Bad Berleburg",
    "Bad Berneck im Fichtelgebirge",
    "Bad Bevensen",
    "Bad Bibra",
    "Bad Blankenburg",
    "Bad Bocklet",
    "Bad Boll",
    "Bad Bramstedt",
    "Bad Breisig",
    "Bad Buchau",
    "Bad Camberg",
    "Bad Ditzenbach",
    "Bad Doberan",
    "Bad Driburg",
    "Bad Duben",
    "Bad Duerkheim",
    "Bad Durrenberg",
    "Bad Durrheim",
    "Bad Eilsen",
    "Bad Elster",
    "Bad Ems",
    "Bad Endbach",
    "Bad Endorf",
    "Bad Essen",
    "Bad Fallingbostel",
    "Bad Feilnbach",
    "Bad Frankenhausen",
    "Bad Freienwalde",
    "Bad Fussing",
    "Bad Gandersheim",
    "Bad Godesberg",
    "Bad Gogging",
    "Bad Gottleuba",
    "Bad Griesbach",
    "Bad Grund",
    "Bad Harzburg",
    "Bad Heilbrunn",
    "Bad Herrenalb",
    "Bad Hersfeld",
    "Bad Hindelang",
    "Bad Homburg",
    "Bad Honnef",
    "Bad Honningen",
    "Bad Iburg",
    "Bad Karlshafen",
    "Bad Kissingen",
    "Bad Kleinen",
    "Bad Klosterlausnitz",
    "Bad Kohlgrub",
    "Bad Konig",
    "Bad Konigshofen im Grabfeld",
    "Bad Kosen",
    "Bad Kostritz",
    "Bad Kreuznach",
    "Bad Krozingen",
    "Bad Laasphe",
    "Bad Laer",
    "Bad Langensalza",
    "Bad Lauchstadt",
    "Bad Lausick",
    "Bad Lauterberg im Harz",
    "Bad Liebenstein",
    "Bad Liebenwerda",
    "Bad Liebenzell",
    "Bad Lippspringe",
    "Bad Lobenstein",
    "Bad Marienberg",
    "Bad Mergentheim",
    "Bad Munder am Deister",
    "Bad Munster am Stein-Ebernburg",
    "Bad Munstereifel",
    "Bad Muskau",
    "Bad Nauheim",
    "Bad Nenndorf",
    "Bad Neuenahr-Ahrweiler",
    "Bad Neustadt an der Saale",
    "Bad Oeynhausen",
    "Bad Oldesloe",
    "Bad Orb",
    "Bad Peterstal-Griesbach",
    "Bad Pyrmont",
    "Bad Rappenau",
    "Bad Reichenhall",
    "Bad Rippoldsau-Schapbach",
    "Bad Rothenfelde",
    "Bad Saarow",
    "Bad Sachsa",
    "Bad Sackingen",
    "Bad Salzdetfurth",
    "Bad Salzschlirf",
    "Bad Salzuflen",
    "Bad Salzungen",
    "Bad Sassendorf",
    "Bad Schachen",
    "Bad Schandau",
    "Bad Schmiedeberg",
    "Bad Schussenried",
    "Bad Schwalbach",
    "Bad Schwartau",
    "Bad Segeberg",
    "Bad Soden am Taunus",
    "Bad Soden-Salmunster",
    "Bad Sooden-Allendorf",
    "Bad Staffelstein",
    "Bad Steben",
    "Bad Sulza",
    "Bad Teinach-Zavelstein",
    "Bad Tennstedt",
    "Bad Toelz",
    "Bad Uberkingen",
    "Bad Urach",
    "Bad Vilbel",
    "Bad Waldsee",
    "Bad Wiessee",
    "Bad Wildbad im Schwarzwald",
    "Bad Wildungen",
    "Bad Wilsnack",
    "Bad Wimpfen",
    "Bad Windsheim",
    "Bad Worishofen",
    "Bad Wurzach",
    "Bad Zwischenahn",
    "Badbergen",
    "Baddeckenstedt",
    "Badem",
    "Baden-Baden",
    "Badenhausen",
    "Badenweiler",
    "Badow",
    "Badra",
    "Baesweiler",
    "Bahlingen",
    "Bahratal",
    "Bahrdorf",
    "Bahrenfleth",
    "Baienfurt",
    "Baierbrunn",
    "Baiern",
    "Baiersbronn",
    "Baiersdorf",
    "Baindt",
    "Bakum",
    "Baldham",
    "Balgheim",
    "Balingen",
    "Ballendorf",
    "Ballenstedt",
    "Ballersbach",
    "Ballrechten",
    "Ballstadt",
    "Baltmannsweiler",
    "Baltringen",
    "Baltrum",
    "Balve",
    "Bamberg",
    "Bamlach",
    "Bammental",
    "Bank",
    "Bankewitz",
    "Bann",
    "Bannberscheid",
    "Bannemin",
    "Bannewitz",
    "Banteln",
    "Banzkow",
    "Barbelroth",
    "Barbing",
    "Barby",
    "Barchfeld",
    "Bardenberg",
    "Bardowick",
    "Barendorf",
    "Barenstein",
    "Barenthal",
    "Bargenstedt",
    "Bargfeld-Stegen",
    "Barghorst",
    "Bargstedt",
    "Bargteheide",
    "Bargum",
    "Barkelsby",
    "Barkenholm",
    "Barkow",
    "Barleben",
    "Barmstedt",
    "Barnau",
    "Barnstorf",
    "Barntrup",
    "Barsbuettel",
    "Barsinghausen",
    "Barssel",
    "Barstadt",
    "Barth",
    "Bartholoma",
    "Bartow",
    "Barum",
    "Baruth",
    "Barwedel",
    "Basel",
    "Bassenheim",
    "Bassum",
    "Bastheim",
    "Bastorf",
    "Battenberg",
    "Battenberg",
    "Batzhausen",
    "Baudenbach",
    "Baumholder",
    "Baunach",
    "Baunatal",
    "Bausendorf",
    "Bautzen",
    "Bawinkel",
    "Bayenthal",
    "Bayerbach",
    "Bayerisch Eisenstein",
    "Bayerisch Gmain",
    "Bayern",
    "Bayersoien",
    "Bayreuth",
    "Bayrischzell",
    "Beaumarais",
    "Bebra",
    "Becheln",
    "Becherbach",
    "Bechhofen",
    "Bechtolsheim",
    "Beckdorf",
    "Beckedorf",
    "Beckeln",
    "Beckingen",
    "Beckstedt",
    "Beckum",
    "Bedburdyck",
    "Bedburg",
    "Bederkesa",
    "Beedenbostel",
    "Beelen",
    "Beelitz",
    "Beerfelden",
    "Beeskow",
    "Beesten",
    "Beetzendorf",
    "Beetzsee",
    "Behrendorf",
    "Behrenhoff",
    "Behringen",
    "Beierfeld",
    "Beiersdorf",
    "Beierstedt",
    "Beilngries",
    "Beilrode",
    "Beilstein",
    "Beim Kronsberg",
    "Beimerstetten",
    "Beindersheim",
    "Beinerstadt",
    "Bekond",
    "Belecke",
    "Belgern",
    "Belgershain",
    "Bell",
    "Bell",
    "Bellamont",
    "Bellenberg",
    "Bellenberg",
    "Bellershausen",
    "Bellheim",
    "Belm",
    "Beltheim",
    "Belum",
    "Belzig",
    "Bemerode",
    "Bempflingen",
    "Bendeleben",
    "Bendestorf",
    "Bendfeld",
    "Bendorf",
    "Benediktbeuern",
    "Benneckenstein",
    "Benningen",
    "Benningen am Neckar",
    "Bennungen",
    "Bensberg",
    "Benshausen",
    "Bensheim",
    "Bentfeld",
    "Bentwisch",
    "Benz",
    "Benzingerode",
    "Beratzhausen",
    "Berching",
    "Berchtesgaden",
    "Berg",
    "Berg",
    "Berg",
    "Berg",
    "Berga",
    "Berga",
    "Bergbau",
    "Berge",
    "Bergen",
    "Bergen",
    "Bergen",
    "Bergen auf Ruegen",
    "Bergfelde",
    "Berggiesshubel",
    "Berghaupten",
    "Berghausen",
    "Bergheim",
    "Berghulen",
    "Bergisch Gladbach",
    "Bergkamen",
    "Bergkirchen",
    "Berglern",
    "Berglicht",
    "Bergneustadt",
    "Bergrheinfeld",
    "Bergtheim",
    "Bergweiler",
    "Berka",
    "Berkenthin",
    "Berkheim",
    "Berlin",
    "Berlin",
    "Berlingerode",
    "Bermatingen",
    "Bermbach",
    "Bermersheim",
    "Bermuthshain",
    "Bernau",
    "Bernau am Chiemsee",
    "Bernau bei Berlin",
    "Bernburg",
    "Berne",
    "Berngau",
    "Bernhardswald",
    "Bernkastel-Kues",
    "Bernried",
    "Bernsbach",
    "Bernsdorf",
    "Bernsdorf",
    "Bernstadt",
    "Bernstadt",
    "Bernterode",
    "Berod bei Wallmerod",
    "Bersenbruck",
    "Berumbur",
    "Berzdorf",
    "Bescheid",
    "Besigheim",
    "Besitz",
    "Bestensee",
    "Bestwig",
    "Betheln",
    "Bettingen",
    "Betzdorf",
    "Betzendorf",
    "Betzenstein",
    "Betzigau",
    "Beucha",
    "Beuern",
    "Beuren",
    "Beuren",
    "Bevern",
    "Beverstedt",
    "Beverungen",
    "Bexbach",
    "Bexhovede",
    "Biberach an der Riss",
    "Biberbach",
    "Biblis",
    "Biburg",
    "Bichl",
    "Bickenbach",
    "Biebelsheim",
    "Bieber",
    "Biebesheim",
    "Biedenkopf",
    "Biederitz",
    "Bielefeld",
    "Bienenbuttel",
    "Bienstadt",
    "Biere",
    "Bierlingen",
    "Biesenthal",
    "Biesingen",
    "Biessenhofen",
    "Bietigheim",
    "Bietigheim-Bissingen",
    "Bietingen",
    "Bilk",
    "Billerbeck",
    "Billigheim",
    "Billigheim-Ingenheim",
    "Billings",
    "Billingshausen",
    "Bimohlen",
    "Binau",
    "Bindlach",
    "Bingen",
    "Bingen am Rhein",
    "Binsfeld",
    "Binzen",
    "Bippen",
    "Birenbach",
    "Birkenau",
    "Birkenfeld",
    "Birkenfeld",
    "Birkenfeld",
    "Birkenheide",
    "Birkenhof",
    "Birkenlohe",
    "Birkenwerder",
    "Birkheim",
    "Birlenbach",
    "Birlinghoven",
    "Birnbach",
    "Birnbach",
    "Birstein",
    "Bischberg",
    "Bischheim",
    "Bischoffen",
    "Bischofferode",
    "Bischofsgrun",
    "Bischofsheim",
    "Bischofsheim an der Rhon",
    "Bischofsmais",
    "Bischofswerda",
    "Bischofswiesen",
    "Bischweier",
    "Bisingen",
    "Bismark",
    "Bismark",
    "Bispingen",
    "Bissendorf",
    "Bissingen",
    "Bissingen an der Teck",
    "Bistensee",
    "Bitburg",
    "Bitterfeld-Wolfen",
    "Bitz",
    "Blaibach",
    "Blaichach",
    "Blankenburg",
    "Blankenfelde",
    "Blankenhagen",
    "Blankenhain",
    "Blankenhain",
    "Blankenheim",
    "Blankenloch",
    "Blankenrath",
    "Blankensee",
    "Blankenstein",
    "Blatzheim",
    "Blaubeuren Abbey",
    "Blechhammer",
    "Bleckede",
    "Bleckenrode",
    "Bleialf",
    "Bleicherode",
    "Blender",
    "Bliesdorf",
    "Blieskastel",
    "Bliesmengen-Bolchen",
    "Blievenstorf",
    "Blitzenreute",
    "Blomberg",
    "Blomberg",
    "Blomesche Wildnis",
    "Blumberg",
    "Blume",
    "Blumenthal",
    "Bobbau",
    "Bobenheim am Berg",
    "Bobenheim-Roxheim",
    "Bobingen",
    "Bobrach",
    "Bocholt",
    "Bochum",
    "Bocka",
    "Bockau",
    "Bockelwitz",
    "Bockenau",
    "Bockenem",
    "Bockenheim",
    "Bockhorn",
    "Bockhorn",
    "Bockhorst",
    "Boddenstedt",
    "Boddin",
    "Bodelshausen",
    "Boden",
    "Bodenfelde",
    "Bodenheim",
    "Bodenkirchen",
    "Bodenmais",
    "Bodensee",
    "Bodenteich",
    "Bodenwerder",
    "Bodenwohr",
    "Bodman-Ludwigshafen",
    "Bodolz",
    "Boel",
    "Boffzen",
    "Bofingen",
    "Bogen",
    "Bogenhausen",
    "Bohl-Iggelheim",
    "Bohlen",
    "Bohmenkirch",
    "Bohmstedt",
    "Bohmte",
    "Bohringen",
    "Boich",
    "Boizenburg",
    "Boklund",
    "Bolanden",
    "Boldebuck",
    "Boldela",
    "Bollendorf",
    "Bollingstedt",
    "Bollschweil",
    "Bollstein",
    "Bolsterlang",
    "Bomlitz",
    "Bondorf",
    "Bonebuttel",
    "Bonefeld",
    "Bonen",
    "Bonn",
    "Bonndorf im Schwarzwald",
    "Bonnigheim",
    "Bonningstedt",
    "Bonstetten",
    "Boos",
    "Boos",
    "Boostedt",
    "Bopfingen",
    "Boppard",
    "Bordelum",
    "Bordesholm",
    "Borgdorf-Seedorf",
    "Borgentreich",
    "Borger",
    "Borgholzhausen",
    "Borgsdorf",
    "Borgstedt",
    "Borgsum",
    "Borken",
    "Borken",
    "Borkheide",
    "Borkum",
    "Borkwalde",
    "Born",
    "Borna",
    "Borne",
    "Bornheim",
    "Bornheim",
    "Bornhoved",
    "Bornich",
    "Bornitz",
    "Bornsen",
    "Bornstein",
    "Borsdorf",
    "Borsfleth",
    "Borstel",
    "Borstel-Hohenraden",
    "Bortlingen",
    "Bosau",
    "Bosel",
    "Bosingen",
    "Bosleben-Wullersleben",
    "Botersen",
    "Bothkamp",
    "Bottrop",
    "Botzingen",
    "Bous",
    "Bovenden",
    "Boxberg",
    "Brachbach",
    "Brackel",
    "Brackenheim",
    "Brake (Unterweser)",
    "Brakel",
    "Bramsche",
    "Bramstedt",
    "Brand",
    "Brand-Erbisdorf",
    "Brande-Hornerkirchen",
    "Brandenburg",
    "Brandis",
    "Brandl",
    "Brandshagen",
    "Brannenburg",
    "Braubach",
    "Braunfels",
    "Braunlage",
    "Braunlingen",
    "Braunsbach",
    "Braunsbedra",
    "Braunschweig",
    "Brauweiler",
    "Brebel",
    "Breckerfeld",
    "Breddenberg",
    "Breddorf",
    "Bredenbek",
    "Bredeney",
    "Bredstedt",
    "Breesen",
    "Breest",
    "Bregenstedt",
    "Breidenbach",
    "Breiholz",
    "Breisach",
    "Breit",
    "Breitbrunn am Chiemsee",
    "Breitenau",
    "Breitenbach",
    "Breitenbach",
    "Breitenbach",
    "Breitenbach am Herzberg",
    "Breitenberg",
    "Breitenbrunn",
    "Breitenbrunn",
    "Breitenburg",
    "Breitenfelde",
    "Breitengussbach",
    "Breitenworbis",
    "Breitnau",
    "Breitscheid",
    "Breitscheid",
    "Breitscheid",
    "Breitscheidt",
    "Breitungen",
    "Brekendorf",
    "Bremen",
    "Bremerhaven",
    "Bremervorde",
    "Brennberg",
    "Brensbach",
    "Brenz",
    "Bretten",
    "Bretthausen",
    "Bretzfeld",
    "Breuna",
    "Brey",
    "Brickeln",
    "Brieselang",
    "Brieske",
    "Brieskow-Finkenheerd",
    "Brietlingen",
    "Brilon",
    "Britz",
    "Brockum",
    "Brodersby",
    "Broderstorf",
    "Brodhagen",
    "Broeckel",
    "Brokdorf",
    "Brokstedt",
    "Brome",
    "Bromskirchen",
    "Brotterode",
    "Bruch",
    "Bruchhausen",
    "Bruchhausen-Vilsen",
    "Bruchkobel",
    "Bruchmuhlbach-Miesau",
    "Bruchsal",
    "Bruchweiler",
    "Bruck",
    "Bruck in der Oberpfalz",
    "Bruckberg",
    "Brucken",
    "Bruckmuhl",
    "Brucktal",
    "Bruel",
    "Bruggen",
    "Bruggen",
    "Bruhl",
    "Bruhl",
    "Brunn",
    "Brunnthal",
    "Brunsbuttel",
    "Brunstorf",
    "Brusewitz",
    "Brussow",
    "Bubenheim",
    "Bubenreuth",
    "Bubesheim",
    "Buborn",
    "Buch",
    "Buch",
    "Buch",
    "Buch am Buchrain",
    "Buch am Erlbach",
    "Bucha",
    "Bucha",
    "Buchbach",
    "Buchbach",
    "Buchberg",
    "Buchel",
    "Buchen",
    "Buchen",
    "Buchenbach",
    "Buchenbach",
    "Buchenberg",
    "Buchfart",
    "Buchholz",
    "Buchholz",
    "Buchholz in der Nordheide",
    "Buchlberg",
    "Buchloe",
    "Buchwaldchen",
    "Buchwalde",
    "Buckeburg",
    "Buckenhof",
    "Buckow",
    "Buden",
    "Budenheim",
    "Budingen",
    "Bueddenstedt",
    "Buedelsdorf",
    "Buende",
    "Buggingen",
    "Buhl",
    "Buhlen",
    "Buhler",
    "Buhlerhohe",
    "Buhlertal",
    "Buhlertann",
    "Buhlerzell",
    "Bulkau",
    "Bullay",
    "Bulow",
    "Bulowerburg",
    "Bunde",
    "Bundenthal",
    "Bunsoh",
    "Burbach",
    "Burbach",
    "Buren",
    "Burg",
    "Burg",
    "Burg auf Fehmarn",
    "Burg bei Magdeburg",
    "Burg Stargard",
    "Burg-Grambke",
    "Burgau",
    "Burgberg",
    "Burgbernheim",
    "Burgbrohl",
    "Burgdorf, Hanover",
    "Burgebrach",
    "Burgen",
    "Burgerhof",
    "Burghaslach",
    "Burghaun",
    "Burghausen",
    "Burgheim",
    "Burgkirchen an der Alz",
    "Burgkunstadt",
    "Burglauer",
    "Burglengenfeld",
    "Burgoberbach",
    "Burgrieden",
    "Burgschwalbach",
    "Burgsinn",
    "Burgsponheim",
    "Burgstadt",
    "Burgstadt",
    "Burgstall",
    "Burgstetten",
    "Burgthann",
    "Burgwedel",
    "Burgwindheim",
    "Burk",
    "Burkardroth",
    "Burkau",
    "Burkhardtsdorf",
    "Burladingen",
    "Burscheid",
    "Burstadt",
    "Burtenbach",
    "Burweg",
    "Buschbell",
    "Buschhausen",
    "Busdorf",
    "Busenberg",
    "Butjadingen",
    "Buttelborn",
    "Buttelstedt",
    "Buttenheim",
    "Buttenwiesen",
    "Butthard",
    "Buttstedt",
    "Butzbach",
    "Butzow",
    "Buxheim",
    "Buxtehude",
    "Cadenberge",
    "Cadolzburg",
    "Calau",
    "Calbe",
    "Calberlah",
    "Calden",
    "Callbach",
    "Callenberg",
    "Calvorde",
    "Calw",
    "Cambs",
    "Camin",
    "Cannewitz",
    "Cappel",
    "Cappeln",
    "Caputh",
    "Carlow",
    "Carlsberg",
    "Carmzow",
    "Carolinensiel",
    "Castrop-Rauxel",
    "Catterfeld",
    "Celle",
    "Cham",
    "Chamerau",
    "Chemnitz",
    "Chieming",
    "Chiemsee",
    "Chostlarn",
    "Clausen",
    "Claussnitz",
    "Clausthal",
    "Clausthal-Zellerfeld",
    "Cleebronn",
    "Cloppenburg",
    "Coburg",
    "Cochem",
    "Coesfeld",
    "Colbe",
    "Colbitz",
    "Colditz",
    "Colmnitz",
    "Colnrade",
    "Cologne",
    "Contwig",
    "Coppenbrugge",
    "Coppengrave",
    "Cornberg",
    "Coswig",
    "Coswig",
    "Cottbus",
    "Crailsheim",
    "Cramonshagen",
    "Cranz",
    "Cremlingen",
    "Creussen",
    "Creuzburg",
    "Crimmitschau",
    "Crinitz",
    "Crivitz",
    "Crossen",
    "Crostau",
    "Crottendorf",
    "Cunewalde",
    "Cuxhaven",
    "Daaden",
    "Dabel",
    "Dabelow",
    "Dabendorf",
    "Dabergotz",
    "Dachau",
    "Dachwig",
    "Dackscheid",
    "Dagersheim",
    "Dahlem",
    "Dahlen",
    "Dahlenburg",
    "Dahlenwarsleben",
    "Dahlewitz",
    "Dahme",
    "Dahme",
    "Dahn",
    "Dahnsdorf",
    "Dallgow-Doeberitz",
    "Dalum",
    "Dambeck",
    "Damlos",
    "Damme",
    "Damp",
    "Danischenhagen",
    "Dankenfeld",
    "Dankmarshausen",
    "Danndorf",
    "Dannenberg",
    "Dannstadt-Schauernheim",
    "Dardesheim",
    "Darmstadt",
    "Darstein",
    "Dasing",
    "Dassel",
    "Dassendorf",
    "Dassow",
    "Datteln",
    "Dattenberg",
    "Dauborn",
    "Dauchingen",
    "Daun",
    "Dausenau",
    "Deckenpfronn",
    "Dedeleben",
    "Dedelstorf",
    "Dederstedt",
    "Degerloch",
    "Deggendorf",
    "Deggenhausen",
    "Deggingen",
    "Deidesheim",
    "Deilingen",
    "Deining",
    "Deiningen",
    "Deinste",
    "Deisenhofen",
    "Deisslingen",
    "Deizisau",
    "Delbruck",
    "Delingsdorf",
    "Delitzsch",
    "Dellfeld",
    "Delligsen",
    "Dellmensingen",
    "Delmenhorst",
    "Delve",
    "Demitz-Thumitz",
    "Demmin",
    "Denkendorf",
    "Denkendorf",
    "Denkingen",
    "Denklingen",
    "Densborn",
    "Dentlein am Forst",
    "Denzlingen",
    "Derben",
    "Derenburg",
    "Dermbach",
    "Dernau",
    "Dernbach",
    "Derschen",
    "Dersum",
    "Dessau",
    "Dessow",
    "Destedt",
    "Detern",
    "Detmold",
    "Dettelbach",
    "Dettenhausen",
    "Dettighofen",
    "Dettingen an der Erms",
    "Dettingen an der Iller",
    "Dettingen unter Teck",
    "Dettmannsdorf",
    "Deutsch",
    "Deutsch Evern",
    "Deutsches Haus",
    "Deutz",
    "Deutzen",
    "Dexheim",
    "Diana",
    "Dibbersen",
    "Diebach",
    "Dieblich",
    "Dieburg",
    "Diedersdorf",
    "Diedorf",
    "Diedorf",
    "Diefflen",
    "Diekholzen",
    "Dielheim",
    "Dielingen",
    "Dienheim",
    "Dienstweiler",
    "Diepenau",
    "Diepersdorf",
    "Diepholz",
    "Dierdorf",
    "Dierkow",
    "Dieskau",
    "Diessen am Ammersee",
    "Dietenheim",
    "Dietenhofen",
    "Dietersburg",
    "Dietersheim",
    "Dietfurt",
    "Dietingen",
    "Dietlingen",
    "Dietmannsried",
    "Dietramszell",
    "Dietrichingen",
    "Dietzenbach",
    "Diez",
    "Dillenburg",
    "Dillingen",
    "Dillingen an der Donau",
    "Dingelstadt",
    "Dingelstedt",
    "Dingolfing",
    "Dingolshausen",
    "Dingstede",
    "Dinkelscherben",
    "Dinklage",
    "Dinslaken",
    "Dintesheim",
    "Dipbach",
    "Dippach",
    "Dipperz",
    "Dippoldiswalde",
    "Dirlewang",
    "Dirmingen",
    "Dirmstein",
    "Dischingen",
    "Dissen",
    "Ditfurt",
    "Dittelbrunn",
    "Dittelsdorf",
    "Dittelsheim-Hessloch",
    "Dittenheim",
    "Dittweiler",
    "Ditzingen",
    "Dobel",
    "Dobeln",
    "Doberlug-Kirchhain",
    "Doberschau",
    "Doberschutz",
    "Dobitschen",
    "Dobler",
    "Dobra",
    "Dockingen",
    "Dogern",
    "Dohlau",
    "Dohle",
    "Dohna",
    "Dohren",
    "Dolgesheim",
    "Dollern",
    "Dollnstein",
    "Dombuhl",
    "Domitz",
    "Dommitzsch",
    "Domsdorf",
    "Domsuhl",
    "Donaueschingen",
    "Donaustauf",
    "Donndorf",
    "Donnersdorf",
    "Donsieders",
    "Donzdorf",
    "Dorentrup",
    "Dorf",
    "Dorf Doberitz",
    "Dorfen",
    "Dorfles",
    "Dorfprozelten",
    "Dormagen",
    "Dormettingen",
    "Dornburg",
    "Dorndorf",
    "Dornhan",
    "Dornheim",
    "Dornstadt",
    "Dornstetten",
    "Dornum",
    "Dorpen",
    "Dorpstedt",
    "Dorstadt",
    "Dorsten",
    "Dorth",
    "Dortmund",
    "Dorum",
    "Dorverden",
    "Dorzbach",
    "Dossenheim",
    "Dotlingen",
    "Dotternhausen",
    "Drachselsried",
    "Drage",
    "Dransfeld",
    "Dranske",
    "Drebach",
    "Drebkau",
    "Dreetz",
    "Dreieich",
    "Dreikirchen",
    "Dreis",
    "Dreisbach",
    "Drelsdorf",
    "Drensteinfurt",
    "Dresden",
    "Drestedt",
    "Dreveskirchen",
    "Driedorf",
    "Driftsethe",
    "Drochtersen",
    "Drolshagen",
    "Droyssig",
    "Drubeck",
    "Duchelsdorf",
    "Ducherow",
    "Duchroth",
    "Dudenhofen",
    "Duderode",
    "Duderstadt",
    "Duggendorf",
    "Duingen",
    "Duisburg",
    "Dulmen",
    "Dummerstorf",
    "Dungenheim",
    "Dunningen",
    "Dunnwald",
    "Dunzweiler",
    "Durach",
    "Durbach",
    "Durbheim",
    "Durchhausen",
    "Durlangen",
    "Durmentingen",
    "Durmersheim",
    "Durnau",
    "Durrbrunn",
    "Dusseldorf",
    "Dusslingen",
    "Ebeleben",
    "Ebelsbach",
    "Ebendorf",
    "Ebensfeld",
    "Ebenweiler",
    "Eberbach",
    "Eberdingen",
    "Eberhardzell",
    "Ebermannsdorf",
    "Ebermannstadt",
    "Ebern",
    "Ebernhahn",
    "Ebersbach",
    "Ebersbach an der Fils",
    "Ebersberg",
    "Ebersdorf",
    "Ebershausen",
    "Eberstadt",
    "Eberstadt",
    "Eberswalde",
    "Ebertsheim",
    "Ebhausen",
    "Ebnat",
    "Ebrach",
    "Ebringen",
    "Ebstorf",
    "Eching",
    "Echterdingen",
    "Echzell",
    "Eckartsberg",
    "Eckartsberga",
    "Eckernforde",
    "Eckersdorf",
    "Eckfeld",
    "Ecklingerode",
    "Eddelak",
    "Edelsfeld",
    "Edemissen",
    "Eden",
    "Edenkoben",
    "Ederheim",
    "Edesheim",
    "Edewecht",
    "Edingen-Neckarhausen",
    "Edling",
    "Effelder",
    "Effeltrich",
    "Efringen-Kirchen",
    "Egeln",
    "Egelsbach",
    "Egenhausen",
    "Egenhausen",
    "Egenhofen",
    "Egesheim",
    "Egestorf",
    "Egg an der Gunz",
    "Eggebek",
    "Eggenfelden",
    "Eggenstein-Leopoldshafen",
    "Eggenthal",
    "Eggersdorf",
    "Eggersdorf",
    "Eggesin",
    "Eggingen",
    "Egglham",
    "Eggolsheim",
    "Eggstatt",
    "Eging",
    "Egling",
    "Egloffstein",
    "Egmating",
    "Ehekirchen",
    "Ehingen",
    "Ehingen",
    "Ehingen",
    "Ehlscheid",
    "Ehndorf",
    "Ehningen",
    "Ehrenberg",
    "Ehrenburg",
    "Ehrenfeld",
    "Ehrenfriedersdorf",
    "Ehringshausen",
    "Eibau",
    "Eibelshausen",
    "Eibelstadt",
    "Eibenstock",
    "Eibsee",
    "Eich",
    "Eichelgarten",
    "Eichen",
    "Eichen",
    "Eichenau",
    "Eichenbarleben",
    "Eichenberg",
    "Eichenbuhl",
    "Eichendorf",
    "Eichenzell",
    "Eicherloh",
    "Eichstaett",
    "Eichstedt",
    "Eichstetten",
    "Eichtersheim",
    "Eichwalde",
    "Eicklingen",
    "Eigeltingen",
    "Eilenburg",
    "Eilenburg, Berg",
    "Eilsleben",
    "Eime",
    "Eimeldingen",
    "Eimsheim",
    "Einbeck",
    "Einhaus",
    "Einhausen",
    "Einhausen",
    "Einsbach",
    "Einselthum",
    "Eiselfing",
    "Eisenach",
    "Eisenbach",
    "Eisenbach",
    "Eisenbach",
    "Eisenberg",
    "Eisenberg",
    "Eisenberg",
    "Eisfeld",
    "Eisingen",
    "Eisingen",
    "Eislingen",
    "Eitelborn",
    "Eitensheim",
    "Eiterfeld",
    "Eitorf",
    "Eitting",
    "Elbe",
    "Elbingerode",
    "Eldena",
    "Eldingen",
    "Elend",
    "Elfershausen",
    "Elgersburg",
    "Elisabeth-Sophien-Koog",
    "Elkenroth",
    "Ellefeld",
    "Ellenberg",
    "Ellerau",
    "Ellerbek",
    "Ellerdorf",
    "Ellerhoop",
    "Ellerstadt",
    "Ellgau",
    "Ellhofen",
    "Ellingen",
    "Ellrich",
    "Ellwangen",
    "Ellzee",
    "Elm",
    "Elmenhorst",
    "Elmlohe",
    "Elmpt",
    "Elmshorn",
    "Elmstein",
    "Elsdorf",
    "Elsdorf-Westermuhlen",
    "Elsendorf",
    "Elsenfeld",
    "Elsfleth",
    "Elster",
    "Elsterberg",
    "Elsterwerda",
    "Elstorf",
    "Elstra",
    "Elterlein",
    "Eltmann",
    "Eltville",
    "Elxleben",
    "Elz",
    "Elzach",
    "Elze",
    "Embsen",
    "Emden",
    "Emerkingen",
    "Emlichheim",
    "Emmelshausen",
    "Emmendorf",
    "Emmerich",
    "Emmering",
    "Emmering",
    "Emmerzhausen",
    "Emmingen-Liptingen",
    "Empfertshausen",
    "Empfingen",
    "Emsburen",
    "Emsdetten",
    "Emskirchen",
    "Emstal",
    "Emstek",
    "Emtinghausen",
    "Emtmannsberg",
    "Endingen",
    "Engden",
    "Engelsberg",
    "Engelsbrand",
    "Engelsburg",
    "Engelshof",
    "Engelskirchen",
    "Engelstadt",
    "Engelthal",
    "Engen",
    "Enger",
    "Eningen unter Achalm",
    "Enkenbach-Alsenborn",
    "Enkirch",
    "Ennepetal",
    "Ennigerloh",
    "Ensdorf",
    "Ensdorf",
    "Ensen",
    "Entringen",
    "Epfenbach",
    "Epfendorf",
    "Eppelborn",
    "Eppelheim",
    "Eppelsheim",
    "Eppenbrunn",
    "Eppendorf",
    "Eppenrod",
    "Eppertshausen",
    "Eppingen",
    "Eppishausen",
    "Eppstein",
    "Erbach",
    "Erbach im Odenwald",
    "Erbendorf",
    "Erbes-Budesheim",
    "Erda",
    "Erdeborn",
    "Erding",
    "Erdmannhausen",
    "Erdweg",
    "Eresing",
    "Erfde",
    "Erfelden",
    "Erftstadt",
    "Erfurt",
    "Ergersheim",
    "Ergolding",
    "Ergoldsbach",
    "Erika",
    "Ering",
    "Eriskirch",
    "Erkelenz",
    "Erkheim",
    "Erkner",
    "Erkrath",
    "Erlabrunn",
    "Erlach",
    "Erlangen",
    "Erlau",
    "Erlbach",
    "Erlenbach",
    "Erlenbach am Main",
    "Erlenbach bei Marktheidenfeld",
    "Erlenmoos",
    "Erlensee",
    "Erligheim",
    "Ermershausen",
    "Ermke",
    "Erndtebruck",
    "Ernsgaden",
    "Ernst",
    "Ernsthof",
    "Ernstroda",
    "Ernstthal",
    "Erolzheim",
    "Erpel",
    "Erpolzheim",
    "Ersingen",
    "Ertingen",
    "Erwitte",
    "Erzenhausen",
    "Erzhausen",
    "Erzingen",
    "Eschach",
    "Eschau",
    "Eschbach",
    "Eschborn",
    "Escheburg",
    "Eschede",
    "Eschelbronn",
    "Eschenbach",
    "Eschenbach",
    "Eschenbach",
    "Eschenlohe",
    "Eschershausen",
    "Eschfeld",
    "Eschlkam",
    "Eschwege",
    "Eschweiler",
    "Esens",
    "Esgrus",
    "Eslarn",
    "Eslohe",
    "Espelkamp",
    "Espelkamp-Mittwald",
    "Espenau",
    "Espenhain",
    "Essel",
    "Esselborn",
    "Essen",
    "Essen",
    "Essenbach",
    "Essenheim",
    "Essingen",
    "Esslingen am Neckar",
    "Estenfeld",
    "Esterwegen",
    "Esthal",
    "Estorf",
    "Etschberg",
    "Etteln",
    "Ettenheim",
    "Ettenstatt",
    "Ettersburg",
    "Etterschlag",
    "Ettleben",
    "Ettlingen",
    "Ettringen",
    "Etzelwang",
    "Etzenricht",
    "Euerbach",
    "Euerdorf",
    "Eurasburg",
    "Euskirchen",
    "Eussenheim",
    "Eutin",
    "Eutingen",
    "Everode",
    "Eversmeer",
    "Everswinkel",
    "Evessen",
    "Ewersbach",
    "Eydelstedt",
    "Eyendorf",
    "Eystrup",
    "Fachbach",
    "Fahrdorf",
    "Fahrenbach",
    "Fahrenbach",
    "Fahrendorf",
    "Fahrenkrug",
    "Fahrenzhausen",
    "Fahrland",
    "Faid",
    "Falkenberg",
    "Falkenberg",
    "Falkendorf",
    "Falkenfels",
    "Falkensee",
    "Falkenstein",
    "Fallersleben",
    "Falls",
    "Fambach",
    "Farchant",
    "Fargau-Pratjau",
    "Farnroda",
    "Farnstadt",
    "Fassberg",
    "Fatschenbrunn",
    "Faulbach",
    "Fehl-Ritzhausen",
    "Fehmarn",
    "Fehrbellin",
    "Feilbingert",
    "Feilitzsch",
    "Feldafing",
    "Feldberg",
    "Feldberg-Ort",
    "Felde",
    "Feldkirchen",
    "Feldkirchen",
    "Feldkirchen-Westerham",
    "Feldrennach",
    "Fell",
    "Fellbach",
    "Felm",
    "Felsberg",
    "Ferchesar",
    "Fernthal",
    "Festenburg",
    "Feucht",
    "Feuchtwangen",
    "Feusdorf",
    "Fichtelberg",
    "Fichtenberg",
    "Fichtenberg",
    "Fichtenwalde",
    "Filderstadt",
    "Filsen",
    "Filsum",
    "Filzen",
    "Fincken",
    "Finkenbach",
    "Finnentrop",
    "Finningen",
    "Finowfurt",
    "Finsing",
    "Finsterwalde",
    "Fintel",
    "Firrel",
    "Fischach",
    "Fischbach",
    "Fischbach",
    "Fischbachau",
    "Fischen",
    "Fischerbach",
    "Fischerhude",
    "Fischingen",
    "Flachslanden",
    "Flacht",
    "Flammersfeld",
    "Flechtingen",
    "Fleckeby",
    "Flecken Zechlin",
    "Flein",
    "Fleisbach",
    "Flensburg",
    "Flieden",
    "Flinsbach",
    "Flintbek",
    "Flintsbach",
    "Floha",
    "Flomborn",
    "Flonheim",
    "Florsheim",
    "Florstadt",
    "Floss",
    "Flothe",
    "Flurstedt",
    "Fockbek",
    "Fohren",
    "Fohren-Linden",
    "Forbach",
    "Forchheim",
    "Forchtenberg",
    "Forheim",
    "Forst",
    "Forst",
    "Forst",
    "Forstern",
    "Forsthaus",
    "Forstinning",
    "Frammersbach",
    "Frankenau",
    "Frankenberg",
    "Frankenberg",
    "Frankenheim",
    "Frankenstein",
    "Frankenthal",
    "Frankenwinheim",
    "Frankfurt (Oder)",
    "Frankfurt am Main",
    "Frankisch-Crumbach",
    "Frankweiler",
    "Franzenheim",
    "Frasdorf",
    "Frauenau",
    "Frauendorf",
    "Frauenstein",
    "Fraunberg",
    "Fraureuth",
    "Frechen",
    "Freckenfeld",
    "Freckenhorst",
    "Fredelsloh",
    "Freden",
    "Fredersdorf",
    "Freiberg",
    "Freiberg am Neckar",
    "Freiburg",
    "Freiburg",
    "Freihung",
    "Freilassing",
    "Freimersheim",
    "Freinsheim",
    "Freirachdorf",
    "Freisbach",
    "Freisen",
    "Freising",
    "Freital",
    "Freiwalde",
    "Fremdingen",
    "Frensdorf",
    "Freren",
    "Fresenburg",
    "Freudenberg",
    "Freudenberg",
    "Freudenberg",
    "Freudenberg",
    "Freudenburg",
    "Freudenstadt",
    "Freudental",
    "Freudenweiler",
    "Freund",
    "Freyburg",
    "Freystadt",
    "Freyung",
    "Frickenhausen",
    "Frickingen",
    "Fridingen an der Donau",
    "Fridolfing",
    "Friedberg",
    "Friedberg",
    "Friedeburg",
    "Friedelsheim",
    "Friedensau",
    "Friedensdorf",
    "Friedenweiler",
    "Friedersdorf",
    "Friedewald",
    "Friedland",
    "Friedland",
    "Friedrichroda",
    "Friedrichsdorf",
    "Friedrichshafen",
    "Friedrichsholm",
    "Friedrichskoog",
    "Friedrichsort",
    "Friedrichstadt",
    "Friedrichsthal",
    "Friedrichswerth",
    "Frielendorf",
    "Friemar",
    "Friesack",
    "Friesenhagen",
    "Friesenheim",
    "Friesenheim",
    "Friesenried",
    "Friesoythe",
    "Friolzheim",
    "Frittlingen",
    "Fritzlar",
    "Frohburg",
    "Frommern",
    "Frondenberg",
    "Fronhausen",
    "Fronhofen",
    "Frontenhausen",
    "Frose",
    "Fuchshain",
    "Fuchsstadt",
    "Fulda",
    "Fuldabruck",
    "Fuldatal",
    "Funfeichen",
    "Funfstetten",
    "Furholzen",
    "Furstenau",
    "Furstenberg",
    "Furstenberg",
    "Fursteneck",
    "Furstenstein",
    "Furstenwald",
    "Furstenwalde",
    "Furstenzell",
    "Furth",
    "Furth im Wald",
    "Furthen",
    "Furtwangen im Schwarzwald",
    "Fusternberg",
    "Gablenz",
    "Gablingen",
    "Gachenbach",
    "Gadebusch",
    "Gadegast",
    "Gagern",
    "Gaggenau",
    "Gahlkow",
    "Gaiberg",
    "Gaienhofen",
    "Gaildorf",
    "Gailingen",
    "Gaimersheim",
    "Gaishofen",
    "Gaissach",
    "Galenbeck",
    "Gammellund",
    "Gammelsdorf",
    "Gammelshausen",
    "Gammertingen",
    "Ganderkesee",
    "Gangelt",
    "Gangkofen",
    "Gangloff",
    "Garbek",
    "Garbsen",
    "Garching an der Alz",
    "Garching bei Munchen",
    "Gardelegen",
    "Garden",
    "Garlstorf",
    "Garmisch-Partenkirchen",
    "Garrel",
    "Gars",
    "Gartow",
    "Gartringen",
    "Garz",
    "Garz",
    "Gatersleben",
    "Gattendorf",
    "Gau-Algesheim",
    "Gau-Bickelheim",
    "Gau-Bischofsheim",
    "Gau-Odernheim",
    "Gaukonigshofen",
    "Gauting",
    "Gebenbach",
    "Gebesee",
    "Gebhardshain",
    "Gechingen",
    "Gedern",
    "Geeste",
    "Geesthacht",
    "Gefell",
    "Gefrees",
    "Gehaus",
    "Gehlenbeck",
    "Gehofen",
    "Gehrde",
    "Gehrden",
    "Gehrweiler",
    "Geiersthal",
    "Geilenkirchen",
    "Geinsheim",
    "Geinsheim",
    "Geiselbach",
    "Geiselhoring",
    "Geiselwind",
    "Geisenfeld",
    "Geisenhain",
    "Geisenhausen",
    "Geisenheim",
    "Geisfeld",
    "Geisig",
    "Geising",
    "Geisingen",
    "Geisling",
    "Geislingen",
    "Geislingen an der Steige",
    "Geithain",
    "Gelbensande",
    "Geldern",
    "Geldersheim",
    "Gelenau",
    "Gelnhausen",
    "Gelsenkirchen",
    "Geltendorf",
    "Gelting",
    "Gemmingen",
    "Gemmrigheim",
    "Gemunden",
    "Gemunden an der Wohra",
    "Genderkingen",
    "Gendorf",
    "Gengenbach",
    "Genkingen",
    "Gensingen",
    "Genthin",
    "Georgensgmund",
    "Georgenthal",
    "Georgsdorf",
    "Georgsmarienhutte",
    "Gera",
    "Geraberg",
    "Gerabronn",
    "Gerach",
    "Geratskirchen",
    "Gerau",
    "Gerbershausen",
    "Gerbrunn",
    "Gerbstedt",
    "Gerdau",
    "Geretsried",
    "Gerhardshofen",
    "Gerichshain",
    "Geringswalde",
    "Gerlachsheim",
    "Gerlfangen",
    "Gerlingen",
    "Germering",
    "Germersheim",
    "Gernrode",
    "Gernrode",
    "Gernsbach",
    "Gernsheim",
    "Gerolfingen",
    "Gerolsbach",
    "Gerolsheim",
    "Gerolstein",
    "Gerolzhofen",
    "Gersdorf",
    "Gersfeld",
    "Gershasen",
    "Gersheim",
    "Gerstetten",
    "Gersthofen",
    "Gerstungen",
    "Gerwisch",
    "Gerzen",
    "Gescher",
    "Geschwenda",
    "Geseke",
    "Gessertshausen",
    "Gestungshausen",
    "Getelo",
    "Gettorf",
    "Gevelsberg",
    "Gevensleben",
    "Geversdorf",
    "Geyer",
    "Giebelstadt",
    "Gieboldehausen",
    "Gielde",
    "Gieleroth",
    "Giengen an der Brenz",
    "Gierstadt",
    "Giesen",
    "Giesenkirchen",
    "Giessen",
    "Gifhorn",
    "Giggenhausen",
    "Gilching",
    "Gillenfeld",
    "Gillersdorf",
    "Gilserberg",
    "Gimbsheim",
    "Gingen an der Fils",
    "Gingst",
    "Ginsheim-Gustavsburg",
    "Girod",
    "Gittelde",
    "Gladbach",
    "Gladbeck",
    "Gladenbach",
    "Glan-Munchweiler",
    "Glandorf",
    "Glasewitz",
    "Glashagen",
    "Glashutte",
    "Glashutten",
    "Glashutten",
    "Glattbach",
    "Glatten",
    "Glauburg",
    "Glauchau",
    "Gleichen",
    "Gleiritsch",
    "Gleissenberg",
    "Gleisweiler",
    "Gleschendorf",
    "Glien",
    "Glienicke",
    "Glinde",
    "Glindenberg",
    "Glonn",
    "Gluckstadt",
    "Gmain bei Weidach",
    "Gmund am Tegernsee",
    "Gnadau",
    "Gnarrenburg",
    "Gnaschwitz",
    "Gnoien",
    "Gnotzheim",
    "Gnutz",
    "Goch",
    "Gochsheim",
    "Goda",
    "Goggingen",
    "Gohrde",
    "Gohren",
    "Goldbach",
    "Goldberg",
    "Goldelund",
    "Goldenstedt",
    "Goldewin",
    "Goldkronach",
    "Gollheim",
    "Gollhofen",
    "Golm",
    "Golmbach",
    "Golssen",
    "Golzow",
    "Gomadingen",
    "Gomaringen",
    "Gommern",
    "Gommersheim",
    "Gondelsheim",
    "Gondenbrett",
    "Gonnheim",
    "Gorenzen",
    "Goritz",
    "Gorkwitz",
    "Gornsdorf",
    "Gorschendorf",
    "Gorwihl",
    "Goseck",
    "Gosheim",
    "Goslar",
    "Gossel",
    "Gossen",
    "Gossersweiler-Stein",
    "Gossnitz",
    "Gossweinstein",
    "Gotha",
    "Gotteszell",
    "Gottfrieding",
    "Gottmadingen",
    "Graach",
    "Grabau",
    "Graben",
    "Graben-Neudorf",
    "Grabenstatt",
    "Grabenstetten",
    "Grabow",
    "Grafelfing",
    "Grafenau",
    "Grafenberg",
    "Grafengehaig",
    "Grafenhainichen",
    "Grafenhausen",
    "Grafenrheinfeld",
    "Grafenroda",
    "Grafenthal",
    "Grafenwohr",
    "Grafhorst",
    "Grafing bei Munchen",
    "Grafrath",
    "Grafschaft",
    "Grainau",
    "Graitschen",
    "Grambin",
    "Grambow",
    "Grammow",
    "Gramzow",
    "Gransee",
    "Grasberg",
    "Grasbrunn",
    "Grassau",
    "Gravenbruch",
    "Graveneck",
    "Gravenwiesbach",
    "Grebbin",
    "Grebenau",
    "Grebenhain",
    "Grebenstein",
    "Greding",
    "Green",
    "Grefrath",
    "Greifenberg",
    "Greifenstein",
    "Greifswald",
    "Greiling",
    "Greiz",
    "Gremersdorf",
    "Gremsdorf",
    "Grenzach-Wyhlen",
    "Grenzau",
    "Greppin",
    "Grethem",
    "Grethen",
    "Grettstadt",
    "Greussen",
    "Greussenheim",
    "Greven",
    "Grevenbroich",
    "Grevesmuhlen",
    "Gribow",
    "Gries",
    "Griesheim",
    "Griesingen",
    "Griesstatt",
    "Grimma",
    "Grimmen",
    "Grobenzell",
    "Grobzig",
    "Groditz",
    "Groditz",
    "Groeningen",
    "Groitzsch",
    "Grolsheim",
    "Grombach",
    "Gromitz",
    "Gronau",
    "Gronau",
    "Gronau",
    "Gronenbach",
    "Gronwohld",
    "Gross Bengerstorf",
    "Gross Gronau",
    "Gross Kiesow",
    "Gross Koris",
    "Gross Kreutz",
    "Gross Kummerfeld",
    "Gross Lindow",
    "Gross Miltzow",
    "Gross Oesingen",
    "Gross Rheide",
    "Gross Santersleben",
    "Gross Twulpstedt",
    "Gross Wittensee",
    "Gross-Bieberau",
    "Gross-Gerau",
    "Gross-Rohrheim",
    "Gross-Umstadt",
    "Gross-Zimmern",
    "Grossaitingen",
    "Grossalmerode",
    "Grossbardorf",
    "Grossbeeren",
    "Grossbettlingen",
    "Grossbottwar",
    "Grossbreitenbach",
    "Grosselfingen",
    "Grossenaspe",
    "Grossengottern",
    "Grossenhain",
    "Grossenkneten",
    "Grossenluder",
    "Grossensee",
    "Grossenseebach",
    "Grossenwiehe",
    "Grosserlach",
    "Grosshabersdorf",
    "Grosshansdorf",
    "Grossharthau",
    "Grossheide",
    "Grossheirath",
    "Grossheubach",
    "Grosskarolinenfeld",
    "Grosskorbetha",
    "Grosskrotzenburg",
    "Grosskugel",
    "Grosslittgen",
    "Grosslobichau",
    "Grossmaischeid",
    "Grossmehring",
    "Grossmonra",
    "Grossostheim",
    "Grossposna",
    "Grossraschen",
    "Grossrinderfeld",
    "Grossrohrsdorf",
    "Grossrosseln",
    "Grossruckerswalde",
    "Grossschirma",
    "Grossschonau",
    "Grosssolt",
    "Grosswallstadt",
    "Grosswechsungen",
    "Grove",
    "Gruibingen",
    "Gruna",
    "Grunberg",
    "Grundau",
    "Grundhof",
    "Grunenberg",
    "Grunenplan",
    "Grunenthal",
    "Grunhainichen",
    "Grunheide",
    "Grunhof",
    "Grunkraut",
    "Grunstadt",
    "Grunwald",
    "Gschwend",
    "Guben",
    "Guckheim",
    "Gudensberg",
    "Guenzburg",
    "Guestrow",
    "Guglingen",
    "Gulzow",
    "Gulzow",
    "Gulzowshof",
    "Gummersbach",
    "Gundelfingen",
    "Gundelfingen",
    "Gundelsheim",
    "Gundelsheim",
    "Gundernhausen",
    "Gundersheim",
    "Gundremmingen",
    "Gunningen",
    "Guntersblum",
    "Guntersleben",
    "Gunthersleben",
    "Gunzenhausen",
    "Gurtweil",
    "Gustavel",
    "Gusten",
    "Gusterath",
    "Gutach (Schwarzwaldbahn)",
    "Gutach im Breisgau",
    "Gutenacker",
    "Gutenberg",
    "Gutenstetten",
    "Gutenzell-Hurbel",
    "Gutmadingen",
    "Gutow",
    "Gutzkow",
    "Guxhagen",
    "Gyhum",
    "Haag",
    "Haag an der Amper",
    "Haan",
    "Haar",
    "Haarbach",
    "Haaren",
    "Habach",
    "Habenhausen",
    "Habersdorf",
    "Hachenburg",
    "Hackenheim",
    "Hadamar",
    "Hademstorf",
    "Haenigsen",
    "Hafenlohr",
    "Haffkrug",
    "Hage",
    "Hagelstadt",
    "Hagen",
    "Hagen",
    "Hagenbach",
    "Hagenburg",
    "Hagenow",
    "Hagnau",
    "Hahn",
    "Hahnbach",
    "Hahnenbach",
    "Hahnheim",
    "Hahnstatten",
    "Haibach",
    "Haide",
    "Haidhof",
    "Haidmuhle",
    "Haiger",
    "Haigerloch",
    "Haimhausen",
    "Haiming",
    "Haina",
    "Hainewalde",
    "Hainichen",
    "Hainsfarth",
    "Haiterbach",
    "Halbe",
    "Halberg",
    "Halberstadt",
    "Halblech",
    "Halbs",
    "Haldensleben I",
    "Haldenwang",
    "Halfing",
    "Hallbergmoos",
    "Halle",
    "Halle",
    "Halle",
    "Halle Neustadt",
    "Hallenberg",
    "Hallerndorf",
    "Hallnberg",
    "Hallschlag",
    "Hallstadt",
    "Halsbach",
    "Halsbrucke",
    "Halsenbach",
    "Halstenbek",
    "Haltern",
    "Halver",
    "Halvesbostel",
    "Hamb",
    "Hamberge",
    "Hambergen",
    "Hambrucken",
    "Hambuhren",
    "Hamburg",
    "Hamdorf",
    "Hamelin",
    "Hamm",
    "Hammah",
    "Hammelburg",
    "Hammersbach",
    "Hamminkeln",
    "Hamwarde",
    "Hanau",
    "Handeloh",
    "Handewitt",
    "Hanerau-Hademarschen",
    "Hanhofen",
    "Hankensbuttel",
    "Hannover",
    "Hanover",
    "Hanstedt",
    "Happurg",
    "Harburg",
    "Hard",
    "Hardegsen",
    "Hardenberg",
    "Hardheim",
    "Hardt",
    "Haren",
    "Hargesheim",
    "Harlachen",
    "Harmsdorf",
    "Harmstorf",
    "Harpstedt",
    "Harra",
    "Harrislee",
    "Harschbach",
    "Harsefeld",
    "Harsewinkel",
    "Harsleben",
    "Harste",
    "Harsum",
    "Hartenfels",
    "Hartenholm",
    "Hartenstein",
    "Hartenstein",
    "Hartha",
    "Harthausen",
    "Hartheim",
    "Hartmannsdorf",
    "Hartmannsdorf",
    "Harxheim",
    "Harzgerode",
    "Hasbergen",
    "Haschbach an der Glan",
    "Hasel",
    "Haselbach",
    "Haseldorf",
    "Haselunne",
    "Hasenmoor",
    "Haslach im Kinzigtal",
    "Hasloch",
    "Hasloh",
    "Hassel",
    "Hassel",
    "Hasselbach",
    "Hasselberg",
    "Hasselfelde",
    "Hassfurt",
    "Hassloch",
    "Hassmersheim",
    "Haste",
    "Hastenrath",
    "Haswede",
    "Hatten",
    "Hattenhofen",
    "Hattenhofen",
    "Hattersheim",
    "Hattert",
    "Hattingen",
    "Hattorf",
    "Hattstedt",
    "Hatzenbuhl",
    "Hatzfeld",
    "Haus",
    "Hausach",
    "Hausen",
    "Hausen",
    "Hausen",
    "Hausen am Tann",
    "Hausen ob Verena",
    "Hausen uber Aar",
    "Hauser",
    "Hausern",
    "Hausham",
    "Hausten",
    "Hauteroda",
    "Hauzenberg",
    "Havelberg",
    "Haverkamp",
    "Havixbeck",
    "Hawangen",
    "Hayingen",
    "Hebertsfelden",
    "Hebertshausen",
    "Hechingen",
    "Hechthausen",
    "Hecklingen",
    "Heddesheim",
    "Heede",
    "Heeg",
    "Heek",
    "Heeslingen",
    "Hefersweiler",
    "Hegge",
    "Hehlen",
    "Heide",
    "Heide",
    "Heideck",
    "Heidehof",
    "Heidekamp",
    "Heidelberg",
    "Heiden",
    "Heidenau",
    "Heidenau",
    "Heidenburg",
    "Heidenheim",
    "Heidesheim",
    "Heigenbrucken",
    "Heikendorf",
    "Heilbad Heiligenstadt",
    "Heilbronn",
    "Heiligengrabe",
    "Heiligenhafen",
    "Heiligenhagen",
    "Heiligenhaus",
    "Heiligenroth",
    "Heiligenstadt",
    "Heiligenstedtenerkamp",
    "Heiligenthal",
    "Heiligkreuzsteinach",
    "Heilingen",
    "Heilsbronn",
    "Heimbach",
    "Heimbach",
    "Heimbuchenthal",
    "Heimenkirch",
    "Heimertingen",
    "Heimerzheim",
    "Heimsheim",
    "Heimstetten",
    "Heinade",
    "Heinbockel",
    "Heinersreuth",
    "Heiningen",
    "Heiningen",
    "Heinkenborstel",
    "Heinrichsthal",
    "Heinsberg",
    "Heinsdorf",
    "Heinz",
    "Heinzenberg",
    "Heinzenhausen",
    "Heisede",
    "Heist",
    "Heitersheim",
    "Helbra",
    "Heldrungen",
    "Helfant",
    "Helferskirchen",
    "Hellenhahn-Schellenberg",
    "Hellenthal",
    "Hellstein",
    "Hellweg",
    "Helmbrechts",
    "Helmenzen",
    "Helmern",
    "Helmershausen",
    "Helmstadt",
    "Helmste",
    "Helmstedt",
    "Helpsen",
    "Helsa",
    "Heltersberg",
    "Helvesiek",
    "Hemau",
    "Hemdingen",
    "Hemelingen",
    "Hemer",
    "Hemhofen",
    "Hemmelzen",
    "Hemmersdorf",
    "Hemmingen",
    "Hemmingen",
    "Hemmingstedt",
    "Hemmoor",
    "Hemsbach",
    "Hemsbunde",
    "Hemslingen",
    "Henfenfeld",
    "Hengersberg",
    "Hennef",
    "Hennickendorf",
    "Hennigsdorf",
    "Hennstedt",
    "Hennweiler",
    "Henstedt-Ulzburg",
    "Hepberg",
    "Heppdiel",
    "Heppenheim an der Bergstrasse",
    "Hepstedt",
    "Herbertingen",
    "Herbolzheim",
    "Herborn",
    "Herborn",
    "Herbrechtingen",
    "Herbsleben",
    "Herbstein",
    "Herdecke",
    "Herdorf",
    "Herdwangen-Schonach",
    "Herfatz",
    "Herford",
    "Herforst",
    "Hergenfeld",
    "Hergisdorf",
    "Hergolding",
    "Hergolshausen",
    "Heringen",
    "Heringen",
    "Heringsdorf",
    "Heringsdorf",
    "Herleshausen",
    "Hermannsburg",
    "Hermaringen",
    "Hermersberg",
    "Hermeskeil",
    "Hermsdorf",
    "Herne",
    "Heroldsbach",
    "Heroldsberg",
    "Herren Steinfeld",
    "Herren-Sulzbach",
    "Herrenberg",
    "Herresbach",
    "Herrieden",
    "Herringen",
    "Herrischried",
    "Herrnburg",
    "Herrnhut",
    "Herrsching am Ammersee",
    "Herrstein",
    "Hersbruck",
    "Herschbach",
    "Herschberg",
    "Herscheid",
    "Herschweiler-Pettersheim",
    "Herten",
    "Herxheim",
    "Herzberg",
    "Herzberg am Harz",
    "Herzebrock",
    "Herzfeld",
    "Herzlake",
    "Herzogenaurach",
    "Herzogenrath",
    "Herzogsweiler",
    "Hesedorf",
    "Hesel",
    "Hespe",
    "Hessdorf",
    "Hesselbach",
    "Hessen",
    "Hessheim",
    "Hessigheim",
    "Hessisch Lichtenau",
    "Hessisch Oldendorf",
    "Hetschburg",
    "Hettenleidelheim",
    "Hettenrodt",
    "Hettstadt",
    "Hettstedt",
    "Hetzenhausen",
    "Hetzerath",
    "Hetzlinshofen",
    "Hetzwege",
    "Heubach",
    "Heubach",
    "Heuchelheim",
    "Heuchlingen",
    "Heudeber",
    "Heudorf",
    "Heuerssen",
    "Heusenstamm",
    "Heustreu",
    "Heusweiler",
    "Heuweiler",
    "Heyerode",
    "Hiddenhausen",
    "Hiddensee",
    "Hilbersdorf",
    "Hilchenbach",
    "Hildburghausen",
    "Hildebrandshausen",
    "Hilden",
    "Hilders",
    "Hildesheim",
    "Hildrizhausen",
    "Hilgermissen",
    "Hilgert",
    "Hilgertshausen-Tandern",
    "Hille",
    "Hillersleben",
    "Hillesheim",
    "Hillscheid",
    "Hilpoltstein",
    "Hilscheid",
    "Hilter",
    "Hiltpoltstein",
    "Hiltrup",
    "Hilwartshausen",
    "Hilzingen",
    "Himbergen",
    "Himmelkron",
    "Himmelpforten",
    "Himmelstadt",
    "Hinrichshagen",
    "Hinte",
    "Hinterhermsdorf",
    "Hinterm Horn",
    "Hinterschmiding",
    "Hinterzarten",
    "Hirrlingen",
    "Hirschaid",
    "Hirschau",
    "Hirschberg",
    "Hirschfelde",
    "Hirschhorn",
    "Hirschhorn",
    "Hirschstein",
    "Hirstein",
    "Hirzenhain",
    "Hirzlei",
    "Hittbergen",
    "Hitzacker",
    "Hitzhofen",
    "Hobbersdorf",
    "Hobeck",
    "Hochberg",
    "Hochborn",
    "Hochdonn",
    "Hochdorf",
    "Hochdorf",
    "Hochdorf-Assenheim",
    "Hochenschwand",
    "Hochheim am Main",
    "Hochkirch",
    "Hochscheid",
    "Hochspeyer",
    "Hochst im Odenwald",
    "Hochstadt",
    "Hochstadt an der Aisch",
    "Hochstadten",
    "Hochstenbach",
    "Hochstetten-Dhaun",
    "Hochstrasse",
    "Hochwang",
    "Hockendorf",
    "Hockenheim",
    "Hodenhagen",
    "Hof",
    "Hof",
    "Hof",
    "Hofbieber",
    "Hofen",
    "Hofen an der Enz",
    "Hofer",
    "Hofgarten",
    "Hofgeismar",
    "Hofheim",
    "Hofheim am Taunus",
    "Hofkirchen",
    "Hofstetten",
    "Hohberg",
    "Hohe",
    "Hoheinod",
    "Hohen",
    "Hohen",
    "Hohen Neuendorf",
    "Hohen Schwarfs",
    "Hohen Wangelin",
    "Hohenaspe",
    "Hohenau",
    "Hohenberg",
    "Hohenberg",
    "Hohenbergham",
    "Hohenbocka",
    "Hohenbrunn",
    "Hohendodeleben",
    "Hoheneggelsen",
    "Hohenerxleben",
    "Hohenfelde",
    "Hohenfelde",
    "Hohenfels",
    "Hohenfinow",
    "Hohenfurch",
    "Hohenhameln",
    "Hohenhaus",
    "Hohenhorn",
    "Hohenkammer",
    "Hohenkirchen-Siegertsbrunn",
    "Hohenleimbach",
    "Hohenleipisch",
    "Hohenleuben",
    "Hohenlimburg",
    "Hohenlinden",
    "Hohenlockstedt",
    "Hohenmolsen",
    "Hohenollen",
    "Hohenpeissenberg",
    "Hohenroda",
    "Hohenroth",
    "Hohenschaftlarn",
    "Hohenstein",
    "Hohenstein",
    "Hohenstein-Ernstthal",
    "Hohentengen am Hochrhein",
    "Hohenthann",
    "Hohenwart",
    "Hohenwarthe",
    "Hohenwestedt",
    "Hohn",
    "Hohn",
    "Hohndorf",
    "Hohne",
    "Hohnhorst",
    "Hohnstein",
    "Hohnstorf",
    "Hohr-Grenzhausen",
    "Hoisdorf",
    "Hoitlingen",
    "Holdorf",
    "Holle",
    "Hollenbach",
    "Hollenstedt",
    "Holler",
    "Hollern",
    "Hollfeld",
    "Hollingstedt",
    "Hollstadt",
    "Holm",
    "Holte",
    "Holthausen",
    "Holtinghausen",
    "Holtland",
    "Holtsee",
    "Holzbach",
    "Holzengel",
    "Holzgerlingen",
    "Holzgunz",
    "Holzhausen",
    "Holzhausen an der Haide",
    "Holzheim",
    "Holzheim",
    "Holzkirchen",
    "Holzmaden",
    "Holzminden",
    "Holzweissig",
    "Holzwickede",
    "Homberg",
    "Homberg",
    "Homburg",
    "Honebach",
    "Hoogstede",
    "Hopferau",
    "Hopfingen",
    "Hoppegarten",
    "Hoppegarten",
    "Hoppstadten-Weiersbach",
    "Hopsten",
    "Horath",
    "Horb am Neckar",
    "Horbach",
    "Horbruch",
    "Hordt",
    "Horgau",
    "Horgertshausen",
    "Horhausen",
    "Hormersdorf",
    "Horn-Bad Meinberg",
    "Hornbach",
    "Hornberg",
    "Hornburg",
    "Horneburg",
    "Horperath",
    "Horressen",
    "Horrweiler",
    "Horselgau",
    "Horst",
    "Horst",
    "Horst",
    "Horstdorf",
    "Horstedt",
    "Horstel",
    "Horstmar",
    "Hosbach",
    "Hosenfeld",
    "Hoslwang",
    "Hosskirch",
    "Host",
    "Hotensleben",
    "Hovelhof",
    "Hoya",
    "Hoyerswerda",
    "Hoym",
    "Huckelhoven",
    "Huckeswagen",
    "Hude",
    "Huellhorst",
    "Huffelsheim",
    "Huffenhardt",
    "Hufingen",
    "Hugelsheim",
    "Huglfing",
    "Huhnerhof",
    "Huje",
    "Hulben",
    "Humptrup",
    "Hunderdorf",
    "Hundhaupten",
    "Hundisburg",
    "Hundsangen",
    "Hundsdorf",
    "Hunfeld",
    "Hungen",
    "Hungenroth",
    "Hunxe",
    "Hunzel",
    "Hurlach",
    "Hurtgenwald",
    "Husby",
    "Husum",
    "Husum",
    "Hutschenhausen",
    "Huttblek",
    "Huttenberg",
    "Hutthurm",
    "Huttisheim",
    "Huttlingen",
    "Ibach",
    "Ibbenbueren",
    "Ichenhausen",
    "Ichtershausen",
    "Icker",
    "Icking",
    "Idar-Oberstein",
    "Idenheim",
    "Idstein",
    "Iffeldorf",
    "Iffezheim",
    "Igel",
    "Igensdorf",
    "Igersheim",
    "Iggensbach",
    "Iggingen",
    "Ihlow",
    "Ihringen",
    "Ihrlerstein",
    "Ilbesheim",
    "Ilfeld",
    "Illerrieden",
    "Illertissen",
    "Illingen",
    "Illingen",
    "Illschwang",
    "Ilmenau",
    "Ilmmunster",
    "Ilsede",
    "Ilsenburg",
    "Ilsfeld",
    "Ilshofen",
    "Ilvesheim",
    "Im Loh",
    "Immendingen",
    "Immenhausen",
    "Immenreuth",
    "Immenstaad am Bodensee",
    "Immenstadt im Allgau",
    "Imsbach",
    "In der Meer",
    "Inchenhofen",
    "Inden",
    "Ingelfingen",
    "Ingelheim am Rhein",
    "Ingersleben",
    "Ingoldingen",
    "Ingolstadt",
    "Innernzell",
    "Inning am Ammersee",
    "Inning am Holz",
    "Insel",
    "Insheim",
    "Insingen",
    "Insul",
    "Inzell",
    "Inzigkofen",
    "Inzlingen",
    "Inzmuhlen",
    "Iphofen",
    "Ippesheim",
    "Ipsheim",
    "Irlbach",
    "Irmenach",
    "Irrel",
    "Irschenberg",
    "Irsee",
    "Irxleben",
    "Isen",
    "Isenburg",
    "Isenburg",
    "Isenbuttel",
    "Iserlohn",
    "Ismaning",
    "Isny im Allgau",
    "Ispringen",
    "Isselburg",
    "Isseroda",
    "Isserstedt",
    "Issigau",
    "Issum",
    "Istein",
    "Ittersbach",
    "Ittlingen",
    "Itzehoe",
    "Itzstedt",
    "Ivenack",
    "Jacobsdorf",
    "Jaderberg",
    "Jagel",
    "Jagerhof",
    "Jagsthausen",
    "Jagstzell",
    "Jahnsdorf",
    "Jahnshof",
    "Jameln",
    "Jarmen",
    "Jebenhausen",
    "Jeddeloh Eins",
    "Jelmstorf",
    "Jembke",
    "Jemgum",
    "Jena",
    "Jengen",
    "Jerichow",
    "Jersbek",
    "Jersleben",
    "Jesberg",
    "Jesendorf",
    "Jessen",
    "Jessnitz",
    "Jestadt",
    "Jesteburg",
    "Jestetten",
    "Jettenbach",
    "Jettingen-Scheppach",
    "Jetzendorf",
    "Jevenstedt",
    "Jever",
    "Jheringsfehn",
    "Joachimsthal",
    "Jockgrim",
    "Joehlingen",
    "Johannesberg",
    "Johanngeorgenstadt",
    "Johstadt",
    "Jork",
    "Jorl",
    "Jubek",
    "Juchen",
    "Jugenheim",
    "Juist",
    "Julbach",
    "Julich",
    "Juliusburg",
    "Jungingen",
    "Junkerath",
    "Juterbog",
    "Kaaks",
    "Kaarst",
    "Kadelburg",
    "Kaden",
    "Kagsdorf",
    "Kahl am Main",
    "Kahla",
    "Kaisersbach",
    "Kaisersesch",
    "Kaiserslautern",
    "Kaisheim",
    "Kakenstorf",
    "Kalbach",
    "Kaldenkirchen",
    "Kalefeld",
    "Kalenborn-Scheuern",
    "Kalkar",
    "Kalkreuth",
    "Kall",
    "Kallmunz",
    "Kallstadt",
    "Kaltenengers",
    "Kaltenkirchen",
    "Kaltennordheim",
    "Kaltenwestheim",
    "Kamen",
    "Kamenz",
    "Kammerforst",
    "Kammerforst",
    "Kammerstein",
    "Kamp-Bornhofen",
    "Kamp-Lintfort",
    "Kampen",
    "Kamsdorf",
    "Kandel",
    "Kandern",
    "Kanzlei",
    "Kapellen-Drusweiler",
    "Kapellenberg",
    "Kappel-Grafenhausen",
    "Kappeln",
    "Kappelrodeck",
    "Kapsweyer",
    "Karbach",
    "Karben",
    "Karin",
    "Karl",
    "Karlsbad",
    "Karlsdorf-Neuthard",
    "Karlsfeld",
    "Karlshagen",
    "Karlshausen",
    "Karlshuld",
    "Karlskron",
    "Karlsruhe",
    "Karlstadt am Main",
    "Karlstein",
    "Karlum",
    "Karsbach",
    "Karsdorf",
    "Karstadt",
    "Karweiler",
    "Kasbach-Ohlenberg",
    "Kasdorf",
    "Kasel-Golzig",
    "Kasendorf",
    "Kasseedorf",
    "Kassel",
    "Kassow",
    "Kastel",
    "Kastellaun",
    "Kastl",
    "Kastorf",
    "Katlenburg-Lindau",
    "Katzenelnbogen",
    "Katzenmoos",
    "Katzenstein",
    "Katzow",
    "Katzwang",
    "Katzwinkel",
    "Kaub",
    "Kaufbeuren",
    "Kaufering",
    "Kaufungen",
    "Kaulsdorf",
    "Kayhude",
    "Kayna",
    "Kefenrod",
    "Kefferhausen",
    "Kehl",
    "Keidelheim",
    "Keitum",
    "Kelberg",
    "Kelbra",
    "Kelheim",
    "Kelkheim (Taunus)",
    "Kell",
    "Kellenhusen",
    "Keller",
    "Kellinghusen",
    "Kelsterbach",
    "Kemberg",
    "Kemerting",
    "Kemmern",
    "Kemnath",
    "Kempen",
    "Kempenich",
    "Kempfeld",
    "Kempten (Allgaeu)",
    "Kendenich",
    "Kenn",
    "Kennenburg",
    "Kenzingen",
    "Kerlingen",
    "Kerpen",
    "Kervenheim",
    "Kerzenheim",
    "Kesselbach",
    "Kesselsdorf",
    "Kestert",
    "Ketsch",
    "Kettenkamp",
    "Kettershausen",
    "Kettig",
    "Ketzin",
    "Kevelaer",
    "Kiedrich",
    "Kiefersfelden",
    "Kiel",
    "Kienberg",
    "Kierspe",
    "Kieselbach",
    "Kieselbronn",
    "Kiez",
    "Kindelbruck",
    "Kinding",
    "Kindsbach",
    "Kinheim",
    "Kinsau",
    "Kipfenberg",
    "Kippenheim",
    "Kirberg",
    "Kirburg",
    "Kirchardt",
    "Kirchberg",
    "Kirchberg",
    "Kirchberg",
    "Kirchberg",
    "Kirchdorf",
    "Kirchdorf",
    "Kirchdorf am Inn",
    "Kirchdorf im Wald",
    "Kirchehrenbach",
    "Kirchen",
    "Kirchendemenreuth",
    "Kirchenlamitz",
    "Kirchentellinsfurt",
    "Kirchenthumbach",
    "Kirchgellersen",
    "Kirchhain",
    "Kirchham",
    "Kirchhasel",
    "Kirchhaslach",
    "Kirchheilingen",
    "Kirchheim",
    "Kirchheim",
    "Kirchheim am Ries",
    "Kirchheim an der Weinstrasse",
    "Kirchheim unter Teck",
    "Kirchheimbolanden",
    "Kirchhellen",
    "Kirchhundem",
    "Kirchlauter",
    "Kirchlengern",
    "Kirchlinteln",
    "Kirchreit",
    "Kirchroth",
    "Kirchsahr",
    "Kirchseelte",
    "Kirchseeon",
    "Kirchtimke",
    "Kirchweg",
    "Kirchweidach",
    "Kirchweyhe",
    "Kirchworbis",
    "Kirchzarten",
    "Kirchzell",
    "Kirkel",
    "Kirn",
    "Kirrweiler",
    "Kirschau",
    "Kirschfurt",
    "Kirschhofen",
    "Kirspenich",
    "Kirtorf",
    "Kisdorf",
    "Kisselbach",
    "Kissenbruck",
    "Kissing",
    "Kisslegg",
    "Kist",
    "Kitzingen",
    "Kitzscher",
    "Kladen",
    "Klais",
    "Klanxbull",
    "Klaus",
    "Klausdorf",
    "Klausen",
    "Klausheide",
    "Klein Ammensleben",
    "Klein Berssen",
    "Klein Kedingshagen",
    "Klein Lichtenhagen",
    "Klein Meckelsen",
    "Klein Nordende",
    "Klein Offenseth-Sparrieshoop",
    "Klein Rogahn",
    "Klein Ronnau",
    "Klein Wesenberg",
    "Klein Wittensee",
    "Klein-Gerau",
    "Klein-Schlamin",
    "Klein-Winternheim",
    "Klein-Zimmern",
    "Kleinbeeren",
    "Kleinblittersdorf",
    "Kleinbundenbach",
    "Kleinfahner",
    "Kleinfurra",
    "Kleinheubach",
    "Kleinkahl",
    "Kleinmachnow",
    "Kleinmaischeid",
    "Kleinostheim",
    "Kleinrinderfeld",
    "Kleinschwabhausen",
    "Kleinsendelbach",
    "Kleinsteinhausen",
    "Kleinwallstadt",
    "Kleinwolmsdorf",
    "Klettwitz",
    "Kleve",
    "Klinge",
    "Klingenberg",
    "Klingenberg am Main",
    "Klingenmunster",
    "Klingenthal",
    "Klink",
    "Klipphausen",
    "Kloster",
    "Klosterhauser",
    "Klosterlechfeld",
    "Klostermansfeld",
    "Klostersee",
    "Klotten",
    "Klotze",
    "Kluis",
    "Klutz",
    "Knesebeck",
    "Knetzgau",
    "Knittelsheim",
    "Knittlingen",
    "Knobelsdorf",
    "Knoringen",
    "Kobern-Gondorf",
    "Koblenz",
    "Kochel",
    "Kodersdorf",
    "Koditz",
    "Kodnitz",
    "Kofering",
    "Kohlberg",
    "Kohra",
    "Kohren-Sahlis",
    "Kolbermoor",
    "Kolbingen",
    "Kolitzheim",
    "Kolkwitz",
    "Kolleda",
    "Kollerbach",
    "Kollmar",
    "Kolln-Reisiek",
    "Kollnburg",
    "Kolpien",
    "Komp",
    "Kongen",
    "Konigheim",
    "Konigs Wusterhausen",
    "Konigsbach-Stein",
    "Konigsberg in Bayern",
    "Konigsbronn",
    "Konigsbruck",
    "Konigsbrunn",
    "Konigsbrunn",
    "Konigsdorf",
    "Konigsdorf",
    "Konigsee",
    "Konigseggwald",
    "Konigsfeld",
    "Konigsfeld im Schwarzwald",
    "Konigsheim",
    "Konigslutter am Elm",
    "Konigstein",
    "Konigstein",
    "Konigstein im Taunus",
    "Konigswartha",
    "Konigswinter",
    "Konken",
    "Konnern",
    "Konradsreuth",
    "Konstanz",
    "Konz",
    "Konzell",
    "Korb",
    "Korbach",
    "Korchow",
    "Kordel",
    "Korle",
    "Korntal",
    "Kornwestheim",
    "Korperich",
    "Korschenbroich",
    "Kosching",
    "Kossa",
    "Kothel",
    "Kottenheim",
    "Kotterichen",
    "Kottgeisering",
    "Kottweiler-Schwanden",
    "Kotzting",
    "Koxhausen",
    "Kraft",
    "Kraftisried",
    "Kraiburg am Inn",
    "Krailling",
    "Krakow am See",
    "Kramerhof",
    "Kranenburg",
    "Kranichfeld",
    "Kranzberg",
    "Krauchenwies",
    "Kraupa",
    "Krauschwitz",
    "Krauschwitz",
    "Krautheim",
    "Krautheim",
    "Krefeld",
    "Kreiensen",
    "Kreischa",
    "Kremmen",
    "Krempe",
    "Kremperheide",
    "Kressbronn am Bodensee",
    "Krettnach",
    "Kretzschau",
    "Kreuz",
    "Kreuzau",
    "Kreuztal",
    "Kreuzwertheim",
    "Krickenbach",
    "Kriebitzsch",
    "Kriebstein",
    "Kriftel",
    "Kritzmow",
    "Kritzow",
    "Krolpa",
    "Krombach",
    "Kronach",
    "Kronau",
    "Kronberg",
    "Kronshagen",
    "Kropelin",
    "Kropp",
    "Kroppelshagen-Fahrendorf",
    "Kroppenstedt",
    "Kroslin",
    "Krostitz",
    "Krov",
    "Kruden",
    "Kruft",
    "Krugsdorf",
    "Krugzell",
    "Krumbach",
    "Krumhermersdorf",
    "Krummensee",
    "Krummesse",
    "Krumpa",
    "Krumstedt",
    "Krun",
    "Kubschutz",
    "Kuchen",
    "Kuddeworde",
    "Kuenzelsau",
    "Kueps Oberfranken",
    "Kuhardt",
    "Kuhbach",
    "Kuhn",
    "Kuhndorf",
    "Kuhstedt",
    "Kukels",
    "Kulmbach",
    "Kulsheim",
    "Kulz",
    "Kumhausen",
    "Kummerfeld",
    "Kummersbruck",
    "Kunreuth",
    "Kunzell",
    "Kunzing",
    "Kupferberg",
    "Kupferzell",
    "Kuppenheim",
    "Kurnach",
    "Kurnbach",
    "Kurort Hartha",
    "Kurort Steinbach-Hallenberg",
    "Kurten",
    "Kurtscheid",
    "Kusel",
    "Kusterdingen",
    "Kutenholz",
    "Kutzenhausen",
    "Kyritz",
    "Laaber",
    "Laage",
    "Laasdorf",
    "Laatzen",
    "Labejum",
    "Labenz",
    "Laboe",
    "Lachen",
    "Lachendorf",
    "Ladbergen",
    "Ladenburg",
    "Laer",
    "Lage",
    "Lage",
    "Lagerdorf",
    "Lagesbuttel",
    "Lahden",
    "Lahm",
    "Lahnstein",
    "Lahr",
    "Lahr",
    "Lahrbach",
    "Laichingen",
    "Lain",
    "Lalendorf",
    "Lam",
    "Lambertsberg",
    "Lambrecht",
    "Lambsheim",
    "Lamerdingen",
    "Lampertheim",
    "Lampertswalde",
    "Lamspringe",
    "Lamstedt",
    "Landau",
    "Landau an der Isar",
    "Landensberg",
    "Landesbergen",
    "Landrecht",
    "Landsberg",
    "Landsberg am Lech",
    "Landscheid",
    "Landscheide",
    "Landshut",
    "Landstuhl",
    "Langballig",
    "Langebruck",
    "Langeln",
    "Langelsheim",
    "Langen",
    "Langen",
    "Langenargen",
    "Langenau",
    "Langenbach",
    "Langenbach",
    "Langenbach",
    "Langenberg",
    "Langenbernsdorf",
    "Langendorf",
    "Langendorf",
    "Langenenslingen",
    "Langenfeld",
    "Langenfeld",
    "Langenhagen",
    "Langenhorn",
    "Langenlonsheim",
    "Langenmosen",
    "Langenneufnach",
    "Langenpreising",
    "Langenselbold",
    "Langensendelbach",
    "Langenweddingen",
    "Langenwetzendorf",
    "Langenwolmsdorf",
    "Langenzenn",
    "Langeoog",
    "Langerringen",
    "Langerwehe",
    "Langewiesen",
    "Langfurth",
    "Langgons",
    "Langlingen",
    "Langquaid",
    "Langstedt",
    "Langsur",
    "Langwasser",
    "Langwedel",
    "Langweid",
    "Langweiler",
    "Langwieden",
    "Langwitz",
    "Lappersdorf",
    "Lasbek",
    "Lassan",
    "Lassentin",
    "Lastrup",
    "Lathen",
    "Laubach",
    "Lauben",
    "Laubenheim",
    "Laubusch",
    "Laucha",
    "Lauchhammer",
    "Lauchheim",
    "Lauchringen",
    "Lauda-Konigshofen",
    "Laudenbach",
    "Laudert",
    "Lauenau",
    "Lauenbruck",
    "Lauenburg",
    "Lauenhagen",
    "Lauf",
    "Lauf an der Pegnitz",
    "Laufach",
    "Laufen",
    "Laufenburg",
    "Lauffen am Neckar",
    "Laugna",
    "Lauingen",
    "Laumersheim",
    "Laupheim",
    "Laurenburg",
    "Lauscha",
    "Lauschied",
    "Lausitz",
    "Laussnitz",
    "Lauta",
    "Lauta",
    "Lautenbach",
    "Lautenthal",
    "Lauter",
    "Lauterach",
    "Lauterbach",
    "Lauterbach/Schwarzwald",
    "Lauterburg",
    "Lauterecken",
    "Lauterhofen",
    "Lautern",
    "Lautersheim",
    "Lautertal",
    "Lautertal",
    "Lautrach",
    "Lautzenhausen",
    "Lawalde",
    "Lebach",
    "Lebrade",
    "Lebus",
    "Lebusa",
    "Lechbruck",
    "Leck",
    "Lederhose",
    "Leegebruch",
    "Leeheim",
    "Leer",
    "Leer",
    "Leezdorf",
    "Leezen",
    "Leezen",
    "Legau",
    "Legden",
    "Lehen",
    "Lehesten",
    "Lehmen",
    "Lehmke",
    "Lehndorf",
    "Lehrberg",
    "Lehre",
    "Lehrensteinsfeld",
    "Lehrte",
    "Leibelbach",
    "Leibertingen",
    "Leiblfing",
    "Leichlingen",
    "Leidersbach",
    "Leiferde",
    "Leimbach",
    "Leimbach",
    "Leimen",
    "Leimen",
    "Leimersheim",
    "Leinach",
    "Leinburg",
    "Leinefelde",
    "Leinfelden-Echterdingen",
    "Leingarten",
    "Leinsweiler",
    "Leipheim",
    "Leipzig",
    "Leisnig",
    "Leitershofen",
    "Leitzkau",
    "Leiwen",
    "Leizen",
    "Lemberg",
    "Lembruch",
    "Lemforde",
    "Lemgo",
    "Lemwerder",
    "Lengdorf",
    "Lengede",
    "Lengenfeld",
    "Lengenfeld",
    "Lengerich",
    "Lengerich",
    "Lenggries",
    "Lengthal",
    "Lennestadt",
    "Lensahn",
    "Lentfohrden",
    "Lenting",
    "Lenzkirch",
    "Leonberg",
    "Leonberg",
    "Leopoldshagen",
    "Leopoldshohe",
    "Lerbach",
    "Lessien",
    "Lettenreuth",
    "Leubsdorf",
    "Leubsdorf",
    "Leukersdorf",
    "Leun",
    "Leuna",
    "Leutenbach",
    "Leutenbach",
    "Leutenberg",
    "Leutershausen",
    "Leuthen",
    "Leutkirch im Allgau",
    "Leverkusen",
    "Lich",
    "Lichte",
    "Lichtenau",
    "Lichtenau",
    "Lichtenau",
    "Lichtenau",
    "Lichtenau",
    "Lichtenberg",
    "Lichtenberg",
    "Lichtenbroich",
    "Lichtenfels",
    "Lichtenstein",
    "Lichtenstein",
    "Lichtentanne",
    "Lichtenwald",
    "Liebenau",
    "Liebenau",
    "Liebenburg",
    "Liebenscheid",
    "Liebenstein",
    "Liebenwalde",
    "Lieberose",
    "Liederbach",
    "Lienen",
    "Liepgarten",
    "Lieser",
    "Lieskau",
    "Lietzen",
    "Lilienthal",
    "Limbach",
    "Limbach",
    "Limbach-Oberfrohna",
    "Limburg an der Lahn",
    "Limburgerhof",
    "Lindau",
    "Linde",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Lindenau",
    "Lindenberg im Allgau",
    "Lindenfels",
    "Lindenthal",
    "Lindern",
    "Lindewitt",
    "Lindhorst",
    "Lindhorst",
    "Lindigshof",
    "Lindlar",
    "Lindwedel",
    "Lingen",
    "Lingenfeld",
    "Lingerhahn",
    "Linkenbach",
    "Linkenheim-Hochstetten",
    "Linnich",
    "Linow",
    "Linsburg",
    "Lintig",
    "Linz am Rhein",
    "Lippe",
    "Lipporn",
    "Lippstadt",
    "Lissendorf",
    "Listerfehrda",
    "Litzendorf",
    "Lobau",
    "Loch",
    "Lochau",
    "Lochgau",
    "Lochhausen",
    "Lochum",
    "Loddin",
    "Loderburg",
    "Loffenau",
    "Loffingen",
    "Lohberg",
    "Lohe-Rickelshof",
    "Loheide",
    "Lohfelden",
    "Lohmar",
    "Lohnberg",
    "Lohne",
    "Lohne",
    "Lohnsfeld",
    "Lohr a. Main",
    "Lohra",
    "Lohsa",
    "Loiching",
    "Loitz",
    "Lollar",
    "Lollbach",
    "Lommatzsch",
    "Longkamp",
    "Longuich",
    "Loningen",
    "Lonnerstadt",
    "Lonnig",
    "Lonsee",
    "Lonsingen",
    "Loose",
    "Lorch",
    "Lorsch",
    "Lorscheid",
    "Lorup",
    "Losheim",
    "Losheim",
    "Lossburg",
    "Lossnitz",
    "Lostau",
    "Lotte",
    "Lottstetten",
    "Lotzbeuren",
    "Lowenstein",
    "Loxstedt",
    "Lubbecke",
    "Lubbenau",
    "Lubesse",
    "Lubmin",
    "Lubstorf",
    "Lubtheen",
    "Lubz",
    "Luchem",
    "Lucka",
    "Luckau",
    "Luckenbach",
    "Luckenwalde",
    "Ludersdorf",
    "Ludersfeld",
    "Ludinghausen",
    "Ludwigsburg",
    "Ludwigsfelde",
    "Ludwigshafen",
    "Ludwigshafen am Rhein",
    "Ludwigshohe",
    "Ludwigslust",
    "Ludwigsstadt",
    "Luebben",
    "Luechow",
    "Luerdissen",
    "Luft",
    "Luftkurort Arendsee",
    "Lugau",
    "Lugde",
    "Luhden",
    "Luhe-Wildenau",
    "Lunne",
    "Lunzenau",
    "Lupburg",
    "Lurschau",
    "Lutau",
    "Lutjenburg",
    "Lutjensee",
    "Lutter am Barenberge",
    "Luttgenrode",
    "Luttum",
    "Lutz",
    "Lutzelbach",
    "Lutzen",
    "Lutzerath",
    "Lutzingen",
    "Lychen",
    "Lynow",
    "Maasholm",
    "Machern",
    "Machtolsheim",
    "Macken",
    "Mackenbach",
    "Magdala",
    "Magdeburg",
    "Magstadt",
    "Mahlberg",
    "Mahlow",
    "Mahndorf",
    "Maierhofen",
    "Maikammer",
    "Mailing",
    "Mainaschaff",
    "Mainbernheim",
    "Mainburg",
    "Mainhardt",
    "Mainleus",
    "Mainstockheim",
    "Maintal",
    "Mainz",
    "Maisach",
    "Maitenbeth",
    "Malberg",
    "Malborn",
    "Malchin",
    "Malchow",
    "Malente",
    "Malgersdorf",
    "Mallersdorf-Pfaffenberg",
    "Mallin",
    "Malsburg",
    "Malsch",
    "Malsch",
    "Malschwitz",
    "Malsfeld",
    "Malterdingen",
    "Mammendorf",
    "Mamming",
    "Manching",
    "Mandel",
    "Mandern",
    "Mannebach",
    "Mannheim",
    "Mannichswalde",
    "Mansfeld",
    "Mantel",
    "Marbach am Neckar",
    "Marburg",
    "March",
    "Margarethen",
    "Margertshausen",
    "Margetshochheim",
    "Mariaposching",
    "Marienberg",
    "Marienburg",
    "Marienfeld",
    "Marienhafe",
    "Marienhagen",
    "Marienhausen",
    "Marienheide",
    "Marienrachdorf",
    "Marienstein",
    "Mariental",
    "Maring-Noviand",
    "Mark",
    "Markdorf",
    "Markersbach",
    "Markgroningen",
    "Markkleeberg",
    "Marklkofen",
    "Marklohe",
    "Markneukirchen",
    "Markranstadt",
    "Marksuhl",
    "Markt",
    "Markt",
    "Markt Einersheim",
    "Markt Erlbach",
    "Markt Indersdorf",
    "Markt Rettenbach",
    "Markt Schwaben",
    "Markt Wald",
    "Marktbreit",
    "Marktgraitz",
    "Marktheidenfeld",
    "Marktl",
    "Marktleugast",
    "Marktleuthen",
    "Marktoberdorf",
    "Marktredwitz",
    "Marktrodach",
    "Marktschellenberg",
    "Marktschorgast",
    "Marl",
    "Marlow",
    "Marne",
    "Marnheim",
    "Marpingen",
    "Marquartstein",
    "Marsberg",
    "Marschacht",
    "Marschall",
    "Marsdorf",
    "Martensrade",
    "Martfeld",
    "Martinsried",
    "Marwitz",
    "Marxen",
    "Marxgrun",
    "Marxheim",
    "Marzling",
    "Maschen",
    "Maselheim",
    "Massbach",
    "Massenbachhausen",
    "Massenhausen",
    "Massenheim",
    "Massing",
    "Mastershausen",
    "Mastholte",
    "Mattstedt",
    "Mauer",
    "Mauer",
    "Mauerstetten",
    "Maulbronn",
    "Maulburg",
    "Mauth",
    "Maxdorf",
    "Maxhutte-Haidhof",
    "Maximilian",
    "Mayen",
    "Mechelgrun",
    "Mechelroda",
    "Mechenried",
    "Mechernich",
    "Meckenbach",
    "Meckenbeuren",
    "Meckenheim",
    "Meckenheim",
    "Meckesheim",
    "Mecklenburg",
    "Medard",
    "Medebach",
    "Medelby",
    "Medow",
    "Meeder",
    "Meerane",
    "Meerbeck",
    "Meerbusch",
    "Meersburg",
    "Meesiger",
    "Megesheim",
    "Mehlbach",
    "Mehlingen",
    "Mehlmeisel",
    "Mehmels",
    "Mehr",
    "Mehring",
    "Mehring",
    "Meine",
    "Meinersen",
    "Meinerzhagen",
    "Meiningen",
    "Meinsdorf",
    "Meisenheim",
    "Meissen",
    "Meissenheim",
    "Meitingen",
    "Melbeck",
    "Melchow",
    "Meldorf",
    "Melle",
    "Mellenbach-Glasbach",
    "Mellingen",
    "Mellrichstadt",
    "Melsbach",
    "Melsdorf",
    "Melsungen",
    "Memmelsdorf",
    "Memmingen",
    "Memmingerberg",
    "Menden",
    "Mendig",
    "Mengen",
    "Mengerschied",
    "Mengersgereuth-Hammern",
    "Mengerskirchen",
    "Mengkofen",
    "Menslage",
    "Meppen",
    "Merching",
    "Merchweiler",
    "Merdingen",
    "Merenberg",
    "Mering",
    "Merkelbach",
    "Merkendorf",
    "Merkers",
    "Merklingen",
    "Merschwitz",
    "Merseburg",
    "Mersheim",
    "Mertendorf",
    "Mertingen",
    "Mertloch",
    "Merxheim",
    "Merzalben",
    "Merzen",
    "Merzenich",
    "Merzhausen",
    "Merzig",
    "Meschede",
    "Messel",
    "Messkirch",
    "Messstetten",
    "Metelen",
    "Metjendorf",
    "Metten",
    "Mettenheim",
    "Metterich",
    "Mettingen",
    "Mettlach",
    "Mettmann",
    "Metzingen",
    "Metzlos-Gehag",
    "Meudt",
    "Meuro",
    "Meuselwitz",
    "Meyn",
    "Michelau",
    "Michelbach an der Bilz",
    "Michelfeld",
    "Michelstadt",
    "Michendorf",
    "Mickhausen",
    "Midlum",
    "Miehlen",
    "Mielenhausen",
    "Mielkendorf",
    "Miellen",
    "Miesbach",
    "Mieste",
    "Mietingen",
    "Mihla",
    "Milbertshofen",
    "Mildenau",
    "Mildstedt",
    "Milow",
    "Miltenberg",
    "Milzau",
    "Mindelheim",
    "Mindelstetten",
    "Minden",
    "Minfeld",
    "Minsen",
    "Mintraching",
    "Mirow",
    "Missen-Wilhams",
    "Mistelbach",
    "Mistelgau",
    "Mittbach",
    "Mittegrossefehn",
    "Mittelbach",
    "Mittelberg",
    "Mittelbiberach",
    "Mitteleschenbach",
    "Mittelherwigsdorf",
    "Mittelneufnach",
    "Mittelnkirchen",
    "Mittelrot",
    "Mittelsaida",
    "Mittelstenweiler",
    "Mittelstetten",
    "Mittelstreu",
    "Mittenaar",
    "Mittenwald",
    "Mittenwalde",
    "Mitterfelden",
    "Mitterfels",
    "Mitterstetten",
    "Mitterteich",
    "Mittweida",
    "Mitwitz",
    "Mixdorf",
    "Mochau",
    "Mochenwangen",
    "Mockern",
    "Mockmuhl",
    "Mockrehna",
    "Moers",
    "Mogendorf",
    "Mogglingen",
    "Moglingen",
    "Mohlsdorf",
    "Mohnesee",
    "Mohren",
    "Mohrendorf",
    "Mohrkirch",
    "Moisburg",
    "Molbergen",
    "Molfsee",
    "Mollenhagen",
    "Molln",
    "Molschow",
    "Molsheim",
    "Mombris",
    "Momlingen",
    "Mommenheim",
    "Monchberg",
    "Monchsdeggingen",
    "Monchsroth",
    "Monchweiler",
    "Monheim",
    "Monheim am Rhein",
    "Monkeberg",
    "Monreal",
    "Monschau",
    "Monsheim",
    "Monsheim",
    "Montabaur",
    "Monzelfeld",
    "Monzingen",
    "Moor",
    "Moorenweis",
    "Moos",
    "Moos",
    "Moosach",
    "Moosburg",
    "Moosinning",
    "Moosthenning",
    "Morbach",
    "Morenhoven",
    "Morfelden-Walldorf",
    "Moringen",
    "Moritz",
    "Moritzburg",
    "Morlenbach",
    "Morsbach",
    "Morscheid",
    "Morschheim",
    "Morschied",
    "Morsfeld",
    "Morshausen",
    "Morsleben",
    "Morslingen",
    "Mosbach",
    "Moschenfeld",
    "Moser",
    "Mossingen",
    "Mottgers",
    "Mottingen",
    "Motzingen",
    "Moyland",
    "Much",
    "Mucheln",
    "Mudau",
    "Muden",
    "Mudersbach",
    "Mudershausen",
    "Muehldorf",
    "Muehlheim am Main",
    "Mugeln",
    "Muggendorf",
    "Muggensturm",
    "Muhl Rosin",
    "Muhlacker",
    "Muhlau",
    "Muhle",
    "Muhlen",
    "Muhlhausen",
    "Muhlhausen",
    "Muhlhausen",
    "Muhlhausen-Ehingen",
    "Muhlheim an der Donau",
    "Muhltal",
    "Muhltroff",
    "Muhr am See",
    "Mulda",
    "Muldenhammer",
    "Muldenstein",
    "Mulfingen",
    "Mulheim-Karlich",
    "Mullrose",
    "Mulsen",
    "Mulsum",
    "Munchaurach",
    "Munchberg",
    "Muncheberg",
    "Munchenbernsdorf",
    "Munchhausen",
    "Munchhausen",
    "Munchsmunster",
    "Munchsteinach",
    "Munchweiler am Klingbach",
    "Munchweiler an der Alsenz",
    "Munderkingen",
    "Mundersbach",
    "Munich",
    "Munich",
    "Munnerstadt",
    "Munsing",
    "Munsingen",
    "Munster",
    "Munster",
    "Munster",
    "Munster-Sarmsheim",
    "Munsterdorf",
    "Munstermaifeld",
    "Munzenberg",
    "Munzingen",
    "Murchin",
    "Murg",
    "Murlenbach",
    "Murnau am Staffelsee",
    "Murr",
    "Murrhardt",
    "Muschenbach",
    "Mutlangen",
    "Mutterschied",
    "Mutterstadt",
    "Mutzenich",
    "Mutzschen",
    "Mylau",
    "Nabburg",
    "Nachrodt-Wiblingwerde",
    "Nachterstedt",
    "Nachtsheim",
    "Nackenheim",
    "Nagel",
    "Nagold",
    "Nahe",
    "Nahrendorf",
    "Naila",
    "Nakensdorf",
    "Nalbach",
    "Namborn",
    "Nandlstadt",
    "Nanzweiler",
    "Nassau",
    "Nassenfels",
    "Nastatten",
    "Nattenheim",
    "Nattheim",
    "Nauen",
    "Nauendorf",
    "Nauheim",
    "Naumburg",
    "Naumburg",
    "Naunheim",
    "Naunhof",
    "Nauort",
    "Naurath",
    "Nebra",
    "Nebringen",
    "Neckarbischofsheim",
    "Neckargemund",
    "Neckargerach",
    "Neckarrems",
    "Neckarsteinach",
    "Neckarsulm",
    "Neckartailfingen",
    "Neckartenzlingen",
    "Neckarweihingen",
    "Neckarwestheim",
    "Neckarzimmern",
    "Neehausen",
    "Neenstetten",
    "Neermoor",
    "Neetze",
    "Negast",
    "Nehren",
    "Neidenfels",
    "Neidenstein",
    "Neidlingen",
    "Neitersen",
    "Nellingen",
    "Nennhausen",
    "Nennig",
    "Nennslingen",
    "Nentershausen",
    "Nerchau",
    "Neresheim",
    "Neroth",
    "Nersingen",
    "Nesselwang",
    "Netphen",
    "Nettersheim",
    "Nettetal",
    "Netze",
    "Netzschkau",
    "Neu Darchau",
    "Neu Duvenstedt",
    "Neu Isenburg",
    "Neu Kaliss",
    "Neu Sanitz",
    "Neu Vitense",
    "Neu Wulmstorf",
    "Neu Zauche",
    "Neu-Anspach",
    "Neu-Ulm",
    "Neubeckum",
    "Neuberg",
    "Neuberg",
    "Neubeuern",
    "Neubiberg",
    "Neuborger",
    "Neubrandenburg",
    "Neubrunn",
    "Neubukow",
    "Neubulach",
    "Neuburg",
    "Neuburg",
    "Neuburg an der Donau",
    "Neudietendorf",
    "Neudorf",
    "Neudrossenfeld",
    "Neuenbrook",
    "Neuenburg am Rhein",
    "Neuendettelsau",
    "Neuendorf",
    "Neuendorf",
    "Neuendorf",
    "Neuengors",
    "Neuenhagen",
    "Neuenhaus",
    "Neuenkirchen",
    "Neuenkirchen",
    "Neuenkirchen",
    "Neuenkirchen",
    "Neuenkirchen",
    "Neuenkirchen",
    "Neuenmarkt",
    "Neuenrade",
    "Neuenstadt am Kocher",
    "Neuenstein",
    "Neuental",
    "Neuerburg",
    "Neufahrn bei Freising",
    "Neufarn",
    "Neuffen",
    "Neufra",
    "Neufrach",
    "Neufraunhofen",
    "Neugersdorf",
    "Neuhardenberg",
    "Neuhaus",
    "Neuhaus",
    "Neuhaus am Inn",
    "Neuhaus am Rennweg",
    "Neuhaus-Schierschnitz",
    "Neuhausel",
    "Neuhausen",
    "Neuhausen",
    "Neuhausen",
    "Neuhausen auf den Fildern",
    "Neuhausen ob Eck",
    "Neuhemsbach",
    "Neuhof",
    "Neuhof",
    "Neuhof",
    "Neuhofen",
    "Neuhutten",
    "Neuhutten",
    "Neukamperfehn",
    "Neukeferloh",
    "Neukieritzsch",
    "Neukirch",
    "Neukirch/Lausitz",
    "Neukirchen",
    "Neukirchen",
    "Neukirchen",
    "Neukirchen am Teisenberg",
    "Neukirchen-Vluyn",
    "Neukloster",
    "Neuleiningen",
    "Neulussheim",
    "Neumagen-Dhron",
    "Neumark",
    "Neumarkt in der Oberpfalz",
    "Neumarkt-Sankt Veit",
    "Neunburg vorm Wald",
    "Neundorf",
    "Neundorf",
    "Neunheilingen",
    "Neunkirchen",
    "Neunkirchen",
    "Neunkirchen",
    "Neunkirchen",
    "Neunkirchen",
    "Neunkirchen am Sand",
    "Neuotting",
    "Neupetershain",
    "Neupotz",
    "Neuravensburg",
    "Neureichenau",
    "Neuried",
    "Neuried",
    "Neuruppin",
    "Neusalza-Spremberg",
    "Neusass",
    "Neuschonau",
    "Neusitz",
    "Neusorg",
    "Neuss",
    "Neustadt",
    "Neustadt",
    "Neustadt",
    "Neustadt (Orla)",
    "Neustadt am Rubenberge",
    "Neustadt an der Aisch",
    "Neustadt an der Orla",
    "Neustadt an der Waldnaab",
    "Neustadt bei Coburg",
    "Neustadt in Holstein",
    "Neustadt in Sachsen",
    "Neustadt-Glewe",
    "Neustadt/Westerwald",
    "Neustrelitz",
    "Neutraubling",
    "Neutrebbin",
    "Neutz-Lettewitz",
    "Neuweiler",
    "Neuwerk",
    "Neuwied",
    "Neuwittenbek",
    "Neuzelle",
    "Neverin",
    "Neversdorf",
    "Newel",
    "Nickenich",
    "Nidda",
    "Nidderau",
    "Nideggen",
    "Niebull",
    "Niedenstein",
    "Nieder Ohmen",
    "Nieder-Beerbach",
    "Nieder-Olm",
    "Niederaichbach",
    "Niederalben",
    "Niederalsen",
    "Niederalteich",
    "Niederau",
    "Niederau",
    "Niederaula",
    "Niederbrechen",
    "Niederbreitbach",
    "Niederburg",
    "Niederding",
    "Niederdorf",
    "Niederdorf",
    "Niederdorfelden",
    "Niederdorla",
    "Niedereisenhausen",
    "Niedereschach",
    "Niederfell",
    "Niederfischbach",
    "Niederfrohna",
    "Niederheimbach",
    "Niederkassel",
    "Niederkirchen",
    "Niederkirchen bei Deidesheim",
    "Niederkruchten",
    "Niederlangen",
    "Niederlindhart",
    "Niedermurach",
    "Niedernberg",
    "Niederndodeleben",
    "Niederneisen",
    "Niederneuching",
    "Niedernhall",
    "Niedernhausen",
    "Niedernwohren",
    "Niederorschel",
    "Niederpollnitz",
    "Niederquembach",
    "Niederrieden",
    "Niedersachswerfen",
    "Niederschonenfeld",
    "Niederselters",
    "Niederspier",
    "Niederstadtfeld",
    "Niederstaufenbach",
    "Niederstetten",
    "Niederstotzingen",
    "Niederviehbach",
    "Niederwambach",
    "Niederweidbach",
    "Niederwerrn",
    "Niederwiesa",
    "Niederwinkling",
    "Niederwuerschnitz",
    "Niederzeuzheim",
    "Niederzier",
    "Niederzissen",
    "Niefern-Oschelbronn",
    "Nieheim",
    "Niemberg",
    "Niemegk",
    "Nienborstel",
    "Nienburg",
    "Nienburg/Saale",
    "Niendorf",
    "Niendorf",
    "Nienhagen",
    "Nienhagen",
    "Nienstadt",
    "Niepars",
    "Nierstein",
    "Niesgrau",
    "Niesky",
    "Nieste",
    "Nindorf",
    "Nittel",
    "Nittenau",
    "Nittendorf",
    "Nitz",
    "Nobitz",
    "Noda",
    "Nohfelden",
    "Nohra",
    "Nonnenhorn",
    "Nonnweiler",
    "Norddeich",
    "Norden",
    "Nordendorf",
    "Nordenham",
    "Norderney",
    "Norderstapel",
    "Norderstedt",
    "Nordhalben",
    "Nordhastedt",
    "Nordhausen",
    "Nordheim",
    "Nordhofen",
    "Nordholz",
    "Nordholz",
    "Nordhorn",
    "Nordkirchen",
    "Nordrach",
    "Nordstemmen",
    "Nordwalde",
    "Norheim",
    "Norken",
    "Norsingen",
    "Norten-Hardenberg",
    "Northeim",
    "Nortmoor",
    "Nortorf",
    "Nortrup",
    "Norvenich",
    "Nossen",
    "Nottuln",
    "Notzingen",
    "Nubbel",
    "Nudlingen",
    "Nufringen",
    "Numbrecht",
    "Nunchritz",
    "Nuremberg",
    "Nurtingen",
    "Nusplingen",
    "Nussdorf am Inn",
    "Nusse",
    "Nussloch",
    "Ober-Erlenbach",
    "Ober-Hilbersheim",
    "Ober-Morlen",
    "Ober-Olm",
    "Ober-Ramstadt",
    "Ober-Roden",
    "Oberammergau",
    "Oberasbach",
    "Oberau",
    "Oberaudorf",
    "Oberaula",
    "Oberberg",
    "Oberbillig",
    "Oberbodnitz",
    "Oberboihingen",
    "Oberderdingen",
    "Oberdiebach",
    "Oberding",
    "Oberdischingen",
    "Oberdolling",
    "Oberdorla",
    "Oberelbert",
    "Oberelsbach",
    "Oberfell",
    "Obergebra",
    "Obergriesbach",
    "Obergunzburg",
    "Obergurig",
    "Oberhaching",
    "Oberhaid",
    "Oberharmersbach",
    "Oberhausen",
    "Oberhausen",
    "Oberhausen",
    "Oberhausen-Rheinhausen",
    "Oberhermsdorf",
    "Oberhof",
    "Oberhof",
    "Oberhonnefeld-Gierend",
    "Oberhundem",
    "Oberickelsheim",
    "Oberirsen",
    "Oberkail",
    "Oberkammlach",
    "Oberkirch",
    "Oberkirchen",
    "Oberkochen",
    "Oberkotzau",
    "Oberlahr",
    "Oberlaindern",
    "Oberleichtersbach",
    "Oberlungwitz",
    "Obermarchtal",
    "Obermeilingen",
    "Obermeitingen",
    "Obermichelbach",
    "Obermohr",
    "Obermoschel",
    "Obernbreit",
    "Obernburg am Main",
    "Oberndorf",
    "Oberndorf",
    "Oberndorf",
    "Oberndorf",
    "Oberndorf",
    "Oberneukirchen",
    "Oberneuses",
    "Obernfeld",
    "Obernheim-Kirchenarnbach",
    "Obernkirchen",
    "Obernzell",
    "Obernzenn",
    "Oberopfingen",
    "Oberornau",
    "Oberotterbach",
    "Oberottmarshausen",
    "Oberpframmern",
    "Oberpierscheid",
    "Oberpleichfeld",
    "Oberpleis",
    "Oberporing",
    "Oberporlitz",
    "Oberraden",
    "Oberreichenbach",
    "Oberreichenbach",
    "Oberreichenbach",
    "Oberried",
    "Oberrieden",
    "Oberriexingen",
    "Oberrod",
    "Oberrot",
    "Oberschefflenz",
    "Oberscheinfeld",
    "Oberschleissheim",
    "Oberschneiding",
    "Oberschona",
    "Oberschweinbach",
    "Oberseifersdorf",
    "Obersontheim",
    "Oberstadion",
    "Oberstadtfeld",
    "Oberstaufen",
    "Oberstdorf",
    "Oberstenfeld",
    "Oberstreu",
    "Obersuhl",
    "Obertaufkirchen",
    "Oberteuringen",
    "Oberthal",
    "Oberthulba",
    "Obertraubling",
    "Obertrubach",
    "Obertshausen",
    "Oberursel",
    "Oberviechtach",
    "Oberweimar",
    "Oberweis",
    "Oberweissbach",
    "Oberwesel",
    "Oberwiesenthal",
    "Oberwinter",
    "Oberwolfach",
    "Oberzissen",
    "Obing",
    "Obrigheim",
    "Obrigheim",
    "Obristfeld",
    "Ochsenfurt",
    "Ochsenhausen",
    "Ochtendung",
    "Ochtmersleben",
    "Ochtrup",
    "Ockenfels",
    "Odelzhausen",
    "Odendorf",
    "Odenheim",
    "Odenthal",
    "Oderberg",
    "Odernheim",
    "Oebisfelde",
    "Oechsen",
    "Oederan",
    "Oedheim",
    "Oelde",
    "Oelerse",
    "Oelixdorf",
    "Oelsberg",
    "Oelsen",
    "Oelsnitz",
    "Oelsnitz",
    "Oelzschau",
    "Oer-Erkenschwick",
    "Oerlenbach",
    "Oerlinghausen",
    "Oersdorf",
    "Oesterdeichstrich",
    "Oestrich-Winkel",
    "Oettersdorf",
    "Oettingen in Bayern",
    "Oeversee",
    "Offenau",
    "Offenbach",
    "Offenbach an der Queich",
    "Offenbach-Hundheim",
    "Offenberg",
    "Offenburg",
    "Offenhausen",
    "Offingen",
    "Offstein",
    "Ofterdingen",
    "Oftersheim",
    "Oggelshausen",
    "Ohlsbach",
    "Ohlsdorf",
    "Ohlstadt",
    "Ohmden",
    "Ohningen",
    "Ohorn",
    "Ohrdruf",
    "Ohren",
    "Ohrensen",
    "Ohringen",
    "Olbernhau",
    "Olbersdorf",
    "Olbersleben",
    "Olbronn-Durrn",
    "Olching",
    "Oldenburg",
    "Oldenburg",
    "Oldenburg in Holstein",
    "Oldendorf",
    "Oldendorf",
    "Oldendorf",
    "Oldenswort",
    "Oldersum",
    "Olderup",
    "Oldisleben",
    "Olfen",
    "Olpe",
    "Olsberg",
    "Olsbrucken",
    "Olzheim",
    "Opfenbach",
    "Opfingen",
    "Oppach",
    "Oppenau",
    "Oppendorf",
    "Oppenheim",
    "Oppenweiler",
    "Oppurg",
    "Oranienbaum",
    "Oranienburg",
    "Orbis",
    "Oring",
    "Ornbau",
    "Orsingen-Nenzingen",
    "Ortenberg",
    "Ortenberg",
    "Ortenburg",
    "Ortrand",
    "Osann-Monzel",
    "Osburg",
    "Osch",
    "Oschatz",
    "Oschersleben",
    "Oschingen",
    "Osdorf",
    "Osede",
    "Osingen",
    "Osloss",
    "Ostbevern",
    "Ostelsheim",
    "Osten",
    "Ostenfeld",
    "Osterberg",
    "Osterbrock",
    "Osterburg",
    "Osterburken",
    "Ostercappeln",
    "Osterfeld",
    "Osterhausen",
    "Osterhofen",
    "Osterholz-Scharmbeck",
    "Osternienburg",
    "Osterode",
    "Osterode am Harz",
    "Osterrade",
    "Osterronfeld",
    "Osterspai",
    "Osterstedt",
    "Osterwald",
    "Osterwieck",
    "Ostfildern",
    "Ostheim",
    "Osthofen",
    "Ostrach",
    "Ostramondra",
    "Ostrhauderfehn",
    "Ostringen",
    "Ostritz",
    "Ostseebad Ahrenshoop",
    "Ostseebad Baabe",
    "Ostseebad Binz",
    "Ostseebad Boltenhagen",
    "Ostseebad Dierhagen",
    "Ostseebad Gohren",
    "Ostseebad Kuhlungsborn",
    "Ostseebad Nienhagen",
    "Ostseebad Prerow",
    "Ostseebad Sellin",
    "Ostseebad Zinnowitz",
    "Oststeinbek",
    "Othenstorf",
    "Otigheim",
    "Otisheim",
    "Ottenbach",
    "Ottendorf-Okrilla",
    "Ottenhofen",
    "Ottenhofen",
    "Ottensoos",
    "Otter",
    "Otterbach",
    "Otterbach",
    "Otterberg",
    "Otterfing",
    "Otterloh",
    "Otterndorf",
    "Ottersberg",
    "Ottersheim",
    "Otterstadt",
    "Ottersweier",
    "Otterwisch",
    "Ottobeuren",
    "Ottobrunn",
    "Ottrau",
    "Ottweiler",
    "Otze",
    "Otzing",
    "Ovelgoenne",
    "Ovelgonne",
    "Overath",
    "Owen",
    "Owingen",
    "Owschlag",
    "Oy-Mittelberg",
    "Oyten",
    "Padelugge",
    "Padenstedt",
    "Paderborn",
    "Pahl",
    "Painten",
    "Palling",
    "Panschwitz-Kuckau",
    "Pansdorf",
    "Pantelitz",
    "Papenburg",
    "Papendorf",
    "Pappenheim",
    "Paradies",
    "Parchim",
    "Parey",
    "Parkstein",
    "Parkstetten",
    "Parnsberg",
    "Parsau",
    "Parsdorf",
    "Partenheim",
    "Partenstein",
    "Parum",
    "Pasewalk",
    "Passau",
    "Passow",
    "Pastetten",
    "Pattensen",
    "Patzig",
    "Paulinenaue",
    "Paunzhausen",
    "Pausa",
    "Pauscha",
    "Pechbrunn",
    "Peckelsheim",
    "Peetsch",
    "Pegau",
    "Pegnitz",
    "Peine",
    "Peissenberg",
    "Peiting",
    "Peitz",
    "Pellingen",
    "Pellworm",
    "Pelm",
    "Pemfling",
    "Penig",
    "Pentenried",
    "Pentling",
    "Penzberg",
    "Penzing",
    "Perach",
    "Perkam",
    "Perl",
    "Perleberg",
    "Perlesreut",
    "Pesch",
    "Pessenburgheim",
    "Pesterwitz",
    "Petersaurach",
    "Petersberg",
    "Petersberg",
    "Petersberg",
    "Petersdorf",
    "Petersdorf",
    "Petershagen",
    "Petershagen",
    "Petershausen",
    "Petriroda",
    "Petting",
    "Pettstadt",
    "Pewsum",
    "Pfaffen-Schwabenheim",
    "Pfaffenhausen",
    "Pfaffenhausen",
    "Pfaffenhofen",
    "Pfaffenhofen an der Ilm",
    "Pfaffenhofen an der Roth",
    "Pfaffenweiler",
    "Pfaffing",
    "Pfaffroda",
    "Pfalzfeld",
    "Pfalzgrafenweiler",
    "Pfarrkirchen",
    "Pfarrweisach",
    "Pfatter",
    "Pfedelbach",
    "Pfeffelbach",
    "Pfeffenhausen",
    "Pflummern",
    "Pforring",
    "Pforzen",
    "Pforzheim",
    "Pfreimd",
    "Pfronten",
    "Pfullendorf",
    "Pfullingen",
    "Pfungstadt",
    "Philippsburg",
    "Philippsthal",
    "Pickliessem",
    "Piding",
    "Piesport",
    "Pilsach",
    "Pilsting",
    "Pinneberg",
    "Pinnow",
    "Pinnow",
    "Pirk",
    "Pirmasens",
    "Pirna",
    "Pittenhart",
    "Pixel",
    "Plaidt",
    "Plan",
    "Planegg",
    "Plankstadt",
    "Plate",
    "Platkow",
    "Platten",
    "Plattenburg",
    "Plattling",
    "Plau am See",
    "Plaue",
    "Plauen",
    "Plech",
    "Pleckhausen",
    "Pleidelsheim",
    "Plein",
    "Pleinfeld",
    "Pleiskirchen",
    "Pleisweiler-Oberhofen",
    "Pleizenhausen",
    "Plessa",
    "Plettenberg",
    "Pleystein",
    "Pliening",
    "Pliezhausen",
    "Plochingen",
    "Plodda",
    "Ploen",
    "Plossberg",
    "Pluderhausen",
    "Pluwig",
    "Pockau",
    "Pocking",
    "Podeldorf",
    "Poggensee",
    "Pohl",
    "Pohl",
    "Pohnstorf",
    "Poing",
    "Point",
    "Polch",
    "Politz",
    "Pollenfeld",
    "Pollhagen",
    "Polling",
    "Pommelsbrunn",
    "Pommerby",
    "Pomster",
    "Poppenhausen",
    "Poppenhausen",
    "Poppenlauer",
    "Poppenricht",
    "Pornbach",
    "Porta Westfalica",
    "Posing",
    "Possendorf",
    "Possendorf",
    "Possenheim",
    "Possneck",
    "Postbauer-Heng",
    "Postmunster",
    "Potsdam",
    "Pottenstein",
    "Pottmes",
    "Pouch",
    "Poxdorf",
    "Pracht",
    "Prackenbach",
    "Prangendorf",
    "Prebberede",
    "Prebitz",
    "Preetz",
    "Preith",
    "Premnitz",
    "Prenzlau",
    "Pressath",
    "Pressen",
    "Pressig",
    "Prestewitz",
    "Pretzfeld",
    "Preussisch Oldendorf",
    "Prichsenstadt",
    "Prien am Chiemsee",
    "Priesendorf",
    "Priestewitz",
    "Prietitz",
    "Prisdorf",
    "Prittriching",
    "Pritzwalk",
    "Probsteierhagen",
    "Prohn",
    "Proseken",
    "Protzen",
    "Pruchten",
    "Prum",
    "Prumzurlay",
    "Prutting",
    "Puchersreuth",
    "Puchheim",
    "Puderbach",
    "Pulheim",
    "Pullach im Isartal",
    "Pullenreuth",
    "Pullhausen",
    "Puls",
    "Pulsnitz",
    "Purgen",
    "Puschendorf",
    "Putbus",
    "Putlitz",
    "Puttlingen",
    "Putzbrunn",
    "Pyrbaum",
    "Quaal",
    "Quakenbruck",
    "Quarnbek",
    "Quarnstedt",
    "Quedlinburg",
    "Queidersbach",
    "Querfurt",
    "Querum",
    "Quickborn",
    "Quierschied",
    "Quitzow",
    "Raben",
    "Rabenau",
    "Rabenholz",
    "Rabenkirchen-Fauluck",
    "Rabke",
    "Rachelsbach",
    "Rackith",
    "Rackwitz",
    "Radbruch",
    "Radeberg",
    "Radebeul",
    "Radeburg",
    "Radefeld",
    "Radegast",
    "Radevormwald",
    "Radibor",
    "Radolfzell",
    "Raesfeld",
    "Raguhn",
    "Rahden",
    "Raibach",
    "Rain",
    "Raisdorf",
    "Raisting",
    "Ralingen",
    "Rambin",
    "Ramerberg",
    "Rammelsbach",
    "Rammenau",
    "Rammingen",
    "Ramsau",
    "Ramsen",
    "Ramsla",
    "Ramsloh",
    "Ramstein-Miesenbach",
    "Ramsthal",
    "Randersacker",
    "Rangendingen",
    "Rangsdorf",
    "Ranis",
    "Ransbach-Baumbach",
    "Ranstadt",
    "Ransweiler",
    "Rantum",
    "Rantzau",
    "Rappenau",
    "Raschau",
    "Rasdorf",
    "Rasselstein",
    "Rastatt",
    "Rastdorf",
    "Rastede",
    "Rastenberg",
    "Rastow",
    "Ratekau",
    "Rath",
    "Rathenow",
    "Ratingen",
    "Rattelsdorf",
    "Rattenberg",
    "Rattenkirchen",
    "Ratzeburg",
    "Raubach",
    "Raubling",
    "Rauda",
    "Rauen",
    "Rauenberg",
    "Rauenstein",
    "Raunheim",
    "Rauschenberg",
    "Rausdorf",
    "Rausdorf",
    "Ravensburg",
    "Ravenstein",
    "Rechberg",
    "Rechberg",
    "Rechberghausen",
    "Rechenberg-Bienenmuhle",
    "Rechtenbach",
    "Rechtenfleth",
    "Rechtenstein",
    "Rechtmehring",
    "Rechtsupweg",
    "Recke",
    "Recklinghausen",
    "Reddelich",
    "Redefin",
    "Redekin",
    "Rednitzhembach",
    "Redwitz an der Rodach",
    "Rees",
    "Regen",
    "Regensburg",
    "Regenstauf",
    "Regis-Breitingen",
    "Regnitzlosau",
    "Rehau",
    "Rehburg-Loccum",
    "Rehden",
    "Reher",
    "Rehfelde",
    "Rehhorst",
    "Rehling",
    "Rehlingen-Siersburg",
    "Rehna",
    "Reich",
    "Reichardtswerben",
    "Reichartshausen",
    "Reichelsheim",
    "Reichelsheim",
    "Reichenbach",
    "Reichenbach",
    "Reichenbach im Tale",
    "Reichenbach-Steegen",
    "Reichenbach/Vogtland",
    "Reichenschwand",
    "Reichersbeuern",
    "Reichertshausen",
    "Reichertshausen",
    "Reichertshofen",
    "Reilingen",
    "Reimlingen",
    "Reimsbach",
    "Reinbek",
    "Reinfeld",
    "Reinhartshausen",
    "Reinheim",
    "Reinsberg",
    "Reinsdorf",
    "Reinstadt",
    "Reipoltskirchen",
    "Reisbach",
    "Reischach",
    "Reiskirchen",
    "Reit im Winkl",
    "Reithofen",
    "Reitmehring",
    "Reitsch",
    "Rellingen",
    "Remagen",
    "Remels",
    "Remlingen",
    "Remmesweiler",
    "Remptendorf",
    "Remscheid",
    "Renchen",
    "Rendsburg",
    "Rengsdorf",
    "Rennerod",
    "Rennersdorf",
    "Rennertshofen",
    "Renningen",
    "Renquishausen",
    "Rentrisch",
    "Rentweinsdorf",
    "Reppenstedt",
    "Retgendorf",
    "Rethwisch",
    "Rettenberg",
    "Rettersen",
    "Rettert",
    "Rettigheim",
    "Rettin",
    "Retzstadt",
    "Reute",
    "Reuth",
    "Reutlingen",
    "Reutlingendorf",
    "Rhade",
    "Rhadereistedt",
    "Rhaunen",
    "Rheda-Wiedenbruck",
    "Rhede",
    "Rhede",
    "Rheden",
    "Rhein",
    "Rhein",
    "Rheinau",
    "Rheinau",
    "Rheinbach",
    "Rheinberg",
    "Rheinbollen",
    "Rheinbreitbach",
    "Rheinbrohl",
    "Rheine",
    "Rheinfelden",
    "Rheinsberg",
    "Rheinzabern",
    "Rheurdt",
    "Rhinow",
    "Rhumspringe",
    "Ribbeck",
    "Ribbesbuttel",
    "Ribnitz",
    "Ribnitz-Damgarten",
    "Richen",
    "Rickenbach",
    "Rickert",
    "Riding",
    "Riedbach",
    "Riedbach",
    "Riede",
    "Rieden",
    "Rieden",
    "Rieden",
    "Rieden",
    "Riedenberg",
    "Riedenburg",
    "Riedenheim",
    "Rieder",
    "Riederich",
    "Riedering",
    "Riedhausen",
    "Riedlingen",
    "Riedmoos",
    "Riegel",
    "Riegelsberg",
    "Riegsee",
    "Rielasingen-Worblingen",
    "Riemerling",
    "Rieneck",
    "Riepsdorf",
    "Riesa",
    "Rieseby",
    "Rieste",
    "Rietberg",
    "Rietheim",
    "Riethnordhausen",
    "Rietschen",
    "Rimbach",
    "Rimbach",
    "Rimhorn",
    "Rimpar",
    "Rimschweiler",
    "Rimsting",
    "Rinchnach",
    "Ringenwalde",
    "Ringsberg",
    "Ringsheim",
    "Rinteln",
    "Rinzenberg",
    "Riol",
    "Ritschenhausen",
    "Ritterhude",
    "Rittersdorf",
    "Robel",
    "Rochlitz",
    "Rockenberg",
    "Rockenhausen",
    "Rodach",
    "Rodalben",
    "Roden",
    "Rodenbach",
    "Rodenbach",
    "Rodenberg",
    "Rodental",
    "Rodersheim-Gronau",
    "Rodewisch",
    "Rodgau",
    "Rodheim",
    "Roding",
    "Rodinghausen",
    "Roehrnbach",
    "Roes",
    "Roetgen",
    "Roetz",
    "Rofingen",
    "Roggenburg",
    "Roggentin",
    "Rogling",
    "Rohr",
    "Rohr",
    "Rohrbach",
    "Rohrbach",
    "Rohrbach",
    "Rohrdorf",
    "Rohrdorf",
    "Rohrenfels",
    "Rohrmoos",
    "Rohrsdorf",
    "Roigheim",
    "Roklum",
    "Rollbach",
    "Rollshausen",
    "Romhild",
    "Rommerskirchen",
    "Romrod",
    "Romsthal",
    "Rondeshagen",
    "Ronneburg",
    "Ronnenberg",
    "Ronsberg",
    "Rorichum",
    "Rosbach vor der Hohe",
    "Roschbach",
    "Rosdorf",
    "Rosdorf",
    "Roseburg",
    "Rosenburg",
    "Rosendahl",
    "Rosendahl",
    "Rosenfeld",
    "Rosengarten",
    "Rosengarten",
    "Rosengarten",
    "Rosenheim",
    "Rosenkopf",
    "Rosenthal",
    "Rositz",
    "Roslau",
    "Rosrath",
    "Rossau",
    "Rossdorf",
    "Rosshaupten",
    "Rosslau",
    "Rosstal",
    "Rosswein",
    "Rostock",
    "Rot am See",
    "Rotenburg",
    "Rotenburg an der Fulda",
    "Rotenhain",
    "Roth",
    "Rotha",
    "Rothenbach",
    "Rothenbach an der Pegnitz",
    "Rothenbuch",
    "Rothenburg",
    "Rothenburg",
    "Rothenfels",
    "Rothenstein",
    "Rothhausen",
    "Rothlein",
    "Rothof",
    "Rott am Inn",
    "Rottach",
    "Rottach-Egern",
    "Rottenacker",
    "Rottenbach",
    "Rottenbuch",
    "Rottenburg",
    "Rottenburg an der Laaber",
    "Rottendorf",
    "Rottenstuben",
    "Rotterode",
    "Rotthalmunster",
    "Rottingen",
    "Rottleberode",
    "Rottweil",
    "Rovershagen",
    "Roxel",
    "Rubke",
    "Ruchheim",
    "Ruchsen",
    "Ruckeroth",
    "Ruckersdorf",
    "Ruckersdorf",
    "Ruckholz",
    "Rudelzhausen",
    "Ruderatshofen",
    "Rudersberg",
    "Rudersdorf",
    "Rudersdorf",
    "Ruderting",
    "Rudesheim",
    "Rudesheim am Rhein",
    "Rudolstadt",
    "Rugendorf",
    "Rugheim",
    "Rugland",
    "Ruhen",
    "Ruhla",
    "Ruhland",
    "Ruhpolding",
    "Ruhstorf",
    "Ruit",
    "Rullstorf",
    "Rulzheim",
    "Rumeltshausen",
    "Rummer",
    "Rummingen",
    "Rumohr",
    "Runding",
    "Runkel",
    "Runthe",
    "Ruppach-Goldhausen",
    "Ruppertsberg",
    "Ruppertshofen",
    "Ruppertsweiler",
    "Ruppichteroth",
    "Ruschberg",
    "Ruscheid",
    "Ruspel",
    "Rust",
    "Rutesheim",
    "Ruthen",
    "Ruthnick",
    "Rutsweiler an der Lauter",
    "Saal",
    "Saal",
    "Saaldorf",
    "Saalfeld",
    "Saalstadt",
    "Saara",
    "Saarburg",
    "Saarlouis",
    "Saarwellingen",
    "Sachsen",
    "Sachsenhagen",
    "Sachsenheim",
    "Sachsenkam",
    "Sack",
    "Saerbeck",
    "Saffig",
    "Sagard",
    "Sailauf",
    "Saint Egidien",
    "Saint Michaelisdonn",
    "Salach",
    "Salching",
    "Saldenburg",
    "Salem",
    "Salgen",
    "Salm",
    "Salmtal",
    "Salz",
    "Salzbergen",
    "Salzburg",
    "Salzgitter",
    "Salzgitter-Bad",
    "Salzhausen",
    "Salzhemmendorf",
    "Salzkotten",
    "Salzstetten",
    "Salzwedel",
    "Salzweg",
    "Samerberg",
    "Samern",
    "Samswegen",
    "Samtens",
    "Sand",
    "Sandberg",
    "Sandbostel",
    "Sande",
    "Sandersdorf",
    "Sandersleben",
    "Sandesneben",
    "Sandhausen",
    "Sandkrug",
    "Sangerhausen",
    "Sankt Andreasberg",
    "Sankt Augustin",
    "Sankt Englmar",
    "Sankt Georgen im Schwarzwald",
    "Sankt Ingbert",
    "Sankt Katharinen",
    "Sankt Leon-Rot",
    "Sankt Peter-Ording",
    "Sankt Wendel",
    "Sankt Wolfgang",
    "Sanssouci",
    "Sargenroth",
    "Sarkwitz",
    "Sarlhusen",
    "Sarstedt",
    "Sasbach",
    "Sasbach",
    "Sasbachwalden",
    "Sassenberg",
    "Sassenburg",
    "Sassnitz",
    "Satjendorf",
    "Satrup",
    "Satteldorf",
    "Sattelstadt",
    "Satzvey",
    "Sauensiek",
    "Sauerlach",
    "Sauldorf",
    "Saulgau",
    "Schaafheim",
    "Schaalby",
    "Schacht-Audorf",
    "Schackendorf",
    "Schacksdorf",
    "Schadeleben",
    "Schafflund",
    "Schafstedt",
    "Schalkau",
    "Schalksmuhle",
    "Schallstadt",
    "Schammelsdorf",
    "Scharbeutz",
    "Scharnebeck",
    "Scharnhorst",
    "Scharrel",
    "Schauenstein",
    "Schauerberg",
    "Schaulings",
    "Schaumburg",
    "Schechen",
    "Schechingen",
    "Scheden",
    "Scheer",
    "Scheessel",
    "Scheidegg",
    "Scheinfeld",
    "Schelklingen",
    "Schellerten",
    "Schellhorn",
    "Schellweiler",
    "Schenefeld",
    "Schenefeld",
    "Schenefeld",
    "Schenkenhorst",
    "Schenkenzell",
    "Schenklengsfeld",
    "Scherenbostel",
    "Schermbeck",
    "Schermen",
    "Scherstetten",
    "Schesslitz",
    "Scheuerfeld",
    "Scheuerhalden",
    "Scheuring",
    "Scheyern",
    "Schieder-Schwalenberg",
    "Schierling",
    "Schiffdorf",
    "Schifferstadt",
    "Schiffweiler",
    "Schildau",
    "Schildow",
    "Schillingsfurst",
    "Schillingshof",
    "Schiltach",
    "Schiltberg",
    "Schindhard",
    "Schiphorst",
    "Schirgiswalde",
    "Schirmitz",
    "Schirnding",
    "Schkeuditz",
    "Schkolen",
    "Schkopau",
    "Schladen",
    "Schlaitdorf",
    "Schlammersdorf",
    "Schlangen",
    "Schlangenbad",
    "Schlanstedt",
    "Schlat",
    "Schleching",
    "Schlegel",
    "Schlehdorf",
    "Schleid",
    "Schleid",
    "Schleiden",
    "Schleife",
    "Schleiz",
    "Schlema",
    "Schlepzig",
    "Schleswig",
    "Schlettau",
    "Schleusingen",
    "Schlieben",
    "Schliengen",
    "Schlier",
    "Schlierbach",
    "Schliersee",
    "Schlitz",
    "Schloss",
    "Schloss Holte-Stukenbrock",
    "Schlossberg",
    "Schlotfeld",
    "Schlotheim",
    "Schluchsee",
    "Schluchtern",
    "Schlusselfeld",
    "Schmalenberg",
    "Schmalensee",
    "Schmalkalden",
    "Schmallenberg",
    "Schmelz",
    "Schmerz",
    "Schmidgaden",
    "Schmidmuhlen",
    "Schmidt",
    "Schmidthachenbach",
    "Schmiechen",
    "Schmiechen",
    "Schmiedefeld am Rennsteig",
    "Schmilau",
    "Schmitten",
    "Schmittweiler",
    "Schmolln",
    "Schnabelwaid",
    "Schnackenburg",
    "Schnaitsee",
    "Schnaittach",
    "Schnaittenbach",
    "Schneckenhausen",
    "Schnee",
    "Schneeberg",
    "Schneeberg",
    "Schnega",
    "Schneizlreuth",
    "Schnellbach",
    "Schnelldorf",
    "Schnellmannshausen",
    "Schneppenbach",
    "Schneverdingen",
    "Schney",
    "Schnurpflingen",
    "Schoeffengrund",
    "Schoenberg",
    "Schoenheide",
    "Schollbrunn",
    "Schollene",
    "Schollkrippen",
    "Schollnach",
    "Schomberg",
    "Schomberg",
    "Schonach im Schwarzwald",
    "Schonaich",
    "Schonau",
    "Schonau",
    "Schonau im Schwarzwald",
    "Schonberg",
    "Schonberg",
    "Schonberg",
    "Schonberg",
    "Schonborn",
    "Schonbrunn",
    "Schondorf am Ammersee",
    "Schondra",
    "Schonebeck",
    "Schoneberg",
    "Schoneck",
    "Schonecken",
    "Schonefeld",
    "Schoneiche",
    "Schonenberg",
    "Schonenberg-Kubelberg",
    "Schonewalde",
    "Schoneworde",
    "Schonfeld",
    "Schonfliess",
    "Schongau",
    "Schongeising",
    "Schoningen",
    "Schonkirchen",
    "Schonsee",
    "Schonstett",
    "Schonthal",
    "Schonungen",
    "Schonwald",
    "Schonwald im Schwarzwald",
    "Schonwalde",
    "Schonwalde",
    "Schopfheim",
    "Schopfloch",
    "Schopfloch",
    "Schoppenstedt",
    "Schoppingen",
    "Schopsdorf",
    "Schorndorf",
    "Schornsheim",
    "Schortens",
    "Schossin",
    "Schotten",
    "Schramberg",
    "Schriesheim",
    "Schrobenhausen",
    "Schrozberg",
    "Schuby",
    "Schuld",
    "Schulendorf",
    "Schulldorf",
    "Schuller",
    "Schulzendorf",
    "Schuttertal",
    "Schutterwald",
    "Schuttorf",
    "Schutz",
    "Schutzbach",
    "Schutzenhof",
    "Schwaan",
    "Schwabach",
    "Schwaben",
    "Schwabenheim",
    "Schwabhausen",
    "Schwabhausen",
    "Schwabmunchen",
    "Schwabniederhofen",
    "Schwabstedt",
    "Schwaibach",
    "Schwaig",
    "Schwaig",
    "Schwaigern",
    "Schwaikheim",
    "Schwalbach",
    "Schwalbach am Taunus",
    "Schwalingen",
    "Schwallungen",
    "Schwalmstadt",
    "Schwalmtal",
    "Schwalmtal",
    "Schwandorf in Bayern",
    "Schwanebeck",
    "Schwaneberg",
    "Schwanewede",
    "Schwanfeld",
    "Schwangau",
    "Schwansee",
    "Schwarme",
    "Schwarmstedt",
    "Schwarz",
    "Schwarzach",
    "Schwarzach",
    "Schwarzbach",
    "Schwarzenbach",
    "Schwarzenbach am Wald",
    "Schwarzenbach an der Saale",
    "Schwarzenbek",
    "Schwarzenberg",
    "Schwarzenborn",
    "Schwarzenbruck",
    "Schwarzenfeld",
    "Schwarzhausen",
    "Schwarzheide",
    "Schwarzholz",
    "Schwarzwald",
    "Schwebheim",
    "Schwedelbach",
    "Schwedt (Oder)",
    "Schwegenheim",
    "Schweiburg",
    "Schweich",
    "Schweigen-Rechtenbach",
    "Schweinfurt",
    "Schweitenkirchen",
    "Schweizerhof",
    "Schwelm",
    "Schwendi",
    "Schwenningen",
    "Schwerin",
    "Schweringen",
    "Schwerstedt",
    "Schwerte",
    "Schwesing",
    "Schwetzingen",
    "Schwieberdingen",
    "Schwienau",
    "Schwifting",
    "Schwindegg",
    "Schwirzheim",
    "Schwollen",
    "Schworstadt",
    "Sebnitz",
    "Seck",
    "Seckach",
    "Seebach",
    "Seebad Ahlbeck",
    "Seebad Bansin",
    "Seebad Heringsdorf",
    "Seebergen",
    "Seebruck",
    "Seeburg",
    "Seeburg",
    "Seedorf",
    "Seefeld",
    "Seefeld",
    "Seefeld",
    "Seeg",
    "Seega",
    "Seehaus",
    "Seehausen",
    "Seeheilbad Graal-Muritz",
    "Seeheim-Jugenheim",
    "Seehof",
    "Seehof",
    "Seeland",
    "Seelbach",
    "Seelitz",
    "Seelow",
    "Seelscheid",
    "Seelze",
    "Seeon-Seebruck",
    "Seesbach",
    "Seesen",
    "Seeshaupt",
    "Seester",
    "Seevetal",
    "Seffern",
    "Sefferweich",
    "Sehlde",
    "Sehlem",
    "Sehlen",
    "Sehma",
    "Sehnde",
    "Seifhennersdorf",
    "Selb",
    "Selbach",
    "Selbitz",
    "Selent",
    "Seligenstadt",
    "Seligenstadt",
    "Seligenthal",
    "Sellerich",
    "Sellin",
    "Selm",
    "Selmsdorf",
    "Selsingen",
    "Selters",
    "Selters",
    "Selzen",
    "Sembach",
    "Senden",
    "Senden",
    "Sendenhorst",
    "Senftenberg",
    "Sengenthal",
    "Sennfeld",
    "Sensweiler",
    "Senzig",
    "Sereetz",
    "Serrig",
    "Sersheim",
    "Sesslach",
    "Seth",
    "Seubersdorf",
    "Seukendorf",
    "Sexau",
    "Sibbesse",
    "Sickte",
    "Siebenbaumen",
    "Siedenbrunzow",
    "Siedenburg",
    "Siegburg",
    "Siegelsbach",
    "Siegen",
    "Siegenburg",
    "Siegsdorf",
    "Sielenbach",
    "Sierksdorf",
    "Siershahn",
    "Siesbach",
    "Sievershutten",
    "Sieverstedt",
    "Sigmaringen",
    "Sigmaringendorf",
    "Sigmarszell",
    "Silling",
    "Simbach",
    "Simbach am Inn",
    "Simmelsdorf",
    "Simmerath",
    "Simmern",
    "Simmersfeld",
    "Simmertal",
    "Simonsberg",
    "Sindelfingen",
    "Sindorf",
    "Sindringen",
    "Singen",
    "Singenbach",
    "Singhofen",
    "Sinn",
    "Sinsheim",
    "Sinzheim",
    "Sinzig",
    "Sinzing",
    "Sippersfeld",
    "Sipplingen",
    "Sittensen",
    "Sochtenau",
    "Soemmerda",
    "Soest",
    "Sogel",
    "Sohland",
    "Sohlde",
    "Sohren",
    "Solden",
    "Solingen",
    "Solkwitz",
    "Soller",
    "Sollerup",
    "Sollstedt",
    "Solms",
    "Soltau",
    "Soltendieck",
    "Somborn",
    "Sommerland",
    "Sommerloch",
    "Sondershausen",
    "Sondheim",
    "Sonneberg",
    "Sonnefeld",
    "Sonnschied",
    "Sonsbeck",
    "Sontheim",
    "Sontheim an der Brenz",
    "Sonthofen",
    "Sontra",
    "Sorth",
    "Sorup",
    "Sosa",
    "Sotterhausen",
    "Sottrum",
    "Soyen",
    "Spaichingen",
    "Spalt",
    "Spangdahlem",
    "Spangenberg",
    "Spardorf",
    "Spatzenhausen",
    "Spay",
    "Spechtsbrunn",
    "Spechtshausen",
    "Speicher",
    "Speichersdorf",
    "Speinshart",
    "Spelle",
    "Spenge",
    "Spessart",
    "Spetzerfehn",
    "Speyer",
    "Spiegelau",
    "Spiegelberg",
    "Spiegelsberge",
    "Spielberg",
    "Spiesen-Elversberg",
    "Spitzkunnersdorf",
    "Splietsdorf",
    "Sponheim",
    "Spora",
    "Spornitz",
    "Spraitbach",
    "Spree",
    "Spreenhagen",
    "Spremberg",
    "Spremberg",
    "Sprendlingen",
    "Sprengel",
    "Springe",
    "Springstille",
    "Sprockhovel",
    "Stabelow",
    "Stade",
    "Stadecken-Elsheim",
    "Stadelhofen",
    "Stadt",
    "Stadt Wehlen",
    "Stadtallendorf",
    "Stadtbergen",
    "Stadthagen",
    "Stadtilm",
    "Stadtkyll",
    "Stadtlauringen",
    "Stadtlohn",
    "Stadtoldendorf",
    "Stadtprozelten",
    "Stadtroda",
    "Stadtsteinach",
    "Stahl",
    "Stahlhofen",
    "Stahnsdorf",
    "Staig",
    "Stammbach",
    "Stammheim",
    "Stamsried",
    "Stangerode",
    "Stapelburg",
    "Stapelfeld",
    "Starnberg",
    "Stassfurt",
    "Stauchitz",
    "Staudt",
    "Staufen im Breisgau",
    "Staufenberg",
    "Stegaurach",
    "Stegen",
    "Steglitz Bezirk",
    "Steigra",
    "Steimbke",
    "Steimel",
    "Stein",
    "Stein an der Traun",
    "Stein-Neukirch",
    "Steinach",
    "Steinach",
    "Steinau an der Strasse",
    "Steinbach am Taunus",
    "Steinburg",
    "Steineberg",
    "Steinegg",
    "Steinen",
    "Steinen",
    "Steinenbronn",
    "Steineroth",
    "Steinfeld",
    "Steinfeld",
    "Steinfurt",
    "Steingaden",
    "Steinhagen",
    "Steinhagen",
    "Steinhausen",
    "Steinheim",
    "Steinheim am Albuch",
    "Steinheim am der Murr",
    "Steinhoring",
    "Steinhorst",
    "Steinigtwolmsdorf",
    "Steinlah",
    "Steinmauern",
    "Steintoch",
    "Steinweiler",
    "Steinweiler",
    "Steinwenden",
    "Steinwiesen",
    "Steisslingen",
    "Stelle",
    "Stellshagen",
    "Stelzenberg",
    "Stendal",
    "Stepfershausen",
    "Stephanskirchen",
    "Sterbfritz",
    "Sternberg",
    "Sternberg",
    "Sternenfels",
    "Sterup",
    "Sterzhausen",
    "Stetten",
    "Stetten",
    "Stettfeld",
    "Steuden",
    "Steudnitz",
    "Steyerberg",
    "Stiege",
    "Stimpfach",
    "Stinstedt",
    "Stockach",
    "Stockelsdorf",
    "Stockhausen",
    "Stockhausen-Illfurth",
    "Stockheim",
    "Stockheim",
    "Stockse",
    "Stocksee",
    "Stockstadt am Main",
    "Stockstadt am Rhein",
    "Stoetze",
    "Stolberg",
    "Stolk",
    "Stollberg",
    "Stolpen",
    "Stolzenau",
    "Stolzenhagen",
    "Storkow",
    "Stornstein",
    "Stotten",
    "Stotternheim",
    "Stottwang",
    "Straelen",
    "Strahlungen",
    "Stralsund",
    "Strande",
    "Strassberg",
    "Strasskirchen",
    "Strasslach-Dingharting",
    "Straubing",
    "Straupitz",
    "Strausberg",
    "Straussfurt",
    "Streich",
    "Streithausen",
    "Strickscheid",
    "Strohkirchen",
    "Strohn",
    "Stromberg",
    "Stromberg",
    "Strucklingen",
    "Strullendorf",
    "Struxdorf",
    "Stubenberg",
    "Stuhlingen",
    "Stuhr",
    "Stukenbrock",
    "Stulln",
    "Stuttgart",
    "Stutzengrun",
    "Stutzerbach",
    "Styrum",
    "Suckow",
    "Suddendorf",
    "Suderau",
    "Suderbrarup",
    "Suderburg",
    "Suderholz",
    "Suderlugum",
    "Suderstapel",
    "Sudheide",
    "Sudlohn",
    "Sugenheim",
    "Suhl",
    "Suhlendorf",
    "Sulfeld",
    "Sulingen",
    "Sulz am Neckar",
    "Sulzbach",
    "Sulzbach",
    "Sulzbach",
    "Sulzbach am Main",
    "Sulzbach-Rosenberg",
    "Sulzberg",
    "Sulzemoos",
    "Sulzfeld",
    "Sulzfeld",
    "Sulzgries",
    "Sulzheim",
    "Sulzheim",
    "Sulzthal",
    "Sunching",
    "Sundern",
    "Sundhausen",
    "Supplingen",
    "Surheim",
    "Susel",
    "Sussen",
    "Syke",
    "Syrau",
    "Taarstedt",
    "Tabarz",
    "Tacherting",
    "Taching am See",
    "Tailfingen",
    "Talheim",
    "Talkau",
    "Tamm",
    "Tangendorf",
    "Tangerhutte",
    "Tangermunde",
    "Tangstedt",
    "Tangstedt",
    "Tann",
    "Tann",
    "Tanna",
    "Tannesberg",
    "Tannhausen",
    "Tantow",
    "Tapfheim",
    "Tappenbeck",
    "Tarbek",
    "Tarmow",
    "Tarmstedt",
    "Tarp",
    "Tating",
    "Tauberbischofsheim",
    "Tauberfeld",
    "Tauberrettersheim",
    "Taucha",
    "Tauche",
    "Taufkirchen",
    "Taufkirchen",
    "Taunusstein",
    "Taura",
    "Tawern",
    "Tecklenburg",
    "Tegernheim",
    "Tegernsee",
    "Teichwolframsdorf",
    "Teisendorf",
    "Teising",
    "Teisnach",
    "Teistungenburg",
    "Telgte",
    "Tellingstedt",
    "Teltow",
    "Temmels",
    "Templin",
    "Tengen",
    "Teningen",
    "Tennenbronn",
    "Terborg",
    "Teschow",
    "Tespe",
    "Tessin",
    "Tetenbull",
    "Tetenhusen",
    "Teterow",
    "Tettau",
    "Tettau",
    "Tettenwang",
    "Tettnang Castle",
    "Teublitz",
    "Teuchern",
    "Teugn",
    "Teuschnitz",
    "Teutschenthal",
    "Thaden",
    "Thale",
    "Thaleischweiler-Froschen",
    "Thalfang",
    "Thalhausen",
    "Thalheim",
    "Thalheim",
    "Thallichtenberg",
    "Thallwitz",
    "Thalmassing",
    "Thalmassing",
    "Thalwenden",
    "Thannberg",
    "Thannhausen",
    "Tharandt",
    "Thedinghausen",
    "Theilenhofen",
    "Theilheim",
    "Theissen",
    "Theley",
    "Themar",
    "Thermalbad Wiesenbad",
    "Thiendorf",
    "Thierhaupten",
    "Thierstein",
    "Tholey",
    "Thoma",
    "Thomasburg",
    "Thomm",
    "Thuine",
    "Thulba",
    "Thum",
    "Thungen",
    "Thungersheim",
    "Thur",
    "Thurkow",
    "Thurnau",
    "Thyrnau",
    "Thyrow",
    "Tiddische",
    "Tiefenbach",
    "Tiefenbach",
    "Tiefenbronn",
    "Tiefenort",
    "Tiefenthal",
    "Tieplitz",
    "Tieringen",
    "Tilleda",
    "Timmaspe",
    "Timmendorf",
    "Timmendorf Beach",
    "Timmenrode",
    "Tinnum",
    "Tirschenreuth",
    "Titisee-Neustadt",
    "Titting",
    "Tittling",
    "Tittmoning",
    "Titz",
    "Tobertitz",
    "Todenbuttel",
    "Todendorf",
    "Todesfelde",
    "Todtenried",
    "Todtenweis",
    "Todtmoos",
    "Todtnau",
    "Todtnauberg",
    "Toging am Inn",
    "Tomerdingen",
    "Tonbach",
    "Tonisvorst",
    "Tonndorf",
    "Tonning",
    "Topen",
    "Toppenstedt",
    "Torgau",
    "Torgelow",
    "Tornesch",
    "Tostedt",
    "Traben-Trarbach",
    "Trabitz",
    "Traisen",
    "Traitsching",
    "Trappenkamp",
    "Trassem",
    "Traubing",
    "Traunreut",
    "Traunstein",
    "Traunwalchen",
    "Trautskirchen",
    "Trebbin",
    "Treben",
    "Trebendorf",
    "Trebsen",
    "Trebur",
    "Treffelhausen",
    "Treffelstein",
    "Treia",
    "Treis-Karden",
    "Tremsbuttel",
    "Trendelburg",
    "Trent",
    "Treuchtlingen",
    "Treudelberg",
    "Treuen",
    "Treuenbrietzen",
    "Triberg im Schwarzwald",
    "Trier",
    "Trierweiler",
    "Triftern",
    "Trimbs",
    "Trippstadt",
    "Triptis",
    "Trittau",
    "Trochtelfingen",
    "Trogen",
    "Troisdorf",
    "Trollenhagen",
    "Trossingen",
    "Trostau",
    "Trostberg an der Alz",
    "Truchtlaching",
    "Trulben",
    "Trunkelsberg",
    "Trusetal",
    "Tschernitz",
    "Tuerkenfeld",
    "Tulau",
    "Tuningen",
    "Tuntenhausen",
    "Turkheim",
    "Tussenhausen",
    "Tuszshing",
    "Tutow",
    "Tuttlingen",
    "Tutzing",
    "Tutzpatz",
    "Twielenfleth",
    "Twist",
    "Twiste",
    "Twistringen",
    "Ubach-Palenberg",
    "Uberfeld",
    "Uberherrn",
    "Uberlingen",
    "Ubersee",
    "Ubstadt-Weiher",
    "Uchte",
    "Uchtelhausen",
    "Uckendorf",
    "Udenheim",
    "Uder",
    "Uebigau",
    "Ueckermunde",
    "Uedem",
    "Uehlfeld",
    "Uelitz",
    "Uelsen",
    "Uelvesbull",
    "Uelzen",
    "Uersfeld",
    "Uess",
    "Ueterlande",
    "Uetersen",
    "Uettingen",
    "Uetze",
    "Uffenheim",
    "Uffing",
    "Uhingen",
    "Uhldingen-Muhlhofen",
    "Uhler",
    "Uhlingen-Birkendorf",
    "Ulbersdorf",
    "Ulm",
    "Ulmen",
    "Ulmet",
    "Ulrichstein",
    "Ulsnis",
    "Umkirch",
    "Ummendorf",
    "Umpfenbach",
    "Unkel",
    "Unlingen",
    "Unna",
    "Unnau",
    "Unterammergau",
    "Unterbaldingen",
    "Unterdiessen",
    "Unterdietfurt",
    "Untereglfing",
    "Untereisesheim",
    "Unterensingen",
    "Untereuerheim",
    "Unterfoehring",
    "Untergimpern",
    "Untergriesbach",
    "Untergruppenbach",
    "Unterhaching",
    "Unterhausen",
    "Unterkirnach",
    "Unterliezheim",
    "Unterluss",
    "Untermeitingen",
    "Untermunkheim",
    "Unterneukirchen",
    "Unterpleichfeld",
    "Unterreichenbach",
    "Unterreit",
    "Unterroth",
    "Unterschleichach",
    "Unterschleissheim",
    "Unterschneidheim",
    "Untersiemau",
    "Untersteinach",
    "Unterthingau",
    "Unteruhldingen",
    "Unterwellenborn",
    "Unterwossen",
    "Untrasried",
    "Upgant-Schott",
    "Urbach",
    "Urbach",
    "Urbar",
    "Urexweiler",
    "Urmitz",
    "Ursensollen",
    "Urspringen",
    "Urweiler",
    "Urzig",
    "Usedom",
    "Usingen",
    "Uslar",
    "Ustersbach",
    "Uttenreuth",
    "Uttenweiler",
    "Utting",
    "Utzenfeld",
    "Uxheim",
    "Vacha",
    "Vachendorf",
    "Vahlbruch",
    "Vahldorf",
    "Vaihingen an der Enz",
    "Vallendar",
    "Valley",
    "Varel",
    "Vastorf",
    "Vaterstetten",
    "Vechelde",
    "Vechta",
    "Veckenstedt",
    "Vegesack",
    "Vehlow",
    "Veilsdorf",
    "Veitsbronn",
    "Veitshochheim",
    "Velbert",
    "Velburg",
    "Velden",
    "Veldenz",
    "Velen",
    "Vellahn",
    "Vellberg",
    "Vellmar",
    "Velpke",
    "Velten",
    "Veltheim",
    "Veltheim",
    "Vendersheim",
    "Venne",
    "Verchen",
    "Verden an der Aller",
    "Veringendorf",
    "Veringenstadt",
    "Verl",
    "Versmold",
    "Vesser",
    "Vestenberg",
    "Vestenbergsgreuth",
    "Vetschau",
    "Vettelschoss",
    "Vettweiss",
    "Viecheln",
    "Viechtach",
    "Vielbach",
    "Vienenburg",
    "Vier",
    "Vierden",
    "Viereth-Trunstadt",
    "Vierhofen",
    "Vierkirchen",
    "Viernau",
    "Viernheim",
    "Viersen",
    "Vieselbach",
    "Vietgest",
    "Villenbach",
    "Villingen",
    "Villingen-Schwenningen",
    "Villingendorf",
    "Villmar",
    "Vilsbiburg",
    "Vilseck",
    "Vilsheim",
    "Vilshofen",
    "Vilshoven",
    "Visbek",
    "Visselhovede",
    "Vitte",
    "Vlotho",
    "Vluyn",
    "Voerde",
    "Vogelsdorf",
    "Vogelsen",
    "Vogt",
    "Vogtareuth",
    "Vogtsburg",
    "Vohburg an der Donau",
    "Vohenstrauss",
    "Vohl",
    "Vohrenbach",
    "Vohringen",
    "Vohringen",
    "Volkach",
    "Volkenschwand",
    "Volkenshagen",
    "Volkersweiler",
    "Volkertshausen",
    "Volklingen",
    "Volkmarsen",
    "Vollersode",
    "Vollersroda",
    "Vollkofen",
    "Vollrathsruhe",
    "Voltlage",
    "Volxheim",
    "Vorbach",
    "Vorbach",
    "Vorden",
    "Vordorf",
    "Vorland",
    "Vorra",
    "Vorstetten",
    "Vorwerk",
    "Voslapp",
    "Vreden",
    "Waakirchen",
    "Waal",
    "Wabern",
    "Wachau",
    "Wachenheim an der Weinstrasse",
    "Wachenroth",
    "Wachsenburg",
    "Wachtberg",
    "Wachtendonk",
    "Wachtersbach",
    "Wachtum",
    "Wacken",
    "Wackernheim",
    "Wackerow",
    "Wackersdorf",
    "Wadelsdorf",
    "Wadern",
    "Wadersloh",
    "Wadgassen",
    "Waffenbrunn",
    "Wagenfeld",
    "Wagenhoff",
    "Wagersrott",
    "Waghausel",
    "Waging am See",
    "Wahlstedt",
    "Wahnwegen",
    "Wahrenholz",
    "Waiblingen",
    "Waibstadt",
    "Waidhaus",
    "Wain",
    "Waischenfeld",
    "Wakendorf",
    "Walchum",
    "Wald",
    "Wald",
    "Wald-Michelbach",
    "Waldalgesheim",
    "Waldaschaff",
    "Waldau",
    "Waldbockelheim",
    "Waldbreitbach",
    "Waldbrol",
    "Waldbrunn",
    "Waldburg",
    "Waldbuttelbrunn",
    "Waldeck",
    "Waldems",
    "Waldenbuch",
    "Waldenburg",
    "Waldenburg",
    "Walderbach",
    "Waldershof",
    "Waldesch",
    "Waldfeucht",
    "Waldfischbach-Burgalben",
    "Waldhambach",
    "Waldheim",
    "Waldkappel",
    "Waldkirch",
    "Waldkirchen",
    "Waldkraiburg",
    "Waldlaubersheim",
    "Waldmohr",
    "Waldmunchen",
    "Waldorf",
    "Waldsassen",
    "Waldschlosschen",
    "Waldsee",
    "Waldshut",
    "Waldshut-Tiengen",
    "Waldsieversdorf",
    "Waldstetten",
    "Walhausen",
    "Walheim",
    "Walkenried",
    "Wall",
    "Walldorf",
    "Walldorf",
    "Walldurn",
    "Walle",
    "Wallendorf",
    "Wallenfels",
    "Wallenhorst",
    "Wallerfangen",
    "Wallersdorf",
    "Wallerstein",
    "Wallertheim",
    "Walleshausen",
    "Wallhausen",
    "Wallhausen",
    "Wallhausen",
    "Wallmerod",
    "Walluf",
    "Walpertshofen",
    "Walpertskirchen",
    "Walsdorf",
    "Walshausen",
    "Walsleben",
    "Walsrode",
    "Waltenhofen",
    "Waltersdorf",
    "Waltershausen",
    "Walting",
    "Waltrop",
    "Wandlitz",
    "Wanfried",
    "Wang",
    "Wangen",
    "Wangen",
    "Wangen im Allgau",
    "Wangerooge",
    "Wankendorf",
    "Wanna",
    "Wanne-Eickel",
    "Wannweil",
    "Wansleben",
    "Wanzleben",
    "Warberg",
    "Warburg",
    "Wardenburg",
    "Wardow",
    "Waren",
    "Warendorf",
    "Warin",
    "Warmensteinach",
    "Warmsen",
    "Warnau",
    "Warngau",
    "Warnow",
    "Warrenzin",
    "Warstein",
    "Wartenberg",
    "Wartenberg-Rohrbach",
    "Warthausen",
    "Warza",
    "Wasbek",
    "Wasbuttel",
    "Waschenbach",
    "Waschenbeuren",
    "Wasenweiler",
    "Wassenach",
    "Wassenberg",
    "Wasser",
    "Wasser",
    "Wasserburg am Inn",
    "Wasserleben",
    "Wasserliesch",
    "Wasserlosen",
    "Wassermungenau",
    "Wassersleben",
    "Wassertrudingen",
    "Wasungen",
    "Wathlingen",
    "Wattenbek",
    "Wattenheim",
    "Wattmannshagen",
    "Watzenborn",
    "Waxweiler",
    "Wechingen",
    "Wechmar",
    "Wechselburg",
    "Weddel",
    "Weddelbrook",
    "Weddingstedt",
    "Wedel",
    "Wedringen",
    "Weede",
    "Weener",
    "Weenzen",
    "Wees",
    "Weeze",
    "Wefensleben",
    "Weferlingen",
    "Wegberg",
    "Wegeleben",
    "Wegscheid",
    "Wehingen",
    "Wehlheiden",
    "Wehnrath",
    "Wehr",
    "Wehrbleck",
    "Wehrheim",
    "Weiberg",
    "Weibern",
    "Weibersbrunn",
    "Weichering",
    "Weichs",
    "Weida",
    "Weiden",
    "Weiden",
    "Weiden",
    "Weiden",
    "Weidenbach",
    "Weidenberg",
    "Weidenhahn",
    "Weidenhof",
    "Weidenthal",
    "Weidhausen",
    "Weiding",
    "Weigendorf",
    "Weihenzell",
    "Weiherhammer",
    "Weihmichl",
    "Weikersheim",
    "Weil",
    "Weil am Rhein",
    "Weil der Stadt",
    "Weil im Schonbuch",
    "Weilar",
    "Weilbach",
    "Weilbach",
    "Weilburg",
    "Weiler",
    "Weiler-Simmerberg",
    "Weilerbach",
    "Weilersbach",
    "Weilerswist",
    "Weilheim",
    "Weilheim",
    "Weilheim an der Teck",
    "Weilmunster",
    "Weiltingen",
    "Weimar",
    "Weimar",
    "Weinahr",
    "Weinbach",
    "Weinbohla",
    "Weingarten",
    "Weingarten",
    "Weinheim",
    "Weinsberg",
    "Weinsfeld",
    "Weinsheim",
    "Weinstadt-Endersbach",
    "Weischlitz",
    "Weisel",
    "Weisen",
    "Weisenbach",
    "Weisendorf",
    "Weisenheim am Berg",
    "Weisenheim am Sand",
    "Weiskirchen",
    "Weismain",
    "Weissach",
    "Weissandt-Golzau",
    "Weissbach",
    "Weissdorf",
    "Weissen",
    "Weissenborn",
    "Weissenbrunn",
    "Weissenburg",
    "Weissenfeld",
    "Weissenfels",
    "Weissenhorn",
    "Weissenohe",
    "Weissensee",
    "Weissenstadt",
    "Weissenthurm",
    "Weisswasser",
    "Weisweil",
    "Weitefeld",
    "Weitenhagen",
    "Weitersborn",
    "Weitersburg",
    "Weiterstadt",
    "Weitersweiler",
    "Weitnau",
    "Weitramsdorf",
    "Weitzschen",
    "Weixerau",
    "Welbsleben",
    "Welden",
    "Welden",
    "Welgesheim",
    "Welkenbach",
    "Welle",
    "Wellheim",
    "Welschbillig",
    "Welshofen",
    "Welsleben",
    "Welterod",
    "Welver",
    "Welzheim",
    "Welzow",
    "Wemding",
    "Wenddorf",
    "Wendeburg",
    "Wendelstein",
    "Wenden",
    "Wendhausen",
    "Wendisch Evern",
    "Wendisch Rietz",
    "Wendlingen am Neckar",
    "Wendtorf",
    "Wengelsdorf",
    "Wennigsen",
    "Wentorf",
    "Wentorf bei Hamburg",
    "Wenzenbach",
    "Wenzendorf",
    "Wenzingen",
    "Wenzlow",
    "Werben",
    "Werda",
    "Werdau",
    "Werder",
    "Werdohl",
    "Werl",
    "Werlaburgdorf",
    "Werlau",
    "Werlte",
    "Wermelskirchen",
    "Wermsdorf",
    "Wernau",
    "Wernberg-Koblitz",
    "Werne",
    "Werneck",
    "Wernersberg",
    "Werneuchen",
    "Wernigerode",
    "Werningshausen",
    "Wernrode",
    "Werpeloh",
    "Werste",
    "Wertach",
    "Wertheim am Main",
    "Werther",
    "Wertingen",
    "Wesel",
    "Weselberg",
    "Wesenberg",
    "Wesendorf",
    "Wesselburen",
    "Wesseling",
    "Wesseln",
    "Wessiszell",
    "Wessling",
    "Wessobrunn Abbey",
    "Weste",
    "Westendorf",
    "Westensee",
    "Wester-Ohrstedt",
    "Westerburg",
    "Westerdeichstrich",
    "Westeregeln",
    "Westergellersen",
    "Westerheim",
    "Westerheim",
    "Westerholt",
    "Westerhorn",
    "Westerkappeln",
    "Westerland",
    "Westerronfeld",
    "Westerstede",
    "Westerstetten",
    "Westfeld",
    "Westhausen",
    "Westhausen",
    "Westheim",
    "Westheim",
    "Westhofen",
    "Westkirchen",
    "Westrhauderfehn",
    "Wetschen",
    "Wettelsheim",
    "Wettenberg",
    "Wetter",
    "Wetter (Ruhr)",
    "Wetterzeube",
    "Wettin",
    "Wettringen",
    "Wettstetten",
    "Wetzlar",
    "Weyarn",
    "Weyerbusch",
    "Weyhausen",
    "Weyhe",
    "Wichte",
    "Wickede",
    "Wicklesgreuth",
    "Wiebelsheim",
    "Wiedemar",
    "Wiederau",
    "Wiedergeltingen",
    "Wiefelstede",
    "Wiehe",
    "Wiehl",
    "Wiek",
    "Wielenbach",
    "Wienhausen",
    "Wienrode",
    "Wiernsheim",
    "Wiesau",
    "Wiesbach",
    "Wiesbach",
    "Wiesbaden",
    "Wieseck",
    "Wieselbach",
    "Wiesenbach",
    "Wiesenbach",
    "Wiesenburg",
    "Wiesenfeld",
    "Wiesenfelden",
    "Wiesensteig",
    "Wiesent",
    "Wiesentheid",
    "Wieske",
    "Wiesloch",
    "Wiesmoor",
    "Wiesthal",
    "Wietmarschen",
    "Wietze",
    "Wietzen",
    "Wietzendorf",
    "Wiggensbach",
    "Wilburgstetten",
    "Wildau",
    "Wildberg",
    "Wildbergerhutte",
    "Wildenberg",
    "Wildenfels",
    "Wildeshausen",
    "Wildpoldsried",
    "Wilferdingen",
    "Wilgartswiesen",
    "Wilhelm",
    "Wilhelmsburg",
    "Wilhelmsdorf",
    "Wilhelmsdorf",
    "Wilhelmsdorf",
    "Wilhelmsfeld",
    "Wilhelmshaven",
    "Wilhelmsheim",
    "Wilhelmsthal",
    "Wilhelmsthal",
    "Wilhermsdorf",
    "Wilkau-Hasslau",
    "Willebadessen",
    "Willensen",
    "Willich",
    "Willingen",
    "Willmars",
    "Willmenrod",
    "Willmersdorf",
    "Willroth",
    "Willstatt",
    "Wilnsdorf",
    "Wilsdruff",
    "Wilstedt",
    "Wilster",
    "Wilsum",
    "Wilthen",
    "Wiltingen",
    "Wimmelburg",
    "Wimsheim",
    "Wincheringen",
    "Windach",
    "Windberg",
    "Windeby",
    "Windehausen",
    "Winden",
    "Winden",
    "Windesheim",
    "Windhagen",
    "Windischeschenbach",
    "Windischleuba",
    "Windorf",
    "Windsbach",
    "Wingst",
    "Winhoring",
    "Winkelhaid",
    "Winkelhaid",
    "Winkwitz",
    "Winnen",
    "Winnenden",
    "Winnigstedt",
    "Winningen",
    "Winnweiler",
    "Winsen",
    "Winsen",
    "Winsen",
    "Winterbach",
    "Winterbach",
    "Winterberg",
    "Winterhausen",
    "Winterlingen",
    "Winterrieden",
    "Wintersdorf",
    "Wintrich",
    "Winzeln",
    "Winzer",
    "Wipfeld",
    "Wippershain",
    "Wirdum",
    "Wirges",
    "Wirsberg",
    "Wisch",
    "Wischhafen",
    "Wismar",
    "Wisper",
    "Wissen",
    "Wistedt",
    "Wittdun",
    "Wittekindshof",
    "Wittelshofen",
    "Witten",
    "Wittenbeck",
    "Wittenberg",
    "Wittenberg",
    "Wittenberge",
    "Wittenborn",
    "Wittenburg",
    "Wittenforden",
    "Wittgendorf",
    "Wittgensdorf",
    "Wittgert",
    "Wittichenau",
    "Wittighausen",
    "Wittingen",
    "Wittislingen",
    "Wittlich",
    "Wittmund",
    "Wittnau",
    "Wittorf",
    "Wittstock",
    "Witzenhausen",
    "Witzhave",
    "Witzin",
    "Witzwort",
    "Woffleben",
    "Wohlsborn",
    "Wohltorf",
    "Wohnbach",
    "Wohnrod",
    "Wohra",
    "Wohrden",
    "Woldegk",
    "Woldert",
    "Wolfach",
    "Wolfegg",
    "Wolfen",
    "Wolferlingen",
    "Wolferode",
    "Wolfersdorf",
    "Wolfersheim",
    "Wolfgang",
    "Wolfhagen",
    "Wolfis",
    "Wolfmannshausen",
    "Wolframs-Eschenbach",
    "Wolfratshausen",
    "Wolfsburg",
    "Wolfschlugen",
    "Wolfsegg",
    "Wolfsheim",
    "Wolgast",
    "Wolkenstein",
    "Wollbrandshausen",
    "Wollstein",
    "Wolmirstedt",
    "Wolnzach",
    "Wolpertshausen",
    "Wolpertswende",
    "Wolpinghausen",
    "Wolsdorf",
    "Wolsendorf",
    "Wolsfeld",
    "Woltersdorf",
    "Woltersdorf",
    "Woltersdorf",
    "Woltershausen",
    "Woltingerode",
    "Womrath",
    "Wonfurt",
    "Wonsees",
    "Worbis",
    "Worfelden",
    "Worms",
    "Wormstedt",
    "Worpswede",
    "Worrstadt",
    "Worth",
    "Worth am Main",
    "Worth am Rhein",
    "Worthsee",
    "Wotersen",
    "Wredenhagen",
    "Wremen",
    "Wrestedt",
    "Wriedel",
    "Wriezen",
    "Wrist",
    "Wrixum",
    "Wuelfrath",
    "Wulfen",
    "Wulfen",
    "Wulften",
    "Wumbach",
    "Wundersleben",
    "Wunnenberg",
    "Wunsiedel",
    "Wunstorf",
    "Wuppertal",
    "Wurmannsquick",
    "Wurmberg",
    "Wurmlingen",
    "Wurmsham",
    "Wurselen",
    "Wurzbach",
    "Wurzburg",
    "Wurzen",
    "Wustenriet",
    "Wustenrot",
    "Wusterhausen",
    "Wusterhusen",
    "Wustermark",
    "Wusterwitz",
    "Wustheuterode",
    "Wustweiler",
    "Wutoschingen",
    "Wyhl",
    "Wyk auf Fohr",
    "Xanten",
    "Zaberfeld",
    "Zahna",
    "Zainingen",
    "Zaisenhausen",
    "Zaisertshofen",
    "Zapel",
    "Zapfendorf",
    "Zarpen",
    "Zarrendorf",
    "Zarrentin",
    "Zaulsdorf",
    "Zechlin Dorf",
    "Zehdenick",
    "Zeil",
    "Zeiskam",
    "Zeithain",
    "Zeitlofs",
    "Zeitz",
    "Zell",
    "Zell",
    "Zell",
    "Zell am Harmersbach",
    "Zell am Main",
    "Zell im Wiesental",
    "Zell unter Aichelberg",
    "Zella-Mehlis",
    "Zellingen",
    "Zeltingen-Rachtig",
    "Zemmer",
    "Zempin",
    "Zeppernick",
    "Zerben",
    "Zerbst",
    "Zerf",
    "Zernien",
    "Zerre",
    "Zerrenthin",
    "Zetel",
    "Zeulenroda",
    "Zeuthen",
    "Zeven",
    "Ziegendorf",
    "Zielitz",
    "Ziemetshausen",
    "Zierenberg",
    "Ziesar",
    "Ziesendorf",
    "Ziltendorf",
    "Zimmern",
    "Zimmern",
    "Zimmern ob Rottweil",
    "Zingst",
    "Zipplingen",
    "Zirkow",
    "Zirndorf",
    "Zittau",
    "Zolling",
    "Zollstock",
    "Zorbig",
    "Zorge",
    "Zorneding",
    "Zornheim",
    "Zoschingen",
    "Zossen",
    "Zottelstedt",
    "Zschopau",
    "Zschorlau",
    "Zschornewitz",
    "Zschortau",
    "Zuchering",
    "Zuffenhausen",
    "Zuhlsdorf",
    "Zulpich",
    "Zusamaltheim",
    "Zusmarshausen",
    "Zussow",
    "Zuzenhausen",
    "Zweiflingen",
    "Zwenkau",
    "Zwesten",
    "Zwickau",
    "Zwiefalten",
    "Zwiesel",
    "Zwingenberg",
    "Zwingenberg",
    "Zwonitz",
  ],
  Ghana: [
    "Accra",
    "Bawku",
    "Berekum",
    "Bolgatanga",
    "Cape Coast",
    "Home",
    "Koforidua",
    "Kumasi",
    "Legon",
    "Mampong",
    "Navrongo",
    "Sunyani",
    "Takoradi",
    "Tema",
    "Wa",
    "Winneba",
  ],
  Greece: [
    "Aegina",
    "Agioi Anargyroi",
    "Agios Nikolaos",
    "Agrinio",
    "Aigaleo",
    "Aigio",
    "Alexandreia",
    "Alexandroupoli",
    "Aliartos",
    "Alimos",
    "Amaliada",
    "Anavyssos",
    "Andravida",
    "Ano Liosia",
    "Archaia Nemea",
    "Argos",
    "Argostoli",
    "Argyroupoli",
    "Arta",
    "Aspropyrgos",
    "Athens",
    "Attica",
    "Ayios Stefanos",
    "Chalcis",
    "Chania",
    "Chania",
    "Chios",
    "Cholargos",
    "Corfu",
    "Corinth",
    "Dafni",
    "Dionysos",
    "Drama",
    "Ekali",
    "Elassona",
    "Elefsina",
    "Elliniko",
    "Eretria",
    "Farsala",
    "Filippiada",
    "Filothei",
    "Florina",
    "Galatsi",
    "Giannitsa",
    "Glyfada",
    "Gonnoi",
    "Goumenissa",
    "Heraklion",
    "Hydra",
    "Ialysos",
    "Ierapetra",
    "Igoumenitsa",
    "Ioannina",
    "Kaisariani",
    "Kalamaria",
    "Kalamata",
    "Kalamos",
    "Kallithea",
    "Kalymnos",
    "Kamatero",
    "Karditsa",
    "Kassiopi",
    "Kastoria",
    "Katerini",
    "Kavala",
    "Keratea",
    "Keratsini",
    "Kifissia",
    "Kilkis",
    "Komotini",
    "Koropi",
    "Kos",
    "Kouvaras",
    "Kozani",
    "Kranidi",
    "Kryonerion",
    "Kymi",
    "Lamia",
    "Larisa",
    "Lefkada",
    "Lefkimmi",
    "Leontario",
    "Litochoro",
    "Lixouri",
    "Loutraki",
    "Lykovrysi",
    "Magoula",
    "Makrygialos",
    "Mandra",
    "Marathon",
    "Markopoulo Mesogaias",
    "Megalochari",
    "Megara",
    "Melissia",
    "Mesagros",
    "Messolonghi",
    "Metaxades",
    "Moires",
    "Mytilene",
    "Nafpaktos",
    "Nafplion",
    "Naousa",
    "Naxos",
    "Nea Alikarnassos",
    "Nea Filadelfeia",
    "Nea Ionia",
    "Nea Kios",
    "Nea Makri",
    "Nea Peramos",
    "Nea Smyrni",
    "Nikaia",
    "Orestiada",
    "Ormylia",
    "Oropos",
    "Paiania",
    "Pallini",
    "Paloukia",
    "Panorama",
    "Parga",
    "Patmos",
    "Pefki",
    "Pella",
    "Perama",
    "Peristeri",
    "Peristeri",
    "Pikermi",
    "Piraeus",
    "Platy",
    "Polichni",
    "Porto Rafti",
    "Preveza",
    "Psychiko",
    "Ptolemaida",
    "Pylaia",
    "Pyrgos",
    "Rafina",
    "Rethymno",
    "Rhodes",
    "Rio",
    "Salamina",
    "Samothraki",
    "Serres",
    "Servia",
    "Sindos",
    "Skala",
    "Skala Oropou",
    "Skiathos",
    "Sparta",
    "Spata",
    "Symi",
    "Tavros",
    "Thebes",
    "Thermi",
    "Thessaloniki",
    "Tinos",
    "Trikala",
    "Tripoli",
    "Vari",
    "Varkiza",
    "Vergina",
    "Veroia",
    "Volos",
    "Voula",
    "Vouliagmeni",
    "Xanthi",
    "Xylokastro",
    "Zakynthos",
  ],
  Greenland: [
    "Aasiaat",
    "Ilulissat",
    "Kapisillit",
    "Maniitsoq",
    "Narsaq",
    "Narsarsuaq",
    "Nuuk",
    "Nuussuaq",
    "Paamiut",
    "Qaqortoq",
    "Qasigiannguit",
    "Qeqertarsuaq",
    "Qeqertat",
    "Sisimiut",
    "Tasiilaq",
    "Upernavik",
    "Uummannaq Kommune",
  ],
  Guadeloupe: [
    "Anse-Bertrand",
    "Baie Mahault",
    "Baie-Mahault",
    "Baillif",
    "Basse-Terre",
    "Capesterre-Belle-Eau",
    "Capesterre-de-Marie-Galante",
    "Deshaies",
    "Gourbeyre",
    "Goyave",
    "Grand-Bourg",
    "Lamentin",
    "Le Gosier",
    "Le Moule",
    "Les Abymes",
    "Petit-Bourg",
    "Petit-Canal",
    "Pointe-Noire",
    "Port-Louis",
    "Saint-Claude",
    "Saint-Francois",
    "Saint-Louis",
    "Sainte-Anne",
    "Sainte-Rose",
    "Terre-de-Bas",
    "Terre-de-Haut",
    "Trois-Rivieres",
    "Village",
  ],
  Guam: [
    "Barrigada Village",
    "Dededo Village",
    "Inarajan Village",
    "Santa Rita",
    "Tamuning-Tumon-Harmon Village",
    "Yigo Village",
  ],
  Guatemala: [
    "Antigua Guatemala",
    "Cambote",
    "Catarina",
    "Central",
    "Chimaltenango",
    "Chiquimula",
    "Ciudad Vieja",
    "Coban",
    "El Limon",
    "El Naranjo",
    "El Salvador",
    "Escuintla",
    "Esquipulas",
    "Flores",
    "Guatemala City",
    "Huehuetenango",
    "Jocotenango",
    "La Reforma",
    "La Reforma",
    "Mazatenango",
    "Melchor de Mencos",
    "Mixco",
    "Palin",
    "Panajachel",
    "Petapa",
    "Puerto Barrios",
    "Quesada",
    "Quetzaltenango",
    "Retalhuleu",
    "San Antonio Miramar",
    "San Jose Pinula",
    "San Juan",
    "San Marcos",
    "San Pedro Sacatepequez",
    "Santa Catarina Pinula",
    "Santa Cruz La Laguna",
    "Santa Cruz Naranjo",
    "Santa Lucia Cotzumalguapa",
    "Santa Rosa",
    "Solola",
    "Villa Nueva",
    "Vista Hermosa",
    "Zacapa",
  ],
  Guinea: [
    "Conakry",
    "Dabola",
    "Kalia",
    "Kankan",
    "Lola",
    "Mamou",
    "Port Kamsar",
    "Sangaredi",
  ],
  Haiti: [
    "Carrefour",
    "Delmar",
    "Duverger",
    "Jacmel",
    "Masseau",
    "Moise",
    "Petionville",
    "Port-au-Prince",
    "Prince",
    "Turgeau",
  ],
  "Hashemite Kingdom of Jordan": [
    "Amman",
    "Ar Ramtha",
    "Farah",
    "Irbid",
    "Madaba",
    "Sahab",
    "Salt",
  ],
  Honduras: [
    "Choloma",
    "Comayagua",
    "Comayaguela",
    "Coxen Hole",
    "El Barro",
    "El Paraiso",
    "El Progreso",
    "La Ceiba",
    "La Hacienda",
    "Morazan",
    "Nacaome",
    "Pinalejo",
    "Piraera",
    "Puerto Lempira",
    "San Antonio de Flores",
    "San Pedro Sula",
    "Santa Barbara",
    "Sula",
    "Tegucigalpa",
  ],
  "Hong Kong": [
    "Aberdeen",
    "Causeway Bay",
    "Central District",
    "Cha Kwo Ling",
    "Chai Wan Kok",
    "Chek Chue",
    "Cheung Kong",
    "Cheung Sha Wan",
    "Chuen Lung",
    "Chung Hau",
    "Fa Yuen",
    "Fanling",
    "Fo Tan",
    "Happy Valley",
    "Ho Man Tin",
    "Hong Kong",
    "Hung Hom",
    "Kat O Sheung Wai",
    "Kennedy Town",
    "Kowloon",
    "Kowloon Tong",
    "Kwai Chung",
    "Kwun Hang",
    "Lai Chi Wo",
    "Lam Tei",
    "Lam Tin",
    "Lin Fa Tei",
    "Lo So Shing",
    "Ma On Shan Tsuen",
    "Ma Wan",
    "Ma Yau Tong",
    "Mau Ping",
    "Mid Levels",
    "Mong Kok",
    "Nam A",
    "Ngau Chi Wan",
    "Ngau Tau Kok",
    "North Point",
    "Pak Ngan Heung",
    "Peng Chau",
    "Ping Yeung",
    "Quarry Bay",
    "Repulse Bay",
    "Sai Keng",
    "San Tsuen",
    "San Tung Chung Hang",
    "Sha Po Kong",
    "Sha Tau Kok",
    "Sha Tin Wai",
    "Sham Shui Po",
    "Sham Tseng",
    "Shatin",
    "Shau Kei Wan",
    "Shek Kip Mei",
    "Shek Tong Tsui",
    "Sheung Shui",
    "Sheung Tsuen",
    "Shuen Wan",
    "Tai Chau To",
    "Tai Hang",
    "Tai Kok Tsui",
    "Tai Lin Pai",
    "Tai Po",
    "Tai Tan",
    "Tai Wai",
    "Tai Wan To",
    "Tin Shui Wai",
    "Tin Wan Resettlement Estate",
    "Ting Kau",
    "To Kwa Wan",
    "Tseung Kwan O",
    "Tsimshatsui",
    "Tsing Lung Tau",
    "Tsz Wan Shan",
    "Tuen Mun San Hui",
    "Wan Tsai",
    "Wo Che",
    "Wong Chuk Hang",
    "Wong Tai Sin",
    "Yau Ma Tei",
    "Ying Pun",
    "Yuen Long San Hui",
  ],
  Hungary: [
    "Abaujszanto",
    "Abda",
    "Abony",
    "Acs",
    "Acsa",
    "Adacs",
    "Adony",
    "Agard",
    "Ajak",
    "Ajka",
    "Alap",
    "Albertirsa",
    "Almasfuzito",
    "Almaskamaras",
    "Almosd",
    "Alsogalla",
    "Alsonemedi",
    "Alsopahok",
    "Alsoszentivan",
    "Alsotold",
    "Alsozsolca",
    "Aparhant",
    "Apatfalva",
    "Apc",
    "Apostag",
    "Arnot",
    "Asotthalom",
    "Aszalo",
    "Aszar",
    "Aszod",
    "Atkar",
    "Attala",
    "Babolna",
    "Bacsalmas",
    "Badacsonytomaj",
    "Badacsonytordemic",
    "Bag",
    "Bagyogszovat",
    "Baja",
    "Bajna",
    "Bakonybel",
    "Bakonycsernye",
    "Bakonyszentlaszlo",
    "Bakonyszombathely",
    "Balassagyarmat",
    "Balaton",
    "Balatonalmadi",
    "Balatonboglar",
    "Balatonfokajar",
    "Balatonfured",
    "Balatonfuzfo",
    "Balatonkenese",
    "Balatonlelle",
    "Balatonszabadi",
    "Balatonszarszo",
    "Balkany",
    "Balloszog",
    "Balmazujvaros",
    "Banhorvati",
    "Baracs",
    "Baracska",
    "Barand",
    "Barcs",
    "Bataszek",
    "Batonyterenye",
    "Bazsi",
    "Becsehely",
    "Bekasmegyer",
    "Bekesszentandras",
    "Belapatfalva",
    "Beled",
    "Beloiannisz",
    "Benye",
    "Berekfurdo",
    "Berettyoujfalu",
    "Berhida",
    "Berkesd",
    "Besnyo",
    "Biatorbagy",
    "Bicske",
    "Biharkeresztes",
    "Bikal",
    "Bocs",
    "Bodajk",
    "Bodaszolo",
    "Bodrogkisfalud",
    "Bogyiszlo",
    "Bohonye",
    "Bokod",
    "Boldog",
    "Boldva",
    "Boly",
    "Boncodfolde",
    "Bonyhad",
    "Borcs",
    "Borsodszirak",
    "Budajeno",
    "Budakalasz",
    "Budakeszi",
    "Budaors",
    "Budapest",
    "Bugac",
    "Bugyi",
    "Buk",
    "Bukkaranyos",
    "Buzsak",
    "Cegled",
    "Cegledbercel",
    "Celldomolk",
    "Center",
    "Cered",
    "Ciko",
    "Cirak",
    "Csabacsud",
    "Csajag",
    "Csakvar",
    "Csanadpalota",
    "Csanytelek",
    "Csecse",
    "Csemo",
    "Csepa",
    "Cserenfa",
    "Cserepfalu",
    "Cserszegtomaj",
    "Csobad",
    "Csobanka",
    "Csoeroeg",
    "Csogle",
    "Csolyospalos",
    "Csomad",
    "Csomor",
    "Csongrad",
    "Csopak",
    "Csorna",
    "Csorvas",
    "Csovar",
    "Dabas",
    "Dad",
    "Danszentmiklos",
    "Dany",
    "Debrecen",
    "Decs",
    "Dedestapolcsany",
    "Deg",
    "Delegyhaza",
    "Demjen",
    "Derecske",
    "Deszk",
    "Devavanya",
    "Devecser",
    "Diosd",
    "Dobrokoz",
    "Domaszek",
    "Dombovar",
    "Dombrad",
    "Domoszlo",
    "Domsod",
    "Donat",
    "Dorog",
    "Doroghaza",
    "Dudar",
    "Dunabogdany",
    "Dunafoldvar",
    "Dunaharaszti",
    "Dunakeszi",
    "Dunaszeg",
    "Dunaszentgyorgy",
    "Dunavarsany",
    "Dunavecse",
    "Dusnok",
    "Ebes",
    "Ecs",
    "Ecser",
    "Edeleny",
    "Eger",
    "Egerszolat",
    "Egyek",
    "Egyhazasdengeleg",
    "Egyhazashetye",
    "Emod",
    "Encs",
    "Eperjeske",
    "Ercsi",
    "Erdokertes",
    "Esztergom",
    "Farmos",
    "Fegyvernek",
    "Fehergyarmat",
    "Felsopakony",
    "Felsoszentivan",
    "Felsotarkany",
    "Felsozsolca",
    "Fertod",
    "Fertoszentmiklos",
    "Fot",
    "Fuzesabony",
    "Fuzesgyarmat",
    "Galambok",
    "Galgamacsa",
    "Garab",
    "Gardony",
    "Gasztony",
    "Gavavencsello",
    "Gelse",
    "Gemzse",
    "Gencsapati",
    "Gerjen",
    "God",
    "Golle",
    "Gonyu",
    "Gorbehaza",
    "Gorgeteg",
    "Gyal",
    "Gyar",
    "Gyenesdias",
    "Gyomaendrod",
    "Gyomro",
    "Gyongyos",
    "Gyongyossolymos",
    "Gyongyostarjan",
    "Gyorkony",
    "Gyorladamer",
    "Gyorsag",
    "Gyorujbarat",
    "Gyorzamoly",
    "Gyula",
    "Gyulahaza",
    "Hajduboszormeny",
    "Hajdudorog",
    "Hajduhadhaz",
    "Hajdunanas",
    "Hajdusamson",
    "Hajduszoboszlo",
    "Hajmasker",
    "Halaszi",
    "Halasztelek",
    "Halimba",
    "Harkany",
    "Harta",
    "Hatvan",
    "Hegyeshalom",
    "Hegyfalu",
    "Hegyhatmaroc",
    "Hegyhatszentmarton",
    "Hehalom",
    "Helvecia",
    "Herceghalom",
    "Hercegkut",
    "Hercegszanto",
    "Hered",
    "Herend",
    "Hernad",
    "Hernadkak",
    "Hernadnemeti",
    "Heves",
    "Hevesaranyos",
    "Hevizgyoerk",
    "Hidas",
    "Hodmezovasarhely",
    "Homrogd",
    "Hont",
    "Hort",
    "Hosszuheteny",
    "Ibrany",
    "Ikreny",
    "Inancs",
    "Inarcs",
    "Iregszemcse",
    "Isaszeg",
    "Ivancsa",
    "Izmeny",
    "Janoshalma",
    "Janoshida",
    "Janossomorja",
    "Jaszapati",
    "Jaszbereny",
    "Jaszfelsoszentgyorgy",
    "Jaszfenyszaru",
    "Jaszkiser",
    "Jaszladany",
    "Juta",
    "Kacsota",
    "Kadarkut",
    "Kajaszo",
    "Kallo",
    "Kalocsa",
    "Kaloz",
    "Kapolnasnyek",
    "Kaposmero",
    "Kapuvar",
    "Karancskeszi",
    "Karancslapujto",
    "Karancssag",
    "Karcag",
    "Kartal",
    "Kazincbarcika",
    "Kecel",
    "Kemecse",
    "Kemence",
    "Kerecsend",
    "Kerekegyhaza",
    "Kerekharaszt",
    "Kerepes",
    "Kesznyeten",
    "Keszthely",
    "Keszu",
    "Ketegyhaza",
    "Kevermes",
    "Kincsesbanya",
    "Kisar",
    "Kisber",
    "Kisdorog",
    "Kisgyor",
    "Kiskinizs",
    "Kiskoros",
    "Kiskunfelegyhaza",
    "Kiskunhalas",
    "Kiskunlachaza",
    "Kislang",
    "Kismanyok",
    "Kismaros",
    "Kisnemedi",
    "Kispest",
    "Kistapolca",
    "Kistarcsa",
    "Kistelek",
    "Kistokaj",
    "Kistormas",
    "Kisujszallas",
    "Kisvarda",
    "Kisvaszar",
    "Klarafalva",
    "Koka",
    "Kolontar",
    "Komlo",
    "Komlod",
    "Kompolt",
    "Kony",
    "Kophaza",
    "Kormend",
    "Kornye",
    "Korosladany",
    "Koszarhegy",
    "Koszeg",
    "Kotaj",
    "Kotelek",
    "Kubekhaza",
    "Kulcs",
    "Kulsobarand",
    "Kunagota",
    "Kunbaja",
    "Kunhegyes",
    "Kunszentmarton",
    "Kunszentmiklos",
    "Kunsziget",
    "Kurd",
    "Kurityan",
    "Labatlan",
    "Labod",
    "Ladbesenyo",
    "Lajoskomarom",
    "Lajosmizse",
    "Lazi",
    "Leanyfalu",
    "Lebeny",
    "Lenti",
    "Letenye",
    "Levelek",
    "Lovas",
    "Lovaszi",
    "Madocsa",
    "Maglod",
    "Magy",
    "Magyaregres",
    "Magyarhertelend",
    "Magyarnandor",
    "Majoshaza",
    "Makad",
    "Makkoshotyka",
    "Maklar",
    "Mako",
    "Malyi",
    "Mandok",
    "Many",
    "Marcali",
    "Mariapocs",
    "Markaz",
    "Marokpapi",
    "Martely",
    "Martfu",
    "Martonvasar",
    "Mateszalka",
    "Matraballa",
    "Matraszolos",
    "Mecseknadasd",
    "Medgyesegyhaza",
    "Melykut",
    "Mende",
    "Mernye",
    "Mesztegnyo",
    "Mezobereny",
    "Mezofalva",
    "Mezohegyes",
    "Mezokovesd",
    "Mezonyarad",
    "Mezoszilas",
    "Mezotur",
    "Mindszent",
    "Misefa",
    "Miskolc",
    "Mogyorod",
    "Monor",
    "Monorierdo",
    "Mor",
    "Morahalom",
    "Mosonszentmiklos",
    "Mosonszolnok",
    "Mucsony",
    "Nagyatad",
    "Nagybarca",
    "Nagyberki",
    "Nagycenk",
    "Nagycserkesz",
    "Nagydobsza",
    "Nagyhalasz",
    "Nagyhegyes",
    "Nagyigmand",
    "Nagykallo",
    "Nagykanizsa",
    "Nagykapornak",
    "Nagykata",
    "Nagykereki",
    "Nagykeresztur",
    "Nagykoros",
    "Nagykoru",
    "Nagykovacsi",
    "Nagykozar",
    "Nagymagocs",
    "Nagymanyok",
    "Nagynyarad",
    "Nagypali",
    "Nagysap",
    "Nagyszekeres",
    "Nagyszenas",
    "Nagyszokoly",
    "Nagytarcsa",
    "Nagytotfalu",
    "Nagyvazsony",
    "Nagyvenyim",
    "Naszaly",
    "Nemesbod",
    "Nemesbuek",
    "Nemesvid",
    "Nemesvita",
    "Nemetker",
    "Neszmely",
    "Nezsa",
    "Nogradsap",
    "Nogradsipek",
    "Nyekladhaza",
    "Nyergesujfalu",
    "Nyiradony",
    "Nyirbator",
    "Nyiregyhaza",
    "Nyirmartonfalva",
    "Nyirtass",
    "Nyul",
    "Ocsa",
    "Ocseny",
    "Ocsod",
    "Ofeherto",
    "Onga",
    "Orbottyan",
    "Oroshaza",
    "Oroszlany",
    "Ostoros",
    "Otteveny",
    "Ozd",
    "Paka",
    "Pakod",
    "Pakozd",
    "Paks",
    "Palotabozsok",
    "Papa",
    "Part",
    "Paszto",
    "Patroha",
    "Patvarc",
    "Paty",
    "Pazmand",
    "Pecel",
    "Pecol",
    "Pellerd",
    "Per",
    "Perbal",
    "Perenye",
    "Peteri",
    "Petfuerdo",
    "Petofibanya",
    "Petohaza",
    "Pilis",
    "Piliscsaba",
    "Pilismarot",
    "Pilisszanto",
    "Pilisszentivan",
    "Pilisszentkereszt",
    "Pilisvorosvar",
    "Polgar",
    "Polgardi",
    "Pomaz",
    "Posfa",
    "Puspokladany",
    "Pusztaszabolcs",
    "Pusztaszemes",
    "Pusztavacs",
    "Pusztavam",
    "Pusztazamor",
    "Rabapatona",
    "Rabapaty",
    "Racalmas",
    "Rackeresztur",
    "Rackeve",
    "Rad",
    "Rajka",
    "Rakoczifalva",
    "Rakocziujfalu",
    "Repcelak",
    "Repceszemere",
    "Retsag",
    "Revfueloep",
    "Rimoc",
    "Roszke",
    "Rudabanya",
    "Sajokapolna",
    "Sajokeresztur",
    "Sajooeroes",
    "Sajopalfala",
    "Sajoszentpeter",
    "Sajovamos",
    "Salfold",
    "Salgotarjan",
    "Sandorfalva",
    "Sarbogard",
    "Sarisap",
    "Sarkad",
    "Sarmellek",
    "Sarosd",
    "Sarospatak",
    "Sarpentele",
    "Sarpilis",
    "Sarszentagota",
    "Sarszentlorinc",
    "Sarszentmihaly",
    "Sarvar",
    "Sasd",
    "Saska",
    "Se",
    "Selyeb",
    "Senyo",
    "Seregelyes",
    "Siklos",
    "Simontornya",
    "Sirok",
    "Solt",
    "Soltszentimre",
    "Solymar",
    "Somogysard",
    "Somogyudvarhely",
    "Sopron",
    "Sopronkovesd",
    "Sored",
    "Soskut",
    "Suelysap",
    "Sukoro",
    "Sumeg",
    "Szabadbattyan",
    "Szabadszallas",
    "Szabadszentkiraly",
    "Szabolcs",
    "Szabolcsveresmart",
    "Szajk",
    "Szajol",
    "Szalaszend",
    "Szany",
    "Szar",
    "Szarvas",
    "Szazhalombatta",
    "Szecseny",
    "Szecsenyfelfalu",
    "Szeged",
    "Szeghalom",
    "Szegvar",
    "Szemely",
    "Szendehely",
    "Szentendre",
    "Szentes",
    "Szentgotthard",
    "Szentistvan",
    "Szentkatalin",
    "Szentkiralyszabadja",
    "Szentlorinc",
    "Szentmartonkata",
    "Szepetnek",
    "Szeremle",
    "Szerencs",
    "Szigetcsep",
    "Szigethalom",
    "Szigetszentmarton",
    "Szigetszentmiklos",
    "Szigetvar",
    "Szigliget",
    "Szikszo",
    "Szilsarkany",
    "Szirmabesenyo",
    "Szod",
    "Szodliget",
    "Szogliget",
    "Szokolya",
    "Szolnok",
    "Szombathely",
    "Szomod",
    "Szomor",
    "Szuhakallo",
    "Tab",
    "Tabajd",
    "Taborfalva",
    "Taksony",
    "Taktaszada",
    "Tamasi",
    "Tapiobicske",
    "Tapiogyorgye",
    "Tapiosag",
    "Tapioszecso",
    "Tapioszele",
    "Taplanszentkereszt",
    "Tapolca",
    "Tar",
    "Tarcal",
    "Tarjan",
    "Tarnok",
    "Tarpa",
    "Tass",
    "Taszar",
    "Tat",
    "Tata",
    "Teglas",
    "Telekes",
    "Telep",
    "Telki",
    "Tepe",
    "Terem",
    "Tet",
    "Tetetlen",
    "Tevel",
    "Tinnye",
    "Tiszaadony",
    "Tiszabo",
    "Tiszacsege",
    "Tiszafoldvar",
    "Tiszafured",
    "Tiszalok",
    "Tiszaluc",
    "Tiszapalkonya",
    "Tiszaszalka",
    "Tiszaujvaros",
    "Tiszavalk",
    "Tiszavasvari",
    "Toekoel",
    "Toeroekbalint",
    "Tokodaltaro",
    "Tolna",
    "Tomorkeny",
    "Tompa",
    "Tordas",
    "Torokszentmiklos",
    "Torony",
    "Toszeg",
    "Totkomlos",
    "Totszerdahely",
    "Tura",
    "Turkeve",
    "Ujfeherto",
    "Ujkenez",
    "Ujkigyos",
    "Ujlengyel",
    "Ujszasz",
    "Ujszentmargita",
    "Ujszilvas",
    "Ujudvar",
    "Ullo",
    "Uraiujfalu",
    "Uri",
    "Urom",
    "Vacduka",
    "Vacratot",
    "Vacszentlaszlo",
    "Vadna",
    "Vaja",
    "Vajszlo",
    "Valko",
    "Valkonya",
    "Vamospercs",
    "Vamosszabadi",
    "Varalja",
    "Varbalog",
    "Varbo",
    "Vardomb",
    "Varosfoeld",
    "Varpalota",
    "Vasarosdombo",
    "Vasvar",
    "Vecses",
    "Vegegyhaza",
    "Velence",
    "Vep",
    "Veresegyhaz",
    "Veroce",
    "Verseg",
    "Vertessomlo",
    "Vertesszolos",
    "Vertestolna",
    "Vezseny",
    "Visznek",
    "Vitnyed",
    "Vizslas",
    "Zagyvaszanto",
    "Zahony",
    "Zalaegerszeg",
    "Zalakomar",
    "Zalalovo",
    "Zalaszentgrot",
    "Zamoly",
    "Zebecke",
    "Zebegeny",
    "Zichyujfalu",
    "Zirc",
    "Zsambek",
    "Zsambok",
    "Zsombo",
  ],
  Iceland: [
    "Akranes",
    "Akureyri",
    "Borgarnes",
    "Dalvik",
    "Grindavik",
    "Hella",
    "Holmavik",
    "Husavik",
    "Hvammstangi",
    "Hveragerdi",
    "Hvolsvollur",
    "Kopavogur",
    "Reykjavik",
    "Selfoss",
    "Skagastrond",
    "Stokkseyri",
    "Vestmannaeyjar",
    "Vogar",
  ],
  India: [
    "Abdul",
    "Adilabad",
    "Adwani",
    "Agartala",
    "Agra",
    "Ahmedabad",
    "Ahmednagar",
    "Aizawl",
    "Ajabpur",
    "Ajmer",
    "Akividu",
    "Akola",
    "Alanallur",
    "Alangulam",
    "Alappuzha",
    "Aldona",
    "Alibag",
    "Aligarh",
    "Alipur",
    "Alipur",
    "Allahabad",
    "Almora",
    "Aluva",
    "Alwar",
    "Amal",
    "Amalapuram",
    "Ambad",
    "Ambah",
    "Ambala",
    "Ambarnath",
    "Ambejogai",
    "Ambikapur",
    "Ambur",
    "Amer",
    "Amet",
    "Amravati",
    "Amreli",
    "Amritsar",
    "Anand",
    "Anantapur",
    "Anantnag",
    "Anantpur",
    "Anchal",
    "Andheri",
    "Andra",
    "Angadipuram",
    "Angul",
    "Ankleshwar",
    "Annamalainagar",
    "Antapur",
    "Arakkonam",
    "Arani",
    "Aranmula",
    "Arch",
    "Ariyalur",
    "Arora",
    "Arpora",
    "Arunachal",
    "Arvi",
    "Asansol",
    "Assagao",
    "Attingal",
    "Attur",
    "Aundh",
    "Aurangabad",
    "Aurangabad",
    "Avanigadda",
    "Azamgarh",
    "Baddi",
    "Badlapur",
    "Bagalkot",
    "Bagh",
    "Bagpat",
    "Bahadurgarh",
    "Baharampur",
    "Baidyabati",
    "Bala",
    "Balaghat",
    "Balana",
    "Balanagar",
    "Balangir",
    "Balasore",
    "Bali",
    "Bali",
    "Ballabgarh",
    "Balu",
    "Balurghat",
    "Bambolim",
    "Banda",
    "Bandra",
    "Banga",
    "Bangalore",
    "Bangaon",
    "Bank",
    "Banka",
    "Bankura",
    "Banswara",
    "Bapatla",
    "Barakpur",
    "Baramati",
    "Barddhaman",
    "Bardoli",
    "Bareilly",
    "Bargarh",
    "Barmer",
    "Barnala",
    "Baroda",
    "Barpali",
    "Barpeta",
    "Basirhat",
    "Basti",
    "Basu",
    "Batala",
    "Bawan",
    "Bawana",
    "Beawar",
    "Begusarai",
    "Behala",
    "Bela",
    "Belapur",
    "Belgaum",
    "Belgharia",
    "Bellare",
    "Bellary",
    "Bemetara",
    "Berasia",
    "Betalbatim",
    "Betim",
    "Betul",
    "Bhadath",
    "Bhadohi",
    "Bhadravati",
    "Bhagalpur",
    "Bhagwan",
    "Bhandari",
    "Bhandup",
    "Bharatpur",
    "Bharuch",
    "Bhatapara",
    "Bhatinda",
    "Bhatkal",
    "Bhavnagar",
    "Bhawan",
    "Bhilai",
    "Bhilwara",
    "Bhimavaram",
    "Bhiwandi",
    "Bhiwani",
    "Bhoj",
    "Bhongir",
    "Bhopal",
    "Bhubaneswar",
    "Bhuj",
    "Bhusawal",
    "Bichpuri",
    "Bidar",
    "Bihar Sharif",
    "Bijapur",
    "Bikaner",
    "Bilaspur",
    "Bilaspur",
    "Bilimora",
    "Binavas",
    "Binnaguri",
    "Bishnupur",
    "Bobbili",
    "Bodhan",
    "Bodinayakkanur",
    "Boisar",
    "Bokaro",
    "Bolpur",
    "Botad",
    "Brahmapur",
    "Budaun",
    "Budbud",
    "Budha",
    "Bulandshahr",
    "Bundi",
    "Calangute",
    "Candolim",
    "Canning",
    "Caranzalem",
    "Chakan",
    "Chakra",
    "Chalisgaon",
    "Chamba",
    "Champa",
    "Chand",
    "Chandan",
    "Chandannagar",
    "Chandauli",
    "Chandausi",
    "Chandigarh",
    "Chandrapur",
    "Changanacheri",
    "Channapatna",
    "Charan",
    "Charu",
    "Chen",
    "Chengannur",
    "Chennai",
    "Chetan",
    "Cheyyar",
    "Chhabra",
    "Chhachhrauli",
    "Chhota Udepur",
    "Chicalim",
    "Chidambaram",
    "Chikodi",
    "Chinchvad",
    "Chintamani",
    "Chiplun",
    "Chirala",
    "Chitra",
    "Chitradurga",
    "Chittoor",
    "Chittur",
    "Choolai",
    "Chopda",
    "Chopra",
    "Churachandpur",
    "Coimbatore",
    "Colaba",
    "Connaught Place",
    "Coonoor",
    "Cuddalore",
    "Cumbum",
    "Cuncolim",
    "Curchorem",
    "Cuttack",
    "Dadri",
    "Dahanu",
    "Dahod",
    "Dam Dam",
    "Daman",
    "Damoh",
    "Dang",
    "Dangi",
    "Darbhanga",
    "Darjeeling",
    "Darsi",
    "Dasna",
    "Dasua",
    "Davangere",
    "Dehradun",
    "Delhi",
    "Deolali",
    "Deoria",
    "Devgarh",
    "Devipattinam",
    "Dewas",
    "Dhaka",
    "Dhamtari",
    "Dhanbad",
    "Dhansura",
    "Dhar",
    "Dharamsala",
    "Dharapuram",
    "Dharavi",
    "Dhariwal",
    "Dharmapuri",
    "Dharwad",
    "Dhenkanal",
    "Dhone",
    "Dhrol",
    "Dhubri",
    "Dhule",
    "Dhuri",
    "Dibrugarh",
    "Dicholi",
    "Dimapur",
    "Dinanagar",
    "Dindigul",
    "Dindori",
    "Dipas",
    "Dogadda",
    "Dona Paula",
    "Dumka",
    "Durg",
    "Durgapur",
    "Dwarahat",
    "Dwarka",
    "Edavanna",
    "Ekkattuthangal",
    "Ellora Caves",
    "Eluru",
    "Eral",
    "Ernakulam",
    "Erode",
    "Etawah",
    "Faizabad",
    "Farakka",
    "Faridabad",
    "Faridkot",
    "Fatehabad",
    "Fatehgarh",
    "Fatehpur",
    "Firozabad",
    "Firozpur",
    "Fort",
    "Gadag",
    "Gampalagudem",
    "Gandhidham",
    "Gandhigram",
    "Gandhinagar",
    "Ganga",
    "Ganganagar",
    "Gangapur",
    "Gangrar",
    "Gangtok",
    "Gannavaram",
    "Ganpat",
    "Gargoti",
    "Garhshankar",
    "Gaya",
    "Ghana",
    "Ghatal",
    "Ghatkopar",
    "Ghaziabad",
    "Goa",
    "Gobichettipalayam",
    "Godhra",
    "Gohana",
    "Golaghat",
    "Gold",
    "Gonda",
    "Gorakhpur",
    "Goregaon",
    "Goshaingaon",
    "Gudivada",
    "Gudur",
    "Guindy",
    "Gujrat",
    "Gulbarga",
    "Guna",
    "Guntur",
    "Gurdaspur",
    "Gurgaon",
    "Guruvayur",
    "Guwahati",
    "Gwalior",
    "Habra",
    "Hadadi",
    "Haldia",
    "Haldwani",
    "Hamirpur",
    "Hamirpur",
    "Hansi",
    "Hapur",
    "Hari",
    "Haridwar",
    "Haripad",
    "Haripur",
    "Haryana",
    "Hassan",
    "Haveri",
    "Hazaribagh",
    "Himatnagar",
    "Hinganghat",
    "Hingoli",
    "Hira",
    "Hiriyur",
    "Hisar",
    "Honavar",
    "Hong",
    "Hoshangabad",
    "Hoshiarpur",
    "Hosur",
    "Howrah",
    "Hubli",
    "Hugli",
    "Hyderabad",
    "Ichalkaranji",
    "Idukki",
    "Igatpuri",
    "Iglas",
    "Imphal",
    "Indore",
    "Indraprast",
    "Irinjalakuda",
    "Itanagar",
    "Jabalpur",
    "Jadabpur",
    "Jagdalpur",
    "Jagraon",
    "Jaipur",
    "Jaisalmer",
    "Jajpur",
    "Jalalabad",
    "Jalalpur",
    "Jalandhar",
    "Jalesar",
    "Jalgaon Jamod",
    "Jalna",
    "Jalpaiguri",
    "Jamal",
    "Jammu",
    "Jamnagar",
    "Jamshedpur",
    "Janjgir",
    "Jaspur",
    "Jatani",
    "Jaunpur",
    "Jayanti",
    "Jaynagar",
    "Jaypur",
    "Jha Jha",
    "Jhajjar",
    "Jhalawar",
    "Jhansi",
    "Jhargram",
    "Jharsuguda",
    "Jhunjhunun",
    "Jind",
    "Jodhpur",
    "Jorhat",
    "Junagadh",
    "Kadapa",
    "Kagal",
    "Kailaras",
    "Kaimganj",
    "Kaithal",
    "Kakdwip",
    "Kakinada",
    "Kaladi",
    "Kalam",
    "Kalamboli",
    "Kalan",
    "Kalinga",
    "Kalka",
    "Kalkaji Devi",
    "Kalol",
    "Kalpakkam",
    "Kalpetta",
    "Kalra",
    "Kalyan",
    "Kalyani",
    "Kamalpur",
    "Kamalpura",
    "Kamat",
    "Kanakpura",
    "Kanchipuram",
    "Kanchrapara",
    "Kandi",
    "Kangayam",
    "Kangra",
    "Kanhangad",
    "Kanigiri",
    "Kaniyambadi",
    "Kankauli",
    "Kanniyakumari",
    "Kannur",
    "Kanpur",
    "Kapurthala Town",
    "Karad",
    "Karaikal",
    "Karaikudi",
    "Karamadai",
    "Karamsad",
    "Karanja",
    "Karari",
    "Kargil",
    "Karimganj",
    "Karimnagar",
    "Karjat",
    "Karnal",
    "Karsiyang",
    "Karur",
    "Karwar",
    "Kasal",
    "Kasaragod",
    "Kasganj",
    "Kashipur",
    "Kasia",
    "Kataria",
    "Kathua",
    "Katni",
    "Katoya",
    "Katra",
    "Kaul",
    "Kavali",
    "Kavaratti",
    "Kayamkulam",
    "Keshod",
    "Khajuraho Group of Monuments",
    "Khalapur",
    "Khambhat",
    "Khammam",
    "Khan",
    "Khanna",
    "Kharagpur",
    "Kharar",
    "Khargone",
    "Khatauli",
    "Kheda",
    "Khergam",
    "Kheri",
    "Khinwara",
    "Khopoli",
    "Khurda",
    "Khurja",
    "Kishangarh",
    "Koch Bihar",
    "Kochi",
    "Kodaikanal",
    "Kodungallur",
    "Kohima",
    "Kokrajhar",
    "Kolar",
    "Kolayat",
    "Kolhapur",
    "Kolkata",
    "Kollam",
    "Kollegal",
    "Koni",
    "Koni",
    "Konnagar",
    "Koothanallur",
    "Koppal",
    "Koraput",
    "Korba",
    "Kosamba",
    "Kot Isa Khan",
    "Kota",
    "Kotian",
    "Kottagudem",
    "Kottakkal",
    "Kottarakara",
    "Kottayam",
    "Kovilpatti",
    "Kovvur",
    "Kozhikode",
    "Krishnagiri",
    "Kulti",
    "Kumar",
    "Kumbakonam",
    "Kumhari",
    "Kundan",
    "Kunwar",
    "Kuppam",
    "Kurali",
    "Kurnool",
    "Kushalnagar",
    "Kuzhithurai",
    "Ladwa",
    "Lakhimpur",
    "Lala",
    "Lalgudi",
    "Lamba Harisingh",
    "Lanka",
    "Latur",
    "Liluah",
    "Lohaghat",
    "Lucknow",
    "Ludhiana",
    "Machhiwara",
    "Machilipatnam",
    "Madanapalle",
    "Madgaon",
    "Madhoganj",
    "Madikeri",
    "Madurai",
    "Madurantakam",
    "Mahabalipuram",
    "Mahad",
    "Mahajan",
    "Mahal",
    "Maharaj",
    "Mahatma",
    "Mahesana",
    "Mahesh",
    "Mahim",
    "Mahulia",
    "Malappuram",
    "Maldah",
    "Malpur",
    "Manali",
    "Mancherial",
    "Mandal",
    "Mandapeta",
    "Mandi",
    "Mandla",
    "Mandsaur",
    "Mandvi",
    "Mandya",
    "Mangalagiri",
    "Mangalore",
    "Mangaon",
    "Manipala",
    "Manipur",
    "Manjeri",
    "Manna",
    "Mannargudi",
    "Manor",
    "Mansa",
    "Manu",
    "Markal",
    "Markapur",
    "Marmagao",
    "Maru",
    "Mashobra",
    "Matar",
    "Mathan",
    "Mathura",
    "Mattanur",
    "Mavelikara",
    "Mawana",
    "Mayapur",
    "Medak",
    "Medarametla",
    "Medchal",
    "Medinipur",
    "Meerut",
    "Mehra",
    "Mettur",
    "Mhow",
    "Mill",
    "Miraj",
    "Mirza Murad",
    "Mirzapur",
    "Mithapur",
    "Modasa",
    "Moga",
    "Mohala",
    "Mohali",
    "Mohan",
    "Moradabad",
    "Morena",
    "Morinda",
    "Morvi",
    "Motihari",
    "Mount Abu",
    "Muddanuru",
    "Mukerian",
    "Muktsar",
    "Multi",
    "Mumbai",
    "Mundgod",
    "Mundra",
    "Munger",
    "Murshidabad",
    "Mussoorie",
    "Muzaffarnagar",
    "Muzaffarpur",
    "Mylapore",
    "Mysore",
    "Nabadwip",
    "Nabha",
    "Nadgaon",
    "Nadia",
    "Nadiad",
    "Nagal",
    "Nagapattinam",
    "Nagar",
    "Nagara",
    "Nagari",
    "Nagaur",
    "Nagercoil",
    "Nagpur",
    "Nagwa",
    "Naini",
    "Nalagarh",
    "Nalbari",
    "Nalgonda",
    "Namakkal",
    "Namrup",
    "Nanda",
    "Nanded",
    "Nandi",
    "Nandigama",
    "Nandurbar",
    "Nandyal",
    "Naraina",
    "Narasaraopet",
    "Narayangaon",
    "Narela",
    "Narnaul",
    "Narsapur",
    "Nashik",
    "Nathdwara",
    "Navelim",
    "Navsari",
    "Nayagarh",
    "Nazira",
    "Nehra",
    "Nellore",
    "Neral",
    "Neri",
    "New Delhi",
    "Neyveli",
    "Nila",
    "Nilambur",
    "Nilokheri",
    "Nizamabad",
    "Noida",
    "Nongpoh",
    "Nongstoin",
    "North Lakhimpur",
    "Nurpur",
    "Nuzvid",
    "Odhan",
    "Omalur",
    "Ongole",
    "Ooty",
    "Orai",
    "Osmanabad",
    "Ottappalam",
    "Pachmarhi",
    "Padrauna",
    "Pahalgam",
    "Pakala",
    "Pala",
    "Palakkad",
    "Palampur",
    "Palani",
    "Palayam",
    "Palghar",
    "Pali",
    "Palladam",
    "Paloncha",
    "Palus",
    "Palwal",
    "Panchal",
    "Panchgani",
    "Pandharpur",
    "Panipat",
    "Panjim",
    "Panruti",
    "Pantnagar",
    "Panvel",
    "Paonta Sahib",
    "Parappanangadi",
    "Paravur",
    "Parbhani",
    "Parel",
    "Parra",
    "Patan",
    "Patancheru",
    "Patel",
    "Patelguda",
    "Pathanamthitta",
    "Pathankot",
    "Patiala",
    "Patna",
    "Pattambi",
    "Pattukkottai",
    "Pauri",
    "Payyanur",
    "Peddapuram",
    "Pehowa",
    "Perambalur",
    "Peranampattu",
    "Perundurai",
    "Petlad",
    "Phagwara",
    "Phaphamau",
    "Piduguralla",
    "Pilani",
    "Pileru",
    "Pilkhuwa",
    "Pimpri",
    "Pitampura",
    "Pithapuram",
    "Pithoragarh",
    "Pochampalli",
    "Pollachi",
    "Ponda",
    "Ponnani",
    "Ponneri",
    "Porbandar",
    "Port Blair",
    "Potti",
    "Powai",
    "Proddatur",
    "Puducherry",
    "Pudukkottai",
    "Puliyur",
    "Punalur",
    "Pune",
    "Puras",
    "Puri",
    "Purnea",
    "Puruliya",
    "Pusa",
    "Pushkar",
    "Puttur",
    "Puttur",
    "Quepem",
    "Raichur",
    "Raigarh",
    "Raipur",
    "Raipur",
    "Rajahmundry",
    "Rajapalaiyam",
    "Rajapur",
    "Rajkot",
    "Rajpur",
    "Rajpura",
    "Raju",
    "Rama",
    "Ramanagaram",
    "Ramanathapuram",
    "Ramapuram",
    "Ramavaram",
    "Ramgarh",
    "Ramnagar",
    "Rampur",
    "Rana",
    "Ranaghat",
    "Ranchi",
    "Rander",
    "Raniganj",
    "Ranippettai",
    "Ranjan",
    "Ratlam",
    "Ratnagiri",
    "Raurkela",
    "Rawal",
    "Raxaul",
    "Rayagada",
    "Rewa",
    "Rewari",
    "Ring",
    "Rishikesh",
    "Rohtak",
    "Roorkee",
    "Roshan",
    "Rudrapur",
    "Rupnagar",
    "Rupnarayanpur",
    "Sachin",
    "Sagar",
    "Sagar",
    "Saha",
    "Saharanpur",
    "Sahibabad",
    "Sakri",
    "Sakri",
    "Salem",
    "Saligao",
    "Salt Lake City",
    "Samastipur",
    "Sambalpur",
    "Sanand",
    "Sandur",
    "Sangam",
    "Sangamner",
    "Sangli",
    "Sangola",
    "Sangrur",
    "Sanquelim",
    "Saranga",
    "Sarangi",
    "Sarwar",
    "Satara",
    "Satna",
    "Sattur",
    "Sawi",
    "Secunderabad",
    "Sehore",
    "Sendhwa",
    "Serampore",
    "Shadnagar",
    "Shahabad",
    "Shahapur",
    "Shahdara",
    "Shahdol",
    "Shahjahanpur",
    "Shahkot",
    "Shamsabad",
    "Shanti Grama",
    "Shillong",
    "Shimla",
    "Shimoga",
    "Shirgaon",
    "Shiv",
    "Sholavandan",
    "Shoranur",
    "Shrigonda",
    "Shyamnagar",
    "Sibsagar",
    "Sidhi",
    "Sidhpur",
    "Sikar",
    "Sikka",
    "Silchar",
    "Siliguri",
    "Silvassa",
    "Singarayakonda",
    "Singtam",
    "Sinnar",
    "Sion",
    "Sirhind",
    "Sirkazhi",
    "Sirohi",
    "Sirsa",
    "Sirsi",
    "Siruguppa",
    "Siruseri",
    "Sirwani",
    "Sitapur",
    "Siuri",
    "Sivaganga",
    "Sivakasi",
    "Sodhi",
    "Sojat",
    "Solan",
    "Solapur",
    "Solim",
    "Somnath",
    "Soni",
    "Sonipat",
    "Sopara",
    "Srikakulam",
    "Srikalahasti",
    "Srinagar",
    "Sriperumbudur",
    "Srirangam",
    "Srivilliputhur",
    "Sukma",
    "Sultan",
    "Sultanpur",
    "Sultans Battery",
    "Suman",
    "Sunam",
    "Sundargarh",
    "Surana",
    "Suratgarh",
    "Surendranagar",
    "Suriapet",
    "Tadepallegudem",
    "Tala",
    "Talcher",
    "Talegaon Dabhade",
    "Talwandi Sabo",
    "Tambaram",
    "Tanda",
    "Tanuku",
    "Tarn Taran",
    "Teri",
    "Tezpur",
    "Thalassery",
    "Thane",
    "Thanjavur",
    "Thasra",
    "Thenali",
    "Thenkasi",
    "Thirumangalam",
    "Thiruthani",
    "Thiruvananthapuram",
    "Thiruvarur",
    "Thoothukudi",
    "Thrissur",
    "Tikamgarh",
    "Tindivanam",
    "Tinsukia",
    "Tiptur",
    "Tiruchchendur",
    "Tiruchi",
    "Tirumala",
    "Tirumala - Tirupati",
    "Tirunelveli",
    "Tiruppur",
    "Tirur",
    "Tiruvalla",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tohana",
    "Tonk",
    "Trimbak",
    "Tuljapur",
    "Turaiyur",
    "Udaigiri",
    "Udaipur",
    "Udupi",
    "Ujjain",
    "Ulhasnagar",
    "Ulubari",
    "Umred",
    "Unnao",
    "Uppal",
    "Uttarkashi",
    "Vadamadurai",
    "Vadner",
    "Vadodara",
    "Vaikam",
    "Vainguinim",
    "Valsad",
    "Vandalur",
    "Vandavasi",
    "Vaniyambadi",
    "Vapi",
    "Varanasi",
    "Vasai",
    "Vasco",
    "Vashi",
    "Vazhakulam",
    "Vellore",
    "Verna",
    "Vidisha",
    "Vijapur",
    "Vijayawada",
    "Vikarabad",
    "Vikasnagar",
    "Villupuram",
    "Vinukonda",
    "Virar",
    "Visakhapatnam",
    "Visnagar",
    "Vizianagaram",
    "Wai",
    "Warangal",
    "Wardha",
    "Wellington",
    "Yadgir",
    "Yamunanagar",
    "Yanam",
    "Yavatmal",
    "Yeola",
    "Yercaud",
  ],
  Indonesia: [
    "Abadi",
    "Adiantorop",
    "Airmadidi",
    "Ambarawa",
    "Ambon City",
    "Amlapura",
    "Anggrek",
    "Angkasa",
    "Area",
    "Badung",
    "Badung",
    "Bali",
    "Balige",
    "Balikpapan",
    "Banda Aceh",
    "Bandar",
    "Bandar Lampung",
    "Bandung",
    "Bangkalan",
    "Bangkinang",
    "Bangli",
    "Banjar",
    "Banjarbaru",
    "Banjarmasin",
    "Banjarnegara",
    "Bantaeng",
    "Bantan",
    "Bantul",
    "Banyumas",
    "Banyuwangi",
    "Baratjaya",
    "Batang",
    "Batu",
    "Baturaja",
    "Baubau",
    "Bekasi",
    "Bengkalis",
    "Bengkulu",
    "Bima",
    "Binjai",
    "Bitung",
    "Blitar",
    "Blora",
    "Bogor",
    "Bojonegoro",
    "Bondowoso",
    "Bontang",
    "Boyolali",
    "Bukit Tinggi",
    "Bulukumba",
    "Buntok",
    "Cakrawala",
    "Cempaka",
    "Cengkareng",
    "Ciamis",
    "Cianjur",
    "Cibitung",
    "Cibubur",
    "Cihampelas",
    "Cikarang",
    "Cikini",
    "Cilacap",
    "Cilegon",
    "Cilegon",
    "Cilincing",
    "Cimahi",
    "Cimanggis",
    "Cipinanglatihan",
    "Ciputat",
    "Cirebon",
    "Citeureup",
    "Darmaga",
    "Darussalam",
    "Demak",
    "Denpasar",
    "Depok",
    "Depok Jaya",
    "Dumai",
    "Duren",
    "Duri",
    "Gandul",
    "Garut",
    "Gedung",
    "Gianyar",
    "Gorontalo",
    "Gresik",
    "Guntung",
    "Gunungsitoli",
    "Holis",
    "Indo",
    "Indramayu",
    "Jagakarsa",
    "Jakarta",
    "Jambi City",
    "Jayapura",
    "Jember",
    "Jepara",
    "Jimbaran",
    "Jombang",
    "Kabanjahe",
    "Kalideres",
    "Kalimantan",
    "Kandangan",
    "Karanganyar",
    "Karawang",
    "Kartasura",
    "Kebayoran Baru",
    "Kebayoran Lama Selatan",
    "Kebumen",
    "Kediri",
    "Kelapa Dua",
    "Kemang",
    "Kendal",
    "Kepanjen",
    "Kerinci",
    "Kerobokan",
    "Klaten",
    "Kopeng",
    "Kota",
    "Kudus",
    "Kulon",
    "Kuningan",
    "Kupang",
    "Kuta",
    "Lamongan",
    "Lampeong",
    "Langsa",
    "Lapan",
    "Lawang",
    "Lestari",
    "Lhokseumawe",
    "Lubuk Pakam",
    "Lumajang",
    "Madiun",
    "Magelang",
    "Magetan",
    "Mail",
    "Majalengka",
    "Makassar",
    "Malang",
    "Mamuju",
    "Manado",
    "Maros",
    "Mataram",
    "Medan",
    "Mega",
    "Menara",
    "Menteng",
    "Mojoagung",
    "Mojokerto",
    "Muntilan",
    "Negara",
    "Negeribesar",
    "Nganjuk",
    "Ngawi",
    "Nusa",
    "Nusa Dua",
    "Nusantara",
    "Pacitan",
    "Padang",
    "Palaihari",
    "Palangka",
    "Palangkaraya",
    "Palembang",
    "Palu",
    "Pamekasan",
    "Pamulang",
    "Panasuan",
    "Pandeglang",
    "Pangaturan",
    "Parakan",
    "Pare",
    "Parman",
    "Pasuruan",
    "Patam",
    "Pati",
    "Payakumbuh",
    "Pekalongan",
    "Pekan",
    "Pekanbaru",
    "Pemalang",
    "Pematangsiantar",
    "Polerejo",
    "Pondok",
    "Ponorogo",
    "Pontianak",
    "Porsea",
    "Poso",
    "Probolinggo",
    "Purbalingga",
    "Purwakarta",
    "Purwodadi Grobogan",
    "Purwokerto",
    "Purworejo",
    "Pusat",
    "Riau",
    "Salatiga",
    "Samarinda",
    "Sampang",
    "Sampit",
    "Sangereng",
    "Sanur",
    "Sejahtera",
    "Sekupang",
    "Selatan",
    "Selong",
    "Semarang",
    "Sengkang",
    "Sentul",
    "Serang",
    "Serang",
    "Serdang",
    "Serpong",
    "Sidoarjo",
    "Sigli",
    "Sijunjung",
    "Simpang",
    "Singaraja",
    "Singkawang",
    "Situbondo",
    "Sleman",
    "Soasio",
    "Soe",
    "Soho",
    "Solo",
    "Sragen",
    "Stabat",
    "Subang",
    "Sukabumi",
    "Sukoharjo",
    "Sumedang",
    "Sungailiat",
    "Sunggal",
    "Sungguminasa",
    "Surabaya",
    "Surabayan",
    "Surakarta",
    "Tabanan",
    "Tangsel",
    "Tanjung",
    "Tanjung",
    "Tanjung Balai",
    "Tanjungpinang",
    "Tarakan",
    "Tasikmalaya",
    "Tebingtinggi",
    "Tegal",
    "Temanggung",
    "Tembagapura",
    "Tengah",
    "Tenggara",
    "Tenggarong",
    "Tigaraksa",
    "Tigarasa",
    "Timur",
    "Tipar Timur",
    "Tirtagangga",
    "Tomohon",
    "Tondano",
    "Trenggalek",
    "Tuban",
    "Tulungagung",
    "Ubud",
    "Udayana",
    "Ungaran",
    "Utama",
    "Utara",
    "Veteran",
    "Wilayah",
    "Wonogiri",
    "Wonosari",
    "Wonosobo",
    "Yogyakarta",
  ],
  Iran: [
    "`Aliabad",
    "`Aliabad-e Aq Hesar",
    "`Oryan",
    "Abadan",
    "Abol",
    "Ahvaz",
    "Amlash",
    "Amol",
    "Arak",
    "Ardabil",
    "Ardakan",
    "Arnan",
    "Arsanjan",
    "Asadabad",
    "Ashan",
    "Ashna",
    "Ashtian",
    "Astara",
    "Azad",
    "Azadi",
    "Azaran",
    "Babol",
    "Badr",
    "Baft",
    "Baghin",
    "Baharan",
    "Bakhtiari",
    "Bakhtiaruyeh",
    "Baladeh",
    "Banak",
    "Band",
    "Bandar-e Khamir",
    "Bandar-e Mahshahr",
    "Barbun",
    "Bardsir",
    "Baseri Hadi",
    "Bastak",
    "Behbahan",
    "Behdasht",
    "Behjan",
    "Behshahr",
    "Bidak",
    "Birizg",
    "Birjand",
    "Bonab",
    "Borran-e Bala",
    "Bostan",
    "Bukan",
    "Chabahar",
    "Chah Deraz",
    "Chapar",
    "Dadeh",
    "Damavand",
    "Damghan",
    "Darmian",
    "Dezful",
    "Dorud",
    "Emam",
    "Esfarayen",
    "Eslamshahr",
    "Estahban",
    "Evin",
    "Falavarjan",
    "Fardis",
    "Farsi",
    "Fasa",
    "Fordu",
    "Garmsar",
    "Gazan-e Bala",
    "Gerash",
    "Germi",
    "Gharbi",
    "Gilan-e Gharb",
    "Gilas",
    "Golestan",
    "Golpayegan",
    "Golriz",
    "Gorgan",
    "Gostar",
    "Hadishahr",
    "Hamadan",
    "Hasanabad-e Qadamgah",
    "Hashtgerd",
    "Hashtpar",
    "Hirad",
    "Homa",
    "Hormozabad",
    "Hush",
    "Idah",
    "Ilam",
    "Iranshahr",
    "Isfahan",
    "Jadir",
    "Jahan",
    "Jahan Nama",
    "Jahrom",
    "Jiroft",
    "Julfa",
    "Kabir",
    "Kaman",
    "Karaj",
    "Karimkhan",
    "Kashan",
    "Kelarabad",
    "Kerman",
    "Kermanshah",
    "Khalkhal",
    "Khameneh",
    "Khash",
    "Khomeyn",
    "Khomeyni Shahr",
    "Khong",
    "Khorasan",
    "Khowrasgan",
    "Khowrshid",
    "Khvajeh",
    "Khvoy",
    "Kian",
    "Kish",
    "Kord Kandi",
    "Kordestan-e `Olya",
    "Kordovan-e Sofla",
    "Lahijan",
    "Lamerd",
    "Mahabad",
    "Mahallat",
    "Mahestan",
    "Malayer",
    "Malek Talesh",
    "Malekan",
    "Mamasani",
    "Manjil",
    "Maragheh",
    "Marand",
    "Mashhad",
    "Mehin",
    "Mehregan",
    "Meshgin Shahr",
    "Meybod",
    "Miandoab",
    "Mianej",
    "Mobarakeh",
    "Moghan",
    "Mohr",
    "Nahavand",
    "Najafabad",
    "Naji",
    "Nasir",
    "Negar",
    "Nehbandan",
    "Neka",
    "Neyriz",
    "Neyshabur",
    "Novin",
    "Onar",
    "Parpis",
    "Parsian",
    "Pasargad",
    "Payam",
    "Peshgoman",
    "Peyk",
    "Piruz",
    "Post",
    "Qasr od Dasht",
    "Qazvin",
    "Qeshm",
    "Quchan",
    "Rabor",
    "Rafsanjan",
    "Rasak",
    "Rasht",
    "Ravand",
    "Ravansar",
    "Ravar",
    "Rayen",
    "Razan",
    "Razaviyeh",
    "Razi",
    "Rigan",
    "Sabz",
    "Sabzevar",
    "Sahand",
    "Salmas",
    "Saman",
    "Samen",
    "Sanandaj",
    "Saravan",
    "Sari",
    "Saveh",
    "Semirom",
    "Semnan",
    "Sena",
    "Servan",
    "Shadman",
    "Shahid",
    "Shahid Chamran",
    "Shahid Madani",
    "Shahr",
    "Shahrak",
    "Shahrak-e Direh",
    "Shahrak-e Milad",
    "Shahrak-e Pars",
    "Shahreza",
    "Shahriar",
    "Shahrud",
    "Sharif",
    "Shazand",
    "Shiraz",
    "Shirvan",
    "Siahkal",
    "Sirjan",
    "Sistan-e `Olya",
    "Soltanabad",
    "Tabriz",
    "Taha",
    "Takestan",
    "Tiran",
    "Tonekabon",
    "Torkan",
    "Urmia",
    "Vaj",
    "Valilu",
    "Vandar",
    "Varamin",
    "Wahna",
    "Yasuj",
    "Yazd",
    "Zabol",
    "Zahedan",
    "Zahra",
    "Zangiabad",
    "Zanjan",
    "Zarand",
  ],
  Iraq: [
    "Al `Amarah",
    "Al Hillah",
    "Baghdad",
    "Bahr",
    "Basere",
    "Basra",
    "Erbil",
    "Haji Hasan",
    "Hayat",
    "Karkh",
    "Kirkuk",
    "Manawi",
    "Mosul",
    "Najaf",
    "Sulaymaniyah",
    "Tikrit",
  ],
  Ireland: [
    "Abbeyfeale",
    "Abbeyleix",
    "Ardee",
    "Arklow",
    "Artane",
    "Ashbourne",
    "Athboy",
    "Athenry",
    "Athlone",
    "Athy",
    "Bagenalstown",
    "Bailieborough",
    "Balbriggan",
    "Baldoyle",
    "Balgriffin",
    "Ballina",
    "Ballinadee",
    "Ballinasloe",
    "Ballincollig",
    "Ballineen",
    "Ballinrobe",
    "Ballintober",
    "Ballivor",
    "Ballon",
    "Ballsbridge",
    "Ballybrack",
    "Ballybrit",
    "Ballycullen",
    "Ballyfermot",
    "Ballygarvan",
    "Ballyhooly",
    "Ballymahon",
    "Ballymote",
    "Ballymount",
    "Ballymun",
    "Ballyphilip",
    "Ballyragget",
    "Ballyroe",
    "Ballyvaghan",
    "Bandon",
    "Bantry",
    "Berrings",
    "Bettystown",
    "Birr",
    "Blackrock",
    "Blanchardstown",
    "Blessington",
    "Bodyke",
    "Boherbue",
    "Bray",
    "Broadford",
    "Buncrana",
    "Bundoran",
    "Cabinteely",
    "Cabra",
    "Caher",
    "Cahersiveen",
    "Callan",
    "Carbury",
    "Carlow",
    "Carnew",
    "Carraroe",
    "Carrick",
    "Carrick on Shannon",
    "Carrick-on-Suir",
    "Carrickmacross",
    "Carrickmines",
    "Carrigaline",
    "Carrigtohill",
    "Cashel",
    "Castlebar",
    "Castleblayney",
    "Castlebridge",
    "Castleknock",
    "Castlemaine",
    "Castlerea",
    "Cavan",
    "Celbridge",
    "Clane",
    "Clarecastle",
    "Claregalway",
    "Claremorris",
    "Clogherhead",
    "Clonakilty",
    "Clondalkin",
    "Clonee",
    "Clones",
    "Clonmel",
    "Clonsilla",
    "Clontarf",
    "Coachford",
    "Cobh",
    "Coolock",
    "Cork",
    "County Galway",
    "County Wexford",
    "Courtown",
    "Crookhaven",
    "Cross",
    "Crosshaven",
    "Crumlin",
    "Crusheen",
    "Dalkey",
    "Delgany",
    "Donabate",
    "Donegal",
    "Donnybrook",
    "Doolin",
    "Doughiska",
    "Douglas",
    "Drogheda",
    "Droichead Nua",
    "Dublin",
    "Duleek",
    "Dunboyne",
    "Dundalk",
    "Dundrum",
    "Dundrum",
    "Dungarvan",
    "Dunlavin",
    "Dunleer",
    "Dunshaughlin",
    "Edenderry",
    "Enfield",
    "Ennis",
    "Enniscorthy",
    "Enniskerry",
    "Feakle",
    "Ferbane",
    "Fermoy",
    "Finglas",
    "Firhouse",
    "Foxford",
    "Foxrock",
    "Foynes",
    "Galway",
    "Garristown",
    "Geevagh",
    "Glanmire",
    "Glasnevin",
    "Glen",
    "Glenealy",
    "Glengarriff",
    "Glenties",
    "Gorey",
    "Gort",
    "Grange",
    "Greystones",
    "Headford",
    "Hospital",
    "Inchicore",
    "Irishtown",
    "Island",
    "Jamestown",
    "Kanturk",
    "Kells",
    "Kenmare",
    "Kilbride Cross Roads",
    "Kilcock",
    "Kilcoole",
    "Kilcullen",
    "Kildalkey",
    "Kildare",
    "Kilfinane",
    "Kilkenny",
    "Killala",
    "Killaloe",
    "Killarney",
    "Killiney",
    "Killinick",
    "Killorglin",
    "Killurin",
    "Killybegs",
    "Killygordon",
    "Kilmainham",
    "Kilmichael",
    "Kilmore",
    "Kilrush",
    "Kiltamagh",
    "Kingscourt",
    "Kingswood",
    "Kinsale",
    "Kinvarra",
    "Leamlara",
    "Leixlip",
    "Leopardstown",
    "Letterkenny",
    "Limerick",
    "Lisselton",
    "Listowel",
    "Longford",
    "Longwood",
    "Loughrea",
    "Louth",
    "Lucan",
    "Lusk",
    "Macroom",
    "Malahide",
    "Mallow",
    "Manorhamilton",
    "Marino",
    "Maynooth",
    "Mayo",
    "Midleton",
    "Milltown",
    "Mitchelstown",
    "Monaghan",
    "Monasterevin",
    "Monkstown",
    "Mornington",
    "Mount Merrion",
    "Mountrath",
    "Moycullen",
    "Mullinavat",
    "Mullingar",
    "Naas",
    "Naul",
    "Navan",
    "Nenagh",
    "New Ross",
    "Newcastle West",
    "Newmarket",
    "Newport",
    "Oranmore",
    "Oughterard",
    "Oysterhaven",
    "Passage West",
    "Patrickswell",
    "Portarlington",
    "Porterstown",
    "Portmarnock",
    "Portumna",
    "Prosperous",
    "Raheny",
    "Rathcoole",
    "Rathfarnham",
    "Rathgar",
    "Rathmines",
    "Rathmolyon",
    "Rathowen",
    "Ratoath",
    "Ringaskiddy",
    "River",
    "Roscommon",
    "Roscrea",
    "Rush",
    "Saggart",
    "Saint Mullins",
    "Sallins",
    "Sallynoggin",
    "Sandyford",
    "Sandymount",
    "Santry",
    "Seafield",
    "Shankill",
    "Shannon",
    "Shrule",
    "Sixmilebridge",
    "Skerries",
    "Skibbereen",
    "Slieve",
    "Sligo",
    "Spiddal",
    "Stepaside",
    "Stillorgan",
    "Stradbally",
    "Straffan",
    "Summerhill",
    "Sutton",
    "Swinford",
    "Swords",
    "Tallaght",
    "Templeogue",
    "Terenure",
    "Thomastown",
    "Thurles",
    "Tipperary",
    "Tralee",
    "Trim",
    "Tuam",
    "Tullamore",
    "Tullow",
    "Tyrrellspass",
    "Virginia",
    "Walkinstown",
    "Waterford",
    "Westport",
    "Wexford",
    "Wicklow",
    "Williamstown",
    "Windy Arbour",
    "Youghal",
  ],
  "Isle of Man": [
    "Ballasalla",
    "Castletown",
    "Crosby",
    "Dalby",
    "Douglas",
    "Foxdale",
    "Laxey",
    "Onchan",
    "Peel",
    "Port Erin",
    "Port Saint Mary",
    "Ramsey",
    "Saint Johns",
  ],
  Israel: [
    "`Alma",
    "`Amir",
    "`Arugot",
    "`Aseret",
    "`En Ayyala",
    "`En HaShelosha",
    "`Evron",
    "Acre",
    "Afiqim",
    "Ahituv",
    "Allonim",
    "Ashdod",
    "Ashqelon",
    "Azor",
    "Bahan",
    "Baraq",
    "Bareqet",
    "Bat Hadar",
    "Bat Hefer",
    "Bat Yam",
    "Beersheba",
    "Ben Shemen-Kefar Hano`ar",
    "Bene Ziyyon",
    "Bet Alfa",
    "Bet Dagan",
    "Bet Oren",
    "Bet Shemesh",
    "Binyamina",
    "Biriyya",
    "Dabburiya",
    "Dimona",
    "Eilat",
    "Elyakhin",
    "Elyaqim",
    "Emunim",
    "Et Taiyiba",
    "Even Yehuda",
    "Gan Hayyim",
    "Gan Yavne",
    "Ganne Tiqwa",
    "Gedera",
    "Gibbeton",
    "Gimzo",
    "Ginnosar",
    "Giv`at Hayyim",
    "Hadar `Am",
    "Hadar Ramatayim",
    "Hadera",
    "Hadid",
    "Haifa",
    "HaKarmel",
    "Haluz",
    "Hazav",
    "Hazor Ashdod",
    "Hazor HaGelilit",
    "Herut",
    "Herzliya",
    "Hever",
    "Hod HaSharon",
    "Holon",
    "Hurfeish",
    "Jaffa",
    "Jerusalem",
    "Kadima",
    "Karkur",
    "Kefar Daniyyel",
    "Kefar Netter",
    "Kefar Witqin",
    "Kefar Yona",
    "Kfar Saba",
    "Liman",
    "Lod",
    "maalot Tarshiha",
    "Magen",
    "Maghar",
    "Mazkeret Batya",
    "Mazliah",
    "Mazor",
    "Mesillat Ziyyon",
    "Migdal",
    "Mikhmoret",
    "Misgav Regional Council",
    "Mizpe Netofa",
    "Modiin",
    "Moran",
    "Naham",
    "Nahariya",
    "Nazareth",
    "Nazerat `Illit",
    "Nesher",
    "Ness Ziona",
    "Netanya",
    "Netivot",
    "Newe Efrayim",
    "Newe Yamin",
    "Nir Zevi",
    "Nirim",
    "Nordiyya",
    "Ofaqim",
    "Or `Aqiva",
    "Or Yehuda",
    "Pardes Hanna Karkur",
    "Pardesiyya",
    "Pasuta",
    "Petah Tikwah",
    "Qiryat Ata",
    "Qiryat Bialik",
    "Qiryat Gat",
    "Qiryat Hayyim",
    "Qiryat Motzkin",
    "Qiryat Ono",
    "Qiryat Tiv`on",
    "Qiryat Yam",
    "Ramat Aviv",
    "Ramat Dawid",
    "Ramat Ef`al",
    "Ramat Gan",
    "Ramat HaSharon",
    "Ramat Poleg",
    "Ramat Yishay",
    "Ramla",
    "Ramot Naftali",
    "Rehovot",
    "Rinnatya",
    "Rishon LeZion",
    "Rishpon",
    "Safed",
    "Sarid",
    "Savyon",
    "Sde Boker",
    "Sde Warburg",
    "Sderot",
    "Sedot Yam",
    "Shamir",
    "Shave Ziyyon",
    "Shefayim",
    "Shelomi",
    "Shetulim",
    "Shoval",
    "Talme Menashe",
    "Tel Aviv",
    "Tel Mond",
    "Tiberias",
    "Timrat",
    "Tirat Karmel",
    "Tirat Yehuda",
    "Urim",
    "Yaqum",
    "Yavne",
    "Yehud",
    "Zoran",
  ],
  Italy: [
    "Abano Terme",
    "Abbadia Lariana",
    "Abbadia San Salvatore",
    "Abbasanta",
    "Abbiategrasso",
    "Abetone",
    "Acate",
    "Acerno",
    "Acerra",
    "Aci Castello",
    "Aci Catena",
    "Acireale",
    "Acquafredda",
    "Acquafredda Inferiore",
    "Acquapendente",
    "Acquappesa",
    "Acquaro",
    "Acquasparta",
    "Acquaviva",
    "Acquaviva delle Fonti",
    "Acquaviva Picena",
    "Acqui Terme",
    "Acri",
    "Adelfia",
    "Adrano",
    "Adria",
    "Adro",
    "Affi",
    "Afragola",
    "Africo Nuovo",
    "Agazzano",
    "Agerola",
    "Agira",
    "Agliana",
    "Agliano",
    "Aglientu",
    "Agna",
    "Agnadello",
    "Agnone",
    "Agordo",
    "Agrano",
    "Agrate Brianza",
    "Agrigento",
    "Agro",
    "Agropoli",
    "Agugliano",
    "Agugliaro",
    "Ai Palazzi",
    "Aiello",
    "Aiello del Sabato",
    "Ailano",
    "Ailoche",
    "Airasca",
    "Airola",
    "Airole",
    "Airuno",
    "Ala",
    "Ala di Stura",
    "Alanno",
    "Alassio",
    "Alatri",
    "Alba",
    "Albanella",
    "Albano di Lucania",
    "Albano Laziale",
    "Albano Vercellese",
    "Albaredo",
    "Albareto",
    "Albenga",
    "Albergo",
    "Alberobello",
    "Alberoni",
    "Albettone",
    "Albiate",
    "Albignasego",
    "Albina",
    "Albinea",
    "Albino",
    "Albissola Marina",
    "Albizzate",
    "Albuzzano",
    "Alcamo",
    "Alcara li Fusi",
    "Aldeno",
    "Ales",
    "Alessandria",
    "Alessandria della Rocca",
    "Alessano",
    "Alezio",
    "Alfonsine",
    "Alghero",
    "Algund",
    "Alice Castello",
    "Alife",
    "Alimena",
    "Alleghe",
    "Allerona",
    "Alliste",
    "Allumiere",
    "Almenno San Bartolomeo",
    "Almese",
    "Alpette",
    "Alpignano",
    "Altamura",
    "Altare",
    "Altavilla",
    "Altavilla Irpina",
    "Altavilla Milicia",
    "Altavilla Silentina",
    "Altavilla Vicentina",
    "Altedo",
    "Altessano",
    "Altidona",
    "Altino",
    "Altofonte",
    "Altomonte",
    "Altopascio",
    "Alviano",
    "Alvignanello",
    "Alvignano",
    "Alvito",
    "Alzano Lombardo",
    "Amalfi",
    "Amandola",
    "Amantea",
    "Amaro",
    "Amato",
    "Ambra",
    "Ambrogio",
    "Ameglia",
    "Amelia",
    "Ameno",
    "Amorosi",
    "Ampezzo",
    "Anacapri",
    "Anagni",
    "Ancarano",
    "Ancona",
    "Andezeno",
    "Andorno Micca",
    "Andrano",
    "Andria",
    "Anfo",
    "Angera",
    "Anghiari",
    "Angolo Terme",
    "Angri",
    "Anguillara",
    "Anguillara Veneta",
    "Anna",
    "Annicco",
    "Annone Veneto",
    "Antea",
    "Antella",
    "Anterselva di Mezzo",
    "Antico di Maiolo",
    "Antignano",
    "Antonimina",
    "Antrodoco",
    "Anzano del Parco",
    "Anzio",
    "Aosta",
    "Apice",
    "Apiro",
    "Appiano Gentile",
    "Appiano sulla strada del vino",
    "Appignano",
    "Appignano del Tronto",
    "Apricena",
    "Aprilia",
    "Aquileia",
    "Aquilonia",
    "Aquino",
    "Arabba",
    "Aradeo",
    "Aragona",
    "Arborea",
    "Arborio",
    "Arbus",
    "Arcade",
    "Arce",
    "Arcene",
    "Arcevia",
    "Arcidosso",
    "Arco",
    "Arcola",
    "Arcole",
    "Arconate",
    "Arcore",
    "Arcugnano",
    "Ardea",
    "Ardesio",
    "Arenzano",
    "Arese",
    "Arezzo",
    "Argelato",
    "Argenta",
    "Ariano Ferrarese",
    "Ariano Irpino",
    "Ariano nel Polesine",
    "Ariccia",
    "Arielli",
    "Arienzo",
    "Arignano",
    "Arizzano",
    "Arluno",
    "Armeno",
    "Armento",
    "Arnesano",
    "Arona",
    "Arosio",
    "Arpaia",
    "Arpino",
    "Arqua Polesine",
    "Arquata Scrivia",
    "Arre",
    "Arrone",
    "Arsago Seprio",
    "Arsita",
    "Arta Terme",
    "Artena",
    "Artogne",
    "Arzachena",
    "Arzano",
    "Arzene",
    "Arzignano",
    "Asciano",
    "Ascoli Piceno",
    "Ascoli Satriano",
    "Asiago",
    "Asola",
    "Asolo",
    "Assemini",
    "Assisi",
    "Asso",
    "Assoro",
    "Asti",
    "Atella",
    "Atena Lucana",
    "Atessa",
    "Atina",
    "Atri",
    "Atripalda",
    "Attigliano",
    "Attimis",
    "Atzara",
    "Augusta",
    "Auletta",
    "Aulla",
    "Aurelia",
    "Auronzo di Cadore",
    "Avella",
    "Avellino",
    "Aversa",
    "Avezzano",
    "Aviano",
    "Avigliana",
    "Avigliano",
    "Avigliano Umbro",
    "Avio",
    "Avola",
    "Azeglio",
    "Azzano",
    "Azzano Decimo",
    "Azzano San Paolo",
    "Azzate",
    "Bacoli",
    "Badia",
    "Badia",
    "Badia Calavena",
    "Badia Polesine",
    "Badolato",
    "Bagheria",
    "Baglio Rizzo",
    "Bagnacavallo",
    "Bagnara",
    "Bagnara Calabra",
    "Bagnara di Romagna",
    "Bagnasco",
    "Bagni",
    "Bagni di Lucca",
    "Bagno a Ripoli",
    "Bagno di Romagna",
    "Bagnoli del Trigno",
    "Bagnoli di Sopra",
    "Bagnoli Irpino",
    "Bagnolo",
    "Bagnolo Cremasco",
    "Bagnolo in Piano",
    "Bagnolo Mella",
    "Bagnolo Piemonte",
    "Bagnolo San Vito",
    "Baia",
    "Baiano",
    "Baiso",
    "Balangero",
    "Baldissero Torinese",
    "Balestrate-Foce",
    "Ballabio",
    "Baluello",
    "Balvano",
    "Balzola",
    "Baragiano",
    "Baranzate",
    "Baratte",
    "Barbarano Vicentino",
    "Barbarasco",
    "Barberino di Mugello",
    "Barbiano",
    "Bardolino",
    "Bardonecchia",
    "Bareggio",
    "Barga",
    "Bargagli",
    "Barge",
    "Bari",
    "Bari Sardo",
    "Baricella",
    "Barile",
    "Barisciano",
    "Barlassina",
    "Barletta",
    "Barni",
    "Baronissi",
    "Barra",
    "Barrafranca",
    "Barrali",
    "Baruchella",
    "Barumini",
    "Barzana",
    "Basaldella",
    "Baschi",
    "Basciano",
    "Baselice",
    "Basiliano",
    "Basilicanova",
    "Bassano Bresciano",
    "Bassano del Grappa",
    "Bassano in Teverina",
    "Bassano Romano",
    "Bastia umbra",
    "Bastiglia",
    "Battaglia Terme",
    "Battipaglia",
    "Baunei",
    "Baveno",
    "Bazzano",
    "Bedollo",
    "Begliano",
    "Beinasco",
    "Beinette",
    "Belfiore",
    "Belforte",
    "Belgioioso",
    "Bella",
    "Bellagio",
    "Bellano",
    "Bellante",
    "Bellaria-Igea Marina",
    "Bellariva",
    "Bellinzago Novarese",
    "Bellocchi",
    "Bellona",
    "Belluno",
    "Belmonte Mezzagno",
    "Belmonte Piceno",
    "Belpasso",
    "Belsito",
    "Belvedere",
    "Belvedere di Spinello",
    "Belvedere Marittimo",
    "Belvedere Ostrense",
    "Benestare",
    "Benetutti",
    "Benevello",
    "Benevento",
    "Benna",
    "Bentivoglio",
    "Berbenno di Valtellina",
    "Berceto",
    "Bergamo",
    "Berlingo",
    "Bernalda",
    "Bernate Ticino",
    "Bertinoro",
    "Bertonico",
    "Berzo Demo",
    "Besana in Brianza",
    "Besano",
    "Besnate",
    "Besozzo",
    "Bettola",
    "Bettolle",
    "Bettona",
    "Beura-Cardezza",
    "Bevilacqua",
    "Biancade",
    "Biancano",
    "Biancavilla",
    "Bianco",
    "Bianconese",
    "Biandronno",
    "Bianzone",
    "Biassono",
    "Bibbiano",
    "Bibbiena",
    "Bibbona",
    "Bibione",
    "Biella",
    "Bienno",
    "Bientina",
    "Binanuova",
    "Binasco",
    "Bisaccia",
    "Bisacquino",
    "Bisceglie",
    "Bitetto",
    "Bitonto",
    "Bitritto",
    "Bitti",
    "Bivongi",
    "Blera",
    "Boara",
    "Bobbio",
    "Boccheggiano",
    "Bocchignano",
    "Boffalora sopra Ticino",
    "Bogliasco",
    "Bojano",
    "Bolano",
    "Bollate",
    "Bollengo",
    "Bologna",
    "Bolzano",
    "Bolzano",
    "Bolzano Vicentino",
    "Bompietro",
    "Bomporto",
    "Bonarcado",
    "Bonassola",
    "Bonavicina",
    "Bondeno",
    "Bonea",
    "Bonorva",
    "Bordighera",
    "Boretto",
    "Borgaro Torinese",
    "Borgetto",
    "Borghetto",
    "Borghetto di Vara",
    "Borghetto Santo Spirito",
    "Borgia",
    "Borgo",
    "Borgo",
    "Borgo a Mozzano",
    "Borgo di Terzo",
    "Borgo Priolo",
    "Borgo Sabotino-Foce Verde",
    "Borgo San Dalmazzo",
    "Borgo San Giacomo",
    "Borgo San Lorenzo",
    "Borgo Ticino",
    "Borgo Tossignano",
    "Borgo Val di Taro",
    "Borgo Vercelli",
    "Borgomanero",
    "Borgone",
    "Borgonovo",
    "Borgonovo Val Tidone",
    "Borgoricco",
    "Borgorose",
    "Borgosatollo",
    "Borgosesia",
    "Bornasco",
    "Borno",
    "Borrello",
    "Borzonasca",
    "Bosa",
    "Boschetto",
    "Bosco",
    "Bosco",
    "Bosco Chiesanuova",
    "Bosconero",
    "Boscoreale",
    "Boscotrecase",
    "Bosisio Parini",
    "Bossolasco",
    "Bottanuco",
    "Bova Marina",
    "Bovalino",
    "Bovegno",
    "Boves",
    "Bovezzo",
    "Boville Ernica",
    "Bovino",
    "Bovisio-Masciago",
    "Bovolenta",
    "Bovolone",
    "Bozzolo",
    "Bra",
    "Braccagni",
    "Bracciano",
    "Bracigliano",
    "Brancaleone",
    "Brandizzo",
    "Braone",
    "Brebbia",
    "Breda di Piave",
    "Breganze",
    "Breguzzo",
    "Brembate",
    "Brembilla",
    "Brendola",
    "Brenner",
    "Breno",
    "Brescello",
    "Brescia",
    "Bressana Bottarone",
    "Bressanvido",
    "Bresso",
    "Briatico",
    "Bricherasio",
    "Briga Novarese",
    "Brignano",
    "Brindisi",
    "Brissago-Valtravaglia",
    "Brivio",
    "Brixen",
    "Brolo",
    "Broni",
    "Bronte",
    "Bronzolo",
    "Brossasco",
    "Brosso",
    "Brugherio",
    "Brugine",
    "Brugnera",
    "Bruino",
    "Brunate",
    "Bruneck",
    "Brunella",
    "Bruno",
    "Brusciano",
    "Brusnengo",
    "Bubbio",
    "Bucchianico",
    "Bucciano",
    "Buccino",
    "Bucine",
    "Budrio",
    "Buggiano",
    "Buia",
    "Buonabitacolo",
    "Burano",
    "Buriasco",
    "Burolo",
    "Buronzo",
    "Busalla",
    "Busca",
    "Buscate",
    "Buscoldo",
    "Busseto",
    "Bussi sul Tirino",
    "Bussolengo",
    "Bussoleno",
    "Busto Arsizio",
    "Busto Garolfo",
    "Butera",
    "Buti",
    "Buttapietra",
    "Buttigliera Alta",
    "Buttrio",
    "Cabras",
    "Caccamo",
    "Caccuri",
    "Cadelbosco di Sopra",
    "Cadeo",
    "Cadoneghe",
    "Caerano di San Marco",
    "Caggiano",
    "Cagli",
    "Cagliari",
    "Cagliari",
    "Cagnano Varano",
    "Caianello",
    "Caiatia",
    "Cairate",
    "Cairo Montenotte",
    "Caivano",
    "Calalzo di Cadore",
    "Calamandrana",
    "Calangianus",
    "Calascibetta",
    "Calatabiano",
    "Calatafimi",
    "Calcara",
    "Calcata",
    "Calci",
    "Calcinaia",
    "Calcinate",
    "Calcinato",
    "Calcinelli",
    "Calcio",
    "Caldaro sulla Strada del Vino",
    "Caldarola",
    "Calderara di Reno",
    "Caldogno",
    "Caldonazzo",
    "Calendasco",
    "Calenzano",
    "Calice al Cornoviglio",
    "Calimera",
    "Calitri",
    "Calizzano",
    "Calliano",
    "Calolziocorte",
    "Caltagirone",
    "Caltanissetta",
    "Caltavuturo",
    "Caluso",
    "Calvanico",
    "Calvatone",
    "Calvenzano",
    "Calvisano",
    "Calvizzano",
    "Camaiore",
    "Cambiago",
    "Cambiano",
    "Camburzano",
    "Camerano",
    "Camerata Picena",
    "Cameri",
    "Cameriano",
    "Camerino",
    "Camerlona",
    "Caminetto",
    "Camino",
    "Camino",
    "Camisa",
    "Camisano",
    "Camisano Vicentino",
    "Camogli",
    "Campagna",
    "Campagnano di Roma",
    "Campagnola",
    "Campagnola Emilia",
    "Camparada",
    "Campegine",
    "Campertogno",
    "Campi",
    "Campi Bisenzio",
    "Campi Salentina",
    "Campiglia Marittima",
    "Campiglio",
    "Campione",
    "Campli",
    "Campo",
    "Campo Ligure",
    "Campo San Martino",
    "Campo Tizzoro",
    "Campobasso",
    "Campobello di Licata",
    "Campobello di Mazara",
    "Campodarsego",
    "Campodenno",
    "Campodimele",
    "Campodipietra",
    "Campodoro",
    "Campofelice di Roccella",
    "Campofilone",
    "Campoformido",
    "Campofranco",
    "Campogalliano",
    "Campoleone",
    "Campoli Appennino",
    "Campolieto",
    "Campolongo Maggiore",
    "Campomarino",
    "Campomorone",
    "Camponogara",
    "Campora",
    "Camporeale",
    "Camporosso",
    "Camposampiero",
    "Camposano",
    "Canale",
    "Canaro",
    "Canazei",
    "Cancello",
    "Cancello ed Arnone",
    "Candelo",
    "Candia Canavese",
    "Candia Lomellina",
    "Candiana",
    "Candiolo",
    "Canegrate",
    "Canelli",
    "Canicattini Bagni",
    "Canino",
    "Canistro",
    "Cannara",
    "Cannella",
    "Cannero Riviera",
    "Canneto Pavese",
    "Cannobio",
    "Canonica",
    "Canosa di Puglia",
    "Canosa Sannita",
    "Canossa",
    "Cantagallo",
    "Cantalice",
    "Cantalupo in Sabina",
    "Cantalupo Ligure",
    "Cantarana",
    "Cantu",
    "Canzo",
    "Caorle",
    "Caorso",
    "Capaccio",
    "Capaci",
    "Capalbio",
    "Capannelle",
    "Capannoli",
    "Capannori",
    "Capena",
    "Capergnanica",
    "Capistrano",
    "Capistrello",
    "Capitignano",
    "Capo Rizzuto",
    "Capodimonte",
    "Capodrise",
    "Capoliveri",
    "Capoterra",
    "Cappella Maggiore",
    "Cappelle sul Tavo",
    "Capralba",
    "Capranica",
    "Caprese Michelangelo",
    "Capri",
    "Capriate San Gervasio",
    "Caprie",
    "Caprino Bergamasco",
    "Caprino Veronese",
    "Capriolo",
    "Capriva del Friuli",
    "Capua",
    "Capurso",
    "Caraglio",
    "Carapelle",
    "Carasco",
    "Carate Brianza",
    "Caravaggio",
    "Caravate",
    "Caravino",
    "Caravonica",
    "Carbonara al Ticino",
    "Carbonara di Bari",
    "Carbonara di Po",
    "Carbonera",
    "Carbonia",
    "Carcare",
    "Carcereri",
    "Carceri",
    "Cardano",
    "Cardito",
    "Carema",
    "Carenno",
    "Carentino",
    "Caresana",
    "Cariati",
    "Carignano",
    "Carini",
    "Carinola",
    "Carlazzo",
    "Carlentini",
    "Carlino",
    "Carloforte",
    "Carmagnola",
    "Carmiano",
    "Carmignano",
    "Carmignano di Brenta",
    "Carnago",
    "Carnate",
    "Carobbio",
    "Carolei",
    "Caronia",
    "Carosino",
    "Carovigno",
    "Carpaneto Piacentino",
    "Carpegna",
    "Carpenedolo",
    "Carpeneto",
    "Carpi",
    "Carpiano",
    "Carpignano Sesia",
    "Carpinello",
    "Carpineti",
    "Carpineto Romano",
    "Carrara",
    "Carsoli",
    "Cartoceto",
    "Cartura",
    "Carugate",
    "Carvico",
    "Casa Bartolacelli-Stella",
    "Casa Gherardi",
    "Casa Pace",
    "Casa Paradiso",
    "Casa Zola",
    "Casacalenda",
    "Casacanditella",
    "Casaccia II",
    "Casaglia",
    "Casal di Principe",
    "Casal Velino",
    "Casalbordino",
    "Casalborgone",
    "Casalbuttano ed Uniti",
    "Casale",
    "Casale Corte Cerro",
    "Casale di Mezzani",
    "Casale di Scodosia",
    "Casale Monferrato",
    "Casale sul Sile",
    "Casalecchio di Reno",
    "Casaleggio Novara",
    "Casaletto Spartano",
    "Casalgrande",
    "Casalgrasso",
    "Casali",
    "Casalmaggiore",
    "Casalmaiocco",
    "Casalmorano",
    "Casalnuovo di Napoli",
    "Casaloldo",
    "Casalpusterlengo",
    "Casalromano",
    "Casalserugo",
    "Casaluce",
    "Casalvecchio Siculo",
    "Casalvieri",
    "Casalvolone",
    "Casalzuigno",
    "Casamarciano",
    "Casamassima",
    "Casandrino",
    "Casanova",
    "Casapulla",
    "Casarano",
    "Casaratta",
    "Casargo",
    "Casarile",
    "Casarsa della Delizia",
    "Casate Raffa",
    "Casateia",
    "Casatenovo",
    "Casavecchia",
    "Casazza",
    "Cascia",
    "Casciana Terme",
    "Cascina",
    "Cascine Maggio",
    "Case",
    "Case Catania",
    "Case Franchini",
    "Casei Gerola",
    "Caselette",
    "Caselle",
    "Caselle",
    "Caselle Lurani",
    "Caselle Torinese",
    "Caserta",
    "Casier",
    "Casignana",
    "Casina",
    "Casinalbo",
    "Casnigo",
    "Casola di Napoli",
    "Casoli",
    "Casorate Primo",
    "Casorate Sempione",
    "Casorezzo",
    "Casoria",
    "Casperia",
    "Caspoggio",
    "Cassacco",
    "Cassano",
    "Cassano delle Murge",
    "Cassano Spinola",
    "Cassine Superiore",
    "Cassino",
    "Cassolnovo",
    "Castagnaro",
    "Castagneto Carducci",
    "Castagneto Po",
    "Castagnole",
    "Castano Primo",
    "Casteggio",
    "Castegnato",
    "Castel",
    "Castel Bolognese",
    "Castel Colonna",
    "Castel del Piano",
    "Castel di Casio",
    "Castel di Judica",
    "Castel Focognano",
    "Castel Frentano",
    "Castel Gabbiano",
    "Castel Gandolfo",
    "Castel Giorgio",
    "Castel Goffredo",
    "Castel Guelfo di Bologna",
    "Castel Madama",
    "Castel Maggiore",
    "Castel Mella",
    "Castel San Giovanni",
    "Castel San Lorenzo",
    "Castel San Pietro Romano",
    "Castel San Pietro Terme",
    "Castel Viscardo",
    "Castel Volturno",
    "Castelbellino",
    "Castelbuono",
    "Castelcucco",
    "Casteldaccia",
    "Castelfidardo",
    "Castelfiorentino",
    "Castelfranci",
    "Castelfranco di Sopra",
    "Castelfranco di Sotto",
    "Castelfranco Emilia",
    "Castelfranco Veneto",
    "Castelgomberto",
    "Castelgrande",
    "Castellabate",
    "Castellalto",
    "Castellammare del Golfo",
    "Castellammare di Stabia",
    "Castellamonte",
    "Castellana Grotte",
    "Castellana Sicula",
    "Castellaneta",
    "Castellanza",
    "Castellar Guidobono",
    "Castellarano",
    "Castellaro",
    "Castellazzo Bormida",
    "Castellengo",
    "Castelleone",
    "Castelletto",
    "Castelletto",
    "Castelletto sopra Ticino",
    "Castelli",
    "Castelli Calepio",
    "Castellina in Chianti",
    "Castellina Marittima",
    "Castelliri",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello",
    "Castello di Annone",
    "Castello di Godego",
    "Castello di Serravalle",
    "Castellucchio",
    "Castelluccio Inferiore",
    "Castelmassa",
    "Castelnovo di Sotto",
    "Castelnuovo",
    "Castelnuovo",
    "Castelnuovo",
    "Castelnuovo",
    "Castelnuovo",
    "Castelnuovo Berardenga",
    "Castelnuovo dei Sabbioni",
    "Castelnuovo della Daunia",
    "Castelnuovo di Garfagnana",
    "Castelnuovo di Porto",
    "Castelnuovo di Val di Cecina",
    "Castelnuovo di Verona",
    "Castelnuovo Don Bosco",
    "Castelnuovo Magra",
    "Castelnuovo Nigra",
    "Castelnuovo Rangone",
    "Castelnuovo Scrivia",
    "Castelpagano",
    "Castelplanio",
    "Castelpoto",
    "Castelraimondo",
    "Castelseprio",
    "Casteltermini",
    "Castelvecchio",
    "Castelvecchio Pascoli",
    "Castelvenere",
    "Castelverde",
    "Castelvetrano",
    "Castelvetro di Modena",
    "Castenaso",
    "Castenedolo",
    "Castiadas",
    "Castiglion Fibocchi",
    "Castiglion Fiorentino",
    "Castiglione",
    "Castiglione",
    "Castiglione",
    "Castiglione Chiavarese",
    "Castiglione Cosentino",
    "Castiglione dei Pepoli",
    "Castiglione del Lago",
    "Castiglione della Pescaia",
    "Castiglione delle Stiviere",
    "Castiglione di Cervia",
    "Castiglione di Garfagnana",
    "Castiglione di Sicilia",
    "Castiglione Messer Raimondo",
    "Castiglione Olona",
    "Castignano",
    "Castilenti",
    "Castione della Presolana",
    "Castions di Strada",
    "Casto",
    "Castorano",
    "Castri di Lecce",
    "Castrignano del Capo",
    "Castro dei Volsci",
    "Castrocaro Terme",
    "Castrofilippo",
    "Castrolibero",
    "Castronno",
    "Castroreale",
    "Castrovillari",
    "Catania",
    "Catanzaro",
    "Catenanuova",
    "Catignano",
    "Cattolica",
    "Cattolica Eraclea",
    "Caulonia",
    "Cava Manara",
    "Cavacurta",
    "Cavagnolo",
    "Cavaion Veronese",
    "Cavalese",
    "Cavallermaggiore",
    "Cavallino",
    "Cavallino",
    "Cavallirio",
    "Cavareno",
    "Cavaria con Premezzo",
    "Cavarzere",
    "Cave",
    "Cavenago di Brianza",
    "Cavezzo",
    "Cavour",
    "Cavriago",
    "Cavriana",
    "Cavriglia",
    "Cazzago San Martino",
    "Ceccano",
    "Cecchini",
    "Cecina",
    "Cedessano",
    "Cedrasco",
    "Ceggia",
    "Ceglie Messapica",
    "Celano",
    "Celenza Valfortore",
    "Celico",
    "Cellamare",
    "Celle Ligure",
    "Cellino Attanasio",
    "Cellino San Marco",
    "Cellio",
    "Cellole",
    "Cembra",
    "Cenaia",
    "Cencenighe Agordino",
    "Cene",
    "Cengio",
    "Centallo",
    "Cento",
    "Centola",
    "Centrale",
    "Centuripe",
    "Cepagatti",
    "Ceppaloni",
    "Ceprano",
    "Cerano",
    "Cerasolo",
    "Cercepiccola",
    "Cercola",
    "Cerea",
    "Ceregnano",
    "Ceres",
    "Ceresara",
    "Cerete",
    "Ceriale",
    "Ceriano Laghetto",
    "Cerignola",
    "Cermenate",
    "Cernobbio",
    "Cernusco sul Naviglio",
    "Cerredolo",
    "Cerreto Guidi",
    "Cerreto Laziale",
    "Cerreto Sannita",
    "Cerrione",
    "Cerro Maggiore",
    "Certaldo",
    "Certosa",
    "Certosa di Pavia",
    "Cervaro",
    "Cerveno",
    "Cervere",
    "Cerveteri",
    "Cervia",
    "Cervignano del Friuli",
    "Cervino",
    "Cervo",
    "Cesa",
    "Cesana Torinese",
    "Cesano Maderno",
    "Cesara",
    "Cese",
    "Cesena",
    "Cesenatico",
    "Cesiomaggiore",
    "Ceto",
    "Cetraro",
    "Ceva",
    "Chambave",
    "Champdepraz",
    "Charvensod",
    "Cherasco",
    "Chiampo",
    "Chianciano Terme",
    "Chiara",
    "Chiaramonte Gulfi",
    "Chiarano",
    "Chiaravalle",
    "Chiaravalle Centrale",
    "Chiari",
    "Chiavari",
    "Chiavazza",
    "Chiavenna",
    "Chieri",
    "Chiesina Uzzanese",
    "Chieti",
    "Chioggia",
    "Chiomonte",
    "Chions",
    "Chitignano",
    "Chiusanico",
    "Chiusano di San Domenico",
    "Chiusavecchia",
    "Chiusi",
    "Chiusi della Verna",
    "Chivasso",
    "Cicagna",
    "Cicciano",
    "Ciciliano",
    "Cicognolo",
    "Cigliano",
    "Cignone",
    "Cilavegna",
    "Cimadolmo",
    "Cimego",
    "Ciminna",
    "Cimitile",
    "Cinecitta",
    "Cingoli",
    "Cinisello Balsamo",
    "Cinisi",
    "Cinquefrondi",
    "Cintano",
    "Cinto Caomaggiore",
    "Circello",
    "Cirimido",
    "Cisano Bergamasco",
    "Cisano sul Neva",
    "Ciserano",
    "Cislago",
    "Cisliano",
    "Cison di Valmarino",
    "Cisterna di Latina",
    "Cisternino",
    "Cittadella",
    "Cittaducale",
    "Cittanova",
    "Cittiglio",
    "Civezzano",
    "Cividale del Friuli",
    "Cividate Camuno",
    "Civita Castellana",
    "Civitanova Alta",
    "Civitanova Marche",
    "Civitavecchia",
    "Civitella del Tronto",
    "Civitella di Romagna",
    "Civitella in Val di Chiana",
    "Civitella Marittima",
    "Civitella Roveto",
    "Civitella San Paolo",
    "Cizzago-Comezzano",
    "Cles",
    "Clusone",
    "Coccaglio",
    "Cocconato",
    "Cocquio-Trevisago",
    "Codaruina",
    "Codevigo",
    "Codigoro",
    "Codogno",
    "Codroipo",
    "Codrongianos",
    "Coggiola",
    "Cogne",
    "Cogoleto",
    "Cogorno",
    "Cola",
    "Colbordolo",
    "Colere",
    "Colico",
    "Collalto",
    "Collazzone",
    "Colle",
    "Colle",
    "Colle Umberto",
    "Collecchio",
    "Collecorvino",
    "Colledara",
    "Colledimezzo",
    "Colleferro",
    "Collefontana-Fontana Liri Inferiore",
    "Collegno",
    "Collelungo",
    "Collepasso",
    "Collesano",
    "Colli del Tronto",
    "Colliano",
    "Collinas",
    "Colloredo di Prato",
    "Colmurano",
    "Cologna",
    "Cologna Veneta",
    "Cologne",
    "Cologno al Serio",
    "Cologno Monzese",
    "Colombaro",
    "Colonna",
    "Colonnella",
    "Colorno",
    "Coltura",
    "Comacchio",
    "Comano",
    "Comazzo",
    "Comerio",
    "Comiso",
    "Como",
    "Compiano",
    "Comun Nuovo",
    "Cona",
    "Conco",
    "Concordia Sagittaria",
    "Concordia sulla Secchia",
    "Concorezzo",
    "Condofuri",
    "Condove",
    "Conegliano",
    "Confienza",
    "Conselice",
    "Conselve",
    "Contigliano",
    "Contrada",
    "Controguerra",
    "Contursi Terme",
    "Conversano",
    "Copertino",
    "Copiano",
    "Copparo",
    "Coppe",
    "Coppito",
    "Corato",
    "Corbetta",
    "Corbola",
    "Corchiano",
    "Corciano",
    "Cordenons",
    "Cordignano",
    "Cordovado",
    "Corea Castelluccia San Paolo",
    "Coredo",
    "Corfinio",
    "Cori",
    "Coriano",
    "Corigliano Calabro",
    "Corinaldo",
    "Corio",
    "Corleone",
    "Corleto Perticara",
    "Cormons",
    "Cornaiano",
    "Cornaredo",
    "Cornedo Vicentino",
    "Corno",
    "Corno di Rosazzo",
    "Corno Giovine",
    "Cornuda",
    "Corona",
    "Correggio",
    "Correzzola",
    "Corridonia",
    "Corropoli",
    "Corsano",
    "Corsico",
    "Cortandone",
    "Corte",
    "Corte Franca",
    "Cortemaggiore",
    "Cortenova",
    "Corteolona",
    "Corti",
    "Cortina",
    "Cortona",
    "Corvara",
    "Corzano",
    "Coseano",
    "Cosenza",
    "Cossato",
    "Costa",
    "Costa",
    "Costa di Rovigo",
    "Costa Volpino",
    "Costabissara",
    "Costarainera",
    "Costermano",
    "Cotignola",
    "Cotronei",
    "Courmayeur",
    "Covo",
    "Cozzo",
    "Creazzo",
    "Crema",
    "Cremolino",
    "Cremona",
    "Crescentino",
    "Crespano del Grappa",
    "Crespellano",
    "Crespiatica",
    "Crespina",
    "Crespino",
    "Cressa",
    "Crevacuore",
    "Crevalcore",
    "Crispiano",
    "Croce",
    "Crocetta del Montello",
    "Crodo",
    "Cropani",
    "Crosia",
    "Crotone",
    "Cuceglio",
    "Cuggiono",
    "Cuglieri",
    "Cugnoli",
    "Cumiana",
    "Cumignano sul Naviglio",
    "Cuneo",
    "Cunico",
    "Cupello",
    "Cupra Marittima",
    "Cupramontana",
    "Curcuris",
    "Curinga",
    "Curno",
    "Curtarolo",
    "Cusano Milanino",
    "Cusano Mutri",
    "Cusino",
    "Custonaci",
    "Cutrofiano",
    "Dairago",
    "Dalmine",
    "Darfo",
    "Davoli",
    "Decima",
    "Decollatura",
    "Deggiano",
    "Dego",
    "Deiva Marina",
    "Delia",
    "Delianuova",
    "Dello",
    "Demonte",
    "Deruta",
    "Dervio",
    "Desenzano del Garda",
    "Desio",
    "Deutschnofen",
    "Diamante",
    "Diano Marina",
    "Diano San Pietro",
    "Dicomano",
    "Diecimo",
    "Dignano",
    "Dimaro",
    "Dipignano",
    "Dogliani",
    "Dolceacqua",
    "Dolianova",
    "Dolo",
    "Domanico",
    "Domaso",
    "Domegliara",
    "Domodossola",
    "Domusnovas",
    "Dongo",
    "Doria",
    "Dorno",
    "Dosolo",
    "Dossobuono",
    "Dosson",
    "Dovadola",
    "Dozza",
    "Dozza",
    "Drapia",
    "Dro",
    "Dronero",
    "Druento",
    "Druogno",
    "Dubino",
    "Dueville",
    "Dugenta",
    "Duino-Aurisina",
    "Eboli",
    "Edolo",
    "Elmas",
    "Empoli",
    "Endine Gaiano",
    "Enemonzo-Quinis",
    "Enna",
    "Envie",
    "Eraclea",
    "Erba",
    "Erchie",
    "Ercolano",
    "Erice",
    "Erve",
    "Escalaplano",
    "Escolca",
    "Esine",
    "Esino Lario",
    "Esperia",
    "Este",
    "Etroubles",
    "Fabbri",
    "Fabbrica",
    "Fabbrico",
    "Fabriano",
    "Fabrica di Roma",
    "Faedis",
    "Faenza",
    "Fagagna",
    "Faggiano",
    "Fagnano Olona",
    "Faiano",
    "Falciano",
    "Falconara",
    "Falconara Albanese",
    "Falconara Marittima",
    "Falcone",
    "Falerna",
    "Falerone",
    "Falze-Signoressa",
    "Fanano",
    "Fanna",
    "Fano",
    "Fantina",
    "Fara Filiorum Petri",
    "Fara in Sabina",
    "Fara Novarese",
    "Farnese",
    "Farra di Soligo",
    "Fasano",
    "Fastello",
    "Fauglia",
    "Favara",
    "Favaro Veneto",
    "Favria",
    "Feldthurns",
    "Felegara",
    "Feletto",
    "Feletto Umberto",
    "Felino",
    "Felitto",
    "Feltre",
    "Fenile",
    "Ferentino",
    "Fermignano",
    "Fermo",
    "Ferno",
    "Feroleto della Chiesa",
    "Ferrandina",
    "Ferrara",
    "Ferrara",
    "Ferrari",
    "Ferrazzano",
    "Ferrero",
    "Ferriere",
    "Fiano",
    "Fiano Romano",
    "Ficarazzi",
    "Ficarolo",
    "Ficarra",
    "Ficulle",
    "Fidenza",
    "Field",
    "Fiera",
    "Fiesole",
    "Fiesso Umbertiano",
    "Figino",
    "Figline Valdarno",
    "Filadelfia",
    "Filettino",
    "Filetto",
    "Filiano",
    "Filighera",
    "Filo",
    "Filottrano",
    "Finale Emilia",
    "Finale Ligure",
    "Fino Mornasco",
    "Fiorano Modenese",
    "Fiore",
    "Fisciano",
    "Fiuggi",
    "Fiume Veneto",
    "Fiumefreddo di Sicilia",
    "Fiumicello",
    "Fiumicino",
    "Flaibano",
    "Flero",
    "Florence",
    "Floridia",
    "Fluminimaggiore",
    "Flussio",
    "Foggia",
    "Foglianise",
    "Fogliano Redipuglia",
    "Foglizzo",
    "Foiano della Chiana",
    "Foiano di Val Fortore",
    "Folgaria",
    "Folignano",
    "Foligno",
    "Follina",
    "Follonica",
    "Fombio",
    "Fondi",
    "Fonni",
    "Fontana",
    "Fontana Liri",
    "Fontanafredda",
    "Fontanarosa",
    "Fontanelice",
    "Fontanella",
    "Fontanella",
    "Fontanellato",
    "Fontanelle",
    "Fontanelle",
    "Fontaniva",
    "Fontevivo",
    "Fonzaso",
    "Forano",
    "Forenza",
    "Foresto",
    "Forino",
    "Forio",
    "Forlimpopoli",
    "Formello",
    "Formia",
    "Formicola",
    "Formigine",
    "Fornace di Miramare",
    "Fornaci",
    "Fornaci di Barga",
    "Forno",
    "Forno Alpi Graie",
    "Forno Canavese",
    "Fornovo di Taro",
    "Forte dei Marmi",
    "Fortunago",
    "Fosco",
    "Fosdinovo",
    "Fossa",
    "Fossacesia",
    "Fossalta di Piave",
    "Fossalta di Portogruaro",
    "Fossano",
    "Fossato di Vico",
    "Fossombrone",
    "Frabosa Sottana",
    "Fragagnano",
    "Francavilla al Mare",
    "Francavilla di Sicilia",
    "Francavilla Fontana",
    "Francavilla in Sinni",
    "Francenigo",
    "Francofonte",
    "Francolise",
    "Frascaro",
    "Frascati",
    "Frascineto",
    "Frassineto Po",
    "Frasso Telesino",
    "Frattamaggiore",
    "Frattaminore",
    "Frattocchie",
    "Fregene",
    "Frigento",
    "Frisa",
    "Front",
    "Frontone",
    "Frosinone",
    "Frugarolo",
    "Fucecchio",
    "Fumane",
    "Fumone",
    "Furci Siculo",
    "Furore",
    "Fuscaldo",
    "Fusignano",
    "Futani",
    "Gabbiano",
    "Gabbro",
    "Gabicce Mare",
    "Gadoni",
    "Gaeta",
    "Gaggi",
    "Gaggiano",
    "Gaggio Montano",
    "Gagliano del Capo",
    "Gaiarine",
    "Gaiole in Chianti",
    "Gais",
    "Galatina",
    "Galatone",
    "Galbiate",
    "Galdo",
    "Galeata",
    "Gallarate",
    "Gallese",
    "Galliate",
    "Gallicano",
    "Gallicano nel Lazio",
    "Galliera",
    "Galliera Veneta",
    "Gallignano",
    "Gallio",
    "Gallipoli",
    "Gallo",
    "Gambara",
    "Gambellara",
    "Gambettola",
    "Gandino",
    "Gangi",
    "Garbagnate Milanese",
    "Garda",
    "Gardone Riviera",
    "Gardone Val Trompia",
    "Garessio",
    "Gargnano",
    "Garibaldi",
    "Garlasco",
    "Garlate",
    "Garlenda",
    "Garrufo",
    "Gassino Torinese",
    "Gattatico",
    "Gatteo",
    "Gatteo a Mare",
    "Gattinara",
    "Gavardo",
    "Gavi",
    "Gavinana",
    "Gavirate",
    "Gavoi",
    "Gazoldo degli Ippoliti",
    "Gazzada Schianno",
    "Gazzaniga",
    "Gazzo",
    "Gazzo Veronese",
    "Gazzola",
    "Gazzuolo",
    "Gela",
    "Gemona",
    "Genazzano",
    "Genivolta",
    "Genoa",
    "Genzano di Roma",
    "Gera Lario",
    "Gerano",
    "Gerenzago",
    "Gergei",
    "Germagnano",
    "Gessate",
    "Gessopalena",
    "Gesualdo",
    "Ghedi",
    "Ghemme",
    "Ghiffa",
    "Ghilarza",
    "Ghisalba",
    "Giano Vetusto",
    "Giardinello",
    "Giardinetto Vecchio",
    "Giardini",
    "Giardini-Naxos",
    "Giarratana",
    "Giarre",
    "Giaveno",
    "Giavera del Montello",
    "Gibellina",
    "Giffoni Valle Piana",
    "Gignese",
    "Gimigliano",
    "Ginosa",
    "Gioia",
    "Gioia del Colle",
    "Gioia Tauro",
    "Gioiosa Ionica",
    "Gioiosa Marea",
    "Giove",
    "Giove",
    "Giovinazzo",
    "Girifalco",
    "Gissi",
    "Giugliano in Campania",
    "Giuliana",
    "Giuliano",
    "Giuliano Teatino",
    "Giulianova",
    "Giungano",
    "Giussago",
    "Giussano",
    "Godiasco",
    "Godo",
    "Goito",
    "Golasecca",
    "Golfo Aranci",
    "Gonars",
    "Gonnesa",
    "Gonnosfanadiga",
    "Gonnostramatza",
    "Gonzaga",
    "Gordona",
    "Gorgo al Monticano",
    "Gorgonzola",
    "Gorizia",
    "Gorla Maggiore",
    "Gorle",
    "Gorno",
    "Gossolengo",
    "Gottolengo",
    "Gozzano",
    "Gradara",
    "Grado",
    "Gradoli",
    "Graglia",
    "Gragnano",
    "Gragnano Trebbiense",
    "Grammichele",
    "Grandate",
    "Granitola Torretta",
    "Grantorto",
    "Grassano",
    "Gravedona",
    "Gravellona Toce",
    "Gravina in Puglia",
    "Grazzanise",
    "Grazzano Badoglio",
    "Greci",
    "Gressoney-Saint-Jean",
    "Greve in Chianti",
    "Grezzana",
    "Grignasco",
    "Grigno",
    "Grimaldi",
    "Grisignano di Zocco",
    "Gropello Cairoli",
    "Grosio",
    "Grosseto",
    "Grotta",
    "Grottaferrata",
    "Grottaglie",
    "Grottaminarda",
    "Grottammare",
    "Grottazzolina",
    "Grotte",
    "Grotte di Castro",
    "Grottolella",
    "Gruaro",
    "Grugliasco",
    "Grumello Cremonese",
    "Grumello del Monte",
    "Grumento Nova",
    "Grumo Appula",
    "Grumo Nevano",
    "Grumolo delle Abbadesse",
    "Guagnano",
    "Gualdo Cattaneo",
    "Gualdo Tadino",
    "Gualtieri",
    "Guamaggiore",
    "Guarda Veneta",
    "Guardamiglio",
    "Guardavalle",
    "Guardia Piemontese",
    "Guardia Sanframondi",
    "Guardiagrele",
    "Guardialfiera",
    "Guarrato",
    "Guasila",
    "Guastalla",
    "Gubbio",
    "Guidizzolo",
    "Guido",
    "Guidonia",
    "Guiglia",
    "Gurro",
    "Guspini",
    "Gussago",
    "Gusti",
    "Iesi",
    "Iglesias",
    "Il Ciocco",
    "Illasi",
    "Imola",
    "Imperia",
    "Impruneta",
    "Induno Olona",
    "Innichen",
    "Inverigo",
    "Inveruno",
    "Inzago",
    "Ischia",
    "Ischia di Castro",
    "Ischitella",
    "Iseo",
    "Isernia",
    "Isili",
    "Isola",
    "Isola del Cantone",
    "Isola del Giglio",
    "Isola del Gran Sasso",
    "Isola del Liri",
    "Isola della Scala",
    "Isola delle Femmine",
    "Isola Dovarese",
    "Isola Vicentina",
    "Isorella",
    "Ispica",
    "Ispra",
    "Issime",
    "Istrana",
    "Itala",
    "Itri",
    "Ittiri",
    "Ivrea",
    "Izano",
    "Jesolo",
    "Jolanda di Savoia",
    "Klausen",
    "La Gala",
    "La Loggia",
    "La Maddalena",
    "La Morra",
    "La Salle",
    "La Salute di Livenza",
    "La Spezia",
    "La Valle Agordina",
    "La Via",
    "La Villa",
    "Labico",
    "Lacchiarella",
    "Lacco Ameno",
    "Ladispoli",
    "Lago",
    "Lago",
    "Lago",
    "Lagosanto",
    "Laigueglia",
    "Lainate",
    "Laino Borgo",
    "Laives",
    "Lajatico",
    "Lajen",
    "Lake Bolsena",
    "Lallio",
    "Lama Mocogno",
    "Lammari",
    "Lamon",
    "Lampedusa",
    "Lamporecchio",
    "Lana",
    "Lancenigo",
    "Lancenigo-Villorba",
    "Lanciano",
    "Landriano",
    "Langhirano",
    "Lanusei",
    "Lanuvio",
    "Lanzo Torinese",
    "Lapio",
    "Lappato",
    "Larciano",
    "Larderello",
    "Lardirago",
    "Lari",
    "Lariano",
    "Larino",
    "Lasino",
    "Lastra",
    "Lastra a Signa",
    "Laterina",
    "Laterza",
    "Latiano",
    "Latina",
    "Latisana",
    "Latronico",
    "Laureana di Borrello",
    "Lauria",
    "Lauro",
    "Lavagna",
    "Lavello",
    "Lavena Ponte Tresa",
    "Laveno-Mombello",
    "Lavis",
    "Lazise",
    "Lazzaro",
    "Le Castella",
    "Lecce",
    "Lecco",
    "Leffe",
    "Legnago",
    "Legnano",
    "Legnaro",
    "Lendinara",
    "Lenna",
    "Lenno",
    "Leno",
    "Lenola",
    "Lentate sul Seveso",
    "Lentiai",
    "Lentini",
    "Leonessa",
    "Leonforte",
    "Leporano",
    "Lercara Friddi",
    "Lerici",
    "Lerma",
    "Lesa",
    "Lesegno",
    "Lesina",
    "Lessona",
    "Lestizza",
    "Lettomanoppello",
    "Lettopalena",
    "Levane",
    "Levanto",
    "Levata",
    "Leverano",
    "Levico Terme",
    "Lezzeno",
    "Librizzi",
    "Licata",
    "Licciana Nardi",
    "Lido",
    "Lido",
    "Lido di Camaiore",
    "Lido di Ostia",
    "Lierna",
    "Lignano Sabbiadoro",
    "Limana",
    "Limatola",
    "Limbiate",
    "Limena",
    "Limite",
    "Limiti di Greccio",
    "Limone Piemonte",
    "Lingotto",
    "Lioni",
    "Lipari",
    "Liscate",
    "Lissone",
    "Liveri",
    "Livigno",
    "Livinallongo del Col di Lana",
    "Livorno",
    "Livorno Ferraris",
    "Lizzanello",
    "Lizzano",
    "Lizzola",
    "Loano",
    "Localita della Chiesa",
    "Locate di Triulzi",
    "Locati",
    "Locorotondo",
    "Locri",
    "Lodi",
    "Lodi Vecchio",
    "Lograto",
    "Loiano",
    "Lomagna",
    "Lomazzo",
    "Lombardore",
    "Lomello",
    "Lonate Ceppino",
    "Lonate Pozzolo",
    "Lonato",
    "Longare",
    "Longarone",
    "Longiano",
    "Longobardi",
    "Lonigo",
    "Loreggia",
    "Loreo",
    "Loreto",
    "Loreto Aprutino",
    "Loria",
    "Loro Ciuffenna",
    "Loro Piceno",
    "Lovere",
    "Lozzo Atestino",
    "Lozzo di Cadore",
    "Luca",
    "Lucca",
    "Lucera",
    "Luciana",
    "Luciano",
    "Lucino",
    "Lucito",
    "Luco dei Marsi",
    "Lucugnano",
    "Lugagnano",
    "Lugnano",
    "Lugnano in Teverina",
    "Lugo",
    "Luino",
    "Lunamatrona",
    "Lungavilla",
    "Lungro",
    "Lurago Marinone",
    "Lurate Caccivio",
    "Lustra",
    "Lutago",
    "Luzzano",
    "Luzzi",
    "Maccagno",
    "Maccarese",
    "Macello",
    "Macerata",
    "Macerata Campania",
    "Macomer",
    "Maddalena di Cazzano",
    "Maddaloni",
    "Maderno",
    "Madignano",
    "Madonna",
    "Madonna di Campiglio",
    "Maerne",
    "Mafalda",
    "Magenta",
    "Maggio",
    "Magione",
    "Magliano",
    "Magliano",
    "Magliano Alpi",
    "Magliano di Tenna",
    "Magliano in Toscana",
    "Magliano Sabina",
    "Maglie",
    "Magnago",
    "Magno",
    "Magreta",
    "Maierato",
    "Maiolati Spontini",
    "Mairano",
    "Malalbergo",
    "Malcesine",
    "Malcontenta",
    "Maletto",
    "Malgrate",
    "Malnate",
    "Malo",
    "Malonno",
    "Mals",
    "Maltignano",
    "Mammola",
    "Mandas",
    "Mandello del Lario",
    "Manduria",
    "Manerbio",
    "Manfredonia",
    "Maniago",
    "Manocalzati",
    "Manoppello",
    "Manta",
    "Mantova",
    "Mantovana",
    "Manzano",
    "Manziana",
    "Manzoni",
    "Mapello",
    "Maranello",
    "Marano di Napoli",
    "Marano Ticino",
    "Marano Vicentino",
    "Maratea",
    "Marcallo con Casone",
    "Marcaria",
    "Marcellina",
    "Marche",
    "Marciana Marina",
    "Marcianise",
    "Marco",
    "Marcon",
    "Mareno di Piave",
    "Mareo",
    "Maresca",
    "Margarita",
    "Marghera",
    "Margherita di Savoia",
    "Mariae",
    "Mariano",
    "Mariano Comense",
    "Mariglianella",
    "Marigliano",
    "Marignano",
    "Marina",
    "Marina di Andora",
    "Marina di Campo",
    "Marina di Carrara",
    "Marina di Castagneto Carducci",
    "Marina di Cerveteri",
    "Marina di Ginosa",
    "Marina di Gioiosa Ionica",
    "Marina di Ragusa",
    "Marineo",
    "Marini",
    "Marino",
    "Marlia",
    "Marmirolo",
    "Marnate",
    "Marola",
    "Marone",
    "Marostica",
    "Marotta",
    "Marrubiu",
    "Marsala",
    "Marsciano",
    "Marsico Nuovo",
    "Marta",
    "Martano",
    "Martellago",
    "Martignacco",
    "Martignano",
    "Martina Franca",
    "Martinengo",
    "Martiniana Po",
    "Martinsicuro",
    "Martirano",
    "Maruggio",
    "Marzabotto",
    "Marzano",
    "Marzano di Nola",
    "Mas",
    "Masainas",
    "Mascali",
    "Mascalucia",
    "Maser",
    "Masera",
    "Maserada sul Piave",
    "Masi Torello",
    "Maslianico",
    "Mason",
    "Massa",
    "Massa",
    "Massa",
    "Massa",
    "Massa e Cozzile",
    "Massa Fiscaglia",
    "Massa Lombarda",
    "Massa Marittima",
    "Massa Martana",
    "Massafra",
    "Massarosa",
    "Masserano",
    "Massignano",
    "Masullas",
    "Matelica",
    "Matera",
    "Mathi",
    "Matino",
    "Mauro",
    "Mazara del Vallo",
    "Mazzano Romano",
    "Mazzarino",
    "Mazzo di Valtellina",
    "Meana Sardo",
    "Meano",
    "Meda",
    "Mede",
    "Medea",
    "Medesano",
    "Medicina",
    "Medolago",
    "Medole",
    "Medolla",
    "Meduna di Livenza",
    "Mel",
    "Melara",
    "Meldola",
    "Melegnano",
    "Melendugno",
    "Melfi",
    "Melicucco",
    "Melilli",
    "Melissano",
    "Melito di Porto Salvo",
    "Melizzano",
    "Melzo",
    "Menaggio",
    "Mendicino",
    "Menfi",
    "Mentana",
    "Meolo",
    "Meran",
    "Merano",
    "Merate",
    "Mercatino Conca",
    "Mercato",
    "Mercato San Severino",
    "Mercato Saraceno",
    "Mercenasco",
    "Mercogliano",
    "Mereto di Tomba",
    "Mergo",
    "Mergozzo",
    "Mesagne",
    "Mesola",
    "Mesoraca",
    "Messina",
    "Mestre",
    "Mestrino",
    "Meta",
    "Metaponto",
    "Mezzanego",
    "Mezzano",
    "Mezzocorona",
    "Mezzolara",
    "Mezzolombardo",
    "Mezzomerico",
    "Miglianico",
    "Migliarino",
    "Migliarino",
    "Migliaro",
    "Miglionico",
    "Mignanego",
    "Mignano Monte Lungo",
    "Milan",
    "Milanesi",
    "Milano",
    "Milano Marittima",
    "Milazzo",
    "Milena",
    "Militello in Val di Catania",
    "Militello Rosmarino",
    "Millesimo",
    "Milo",
    "Mineo",
    "Minerbe",
    "Minerbio",
    "Minervino di Lecce",
    "Minervino Murge",
    "Minori",
    "Minturno",
    "Mira",
    "Mirabella",
    "Mirabella Eclano",
    "Mirabella Imbaccari",
    "Mirabello",
    "Mirabello Monferrato",
    "Miradolo Terme",
    "Mirandola",
    "Mirano",
    "Miravalle",
    "Misano Adriatico",
    "Misilmeri",
    "Misinto",
    "Missaglia",
    "Misterbianco",
    "Mistretta",
    "Modena",
    "Modica",
    "Modigliana",
    "Modugno",
    "Moena",
    "Moglia",
    "Mogliano",
    "Mogliano Veneto",
    "Mogoro",
    "Moiano",
    "Moimacco",
    "Moio della Civitella",
    "Mojo Alcantara",
    "Mola di Bari",
    "Molazzana",
    "Molfetta",
    "Molinari",
    "Molinella",
    "Molino",
    "Molise",
    "Moliterno",
    "Mombercelli",
    "Momo",
    "Monaco",
    "Monasterace",
    "Monastero",
    "Monasterolo del Castello",
    "Monastir",
    "Moncalieri",
    "Moncalvo",
    "Mondavio",
    "Mondolfo",
    "Mondragone",
    "Moneglia",
    "Monfalcone",
    "Monforte San Giorgio",
    "Monghidoro",
    "Mongrando",
    "Moniga del Garda",
    "Monopoli",
    "Monreale",
    "Monsampietro Morico",
    "Monsampolo del Tronto",
    "Monsano",
    "Monselice",
    "Monserrato",
    "Monsummano Terme",
    "Montafia",
    "Montagnana",
    "Montalbano",
    "Montalbano Jonico",
    "Montalcino",
    "Montaldo",
    "Montale",
    "Montalenghe",
    "Montalto delle Marche",
    "Montalto di Castro",
    "Montalto Dora",
    "Montalto Uffugo",
    "Montanara",
    "Montanaro",
    "Montanaso Lombardo",
    "Montappone",
    "Monte",
    "Monte",
    "Monte Castello di Vibio",
    "Monte Compatri",
    "Monte di Malo",
    "Monte di Procida",
    "Monte Giberto",
    "Monte Porzio Catone",
    "Monte Roberto",
    "Monte San Giovanni Campano",
    "Monte San Giusto",
    "Monte San Pietro",
    "Monte San Savino",
    "Monte San Vito",
    "Monte Urano",
    "Monte Vidon Corrado",
    "Montebello",
    "Montebello Vicentino",
    "Montebelluna",
    "Montecalvoli",
    "Montecarlo",
    "Montecarotto",
    "Montecassiano",
    "Montecastrilli",
    "Montecatini Terme",
    "Montecchia di Crosara",
    "Montecchio",
    "Montecchio",
    "Montecchio",
    "Montecchio Emilia",
    "Montecchio Maggiore",
    "Montechiarugolo",
    "Montecorice",
    "Montecorvino Rovella",
    "Montecosaro",
    "Montefalcione",
    "Montefano",
    "Montefiascone",
    "Montefiore Conca",
    "Montefiorino",
    "Monteforte Irpino",
    "Montefortino",
    "Montefranco",
    "Montefredane",
    "Montegaldella",
    "Montegiorgio",
    "Montegranaro",
    "Montegrotto Terme",
    "Monteiasi",
    "Montelabbate",
    "Montelanico",
    "Montelepre",
    "Montelparo",
    "Montelupo Fiorentino",
    "Montemaggiore al Metauro",
    "Montemarano",
    "Montemarciano",
    "Montemassi",
    "Montemiletto",
    "Montemurlo",
    "Montemurro",
    "Montenero di Bisaccia",
    "Montenerodomo",
    "Monteodorisio",
    "Montepaone",
    "Montepiano",
    "Monteprandone",
    "Montepulciano",
    "Montepulciano Stazione",
    "Monterchi",
    "Montereale",
    "Montereale Valcellina",
    "Monterenzio",
    "Monteriggioni",
    "Monteroduni",
    "Monteroni di Lecce",
    "Monterosi",
    "Monterosso al Mare",
    "Monterosso Calabro",
    "Monterotondo",
    "Monterubbiano",
    "Montesano Salentino",
    "Montesano sulla Marcellana",
    "Montesarchio",
    "Montescaglioso",
    "Montescudo",
    "Montese",
    "Montesilvano Colle",
    "Montespertoli",
    "Montevago",
    "Montevarchi",
    "Monteveglio",
    "Monteverde",
    "Monteviale",
    "Monticelli",
    "Monticelli",
    "Monticello",
    "Monticello Conte Otto",
    "Montichiari",
    "Monticiano",
    "Montignoso",
    "Montodine",
    "Montoggio",
    "Montorio al Vomano",
    "Montoro",
    "Montorso Vicentino",
    "Monza",
    "Monzambano",
    "Monzuno",
    "Mora",
    "Morano Calabro",
    "Morbegno",
    "Morciano di Leuca",
    "Morciano di Romagna",
    "Morcone",
    "Mordano",
    "Moretta",
    "Morgano",
    "Morgex",
    "Mori",
    "Moricone",
    "Morlupo",
    "Mornago",
    "Morolo",
    "Morozzo",
    "Morrone",
    "Morrovalle",
    "Mortara",
    "Mortegliano",
    "Morter",
    "Moscufo",
    "Moso",
    "Mossa",
    "Motta di Livenza",
    "Motta San Giovanni",
    "Motta San Guglielmo",
    "Motta Visconti",
    "Mottafollone",
    "Mottalciata",
    "Mottola",
    "Mozzagrogna",
    "Mozzanica",
    "Mozzate",
    "Mozzecane",
    "Muggia",
    "Mugnano",
    "Mugnano del Cardinale",
    "Mulazzano",
    "Murano",
    "Muravera",
    "Murlo",
    "Muro Leccese",
    "Muro Lucano",
    "Muros",
    "Musei",
    "Musile di Piave",
    "Mussomeli",
    "Nanto",
    "Naples",
    "Narbolia",
    "Narcao",
    "Narni",
    "Narni Scalo",
    "Naro",
    "Naturns",
    "Natz-Schabs",
    "Navacchio",
    "Nave",
    "Nave",
    "Nazzano",
    "Negrar",
    "Neirone",
    "Neive",
    "Nembro",
    "Nepi",
    "Nereto",
    "Nervesa della Battaglia",
    "Nerviano",
    "Nettuno",
    "Neumarkt",
    "Neviano",
    "Neviano degli Arduini",
    "Nichelino",
    "Nicola",
    "Nicolosi",
    "Nicosia",
    "Nicotera",
    "Niederdorf",
    "Nimis",
    "Niscemi",
    "Nizza di Sicilia",
    "Nizza Monferrato",
    "Noale",
    "Nocciano",
    "Nocelleto",
    "Nocera Inferiore",
    "Nocera Superiore",
    "Nocera Terinese",
    "Noceto",
    "Noci",
    "Nociglia",
    "Nogara",
    "Nogaredo al Torre",
    "Nogarole Rocca",
    "Noicattaro",
    "Nola",
    "Noli",
    "Nonantola",
    "None",
    "Norcia",
    "Norma",
    "Notaresco",
    "Noto",
    "Nova Milanese",
    "Nova Siri",
    "Novafeltria",
    "Novalesa",
    "Novara",
    "Novate Mezzola",
    "Novate Milanese",
    "Nove",
    "Novellara",
    "Novello",
    "Noventa di Piave",
    "Noventa Vicentina",
    "Novi di Modena",
    "Novi Ligure",
    "Novi Velia",
    "Novoli",
    "Numana",
    "Nuoro",
    "Nurachi",
    "Nurallao",
    "Nuraminis",
    "Nurri",
    "Nusco",
    "Nuvolento",
    "Occhieppo Inferiore",
    "Occhieppo Superiore",
    "Occhiobello",
    "Ocre",
    "Oderzo",
    "Offagna",
    "Offanengo",
    "Offida",
    "Offlaga",
    "Oggiono",
    "Oglianico",
    "Olbia",
    "Oleggio",
    "Olevano Romano",
    "Olevano sul Tusciano",
    "Olgiate Comasco",
    "Olgiate Molgora",
    "Olgiate Olona",
    "Olginate",
    "Oliena",
    "Oliva",
    "Oliveri",
    "Oliveto",
    "Oliveto Citra",
    "Oliveto Lario",
    "Olivetta",
    "Olmedo",
    "Omegna",
    "Omignano",
    "Opera",
    "Oppeano",
    "Oppido Lucano",
    "Orani",
    "Oratino",
    "Orbassano",
    "Orbetello",
    "Orciano di Pesaro",
    "Orgiano",
    "Orgosolo",
    "Oria",
    "Origgio",
    "Orio al Serio",
    "Orio Litta",
    "Oriolo Romano",
    "Oristano",
    "Ormea",
    "Ormelle",
    "Ornago",
    "Ornavasso",
    "Orosei",
    "Orotelli",
    "Orsenigo",
    "Orsogna",
    "Orta Nova",
    "Orta San Giulio",
    "Orte",
    "Ortelle",
    "Ortona",
    "Ortonovo",
    "Ortovero",
    "Orvieto",
    "Orzinuovi",
    "Orzivecchi",
    "Osiglia",
    "Osimo",
    "Osnago",
    "Osoppo",
    "Ospedaletti",
    "Ospedaletto",
    "Ospedaletto Euganeo",
    "Ospedaletto-Expopisa",
    "Ospitaletto",
    "Ossago Lodigiano",
    "Ossi",
    "Ossona",
    "Ostiano",
    "Ostiglia",
    "Ostigliano",
    "Ostra",
    "Ostra Vetere",
    "Ostuni",
    "Otranto",
    "Otricoli",
    "Ottaviano",
    "Ottiglio",
    "Oulx",
    "Ovada",
    "Ovaro",
    "Ozegna",
    "Ozieri",
    "Ozzano Monferrato",
    "Ozzero",
    "Pace",
    "Paceco",
    "Pacentro",
    "Pachino",
    "Paciano",
    "Paderno",
    "Paderno Dugnano",
    "Paderno Franciacorta",
    "Paderno Ponchielli",
    "Padua",
    "Padula",
    "Paesana",
    "Paese",
    "Pagani",
    "Paganica",
    "Pagazzano",
    "Paglieta",
    "Pago Veiano",
    "Palagianello",
    "Palagiano",
    "Palagonia",
    "Palaia",
    "Palau",
    "Palazzina",
    "Palazzo",
    "Palazzo",
    "Palazzo Canavese",
    "Palazzolo",
    "Palazzolo Acreide",
    "Palazzolo dello Stella",
    "Palermo",
    "Palestrina",
    "Paliano",
    "Palizzi Marina",
    "Palladio",
    "Pallanza-Intra-Suna",
    "Pallanzeno",
    "Pallerone",
    "Palma Campania",
    "Palma di Montechiaro",
    "Palmanova",
    "Palmariggi",
    "Palmas Arborea",
    "Palmi",
    "Palo del Colle",
    "Palombara Sabina",
    "Palosco",
    "Paluzza",
    "Panaro",
    "Pancalieri",
    "Pandino",
    "Panicale",
    "Pannarano",
    "Pantano",
    "Pantigliate",
    "Panzano",
    "Paola",
    "Parabiago",
    "Parabita",
    "Paratico",
    "Parghelia",
    "Parigi",
    "Parma",
    "Parre",
    "Partanna",
    "Partinico",
    "Paruzzaro",
    "Pasian di Prato",
    "Passarella",
    "Passetto",
    "Passignano sul Trasimeno",
    "Passirano",
    "Passo",
    "Passo Corese",
    "Passo di Treia",
    "Pastene",
    "Pastrengo",
    "Pasturo",
    "Paterno",
    "Paternopoli",
    "Patrica",
    "Patti",
    "Pauli Arbarei",
    "Paulilatino",
    "Paullo",
    "Pavia",
    "Pavia di Udine",
    "Pavona",
    "Pavone Canavese",
    "Pavullo nel Frignano",
    "Peccioli",
    "Pecetto",
    "Pedara",
    "Pedaso",
    "Pedavena",
    "Pedemonte",
    "Pederobba",
    "Peglio",
    "Pegognaga",
    "Pelago",
    "Pellegrini",
    "Pellegrino",
    "Pellegrino Parmense",
    "Pellestrina",
    "Pellezzano",
    "Pennabilli",
    "Penne",
    "Perdasdefogu",
    "Perdaxius",
    "Perdifumo",
    "Perfugas",
    "Pergine Valsugana",
    "Pergola",
    "Perignano",
    "Perinaldo",
    "Pernumia",
    "Pero",
    "Pero",
    "Perosa Argentina",
    "Pertengo",
    "Perteole",
    "Pertosa",
    "Perugia",
    "Pesaro",
    "Pescantina",
    "Pescara",
    "Pescarolo ed Uniti",
    "Peschici",
    "Peschiera Borromeo",
    "Peschiera del Garda",
    "Pescia",
    "Pescina",
    "Pescocostanzo",
    "Pessinetto",
    "Pessione",
    "Petacciato",
    "Petilia Policastro",
    "Petriano",
    "Petritoli",
    "Petrosino",
    "Petruro",
    "Pettinengo",
    "Pettoranello del Molise",
    "Pettorazza Grimani",
    "Peveragno",
    "Pezzana",
    "Pezze di Greco",
    "Piacenza",
    "Piadena",
    "Piaggine",
    "Pian Camuno",
    "Pian-Fossal",
    "Piana",
    "Piana degli Albanesi",
    "Piancastagnaio",
    "Piancogno",
    "Piandimeleto",
    "Piane Crati",
    "Pianella",
    "Pianella",
    "Pianello Val Tidone",
    "Pianezza",
    "Pianezze",
    "Piangipane",
    "Pianiga",
    "Piano",
    "Piano",
    "Piano di Coreglia-Ghivizzano",
    "Piano di Sorrento",
    "Piano-Vetrale",
    "Pianoro",
    "Piasco",
    "Piateda",
    "Piavon",
    "Piazza",
    "Piazza al Serchio",
    "Piazza Armerina",
    "Piazza Brembana",
    "Piazza Roma",
    "Piazzola sul Brenta",
    "Picerno",
    "Pico",
    "Piedimonte Etneo",
    "Piedimonte Matese",
    "Piedimonte San Germano",
    "Piegaro",
    "Piemonte",
    "Pieris",
    "Pietra",
    "Pietra Ligure",
    "Pietrabbondante",
    "Pietracuta",
    "Pietrapaola",
    "Pietraperzia",
    "Pietrasanta",
    "Pieve",
    "Pieve",
    "Pieve Albignola",
    "Pieve del Cairo",
    "Pieve di Cadore",
    "Pieve di Cento",
    "Pieve di Soligo",
    "Pieve Fosciana",
    "Pieve Ligure",
    "Pieve San Giacomo",
    "Pieve Vecchia",
    "Pievebovigliana",
    "Pievepelago",
    "Piglio",
    "Pignataro Interamna",
    "Pignataro Maggiore",
    "Pignola",
    "Pignone",
    "Pilcante",
    "Pimonte",
    "Pinarella",
    "Pinerolo",
    "Pineta",
    "Pineto",
    "Pinzano al Tagliamento",
    "Pinzolo",
    "Piobbico",
    "Piobesi Torinese",
    "Pioltello",
    "Piombino",
    "Piombino Dese",
    "Pioraco",
    "Piossasco",
    "Piove di Sacco",
    "Piovene Rocchette",
    "Piraino",
    "Pirri",
    "Pisa",
    "Pisciotta",
    "Pisogne",
    "Pisticci",
    "Pistoia",
    "Pistrino",
    "Pitigliano",
    "Piumazzo",
    "Pizzighettone",
    "Pizzo",
    "Plan da Tieja",
    "Ploaghe",
    "Pocenia",
    "Podenzana",
    "Podenzano",
    "Pofi",
    "Poggiardo",
    "Poggibonsi",
    "Poggio",
    "Poggio a Caiano",
    "Poggio Berni",
    "Poggio di Roio",
    "Poggio Mirteto",
    "Poggio Moiano",
    "Poggio Nativo",
    "Poggio Renatico",
    "Poggio Rusco",
    "Poggio San Lorenzo",
    "Poggiomarino",
    "Pogliano",
    "Pogliano Milanese",
    "Pogno",
    "Poiana Maggiore",
    "Poirino",
    "Polesella",
    "Polesine Parmense",
    "Poli",
    "Policoro",
    "Polignano a Mare",
    "Polinago",
    "Polistena",
    "Polla",
    "Pollein",
    "Pollenza",
    "Pollica",
    "Pollina",
    "Polverara",
    "Polverigi",
    "Pomarance",
    "Pombia",
    "Pomezia",
    "Pompei",
    "Pompiano",
    "Pomponesco",
    "Poncarale",
    "Ponsacco",
    "Ponso",
    "Pont",
    "Pont-Canavese",
    "Pont-Saint-Martin",
    "Pontassieve",
    "Ponte",
    "Ponte",
    "Ponte",
    "Ponte a Evola",
    "Ponte a Moriano",
    "Ponte Basso",
    "Ponte Buggianese",
    "Ponte di Barbarano",
    "Ponte di Piave",
    "Ponte Galeria-La Pisana",
    "Ponte Lambro",
    "Ponte nelle Alpi",
    "Ponte Nossa",
    "Ponte San Giovanni",
    "Ponte San Nicolo",
    "Ponte San Pietro",
    "Pontecagnano",
    "Pontecchio",
    "Pontecchio Polesine",
    "Pontecorvo",
    "Pontecurone",
    "Pontedassio",
    "Pontedera",
    "Pontelongo",
    "Pontenure",
    "Pontestura",
    "Pontevico",
    "Ponti",
    "Ponticelli",
    "Pontida",
    "Pontinia",
    "Pontremoli",
    "Ponza",
    "Ponzano",
    "Ponzano Veneto",
    "Ponzone",
    "Popoli",
    "Poppi",
    "Porano",
    "Porcari",
    "Porcia",
    "Pordenone",
    "Porlezza",
    "Porpetto",
    "Porretta Terme",
    "Porta",
    "Portici",
    "Portico di Caserta",
    "Porto",
    "Porto",
    "Porto Azzurro",
    "Porto Empedocle",
    "Porto Ercole",
    "Porto Garibaldi",
    "Porto Potenza Picena",
    "Porto Recanati",
    "Porto San Giorgio",
    "Porto Tolle",
    "Porto Torres",
    "Porto Viro",
    "Portoferraio",
    "Portofino",
    "Portogruaro",
    "Portomaggiore",
    "Portoscuso",
    "Portovenere",
    "Positano",
    "Posta Fibreno",
    "Posta Vecchia",
    "Postiglione",
    "Postioma",
    "Potenza",
    "Potenza Picena",
    "Povegliano",
    "Povegliano Veronese",
    "Poviglio",
    "Pozza di Fassa",
    "Pozza-San Marco",
    "Pozzallo",
    "Pozzilli",
    "Pozzo",
    "Pozzo di Gotto",
    "Pozzolengo",
    "Pozzoleone",
    "Pozzolo",
    "Pozzolo Formigaro",
    "Pozzonovo",
    "Pozzuoli",
    "Pozzuolo del Friuli",
    "Prad am Stilfser Joch",
    "Pradamano",
    "Praia a Mare",
    "Praiano",
    "Pralormo",
    "Pralungo",
    "Pramaggiore",
    "Prarolo",
    "Prata",
    "Prata Camportaccio",
    "Prata di Pordenone",
    "Prata Sannita",
    "Prato",
    "Prato Sesia",
    "Pratola Peligna",
    "Pratola Serra",
    "Pratovecchio",
    "Pravisdomini",
    "Pray",
    "Predazzo",
    "Predore",
    "Predosa",
    "Preganziol",
    "Premana",
    "Premariacco",
    "Premosello-Chiovenda",
    "Presicce",
    "Pressana",
    "Pretoro",
    "Prevalle",
    "Prezza",
    "Priolo Gargallo",
    "Priverno",
    "Prizzi",
    "Procida",
    "Provesano",
    "Province of Messina",
    "Puglianello",
    "Pugliano",
    "Puianello",
    "Pula",
    "Pulsano",
    "Punta Marina",
    "Putignano",
    "Quadri",
    "Quagliuzzo",
    "Qualiano",
    "Qualso",
    "Quaregna",
    "Quargnento",
    "Quarona",
    "Quarrata",
    "Quart",
    "Quarto",
    "Quarto Inferiore",
    "Quartucciu",
    "Quattordio",
    "Quattro Castella",
    "Quero",
    "Quiliano",
    "Quindici",
    "Quinto di Treviso",
    "Quinto Vercellese",
    "Quinto Vicentino",
    "Quistello",
    "Racale",
    "Racalmuto",
    "Racconigi",
    "Radda in Chianti",
    "Raffadali",
    "Ragogna",
    "Ragusa",
    "Raiano",
    "Raldon",
    "Ramacca",
    "Ramiseto",
    "Ramon",
    "Ramponio",
    "Rancio Valcuvia",
    "Randazzo",
    "Ranieri",
    "Rapagnano",
    "Rapallo",
    "Rapolla",
    "Rassina",
    "Ratschings",
    "Ravanusa",
    "Ravarino",
    "Ravello",
    "Ravenna",
    "Ravi",
    "Recale",
    "Recanati",
    "Recco",
    "Recoaro Terme",
    "Regalbuto",
    "Reggello",
    "Reggio Calabria",
    "Reggiolo",
    "Regina",
    "Regina Margherita",
    "Remanzacco",
    "Renazzo",
    "Rende",
    "Reno",
    "Resana",
    "Rescaldina",
    "Resia",
    "Revello",
    "Revere",
    "Revigliasco",
    "Revine",
    "Rezzato",
    "Rho",
    "Riale",
    "Riano",
    "Ribera",
    "Ribolla",
    "Ricadi",
    "Riccia",
    "Riccione",
    "Riccovolto",
    "Riese Pio X",
    "Riesi",
    "Rieti",
    "Rignano Flaminio",
    "Rignano Garganico",
    "Rimini",
    "Rino",
    "Rio Marina",
    "Rio Saliceto",
    "Riola Sardo",
    "Riolo Terme",
    "Riomaggiore",
    "Rionero in Vulture",
    "Ripa",
    "Ripa Teatina",
    "Ripa-Pozzi-Querceta-Ponterosso",
    "Ripafratta-Farneta",
    "Ripalta Cremasca",
    "Riparbella",
    "Ripatransone",
    "Ripe",
    "Ripe San Ginesio",
    "Ripi",
    "Ripoli",
    "Riposto",
    "Riva",
    "Riva",
    "Riva del Garda",
    "Riva Ligure",
    "Riva presso Chieri",
    "Rivalba",
    "Rivalta",
    "Rivalta di Torino",
    "Rivanazzano",
    "Rivara",
    "Rivarolo del Re ed Uniti",
    "Rivarolo Ligure",
    "Rivarolo Mantovano",
    "Rivello",
    "Rivergaro",
    "Riviera",
    "Rivignano",
    "Rivodutri",
    "Rivoli",
    "Rizzi",
    "Rizziconi",
    "Roasio",
    "Robassomero",
    "Robbiate",
    "Robbio",
    "Robecchetto con Induno",
    "Robecco sul Naviglio",
    "Rocca",
    "Rocca Canavese",
    "Rocca di Papa",
    "Rocca Massima",
    "Rocca Priora",
    "Rocca San Casciano",
    "Roccabernarda",
    "Roccabianca",
    "Roccadaspide",
    "Roccafranca",
    "Roccalumera",
    "Roccamonfina",
    "Roccapiemonte",
    "Roccarainola",
    "Roccaromana",
    "Roccasecca",
    "Roccastrada",
    "Roccavione",
    "Roccavivara",
    "Roccavivi",
    "Roccella",
    "Roccella Ionica",
    "Rocchetta di Vara",
    "Rocco",
    "Rodano",
    "Rodeneck-Rodengo",
    "Rodi Garganico",
    "Rodigo",
    "Roggiano Gravina",
    "Rogliano",
    "Rognano",
    "Roiano",
    "Rolo",
    "Romagnano Sesia",
    "Romana",
    "Romanengo",
    "Romano Canavese",
    "Romano di Lombardia",
    "Rome",
    "Romentino",
    "Rometta",
    "Roncade",
    "Roncadelle",
    "Roncalceci",
    "Roncegno",
    "Ronchi",
    "Ronchi dei Legionari",
    "Ronchis",
    "Ronciglione",
    "Ronco",
    "Ronco Scrivia",
    "Ronco-Chiesa",
    "Roncoferraro",
    "Roncone",
    "Rondissone",
    "Rosa",
    "Rosate",
    "Rosciano",
    "Rose",
    "Roseto Capo Spulico",
    "Roseto degli Abruzzi",
    "Roseto Valfortore",
    "Rosignano Marittimo",
    "Rosignano Solvay-Castiglioncello",
    "Rosolina",
    "Rosolini",
    "Rossano",
    "Rossano Veneto",
    "Rossi",
    "Rossiglione",
    "Rota Greca",
    "Rotella",
    "Rotello",
    "Rotondi",
    "Rottofreno",
    "Rovagnate",
    "Rovato",
    "Rovellasca",
    "Rovello Porro",
    "Roverbella",
    "Rovere",
    "Roveredo",
    "Roveredo in Piano",
    "Rovereto",
    "Rovetta",
    "Roviano",
    "Rovigo",
    "Rovito",
    "Rozzano",
    "Rubano",
    "Rubbiano",
    "Rubiana",
    "Rubiera",
    "Ruda",
    "Rudiano",
    "Ruffano",
    "Rufina",
    "Ruoti",
    "Russi",
    "Russo",
    "Rutigliano",
    "Ruviano",
    "Ruvo di Puglia",
    "Sabatino",
    "Sabaudia",
    "Sabbio Chiese",
    "Sabbioneta",
    "Sacco",
    "Saccolongo",
    "Sacile",
    "Sacrofano",
    "Sagrado",
    "Saint-Pierre",
    "Saint-Vincent",
    "Sala Baganza",
    "Sala Bolognese",
    "Sala Consilina",
    "Salara",
    "Salassa",
    "Sale",
    "Sale Marasino",
    "Salemi",
    "Salento",
    "Salerno",
    "Saletto",
    "Salice Salentino",
    "Saliceto",
    "Saline",
    "Salmour",
    "Salorno",
    "Salsomaggiore Terme",
    "Saltocchio",
    "Saludecio",
    "Saluggia",
    "Saluzzo",
    "Salvaterra",
    "Salve",
    "Salvirola",
    "Salzano",
    "Samarate",
    "Samassi",
    "Sambruson",
    "Sambuceto",
    "Sammichele di Bari",
    "Samone",
    "San Bartolomeo",
    "San Bassano",
    "San Benedetto dei Marsi",
    "San Benedetto del Tronto",
    "San Benedetto Po",
    "San Benedetto Val di Sambro",
    "San Benigno Canavese",
    "San Bernardino",
    "San Biagio",
    "San Biagio di Callalta",
    "San Bonifacio",
    "San Carlo",
    "San Carlo",
    "San Casciano dei Bagni",
    "San Casciano in Val di Pesa",
    "San Cassiano",
    "San Cataldo",
    "San Cesareo",
    "San Cesario di Lecce",
    "San Cesario sul Panaro",
    "San Chirico Raparo",
    "San Cipirello",
    "San Cipriano Picentino",
    "San Colombano al Lambro",
    "San Costanzo",
    "San Daniele del Friuli",
    "San Demetrio Corone",
    "San Dona",
    "San Donaci",
    "San Donato",
    "San Donato",
    "San Donato di Lecce",
    "San Donato Milanese",
    "San Dorligo della Valle",
    "San Fedele",
    "San Felice Circeo",
    "San Felice sul Panaro",
    "San Ferdinando",
    "San Ferdinando di Puglia",
    "San Fili",
    "San Filippo",
    "San Filippo",
    "San Fior",
    "San Fiorano",
    "San Francesco",
    "San Francesco",
    "San Francesco al Campo",
    "San Fratello",
    "San Gavino Monreale",
    "San Gemini",
    "San Genesio ed Uniti",
    "San Gennaro Vesuviano",
    "San Germano Vercellese",
    "San Giacomo",
    "San Giacomo degli Schiavoni",
    "San Gillio",
    "San Gimignano",
    "San Ginesio",
    "San Giorgio",
    "San Giorgio",
    "San Giorgio",
    "San Giorgio",
    "San Giorgio a Cremano",
    "San Giorgio a Liri",
    "San Giorgio Canavese",
    "San Giorgio del Sannio",
    "San Giorgio della Richinvelda",
    "San Giorgio di Lomellina",
    "San Giorgio di Nogaro",
    "San Giorgio di Piano",
    "San Giorgio in Bosco",
    "San Giorgio Ionico",
    "San Giorgio la Molara",
    "San Giorgio Piacentino",
    "San Giovanni",
    "San Giovanni",
    "San Giovanni",
    "San Giovanni",
    "San Giovanni a Piro",
    "San Giovanni al Natisone",
    "San Giovanni Bianco",
    "San Giovanni Gemini",
    "San Giovanni in Croce",
    "San Giovanni in Fiore",
    "San Giovanni in Marignano",
    "San Giovanni in Persiceto",
    "San Giovanni la Punta",
    "San Giovanni Lupatoto",
    "San Giovanni Rotondo",
    "San Giovanni Suergiu",
    "San Giovanni Valdarno",
    "San Giuliano Milanese",
    "San Giuliano Terme",
    "San Giuseppe",
    "San Giuseppe Jato",
    "San Giuseppe Vesuviano",
    "San Giustino",
    "San Giusto Canavese",
    "San Gregorio",
    "San Gregorio",
    "San Gregorio da Sassola",
    "San Gregorio di Catania",
    "San Lazzaro degli Armeni",
    "San Lazzaro di Savena",
    "San Leo",
    "San Lorenzello",
    "San Lorenzo",
    "San Lorenzo",
    "San Lorenzo",
    "San Lorenzo al Mare",
    "San Lorenzo del Vallo",
    "San Lorenzo in Campo",
    "San Lorenzo Isontino",
    "San Luca",
    "San Lucido",
    "San Mango",
    "San Mango Piemonte",
    "San Marcello",
    "San Marcello Pistoiese",
    "San Marco",
    "San Marco",
    "San Marco Argentano",
    "San Marco dei Cavoti",
    "San Marco Evangelista",
    "San Marco in Lamis",
    "San Mariano",
    "San Martino",
    "San Martino",
    "San Martino",
    "San Martino",
    "San Martino",
    "San Martino al Tagliamento",
    "San Martino Buon Albergo",
    "San Martino Canavese",
    "San Martino di Lupari",
    "San Martino in Pensilis",
    "San Martino in Rio",
    "San Martino in Strada",
    "San Martino Sannita",
    "San Martino Siccomario",
    "San Marzano di San Giuseppe",
    "San Marzano sul Sarno",
    "San Maurizio",
    "San Maurizio Canavese",
    "San Mauro",
    "San Mauro Pascoli",
    "San Mauro Torinese",
    "San Michele al Tagliamento",
    "San Michele Prazzo",
    "San Michele Salentino",
    "San Miniato",
    "San Miniato Basso",
    "San Nazario",
    "San Niccolo",
    "San Nicola",
    "San Nicola",
    "San Nicola Arcella",
    "San Nicolo",
    "San Pancrazio Salentino",
    "San Paolo",
    "San Pellegrino Terme",
    "San Piero a Sieve",
    "San Piero in Bagno",
    "San Piero Patti",
    "San Pietro",
    "San Pietro",
    "San Pietro",
    "San Pietro",
    "San Pietro",
    "San Pietro a Maida",
    "San Pietro di Cadore",
    "San Pietro di Feletto",
    "San Pietro in Casale",
    "San Pietro in Gu",
    "San Pietro in Lama",
    "San Pietro Mosezzo",
    "San Pietro Vernotico",
    "San Pietro Viminario",
    "San Polo",
    "San Polo",
    "San Polo di Piave",
    "San Prisco",
    "San Prospero",
    "San Quirino",
    "San Raineri",
    "San Rocco",
    "San Rufo",
    "San Salvatore",
    "San Salvatore",
    "San Salvatore Monferrato",
    "San Salvatore Telesino",
    "San Salvo",
    "San Sebastiano al Vesuvio",
    "San Secondo",
    "San Secondo Parmense",
    "San Severino",
    "San Severino Marche",
    "San Severo",
    "San Sisto",
    "San Sossio Baronia",
    "San Sosti",
    "San Sperate",
    "San Tammaro",
    "San Teodoro",
    "San Valentino Torio",
    "San Venanzio",
    "San Venanzo",
    "San Vendemiano",
    "San Vero Milis",
    "San Vincenzo",
    "San Vito",
    "San Vito al Tagliamento",
    "San Vito Chietino",
    "San Vito dei Normanni",
    "San Vito di Cadore",
    "San Vito Lo Capo",
    "San Vito Romano",
    "San Vittore",
    "San Zaccaria",
    "San Zeno",
    "San Zenone al Lambro",
    "San Zenone al Po",
    "San Zenone degli Ezzelini",
    "Sand in Taufers",
    "Sandigliano",
    "Sandon",
    "Sandrigo",
    "Sangano",
    "Sanguinetto",
    "Sanluri",
    "Sannicandro di Bari",
    "Sannicandro Garganico",
    "Sannicola",
    "Sanremo",
    "Sansepolcro",
    "Sant Angelo",
    "Santa Barbara",
    "Santa Caterina dello Ionio",
    "Santa Caterina Villarmosa",
    "Santa Cristina",
    "Santa Croce",
    "Santa Croce Camerina",
    "Santa Elisabetta",
    "Santa Eufemia Lamezia",
    "Santa Fiora",
    "Santa Flavia",
    "Santa Giuletta",
    "Santa Giusta",
    "Santa Giustina",
    "Santa Giustina in Colle",
    "Santa Lucia",
    "Santa Lucia",
    "Santa Lucia di Piave",
    "Santa Margherita di Belice",
    "Santa Margherita Ligure",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria a Monte",
    "Santa Maria a Vico",
    "Santa Maria Capua Vetere",
    "Santa Maria Codifiume",
    "Santa Maria degli Angeli",
    "Santa Maria di Licodia",
    "Santa Maria di Sala",
    "Santa Maria Imbaro",
    "Santa Maria Nuova",
    "Santa Marinella",
    "Santa Ninfa",
    "Santa Severa",
    "Santa Sofia",
    "Santa Teresa di Riva",
    "Santa Teresa Gallura",
    "Santa Venerina",
    "Santa Vittoria di Libiola",
    "Santa Vittoria in Matenano",
    "Santadi",
    "Santarcangelo di Romagna",
    "Santeramo in Colle",
    "Santi",
    "Santi Cosma e Damiano",
    "Santo Pietro",
    "Santo Spirito",
    "Santo Stefano",
    "Santo Stefano al Mare",
    "Santo Stefano Belbo",
    "Santo Stefano di Briga",
    "Santo Stefano di Cadore",
    "Santo Stefano di Magra",
    "Santo Stefano di Rogliano",
    "Santo Stefano Quisquina",
    "Santo Stefano Ticino",
    "Santo Stino di Livenza",
    "Santorso",
    "Santu Lussurgiu",
    "Saponara",
    "Sapri",
    "Sarcedo",
    "Sarezzo",
    "Sarmede",
    "Sarnano",
    "Sarnico",
    "Sarno",
    "Sarnthein",
    "Saronno",
    "Sarroch",
    "Sarsina",
    "Sarteano",
    "Sartirana Lomellina",
    "Sarzana",
    "Sassa",
    "Sassari",
    "Sassello",
    "Sassetta",
    "Sassinoro",
    "Sasso",
    "Sasso Marconi",
    "Sassoferrato",
    "Sassuolo",
    "Saturnia",
    "Sava",
    "Saviano",
    "Savigliano",
    "Savignano sul Panaro",
    "Savignano sul Rubicone",
    "Savio",
    "Savona",
    "Scafa",
    "Scafati",
    "Scala",
    "Scaldasole",
    "Scalea",
    "Scalenghe",
    "Scaletta Zanclea",
    "Scandiano",
    "Scandicci",
    "Scanno",
    "Scansano",
    "Scanzano",
    "Scanzano Jonico",
    "Scarlino",
    "Scarmagno",
    "Scarperia",
    "Scauri",
    "Scerni",
    "Schiavon",
    "Schio",
    "Schlanders",
    "Sciacca",
    "Scicli",
    "Scilla",
    "Scoppito",
    "Scordia",
    "Scorrano",
    "Scuola",
    "Seano",
    "Seborga",
    "Secondigliano",
    "Sedegliano",
    "Sedico",
    "Sedilo",
    "Sedriano",
    "Segariu",
    "Seglia San Bernardo",
    "Segni",
    "Segrate",
    "Selargius",
    "Selci",
    "Selegas",
    "Sella",
    "Selva",
    "Selvazzano Dentro",
    "Selvino",
    "Seminara",
    "Senago",
    "Senerchia",
    "Seniga",
    "Senigallia",
    "Senise",
    "Sennori",
    "Seravezza",
    "Seregno",
    "Sergnano",
    "Seriate",
    "Serino",
    "Serle",
    "Sermide",
    "Sermoneta",
    "Sernaglia della Battaglia",
    "Serra",
    "Serra Pedace",
    "Serra San Bruno",
    "Serra San Quirico",
    "Serracapriola",
    "Serradifalco",
    "Serraglio",
    "Serramanna",
    "Serramazzoni",
    "Serramezzana",
    "Serrano",
    "Serrara Fontana",
    "Serravalle Pistoiese",
    "Serravalle Scrivia",
    "Serravalle Sesia",
    "Serre",
    "Serrenti",
    "Sersale",
    "Servigliano",
    "Sessa Aurunca",
    "Sesto al Reghena",
    "Sesto Calende",
    "Sesto Fiorentino",
    "Sesto San Giovanni",
    "Sestri Levante",
    "Sestriere",
    "Sestu",
    "Settimo",
    "Settimo San Pietro",
    "Settimo Torinese",
    "Settimo Vittone",
    "Seveso",
    "Sexten",
    "Sezze",
    "Sgonico",
    "Sgurgola",
    "Siamaggiore",
    "Siamanna",
    "Siano",
    "Siapiccia",
    "Sibari",
    "Sicignano degli Alburni",
    "Siculiana",
    "Siderno",
    "Siena",
    "Sigillo",
    "Signa",
    "Silanus",
    "Silea",
    "Siliqua",
    "Sillavengo",
    "Silvi",
    "Silvi Paese",
    "Simaxis",
    "Simone",
    "Sinalunga",
    "Sindia",
    "Siniscola",
    "Sinnai",
    "Sinopoli",
    "Sirignano",
    "Sirmione",
    "Siziano",
    "Sizzano",
    "Soave",
    "Soave",
    "Soci",
    "Sogliano Cavour",
    "Solagna",
    "Solarino",
    "Solaro",
    "Solarolo",
    "Solarolo",
    "Solato",
    "Solbiate Arno",
    "Solbiate Olona",
    "Solda",
    "Soldano",
    "Soleminis",
    "Solesino",
    "Soleto",
    "Soliera",
    "Solignano Nuovo",
    "Solofra",
    "Solopaca",
    "Solto Collina",
    "Somma Lombardo",
    "Somma Vesuviana",
    "Sommacampagna",
    "Sommariva del Bosco",
    "Sommariva Perno",
    "Sommatino",
    "Sona",
    "Soncino",
    "Sondalo",
    "Sondrio",
    "Sonnino",
    "Sora",
    "Soragna",
    "Sorbolo",
    "Sordevolo",
    "Soresina",
    "Sorgono",
    "Sori",
    "Soriano nel Cimino",
    "Sorisole",
    "Sorrento",
    "Sorso",
    "Sortino",
    "Sospiro",
    "Sospirolo",
    "Sossano",
    "Sotto Castello",
    "Sotto il Monte Giovanni XXIII",
    "Sottomarina",
    "Soverato Marina",
    "Sovere",
    "Soveria Mannelli",
    "Sovicille",
    "Spadafora",
    "Sparanise",
    "Sparone",
    "Specchia",
    "Spello",
    "Sperlonga",
    "Sperone",
    "Spezzano Albanese",
    "Spezzano della Sila",
    "Spigno Saturnia",
    "Spilamberto",
    "Spilimbergo",
    "Spin",
    "Spina",
    "Spinazzola",
    "Spinea",
    "Spineda",
    "Spinete",
    "Spineto",
    "Spinetoli",
    "Spinetta Marengo",
    "Spirano",
    "Spoleto",
    "Spoltore",
    "Spotorno",
    "Spresiano",
    "Squillace",
    "Squinzano",
    "Staffolo",
    "Stagno",
    "Stanghella",
    "Staranzano",
    "Statte",
    "Stazione Castelguelfo",
    "Stazione Ponte Rio",
    "Stazzano",
    "Stefania",
    "Stella Cilento",
    "Sterzing",
    "Stezzano",
    "Stia",
    "Stienta",
    "Stigliano",
    "Stimigliano",
    "Stornarella",
    "Storo",
    "Stra",
    "Strada San Zeno",
    "Stradella",
    "Strambino",
    "Stresa",
    "Stretti",
    "Strevi",
    "Striano",
    "Strigno",
    "Strona",
    "Stroncone",
    "Strongoli",
    "Stroppiana",
    "Sturno",
    "Suardi",
    "Subbiano",
    "Subiaco",
    "Sulmona",
    "Sulzano",
    "Sumirago",
    "Suno",
    "Supino",
    "Surbo",
    "Susa",
    "Susegana",
    "Sutri",
    "Sutrio",
    "Suzzara",
    "Syracuse",
    "Taggia",
    "Tagliacozzo",
    "Taglio di Po",
    "Taibon Agordino",
    "Taino",
    "Taio",
    "Talsano",
    "Taormina",
    "Taranta Peligna",
    "Tarantasca",
    "Taranto",
    "Tarcento",
    "Tarquinia",
    "Tarvisio",
    "Tarzo",
    "Tassullo",
    "Taurano",
    "Taurianova",
    "Tavagnacco",
    "Tavarnelle Val di Pesa",
    "Tavarnuzze",
    "Taverna",
    "Tavernola Bergamasca",
    "Taviano",
    "Tavullia",
    "Teggiano",
    "Teglio",
    "Teglio Veneto",
    "Telese",
    "Telgate",
    "Tempio Pausania",
    "Tenno",
    "Teolo",
    "Teramo",
    "Terenten",
    "Terenzo",
    "Terlan",
    "Terlizzi",
    "Termeno",
    "Termini Imerese",
    "Termoli",
    "Ternate",
    "Terni",
    "Terracina",
    "Terralba",
    "Terranova da Sibari",
    "Terranuova Bracciolini",
    "Terrarossa",
    "Terrasini",
    "Terricciola",
    "Terruggia",
    "Tertenia",
    "Terzigno",
    "Terzo",
    "Terzorio",
    "Tessera",
    "Tezze sul Brenta",
    "Thiene",
    "Thiesi",
    "Ticengo",
    "Tina",
    "Tione di Trento",
    "Tirano",
    "Tiriolo",
    "Tirrenia",
    "Tito",
    "Tivoli",
    "Toblach",
    "Todi",
    "Toffia",
    "Toirano",
    "Tolentino",
    "Tolfa",
    "Tollegno",
    "Tolmezzo",
    "Tolve",
    "Tomba",
    "Tombolo",
    "Tonezza del Cimone",
    "Torano Castello",
    "Torchiarolo",
    "Torella dei Lombardi",
    "Torgiano",
    "Torgnon",
    "Toritto",
    "Tornaco",
    "Tornareccio",
    "Toro",
    "Torrazza",
    "Torre",
    "Torre",
    "Torre a Mare",
    "Torre Annunziata",
    "Torre Boldone",
    "Torre del Greco",
    "Torre del Lago Puccini",
    "Torre di Ruggiero",
    "Torre Orsaia",
    "Torre Pellice",
    "Torre San Patrizio",
    "Torre Santa Susanna",
    "Torrebelvicino",
    "Torrecuso",
    "Torreglia",
    "Torregrotta",
    "Torrenova",
    "Torretta",
    "Torrevecchia Teatina",
    "Torri",
    "Torri del Benaco",
    "Torri di Quartesolo",
    "Torri in Sabina",
    "Torriana",
    "Torrice",
    "Torricella",
    "Torricella",
    "Torrile",
    "Torrita di Siena",
    "Torrita Tiberina",
    "Tortona",
    "Tortora",
    "Tortoreto",
    "Torviscosa",
    "Toscolano-Maderno",
    "Tossicia",
    "Trabia",
    "Tradate",
    "Tramonti",
    "Tramutola",
    "Trana",
    "Trani",
    "Transacqua",
    "Traona",
    "Trapani",
    "Trappeto",
    "Trasacco",
    "Tratalias",
    "Travagliato",
    "Travedona Monate",
    "Traversetolo",
    "Travo",
    "Trebaseleghe",
    "Trebisacce",
    "Trecastagni",
    "Trecate",
    "Trecenta",
    "Tredozio",
    "Treglio",
    "Tregnago",
    "Treia",
    "Trento",
    "Trentola-Ducenta",
    "Trepuzzi",
    "Trequanda",
    "Tres",
    "Trescore Balneario",
    "Trescore Cremasco",
    "Tresenda",
    "Trevenzuolo",
    "Trevi",
    "Trevico",
    "Treviglio",
    "Trevignano",
    "Trevignano Romano",
    "Treviso",
    "Trezzano Rosa",
    "Trezzano sul Naviglio",
    "Tribiano",
    "Tricarico",
    "Tricase",
    "Tricesimo",
    "Trichiana",
    "Trieste",
    "Triggianello",
    "Triggiano",
    "Trigolo",
    "Trinitapoli",
    "Trino",
    "Trissino",
    "Triuggio",
    "Trivento",
    "Trivero",
    "Trivignano",
    "Trivignano Udinese",
    "Trofarello",
    "Troia",
    "Troina",
    "Tromello",
    "Tronzano Vercellese",
    "Tropea",
    "Trovo",
    "Truccazzano",
    "Tufino",
    "Tuglie",
    "Tuili",
    "Tuoro sul Trasimeno",
    "Turbigo",
    "Turi",
    "Turin",
    "Turriaco",
    "Tursi",
    "Tusa",
    "Tuscania",
    "Udine",
    "Ugento",
    "Uggiate Trevano",
    "Umbertide",
    "Urbania",
    "Urbino",
    "Urbisaglia",
    "Urgnano",
    "Ururi",
    "Uscio",
    "Usellus",
    "Usini",
    "Usmate Velate",
    "Ussana",
    "Uta",
    "Uzzano",
    "Vacri",
    "Vadena",
    "Vado",
    "Vado Ligure",
    "Vaglia",
    "Vaglio Basilicata",
    "Vahrn",
    "Vaiano",
    "Vaiano Cremasco",
    "Vailate",
    "Vairano Patenora",
    "Vajont",
    "Valdagno",
    "Valdengo",
    "Valdieri",
    "Valdobbiadene",
    "Valduggia",
    "Valeggio sul Mincio",
    "Valentano",
    "Valentina",
    "Valenza",
    "Valenzano",
    "Valfabbrica",
    "Valfenera",
    "Valganna",
    "Valguarnera Caropepe",
    "Vallata",
    "Valle",
    "Valle",
    "Valle",
    "Valle Agricola",
    "Valle di Cadore",
    "Valle Lomellina",
    "Valle Mosso",
    "Vallecrosia",
    "Vallefiorita",
    "Vallerano",
    "Valli del Pasubio",
    "Vallo",
    "Vallo della Lucania",
    "Vallombrosa",
    "Valmadrera",
    "Valmontone",
    "Valperga",
    "Valverde",
    "Vanzaghello",
    "Vanzago",
    "Varallo Pombia",
    "Varallo Sesia",
    "Varano",
    "Varapodio",
    "Varazze",
    "Varedo",
    "Varese",
    "Varese Ligure",
    "Varzo",
    "Vas",
    "Vasanello",
    "Vascon",
    "Vasto",
    "Vattaro",
    "Vecchiano-Nodica",
    "Vedano Olona",
    "Vedelago",
    "Veglie",
    "Velletri",
    "Vellezzo Bellini",
    "Vena di Maida",
    "Venafro",
    "Venaria Reale",
    "Venarotta",
    "Venaus",
    "Venegono Inferiore",
    "Venegono Superiore",
    "Venetico",
    "Veneto",
    "Venice",
    "Venosa",
    "Ventimiglia",
    "Ventimiglia di Sicilia",
    "Venturina",
    "Verbicaro",
    "Vercelli",
    "Vercurago",
    "Verdellino",
    "Verdello",
    "Vergano-Villa",
    "Vergato",
    "Vergiate",
    "Vermezzo",
    "Vernate",
    "Vernio",
    "Vernole",
    "Verolanuova",
    "Verolavecchia",
    "Verolengo",
    "Veroli",
    "Verona",
    "Veronella",
    "Verrone",
    "Vertova",
    "Verucchio",
    "Verzuolo",
    "Vescovana",
    "Vescovato",
    "Vespolate",
    "Vestone",
    "Vetralla",
    "Vezzano Ligure",
    "Vezzano sul Crostolo",
    "Viadana",
    "Viagrande",
    "Viano",
    "Viareggio",
    "Vibo Valentia",
    "Vicarello",
    "Vicari",
    "Vicchio",
    "Vicenza",
    "Vico Equense",
    "Vicoforte",
    "Vicolungo",
    "Vicopisano",
    "Vicovaro",
    "Vidardo",
    "Vidiciatico",
    "Vidigulfo",
    "Vieste",
    "Vietri sul Mare",
    "Vigarano Mainarda",
    "Vigarano Pieve",
    "Vigasio",
    "Vigevano",
    "Vigliano Biellese",
    "Vignacastrisi",
    "Vignale Monferrato",
    "Vignanello",
    "Vignate",
    "Vignola",
    "Vigo di Cadore",
    "Vigo di Fassa",
    "Vigo Rendena",
    "Vigodarzere",
    "Vigolo Vattaro",
    "Vigolzone",
    "Vigone",
    "Vigonovo",
    "Vigonza",
    "Viguzzolo",
    "Villa",
    "Villa",
    "Villa Bartolomea",
    "Villa Basilica",
    "Villa Castelli",
    "Villa Cortese",
    "Villa del Conte",
    "Villa di Serio",
    "Villa di Tirano",
    "Villa Estense",
    "Villa Faraldi",
    "Villa Guardia",
    "Villa Lagarina",
    "Villa Literno",
    "Villa Marina",
    "Villa Minozzo",
    "Villa Morelli",
    "Villa Ottone",
    "Villa San Giovanni",
    "Villa San Pietro",
    "Villa San Sebastiano",
    "Villa Santa Lucia",
    "Villa Verucchio",
    "Villa Vicentina",
    "Villabate",
    "Villacidro",
    "Villadose",
    "Villafranca di Forli",
    "Villafranca di Verona",
    "Villafranca in Lunigiana",
    "Villafranca Piemonte",
    "Villafranca Tirrena",
    "Villafrati",
    "Villaggio",
    "Villaggio Montegrappa",
    "Villagrazia",
    "Villalago",
    "Villamagna",
    "Villamar",
    "Villamassargia",
    "Villanova",
    "Villanova",
    "Villanova",
    "Villanova del Ghebbo-Valdentro",
    "Villanova Monferrato",
    "Villanovafranca",
    "Villanterio",
    "Villanuova sul Clisi",
    "Villapiana",
    "Villaputzu",
    "Villar",
    "Villaricca",
    "Villarosa",
    "Villasalto",
    "Villasanta",
    "Villasmundo",
    "Villasor",
    "Villaspeciosa",
    "Villastellone",
    "Villastrada",
    "Villaurbana",
    "Villaverla",
    "Villavesco",
    "Ville",
    "Villetta",
    "Villetta",
    "Vimercate",
    "Vimodrone",
    "Vinci",
    "Viola",
    "Virgilio",
    "Viscone",
    "Viserba",
    "Visinale",
    "Vistrorio",
    "Viterbo",
    "Vitigliano",
    "Vitorchiano",
    "Vittoria",
    "Vittorio Veneto",
    "Vittuone",
    "Vitulano",
    "Vitulazio",
    "Vivaro",
    "Vizzini",
    "Vo",
    "Vobarno",
    "Voghenza",
    "Voghera",
    "Vogogna",
    "Volano",
    "Volla",
    "Volpago del Montello",
    "Volpedo",
    "Volpiano",
    "Volta Mantovana",
    "Voltago",
    "Volterra",
    "Volturara Irpina",
    "Volturino",
    "Volvera",
    "Welsberg-Taisten",
    "Welschnofen",
    "Zafferana Etnea",
    "Zagarolo",
    "Zambana",
    "Zambrone",
    "Zandobbio",
    "Zanetti",
    "Zangarona",
    "Zanica",
    "Zelarino",
    "Zelo",
    "Zelo Buon Persico",
    "Zeme",
    "Zerbo",
    "Zerfaliu",
    "Zermeghedo",
    "Zero Branco",
    "Zevio",
    "Zibido San Giacomo",
    "Zimella",
    "Zimone",
    "Zinasco",
    "Zoagli",
    "Zocca",
    "Zogno",
    "Zola Predosa",
    "Zoldo Alto",
    "Zollino",
    "Zoppola",
    "Zumaglia",
  ],
  Jamaica: [
    "Black River",
    "Browns Town",
    "Gordon Town",
    "Gregory Park",
    "Kingston",
    "Mandeville",
    "May Pen",
    "Moneague",
    "Montego Bay",
    "Negril",
    "Ocho Rios",
    "Old Harbour",
    "Port Maria",
    "Portland Cottage",
    "Portmore",
    "Runaway Bay",
    "Spanish Town",
  ],
  Japan: [
    "Abashiri",
    "Abiko",
    "Abira",
    "Aboshiku-okinohama",
    "Agano",
    "Agena",
    "Ageo",
    "Aikawa",
    "Aioi",
    "Aisai",
    "Aitsu",
    "Aizu-Wakamatsu",
    "Akao",
    "Akasaka",
    "Akasaka",
    "Akasaki",
    "Akashi",
    "Akashi",
    "Akeno",
    "Aki",
    "Akiruno-shi",
    "Akishima",
    "Akita",
    "Ama-higashimachi",
    "Amagasaki",
    "Ami",
    "Anabuki",
    "Anan",
    "Anesaki",
    "Anjo",
    "Annaka",
    "Aoicho",
    "Aomori",
    "Arai",
    "Arakawa",
    "Ariake",
    "Arida",
    "Arita",
    "Asahi",
    "Asahi",
    "Asahikawa",
    "Asahimachi",
    "Asaka",
    "Ashihara",
    "Ashikaga",
    "Ashiya",
    "Aso",
    "Atami",
    "Atsugi",
    "Awara",
    "Ayase",
    "Azuchi",
    "Azumino",
    "Bakurocho",
    "Bakurocho",
    "Bandaicho",
    "Bando",
    "Beppu",
    "Bibai",
    "Budo",
    "Chatan",
    "Chiba",
    "Chichibu",
    "Chigasaki",
    "Chiji",
    "Chikusei",
    "Chino",
    "Chiran",
    "Chiryu",
    "Chita",
    "Chitose",
    "Choshi",
    "Chuo",
    "Daido",
    "Daigo",
    "Daimon",
    "Daisen",
    "Daitocho",
    "Date",
    "Ebetsu",
    "Ebina",
    "Ebisu",
    "Echizen",
    "Echizen",
    "Edagawa",
    "Edogawa-ku",
    "Ena",
    "Eniwa-shi",
    "Esashi",
    "Fuchu-machi",
    "Fuchucho",
    "Fuefuki-shi",
    "Fuji",
    "Fujieda",
    "Fujiidera",
    "Fujimi",
    "Fujimino",
    "Fujinomiya",
    "Fujioka",
    "Fujisato",
    "Fujisawa",
    "Fujita",
    "Fukai",
    "Fukayacho",
    "Fukiage-fujimi",
    "Fukuchi",
    "Fukuchiyama",
    "Fukuda",
    "Fukui",
    "Fukui-shi",
    "Fukuoka",
    "Fukuroi",
    "Fukushima-shi",
    "Fukuyama",
    "Fumoto",
    "Funabashi",
    "Fushiki-minatomachi",
    "Fushimi",
    "Fussa",
    "Futoo",
    "Futtsu",
    "Gamagori",
    "Gannosu",
    "Geinocho-mukumoto",
    "Gifu",
    "Ginowan",
    "Ginza",
    "Gobo",
    "Godo",
    "Gojo",
    "Gosen",
    "Goshogawara",
    "Gotanda",
    "Gotanda",
    "Gotemba",
    "Gotsucho",
    "Goya",
    "Gujo",
    "Gundo",
    "Gushikawa",
    "Gyoda",
    "Habikino",
    "Hachiman-cho",
    "Hachimantai",
    "Hachimori",
    "Hachinohe",
    "Hadano",
    "Haebaru",
    "Hagi",
    "Hagi",
    "Haibara-akanedai",
    "Haijima",
    "Hakata",
    "Hakodate",
    "Hakone",
    "Hakozaki",
    "Hakui",
    "Hama",
    "Hamada",
    "Hamamatsu",
    "Hamana",
    "Hamura",
    "Hanabatacho",
    "Hanamaki",
    "Hanawadamachi",
    "Handa",
    "Hannan",
    "Hanno",
    "Hanyu",
    "Harigaya",
    "Hashima",
    "Hashimoto",
    "Hashimoto",
    "Hasuda",
    "Hatogaya-honcho",
    "Hatoyama",
    "Hatsukaichi",
    "Hayama",
    "Hayashi",
    "Hayashima",
    "Hayashino",
    "Heiwajima",
    "Hekinan",
    "Hidaka",
    "Hidakacho-ebara",
    "Higashi-iwashiro",
    "Higashi-takadama",
    "Higashiasahimachi",
    "Higashiboridori",
    "Higashicho",
    "Higashiiya-takano",
    "Higashiiya-wakabayashi",
    "Higashikawa",
    "Higashimurayama-shi",
    "Higashine",
    "Higashiomi-shi",
    "Higashiosaka",
    "Higashiura",
    "Higashiyama",
    "Higashiyamato",
    "Hiji",
    "Hikari",
    "Hikawa",
    "Hiki",
    "Hikone",
    "Himeji",
    "Himi",
    "Hinata",
    "Hino",
    "Hino",
    "Hinode",
    "Hirakata",
    "Hirakawa",
    "Hiranuma",
    "Hiratsuka",
    "Hirokawa",
    "Hirosaki",
    "Hiroshima",
    "Hita",
    "Hitachi",
    "Hitoyoshi",
    "Hiyoshi",
    "Hodogayacho",
    "Hofu",
    "Hojo",
    "Hokota",
    "Hokuto-shi",
    "Hommachi",
    "Honcho",
    "Honcho",
    "Honden",
    "Hongo",
    "Honjo",
    "Honjo",
    "Honjocho",
    "Horinouchi",
    "Horyuji",
    "Hosobara",
    "Hotei",
    "Hyogo",
    "Ibara",
    "Ibaraki",
    "Ichi",
    "Ichibanhoridoricho",
    "Ichihara",
    "Ichikawa",
    "Ichimura",
    "Ichinogo",
    "Ichinomiya",
    "Ichinomiya",
    "Ichinomiyacho",
    "Ichinomotocho",
    "Ichinoseki",
    "Ida",
    "Iida",
    "Iiyama",
    "Iizuka",
    "Iju",
    "Ikaruga",
    "Ikeda",
    "Ikedacho-itano",
    "Ikejiri",
    "Ikoma",
    "Ikuno",
    "Imabari-shi",
    "Imabuku",
    "Imagami",
    "Imaicho",
    "Imari",
    "Imizucho",
    "Ina",
    "Inabe",
    "Inage",
    "Inagi-shi",
    "Inami",
    "Inami",
    "Inashiki",
    "Inazawa",
    "Innoshima Hara-cho",
    "Ino",
    "Inuyama",
    "Inzai",
    "Iruma",
    "Isahaya",
    "Isawa",
    "Ise",
    "Isehara",
    "Isesaki",
    "Ishida",
    "Ishigaki",
    "Ishige",
    "Ishii",
    "Ishikari",
    "Ishikawa",
    "Ishikawa",
    "Ishiki",
    "Ishinomaki",
    "Ishioka",
    "Isomura",
    "Isoya",
    "Isumi",
    "Itabashi",
    "Itako",
    "Itami",
    "Itoi",
    "Itoigawa",
    "Itoman",
    "Iwade",
    "Iwaki",
    "Iwakiri",
    "Iwakuni",
    "Iwakura",
    "Iwamizawa",
    "Iwanai",
    "Iwanuma",
    "Iwata",
    "Iwatsuki",
    "Izu",
    "Izumi",
    "Izumiotsu",
    "Izumo",
    "Izunokuni",
    "Jissen",
    "Joetsu",
    "Jogo",
    "Joshi",
    "Joso",
    "Jumonji",
    "Kabe",
    "Kadoma",
    "Kaga",
    "Kagawa",
    "Kagoshima",
    "Kagoya",
    "Kahoku",
    "Kahoku",
    "Kaigandori",
    "Kainan",
    "Kaisei",
    "Kaita",
    "Kaizu",
    "Kaizuka",
    "Kakamigahara",
    "Kakegawa",
    "Kakogawa",
    "Kakuda",
    "Kamagaya-shi",
    "Kamaishi",
    "Kamakura",
    "Kameda-honcho",
    "Kameoka",
    "Kameoka",
    "Kameyama",
    "Kami-kawakami",
    "Kami-nagarekawacho",
    "Kamibun",
    "Kamigori",
    "Kamikawa",
    "Kamikita-kita",
    "Kamimura",
    "Kaminokawa",
    "Kaminoyama",
    "Kamirenjaku",
    "Kamisu",
    "Kamitosa",
    "Kamo",
    "Kamo",
    "Kamogatacho-kamogata",
    "Kamoi",
    "Kanada",
    "Kanagawa",
    "Kanaya",
    "Kanayama",
    "Kanazawa",
    "Kanda",
    "Kanegasaki",
    "Kani",
    "Kanie",
    "Kano",
    "Kanomata",
    "Kanuma",
    "Kanzaki",
    "Kanzaki",
    "Karatsu",
    "Kariya",
    "Kariya",
    "Kasai",
    "Kasai",
    "Kasama",
    "Kasamatsucho",
    "Kasaoka",
    "Kaseda-shirakame",
    "Kashiba",
    "Kashihara",
    "Kashima",
    "Kashima-shi",
    "Kashiwa",
    "Kashiwagimachi",
    "Kashiwara",
    "Kashiwazaki",
    "Kasuga",
    "Kasugai",
    "Kasukabe",
    "Kasumicho",
    "Kasumicho",
    "Kasumigaseki",
    "Kasumigaura",
    "Kataoka",
    "Katori-shi",
    "Katsumada",
    "Katsura",
    "Katsuura",
    "Katsuyama",
    "Katta",
    "Kawagoe",
    "Kawaguchi",
    "Kawahara",
    "Kawanishi",
    "Kawasaki",
    "Kayano",
    "Kazo",
    "Kazuka",
    "Kazuno",
    "Kesennuma",
    "Keta",
    "Kichijoji-honcho",
    "Kii-nagashimaku-nagashima",
    "Kikuchi",
    "Kikugawa",
    "Kikyo",
    "Kimitsu",
    "Kinokawa",
    "Kinoshita",
    "Kiryu",
    "Kisarazu",
    "Kishiwada",
    "Kita",
    "Kita-sannomaru",
    "Kitagata",
    "Kitahiroshima",
    "Kitaibaraki",
    "Kitakami",
    "Kitakata",
    "Kitakyushu",
    "Kitami",
    "Kitamoto",
    "Kitanagoya",
    "Kitaozuma",
    "Kitsuki",
    "Kitsuregawa",
    "Kiyama",
    "Kiyokawa",
    "Kiyose",
    "Kiyosu",
    "Kobe",
    "Kobe",
    "Kochi-shi",
    "Kochino",
    "Kocho",
    "Kodaira",
    "Kodamacho-kodamaminami",
    "Kofu",
    "Koga",
    "Koga",
    "Koganei",
    "Kojima",
    "Koka",
    "Kokawa",
    "Kokubu-matsuki",
    "Kokubunji",
    "Kokufu",
    "Kokura",
    "Kokuryo",
    "Komaba",
    "Komae-shi",
    "Komagane",
    "Komagawa",
    "Komagome",
    "Komaki",
    "Komatsu",
    "Komatsu",
    "Komatsubara",
    "Komatsushimacho",
    "Komono",
    "Komoro",
    "Konan",
    "Konancho-fukawa",
    "Konosu",
    "Koori",
    "Koriyama",
    "Koriyama",
    "Koryocho-itazu",
    "Kosai",
    "Kose",
    "Koshigaya",
    "Koto",
    "Kotohira",
    "Kotoku",
    "Koyama",
    "Koyasan",
    "Koyo",
    "Koza",
    "Kubo",
    "Kudamatsu",
    "Kugayama",
    "Kukichuo",
    "Kumagaya",
    "Kumamoto",
    "Kumano",
    "Kunitachi",
    "Kurami",
    "Kurashiki",
    "Kurayoshi",
    "Kure",
    "Kure",
    "Kurihara",
    "Kurihashi",
    "Kurobeshin",
    "Kurogi",
    "Kuroishi",
    "Kuroiwa",
    "Kurume",
    "Kusai",
    "Kusatsu",
    "Kuse",
    "Kushimoto",
    "Kushiro",
    "Kusunoki",
    "Kutchan",
    "Kuwana-shi",
    "Kyodo",
    "Kyoritsu",
    "Kyoto",
    "Kyowa",
    "Machi",
    "Machi",
    "Machi",
    "Machi",
    "Machida",
    "Machiya",
    "Maebaru",
    "Maebashi",
    "Maibara",
    "Maizuru",
    "Maki",
    "Makinohara",
    "Makuhari",
    "Marugame",
    "Marunouchi",
    "Maruyama",
    "Masaki",
    "Mashiko",
    "Masuda",
    "Matsubara",
    "Matsudo",
    "Matsue-shi",
    "Matsukawamachi",
    "Matsumoto",
    "Matsusaka",
    "Matsuto",
    "Matsuyama",
    "Matsuyama",
    "Matsuyama",
    "Meguro",
    "Meiji",
    "Mibu",
    "Midori",
    "Miemachi",
    "Mihama",
    "Mihama",
    "Mihara",
    "Mihara",
    "Miki",
    "Mima",
    "Mimasaka",
    "Minabe",
    "Minamata",
    "Minami",
    "Minami",
    "Minamiaoyama",
    "Minamiashigara",
    "Minamiuonuma",
    "Minato",
    "Minato",
    "Minatogawa",
    "Minatomachi",
    "Minatomachi",
    "Mine",
    "Mineyama",
    "Mino",
    "Mino",
    "Minobu",
    "Minokamo",
    "Minoshima",
    "Minowa",
    "Misakicho",
    "Misakimachi-konami",
    "Misato",
    "Misawa",
    "Mishima",
    "Mitai",
    "Mitake",
    "Mito-shi",
    "Mitsucho-iwami",
    "Mitsui",
    "Mitsukaido",
    "Mitsuke",
    "Mitsushimacho-osaki",
    "Miura",
    "Miwa",
    "Miyagi",
    "Miyako",
    "Miyakojima",
    "Miyakonojo",
    "Miyama",
    "Miyata",
    "Miyazaki-shi",
    "Miyazu",
    "Miyoshi",
    "Miyoshi",
    "Mizugama",
    "Mizuko",
    "Mizunami",
    "Mizusawa",
    "Mizushima-nishichidoricho",
    "Mobara",
    "Mochida",
    "Moji",
    "Mombetsu",
    "Momoishi",
    "Monzenmachi-igisu",
    "Mooka",
    "Mori",
    "Moriguchi",
    "Morioka",
    "Moriya",
    "Moriyama",
    "Morodomitsu",
    "Morohongo",
    "Morowa",
    "Motegi",
    "Motomiya",
    "Muikamachi",
    "Muko",
    "Murai",
    "Murakami",
    "Murata",
    "Murayama",
    "Muroran",
    "Muroto-misakicho",
    "Musa",
    "Musashimurayama",
    "Musashino",
    "Mutsu",
    "Mutsumi",
    "Nabari",
    "Nada",
    "Naebocho",
    "Nagahama",
    "Nagano-shi",
    "Naganohara",
    "Nagao",
    "Nagaoka",
    "Nagaoka",
    "Nagareyama",
    "Nagasaki",
    "Nagase",
    "Nagashima",
    "Nagato",
    "Nagawado",
    "Nago",
    "Nagoya-shi",
    "Naha",
    "Naitobokujo",
    "Naka",
    "Nakada",
    "Nakagawa",
    "Nakagomi",
    "Nakagusuku",
    "Nakahai",
    "Nakahata",
    "Nakaichi",
    "Nakama",
    "Nakamura",
    "Nakanishi",
    "Nakano",
    "Nakano",
    "Nakano-kami",
    "Nakanoshima",
    "Nakanoshima",
    "Nakatsu",
    "Nakatsugawa",
    "Nakayama",
    "Nakazawa",
    "Nakijin",
    "Namerikawa",
    "Nanae",
    "Nanao",
    "Nanto",
    "Nara",
    "Nara",
    "Narashino-shi",
    "Narita",
    "Narutocho-mitsuishi",
    "Nasa",
    "Nasukarasuyama",
    "Natori-shi",
    "Natsumi",
    "Nayoro",
    "Nemuro",
    "Nerima-ku",
    "Neyagawa",
    "Nichinan",
    "Nihommatsu",
    "Niigata",
    "Niihama",
    "Niimi",
    "Niitsu",
    "Niiza",
    "Nikaho",
    "Ninohe",
    "Ninomiya",
    "Nirasaki",
    "Nishi",
    "Nishi-Tokyo-shi",
    "Nishihara",
    "Nishikawa",
    "Nishikicho",
    "Nishiko",
    "Nishimachi",
    "Nishime",
    "Nishinomiya",
    "Nishio",
    "Nishiwaki",
    "Nishiyama",
    "Nisshin",
    "Nita",
    "Nobeoka",
    "Nobitome",
    "Noboribetsu",
    "Noda",
    "Nodani",
    "Nogata",
    "Noguchi",
    "Nomimachi",
    "Nomura",
    "None-mura",
    "Nonoichi",
    "Nose",
    "Noshiro",
    "Numazu",
    "Nyuzen",
    "Oarai",
    "Obama",
    "Obanazawa",
    "Obari",
    "Obayashi",
    "Obihiro",
    "Obu",
    "Obuse",
    "Ochiai",
    "Ochiai",
    "Ochikawa",
    "Odaira",
    "Odawara",
    "Odori",
    "Ogaki",
    "Ogawa",
    "Ogi",
    "Ogori-shimogo",
    "Oguni",
    "Ogura",
    "Ohara",
    "Oiso",
    "Oiwake",
    "Oizu",
    "Ojiya",
    "Okawa",
    "Okawachi",
    "Okaya",
    "Okayama",
    "Okazaki",
    "Okegawa",
    "Okinawa",
    "Okubo",
    "Okuchi",
    "Okuma",
    "Okuura",
    "Omachi",
    "Omaezaki",
    "Omagari",
    "Ome",
    "Omihachiman",
    "Omiya",
    "Omiya-ku",
    "Omori",
    "Omura",
    "Omuta",
    "Onna",
    "Ono",
    "Onoda",
    "Onogawa",
    "Onojo",
    "Onomichi",
    "Onuma",
    "Osaka",
    "Osaka",
    "Osaki",
    "Osawa",
    "Oshima",
    "Oshu",
    "Ota",
    "Otake",
    "Otani",
    "Otaru",
    "Otawara",
    "Otemachi",
    "Otsukacho",
    "Otsuki",
    "Otsuku-kibi",
    "owariasahi",
    "Oyabe",
    "Oyama",
    "Oyama",
    "Oyama",
    "Oyamato",
    "Ozu",
    "Raiba",
    "Rankoshi-cho",
    "Rifu",
    "Ritto",
    "Roppongi",
    "Ryugasaki",
    "Ryuo",
    "Sabae",
    "Sado",
    "Saga-shi",
    "Sagae",
    "Sagamihara-shi",
    "Saidaiji-naka",
    "Saijo",
    "Saikai",
    "Saiki",
    "Saitama",
    "Sakado",
    "Sakae",
    "Sakai",
    "Sakaidecho",
    "Sakaiminato",
    "Sakata",
    "Saku",
    "Sakura",
    "Sakura",
    "Sakuracho",
    "Sakurai",
    "Sakurajima-koikecho",
    "Sakurakabu",
    "Sandacho",
    "Sango",
    "Sanjo",
    "Sanmu",
    "Sanno",
    "Sannohe",
    "Sano",
    "Sanuki",
    "Sapporo",
    "Sarugakucho",
    "Sasaguri",
    "Sasayama",
    "Sasebo",
    "Satocho-sato",
    "Satte",
    "Sawara",
    "Sayama",
    "Sayama",
    "Sayo",
    "Seiro",
    "Sekicho-furumaya",
    "Senboku",
    "Sendai",
    "Sendai",
    "Senju",
    "Sennan",
    "Senriyama",
    "Senzoku",
    "Seta",
    "Setagaya-ku",
    "Seto",
    "Settsu",
    "Shiba-Kaigandori",
    "Shibadaimon",
    "Shibata",
    "Shibaura",
    "Shibukawa",
    "Shichibancho",
    "Shijonawate",
    "Shikamaku-miya",
    "Shiki",
    "Shima",
    "Shimabara",
    "Shimada",
    "Shimizukoji",
    "Shimizumachi",
    "Shimo-iino",
    "Shimoda",
    "Shimogo",
    "Shimomeguro",
    "Shimonoseki",
    "Shimotoda",
    "Shimotsucho-kominami",
    "Shimotsuke",
    "Shin",
    "Shin-shigai",
    "Shinagawa",
    "Shingu",
    "Shingu",
    "Shinjo",
    "Shinjuku",
    "Shinkawa",
    "Shinmachi",
    "Shinshiro",
    "Shinshuku",
    "Shinsuka",
    "Shiobaru",
    "Shiogama",
    "Shiojiri",
    "Shioya",
    "Shirakawa-machi",
    "Shiraoka",
    "Shirayamamachi",
    "Shiretoko",
    "Shiroi",
    "Shiroishi",
    "Shiroishi",
    "Shirokane",
    "Shisui",
    "Shitama",
    "Shizukuishi",
    "Shizunai-misono",
    "Shizuoka",
    "Shobara",
    "Shobusawa",
    "Shoinmachi-koji",
    "Shonaicho",
    "Showa",
    "Shuchi",
    "Sodegaura",
    "Sogabe-minamijo",
    "Soja",
    "Soka",
    "Sone",
    "Sonobe",
    "Subashiri",
    "Suda",
    "Suginomecho",
    "Sugito",
    "Sugiyama",
    "Suigetsu",
    "Suijo",
    "Suita",
    "Sukagawa",
    "Sukumo",
    "Sumidacho",
    "Sumiyocho-kawauchi",
    "Sumiyoshi",
    "Sumiyoshi",
    "Sumoto",
    "Susaki",
    "Susami",
    "Susono",
    "Suwa",
    "Suzaka",
    "Suzuka",
    "Tachibana",
    "Tadotsu",
    "Taga",
    "Taga",
    "Tagajo-shi",
    "Tagawa",
    "Tahara",
    "Taiji",
    "Taiki",
    "Tainoura",
    "Tajimi",
    "Tajiri",
    "Takahama",
    "Takahashi",
    "Takaishi",
    "Takaiwa",
    "Takajo",
    "Takajomachi",
    "Takamatsu",
    "Takamori",
    "Takanawa",
    "Takaoka",
    "Takara",
    "Takarazuka",
    "Takasagocho-takasemachi",
    "Takasaki",
    "Takase",
    "Takashima",
    "Takashima",
    "Takasu",
    "Takata",
    "Takatsuki",
    "Takayama",
    "Takedamachi",
    "Takemiya",
    "Takeo",
    "Taketoyo",
    "Takicho-oda",
    "Takikawa",
    "Takinogawa",
    "Tama",
    "Tamachi",
    "Tamagawa",
    "Tamana",
    "Tamano",
    "Tamashima",
    "Tamatsukuri",
    "Tamura",
    "Tanabe",
    "Tanaka",
    "Taniyama-chuo",
    "Tanushimarumachi-sugawara",
    "Tashima",
    "Tashiro",
    "Tatebayashi",
    "Tateyama",
    "Tatsumi",
    "Tatsuno",
    "Tatsunocho-tominaga",
    "Tawaramoto",
    "Teine-ku",
    "Temma",
    "Tendo",
    "Tennojicho-kita",
    "Tenri",
    "Toba",
    "Tobata",
    "Tobe",
    "Tobo",
    "Tochigi",
    "Togane",
    "Toge",
    "Togitsu",
    "Tokai",
    "Tokamachi",
    "Toki",
    "Tokiwa",
    "Tokiwamachi-tokiwa",
    "Tokoname",
    "Tokorozawa",
    "Tokushima",
    "Tokuyama",
    "Tokyo",
    "Tomakomai",
    "Tomioka",
    "Tomisato",
    "Tomiya",
    "Tomo",
    "Tomobe",
    "Tonami",
    "Tondabayashi",
    "Tone",
    "Tono",
    "Toranomon",
    "Toride",
    "Toshima Mura",
    "Tosu",
    "Totsukacho",
    "Tottori-shi",
    "Towada",
    "Toyama-shi",
    "Toyo",
    "Toyoake",
    "Toyoda",
    "Toyohashi",
    "Toyohira-ku",
    "Toyokawa",
    "Toyonaka",
    "Toyonomachi-toyono",
    "Toyooka",
    "Toyota",
    "Toyota",
    "Tsu",
    "Tsubame",
    "Tsubata",
    "Tsuchiara",
    "Tsuchiura",
    "Tsudanuma",
    "Tsuhako",
    "Tsukawaki",
    "Tsukayama",
    "Tsukuba",
    "Tsukubamirai",
    "Tsukumiura",
    "Tsuru",
    "Tsuruga",
    "Tsurugashima",
    "Tsurumi",
    "Tsuruoka",
    "Tsushima",
    "Tsuyama",
    "Ube",
    "Uchimaru",
    "Ueda, Nagano",
    "Ueki",
    "Ueno",
    "Ueno-ebisumachi",
    "Uenohara",
    "Uji",
    "Ujiie",
    "Uki",
    "Umeda",
    "Uonuma",
    "Uozu",
    "Urasoe",
    "Urayama",
    "Urayasu",
    "Ureshino",
    "Uruma",
    "Usa",
    "Ushiku",
    "Usuki",
    "Utazu",
    "Utsunomiya",
    "Uwajima",
    "Uwayama",
    "Wadayama",
    "Wajima",
    "Wakamatsucho",
    "Wakasa",
    "Wakayama",
    "Wakicho",
    "Wakimachi",
    "Wakkanai",
    "Wako",
    "Warabi-shi",
    "Waseda",
    "Watari",
    "Yabase",
    "Yabasehoncho",
    "Yabu",
    "Yabuki",
    "Yachimata",
    "Yachiyo",
    "Yaese",
    "Yahagi",
    "Yahata",
    "Yaita",
    "Yaizu",
    "Yakage",
    "Yakuin-Horibata",
    "Yakumo",
    "Yamada",
    "Yamadayu",
    "Yamaga",
    "Yamagata-shi",
    "Yamaguchi",
    "Yamakita",
    "Yamanashi",
    "Yamanashi",
    "Yamano",
    "Yamashitacho",
    "Yamato-Takada",
    "Yamatomachi",
    "Yamatomachi-mitsuai",
    "Yamauchi",
    "Yamawaki",
    "Yamazaki",
    "Yamazakicho-nakabirose",
    "Yanagawa",
    "Yanagi",
    "Yanai",
    "Yano",
    "Yanocho-futatsugi",
    "Yao",
    "Yashima",
    "Yashio-shi",
    "Yasu-shi",
    "Yasuda",
    "Yasugicho",
    "Yatsushiro",
    "Yawata",
    "Yodogawa",
    "Yoichi",
    "Yokaichiba",
    "Yokkaichi",
    "Yokogawa",
    "Yokohama",
    "Yokoo",
    "Yokosuka",
    "Yokotemachi",
    "Yomitan Son",
    "Yomogita",
    "Yonago",
    "Yoneyamacho-nishino",
    "Yonezawa",
    "Yorii",
    "Yorishimacho",
    "Yoshida",
    "Yoshida-kasugacho",
    "Yoshii",
    "Yoshikawa",
    "Yoshimi",
    "Yoshino",
    "Yotsukaido",
    "Youkaichi",
    "Yubari",
    "Yufutsu",
    "Yugawara",
    "Yuge",
    "Yuki",
    "Yukuhashi",
    "Yunoshima",
    "Yurihonjo",
    "Yutaka",
    "Yuzawa",
    "Zama",
    "Zushi",
  ],
  Kazakhstan: [
    "Aksay",
    "Aksoran",
    "Almaty",
    "Aqtas",
    "Aqtau",
    "Astana",
    "Atyrau",
    "Baikonur",
    "Dostyk",
    "Dzhezkazgan",
    "Ekibastuz",
    "Esil",
    "Karagandy",
    "Karagandy",
    "Kazakh",
    "Kentau",
    "Koktem",
    "Kostanay",
    "Kyzyl",
    "Kyzylorda",
    "Lomonosovka",
    "Oral",
    "Pavlodar",
    "Petropavl",
    "Qaraghandy",
    "Qaskeleng",
    "Ridder",
    "Rudnyy",
    "Semey",
    "Serebryansk",
    "Shymkent",
    "Sonaly",
    "Stepnogorsk",
    "Taldyk",
    "Taldykorgan",
    "Talghar",
    "Taraz",
    "Temirtau",
    "Turkestan",
    "Uritskiy",
    "Ust-Kamenogorsk",
    "Vostok",
    "Zyryanovsk",
  ],
  Kenya: [
    "Bondo",
    "Chuka",
    "Eldoret",
    "Kabete",
    "Kaiboi",
    "Karatina",
    "Kiambu",
    "Kikuyu",
    "Kisii",
    "Kisumu",
    "Kitale",
    "Kitui",
    "Machakos",
    "Maseno",
    "Mbita",
    "Mombasa",
    "Nairobi",
    "Nakuru",
    "Sawa Sawa",
    "Siaya",
    "Thika",
    "Turbo",
    "Wote",
  ],
  Kosovo: ["Dunavo", "Ferizaj", "Mitrovica", "Pristina", "Prizren"],
  Kuwait: [
    "Abraq Khaytan",
    "Ad Dasmah",
    "Ad Dawhah",
    "Al Ahmadi",
    "Al Farwaniyah",
    "Al Shamiya",
    "Ar Rawdah",
    "As Salimiyah",
    "Ash Shu`aybah",
    "Ash Shuwaykh",
    "Bayan",
    "Hawalli",
    "Janub as Surrah",
    "Kayfan",
    "Kuwait City",
    "Salwa",
  ],
  Latvia: [
    "Adazi",
    "Agenskalns",
    "Aizkraukle",
    "Aizpute",
    "Baldone",
    "Balvi",
    "Bauska",
    "Brankas",
    "Carnikava",
    "Centrs",
    "Daugavpils",
    "Dobele",
    "Durbe",
    "Gulbene",
    "Ilguciems",
    "Izvalta",
    "Jaunolaine",
    "Jelgava",
    "Kandava",
    "Kolka",
    "Lapmezciems",
    "Liepa",
    "Ludza",
    "Madona",
    "Malpils",
    "Malta",
    "Marupe",
    "Mazsalaca",
    "Ogre",
    "Olaine",
    "Ozolnieki",
    "Pilsrundale",
    "Priedkalne",
    "Pure",
    "Riga",
    "Salaspils",
    "Saldus",
    "Sigulda",
    "Smiltene",
    "Stalgene",
    "Talsi",
    "Tukums",
    "Valka",
    "Valmiera",
    "Varsava",
    "Ventspils",
    "Zasa",
    "Zilupe",
  ],
  Lebanon: [
    "Aaley",
    "Adma",
    "Ashrafiye",
    "Baabda",
    "Baalbek",
    "Beirut",
    "Broummana",
    "Bsalim",
    "Chekka",
    "Dbaiye",
    "Dik el Mehdi",
    "Halba",
    "Hboub",
    "Sarba",
    "Sidon",
    "Tripoli",
    "Yanar",
    "Zgharta",
  ],
  Libya: ["Benghazi", "Misratah", "Sabha", "Tripoli", "Zliten"],
  Liechtenstein: [
    "Balzers",
    "Bendern",
    "Eschen",
    "Gamprin",
    "Mauren",
    "Nendeln",
    "Planken",
    "Ruggell",
    "Schaan",
    "Schaanwald",
    "Schellenberg",
    "Triesen",
    "Triesenberg",
    "Vaduz",
  ],
  Luxembourg: [
    "Ahn",
    "Alzingen",
    "Bascharage",
    "Beaufort",
    "Beckerich",
    "Beggen",
    "Beidweiler",
    "Belvaux",
    "Berchem",
    "Bereldange",
    "Bergem",
    "Bertrange",
    "Bettembourg",
    "Betzdorf",
    "Bigonville",
    "Bissen",
    "Bivange",
    "Bofferdange",
    "Bonnevoie",
    "Bourglinster",
    "Bridel",
    "Burmerange",
    "Canach",
    "Cap",
    "Capellen",
    "Colmar",
    "Contern",
    "Crauthem",
    "Dalheim",
    "Differdange",
    "Dippach",
    "Dudelange",
    "Echternach",
    "Ehnen",
    "Ernster",
    "Erpeldange-lez-Bous",
    "Esch-sur-Alzette",
    "Esch-sur-Sure",
    "Ettelbruck",
    "Foetz",
    "Frisange",
    "Godbrange",
    "Goetzingen",
    "Gonderange",
    "Gosseldange",
    "Gostingen",
    "Grevenknapp",
    "Grevenmacher",
    "Grosbous",
    "Hagen",
    "Hautcharage",
    "Heffingen",
    "Heisdorf-sur-Alzette",
    "Helmdange",
    "Helmsange",
    "Hersberg",
    "Hesperange",
    "Hobscheid",
    "Hollerich",
    "Holzem",
    "Huncherange",
    "Hunsdorf",
    "Itzig",
    "Junglinster",
    "Kayl",
    "Kehlen",
    "Kleinbettingen",
    "Koetschette",
    "Lamadelaine",
    "Larochette",
    "Leudelange",
    "Limpertsberg",
    "Lintgen",
    "Lorentzweiler",
    "Luxembourg",
    "Luxembourg-Kirchberg",
    "Mamer",
    "Medernach",
    "Merl",
    "Mersch",
    "Mertert",
    "Mondercange",
    "Mondorf-les-Bains",
    "Moutfort",
    "Niedercorn",
    "Noerdange",
    "Nospelt",
    "Oberanven",
    "Oetrange",
    "Olm",
    "Pontpierre",
    "Remerschen",
    "Remich",
    "Rodange",
    "Roeser",
    "Roodt-sur-Syre",
    "Rumelange",
    "Sandweiler",
    "Sanem",
    "Schieren",
    "Schifflange",
    "Schoos",
    "Schouweiler",
    "Schuttrange",
    "Soleuvre",
    "Steinfort",
    "Steinsel",
    "Strassen",
    "Syren",
    "Tetange",
    "Tuntange",
    "Uebersyren",
    "Walferdange",
    "Wasserbillig",
    "Wolpert",
    "Wormeldange",
  ],
  Macedonia: [
    "Berovo",
    "Bitola",
    "Bogdanci",
    "Cair",
    "Debar",
    "Delcevo",
    "Demir Hisar",
    "Gevgelija",
    "Gostivar",
    "Ilinden",
    "Kadino",
    "Kavadarci",
    "Kicevo",
    "Kumanovo",
    "Labunista",
    "Lisice",
    "Makedonska Kamenica",
    "Negotino",
    "Novo Selo",
    "Ohrid",
    "Prilep",
    "Pripor",
    "Probistip",
    "Radovis",
    "Shtip",
    "Skopje",
    "Struga",
    "Strumica",
    "Tetovo",
    "Veles",
  ],
  Madagascar: [
    "Ambanja",
    "Antananarivo",
    "Antsirabe",
    "Antsiranana",
    "Fianarantsoa",
    "Toamasina",
    "Toliara",
  ],
  Malaysia: [
    "Alor Gajah",
    "Alor Star",
    "Ampang",
    "Ayer Itam",
    "Ayer Tawar",
    "Bachok",
    "Bagan Serai",
    "Bahau",
    "Balik Pulau",
    "Bandar",
    "Bandar Baru Bangi",
    "Bandar Tenggara",
    "Bandar Tun Razak",
    "Bangi",
    "Banting",
    "Baru",
    "Batang Berjuntai",
    "Batang Kali",
    "Batu",
    "Batu Arang",
    "Batu Berendam",
    "Batu Caves",
    "Batu Feringgi",
    "Batu Gajah",
    "Batu Pahat",
    "Bau",
    "Bayan Lepas",
    "Beaufort",
    "Bedong",
    "Bentung",
    "Benut",
    "Bidur",
    "Bintulu",
    "Bongawan",
    "Broga",
    "Bukit Asahan",
    "Bukit Gambir",
    "Bukit Kayu Hitam",
    "Bukit Mertajam",
    "Bukit Pasir",
    "Bukit Rotan",
    "Butterworth",
    "Cameron Highlands",
    "Changloon",
    "Chemor",
    "Cheras",
    "Cukai",
    "Cyberjaya",
    "Damansara",
    "Dengkil",
    "Dungun",
    "Durian Tunggal",
    "Gebeng",
    "Gelang Patah",
    "Gemas",
    "Genting",
    "George Town",
    "Gerik",
    "Gopeng",
    "Gua Musang",
    "Gurun",
    "Hulu Langat",
    "Hutan Melintang",
    "Inanam",
    "Ipoh",
    "Itam",
    "Jasin",
    "Jawi",
    "Jelutong",
    "Jenjarum",
    "Jeram",
    "Jerantut",
    "Jertih",
    "Jinjang",
    "Jitra",
    "Johor Bahru",
    "Juasseh",
    "Kajang",
    "Kampar",
    "Kampong Changkat",
    "Kampong Kahang Batu Twenty-eight",
    "Kampong Kemaman",
    "Kampong Pasir Segambut",
    "Kampung Ayer Keroh",
    "Kampung Baharu Nilai",
    "Kampung Batu Kikir",
    "Kampung Batu Uban",
    "Kampung Bota Kiri",
    "Kampung Likas",
    "Kampung Paya Simpang Empat Genting",
    "Kampung Pekan Kechil Beranang",
    "Kampung Tanjung Aru",
    "Kampung Tasek",
    "Kampung Teluk Baru",
    "Kampung Teriang",
    "Kamunting",
    "Kangar",
    "Kapar",
    "Karak",
    "Keningau",
    "Kepala Batas",
    "Kepala Batas",
    "Kepong",
    "Keramat Pulai",
    "Kerling",
    "Kertih",
    "Ketereh",
    "Klang",
    "Kluang",
    "Kompleks Sukan Tenom",
    "Kota",
    "Kota Baharu",
    "Kota Belud",
    "Kota Bharu",
    "Kota Kinabalu",
    "Kota Tinggi",
    "Kuah",
    "Kuala",
    "Kuala Berang",
    "Kuala Kangsar",
    "Kuala Ketil",
    "Kuala Klawang",
    "Kuala Kubu Baharu",
    "Kuala Lipis",
    "Kuala Lumpur",
    "Kuala Nerang",
    "Kuala Pahang",
    "Kuala Penyu",
    "Kuala Pilah",
    "Kuala Selangor",
    "Kuala Terengganu",
    "Kuang",
    "Kuantan",
    "Kubang Kerian",
    "Kuching",
    "Kulai",
    "Kulim",
    "Labis",
    "Labu",
    "Lahad Datu",
    "Lahat",
    "Langat",
    "Layang Layang",
    "Lenggeng",
    "Lintang",
    "Lukut",
    "Lumut",
    "Lunas",
    "Machang",
    "Malacca",
    "Mantin",
    "Marang",
    "Masai",
    "Masjid Tanah",
    "Melaka Tengah",
    "Melor",
    "Membakut",
    "Menggatal",
    "Menglembu",
    "Mentekab",
    "Merbok",
    "Merlimau",
    "Miri",
    "Muadzam Shah",
    "Muar town",
    "Mukah",
    "Nibong Tebal",
    "Padang Rengas",
    "Padang Serai",
    "Pandan",
    "Pantai",
    "Pantai Remis",
    "Papar",
    "Parit",
    "Parit Buntar",
    "Parit Raja",
    "Pasir Gudang",
    "Pasir Mas",
    "Pasir Puteh",
    "Pedas",
    "Pekan",
    "Penaga",
    "Penampang",
    "Pendang",
    "Pengerang",
    "Pengkalan Chepa",
    "Pengkalan Kempas",
    "Perai",
    "Permatang Pauh",
    "Petaling Jaya",
    "Pontian Kechil",
    "Port Dickson",
    "Port Klang",
    "Puchong Batu Dua Belas",
    "Pudu",
    "Pudu Ulu",
    "Pulau Pinang",
    "Pusing",
    "Putrajaya",
    "Ranau",
    "Raub",
    "Rawang",
    "Rembau",
    "Rengam",
    "Rengit",
    "Sabak Bernam",
    "Sandakan",
    "Sarikei",
    "Seberang Jaya",
    "Segamat",
    "Selama",
    "Semenyih",
    "Semerah",
    "Senggarang",
    "Sentul",
    "Sepang",
    "Serdang",
    "Serdang",
    "Seremban",
    "Serendah",
    "Seri Kembangan",
    "Seri Manjung",
    "Setapak",
    "Shah Alam",
    "Sibu",
    "Simpang Empat",
    "Simpang Pertang",
    "Simpang Renggam",
    "Simpang Tiga",
    "Simunjan",
    "Sitiawan",
    "Slim River",
    "Sungai Bakap",
    "Sungai Besar",
    "Sungai Besi",
    "Sungai Buluh",
    "Sungai Pelek",
    "Sungai Petani",
    "Sungai Puyu",
    "Sungai Udang",
    "Taiping",
    "Taman Bukit Skudai",
    "Taman Desa",
    "Taman Kota Jaya",
    "Taman Megah Ria",
    "Taman Prai",
    "Taman Senai",
    "Taman Senawang Indah",
    "Taman Seremban Jaya",
    "Taman Sri Ampang",
    "Tambunan",
    "Tamparuli",
    "Tampin",
    "Tampoi",
    "Tanah Merah",
    "Tanah Merah New Village Site C",
    "Tangkak",
    "Tanjong",
    "Tanjong Karang",
    "Tanjong Malim",
    "Tanjung",
    "Tanjung Bunga",
    "Tanjung Sepat",
    "Tapah Road",
    "Tasek Glugor",
    "Tatau",
    "Tawau",
    "Teluk Intan",
    "Teluk Panglima Garang",
    "Temerluh",
    "Temoh",
    "Teriang",
    "Teronoh",
    "Tumpat",
    "Ulu Tiram",
    "Victoria",
  ],
  Malta: [
    "Attard",
    "Balzan",
    "Bingemma",
    "Birgu",
    "Birkirkara",
    "Bugibba",
    "Cospicua",
    "Dingli",
    "Fgura",
    "Floriana",
    "Fontana",
    "Ghajnsielem",
    "Gharb",
    "Ghasri",
    "Ghaxaq",
    "Gudja",
    "Gzira",
    "Hal Gharghur",
    "Hamrun",
    "Haz-Zebbug",
    "Il-Madliena",
    "Il-Pergla",
    "Imsida",
    "Imtarfa",
    "Iz-Zebbiegh",
    "Kirkop",
    "L-Iklin",
    "L-Iskorvit",
    "Lija",
    "Luqa",
    "Manikata",
    "Marsa",
    "Marsalforn",
    "Marsaskala",
    "Marsaxlokk",
    "Mdina",
    "Mellieha",
    "Mosta",
    "Mqabba",
    "Munxar",
    "Nadur",
    "Naxxar",
    "Paola",
    "Pembroke",
    "Qormi",
    "Qrendi",
    "Rabat",
    "Safi",
    "Saint John",
    "Saint Lawrence",
    "Saint Lucia",
    "Saint Venera",
    "San Pawl il-Bahar",
    "Sannat",
    "Senglea",
    "Siggiewi",
    "Sliema",
    "Swieqi",
    "Tarxien",
    "Valletta",
    "Victoria",
    "Xaghra",
    "Xewkija",
    "Zabbar",
    "Zejtun",
    "Zurrieq",
  ],
  Martinique: [
    "Case-Pilote",
    "Ducos",
    "Fort-de-France",
    "Le Carbet",
    "Le Diamant",
    "Le Francois",
    "Le Gros-Morne",
    "Le Lamentin",
    "Le Morne-Rouge",
    "Le Robert",
    "Les Trois-Ilets",
    "Riviere-Salee",
    "Saint-Esprit",
    "Saint-Joseph",
    "Sainte-Anne",
    "Sainte-Luce",
    "Sainte-Marie",
    "Schoelcher",
  ],
  Mauritius: [
    "Beau Bassin",
    "Chemin Grenier",
    "Ebene CyberCity",
    "Floreal",
    "Goodlands",
    "Le Reduit",
    "Port Louis",
    "Port Mathurin",
    "Quatre Bornes",
    "Rose Hill",
    "Saint Jean",
    "Tamarin",
    "Vacoas",
  ],
  Mayotte: [
    "Bandaboa",
    "Chiconi",
    "Combani",
    "Dzaoudzi",
    "Dzoumonye",
    "Koungou",
    "Mamoudzou",
    "Ouangani",
    "Pamandzi",
    "Sada",
    "Tsingoni",
    "Tsoundzou 1",
  ],
  Mexico: [
    "Abasolo",
    "Acambaro",
    "Acambay",
    "Acapulco",
    "Acatic",
    "Acatlan de Perez Figueroa",
    "Acatzingo de Hidalgo",
    "Acolman",
    "Actopan",
    "Acuna",
    "Adolfo Lopez Mateos",
    "Adolfo Ruiz Cortines",
    "Agua Amarga",
    "Agua Azul",
    "Agua Dulce",
    "Agua Prieta",
    "Aguascalientes",
    "Ahome",
    "Ahualulco de Mercado",
    "Ajijic",
    "Alamo",
    "Alamos",
    "Aldama",
    "Allende",
    "Almada",
    "Almoloya",
    "Altamira",
    "Altotonga",
    "Alvaro Obregon Borough",
    "Amado Nervo",
    "Ameca",
    "Amecameca de Juarez",
    "Anahuac",
    "Anahuac",
    "Angamacutiro de la Union",
    "Apan",
    "Apaseo el Alto",
    "Apaseo el Grande",
    "Apatzingan",
    "Apaxco de Ocampo",
    "Apizaco",
    "Apodaca",
    "Arandas",
    "Arcelia",
    "Arizpe",
    "Arriaga",
    "Asuncion Ixtaltepec",
    "Asuncion Nochixtlan",
    "Atequiza",
    "Atizapan",
    "Atlacholoaya",
    "Atlacomulco",
    "Atlatlahucan",
    "Atlixco",
    "Atlzayanca",
    "Atotonilco el Alto",
    "Atoyac de Alvarez",
    "Autlan de Navarro",
    "Autopista Puebla Orizaba 185 (La Condusa)",
    "Axochiapan",
    "Azcapotzalco",
    "Azteca",
    "Badiraguato",
    "Bahia de Kino",
    "Baja California",
    "Balancan de Dominguez",
    "Bamoa",
    "Banderas del Aguila",
    "Banderilla",
    "Barra de Navidad",
    "Barranca de Santa Clara",
    "Barrio de Mexico",
    "Benito Juarez",
    "Benito Juarez",
    "Benito Juarez",
    "Benito Juarez (Santa Elena)",
    "Berriozabal",
    "Boca del Rio",
    "Brisas Barra de Suchiate",
    "Bucerias",
    "Cabo San Lucas",
    "Cabos",
    "Cadereyta",
    "Cadereyta de Montes",
    "Cadereyta Jimenez",
    "Cajeme",
    "Cajititlan",
    "Camargo",
    "Campeche",
    "Campestre Tarimbaro",
    "Cananea",
    "Canatlan",
    "Candelaria",
    "Cardenas",
    "Cardonal",
    "Catemaco",
    "Cazones de Herrera",
    "Celaya",
    "Centro",
    "Centro",
    "Cerritos",
    "Chalco de Diaz Covarrubias",
    "Chapala",
    "Chetumal",
    "Chiapa de Corzo",
    "Chiapas",
    "Chiautempan",
    "Chiautla",
    "Chicago",
    "Chiconcuac",
    "Chignahuapan",
    "Chihuahua",
    "Chihuahua",
    "Chihuahua",
    "Chilpancingo",
    "Cholula",
    "Ciudad Ayala",
    "Ciudad Constitucion",
    "Ciudad de Huajuapam de Leon",
    "Ciudad de Villa de Alvarez",
    "Ciudad del Carmen",
    "Ciudad Hidalgo",
    "Ciudad Lopez Mateos",
    "Ciudad Madero",
    "Ciudad Mendoza",
    "Ciudad Nezahualcoyotl",
    "Ciudad Sabinas",
    "Ciudad Sahagun",
    "Ciudad Valles",
    "Ciudad Victoria",
    "Coacalco",
    "Coahuila",
    "Coatepec",
    "Coatzacoalcos",
    "Coatzintla",
    "Cocoyoc",
    "Cocula",
    "Colima",
    "Colimas",
    "Colon",
    "Colonia",
    "Colonia Benito Juarez",
    "Colonia Cuauhtemoc",
    "Colonia de Venado",
    "Colonia Emiliano Zapata (Ejido Santiago Temoaya)",
    "Colonia Guerrero (Los Guajes)",
    "Colonia Hidalgo",
    "Colonia la Presa (Ejido de Chapultepec)",
    "Colonia Lopez Nelio (Las Palmas)",
    "Colonia Madero (Madero)",
    "Colonia Mexico",
    "Colonia Miguel Aleman Valdez",
    "Colonia Veracruz",
    "Comalcalco",
    "Compostela",
    "Corregidora",
    "Cortazar",
    "Cosamaloapan de Carpio",
    "Cosoleacaque",
    "Coyoacan",
    "Coyuca de Catalan",
    "Cuajimalpa",
    "Cuarta Brigada",
    "Cuauhtemoc",
    "Cuauhtemoc",
    "Cuauhtemoc",
    "Cuautepec",
    "Cuautepec de Hinojosa",
    "Cuautitlan",
    "Cuautla",
    "Cuautlancingo",
    "Cuernavaca",
    "Cuitlahuac",
    "Cunduacan",
    "Cutzamala de Pinzon",
    "Cuyoaco",
    "Dolores Hidalgo Cuna de la Independencia Nacional",
    "Durango",
    "Ecatepec de Morelos",
    "Ejido de Otumba (Loma Bonita)",
    "Ejido Distrito Federal",
    "Ejido Santa Maria Tianguistenco (Ejido el Rosario)",
    "El Alto Culiacan",
    "El Carmen",
    "El Colli",
    "El Fuerte de Montes Claros",
    "El Grullo",
    "El Leon",
    "El Mirador",
    "El Ocotito",
    "El Parque",
    "El Potrero",
    "El Rio",
    "El Sabinal (San Pedro)",
    "El Salto",
    "El Salto",
    "El Sauz",
    "El Tecuan",
    "El Torreon",
    "Emiliano Zapata",
    "Emiliano Zapata",
    "Empalme",
    "Ensenada",
    "Epazoyucan",
    "Erongaricuaro",
    "Escobedo",
    "Escobedo",
    "Escondida de lo Verde",
    "Escuinapa de Hidalgo",
    "Escuintla",
    "Estado de Mexico",
    "Estados Unidos",
    "Ex-Hacienda Pantitlan",
    "Ezequiel Montes",
    "Florida",
    "Fortin de las Flores",
    "Fraccionamiento Iberica Calimaya",
    "Francisco Castellanos",
    "Francisco I. Madero",
    "Fresnillo",
    "Frontera",
    "Garcia",
    "Garza Garcia",
    "General Escobedo",
    "General Teran",
    "Gomez Palacio",
    "Graciano Sanchez",
    "Granjas",
    "Guadalajara",
    "Guadalajara",
    "Guadalupe",
    "Guadalupe",
    "Guadalupe",
    "Guadalupe",
    "Guadalupe Victoria",
    "Guadalupito",
    "Guamuchil",
    "Guanajuato",
    "Guasave",
    "Guaymas",
    "Gueramaro",
    "Gustavo A. Madero Borough",
    "Gutierrez Zamora",
    "Hermosillo",
    "Heroica Alvarado",
    "Heroica Caborca",
    "Heroica Coscomatepec de Bravo",
    "Heroica Matamoros",
    "Heroica Zitacuaro",
    "Huandacareo",
    "Huanimaro",
    "Huatabampo",
    "Huauchinango",
    "Huehuetoca",
    "Huejotzingo",
    "Huejucar",
    "Huichapan",
    "Huimanguillo",
    "Huimilpan",
    "Huixquilucan",
    "Huixtla",
    "Hunucma",
    "Iglesia Vieja",
    "Ignacio Allende",
    "Iguala de la Independencia",
    "Insurgentes",
    "Irapuato",
    "Isla Holbox",
    "Isla Mujeres",
    "Ixmiquilpan",
    "Ixtapa",
    "Ixtapa",
    "Ixtapa-Zihuatanejo",
    "Ixtapaluca",
    "Ixtapan de la Sal",
    "Ixtlahuaca de Villada",
    "Ixtlahuacan del Rio",
    "Ixtlan del Rio",
    "Izamal",
    "Iztacalco",
    "Iztapalapa",
    "Izucar de Matamoros",
    "Jacobo",
    "Jacona de Plancarte",
    "Jala",
    "Jalapa",
    "Jalisco",
    "Jalostotitlan",
    "Jalpa",
    "Jaltipan de Morelos",
    "Jalupa",
    "Jaumave",
    "Jerez de Garcia Salinas",
    "Jesus Carranza",
    "Jesus del Monte",
    "Jesus Maria",
    "Jesus Maria",
    "Jilotepec",
    "Jimenez",
    "Jimenez",
    "Jiquilpan de Juarez",
    "Jiquipilco",
    "Jiutepec",
    "Jocotepec",
    "Jocotitlan",
    "Jojutla",
    "Jonacatepec de Leandro Valle",
    "Jose Cardel",
    "Juan",
    "Juarez",
    "Juarez",
    "Juarez",
    "Juarez",
    "Kanasin",
    "Kilometro 32 Texcoco Lecheria",
    "La Barca",
    "La Canada",
    "La Ciudad",
    "La Cruz",
    "La Florida",
    "La Habana",
    "La Herradura",
    "La Isla",
    "La Joya",
    "La Moncada",
    "La Paletena",
    "La Paz",
    "La Piedad",
    "La Pista de Tizayuca",
    "La Reforma",
    "La Tapachula",
    "La Union",
    "La Victoria",
    "Lagos de Moreno",
    "Laredo",
    "Las Americas",
    "Las Cabanas (Colinas de los Angeles)",
    "Las Canoras (Altiva)",
    "Las Choapas",
    "Las Delicias",
    "Las Delicias",
    "Leon",
    "Lerdo",
    "Lerma",
    "Leyva Solano",
    "Libramiento Leon-Salamanca",
    "Libres",
    "Linares",
    "Loma de los Cavazos (Maria de la Luz)",
    "Loreto",
    "Loreto",
    "Los Garzas",
    "Los Mochis",
    "Los Reyes",
    "Los Reyes",
    "Los Reyes de Salgado",
    "Los Sabinos",
    "Luvianos",
    "Macuspana",
    "Madero",
    "Magdalena de Kino",
    "Magdalena Petlacalco",
    "Magiscatzin",
    "Malinalco",
    "Manuel Avila Camacho",
    "Manzanillo",
    "Mariano Matamoros",
    "Marquelia",
    "Martinez de la Torre",
    "Mazatecochco",
    "Mazatepec",
    "Mazatlan",
    "Medellin de Bravo",
    "Melchor",
    "Melchor Muzquiz",
    "Mendoza",
    "Mexicali",
    "Mexico City",
    "Mextepec",
    "Mier",
    "Miguel Aleman",
    "Miguel Hidalgo",
    "Mina Mexico",
    "Mineral",
    "Mineral de la Reforma",
    "Mineral del Chico",
    "Mineral del Monte",
    "Mixquiahuala de Juarez",
    "Moctezuma",
    "Monclova",
    "Monclova",
    "Monte Leon",
    "Montemorelos",
    "Monterrey",
    "Monterrey",
    "Morelia",
    "Morelos",
    "Morelos",
    "Morelos",
    "Moroleon",
    "Motozintla de Mendoza",
    "Muna",
    "Nacozari Viejo",
    "Naucalpan",
    "Navojoa",
    "Navolato",
    "Netzahualcoyotl",
    "Nextlalpan",
    "Nicolas Romero",
    "Nogales",
    "Noxtepec de Zaragoza (El Aguacate)",
    "Nueva Italia de Ruiz",
    "Nuevo Casas Grandes",
    "Nuevo Coahuila",
    "Nuevo Ideal",
    "Nuevo Laredo",
    "Nuevo Leon",
    "Oaxaca",
    "Oaxtepec",
    "Ocotlan",
    "Ocoyoacac",
    "Ocozocoautla de Espinosa",
    "Ojinaga",
    "Ojo de Agua",
    "Ojuelos de Jalisco",
    "Orizaba",
    "Oxkutzkab",
    "Pabellon",
    "Pabellon de Arteaga",
    "Pachuca",
    "Pachuca de Soto",
    "Panotla",
    "Pantanal",
    "Paraiso",
    "Paraje Nuevo",
    "Parral",
    "Paseos de Tecamac",
    "Paso Real",
    "Pastor Ortiz",
    "Patzcuaro",
    "Pedregal de Jerico",
    "Pedro Escobedo",
    "Pedro Meoqui",
    "Pericos",
    "Perote",
    "Pesqueria",
    "Petaquillas",
    "Petatlan",
    "Pichucalco",
    "Piedras Negras",
    "Piedras Negras",
    "Playa del Carmen",
    "Polanco",
    "Poncitlan",
    "Poza Rica Chacas",
    "Poza Rica de Hidalgo",
    "Primero de Mayo",
    "Progreso",
    "Prolongacion Avenida Mexico Norte",
    "Puebla",
    "Pueblo Hidalgo",
    "Pueblo Juarez (La Magdalena)",
    "Pueblo Viejo",
    "Pueblo Yaqui",
    "Puente de Ixtla",
    "Puente Grande",
    "Puerto Escondido",
    "Puerto Mexico",
    "Puerto Morelos",
    "Puerto Vallarta",
    "Purisima de Bustos",
    "Purisima del Zapote",
    "Puruandiro",
    "Queseria",
    "Quintana Roo",
    "Quintana Roo",
    "Quiroga",
    "Ramos Arizpe",
    "Rancho Xalnene",
    "Rayon",
    "Rayon",
    "Reforma (Madrigal 2da. Seccion)",
    "Refractarios Guadalajara",
    "Reyes de Vallarta",
    "Reynosa",
    "Rincon de Tamayo",
    "Rio Blanco",
    "Rio Bravo",
    "Roma",
    "Rosarito",
    "Sahuayo de Morelos",
    "Salamanca",
    "Salina Cruz",
    "Salinas de Hidalgo",
    "Saltillo",
    "Salvatierra",
    "San Agustin",
    "San Agustin (Salvador Alvarado)",
    "San Agustin Altamirano",
    "San Agustin Tlaxiaca",
    "San Andres Cholula",
    "San Andres Tuxtla",
    "San Angel Inn",
    "San Antonio",
    "San Antonio la Isla",
    "San Antonio Virreyes",
    "San Bernardo",
    "San Cristobal",
    "San Cristobal",
    "San Felipe",
    "San Felipe Torres Mochas [Ganaderia]",
    "San Fernando",
    "San Fernando",
    "San Francisco",
    "San Francisco",
    "San Francisco Javier",
    "San Gabriel",
    "San Jeronimo de Juarez",
    "San Jose del Valle",
    "San Jose Iturbide",
    "San Juan",
    "San Juan",
    "San Juan Bautista Tuxtla",
    "San Juan de Aragon",
    "San Juan del Rio",
    "San Luis",
    "San Luis",
    "San Luis de la Paz",
    "San Luis Soyatlan",
    "San Martin de Camargo",
    "San Martin Duraznos",
    "San Martin Hidalgo",
    "San Martin Notario",
    "San Martin Texmelucan de Labastida",
    "San Mateo",
    "San Mateo Atenco",
    "San Mateo Mexicaltzingo",
    "San Miguel",
    "San Miguel",
    "San Miguel Balderas",
    "San Miguel de Allende",
    "San Miguel de Atotonilco",
    "San Miguel de Cozumel",
    "San Miguel de Papasquiaro",
    "San Miguel el Alto",
    "San Miguel el Alto (El Puerto)",
    "San Miguel Zinacantepec",
    "San Nicolas",
    "San Nicolas",
    "San Nicolas",
    "San Nicolas Totolapan",
    "San Pablo Hidalgo",
    "San Patricio",
    "San Pedro",
    "San Pedro",
    "San Pedro",
    "San Pedro Cholula",
    "San Pedro de Las Colonias",
    "San Pedro de Nolasco",
    "San Pedro Piedra Gorda",
    "San Pedro Pochutla",
    "San Rafael",
    "San Salvador Atenco",
    "San Salvador Huixcolotla",
    "San Salvador Tzompantepec",
    "San Sebastian el Grande",
    "San Sebastian Tutla",
    "San Sebastian Xhala",
    "San Simon de Guerrero",
    "San Vicente",
    "San Vicente Chicoloapan",
    "Santa Anita",
    "Santa Apolonia",
    "Santa Catalina",
    "Santa Catarina",
    "Santa Catarina Juquila",
    "Santa Fe",
    "Santa Isabel Tlanepantla",
    "Santa Lucia",
    "Santa Maria Chimalhuacan",
    "Santiago",
    "Santiago de Queretaro",
    "Santiago Ixcuintla",
    "Santiago Tianguistenco",
    "Satelite",
    "Seccion Primera Santiago Tetla",
    "Selene",
    "Sierra Madre",
    "Silao",
    "Sinaloa de Leyva",
    "Sombrerete",
    "Sonora",
    "Soto la Marina",
    "Suchiapa",
    "Tabasco",
    "Tablon Grande (Bombas de Apaxco)",
    "Tacambaro de Codallos",
    "Tacotalpa",
    "Tala",
    "Tamaulipas",
    "Tamazula de Gordiano",
    "Tampico",
    "Tampico Alto",
    "Tangancicuaro de Arista",
    "Tapachula",
    "Tapalpa",
    "Taxco",
    "Teapa",
    "Tecalitlan",
    "Tecamachalco",
    "Tecate",
    "Tecax",
    "Tecoman",
    "Tecuala",
    "Tejupilco de Hidalgo",
    "Tekoh",
    "Teloloapan",
    "Temascalcingo",
    "Temascaltepec de Gonzalez",
    "Temixco",
    "Tenamaxtlan",
    "Tenancingo de Degollado",
    "Tenosique de Pino Suarez",
    "Teoloyucan",
    "Teotihuacan de Arista",
    "Tepalcatepec",
    "Tepeaca",
    "Tepeapulco",
    "Tepeji de Ocampo",
    "Tepetlacolco",
    "Tepetlapa",
    "Tepic",
    "Tepotzotlan",
    "Tepoztlan",
    "Tequila",
    "Tequisistlan",
    "Tequisquiapan",
    "Tequixquiac",
    "Texcoco",
    "Tezonapa",
    "Tezontepec",
    "Tezontepec de Aldama",
    "Tezoyuca",
    "Tezoyuca",
    "Tierra Blanca",
    "Tierra Colorada",
    "Tijuana",
    "Tilzapotla",
    "Tirindaro",
    "Tizapan el Alto",
    "Tlacolula de Matamoros",
    "Tlacotalpan",
    "Tlahuac",
    "Tlahuelilpan",
    "Tlajomulco de Zuniga",
    "Tlalmanalco de Velazquez",
    "Tlalnepantla",
    "Tlalnepantla",
    "Tlalpan",
    "Tlaltizapan",
    "Tlanalapa",
    "Tlapa de Comonfort",
    "Tlapacoyan",
    "Tlaquepaque",
    "Tlaquiltenango",
    "Tlatlauquitepec",
    "Tlatlaya",
    "Tlaxcala",
    "Tlaxcalancingo",
    "Tocatlan",
    "Tocumbo",
    "Todos Santos",
    "Tolcayuca",
    "Toluca",
    "Tonala",
    "Tonala",
    "Tonatico",
    "Topolobampo",
    "Tula de Allende",
    "Tulancingo",
    "Tultepec",
    "Tultitlan",
    "Tulum",
    "Tuxpan",
    "Tuxpan",
    "Tuxpan de Rodriguez Cano",
    "Tuxtla",
    "Uman",
    "Union de Tula",
    "Ures",
    "Uriangato",
    "Ursulo Galvan",
    "Uruapan",
    "Valladolid",
    "Valle de Bravo",
    "Valle de Santiago",
    "Valle Hermoso",
    "Valles",
    "Venustiano Carranza",
    "Venustiano Carranza",
    "Veracruz",
    "Vicente Guerrero",
    "Vicente Guerrero",
    "Victoria",
    "Victoria",
    "Villa Cuauhtemoc Otzolotepec",
    "Villa de Almoloya de Juarez",
    "Villa de Costa Rica",
    "Villa Garcia",
    "Villa Hidalgo",
    "Villa Nanchital",
    "Villadiego",
    "Villagran",
    "Villahermosa",
    "Visitacion",
    "Vistahermosa de Negrete",
    "Xalapa",
    "Xalisco",
    "Xochimilco",
    "Xochimilco",
    "Xochitepec",
    "Xonacatlan",
    "Yanga",
    "Yautepec",
    "Yecapixtla",
    "Yucatan",
    "Yurecuaro",
    "Yuriria",
    "Zacapoaxtla",
    "Zacapu",
    "Zacatecas",
    "Zacatelco",
    "Zacatepec",
    "Zacatlan",
    "Zacualpan",
    "Zacualpan",
    "Zacualtipan",
    "Zamora",
    "Zapopan",
    "Zapotitlan de Vadillo",
    "Zapotlan de Juarez",
    "Zapotlanejo",
    "Zapotlanejo",
    "Zaragoza",
    "Zinapecuaro",
    "Zitlala",
    "Zongolica",
    "Zumpango",
  ],
  Mongolia: [
    "Altai",
    "Arvayheer",
    "Baruun-Urt",
    "Bayangol",
    "Bayanhongor",
    "Cecerleg",
    "Chihertey",
    "Choyr",
    "Dalandzadgad",
    "Darhan",
    "Han-Uul",
    "Javhlant",
    "Khovd",
    "Mandalgovi",
    "Ovoot",
    "Saynshand",
    "Toyrim",
    "Ulaan-Uul",
    "Ulan Bator",
    "Uliastay",
    "Undurkhaan",
  ],
  Montenegro: [
    "Budva",
    "Crna Gora",
    "Herceg Novi",
    "Igalo",
    "Kotor",
    "Niksic",
    "Pljevlja",
    "Podgorica",
    "Stari Bar",
    "Ulcinj",
  ],
  Morocco: [
    "Afourer",
    "Agadir",
    "Ait Melloul",
    "Al Hoceima",
    "Assa",
    "Benguerir",
    "Beni Mellal",
    "Berrechid",
    "Casablanca",
    "Deroua",
    "El Gara",
    "El Hajeb",
    "El Jadida",
    "Erfoud",
    "Fes",
    "Fkih Ben Salah",
    "Kenitra",
    "Khemisset",
    "Khouribga",
    "Ksar el Kebir",
    "Larache",
    "Mansour",
    "Marrakesh",
    "Mehediyah",
    "Meknes",
    "Mohammedia",
    "Nador",
    "Ouazzane",
    "Oued Zem",
    "Oujda",
    "Oulad Teima",
    "Rabat",
    "Safi",
    "Sefrou",
    "Settat",
    "Sidi Bennour",
    "Sidi Slimane",
    "Skhirat",
    "Tahala",
    "Tan-Tan",
    "Tangier",
    "Tarfaya",
    "Taza",
    "Temara",
    "Tiflet",
    "Tiznit",
    "Touissite",
  ],
  Mozambique: [
    "Beira",
    "Maputo",
    "Matola",
    "Mozambique",
    "Nampula",
    "Pemba",
    "Quelimane",
    "Tete",
  ],
  "Myanmar [Burma]": [
    "Hlaing",
    "Inya",
    "Kyauktada",
    "Mandalay",
    "Wagan",
    "Yangon",
  ],
  Namibia: [
    "Etunda",
    "Grootfontein",
    "Katima Mulilo",
    "Keetmanshoop",
    "Mpapuka",
    "Olympia",
    "Ondangwa",
    "Ongwediva",
    "Oranjemund",
    "Oshakati",
    "Otjiwarongo",
    "Outapi",
    "Swakopmund",
    "Tsumeb",
    "Walvis Bay",
    "Windhoek",
  ],
  Nepal: ["Bharatpur", "Jawlakhel", "Kathmandu", "Lumbini", "Palpa", "Patan"],
  Netherlands: [
    "Aagtekerke",
    "Aalburg",
    "Aalden",
    "Aalsmeer",
    "Aalsmeerderbrug",
    "Aalst",
    "Aalten",
    "Aardenburg",
    "Abbekerk",
    "Abbenes",
    "Abcoude",
    "Achterberg",
    "Achterveld",
    "Achthuizen",
    "Achtmaal",
    "Aduard",
    "Aerdenhout",
    "Aerdt",
    "Afferden",
    "Akersloot",
    "Akkrum",
    "Albergen",
    "Alblasserdam",
    "Alde Leie",
    "Alem",
    "Alkmaar",
    "Almelo",
    "Almere",
    "Almere Haven",
    "Almkerk",
    "Alphen",
    "Alphen aan den Rijn",
    "Ameide",
    "America",
    "Amerongen",
    "Amersfoort",
    "Ammerstol",
    "Ammerzoden",
    "Amstelhoek",
    "Amstelveen",
    "Amstenrade",
    "Amsterdam",
    "Anderen",
    "Andijk",
    "Ane",
    "Angeren",
    "Anjum",
    "Ankeveen",
    "Anloo",
    "Anna Paulowna",
    "Annen",
    "Apeldoorn",
    "Appelscha",
    "Appeltern",
    "Appingedam",
    "Arcen",
    "Arkel",
    "Arnemuiden",
    "Arnhem",
    "Asperen",
    "Assen",
    "Assendelft",
    "Asten",
    "Augustinusga",
    "Avenhorn",
    "Axel",
    "Baambrugge",
    "Baarland",
    "Baarle-Nassau",
    "Baarlo",
    "Baarn",
    "Baars",
    "Babberich",
    "Badhoevedorp",
    "Baexem",
    "Bakel",
    "Baken",
    "Bakhuizen",
    "Balgoij",
    "Balk",
    "Ballum",
    "Banholt",
    "Barchem",
    "Barendrecht",
    "Barneveld",
    "Batenburg",
    "Bathmen",
    "Bavel",
    "Bedum",
    "Beegden",
    "Beek",
    "Beek",
    "Beek",
    "Beekbergen",
    "Beerta",
    "Beerzerveld",
    "Beesd",
    "Beesel",
    "Beets",
    "Beetsterzwaag",
    "Beilen",
    "Beinsdorp",
    "Belfeld",
    "Bellingwolde",
    "Bemelen",
    "Bemmel",
    "Beneden-Leeuwen",
    "Bennebroek",
    "Bennekom",
    "Benningbroek",
    "Benthuizen",
    "Berg",
    "Berg en Dal",
    "Bergambacht",
    "Bergeijk",
    "Bergen",
    "Bergen",
    "Bergen op Zoom",
    "Bergentheim",
    "Bergharen",
    "Berghem",
    "Bergschenhoek",
    "Beringe",
    "Berkenwoude",
    "Berkhout",
    "Berlicum",
    "Best",
    "Beugen",
    "Beuningen",
    "Beusichem",
    "Beverwijk",
    "Biddinghuizen",
    "Bierum",
    "Biervliet",
    "Biest",
    "Bilthoven",
    "Bitgum",
    "Bladel",
    "Blaricum",
    "Bleiswijk",
    "Blerick",
    "Bleskensgraaf",
    "Blitterswijck",
    "Bloemendaal",
    "Blokker",
    "Blokzijl",
    "Bocholtz",
    "Bodegraven",
    "Boeicop",
    "Boekel",
    "Boelenslaan",
    "Boerakker",
    "Boesingheliede",
    "Bolsward",
    "Borculo",
    "Borger",
    "Born",
    "Borne",
    "Borsele",
    "Bosch en Duin",
    "Boskoop",
    "Bosschenhoofd",
    "Bourtange",
    "Boven-Hardinxveld",
    "Boven-Leeuwen",
    "Bovenkarspel",
    "Bovensmilde",
    "Boxmeer",
    "Boxtel",
    "Brakel",
    "Breda",
    "Bredevoort",
    "Breezand",
    "Breskens",
    "Breugel",
    "Breukelen",
    "Breukeleveen",
    "Brielle",
    "Briltil",
    "Britsum",
    "Broek in Waterland",
    "Broek op Langedijk",
    "Broekhin",
    "Broekhuizen",
    "Brouwershaven",
    "Bruchem",
    "Brucht",
    "Bruinisse",
    "Brummen",
    "Brunssum",
    "Buchten",
    "Budel",
    "Budel-Dorplein",
    "Budel-Schoot",
    "Buinerveen",
    "Buitenkaag",
    "Buitenpost",
    "Bunde",
    "Bunnik",
    "Bunschoten",
    "Buren",
    "Buren",
    "Burgerveen",
    "Burgum",
    "Burgwerd",
    "Burum",
    "Bussum",
    "Buurse",
    "Cadier en Keer",
    "Cadzand",
    "Callantsoog",
    "Capelle aan den IJssel",
    "Castricum",
    "Centrum",
    "Chaam",
    "Clinge",
    "Coevorden",
    "Colijnsplaat",
    "Colmschate",
    "Craailo",
    "Cromvoirt",
    "Cruquius",
    "Cuijk",
    "Culemborg",
    "Daarle",
    "Dalen",
    "Dalfsen",
    "Damwald",
    "De Bilt",
    "De Blesse",
    "De Cocksdorp",
    "De Falom",
    "De Glind",
    "De Goorn",
    "De Hoef",
    "De Horst",
    "De Klomp",
    "De Koog",
    "De Kwakel",
    "De Lier",
    "De Lutte",
    "De Meern",
    "De Moer",
    "De Punt",
    "De Rijp",
    "De Steeg",
    "De Waal",
    "De Westereen",
    "De Wijk",
    "De Wilp",
    "De Zilk",
    "Dedemsvaart",
    "Deest",
    "Delden",
    "Delden",
    "Delfgauw",
    "Delft",
    "Delfzijl",
    "Den Bommel",
    "Den Burg",
    "Den Deijl",
    "Den Dolder",
    "Den Dungen",
    "Den Helder",
    "Den Hoorn",
    "Den Hout",
    "Den Ilp",
    "Den Oever",
    "Denekamp",
    "Deurne",
    "Deventer",
    "Didam",
    "Diemen",
    "Diepenheim",
    "Diepenveen",
    "Dieren",
    "Diessen",
    "Dieteren",
    "Diever",
    "Dijk",
    "Dinteloord",
    "Dinxperlo",
    "Dirkshorn",
    "Dirksland",
    "Dodewaard",
    "Doenrade",
    "Doesburg",
    "Doetinchem",
    "Dokkum",
    "Domburg",
    "Dongen",
    "Doorn",
    "Doornenburg",
    "Doornspijk",
    "Doorwerth",
    "Dordrecht",
    "Dorp",
    "Dorst",
    "Drachten",
    "Drempt",
    "Dreumel",
    "Driebergen",
    "Driebruggen",
    "Driehuis",
    "Driel",
    "Driemond",
    "Drogeham",
    "Dronryp",
    "Dronten",
    "Drunen",
    "Druten",
    "Duin",
    "Duiven",
    "Duivendrecht",
    "Dwingeloo",
    "Earnewald",
    "Echt",
    "Echten",
    "Echtenerbrug",
    "Eckart",
    "Edam",
    "Ede",
    "Ederveen",
    "Eede",
    "Eefde",
    "Eelde-Paterswolde",
    "Eelderwolde",
    "Eemnes",
    "Eenrum",
    "Eerbeek",
    "Eersel",
    "Eethen",
    "Eext",
    "Eexterzandvoort",
    "Egchel",
    "Egmond aan Zee",
    "Egmond-Binnen",
    "Eibergen",
    "Eijsden",
    "Eindhoven",
    "Einighausen",
    "Elburg",
    "Ell",
    "Ellecom",
    "Elsendorp",
    "Elsloo",
    "Elst",
    "Elst",
    "Emmeloord",
    "Emmen",
    "Emmer-Compascuum",
    "Emst",
    "Engelen",
    "Enkhuizen",
    "Ens",
    "Enschede",
    "Enschot",
    "Enspijk",
    "Enter",
    "Enumatil",
    "Epe",
    "Epen",
    "Epse",
    "Erica",
    "Erichem",
    "Erm",
    "Ermelo",
    "Erp",
    "Escharen",
    "Espel",
    "Etten",
    "Everdingen",
    "Ewijk",
    "Exloo",
    "Eygelshoven",
    "Eys",
    "Ezinge",
    "Farmsum",
    "Feanwalden",
    "Ferwert",
    "Fijnaart",
    "Finsterwolde",
    "Fleringen",
    "Fluitenberg",
    "Flushing",
    "Foxhol",
    "Franeker",
    "Frederiksoord",
    "Gaanderen",
    "Galder",
    "Gameren",
    "Gapinge",
    "Garderen",
    "Garmerwolde",
    "Garsthuizen",
    "Garyp",
    "Gasselte",
    "Gasselternijveen",
    "Geertruidenberg",
    "Geervliet",
    "Gees",
    "Geesteren",
    "Geesteren",
    "Geldermalsen",
    "Geldrop",
    "Geleen",
    "Gelselaar",
    "Gemeente Rotterdam",
    "Gemert",
    "Gemonde",
    "Genderen",
    "Gendringen",
    "Gendt",
    "Genemuiden",
    "Gennep",
    "Giesbeek",
    "Giessenburg",
    "Giessendam",
    "Gieten",
    "Gieterveen",
    "Giethoorn",
    "Gilze",
    "Glimmen",
    "Goes",
    "Goirle",
    "Goor",
    "Gorinchem",
    "Gorredijk",
    "Gorssel",
    "Gouda",
    "Gouderak",
    "Goudswaard",
    "Goutum",
    "Gramsbergen",
    "Grashoek",
    "Grathem",
    "Grave",
    "Greup",
    "Grijpskerk",
    "Groeningen",
    "Groenlo",
    "Groenveld",
    "Groesbeek",
    "Groessen",
    "Groet",
    "Groningen",
    "Gronsveld",
    "Groot-Agelo",
    "Groot-Ammers",
    "Grootebroek",
    "Grootegast",
    "Grou",
    "Grubbenvorst",
    "Gulpen",
    "Guttecoven",
    "Gytsjerk",
    "Haaften",
    "Haaksbergen",
    "Haalderen",
    "Haamstede",
    "Haaren",
    "Haarle",
    "Haarlem",
    "Haarlemmerliede",
    "Haelen",
    "Halfweg",
    "Halle",
    "Hallum",
    "Halsteren",
    "Hank",
    "Hantum",
    "Hantumhuizen",
    "Hapert",
    "Haps",
    "Hardenberg",
    "Harderwijk",
    "Haren",
    "Haren",
    "Harich",
    "Harkema",
    "Harkstede",
    "Harlingen",
    "Harmelen",
    "Hartwerd",
    "Hasselt",
    "Hattem",
    "Haule",
    "Haulerwijk",
    "Hauwert",
    "Havelte",
    "Hazerswoude-Dorp",
    "Hazerswoude-Rijndijk",
    "Hedel",
    "Hedel",
    "Heeg",
    "Heelsum",
    "Heemskerk",
    "Heemstede",
    "Heenvliet",
    "Heerde",
    "Heerenveen",
    "Heerhugowaard",
    "Heerjansdam",
    "Heerle",
    "Heerlen",
    "Heesch",
    "Heeswijk",
    "Heeswijk-Dinther",
    "Heeten",
    "Heeze",
    "Heijen",
    "Heijningen",
    "Heikant",
    "Heilig Landstichting",
    "Heiloo",
    "Heinenoord",
    "Heino",
    "Hekelingen",
    "Helden",
    "Helenaveen",
    "Hellendoorn",
    "Hellevoetsluis",
    "Hellouw",
    "Helmond",
    "Helvoirt",
    "Hem",
    "Hemelum",
    "Hendrik-Ido-Ambacht",
    "Hengelo",
    "Hengelo",
    "Hengevelde",
    "Hennaard",
    "Hensbroek",
    "Herbaijum",
    "Herkenbosch",
    "Herkingen",
    "Hernen",
    "Herpen",
    "Herten",
    "Herveld",
    "Herwijnen",
    "Heteren",
    "Heukelum",
    "Heusden",
    "Heveadorp",
    "Heythuysen",
    "Hierden",
    "Hijken",
    "Hillegom",
    "Hilvarenbeek",
    "Hilversum",
    "Hindeloopen",
    "Hippolytushoef",
    "Hoedekenskerke",
    "Hoek",
    "Hoek van Holland",
    "Hoenderloo",
    "Hoensbroek",
    "Hoevelaken",
    "Hoge Donk",
    "Hollandsche Rading",
    "Hollandscheveld",
    "Hollum",
    "Holten",
    "Holthees",
    "Holthone",
    "Holtum",
    "Holwierde",
    "Homoet",
    "Honselersdijk",
    "Hoofddorp",
    "Hoofdplaat",
    "Hoogblokland",
    "Hooge Zwaluwe",
    "Hoogeloon",
    "Hoogerheide",
    "Hoogersmilde",
    "Hoogeveen",
    "Hoogezand",
    "Hooghalen",
    "Hoogkarspel",
    "Hoogkerk",
    "Hoogland",
    "Hooglanderveen",
    "Hoogmade",
    "Hoogvliet",
    "Hoogwoud",
    "Hoorn",
    "Hoorn",
    "Hoornaar",
    "Horn",
    "Horst",
    "Hout",
    "Houtakker",
    "Houten",
    "Huijbergen",
    "Huis ter Heide",
    "Huissen",
    "Huizen",
    "Hulsberg",
    "Hulshorst",
    "Hulst",
    "Hummelo",
    "Hurdegaryp",
    "IJhorst",
    "IJlst",
    "IJmuiden",
    "IJsselmuiden",
    "IJsselstein",
    "IJzendijke",
    "IJzendoorn",
    "Ilpendam",
    "Ingelum",
    "Ingen",
    "Ittervoort",
    "Jabeek",
    "Jisp",
    "Joppe",
    "Joure",
    "Jubbega",
    "Julianadorp",
    "Jutrijp",
    "Kaag",
    "Kaatsheuvel",
    "Kalenberg",
    "Kamerik",
    "Kampen",
    "Kamperland",
    "Kantens",
    "Kapelle",
    "Kats",
    "Katwijk",
    "Katwijk aan Zee",
    "Katwoude",
    "Keijenborg",
    "Kekerdom",
    "Keldonk",
    "Kerk-Avezaath",
    "Kerkdriel",
    "Kerkenveld",
    "Kerkrade",
    "Kessel",
    "Kesteren",
    "Kimswerd",
    "Kinderdijk",
    "Klaaswaal",
    "Klazienaveen",
    "Klimmen",
    "Kloetinge",
    "Kloosterhaar",
    "Klundert",
    "Kockengen",
    "Koedijk",
    "Koekange",
    "Koewacht",
    "Kolham",
    "Kolhorn",
    "Kollum",
    "Kommerzijl",
    "Koog aan de Zaan",
    "Kootstertille",
    "Kootwijkerbroek",
    "Kortenhoef",
    "Kortgene",
    "Koudekerk aan den Rijn",
    "Koudekerke",
    "Koudum",
    "Kraggenburg",
    "Krimpen aan de Lek",
    "Krimpen aan den IJssel",
    "Krommenie",
    "Kropswolde",
    "Kruiningen",
    "Kruisland",
    "Kudelstaart",
    "Kuitaart",
    "Kwintsheul",
    "Laag-Soeren",
    "Lage Mierde",
    "Lage Zwaluwe",
    "Landsmeer",
    "Langedijk",
    "Langezwaag",
    "Laren",
    "Lathum",
    "Leek",
    "Leerdam",
    "Leersum",
    "Leeuwarden",
    "Legemeer",
    "Leiden",
    "Leiderdorp",
    "Leidschendam",
    "Leimuiden",
    "Lekkerkerk",
    "Lelystad",
    "Lemele",
    "Lemelerveld",
    "Lemiers",
    "Lemmer",
    "Lent",
    "Lettele",
    "Leusden",
    "Leuth",
    "Lewenborg",
    "Lexmond",
    "Lichtenvoorde",
    "Liempde",
    "Lienden",
    "Lienden",
    "Lies",
    "Lieshout",
    "Liessel",
    "Lievelde",
    "Lijnden",
    "Limbricht",
    "Limmel",
    "Limmen",
    "Linne",
    "Linschoten",
    "Lippenhuizen",
    "Lisse",
    "Lithoijen",
    "Lobith",
    "Lochem",
    "Loenen",
    "Loon op Zand",
    "Loosdrecht",
    "Loozen",
    "Lopik",
    "Loppersum",
    "Losser",
    "Lottum",
    "Lunteren",
    "Lutjebroek",
    "Lutjewinkel",
    "Lutten",
    "Luyksgestel",
    "Maarheeze",
    "Maarn",
    "Maarssen",
    "Maarssenbroek",
    "Maartensdijk",
    "Maasbommel",
    "Maasbracht",
    "Maasbree",
    "Maasdam",
    "Maasdijk",
    "Maashees",
    "Maaskantje",
    "Maasland",
    "Maassluis",
    "Maastricht",
    "Made",
    "Makkum",
    "Malden",
    "Manderveen",
    "Margraten",
    "Marienberg",
    "Markelo",
    "Marknesse",
    "Marrum",
    "Marum",
    "Mastenbroek",
    "Maurik",
    "Mechelen",
    "Medemblik",
    "Meeden",
    "Meer",
    "Meerkerk",
    "Meerlo",
    "Meerssen",
    "Meeuwen",
    "Megchelen",
    "Meijel",
    "Melick",
    "Meppel",
    "Merkelbeek",
    "Merselo",
    "Middelbeers",
    "Middelburg",
    "Middelharnis",
    "Middenbeemster",
    "Middenmeer",
    "Midlum",
    "Mierlo",
    "Mijdrecht",
    "Mijnsheerenland",
    "Mildam",
    "Milheeze",
    "Mill",
    "Millingen",
    "Millingen aan de Rijn",
    "Milsbeek",
    "Moerdijk",
    "Moergestel",
    "Moerkapelle",
    "Molenaarsgraaf",
    "Molenhoek",
    "Molenhoek",
    "Molenschot",
    "Monnickendam",
    "Monster",
    "Montfoort",
    "Montfort",
    "Mook",
    "Mookhoek",
    "Moordrecht",
    "Mortel",
    "Muiden",
    "Muiderberg",
    "Munstergeleen",
    "Muntendam",
    "Mussel",
    "Naaldwijk",
    "Naarden",
    "Nederasselt",
    "Nederhemert",
    "Nederhorst den Berg",
    "Nederweert",
    "Neede",
    "Neer",
    "Neer-Andel",
    "Nes",
    "Netterden",
    "Nibbixwoud",
    "Nieuw-Amsterdam",
    "Nieuw-Balinge",
    "Nieuw-Beijerland",
    "Nieuw-Bergen",
    "Nieuw-Buinen",
    "Nieuw-Dordrecht",
    "Nieuw-Lekkerland",
    "Nieuw-Namen",
    "Nieuw-Schoonebeek",
    "Nieuw-Vennep",
    "Nieuw-Vossemeer",
    "Nieuwdorp",
    "Nieuwe Pekela",
    "Nieuwe Wetering",
    "Nieuwe-Niedorp",
    "Nieuwe-Tonge",
    "Nieuwegein",
    "Nieuwehorne",
    "Nieuwendijk",
    "Nieuwer-Ter-Aa",
    "Nieuwerbrug",
    "Nieuwerkerk aan den IJssel",
    "Nieuweschoot",
    "Nieuwkoop",
    "Nieuwkuijk",
    "Nieuwlande",
    "Nieuwleusen",
    "Nieuwolda",
    "Nieuwpoort",
    "Nieuwstadt",
    "Nieuwveen",
    "Nigtevecht",
    "Nijbroek",
    "Nijhuizum",
    "Nijkerk",
    "Nijkerkerveen",
    "Nijland",
    "Nijmegen",
    "Nijnsel",
    "Nijverdal",
    "Nistelrode",
    "Noardburgum",
    "Noorbeek",
    "Noord-Scharwoude",
    "Noord-Sleen",
    "Noordbroek",
    "Noordeloos",
    "Noorden",
    "Noordgouwe",
    "Noordhorn",
    "Noordlaren",
    "Noordscheschut",
    "Noordwelle",
    "Noordwijk aan Zee",
    "Noordwijk-Binnen",
    "Noordwijkerhout",
    "Noordwolde",
    "Nootdorp",
    "Norg",
    "Nuenen",
    "Nuis",
    "Nuland",
    "Numansdorp",
    "Nunhem",
    "Nunspeet",
    "Nuth",
    "Obbicht",
    "Obdam",
    "Ochten",
    "Odijk",
    "Oeffelt",
    "Oegstgeest",
    "Oene",
    "Oijen",
    "Oirsbeek",
    "Oirschot",
    "Oisterwijk",
    "Oldeberkoop",
    "Oldebroek",
    "Oldeholtpade",
    "Oldehove",
    "Oldekerk",
    "Oldemarkt",
    "Oldenzaal",
    "Olst",
    "Ommen",
    "Onnen",
    "Ooij",
    "Ooltgensplaat",
    "Oost-Souburg",
    "Oostburg",
    "Oostendam",
    "Oosterbeek",
    "Oosterbierum",
    "Oosterend",
    "Oosterhesselen",
    "Oosterhout",
    "Oosterland",
    "Oosternijkerk",
    "Oosterwolde",
    "Oosterwolde",
    "Oosterzee",
    "Oosthuizen",
    "Oostkapelle",
    "Oostvoorne",
    "Oostwold",
    "Oostwoud",
    "Oostzaan",
    "Ootmarsum",
    "Op den Bosch",
    "Opeinde",
    "Ophemert",
    "Opheusden",
    "Opmeer",
    "Oranje",
    "Oranjewoud",
    "Ospel",
    "Oss",
    "Ossendrecht",
    "Oterleek",
    "Otterlo",
    "Ottersum",
    "Oud-Ade",
    "Oud-Alblas",
    "Oud-Beijerland",
    "Oud-Gastel",
    "Oud-Zuilen",
    "Ouddorp",
    "Oude Pekela",
    "Oude Wetering",
    "Oude-Tonge",
    "Oudega",
    "Oudehaske",
    "Oudehorne",
    "Oudelande",
    "Oudemirdum",
    "Oudemolen",
    "Oudenbosch",
    "Oudendijk",
    "Ouderkerk aan de Amstel",
    "Oudeschild",
    "Oudesluis",
    "Oudewater",
    "Oudkarspel",
    "Oudorp",
    "Oudwoude",
    "Overasselt",
    "Overberg",
    "Overdinkel",
    "Overloon",
    "Overveen",
    "Ovezande",
    "Palemig",
    "Pannerden",
    "Panningen",
    "Papekop",
    "Papendrecht",
    "Partij",
    "Paterswolde",
    "Peize",
    "Pernis",
    "Petten",
    "Philippine",
    "Piershil",
    "Pieterburen",
    "Pieterzijl",
    "Pijnacker",
    "Pingjum",
    "Plasmolen",
    "Poeldijk",
    "Poortugaal",
    "Poortvliet",
    "Posterholt",
    "Princenhof",
    "Prinsenbeek",
    "Puiflijk",
    "Purmerend",
    "Purmerland",
    "Puth",
    "Putte",
    "Putten",
    "Puttershoek",
    "Raalte",
    "Raamsdonksveer",
    "Randwijk",
    "Ravenstein",
    "Ravenswoud",
    "Reeuwijk",
    "Rekken",
    "Renesse",
    "Renkum",
    "Renswoude",
    "Ressen",
    "Retranchement",
    "Reusel",
    "Reuver",
    "Rheden",
    "Rhenen",
    "Rhenoy",
    "Rhoon",
    "Ridderkerk",
    "Riethoven",
    "Rietveld",
    "Rijckholt",
    "Rijen",
    "Rijkevoort",
    "Rijnsburg",
    "Rijpwetering",
    "Rijsbergen",
    "Rijsenhout",
    "Rijssen",
    "Rijswijk",
    "Rijswijk",
    "Rilland",
    "Rinsumageast",
    "Rips",
    "Rivierenwijk",
    "Rixtel",
    "Rockanje",
    "Roden",
    "Rodenrijs",
    "Roelofarendsveen",
    "Roermond",
    "Roggel",
    "Rolde",
    "Roodeschool",
    "Roosendaal",
    "Roosteren",
    "Rooth",
    "Rosmalen",
    "Rossum",
    "Rossum",
    "Rotterdam",
    "Rottum",
    "Rottum",
    "Rozenburg",
    "Rozenburg",
    "Rozendaal",
    "Rucphen",
    "Ruinen",
    "Ruinerwold",
    "Rutten",
    "Ruurlo",
    "s-Heerenberg",
    "Saasveld",
    "Sambeek",
    "Santpoort-Noord",
    "Sappemeer",
    "Sas van Gent",
    "Sassenheim",
    "Schagen",
    "Schagerbrug",
    "Schaijk",
    "Schalkhaar",
    "Scharmer",
    "Scheemda",
    "Schellinkhout",
    "Schermerhorn",
    "Scherpenisse",
    "Scherpenzeel",
    "Schiedam",
    "Schiermonnikoog",
    "Schijndel",
    "Schildwolde",
    "Schimmert",
    "Schin op Geul",
    "Schinnen",
    "Schinveld",
    "Schipborg",
    "Schipluiden",
    "Schoondijke",
    "Schoonebeek",
    "Schoonhoven",
    "Schoorl",
    "Schore",
    "Sellingen",
    "Serooskerke",
    "Sevenum",
    "Sibculo",
    "Siddeburen",
    "Siebengewald",
    "Silvolde",
    "Simpelveld",
    "Sint Agatha",
    "Sint Annaland",
    "Sint Annaparochie",
    "Sint Anthonis",
    "Sint Hubert",
    "Sint Jansteen",
    "Sint Joost",
    "Sint Maarten",
    "Sint Maartensdijk",
    "Sint Nicolaasga",
    "Sint Odilienberg",
    "Sint Pancras",
    "Sint Philipsland",
    "Sint Willebrord",
    "Sint-Michielsgestel",
    "Sint-Oedenrode",
    "Sintjohannesga",
    "Sittard",
    "Slagharen",
    "Sleen",
    "Sleeuwijk",
    "Slenaken",
    "Sliedrecht",
    "Slijk-Ewijk",
    "Slochteren",
    "Slootdorp",
    "Sloterdijk",
    "Sluis",
    "Sluiskil",
    "Smilde",
    "Sneek",
    "Soerendonk",
    "Soest",
    "Soesterberg",
    "Someren",
    "Sommelsdijk",
    "Son en Breugel",
    "Spaarndam",
    "Spakenburg",
    "Spanbroek",
    "Spaubeek",
    "Spierdijk",
    "Spijk",
    "Spijkenisse",
    "Sprang",
    "Sprundel",
    "Stadskanaal",
    "Stampersgat",
    "Staphorst",
    "Stationsbuurt",
    "Stedum",
    "Steenbergen",
    "Steensel",
    "Steenwijk",
    "Steggerda",
    "Steijl",
    "Stein",
    "Stellendam",
    "Sterksel",
    "Stevensbeek",
    "Stevensweert",
    "Stiens",
    "Stolwijk",
    "Stompetoren",
    "Stompwijk",
    "Stoutenburg",
    "Strijen",
    "Strijensas",
    "Stroe",
    "Stuifzand",
    "Sumar",
    "Surhuisterveen",
    "Surhuizum",
    "Susteren",
    "Suwald",
    "Swalmen",
    "Sweikhuizen",
    "Swifterbant",
    "Taarlo",
    "Tegelen",
    "Ten Boer",
    "Ten Post",
    "Ter Aar",
    "Ter Apel",
    "Ter Apelkanaal",
    "Terblijt",
    "Terborg",
    "Terheijden",
    "Terneuzen",
    "Terschuur",
    "Teteringen",
    "The Hague",
    "Tholen",
    "Tiel",
    "Tienhoven",
    "Tienraij",
    "Tijnje",
    "Tilburg",
    "Tilligte",
    "Tinte",
    "Tjerkwerd",
    "Tolbert",
    "Toldijk",
    "Tolkamer",
    "Tricht",
    "Tubbergen",
    "Tuitjenhorn",
    "Tull",
    "Tweede Exloermond",
    "Tweede Valthermond",
    "Twello",
    "Twijzelerheide",
    "Twisk",
    "Tynaarlo",
    "Tytsjerk",
    "Tzummarum",
    "Uddel",
    "Uden",
    "Udenhout",
    "Ugchelen",
    "Uitdam",
    "Uitgeest",
    "Uithoorn",
    "Uithuizen",
    "Uithuizermeeden",
    "Uitwellingerga",
    "Ulestraten",
    "Ulft",
    "Ulicoten",
    "Ulrum",
    "Ulvenhout",
    "Ureterp",
    "Urk",
    "Urmond",
    "Ursem",
    "Utrecht",
    "Vaals",
    "Vaassen",
    "Valburg",
    "Valkenburg",
    "Valkenburg",
    "Valkenswaard",
    "Valthe",
    "Valthermond",
    "Varik",
    "Varsseveld",
    "Vasse",
    "Veen",
    "Veendam",
    "Veenendaal",
    "Veenhuizen",
    "Veenoord",
    "Veghel",
    "Velddriel",
    "Velden",
    "Veldhoven",
    "Velp",
    "Velsen",
    "Velsen-Noord",
    "Ven-Zelderheide",
    "Venhorst",
    "Venhuizen",
    "Venlo",
    "Venray",
    "Vianen",
    "Vierhouten",
    "Vierlingsbeek",
    "Vierpolders",
    "Vijfhuizen",
    "Vilt",
    "Vinkeveen",
    "Vlaardingen",
    "Vlagtwedde",
    "Vledder",
    "Vleuten",
    "Vlieland",
    "Vlijmen",
    "Vlodrop",
    "Voerendaal",
    "Vogelenzang",
    "Vogelwaarde",
    "Volendam",
    "Volkel",
    "Voorburg",
    "Voorhout",
    "Voorschoten",
    "Voorst",
    "Voorthuizen",
    "Vorden",
    "Vorstenbosch",
    "Vortum-Mullem",
    "Vragender",
    "Vreeland",
    "Vries",
    "Vriezenveen",
    "Vroomshoop",
    "Vrouwenpolder",
    "Vught",
    "Vuren",
    "Waalre",
    "Waalwijk",
    "Waardenburg",
    "Waarder",
    "Waarland",
    "Waddinxveen",
    "Wagenberg",
    "Wagenborgen",
    "Wageningen",
    "Walterswald",
    "Wamel",
    "Wanroij",
    "Wanssum",
    "Wapenveld",
    "Warder",
    "Warffum",
    "Warmenhuizen",
    "Warmond",
    "Warnsveld",
    "Waspik",
    "Wassenaar",
    "Wateringen",
    "Waterland",
    "Weerselo",
    "Weert",
    "Weesp",
    "Wehl",
    "Weidum",
    "Well",
    "Wellerlooi",
    "Wemeldinge",
    "Werkendam",
    "Werkhoven",
    "Wervershoof",
    "Wessem",
    "West-Knollendam",
    "West-Terschelling",
    "Westbeemster",
    "Westbroek",
    "Westdorpe",
    "Westerbork",
    "Westerhaar-Vriezenveensewijk",
    "Westerhoven",
    "Westervoort",
    "Westerwijtwerd",
    "Westkapelle",
    "Westmaas",
    "Westzaan",
    "Weurt",
    "Wezep",
    "Wierden",
    "Wieringerwaard",
    "Wieringerwerf",
    "Wierum",
    "Wijchen",
    "Wijckel",
    "Wijdenes",
    "Wijdewormer",
    "Wijhe",
    "Wijk aan Zee",
    "Wijk bij Duurstede",
    "Wijlre",
    "Wijnaldum",
    "Wijnandsrade",
    "Wijngaarden",
    "Wijster",
    "Wildervank",
    "Willemstad",
    "Wilnis",
    "Wilp",
    "Winkel",
    "Winschoten",
    "Winssen",
    "Winsum",
    "Wintelre",
    "Winterswijk",
    "Wissenkerke",
    "Witharen",
    "Wittem",
    "Witteveen",
    "Woensdrecht",
    "Woerden",
    "Woerdense Verlaat",
    "Wognum",
    "Wolfheze",
    "Wolphaartsdijk",
    "Woltersum",
    "Wolvega",
    "Wommels",
    "Wons",
    "Workum",
    "Wormer",
    "Wormerveer",
    "Woubrugge",
    "Woudenberg",
    "Woudrichem",
    "Woudsend",
    "Wouwsche Plantage",
    "Yde",
    "Yerseke",
    "Ysbrechtum",
    "Zaamslag",
    "Zaandam",
    "Zaandijk",
    "Zaanstad",
    "Zalk",
    "Zaltbommel",
    "Zandeweer",
    "Zandvoort",
    "Zeddam",
    "Zeeland",
    "Zeerijp",
    "Zeewolde",
    "Zeist",
    "Zelhem",
    "Zetten",
    "Zevenaar",
    "Zevenbergen",
    "Zevenbergschen Hoek",
    "Zevenhoven",
    "Zevenhuizen",
    "Zierikzee",
    "Zieuwent",
    "Zijderveld",
    "Zoetermeer",
    "Zoeterwoude",
    "Zonnemaire",
    "Zorgvlied",
    "Zoutelande",
    "Zoutkamp",
    "Zuid-Beijerland",
    "Zuid-Scharwoude",
    "Zuidbroek",
    "Zuidermeer",
    "Zuiderpark",
    "Zuidhorn",
    "Zuidland",
    "Zuidlaren",
    "Zuidoostbeemster",
    "Zuidwolde",
    "Zuidzande",
    "Zuilichem",
    "Zundert",
    "Zutphen",
    "Zwaag",
    "Zwaagdijk-Oost",
    "Zwaanshoek",
    "Zwanenburg",
    "Zwartebroek",
    "Zwartemeer",
    "Zwartewaal",
    "Zwartsluis",
    "Zwijndrecht",
    "Zwinderen",
    "Zwolle",
  ],
  "New Caledonia": ["Dumbea", "Mont-Dore", "Noumea", "Nouville", "Paita"],
  "New Zealand": [
    "Ahaura",
    "Albany",
    "Amberley",
    "Ashhurst",
    "Auckland",
    "Avondale",
    "Awanui",
    "Balclutha",
    "Balfour",
    "Beachlands",
    "Belmont",
    "Bethlehem",
    "Blackburn",
    "Bombay",
    "Brightwater",
    "Browns Bay",
    "Bulls",
    "Campbells Bay",
    "Cashmere Hills",
    "Cave",
    "Cheviot",
    "Christchurch",
    "Clarks",
    "Clevedon",
    "Clinton",
    "Clive",
    "Clyde",
    "Coalgate",
    "Coatesville",
    "Collingwood",
    "Colville",
    "Coromandel",
    "Darfield",
    "Dargaville",
    "Douglas",
    "Doyleston",
    "Drury",
    "Dunedin",
    "Duvauchelle",
    "East Tamaki",
    "Eastbourne",
    "Edendale",
    "Eltham",
    "Fairlie",
    "Favona",
    "Foxton",
    "Foxton Beach",
    "Franz Josef",
    "Gisborne",
    "Glen Eden",
    "Glenbrook",
    "Greenhithe",
    "Greerton",
    "Greymouth",
    "Haast",
    "Halswell",
    "Hamilton",
    "Hastings",
    "Havelock North",
    "Hawarden",
    "Hawera",
    "Herne Bay",
    "Hikuai",
    "Hokitika",
    "Howick",
    "Hunterville",
    "Invercargill",
    "Johnsonville",
    "Kaeo",
    "Kaiata",
    "Kaikoura",
    "Kaitangata",
    "Kaiwaka",
    "Kaiwharawhara",
    "Kamo",
    "Karamea",
    "Katikati",
    "Kawakawa",
    "Kawerau",
    "Kawhia",
    "Kerikeri",
    "Khandallah",
    "Kimbolton",
    "Kirwee",
    "Kohukohu",
    "Kumeu",
    "Kurow",
    "Lake Tekapo",
    "Leamington",
    "Leeston",
    "Levels",
    "Levin",
    "Lincoln",
    "Linwood",
    "Longburn",
    "Lower Hutt",
    "Lumsden",
    "Mangaroa",
    "Mangawhai Heads",
    "Mangere",
    "Mangonui",
    "Manukau",
    "Manukau",
    "Manunui",
    "Marua",
    "Masterton",
    "Matakana",
    "Maungatapere",
    "Maungaturoto",
    "Menzies Ferry",
    "Methven",
    "Milton",
    "Mission Bay",
    "Moerewa",
    "Mosgiel",
    "Mossburn",
    "Mount Maunganui",
    "Murupara",
    "Myross Bush",
    "Napier City",
    "Nelson",
    "New Plymouth",
    "Ngaio",
    "Ngatea",
    "North Shore",
    "Northcote Point",
    "Northland",
    "Oakura",
    "Oamaru",
    "Oban",
    "Ohaupo",
    "Ohura",
    "Okaiawa",
    "Okaihau",
    "Okato",
    "Onga Onga",
    "Opaheke",
    "Opotiki",
    "Opunake",
    "Orakei",
    "Oratia",
    "Orewa",
    "Otaki",
    "Otaki Beach",
    "Otane",
    "Otautau",
    "Otematata",
    "Otorohanga",
    "Oturehua",
    "Outram",
    "Owaka",
    "Oxford",
    "Paekakariki",
    "Paeroa",
    "Paihia",
    "Pakuranga",
    "Palmerston North",
    "Panmure",
    "Papakura",
    "Papamoa",
    "Paradise",
    "Paraparaumu",
    "Paraparaumu Beach",
    "Paremata",
    "Parnell",
    "Penrose",
    "Petone",
    "Piopio",
    "Pirongia",
    "Pleasant Point",
    "Pokeno",
    "Ponsonby",
    "Porangahau",
    "Porirua",
    "Port Chalmers",
    "Portland",
    "Puhoi",
    "Pukeatua",
    "Punakaiki",
    "Queenstown",
    "Raglan",
    "Rakaia",
    "Rakaia Gorge",
    "Ranfurly",
    "Red Beach",
    "Renwick",
    "Reporoa",
    "Riverhead",
    "Riversdale",
    "Riverton",
    "Rolleston",
    "Rotherham",
    "Rotorua",
    "Ruawai",
    "Russell",
    "Saint Heliers",
    "Sawyers Bay",
    "Sefton",
    "Silverdale",
    "Springs Junction",
    "Stanmore Bay",
    "Swanson",
    "Tahoraiti",
    "Takaka",
    "Takanini",
    "Takapau",
    "Takapuna",
    "Tamahere",
    "Taradale",
    "Tasman",
    "Taupiri",
    "Taupo",
    "Tauranga",
    "Te Anau",
    "Te Atatu",
    "Te Kauwhata",
    "Te Roti",
    "Templeton",
    "Thames",
    "Timaru",
    "Tinwald",
    "Tirau",
    "Titirangi North",
    "Tokomaru",
    "Tokoroa",
    "Torbay",
    "Tuakau",
    "Tuatapere",
    "Turangi",
    "Upper Moutere",
    "Urenui",
    "Waiau",
    "Waiau Pa",
    "Waihi",
    "Waihi Beach",
    "Waikanae",
    "Waikari",
    "Waikawa",
    "Waikuku",
    "Waikuku Beach",
    "Waimana",
    "Waimauku",
    "Wainuiomata",
    "Waiouru",
    "Waipara",
    "Waipawa",
    "Waipu",
    "Wairoa",
    "Wairoa",
    "Waitakere City",
    "Waitangi",
    "Waitara",
    "Waitati",
    "Waitoa",
    "Waiuku",
    "Wakefield",
    "Walton",
    "Wanaka",
    "Wanganui",
    "Warkworth",
    "Waverley",
    "Wellington",
    "Wellsford",
    "Wendon Valley",
    "West Melton",
    "Weston",
    "Westport",
    "Weymouth",
    "Whakatane",
    "Whangamata",
    "Whangaparaoa",
    "Whangarei",
    "Whitianga",
    "Winchester",
    "Windermere",
    "Winton",
    "Woodend",
    "Woolston",
    "Wyndham",
    "Yaldhurst",
  ],
  Nicaragua: [
    "Bluefields",
    "Chinandega",
    "El Panama",
    "Esteli",
    "Granada",
    "Jinotega",
    "Los Arados",
    "Managua",
    "Masaya",
    "Matagalpa",
    "Ocotal",
    "Rivas",
    "San Juan del Sur",
  ],
  Nigeria: [
    "Aba",
    "Abakaliki",
    "Abeokuta",
    "Abraka",
    "Abraka",
    "Abuja",
    "Ado-Ekiti",
    "Adodo",
    "Aganga",
    "Agege",
    "Agidingbi",
    "Ajegunle",
    "Ajuwon",
    "Akure",
    "Alimosho",
    "Anambra",
    "Apapa",
    "Ayobo",
    "Benin City",
    "Birnin Kebbi",
    "Bonny",
    "Burutu",
    "Bwari",
    "Calabar",
    "Chafe",
    "Damaturu",
    "Egbeda",
    "Ekpoma",
    "Enugu",
    "Forum",
    "Funtua",
    "Ibadan",
    "Ido",
    "Ifako",
    "Igando",
    "Igueben",
    "Ikeja",
    "Ikorodu",
    "Ikotun",
    "Ile-Ife",
    "Ilesa",
    "Ilorin",
    "Ipaja",
    "Iseri-Oke",
    "Isolo",
    "Jalingo",
    "Jos",
    "Kaduna",
    "Kano",
    "Kebbi",
    "Lagos",
    "Lekki",
    "Lokoja",
    "Magodo",
    "Makurdi",
    "Maryland",
    "Minna",
    "Mogho",
    "Mowe",
    "Mushin",
    "Nsukka",
    "Obafemi",
    "Obudu",
    "Odau",
    "Ojo",
    "Ojota",
    "Ondo",
    "Onigbongbo",
    "Orile Oshodi",
    "Oshodi",
    "Osogbo",
    "Ota",
    "Owerri",
    "Oworonsoki",
    "Port Harcourt",
    "Shomolu",
    "Suleja",
    "Suru-Lere",
    "Tara",
    "Ughelli",
    "Ungwan Madaki",
    "Uyo",
    "Warri",
    "Warri",
    "Yaba",
    "Yola",
    "Zaria",
  ],
  Norway: [
    "Abelvaer",
    "Adalsbruk",
    "Adland",
    "Agotnes",
    "Agskardet",
    "Aker",
    "Akkarfjord",
    "Akrehamn",
    "Al",
    "Alen",
    "Algard",
    "Almas",
    "Alta",
    "Alvdal",
    "Amli",
    "Amli",
    "Amot",
    "Amot",
    "Ana-Sira",
    "Andalsnes",
    "Andenes",
    "Angvika",
    "Ankenes",
    "Annstad",
    "Ardal",
    "Ardalstangen",
    "Arendal",
    "Arland",
    "Arneberg",
    "Arnes",
    "Aros",
    "As",
    "Asen",
    "Aseral",
    "Asgardstrand",
    "Ask",
    "Ask",
    "Asker",
    "Askim",
    "Aukra",
    "Auli",
    "Aurdal",
    "Aure",
    "Aursmoen",
    "Austbo",
    "Austbygdi",
    "Austevoll",
    "Austmarka",
    "Baerums verk",
    "Bagn",
    "Balestrand",
    "Ballangen",
    "Ballstad",
    "Bangsund",
    "Barkaker",
    "Barstadvik",
    "Batnfjordsora",
    "Batsto",
    "Beisfjord",
    "Beitostolen",
    "Bekkjarvik",
    "Berge",
    "Berge",
    "Bergen",
    "Berger",
    "Berkak",
    "Birkeland",
    "Birtavarre",
    "Bjaland",
    "Bjerka",
    "Bjerkvik",
    "Bjoneroa",
    "Bjordal",
    "Bjorke",
    "Bjorkelangen",
    "Bjornevatn",
    "Blaker",
    "Blakset",
    "Bleikvasslia",
    "Bo",
    "Bo",
    "Bo",
    "Bomlo",
    "Bones",
    "Borge",
    "Borgen",
    "Borhaug",
    "Borkenes",
    "Borregard",
    "Bostad",
    "Bovagen",
    "Boverfjorden",
    "Brandbu",
    "Brandval",
    "Brattholmen",
    "Brattvag",
    "Brekke",
    "Brekstad",
    "Brennasen",
    "Brevik",
    "Bronnoysund",
    "Bru",
    "Bruflat",
    "Brumunddal",
    "Brusand",
    "Bruvik",
    "Bryne",
    "Bud",
    "Burfjord",
    "Buskerud",
    "Buvika",
    "Byglandsfjord",
    "Bygstad",
    "Bykle",
    "Byrknes Nordre",
    "Cavkkus",
    "Dal",
    "Dale",
    "Dalen",
    "Davik",
    "Deknepollen",
    "Digermulen",
    "Dilling",
    "Dimmelsvik",
    "Dirdal",
    "Disena",
    "Dokka",
    "Dolemo",
    "Dovre",
    "Drag",
    "Drammen",
    "Drangedal",
    "Drobak",
    "Dverberg",
    "Dyrvika",
    "Ebru",
    "Egersund",
    "Eggedal",
    "Eggkleiva",
    "Eide",
    "Eide",
    "Eidfjord",
    "Eidsa",
    "Eidsberg",
    "Eidsdal",
    "Eidsfoss",
    "Eidsnes",
    "Eidsvag",
    "Eidsvag",
    "Eidsvoll",
    "Eidsvoll verk",
    "Eikanger",
    "Eikelandsosen",
    "Eiken",
    "Eina",
    "Eivindvik",
    "Elverum",
    "Enebakkneset",
    "Enga",
    "Engalsvik",
    "Erdal",
    "Erfjord",
    "Ervik",
    "Espeland",
    "Etne",
    "Evanger",
    "Evenskjer",
    "Evje",
    "Eydehavn",
    "Faberg",
    "Faervik",
    "Fagernes",
    "Fagerstrand",
    "Fall",
    "Fardal",
    "Farsund",
    "Fauske",
    "Feda",
    "Fedje",
    "Feiring",
    "Felle",
    "Fenstad",
    "Fetsund",
    "Fevik",
    "Figgjo",
    "Finnoy",
    "Finnsnes",
    "Finsand",
    "Fiska",
    "Fiskum",
    "Fister",
    "Fitjar",
    "Fjellstrand",
    "Fla",
    "Flam",
    "Flateby",
    "Flekke",
    "Flekkefjord",
    "Flemma",
    "Flesberg",
    "Flesnes",
    "Floro",
    "Florvag",
    "Foldereid",
    "Folderoy",
    "Folkestad",
    "Follafoss",
    "Follebu",
    "Follese",
    "Fonnes",
    "Forde",
    "Forde",
    "Fornebu",
    "Fosnavag",
    "Fossdalen",
    "Fosser",
    "Fotlandsvag",
    "Fredrikstad",
    "Frekhaug",
    "Fresvik",
    "Frogner",
    "Froland",
    "From",
    "Furnes",
    "Fyrde",
    "Fyresdal",
    "Gan",
    "Gardermoen",
    "Gargan",
    "Garnes",
    "Gasbakken",
    "Gaupen",
    "Geilo",
    "Geithus",
    "Gjerdrum",
    "Gjerstad",
    "Gjolme",
    "Glesvaer",
    "Glomfjord",
    "Godoy",
    "Godvik",
    "Gol",
    "Gran",
    "Gransherad",
    "Granvin",
    "Gratangen",
    "Gravdal",
    "Greaker",
    "Grendi",
    "Gressvik",
    "Grimstad",
    "Groa",
    "Grong",
    "Grua",
    "Gullaug",
    "Gvarv",
    "Haddal",
    "Haegeland",
    "Haerland",
    "Hagan",
    "Hagavik",
    "Hakadal",
    "Halden",
    "Hallingby",
    "Halsa",
    "Haltdalen",
    "Hamar",
    "Hamarvik",
    "Hammerfest",
    "Hansnes",
    "Haram",
    "Hareid",
    "Harstad",
    "Haslum",
    "Hasvik",
    "Hatlestranda",
    "Hauge",
    "Haugesund",
    "Haukeland",
    "Havik",
    "Havik",
    "Hebnes",
    "Hedal",
    "Heggedal",
    "Heggenes",
    "Hegra",
    "Heimdal",
    "Helgeland",
    "Helgeroa",
    "Hell",
    "Hellandsjoen",
    "Helleland",
    "Hellesylt",
    "Hellvik",
    "Hemnes",
    "Hemnesberget",
    "Hemnskjela",
    "Hemsedal",
    "Henningsvaer",
    "Herand",
    "Heroysund",
    "Herre",
    "Hersaeter",
    "Hestvika",
    "Hetlevik",
    "Hildre",
    "Hitra",
    "Hjellestad",
    "Hjelmas",
    "Hjelset",
    "Hjorungavag",
    "Hof",
    "Hokkasen",
    "Hokksund",
    "Hol",
    "Hole",
    "Holen",
    "Holmefjord",
    "Holmen",
    "Holmenkollen",
    "Holmestrand",
    "Holsen",
    "Holter",
    "Hommelvik",
    "Hommersak",
    "Honefoss",
    "Hordvik",
    "Hornnes",
    "Horte",
    "Horten",
    "Hov",
    "Hovag",
    "Hovden",
    "Hovet",
    "Hovik verk",
    "Hovin",
    "Hoyanger",
    "Hundven",
    "Hunndalen",
    "Husoy",
    "Hustad",
    "Hvalstad",
    "Hvam",
    "Hvitsten",
    "Hvittingfoss",
    "Hyggen",
    "Hylkje",
    "Hyllestad",
    "Ikornnes",
    "Indre Arna",
    "Indre Billefjord",
    "Indre Klubben",
    "Indre Ulvsvag",
    "Indreby",
    "Innbygda",
    "Inndyr",
    "Innvik",
    "Isdalsto",
    "Ise",
    "Ivgobahta",
    "Jakobselv",
    "Jar",
    "Jaren",
    "Jessheim",
    "Jevnaker",
    "Jomna",
    "Jorpeland",
    "Kabelvag",
    "Kaldfarnes",
    "Kalvag",
    "Kamben",
    "Karasjok",
    "Karlshus",
    "Karlshus",
    "Kaupanger",
    "Kautokeino",
    "Kirkenaer",
    "Kirkenes",
    "Kjeller",
    "Kjellmyra",
    "Kjerstad",
    "Kjollefjord",
    "Kjopsvik",
    "Kleive",
    "Klepp",
    "Kleppe",
    "Kleppesto",
    "Kleppstad",
    "Klofta",
    "Klokkarvik",
    "Knapper",
    "Knappstad",
    "Knarrevik",
    "Knarrlaget",
    "Kolbjornsvik",
    "Kolbotn",
    "Kolbu",
    "Kolltveit",
    "Kolnes",
    "Kolsas",
    "Kolvereid",
    "Kongsberg",
    "Kongshamn",
    "Kongsvika",
    "Kongsvinger",
    "Konsmo",
    "Konsvikosen",
    "Kopervik",
    "Koppang",
    "Korgen",
    "Kornsjo",
    "Korsvegen",
    "Kragero",
    "Krakeroy",
    "Krakstad",
    "Kristiansand",
    "Kristiansund",
    "Kroderen",
    "Krokstadelva",
    "Kval",
    "Kvalsund",
    "Kvam",
    "Kvammen",
    "Kvanne",
    "Kvelde",
    "Kvinesdal",
    "Kvinlog",
    "Kvisvik",
    "Kviteseid",
    "Kyrkjebo",
    "Kyrksaeterora",
    "Lakselv",
    "Laksevag",
    "Laksvatn",
    "Lalm",
    "Land",
    "Langangen",
    "Langesund",
    "Langevag",
    "Langfjordbotn",
    "Langhus",
    "Larkollen",
    "Larvik",
    "Laukvik",
    "Lauvsnes",
    "Lauvstad",
    "Leikang",
    "Leines",
    "Leira",
    "Leirfjord",
    "Leirsund",
    "Leirvik",
    "Leknes",
    "Lena",
    "Lensvik",
    "Lenvik",
    "Lepsoy",
    "Levanger",
    "Lidaladdi",
    "Lier",
    "Lillehammer",
    "Lillesand",
    "Lindas",
    "Loddefjord",
    "Lodingen",
    "Loen",
    "Lofthus",
    "Loken",
    "Lokken Verk",
    "Lom",
    "Lonevag",
    "Longva",
    "Lorenfallet",
    "Loten",
    "Lovund",
    "Lundamo",
    "Lunde",
    "Lunner",
    "Lyngdal",
    "Lyngseidet",
    "Lyngstad",
    "Lysaker",
    "Lysoysundet",
    "Magnor",
    "Malm",
    "Maloy",
    "Malvik",
    "Mandal",
    "Manger",
    "Manndalen",
    "Marheim",
    "Masfjorden",
    "Mathopen",
    "Maura",
    "Mehamn",
    "Meisingset",
    "Melbu",
    "Meldal",
    "Melhus",
    "Melsomvik",
    "Meraker",
    "Mestervik",
    "Midsund",
    "Miland",
    "Minnesund",
    "Mirza Rafi Sauda",
    "Misje",
    "Misvaer",
    "Mjolkeraen",
    "Mjondalen",
    "Mo",
    "Mo i Rana",
    "Modalen",
    "Moelv",
    "Moen",
    "Moen",
    "Moi",
    "Molde",
    "Moldjord",
    "Morgedal",
    "Mosby",
    "Mosjoen",
    "Moss",
    "Movik",
    "Myking",
    "Myre",
    "Myre",
    "Mysen",
    "Na",
    "Naerbo",
    "Naersnes",
    "Namsos",
    "Namsskogan",
    "Narvik",
    "Naustdal",
    "Nedenes",
    "Nedre Frei",
    "Nesbru",
    "Nesbyen",
    "Nesgrenda",
    "Nesna",
    "Nesoddtangen",
    "Nesttun",
    "Neverdal",
    "Nevlunghamn",
    "Nodeland",
    "Nordby Bruk",
    "Nordfjordeid",
    "Nordkisa",
    "Nordland",
    "Nordstrono",
    "Noresund",
    "Norheimsund",
    "Notodden",
    "Nybergsund",
    "Nyborg",
    "Nydalen",
    "Nygardsjoen",
    "Nyhus",
    "Nykirke",
    "Odda",
    "Odnes",
    "Oksfjord",
    "Oksvoll",
    "Olden",
    "Olderdalen",
    "Olen",
    "Oltedal",
    "Oma",
    "Onarheim",
    "Oppdal",
    "Oppegard",
    "Opphaug",
    "Oresvika",
    "Orje",
    "Orkanger",
    "Ornes",
    "Orre",
    "Os",
    "Os",
    "Oslo",
    "Otta",
    "Otteroy",
    "Ottestad",
    "Oveland",
    "Ovre Ardal",
    "Ovrebo",
    "Oyeren",
    "Oystese",
    "Porsgrunn",
    "Prestfoss",
    "Raholt",
    "Rakkestad",
    "Ramberg",
    "Ramfjordbotn",
    "Ramnes",
    "Rana",
    "Ranasfoss",
    "Randaberg",
    "Ranheim",
    "Raudeberg",
    "Raudsand",
    "Raufoss",
    "Rauland",
    "Re",
    "Re",
    "Reine",
    "Reinsvoll",
    "Reipa",
    "Reistad",
    "Reitan",
    "Rena",
    "Rennebu",
    "Rindal",
    "Ringebu",
    "Ringsaker",
    "Ringstad",
    "Risoyhamn",
    "Rjukan",
    "Roa",
    "Rodberg",
    "Rodoy",
    "Rognan",
    "Rogne",
    "Rokland",
    "Roldal",
    "Rollag",
    "Rolvsoy",
    "Romedal",
    "Rong",
    "Roros",
    "Rorvik",
    "Rosendal",
    "Rossland",
    "Rost",
    "Rovde",
    "Roverud",
    "Royken",
    "Royneberg",
    "Rubbestadneset",
    "Rud",
    "Rygge",
    "Rykene",
    "Rypefjord",
    "Saebo",
    "Saebovik",
    "Saetre",
    "Saevareid",
    "Saeveland",
    "Sagvag",
    "Salhus",
    "Salsbruket",
    "Salsnes",
    "Saltnes",
    "Samuelsberg",
    "Sand",
    "Sand",
    "Sandane",
    "Sande",
    "Sande",
    "Sandefjord",
    "Sandeid",
    "Sander",
    "Sandnes",
    "Sandnes",
    "Sandnessjoen",
    "Sandshamn",
    "Sandstad",
    "Sandtorg",
    "Sandvika",
    "Sandvoll",
    "Sannidal",
    "Sarpsborg",
    "Saupstad",
    "Selasvatn",
    "Selje",
    "Seljord",
    "Sellebakk",
    "Selva",
    "Selvaer",
    "Sem",
    "Setermoen",
    "Siggerud",
    "Siljan",
    "Silsand",
    "Singsas",
    "Sira",
    "Sirevag",
    "Sistranda",
    "Sjovegan",
    "Skabu",
    "Skage",
    "Skanevik",
    "Skarer",
    "Skarnes",
    "Skatoy",
    "Skaun",
    "Skedsmokorset",
    "Skeie",
    "Ski",
    "Skien",
    "Skjeberg",
    "Skjerstad",
    "Skjervoy",
    "Skjold",
    "Skjoldastraumen",
    "Skjolden",
    "Skodje",
    "Skogn",
    "Skogn",
    "Skoppum",
    "Skotbu",
    "Skotterud",
    "Skreia",
    "Skudeneshavn",
    "Skulsfjord",
    "Skutvika",
    "Slastad",
    "Slattum",
    "Slemdal",
    "Slemmestad",
    "Sletta",
    "Snaase",
    "Snillfjord",
    "Sogn",
    "Sokna",
    "Sokndal",
    "Soknedal",
    "Sola",
    "Solbergelva",
    "Solvorn",
    "Sommaroy",
    "Somna",
    "Son",
    "Sondeled",
    "Sor-Fron",
    "Sorbo",
    "Soreidgrenda",
    "Sorli",
    "Sortland",
    "Sorum",
    "Sorumsand",
    "Sorvaer",
    "Sorvagen",
    "Sorvik",
    "Spangereid",
    "Sparbu",
    "Sperrebotn",
    "Spillum",
    "Spydeberg",
    "Stabbestad",
    "Stabekk",
    "Stamnes",
    "Stamsund",
    "Stange",
    "Stathelle",
    "Staubo",
    "Stavanger",
    "Stavern",
    "Stavern",
    "Steigen",
    "Steinberg",
    "Steinkjer",
    "Steinsdalen",
    "Sto",
    "Stokke",
    "Stokmarknes",
    "Stol",
    "Storas",
    "Stordal",
    "Storebo",
    "Storforshei",
    "Storslett",
    "Storsteinnes",
    "Stranda",
    "Straume",
    "Straumen",
    "Strommen",
    "Stronstad",
    "Strusshamn",
    "Stryn",
    "Suldalsosen",
    "Sulisjielmma",
    "Sund",
    "Sundal",
    "Sunde",
    "Sunndalsora",
    "Surnadalsora",
    "Svarstad",
    "Svartskog",
    "Sveio",
    "Svelgen",
    "Svelvik",
    "Svene",
    "Svortland",
    "Sylling",
    "Syvik",
    "Tafjord",
    "Talvik",
    "Tananger",
    "Tanem",
    "Tangen",
    "Tau",
    "Tennevoll",
    "Tennfjord",
    "Tertnes",
    "Tiller",
    "Tingvoll",
    "Tistedal",
    "Tjeldsto",
    "Tjelta",
    "Tjong",
    "Tjorvag",
    "Tjotta",
    "Tofte",
    "Tolga",
    "Tomasjorda",
    "Tomter",
    "Tonstad",
    "Tornes",
    "Torod",
    "Torp",
    "Torpo",
    "Tovik",
    "Trana",
    "Tranby",
    "Trengereid",
    "Tretten",
    "Treungen",
    "Trofors",
    "Trollfjorden",
    "Tromsdalen",
    "Trondheim",
    "Trones",
    "Turoy",
    "Tvedestrand",
    "Tveit",
    "Tynset",
    "Tyristrand",
    "Tysnes",
    "Tysse",
    "Tyssedal",
    "Uggdal",
    "Ulefoss",
    "Ulstein",
    "Ulsteinvik",
    "Ulvagen",
    "Ulvik",
    "Undeim",
    "Uskedalen",
    "Utsira",
    "Utskarpen",
    "Uvdal",
    "Vadheim",
    "Vage",
    "Vagland",
    "Vaksdal",
    "Vale",
    "Valen",
    "Valer",
    "Valer",
    "Valestrand",
    "Valestrandfossen",
    "Valldal",
    "Valle",
    "Valle",
    "Valsoyfjord",
    "Vangsvika",
    "Vannvag",
    "Vanse",
    "Varangerbotn",
    "Varhaug",
    "Vassenden",
    "Vatne",
    "Vedavagen",
    "Vegarshei",
    "Veggli",
    "Venabygd",
    "Vennesla",
    "Verdal",
    "Vestby",
    "Vestfossen",
    "Vestnes",
    "Vestra Mosterhamn",
    "Vestre Gausdal",
    "Vevang",
    "Vevelstad",
    "Vigrestad",
    "Vikebygd",
    "Vikedal",
    "Vikersund",
    "Vikesa",
    "Vikran",
    "Vingelen",
    "Vinje",
    "Vinstra",
    "Voksa",
    "Volda",
    "Vollen",
    "Vormedal",
    "Vormsund",
    "Voss",
    "Vossestrand",
    "Vraliosen",
    "Ytre Alvik",
  ],
  Oman: ["Al Sohar", "Muscat", "Nizwa", "Ruwi", "Saham", "Salalah", "Samad"],
  Pakistan: [
    "Abbottabad",
    "Attock",
    "Batgram",
    "Bhimbar",
    "Burewala",
    "Cantt",
    "Chakwal",
    "Clifton",
    "Daska",
    "Daud Khel",
    "Dera Ghazi Khan",
    "Faisalabad",
    "Fazal",
    "Gilgit",
    "Goth Abad Magsi",
    "Gujar Khan",
    "Gujranwala",
    "Gujrat",
    "Gulberg",
    "Gulshan-e-Iqbal",
    "Habib Baihk",
    "Haripur",
    "Havelian",
    "Hyderabad",
    "Islamabad",
    "Jhang City",
    "Jhang Sadr",
    "Jhelum",
    "Jhumra",
    "Kabirwala",
    "Karachi",
    "Kasur",
    "Khan",
    "Khanewal",
    "Khanpur",
    "Kohat",
    "Lahore",
    "Mandi",
    "Mandi Bahauddin",
    "Mansehra",
    "Mardan",
    "Mian Channu",
    "Mianwali",
    "Miran Shah",
    "Multan",
    "Muzaffarabad",
    "Nangar",
    "Nankana Sahib",
    "Narowal",
    "New Mirpur",
    "Nowshera",
    "Okara",
    "Peshawar",
    "Pindi",
    "Plot",
    "Quetta",
    "Rawalpindi",
    "Rawlakot",
    "Saddar",
    "Sahiwal",
    "Sarai Sidhu",
    "Sargodha",
    "Sarwar",
    "Sheikhupura",
    "Sialkot",
    "Sukkur",
    "Toba Tek Singh",
    "Usman",
    "Wazirabad",
    "Ziauddin",
  ],
  Palestine: [
    "Al Mawasi",
    "Bethlehem",
    "Gaza",
    "Hebron",
    "Jenin",
    "Jericho",
    "Nablus",
    "Ramallah",
  ],
  Panama: [
    "Aguadulce",
    "Albrook",
    "Ancon",
    "Arosemena",
    "Arraijan",
    "Balboa",
    "Bella Vista",
    "Bocas del Toro",
    "Boquete",
    "Bugaba",
    "Calidonia",
    "Campo Alegre",
    "Cerro Viento",
    "Chigore",
    "Chiriqui",
    "Cocle",
    "Corozal",
    "Cristobal",
    "Curundame",
    "Curundu",
    "David",
    "El Arado",
    "El Cangrejo",
    "El Lago",
    "Elena",
    "Entre Rios",
    "Finca Blanco Numero Uno",
    "Fuerte Amador",
    "Guadalupe",
    "Jimenez",
    "Juan Diaz",
    "Juan Diaz",
    "Juan Franco",
    "Kuba",
    "La Chorrera",
    "La Exposicion",
    "La Loma",
    "Las Mercedes",
    "Las Sabanas",
    "Las Tablas",
    "Llano Tugri",
    "Los Angeles",
    "Muerto",
    "Mulatupo",
    "Paitilla",
    "Panama City",
    "Parque Lefevre",
    "Peru",
    "Playa Blanca",
    "Plaza",
    "Portobelo",
    "Pueblo Nuevo",
    "Quebrada de Camilo",
    "Rio Abajo",
    "Sabalo",
    "Sacramento",
    "San Cristobal",
    "San Felipe",
    "San Francisco",
    "San Miguelito",
    "Santa Ana",
    "Santa Catalina",
    "Santa Clara",
    "Santa Isabel",
    "Santiago",
    "Santo Domingo",
    "Tocumen",
    "Torre",
    "Torres Bluff",
    "Veraguas",
    "Victoria",
    "Vista Alegre",
    "Vista Hermosa",
  ],
  "Papua New Guinea": [
    "Aitape",
    "Arawa",
    "Daru",
    "Goroka",
    "Kavieng",
    "Kerema",
    "Kikori",
    "Kimbe",
    "Kiunga",
    "Kokopo",
    "Kundiawa",
    "Kupano",
    "Lae",
    "Lorengau",
    "Madang",
    "Mendi",
    "Mount Hagen",
    "Namatanai",
    "Nambaga",
    "Paivara",
    "Pongani",
    "Popondetta",
    "Port Moresby",
    "Vanimo",
    "Wabag",
    "Waigani",
    "Wewak",
  ],
  Paraguay: [
    "Ayolas",
    "Boqueron",
    "Chore",
    "Ciudad del Este",
    "Colonia Mariano Roque Alonso",
    "Coronel Oviedo",
    "Fernando de la Mora",
    "Fuerte Olimpo",
    "Hernandarias",
    "Hohenau",
    "Independencia",
    "La Paz",
    "Limpio",
    "Loma Plata",
    "Luque",
    "Nemby",
    "Presidente Franco",
    "Salto del Guaira",
    "San Alberto",
    "San Juan Bautista",
    "San Lorenzo",
    "Santa Rita",
    "Santa Rosa",
    "Villa Elisa",
    "Villa Hayes",
    "Villarrica",
    "Yataity",
  ],
  Peru: [
    "Abancay",
    "Arequipa",
    "Ate",
    "Ayacucho",
    "Bagua",
    "Barranca",
    "Barranco",
    "Bellavista",
    "Bolivar",
    "Cajamarca",
    "Callao",
    "Calle",
    "Caras",
    "Cerro de Pasco",
    "Chachapoyas",
    "Chiclayo",
    "Chimbote",
    "Chincha",
    "Cusco",
    "Cuzco",
    "El Agustino",
    "Hacienda La Palma",
    "Huacho",
    "Huancavelica",
    "Huancayo",
    "Huaral",
    "Huaraz",
    "Huaytara",
    "Ica",
    "Ilo",
    "Imperial",
    "Iquitos",
    "Jaen",
    "Jesus Maria",
    "Jose Olaya",
    "Juliaca",
    "Junin",
    "La Molina",
    "La Perla",
    "La Punta",
    "Lambayeque",
    "Lima",
    "Lince",
    "Loreto",
    "Lurigancho",
    "Machu Picchu",
    "Maldonado",
    "Minas de Marcona",
    "Miraflores",
    "Moquegua",
    "Moyobamba",
    "Pasco",
    "Paucarpata",
    "Pimentel",
    "Pisco",
    "Piura",
    "Progreso",
    "Pucallpa",
    "Puerto Inca",
    "Puerto Maldonado",
    "Puno",
    "Rimac",
    "Rimac",
    "Rioja",
    "San Borja",
    "San Isidro",
    "San Isidro",
    "San Juan Bautista",
    "San Martin",
    "San Martin",
    "San Miguel",
    "Santa",
    "Santiago",
    "Santiago De Surco",
    "Sullana",
    "Surco",
    "Surquillo",
    "Tacna",
    "Talara",
    "Tarapoto",
    "Tingo Maria",
    "Trujillo",
    "Tumbes",
    "Ventanilla",
    "Victoria",
  ],
  Philippines: [
    "Abucay",
    "Acacia",
    "Aguilar",
    "Agusan Pequeno",
    "Alabang",
    "Alaminos",
    "Alaminos",
    "Alcala",
    "Alfonso",
    "Alitagtag",
    "Amadeo",
    "Angat",
    "Angeles City",
    "Angeles City",
    "Angono",
    "Antipolo",
    "Antipolo",
    "Apalit",
    "Aquino",
    "Arayat",
    "Asia",
    "Aurora",
    "Ayala",
    "Baao",
    "Baclaran",
    "Bacolod City",
    "Bacoor",
    "Bagac",
    "Bago City",
    "Bagong Nayon",
    "Baguio City",
    "Bagumbayan",
    "Balabag",
    "Balagtas",
    "Balamban",
    "Balanga",
    "Balayan",
    "Baliuag",
    "Balungao",
    "Barbar",
    "Bataan",
    "Bataan",
    "Batangas",
    "Bates",
    "Batis",
    "Bauan",
    "Bauan",
    "Bautista",
    "Bay",
    "Bayan",
    "Bayawan",
    "Binan",
    "Binangonan",
    "Binondo",
    "Blumentritt",
    "Boac",
    "Bocaue",
    "Bonifacio",
    "Bool",
    "Bukidnon",
    "Bulacan",
    "Bulacan",
    "Burgos",
    "Bustos",
    "Buting",
    "Butuan",
    "Cabanatuan City",
    "Cabatuan",
    "Cabugao",
    "Cabuyao",
    "Cafe",
    "Cagayan",
    "Cagayan de Oro",
    "Cainta",
    "Calaca",
    "Calamba",
    "Calamba City",
    "Calapan",
    "Calasiao",
    "Calbayog City",
    "Calinan",
    "Caloocan",
    "Caloocan",
    "Caloocan City",
    "Campus",
    "Candelaria",
    "Candon",
    "Canlubang",
    "Capitol",
    "Cardona",
    "Carlatan",
    "Carlos",
    "Carmona",
    "Carolinas",
    "Cauayan",
    "Cavinti",
    "Cavite City",
    "Cebu City",
    "Central",
    "Centre",
    "Centro",
    "China",
    "Cinco",
    "City of Batac",
    "Cogan",
    "Concepcion",
    "Concepcion",
    "Cotabato City",
    "Cotabato City",
    "Cruz",
    "Cubao",
    "Dagupan",
    "Dagupan",
    "Danao",
    "Dapitan",
    "Daraga",
    "Dasmarinas",
    "Davao City",
    "Del Monte",
    "Del Pilar",
    "Digos",
    "Diliman Primero",
    "Dinas",
    "Dingras",
    "Dipolog City",
    "Dolores",
    "Domingo",
    "Don Bosco Executive Village",
    "Don Jose",
    "Dumaguete",
    "Fairview",
    "Feliciano",
    "Fernandez",
    "Fernando",
    "Fortuna",
    "Gallego",
    "General",
    "General Santos",
    "General Trias",
    "Gitagum",
    "Gloria",
    "Guagua",
    "Hagonoy",
    "Hilongos",
    "Himamaylan",
    "Ilagan",
    "Iligan",
    "Ilog",
    "Iloilo City",
    "Iloilo City",
    "Imus",
    "Imus",
    "Interior",
    "Iriga City",
    "Isabela",
    "Island Park",
    "Jagna",
    "Kalibo",
    "Kapatagan",
    "Kawit",
    "Kidapawan",
    "Koronadal",
    "La Salette",
    "La Trinidad",
    "La Union",
    "Laguerta",
    "Laguna",
    "Laguna",
    "Lahug",
    "Lamitan",
    "Laoag",
    "Lapu-Lapu City",
    "Las Pinas",
    "Lawis",
    "Legaspi",
    "Leon",
    "Leyte",
    "Liberty",
    "Libis",
    "Lilio",
    "Limay",
    "Lipa City",
    "Llanera",
    "Looc",
    "Los Banos",
    "Los Martires",
    "Lourdes",
    "Lucena City",
    "Luna",
    "Luna",
    "Luzon",
    "Mabalacat",
    "Mabini",
    "Macabebe",
    "Magsaysay",
    "Makati City",
    "Malabon",
    "Malabon",
    "Malasiqui",
    "Malate",
    "Malolos",
    "Malvar",
    "Mamungan",
    "Manaoag",
    "Mandaluyong City",
    "Mandaue City",
    "Mandaue City",
    "Mangaldan",
    "Mangrove",
    "Manila",
    "Manila",
    "Mapua",
    "Maquiling",
    "Marcos",
    "Maria",
    "Marikina City",
    "Marilag",
    "Marilao",
    "Marina",
    "Mariveles",
    "Masbate",
    "Matalam",
    "Matandang Balara",
    "Mauban",
    "Mawab",
    "Merville Subdivision",
    "Meycauayan",
    "Miagao",
    "Minalin",
    "Mindanaw",
    "Minglanilla",
    "Misamis",
    "Molave",
    "Munoz East",
    "Muntinlupa",
    "Naga",
    "Naga City",
    "Naguilian",
    "Navotas",
    "Navotas",
    "Navotas",
    "New Manila",
    "Norte",
    "Novaliches",
    "Nueva",
    "Oara",
    "Obando",
    "Occidental",
    "Olongapo City",
    "Olongapo City",
    "Orani",
    "Orion",
    "Osmena",
    "Ozamiz City",
    "Pacita",
    "Padre Garcia",
    "Paete",
    "Pagadian",
    "Pagasinan",
    "Pagsanjan",
    "Palma Gil",
    "Palo",
    "Pampanga",
    "Panabo",
    "Pandi",
    "Pangil",
    "Paniqui",
    "Paranaque City",
    "Pardo",
    "Pasay",
    "Pasig",
    "Pasig",
    "Pasong Tamo",
    "Pateros",
    "Paul",
    "Philippine",
    "Pias",
    "Pikit",
    "Pinaglabanan",
    "Plaridel",
    "Plaridel",
    "Plaza",
    "Poblacion",
    "Poblacion, San Felipe",
    "Princesa",
    "Province of Cebu",
    "Province of Laguna",
    "Province of Pampanga",
    "Province of Pangasinan",
    "Puerto Princesa City",
    "Pulilan",
    "Putatan",
    "Quezon",
    "Quezon",
    "Quezon City",
    "Ramon",
    "Real",
    "Rizal",
    "Rizal",
    "Rodriguez",
    "Roosevelt",
    "Roque",
    "Rosales",
    "Rosario West",
    "Roxas City",
    "Salcedo",
    "Salinas",
    "Salle",
    "Samal",
    "Sampaloc",
    "San Agustin",
    "San Andres",
    "San Antonio",
    "San Carlos City",
    "San Fabian",
    "San Fernando",
    "San Fernando",
    "San Francisco",
    "San Jose",
    "San Jose",
    "San Jose del Monte",
    "San Juan",
    "San Mateo",
    "San Miguel",
    "San Miguel",
    "San Pablo City",
    "San Pedro",
    "San Pedro",
    "San Vicente",
    "Santa Cruz",
    "Santa Rosa",
    "Santiago",
    "Santo",
    "Santo Tomas",
    "Santos",
    "Sariaya",
    "Silang",
    "Silang",
    "Silay",
    "Siniloan",
    "Smart",
    "Sorsogon",
    "Sta Cruz",
    "Sto Nino",
    "Subic",
    "Subic",
    "Subic",
    "Sucat",
    "Sulo",
    "Sultan Kudarat",
    "Summit",
    "Suyo",
    "Taal",
    "Tacloban City",
    "Tacurong",
    "Taft",
    "Tagaytay",
    "Tagbilaran City",
    "Taguig",
    "Tagum",
    "Talisay City",
    "Talon",
    "Tamag",
    "Tambler",
    "Tambo",
    "Tanauan",
    "Tanauan",
    "Tanay",
    "Tandang Sora",
    "Tanza",
    "Tarlac City",
    "Tayabas",
    "Taytay",
    "Tayug",
    "Tejeros Convention",
    "Tigbauan",
    "Toledo City",
    "Trece Martires City",
    "Trinidad",
    "Tuguegarao City",
    "Tuktukan",
    "Ugong Norte",
    "Upper Bicutan",
    "Urdaneta",
    "Valencia",
    "Valencia",
    "Valenzuela",
    "Vargas",
    "Ventura",
    "Veronica",
    "Victorias City",
    "Victory",
    "Vigan",
    "Villa",
    "Villanueva",
    "Vito",
    "West",
    "West Triangle",
    "Wines",
    "Zamboanga City",
    "Zamboanga City",
  ],
  Poland: [
    "Adama",
    "Alwernia",
    "Andrespol",
    "Andrychow",
    "Anin",
    "Annopol",
    "Arkadia",
    "Babienica",
    "Babimost",
    "Baborow",
    "Baboszewo",
    "Balice",
    "Balice",
    "Banino",
    "Baniocha",
    "Baran",
    "Baranow",
    "Baranowko",
    "Barciany",
    "Barcice",
    "Barcin",
    "Barczewo",
    "Barglowka",
    "Barlinek",
    "Bartoszyce",
    "Baruchowo",
    "Barwice",
    "Bazanowka",
    "Beblo",
    "Bedkow",
    "Bejsce",
    "Belk",
    "Belsk Duzy",
    "Belsznica",
    "Bestwina",
    "Bestwinka",
    "Biala",
    "Bialobrzegi",
    "Bialy Dunajec",
    "Bibice",
    "Biecz",
    "Biedrusko",
    "Bielany",
    "Bielany Wroclawskie",
    "Bielawa",
    "Bielawa",
    "Bielawy",
    "Bielcza",
    "Bieliny",
    "Bielkowo",
    "Bielsk",
    "Bielsk Podlaski",
    "Bielsko-Biala",
    "Bierun",
    "Bierun Nowy",
    "Bierutow",
    "Biesiekierz",
    "Biezanow-Prokocim",
    "Bircza",
    "Biskupice",
    "Biskupice",
    "Biskupice Oloboczne",
    "Biskupiec",
    "Biskupin",
    "Bisztynek",
    "Blachownia",
    "Blazowa",
    "Bledzew",
    "Blizne",
    "Blizyn",
    "Bobrowniki",
    "Bobrza",
    "Bochnia",
    "Bochotnica",
    "Bochowo",
    "Bodzentyn",
    "Bogatynia",
    "Bogdaszowice",
    "Bogumilowice",
    "Bogunice",
    "Boguslaw",
    "Bohdan",
    "Bojadla",
    "Bojano",
    "Bojanowo",
    "Bojszow",
    "Bojszowy",
    "Bolechowice",
    "Boleslaw",
    "Bolewice",
    "Bolkow",
    "Bolszewo",
    "Borek Strzelinski",
    "Borki",
    "Borkowice",
    "Borkowo",
    "Borne Sulinowo",
    "Borowa",
    "Borowe",
    "Borowiec",
    "Bory",
    "Borzecin",
    "Borzykowo",
    "Bralin",
    "Bramki",
    "Braniewo",
    "Braszewice",
    "Bratkowice",
    "Brenna",
    "Brochocin",
    "Brodnica",
    "Brok",
    "Brudzice",
    "Brudzowice",
    "Brynica",
    "Brzeg",
    "Brzeg Dolny",
    "Brzesko",
    "Brzeszcze",
    "Brzezinka",
    "Brzeziny",
    "Brzeznica",
    "Brzeznica",
    "Brzostek",
    "Brzostowka",
    "Brzoza Krolewska",
    "Brzozow",
    "Brzyska Wola",
    "Buczkowice",
    "Budziska",
    "Budzyn",
    "Buk",
    "Bukowice",
    "Bukowiec Opoczynski",
    "Bukowina Tatrzanska",
    "Bukowno",
    "Bukowsko",
    "Burzenin",
    "Bychawa",
    "Byczyna",
    "Bydgoszcz",
    "Bydlin",
    "Byslaw",
    "Bystra",
    "Bystrzyca Klodzka",
    "Bytom",
    "Cekcyn",
    "Cekow",
    "Chalupki",
    "Charzykowy",
    "Checiny",
    "Chelm",
    "Chelmek",
    "Chelmsko Slaskie",
    "Chmielnik",
    "Chmielnik",
    "Chmielow",
    "Chocianow",
    "Chociwel",
    "Choczewo",
    "Chocznia",
    "Chojna",
    "Chojnice",
    "Choroszcz",
    "Chorzele",
    "Chorzelow",
    "Choszczno",
    "Chotel",
    "Chotomow",
    "Chrzan",
    "Chrzastowka",
    "Chrzesne",
    "Chrzowice",
    "Chwaszczyno",
    "Chybie",
    "Chylice",
    "Chyliczki",
    "Cianowice Duze",
    "Ciasna",
    "Ciechocin",
    "Ciechocinek",
    "Cierpice",
    "Cieszkow",
    "Cieszyn",
    "Ciezkowice",
    "Cigacice",
    "Cisiec",
    "Cmielow",
    "Cmolas",
    "Cwiklice",
    "Cybinka",
    "Czaniec",
    "Czaplinek",
    "Czapury",
    "Czarna",
    "Czarne",
    "Czarnkow",
    "Czarnozyly",
    "Czarny Bor",
    "Czarny Dunajec",
    "Czarny Las",
    "Czarze",
    "Czastary",
    "Czechowice-Dziedzice",
    "Czekanow",
    "Czeladz",
    "Czempin",
    "Czernica",
    "Czernica",
    "Czernichow",
    "Czerniewice",
    "Czersk",
    "Czersk",
    "Czerwiensk",
    "Czerwionka-Leszczyny",
    "Czerwonak",
    "Czluchow",
    "Czosnow",
    "Czudec",
    "Czyzyny",
    "Dabki",
    "Dabrowa",
    "Dabrowa Bialostocka",
    "Dabrowa Biskupia",
    "Dabrowa Chelminska",
    "Dabrowa Chotomowska",
    "Dabrowka Wielka",
    "Dabrowno",
    "Dabrowskie",
    "Daleszyce",
    "Damnica",
    "Dankowice",
    "Dargoslaw",
    "Darnowo",
    "Debe Wielkie",
    "Debica",
    "Debina",
    "Deblin",
    "Debno",
    "Debno",
    "Debowiec",
    "Debrzno Wies",
    "Deszczno",
    "Dlugopole-Zdroj",
    "Dobiesz",
    "Dobieszowice",
    "Dobra",
    "Dobra",
    "Dobre Miasto",
    "Dobrodzien",
    "Dobromierz",
    "Dobron",
    "Dobroszyce",
    "Dobroszyce",
    "Dobrzany",
    "Dobrzen Wielki",
    "Dobrzyca",
    "Dolaszewo",
    "Dolice",
    "Domaniewice",
    "Domaradz",
    "Domaslaw",
    "Dopiewiec",
    "Drawno",
    "Drawsko Pomorskie",
    "Drewnica",
    "Drezdenko",
    "Drohiczyn",
    "Drozdowo",
    "Druzbice",
    "Drzewica",
    "Duczki",
    "Dukla",
    "Dulcza Wielka",
    "Dunaj",
    "Duszniki-Zdroj",
    "Dygowo",
    "Dylewo",
    "Dynow",
    "Dywity",
    "Dzialoszyn",
    "Dziechciniec",
    "Dziegielow",
    "Dziekanow Lesny",
    "Dzielna",
    "Dzierzazno",
    "Dzierzgon",
    "Dzierzoniow",
    "Dziewin",
    "Dzikowiec",
    "Dziwnow",
    "Dzwierzuty",
    "Elblag",
    "Elzbieta",
    "Fabianki",
    "Falkow",
    "Falkowo",
    "Frank",
    "Frombork",
    "Frydrychowice",
    "Frysztak",
    "Gadka Stara",
    "Garbow",
    "Garby",
    "Garki",
    "Garwolin",
    "Gaszowice",
    "Gaworzyce",
    "Gdow",
    "Gdynia",
    "Giby",
    "Gieraltowice",
    "Gieraltowice",
    "Gizalki",
    "Gizyce",
    "Gliwice",
    "Glogow Malopolski",
    "Glogowek",
    "Gloskow",
    "Glowienka",
    "Glowna",
    "Glowno",
    "Glubczyce",
    "Glucholazy",
    "Gluchow",
    "Gluszyca",
    "Gmina Babiak",
    "Gmina Bobrowo",
    "Gmina Chmielno",
    "Gmina Ciechanowiec",
    "Gmina Gnojno",
    "Gmina Kiszkowo",
    "Gmina Kolno",
    "Gmina Konarzyny",
    "Gmina Lipno",
    "Gmina Ludwin",
    "Gmina Lutomiersk",
    "Gmina Morawica",
    "Gmina Moszczenica",
    "Gmina Opole Lubelskie",
    "Gmina Przywidz",
    "Gmina Sadowie",
    "Gmina Sierakowice",
    "Gmina Strzelin",
    "Gmina Szubin",
    "Gmina Widawa",
    "Gmina Wyszki",
    "Gmina Zakliczyn",
    "Gniew",
    "Gniewino",
    "Gniewkowo",
    "Gniezno",
    "Gnuszyn",
    "Godziszewo",
    "Gogolin",
    "Golanice",
    "Golasowice",
    "Golczewo",
    "Goleszow",
    "Golkowice",
    "Golotczyzna",
    "Golub-Dobrzyn",
    "Gomunice",
    "Gora",
    "Gora",
    "Gora Kalwaria",
    "Gora Pulawska",
    "Gora Siewierska",
    "Gorazdze",
    "Gorki Wielkie",
    "Gorlice",
    "Gorno",
    "Gorz",
    "Gorzkow",
    "Gorzkowice",
    "Gorzow",
    "Gorzow Slaski",
    "Gorzyce",
    "Gorzyce",
    "Gorzyce",
    "Gorzyczki",
    "Gostyn",
    "Gostynin",
    "Goszczyn",
    "Gowarzewo",
    "Gowino",
    "Gozdnica",
    "Gozdowo",
    "Grabiec",
    "Grabki Duze",
    "Grabow nad Prosna",
    "Grabowka",
    "Gracze",
    "Grajewo",
    "Grebocice",
    "Grebocin",
    "Grodki",
    "Grodkow",
    "Grodzisk",
    "Grodzisk Mazowiecki",
    "Grodzisk Wielkopolski",
    "Grodzisko Dolne",
    "Grojec",
    "Gromiec",
    "Gronowo Elblaskie",
    "Gruczno",
    "Grunwald",
    "Grupa",
    "Gruszczyn",
    "Grybow",
    "Gryfice",
    "Gryfino",
    "Gryfow Slaski",
    "Grzebien",
    "Grzegorz",
    "Grzmiaca",
    "Grzmucin",
    "Gubin",
    "Gzin",
    "Haczow",
    "Harasiuki",
    "Hecznarowice",
    "Henrykow",
    "Hornowek",
    "Hucisko Jawornickie",
    "Humniska",
    "Huta Dabrowa",
    "Huta Dlutowska",
    "Huta Stara",
    "Ilowa",
    "Ilowo",
    "Ilza",
    "Imielin",
    "Iwaniska",
    "Iwanowice",
    "Iwiczna",
    "Iwierzyce",
    "Iwla",
    "Iwonicz-Zdroj",
    "Izabela",
    "Izabelin",
    "Jablonica",
    "Jablonka",
    "Jablonna",
    "Janin",
    "Jankowice",
    "Janow",
    "Janow Lubelski",
    "Jarkowice",
    "Jarocin",
    "Jaroszow",
    "Jaroszowiec",
    "Jasienica",
    "Jasienica Dolna",
    "Jasieniec",
    "Jaslo",
    "Jastkow",
    "Jastrowie",
    "Jastrzebie",
    "Jastrzebie",
    "Jawiszowice",
    "Jawor",
    "Jaworzno",
    "Jaworzyna Slaska",
    "Jedlicze",
    "Jedlnia-Letnisko",
    "Jelesnia",
    "Jemielnica",
    "Jerzmanowice",
    "Jeziora Wielkie",
    "Jeziorany",
    "Jezowe",
    "Jordanow",
    "Jozefatow",
    "Jozefoslaw",
    "Jozefow",
    "Juchnowiec Koscielny",
    "Jugow",
    "Juszkowo",
    "Jutrosin",
    "Kaczkowo",
    "Kaczor",
    "Kalety",
    "Kalisz",
    "Kalwaria Zebrzydowska",
    "Kamien",
    "Kamien",
    "Kamien Krajenski",
    "Kamien Pomorski",
    "Kamien Slaski",
    "Kamienica Polska",
    "Kamieniec",
    "Kamienna Gora",
    "Kamionki",
    "Kampinos",
    "Kanczuga",
    "Karchowice",
    "Karczew",
    "Kargowa",
    "Karlikowo",
    "Karlino",
    "Karnice",
    "Karniowice",
    "Karpacz",
    "Karpiska",
    "Karsko",
    "Kartuzy",
    "Kasinka",
    "Katarzyna",
    "Katowice",
    "Katy",
    "Katy",
    "Katy Wroclawskie",
    "Kazimierz Biskupi",
    "Kazimierz Dolny",
    "Kazimierza Wielka",
    "Kazmierz",
    "Kcynia",
    "Keblowo",
    "Keblowo",
    "Kedzierzyn",
    "Kedzierzyn-Kozle",
    "Kety",
    "Kicin",
    "Kielce",
    "Kielcz",
    "Kielczow",
    "Kielno",
    "Kielpin",
    "Kijewo Krolewskie",
    "Klaj",
    "Klecko",
    "Klecza Dolna",
    "Kleczany",
    "Klenica",
    "Kleszczewo",
    "Kleszczow",
    "Klikawa",
    "Klikuszowa",
    "Klimontow",
    "Kliniska",
    "Klobuck",
    "Klodawa",
    "Klomnice",
    "Kluczbork",
    "Klucze",
    "Knurow",
    "Knyszyn",
    "Kobiernice",
    "Kobierzyce",
    "Kobior",
    "Kobylanka",
    "Kobylka",
    "Kobylnica",
    "Kochcice",
    "Kocierzew Poludniowy",
    "Kocmyrzow",
    "Kojszowka",
    "Kokoszkowy",
    "Kolbudy",
    "Kolbuszowa",
    "Koleczkowo",
    "Kolno",
    "Kolodziejewo",
    "Kolonia Zawada",
    "Kolonowskie",
    "Koluszki",
    "Komorniki",
    "Komorow",
    "Komorsk",
    "Konarzewo",
    "Konarzyce",
    "Konczyce Male",
    "Koniakow",
    "Koniecpol",
    "Konin",
    "Koniusza",
    "Konopiska",
    "Konradowka",
    "Konstancin-Jeziorna",
    "Konstantyn",
    "Konstantynow",
    "Konstantynow Lodzki",
    "Kopki",
    "Korczyna",
    "Korfantow",
    "Kornik",
    "Koronowo",
    "Korsze",
    "Korytow",
    "Korzenna",
    "Kosakowo",
    "Koscielisko",
    "Koscierzyna",
    "Kosina",
    "Kostrzyn",
    "Kostrzyn nad Odra",
    "Koszalin",
    "Koszecin",
    "Koszyce",
    "Koszyce",
    "Koteze",
    "Kotlin",
    "Kowal",
    "Kowalew",
    "Kowalewo",
    "Kowalewo Pomorskie",
    "Kowalkow",
    "Kowalowa",
    "Kowary",
    "Kowiesy",
    "Kozieglowy",
    "Kozieglowy",
    "Kozienice",
    "Kozmin Wielkopolski",
    "Kozminek",
    "Kozuchow",
    "Kozy",
    "Kozy",
    "Krakow",
    "Krapkowice",
    "Krasiejow",
    "Krasne",
    "Krasnik",
    "Krasnystaw",
    "Krasocin",
    "Kraszew",
    "Krepa Kaszubska",
    "Krokowa",
    "Kroscienko Wyzne",
    "Krosno",
    "Krosno Odrzanskie",
    "Krosnowice",
    "Krotoszyn",
    "Kruszyn",
    "Kruszyna",
    "Krynica",
    "Krynica-Zdroj",
    "Krypno",
    "Krzepice",
    "Krzeszow",
    "Krzeszowice",
    "Krzyki-Partynice",
    "Krzyszkowice",
    "Krzywcza",
    "Krzywin",
    "Krzyz Wielkopolski",
    "Krzyzanowice",
    "Ksiazenice",
    "Ksieginice",
    "Kukow",
    "Kuligow",
    "Kunice",
    "Kunow",
    "Kurdwanow",
    "Kurek",
    "Kurylowka",
    "Kurzetnik",
    "Kusnierz",
    "Kutno",
    "Kuznia",
    "Kuznica Czarnkowska",
    "Kuzniki",
    "Kwaczala",
    "Kwidzyn",
    "Kwilcz",
    "Labiszyn",
    "Labowa",
    "Labunie",
    "Lachowice",
    "Lack",
    "Lacko",
    "Ladek",
    "Lajsk",
    "Laka",
    "Lancut",
    "Lany",
    "Lask",
    "Laska",
    "Laskarzew",
    "Latowicz",
    "Laziska",
    "Laziska Gorne",
    "Leba",
    "Lebork",
    "Leczna",
    "Ledziny",
    "Legionowo",
    "Legnica",
    "Legowo",
    "Lekawica",
    "Lelow",
    "Lesko",
    "Lesna",
    "Lesnica",
    "Leszkowice",
    "Leszno",
    "Leszno",
    "Lewin Brzeski",
    "Lezajsk",
    "Libiaz",
    "Lidzbark",
    "Ligota",
    "Limanowa",
    "Liniewo",
    "Linowko",
    "Lipinki Luzyckie",
    "Lipiny",
    "Lipnica Murowana",
    "Lipnica Wielka",
    "Lipnik",
    "Lipno",
    "Lipowa",
    "Lipsko",
    "Lipusz",
    "Lisewo",
    "Liskow",
    "Liszki",
    "Liw",
    "Lobez",
    "Lobzenica",
    "Lochow",
    "Lochowo",
    "Lodygowice",
    "Lomianki",
    "Lomianki Dolne",
    "Lomnica",
    "Lotyn",
    "Lowicz",
    "Lubaczow",
    "Luban",
    "Lubanie",
    "Lubartow",
    "Lubaszowa",
    "Lubawa",
    "Lubawka",
    "Lubenia",
    "Lubichowo",
    "Lubicz",
    "Lubien",
    "Lubin",
    "Lublewo",
    "Lublin",
    "Lubliniec",
    "Lubnice",
    "Lubochnia",
    "Lubomia",
    "Lubomierz",
    "Lubon",
    "Luborzyca",
    "Lubraniec",
    "Lubsko",
    "Lubsza",
    "Lubycza Krolewska",
    "Lukow",
    "Lulin",
    "Lusowko",
    "Lutynia",
    "Luzino",
    "Lysomice",
    "Mackowice",
    "Magnuszew",
    "Majdan Krolewski",
    "Majewo",
    "Makow",
    "Makow Mazowiecki",
    "Makow Podhalanski",
    "Makowiec",
    "Maksymilianowo",
    "Malbork",
    "Malczyce",
    "Malogoszcz",
    "Manowo",
    "Marcinowice",
    "Marek",
    "Margonin",
    "Maria",
    "Marki",
    "Marklowice",
    "Marta",
    "Mary",
    "MaryLka",
    "Maslice",
    "Maslow",
    "Maszewo",
    "Mazancowice",
    "Mechelinki",
    "Medyka",
    "Medynia Glogowska",
    "Melno",
    "Meszna",
    "Mialy",
    "Miasteczko Slaskie",
    "Miastko",
    "Michalowice",
    "Miechow",
    "Mieczewo",
    "Miedzna",
    "Miedzyborow",
    "Miedzyborz",
    "Miedzybrodzie Zywieckie",
    "Miedzylesie",
    "Miedzyzdroje",
    "Miejska Gorka",
    "Mielec",
    "Mielno",
    "Mieroszow",
    "Mierzecice",
    "Mierzeszyn",
    "Mikolajki",
    "Mikoszewo",
    "Mikstat",
    "Milanow",
    "Milcza",
    "Milejow",
    "Milicz",
    "Milkowice",
    "Milobadz",
    "Miloradz",
    "Milowka",
    "Minoga",
    "Mirkow",
    "Miroslaw",
    "Miroslawiec",
    "Mirsk",
    "Miszkowice",
    "Mniow",
    "Modlnica",
    "Modlniczka",
    "Modrze",
    "Mogilany",
    "Mogilno",
    "Mokrsko",
    "Morawica",
    "Moryn",
    "Mosina",
    "Mosty",
    "Moszczanka",
    "Mragowo",
    "Mrocza",
    "Mrowino",
    "Mscice",
    "Msciwojow",
    "Mszana",
    "Mszana Dolna",
    "Mucharz",
    "Murowana Goslina",
    "Muszyna",
    "Myslachowice",
    "Myslenice",
    "Mysliborz",
    "Myszkow",
    "Myszyniec",
    "Nacpolsk",
    "Nadarzyn",
    "Naklo",
    "Naleczow",
    "Namyslow",
    "Naprawa",
    "Narew",
    "Narzym",
    "Nasielsk",
    "Nawodna",
    "Nebrowo Wielkie",
    "Nidzica",
    "Nieborowice",
    "Niechanowo",
    "Niedomice",
    "Niedrzwica Duza",
    "Niegoslawice",
    "Nielisz",
    "Niemcz",
    "Niemcza",
    "Niemodlin",
    "Nienadowka",
    "Niepolomice",
    "Niewierz",
    "Nisko",
    "Niwica",
    "Nowa",
    "Nowa Biala",
    "Nowa Deba",
    "Nowa Huta",
    "Nowa Ruda",
    "Nowa Slupia",
    "Nowa Sol",
    "Nowa Wies Elcka",
    "Nowe Chechlo",
    "Nowe Lignowy",
    "Nowe Miasto Lubawskie",
    "Nowe Miasto nad Pilica",
    "Nowe Miasto nad Warta",
    "Nowe Skalmierzyce",
    "Nowo-Aleksandrowo",
    "Nowogard",
    "Nowogrodziec",
    "Nowy Dwor",
    "Nowy Dwor Gdanski",
    "Nowy Dwor Mazowiecki",
    "Nowy Korczyn",
    "Nowy Staw",
    "Nowy Swietow",
    "Nowy Targ",
    "Nowy Tomysl",
    "Nowy Wisnicz",
    "Nysa",
    "Oblaczkowo",
    "Oborniki",
    "Obrzycko",
    "Obsza",
    "Odolanow",
    "Odolin",
    "Odrzykon",
    "Ogrody",
    "Ogrodzieniec",
    "Ojrzen",
    "Oksywie",
    "Olawa",
    "Olecko",
    "Olejnica",
    "Olesnica",
    "Olesno",
    "Oleszno",
    "Olimpia",
    "Olkusz",
    "Olszany",
    "Olszowice",
    "Olsztyn",
    "Olsztynek",
    "Olszyna",
    "Oltarzew",
    "Opalenica",
    "Opatow",
    "Opatowek",
    "Opoczno",
    "Opole",
    "Orchowo",
    "Orneta",
    "Ornontowice",
    "Orzel",
    "Orzesze",
    "Orzysz",
    "Osieck",
    "Osieczna",
    "Osiek",
    "Osiek",
    "Osiek Jasielski",
    "Osielsko",
    "Osno",
    "Osno Lubuskie",
    "Ostaszewo",
    "Ostrog",
    "Ostroszowice",
    "Ostrow",
    "Ostrow Lubelski",
    "Ostrow Mazowiecka",
    "Ostrowek",
    "Ostrowite",
    "Ostrowy",
    "Ostrzeszow",
    "Otoki",
    "Otomin",
    "Otrebusy",
    "Otwock",
    "Otyn",
    "Owinska",
    "Ozarow Mazowiecki",
    "Ozimek",
    "Ozorkow",
    "Pabianice",
    "Pacanow",
    "Pajeczno",
    "Paledzie",
    "Paliszewo",
    "Paniowki",
    "Papowo",
    "Parczew",
    "Pastuchow",
    "Paulina",
    "Pawlowice",
    "Pawlowice",
    "Pcim",
    "Peczniew",
    "Pedziwiatry",
    "Pegow",
    "Pelplin",
    "Pepowo",
    "Pewel Mala",
    "Piaseczno",
    "Piasek",
    "Piaski",
    "Piaski",
    "Piastow",
    "Piechowice",
    "Piekary",
    "Piekary Slaskie",
    "Piekielnik",
    "Piekoszow",
    "Pielgrzymka",
    "Pielice",
    "Piensk",
    "Pierwoszyno",
    "Pieszkow",
    "Pieszyce",
    "Pietrowice Wielkie",
    "Pietrzykowice",
    "Pila",
    "Pila",
    "Pila Koscielecka",
    "Pilawa",
    "Pilawa",
    "Pilawa Gorna",
    "Pilchowice",
    "Pilica",
    "Pinczow",
    "Pionki",
    "Pisarzowice",
    "Pisz",
    "Plesna",
    "Pleszew",
    "Plewiska",
    "Plochocin",
    "Pniewy",
    "Pniewy",
    "Pobiedna",
    "Pobiedziska",
    "Poczesna",
    "Podegrodzie",
    "Podgorne",
    "Podgorze",
    "Podlasie",
    "Podleze",
    "Pogodki",
    "Pogorzela",
    "Pogwizdow",
    "Pokrzywnica",
    "Polajewo",
    "Polanka Wielka",
    "Polczyn-Zdroj",
    "Police",
    "Polkowice",
    "Polomia",
    "Polskie",
    "Pomiechowek",
    "Pomorskie",
    "Popow",
    "Popowice",
    "Poraj",
    "Poreba",
    "Poswietne",
    "Poznan",
    "Prabuty",
    "Prabuty",
    "Praca",
    "Praszka",
    "Prawiedniki",
    "Prochowice",
    "Prokocim",
    "Prosna",
    "Proszowice",
    "Pruchna",
    "Prudnik",
    "Prusice",
    "Pruszcz Gdanski",
    "Pruszcz Pomorski",
    "Przasnysz",
    "Przechlewo",
    "Przeclaw",
    "Przeclaw",
    "Przemet",
    "Przemysl",
    "Przemysl",
    "Przemyslaw",
    "Przeworsk",
    "Przezmierowo",
    "Przygodzice",
    "Przylep",
    "Przyrow",
    "Przysiek",
    "Przystajn",
    "Przyszowice",
    "Pszczew",
    "Pszczyna",
    "Pszow",
    "Puck",
    "Pustkow",
    "Puszczew",
    "Puszczykowo",
    "Pyrzyce",
    "Pyskowice",
    "Pysznica",
    "Rabien",
    "Rabka-Zdroj",
    "Raciaz",
    "Raciazek",
    "Racula",
    "Raczka",
    "Raczki",
    "Radgoszcz",
    "Radlin",
    "Radlow",
    "Radom",
    "Radomin",
    "Radomsko",
    "Radomysl Wielki",
    "Radoslaw",
    "Radostowice",
    "Radoszyce",
    "Radymno",
    "Radzanow",
    "Radzanowo",
    "Radziechowy",
    "Radziejowice",
    "Radzionkow",
    "Radzymin",
    "Radzyn Podlaski",
    "Rajszew",
    "Rakow",
    "Rakowiec",
    "Rakszawa",
    "Ranizow",
    "Raszowa",
    "Raszowka",
    "Raszyn",
    "Rawa Mazowiecka",
    "Rawicz",
    "Rebkow",
    "Rebowo",
    "Reczno",
    "Reda",
    "Regimin",
    "Rejowiec",
    "Reszel",
    "Rewa",
    "Rewal",
    "Roczyny",
    "Rogalinek",
    "Rogow",
    "Rogow",
    "Rogowo",
    "Rogoznik",
    "Rogozno",
    "Rokietnica",
    "Rokitki",
    "Rokitnica",
    "Ropczyce",
    "Rosnowko",
    "Rostarzewo",
    "Rozanka",
    "Rozgarty",
    "Rozprza",
    "Roztoka",
    "Ruda",
    "Rudka",
    "Rudna Mala",
    "Rudnik nad Sanem",
    "Rudy",
    "Rudzica",
    "Rudziniec",
    "Rumia",
    "Rumianek",
    "Rusiec",
    "Rybna",
    "Rybnik",
    "Rybno",
    "Rychwal",
    "Rydzyna",
    "Ryglice",
    "Ryki",
    "Ryman",
    "Rymanow",
    "Ryn",
    "Rypin",
    "Rzasnia",
    "Rzeczyce",
    "Rzemien",
    "Rzepin",
    "Rzeszotary",
    "Rzewnie",
    "Rzgow Pierwszy",
    "Rzuchowa",
    "Sadlinki",
    "Sadlno",
    "Sady",
    "Samin",
    "Sandomierz",
    "Sanniki",
    "Sanok",
    "Santok",
    "Sarbinowo",
    "Scinawa Mala",
    "Scinawa Nyska",
    "Sedziszow",
    "Sedziszow Malopolski",
    "Sepolno Krajenskie",
    "Serock",
    "Serock",
    "Serwis",
    "Sianow",
    "Sidra",
    "Sidzina",
    "Siechnice",
    "Siedlce",
    "Siedlec",
    "Siekierczyn",
    "Siemiatycze",
    "Siemirowice",
    "Siemkowice",
    "Sieniawa",
    "Sieniawa Zarska",
    "Sieniawka",
    "Siennica",
    "Siennica Nadolna",
    "Sieradz",
    "Sieroszowice",
    "Sierpc",
    "Siewierz",
    "Siwek",
    "Skala",
    "Skarszewy",
    "Skarzysko-Kamienna",
    "Skawina",
    "Skierdy",
    "Skierniewice",
    "Skoczow",
    "Skomlin",
    "Skopanie",
    "Skorcz",
    "Skorzec",
    "Skorzewo",
    "Skrbensko",
    "Skrzetusz",
    "Skrzyszow",
    "Skwierzyna",
    "Slawkow",
    "Slawno B",
    "Slawoszyno",
    "Slemien",
    "Slomniki",
    "Slone",
    "Slupca",
    "Slupno",
    "Smardzew",
    "Smardzowice",
    "Smigiel",
    "Smolec",
    "Smolnica",
    "Smolnik",
    "Sobienie Jeziory",
    "Sobotka",
    "Sobotka",
    "Sobowidz",
    "Sochaczew",
    "Sochocin",
    "Soczewka",
    "Sokolow Malopolski",
    "Sokolowsko",
    "Solec Kujawski",
    "Sompolno",
    "Sopot",
    "Sosnie",
    "Sosnowiec",
    "Sroda Wielkopolska",
    "Stadla",
    "Stalowa Wola",
    "Staniatki",
    "Staniszow",
    "Stankowo",
    "Stanowice",
    "Stara",
    "Stara Kamienica",
    "Stara Lubianka",
    "Starachowice",
    "Stare Babice",
    "Stare Bogaczowice",
    "Stare Czarnowo",
    "Stare Juchy",
    "Stare Kurowo",
    "Stare Miasto",
    "Stary Broniszew",
    "Stary Garbow",
    "Stary Sacz",
    "Stary Zamosc",
    "Staszow",
    "Staw",
    "Stawiany",
    "Stawiguda",
    "Stegna",
    "Steszew",
    "Stoczek",
    "Stolno",
    "Stopnica",
    "Straszyn",
    "Strawczyn",
    "Strazow",
    "Stronie Slaskie",
    "Stroze",
    "Strumien",
    "Strykow",
    "Stryszawa",
    "Stryszow",
    "Strzalkowo",
    "Strzebielino",
    "Strzebin",
    "Strzegom",
    "Strzelce Krajenskie",
    "Strzelce Opolskie",
    "Strzeleczki",
    "Strzelno",
    "Strzeszow",
    "Strzyze",
    "Strzyzow",
    "Strzyzow",
    "Studzieniec",
    "Subkowy",
    "Sucha Beskidzka",
    "Suchedniow",
    "Suchowola",
    "Suchy Dab",
    "Suchy Las",
    "Sulechow",
    "Sulejow",
    "Sulejowek",
    "Sulistrowice",
    "Sulmierzyce",
    "Sulmierzyce",
    "Sulmin",
    "Suloszowa",
    "Supienie",
    "Susiec",
    "Susz",
    "Suszec",
    "Suwaki",
    "Swarorzyn",
    "Swarzedz",
    "Swarzewo",
    "Swiatki",
    "Swidnica",
    "Swidnik",
    "Swidwin",
    "Swiebodzice",
    "Swiebodzin",
    "Swiecie nad Osa",
    "Swiekatowo",
    "Swierk",
    "Swierklany",
    "Swieta Katarzyna",
    "Swietno",
    "Swietoniowa",
    "Swiniary",
    "Swoboda",
    "Sycewice",
    "Sycow",
    "Syrynia",
    "Szadek",
    "Szadlowice",
    "Szczaniec",
    "Szczawno",
    "Szczawno-Zdroj",
    "Szczecin",
    "Szczecinek",
    "Szczejkowice",
    "Szczepanow",
    "Szczercow",
    "Szczucin",
    "Szczuczyn",
    "Szczurowa",
    "Szczyrk",
    "Szczytniki",
    "Szczytno",
    "Szebnie",
    "Szemud",
    "Szepietowo",
    "Szewce",
    "Szlachta",
    "Szostka",
    "Szowsko",
    "Szprotawa",
    "Szreniawa",
    "Sztum",
    "Sztutowo",
    "Szyce",
    "Szydlowiec",
    "Szydlowo",
    "Szyldak",
    "Szymanow",
    "Szypliszki",
    "Tanowo",
    "Tarchaly Wielkie",
    "Tarczyn",
    "Targanice",
    "Tarnobrzeg",
    "Tarnogrod",
    "Tarnow Opolski",
    "Tarnowiec",
    "Tarnowo Podgorne",
    "Tarnowskie Gory",
    "Tczew",
    "Tecza",
    "Tegoborze",
    "Tenczyn",
    "Teofilow",
    "Teresin",
    "Terespol",
    "Tluczan",
    "Tolkmicko",
    "Tomaszow",
    "Tomaszow",
    "Tomaszow Lubelski",
    "Tomaszowice",
    "Topola Mala",
    "Torzym",
    "Toszek",
    "Towarzystwo",
    "Trabki",
    "Trawniki",
    "Trojanow",
    "Trzciana",
    "Trzcianka",
    "Trzciel",
    "Trzcinsko",
    "Trzebinia",
    "Trzebnica",
    "Trzebownisko",
    "Trzebunia",
    "Trzemesnia",
    "Trzemeszno",
    "Trzemeszno Lubuskie",
    "Trzesniow",
    "Trzeszczyn",
    "Trzyciaz",
    "Trzydnik Duzy",
    "Tuchola",
    "Tuchow",
    "Tuczno",
    "Tulce",
    "Turbia",
    "Turek",
    "Turobin",
    "Turowiec",
    "Tuszyn",
    "Twardawa",
    "Twardogora",
    "Tworog",
    "Tychy",
    "Tyczyn",
    "Tyczyn",
    "Tykocin",
    "Tylicz",
    "Tylmanowa",
    "Tymbark",
    "Tymowa",
    "Tyszowce",
    "Uciechow",
    "Ujazd",
    "Ujscie",
    "Ulez",
    "Unieszewo",
    "Ustanow",
    "Ustka",
    "Ustron",
    "Ustrzyki Dolne",
    "Wabrzezno",
    "Wachock",
    "Wadowice",
    "Wadowice Gorne",
    "Waganiec",
    "Wagrowiec",
    "Walcz",
    "Walim",
    "Wambierzyce",
    "Wapielsk",
    "Warka",
    "Warsaw",
    "Warzachewka Polska",
    "Warzno",
    "Warzymice",
    "Wasilkow",
    "Wasniow",
    "Wawel",
    "Wawolnica",
    "Wegierska Gorka",
    "Wegliniec",
    "Weglowice",
    "Wegrzce Wielkie",
    "Wejherowo",
    "Wiazow",
    "Wicko",
    "Wieckowice",
    "Wielbark",
    "Wielen Zaobrzanski",
    "Wielgie",
    "Wielichowo",
    "Wieliczka",
    "Wieliszew",
    "Wielki Klincz",
    "Wieloglowy",
    "Wielopole Skrzynskie",
    "Wielowies",
    "Wieruszow",
    "Wierzchowisko",
    "Wieszowa",
    "Wijewo",
    "Wilczeta",
    "Wilczyce",
    "Wilczyn",
    "Wilga",
    "Wilkanowo",
    "Wilkow",
    "Wilkowice",
    "Wilkowisko",
    "Winnica",
    "Winsko",
    "Wiorek",
    "Wisla",
    "Wisla Wielka",
    "Wisniew",
    "Wisniowa",
    "Wisznice",
    "Witaszyce",
    "Witkowo",
    "Witnica",
    "Wlodawa",
    "Wlodzimierz",
    "Wloszczowa",
    "Wojciech",
    "Wojkowice",
    "Wojkowice Koscielne",
    "Wola Baranowska",
    "Wola Filipowska",
    "Wola Kopcowa",
    "Wola Krzysztoporska",
    "Wola Radlowska",
    "Wola Rasztowska",
    "Wola Rebkowska",
    "Wola Zaradzynska",
    "Wolborz",
    "Wolbrom",
    "Wolin",
    "Wolka",
    "Wolow",
    "Wolsztyn",
    "Wozniki",
    "Wreczyca Wielka",
    "Wronki",
    "Wrzesnia",
    "Wrzosowa",
    "Wrzosowka",
    "Wschowa",
    "Wylatowo",
    "Wymiarki",
    "Wyrzysk",
    "Wysogotowo",
    "Wysoka",
    "Wysoka Strzyzowska",
    "Wysokie Mazowieckie",
    "Wystep",
    "Wyszogrod",
    "Zabia Wola",
    "Zabierzow Bochenski",
    "Zablocie",
    "Zabno",
    "Zabor",
    "Zabrowo",
    "Zabrze",
    "Zabrzeg",
    "Zaczernie",
    "Zagnansk",
    "Zagorow",
    "Zagorzyce",
    "Zagosciniec",
    "Zagrodno",
    "Zakopane",
    "Zalakowo",
    "Zalasewo",
    "Zalesie",
    "Zaleszany",
    "Zalewo",
    "Zalubice Nowe",
    "Zalubice Stare",
    "Zaluski",
    "Zaniemysl",
    "Zaparcin",
    "Zarow",
    "Zarowka",
    "Zarszyn",
    "Zary",
    "Zator",
    "Zawada",
    "Zawada",
    "Zawady",
    "Zawadzkie",
    "Zawidow",
    "Zawiercie",
    "Zawoja",
    "Zawonia",
    "Zbaszyn",
    "Zbaszynek",
    "Zbiczno",
    "Zblewo",
    "Zbroslawice",
    "Zdunska Wola",
    "Zduny",
    "Zduny",
    "Zdzieszowice",
    "Zebowice",
    "Zebrzydowice",
    "Zegrze Pomorskie",
    "Zelazkow",
    "Zelow",
    "Zembrzyce",
    "Zerkow",
    "Zernica",
    "Zerniki",
    "Zgierz",
    "Zglobice",
    "Zglobien",
    "Zgorsko",
    "Zgorzala",
    "Zgorzelec",
    "Ziebice",
    "Zielona",
    "Zielone",
    "Zielonka",
    "Zielonki",
    "Zielonki-Wies",
    "Zlocieniec",
    "Zloczew",
    "Zlotow",
    "Zloty Stok",
    "Zmigrod",
    "Znin",
    "Zofia",
    "Zorawia",
    "Zorawina",
    "Zosin",
    "Zrecin",
    "Zukowo",
    "Zurawica",
    "Zwolen",
    "Zyrakow",
  ],
  Portugal: [
    "Abobada",
    "Abrantes",
    "Acores",
    "Aguada de Cima",
    "Agualva",
    "Agucadoura",
    "Aguiar da Beira Municipality",
    "Alandroal",
    "Albergaria-a-Velha",
    "Albufeira",
    "Alcabideche",
    "Alcacer do Sal",
    "Alcains",
    "Alcanede",
    "Alcanena",
    "Alcobaca",
    "Alcochete",
    "Alcoentre",
    "Aldeia de Paio Pires",
    "Aldeia do Meio",
    "Aldeia dos Fernandes",
    "Alenquer",
    "Alfarelos",
    "Alfena",
    "Alfornelos",
    "Alfragide",
    "Alges",
    "Algoz",
    "Algueirao",
    "Alhandra",
    "Alhos Vedros",
    "Aljezur",
    "Aljustrel",
    "Almada",
    "Almancil",
    "Almargem",
    "Almeirim",
    "Almodovar",
    "Alpiarca",
    "Alportel",
    "Alqueidao da Serra",
    "Alter do Chao",
    "Altura",
    "Alvaiazere",
    "Alvarinhos",
    "Alvelos",
    "Alverca",
    "Amadora",
    "Amarante",
    "Amares",
    "Amiaes de Cima",
    "Amor",
    "Amora",
    "Amoreira",
    "Amorim",
    "Anadia",
    "Anta",
    "Apulia",
    "Arazede",
    "Arco da Calheta",
    "Arcos de Valdevez",
    "Arcozelo",
    "Arganil",
    "Argoncilhe",
    "Armacao de Pera",
    "Arouca",
    "Arraiolos",
    "Arrifana",
    "Arronches",
    "Arruda dos Vinhos",
    "Arvore",
    "Assumar",
    "Atalaia",
    "Atouguia da Baleia",
    "Avanca",
    "Aveiras de Cima",
    "Aveiro",
    "Avelar",
    "Avidos",
    "Avintes",
    "Azoia",
    "Azueira",
    "Azurem",
    "Baguim",
    "Baguim do Monte",
    "Baleia",
    "Baltar",
    "Barcarena",
    "Barcelos",
    "Barreiro",
    "Barrosas",
    "Basto",
    "Batalha",
    "Beira",
    "Beja",
    "Belas",
    "Belmonte",
    "Bemfica",
    "Benafim",
    "Benavente",
    "Benedita",
    "Bicesse",
    "Bico",
    "Boavista",
    "Bobadela",
    "Boliqueime",
    "Bombarral",
    "Borba",
    "Boticas",
    "Bouca",
    "Braga",
    "Braganca",
    "Brito",
    "Bucelas",
    "Buraca",
    "Cabanelas",
    "Cabeceiras de Basto",
    "Cabeco de Vide",
    "Cabecudos",
    "Cabrela",
    "Cacela",
    "Cacem",
    "Cacilhas",
    "Cadaval",
    "Caldas",
    "Caldas da Rainha",
    "Caldas das Taipas",
    "Caldas de Sao Jorge",
    "Caldas de Vizela",
    "Calvao",
    "Calvaria de Cima",
    "Camarate",
    "Caminha",
    "Campanario",
    "Campia",
    "Campinho",
    "Campo",
    "Campo Maior",
    "Canecas",
    "Canedo",
    "Canedo",
    "Canelas",
    "Canhas",
    "Canical",
    "Canico",
    "Canidelo",
    "Cano",
    "Cantanhede",
    "Caparica",
    "Caramulo",
    "Carapinheira",
    "Carcavelos",
    "Carnaxide",
    "Carrazedo",
    "Carregado",
    "Carrico",
    "Cartaxo",
    "Carvalhal",
    "Carvalho",
    "Carvalhosa",
    "Carvoeiro",
    "Casal",
    "Casal da Areia",
    "Cascais",
    "Castanheira do Ribatejo",
    "Castelejo",
    "Castelo",
    "Castelo Branco",
    "Castelo de Paiva",
    "Castro Daire",
    "Castro Marim",
    "Castro Verde Municipality",
    "Catraia de Sao Paio",
    "Cavaleiros",
    "Cavaloes",
    "Caxias",
    "Ceira",
    "Celorico de Basto",
    "Cercal",
    "Cernache",
    "Cesar",
    "Chao de Couce",
    "Charneca",
    "Chaves",
    "Cidade",
    "Cinfaes",
    "Coimbra",
    "Coimbra",
    "Colares",
    "Colmeias",
    "Condeixa-a-Nova",
    "Constance",
    "Constancia",
    "Corroios",
    "Cortegaca",
    "Costa de Caparica",
    "Costa de Lavos",
    "Covilha",
    "Creixomil",
    "Cruz Quebrada",
    "Cuba",
    "Cucujaes",
    "Dalvares",
    "Damaia",
    "Darque",
    "Dois Portos",
    "Dona Maria",
    "Dossaos",
    "Eixo",
    "Elvas",
    "Encarnacao",
    "Entroncamento",
    "Entroncamento",
    "Ericeira",
    "Ermidas",
    "Escapaes",
    "Escoural",
    "Esmoriz",
    "Espinho",
    "Esposende",
    "Estarreja",
    "Estoi",
    "Estombar",
    "Estoril",
    "Estremoz",
    "Evora",
    "Fafe",
    "Famoes",
    "Fanzeres",
    "Fao",
    "Faro",
    "Feira",
    "Felgueiras",
    "Felgueiras",
    "Felgueiras",
    "Ferradosa",
    "Ferreira",
    "Ferreira do Alentejo",
    "Ferrel",
    "Ferro",
    "Fiaes",
    "Figueira da Foz Municipality",
    "Figueira de Lorvao",
    "Figueiro dos Vinhos",
    "Fogueteiro",
    "Folgados",
    "Forte",
    "Foz do Sousa",
    "Fradelos",
    "Freamunde",
    "Frielas",
    "Funchal",
    "Fundao",
    "Gafanha da Nazare",
    "Galiza",
    "Gandara dos Olivais",
    "Gandra",
    "Garruchas",
    "Gemunde",
    "Godim",
    "Golega",
    "Gondomar",
    "Gouveia",
    "Graca",
    "Gradil",
    "Grandola",
    "Granja",
    "Granja do Ulmeiro",
    "Grijo",
    "Guarda",
    "Guardizela",
    "Guia",
    "Guimaraes",
    "Gulpilhares",
    "Igreja Nova",
    "Igreja Nova",
    "Ilhavo",
    "Infesta",
    "Joane",
    "Jovim",
    "Juncal",
    "Lagarinhos",
    "Lago",
    "Lagoa",
    "Lagoa",
    "Lagoa",
    "Lagoas",
    "Lagos",
    "Lajes",
    "Lamego",
    "Landim",
    "Lavos",
    "Lavra",
    "Lavradio",
    "Leca da Palmeira",
    "Leca do Bailio",
    "Leiria",
    "Linda a Velha",
    "Linhaceira",
    "Linho",
    "Lisbon",
    "Lordelo",
    "Loule",
    "Lourel de Baixo",
    "Loures",
    "Lourical",
    "Lourosa",
    "Lousa",
    "Lousada",
    "Lousado",
    "Macas de Caminho",
    "Maceda",
    "Macedo de Cavaleiros",
    "Maceira",
    "Machico",
    "Macieira da Lixa",
    "Madeira",
    "Mafra",
    "Maia",
    "Malveira",
    "Malveira da Serra",
    "Mamouros",
    "Mangualde",
    "Manique de Baixo",
    "Manique do Intendente",
    "Marco",
    "Marco de Canaveses",
    "Margaride",
    "Marinha",
    "Marinha Grande",
    "Marinhais",
    "Marteleira",
    "Martinganca",
    "Massama",
    "Massarelos",
    "Matosinhos Municipality",
    "Mealhada",
    "Mem Martins",
    "Mesao Frio",
    "Mesao Frio",
    "Mexilhoeira Grande",
    "Milharado",
    "Milhazes",
    "Minde",
    "Mira",
    "Mira",
    "Miranda do Corvo",
    "Miranda do Douro",
    "Mirandela",
    "Mocarria",
    "Modivas",
    "Moita",
    "Moncao",
    "Monchique",
    "Monforte",
    "Monsanto",
    "Monsaraz",
    "Montalvo",
    "Monte Estoril",
    "Montelavar",
    "Montemor-o-Novo",
    "Montemor-o-Velho",
    "Montes",
    "Montes",
    "Montijo",
    "Moreira de Conegos",
    "Mortagua",
    "Moscavide",
    "Moura",
    "Moura",
    "Moure",
    "Mourisca do Vouga",
    "Mozelos",
    "Muro",
    "Murtede",
    "Murteira",
    "Nadadouro",
    "Negrelos",
    "Neiva",
    "Nelas",
    "Nespereira",
    "Nine",
    "Nisa",
    "Nogueira",
    "Nogueira da Regedoura",
    "Nogueira do Cravo",
    "Obidos",
    "Obidos",
    "Odemira",
    "Odivelas",
    "Oeiras",
    "Oia",
    "Olhao",
    "Olhos de Agua",
    "Olivais",
    "Olivais",
    "Olival",
    "Oliveira",
    "Oliveira do Bairro",
    "Oliveira do Douro",
    "Oliveira do Hospital",
    "Ortiga",
    "Ota",
    "Ourem",
    "Ourique",
    "Outeiro",
    "Ovar",
    "Paco de Arcos",
    "Paderne",
    "Palhaca",
    "Palhais",
    "Palmeira",
    "Palmela",
    "Parada de Gonta",
    "Parada do Bispo",
    "Paraiso",
    "Parchal",
    "Pardilho",
    "Parede",
    "Paredes",
    "Paredes",
    "Paredes da Beira",
    "Paredes de Coura",
    "Pataias",
    "Paul",
    "Pechao",
    "Pedreiras",
    "Pedroso",
    "Pedroucos",
    "Pegoes Velhos",
    "Penacova",
    "Penafiel",
    "Penedono",
    "Penela",
    "Penha Garcia",
    "Peniche",
    "Penteado",
    "Pereira",
    "Pero Pinheiro",
    "Pevidem",
    "Piedade",
    "Pinhal",
    "Pinhal Novo",
    "Pinheiro",
    "Pinheiro da Bemposta",
    "Pinheiro de Azere",
    "Poceirao",
    "Poco Barreto",
    "Poiares",
    "Polima",
    "Pombal",
    "Ponta",
    "Ponta Delgada",
    "Ponta do Sol",
    "Ponte",
    "Ponte da Barca",
    "Ponte de Lima",
    "Ponte de Sor",
    "Ponte de Vagos",
    "Pontevel",
    "Pontinha",
    "Portalegre",
    "Portel",
    "Portela",
    "Portimao",
    "Porto",
    "Porto",
    "Porto",
    "Porto",
    "Porto Alto",
    "Porto da Cruz",
    "Porto de Mos",
    "Porto Moniz",
    "Porto Salvo",
    "Povoa",
    "Povoa",
    "Povoa da Galega",
    "Povoa de Lanhoso",
    "Povoa de Santa Iria",
    "Povoa de Santo Adriao",
    "Povoa do Forno",
    "Prado",
    "Pragal",
    "Praia da Vitoria",
    "Praia de Mira",
    "Praia do Almoxarife",
    "Praia do Carvoeiro",
    "Praia do Ribatejo",
    "Prazins",
    "Prime",
    "Proenca-a-Nova",
    "Quarteira",
    "Quejas",
    "Queluz",
    "Queluz de Baixo",
    "Quinchaes",
    "Quinta",
    "Quinta",
    "Quinta do Anjo",
    "Ramada",
    "Ramo",
    "Rana",
    "Real",
    "Rebordosa",
    "Recarei",
    "Redondo",
    "Regueira de Pontes",
    "Reguengo Grande",
    "Reguengos de Monsaraz",
    "Riachos",
    "Riba de Ave",
    "Ribeira Brava",
    "Ribeira Grande",
    "Ribeirao",
    "Rinchoa",
    "Rio de Moinhos",
    "Rio de Mouro",
    "Rio Maior",
    "Rio Tinto",
    "Romariz",
    "Ronfe",
    "Roque",
    "Roriz",
    "Runa",
    "Sabugal",
    "Sacavem",
    "Salreu",
    "Salto",
    "Salvaterra de Magos",
    "Samil",
    "Samora Correia",
    "Samouco",
    "Sandim",
    "Sanfins do Douro",
    "Sangalhos",
    "Sanguedo",
    "Santa Barbara de Nexe",
    "Santa Catarina",
    "Santa Catarina da Serra",
    "Santa Clara-a-Velha",
    "Santa Comba Dao",
    "Santa Cruz",
    "Santa Iria da Azoia",
    "Santa Marta",
    "Santa Marta de Penaguiao",
    "Santana",
    "Santiago",
    "Santiago",
    "Santiago da Guarda",
    "Santiago de Riba-Ul",
    "Santiago do Cacem",
    "Santo Andre",
    "Santo Antao do Tojal",
    "Santo Antonio",
    "Santo Antonio da Charneca",
    "Santo Tirso",
    "Sao Bras",
    "Sao Bras de Alportel",
    "Sao Domingos de Rana",
    "Sao Felix da Marinha",
    "Sao Francisco",
    "Sao Joao",
    "Sao Joao da Madeira",
    "Sao Joao da Talha",
    "Sao Joao das Lampas",
    "Sao Joao de Ver",
    "Sao Joao do Campo",
    "Sao Joao dos Montes",
    "Sao Juliao do Tojal",
    "Sao Mamede de Infesta",
    "Sao Marcos",
    "Sao Pedro da Cadeira",
    "Sao Pedro do Estoril",
    "Sao Romao do Coronado",
    "Sao Roque",
    "Sao Vicente da Beira",
    "Sao Vicente de Lafoes",
    "Sardoal",
    "Sarilhos Grandes",
    "Satao",
    "Seia",
    "Seixal",
    "Seixal",
    "Sendim",
    "Senhora da Hora",
    "Seroa",
    "Serpa",
    "Serpins",
    "Serta",
    "Sesimbra",
    "Sete Rios",
    "Sever do Vouga",
    "Silva",
    "Silvares",
    "Silveira",
    "Silves",
    "Sines Municipality",
    "Sintra",
    "Soalheira",
    "Sobrado",
    "Sobrado de Paiva",
    "Sobral de Monte Agraco",
    "Sobralinho",
    "Sobreda",
    "Sobreiro",
    "Sobrosa",
    "Soito",
    "Soure Municipality",
    "Souto da Carpalhosa",
    "Souzel",
    "Sul",
    "Tabuaco",
    "Talaide",
    "Tamel",
    "Tarouca",
    "Taveiro",
    "Tavira Municipality",
    "Teixoso",
    "Telhado",
    "Tercena",
    "Terena",
    "Tires",
    "Tocha",
    "Tomar",
    "Tondela",
    "Torre da Marinha",
    "Torre de Moncorvo",
    "Torres Novas",
    "Torres Vedras",
    "Touguinha",
    "Tourais",
    "Trafaria",
    "Trancoso",
    "Trancoso de Baixo",
    "Travanca",
    "Treixedo",
    "Trofa",
    "Tunes",
    "Turcifal",
    "Vagos",
    "Valado de Frades",
    "Valbom",
    "Vale",
    "Vale",
    "Vale",
    "Vale da Pinta",
    "Vale de Acor",
    "Vale de Cambra",
    "Valega",
    "Valenca",
    "Valongo",
    "Valverde",
    "Varzea do Douro",
    "Venda do Pinheiro",
    "Vendas Novas",
    "Vermelha",
    "Vermoil",
    "Vialonga",
    "Viana do Alentejo",
    "Viana do Castelo",
    "Vidago",
    "Vidigueira",
    "Vieira de Leiria",
    "Vieira do Minho",
    "Vila Alva",
    "Vila Boa de Quires",
    "Vila Cha",
    "Vila Cha de Ourique",
    "Vila de Porto Santo",
    "Vila do Bispo",
    "Vila do Conde",
    "Vila Franca de Xira",
    "Vila Franca do Rosario",
    "Vila Nogueira de Azeitao",
    "Vila Nova",
    "Vila Nova",
    "Vila Nova",
    "Vila Nova",
    "Vila Nova da Barquinha",
    "Vila Nova de Cerveira",
    "Vila Nova de Famalicao",
    "Vila Nova de Gaia",
    "Vila Nova de Paiva",
    "Vila Praia de Ancora",
    "Vila Real",
    "Vila Verde",
    "Vilar de Perdizes",
    "Vilar do Paraiso",
    "Vilar do Pinheiro",
    "Vilar Formoso",
    "Vilarinho das Paranheiras",
    "Vilarinho de Arcos",
    "Vilarinho do Bairro",
    "Vilela",
    "Vimieiro",
    "Vinhais",
    "Viseu",
    "Vizela",
    "Vizela",
    "Zambujal",
    "Zibreira",
  ],
  "Puerto Rico": [
    "Adjuntas",
    "Aguada",
    "Aguadilla",
    "Aguas Buenas",
    "Aibonito",
    "Anasco",
    "Arecibo",
    "Arroyo",
    "Bajadero",
    "Barceloneta",
    "Barranquitas",
    "Boqueron",
    "Cabo Rojo",
    "Caguas",
    "Camuy",
    "Canovanas",
    "Carolina",
    "Catano",
    "Cayey",
    "Ceiba",
    "Ciales",
    "Cidra",
    "Coamo",
    "Corozal",
    "Coto Laurel",
    "Culebra",
    "Dorado",
    "Ensenada",
    "Fajardo",
    "Florida",
    "Garrochales",
    "Guanica",
    "Guayama",
    "Guayanilla",
    "Guaynabo",
    "Gurabo",
    "Hatillo",
    "Hormigueros",
    "Humacao",
    "Isabela",
    "Jayuya",
    "Juana Diaz",
    "Juncos",
    "Lajas",
    "Lares",
    "Las Piedras",
    "Loiza",
    "Luquillo",
    "Manati",
    "Maricao",
    "Maunabo",
    "Mayaguez",
    "Mercedita",
    "Moca",
    "Morovis",
    "Naguabo",
    "Naranjito",
    "Orocovis",
    "Palmer",
    "Patillas",
    "Penuelas",
    "Ponce",
    "Punta Santiago",
    "Quebradillas",
    "Residencial Puerto Real",
    "Rincon",
    "Rio Grande",
    "Sabana Grande",
    "Sabana Seca",
    "Saint Just",
    "Salinas",
    "San Antonio",
    "San German",
    "San Juan",
    "San Lorenzo",
    "San Sebastian",
    "Santa Isabel",
    "Toa Alta",
    "Toa Baja",
    "Trujillo Alto",
    "Utuado",
    "Vega Alta",
    "Vega Baja",
    "Vieques",
    "Villalba",
    "Yabucoa",
    "Yauco",
  ],
  "Republic of Korea": [
    "Andong",
    "Ansan-si",
    "Anseong",
    "Anyang-si",
    "Asan",
    "Bucheon-si",
    "Busan",
    "Changwon",
    "Cheonan",
    "Cheongju-si",
    "Chilgok",
    "Chuncheon",
    "Chungju",
    "Chungnim",
    "Daegu",
    "Daejeon",
    "Deokjin",
    "Duchon",
    "Gangneung",
    "Gimhae",
    "Gongju",
    "Gumi",
    "Gunnae",
    "Gwangju",
    "Gwangmyeong",
    "Gyeongju",
    "Gyeongsan-si",
    "Hadong",
    "Haesan",
    "Haeundae",
    "Hanam",
    "Hansol",
    "Hyangyang",
    "Hyoja-dong",
    "Icheon-si",
    "Iksan",
    "Ilsan-dong",
    "Incheon",
    "Janghowon",
    "Jecheon",
    "Jeju-si",
    "Jeonju",
    "Jinju",
    "Ka-ri",
    "Kimso",
    "Kisa",
    "Koyang-dong",
    "Kwanghui-dong",
    "Mapo-dong",
    "Miryang",
    "Moppo",
    "Nae-ri",
    "Naju",
    "Namhyang-dong",
    "Namyang",
    "Namyangju",
    "Nonsan",
    "Okpo-dong",
    "Osan",
    "Paju",
    "Pohang",
    "Pon-dong",
    "Pyong-gol",
    "Samjung-ni",
    "Samsung",
    "Sangdo-dong",
    "Sasang",
    "Se-ri",
    "Seocho",
    "Seong-dong",
    "Seongnam-si",
    "Seosan City",
    "Seoul",
    "Songam",
    "Songang",
    "Songjeong",
    "Songnim",
    "Suncheon",
    "Suwon-si",
    "Taebuk",
    "Tang-ni",
    "Tongan",
    "Uiwang",
    "Ulchin",
    "Ulsan",
    "Unpo",
    "Wonju",
    "Wonmi-gu",
    "Yangsan",
    "Yeoju",
    "Yeosu",
    "Yongin",
    "Yongsan-dong",
  ],
  "Republic of Lithuania": [
    "Alytus",
    "Anciskiai",
    "Antakalnis",
    "Garliava",
    "Ignalina",
    "Jonava",
    "Jurbarkas",
    "Juskonys",
    "Kaunas",
    "Kretinga",
    "Mastaiciai",
    "Palanga",
    "Panevezys",
    "Sakiai",
    "Salcininkai",
    "Trakai",
    "Ukmerge",
    "Uzliedziai",
    "Venta",
    "Vievis",
    "Vilniaus Apskritis",
    "Vilnius",
    "Visaginas",
  ],
  "Republic of Moldova": [
    "Anenii Noi",
    "Briceni",
    "Cahul",
    "Calarasi",
    "Ciorescu",
    "Cojusna",
    "Comrat",
    "Drochia",
    "Durlesti",
    "Ialoveni",
    "Magdacesti",
    "Nisporeni",
    "Orhei",
    "Soroca",
    "Straseni",
    "Tighina",
    "Tiraspol",
    "Voinescu",
    "Vulcanesti",
  ],
  Romania: [
    "Adjud",
    "Afumati",
    "Agnita",
    "Aiud",
    "Alba",
    "Alba Iulia",
    "Albesti-Paleologu",
    "Alesd",
    "Alexandria",
    "Alunu",
    "Apahida",
    "Apata",
    "Arad",
    "Avrig",
    "Baba Novac",
    "Baia Mare",
    "Baia Sprie",
    "Baicoi",
    "Baile Olanesti",
    "Baiut",
    "Balomiru de Camp",
    "Balotesti",
    "Banesti",
    "Baraolt",
    "Barbuletu",
    "Bascov",
    "Becicherecu Mic",
    "Berceni",
    "Berzasca",
    "Bethausen",
    "Bic",
    "Bilciuresti",
    "Birda",
    "Blaj",
    "Bobolia",
    "Bocsa",
    "Bogdanita",
    "Bolintin Deal",
    "Borca",
    "Borsa",
    "Bozieni",
    "Bragadiru",
    "Branistea",
    "Brazii de Sus",
    "Breaza",
    "Bucecea",
    "Bucharest",
    "Bucsani",
    "Bucu",
    "Bucurestii Noi",
    "Buftea",
    "Buhusi",
    "Bujor",
    "Bumbesti-Jiu",
    "Busteni",
    "Buzias",
    "Calafat",
    "Calan",
    "Calarasi",
    "Calinesti",
    "Campeni",
    "Campia",
    "Campia Turzii",
    "Campina",
    "Campulung Moldovenesc",
    "Campulung Muscel",
    "Cara",
    "Caracal",
    "Caransebes",
    "Carbunesti",
    "Carei",
    "Catcau",
    "Catunele",
    "Cernisoara",
    "Cetatea de Balta",
    "Cetatuia",
    "Chiajna",
    "Chiselet",
    "Chisineu-Cris",
    "Chisoda",
    "Chitila",
    "Cisnadie",
    "Ciumani",
    "Cluj-Napoca",
    "Cobadin",
    "Codlea",
    "Cojasca",
    "Comana",
    "Comanesti",
    "Corabia",
    "Corbeanca",
    "Corbita",
    "Corlateni",
    "Cosbuc",
    "Costesti",
    "Cotnari",
    "Covasna",
    "Craiova",
    "Crangasi",
    "Crasna",
    "Cris",
    "Cristian",
    "Cristuru Secuiesc",
    "Cugir",
    "Curtici",
    "Cuza Voda",
    "Daia Romana",
    "Decebal",
    "Dej",
    "Deta",
    "Deva",
    "Dimitrie Cantemir",
    "Ditrau",
    "Doamna",
    "Dobroesti",
    "Domnesti",
    "Dor Marunt",
    "Dorohoi",
    "Dragotesti",
    "Dridu",
    "Dudestii Vechi",
    "Dumbravita",
    "Eforie",
    "Fabrica",
    "Faget",
    "Fagetu",
    "Falcau",
    "Falticeni",
    "Fetesti",
    "Filias",
    "Filiasi",
    "Filipestii de Padure",
    "Floresti",
    "Floresti",
    "Floresti",
    "Fogarasch",
    "Fundulea",
    "Gaesti",
    "Garla-Mare",
    "Gataia",
    "Geoagiu",
    "Gheorgheni",
    "Gherea",
    "Gherla",
    "Giarmata",
    "Gilau",
    "Giroc",
    "Giulesti",
    "Giurgiu",
    "Glina",
    "Gramesti",
    "Grojdibodu",
    "Gura Humorului",
    "Hangulesti",
    "Harsova",
    "Hateg",
    "Horia",
    "Huedin",
    "Humulesti",
    "Hunedoara",
    "Husi",
    "Iadara",
    "Iernut",
    "Ion Ghica",
    "Iorga",
    "Izvoru Crisului",
    "Jibou",
    "Jilava",
    "Lacu",
    "Lacu Sinaia",
    "Lancram",
    "Lazuri",
    "Lehliu-Gara",
    "Lenauheim",
    "Livezi",
    "Ludesti",
    "Lugoj",
    "Lunguletu",
    "Macin",
    "Madaras",
    "Magura",
    "Magurele",
    "Magurele",
    "Mandruloc",
    "Mangalia",
    "Manta",
    "Marasheshty",
    "Marasti",
    "Marginea",
    "Masloc",
    "Matasari",
    "Matei",
    "Medgidia",
    "Miercurea Sibiului",
    "Miercurea-Ciuc",
    "Mihai Bravu",
    "Mihail Kogalniceanu",
    "Mioveni",
    "Mircea",
    "Mizil",
    "Moara Vlasiei",
    "Mocira",
    "Mogosoaia",
    "Moinesti",
    "Moisei",
    "Moldova Noua",
    "Moreni",
    "Moroda",
    "Motru",
    "Murfatlar",
    "Musenita",
    "Nana",
    "Nasaud",
    "Navodari",
    "Negresti",
    "Negru Voda",
    "Nenciulesti",
    "Nicolae Balcescu",
    "Nucsoara",
    "Obreja",
    "Ocna Mures",
    "Ocnita",
    "Odobesti",
    "Odorheiu Secuiesc",
    "Oltenita",
    "Oradea",
    "Oravita",
    "Orsova",
    "Otelu Rosu",
    "Otopeni",
    "Padureni",
    "Panciu",
    "Panduri",
    "Pantelimon",
    "Pascani",
    "Patarlagele",
    "Pecica",
    "Petresti",
    "Petresti",
    "Plopeni",
    "Podu Dambovitei",
    "Poiana Ilvei",
    "Popesti",
    "Popesti-Leordeni",
    "Potlogi",
    "Prejmer",
    "Prim Decembrie",
    "Prod",
    "Pucheni",
    "Rabagani",
    "Racari",
    "Racoasa",
    "Racovita",
    "Radauti",
    "Radovanu",
    "Ramnicu Sarat",
    "Reghin",
    "Roata de Jos",
    "Rohia",
    "Roman",
    "Rosia",
    "Rosiori de Vede",
    "Sacele",
    "Salatrucel",
    "Salcea",
    "Salcioara",
    "Salonta",
    "Sancraieni",
    "Sangeorge",
    "Sangeorz-Bai",
    "Santimbru",
    "Satu Mare",
    "Saveni",
    "Schela",
    "Sebes",
    "Seini",
    "Sfantu Gheorghe",
    "Sfantu Gheorghe",
    "Sfantu-Gheorghe",
    "Sibiu",
    "Siculeni",
    "Sighisoara",
    "Simeria",
    "Slatina",
    "Slobozia",
    "Soimus",
    "Somcuta Mare",
    "Sovata",
    "Spiru Haret",
    "Stalpeni",
    "Stanilesti",
    "Stefanesti",
    "Stefanestii de Jos",
    "Stroesti",
    "Suceava",
    "Susenii Bargaului",
    "Sutesti",
    "Talisoara",
    "Tandarei",
    "Targu Gangulesti",
    "Targu Neamt",
    "Tartasesti",
    "Tatarani",
    "Tatarusi",
    "Tecuci",
    "Teiu",
    "Teius",
    "Telega",
    "Tibeni",
    "Tileagd",
    "Timus",
    "Tina",
    "Tipari",
    "Titesti",
    "Titu",
    "Tomesti",
    "Topoloveni",
    "Traian",
    "Trestiana",
    "Tulcea",
    "Tulnici",
    "Tunari",
    "Turda",
    "Turnu Magurele",
    "Turnu Ruieni",
    "Tuzla",
    "Uioara de Jos",
    "Ungheni",
    "Unirea",
    "Unirea",
    "Urlati",
    "Urziceni",
    "Utvin",
    "Vacaresti",
    "Valcea",
    "Valea Lupului",
    "Valea Seaca",
    "Valenii de Munte",
    "Vama",
    "Vaslui",
    "Vatra Dornei",
    "Vedea",
    "Vetresti-Herastrau",
    "Victoria",
    "Videle",
    "Vidin",
    "Vidra",
    "Viseu de Jos",
    "Viseu de Sus",
    "Voinesti",
    "Voiteg",
    "Voluntari",
    "Voronet",
    "Zetea",
    "Zimnicea",
  ],
  Russia: [
    "Abakan",
    "Abinsk",
    "Achinsk",
    "Adygeysk",
    "Agapovka",
    "Agidel",
    "Akhtubinsk",
    "Aksay",
    "Aksenovo",
    "Alapayevsk",
    "Aldan",
    "Aleksandrov",
    "Aleksandrovka",
    "Alekseyevka",
    "Alekseyevka",
    "Aleksin",
    "Aleksino",
    "Aleysk",
    "Alikovo",
    "Altay",
    "Altayskoye",
    "Amur",
    "Amursk",
    "Anapa",
    "Angarsk",
    "Anuchino",
    "Anzhero-Sudzhensk",
    "Apatity",
    "Apazovo",
    "Aprelevka",
    "Aramil",
    "Ariadnoye",
    "Arkhangelsk",
    "Arkhara",
    "Armavir",
    "Aro",
    "Artem",
    "Arti",
    "Arzamas",
    "Asbest",
    "Asha",
    "Ashchibutak",
    "Asino",
    "Astrakhan",
    "Atkarsk",
    "Atlas",
    "Avangard",
    "Avri",
    "Avtozavodskiy Rayon",
    "Aykino",
    "Azov",
    "Bagan",
    "Baksan",
    "Balabanovo",
    "Balakovo",
    "Balashikha",
    "Balashov",
    "Baltiysk",
    "Barabash",
    "Baranchinskiy",
    "Barda",
    "Barnaul",
    "Barnaul",
    "Bashkortostan",
    "Bataysk",
    "Baykal",
    "Baza",
    "Begunitsy",
    "Belaya Kalitva",
    "Belebey",
    "Belgorod",
    "Belgorod",
    "Belogorka",
    "Belogorsk",
    "Belorechensk",
    "Beloretsk",
    "Belovo",
    "Belyy",
    "Berdsk",
    "Berezayka",
    "Berezniki",
    "Berezovka",
    "Beryozovsky",
    "Betlitsa",
    "Beya",
    "Bezhetsk",
    "Bibayevo",
    "Bilibino",
    "Birobidzhan",
    "Birsk",
    "Birzha",
    "Biysk",
    "Blagovar",
    "Blagoveshchensk",
    "Bogdanovich",
    "Bogdashino",
    "Bogoroditsk",
    "Bogorodsk",
    "Bogorodskoye",
    "Boguchany",
    "Boksitogorsk",
    "Bologoye",
    "Bor",
    "Borisoglebsk",
    "Borkovka",
    "Borodino",
    "Borodynovka",
    "Borovichi",
    "Borovskiy",
    "Borzya",
    "Bovykiny",
    "Bras",
    "Bratsk",
    "Brod",
    "Bronnitsy",
    "Bryansk",
    "Bugry",
    "Bulgakov",
    "Buyantsevo",
    "Buzuluk",
    "Chapayevsk",
    "Chaplygin",
    "Chastyye",
    "Chaykovskiy",
    "Cheboksary",
    "Chekhov",
    "Chelbasskaya",
    "Chelyabinsk",
    "Chemashur",
    "Cheremkhovo",
    "Cherepanovo",
    "Cherepovets",
    "Cherkessk",
    "Cherkizovo",
    "Chernigovka",
    "Chernogolovka",
    "Chernogorsk",
    "Chernushka",
    "Chernushka",
    "Chernyakhovsk",
    "Chesnokovka",
    "Chik",
    "China",
    "Chita",
    "Chiverevo",
    "Chkalov",
    "Chudovo",
    "Chyorny Yar",
    "Dagomys",
    "Dalmatovo",
    "Damkhurts",
    "Danilov",
    "Dedenevo",
    "Dedovsk",
    "Denisovo",
    "Derbent",
    "Desnogorsk",
    "Detchino",
    "Dimitrovgrad",
    "Dinskaya",
    "Divnogorsk",
    "Divo",
    "Dmitriyevka",
    "Dmitrov",
    "Dobryanka",
    "Dolgoprudnyy",
    "Domodedovo",
    "Donetsk",
    "Donskoy",
    "Drezna",
    "Druzhba",
    "Dubenka",
    "Dubna",
    "Dubovka",
    "Dubovka",
    "Dubrovka",
    "Dudinka",
    "Dyurtyuli",
    "Dzerzhinsk",
    "Dzerzhinskiy",
    "Elektrogorsk",
    "Elektrostal",
    "Elektrougli",
    "Elista",
    "Enkheluk",
    "Fatezh",
    "Fialka",
    "Filatovskoye",
    "Filial",
    "Frolovo",
    "Frunzenskiy",
    "Fryazino",
    "Furmanov",
    "Gagarin",
    "Gagino",
    "Gatchina",
    "Gaurilov",
    "Gelendzhik",
    "Georgiyevsk",
    "Georgiyevskoye",
    "Glazov",
    "Glazunovka",
    "Glebychevo",
    "Gogolya",
    "Golitsyno",
    "Golovchino",
    "Gorbatov",
    "Gorin",
    "Gorno-Altaysk",
    "Gornozavodsk",
    "Gorodna",
    "Granit",
    "Grozny",
    "Gryazovets",
    "Gubakha",
    "Gubkin",
    "Gubkin",
    "Gubkinskiy",
    "Gudermes",
    "Gukovo",
    "Gusev",
    "Gusinoozyorsk",
    "Gvardeysk",
    "Ibresi",
    "Ibrya",
    "Iglino",
    "Igra",
    "Ilim",
    "Ilovlya",
    "Ilya",
    "Insar",
    "Inta",
    "Irbit",
    "Irkutsk",
    "Isheyevka",
    "Ishim",
    "Ishimbay",
    "Ishimskiy",
    "Ishkulovo",
    "Ishley",
    "Iskitim",
    "Istra",
    "Ivangorod",
    "Ivanovo",
    "Izberbash",
    "Izhevsk",
    "Izmaylovo",
    "Kabanovo",
    "Kabardino",
    "Kachkanar",
    "Kadrovik",
    "Kalashnikovo",
    "Kalinina",
    "Kaliningrad",
    "Kalininskaya",
    "Kalino",
    "Kaltan",
    "Kaluga",
    "Kamchatka",
    "Kamenka",
    "Kamennogorsk",
    "Kamennomostskiy",
    "Kamenolomni",
    "Kamensk",
    "Kamensk-Shakhtinskiy",
    "Kamyshin",
    "Kamyshovka",
    "Kanash",
    "Kanavka",
    "Kandalaksha",
    "Kanevskaya",
    "Kansk",
    "Karabash",
    "Karabulak",
    "Karachev",
    "Karin Perevoz",
    "Karla Marksa",
    "Kartaly",
    "Kashira",
    "Kasimov",
    "Kasli",
    "Kastornoye",
    "Katav-Ivanovsk",
    "Kavkaz",
    "Kemerovo",
    "Khabarovsk",
    "Khabarskoye",
    "Khangash-Yurt",
    "Khanty-Mansiysk",
    "Kharp",
    "Khasavyurt",
    "Khimki",
    "Kholmistyy",
    "Kholmsk",
    "Khrenovoye",
    "Kichmengskiy Gorodok",
    "Kimovsk",
    "Kimry",
    "Kineshma",
    "Kingisepp",
    "Kirgiz-Miyaki",
    "Kirilly",
    "Kirishi",
    "Kirov",
    "Kirova",
    "Kirovgrad",
    "Kirovo-Chepetsk",
    "Kirovsk",
    "Kirovsk",
    "Kirovskaya",
    "Kirsanov",
    "Kiryabinskoye",
    "Kirzhach",
    "Kislovodsk",
    "Kizlyar",
    "Kizner",
    "Klimovsk",
    "Klin",
    "Klintsy",
    "Klyuchi",
    "Klyuchi-Bulak",
    "Kochkurovo",
    "Kodinsk",
    "Kola",
    "Kolomino",
    "Kolomna",
    "Kolpashevo",
    "Kolpino",
    "Komarov",
    "Komintern",
    "Komsomolsk-on-Amur",
    "Konakovo",
    "Kondopoga",
    "Konosha",
    "Kopeysk",
    "Koptyuga",
    "Korkino",
    "Korolev",
    "Korsakov",
    "Koryazhma",
    "Kosteniki",
    "Kostomuksha",
    "Kostroma",
    "Kotlas",
    "Kovdor",
    "Kovrov",
    "Kraskovo",
    "Krasnoarmeysk",
    "Krasnoarmeyskaya",
    "Krasnoarmeyskoye",
    "Krasnodar",
    "Krasnodarskiy",
    "Krasnogorsk",
    "Krasnogorskiy",
    "Krasnogvardeyskiy",
    "Krasnokamensk",
    "Krasnokamensk",
    "Krasnokamsk",
    "Krasnoobsk",
    "Krasnoufimsk",
    "Krasnoyarsk",
    "Krasnoye-na-Volge",
    "Krasnoznamensk",
    "Krasnoznamensk",
    "Krasnyy Kut",
    "Krasnyy Sulin",
    "Krasnyy Yar",
    "Kristall",
    "Kromy",
    "Kronshtadt",
    "Kropotkin",
    "Krugloye",
    "Krymsk",
    "Kstovo",
    "Kubinka",
    "Kugesi",
    "Kulikov",
    "Kumertau",
    "Kungur",
    "Kupavna",
    "Kurchatov",
    "Kurgan",
    "Kurgan",
    "Kurgan",
    "Kurganinsk",
    "Kursk",
    "Kurtamysh",
    "Kusa",
    "Kusakovka",
    "Kushnarenkovo",
    "Kushva",
    "Kuybyshev",
    "Kuznetsk",
    "Kuznetskiy Rayon",
    "Kuzovatovo",
    "Kvatchi",
    "Kyshtym",
    "Kyzyl",
    "Labinsk",
    "Labytnangi",
    "Lada",
    "Lakinsk",
    "Lampovo",
    "Lazo",
    "Lebedev",
    "Lenina",
    "Leningradskaya",
    "Leningradskiy",
    "Leninogorsk",
    "Leninsk-Kuznetsky",
    "Leninskiy",
    "Leninskoye",
    "Lenkino",
    "Lermontov",
    "Lesnoy",
    "Lesnoy",
    "Lesnoy",
    "Lesnoy Gorodok",
    "Lesodacha",
    "Lesosibirsk",
    "Lesozavodsk",
    "Likino-Dulevo",
    "Linda",
    "Linevo",
    "Liniya",
    "Lipetsk",
    "Lipetsk",
    "Lisikha",
    "Liski",
    "Livny",
    "Lobnya",
    "Lodeynoye Pole",
    "Lomonosov",
    "Losino-Petrovskiy",
    "Luchegorsk",
    "Lukhovitsy",
    "Luzino",
    "Lyantor",
    "Lytkarino",
    "Lyubertsy",
    "Magadan",
    "Magdagachi",
    "Magnitnyy",
    "Magnitogorsk",
    "Makhachkala",
    "Maksimovka",
    "Malaya Vishera",
    "Malino",
    "Maloyaroslavets",
    "Malysheva",
    "Mamontovka",
    "Mamontovo",
    "Manturovo",
    "Mariinskiy Posad",
    "Markovo",
    "Marks",
    "Maslyanino",
    "Mayakovskogo",
    "Maykop",
    "Maykor",
    "Mednogorsk",
    "Medvedevo",
    "Medvenka",
    "Mega",
    "Megion",
    "Melenki",
    "Meleuz",
    "Metallostroy",
    "Metallurg",
    "Mezhdurechensk",
    "Mga",
    "Miass",
    "Miasskoye",
    "Michurinsk",
    "Mikhaylovskoye",
    "Millerovo",
    "Mineralnye Vody",
    "Minusinsk",
    "Mira",
    "Moldavanskoye",
    "Monchegorsk",
    "Monino",
    "Morozov",
    "Morozovo",
    "Morozovsk",
    "Morshansk",
    "Moscow",
    "Moskovskaya",
    "Moskovskiy",
    "Mostovskoy",
    "Mozdok",
    "Mozhaysk",
    "Mozhga",
    "Mtsensk",
    "Muravlenko",
    "Murmansk",
    "Murmashi",
    "Murom",
    "Myski",
    "Mytishchi",
    "Naberezhnyye Chelny",
    "Nadezhdinka",
    "Nadvoitsy",
    "Nadym",
    "Nakhabino",
    "Nakhodka",
    "Naro-Fominsk",
    "Nartkala",
    "Naryan-Mar",
    "Nauka",
    "Naumkino",
    "Nazarovo",
    "Nazran",
    "Neftekamsk",
    "Neftekumsk",
    "Nefteyugansk",
    "Nelazskoye",
    "Nelidovo",
    "Neman",
    "Neryungri",
    "Neva",
    "Nevinnomyssk",
    "Nevskiy",
    "Neya",
    "Nikel",
    "Nikolayevsk",
    "Nikolayevsk-on-Amur",
    "Nikulin",
    "Nizhegorodskaya",
    "Nizhnekamsk",
    "Nizhneudinsk",
    "Nizhnevartovsk",
    "Nizhneyashkino",
    "Nizhniy Novgorod",
    "Nizhniy Odes",
    "Nizhniy Tagil",
    "Noginsk",
    "Noginskaya",
    "Nova",
    "Novator",
    "Novaya Igirma",
    "Novaya Silava",
    "Novoaltaysk",
    "Novobelokatay",
    "Novocheboksarsk",
    "Novocherkassk",
    "Novodmitriyevka",
    "Novodvinsk",
    "Novokorsunskaya",
    "Novokruchininskiy",
    "Novokuybyshevsk",
    "Novokuznetsk",
    "Novomichurinsk",
    "Novomoskovsk",
    "Novorossiysk",
    "Novoselovo",
    "Novosemeykino",
    "Novoshakhtinsk",
    "Novoshakhtinskiy",
    "Novosibirsk",
    "Novosokolovogorskiy",
    "Novotroitsk",
    "Novovarshavka",
    "Novovoronezh",
    "Novoye Devyatkino",
    "Novozavedennoye",
    "Novozolotovskaya",
    "Novozybkov",
    "Novy Urengoy",
    "Novyy",
    "Noyabrsk",
    "Nyagan",
    "Nytva",
    "Obninsk",
    "Obyedineniye",
    "Ocher",
    "Odintsovo",
    "Odintsovo",
    "Ogarev",
    "Okha",
    "Okhansk",
    "Okulovka",
    "Olenegorsk",
    "Olimpiyskiy",
    "Omchak",
    "Omsk",
    "Orekhovo-Zuyevo",
    "Orenburg",
    "Orlovskiy",
    "Orshanka",
    "Orsk",
    "Oshib",
    "Osnova",
    "Ostankino",
    "Ostrov",
    "Otradnoye",
    "Otradnyy",
    "Ovchinnikov",
    "Panayevsk",
    "Pangody",
    "Parapino",
    "Parfino",
    "Pargolovo",
    "Parkovyy",
    "Partizansk",
    "Patrushi",
    "Pavlovo",
    "Pavlovsk",
    "Pavlovskaya",
    "Pavlovskaya Sloboda",
    "Pavlovskiy Posad",
    "Pechora",
    "Pechory",
    "Penza",
    "Perepravnaya",
    "Perm",
    "Perovka",
    "Persianovka",
    "Pervo",
    "Pervomayskaya",
    "Pervouralsk",
    "Peschanka",
    "Pestovo",
    "Petergof",
    "Petropavlovka",
    "Petropavlovsk-Kamchatsky",
    "Petrozavodsk",
    "Pikalyovo",
    "Pioner",
    "Piter",
    "Pitkyaranta",
    "Pivovar",
    "Plavsk",
    "Pleloye",
    "Podgortsy",
    "Podolsk",
    "Podstepki",
    "Pokrov",
    "Polevskoy",
    "Polyarnyy",
    "Poronaysk",
    "Posad",
    "Poselok",
    "Poselok",
    "Postnikova",
    "Potok",
    "Povarovo",
    "Povedniki",
    "Poztykeres",
    "Pregradnaya",
    "Priozersk",
    "Prirechensk",
    "Progress",
    "Prokhladnyy",
    "Protvino",
    "Pryazha",
    "Pskov",
    "Pskovskoye",
    "Pugachev",
    "Pulkovo",
    "Purovsk",
    "Pushchino",
    "Pushchino",
    "Pushchino",
    "Pushkin",
    "Pushkina",
    "Pushkino",
    "Putilkovo",
    "Pyatigorsk",
    "Pychas",
    "Pyshma",
    "Radist",
    "Raduga",
    "Rakita",
    "Ramenskoye",
    "Rassvet",
    "Ratmirovo",
    "Raychikhinsk",
    "Redkino",
    "Reftinskiy",
    "Reutov",
    "Revda",
    "Revda",
    "Rezh",
    "Rikasikha",
    "Rodionovka",
    "Rodionovo-Nesvetayskaya",
    "Rodniki",
    "Romanovka",
    "Rosa",
    "Roshchino",
    "Roshchino",
    "Rossiyskiy",
    "Rostov",
    "Rostov-on-Don",
    "Rostovskaya",
    "Rovnoye",
    "Rozhdestvenka",
    "Rubin",
    "Rubtsovsk",
    "Russa",
    "Ruza",
    "Ruzayevka",
    "Ryasnoye",
    "Ryazan",
    "Rybinsk",
    "Rybnoye",
    "Rzhev",
    "Safonovo",
    "Saint Petersburg",
    "Salavat",
    "Salekhard",
    "Salym",
    "Samara",
    "Samara",
    "Samara",
    "Samsonovka",
    "Saransk",
    "Sarapul",
    "Saratov",
    "Sarov",
    "Sartykov",
    "Sary",
    "Satis",
    "Satka",
    "Sayanogorsk",
    "Sayansk",
    "Sebezh",
    "Segezha",
    "Selo",
    "Selyatino",
    "Semibratovo",
    "Semyonov",
    "Serafimovskiy",
    "Serdobsk",
    "Sergeyevka",
    "Sergiyev Posad",
    "Sergiyevskoye",
    "Serov",
    "Serpukhov",
    "Sertolovo",
    "Seryshevo",
    "Sestroretsk",
    "Severo",
    "Severo-Zapad",
    "Severodvinsk",
    "Severomorsk",
    "Seversk",
    "Severskaya",
    "Sevsk",
    "Shadrinsk",
    "Shakhty",
    "Shalinskoye",
    "Shalushka",
    "Sharapova Okhota",
    "Sharnutovskiy",
    "Shatura",
    "Shaturtorf",
    "Shchekino",
    "Shchelkovo",
    "Shebalino",
    "Shebekino",
    "Sheksna",
    "Shelekhov",
    "Sheregesh",
    "Shoshma",
    "Shumerlya",
    "Sibay",
    "Sistema",
    "Skala",
    "Skat",
    "Skovorodino",
    "Slantsy",
    "Slavgorod",
    "Slavyanka",
    "Slavyansk-na-Kubani",
    "Sloboda",
    "Slyudyanka",
    "Smolensk",
    "Smolenskaya",
    "Snegiri",
    "Snezhinsk",
    "Snezhinskiy",
    "Snezhnogorsk",
    "Sobinka",
    "Sochi",
    "Sodyshka",
    "Sofrino",
    "Sofrino",
    "Sokol",
    "Sokolovo",
    "Soldatskoye",
    "Solikamsk",
    "Solnechnogorsk",
    "Sorochinsk",
    "Sortavala",
    "Sosnogorsk",
    "Sosnovo",
    "Sosnovoborsk",
    "Sosnovyy Bor",
    "Sosny",
    "Sovetsk",
    "Sovetskiy",
    "Spas-Demensk",
    "Spassk",
    "Sputnik",
    "Sredneuralsk",
    "Srostki",
    "Staraya Kupavna",
    "Staraya Russa",
    "Starobachaty",
    "Starodub",
    "Staronizhestebliyevskaya",
    "Staropesterevo",
    "Starovelichkovskaya",
    "Start",
    "Stary Oskol",
    "Stavropol",
    "Sterlitamak",
    "Strezhevoy",
    "Stroiteley",
    "Studenets",
    "Stupino",
    "Sukhanovo",
    "Surgut",
    "Surkhakhi",
    "Sursk",
    "Sverdlova",
    "Sverdlovskaya",
    "Sverdlovskiy",
    "Svetlaya",
    "Svetlogorsk",
    "Svetlograd",
    "Svetlyy",
    "Svetlyy",
    "Svetogorsk",
    "Sviblovo",
    "Svirsk",
    "Svobodnaya",
    "Svobodnyy",
    "Syamzha",
    "Syktyvkar",
    "Taganrog",
    "Tagil",
    "Taksimo",
    "Taldom",
    "Tambov",
    "Tapkhar",
    "Tarko-Sale",
    "Tarusa",
    "Tatlybayevo",
    "Taushkasy",
    "Tayga",
    "Tayshet",
    "Tazovskiy",
    "Techa",
    "Temryuk",
    "Teykovo",
    "Tikhoretsk",
    "Tikhvin",
    "Tim",
    "Timiryazev",
    "Tochka",
    "Tolstoy",
    "Tolyatti",
    "Tomilino",
    "Tomsk",
    "Topki",
    "Torzhok",
    "Tosno",
    "Trekhgornyy",
    "Troitsk",
    "Troitsk",
    "Troitsko-Pechorsk",
    "Trubchevsk",
    "Tsna",
    "Tsudakhar",
    "Tuapse",
    "Tuchkovo",
    "Tula",
    "Tulun",
    "Turinsk",
    "Tuymazy",
    "Tver",
    "Tynda",
    "Tyrma",
    "Tyrnyauz",
    "Tyumen",
    "Uchaly",
    "Udomlya",
    "Ufa",
    "Ufa",
    "Uglegorsk",
    "Uglich",
    "Ugra",
    "Ugresha",
    "Ukhta",
    "Uktuz",
    "Ulan-Ude",
    "Ulyanovsk",
    "Unecha",
    "Ural",
    "Uray",
    "Urengoy",
    "Urgal",
    "Urus-Martan",
    "Uryupinsk",
    "Urzhum",
    "Usinsk",
    "Usinskoye",
    "Uspenskoye",
    "Ussuriysk",
    "Uvarovo",
    "Uyar",
    "Uzlovaya",
    "Vagay",
    "Valday",
    "Valentin",
    "Vanino",
    "Varvarovka",
    "Vega",
    "Velikiy Ustyug",
    "Velikiye Luki",
    "Veliky Novgorod",
    "Verkhnekazymskiy",
    "Verkhniy Ufaley",
    "Verkhnyaya Pyshma",
    "Verkhnyaya Salda",
    "Veshki",
    "Vichuga",
    "Vidnoye",
    "Vikhorevka",
    "Vinsady",
    "Vitebskaya",
    "Vladikavkaz",
    "Vladimir",
    "Vladivostok",
    "Vladykino",
    "Vnukovo",
    "Volga",
    "Volga",
    "Volga",
    "Volgodonsk",
    "Volgograd",
    "Volgorechensk",
    "Volkhonshchino",
    "Volkhov",
    "Volna",
    "Volochek",
    "Volodarskiy",
    "Vologda",
    "Volovo",
    "Volzhsk",
    "Volzhskiy",
    "Volzhskiy",
    "Vorkuta",
    "Voronezh",
    "Voronezhskiy",
    "Vorovskogo",
    "Voskresensk",
    "Voskresenskoye",
    "Voskresenskoye",
    "Vostochnaya",
    "Vostochnyy",
    "Votkinsk",
    "Vsevolozhsk",
    "Vurnary",
    "Vyatka",
    "Vyatskiye Polyany",
    "Vyazemskiy",
    "Vyazniki",
    "Vyborg",
    "Vyksa",
    "Vysokogornyy",
    "Vysokovsk",
    "Vytegra",
    "Yakhroma",
    "Yakovlevo",
    "Yakutsk",
    "Yalta",
    "Yalutorovsk",
    "Yanaul",
    "Yaratovo",
    "Yaroslavl",
    "Yashkino",
    "Yaya",
    "Yekaterinburg",
    "Yekaterinoslavka",
    "Yekaterinovka",
    "Yelabuga",
    "Yelanskiy",
    "Yelets",
    "Yelizovo",
    "Yermolkino",
    "Yessentuki",
    "Yeysk",
    "Yoshkar-Ola",
    "Yugorsk",
    "Yugra",
    "Yurga",
    "Yuzhno-Sakhalinsk",
    "Zainsk",
    "Zaokskiy",
    "Zaozersk",
    "Zaprudnya",
    "Zaraysk",
    "Zarechenskiy",
    "Zarechnyy",
    "Zarinsk",
    "Zavodoukovsk",
    "Zavodouspenskoye",
    "Zelenodolsk",
    "Zelenogorsk",
    "Zelenogorsk",
    "Zelenograd",
    "Zelenogradsk",
    "Zernograd",
    "Zeya",
    "Zheleznodorozhnyy",
    "Zheleznogorsk",
    "Zheleznogorsk",
    "Zheleznogorsk-Ilimskiy",
    "Zheleznovodsk",
    "Zhigulevsk",
    "Zhirnovsk",
    "Zhukovskiy",
    "Zhukovskogo",
    "Zhuravna",
    "Zima",
    "Zlatoust",
    "Zolotarevka",
    "Zori",
    "Zubovo",
    "Zvenigorod",
    "Zvenigovo",
    "Zvezda",
  ],
  "Saint Lucia": [
    "Anse La Raye",
    "Castries",
    "Choiseul",
    "Dauphin",
    "Gros Islet",
    "Vieux Fort",
  ],
  "San Marino": [
    "Acquaviva",
    "Falciano",
    "Fiorentino",
    "San Marino",
    "Serravalle",
  ],
  Senegal: [
    "Boussinki",
    "Camberene",
    "Dakar",
    "Dodji",
    "Guediawaye",
    "Kaolack",
    "Kedougou",
    "Louga",
    "Madina Kokoun",
    "Saint-Louis",
    "Sama",
    "Tanaf",
  ],
  Serbia: [
    "Ada",
    "Aleksinac",
    "Apatin",
    "Arilje",
    "Avala",
    "Backa Topola",
    "Backi Jarak",
    "Backi Petrovac",
    "Backo Gradiste",
    "Banatsko Novo Selo",
    "Barajevo",
    "Basaid",
    "Batajnica",
    "Becej",
    "Belgrade",
    "Bocar",
    "Bor",
    "Cantavir",
    "Coka",
    "Cukarica",
    "Cuprija",
    "Debeljaca",
    "Despotovac",
    "Dolovo",
    "Drenovac",
    "Futog",
    "Glozan",
    "Golubinci",
    "Gornji Milanovac",
    "Grocka",
    "Ingija",
    "Izvor",
    "Jagodina",
    "Kacarevo",
    "Kanjiza",
    "Kovin",
    "Kragujevac",
    "Kraljevo",
    "Leskovac",
    "Loznica",
    "Melenci",
    "Nikinci",
    "Nova Pazova",
    "Novi Banovci",
    "Novi Becej",
    "Novi Belgrade",
    "Novi Pazar",
    "Novi Sad",
    "Odzaci",
    "Palic",
    "Paracin",
    "Petrovac",
    "Petrovaradin",
    "Pirot",
    "Popovac",
    "Priboj",
    "Prokuplje",
    "Ratkovo",
    "Ruma",
    "Rumenka",
    "Savski Venac",
    "Selo Mladenovac",
    "Senta",
    "Sibac",
    "Simanovci",
    "Sirig",
    "Smederevo",
    "Sombor",
    "Srbobran",
    "Sremcica",
    "Sremska Kamenica",
    "Sremska Mitrovica",
    "Sremski Karlovci",
    "Stara Pazova",
    "Stari Banovci",
    "Subotica",
    "Surcin",
    "Svilajnac",
    "Svrljig",
    "Temerin",
    "Titel",
    "Tornjos",
    "Ugrinovci",
    "Umcari",
    "Umka",
    "Vajska",
    "Valjevo",
    "Veternik",
    "Vrbas",
    "Zajecar",
    "Zemun Polje",
    "Zlatibor",
    "Zrenjanin",
  ],
  Singapore: [
    "Ang Mo Kio New Town",
    "Ayer Raja New Town",
    "Bedok New Town",
    "Boon Lay",
    "Bukit Batok New Town",
    "Bukit Panjang New Town",
    "Bukit Timah",
    "Bukit Timah Estate",
    "Changi Village",
    "Choa Chu Kang New Town",
    "Clementi New Town",
    "Holland Village",
    "Hougang",
    "Jurong East New Town",
    "Jurong Town",
    "Jurong West New Town",
    "Kalang",
    "Kampong Pasir Ris",
    "Kembangan",
    "Pandan Valley",
    "Pasir Panjang",
    "Punggol",
    "Queenstown Estate",
    "Serangoon",
    "Simei New Town",
    "Singapore",
    "Tai Seng",
    "Tampines New Town",
    "Tanglin Halt",
    "Tanjong Pagar",
    "Toa Payoh New Town",
    "Woodlands New Town",
    "Yew Tee",
    "Yishun New Town",
  ],
  Slovakia: [
    "Bahon",
    "Baka",
    "Banovce nad Bebravou",
    "Bardejov",
    "Bela",
    "Beladice",
    "Bernolakovo",
    "Besenov",
    "Blatnica",
    "Bobrov",
    "Bohdanovce",
    "Boleraz",
    "Borovce",
    "Branc",
    "Bratislava",
    "Brezno",
    "Brezova pod Bradlom",
    "Bystricka",
    "Cecejovce",
    "Cechynce",
    "Cerova",
    "Cerveny Hradok",
    "Cerveny Kamen",
    "Chlebnice",
    "Chorvatsky Grob",
    "Chtelnica",
    "Cifer",
    "Detva",
    "Diakovce",
    "Diviaky nad Nitricou",
    "Dlha nad Oravou",
    "Dlhe Klcovo",
    "Dlhe nad Cirochou",
    "Dolna Marikova",
    "Dolna Streda",
    "Dolne Oresany",
    "Dolne Vestenice",
    "Dolny Hricov",
    "Dolny Stal",
    "Druzstevna pri Hornade",
    "Eliasovce",
    "Fintice",
    "Galanta",
    "Gbely",
    "Gelnica",
    "Giraltovce",
    "Golianovo",
    "Handlova",
    "Hencovce",
    "Hlinik",
    "Hlinik nad Hronom",
    "Hlohovec",
    "Holic",
    "Holice",
    "Horna Lehota",
    "Horna Stubna",
    "Horne Oresany",
    "Horne Saliby",
    "Horny Smokovec",
    "Hradiste",
    "Hrinova",
    "Hrochot,Slovakia",
    "Hruby Sur",
    "Ilava",
    "Istebne",
    "Ivanka pri Dunaji",
    "Jablonove",
    "Jacovce",
    "Jasenica",
    "Jaslovske Bohunice",
    "Jelenec",
    "Jelka",
    "Kajal",
    "Kanianka",
    "Klatova Nova Ves",
    "Klokoc",
    "Klokocov",
    "Kolarovo",
    "Komjatice",
    "Kopcany",
    "Kosicka Nova Ves",
    "Kremnica",
    "Krizovany nad Dudvahom",
    "Krompachy",
    "Krupina",
    "Krusovce",
    "Kukova",
    "Kvetoslavov",
    "Kysucky Lieskovec",
    "Ladce",
    "Lednicke Rovne",
    "Lehota",
    "Lemesany",
    "Levice",
    "Likavka",
    "Liptovske Revuce",
    "Liptovsky Hradok",
    "Lozorno",
    "Lubina",
    "Ludanice",
    "Lukacovce",
    "Madunice",
    "Malacky",
    "Maly Lapas",
    "Marianka",
    "Martin",
    "Mesto",
    "Michalovce",
    "Miloslavov",
    "Mojmirovce",
    "Moldava nad Bodvou",
    "Moravany nad Vahom",
    "Mutne",
    "Myjava",
    "Nana",
    "Nededza",
    "Nitra",
    "Nitrianske Rudno",
    "Nitrianske Sucany",
    "Nizna",
    "Nizna Kamenica",
    "Nova Bana",
    "Novaky",
    "Nove Sady",
    "Novy Salas",
    "Nyrovce",
    "Okoc",
    "Olesna",
    "Opatovce nad Nitrou",
    "Opoj",
    "Oravske Vesele",
    "Pastuchov",
    "Pata",
    "Pernek",
    "Pezinok",
    "Plave Vozokany",
    "Plavecke Podhradie",
    "Pliesovce",
    "Pobedim",
    "Podbrezova",
    "Podolie",
    "Polomka",
    "Poprad",
    "Potvorice",
    "Praha",
    "Prakovce",
    "Praznovce",
    "Prievidza",
    "Proc",
    "Reca",
    "Rosina",
    "Rovinka",
    "Ruzindol",
    "Sabinov",
    "Samorin",
    "Sarisske Bohdanovce",
    "Secovce",
    "Secovska Polianka",
    "Selice",
    "Selpice",
    "Sena",
    "Senec",
    "Senica",
    "Sipkove",
    "Skalica",
    "Sladkovicovo",
    "Slovenska Ves",
    "Slovensky Grob",
    "Smizany",
    "Snina",
    "Sobrance",
    "Solosnica",
    "Spacince",
    "Spissky Hrusov",
    "Spissky Stiavnik",
    "Spissky Stvrtok",
    "Stara Lesna",
    "Stara Tura",
    "Stary Smokovec",
    "Staskov",
    "Stefanov",
    "Stropkov",
    "Studienka",
    "Stupava",
    "Sucany",
    "Sucha nad Parnou",
    "Svaty Kriz",
    "Svaty Peter",
    "Svit",
    "Svodin",
    "Tajov",
    "Terchova",
    "Tesedikovo",
    "Tlmace",
    "Tomasov",
    "Torysky",
    "Trnava",
    "Trnovec nad Vahom",
    "Trstena",
    "Turany",
    "Tvrdosin",
    "Tvrdosovce",
    "Udavske",
    "Urmince",
    "Vahovce",
    "Varhanovce",
    "Varin",
    "Vecelkov",
    "Velcice",
    "Velicna",
    "Velky Meder",
    "Vinne",
    "Vitanova",
    "Vlckovce",
    "Vlkanova",
    "Vojcice",
    "Vrable",
    "Vrutky",
    "Vysne Raslavice",
    "Vysny Orlik",
    "Zakamenne",
    "Zavod",
    "Zbince",
    "Zelenec",
    "Zeliezovce",
    "Ziar nad Hronom",
    "Zikava",
    "Zlate",
    "Zuberec",
    "Zvolen",
  ],
  Slovenia: [
    "Ankaran",
    "Begunje na Gorenjskem",
    "Beltinci",
    "Besnica",
    "Bevke",
    "Bistrica pri Rusah",
    "Bled",
    "Bohinjska Bela",
    "Borovnica",
    "Breginj",
    "Brestanica",
    "Breznica",
    "Cemsenik",
    "Cerklje ob Krki",
    "Cerknica",
    "Cersak",
    "Cirkovce",
    "Crensovci",
    "Dekani",
    "Dob",
    "Dobravlje",
    "Dobrova",
    "Dobrunje",
    "Dol pri Ljubljani",
    "Dolenjske Toplice",
    "Dornava",
    "Dravograd",
    "Globoko",
    "Gmajnica",
    "Gorenja Vas",
    "Gorisnica",
    "Gornja Radgona",
    "Grize",
    "Grosuplje",
    "Horjul",
    "Hotedrsica",
    "Hrastnik",
    "Hrusica",
    "Idrija",
    "Ig",
    "Ilirska Bistrica",
    "Izola",
    "Jesenice",
    "Kamnik",
    "Kidricevo",
    "Knezak",
    "Kobarid",
    "Kojsko",
    "Komenda",
    "Koper",
    "Krajna",
    "Kranj",
    "Kranjska Gora",
    "Lenart v Slov. Goricah",
    "Lendava",
    "Lesce",
    "Limbus",
    "Litija",
    "Ljubecna",
    "Ljubljana",
    "Log pri Brezovici",
    "Logatec",
    "Lokev",
    "Lovrenc na Dravskem Polju",
    "Lovrenc na Pohorju",
    "Maribor",
    "Markovci",
    "Medvode",
    "Menges",
    "Mezica",
    "Miklavz na Dravskem Polju",
    "Miren",
    "Mirna",
    "Mojstrana",
    "Moravce",
    "Mozirje",
    "Murska Sobota",
    "Naklo",
    "Notranje Gorice",
    "Nova Cerkev",
    "Nova Gorica",
    "Novo Mesto",
    "Pernica",
    "Pesnica pri Mariboru",
    "Petrovce",
    "Piran",
    "Pobegi",
    "Podbocje",
    "Polhov Gradec",
    "Poljcane",
    "Polzela",
    "Postojna",
    "Prebold",
    "Preserje",
    "Prestranek",
    "Prevalje",
    "Ptuj",
    "Puconci",
    "Radlje ob Dravi",
    "Radomlje",
    "Radovljica",
    "Rakek",
    "Ravne",
    "Ravne na Koroskem",
    "Rocinj",
    "Secovlje",
    "Selnica ob Dravi",
    "Sempeter pri Gorici",
    "Sencur",
    "Sentjanz",
    "Sentvid pri Sticni",
    "Sevnica",
    "Skofljica",
    "Slovenj Gradec",
    "Slovenska Bistrica",
    "Slovenske Konjice",
    "Smarje",
    "Smarje pri Jelsah",
    "Smarje-Sap",
    "Smartno ob Paki",
    "Smlednik",
    "Sostanj",
    "Spodnje Hoce",
    "Spodnji Brnik",
    "Spodnji Duplek",
    "Spodnji Ivanjci",
    "Sredisce ob Dravi",
    "Stahovica",
    "Stara Cerkev",
    "Stari Trg pri Lozu",
    "Starse",
    "Tisina",
    "Tolmin",
    "Trbovlje",
    "Trzic",
    "Trzin",
    "Velenje",
    "Velika Loka",
    "Verzej",
    "Videm",
    "Vipava",
    "Vodice",
    "Vojnik",
    "Vrhnika",
    "Zagorje ob Savi",
    "Zelezniki",
    "Zgornja Polskava",
    "Zgornje Gorje",
    "Zgornje Hoce",
    "Zgornje Skofije",
    "Zidani Most",
    "Ziri",
  ],
  "South Africa": [
    "Alberton",
    "Alice",
    "Alrode",
    "Amanzimtoti",
    "Ashton",
    "Atlantis",
    "Balfour",
    "Bathurst",
    "Beaufort West",
    "Bedfordview",
    "Belhar",
    "Bellville",
    "Benoni",
    "Bergvliet",
    "Bethal",
    "Bethlehem",
    "Bloemfontein",
    "Boksburg",
    "Bothasig",
    "Botshabelo",
    "Braamfontein",
    "Brackenfell",
    "Brakpan",
    "Bredasdorp",
    "Brits",
    "Bronkhorstspruit",
    "Brooklyn",
    "Bryanston",
    "Buffalo",
    "Butterworth",
    "Cape Town",
    "Capital Park",
    "Carletonville",
    "Carolina",
    "Centurion",
    "City of Cape Town",
    "Clanwilliam",
    "Claremont",
    "Coega",
    "Coligny",
    "Constantia",
    "Craighall",
    "Cullinan",
    "De Aar",
    "Delmas",
    "Deneysville",
    "Despatch",
    "Doornfontein",
    "Dube Location",
    "Duiwelskloof",
    "Dullstroom",
    "Dundee",
    "Durban",
    "Durbanville",
    "East London",
    "Edenburg",
    "Edenvale",
    "Eersterivier",
    "Elandsfontein",
    "Elarduspark",
    "Eloff",
    "Elsburg",
    "Elsenburg",
    "Emerald Hill",
    "Emnambithi-Ladysmith",
    "Empangeni",
    "Erasmia",
    "Ermelo",
    "Eshowe",
    "Evander",
    "Faure",
    "Florida Glen",
    "Fochville",
    "Fordsburg",
    "Fort Cox",
    "Franschhoek",
    "Gansbaai",
    "Ganspan",
    "George",
    "Germiston",
    "Gezina",
    "Gillitts",
    "Gingindlovu",
    "Glen Austin",
    "Goodwood",
    "Graaff Reinet",
    "Grabouw",
    "Grahamstown",
    "Greytown",
    "Groblersdal",
    "Haenertsburg",
    "Harrismith",
    "Hartbeespoort",
    "Hartenbos",
    "Hartswater",
    "Hatfield",
    "Hawston",
    "Hazyview",
    "Heidelberg",
    "Hekpoort",
    "Henley on Klip",
    "Hennenman",
    "Hermanus",
    "Hillcrest",
    "Hilton",
    "Himeville",
    "Hluhluwe",
    "Hoedspruit",
    "Hogsback",
    "Houghton Estate",
    "Hout Bay",
    "Howick",
    "Humansdorp",
    "Hyde Park",
    "Irene",
    "Isando",
    "Ixopo",
    "Johannesburg",
    "Kalkbaai",
    "Katlehong",
    "Kempton Park",
    "Kimberley",
    "Kingsborough",
    "Kingsley",
    "Kingsmead",
    "Kinross",
    "Kleinmond",
    "Klerksdorp",
    "Kloof",
    "Knysna",
    "Kokstad",
    "Kraaifontein",
    "Kranskop",
    "Kriel",
    "Kroonstad",
    "Krugersdorp",
    "Kuils River",
    "KwaDukuza",
    "La Lucia",
    "La Motte",
    "Ladybrand",
    "Lansdowne",
    "Laudium",
    "Lephalale",
    "Lichtenburg",
    "Lombardy East",
    "London",
    "Louis Trichardt",
    "Lutzville",
    "Lydenburg",
    "Lyndhurst",
    "Lynnwood",
    "Lyttelton",
    "Machadodorp",
    "Mafikeng",
    "Maidstone",
    "Majuba",
    "Malmesbury",
    "Malvern",
    "Maraisburg",
    "Margate",
    "Marshalltown",
    "Meadowridge",
    "Medunsa",
    "Melkbosstrand",
    "Merrivale",
    "Messina",
    "Meyerton",
    "Middelburg",
    "Middelburg",
    "Midrand",
    "Milnerton",
    "Mmabatho",
    "Mobeni",
    "Modderfontein",
    "Mokopane",
    "Montagu",
    "Monument Park",
    "Mooi River",
    "Mookgophong",
    "Moorreesburg",
    "Morningside Manor",
    "Mossel Bay",
    "Mount Edgecombe",
    "Mthatha",
    "Muizenberg",
    "Napier",
    "Nelspruit",
    "New Germany",
    "Newcastle",
    "Newlands",
    "Nigel",
    "Northcliff",
    "Odendaalsrus",
    "Ogies",
    "Olifantsfontein",
    "Onderstepoort",
    "Onverwacht",
    "Oranjeville",
    "Oranjezicht",
    "Ottosdal",
    "Oudtshoorn",
    "Overport",
    "Paarl",
    "Panorama",
    "Parktown",
    "Parkview",
    "Parow",
    "Peninsula",
    "Phalaborwa",
    "Philadelphia",
    "Philipstown",
    "Phoenix",
    "Phokeng",
    "Pietermaritzburg",
    "Pinelands",
    "Pinetown",
    "Plettenberg Bay",
    "Plumstead",
    "Polokwane",
    "Pongola",
    "Port Alfred",
    "Port Elizabeth",
    "Port Shepstone",
    "Potchefstroom",
    "Pretoria",
    "Prieska",
    "Primrose",
    "Pringle Bay",
    "Queensburgh",
    "Queenstown",
    "Queenswood",
    "Randburg",
    "Randfontein",
    "Rawsonville",
    "Rhodes",
    "Richards Bay",
    "Richmond",
    "Riebeek-Kasteel",
    "Rivonia",
    "Roggebaai",
    "Roodepoort",
    "Rooigrond",
    "Rooihuiskraal",
    "Rosebank",
    "Rosettenville",
    "Rosslyn",
    "Rustenburg",
    "Sabie",
    "Saint Helena Bay",
    "Saint James",
    "Saldanha",
    "Salt Rock",
    "Sandown",
    "Sandton",
    "Sasolburg",
    "Schweizer-Reneke",
    "Scottburgh",
    "Sebokeng",
    "Secunda",
    "Sedgefield",
    "Senekal",
    "Shelly Beach",
    "Silverton",
    "Sinoville",
    "Somerset West",
    "Soshanguve",
    "Soweto",
    "Springs",
    "Standerton",
    "Stanford",
    "Stellenbosch",
    "Stilfontein",
    "Strand",
    "Sun Valley",
    "Swartkops",
    "Temba",
    "Tembisa",
    "Thabazimbi",
    "The Rest",
    "Thohoyandou",
    "Three Anchor Bay",
    "Three Rivers",
    "Tokai",
    "Tongaat",
    "Tzaneen",
    "Uitenhage",
    "Umbogintwini",
    "Umdloti",
    "Umhlanga",
    "Umkomaas",
    "Umtentweni",
    "Upington",
    "Vaalbank",
    "Vaalpark",
    "Vanderbijlpark",
    "Vereeniging",
    "Verulam",
    "Villiersdorp",
    "Virginia",
    "Vosloorus",
    "Vrededorp",
    "Vredenburg",
    "Vryheid",
    "Walkerville",
    "Walmer Heights",
    "Warner Beach",
    "Wartburg",
    "Waverley",
    "Waverley",
    "Welgelegen",
    "Welgemoed",
    "Welkom",
    "Welkom",
    "Wellington",
    "Weltevreden",
    "Weltevreedenpark",
    "Westonaria",
    "Westville",
    "White River",
    "Wilderness",
    "Winterton",
    "Witbank",
    "Witpoortjie",
    "Wonderboom",
    "Worcester",
    "Wynberg",
    "Zeerust",
  ],
  Spain: [
    "A Cidade",
    "A Estrada",
    "A Pobra do Caraminal",
    "Abadino",
    "Abanilla",
    "Abanto",
    "Abaran",
    "Abegondo",
    "Abrera",
    "Acala del Rio",
    "Aceuchal",
    "Adeje",
    "Ador",
    "Adra",
    "Adrall",
    "Aduna",
    "Agaete",
    "Agoncillo",
    "Agost",
    "Agramunt",
    "Agreda",
    "Aguadulce",
    "Aguilafuente",
    "Aguilar",
    "Aguilar de Campoo",
    "Aguilar del Rio Alhama",
    "Agullana",
    "Agullent",
    "Ahigal",
    "Aielo de Malferit",
    "Aiguafreda",
    "Aizarnazabal",
    "Aizoain",
    "Ajalvir",
    "Ajo",
    "Ajofrin",
    "Alaejos",
    "Alagon",
    "Alameda",
    "Alanis",
    "Alava",
    "Alba de Tormes",
    "Albacete",
    "Albaida",
    "Albal",
    "Albalat dels Sorells",
    "Albalat dels Tarongers",
    "Albalate de Cinca",
    "Albarreal de Tajo",
    "Albatera",
    "Albelda de Iregua",
    "Albendin",
    "Alberic",
    "Alberite",
    "Albolote",
    "Alboraya",
    "Albox",
    "Albuixech",
    "Albujon",
    "Alburquerque",
    "Alcala de Xivert",
    "Alcala del Obispo",
    "Alcala la Real",
    "Alcanadre",
    "Alcanar",
    "Alcantara",
    "Alcantarilla",
    "Alcarras",
    "Alcazar de San Juan",
    "Alcobendas",
    "Alcoletge",
    "Alcorisa",
    "Alcossebre",
    "Alcover",
    "Alcoy",
    "Aldaia",
    "Aldealsenor",
    "Aldeamayor de San Martin",
    "Aldeanueva",
    "Aldeanueva de Ebro",
    "Aldeaseca de Armuna",
    "Aldeatejada",
    "Alegria",
    "Alella",
    "Alfafar",
    "Alfajarin",
    "Alfambra",
    "Alfara de Algimia",
    "Alfara del Patriarca",
    "Alfaro",
    "Alfarrasi",
    "Alfondeguilla",
    "Alforja",
    "Algadefe",
    "Algaida",
    "Algar",
    "Algarrobo",
    "Algeciras",
    "Algemesi",
    "Algete",
    "Algezares",
    "Alginet",
    "Algodonales",
    "Algorta",
    "Alguazas",
    "Alhama de Aragon",
    "Alhama de Granada",
    "Alhama de Murcia",
    "Alhambra",
    "Alhaurin de la Torre",
    "Alhaurin el Grande",
    "Alhendin",
    "Alicante",
    "Aliseda",
    "Aljaraque",
    "Allariz",
    "Almagro",
    "Almansa",
    "Almargen",
    "Almassera",
    "Almassora",
    "Almazan",
    "Almazcara",
    "Almenar",
    "Almenara",
    "Almendralejo",
    "Almensilla",
    "Almodovar del Campo",
    "Almodovar del Rio",
    "Almonacid de Zorita",
    "Almonte",
    "Almoradi",
    "Almoster",
    "Almudebar",
    "Almuna",
    "Alonsotegi",
    "Alora",
    "Alosno",
    "Alovera",
    "Alozaina",
    "Alpedrete",
    "Alpicat",
    "Alquerias",
    "Alta",
    "Altafulla",
    "Altea",
    "Altet",
    "Altura",
    "Alumbres",
    "Alza",
    "Alzira",
    "Amavida",
    "Amer",
    "Ames",
    "Ametlla",
    "Amorebieta",
    "Amposta",
    "Ampuero",
    "Amurrio",
    "Anchuelo",
    "Andeiro",
    "Andoain",
    "Andorra",
    "Andosilla",
    "Andujar",
    "Anglesola",
    "Anguciana",
    "Anoeta",
    "Anora",
    "Anorbe",
    "Antas",
    "Antequera",
    "Antigua",
    "Aracena",
    "Arafo",
    "Arama",
    "Aranda de Duero",
    "Aranguren",
    "Aranjuez",
    "Aravaca",
    "Arbizu",
    "Arbo",
    "Arboleas",
    "Arca",
    "Arcade",
    "Arce",
    "Archena",
    "Archidona",
    "Arcicollar",
    "Arcos de la Frontera",
    "Arenas de San Pedro",
    "Arenys de Mar",
    "Arenys de Munt",
    "Ares",
    "Arevalo",
    "Argamasilla de Alba",
    "Argamasilla de Calatrava",
    "Arganda",
    "Argelaguer",
    "Argentona",
    "Arges",
    "Argonos",
    "Arico",
    "Arino",
    "Arjona",
    "Armilla",
    "Armunia",
    "Arnedillo",
    "Arnedo",
    "Arnuero",
    "Aroche",
    "Arona",
    "Arrecife",
    "Arriate",
    "Arrigorriaga",
    "Arroyal",
    "Arroyo",
    "Arroyo de la Luz",
    "Arroyo de la Miel",
    "Arroyo del Ojanco",
    "Arroyomolinos",
    "Arrubal",
    "Artajo",
    "Artajona",
    "Artana",
    "Arteixo",
    "Artenara",
    "Artes",
    "Artesa de Segre",
    "Arucas",
    "Arzua",
    "As Pontes de Garcia Rodriguez",
    "Aspe",
    "Asteasu",
    "Astigarraga",
    "Astorga",
    "Astrabudua",
    "Astrain",
    "Astudillo",
    "Atarfe",
    "Atarrabia",
    "Ataun",
    "Ateca",
    "Autol",
    "Avia",
    "Avinyonet del Penedes",
    "Axpe",
    "Ayamonte",
    "Ayegui",
    "Azagra",
    "Azkoitia",
    "Aznalcazar",
    "Aznalcollar",
    "Azpeitia",
    "Azuaga",
    "Azuqueca de Henares",
    "Babilafuente",
    "Badajoz",
    "Badalona",
    "Baena",
    "Baeza",
    "Baga",
    "Bailen",
    "Baina",
    "Bajamar",
    "Balaguer",
    "Balazote",
    "Balenya",
    "Balerma",
    "Balsareny",
    "Balsicas",
    "Banugues",
    "Bara",
    "Barakaldo",
    "Baranain",
    "Barbarroja",
    "Barbastro",
    "Barbate",
    "Barcarrota",
    "Barcelona",
    "Barcena de Cicero",
    "Bargas",
    "Barranda",
    "Barros",
    "Bas",
    "Basauri",
    "Batres",
    "Baza",
    "Beas",
    "Beas de Segura",
    "Becerril de la Sierra",
    "Bedmar",
    "Begijar",
    "Begis",
    "Begues",
    "Begur",
    "Bejar",
    "Belgida",
    "Belicena",
    "Bellpuig",
    "Bellvei",
    "Bellver de Cerdanya",
    "Bellvis",
    "Belvis de Jarama",
    "Belvis de la Jara",
    "Bembibre",
    "Benacazon",
    "Benagalbon",
    "Benahadux",
    "Benahavis",
    "Benamargosa",
    "Benameji",
    "Benatae",
    "Benavarri / Benabarre",
    "Benavente",
    "Benavides",
    "Benejuzar",
    "Beniajan",
    "Beniarbeig",
    "Beniarjo",
    "Beniarres",
    "Benicassim",
    "Benicolet",
    "Benidoleig",
    "Benidorm",
    "Beniel",
    "Benifairo de les Valls",
    "Benifato",
    "Beniganim",
    "Benijofar",
    "Benimodo",
    "Beniparrell",
    "Benisano",
    "Benissa",
    "Benisuera",
    "Benitachell",
    "Beranga",
    "Berango",
    "Berantevilla",
    "Berastegui",
    "Berga",
    "Bergondo",
    "Berguenda",
    "Beriain",
    "Berja",
    "Bermeo",
    "Bernuy",
    "Berriatua",
    "Berriobeiti",
    "Berriozar",
    "Berriz",
    "Berrobi",
    "Berron",
    "Bescano",
    "Betanzos",
    "Betelu",
    "Betera",
    "Beznar",
    "Biar",
    "Bicorp",
    "Bigastro",
    "Bigues i Riells",
    "Bilbao",
    "Binefar",
    "Bitem",
    "Blanca",
    "Blancos",
    "Blanes",
    "Boadilla del Monte",
    "Bocairent",
    "Boceguillas",
    "Boecillo",
    "Bogajo",
    "Boimorto",
    "Boiro",
    "Bolanos de Calatrava",
    "Bollullos de la Mitacion",
    "Bollullos par del Condado",
    "Boltana",
    "Bolvir",
    "Bonares",
    "Borben",
    "Bordils",
    "Borja",
    "Bormujos",
    "Bornos",
    "Borox",
    "Borriol",
    "Botarell",
    "Botorrita",
    "Brafim",
    "Brazatortas",
    "Brea de Aragon",
    "Breda",
    "Brenes",
    "Brihuega",
    "Brion",
    "Briviesca",
    "Broto",
    "Brunete",
    "Buenache de Alarcon",
    "Bueu",
    "Bujaraloz",
    "Bullas",
    "Burela de Cabo",
    "Burgos",
    "Burguillos",
    "Burguillos del Cerro",
    "Burjassot",
    "Burlata",
    "Burriana",
    "Busot",
    "Bustarviejo",
    "Busturi-Axpe",
    "Cabana",
    "Cabanaquinta",
    "Cabanas",
    "Cabanas de la Sagra",
    "Cabanes",
    "Cabanillas del Campo",
    "Cabeza del Buey",
    "Cabezarrubias del Puerto",
    "Cabezo de Torres",
    "Cabezon de la Sal",
    "Caborana",
    "Cabra",
    "Cabrera de Almanza",
    "Cabrerizos",
    "Cabrils",
    "Cacheiras",
    "Cadiar",
    "Cadiz",
    "Cadreita",
    "Caion",
    "Cajar",
    "Cala",
    "Cala del Moral",
    "Cala Millor",
    "Cala Ratjada",
    "Calaf",
    "Calafell",
    "Calahonda",
    "Calahorra",
    "Calamonte",
    "Calasparra",
    "Calatayud",
    "Calatorao",
    "Caldelas",
    "Calders",
    "Caldes de Malavella",
    "Calella",
    "Calig",
    "Callosa de Segura",
    "Calo",
    "Calonge",
    "Calpe",
    "Camarassa",
    "Camarenilla",
    "Camargo",
    "Camarinas",
    "Camarma de Esteruelas",
    "Camas",
    "Cambados",
    "Cambre",
    "Cambrils",
    "Camos",
    "Campanario",
    "Campillo",
    "Campillos",
    "Campina",
    "Campins",
    "Campo",
    "Campo de Criptana",
    "Campo Real",
    "Campohermoso",
    "Camponaraya",
    "Campos",
    "Camprodon",
    "Campuzano",
    "Can Picafort",
    "Canal",
    "Canals",
    "Canar",
    "Candas",
    "Candelaria",
    "Candeleda",
    "Caneja",
    "Canet de Mar",
    "Canete de las Torres",
    "Cangas de Onis",
    "Cangas del Narcea",
    "Cangas do Morrazo",
    "Canillas de Albaida",
    "Canovelles",
    "Cantillana",
    "Cantimpalos",
    "Capdepera",
    "Capela",
    "Capella",
    "Capellades",
    "Capileira",
    "Capmany",
    "Capsec",
    "Carabana",
    "Caravaca",
    "Carbajal de la Legua",
    "Carbajosa de la Sagrada",
    "Carballo",
    "Carcabuey",
    "Carcar",
    "Carcastillo",
    "Cardedeu",
    "Cardenal",
    "Cardenosa",
    "Cardona",
    "Carinena",
    "Carlet",
    "Carmona",
    "Carpesa",
    "Carpio",
    "Carral",
    "Carranque",
    "Carrascal de Barregas",
    "Carrion de los Cespedes",
    "Carrion de los Condes",
    "Carrizo de la Ribera",
    "Cartagena",
    "Cartama",
    "Cartaya",
    "Cartelle",
    "Casabermeja",
    "Casar de Caceres",
    "Casares",
    "Casarrubios del Monte",
    "Casas del Castanar",
    "Casas del Monte",
    "Casas Viejas",
    "Caserio El Campello",
    "Casetas",
    "Casillas de Coria",
    "Casillas del Angel",
    "Casla",
    "Caso",
    "Caspe",
    "Castalla",
    "Castandiello",
    "Castejon",
    "Castejon del Puente",
    "Castell de Ferro",
    "Castellar de la Frontera",
    "Castellar de Santiago",
    "Castellar del Valles",
    "Castellbell i el Vilar",
    "Castellbisbal",
    "Castelldefels",
    "Castellet",
    "Castello",
    "Castellvell del Camp",
    "Castellvi de Rosanes",
    "Castelseras",
    "Castilblanco de los Arroyos",
    "Castilleja de Guzman",
    "Castilleja de la Cuesta",
    "Castillo de Locubin",
    "Castillo del Romeral",
    "Castrillon",
    "Castro",
    "Castro Urdiales",
    "Castrogonzalo",
    "Castropol",
    "Castuera",
    "Catadau",
    "Catarroja",
    "Cati",
    "Catoira",
    "Catral",
    "Catral",
    "Caudete",
    "Cazalegas",
    "Cazorla",
    "Cea",
    "Cebolla",
    "Cebreros",
    "Cecenas",
    "Cedillo del Condado",
    "Celanova",
    "Celeiro",
    "Celeiros",
    "Celra",
    "Cenes de la Vega",
    "Cenicero",
    "Cenicientos",
    "Central",
    "Cerceda",
    "Cercedilla",
    "Cerdeda",
    "Cerro Muriano",
    "Cervello",
    "Cervera",
    "Cervera del Rio Alhama",
    "Cervo",
    "Cetina",
    "Ceuta",
    "Ceuti",
    "Chantada",
    "Chapela",
    "Chauchina",
    "Checa",
    "Chelva",
    "Cheste",
    "Chiclana de la Frontera",
    "Chiclana de Segura",
    "Chilches",
    "Chiloeches",
    "Chinchilla de Monte Aragon",
    "Chipiona",
    "Chiva",
    "Chozas de Canales",
    "Chucena",
    "Churra",
    "Churriana",
    "Churriana de la Vega",
    "Cicero",
    "Cidones",
    "Ciempozuelos",
    "Cieza",
    "Cifuentes",
    "Cigales",
    "Cijuela",
    "Cilleros",
    "Ciriza",
    "Cisterniga",
    "Cistierna",
    "Ciudad Real",
    "Ciudad Rodrigo",
    "Ciutadella",
    "Cizur Menor",
    "Cobatillas",
    "Cobena",
    "Cobisa",
    "Coca",
    "Cocentaina",
    "Cogollos",
    "Coin",
    "Coiros",
    "Colera",
    "Coles",
    "Colinas de Trasmonte",
    "Colindres",
    "Collado",
    "Collado Mediano",
    "Collado Villalba",
    "Collbato",
    "Colmenar de Oreja",
    "Colmenar del Arroyo",
    "Colmenar Viejo",
    "Colmenarejo",
    "Colonia de San Pedro",
    "Comares",
    "Comillas",
    "Competa",
    "Compostela",
    "Conil de la Frontera",
    "Consell",
    "Constanti",
    "Constantina",
    "Consuegra",
    "Corbera",
    "Corbera de Llobregat",
    "Corchuela",
    "Cordova",
    "Corella",
    "Corgo",
    "Coria",
    "Coristanco",
    "Corme-Porto",
    "Cornellana",
    "Corral de Almaguer",
    "Corralejo",
    "Corrales",
    "Corrales",
    "Cortegana",
    "Cortes de la Frontera",
    "Cortiguera",
    "Corvera",
    "Corvera",
    "Coslada",
    "Costa",
    "Costur",
    "Couso de Salas",
    "Covaleda",
    "Cox",
    "Creixell",
    "Crevillent",
    "Cruce de Arinaga",
    "Cruces",
    "Cruilles",
    "Cruz",
    "Cuadros",
    "Cuarte de Huerva",
    "Cubas",
    "Cubas",
    "Cubelles",
    "Cubillos del Sil",
    "Cudillero",
    "Cuellar",
    "Cuenca",
    "Cueto",
    "Cuevas de San Marcos",
    "Cuevas de Vinroma",
    "Cuevas del Almanzora",
    "Cullera",
    "Culleredo",
    "Cunit",
    "Cuntis",
    "Curro",
    "Curtis",
    "Daganzo de Arriba",
    "Daimiel",
    "Dalias",
    "Daroca",
    "Daya Vieja",
    "Deba",
    "Denia",
    "Derio",
    "Deustu",
    "Diezma",
    "Dilar",
    "Dodro",
    "Dolores",
    "Domingo Perez",
    "Don Benito",
    "Dos Hermanas",
    "Dosbarrios",
    "Dosrius",
    "Duenas",
    "Dumbria",
    "Durango",
    "Durcal",
    "Echarri-Aranaz",
    "Egues-Uharte",
    "Eibar",
    "Ejea de los Caballeros",
    "El Abrigo",
    "El Alamo",
    "El Alamo",
    "El Arahal",
    "El Astillero",
    "El Ballestero",
    "El Barco de Avila",
    "El Burgo de Ebro",
    "El Burgo de Osma",
    "el Camp de Mirra",
    "el Campello",
    "El Casar",
    "el Catllar",
    "El Coronil",
    "El Corrillo",
    "El Cuervo",
    "El Ejido",
    "El Escorial",
    "El Escorial",
    "El Espinar",
    "El Garrobo",
    "El Grado",
    "El Grao",
    "El Hoyo de Pinares",
    "el Masnou",
    "El Medano",
    "El Molar",
    "El Moral",
    "el Morche",
    "el Morell",
    "El Palmar",
    "El Palmar de Troya",
    "El Papiol",
    "El Pedernoso",
    "El Perello",
    "el Poal",
    "El Pozo de los Frailes",
    "el Prat de Llobregat",
    "El Provencio",
    "El Puerto",
    "El Raal",
    "El Real de la Jara",
    "El Saler",
    "El Sauzal",
    "El Sotillo",
    "El Tablero",
    "El Torno",
    "El Toro",
    "El Vellon",
    "El Vendrell",
    "El Viso de San Juan",
    "El Viso del Alcor",
    "Elburgo",
    "Elche",
    "Elda",
    "Elechas",
    "Elexalde",
    "Elgoibar",
    "Elgorriaga",
    "Elizondo",
    "Elorrio",
    "els Hostalets de Pierola",
    "Els Monjos",
    "els Pallaresos",
    "Eltzaburu",
    "Encinas Reales",
    "Encinasola",
    "Eneriz",
    "Enguera",
    "Entrambasaguas",
    "Entrevias",
    "Epila",
    "Erandio",
    "Ermua",
    "Errenteria",
    "Escalona",
    "Escalonilla",
    "Escatron",
    "Escurial",
    "Esparreguera",
    "Espartinas",
    "Espejo",
    "Espera",
    "Espiel",
    "Espinardo",
    "Espirdo",
    "Esplugues de Llobregat",
    "Esplus",
    "Espolla",
    "Esquivias",
    "Esteiro",
    "Estella-Lizarra",
    "Estellencs",
    "Estepa",
    "Estepona",
    "Estrada",
    "Ezcaray",
    "Fabero",
    "Falces",
    "Falset",
    "Fasnia",
    "Faura",
    "Felanitx",
    "Fene",
    "Ferreira",
    "Ferreries",
    "Ferrol",
    "Figaro",
    "Figueras",
    "Finestrat",
    "Firgas",
    "Fitero",
    "Flix",
    "Formentera de Segura",
    "Fornells de la Selva",
    "Fortia",
    "Fortuna",
    "Forua",
    "Foz",
    "Fraga",
    "Frailes",
    "Fregenal de la Sierra",
    "Fresno de la Vega",
    "Frigiliana",
    "Friol",
    "Fruiz",
    "Fuengirola",
    "Fuenlabrada",
    "Fuenmayor",
    "Fuensalida",
    "Fuensanta",
    "Fuensanta de Martos",
    "Fuente del Maestre",
    "Fuente el Fresno",
    "Fuente el Saz",
    "Fuente Vaqueros",
    "Fuente-Alamo",
    "Fuentealbilla",
    "Fuentelapena",
    "Fuenterrabia",
    "Fuentes",
    "Fuentes de Andalucia",
    "Fuentes de Ebro",
    "Fuentes de Jiloca",
    "Fuentes de Leon",
    "Fuentes de Valdepero",
    "Gaceta",
    "Galapagar",
    "Galapagos",
    "Galaroza",
    "Galdakao",
    "Galdames Beitia",
    "Galera",
    "Galizano",
    "Gallarta",
    "Gallur",
    "Galvez",
    "Gamiz",
    "Gamonal",
    "Gandesa",
    "Gandia",
    "Garachico",
    "Garcia",
    "Garciaz",
    "Gargallo",
    "Garraf",
    "Garrigas",
    "Garriguella",
    "Garrovillas",
    "Garrucha",
    "Gata",
    "Gata de Gorgos",
    "Gaucin",
    "Gautegiz Arteaga",
    "Gava",
    "Gelida",
    "Gelsa",
    "Gelves",
    "Genoves",
    "Gerena",
    "Gernika-Lumo",
    "Getafe",
    "Getaria",
    "Gilet",
    "Gines",
    "Girona",
    "Gironella",
    "Gizaburuaga",
    "Godella",
    "Godelleta",
    "Gojar",
    "Golmayo",
    "Golmes",
    "Gondomar",
    "Gordon",
    "Gorriti",
    "Gozon de Ucieza",
    "Grado",
    "Granada",
    "Granadilla de Abona",
    "Granja de Rocamora",
    "Granollers",
    "Graus",
    "Grazalema",
    "Grijota",
    "Grinon",
    "Grisen",
    "Grove, O",
    "Guadalajara",
    "Guadalcazar",
    "Guadalix de la Sierra",
    "Guadalupe",
    "Guadalupe",
    "Guadamur",
    "Guadarrama",
    "Guadiana del Caudillo",
    "Guadiaro",
    "Guadix",
    "Guamasa",
    "Guardamar del Segura",
    "Guardo",
    "Guarena",
    "Guarnizo",
    "Guejar-Sierra",
    "Guenes",
    "Guevejar",
    "Guijuelo",
    "Guillena",
    "Guimar",
    "Guitiriz",
    "Gurb",
    "Guzman",
    "Haro",
    "Hellin",
    "Henares",
    "Herencia",
    "Hernani",
    "Hernansancho",
    "Hernialde",
    "Herrera",
    "Herrera",
    "Herrera de Pisuerga",
    "Herrera del Duque",
    "Higuera la Real",
    "Hijar",
    "Hinojedo",
    "Hinojos",
    "Hinojosa del Duque",
    "Hinojosa del Valle",
    "Hondon de las Nieves",
    "Hondon de los Frailes",
    "Hontanares de Eresma",
    "Hontoria del Pinar",
    "Horcajo de las Torres",
    "Horche",
    "Hornachos",
    "Hornachuelos",
    "Horta de Sant Joan",
    "Hortichuela",
    "Hospital",
    "Hostalric",
    "Hoya-Gonzalo",
    "Hoyo de Manzanares",
    "Huarte-Araquil",
    "Huecas",
    "Huelma",
    "Huelva",
    "Huelves",
    "Huercal de Almeria",
    "Huercal Overa",
    "Huercanos",
    "Huertas",
    "Huerto",
    "Huesca",
    "Huete",
    "Huetor Santillan",
    "Huetor Vega",
    "Huetor-Tajar",
    "Huevar",
    "Humanes",
    "Humanes de Madrid",
    "Hurchillo",
    "Ibarra",
    "Ibarruri",
    "Ibi",
    "Ibiza",
    "Ibros",
    "Icod de los Vinos",
    "Idiazabal",
    "Igualada",
    "Igualeja",
    "Igueste",
    "Illa",
    "Illana",
    "Illas",
    "Illescas",
    "Illora",
    "Illueca",
    "Inca",
    "Incio",
    "Infantes",
    "Ingenio",
    "Iniesta",
    "Ipazter",
    "Irun",
    "Irura",
    "Isla Becoa",
    "Isla Cristina",
    "Isora",
    "Itza",
    "Iznajar",
    "Iznate",
    "Jabaga",
    "Jabali Nuevo",
    "Jaca",
    "Jacarilla",
    "Jaraiz de la Vera",
    "Javea",
    "Jerez de la Frontera",
    "Jerez de los Caballeros",
    "Jerte",
    "Jesus",
    "Jijona",
    "Jimena de la Frontera",
    "Joanetes",
    "Jodar",
    "Jorba",
    "Jumilla",
    "Juneda",
    "La Abadilla",
    "La Adrada",
    "La Alameda de la Sagra",
    "La Alberca",
    "La Algaba",
    "La Aljorra",
    "La Almunia de Dona Godina",
    "La Antilla",
    "La Arena",
    "La Arena",
    "La Baneza",
    "la Baronia de Rialb",
    "La Batlloria",
    "La Bisbal",
    "la Bisbal del Penedes",
    "La Cabrera",
    "La Calzada",
    "La Calzada de Calatrava",
    "La Calzada de Oropesa",
    "La Campana",
    "La Canada",
    "La Canada de San Urbano",
    "La Canalosa",
    "La Canonja",
    "La Caridad",
    "La Carlota",
    "La Carolina",
    "La Cavada",
    "La Codosera",
    "La Espina",
    "la Febro",
    "La Felguera",
    "La Figuera",
    "La Flecha",
    "La Floresta Pearson",
    "La Florida",
    "La Fresneda",
    "La Frontera",
    "La Fuente de San Esteban",
    "La Galera",
    "La Garganta",
    "la Garriga",
    "La Gineta",
    "La Granada",
    "la Granadella",
    "La Granja",
    "La Granja de San Vicente",
    "La Guancha",
    "La Guardia",
    "La Guardia de Jaen",
    "La Herradura",
    "La Higuerita",
    "La Lantejuela",
    "La Llacuna",
    "La Llagosta",
    "La Llosa",
    "La Luisiana",
    "La Mamola",
    "La Mancha Blanca",
    "La Matanza de Acentejo",
    "La Mojonera",
    "La Muela",
    "La Nora",
    "la Nou de Bergueda",
    "la Nucia",
    "La Oliva",
    "La Orden",
    "La Orotava",
    "La Palma del Condado",
    "La Paz",
    "La Pedraja de Portillo",
    "La Pedrera",
    "La Penilla",
    "La Perdoma",
    "La Plana",
    "La Playa de Arguineguin",
    "La Pobla de Claramunt",
    "La Pobla de Lillet",
    "la Pobla de Mafumet",
    "la Pobla de Montornes",
    "La Pola de Gordon",
    "La Puebla de Almoradiel",
    "La Puebla de Arganzon",
    "La Puebla de Cazalla",
    "La Puebla de Hijar",
    "La Puebla de los Infantes",
    "La Puebla de Montalban",
    "La Puebla del Rio",
    "La Pueblanueva",
    "La Puerta de Segura",
    "La Rambla",
    "La Rambla",
    "La Rapita",
    "La Raya",
    "La Red de Valdetuejar",
    "La Riba",
    "la Riera de Gaia",
    "La Rinconada",
    "La Robla",
    "La Roca del Valles",
    "La Roda",
    "La Roda de Andalucia",
    "La Seca",
    "La Secuita",
    "La Selva",
    "La Serna",
    "La Solana",
    "La Torre",
    "La Torre",
    "La Torre",
    "La Torre",
    "la Vall",
    "la Vall del Bac",
    "La Vera",
    "La Victoria",
    "La Victoria de Acentejo",
    "La Vinuela",
    "La Virgen del Camino",
    "La Virgen del Pilar",
    "La Yedra",
    "Labajos",
    "Lagartera",
    "Lago",
    "Laguardia",
    "Laguna de Duero",
    "Lajares",
    "Lalin",
    "Lama",
    "Lamadrid",
    "Lanaja",
    "Landa",
    "Landa",
    "Langa",
    "Langa de Duero",
    "Langreo",
    "Lanjaron",
    "Lantadilla",
    "Laracha",
    "Lardero",
    "Laredo",
    "Las Cabezas de San Juan",
    "Las Galletas",
    "Las Hortichuelas",
    "Las Islas",
    "Las Matas",
    "Las Navas de la Concepcion",
    "Las Navas del Marques",
    "Las Palmas de Gran Canaria",
    "Las Rozas de Madrid",
    "Las Torres de Cotillas",
    "Las Vegas",
    "Las Ventanas",
    "Las Ventas de Retamosa",
    "Lasarte",
    "Laudio-Llodio",
    "Lazagurria",
    "Lebrija",
    "Ledana",
    "Ledrada",
    "Legasa",
    "Legazpia",
    "Legorreta",
    "Lekeitio",
    "Lepe",
    "Lerin",
    "Lerma",
    "les Borges del Camp",
    "Les Fonts",
    "Lezama",
    "Lezo",
    "Liano",
    "Librilla",
    "Liencres",
    "Lijar",
    "Lillo",
    "Limones",
    "Limpias",
    "Linares",
    "Linyola",
    "Lires",
    "Llafranc",
    "Llagostera",
    "Llanera",
    "Llanes",
    "Llano",
    "Lledo",
    "Lleida",
    "Llerena",
    "Llers",
    "Llinars del Valles",
    "Lliria",
    "Llofriu",
    "Lloret de Mar",
    "Llosa de Ranes",
    "Lobios",
    "Lodosa",
    "Loeches",
    "Loja",
    "Lomo de Arico",
    "Lopera",
    "Lorca",
    "Loriguilla",
    "Lorqui",
    "Los Alcazares",
    "Los Angeles",
    "Los Barrios",
    "Los Barrios",
    "Los Corrales",
    "Los Corrales de Buelna",
    "Los Cristianos",
    "Los Dolores",
    "Los Gallardos",
    "Los Garres",
    "Los Hueros",
    "Los Llanos de Aridane",
    "Los Marines",
    "Los Molinos",
    "Los Montes",
    "Los Narejos",
    "Los Navalmorales",
    "Los Navalucillos",
    "Los Palacios",
    "Los Palacios y Villafranca",
    "Los Rios",
    "Los Rosales",
    "Los Santos de la Humosa",
    "Los Silos",
    "Los Villares",
    "Los Yebenes",
    "Losar de la Vera",
    "Lousame",
    "Luanco",
    "Luarca",
    "Lucena",
    "Lucena del Cid",
    "Lucena del Puerto",
    "Luceni",
    "Lucillos",
    "Lugo",
    "Lugones",
    "Luou",
    "Luyando",
    "Luzaide",
    "Luzmela",
    "Macael",
    "Macanet de la Selva",
    "Maceira",
    "Madrid",
    "Madridanos",
    "Madridejos",
    "Madrigal de la Vera",
    "Madrigalejo",
    "Madrigueras",
    "Madronera",
    "Magan",
    "Mahon",
    "Mairena del Alcor",
    "Mairena del Aljarafe",
    "Majadahonda",
    "Malagon",
    "Malgrat de Mar",
    "Maliano",
    "Mallen",
    "Malpartida de Caceres",
    "Malpartida de Plasencia",
    "Malpica",
    "Malpica",
    "Manacor",
    "Mancha Real",
    "Manilva",
    "Manises",
    "Manlleu",
    "Manresa",
    "Manuel",
    "Manzanares",
    "Manzanares el Real",
    "Maracena",
    "Marbella",
    "Marchamalo",
    "Marchena",
    "Marcilla",
    "Margarida",
    "Maria de Huerva",
    "Maria de la Salut",
    "Marin",
    "Mariola",
    "Marmolejo",
    "Martin",
    "Martinet",
    "Martorell",
    "Martos",
    "Marugan",
    "Marzagan",
    "Masdenverge",
    "Maside",
    "Masllorenc",
    "Maspalomas",
    "Masquefa",
    "Massamagrell",
    "Mata",
    "Matadepera",
    "Mataelpino",
    "Mataluenga",
    "Matamorosa",
    "Matapozuelos",
    "Mayor",
    "Mazagon",
    "Meano",
    "Mecina Fondales",
    "Meco",
    "Medina de Pomar",
    "Medina del Campo",
    "Medina-Sidonia",
    "Medinya",
    "Medio",
    "Mediona",
    "Medrano",
    "Meira",
    "Meis",
    "Mejorada",
    "Mejorada del Campo",
    "Meliana",
    "Melilla",
    "Membrilla",
    "Mendaro",
    "Mengibar",
    "Mentrida",
    "Mequinensa / Mequinenza",
    "Mercadal",
    "Mestanza",
    "Mezalocha",
    "Miajadas",
    "Mieres",
    "Miguel Esteban",
    "Miguelturra",
    "Mijas",
    "Milagro",
    "Millares",
    "Minas de Riotinto",
    "Ministerio",
    "Mino",
    "Miono",
    "Mira",
    "Mirador",
    "Miraflores de la Sierra",
    "Miramar",
    "Miranda de Arga",
    "Miranda de Ebro",
    "Miranda del Castanar",
    "Mislata",
    "Moana",
    "Mocejon",
    "Mogente",
    "Moguer",
    "Moia",
    "Moja",
    "Mojacar Pueblo",
    "Mojados",
    "Molina de Aragon",
    "Molina de Segura",
    "Molinos de Duero",
    "Molins de Rei",
    "Mollina",
    "Mollo",
    "Mombeltran",
    "Monachil",
    "Moncada",
    "Moncofar",
    "Monda",
    "Mondariz",
    "Mondonedo",
    "Mondujar",
    "Monesterio",
    "Monforte de Lemos",
    "Monforte del Cid",
    "Monistrol de Calders",
    "Monserrat",
    "Mont",
    "Montagut",
    "Montalban de Cordoba",
    "Montcada i Reixac",
    "Monte",
    "Monteagudo",
    "Montealegre del Castillo",
    "Montearagon",
    "Montefrio",
    "Montehermoso",
    "Montejicar",
    "Montellano",
    "Montemayor de Pililla",
    "Monterroso",
    "Monterrubio de la Serena",
    "Montesa",
    "Montesquiu",
    "Montfulla",
    "Montgat",
    "Montijo",
    "Montilla",
    "Montillana",
    "Montmajor",
    "Montroy",
    "Montseny",
    "Monzalbarba",
    "Monzon",
    "Mora",
    "Mora de Rubielos",
    "Moraleja de Enmedio",
    "Moralzarzal",
    "Morata de Jalon",
    "Morata de Tajuna",
    "Moratalla",
    "Moreda",
    "Morella",
    "Moriscos",
    "Morro del Jable",
    "Mortera",
    "Moscas del Paramo",
    "Mosqueruela",
    "Mostoles",
    "Mota del Marques",
    "Motilla del Palancar",
    "Motril",
    "Moya",
    "Muchamiel",
    "Mugardos",
    "Mula",
    "Mungia",
    "Mura",
    "Murcia",
    "Muriedas",
    "Muro",
    "Muro",
    "Muro del Alcoy",
    "Muros",
    "Muros de Nalon",
    "Museros",
    "Mutiloa",
    "Mutilva Baja",
    "Mutriku",
    "Nalda",
    "Nambroca",
    "Nanclares de la Oca",
    "Naquera",
    "Nava de la Asuncion",
    "Navacerrada",
    "Navaconcejo",
    "Navahermosa",
    "Navalcan",
    "Navalcarnero",
    "Navalmoral de la Mata",
    "Navalperal de Pinares",
    "Navamorcuende",
    "Navarcles",
    "Navaridas",
    "Navarres",
    "Navarrete",
    "Navas de Riofrio",
    "Navas del Rey",
    "Navata",
    "Navatejera",
    "Navezuelas",
    "Navia",
    "Neda",
    "Neda",
    "Negreira",
    "Nembro",
    "Nerja",
    "Nerva",
    "Nestares",
    "Nigran",
    "Nijar",
    "Nino Perdido",
    "Nivar",
    "Noain",
    "Noja",
    "Nora",
    "Norena",
    "Novallas",
    "Novelda",
    "Noves",
    "Nubledo",
    "Nueva",
    "Nuevo Baztan",
    "Nuez de Ebro",
    "Nules",
    "Numancia de la Sagra",
    "O Barco de Valdeorras",
    "O Carballino",
    "O Grove",
    "O Mazo",
    "Oasis (La Marina)",
    "Obanos",
    "Ocana",
    "Odena",
    "Odon",
    "Ogijares",
    "Ojen",
    "Olaberria",
    "Olazagutia",
    "Oleiros",
    "Oleiros",
    "Olesa de Bonesvalls",
    "Olesa de Montserrat",
    "Oliana",
    "Olias del Rey",
    "Olite",
    "Oliva",
    "Oliva de Merida",
    "Oliva de Plasencia",
    "Olivares",
    "Olivella",
    "Olivenza",
    "Olmedo",
    "Olocau",
    "Olot",
    "Olula de Castro",
    "Olula del Rio",
    "Olvega",
    "Olvera",
    "Onda",
    "Ondara",
    "Ondarroa",
    "Onil",
    "Ontigola",
    "Ontinena",
    "Ontinyent",
    "Ontur",
    "Orce",
    "Orcoyen",
    "Ordes",
    "Ordis",
    "Orellana la Vieja",
    "Oria",
    "Orial",
    "Orihuela",
    "Orio",
    "Oroso",
    "Orpesa/Oropesa del Mar",
    "Ortiguero",
    "Oruna",
    "Orusco",
    "Osuna",
    "Otero",
    "Otura",
    "Ouces",
    "Ourense",
    "Ourense",
    "Outes",
    "Oviedo",
    "Oyon",
    "Oza de los Rios",
    "Padilla del Ducado",
    "Padul",
    "Paiporta",
    "Pajara",
    "Palafolls",
    "Palafrugell",
    "Palau",
    "Palencia",
    "Palma de Mallorca",
    "Palma del Rio",
    "Palmeira",
    "Palmera",
    "Palmones",
    "Palol",
    "Palomares del Rio",
    "Palos de la Frontera",
    "Pals",
    "Pamplona",
    "Panillo",
    "Panticosa",
    "Pantoja",
    "Para",
    "Paracuellos",
    "Paracuellos de Jarama",
    "Parada",
    "Paradas",
    "Paradela",
    "Parbayon",
    "Parcent",
    "Paredes de Nava",
    "Parla",
    "Parres",
    "Pasai San Pedro",
    "Pasaia",
    "Pastrana",
    "Paterna",
    "Paterna del Campo",
    "Pau",
    "Pauels",
    "Pedrajas",
    "Pedrajas de San Esteban",
    "Pedralba",
    "Pedreguer",
    "Pedrezuela",
    "Pedro",
    "Pedro Abad",
    "Pedro Munoz",
    "Pedrola",
    "Pedrosillo de Alba",
    "Pedrosillo el Ralo",
    "Pego",
    "Pelayos de la Presa",
    "Peligros",
    "Penafiel",
    "Penaflor",
    "Penagos",
    "Penarroya-Pueblonuevo",
    "Perafita",
    "Perafort",
    "Peralejo",
    "Perales del Alfambra",
    "Perales del Puerto",
    "Peratallada",
    "Perdiguera",
    "Pereiro de Aguiar",
    "Periedo",
    "Perillo",
    "Pescador",
    "Pescueza",
    "Petrel",
    "Petres",
    "Pezuela de las Torres",
    "Piedra",
    "Piedrabuena",
    "Piera",
    "Pilar de la Horadada",
    "Pilas",
    "Pina de Ebro",
    "Pinar",
    "Pineda de Mar",
    "Pinos del Valle",
    "Pinos Puente",
    "Pinoso",
    "Pinseque",
    "Pinto",
    "Pioz",
    "Placencia",
    "Plan",
    "Planes",
    "Plasencia",
    "Plasenzuela",
    "Playa",
    "Playa Blanca",
    "Plegamans",
    "Plentzia",
    "Poblete",
    "Poboa de San Xulian",
    "Poboleda",
    "Pola de Laviana",
    "Pola de Lena",
    "Pola de Siero",
    "Polan",
    "Polinya",
    "Polop",
    "Pomar",
    "Pomar de Cinca",
    "Ponferrada",
    "Pont de Molins",
    "Pontejos",
    "Pontevedra",
    "Pontos",
    "Ponts",
    "Poris de Abona",
    "Porrino",
    "Port de Sagunt",
    "Portillo",
    "Portillo de Toledo",
    "Porto",
    "Porto Cristo",
    "Portocolom",
    "Portonovo",
    "Portugalete",
    "Portus",
    "Posadas",
    "Posadas",
    "Pozo de Guadalajara",
    "Pozo de la Serna",
    "Pozoamargo",
    "Pozoblanco",
    "Pozuelo del Rey",
    "Pradejon",
    "Prado del Rey",
    "Pravia",
    "Premia de Mar",
    "Priego",
    "Priego de Cordoba",
    "Pruna",
    "Pruvia",
    "Pucol",
    "Puebla de Alfinden",
    "Puebla de Don Fadrique",
    "Puebla de la Calzada",
    "Puebla del Maestre",
    "Puente Viesgo",
    "Puente-Genil",
    "Puentelarra",
    "Puentenansa",
    "Puerto",
    "Puerto",
    "Puerto de Gandia",
    "Puerto de la Cruz",
    "Puerto del Carmen",
    "Puerto del Rosario",
    "Puerto Lumbreras",
    "Puerto Real",
    "Puertollano",
    "Pueyo",
    "Pueyo de Santa Cruz",
    "Puig",
    "Puigpelat",
    "Pulgar",
    "Pulianas",
    "Purchil",
    "Quart de Poblet",
    "Quel",
    "Quer",
    "Querol",
    "Quijorna",
    "Quincoces de Yuso",
    "Quintana de la Serena",
    "Quintana de Raneros",
    "Quintanar de la Orden",
    "Quintanar del Rey",
    "Quintes",
    "Rafal",
    "Rafelbunyol",
    "Rafelcofer",
    "Rafelguaraf",
    "Rairiz de Veiga",
    "Rajadell",
    "Ramales de la Victoria",
    "Rasines",
    "Real",
    "Real de Gandia",
    "Rebolledo",
    "Rebordanes",
    "Recas",
    "Redes",
    "Redondela",
    "Redovan",
    "Reinosa",
    "Rendar",
    "Renedo",
    "Renedo",
    "Reocin",
    "Requena",
    "Reus",
    "Revellinos",
    "Reyes",
    "Rianxo",
    "Riaza",
    "Riba",
    "Ribadavia",
    "Ribadeo",
    "Ribadesella",
    "Ribarroja",
    "Ribeira",
    "Ribera del Fresno",
    "Ribes de Freser",
    "Ricla",
    "Riells i Viabrea",
    "Rielves",
    "Rincon",
    "Rincon de la Victoria",
    "Rincon de Soto",
    "Rioja",
    "Riola",
    "Rios",
    "Ripoll",
    "Ripollet",
    "Riudellots de la Selva",
    "Riudoms",
    "Riumors",
    "Rivabellosa",
    "Roales",
    "Robleda-Cervantes",
    "Robledillo de Gata",
    "Robledillo de la Vera",
    "Robledo de Chavela",
    "Rocafort",
    "Roda de Bara",
    "Rodonya",
    "Rojales",
    "Roldan",
    "Ron",
    "Ronda",
    "Roquetas de Mar",
    "Rosell",
    "Roses",
    "Rota",
    "Rubielos de Mora",
    "Ruente",
    "Rupit",
    "Rus",
    "Rute",
    "Saamasas",
    "Sabadell",
    "Sabero",
    "Sabinanigo",
    "Sabiote",
    "Sabucedo",
    "Sada",
    "Sagra",
    "Sagunto",
    "Salamanca",
    "Salas de los Infantes",
    "Salceda",
    "Salcedo",
    "Saldana",
    "Salinas",
    "Salinas",
    "Sallent",
    "Salobral",
    "Salobre",
    "Salou",
    "Salt",
    "Salteras",
    "Salvatierra",
    "Salvatierra de los Barros",
    "Salvatierra de Mino",
    "San Adrian",
    "San Agustin de Guadalix",
    "San Andres del Rabanedo",
    "San Anton",
    "San Antonio",
    "San Blas",
    "San Clemente",
    "San Cristobal",
    "San Cristobal de Entrevinas",
    "San Esteban del Valle",
    "San Felices",
    "San Feliz de Torio",
    "San Fernando",
    "San Fernando",
    "San Fernando de Henares",
    "San Ildefonso",
    "San Isidro",
    "San Isidro",
    "San Javier",
    "San Jorge",
    "San Juan",
    "San Juan",
    "San Juan de Alicante",
    "San Juan de Aznalfarache",
    "San Juan de Moro",
    "San Juan del Puerto",
    "San Julian de Muskiz",
    "San Luis de Sabinillas",
    "San Martin",
    "San Martin de la Vega",
    "San Martin de Unx",
    "San Martin de Valdeiglesias",
    "San Martino",
    "San Miguel",
    "San Miguel",
    "San Miguel de las Duenas",
    "San Nicolas",
    "San Nicolas del Puerto",
    "San Pedro",
    "San Pedro",
    "San Pedro",
    "San Pedro",
    "San Pedro del Pinatar",
    "San Rafael",
    "San Roman",
    "San Roque",
    "San Sebastian",
    "San Sebastian de los Ballesteros",
    "San Vicent del Raspeig",
    "San Vicente",
    "San Vicente de Alcantara",
    "San Vicente de Leon",
    "Sancibrian",
    "Sancti Spiritus",
    "Sangonera la Verde",
    "Sanlucar la Mayor",
    "Sant Andreu de la Barca",
    "Sant Andreu de Llavaneres",
    "Sant Bartomeu del Grau",
    "Sant Carles de la Rapita",
    "Sant Feliu de Llobregat",
    "Sant Joan de les Abadesses",
    "Sant Joan de Vilatorrada",
    "Sant Joan les Fonts",
    "Sant Jordi",
    "Sant Josep de sa Talaia",
    "Sant Just Desvern",
    "Sant Marti de Malda",
    "Sant Pere de Ribes",
    "Sant Pere de Riudebitlles",
    "Sant Pere, Santa Caterina i La Ribera",
    "Sant Vicenc de Castellet",
    "Santa Barbara",
    "Santa Brigida",
    "Santa Coloma de Farners",
    "Santa Coloma de Gramenet",
    "Santa Coloma de Queralt",
    "Santa Cristina",
    "Santa Cristina de Aro",
    "Santa Cruz",
    "Santa Cruz de Bezana",
    "Santa Cruz de La Palma",
    "Santa Cruz de la Zarza",
    "Santa Cruz de Mudela",
    "Santa Cruz de Tenerife",
    "Santa Eulalia",
    "Santa Eulalia",
    "Santa Eulalia Bajera",
    "Santa Fe",
    "Santa Fe de Mondujar",
    "Santa Lucia",
    "Santa Lucia",
    "Santa Margarida",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria de Palautordera",
    "Santa Maria del Cami",
    "Santa Maria del Campo",
    "Santa Maria del Paramo",
    "Santa Marina del Rey",
    "Santa Marta de Ortigueira",
    "Santa Marta de Tormes",
    "Santa Olalla",
    "Santa Oliva",
    "Santa Pau",
    "Santa Perpetua de Mogoda",
    "Santa Pola",
    "Santa Uxia de Ribeira",
    "Santalla",
    "Santander",
    "Santiago",
    "Santiago de Cartes",
    "Santiago de Compostela",
    "Santiago de la Ribera",
    "Santiago Pena",
    "Santiponce",
    "Santisteban del Puerto",
    "Santiurde de Toranzo",
    "Santo Angel",
    "Santo Domingo",
    "Santo Domingo de la Calzada",
    "Santomera",
    "Santona",
    "Santovenia de Pisuerga",
    "Santpedor",
    "Santurtzi",
    "Sarandones",
    "Sardina",
    "Sarinena",
    "Saron",
    "Sarria",
    "Sarria",
    "Sarria de Ter",
    "Sarroca de Lleida",
    "Sax",
    "Sayalonga",
    "Sedavi",
    "Segorbe",
    "Segovia",
    "Segovia",
    "Segura",
    "Segura",
    "Segura de la Sierra",
    "Selaya",
    "Selva",
    "Senija",
    "Seoane",
    "Sepulveda",
    "Serena",
    "Serra",
    "Serracines",
    "Serranillos del Valle",
    "Serrateix",
    "ses Salines",
    "Sesena",
    "Sesena Nuevo",
    "Sestao",
    "Seva",
    "Sevares",
    "Sevilla La Nueva",
    "Seville",
    "Sierra",
    "Sierra de Fuentes",
    "Sierra de Luna",
    "Siete Iglesias de Trabancos",
    "Sigueiro",
    "Siguenza",
    "Silla",
    "Silleda",
    "Sils",
    "Simancas",
    "Sitges",
    "Sixto",
    "Sobarzo",
    "Sobradiel",
    "Sobremazas",
    "Socuellamos",
    "Sodupe",
    "Sojuela",
    "Solares",
    "Sollana",
    "Soller",
    "Solsona",
    "Somahoz",
    "Somo",
    "Son Curt",
    "Son Servera",
    "Soneja",
    "Sopela",
    "Sorbas",
    "Soria",
    "Sotillo de la Adrada",
    "Soto",
    "Soto de Cerrato",
    "Soto de la Marina",
    "Sotogrande",
    "Sotopalacios",
    "Sotrondio",
    "Suances",
    "Subirats",
    "Sudanell",
    "Sueca",
    "Sufli",
    "Tabernas",
    "Taboada",
    "Tacoronte",
    "Tafalla",
    "Tahiche",
    "Talamanca de Jarama",
    "Talarn",
    "Talavera de la Reina",
    "Tamames",
    "Tamaraceite",
    "Taradell",
    "Tarancon",
    "Tarazona",
    "Tarazona de la Mancha",
    "Tarifa",
    "Tarragona",
    "Tarrega",
    "Teba",
    "Tegueste",
    "Teguise",
    "Tejina",
    "Telde",
    "Tenteniguada",
    "Teo",
    "Termino",
    "Teror",
    "Terradillos",
    "Terrassa",
    "Terrassa",
    "Terrer",
    "Teruel",
    "Teulada",
    "Tiagua",
    "Tiana",
    "Tias",
    "Tigaiga",
    "Tijarafe",
    "Tijola",
    "Tineo",
    "Titulcia",
    "Tobarra",
    "Toledo",
    "Tolosa",
    "Tomares",
    "Tombrio de Arriba",
    "Tomelloso",
    "Tomino",
    "Tona",
    "Tora de Riubregos",
    "Toral de los Vados",
    "Tordera",
    "Tordesillas",
    "Tordillos",
    "Toreno",
    "Torija",
    "Tormantos",
    "Toro",
    "Torralba de Calatrava",
    "Torralba de Oropesa",
    "Torre del Campo",
    "Torre del Mar",
    "Torre-Pacheco",
    "Torreaguera",
    "Torreblascopedro",
    "Torrecaballeros",
    "Torrecilla de Alcaniz",
    "Torredembarra",
    "Torredonjimeno",
    "Torrefarrera",
    "Torrefeta",
    "Torrejon de la Calzada",
    "Torrejon del Rey",
    "Torrelaguna",
    "Torrelavega",
    "Torrelles de Llobregat",
    "Torrelletas",
    "Torrelodones",
    "Torremenga",
    "Torremocha del Campo",
    "Torremolinos",
    "Torrent",
    "Torrent",
    "Torrenueva",
    "Torreperogil",
    "Torres",
    "Torres de Berrellen",
    "Torres de la Alameda",
    "Torres de Segre",
    "Torresandino",
    "Torrevieja",
    "Torrijos",
    "Torroella de Fluvia",
    "Torroella de Montgri",
    "Torrox",
    "Tortella",
    "Tortola de Henares",
    "Tortosa",
    "Totana",
    "Touro",
    "Tous",
    "Trabada",
    "Traiguera",
    "Traslavina",
    "Trasona",
    "Trazo",
    "Trebujena",
    "Tremp",
    "Trespaderne",
    "Trevino",
    "Triana",
    "Trillo",
    "Trives",
    "Trobajo del Camino",
    "Trujillanos",
    "Trujillo",
    "Tudela",
    "Tudela de Duero",
    "Tuineje",
    "Turia",
    "Turis",
    "Ubrique",
    "Uceda",
    "Ugena",
    "Ugijar",
    "Ujo",
    "Ullastrell",
    "Umbrete",
    "Urda",
    "Urdiain",
    "Urduna",
    "Urnieta",
    "Urroz",
    "Urtuella",
    "Usansolo",
    "Useras",
    "Usurbil",
    "Utebo",
    "Utiel",
    "Utrera",
    "Vaciamadrid",
    "Val de Santo Domingo",
    "Valcabado",
    "Valdeavero",
    "Valdeaveruelo",
    "Valdecabras",
    "Valdecilla",
    "Valdemaqueda",
    "Valdemorillo",
    "Valdemoro",
    "Valdepenas",
    "Valdepenas de la Sierra",
    "Valdepolo",
    "Valderrey",
    "Valderrobres",
    "Valderrubio",
    "Valdesogo de Abajo",
    "Valdetorres",
    "Valdetorres de Jarama",
    "Valdezufre",
    "Valdilecha",
    "Valencia",
    "Valencia",
    "Valencia de Don Juan",
    "Valencina de la Concepcion",
    "Vall de Almonacid",
    "Vallada",
    "Valladolid",
    "Valladolises",
    "Valldoreix",
    "Valle",
    "Valle de Oro",
    "Valles",
    "Valleseco",
    "Vallgorguina",
    "Vallirana",
    "Vallmoll",
    "Valls",
    "Valmojado",
    "Valpalmas",
    "Valsequillo de Gran Canaria",
    "Valverde",
    "Valverde",
    "Valverde del Camino",
    "Valverde del Fresno",
    "Valverde del Majano",
    "Vedra",
    "Vejer de la Frontera",
    "Velez de Benaudalla",
    "Velez-Rubio",
    "Velilla",
    "Velilla de San Antonio",
    "Venta",
    "Venta de Banos",
    "Ventallo",
    "Venturada",
    "Vera",
    "Verdu",
    "Vergel",
    "Verges",
    "Verin",
    "Viana",
    "Viator",
    "Vic",
    "Vicar",
    "Vicolozano",
    "Vigo",
    "Vila",
    "Vila-real",
    "Vila-seca",
    "Vilabertran",
    "Vilacolum",
    "Viladecans",
    "Viladrau",
    "Vilafant",
    "Vilagarcia de Arousa",
    "Vilajuiga",
    "Vilamalla",
    "Vilamaniscle",
    "Vilanova",
    "Vilanova de Arousa",
    "Vilaplana",
    "Vilasantar",
    "Vilassar de Mar",
    "Vilatenim",
    "Vilavella",
    "Vilches",
    "Villa",
    "Villa de Don Fadrique",
    "Villa del Prado",
    "Villa del Rio",
    "Villabalter",
    "Villabanez",
    "Villablino",
    "Villabona",
    "Villacanas",
    "Villacarriedo",
    "Villacarrillo",
    "Villacastin",
    "Villacondide",
    "Villaconejos",
    "Villacuende",
    "Villafranca",
    "Villafranca",
    "Villafranca de Cordoba",
    "Villafranca de los Barros",
    "Villafranca de los Caballeros",
    "Villafranca del Campo",
    "Villafranqueza",
    "Villahermosa",
    "Villajoyosa",
    "Villalar de los Comuneros",
    "Villalba de los Alcores",
    "Villalbilla",
    "Villalonga",
    "Villalpardo",
    "Villaluenga",
    "Villamalea",
    "Villamanrique",
    "Villamanrique de la Condesa",
    "Villamanta",
    "Villamartin",
    "Villamayor",
    "Villamayor",
    "Villamayor",
    "Villamayor de Santiago",
    "Villamediana de Iregua",
    "Villamiel de Toledo",
    "Villamuriel de Cerrato",
    "Villanubla",
    "Villanueva",
    "Villanueva de Azoague",
    "Villanueva de Castellon",
    "Villanueva de Cordoba",
    "Villanueva de Gallego",
    "Villanueva de Gomez",
    "Villanueva de la Canada",
    "Villanueva de la Jara",
    "Villanueva de la Reina",
    "Villanueva de la Serena",
    "Villanueva de la Torre",
    "Villanueva de Perales",
    "Villanueva de Villaescusa",
    "Villanueva del Ariscal",
    "Villanueva del Arzobispo",
    "Villanueva del Fresno",
    "Villanueva del Pardillo",
    "Villanueva del Rio",
    "Villanueva del Rosario",
    "Villaquilambre",
    "Villar",
    "Villar de Gallimazo",
    "Villar de Olalla",
    "Villar del Arzobispo",
    "Villaralbo",
    "Villarcayo",
    "Villarejo",
    "Villares de la Reina",
    "Villares del Saz",
    "Villaricos",
    "Villarluengo",
    "Villarquemado",
    "Villarreal de Alava",
    "Villarrobledo",
    "Villarrubia",
    "Villarrubia de los Ojos",
    "Villarrubia de Santiago",
    "Villarta de San Juan",
    "Villasana de Mena",
    "Villaseca de la Sagra",
    "Villasequilla de Yepes",
    "Villasevil",
    "Villatuerta",
    "Villaverde de los Cestos",
    "Villaverde del Rio",
    "Villaviad",
    "Villaviciosa",
    "Villaviciosa",
    "Villaviciosa de Odon",
    "Villena",
    "Vinaixa",
    "Vinales",
    "Vinalesa",
    "Vinaros",
    "Vitigudino",
    "Vitoria-Gasteiz",
    "Viveda",
    "Viveiro",
    "Vivero",
    "Xunqueira de Espadanedo",
    "Yaiza",
    "Yebes",
    "Yecla",
    "Yeles",
    "Yepes",
    "Yuncler",
    "Yuncos",
    "Yunquera de Henares",
    "Zafra",
    "Zahara de los Atunes",
    "Zahinos",
    "Zalamea la Real",
    "Zamora",
    "Zamudio",
    "Zaragoza",
    "Zaramillo",
    "Zaratamo",
    "Zaratan",
    "Zarautz",
    "Zarzalejo",
    "Zarzuela",
    "Ziga",
    "Zorraquin",
    "Zuazo de Vitoria",
    "Zubia",
    "Zubiri",
    "Zuera",
    "Zumaia",
    "Zumarraga",
  ],
  "Sri Lanka": [
    "Badulla",
    "Battaramulla South",
    "Biyagama",
    "Boralesgamuwa South",
    "Colombo",
    "Dehiwala",
    "Dehiwala-Mount Lavinia",
    "Eppawala",
    "Gampaha",
    "Gangodawila North",
    "Hekitta",
    "Homagama",
    "Kaduwela",
    "Kandana",
    "Kandy",
    "Katubedda",
    "Katunayaka",
    "Kelaniya",
    "Kohuwala",
    "Maharagama",
    "Makola South",
    "Matale",
    "Moratuwa",
    "Nattandiya Town",
    "Negombo",
    "Nugegoda",
    "Orugodawatta",
    "Padukka",
    "Pannipitiya",
    "Peradeniya",
    "Piliyandala",
    "Ragama",
    "Rajagiriya",
    "Sri Jayewardenepura Kotte",
    "Talapathpitiya",
    "Wattala",
    "Wellampitiya",
  ],
  Sudan: ["Kassala", "Khartoum", "Nyala", "Shendi", "Thabit", "Umm Durman"],
  Suriname: [
    "Botopasi",
    "Brownsweg",
    "Friendship",
    "Groningen",
    "Moengo",
    "Nieuw Amsterdam",
    "Onverwacht",
    "Paramaribo",
    "Totness",
  ],
  Swaziland: ["Kwaluseni", "Lobamba", "Manzini", "Mbabane", "Piggs Peak"],
  Sweden: [
    "Aby",
    "AElmhult",
    "AElvdalen",
    "AElvkarleby",
    "AElvsbyn",
    "Agnesberg",
    "Agunnaryd",
    "Akarp",
    "Akers Styckebruk",
    "Akersberga",
    "Alafors",
    "Alandsbro",
    "Aled",
    "Alem",
    "Alfta",
    "Alingsas",
    "Allerum",
    "Almeboda",
    "Almunge",
    "Alno",
    "Alsen",
    "Alsterbro",
    "Alstermo",
    "Alta",
    "Altersbruk",
    "Alunda",
    "Alunda",
    "Alvangen",
    "Alvesta",
    "Alvsjo",
    "Amal",
    "Ambjornarp",
    "Amotfors",
    "Anderslov",
    "Anderstorp",
    "Anderstorp",
    "Aneby",
    "Ange",
    "Angered",
    "Ankarsrum",
    "Ankarsvik",
    "Annelov",
    "Annerstad",
    "Ansvar",
    "Aplared",
    "Arboga",
    "Arbra",
    "Arholma",
    "Arjaeng",
    "Arjeplog",
    "Arkelstorp",
    "Arla",
    "Arloev",
    "Arnasvall",
    "Arsunda",
    "Arvidsjaur",
    "Arvika",
    "Aryd",
    "As",
    "Asa",
    "Asarna",
    "Asarum",
    "Asbro",
    "Aseda",
    "Asele",
    "Asenhoga",
    "Asensbruk",
    "Ashammar",
    "Askersund",
    "Askim",
    "Askloster",
    "Asmundtorp",
    "Aspas",
    "Aspered",
    "Aspero",
    "Astorp",
    "Atorp",
    "Atran",
    "Atvidaberg",
    "Avesta",
    "Axvall",
    "Backa",
    "Backaryd",
    "Backe",
    "Backefors",
    "Balinge",
    "Ballingslov",
    "Balsta",
    "Bankeryd",
    "Bara",
    "Barkarby",
    "Barseback",
    "Barsebackshamn",
    "Bastad",
    "Beddinge Lage",
    "Bengtsfors",
    "Berga",
    "Bergeforsen",
    "Bergkvara",
    "Bergshamra",
    "Bergsjoe",
    "Bergvik",
    "Bettna",
    "Billdal",
    "Billeberga",
    "Billesholm",
    "Billinge",
    "Billingsfors",
    "Bjarnum",
    "Bjarred",
    "Bjartra",
    "Bjasta",
    "Bjorbo",
    "Bjoringe",
    "Bjorkhaga",
    "Bjorklinge",
    "Bjorkoby",
    "Bjorkvik",
    "Bjorna",
    "Bjorneborg",
    "Bjornlunda",
    "Bjuraker",
    "Bjurholm",
    "Bjursas",
    "Bjuv",
    "Blackstad",
    "Blasmark",
    "Blattnicksele",
    "Blentarp",
    "Bockara",
    "Bodafors",
    "Boden",
    "Bofors",
    "Bohus",
    "Boliden",
    "Bollebygd",
    "Bollnas",
    "Bollstabruk",
    "Bor",
    "Borensberg",
    "Borghamn",
    "Borgholm",
    "Borgstena",
    "Borrby",
    "Botsmark",
    "Bottnaryd",
    "Boxholm",
    "Braas",
    "Braecke",
    "Brakne-Hoby",
    "Bralanda",
    "Bramhult",
    "Branno",
    "Brastad",
    "Bredared",
    "Bredaryd",
    "Bro",
    "Broby",
    "Brokind",
    "Bromma",
    "Bromoella",
    "Brosarp",
    "Brottby",
    "Brunflo",
    "Brunnby",
    "Bua",
    "Bullmark",
    "Bunkeflostrand",
    "Burea",
    "Burtraesk",
    "Bygdea",
    "Bygdsiljum",
    "Byske",
    "Byske",
    "Charlottenberg",
    "Dala-Floda",
    "Dala-Jarna",
    "Dalaro",
    "Dalby",
    "Dals Langed",
    "Dals Rostock",
    "Dalsjofors",
    "Dalstorp",
    "Danderyd",
    "Degeberga",
    "Degerfors",
    "Deje",
    "Delsbo",
    "Dikanaes",
    "Dingle",
    "Dio",
    "Djura",
    "Djuras",
    "Djurhamn",
    "Djursholm",
    "Domsjo",
    "Donso",
    "Dorotea",
    "Dosjebro",
    "Drottningholm",
    "Duved",
    "Dyltabruk",
    "Ed",
    "Edsbro",
    "Edsbyn",
    "Edsele",
    "Edshultshall",
    "Edsvalla",
    "Ekeby",
    "Ekenassjon",
    "Ekshaerad",
    "Eksjoe",
    "Eldsberga",
    "Elloes",
    "Emmaboda",
    "Enanger",
    "Enebyberg",
    "Eneryda",
    "Enkoping",
    "Enskede-Arsta-Vantoer",
    "Enviken",
    "Erikslund",
    "Eriksmala",
    "Eskilstuna",
    "Eslov",
    "Faergelanda",
    "Faerjestaden",
    "Fagelfors",
    "Fageras",
    "Fagerberg",
    "Fagered",
    "Fagerhult",
    "Fagersanna",
    "Fagersta",
    "Fagervik",
    "Falkenberg",
    "Fallfors",
    "Falun",
    "Farbo",
    "Farila",
    "Farlov",
    "Farna",
    "Farnas",
    "Faro",
    "Fellingsbro",
    "Fengersfors",
    "Figeholm",
    "Filipstad",
    "Finja",
    "Finnerodja",
    "Finspang",
    "Fiskeby",
    "Fjaeras station",
    "Fjalkinge",
    "Fjardhundra",
    "Fjugesta",
    "Flen",
    "Fleninge",
    "Flisby",
    "Floby",
    "Floda",
    "Flyinge",
    "Follinge",
    "Follinge",
    "Fors",
    "Forsa",
    "Forsbacka",
    "Forsby",
    "Forserum",
    "Forshaga",
    "Forsheda",
    "Forslov",
    "Frandefors",
    "Fransta",
    "Fredriksberg",
    "Frillesas",
    "Fristad",
    "Fritsla",
    "Froso",
    "Frovi",
    "Frufallan",
    "Furulund",
    "Furuvik",
    "Gabo",
    "Gagnef",
    "Gallo",
    "Gallstad",
    "Gamla stan",
    "Gamleby",
    "Gammelstad",
    "Ganghester",
    "Gards Kopinge",
    "Gargnas",
    "Garpenberg",
    "Garphyttan",
    "Garsas",
    "Garsnas",
    "Gemla",
    "Genarp",
    "Genevad",
    "Getinge",
    "Gidea",
    "Gimo",
    "Gislaved",
    "Glanshammar",
    "Glimakra",
    "Glommen",
    "Glommerstrask",
    "Glostorp",
    "Glumslov",
    "Gnarp",
    "Gnesta",
    "Gnosjoe",
    "Goetene",
    "Gota",
    "Gothenburg",
    "Grabo",
    "Graddo",
    "Graestorp",
    "Grangarde",
    "Grangesberg",
    "Grasmyr",
    "Graso",
    "Grebbestad",
    "Grillby",
    "Grimeton",
    "Grimsas",
    "Grimslov",
    "Grimstorp",
    "Grisslehamn",
    "Grondal",
    "Gronskara",
    "Grums",
    "Grundsund",
    "Grycksbo",
    "Gryt",
    "Grythyttan",
    "Gualov",
    "Guldsmedshyttan",
    "Gullbrandstorp",
    "Gullspang",
    "Gunnebo",
    "Gunnilse",
    "Gusselby",
    "Gustafs",
    "Gustavsberg",
    "Gusum",
    "Gyttorp",
    "Hackas",
    "Hacksvik",
    "Haegersten",
    "Haellefors",
    "Haerryda",
    "Haesselby",
    "Hagfors",
    "Hagglund",
    "Hajom",
    "Hakkas",
    "Haknas",
    "Haljarp",
    "Hallabro",
    "Halland",
    "Hallarod",
    "Hallberg",
    "Halleforsnas",
    "Hallekis",
    "Hallestad",
    "Hallestad",
    "Hallevadsholm",
    "Hallingsjo",
    "Hallsberg",
    "Hallsta",
    "Hallstahammar",
    "Hallstavik",
    "Halmstad",
    "Halso",
    "Halta",
    "Hamburgsund",
    "Hammar",
    "Hammaro",
    "Hammarstrand",
    "Hammenhog",
    "Hammerdal",
    "Hamneda",
    "Hamrangefjarden",
    "Hanaskog",
    "Handen",
    "Haparanda",
    "Harads",
    "Harbo",
    "Harby",
    "Harestad",
    "Harmanger",
    "Harplinge",
    "Harslov",
    "Hasselby",
    "Hasselfors",
    "Hasslarp",
    "Hasslo",
    "Hastveda",
    "Havdhem",
    "Hebo",
    "Hedared",
    "Hede",
    "Hedekas",
    "Hedemora",
    "Hedesunda",
    "Helgum",
    "Helsingborg",
    "Hemmingsmark",
    "Hemse",
    "Henan",
    "Herrangen",
    "Herrljunga",
    "Hestra",
    "Hillared",
    "Hillerstorp",
    "Hindas",
    "Hjaerup",
    "Hjalteby",
    "Hjarnarp",
    "Hjartum",
    "Hjo",
    "Hoegsby",
    "Hoeoer",
    "Hoerby",
    "Hoernefors",
    "Hofors",
    "Hoganas",
    "Hogsaters",
    "Hogsjo",
    "Hok",
    "Hokerum",
    "Hollviken",
    "Holmen",
    "Holmsjo",
    "Holmsund",
    "Holmsveden",
    "Holo",
    "Holsbybrunn",
    "Holsljunga",
    "Hono",
    "Horn",
    "Horndal",
    "Horred",
    "Hortlax",
    "Hova",
    "Hovas",
    "Hoviksnas",
    "Hovmantorp",
    "Huarod",
    "Huddinge",
    "Hudiksvall",
    "Hultafors",
    "Hultsfred",
    "Hunnebostrand",
    "Hunnestad",
    "Huskvarna",
    "Husum",
    "Hyllinge",
    "Hyltebruk",
    "Hyssna",
    "Iggesund",
    "Immeln",
    "Indal",
    "Ingarp",
    "Ingelstad",
    "Ingmarso",
    "Insjon",
    "Jaemjoe",
    "Jaervsoe",
    "Jamshog",
    "Jarbo",
    "Jarlasa",
    "Jarna",
    "Jarpen",
    "Jarved",
    "Joern",
    "Johanneshov",
    "Johannishus",
    "Jokkmokk",
    "Jonaker",
    "Jonsered",
    "Jordbro",
    "Jorlanda",
    "Jude",
    "Junosuando",
    "Kaevlinge",
    "Kage",
    "Kagerod",
    "Kalix",
    "Kallby",
    "Kallered",
    "Kallinge",
    "Kallo",
    "Kalmar",
    "Kareby",
    "Karesuando",
    "Karl Gustav",
    "Karlsborg",
    "Karlshamn",
    "Karlskoga",
    "Karlskrona",
    "Karlstad",
    "Karna",
    "Karra",
    "Katrineholm",
    "Kattarp",
    "Katthammarsvik",
    "Kattilstorp",
    "Kil",
    "Kilafors",
    "Killeberg",
    "Kimstad",
    "Kinna",
    "Kinnared",
    "Kinnarp",
    "Kiruna",
    "Kisa",
    "Kista",
    "Kivik",
    "Kladesholmen",
    "Klagerup",
    "Klagshamn",
    "Klagstorp",
    "Klassbol",
    "Klavrestrom",
    "Klintehamn",
    "Klippan",
    "Klovedal",
    "Knared",
    "Knislinge",
    "Knivsta",
    "Knivsta",
    "Knutby",
    "Kode",
    "Kolback",
    "Kolmarden",
    "Kolsva",
    "Konga",
    "Koping",
    "Kopingebro",
    "Kopmanholmen",
    "Kopparberg",
    "Koppom",
    "Korpilombolo",
    "Korsberga",
    "Korskrogen",
    "Koskullskulle",
    "Kosta",
    "Kramfors",
    "Kristdala",
    "Kristianstad",
    "Kristineberg",
    "Kristinehamn",
    "Krokom",
    "Krylbo",
    "Kulla",
    "Kullavik",
    "Kulltorp",
    "Kumla",
    "Kungsaengen",
    "Kungsbacka",
    "Kungsgarden",
    "Kungshamn",
    "Kungsoer",
    "Kvanum",
    "Kvibille",
    "Kvicksund",
    "Kvidinge",
    "Kvissleby",
    "Kyrkobyn",
    "Lagan",
    "Laholm",
    "Lammhult",
    "Landeryd",
    "Landfjarden",
    "Landsbro",
    "Landskrona",
    "Landvetter",
    "Langas",
    "Langasjo",
    "Langhem",
    "Langsele",
    "Langshyttan",
    "Langviksmon",
    "Lanna",
    "Lannavaara",
    "Larbro",
    "Larv",
    "Lavsjo",
    "Laxa",
    "Lekeryd",
    "Leksand",
    "Lenhovda",
    "Lerberget",
    "Lerdala",
    "Lerum",
    "Lessebo",
    "Liden",
    "Lidhult",
    "Lidingoe",
    "Lidkoping",
    "Likenas",
    "Lilla Edet",
    "Lillpite",
    "Lima",
    "Limhamn",
    "Limmared",
    "Lindas",
    "Lindesberg",
    "Lindome",
    "Linghem",
    "Linneryd",
    "Listerby",
    "Lit",
    "Ljugarn",
    "Ljung",
    "Ljungaverk",
    "Ljungby",
    "Ljungbyhed",
    "Ljungbyholm",
    "Ljungsarp",
    "Ljungsbro",
    "Ljungskile",
    "Ljusdal",
    "Ljusfallshammar",
    "Ljusne",
    "Ljustero",
    "Ljustorp",
    "Loberod",
    "Loddekopinge",
    "Loderup",
    "Lodose",
    "Lomma",
    "Lonashult",
    "Lonsboda",
    "Los",
    "Lotorp",
    "Lottorp",
    "Lovanger",
    "Lovestad",
    "Lovikka",
    "Ludvigsborg",
    "Ludvika",
    "Lugnvik",
    "Lund",
    "Lycke",
    "Lyckeby",
    "Lycksele",
    "Lysekil",
    "Lysvik",
    "Mala",
    "Maleras",
    "Malilla",
    "Malma",
    "Malma",
    "Malmback",
    "Malmberget",
    "Malmkoping",
    "Malmo",
    "Malsryd",
    "Malung",
    "Mankarbo",
    "Mansarp",
    "Mantorp",
    "Marback",
    "Mardaklev",
    "Mariannelund",
    "Mariefred",
    "Marieholm",
    "Marieholm",
    "Mariestad",
    "Markaryd",
    "Marma",
    "Marsta",
    "Marstrand",
    "Matfors",
    "Mellansel",
    "Mellbystrand",
    "Mellerud",
    "Mjallom",
    "Mjolby",
    "Mockfjard",
    "Moelle",
    "Moelndal",
    "Moensteras",
    "Moerbylanga",
    "Moheda",
    "Moholm",
    "Moliden",
    "Molkom",
    "Molltorp",
    "Molnbo",
    "Molnlycke",
    "Mora",
    "Morarp",
    "Morgongava",
    "Morlunda",
    "Morrum",
    "Morsil",
    "Morup",
    "Motala",
    "Mullhyttan",
    "Mullsjoe",
    "Munka-Ljungby",
    "Munkedal",
    "Munkfors",
    "Musko",
    "Myggenas",
    "Myresjo",
    "Nacka",
    "Nalden",
    "Nashulta",
    "Nassjo",
    "Nasum",
    "Nasviken",
    "Nattraby",
    "Navekvarn",
    "Navlinge",
    "Nissafors",
    "Njurundabommen",
    "Njutanger",
    "Nodinge",
    "Nodinge-Nol",
    "Nora",
    "Norberg",
    "Nordingra",
    "Nordmaling",
    "Norrahammar",
    "Norrala",
    "Norrfjarden",
    "Norrhult",
    "Norrsundet",
    "Norrtalje",
    "Norsborg",
    "Norsholm",
    "Norsjoe",
    "Nossebro",
    "Nybro",
    "Nygard",
    "Nyhammar",
    "Nyhamnslage",
    "Nykil",
    "Nykroppa",
    "Nykvarn",
    "Nyland",
    "Nyland",
    "Nynas",
    "Nynashamn",
    "Nysater",
    "Nyvang",
    "Obbola",
    "Ockelbo",
    "Odakra",
    "Odeborg",
    "Odensbacken",
    "OEckeroe",
    "OEdeshoeg",
    "OErkelljunga",
    "OEsterbymo",
    "OEstervala",
    "OEsthammar",
    "OEverkalix",
    "OEvertornea",
    "Ojebyn",
    "Ojersjo",
    "Olofstorp",
    "Olofstroem",
    "Olsfors",
    "Onnestad",
    "Orby",
    "Orbyhus",
    "Oregrund",
    "Ormaryd",
    "Orno",
    "Ornskoldsvik",
    "Orrefors",
    "Orsa",
    "Orsundsbro",
    "Orviken",
    "Osby",
    "Oskarshamn",
    "Oskarstrom",
    "Osmo",
    "Ostansjo",
    "Osterbybruk",
    "Osterbybruk",
    "Osterhaninge",
    "Osterskar",
    "Ostra",
    "Ostra Frolunda",
    "Ostra Hindalebyn",
    "Ostra Karup",
    "Ostra Ryd",
    "Otterbacken",
    "Ovanaker",
    "Overhornas",
    "Overkovland",
    "Overlida",
    "Overum",
    "Oviken",
    "Oxaback",
    "Oxelosund",
    "Oxie",
    "Paarp",
    "Pajala",
    "Palange",
    "Palsboda",
    "Partille",
    "Paskallavik",
    "Perstorp",
    "Pilgrimstad",
    "Pixbo",
    "Raa",
    "Raettvik",
    "Ramdala",
    "Ramnas",
    "Ramsele",
    "Ramsjo",
    "Ramvik",
    "Ranea",
    "Rangedala",
    "Ransater",
    "Ransta",
    "Ravlanda",
    "Reftele",
    "Rejmyre",
    "Rekarne",
    "Rengsjo",
    "Reso",
    "Restad",
    "Riddarhyttan",
    "Rimbo",
    "Rimforsa",
    "Ring",
    "Ringarum",
    "Rinkaby",
    "Roback",
    "Robertsfors",
    "Rockneby",
    "Rodeby",
    "Roke",
    "Rolfstorp",
    "Romakloster",
    "Romelanda",
    "Ronnang",
    "Ronneby",
    "Ronninge",
    "Roro",
    "Rorvik",
    "Rosersberg",
    "Rosson",
    "Rostanga",
    "Rosvik",
    "Rotebro",
    "Rottne",
    "Ruda",
    "Rundvik",
    "Ryd",
    "Rydaholm",
    "Rydboholm",
    "Rydeback",
    "Rydobruk",
    "Rydsgard",
    "Ryssby",
    "Saeffle",
    "Saeter",
    "Saevar",
    "Saevast",
    "Saevsjoe",
    "Sagmyra",
    "Sala",
    "Saltsjo-Duvnas",
    "Saltsjobaden",
    "Sand",
    "Sandared",
    "Sandarne",
    "Sandby",
    "Sandhem",
    "Sandhult",
    "Sandviken",
    "Sankt Olof",
    "Saro",
    "Satila",
    "Savedalen",
    "Savsjostrom",
    "Saxtorp",
    "Segersta",
    "Seglora",
    "Segmon",
    "Sennan",
    "Sexdrega",
    "Sibbhult",
    "Sidensjo",
    "Sifferbo",
    "Sigtuna",
    "Siljansnas",
    "Simlangsdalen",
    "Simrishamn",
    "Sjalevad",
    "Sjoebo",
    "Sjomarken",
    "Sjotofta",
    "Sjovik",
    "Sjuntorp",
    "Ska",
    "Skaerhamn",
    "Skaerholmen",
    "Skallinge",
    "Skane",
    "Skanes Fagerhult",
    "Skanninge",
    "Skanor med Falsterbo",
    "Skara",
    "Skaraborg",
    "Skarblacka",
    "Skarplinge",
    "Skarpnaeck",
    "Skattkarr",
    "Skee",
    "Skelleftehamn",
    "Skene",
    "Skepplanda",
    "Skillingaryd",
    "Skillinge",
    "Skinnskatteberg",
    "Skivarp",
    "Skogas",
    "Skoghall",
    "Skogstorp",
    "Skoldinge",
    "Skollersta",
    "Skondal",
    "Skruv",
    "Skultuna",
    "Skummeslovsstrand",
    "Skurup",
    "Skyllberg",
    "Skyttorp",
    "Slite",
    "Sloinge",
    "Slottsbron",
    "Slutarp",
    "Smalandsstenar",
    "Smedjebacken",
    "Smedstorp",
    "Smoegen",
    "Soderakra",
    "Soderala",
    "Soderby",
    "Soderfors",
    "Sodergard",
    "Soderhamn",
    "Sodra Sandby",
    "Sodra Sunderbyn",
    "Sodra Vi",
    "Soederbaerke",
    "Soederkoeping",
    "Sollebrunn",
    "Solleftea",
    "Sollentuna",
    "Solna",
    "Sommen",
    "Soraker",
    "Sorberge",
    "Sorombacken",
    "Sorsele",
    "Sosdala",
    "Sovde",
    "Spanga",
    "Sparsor",
    "Spekerod",
    "Staffanstorp",
    "Stallarholmen",
    "Stalldalen",
    "Stanga",
    "Stehag",
    "Stenhamra",
    "Stenkullen",
    "Stensele",
    "Stenstorp",
    "Stenungsund",
    "Stigen",
    "Stigtomta",
    "Stjarnhov",
    "Stoby",
    "Stockaryd",
    "Stockholm",
    "Stocksund",
    "Stode",
    "Stollet",
    "Stora",
    "Stora Hoga",
    "Stora Mellosa",
    "Stora Skedvi",
    "Stora Vika",
    "Storebro",
    "Storfors",
    "Storlien",
    "Storuman",
    "Storvik",
    "Storvreta",
    "Stra",
    "Strangnas",
    "Stratjara",
    "Stravalla",
    "Stroemstad",
    "Stroemsund",
    "Stromsbruk",
    "Stromsholm",
    "Stromsnasbruk",
    "Strovelstorp",
    "Stugun",
    "Sturefors",
    "Sturko",
    "Styrso",
    "Sundborn",
    "Sundbyberg",
    "Sundsbruk",
    "Sundsvall",
    "Sunnansjo",
    "Sunne",
    "Sunnemo",
    "Surahammar",
    "Surte",
    "Svaloev",
    "Svaneholm",
    "Svaneholm",
    "Svanesund",
    "Svangsta",
    "Svardsjo",
    "Svarta",
    "Svartinge",
    "Svartsjo",
    "Svedala",
    "Sveg",
    "Svenljunga",
    "Svensbyn",
    "Svenshogen",
    "Svenstavik",
    "Taberg",
    "Taby",
    "Taenndalen",
    "Taernaby",
    "Taftea",
    "Tagarp",
    "Talje",
    "Taljo",
    "Tallasen",
    "Tallberg",
    "Tanumshede",
    "Tarnsjo",
    "Tavelsjo",
    "Teckomatorp",
    "Tegeltorp",
    "Tenhult",
    "Tibro",
    "Tidaholm",
    "Tidan",
    "Tierp",
    "Timmele",
    "Timmernabben",
    "Timmersdala",
    "Timra",
    "Tingsryd",
    "Tingstade",
    "Tjornarp",
    "Toecksfors",
    "Toere",
    "Toereboda",
    "Tolg",
    "Tollarp",
    "Tollered",
    "Tomelilla",
    "Torekov",
    "Tormestorp",
    "Torpshammar",
    "Torsaker",
    "Torsas",
    "Torsby",
    "Torshalla",
    "Torslanda",
    "Torup",
    "Tranas",
    "Tranemo",
    "Trangsund",
    "Transtrand",
    "Traryd",
    "Traslovslage",
    "Traslovslage",
    "Travad",
    "Trehorningsjo",
    "Trekanten",
    "Trelleborg",
    "Trollhattan",
    "Trosa",
    "Tullinge",
    "Tumba",
    "Tun",
    "Tungelsta",
    "Tvaaker",
    "Tvaralund",
    "Tving",
    "Tygelsjo",
    "Tyreso Strand",
    "Tyringe",
    "Tystberga",
    "Ucklum",
    "Uddebo",
    "Uddeholm",
    "Uddevalla",
    "Ugglarp",
    "Ullared",
    "Ulricehamn",
    "Ulrika",
    "Undersaker",
    "Unnaryd",
    "Uppharad",
    "Upplands Vasby",
    "Uppsala",
    "Urshult",
    "Ursviken",
    "Utansjo",
    "Uttran",
    "Vackelsang",
    "Vaddo",
    "Vaderstad",
    "Vadstena",
    "Vaeja",
    "Vaellingby",
    "Vaennaes",
    "Vaermdoe",
    "Vaggeryd",
    "Vagnharad",
    "Valadalen",
    "Valberg",
    "Valbo",
    "Valdemarsvik",
    "Valla",
    "Vallakra",
    "Vallda",
    "Vallentuna",
    "Vallsta",
    "Valskog",
    "Vanersborg",
    "Vankiva",
    "Vannasby",
    "Vansbro",
    "Vara",
    "Varberg",
    "Varby",
    "Varekil",
    "Vargarda",
    "Vargon",
    "Varing",
    "Varmland",
    "Varnamo",
    "Varnhem",
    "Varobacka",
    "Varsas",
    "Varsta",
    "Vartofta",
    "Vase",
    "Vassmolosa",
    "Vasterfarnebo",
    "Vasterljung",
    "Vastervik",
    "Vastra Amtervik",
    "Vastra Frolunda",
    "Vastra Karup",
    "Vastra Ramlosa",
    "Vastra Torup",
    "Vato",
    "Vattholma",
    "Vaxholm",
    "Vaxjo",
    "Vaxtorp",
    "Veberod",
    "Veddige",
    "Vedum",
    "Vega",
    "Vegby",
    "Veinge",
    "Vejbystrand",
    "Vellinge",
    "Vendelso",
    "Vessigebro",
    "Vetlanda",
    "Vidsel",
    "Vikarbyn",
    "Viken",
    "Vikingstad",
    "Vikmanshyttan",
    "Viksjofors",
    "Vilhelmina",
    "Villan",
    "Vimmerby",
    "Vinberg",
    "Vindeln",
    "Vingaker",
    "Vinninga",
    "Vinslov",
    "Vintrie",
    "Vintrosa",
    "Virsbo Bruk",
    "Virserum",
    "Visby",
    "Viskafors",
    "Vislanda",
    "Vissefjarda",
    "Vitaby",
    "Vittaryd",
    "Vittaryd",
    "Vittsjo",
    "Vollsjo",
    "Vrena",
    "Vreta Kloster",
    "Vretstorp",
    "Yngsjo",
    "Ystad",
    "Ytterby",
    "Zinkgruvan",
  ],
  Switzerland: [
    "Aadorf",
    "Aarau",
    "Aarberg",
    "Aarburg",
    "Abtwil",
    "Adelboden",
    "Adligenswil",
    "Adliswil",
    "Aesch",
    "Aesch",
    "Aeschau",
    "Aeschlen ob Gunten",
    "Aettenschwil",
    "Aeugst am Albis",
    "Affoltern am Albis",
    "Agno",
    "Agra",
    "Aigle",
    "Airolo",
    "Alberswil",
    "Albligen",
    "Alchenstorf",
    "Allaman",
    "Allschwil",
    "Alpnach",
    "Alt-Bachs",
    "Altdorf",
    "Altendorf",
    "Altenrhein",
    "Alterswil",
    "Altishofen",
    "Altnau",
    "Altstatten",
    "Amden",
    "Amriswil",
    "Amsoldingen",
    "Andermatt",
    "Andwil",
    "Anieres",
    "Appenzell",
    "Apples",
    "Apro",
    "Arbaz",
    "Arbedo",
    "Arbon",
    "Arcegno",
    "Ardon",
    "Arisdorf",
    "Aristau",
    "Arlesheim",
    "Arni",
    "Arogno",
    "Arosa",
    "Arth",
    "Arzier",
    "Arzo",
    "Ascona",
    "Assens",
    "Attalens",
    "Attinghausen",
    "Attiswil",
    "Au",
    "Au",
    "Aubonne",
    "Auenstein",
    "Augst",
    "Ausser-Dinhard",
    "Autigny",
    "Auvernier",
    "Auw",
    "Avenches",
    "Avry",
    "Ayent",
    "Ayer",
    "Baar",
    "Bach",
    "Bachenbulach",
    "Bad Ragaz",
    "Bad Zurzach",
    "Baden",
    "Balerna",
    "Balgach",
    "Ballwil",
    "Balsthal",
    "Balterswil",
    "Banco",
    "Bangerten",
    "Bannwil",
    "Barbereche",
    "Baretswil",
    "Bariswil",
    "Basadingen",
    "Basel",
    "Basse-Nendaz",
    "Bassecourt",
    "Bassersdorf",
    "Batterkinden",
    "Baulmes",
    "Bauma",
    "Bavois",
    "Bazenheid",
    "Beckenried",
    "Begnins",
    "Beinwil",
    "Belfaux",
    "Bellerive",
    "Bellevue",
    "Bellinzona",
    "Bellmund",
    "Belmont-sur-Lausanne",
    "Belp",
    "Benken",
    "Berg",
    "Berikon",
    "Beringen",
    "Berlikon",
    "Bern",
    "Bern / Liebefeld",
    "Berneck",
    "Bernex",
    "Beromuenster",
    "Bettingen",
    "Bettlach",
    "Beuson",
    "Bevaix",
    "Bevilard",
    "Bex",
    "Biasca",
    "Biberist",
    "Biel-Benken",
    "Biel/Bienne",
    "Biere",
    "Bigenthal",
    "Bigorio",
    "Bilten",
    "Binningen",
    "Bioggio",
    "Birmensdorf",
    "Birmenstorf",
    "Birr",
    "Birsfelden",
    "Bissegg",
    "Bissone",
    "Bitsch",
    "Blonay",
    "Blumenstein",
    "Bogis-Bossey",
    "Boll",
    "Bolligen",
    "Bonaduz",
    "Bonigen",
    "Bonnefontaine",
    "Bonstetten",
    "Borex",
    "Bosingen",
    "Bottens",
    "Bottenwil",
    "Bottighofen",
    "Bottmingen",
    "Boudry",
    "Bouveret",
    "Bramois",
    "Breitenbach",
    "Bremgarten",
    "Brenzikofen",
    "Brienz",
    "Brig",
    "Brislach",
    "Brissago",
    "Bristen",
    "Brittnau",
    "Broc",
    "Bronschhofen",
    "Brugg",
    "Brunnen",
    "Brusino Arsizio",
    "Brutten",
    "Bruttisellen",
    "Bubendorf",
    "Bubikon",
    "Buch",
    "Buchberg",
    "Buchrain",
    "Buchs",
    "Buchs",
    "Buchs / Buchs (Dorf)",
    "Buetschwil",
    "Buix",
    "Bulach",
    "Bulle",
    "Buochs",
    "Buonas",
    "Burchen",
    "Buren an der Aare",
    "Buren nid dem Bach",
    "Burgdorf",
    "Burglen",
    "Burglen",
    "Bursins",
    "Busserach",
    "Bussigny",
    "Busswil",
    "Buttes",
    "Buttisholz",
    "Butzberg",
    "Buus",
    "Cadempino",
    "Cadenazzo",
    "Cadro",
    "Camorino",
    "Campfer",
    "Canobbio",
    "Capolago",
    "Carouge",
    "Cartigny",
    "Caslano",
    "Castione",
    "Cazis",
    "Celerina",
    "Celigny",
    "Cernier",
    "Chalais",
    "Cham",
    "Chambesy",
    "Chamoson",
    "Champagne",
    "Champex",
    "Champsec",
    "Chancy",
    "Chapelle",
    "Chardonne",
    "Charmey",
    "Charrat-les-Chenes",
    "Chatel-Saint-Denis",
    "Chatelaine",
    "Chavannes",
    "Chavannes de Bogis",
    "Chavannes-les-Forts",
    "Chavornay",
    "Cheiry",
    "Chene-Bougeries",
    "Chene-Bourg",
    "Chene-Paquier",
    "Cheseaux-sur-Lausanne",
    "Chesieres",
    "Chexbres",
    "Cheyres",
    "Chez-le-Bart",
    "Chiasso",
    "Chippis",
    "Chur",
    "Claro",
    "Coeuve",
    "Cointrin",
    "Collombey",
    "Colombier",
    "Colombier",
    "Concise",
    "Confignon",
    "Conthey",
    "Contone",
    "Coppet",
    "Coppet",
    "Corcelles",
    "Corcelles",
    "Cordast",
    "Corgemont",
    "Corminboeuf",
    "Cornaux",
    "Corsier",
    "Cortaillod",
    "Cossonay",
    "Cottens",
    "Courfaivre",
    "Courgenay",
    "Courrendlin",
    "Courroux",
    "Court",
    "Courtedoux",
    "Courtelary",
    "Courtemaiche",
    "Courtepin",
    "Courtetelle",
    "Courtion",
    "Couvet",
    "Crassier",
    "Cremines",
    "Cressier",
    "Cressier",
    "Crissier",
    "Cudrefin",
    "Cugnasco",
    "Cugy",
    "Cully",
    "Dachsen",
    "Daettlikon",
    "Dagmersellen",
    "Daillens",
    "Dallenwil",
    "Daniken",
    "Dardagny",
    "Dattwil",
    "Davos",
    "Davos Dorf",
    "Davos Platz",
    "Degersheim",
    "Deitingen",
    "Densbueren",
    "Derendingen",
    "Diegten",
    "Dielsdorf",
    "Diepoldsau",
    "Diessbach",
    "Diessenhofen",
    "Dietikon",
    "Doettingen",
    "Domat",
    "Dombresson",
    "Domdidier",
    "Dompierre",
    "Dornach",
    "Dottikon",
    "Dozwil",
    "Dubendorf",
    "Durnten",
    "Durrenasch",
    "Ebikon",
    "Ebnat-Kappel",
    "Ecublens",
    "Ecuvillens",
    "Effretikon",
    "Egerkingen",
    "Egg",
    "Eggenwil",
    "Eggersriet",
    "Eggiwil",
    "Eglisau",
    "Egliswil",
    "Egnach",
    "Eich",
    "Eichberg",
    "Eiken",
    "Einigen",
    "Einsiedeln",
    "Elgg",
    "Ellikon an der Thur",
    "Elsau-Raeterschen",
    "Embrach",
    "Emmen",
    "Emmenbruecke",
    "Emmetten",
    "Endingen",
    "Engelberg",
    "Ennenda",
    "Enney",
    "Entlebuch",
    "Epalinges",
    "Epautheyres",
    "Ependes",
    "Erde",
    "Erlach",
    "Erlen",
    "Erlenbach",
    "Ermatingen",
    "Erschwil",
    "Ersigen",
    "Erstfeld",
    "Eschenbach",
    "Eschenbach",
    "Eschlikon",
    "Escholzmatt",
    "Essertines-sur-Rolle",
    "Estavayer-le-Lac",
    "Ettingen",
    "Euthal",
    "Evilard",
    "Evionnaz",
    "Eysins",
    "Fahrwangen",
    "Fallanden",
    "Faoug",
    "Fehraltorf",
    "Felben",
    "Feldbach",
    "Feldbrunnen",
    "Feldmeilen",
    "Felsberg",
    "Felsenau",
    "Ferden",
    "Ferenbalm",
    "Feuerthalen",
    "Feusisberg",
    "Finhaut",
    "Fischenthal",
    "Fischingen",
    "Flaach",
    "Flamatt",
    "Flawil",
    "Fleurier",
    "Flims",
    "Fluehli",
    "Flueli",
    "Flums",
    "Flurlingen",
    "Fontainemelon",
    "Forel",
    "Founex",
    "Fraubrunnen",
    "Frauenfeld",
    "Freidorf",
    "Freienbach",
    "Frenkendorf",
    "Fribourg",
    "Frick",
    "Froideville",
    "Frutigen",
    "Fulenbach",
    "Full",
    "Fullinsdorf",
    "Fully",
    "Furstenaubruck",
    "Gachlingen",
    "Gais",
    "Gampel",
    "Gampelen",
    "Gandria",
    "Ganterschwil",
    "Gebenstorf",
    "Gelfingen",
    "Gelterkinden",
    "Genestrerio",
    "Geneva",
    "Gerlafingen",
    "Gerlikon",
    "Gersau",
    "Gerzensee",
    "Gettnau",
    "Geuensee",
    "Gimel",
    "Gingins",
    "Gipf-Oberfrick",
    "Giswil",
    "Giubiasco",
    "Givrins",
    "Gland",
    "Glaris",
    "Glarus",
    "Glattburg",
    "Glattfelden",
    "Glion",
    "Glis",
    "Gnosca",
    "Goldach",
    "Goldau",
    "Goldiwil",
    "Gommiswald",
    "Gonten",
    "Gontenschwil",
    "Gordevio",
    "Gordola",
    "Goslikon",
    "Gossau",
    "Gossau",
    "Gottlieben",
    "Grabs",
    "Graenichen",
    "Grancy",
    "Grandcour",
    "Grandson",
    "Grandval",
    "Grandvillard",
    "Granges",
    "Granges-pres-Sion",
    "Gravesano",
    "Greifensee",
    "Grellingen",
    "Grenchen",
    "Greppen",
    "Grimentz",
    "Grimisuat",
    "Grindelwald",
    "Grolley",
    "Grone",
    "Grono",
    "Grossandelfingen",
    "Grosswangen",
    "Grueningen",
    "Grund",
    "Gryon",
    "Gstaad",
    "Gumligen",
    "Gummenen",
    "Gunten",
    "Gunzgen",
    "Guttingen",
    "Gwatt",
    "Habkern",
    "Habsburg",
    "Hagenbuch",
    "Hagendorf",
    "Hagglingen",
    "Hagneck",
    "Haldenstein",
    "Hasle",
    "Hauptwil",
    "Hausen",
    "Hausen am Albis / Hausen (Dorf)",
    "Hauterive",
    "Hauteville",
    "Heerbrugg",
    "Heiden",
    "Heimberg",
    "Heimiswil",
    "Heitenried",
    "Hellbuehl",
    "Hemmental",
    "Henau",
    "Hendschiken",
    "Henggart",
    "Herbetswil",
    "Herdern",
    "Hergiswil",
    "Hergiswil",
    "Herisau",
    "Hermance",
    "Hermetschwil-Staffeln",
    "Herrliberg",
    "Herzogenbuchsee",
    "Hettlingen",
    "Hildisrieden",
    "Hilterfingen",
    "Himmelried",
    "Hindelbank",
    "Hinterkappelen",
    "Hinwil",
    "Hirzel-Kirche",
    "Hittnau",
    "Hitzkirch",
    "Hochdorf",
    "Hochwald",
    "Hoerstetten",
    "Hofstetten",
    "Hofstetten",
    "Holderbank",
    "Holderbank",
    "Holstein",
    "Hombrechtikon",
    "Horgen",
    "Horn",
    "Hornussen",
    "Horw",
    "Hundwil",
    "Hunenberg",
    "Hunibach",
    "Huntwangen",
    "Hunzenschwil",
    "Huttwil",
    "Ibach",
    "Igis",
    "Ilanz",
    "Illnau",
    "Immensee",
    "Ingenbohl",
    "Ins",
    "Interlaken",
    "Inwil",
    "Iragna",
    "Iseo",
    "Iserables",
    "Islikon",
    "Ittenthal",
    "Jegenstorf",
    "Jenaz",
    "Jona",
    "Jonen",
    "Jonschwil",
    "Jussy",
    "Kaiseraugst",
    "Kaiserstuhl",
    "Kaisten",
    "Kallnach",
    "Kaltbrunn",
    "Kappel",
    "Kappel",
    "Kastanienbaum",
    "Kefikon",
    "Kehrsatz",
    "Kempttal",
    "Kernenried",
    "Kerns",
    "Kerzers",
    "Kiesen",
    "Kilchberg",
    "Killwangen",
    "Kirchberg",
    "Kirchberg",
    "Kirchdorf",
    "Kleinlutzel",
    "Klingnau",
    "Klosters Platz",
    "Klosters Serneus",
    "Kloten",
    "Koblenz",
    "Kollbrunn",
    "Kolliken",
    "Konolfingen",
    "Koppigen",
    "Kradolf",
    "Krauchthal",
    "Kreuzlingen",
    "Kriegstetten",
    "Kriens",
    "Kronbuhl",
    "Kuesnacht",
    "Kulm",
    "La Chaux",
    "La Chaux-de-Fonds",
    "La Chiesaz",
    "La Neuveville",
    "La Plaine",
    "La Roche",
    "La Sarraz",
    "La Tour-de-Peilz",
    "Laax",
    "Lachen",
    "Lamboing",
    "Landquart",
    "Langenbruck",
    "Langenthal",
    "Langnau",
    "Langnau",
    "Langnau am Albis",
    "Lauenen",
    "Lauerz",
    "Laufelfingen",
    "Laufen",
    "Laufenburg",
    "Laupen",
    "Lausanne",
    "Lausen",
    "Le Brassus",
    "Le Bry",
    "Le Cret",
    "Le Grand-Saconnex",
    "Le Landeron",
    "Le Locle",
    "Le Mont-sur-Lausanne",
    "Le Noirmont",
    "Le Sentier",
    "Le Vaud",
    "Leibstadt",
    "Lengnau",
    "Lenk",
    "Lenz",
    "Lenzburg",
    "Lenzerheide",
    "Les Acacias",
    "Les Breuleux",
    "Les Diablerets",
    "Les Geneveys-sur-Coffrane",
    "Les Genevez",
    "Les Hauts-Geneveys",
    "Les Pommerats",
    "Les Verrieres",
    "Leukerbad",
    "Leutwil",
    "Leysin",
    "Lichtensteig",
    "Liddes",
    "Liebistorf",
    "Lienz",
    "Liestal",
    "Ligerz",
    "Lignieres",
    "Lindau",
    "Littau",
    "Locarno",
    "Loco",
    "Lodrino",
    "Lohn",
    "Lommis",
    "Losone",
    "Lostorf",
    "Lotzwil",
    "Lucens",
    "Lucerne",
    "Lugano",
    "Lungern",
    "Lurtigen",
    "Lutisburg",
    "Lutry",
    "Luvis",
    "Lyss",
    "Lyssach",
    "Maerstetten-Dorf",
    "Magadino",
    "Magden",
    "Maggia",
    "Maisprach",
    "Malleray",
    "Malters",
    "Mannedorf",
    "Manno",
    "Marin",
    "Maroggia",
    "Marsens",
    "Martigny-Croix",
    "Martigny-Ville",
    "Maschwanden",
    "Massagno",
    "Matt",
    "Matzendorf",
    "Matzingen",
    "Mauensee",
    "Maur",
    "Meggen",
    "Meierskappel",
    "Meilen",
    "Meinier",
    "Meinisberg",
    "Meiringen",
    "Meisterschwanden",
    "Melano",
    "Melchnau",
    "Melide",
    "Mellingen",
    "Mels",
    "Mendrisio",
    "Menziken",
    "Menzingen",
    "Merenschwand",
    "Merishausen",
    "Messen",
    "Mettendorf",
    "Mettlen",
    "Mettmenstetten",
    "Mettmenstetten",
    "Meyrin",
    "Miecourt",
    "Minusio",
    "Mohlin",
    "Mollis",
    "Monchaltorf",
    "Montagnola",
    "Montana",
    "Montet",
    "Monthey",
    "Montreux",
    "Moosleerau",
    "Morbio Inferiore",
    "Morcote",
    "Morges",
    "Morgins",
    "Morigen",
    "Moriken",
    "Morschach",
    "Morschwil",
    "Mosnang",
    "Motiers",
    "Moudon",
    "Moutier",
    "Muerren",
    "Muhen",
    "Muhlau",
    "Muhleberg",
    "Mullheim",
    "Mumliswil",
    "Mumpf",
    "Munchenbuchsee",
    "Munchenstein",
    "Munchwilen",
    "Munsingen",
    "Munster",
    "Muolen",
    "Muotathal",
    "Murgenthal",
    "Muri",
    "Muri bei Bern",
    "Murten",
    "Muttenz",
    "Nafels",
    "Nanikon",
    "Naters",
    "Nax",
    "Neftenbach",
    "Nesslau",
    "Netstal",
    "Neu-Rheinau",
    "Neuchatel",
    "Neuenhof",
    "Neuenkirch",
    "Neuhausen",
    "Neuheim",
    "Neukirch",
    "Neunkirch",
    "Neyruz",
    "Nidau",
    "Niederbipp",
    "Niederbuchsiten",
    "Niederburen",
    "Niedererlinsbach",
    "Niedergosgen",
    "Niederhallwil",
    "Niederhelfenschwil",
    "Niederlenz",
    "Niederrohrdorf",
    "Niederscherli",
    "Niederurnen",
    "Niederuzwil",
    "Niederweningen",
    "Nohl",
    "Noreaz",
    "Nottwil",
    "Novaggio",
    "Novazzano",
    "Nunningen",
    "Nurensdorf",
    "Nussbaumen",
    "Nussbaumen",
    "Nyon",
    "Ober Urdorf",
    "Oberbalm",
    "Oberbipp",
    "Oberbuchsiten",
    "Oberburg",
    "Oberdiessbach",
    "Oberdorf",
    "Oberdorf",
    "Oberegg",
    "Oberembrach",
    "Oberengstringen",
    "Oberentfelden",
    "Obergerlafingen",
    "Oberglatt",
    "Obergosgen",
    "Oberhelfenschwil",
    "Oberhofen",
    "Oberhofen bei Etzgen",
    "Oberkirch",
    "Oberlunkhofen",
    "Oberonz",
    "Oberrieden",
    "Oberriet",
    "Obersaxen",
    "Oberschlatt",
    "Oberstocken",
    "Oberuzwil",
    "Obervaz",
    "Oberwangen",
    "Oberweningen",
    "Oberwil",
    "Oberwil",
    "Obfelden",
    "Oensingen",
    "Oetwil / Oetwil an der Limmat",
    "Oetwil am See",
    "Oftringen",
    "Olten",
    "Onex",
    "Onnens",
    "Opfikon",
    "Orbe",
    "Ormalingen",
    "Oron-la-ville",
    "Orpund",
    "Orsonnens",
    "Orvin",
    "Ossingen",
    "Ostermundigen",
    "Otelfingen",
    "Othmarsingen",
    "Ottenbach",
    "Ouchy",
    "Ovronnaz",
    "Palezieux",
    "Pampigny",
    "Paudex",
    "Payerne",
    "Perlen",
    "Perroy",
    "Pery",
    "Peseux",
    "Pfaeffikon",
    "Pfaeffikon",
    "Pfaffnau",
    "Pfeffikon",
    "Pfeffingen",
    "Pfungen",
    "Pfyn",
    "Pieterlen",
    "Plaffeien",
    "Plan-les-Ouates",
    "Porrentruy",
    "Porsel",
    "Portalban- Dessous",
    "Prangins",
    "Pratteln",
    "Pregassona",
    "Preles",
    "Preverenges",
    "Prilly",
    "Puidoux",
    "Pully",
    "Pura",
    "Quartino",
    "Rafz",
    "Ramsen",
    "Rances",
    "Raperswilen",
    "Rapperswil",
    "Rapperswil",
    "Raron",
    "Rebstein",
    "Rechthalten",
    "Reconvilier",
    "Regensdorf",
    "Rehetobel",
    "Reichenburg",
    "Reiden",
    "Reigoldswil",
    "Reinach",
    "Reinach",
    "Rekingen",
    "Renens",
    "Rhazuns",
    "Rheineck",
    "Rheinfelden",
    "Riaz",
    "Richenthal",
    "Richigen",
    "Richterswil",
    "Ricken",
    "Rickenbach",
    "Rickenbach",
    "Riddes",
    "Ried",
    "Riedholz",
    "Rifferswil",
    "Riggisberg",
    "Rikon / Rikon (Dorfkern)",
    "Ringgenberg",
    "Riva San Vitale",
    "Roche",
    "Roemerswil",
    "Roggwil",
    "Roggwil",
    "Rohr",
    "Rohrbach",
    "Rolle",
    "Romainmotier",
    "Romanel",
    "Romanshorn",
    "Romont",
    "Rondchatel",
    "Root",
    "Rorbas",
    "Rorschach",
    "Roschenz",
    "Rossens",
    "Rothenbach",
    "Rothenthurm",
    "Rothrist",
    "Rotkreuz",
    "Roveredo",
    "Rubigen",
    "Rudlingen",
    "Rudolfstetten",
    "Rue",
    "Rueggisberg",
    "Rueti",
    "Rueyres",
    "Rufi",
    "Rumlang",
    "Rupperswil",
    "Ruschlikon",
    "Russikon",
    "Russin",
    "Ruswil",
    "Ruthi",
    "Saanen",
    "Saanenmoser",
    "Saas-Fee",
    "Saas-Grund",
    "Sachseln",
    "Safenwil",
    "Saignelegier",
    "Saint-Aubin",
    "Saint-Aubin-Sauges",
    "Saint-Blaise",
    "Saint-Cergue",
    "Saint-Imier",
    "Saint-Leonard",
    "Saint-Livres",
    "Saint-Maurice",
    "Saint-Prex",
    "Saint-Sulpice",
    "Saint-Sulpice",
    "Sainte-Croix",
    "Salmsach",
    "Samstagern",
    "Sankt Gallenkappel",
    "Sankt Margrethen",
    "Sargans",
    "Sariswil",
    "Sarmenstorf",
    "Sarn",
    "Sarnen",
    "Satigny",
    "Sattel",
    "Saviese",
    "Savigny",
    "Savognin",
    "Saxon",
    "Schachen",
    "Schaffhausen",
    "Schafisheim",
    "Schanis",
    "Schattdorf",
    "Schenkon",
    "Schiers",
    "Schindellegi",
    "Schinznach Bad",
    "Schinznach Dorf",
    "Schlatt",
    "Schleitheim",
    "Schlieren",
    "Schmerikon",
    "Schmitten",
    "Schmitten",
    "Schnottwil",
    "Schoetz",
    "Schoftland",
    "Schonbuhl",
    "Schonenberg",
    "Schonenbuch",
    "Schonenwerd",
    "Schongau",
    "Schonholzerswilen",
    "Schonried",
    "Schubelbach",
    "Schuepfheim",
    "Schupfen",
    "Schwarzenberg",
    "Schwarzenburg",
    "Schwellbrunn",
    "Schwyz",
    "Scuol",
    "Seedorf",
    "Seedorf",
    "Seegraben",
    "Seelisberg",
    "Seengen",
    "Seewis im Pratigau",
    "Seftigen",
    "Seltisberg",
    "Selzach",
    "Sembrancher",
    "Sementina",
    "Sempach",
    "Semsales",
    "Sennwald",
    "Seon",
    "Sessa",
    "Seuzach Dorf",
    "Sevelen",
    "Sezegnin",
    "Siebnen",
    "Sierre",
    "Siglistorf",
    "Sigriswil",
    "Sils-Segl Maria",
    "Sins",
    "Sion",
    "Sirnach",
    "Siselen",
    "Sissach",
    "Siviriez",
    "Solothurn",
    "Sonceboz",
    "Sonvico",
    "Sonvilier",
    "Sorengo",
    "Sottens",
    "Soyhieres",
    "Speicher",
    "Spiez",
    "Spreitenbach",
    "St. Gallen",
    "Stabio",
    "Stadel",
    "Stafa",
    "Stallikon",
    "Stans",
    "Stansstad",
    "Staufen",
    "Steckborn",
    "Steffisburg",
    "Steg",
    "Stein",
    "Stein",
    "Stein am Rhein",
    "Steinen",
    "Steinhausen",
    "Stettfurt",
    "Stettlen",
    "Stoos",
    "Strengelbach",
    "Subingen",
    "Suhr",
    "Sulgen",
    "Sulz",
    "Sumiswald",
    "Sursee",
    "Tafers",
    "Tagerwilen",
    "Tamins",
    "Tanay",
    "Tann",
    "Tasch",
    "Tauffelen",
    "Tavannes",
    "Tegerfelden",
    "Tenero",
    "Termen",
    "Territet",
    "Tesserete",
    "Teufen AR",
    "Teufenthal",
    "Thalheim",
    "Thalheim",
    "Thalwil",
    "Thayngen",
    "Therwil",
    "Thielle",
    "Thierachern",
    "Thierrens",
    "Thonex",
    "Thorishaus",
    "Thun",
    "Thundorf",
    "Thusis",
    "Toffen",
    "Tramelan",
    "Trasadingen",
    "Travers",
    "Treyvaux",
    "Triengen",
    "Trimbach",
    "Trimmis",
    "Trogen",
    "Troistorrents",
    "Trubbach",
    "Trubschachen",
    "Trun",
    "Tschuggen",
    "Tuggen",
    "Turbenthal",
    "Turgi",
    "Twann",
    "Udligenswil",
    "Ueberstorf",
    "Uerikon",
    "Uerkheim",
    "Uetendorf",
    "Uetikon",
    "Ufhusen",
    "Uhwiesen",
    "Uitikon",
    "Ulrichen",
    "Unter-Teufen",
    "Unterageri",
    "Unterehrendingen",
    "Unterengstringen",
    "Untererlinsbach",
    "Unteriberg",
    "Unterkulm",
    "Unterlunkhofen",
    "Unterseen",
    "Untersiggenthal",
    "Untervaz",
    "Ursenbach",
    "Urtenen",
    "Uster",
    "Utzenstorf",
    "Uznach",
    "Uzwil",
    "Vacallo",
    "Vallorbe",
    "Vandoeuvres",
    "Vauderens",
    "Vaulruz",
    "Veltheim",
    "Verbier",
    "Vercorin",
    "Vernayaz",
    "Vernier",
    "Verscio",
    "Versoix",
    "Vesenaz",
    "Vessy",
    "Vetroz",
    "Vevey",
    "Vex",
    "Veyrier",
    "Veytaux",
    "Vicques",
    "Viganello",
    "Villa",
    "Villars",
    "Villars-sur-Glane",
    "Villars-sur-Ollon",
    "Villaz-Saint-Pierre",
    "Villeneuve",
    "Villeret",
    "Villigen",
    "Villmergen",
    "Villnachern",
    "Vilters",
    "Vinzel",
    "Vionnaz",
    "Visp",
    "Vitznau",
    "Volketswil",
    "Vordemwald",
    "Vouvry",
    "Vuadens",
    "Vuiteboeuf",
    "Vullierens",
    "Wabern",
    "Wadenswil",
    "Wagenhausen",
    "Wahlen",
    "Walchwil",
    "Wald",
    "Waldenburg",
    "Waldhaus",
    "Waldkirch",
    "Waldstatt",
    "Walkringen",
    "Wallisellen",
    "Waltenschwil",
    "Walzenhausen",
    "Wangen",
    "Wangen",
    "Wangen an der Aare",
    "Wangi",
    "Wangs",
    "Wasen",
    "Wattwil",
    "Wauwil",
    "Weesen",
    "Wegenstetten",
    "Weggis",
    "Weinfelden",
    "Weiningen",
    "Weiningen",
    "Weissbad",
    "Weisslingen",
    "Welschenrohr",
    "Wengen",
    "Werthenstein",
    "Wettingen",
    "Wetzikon",
    "Wichtrach",
    "Wiedlisbach",
    "Wiesendangen",
    "Wigoltingen",
    "Wikon",
    "Wil",
    "Wil",
    "Wila",
    "Wildegg",
    "Wilderswil",
    "Wileroltigen",
    "Willisau",
    "Wimmis",
    "Windisch",
    "Winkel",
    "Winterberg",
    "Winterthur",
    "Wittenbach",
    "Wittnau",
    "Wohlen",
    "Wolfenschiessen",
    "Wolfgang",
    "Wolfhalden",
    "Wolfwil",
    "Wolhusen",
    "Wollerau",
    "Worb",
    "Worben",
    "Worblaufen",
    "Wuppenau",
    "Wurenlingen",
    "Wurenlos",
    "Wynau",
    "Yens",
    "Yverdon-les-Bains",
    "Yvonand",
    "Yvorne",
    "Zaziwil",
    "Zeglingen",
    "Zeihen",
    "Zell",
    "Zell",
    "Zermatt",
    "Ziegelbrucke",
    "Zihlschlacht",
    "Zizers",
    "Zofingen",
    "Zollbruck",
    "Zollikofen",
    "Zollikon",
    "Zuchwil",
    "Zuckenriet",
    "Zufikon",
    "Zug",
    "Zullwil",
    "Zumikon",
    "Zunzgen",
    "Zurich",
    "Zuzgen",
    "Zuzwil",
    "Zwingen",
  ],
  Taiwan: [
    "Budai",
    "Caogang",
    "Chang-hua",
    "Checheng",
    "Chiayi",
    "Dahu",
    "Douliu",
    "Erlin",
    "Fanlu",
    "Fengshan",
    "Gangshan",
    "Gaozhongyicun",
    "Hemei",
    "Hengchun",
    "Hsinchu",
    "Hualian",
    "Jincheng",
    "Kao-sung",
    "Kaohsiung City",
    "Keelung",
    "Linkou",
    "Lugang",
    "Ma-kung",
    "Maru",
    "Matsu",
    "Meilun",
    "Miaoli",
    "Nanhua",
    "Nankan",
    "Nantou",
    "Neihu",
    "Pingtung",
    "Pozi",
    "Puli",
    "Shalun",
    "Shetou",
    "Shoufeng",
    "Shuilin",
    "Shuiliu",
    "Shuishang",
    "Shulin",
    "Shuyi",
    "Suqin",
    "Taichang",
    "Taichung",
    "Tainan",
    "Taipei",
    "Taipingding",
    "Taitung",
    "Taoyuan",
    "Toucheng",
    "Tuchang",
    "Wan-hua",
    "Wangtongwen",
    "Wanluan",
    "Wutai",
    "Xinji",
    "Xinzhuang",
    "Yangmei",
    "Yanping",
    "Yilan",
    "Yuli",
    "Yunlin County",
    "Zhubei",
    "Zhunan",
    "Zhuqi",
  ],
  Tanzania: [
    "Arusha",
    "Bukoba",
    "Dar es Salaam",
    "Dodoma",
    "Morogoro",
    "Mwanza",
    "Njombe",
    "Raha",
    "Tanga",
    "Zanzibar",
  ],
  Thailand: [
    "Amnat Charoen",
    "Amphawa",
    "Amphoe Aranyaprathet",
    "Ang Thong",
    "Ayutthaya",
    "Ban Ang Thong",
    "Ban Bang Phli Nakhon",
    "Ban Bang Plong",
    "Ban Bueng",
    "Ban Don Sak",
    "Ban Hua Thale",
    "Ban Khlong Ngae",
    "Ban Khlong Prasong",
    "Ban Khlong Prawet",
    "Ban Khlong Takhian",
    "Ban Ko Pao",
    "Ban Na",
    "Ban Nam Hak",
    "Ban Pat Mon",
    "Ban Phichit",
    "Ban Phlam",
    "Ban Pong Lang",
    "Ban Pratunam Tha Khai",
    "Ban Talat Rangsit",
    "Ban Tha Duea",
    "Ban Tha Pai",
    "Ban Tha Ruea",
    "Ban Tham",
    "Ban Wang Yai",
    "Bang Kapi",
    "Bang Khae",
    "Bang Khun Thian",
    "Bang Kruai",
    "Bang Lamung",
    "Bang Na",
    "Bang Phlat",
    "Bang Sao Thong",
    "Bang Sue",
    "Bangkok",
    "Bangkok",
    "Buriram",
    "Cha-am",
    "Chachoengsao",
    "Chaiyaphum",
    "Chanthaburi",
    "Chatuchak",
    "Chiang Mai",
    "Chiang Muan",
    "Chiang Rai",
    "Chon Buri",
    "Chulabhorn",
    "Chumphon",
    "Din Daeng",
    "Don Mueang",
    "Dusit",
    "Hat Yai",
    "Hua Hin",
    "Huai Khwang",
    "Ka Bang",
    "Kalasin",
    "Kamphaeng Phet",
    "Kanchanaburi",
    "Kathu",
    "Khan Na Yao",
    "Khlong Luang",
    "Khlong San",
    "Khlong Toei",
    "Khlung",
    "Khon Kaen",
    "Klaeng",
    "Kosum Phisai",
    "Krabi",
    "Krathum Baen",
    "Kumphawapi",
    "Lampang",
    "Lamphun",
    "Lat Krabang",
    "Loei",
    "Long",
    "Lopburi",
    "Mae Hong Son",
    "Mae Sot",
    "Maha Sarakham",
    "Makkasan",
    "Manorom",
    "Min Buri",
    "Muak Lek",
    "Mueang Nonthaburi",
    "Mueang Samut Prakan",
    "Mukdahan",
    "Nakhon Pathom",
    "Nakhon Ratchasima",
    "Nakhon Sawan",
    "Nakhon Si Thammarat",
    "Nan",
    "Narathiwat",
    "New Sukhothai",
    "Non Thai",
    "Nong Bua",
    "Nong Bua Lamphu",
    "Nong Khai",
    "Nong Prue",
    "Pak Kret",
    "Pathum Thani",
    "Pathum Wan",
    "Pattani",
    "Pattaya",
    "Phachi",
    "Phan",
    "Phang Khon",
    "Phang Nga",
    "Phanna Nikhom",
    "Phatthalung",
    "Phaya Thai",
    "Phayao",
    "Phetchabun",
    "Phetchaburi",
    "Phichit",
    "Phitsanulok",
    "Photharam",
    "Phrae",
    "Phuket",
    "Prachin Buri",
    "Prachuap Khiri Khan",
    "Prakanong",
    "Ranong",
    "Ratchaburi",
    "Rawai",
    "Rayong",
    "Roi Et",
    "Sa Kaeo",
    "Sakon Nakhon",
    "Salaya",
    "Sam Khok",
    "Sam Sen",
    "Samphanthawong",
    "Samut Sakhon",
    "Samut Songkhram",
    "San Sai",
    "Sathon",
    "Sattahip",
    "Satun",
    "Sena",
    "Si Racha",
    "Si Sa Ket",
    "Sing Buri",
    "Songkhla",
    "Suan Luang",
    "Sung Noen",
    "Suphan Buri",
    "Surin",
    "Tak",
    "Thalang",
    "Thanyaburi",
    "Thon Buri",
    "Trang",
    "Trat",
    "Ubon Ratchathani",
    "Udon Thani",
    "Uthai Thani",
    "Uttaradit",
    "Wang Thonglang",
    "Watthana",
    "Wiphawadi",
    "Yala",
    "Yasothon",
  ],
  "Trinidad and Tobago": [
    "Arima",
    "Arouca",
    "Barataria",
    "California",
    "Carapichaima",
    "Carenage",
    "Caroni",
    "Chaguanas",
    "Claxton Bay",
    "Couva",
    "Cumuto",
    "Cunupia",
    "Curepe",
    "Debe",
    "Diego Martin",
    "El Dorado",
    "Freeport",
    "Fyzabad",
    "Gasparillo",
    "Golden Lane",
    "Laventille",
    "Lopinot",
    "Marabella",
    "Maraval",
    "Moriah",
    "Morvant",
    "Penal",
    "Petit Valley",
    "Phoenix Park",
    "Piarco",
    "Port of Spain",
    "Princes Town",
    "Rio Claro",
    "Roxborough",
    "Saint Augustine",
    "Saint Clair",
    "Saint James",
    "Saint Joseph",
    "San Fernando",
    "San Juan",
    "Sangre Grande",
    "Santa Cruz",
    "Scarborough",
    "Siparia",
    "Tunapuna",
    "Valsayn",
    "Victoria",
    "Ward of Tacarigua",
    "Woodbrook",
  ],
  Tunisia: [
    "Ariana",
    "Beja",
    "Gafsa",
    "Hammamet",
    "Le Bardo",
    "Manouba",
    "Monastir",
    "Rades",
    "Sfax",
    "Sidi Bouzid",
    "Sousse",
    "Tunis",
  ],
  Turkey: [
    "Abdullah",
    "Acibadem",
    "Ada",
    "Adana",
    "Adnan Menderes",
    "Afyonkarahisar",
    "Akhisar",
    "Aksaray",
    "Alanya",
    "Alpaslan",
    "Amasra",
    "Amasya",
    "Andac",
    "Ankara",
    "Antakya",
    "Antalya",
    "Ardahan",
    "Artvin",
    "Atakoy",
    "Ataturk",
    "Atlas",
    "Ayazaga",
    "Aydin",
    "Aydogan",
    "Aydogdu",
    "Babaeski",
    "Balcova",
    "Banaz",
    "Bandirma",
    "Bartin",
    "Basari",
    "Baskent",
    "Baskil",
    "Batman",
    "Bayburt",
    "Bayrampasa",
    "Belek",
    "Bergama",
    "Besiktas",
    "Beykent",
    "Beykoz",
    "Beysehir",
    "Biga",
    "Bilecik",
    "Bilgi",
    "Bingol",
    "Birbir",
    "Bitam",
    "Bitlis",
    "Bodrum",
    "Bogazici",
    "Bolu",
    "Bor",
    "Bornova",
    "Bozuyuk",
    "Buca",
    "Bucak",
    "Bueyuekcekmece",
    "Bugdayli",
    "Bulut",
    "Burdur",
    "Burgaz",
    "Burhaniye",
    "Bursa",
    "Calkaya",
    "Camliyayla",
    "Cekme",
    "Celal",
    "Cermik",
    "Ceyhan",
    "Cine",
    "Cukurova",
    "Dalaman",
    "Darica",
    "Dayanikli",
    "Demetevleri",
    "Deniz",
    "Denizli",
    "Doga",
    "Dogankoy",
    "Dogu",
    "Dogus",
    "Dokuz",
    "Dortyol",
    "Doruklu",
    "Dumlupinar",
    "Duran",
    "Duzce",
    "Edebey",
    "Edirne",
    "Edremit",
    "Elbistan",
    "Elek",
    "Erbaa",
    "Ercis",
    "Erciyes",
    "Erenkoy",
    "Erzin",
    "Erzincan",
    "Erzurum",
    "Esenkent",
    "Esentepe",
    "Esenyurt",
    "Eser",
    "Eskisehir",
    "Etimesgut",
    "Fatih",
    "Ferizli",
    "Fethiye",
    "Findikli",
    "Gazi",
    "Gaziantep",
    "Gebze",
    "Gediz",
    "Gelibolu",
    "Gemlik",
    "Germencik",
    "Geulzuk",
    "Giresun",
    "Girne",
    "Golmarmara",
    "Gonen",
    "Gunbuldu",
    "Guzelyurt",
    "Hakkari",
    "Halic",
    "Harbiye",
    "Harran",
    "Hendek",
    "Hurriyet",
    "Ibrahim",
    "Ikitelli",
    "Iskilip",
    "Isparta",
    "Istanbul",
    "Izmir",
    "Kadikoey",
    "Kadikoy",
    "Kadir",
    "Kadirli",
    "Kagithane",
    "Karabuk",
    "Karaca",
    "Karakoy",
    "Karaman",
    "Karamursel",
    "Karatekin",
    "Kars",
    "Kartal",
    "Kastamonu",
    "Kayseri",
    "Kecioeren",
    "Kemal",
    "Kibriskoy",
    "Kilis",
    "Kirikkale",
    "Kisir",
    "Kocakoy",
    "Kocatepe",
    "Konak",
    "Konya",
    "Korfez",
    "Kozan",
    "Kucukcekmece",
    "Kumluca",
    "Kusadasi",
    "Kuzey",
    "Magnesia ad Sipylum",
    "Mahmutkoy",
    "Malatya",
    "Maltepe",
    "Manavgat",
    "Mardin",
    "Marmaris",
    "Maslak",
    "Mehmet Akif Ersoy",
    "Meliksah",
    "Meric",
    "Mersin",
    "Merzifon",
    "Mezitli",
    "Milas",
    "Mugla",
    "Muradiye",
    "Muratpasa",
    "Mustafa",
    "Mustafa Kemal",
    "Mustafakemalpasa",
    "Nazilli",
    "Niksar",
    "Niluefer",
    "Nizip",
    "Ordu",
    "Orhangazi",
    "Orta",
    "Osmaneli",
    "Osmangazi",
    "Osmaniye",
    "Ostim",
    "Pamukkale",
    "Patnos",
    "Pendik",
    "Piri",
    "Rize",
    "Sabanci",
    "Safranbolu",
    "Saglik",
    "Sahinbey",
    "Salihli",
    "Samsun",
    "Sanayi",
    "Sanliurfa",
    "Sariyer",
    "Seferihisar",
    "Sehitkamil",
    "Selcuk",
    "Serdivan",
    "Serik",
    "Seydisehir",
    "Seyhan",
    "Siirt",
    "Sile",
    "Silifke",
    "Siliviri",
    "Sinankoy",
    "Sincan",
    "Sinop",
    "Sirnak",
    "Sisli",
    "Sisman",
    "Sivas",
    "Soeke",
    "Suleyman",
    "Sultanpinar",
    "Sungurlu",
    "Susurluk",
    "Taksim",
    "Tarsus",
    "Tasdelen",
    "Tatvan",
    "Tesvikiye",
    "Tire",
    "Tokat Province",
    "Toprak",
    "Torbali",
    "Toros",
    "Trabzon",
    "Trakya",
    "Tunceli",
    "Turgut",
    "Turgutlu",
    "Uchisar",
    "UEskuedar",
    "Ugur",
    "Ulker",
    "Uludag",
    "Ulus",
    "Umraniye",
    "Unieh",
    "Urgub",
    "Urla",
    "Uzun Keupru",
    "Van",
    "Yagcilar",
    "Yakakoy",
    "Yalova",
    "Yaman",
    "Yasarkoy",
    "Yeditepe",
    "Yeni",
    "Yenibosna",
    "Yildiz",
    "Yozgat",
    "Yuregir",
    "Zekeriya",
    "Zeytinburnu",
    "Zonguldak",
  ],
  "U.S. Virgin Islands": [
    "Charlotte Amalie",
    "Christiansted",
    "Frederiksted",
    "Kingshill",
    "St John Island",
  ],
  Ukraine: [
    "Aleksandriya",
    "Aleksandrovka",
    "Alekseyevo-Druzhkovka",
    "Alupka",
    "Alushta",
    "Babin",
    "Barashivka",
    "Baryshivka",
    "Belaya",
    "Berdychiv",
    "Berehove",
    "Bila Hora",
    "Bila Tserkva",
    "Bolekhiv",
    "Borodyanka",
    "Bortnychi",
    "Boryslav",
    "Boryspil",
    "Boryspil Raion",
    "Boyarka",
    "Bozhenka",
    "Brody",
    "Brovary",
    "Bryanka",
    "Bucha",
    "Buchach",
    "Bukova",
    "Bushtyno",
    "Cherkasy",
    "Chernev",
    "Chernihiv",
    "Chernivtsi",
    "Chervona Hirka",
    "Chervonohrad",
    "Chop",
    "Darakhov",
    "Davydov",
    "Desna",
    "Dimitrov",
    "Dmitrovichi",
    "Dnipropetrovsk",
    "Donetsk",
    "Drohobych",
    "Druzhkovka",
    "Dubno",
    "Dzhankoy",
    "Energodar",
    "Fastiv",
    "Feodosiya",
    "Fontanka",
    "Frunze",
    "German",
    "Goncharov",
    "Gorlovka",
    "Grebenyuk",
    "Haysyn",
    "Hlukhiv",
    "Horlivka",
    "Horodenka",
    "Hoshiv",
    "Illintsi",
    "Irpin",
    "Ivankov",
    "Ivanov",
    "Izmail",
    "Kakhovka",
    "Kalush",
    "Kalynivka",
    "Kalynivka",
    "Kaniv",
    "Kerch",
    "Kharkiv",
    "Kherson",
    "Khust",
    "Khyzha",
    "Kiev",
    "Kirovohrad",
    "Kirovsk",
    "Knyazhychi",
    "Kolomyia",
    "Komsomolsk",
    "Konotop",
    "Korosten",
    "Korostyshiv",
    "Kovel",
    "Kozyatyn",
    "Kramatorsk",
    "Krasnodon",
    "Krasnohrad",
    "Kremenchuk",
    "Kremenets",
    "Kreminna",
    "Kryvyy Rih",
    "Kuna",
    "Kurakhovo",
    "Kurylivka",
    "Kuznetsovsk",
    "Kvasyliv",
    "Kyseliv",
    "Kyyiv",
    "Lanovka",
    "Lenina",
    "Letychiv",
    "Lisichansk",
    "Lityn",
    "Lohvynove",
    "Lubny",
    "Lugansk",
    "Luganskoye",
    "Luhansk",
    "Lutsk",
    "Lutugino",
    "Lviv",
    "Makariv",
    "Makiyivka",
    "Maksim",
    "Mala Tokmachka",
    "Malyn",
    "Mariupol",
    "Markova",
    "Mega",
    "Melitopol",
    "Merefa",
    "Monastyryshche",
    "Mukacheve",
    "Mykolayiv",
    "Mykolayiv",
    "Mylove",
    "Nadezhda",
    "Netishyn",
    "Nizhyn",
    "Nosivka",
    "Novi Sanzhary",
    "Novomoskovsk",
    "Novyy Buh",
    "Obukhov",
    "Odesa",
    "Oleh",
    "Oreanda",
    "Osnova",
    "Osypenko",
    "Pavlograd",
    "Pavlohrad",
    "Petranka",
    "Petrenky",
    "Pobeda",
    "Podol",
    "Poltava",
    "Poplavy",
    "Pyrohiv",
    "Rayhorodka",
    "Rivne",
    "Rivne",
    "Rodinskoye",
    "Romaniv",
    "Romny",
    "Rovenki",
    "Rubizhne",
    "Saky",
    "Selidovo",
    "Semen",
    "Sencha",
    "Serhiyi",
    "Sevastopol",
    "Shepetivka",
    "Shevchenka",
    "Shevchenko",
    "Shostka",
    "Shpola",
    "Simferopol",
    "Slavuta",
    "Sloviansk",
    "Slynkivshchyna",
    "Smila",
    "Sokol",
    "Sokoliv",
    "Soroka",
    "Soroki",
    "Sorotskoye",
    "Spas",
    "Stakhanov",
    "Stanislav",
    "Stat",
    "Stryi",
    "Sudak",
    "Sumy",
    "Svalyava",
    "Svessa",
    "Teplodar",
    "Teremky",
    "Ternopil",
    "Ternovka",
    "Tkachenka",
    "Tokmak",
    "Toporov",
    "Torez",
    "Trypillia",
    "Tsvetkov",
    "Tsyurupinsk",
    "Turka",
    "Tyachiv",
    "Ugledar",
    "Ukrayinka",
    "Uzhhorod",
    "Vasylkiv",
    "Vinnytsya",
    "Volkov",
    "Volnovakha",
    "Voronovo",
    "Vorovskiy",
    "Voznesenka",
    "Vyshhorod",
    "Vyshneve",
    "Yalta",
    "Yasinovataya",
    "Yenakiyeve",
    "Zabolotiv",
    "Zaporizhia",
    "Zaporozhe",
    "Zastava",
    "Zavod",
    "Zdolbuniv",
    "Zhashkiv",
    "Zhdanovka",
    "Zhmerynka",
    "Zhytomyr",
    "Zlazne",
    "Zmiyiv",
    "Zolochiv",
    "Zolotonosha",
  ],
  "United Arab Emirates": [
    "Abu Dhabi",
    "Al Ain",
    "Al Khan",
    "Ar Ruways",
    "As Satwah",
    "Dayrah",
    "Dubai",
    "Fujairah",
    "Ras al-Khaimah",
    "Sharjah",
  ],
  "United Kingdom": [
    "Abberton",
    "Abbots Langley",
    "Aberaeron",
    "Aberchirder",
    "Abercynon",
    "Aberdare",
    "Aberdeen",
    "Aberfeldy",
    "Aberford",
    "Aberfoyle",
    "Abergavenny",
    "Abergele",
    "Abergwynfi",
    "Abergynolwyn",
    "Aberkenfig",
    "Aberlour",
    "Abersychan",
    "Abertillery",
    "Aberystwyth",
    "Abingdon",
    "Aboyne",
    "Accrington",
    "Acton",
    "Addington",
    "Addington",
    "Addlestone",
    "Adlingfleet",
    "Aghalee",
    "Aintree",
    "Airdrie",
    "Akeley",
    "Albrighton",
    "Alcester",
    "Aldeburgh",
    "Alderholt",
    "Alderley Edge",
    "Aldermaston",
    "Aldershot",
    "Aldridge",
    "Alexandria",
    "Alfold",
    "Alford",
    "Alford",
    "Alfreton",
    "Alloa",
    "Alness",
    "Alnwick",
    "Alresford",
    "Alrewas",
    "Alsager",
    "Alston",
    "Altham",
    "Alton",
    "Alton",
    "Altrincham",
    "Alva",
    "Alvechurch",
    "Alveston",
    "Ambleside",
    "Amersham",
    "Amesbury",
    "Amlwch",
    "Ammanford",
    "Ampthill",
    "Andover",
    "Angmering",
    "Anlaby",
    "Annalong",
    "Annan",
    "Annesley",
    "Anniesland",
    "Anstey",
    "Anstey",
    "Anstruther",
    "Antrim",
    "Appleby",
    "Appleford",
    "Appleton",
    "Appley Bridge",
    "Arbroath",
    "Ardingly",
    "Ardmillan",
    "Ardrossan",
    "Arlesey",
    "Arley",
    "Armadale",
    "Armagh",
    "Armitage",
    "Arnold",
    "Arrochar",
    "Arthog",
    "Arundel",
    "Ascot",
    "Ash",
    "Ash Vale",
    "Ashbourne",
    "Ashburton",
    "Ashby de la Launde",
    "Ashby de la Zouch",
    "Ashdon",
    "Ashen",
    "Ashfield",
    "Ashford",
    "Ashford",
    "Ashington",
    "Ashington",
    "Ashley",
    "Ashmore",
    "Ashtead",
    "Ashton in Makerfield",
    "Ashton-under-Lyne",
    "Ashwell",
    "Askam in Furness",
    "Askern",
    "Askham",
    "Astley",
    "Aston",
    "Aston Clinton",
    "Atherstone",
    "Atherton",
    "Attleborough",
    "Attleborough",
    "Atworth",
    "Auchinleck",
    "Auchterarder",
    "Auchtermuchty",
    "Augher",
    "Aughnacloy",
    "Aultbea",
    "Aveley",
    "Aviemore",
    "Avoch",
    "Avonmouth",
    "Axbridge",
    "Axminster",
    "Aycliffe",
    "Aylesbury",
    "Aylesford",
    "Aylsham",
    "Ayr",
    "Babraham",
    "Back",
    "Bacton",
    "Bacup",
    "Bagillt",
    "Bagshot",
    "Baildon",
    "Bainton",
    "Bakewell",
    "Bala",
    "Baldock",
    "Balerno",
    "Balham",
    "Ball",
    "Ballingry",
    "Balloch",
    "Ballybogy",
    "Ballycastle",
    "Ballyclare",
    "Ballymena",
    "Ballymoney",
    "Ballynahinch",
    "Bampton",
    "Banbridge",
    "Banbury",
    "Banchory",
    "Banff",
    "Bangor",
    "Bangor",
    "Bangor-is-y-coed",
    "Banham",
    "Banstead",
    "Barbaraville",
    "Bargoed",
    "Barking",
    "Barkway",
    "Barley",
    "Barmouth",
    "Barmston",
    "Barnard Castle",
    "Barnes",
    "Barnet",
    "Barnham",
    "Barnoldswick",
    "Barnsley",
    "Barnstaple",
    "Barnwell",
    "Barrow",
    "Barrow in Furness",
    "Barrow upon Humber",
    "Barrow upon Soar",
    "Barrowford",
    "Barry",
    "Barton in Fabis",
    "Barton on Sea",
    "Barton under Needwood",
    "Barton upon Humber",
    "Barton-le-Clay",
    "Barwell",
    "Basildon",
    "Basingstoke",
    "Baslow",
    "Bath",
    "Bathgate",
    "Batley",
    "Battle",
    "Bawtry",
    "Bayble",
    "Bayford",
    "Beach",
    "Beaconsfield",
    "Beal",
    "Bealings",
    "Beaminster",
    "Beaufort",
    "Beaulieu",
    "Beauly",
    "Beaumaris",
    "Beaumont",
    "Beaworthy",
    "Bebington",
    "Beccles",
    "Beckenham",
    "Beckingham",
    "Beckley",
    "Beckton",
    "Bedale",
    "Bedford",
    "Bedlington",
    "Bedminster",
    "Bedworth",
    "Beer",
    "Beeston",
    "Beith",
    "Bekesbourne",
    "Belbroughton",
    "Belcoo",
    "Belfast",
    "Belleek",
    "Bellshill",
    "Belmont",
    "Belper",
    "Belvedere",
    "Bembridge",
    "Ben Rhydding",
    "Benburb",
    "Benenden",
    "Benllech",
    "Benson",
    "Bentley",
    "Berkeley",
    "Berkhamstead",
    "Bermondsey",
    "Berwick",
    "Berwick-Upon-Tweed",
    "Besthorpe",
    "Betchworth",
    "Bettws",
    "Betws",
    "Beverley",
    "Bewdley",
    "Bexhill",
    "Bexleyheath",
    "Bicester",
    "Bickenhill",
    "Bickleigh",
    "Biddenden",
    "Biddenham",
    "Biddulph",
    "Bideford",
    "Bidford-on-Avon",
    "Bidston",
    "Bierton",
    "Biggar",
    "Biggin Hill",
    "Biggleswade",
    "Bignor",
    "Bildeston",
    "Billericay",
    "Billesley",
    "Billingham",
    "Billingshurst",
    "Bilsthorpe",
    "Bilston",
    "Binfield",
    "Bingham",
    "Bingley",
    "Binsted",
    "Birchanger",
    "Birchington",
    "Birdbrook",
    "Birkenhead",
    "Birmingham",
    "Birnam",
    "Birstall",
    "Birtley",
    "Birtley",
    "Bishop Auckland",
    "Bishop Burton",
    "Bishopbriggs",
    "Bishops Cleeve",
    "Bishops Waltham",
    "Bishopstoke",
    "Bishopstrow",
    "Bishopton",
    "Bisley",
    "Blackburn",
    "Blackford",
    "Blackford",
    "Blackheath",
    "Blackpool",
    "Blackwater",
    "Blackwater",
    "Blackwood",
    "Blackwood",
    "Blaenau-Ffestiniog",
    "Blaenavon",
    "Blaenwaun",
    "Blaina",
    "Blairgowrie",
    "Blakeney",
    "Blakeney",
    "Blandford Forum",
    "Blaydon",
    "Bledlow",
    "Bletchingley",
    "Bletchley",
    "Blewbury",
    "Blidworth",
    "Bloomsbury",
    "Bloxwich",
    "Blunham",
    "Bluntisham",
    "Blyth",
    "Bodelwyddan",
    "Bodmin",
    "Bognor Regis",
    "Boldon Colliery",
    "Bollington",
    "Bolney",
    "Bolsover",
    "Bolton",
    "Bonar Bridge",
    "Bonnybridge",
    "Bonnyrigg",
    "Bookham",
    "Bootle",
    "Bordesley",
    "Boreham",
    "Borehamwood",
    "Borough Green",
    "Boroughbridge",
    "Bosbury",
    "Boscastle",
    "Boston",
    "Boston Spa",
    "Botesdale",
    "Bothwell",
    "Botley",
    "Bottesford",
    "Bourne",
    "Bourne End",
    "Bournemouth",
    "Bournville",
    "Bourton on the Water",
    "Bovey Tracey",
    "Bow",
    "Bowdon",
    "Bowes",
    "Bowness-on-Windermere",
    "Box",
    "Boxley",
    "Bozeat",
    "Brackley",
    "Bracknell",
    "Bradfield St George",
    "Bradford",
    "Bradford-on-Avon",
    "Bradwell on Sea",
    "Braintree",
    "Bramford",
    "Bramhall",
    "Bramley",
    "Brampton",
    "Brandesburton",
    "Brandon",
    "Brandon",
    "Brandon",
    "Bransford",
    "Bransgore",
    "Brasted",
    "Braunstone",
    "Braunton",
    "Brayton",
    "Brechin",
    "Brecon",
    "Bredbury",
    "Brede",
    "Brenchley",
    "Brentford",
    "Brentwood",
    "Brewood",
    "Bridge",
    "Bridgemary",
    "Bridgend",
    "Bridgham",
    "Bridgnorth",
    "Bridgwater",
    "Bridlington",
    "Bridport",
    "Brierfield",
    "Brierley Hill",
    "Brigg",
    "Brighouse",
    "Brightlingsea",
    "Brighton",
    "Brigstock",
    "Brimpton",
    "Brimscombe",
    "Bristol",
    "Briton Ferry",
    "Brixham",
    "Brixton Hill",
    "Brixworth",
    "Broad Blunsdon",
    "Broadstairs",
    "Broadstone",
    "Broadwas",
    "Broadway",
    "Brockenhurst",
    "Brockley Green",
    "Brockworth",
    "Bromborough",
    "Bromham",
    "Brompton",
    "Bromsgrove",
    "Bromyard",
    "Brook",
    "Brook",
    "Brooke",
    "Brookland",
    "Broom",
    "Broseley",
    "Brough",
    "Broughton",
    "Broughton",
    "Broughton",
    "Broughton",
    "Broughton",
    "Broughton",
    "Broughton",
    "Broughty Ferry",
    "Brownhills",
    "Broxbourne",
    "Broxburn",
    "Bruton",
    "Buckden",
    "Buckfastleigh",
    "Buckhurst Hill",
    "Buckie",
    "Buckingham",
    "Buckland",
    "Buckley",
    "Bucknell",
    "Bude",
    "Budleigh Salterton",
    "Bugbrooke",
    "Builth Wells",
    "Bulford",
    "Bulkington",
    "Bulwell",
    "Bungay",
    "Buntingford",
    "Burbage",
    "Burbage",
    "Burford",
    "Burgess Hill",
    "Burgh le Marsh",
    "Burghclere",
    "Burley",
    "Burnham",
    "Burnham on Crouch",
    "Burnham-on-Sea",
    "Burnley",
    "Burnopfield",
    "Burntisland",
    "Burntwood",
    "Burry Port",
    "Burscough",
    "Burslem",
    "Burstwick",
    "Burton",
    "Burton",
    "Burton Pidsea",
    "Burton-on-Trent",
    "Burwash",
    "Burwell",
    "Bury",
    "Bury",
    "Bury St Edmunds",
    "Burythorpe",
    "Bushey",
    "Butterton",
    "Buxted",
    "Buxton",
    "Byfield",
    "Byfleet",
    "Cadbury",
    "Caddington",
    "Caernarfon",
    "Caerphilly",
    "Caersws",
    "Caister-on-Sea",
    "Caistor",
    "Caldicot",
    "Callander",
    "Callington",
    "Calne",
    "Calstock",
    "Calverton",
    "Cam",
    "Camberley",
    "Camberwell",
    "Camborne",
    "Cambridge",
    "Cambuslang",
    "Cambusnethan",
    "Camelford",
    "Campbeltown",
    "Canewdon",
    "Cannock",
    "Canterbury",
    "Capel",
    "Capenhurst",
    "Carbrooke",
    "Cardiff",
    "Cardigan",
    "Cardonald",
    "Cardross",
    "Carlisle",
    "Carlton",
    "Carlton",
    "Carlton le Moorland",
    "Carluke",
    "Carmarthen",
    "Carnforth",
    "Carnmoney",
    "Carnoustie",
    "Carrbridge",
    "Carrickfergus",
    "Carrowdore",
    "Carshalton",
    "Carterton",
    "Castle Cary",
    "Castle Donington",
    "Castle Douglas",
    "Castlederg",
    "Castleford",
    "Castlereagh",
    "Castleton",
    "Castlewellan",
    "Caston",
    "Caterham",
    "Catford",
    "Catherington",
    "Catterick",
    "Caversham",
    "Cawston",
    "Caxton",
    "Caythorpe",
    "Chacombe",
    "Chaddesden",
    "Chadwell",
    "Chalfont Saint Peter",
    "Chalgrove",
    "Chandlers Ford",
    "Chapel en le Frith",
    "Chapeltown",
    "Chard",
    "Charfield",
    "Charing Cross",
    "Charlestown",
    "Charlton on Otmoor",
    "Charlwood",
    "Charmouth",
    "Chartham",
    "Chasetown",
    "Chatham",
    "Chatteris",
    "Cheadle",
    "Cheadle",
    "Cheadle Hulme",
    "Cheam",
    "Checkley",
    "Cheddar",
    "Chellaston",
    "Chelmsford",
    "Chelsfield",
    "Cheltenham",
    "Chepstow",
    "Chertsey",
    "Chesham",
    "Cheshunt",
    "Chessington",
    "Chester",
    "Chester-le-Street",
    "Chesterfield",
    "Chestfield",
    "Chichester",
    "Chigwell",
    "Chilbolton",
    "Chilcompton",
    "Childwall",
    "Chilton",
    "Chilton",
    "Chilton Trinity",
    "Chilwell",
    "Chingford",
    "Chinley",
    "Chinnor",
    "Chippenham",
    "Chipping Campden",
    "Chipping Norton",
    "Chipping Ongar",
    "Chipping Sodbury",
    "Chipstead",
    "Chislehurst",
    "Chiswick",
    "Choppington",
    "Chorley",
    "Christchurch",
    "Christleton",
    "Chryston",
    "Chulmleigh",
    "Church",
    "Church Gresley",
    "Church Leigh",
    "Church Stretton",
    "Churchill",
    "Cinderford",
    "Cirencester",
    "City of London",
    "City of Westminster",
    "Clackmannan",
    "Clacton-on-Sea",
    "Clapham",
    "Clapham",
    "Clapham",
    "Clarbeston Road",
    "Clare",
    "Clare",
    "Claverdon",
    "Clavering",
    "Claygate",
    "Clayton West",
    "Cleator",
    "Cleator Moor",
    "Cleckheaton",
    "Clevedon",
    "Cleveleys",
    "Cliffe",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton Hampden",
    "Clipstone",
    "Clitheroe",
    "Clovenfords",
    "Clun",
    "Clunderwen",
    "Clutton",
    "Clydach",
    "Clydebank",
    "Coalbrookdale",
    "Coalisland",
    "Coalville",
    "Coatbridge",
    "Cobham",
    "Cobham",
    "Cockerham",
    "Cockermouth",
    "Codsall",
    "Coed-Talon",
    "Cogenhoe",
    "Coggeshall",
    "Colchester",
    "Coleford",
    "Coleraine",
    "Colnbrook",
    "Colne",
    "Colne",
    "Colwyn Bay",
    "Colyford",
    "Colyton",
    "Comber",
    "Compton",
    "Compton Dundon",
    "Comrie",
    "Congleton",
    "Conisbrough",
    "Connahs Quay",
    "Conon Bridge",
    "Consett",
    "Conway",
    "Cookham",
    "Cooksbridge",
    "Cookstown",
    "Coppenhall",
    "Coppull",
    "Corbridge",
    "Corby",
    "Corfe Castle",
    "Corfe Mullen",
    "Corpach",
    "Corringham",
    "Corsham",
    "Corwen",
    "Coseley",
    "Cosham",
    "Cotgrave",
    "Cottenham",
    "Cottered",
    "Cottingham",
    "Coulsdon",
    "Countess Wear",
    "Coupar Angus",
    "Covent Garden",
    "Coventry",
    "Cowbridge",
    "Cowden",
    "Cowdenbeath",
    "Cowley",
    "Cradley",
    "Craigavon",
    "Cramlington",
    "Cranbrook",
    "Cranfield",
    "Cranford",
    "Cranleigh",
    "Crathorne",
    "Craven Arms",
    "Crawley",
    "Crayford",
    "Crediton",
    "Crewe",
    "Crewkerne",
    "Criccieth",
    "Crick",
    "Crickhowell",
    "Cricklade",
    "Cricklewood",
    "Crieff",
    "Crofton",
    "Cromer",
    "Cromwell",
    "Crook",
    "Crookham",
    "Crosby",
    "Cross",
    "Cross in Hand",
    "Cross Keys",
    "Crossgar",
    "Crossgates",
    "Crosshouse",
    "Croston",
    "Croughton",
    "Crowborough",
    "Crowland",
    "Crowthorne",
    "Croxley Green",
    "Croydon",
    "Crumlin",
    "Crymych",
    "Cublington",
    "Cuckfield",
    "Cuffley",
    "Cullen",
    "Cullompton",
    "Cumbernauld",
    "Cumnock",
    "Cupar",
    "Curdridge",
    "Currie",
    "Cwmbran",
    "Cynwyd",
    "Dagenham",
    "Dalbeattie",
    "Dalkeith",
    "Dalry",
    "Dalton in Furness",
    "Daresbury",
    "Darfield",
    "Darlaston",
    "Darlington",
    "Dartford",
    "Dartmouth",
    "Darvel",
    "Darwen",
    "Datchet",
    "Daventry",
    "Dawley",
    "Dawlish",
    "Deal",
    "Dean",
    "Deighton",
    "Denbigh",
    "Denby",
    "Denham",
    "Denny",
    "Denton",
    "Deptford",
    "Derby",
    "Dereham",
    "Derwen",
    "Desborough",
    "Devizes",
    "Dewsbury",
    "Didcot",
    "Diddington",
    "Dinas Powys",
    "Dingestow",
    "Dingwall",
    "Dinnington",
    "Diss",
    "Doagh",
    "Dolgelly",
    "Dollar",
    "Dollis Hill",
    "Dolwyddelan",
    "Donaghadee",
    "Doncaster",
    "Donnington",
    "Dorchester",
    "Dorking",
    "Dorney",
    "Dornoch",
    "Dorridge",
    "Douglas",
    "Doune",
    "Dover",
    "Dovercourt",
    "Downend",
    "Downham Market",
    "Downpatrick",
    "Draperstown",
    "Draycott",
    "Draycott in the Moors",
    "Drayton",
    "Drayton Bassett",
    "Drayton Saint Leonard",
    "Driffield",
    "Drighlington",
    "Droitwich",
    "Dromara",
    "Dromore",
    "Dronfield",
    "Droxford",
    "Droylsden",
    "Drumahoe",
    "Drumchapel",
    "Drybrook",
    "Drymen",
    "Duddington",
    "Dudley",
    "Duffield",
    "Duffus",
    "Dukinfield",
    "Dulverton",
    "Dulwich",
    "Dumbarton",
    "Dumbleton",
    "Dumfries",
    "Dunbar",
    "Dunblane",
    "Dunchurch",
    "Dundee",
    "Dundonald",
    "Dunfermline",
    "Dungannon",
    "Dungiven",
    "Dunkeld",
    "Dunkeswell",
    "Dunmurry",
    "Dunning",
    "Dunoon",
    "Duns",
    "Dunstable",
    "Durham",
    "Durrington",
    "Dursley",
    "Duxford",
    "Dyce",
    "Dymock",
    "Dyserth",
    "Eagle",
    "Eaglesfield",
    "Eaglesham",
    "Earley",
    "Earlham",
    "Earls Colne",
    "Earls Court",
    "Earlston",
    "Earnley",
    "Easington",
    "Easingwold",
    "East Barnet",
    "East Bergholt",
    "East Boldon",
    "East Budleigh",
    "East Challow",
    "East Cowes",
    "East Down",
    "East Dulwich",
    "East Grinstead",
    "East Hagbourne",
    "East Ham",
    "East Hanningfield",
    "East Harling",
    "East Hoathly",
    "East Horsley",
    "East Keal",
    "East Kilbride",
    "East Leake",
    "East Linton",
    "East Malling",
    "East Molesey",
    "East Peckham",
    "East Preston",
    "East Retford",
    "East Stour",
    "East Tilbury",
    "Eastbourne",
    "Eastchurch",
    "Eastcote",
    "Eastham",
    "Eastington",
    "Eastleigh",
    "Easton",
    "Eastwood",
    "Ebbw Vale",
    "Eccles",
    "Eccleshall",
    "Edenbridge",
    "Edenfield",
    "Edgbaston",
    "Edgefield",
    "Edgware",
    "Edinburgh",
    "Edmonton",
    "Edwalton",
    "Edwinstowe",
    "Effingham",
    "Egerton",
    "Egham",
    "Egremont",
    "Egton",
    "Elderslie",
    "Elgin",
    "Elland",
    "Ellesmere",
    "Ellesmere Port",
    "Ellington",
    "Ellon",
    "Elloughton",
    "Elmley Lovett",
    "Elstead",
    "Elstree",
    "Elsworth",
    "Eltham",
    "Ely",
    "Ely",
    "Empingham",
    "Emsworth",
    "Enfield",
    "Englefield Green",
    "Enniskillen",
    "Enstone",
    "Epping",
    "Epping Green",
    "Epsom",
    "Erdington",
    "Erith",
    "Esher",
    "Essendon",
    "Etchingham",
    "Eton",
    "Eversholt",
    "Evesham",
    "Ewelme",
    "Ewhurst",
    "Exeter",
    "Exminster",
    "Exmouth",
    "Eye",
    "Eyemouth",
    "Eynsham",
    "Failsworth",
    "Fairford",
    "Fairlight",
    "Fakenham",
    "Falkirk",
    "Falkland",
    "Falmouth",
    "Fangfoss",
    "Fareham",
    "Faringdon",
    "Farmborough",
    "Farnborough",
    "Farndon",
    "Farnham",
    "Farnham Royal",
    "Farningham",
    "Farnworth",
    "Fauldhouse",
    "Faulkland",
    "Faversham",
    "Felbridge",
    "Felixstowe",
    "Felsted",
    "Feltham",
    "Ferndale",
    "Ferryhill",
    "Ferryside",
    "Filey",
    "Fillongley",
    "Finchampstead",
    "Finchley",
    "Finedon",
    "Fintry",
    "Fishburn",
    "Fishguard",
    "Fitzwilliam",
    "Fivemiletown",
    "Fladbury",
    "Fleet",
    "Fleetwood",
    "Flint",
    "Flitwick",
    "Flordon",
    "Fochabers",
    "Folkestone",
    "Ford",
    "Fordingbridge",
    "Forest Row",
    "Forfar",
    "Formby",
    "Forres",
    "Fort William",
    "Four Marks",
    "Fowey",
    "Fownhope",
    "Framlingham",
    "Frant",
    "Fraserburgh",
    "Freckleton",
    "Frensham",
    "Freshwater",
    "Fressingfield",
    "Friern Barnet",
    "Frimley",
    "Fringford",
    "Frinton-on-Sea",
    "Friskney",
    "Frithville",
    "Frizington",
    "Frodsham",
    "Froggatt",
    "Frome",
    "Fulham",
    "Fulmer",
    "Gaerwen",
    "Gainsborough",
    "Galashiels",
    "Galston",
    "Gamlingay",
    "Gargrave",
    "Gargunnock",
    "Garrison",
    "Garstang",
    "Garston",
    "Garth",
    "Gateshead",
    "Gatwick",
    "Gaydon",
    "Gayton Thorpe",
    "Gelligaer",
    "Gifford",
    "Giggleswick",
    "Gillingham",
    "Gillingham",
    "Gipping",
    "Girton",
    "Girvan",
    "Glasdrumman",
    "Glasgow",
    "Glastonbury",
    "Glenboig",
    "Glenrothes",
    "Glenshee",
    "Glentham",
    "Glossop",
    "Gloucester",
    "Gnosall",
    "Godalming",
    "Godmanchester",
    "Godmersham",
    "Godstone",
    "Golborne",
    "Gomersal",
    "Goodmayes",
    "Goodwick",
    "Goole",
    "Goostrey",
    "Gordon",
    "Gorebridge",
    "Goring",
    "Goring",
    "Gorleston-on-Sea",
    "Gorseinon",
    "Gorslas",
    "Gorton",
    "Gosberton",
    "Gosfield",
    "Gosforth",
    "Gosport",
    "Goudhurst",
    "Gourock",
    "Granby",
    "Grange",
    "Grangemouth",
    "Grantham",
    "Grantown on Spey",
    "Grasmere",
    "Grateley",
    "Graveley",
    "Gravesend",
    "Grays",
    "Great Amwell",
    "Great Baddow",
    "Great Barton",
    "Great Billing",
    "Great Chesterford",
    "Great Dunmow",
    "Great Fransham",
    "Great Gidding",
    "Great Glemham",
    "Great Gransden",
    "Great Milton",
    "Great Missenden",
    "Great Ryburgh",
    "Great Staughton",
    "Great Torrington",
    "Great Waldingfield",
    "Great Yarmouth",
    "Greenfield",
    "Greenford",
    "Greenhead",
    "Greenhithe",
    "Greenisland",
    "Greenock",
    "Greensted",
    "Greenwich",
    "Grendon",
    "Grendon Underwood",
    "Gresford",
    "Gretna",
    "Gretna Green",
    "Gretton",
    "Grimsargh",
    "Grimsby",
    "Groombridge",
    "Grove",
    "Guildford",
    "Guisborough",
    "Guiseley",
    "Gullane",
    "Gunnislake",
    "Guthrie",
    "Hackbridge",
    "Hackney",
    "Haddenham",
    "Haddington",
    "Hadleigh",
    "Hadleigh",
    "Hadlow",
    "Hadlow Down",
    "Hagley",
    "Hailsham",
    "Halesowen",
    "Halesworth",
    "Halewood",
    "Halifax",
    "Halstead",
    "Halstead",
    "Halton",
    "Haltwhistle",
    "Halwell",
    "Hamble",
    "Hambleden",
    "Hambleton",
    "Hamilton",
    "Hammersmith",
    "Hampton",
    "Hampton",
    "Hampton Lucy",
    "Handcross",
    "Handforth",
    "Handsworth",
    "Hanley",
    "Hanwell",
    "Hanworth",
    "Hapton",
    "Harby",
    "Hardham",
    "Harefield",
    "Harlaxton",
    "Harlech",
    "Harlesden",
    "Harleston",
    "Harlow",
    "Harold Wood",
    "Harpenden",
    "Harrogate",
    "Harrold",
    "Harrow",
    "Harrow on the Hill",
    "Harrow Weald",
    "Hartfield",
    "Hartford",
    "Hartlepool",
    "Hartley",
    "Hartpury",
    "Hartwell",
    "Harwell",
    "Harwich",
    "Harworth",
    "Haslemere",
    "Haslingden",
    "Hassocks",
    "Hastings",
    "Hatch",
    "Hatfield",
    "Hatherleigh",
    "Hathersage",
    "Hatton",
    "Havant",
    "Haverfordwest",
    "Haverhill",
    "Havering atte Bower",
    "Hawarden",
    "Hawes",
    "Hawick",
    "Hawkhurst",
    "Hawkwell",
    "Hawley",
    "Haydock",
    "Haydon Bridge",
    "Hayes",
    "Hayes",
    "Hayle",
    "Haywards Heath",
    "Hazel Grove",
    "Hazelwood",
    "Headcorn",
    "Headington",
    "Headley",
    "Heanor",
    "Heath",
    "Heathfield",
    "Heathrow",
    "Hebburn",
    "Hebden Bridge",
    "Heckington",
    "Heckmondwike",
    "Hedgerley",
    "Hednesford",
    "Hedon",
    "Helens Bay",
    "Helensburgh",
    "Hellesdon",
    "Helmsley",
    "Helston",
    "Hemel Hempstead",
    "Hemingstone",
    "Hemswell",
    "Hemsworth",
    "Hendon",
    "Henfield",
    "Hengoed",
    "Henham",
    "Henley",
    "Henley-on-Thames",
    "Henlow",
    "Hennock",
    "Henstridge",
    "Hereford",
    "Heriot",
    "Hermitage",
    "Herne Bay",
    "Herriard",
    "Hersham",
    "Herstmonceux",
    "Hertford",
    "Hessle",
    "Heston",
    "Heswall",
    "Hever",
    "Hexham",
    "Heybridge",
    "Heysham",
    "Heythrop",
    "Heywood",
    "High Bentham",
    "High Blantyre",
    "High Halden",
    "High Legh",
    "High Peak",
    "High Peak Junction",
    "High Wycombe",
    "Higham Ferrers",
    "Higham on the Hill",
    "Highbridge",
    "Highbury",
    "Highcliffe",
    "Higher Bebington",
    "Hightown",
    "Highway",
    "Highworth",
    "Hilborough",
    "Hilderstone",
    "Hill",
    "Hillingdon",
    "Hillsborough",
    "Hillside",
    "Hilton",
    "Hinckley",
    "Hindhead",
    "Hindley",
    "Hindon",
    "Hingham",
    "Hinton St George",
    "Histon",
    "Hitcham",
    "Hitchin",
    "Hockley",
    "Hoddesdon",
    "Holbeach",
    "Holborn",
    "Holmes Chapel",
    "Holmewood",
    "Holmfirth",
    "Holsworthy",
    "Holt",
    "Holt",
    "Holyhead",
    "Holywell",
    "Holywell",
    "Holywood",
    "Honingham",
    "Honiton",
    "Hook",
    "Hooke",
    "Hopwood",
    "Horam",
    "Horbling",
    "Horbury",
    "Horley",
    "Horley",
    "Horncastle",
    "Hornchurch",
    "Horndean",
    "Horndon on the Hill",
    "Hornsea",
    "Hornsey",
    "Horrabridge",
    "Horsham",
    "Horsmonden",
    "Horsted Keynes",
    "Horton Kirby",
    "Horwich",
    "Hotham",
    "Houghton on the Hill",
    "Houghton Regis",
    "Houghton-le-Spring",
    "Houston",
    "Hove",
    "Howden",
    "Hoylake",
    "Hucknall Torkard",
    "Hucknall under Huthwaite",
    "Huddersfield",
    "Huish",
    "Hull",
    "Humberston",
    "Humbie",
    "Hungerford",
    "Hunstanton",
    "Huntingdon",
    "Huntly",
    "Huntspill",
    "Hursley",
    "Hurstbourne Tarrant",
    "Hurstpierpoint",
    "Hurworth",
    "Huyton",
    "Hyde",
    "Hythe",
    "Ibstock",
    "Ickenham",
    "Ifield",
    "Ilchester",
    "Ilford",
    "Ilfracombe",
    "Ilkeston",
    "Ilkley",
    "Ilminster",
    "Immingham",
    "Inchinnan",
    "Ingatestone",
    "Innerleithen",
    "Insch",
    "Inveraray",
    "Invergordon",
    "Inverkeilor",
    "Inverkeithing",
    "Inverkip",
    "Inverness",
    "Inverness-shire",
    "Inverurie",
    "Ipswich",
    "Irlam",
    "Irthlingborough",
    "Irvine",
    "Isham",
    "Isleham",
    "Isleworth",
    "Islington",
    "Islip",
    "Itchen",
    "Itchen Abbas",
    "Iver",
    "Ivybridge",
    "Iwerne Courtney",
    "Jarrow",
    "Jedburgh",
    "Johnstone",
    "Jordanstown",
    "Juniper Green",
    "Kedington",
    "Keele",
    "Keighley",
    "Keith",
    "Kelbrook",
    "Kelly",
    "Kelmarsh",
    "Kelsall",
    "Kelso",
    "Kelty",
    "Kelvedon",
    "Kempston",
    "Kendal",
    "Kenilworth",
    "Kenley",
    "Kennington",
    "Kennoway",
    "Kensington",
    "Kent",
    "Keresley",
    "Keston",
    "Keswick",
    "Ketley",
    "Kettering",
    "Keynsham",
    "Kibworth Harcourt",
    "Kidderminster",
    "Kidlington",
    "Kidsgrove",
    "Kidwelly",
    "Kilbarchan",
    "Kilbirnie",
    "Kilbride",
    "Kilbride",
    "Kilkeel",
    "Killamarsh",
    "Killin",
    "Kilmacolm",
    "Kilmarnock",
    "Kilsyth",
    "Kilwinning",
    "Kimberley",
    "Kimbolton",
    "Kingham",
    "Kinghorn",
    "Kinglassie",
    "Kings Langley",
    "Kings Lynn",
    "Kings Norton",
    "Kings Sutton",
    "Kingsbridge",
    "Kingskerswell",
    "Kingsland",
    "Kingsteignton",
    "Kingston",
    "Kingston",
    "Kingston Seymour",
    "Kingswinford",
    "Kingswood",
    "Kingswood",
    "Kingussie",
    "Kinloch Rannoch",
    "Kinmel",
    "Kinnerley",
    "Kinross",
    "Kirby Cross",
    "Kirk Ella",
    "Kirkby",
    "Kirkby in Ashfield",
    "Kirkby Stephen",
    "Kirkcaldy",
    "Kirkconnel",
    "Kirkcudbright",
    "Kirkham",
    "Kirkintilloch",
    "Kirkliston",
    "Kirkwall",
    "Kirriemuir",
    "Kirtlington",
    "Kirton in Lindsey",
    "Knaresborough",
    "Knebworth",
    "Kneeton",
    "Knighton",
    "Knottingley",
    "Knowsley",
    "Knutsford",
    "Kyle of Lochalsh",
    "Laindon",
    "Lakenheath",
    "Lambeth",
    "Lambourn",
    "Lampeter",
    "Lanark",
    "Lancaster",
    "Lancing",
    "Landrake",
    "Langho",
    "Langley",
    "Langport",
    "Langstone",
    "Lapworth",
    "Larbert",
    "Largs",
    "Larkhall",
    "Larne",
    "Lauder",
    "Laugharne",
    "Launceston",
    "Laurencekirk",
    "Lavant",
    "Lavendon",
    "Lawrencetown",
    "Laxfield",
    "Laxton",
    "Leatherhead",
    "Lechlade",
    "Leconfield",
    "Ledbury",
    "Lee",
    "Lee-on-the-Solent",
    "Leeds",
    "Leeds",
    "Leek",
    "Leek Wootton",
    "Leicester",
    "Leigh",
    "Leigh",
    "Leigh-on-Sea",
    "Leighton Buzzard",
    "Leiston",
    "Leitholm",
    "Lenham",
    "Leominster",
    "Lerwick",
    "Lesmahagow",
    "Letchworth",
    "Leuchars",
    "Leven",
    "Levenshulme",
    "Lewes",
    "Lewisham",
    "Leyburn",
    "Leyland",
    "Leysdown-on-Sea",
    "Leyton",
    "Lichfield",
    "Lidlington",
    "Lifton",
    "Limavady",
    "Limekilns",
    "Lincoln",
    "Lindal in Furness",
    "Lindfield",
    "Lingfield",
    "Linlithgow",
    "Linthwaite",
    "Linton",
    "Linton",
    "Linton upon Ouse",
    "Liphook",
    "Lisburn",
    "Liskeard",
    "Lisnaskea",
    "Liss",
    "Litherland",
    "Little Barningham",
    "Little Canfield",
    "Little Eaton",
    "Little Gaddesden",
    "Little Hulton",
    "Little Kimble",
    "Little Lever",
    "Little Milton",
    "Little Paxton",
    "Littleborough",
    "Littlebury",
    "Littlehampton",
    "Littleport",
    "Liverpool",
    "Liversedge",
    "Livingston",
    "Llanbedr",
    "Llanddulas",
    "Llandeilo",
    "Llandovery",
    "Llandrillo",
    "Llandrindod Wells",
    "Llandudno",
    "Llandudno Junction",
    "Llanelli",
    "Llanfair-Dyffryn-Clwyd",
    "Llanfairfechan",
    "Llanfyllin",
    "Llanfyrnach",
    "Llangattock",
    "Llangefni",
    "Llangennech",
    "Llangollen",
    "Llanharan",
    "Llanidloes",
    "Llanishen",
    "Llanon",
    "Llanrwst",
    "Llansantffraid-ym-Mechain",
    "Llantrisant",
    "Llantrisant",
    "Llantwit Fardre",
    "Llantwit Major",
    "Llanwrda",
    "Llanwrtyd Wells",
    "Llanymynech",
    "Llwyngwril",
    "Llwynypia",
    "Loanhead",
    "Lochgelly",
    "Lochgilphead",
    "Lochmaddy",
    "Lochwinnoch",
    "Lockerbie",
    "Loddington",
    "London",
    "London Borough of Bromley",
    "London Borough of Hounslow",
    "London Borough of Wandsworth",
    "Londonderry",
    "Long Buckby",
    "Long Ditton",
    "Long Eaton",
    "Long Melford",
    "Long Stratton",
    "Longdown",
    "Longfield",
    "Longhope",
    "Longniddry",
    "Longport",
    "Longridge",
    "Longstanton",
    "Longton",
    "Looe",
    "Loppington",
    "Lossiemouth",
    "Lostwithiel",
    "Loudwater",
    "Loughborough",
    "Loughor",
    "Loughton",
    "Louth",
    "Low Ham",
    "Lowdham",
    "Lower Beeding",
    "Lower Brailes",
    "Lower Darwen",
    "Lowestoft",
    "Lowton",
    "Lubenham",
    "Ludlow",
    "Lupton",
    "Lurgan",
    "Lustleigh",
    "Luton",
    "Lutterworth",
    "Lydbury North",
    "Lydney",
    "Lyme Regis",
    "Lyminge",
    "Lymington",
    "Lymm",
    "Lympsham",
    "Lyndhurst",
    "Lyng",
    "Lytchett Minster",
    "Lytham",
    "Mablethorpe",
    "Macclesfield",
    "Macduff",
    "Machynlleth",
    "Maerdy",
    "Maesteg",
    "Maghera",
    "Magherafelt",
    "Magheralin",
    "Maghull",
    "Maida Vale",
    "Maiden Newton",
    "Maidenhead",
    "Maidstone",
    "Malden",
    "Maldon",
    "Mallaig",
    "Malmesbury",
    "Malpas",
    "Malton",
    "Malvern",
    "Manchester",
    "Manningtree",
    "Manor",
    "Mansfield",
    "Mansfield Woodhouse",
    "Manton",
    "March",
    "Marcham",
    "Marden",
    "Margate",
    "Marhamchurch",
    "Mark",
    "Market Deeping",
    "Market Drayton",
    "Market Harborough",
    "Market Rasen",
    "Market Weighton",
    "Markfield",
    "Marlborough",
    "Marlow",
    "Marnhull",
    "Marple",
    "Marr",
    "Marsham",
    "Marske",
    "Martin",
    "Martley",
    "Martock",
    "Maryhill",
    "Maryport",
    "Masham",
    "Matching",
    "Matlock",
    "Mattishall",
    "Mauchline",
    "Maulden",
    "Maybole",
    "Mayfair",
    "Mayfield",
    "Mayfield",
    "Meanwood",
    "Measham",
    "Medmenham",
    "Medstead",
    "Meesden",
    "Meggies",
    "Meifod",
    "Melbourne",
    "Meldreth",
    "Melksham",
    "Mells",
    "Melrose",
    "Melton",
    "Melton Constable",
    "Melton Mowbray",
    "Menai Bridge",
    "Mendlesham",
    "Menheniot",
    "Menston",
    "Meopham",
    "Mere",
    "Merthyr Mawr",
    "Merthyr Tydfil",
    "Merton",
    "Metheringham",
    "Methil",
    "Mexborough",
    "Mickleton",
    "Mickleton",
    "Mid Calder",
    "Middlesbrough",
    "Middleton",
    "Middleton",
    "Middleton",
    "Middleton One Row",
    "Middlewich",
    "Midford",
    "Midgham",
    "Midhurst",
    "Midsomer Norton",
    "Mildenhall",
    "Milford",
    "Milford Haven",
    "Milford on Sea",
    "Mill Hill",
    "Millbrook",
    "Millbrook",
    "Millom",
    "Milltimber",
    "Milnathort",
    "Milnthorpe",
    "Milton",
    "Milton",
    "Milton",
    "Milton Keynes",
    "Milton on Stour",
    "Minchinhampton",
    "Minehead",
    "Minster",
    "Minster Lovell",
    "Minsterley",
    "Mirfield",
    "Mitcham",
    "Mitcheldean",
    "Mobberley",
    "Mochdre",
    "Moira",
    "Mold",
    "Molesey",
    "Molesey",
    "Mollington",
    "Moneymore",
    "Monifieth",
    "Monkswood",
    "Monkton",
    "Monmouth",
    "Montgomery",
    "Montrose",
    "Monyash",
    "Moorsholm",
    "Moorside",
    "Morden",
    "More",
    "Morecambe",
    "Moreton",
    "Moreton in Marsh",
    "Morley",
    "Morpeth",
    "Morriston",
    "Moseley",
    "Moss",
    "Mossley",
    "Moston",
    "Motherwell",
    "Moulton",
    "Moulton St Michael",
    "Moulton St Michael",
    "Mount Bures",
    "Mount Hamilton",
    "Mountain Ash",
    "Mountsorrel",
    "Much Hadham",
    "Much Hoole",
    "Much Wenlock",
    "Muir of Ord",
    "Mundesley",
    "Murton",
    "Musselburgh",
    "Myddle",
    "Mytholmroyd",
    "Myton on Swale",
    "Nafferton",
    "Nailsea",
    "Nairn",
    "Nantwich",
    "Nantyglo",
    "Napton on the Hill",
    "Narberth",
    "Naseby",
    "Nash",
    "Nassington",
    "Neasden",
    "Neath",
    "Needingworth",
    "Neilston",
    "Nelson",
    "Nelson",
    "Neston",
    "Nettlebed",
    "Nettleton",
    "New Barnet",
    "New Buckenham",
    "New Cross",
    "New Ferry",
    "New Mills",
    "New Milton",
    "New Quay",
    "New Romney",
    "New Southgate",
    "New Tredegar",
    "Newark on Trent",
    "Newbiggin-by-the-Sea",
    "Newbold",
    "Newbridge",
    "Newbridge",
    "Newburgh",
    "Newbury",
    "Newcastle",
    "Newcastle",
    "Newcastle Emlyn",
    "Newcastle upon Tyne",
    "Newcastle-under-Lyme",
    "Newent",
    "Newhall",
    "Newham",
    "Newham",
    "Newhaven",
    "Newick",
    "Newlands",
    "Newmarket",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport Pagnell",
    "Newport-On-Tay",
    "Newquay",
    "Newry",
    "Newton Abbot",
    "Newton Aycliffe",
    "Newton on Trent",
    "Newton Stewart",
    "Newton-le-Willows",
    "Newtonmore",
    "Newtown",
    "Newtownabbey",
    "Newtownards",
    "Norbury",
    "Nordelph",
    "Norham",
    "Normandy",
    "Normanton",
    "North Berwick",
    "North Elmsall",
    "North Ferriby",
    "North Hykeham",
    "North Kilworth",
    "North Leigh",
    "North Moreton",
    "North Newton",
    "North Petherton",
    "North Shields",
    "North Somercotes",
    "North Tawton",
    "North Walsham",
    "North Waltham",
    "North Weald",
    "Northallerton",
    "Northampton",
    "Northenden",
    "Northfield",
    "Northfleet",
    "Northleach",
    "Northolt",
    "Northop",
    "Northrepps",
    "Northwich",
    "Northwood",
    "Norton",
    "Norton",
    "Norton",
    "Norton Lindsey",
    "Norwich",
    "Norwood",
    "Nottingham",
    "Nuffield",
    "Nuneaton",
    "Nutfield",
    "Nutley",
    "Oadby",
    "Oakamoor",
    "Oakford",
    "Oakham",
    "Oakhill",
    "Oakley",
    "Oare",
    "Oban",
    "Odiham",
    "Offord Cluny",
    "Okehampton",
    "Old Basing",
    "Old Buckenham",
    "Old Colwyn",
    "Old Malton",
    "Old Windsor",
    "Oldbury",
    "Oldbury",
    "Oldham",
    "Oldmeldrum",
    "Olney",
    "Omagh",
    "Ormesby",
    "Ormiston",
    "Ormskirk",
    "Orpington",
    "Ossett",
    "Oswaldtwistle",
    "Oswestry",
    "Otford",
    "Otley",
    "Otley",
    "Otterburn",
    "Ottershaw",
    "Ottery St Mary",
    "Oulton",
    "Oundle",
    "Overton",
    "Oving",
    "Ovingdean",
    "Owslebury",
    "Oxenhope",
    "Oxford",
    "Oxshott",
    "Oxted",
    "Padbury",
    "Paddock Wood",
    "Padiham",
    "Padstow",
    "Paignton",
    "Painswick",
    "Paisley",
    "Palmers Green",
    "Pampisford",
    "Papworth Everard",
    "Par",
    "Parbold",
    "Partington",
    "Partridge Green",
    "Paston",
    "Patchway",
    "Pathhead",
    "Patrington",
    "Paul",
    "Peebles",
    "Pelsall",
    "Pembroke",
    "Pembroke Dock",
    "Pembury",
    "Penarth",
    "Pencader",
    "Pencaitland",
    "Pencarreg",
    "Pencoed",
    "Pendlebury",
    "Penicuik",
    "Penkridge",
    "Penn",
    "Pennington",
    "Penrice",
    "Penrith",
    "Penryn",
    "Penshurst",
    "Pentraeth",
    "Penwortham",
    "Penzance",
    "Perivale",
    "Perranporth",
    "Pershore",
    "Perth",
    "Peterborough",
    "Peterculter",
    "Peterhead",
    "Peterlee",
    "Petersfield",
    "Petworth",
    "Pevensey",
    "Pewsey",
    "Pickering",
    "Piddington",
    "Pilham",
    "Pilton",
    "Pinner",
    "Pinxton",
    "Pitlochry",
    "Pitsford",
    "Pittenweem",
    "Plaistow",
    "Plaitford",
    "Pleshey",
    "Plockton",
    "Plumstead",
    "Plymouth",
    "Plymstock",
    "Pocklington",
    "Polegate",
    "Polmont",
    "Polperro",
    "Ponders End",
    "Pontardawe",
    "Pontefract",
    "Ponteland",
    "Pontesbury",
    "Pontycymer",
    "Pontypool",
    "Pontypridd",
    "Poole",
    "Poplar",
    "Porlock",
    "Port Erroll",
    "Port Glasgow",
    "Port Sunlight",
    "Port Talbot",
    "Portadown",
    "Portaferry",
    "Portchester",
    "Portglenone",
    "Porth",
    "Porthcawl",
    "Porthleven",
    "Portishead",
    "Portlethen",
    "Portmadoc",
    "Portree",
    "Portrush",
    "Portslade-by-Sea",
    "Portsmouth",
    "Portstewart",
    "Postling",
    "Potters Bar",
    "Potterspury",
    "Potton",
    "Poulton le Fylde",
    "Powfoot",
    "Powick",
    "Poynton",
    "Prees",
    "Preesall",
    "Prescot",
    "Prestatyn",
    "Presteigne",
    "Preston",
    "Prestonpans",
    "Prestwich",
    "Prestwick",
    "Princes Risborough",
    "Princethorpe",
    "Privett",
    "Prudhoe",
    "Puckeridge",
    "Pudsey",
    "Pulborough village hall",
    "Pulloxhill",
    "Purfleet",
    "Purleigh",
    "Purley",
    "Pwllheli",
    "Pyle",
    "Quedgeley",
    "Queenborough",
    "Queensferry",
    "Quinton",
    "Radcliffe",
    "Radcliffe on Trent",
    "Radlett",
    "Radnage",
    "Radstock",
    "Rainford",
    "Rainham",
    "Rainham",
    "Rainhill",
    "Rainworth",
    "Ramsbottom",
    "Ramsbury",
    "Ramsey",
    "Ramsey Saint Marys",
    "Ramsgate",
    "Randalstown",
    "Ratcliffe on Soar",
    "Rathfriland",
    "Ravenstone",
    "Rawmarsh",
    "Rawreth",
    "Rawtenstall",
    "Rayleigh",
    "Rayne",
    "Raynes Park",
    "Reading",
    "Redbourn",
    "Redcar",
    "Reddish",
    "Redditch",
    "Redhill",
    "Redhill",
    "Redland",
    "Redruth",
    "Reed",
    "Reepham",
    "Reigate",
    "Renfrew",
    "Renton",
    "Repton",
    "Reydon",
    "Rhayader",
    "Rhondda",
    "Rhoose",
    "Rhos-on-Sea",
    "Rhyl",
    "Richmond",
    "Richmond",
    "Rickmansworth",
    "Ridingmill",
    "Rimington",
    "Ringmer",
    "Ringwood",
    "Ripe",
    "Ripley",
    "Ripley",
    "Ripon",
    "Ripponden",
    "Risca",
    "Risley",
    "River",
    "Rivington",
    "Roade",
    "Roath",
    "Robertsbridge",
    "Rochdale",
    "Roche",
    "Rochester",
    "Rochford",
    "Rock",
    "Rock Ferry",
    "Roehampton",
    "Roffey",
    "Rogerstone",
    "Rogiet",
    "Romford",
    "Romsey",
    "Ross on Wye",
    "Rosslea",
    "Rosyth",
    "Rothbury",
    "Rotherfield",
    "Rotherham",
    "Rothesay",
    "Rothwell",
    "Rothwell",
    "Rottingdean",
    "Rowde",
    "Rowhedge",
    "Rowlands Castle",
    "Rowlands Gill",
    "Rowley Regis",
    "Roxwell",
    "Royal Leamington Spa",
    "Royal Tunbridge Wells",
    "Royal Wootton Bassett",
    "Roydon",
    "Royston",
    "Royston",
    "Ruabon",
    "Ruddington",
    "Rugby",
    "Rugeley",
    "Ruislip",
    "Runcorn",
    "Rushden",
    "Rushden",
    "Rustington",
    "Rutherglen",
    "Ruthin",
    "Ryde",
    "Rye",
    "Ryton",
    "Sacriston",
    "Saffron Walden",
    "Saint Agnes",
    "Saint Annes on the Sea",
    "Saint Bees",
    "Saint Brides Major",
    "Saint Clears",
    "Saint Columb Major",
    "Saint Erme",
    "Saint Ives",
    "Saint Leonards-on-Sea",
    "Saint Neots",
    "Sale",
    "Salford",
    "Salfords",
    "Salisbury",
    "Saltash",
    "Saltburn-by-the-Sea",
    "Saltcoats",
    "Salthouse",
    "Sandbach",
    "Sanderstead",
    "Sandhurst",
    "Sandiacre",
    "Sandown",
    "Sandwell",
    "Sandwich",
    "Sandy",
    "Sandycroft",
    "Sanquhar",
    "Sarratt",
    "Saundersfoot",
    "Sawbridgeworth",
    "Sawley",
    "Saxmundham",
    "Saxtead",
    "Scalby",
    "Scapa",
    "Scarborough",
    "Scunthorpe",
    "Seacroft",
    "Seaford",
    "Seaham",
    "Seale",
    "Seascale",
    "Seaton",
    "Seaton",
    "Seaview",
    "Sedbergh",
    "Sedgeberrow",
    "Sedgefield",
    "Sedgley",
    "Seend",
    "Seghill",
    "Selborne",
    "Selby",
    "Selkirk",
    "Selsey",
    "Selston",
    "Send",
    "Settle",
    "Seven Kings",
    "Sevenoaks",
    "Shadoxhurst",
    "Shaftesbury",
    "Shalford",
    "Shalford",
    "Shanklin",
    "Shardlow",
    "Shaw",
    "Shawbury",
    "Sheering",
    "Sheerness",
    "Sheffield",
    "Shefford",
    "Sheldon",
    "Shelford",
    "Shenfield",
    "Shepperton",
    "Shepshed",
    "Shepton Mallet",
    "Sherborne",
    "Sherfield upon Loddon",
    "Sheringham",
    "Shifnal",
    "Shildon",
    "Shilton",
    "Shinfield",
    "Shipley",
    "Shipston on Stour",
    "Shirebrook",
    "Shirehampton",
    "Shireoaks",
    "Shoeburyness",
    "Shoreham-by-Sea",
    "Shotley Gate",
    "Shotton",
    "Shotts",
    "Shrewsbury",
    "Shrivenham",
    "Sidcot",
    "Sidcup",
    "Sidlesham",
    "Sidmouth",
    "Sileby",
    "Silloth",
    "Silsden",
    "Silsoe",
    "Silverdale",
    "Silverstone",
    "Sittingbourne",
    "Skegness",
    "Skelmersdale",
    "Skelmorlie",
    "Skelton",
    "Skilgate",
    "Skipton",
    "Slapton",
    "Slawitt",
    "Sleaford",
    "Slinfold",
    "Slough",
    "Smalley",
    "Smarden",
    "Smethwick",
    "Snaresbrook",
    "Snettisham",
    "Snodland",
    "Soham",
    "Solihull",
    "Somerset",
    "Somersham",
    "Somerton",
    "Sompting",
    "Sonning",
    "South Benfleet",
    "South Brent",
    "South Brewham",
    "South Carlton",
    "South Cave",
    "South Cerney",
    "South Elmsall",
    "South Hayling",
    "South Hetton",
    "South Killingholme",
    "South Milford",
    "South Molton",
    "South Normanton",
    "South Ockendon",
    "South Petherton",
    "South Shields",
    "Southall",
    "Southam",
    "Southampton",
    "Southbourne",
    "Southend-on-Sea",
    "Southgate",
    "Southgate",
    "Southminster",
    "Southport",
    "Southsea",
    "Southwark",
    "Southwater",
    "Southwell",
    "Southwick",
    "Southwold",
    "Sowerby Bridge",
    "Spalding",
    "Sparsholt",
    "Speke",
    "Speldhurst",
    "Spennymoor",
    "Spetisbury",
    "Spilsby",
    "Spittal",
    "Spondon",
    "Spratton",
    "Sprowston",
    "Square",
    "St Albans",
    "St Andrews",
    "St Asaph",
    "St Austell",
    "St Helens",
    "St Ives",
    "St. Dogmaels",
    "Stafford",
    "Staindrop",
    "Staines",
    "Stalham",
    "Stallingborough",
    "Stalybridge",
    "Stamford",
    "Stanbridge",
    "Standlake",
    "Stanford",
    "Stanford",
    "Stanford",
    "Stanground",
    "Stanhope",
    "Stanley",
    "Stanmore",
    "Stanstead",
    "Stansted",
    "Stanton",
    "Stanton",
    "Stanton Fitzwarren",
    "Stanwell",
    "Staple",
    "Staplehurst",
    "Star",
    "Staverton",
    "Stawell",
    "Steeple Claydon",
    "Steeton",
    "Stepps",
    "Stevenage",
    "Stevenston",
    "Steventon",
    "Stewarton",
    "Steyning",
    "Stiffkey",
    "Stifford",
    "Stillington",
    "Stirling",
    "Stisted",
    "Stock",
    "Stockbridge",
    "Stockcross",
    "Stockport",
    "Stocksbridge",
    "Stocksfield",
    "Stockton",
    "Stockton-on-Tees",
    "Stoke",
    "Stoke by Nayland",
    "Stoke Climsland",
    "Stoke Newington",
    "Stoke Poges",
    "Stoke Prior",
    "Stoke upon Tern",
    "Stoke-on-Trent",
    "Stokesley",
    "Stone",
    "Stone",
    "Stone Allerton",
    "Stonehaven",
    "Stonehouse",
    "Stoneleigh",
    "Stonesfield",
    "Stony Stratford",
    "Stornoway",
    "Storrington",
    "Stotfold",
    "Stourbridge",
    "Stourport On Severn",
    "Stow",
    "Stow",
    "Stow on the Wold",
    "Stowe",
    "Stowmarket",
    "Strabane",
    "Stranmillis",
    "Stranraer",
    "Stratfield Mortimer",
    "Stratford",
    "Stratford-upon-Avon",
    "Strathaven",
    "Strathmiglo",
    "Strathyre",
    "Streatham",
    "Streatley",
    "Stretford",
    "Stretton on Fosse",
    "Stromness",
    "Strood",
    "Stroud",
    "Stuartfield",
    "Studham",
    "Studley",
    "Studley",
    "Sturmer",
    "Sturminster Newton",
    "Stutton",
    "Sudbury",
    "Sully",
    "Sunbury-on-Thames",
    "Sunderland",
    "Sundon Park",
    "Sunningdale",
    "Sunninghill",
    "Surbiton",
    "Surrey",
    "Sutton",
    "Sutton Bridge",
    "Sutton Coldfield",
    "Sutton Courtenay",
    "Sutton in Ashfield",
    "Sutton on Hull",
    "Sutton on the Hill",
    "Sutton on Trent",
    "Swadlincote",
    "Swaffham",
    "Swalwell",
    "Swanage",
    "Swanland",
    "Swanscombe",
    "Swansea",
    "Swavesey",
    "Swaythling",
    "Swindon",
    "Swindon Village",
    "Swinton",
    "Swynnerton",
    "Symington",
    "Syston",
    "Tackley",
    "Tadcaster",
    "Tadley",
    "Tadmarton",
    "Tadworth",
    "Tain",
    "Takeley",
    "Talgarth",
    "Talsarnau",
    "Talybont",
    "Tamworth",
    "Tandragee",
    "Tansley",
    "Taplow",
    "Tarbert",
    "Tarleton",
    "Tarporley",
    "Tatsfield",
    "Tattershall",
    "Taunton",
    "Tavistock",
    "Taynuilt",
    "Tayport",
    "Teddington",
    "Teddington",
    "Teignmouth",
    "Telford",
    "Temple",
    "Templecombe",
    "Templepatrick",
    "Tempo",
    "Tenbury Wells",
    "Tenby",
    "Tendring",
    "Tenterden",
    "Tetbury",
    "Tetsworth",
    "Tewin",
    "Tewkesbury",
    "Teynham",
    "Thakeham",
    "Thame",
    "Thames Ditton",
    "Thatcham",
    "The Hyde",
    "Theale",
    "Theale",
    "Thetford",
    "Theydon Bois",
    "Thirsk",
    "Thornaby",
    "Thornaby",
    "Thornbury",
    "Thornton",
    "Thornton",
    "Thornton Heath",
    "Thornton-in-Craven",
    "Thorpe St Peter",
    "Thorrington",
    "Thrapston",
    "Three Legged Cross",
    "Threlkeld",
    "Thurleigh",
    "Thurso",
    "Thurston",
    "Ticehurst",
    "Ticknall",
    "Tideswell",
    "Tilbury",
    "Tile Hill",
    "Tilehurst",
    "Tillicoultry",
    "Tillingham",
    "Timperley",
    "Tipton",
    "Tiptree",
    "Tisbury",
    "Tiverton",
    "Toddington",
    "Todmorden",
    "Tollard Royal",
    "Tollerton",
    "Tonbridge",
    "Tongham",
    "Tonypandy",
    "Tonyrefail",
    "Torpoint",
    "Torquay",
    "Totnes",
    "Tottenham",
    "Totteridge",
    "Totternhoe",
    "Totton",
    "Towcester",
    "Tranent",
    "Tredegar",
    "Trefonen",
    "Treforest",
    "Tregarth",
    "Trehafod",
    "Treharris",
    "Treherbert",
    "Trent",
    "Treorchy",
    "Treuddyn",
    "Trimdon",
    "Tring",
    "Troon",
    "Trowbridge",
    "Truro",
    "Trusthorpe",
    "Tunstall",
    "Turnberry",
    "Turriff",
    "Turvey",
    "Tweedmouth",
    "Twickenham",
    "Twyford",
    "Twyford",
    "Tyldesley",
    "Tynemouth",
    "Tywardreath",
    "Uckfield",
    "Uddingston",
    "Ufford",
    "Ulceby",
    "Ulcombe",
    "Ullesthorpe",
    "Ulverston",
    "Unstone",
    "Uphall",
    "Upminster",
    "Upper Beeding",
    "Upper Slaughter",
    "Uppingham",
    "Upton",
    "Upton",
    "Upton",
    "Upton upon Severn",
    "Upwell",
    "Urchfont",
    "Urmston",
    "Ushaw Moor",
    "Usk",
    "Uttoxeter",
    "Uxbridge",
    "Uxbridge",
    "Valley",
    "Ventnor",
    "Verwood",
    "Victoria",
    "Voe",
    "Waddesdon",
    "Wadebridge",
    "Wadhurst",
    "Wakefield",
    "Waldringfield",
    "Wales",
    "Walford",
    "Walgrave",
    "Walkden",
    "Walker",
    "Wallasey",
    "Wallingford",
    "Wallington",
    "Wallsend",
    "Walmer",
    "Walsall",
    "Waltham",
    "Waltham",
    "Waltham Abbey",
    "Waltham Cross",
    "Walthamstow",
    "Walton on Thames",
    "Walton on the Hill",
    "Walton on the Hill",
    "Walton-on-the-Naze",
    "Wanstead",
    "Wantage",
    "Warden",
    "Ware",
    "Wareham",
    "Warfield",
    "Wargrave",
    "Waringstown",
    "Warlingham",
    "Warmington",
    "Warminster",
    "Warrenpoint",
    "Warrington",
    "Warton",
    "Warwick",
    "Washington",
    "Watchet",
    "Watchfield",
    "Water Orton",
    "Waterbeach",
    "Waterlooville",
    "Watford",
    "Wath upon Dearne",
    "Watlington",
    "Wattisfield",
    "Watton",
    "Weald",
    "Wealdstone",
    "Weare",
    "Weaverham",
    "Wedmore",
    "Wednesbury",
    "Wednesfield",
    "Weedon",
    "Weedon Bec",
    "Well",
    "Welling",
    "Wellingborough",
    "Wellington",
    "Wellington",
    "Wells",
    "Welshpool",
    "Welwyn",
    "Welwyn Garden City",
    "Wem",
    "Wembley",
    "Wemyss Bay",
    "Wendover",
    "Wentworth",
    "Weobley",
    "West Ashby",
    "West Bromwich",
    "West Byfleet",
    "West Calder",
    "West Clandon",
    "West Cowes",
    "West Drayton",
    "West Drayton",
    "West Grinstead",
    "West Horsley",
    "West Kilbride",
    "West Kirby",
    "West Langdon",
    "West Linton",
    "West Malling",
    "West Mersea",
    "West Raynham",
    "West Row",
    "West Rudham",
    "West Wickham",
    "West Wickham",
    "West Wittering",
    "Westbury",
    "Westbury-sub-Mendip",
    "Westcott",
    "Westerham",
    "Westfield",
    "Westgate",
    "Westgate on Sea",
    "Westhay",
    "Westhoughton",
    "Westleigh",
    "Westleton",
    "Weston",
    "Weston",
    "Weston in Gordano",
    "Weston under Lizard",
    "Weston-super-Mare",
    "Wetherby",
    "Wethersfield",
    "Weybridge",
    "Weymouth",
    "Whaley Bridge",
    "Whalley",
    "Wheathampstead",
    "Wheatley",
    "Wheaton Aston",
    "Whetstone",
    "Whickham",
    "Whimple",
    "Whisby",
    "Whitbourne",
    "Whitburn",
    "Whitby",
    "Whitchurch",
    "Whitchurch",
    "Whitchurch",
    "Whitchurch",
    "Whitefield",
    "Whitehaven",
    "Whitehouse",
    "Whiteparish",
    "Whitland",
    "Whitley Bay",
    "Whitnash",
    "Whitstable",
    "Whittlebury",
    "Whittlesey",
    "Whittlesford",
    "Whitton",
    "Whitwell",
    "Whitwick",
    "Whyteleafe",
    "Wick",
    "Wick",
    "Wick",
    "Wick",
    "Wicken",
    "Wickersley",
    "Wickford",
    "Wickham",
    "Wickham Bishops",
    "Wickham Market",
    "Widdrington",
    "Widmerpool",
    "Widnes",
    "Wigan",
    "Wigston",
    "Wigton",
    "Wilburton",
    "Wilden",
    "Willaston",
    "Willenhall",
    "Willesden",
    "Willingale Doe",
    "Willingham",
    "Willington",
    "Willington",
    "Willington Quay",
    "Wilmington",
    "Wilmslow",
    "Wilnecote",
    "Wilshamstead",
    "Wimbledon",
    "Wimborne Minster",
    "Wincanton",
    "Winchester",
    "Windermere",
    "Windlesham",
    "Windsor",
    "Windsor",
    "Wing",
    "Wingate",
    "Winkleigh",
    "Winlaton",
    "Winnersh",
    "Winnington",
    "Winsford",
    "Winslow",
    "Winterborne Kingston",
    "Winterbourne Dauntsey",
    "Winton",
    "Wirksworth",
    "Wisbech",
    "Wishaw",
    "Witham",
    "Withernsea",
    "Withernwick",
    "Withington",
    "Witley",
    "Witney",
    "Wix",
    "Woburn Sands",
    "Woking",
    "Wokingham",
    "Wold Newton",
    "Woldingham",
    "Wolstanton",
    "Wolverhampton",
    "Wolverley",
    "Wombourne",
    "Wombwell",
    "Wooburn",
    "Woodbridge",
    "Woodford",
    "Woodford Green",
    "Woodhall Spa",
    "Woodham Ferrers",
    "Woodhouse",
    "Woodhouse",
    "Woodley",
    "Woodmancote",
    "Woodsetts",
    "Woodstock",
    "Woolacombe",
    "Woolwich",
    "Woore",
    "Wootton",
    "Wootton",
    "Wootton",
    "Wootton",
    "Worcester",
    "Worcester Park",
    "Workington",
    "Worksop",
    "Worle",
    "Worminghall",
    "Worsley",
    "Worth",
    "Worthing",
    "Wotton Underwood",
    "Wotton-under-Edge",
    "Wragby",
    "Wrangaton",
    "Wrangle",
    "Wrawby",
    "Wraysbury",
    "Wrea Green",
    "Wrexham",
    "Wrington",
    "Writtle",
    "Wroughton",
    "Wroxall",
    "Wyke",
    "Wymeswold",
    "Wymondham",
    "Wythenshawe",
    "Wyton",
    "Wyverstone",
    "Yarm",
    "Yate",
    "Yatton",
    "Yeadon",
    "Yelverton",
    "Yeovil",
    "York",
    "Yoxford",
    "Ystalyfera",
    "Ystrad Mynach",
    "Ystradgynlais",
    "Zeals",
  ],
  "United States": [
    "Abbeville",
    "Abbeville",
    "Abbeville",
    "Abbeville",
    "Abbeville",
    "Abbotsford",
    "Abbott",
    "Abbottstown",
    "Abercrombie",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen",
    "Aberdeen Proving Ground",
    "Abernathy",
    "Abilene",
    "Abilene",
    "Abingdon",
    "Abingdon",
    "Abingdon",
    "Abington",
    "Abington Township",
    "Abiquiu",
    "Abita Springs",
    "Abrams",
    "Absarokee",
    "Absecon",
    "Acampo",
    "Access",
    "Accident",
    "Accokeek",
    "Accomac",
    "Accord",
    "Accoville",
    "Achille",
    "Achilles",
    "Ackerly",
    "Ackerman",
    "Ackley",
    "Acme",
    "Acme",
    "Acton",
    "Acton",
    "Acushnet",
    "Acworth",
    "Acworth",
    "Ada",
    "Ada",
    "Ada",
    "Ada",
    "Adah",
    "Adair",
    "Adair",
    "Adairsville",
    "Adairville",
    "Adak",
    "Adams",
    "Adams",
    "Adams",
    "Adams",
    "Adams",
    "Adams",
    "Adams",
    "Adams",
    "Adams Center",
    "Adams Run",
    "Adamstown",
    "Adamstown",
    "Adamsville",
    "Adamsville",
    "Addieville",
    "Addis",
    "Addison",
    "Addison",
    "Addison",
    "Addison",
    "Addison",
    "Addison",
    "Addison",
    "Addyston",
    "Adel",
    "Adel",
    "Adelanto",
    "Adell",
    "Adena",
    "Adger",
    "Adin",
    "Adkins",
    "Admire",
    "Adolphus",
    "Adona",
    "Adrian",
    "Adrian",
    "Adrian",
    "Adrian",
    "Adrian",
    "Adrian",
    "Advance",
    "Advance",
    "Afton",
    "Afton",
    "Afton",
    "Afton",
    "Afton",
    "Afton",
    "Afton",
    "Agar",
    "Agate",
    "Agawam",
    "Agency",
    "Agoura Hills",
    "Agra",
    "Agra",
    "Aguanga",
    "Aguila",
    "Ahoskie",
    "Ahwahnee",
    "Aiken",
    "Ailey",
    "Ainsworth",
    "Ainsworth",
    "Air Force Academy",
    "Airville",
    "Airway Heights",
    "Aitkin",
    "Ajo",
    "Akaska",
    "Akeley",
    "Akhiok",
    "Akiachak",
    "Akiak",
    "Akron",
    "Akron",
    "Akron",
    "Akron",
    "Akron",
    "Akron",
    "Akron",
    "Akutan",
    "Alabaster",
    "Alachua",
    "Alakanuk",
    "Alamance",
    "Alameda",
    "Alamo",
    "Alamo",
    "Alamo",
    "Alamo",
    "Alamo",
    "Alamo",
    "Alamogordo",
    "Alamosa",
    "Alanson",
    "Alapaha",
    "Alba",
    "Alba",
    "Alba",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albany",
    "Albemarle",
    "Albers",
    "Albert City",
    "Albert Lea",
    "Alberton",
    "Albertson",
    "Albertson",
    "Albertville",
    "Albertville",
    "Albia",
    "Albin",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Albion",
    "Alborn",
    "Albright",
    "Albrightsville",
    "Albuquerque",
    "Alburg",
    "Alburnett",
    "Alburtis",
    "Alcalde",
    "Alcester",
    "Alcoa",
    "Alcolu",
    "Alda",
    "Alden",
    "Alden",
    "Alden",
    "Alden",
    "Alden",
    "Alderpoint",
    "Alderson",
    "Aldie",
    "Aldrich",
    "Aledo",
    "Aledo",
    "Alex",
    "Alexander",
    "Alexander",
    "Alexander",
    "Alexander",
    "Alexander",
    "Alexander City",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria",
    "Alexandria Bay",
    "Alexis",
    "Alexis",
    "Alford",
    "Alfred",
    "Alfred",
    "Alfred",
    "Alfred Station",
    "Alger",
    "Algodones",
    "Algoma",
    "Algona",
    "Algonac",
    "Algonquin",
    "Alhambra",
    "Alhambra",
    "Alice",
    "Aliceville",
    "Aline",
    "Aliquippa",
    "Aliso Viejo",
    "Alkol",
    "Allamuchy",
    "Allardt",
    "Allegan",
    "Allegany",
    "Allen",
    "Allen",
    "Allen",
    "Allen",
    "Allen",
    "Allen Park",
    "Allendale",
    "Allendale",
    "Allendale",
    "Allenhurst",
    "Allenport",
    "Allenspark",
    "Allenton",
    "Allenton",
    "Allenton",
    "Allentown",
    "Allentown",
    "Allenwood",
    "Allenwood",
    "Allerton",
    "Alliance",
    "Alliance",
    "Allison",
    "Allison",
    "Allison Park",
    "Allons",
    "Alloway",
    "Allston",
    "Allyn",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma",
    "Alma Center",
    "Almena",
    "Almena",
    "Almira",
    "Almo",
    "Almond",
    "Almond",
    "Almont",
    "Alna",
    "Alpaugh",
    "Alpena",
    "Alpena",
    "Alpha",
    "Alpha",
    "Alpha",
    "Alpha",
    "Alpha",
    "Alpharetta",
    "Alpine",
    "Alpine",
    "Alpine",
    "Alpine",
    "Alpine",
    "Alpine",
    "Alpine",
    "Alplaus",
    "Alsea",
    "Alsey",
    "Alsip",
    "Alstead",
    "Alta",
    "Alta Vista",
    "Altadena",
    "Altamont",
    "Altamont",
    "Altamont",
    "Altamont",
    "Altamont",
    "Altamonte Springs",
    "Altaville",
    "Altavista",
    "Altenburg",
    "Altha",
    "Altmar",
    "Alto",
    "Alto",
    "Alto",
    "Alto",
    "Alton",
    "Alton",
    "Alton",
    "Alton",
    "Alton",
    "Alton",
    "Alton Bay",
    "Altona",
    "Altoona",
    "Altoona",
    "Altoona",
    "Altoona",
    "Altoona",
    "Altoona",
    "Altura",
    "Alturas",
    "Alturas",
    "Altus",
    "Alum Bank",
    "Alum Creek",
    "Alva",
    "Alva",
    "Alvada",
    "Alvarado",
    "Alvaton",
    "Alverda",
    "Alverton",
    "Alvin",
    "Alvin",
    "Alviso",
    "Alvo",
    "Alvord",
    "Alvord",
    "Alvordton",
    "Ama",
    "Amagansett",
    "Amana",
    "Amanda",
    "Amargosa Valley",
    "Amarillo",
    "Amawalk",
    "Amazonia",
    "Amberg",
    "Ambler",
    "Ambler",
    "Amboy",
    "Amboy",
    "Amboy",
    "Ambridge",
    "Ambrose",
    "Amelia",
    "Amelia",
    "Amelia Court House",
    "Amenia",
    "Amenia",
    "America",
    "American Canyon",
    "American Falls",
    "American Fork",
    "Americus",
    "Americus",
    "Amery",
    "Ames",
    "Ames",
    "Amesbury",
    "Amesville",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst",
    "Amherst Junction",
    "Amherstdale",
    "Amidon",
    "Amissville",
    "Amite",
    "Amity",
    "Amity",
    "Amity",
    "Amity",
    "Amityville",
    "Ammon",
    "Amo",
    "Amoret",
    "Amory",
    "Amsterdam",
    "Amsterdam",
    "Amsterdam",
    "Amston",
    "Anacoco",
    "Anaconda",
    "Anacortes",
    "Anadarko",
    "Anaheim",
    "Anahola",
    "Anahuac",
    "Anamosa",
    "Anchor Point",
    "Anchorage",
    "Ancona",
    "Andale",
    "Andalusia",
    "Andalusia",
    "Anderson",
    "Anderson",
    "Anderson",
    "Anderson",
    "Anderson",
    "Andersonville",
    "Andersonville",
    "Andes",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andover",
    "Andreas",
    "Andrew",
    "Andrews",
    "Andrews",
    "Andrews",
    "Andrews",
    "Andrews Air Force Base",
    "Angel Fire",
    "Angelica",
    "Angie",
    "Angier",
    "Angle Inlet",
    "Angleton",
    "Angola",
    "Angola",
    "Angoon",
    "Angora",
    "Anguilla",
    "Angwin",
    "Animas",
    "Anita",
    "Aniwa",
    "Ankeny",
    "Anmoore",
    "Ann Arbor",
    "Anna",
    "Anna",
    "Anna",
    "Annabella",
    "Annandale",
    "Annandale",
    "Annandale",
    "Annandale-on-Hudson",
    "Annapolis",
    "Annawan",
    "Anniston",
    "Annona",
    "Annville",
    "Annville",
    "Anoka",
    "Anselmo",
    "Ansley",
    "Anson",
    "Anson",
    "Ansonia",
    "Ansted",
    "Antelope",
    "Antelope",
    "Anthem",
    "Anthon",
    "Anthony",
    "Anthony",
    "Anthony",
    "Anthony",
    "Antigo",
    "Antioch",
    "Antioch",
    "Antioch",
    "Antioch",
    "Antlers",
    "Antrim",
    "Antwerp",
    "Anza",
    "Apache",
    "Apache Junction",
    "Apalachicola",
    "Apalachin",
    "Apex",
    "Apison",
    "Aplington",
    "Apollo",
    "Apollo Beach",
    "Apopka",
    "Appalachia",
    "Apple Creek",
    "Apple River",
    "Apple Valley",
    "Applegate",
    "Applegate",
    "Appleton",
    "Appleton",
    "Appleton",
    "Appleton City",
    "Appling",
    "Appomattox",
    "Aptos",
    "Aquasco",
    "Aquashicola",
    "Aquebogue",
    "Aquilla",
    "Arab",
    "Arabi",
    "Arabi",
    "Aragon",
    "Aransas Pass",
    "Arapaho",
    "Arapahoe",
    "Arapahoe",
    "Arapahoe",
    "Ararat",
    "Ararat",
    "Arbela",
    "Arbon",
    "Arbovale",
    "Arbuckle",
    "Arbyrd",
    "Arcade",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcadia",
    "Arcanum",
    "Arcata",
    "Archbald",
    "Archbold",
    "Archer",
    "Archer City",
    "Archie",
    "Arco",
    "Arco",
    "Arcola",
    "Arcola",
    "Arden",
    "Ardmore",
    "Ardmore",
    "Ardmore",
    "Ardmore",
    "Ardsley",
    "Aredale",
    "Arena",
    "Arenas Valley",
    "Arendtsville",
    "Arenzville",
    "Argenta",
    "Argillite",
    "Argonia",
    "Argonne",
    "Argos",
    "Argusville",
    "Argyle",
    "Argyle",
    "Argyle",
    "Argyle",
    "Argyle",
    "Ariel",
    "Arimo",
    "Arispe",
    "Ariton",
    "Arivaca",
    "Arizona City",
    "Arkadelphia",
    "Arkansas City",
    "Arkansas City",
    "Arkdale",
    "Arkoma",
    "Arkville",
    "Arlee",
    "Arley",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington",
    "Arlington Heights",
    "Arma",
    "Armada",
    "Armagh",
    "Armington",
    "Armona",
    "Armonk",
    "Armour",
    "Armstrong",
    "Armstrong",
    "Armuchee",
    "Arnaudville",
    "Arnegard",
    "Arnett",
    "Arnett",
    "Arnold",
    "Arnold",
    "Arnold",
    "Arnold",
    "Arnoldsburg",
    "Arnoldsville",
    "Aroma Park",
    "Aromas",
    "Aroostook Band of Micmac Trust Land",
    "Arp",
    "Arpin",
    "Arriba",
    "Arrington",
    "Arrington",
    "Arrowsmith",
    "Arroyo Grande",
    "Arroyo Hondo",
    "Arroyo Seco",
    "Artesia",
    "Artesia",
    "Artesian",
    "Arthur",
    "Arthur",
    "Arthur City",
    "Arvada",
    "Arvada",
    "Arverne",
    "Arvin",
    "Arvonia",
    "Ary",
    "Asbury",
    "Asbury",
    "Asbury Park",
    "Ascutney",
    "Ash",
    "Ash Flat",
    "Ash Fork",
    "Ash Grove",
    "Ash Hill",
    "Ashaway",
    "Ashburn",
    "Ashburn",
    "Ashburnham",
    "Ashby",
    "Ashby",
    "Ashcamp",
    "Ashdown",
    "Asheboro",
    "Asherton",
    "Asheville",
    "Ashfield",
    "Ashford",
    "Ashford",
    "Ashkum",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland",
    "Ashland City",
    "Ashley",
    "Ashley",
    "Ashley",
    "Ashley",
    "Ashley",
    "Ashmore",
    "Ashtabula",
    "Ashton",
    "Ashton",
    "Ashton",
    "Ashton",
    "Ashville",
    "Ashville",
    "Ashville",
    "Askov",
    "Asotin",
    "Aspen",
    "Aspermont",
    "Aspers",
    "Assaria",
    "Assonet",
    "Assumption",
    "Astatula",
    "Aston Arms",
    "Astor",
    "Astoria",
    "Astoria",
    "Astoria",
    "Atalissa",
    "Atascadero",
    "Atascosa",
    "Atchison",
    "Atco",
    "Atglen",
    "Athelstane",
    "Athena",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Athens",
    "Atherton",
    "Athol",
    "Athol",
    "Athol",
    "Atkins",
    "Atkins",
    "Atkins",
    "Atkinson",
    "Atkinson",
    "Atkinson",
    "Atlanta",
    "Atlanta",
    "Atlanta",
    "Atlanta",
    "Atlanta",
    "Atlanta",
    "Atlanta",
    "Atlantic",
    "Atlantic",
    "Atlantic",
    "Atlantic Beach",
    "Atlantic Beach",
    "Atlantic Beach",
    "Atlantic City",
    "Atlantic Highlands",
    "Atlantic Mine",
    "Atlasburg",
    "Atmore",
    "Atoka",
    "Atoka",
    "Atqasuk",
    "Attalla",
    "Attapulgus",
    "Attica",
    "Attica",
    "Attica",
    "Attica",
    "Attica",
    "Attleboro",
    "Attleboro Falls",
    "Atwater",
    "Atwater",
    "Atwater",
    "Atwood",
    "Atwood",
    "Atwood",
    "Au Gres",
    "Au Sable Forks",
    "Au Train",
    "Auberry",
    "Aubrey",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn",
    "Auburn Hills",
    "Auburndale",
    "Auburndale",
    "Auburndale",
    "Audubon",
    "Audubon",
    "Audubon",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Augusta",
    "Aulander",
    "Ault",
    "Aumsville",
    "Aurelia",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Aurora",
    "Austell",
    "Austerlitz",
    "Austin",
    "Austin",
    "Austin",
    "Austin",
    "Austin",
    "Austin",
    "Austinburg",
    "Austinville",
    "Autaugaville",
    "Autryville",
    "Auxier",
    "Auxvasse",
    "Ava",
    "Ava",
    "Ava",
    "Ava",
    "Avalon",
    "Avalon",
    "Avalon",
    "Avalon",
    "Avella",
    "Avenal",
    "Avenel",
    "Aventura",
    "Averill Park",
    "Avery",
    "Avery",
    "Avila Beach",
    "Avilla",
    "Avinger",
    "Aviston",
    "Avoca",
    "Avoca",
    "Avoca",
    "Avoca",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon",
    "Avon Lake",
    "Avon Park",
    "Avon-by-the-Sea",
    "Avondale",
    "Avondale",
    "Avondale",
    "Avondale Estates",
    "Avonmore",
    "Awendaw",
    "Axis",
    "Axtell",
    "Axtell",
    "Axtell",
    "Axtell",
    "Axton",
    "Ayden",
    "Ayer",
    "Aylett",
    "Aynor",
    "Azalea",
    "Azle",
    "Aztec",
    "Azusa",
    "Babb",
    "Babbitt",
    "Babcock",
    "Babson Park",
    "Babson Park",
    "Babylon",
    "Backus",
    "Bacliff",
    "Bad Axe",
    "Baden",
    "Badger",
    "Badger",
    "Badin",
    "Bagdad",
    "Bagdad",
    "Bagdad",
    "Baggs",
    "Bagley",
    "Bagley",
    "Bagwell",
    "Bahama",
    "Bailey",
    "Bailey",
    "Bailey",
    "Bailey Island",
    "Baileys Harbor",
    "Baileyton",
    "Baileyville",
    "Bainbridge",
    "Bainbridge",
    "Bainbridge",
    "Bainbridge",
    "Bainbridge",
    "Bainbridge Island",
    "Bainville",
    "Baird",
    "Bairdford",
    "Baisden",
    "Baker",
    "Baker",
    "Baker",
    "Baker",
    "Baker",
    "Baker City",
    "Bakersfield",
    "Bakersfield",
    "Bakersfield",
    "Bakersville",
    "Bakewell",
    "Bala-Cynwyd",
    "Balaton",
    "Bald Knob",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin",
    "Baldwin City",
    "Baldwin Park",
    "Baldwin Place",
    "Baldwinsville",
    "Baldwinville",
    "Baldwyn",
    "Ball",
    "Ball Ground",
    "Ballantine",
    "Ballico",
    "Ballinger",
    "Ballston Lake",
    "Ballston Spa",
    "Ballwin",
    "Bally",
    "Balsam Lake",
    "Baltic",
    "Baltic",
    "Baltic",
    "Baltimore",
    "Baltimore",
    "Bamberg",
    "Bancroft",
    "Bancroft",
    "Bancroft",
    "Bancroft",
    "Bandera",
    "Bandon",
    "Bangor",
    "Bangor",
    "Bangor",
    "Bangor",
    "Bangs",
    "Banks",
    "Banks",
    "Banks",
    "Bankston",
    "Banner",
    "Banner Elk",
    "Banning",
    "Bannister",
    "Banquete",
    "Bantam",
    "Bapchule",
    "Baptistown",
    "Bar Harbor",
    "Baraboo",
    "Baraga",
    "Barbeau",
    "Barberton",
    "Barboursville",
    "Barboursville",
    "Barbourville",
    "Barclay",
    "Bardstown",
    "Bardwell",
    "Bardwell",
    "Bargersville",
    "Baring",
    "Baring",
    "Bark River",
    "Barkhamsted",
    "Barling",
    "Barlow",
    "Barnard",
    "Barnard",
    "Barnardsville",
    "Barnegat",
    "Barnes",
    "Barnes City",
    "Barnesville",
    "Barnesville",
    "Barnesville",
    "Barnesville",
    "Barnet",
    "Barneveld",
    "Barney",
    "Barnhart",
    "Barnsdall",
    "Barnstable",
    "Barnstead",
    "Barnum",
    "Barnwell",
    "Baroda",
    "Barre",
    "Barre",
    "Barren Springs",
    "Barrett",
    "Barrington",
    "Barrington",
    "Barrington",
    "Barrington",
    "Barron",
    "Barronett",
    "Barrow",
    "Barry",
    "Barry",
    "Barryton",
    "Barryville",
    "Barstow",
    "Bart Township",
    "Bartelso",
    "Bartlesville",
    "Bartlett",
    "Bartlett",
    "Bartlett",
    "Bartley",
    "Barto",
    "Barton",
    "Barton",
    "Barton",
    "Bartonsville",
    "Bartow",
    "Bartow",
    "Bartow",
    "Basalt",
    "Basalt",
    "Bascom",
    "Basehor",
    "Basile",
    "Basin",
    "Basin",
    "Basking Ridge",
    "Bassett",
    "Bassett",
    "Bassfield",
    "Bastrop",
    "Bastrop",
    "Basye",
    "Batavia",
    "Batavia",
    "Batavia",
    "Batavia",
    "Batchelor",
    "Batchtown",
    "Bates City",
    "Batesburg",
    "Batesville",
    "Batesville",
    "Batesville",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bath",
    "Bathgate",
    "Baton Rouge",
    "Batson",
    "Battle Creek",
    "Battle Creek",
    "Battle Creek",
    "Battle Ground",
    "Battle Ground",
    "Battle Lake",
    "Battle Mountain",
    "Battleboro",
    "Baudette",
    "Bausman",
    "Bauxite",
    "Baxley",
    "Baxter",
    "Baxter",
    "Baxter",
    "Baxter",
    "Baxter Springs",
    "Bay City",
    "Bay City",
    "Bay City",
    "Bay City",
    "Bay Minette",
    "Bay Port",
    "Bay Saint Louis",
    "Bay Shore",
    "Bay Springs",
    "Bay Village",
    "Bayard",
    "Bayard",
    "Bayboro",
    "Bayfield",
    "Bayfield",
    "Bayonne",
    "Bayport",
    "Bayport",
    "Bayside",
    "Bayside",
    "Baytown",
    "Bayview",
    "Bayville",
    "Bayville",
    "Bazine",
    "Beach",
    "Beach City",
    "Beach Haven",
    "Beach Lake",
    "Beachwood",
    "Beachwood",
    "Beacon",
    "Beacon",
    "Beacon Falls",
    "Bealeton",
    "Beaman",
    "Bean Station",
    "Beans Purchase",
    "Bear",
    "Bear Creek",
    "Bear Creek",
    "Bear Creek",
    "Bear Lake",
    "Bear Lake",
    "Bear River City",
    "Bearden",
    "Beardstown",
    "Bearsville",
    "Beasley",
    "Beatrice",
    "Beattie",
    "Beatty",
    "Beattyville",
    "Beaufort",
    "Beaufort",
    "Beaufort",
    "Beaumont",
    "Beaumont",
    "Beaumont",
    "Beaumont",
    "Beauty",
    "Beaver",
    "Beaver",
    "Beaver",
    "Beaver",
    "Beaver",
    "Beaver",
    "Beaver Bay",
    "Beaver Creek",
    "Beaver Creek",
    "Beaver Crossing",
    "Beaver Dam",
    "Beaver Dam",
    "Beaver Falls",
    "Beaver Meadows",
    "Beaver Springs",
    "Beaverdam",
    "Beaverton",
    "Beaverton",
    "Beavertown",
    "Bechtelsville",
    "Beckemeyer",
    "Becker",
    "Becket",
    "Beckley",
    "Beckville",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford",
    "Bedford Hills",
    "Bedford Park",
    "Bedias",
    "Bedminster",
    "Bee Branch",
    "Bee Spring",
    "Beebe",
    "Beech Bluff",
    "Beech Bottom",
    "Beech Creek",
    "Beech Grove",
    "Beech Island",
    "Beecher",
    "Beecher City",
    "Beechgrove",
    "Beechmont",
    "Beeville",
    "Beggs",
    "Bel Air",
    "Bel Alton",
    "Belcamp",
    "Belchertown",
    "Belcourt",
    "Belden",
    "Beldenville",
    "Belding",
    "Belen",
    "Belfair",
    "Belfast",
    "Belfast",
    "Belfield",
    "Belford",
    "Belfry",
    "Belfry",
    "Belgium",
    "Belgrade",
    "Belgrade",
    "Belgrade",
    "Belgrade",
    "Belhaven",
    "Belington",
    "Belknap",
    "Bell",
    "Bell",
    "Bell Buckle",
    "Bell City",
    "Bell Island Hot Springs",
    "Bella Vista",
    "Bella Vista",
    "Bellaire",
    "Bellaire",
    "Bellaire",
    "Bellbrook",
    "Belle",
    "Belle",
    "Belle Center",
    "Belle Chasse",
    "Belle Fourche",
    "Belle Glade",
    "Belle Haven",
    "Belle Mead",
    "Belle Plaine",
    "Belle Plaine",
    "Belle Plaine",
    "Belle Rose",
    "Belle Valley",
    "Belle Vernon",
    "Belleair Beach",
    "Bellefontaine",
    "Bellefontaine",
    "Bellefonte",
    "Bellemont",
    "Bellerose",
    "Belleview",
    "Belleville",
    "Belleville",
    "Belleville",
    "Belleville",
    "Belleville",
    "Belleville",
    "Belleville",
    "Belleville",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellevue",
    "Bellflower",
    "Bellflower",
    "Bellflower",
    "Bellingham",
    "Bellingham",
    "Bellingham",
    "Bellmawr",
    "Bellmore",
    "Bellows Falls",
    "Bellport",
    "Bells",
    "Bells",
    "Bellville",
    "Bellville",
    "Bellvue",
    "Bellwood",
    "Bellwood",
    "Bellwood",
    "Belmar",
    "Belmond",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Belmont",
    "Beloit",
    "Beloit",
    "Beloit",
    "Belpre",
    "Belt",
    "Belton",
    "Belton",
    "Belton",
    "Beltrami",
    "Beltsville",
    "Belvidere",
    "Belvidere",
    "Belvidere",
    "Belvidere Center",
    "Belview",
    "Belvue",
    "Belzoni",
    "Bement",
    "Bemidji",
    "Bemus Point",
    "Ben Lomond",
    "Ben Wheeler",
    "Bena",
    "Benavides",
    "Bend",
    "Bendersville",
    "Benedict",
    "Benedict",
    "Benham",
    "Benicia",
    "Benkelman",
    "Benld",
    "Bennet",
    "Bennett",
    "Bennett",
    "Bennettsville",
    "Bennington",
    "Bennington",
    "Bennington",
    "Bennington",
    "Bennington",
    "Benoit",
    "Bensalem",
    "Bensenville",
    "Benson",
    "Benson",
    "Benson",
    "Benson",
    "Bent",
    "Bent Mountain",
    "Bentleyville",
    "Benton",
    "Benton",
    "Benton",
    "Benton",
    "Benton",
    "Benton",
    "Benton",
    "Benton",
    "Benton City",
    "Benton Harbor",
    "Benton Ridge",
    "Bentonia",
    "Bentonville",
    "Bentonville",
    "Benwood",
    "Benzonia",
    "Berea",
    "Berea",
    "Berea",
    "Beresford",
    "Bergen",
    "Bergenfield",
    "Berger",
    "Bergheim",
    "Berkeley",
    "Berkeley",
    "Berkeley Heights",
    "Berkeley Springs",
    "Berkley",
    "Berkley",
    "Berkshire",
    "Berkshire",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin",
    "Berlin Center",
    "Berlin Heights",
    "Bern",
    "Bernalillo",
    "Bernard",
    "Bernard",
    "Bernardston",
    "Bernardsville",
    "Berne",
    "Berne",
    "Bernice",
    "Bernie",
    "Bernville",
    "Berrien Center",
    "Berrien Springs",
    "Berry",
    "Berry",
    "Berry Creek",
    "Berrysburg",
    "Berryton",
    "Berryville",
    "Berryville",
    "Bertha",
    "Berthoud",
    "Bertram",
    "Bertrand",
    "Berwick",
    "Berwick",
    "Berwick",
    "Berwind",
    "Berwyn",
    "Berwyn",
    "Beryl",
    "Bessemer",
    "Bessemer",
    "Bessemer",
    "Bessemer City",
    "Bethalto",
    "Bethania",
    "Bethany",
    "Bethany",
    "Bethany",
    "Bethany",
    "Bethany",
    "Bethany Beach",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel",
    "Bethel Island",
    "Bethel Park",
    "Bethel Springs",
    "Bethesda",
    "Bethesda",
    "Bethlehem",
    "Bethlehem",
    "Bethlehem",
    "Bethlehem",
    "Bethlehem",
    "Bethpage",
    "Bethpage",
    "Bethune",
    "Betsy Layne",
    "Bettendorf",
    "Beulah",
    "Beulah",
    "Beulah",
    "Beulah",
    "Beulaville",
    "Beverly",
    "Beverly",
    "Beverly",
    "Beverly",
    "Beverly Hills",
    "Beverly Hills",
    "Bevington",
    "Bickmore",
    "Bicknell",
    "Bicknell",
    "Biddeford",
    "Bidwell",
    "Bieber",
    "Big Bay",
    "Big Bear",
    "Big Bear Lake",
    "Big Bend",
    "Big Cabin",
    "Big Cove Tannery",
    "Big Creek",
    "Big Creek",
    "Big Creek",
    "Big Falls",
    "Big Flats",
    "Big Horn",
    "Big Lake",
    "Big Lake",
    "Big Lake",
    "Big Pine",
    "Big Pine Key",
    "Big Piney",
    "Big Pool",
    "Big Rapids",
    "Big Rock",
    "Big Rock",
    "Big Sandy",
    "Big Sandy",
    "Big Sandy",
    "Big Sky",
    "Big Spring",
    "Big Springs",
    "Big Stone City",
    "Big Stone Gap",
    "Big Sur",
    "Big Timber",
    "Big Wells",
    "Bigbend",
    "Bigelow",
    "Bigelow",
    "Bigfoot",
    "Bigfork",
    "Bigfork",
    "Biggs",
    "Biggsville",
    "Biglerville",
    "Bill",
    "Billerica",
    "Billings",
    "Billings",
    "Biloxi",
    "Bim",
    "Binford",
    "Bingen",
    "Binger",
    "Bingham Lake",
    "Binghamton",
    "Biola",
    "Bippus",
    "Birch River",
    "Birch Run",
    "Birch Tree",
    "Birchleaf",
    "Birchwood",
    "Birchwood",
    "Bird City",
    "Bird in Hand",
    "Bird Island",
    "Birdsboro",
    "Birdseye",
    "Birdsnest",
    "Birmingham",
    "Birmingham",
    "Birmingham",
    "Birmingham",
    "Birnamwood",
    "Bisbee",
    "Biscoe",
    "Bishop",
    "Bishop",
    "Bishop",
    "Bishopville",
    "Bishopville",
    "Bismarck",
    "Bismarck",
    "Bismarck",
    "Bismarck",
    "Bison",
    "Bitely",
    "Bivalve",
    "Bivins",
    "Biwabik",
    "Bixby",
    "Bixby",
    "Blachly",
    "Black Canyon City",
    "Black Creek",
    "Black Creek",
    "Black Diamond",
    "Black Eagle",
    "Black Earth",
    "Black Hawk",
    "Black Mountain",
    "Black River",
    "Black River",
    "Black River Falls",
    "Black Rock",
    "Blackduck",
    "Blackfoot",
    "Blackhawk",
    "Blacklick",
    "Blacksburg",
    "Blacksburg",
    "Blackshear",
    "Blackstock",
    "Blackstone",
    "Blackstone",
    "Blackstone",
    "Blacksville",
    "Blackville",
    "Blackwater",
    "Blackwell",
    "Blackwell",
    "Blackwood",
    "Bladenboro",
    "Bladensburg",
    "Blain",
    "Blaine",
    "Blaine",
    "Blair",
    "Blair",
    "Blair",
    "Blairs",
    "Blairs Mills",
    "Blairsburg",
    "Blairstown",
    "Blairstown",
    "Blairsville",
    "Blairsville",
    "Blakely",
    "Blakesburg",
    "Blakeslee",
    "Blanch",
    "Blanchard",
    "Blanchard",
    "Blanchard",
    "Blanchard",
    "Blanchardville",
    "Blanchester",
    "Blanco",
    "Bland",
    "Bland",
    "Blandburg",
    "Blandford",
    "Blanding",
    "Blandinsville",
    "Blandon",
    "Blauvelt",
    "Bledsoe",
    "Blencoe",
    "Blenheim",
    "Blenker",
    "Blessing",
    "Bliss",
    "Bliss",
    "Blissfield",
    "Block Island",
    "Blockton",
    "Blodgett",
    "Blodgett Mills",
    "Bloomburg",
    "Bloomdale",
    "Bloomer",
    "Bloomery",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield",
    "Bloomfield Hills",
    "Blooming Glen",
    "Blooming Grove",
    "Blooming Prairie",
    "Bloomingburg",
    "Bloomingburg",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomingdale",
    "Bloomington",
    "Bloomington",
    "Bloomington",
    "Bloomington",
    "Bloomington",
    "Bloomington Springs",
    "Bloomsburg",
    "Bloomsbury",
    "Bloomsdale",
    "Bloomville",
    "Blossburg",
    "Blossom",
    "Blossvale",
    "Blountstown",
    "Blountsville",
    "Blountville",
    "Blowing Rock",
    "Blue",
    "Blue Ball",
    "Blue Bell",
    "Blue Creek",
    "Blue Earth",
    "Blue Eye",
    "Blue Grass",
    "Blue Hill",
    "Blue Hill",
    "Blue Island",
    "Blue Jay",
    "Blue Lake",
    "Blue Mound",
    "Blue Mounds",
    "Blue Mountain",
    "Blue Mountain Lake",
    "Blue Point",
    "Blue Rapids",
    "Blue Ridge",
    "Blue Ridge",
    "Blue Ridge",
    "Blue Ridge Summit",
    "Blue River",
    "Blue River",
    "Blue Springs",
    "Blue Springs",
    "Blue Springs",
    "Bluebell",
    "Bluefield",
    "Bluefield",
    "Bluejacket",
    "Bluemont",
    "Bluewater",
    "Bluff",
    "Bluff City",
    "Bluff City",
    "Bluff Dale",
    "Bluffs",
    "Bluffton",
    "Bluffton",
    "Bluffton",
    "Bluford",
    "Blum",
    "Bly",
    "Blythe",
    "Blythe",
    "Blytheville",
    "Blythewood",
    "Boalsburg",
    "Boardman",
    "Boardman",
    "Boaz",
    "Boaz",
    "Bob White",
    "Bobtown",
    "Boca Grande",
    "Boca Raton",
    "Bode",
    "Bodega Bay",
    "Bodfish",
    "Boelus",
    "Boerne",
    "Bogalusa",
    "Bogard",
    "Bogart",
    "Bogata",
    "Boggstown",
    "Bogota",
    "Bogue Chitto",
    "Bohannon",
    "Bohemia",
    "Boiceville",
    "Boiling Springs",
    "Boiling Springs",
    "Boiling Springs",
    "Boise",
    "Boise City",
    "Boissevain",
    "Bokchito",
    "Bokeelia",
    "Bolckow",
    "Boligee",
    "Bolinas",
    "Boling",
    "Bolingbroke",
    "Bolingbrook",
    "Bolivar",
    "Bolivar",
    "Bolivar",
    "Bolivar",
    "Bolivar",
    "Bolivia",
    "Bolt",
    "Bolton",
    "Bolton",
    "Bolton",
    "Bolton Landing",
    "Bomont",
    "Bon Aqua",
    "Bonaire",
    "Bonaparte",
    "Bondsville",
    "Bonduel",
    "Bondurant",
    "Bondville",
    "Bone Gap",
    "Bonfield",
    "Bonham",
    "Bonifay",
    "Bonita",
    "Bonita",
    "Bonita Springs",
    "Bonlee",
    "Bonne Terre",
    "Bonneau",
    "Bonner Springs",
    "Bonnerdale",
    "Bonners Ferry",
    "Bonney Lake",
    "Bonnie",
    "Bonnieville",
    "Bonnots Mill",
    "Bonnyman",
    "Bono",
    "Bonsall",
    "Boody",
    "Booker",
    "Boomer",
    "Boomer",
    "Boone",
    "Boone",
    "Boones Mill",
    "Booneville",
    "Booneville",
    "Booneville",
    "Boonsboro",
    "Boonton",
    "Boonville",
    "Boonville",
    "Boonville",
    "Boonville",
    "Boothbay",
    "Boothbay Harbor",
    "Borden",
    "Bordentown",
    "Borger",
    "Boring",
    "Boron",
    "Borrego Springs",
    "Boscobel",
    "Bosque Farms",
    "Bossier City",
    "Bostic",
    "Boston",
    "Boston",
    "Boston",
    "Boston",
    "Bostwick",
    "Boswell",
    "Boswell",
    "Bosworth",
    "Bothell",
    "Botkins",
    "Bottineau",
    "Boulder",
    "Boulder",
    "Boulder City",
    "Boulder Creek",
    "Boulder Town",
    "Boulevard",
    "Bound Brook",
    "Bountiful",
    "Bourbon",
    "Bourbon",
    "Bourbonnais",
    "Bourg",
    "Boutte",
    "Bovard",
    "Bovey",
    "Bovina",
    "Bovina Center",
    "Bow",
    "Bow Center",
    "Bowdoin Center",
    "Bowdoinham",
    "Bowdon",
    "Bowerston",
    "Bowersville",
    "Bowie",
    "Bowie",
    "Bowie",
    "Bowler",
    "Bowling Green",
    "Bowling Green",
    "Bowling Green",
    "Bowling Green",
    "Bowling Green",
    "Bowling Green",
    "Bowlus",
    "Bowman",
    "Bowman",
    "Bowman",
    "Bowmanstown",
    "Bowmansville",
    "Box Elder",
    "Box Elder",
    "Box Springs",
    "Boxford",
    "Boxholm",
    "Boyce",
    "Boyce",
    "Boyceville",
    "Boyd",
    "Boyd",
    "Boyd",
    "Boyd",
    "Boyden",
    "Boyds",
    "Boydton",
    "Boyers",
    "Boyertown",
    "Boyle",
    "Boylston",
    "Boyne City",
    "Boyne Falls",
    "Boynton",
    "Boynton Beach",
    "Bozeman",
    "Braceville",
    "Bracey",
    "Brackenridge",
    "Brackettville",
    "Brackney",
    "Braddock",
    "Bradenton",
    "Bradenton Beach",
    "Bradenville",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradford",
    "Bradley",
    "Bradley",
    "Bradley",
    "Bradley",
    "Bradley",
    "Bradley Beach",
    "Bradley Junction",
    "Bradner",
    "Bradshaw",
    "Brady",
    "Brady",
    "Brady",
    "Bradyville",
    "Bragg City",
    "Braham",
    "Braidwood",
    "Brainerd",
    "Braintree",
    "Braithwaite",
    "Braman",
    "Bramwell",
    "Branch",
    "Branch Township",
    "Branchland",
    "Branchville",
    "Brandamore",
    "Brandenburg",
    "Brandon",
    "Brandon",
    "Brandon",
    "Brandon",
    "Brandon",
    "Brandon",
    "Brandt",
    "Brandy Station",
    "Brandywine",
    "Brandywine",
    "Branford",
    "Branford",
    "Branson",
    "Branson",
    "Brant",
    "Brant Lake",
    "Brantingham",
    "Braselton",
    "Brashear",
    "Brashear",
    "Brasher Falls",
    "Brasstown",
    "Brattleboro",
    "Brave",
    "Brawley",
    "Braxton",
    "Brayton",
    "Brazil",
    "Brazoria",
    "Brea",
    "Breaux Bridge",
    "Breckenridge",
    "Breckenridge",
    "Breckenridge",
    "Breckenridge",
    "Breckenridge",
    "Brecksville",
    "Breda",
    "Breese",
    "Breezewood",
    "Breezy Point",
    "Breinigsville",
    "Bremen",
    "Bremen",
    "Bremen",
    "Bremen",
    "Bremen",
    "Bremen",
    "Bremerton",
    "Bremo Bluff",
    "Brenham",
    "Brent",
    "Brentwood",
    "Brentwood",
    "Brentwood",
    "Brentwood",
    "Bretton Woods",
    "Brevard",
    "Brewer",
    "Brewerton",
    "Brewster",
    "Brewster",
    "Brewster",
    "Brewster",
    "Brewster",
    "Brewster",
    "Brewton",
    "Brian Head",
    "Briarcliff Manor",
    "Bricelyn",
    "Briceville",
    "Brick Township",
    "Bridge City",
    "Bridgehampton",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridgeport",
    "Bridger",
    "Bridgeton",
    "Bridgeton",
    "Bridgeview",
    "Bridgeville",
    "Bridgeville",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater",
    "Bridgewater Corners",
    "Bridgman",
    "Bridgton",
    "Brielle",
    "Brier Hill",
    "Brierfield",
    "Brigantine",
    "Briggs",
    "Briggsdale",
    "Brigham City",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brighton",
    "Brightwaters",
    "Brightwood",
    "Brightwood",
    "Brilliant",
    "Brilliant",
    "Brillion",
    "Brimfield",
    "Brimfield",
    "Brimley",
    "Bringhurst",
    "Brinkley",
    "Brisbane",
    "Briscoe",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristol",
    "Bristolville",
    "Bristow",
    "Bristow",
    "Bristow",
    "Britt",
    "Brittany",
    "Britton",
    "Britton",
    "Broad Brook",
    "Broad Run",
    "Broadalbin",
    "Broaddus",
    "Broadlands",
    "Broadus",
    "Broadview",
    "Broadview Heights",
    "Broadway",
    "Broadway",
    "Broadway",
    "Brockport",
    "Brockport",
    "Brockton",
    "Brockway",
    "Brocton",
    "Brodhead",
    "Brodhead",
    "Brodheadsville",
    "Brodnax",
    "Brogue",
    "Brohard",
    "Broken Arrow",
    "Broken Bow",
    "Broken Bow",
    "Bronaugh",
    "Bronson",
    "Bronson",
    "Bronson",
    "Bronson",
    "Bronston",
    "Bronte",
    "Bronwood",
    "Bronxville",
    "Brook",
    "Brook Park",
    "Brook Park",
    "Brookdale",
    "Brookeland",
    "Brooker",
    "Brookeville",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookfield",
    "Brookhaven",
    "Brookhaven",
    "Brookhaven",
    "Brookings",
    "Brookings",
    "Brookland",
    "Brooklandville",
    "Brooklet",
    "Brooklin",
    "Brookline",
    "Brookline",
    "Brookline",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brooklyn",
    "Brookport",
    "Brooks",
    "Brooks",
    "Brooks",
    "Brooks",
    "Brooks",
    "Brookshire",
    "Brookston",
    "Brookston",
    "Brookston",
    "Brooksville",
    "Brooksville",
    "Brooksville",
    "Brooksville",
    "Brookton",
    "Brooktondale",
    "Brookville",
    "Brookville",
    "Brookville",
    "Brookville",
    "Brookwood",
    "Broomall",
    "Broomfield",
    "Brooten",
    "Broseley",
    "Broughton",
    "Broussard",
    "Browder",
    "Brown City",
    "Brownell",
    "Brownfield",
    "Brownfield",
    "Browning",
    "Browning",
    "Browns Mills",
    "Browns Summit",
    "Browns Valley",
    "Browns Valley",
    "Brownsboro",
    "Brownsboro",
    "Brownsburg",
    "Brownsdale",
    "Brownstown",
    "Brownstown",
    "Brownstown",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Brownsville",
    "Browntown",
    "Brownville",
    "Brownville",
    "Brownwood",
    "Broxton",
    "Bruce",
    "Bruce",
    "Bruceton",
    "Bruceton Mills",
    "Bruceville",
    "Bruin",
    "Brule",
    "Brule",
    "Brundidge",
    "Bruner",
    "Bruni",
    "Bruning",
    "Bruno",
    "Brunsville",
    "Brunswick",
    "Brunswick",
    "Brunswick",
    "Brunswick",
    "Brunswick",
    "Brunswick",
    "Brunswick",
    "Brush",
    "Brush Prairie",
    "Brushton",
    "Brusly",
    "Brussels",
    "Bryan",
    "Bryan",
    "Bryans Road",
    "Bryant",
    "Bryant",
    "Bryant",
    "Bryant",
    "Bryant",
    "Bryant Pond",
    "Bryantville",
    "Bryce Canyon",
    "Bryce Canyon City",
    "Bryceville",
    "Bryn Athyn",
    "Bryn Mawr",
    "Bryson",
    "Bryson City",
    "Buchanan",
    "Buchanan",
    "Buchanan",
    "Buchanan",
    "Buchanan",
    "Buchanan Dam",
    "Buckatunna",
    "Buckeye",
    "Buckeystown",
    "Buckfield",
    "Buckhannon",
    "Buckhead",
    "Buckholts",
    "Buckingham",
    "Buckingham",
    "Buckingham Township",
    "Buckland",
    "Buckland",
    "Buckley",
    "Buckley",
    "Bucklin",
    "Bucklin",
    "Buckner",
    "Buckner",
    "Buckner",
    "Bucksport",
    "Bucyrus",
    "Bucyrus",
    "Bud",
    "Buda",
    "Buda",
    "Budd Lake",
    "Bude",
    "Buellton",
    "Buena",
    "Buena Park",
    "Buena Vista",
    "Buena Vista",
    "Buena Vista",
    "Buena Vista",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo",
    "Buffalo Center",
    "Buffalo Gap",
    "Buffalo Grove",
    "Buffalo Lake",
    "Buffalo Valley",
    "Buford",
    "Buhl",
    "Buhler",
    "Buies Creek",
    "Bulan",
    "Bulger",
    "Bull Shoals",
    "Bullard",
    "Bullhead",
    "Bullhead City",
    "Bullock",
    "Bulls Gap",
    "Bullville",
    "Bulpitt",
    "Bulverde",
    "Bumpass",
    "Bumpus Mills",
    "Buna",
    "Bunceton",
    "Bunch",
    "Buncombe",
    "Bunker Hill",
    "Bunker Hill",
    "Bunker Hill",
    "Bunkie",
    "Bunn",
    "Bunnell",
    "Bunnlevel",
    "Bunola",
    "Buras",
    "Burbank",
    "Burbank",
    "Burbank",
    "Burbank",
    "Burbank",
    "Burden",
    "Burdett",
    "Burdett",
    "Burdick",
    "Bureau",
    "Burgaw",
    "Burgess",
    "Burgettstown",
    "Burgin",
    "Burkburnett",
    "Burke",
    "Burke",
    "Burkesville",
    "Burket",
    "Burkeville",
    "Burkeville",
    "Burleson",
    "Burley",
    "Burley",
    "Burlingame",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington",
    "Burlington Flats",
    "Burlington Junction",
    "Burlison",
    "Burnet",
    "Burnettsville",
    "Burney",
    "Burneyville",
    "Burnham",
    "Burnham",
    "Burns",
    "Burns",
    "Burns",
    "Burns",
    "Burns Flat",
    "Burnside",
    "Burnside",
    "Burnsville",
    "Burnsville",
    "Burnsville",
    "Burnsville",
    "Burnt Hills",
    "Burr",
    "Burr Hill",
    "Burrton",
    "Burt",
    "Burt",
    "Burt",
    "Burton",
    "Burton",
    "Burton",
    "Burton",
    "Burton",
    "Burtonsville",
    "Burtrum",
    "Burwell",
    "Busby",
    "Bush",
    "Bushkill",
    "Bushland",
    "Bushnell",
    "Bushnell",
    "Bushton",
    "Buskirk",
    "Busy",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butler",
    "Butlerville",
    "Butner",
    "Butte",
    "Butte",
    "Butte",
    "Butte des Morts",
    "Butterfield",
    "Butternut",
    "Buttonwillow",
    "Buxton",
    "Buxton",
    "Buxton",
    "Buzzards Bay",
    "Byers",
    "Byers",
    "Byesville",
    "Byfield",
    "Byhalia",
    "Bylas",
    "Bynum",
    "Byram",
    "Byrdstown",
    "Byron",
    "Byron",
    "Byron",
    "Byron",
    "Byron",
    "Byron",
    "Byron",
    "Byron",
    "Byron Center",
    "Cabazon",
    "Cabery",
    "Cabin Creek",
    "Cabin John",
    "Cable",
    "Cable",
    "Cabool",
    "Cabot",
    "Cabot",
    "Cabot",
    "Cache",
    "Cactus",
    "Caddo",
    "Caddo Gap",
    "Caddo Mills",
    "Cade",
    "Cades",
    "Cadet",
    "Cadillac",
    "Cadiz",
    "Cadiz",
    "Cadott",
    "Cadyville",
    "Cainsville",
    "Cairnbrook",
    "Cairo",
    "Cairo",
    "Cairo",
    "Cairo",
    "Cairo",
    "Cairo",
    "Calabasas",
    "Calabash",
    "Calais",
    "Calamus",
    "Calder",
    "Caldwell",
    "Caldwell",
    "Caldwell",
    "Caldwell",
    "Caldwell",
    "Caledonia",
    "Caledonia",
    "Caledonia",
    "Caledonia",
    "Caledonia",
    "Caledonia",
    "Caledonia",
    "Calera",
    "Calera",
    "Calexico",
    "Calhan",
    "Calhoun",
    "Calhoun",
    "Calhoun",
    "Calhoun",
    "Calhoun",
    "Calhoun City",
    "Calhoun Falls",
    "Calico Rock",
    "Caliente",
    "Caliente",
    "Califon",
    "California",
    "California",
    "California",
    "California",
    "California City",
    "Calimesa",
    "Calipatria",
    "Calistoga",
    "Callahan",
    "Callao",
    "Callao",
    "Callaway",
    "Callaway",
    "Callicoon",
    "Calmar",
    "Calpella",
    "Calpine",
    "Calumet",
    "Calumet",
    "Calumet",
    "Calumet City",
    "Calvert",
    "Calvert",
    "Calvert City",
    "Calverton",
    "Calypso",
    "Camanche",
    "Camano",
    "Camarillo",
    "Camas",
    "Cambria",
    "Cambria",
    "Cambria Heights",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge",
    "Cambridge City",
    "Cambridge Springs",
    "Cambridgeport",
    "Camby",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden",
    "Camden Point",
    "Camdenton",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron",
    "Cameron Mills",
    "Camilla",
    "Camillus",
    "Camino",
    "Camp Creek",
    "Camp Dennison",
    "Camp Douglas",
    "Camp H M Smith",
    "Camp Hill",
    "Camp Meeker",
    "Camp Point",
    "Camp Verde",
    "Camp Wood",
    "Campbell",
    "Campbell",
    "Campbell",
    "Campbell",
    "Campbell",
    "Campbell",
    "Campbell",
    "Campbell Hall",
    "Campbell Hill",
    "Campbellsburg",
    "Campbellsburg",
    "Campbellsport",
    "Campbellsville",
    "Campbelltown",
    "Campo",
    "Campobello",
    "Campti",
    "Campton",
    "Campton Upper Village",
    "Campus",
    "Cana",
    "Canaan",
    "Canaan",
    "Canaan",
    "Canaan",
    "Canaan",
    "Canada",
    "Canadensis",
    "Canadian",
    "Canadian",
    "Canajoharie",
    "Canal Fulton",
    "Canal Point",
    "Canal Winchester",
    "Canalou",
    "Canandaigua",
    "Canastota",
    "Canby",
    "Canby",
    "Candia",
    "Candler",
    "Cando",
    "Candor",
    "Candor",
    "Canehill",
    "Caney",
    "Caney",
    "Caneyville",
    "Canfield",
    "Canisteo",
    "Canmer",
    "Cannel City",
    "Cannelton",
    "Cannon",
    "Cannon Falls",
    "Cannonville",
    "Canoga Park",
    "Canon",
    "Canon City",
    "Canonsburg",
    "Canterbury",
    "Canterbury",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Canton",
    "Cantonment",
    "Cantrall",
    "Canute",
    "Canutillo",
    "Canvas",
    "Canyon",
    "Canyon",
    "Canyon",
    "Canyon City",
    "Canyon Country",
    "Canyon Lake",
    "Canyonville",
    "Capac",
    "Cape Canaveral",
    "Cape Charles",
    "Cape Coral",
    "Cape Elizabeth",
    "Cape Fair",
    "Cape Girardeau",
    "Cape May",
    "Cape May Court House",
    "Cape May Point",
    "Cape Neddick",
    "Cape Porpoise",
    "Cape Vincent",
    "Capeville",
    "Capistrano Beach",
    "Capitan",
    "Capitola",
    "Capon Bridge",
    "Capron",
    "Capron",
    "Captain Cook",
    "Captiva",
    "Caputa",
    "Caraway",
    "Carbon",
    "Carbon Hill",
    "Carbon Hill",
    "Carbonado",
    "Carbondale",
    "Carbondale",
    "Carbondale",
    "Carbondale",
    "Cardale",
    "Cardington",
    "Carefree",
    "Carencro",
    "Carey",
    "Careywood",
    "Caribou",
    "Carl Junction",
    "Carle Place",
    "Carleton",
    "Carlin",
    "Carlinville",
    "Carlisle",
    "Carlisle",
    "Carlisle",
    "Carlisle",
    "Carlisle",
    "Carlisle",
    "Carlisle",
    "Carlock",
    "Carlos",
    "Carlotta",
    "Carlsbad",
    "Carlsbad",
    "Carlsborg",
    "Carlstadt",
    "Carlton",
    "Carlton",
    "Carlton",
    "Carlton",
    "Carlyle",
    "Carman",
    "Carmel",
    "Carmel",
    "Carmel",
    "Carmel",
    "Carmel Valley",
    "Carmen",
    "Carmi",
    "Carmichael",
    "Carmichaels",
    "Carmine",
    "Carnation",
    "Carnegie",
    "Carnegie",
    "Carnesville",
    "Carney",
    "Carney",
    "Caro",
    "Caroga Lake",
    "Carol Stream",
    "Carolina",
    "Carolina",
    "Carolina Beach",
    "Carp Lake",
    "Carpentersville",
    "Carpinteria",
    "Carr",
    "Carrabelle",
    "Carrboro",
    "Carrie",
    "Carrier",
    "Carrier Mills",
    "Carriere",
    "Carrington",
    "Carrizo Springs",
    "Carrizozo",
    "Carroll",
    "Carroll",
    "Carroll",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrollton",
    "Carrolltown",
    "Carrsville",
    "Carson",
    "Carson",
    "Carson",
    "Carson",
    "Carson",
    "Carson City",
    "Carson City",
    "Carsonville",
    "Carter Lake",
    "Carteret",
    "Cartersville",
    "Carterville",
    "Carterville",
    "Carthage",
    "Carthage",
    "Carthage",
    "Carthage",
    "Carthage",
    "Carthage",
    "Carthage",
    "Carthage",
    "Cartwright",
    "Caruthers",
    "Caruthersville",
    "Carver",
    "Carver",
    "Cary",
    "Cary",
    "Caryville",
    "Caryville",
    "Casa Grande",
    "Casanova",
    "Casar",
    "Cascade",
    "Cascade",
    "Cascade",
    "Cascade",
    "Cascade",
    "Cascade Locks",
    "Cascilla",
    "Casco",
    "Casco",
    "Casco",
    "Caseville",
    "Casey",
    "Casey",
    "Caseyville",
    "Cashiers",
    "Cashion",
    "Cashion",
    "Cashmere",
    "Cashton",
    "Cashtown",
    "Casnovia",
    "Casper",
    "Caspian",
    "Cass City",
    "Cass Lake",
    "Cassadaga",
    "Cassatt",
    "Cassel",
    "Casselberry",
    "Casselton",
    "Cassoday",
    "Cassopolis",
    "Casstown",
    "Cassville",
    "Cassville",
    "Castaic",
    "Castalia",
    "Castalian Springs",
    "Castile",
    "Castine",
    "Castle Dale",
    "Castle Hayne",
    "Castle Point",
    "Castle Rock",
    "Castle Rock",
    "Castleberry",
    "Castleton",
    "Castleton-on-Hudson",
    "Castlewood",
    "Castlewood",
    "Castor",
    "Castorland",
    "Castro Valley",
    "Castroville",
    "Castroville",
    "Cat Spring",
    "Cataldo",
    "Catasauqua",
    "Cataula",
    "Cataumet",
    "Catawba",
    "Catawba",
    "Catawissa",
    "Catawissa",
    "Catharpin",
    "Cathedral City",
    "Catherine",
    "Catheys Valley",
    "Cathlamet",
    "Catlett",
    "Catlettsburg",
    "Catlin",
    "Cato",
    "Catonsville",
    "Catoosa",
    "Catron",
    "Catskill",
    "Cattaraugus",
    "Caulfield",
    "Cavalier",
    "Cave City",
    "Cave City",
    "Cave Creek",
    "Cave Creek",
    "Cave Junction",
    "Cave Spring",
    "Cave Springs",
    "Cave-in-Rock",
    "Cavendish",
    "Cavour",
    "Cawood",
    "Cayce",
    "Cayucos",
    "Cayuga",
    "Cayuta",
    "Cazadero",
    "Cazenovia",
    "Cazenovia",
    "Cecil",
    "Cecil",
    "Cecil",
    "Cecil",
    "Cecilia",
    "Cecilia",
    "Cecilton",
    "Cedar",
    "Cedar",
    "Cedar Bluff",
    "Cedar Bluff",
    "Cedar Bluffs",
    "Cedar Brook",
    "Cedar City",
    "Cedar Creek",
    "Cedar Crest",
    "Cedar Falls",
    "Cedar Glen",
    "Cedar Grove",
    "Cedar Grove",
    "Cedar Grove",
    "Cedar Grove",
    "Cedar Hill",
    "Cedar Hill",
    "Cedar Hill",
    "Cedar Key",
    "Cedar Knolls",
    "Cedar Lake",
    "Cedar Lake",
    "Cedar Park",
    "Cedar Point",
    "Cedar Point",
    "Cedar Rapids",
    "Cedar Ridge",
    "Cedar Springs",
    "Cedar Vale",
    "Cedar Valley",
    "Cedarburg",
    "Cedaredge",
    "Cedarhurst",
    "Cedartown",
    "Cedarville",
    "Cedarville",
    "Cedarville",
    "Celeste",
    "Celina",
    "Celina",
    "Celina",
    "Celoron",
    "Cement",
    "Cement City",
    "Centenary",
    "Center",
    "Center",
    "Center",
    "Center",
    "Center Barnstead",
    "Center City",
    "Center Conway",
    "Center Cross",
    "Center Harbor",
    "Center Hill",
    "Center Junction",
    "Center Line",
    "Center Moriches",
    "Center Ossipee",
    "Center Point",
    "Center Point",
    "Center Point",
    "Center Point",
    "Center Point",
    "Center Ridge",
    "Center Rutland",
    "Center Sandwich",
    "Center Tuftonboro",
    "Center Valley",
    "Centerbrook",
    "Centerburg",
    "Centereach",
    "Centerfield",
    "Centerport",
    "Centerton",
    "Centertown",
    "Centertown",
    "Centerview",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centerville",
    "Centrahoma",
    "Central",
    "Central",
    "Central Bridge",
    "Central City",
    "Central City",
    "Central City",
    "Central City",
    "Central City",
    "Central Falls",
    "Central Islip",
    "Central Lake",
    "Central Point",
    "Central Square",
    "Central Valley",
    "Central Village",
    "Centralia",
    "Centralia",
    "Centralia",
    "Centralia",
    "Centre",
    "Centre Hall",
    "Centreville",
    "Centreville",
    "Centreville",
    "Centreville",
    "Centreville",
    "Centuria",
    "Century",
    "Ceres",
    "Ceres",
    "Ceresco",
    "Ceresco",
    "Cerrillos",
    "Cerritos",
    "Cerro",
    "Cerro Gordo",
    "Cerro Gordo",
    "Cerulean",
    "Chadbourn",
    "Chadds Ford",
    "Chadron",
    "Chadwick",
    "Chaffee",
    "Chaffee",
    "Chagrin Falls",
    "Chalfont",
    "Challis",
    "Chalmette",
    "Chama",
    "Chamberino",
    "Chamberlain",
    "Chambers",
    "Chambersburg",
    "Chambersburg",
    "Champaign",
    "Champion",
    "Champion",
    "Champlain",
    "Champlin",
    "Chana",
    "Chancellor",
    "Chancellor",
    "Chandler",
    "Chandler",
    "Chandler",
    "Chandler",
    "Chandler",
    "Chandlersville",
    "Chandlerville",
    "Chanhassen",
    "Channahon",
    "Channelview",
    "Channing",
    "Chantilly",
    "Chanute",
    "Chaparral",
    "Chapel Hill",
    "Chapel Hill",
    "Chapin",
    "Chaplin",
    "Chaplin",
    "Chapman",
    "Chapman",
    "Chapmansboro",
    "Chapmanville",
    "Chappaqua",
    "Chappell Hill",
    "Chardon",
    "Charenton",
    "Chariton",
    "Charlemont",
    "Charleroi",
    "Charles City",
    "Charles City",
    "Charles Town",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charleston",
    "Charlestown",
    "Charlestown",
    "Charlestown",
    "Charlestown",
    "Charlevoix",
    "Charlotte",
    "Charlotte",
    "Charlotte",
    "Charlotte",
    "Charlotte",
    "Charlotte",
    "Charlotte",
    "Charlotte Court House",
    "Charlotte Hall",
    "Charlottesville",
    "Charlotteville",
    "Charlton",
    "Charter Oak",
    "Charter Township of Clinton",
    "Chartley",
    "Chase",
    "Chase City",
    "Chaseburg",
    "Chaseley",
    "Chaska",
    "Chassell",
    "Chataignier",
    "Chatfield",
    "Chatham",
    "Chatham",
    "Chatham",
    "Chatham",
    "Chatham",
    "Chatham",
    "Chatom",
    "Chatsworth",
    "Chatsworth",
    "Chatsworth",
    "Chattahoochee",
    "Chattanooga",
    "Chattaroy",
    "Chattaroy",
    "Chaumont",
    "Chauncey",
    "Chautauqua",
    "Chauvin",
    "Chavies",
    "Chazy",
    "Chebanse",
    "Chebeague Island",
    "Cheboygan",
    "Checotah",
    "Chefornak",
    "Chehalis",
    "Chelan",
    "Chelan Falls",
    "Chelmsford",
    "Chelsea",
    "Chelsea",
    "Chelsea",
    "Chelsea",
    "Chelsea",
    "Chelsea",
    "Cheltenham",
    "Cheltenham",
    "Chemung",
    "Chenango Forks",
    "Cheney",
    "Cheney",
    "Cheneyville",
    "Chenoa",
    "Chepachet",
    "Cheraw",
    "Cheriton",
    "Cherokee",
    "Cherokee",
    "Cherokee",
    "Cherokee",
    "Cherokee",
    "Cherokee Village",
    "Cherry Hill",
    "Cherry Log",
    "Cherry Point Marine Base Mobile Home Park",
    "Cherry Tree",
    "Cherry Valley",
    "Cherry Valley",
    "Cherry Valley",
    "Cherryfield",
    "Cherryvale",
    "Cherryville",
    "Chesaning",
    "Chesapeake",
    "Chesapeake",
    "Chesapeake Beach",
    "Chesapeake City",
    "Cheshire",
    "Cheshire",
    "Cheshire",
    "Chesnee",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester",
    "Chester Heights",
    "Chester Springs",
    "Chesterfield",
    "Chesterfield",
    "Chesterfield",
    "Chesterfield",
    "Chesterfield",
    "Chesterhill",
    "Chesterland",
    "Chesterton",
    "Chestertown",
    "Chestertown",
    "Chestnut",
    "Chestnut Hill",
    "Chestnut Mountain",
    "Chestnut Ridge",
    "Cheswick",
    "Chetek",
    "Chetopa",
    "Chevak",
    "Chevy Chase",
    "Chewelah",
    "Cheyenne",
    "Cheyenne",
    "Cheyenne Wells",
    "Cheyney",
    "Chicago",
    "Chicago Heights",
    "Chicago Ridge",
    "Chichester",
    "Chickamauga",
    "Chickasha",
    "Chicken",
    "Chico",
    "Chico",
    "Chicopee",
    "Chicora",
    "Chiefland",
    "Childersburg",
    "Childress",
    "Childs",
    "Chilhowee",
    "Chilhowie",
    "Chili",
    "Chillicothe",
    "Chillicothe",
    "Chillicothe",
    "Chillicothe",
    "Chilo",
    "Chilton",
    "Chilton",
    "Chimacum",
    "China Grove",
    "China Springs",
    "Chinchilla",
    "Chincoteague Island",
    "Chinese Camp",
    "Chinle",
    "Chino",
    "Chino Hills",
    "Chino Valley",
    "Chinook",
    "Chipley",
    "Chippewa Falls",
    "Chippewa Lake",
    "Chireno",
    "Chisago City",
    "Chisholm",
    "Chittenango",
    "Chittenden",
    "Chloride",
    "Chocorua",
    "Chocowinity",
    "Choctaw",
    "Choctaw",
    "Chokio",
    "Choteau",
    "Choudrant",
    "Chouteau",
    "Chowchilla",
    "Chrisman",
    "Christchurch",
    "Christiana",
    "Christiana",
    "Christiansburg",
    "Christine",
    "Christmas",
    "Christmas Valley",
    "Christopher",
    "Christoval",
    "Chualar",
    "Chuckey",
    "Chugiak",
    "Chugwater",
    "Chula",
    "Chula",
    "Chula Vista",
    "Chunchula",
    "Chunky",
    "Church Hill",
    "Church Hill",
    "Church Point",
    "Church Rock",
    "Churchton",
    "Churchville",
    "Churchville",
    "Churchville",
    "Churdan",
    "Churubusco",
    "Cibola",
    "Cibolo",
    "Cicero",
    "Cicero",
    "Cicero",
    "Cimarron",
    "Cimarron",
    "Cimarron",
    "Cincinnati",
    "Cincinnatus",
    "Cinebar",
    "Circle",
    "Circle Pines",
    "Circleville",
    "Circleville",
    "Circleville",
    "Cisco",
    "Cisco",
    "Cisco",
    "Cisne",
    "Cissna Park",
    "Citra",
    "Citronelle",
    "Citrus Heights",
    "City of Angels",
    "City of Industry",
    "City of Picher (historical)",
    "City of Saint Marys",
    "City of Saint Peters",
    "Clackamas",
    "Claflin",
    "Claire City",
    "Clairton",
    "Clam Lake",
    "Clancy",
    "Clanton",
    "Clara City",
    "Clare",
    "Clare",
    "Claremont",
    "Claremont",
    "Claremont",
    "Claremont",
    "Claremont",
    "Claremont",
    "Claremore",
    "Clarence",
    "Clarence",
    "Clarence",
    "Clarence",
    "Clarendon",
    "Clarendon",
    "Clarendon Hills",
    "Claridge",
    "Clarinda",
    "Clarington",
    "Clarion",
    "Clarion",
    "Clarissa",
    "Clark",
    "Clark",
    "Clark",
    "Clark",
    "Clark Fork",
    "Clark Mills",
    "Clarkesville",
    "Clarkfield",
    "Clarkia",
    "Clarklake",
    "Clarkrange",
    "Clarks",
    "Clarks",
    "Clarks Grove",
    "Clarks Hill",
    "Clarks Hill",
    "Clarks Summit",
    "Clarksboro",
    "Clarksburg",
    "Clarksburg",
    "Clarksburg",
    "Clarksburg",
    "Clarksburg",
    "Clarksburg",
    "Clarksdale",
    "Clarksdale",
    "Clarkson",
    "Clarkson",
    "Clarkston",
    "Clarkston",
    "Clarkston",
    "Clarkston",
    "Clarkston",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarksville",
    "Clarkton",
    "Claryville",
    "Clatonia",
    "Clatskanie",
    "Claude",
    "Claudville",
    "Claverack",
    "Clawson",
    "Clawson",
    "Claxton",
    "Clay",
    "Clay",
    "Clay Center",
    "Clay Center",
    "Clay City",
    "Clay City",
    "Clay City",
    "Claymont",
    "Claypool",
    "Claypool",
    "Claysburg",
    "Claysville",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Clayton",
    "Cle Elum",
    "Clear Brook",
    "Clear Fork",
    "Clear Lake",
    "Clear Lake",
    "Clear Lake",
    "Clear Lake",
    "Clear Spring",
    "Clearbrook",
    "Clearfield",
    "Clearfield",
    "Clearfield",
    "Clearfield",
    "Clearlake",
    "Clearlake Oaks",
    "Clearmont",
    "Clearmont",
    "Clearville",
    "Clearwater",
    "Clearwater",
    "Clearwater",
    "Clearwater",
    "Clearwater Beach",
    "Cleburne",
    "Cleghorn",
    "Clementon",
    "Clements",
    "Clemmons",
    "Clemons",
    "Clemson",
    "Clendenin",
    "Clermont",
    "Clermont",
    "Clermont",
    "Clermont",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Cleveland",
    "Clever",
    "Cleves",
    "Clewiston",
    "Clifford",
    "Clifford",
    "Cliffside Park",
    "Cliffwood",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton",
    "Clifton Forge",
    "Clifton Heights",
    "Clifton Park",
    "Clifton Springs",
    "Climax",
    "Climax",
    "Climax",
    "Climax",
    "Climax",
    "Climax Springs",
    "Clinchco",
    "Clint",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton",
    "Clinton Corners",
    "Clintondale",
    "Clintonville",
    "Clintonville",
    "Clintwood",
    "Clio",
    "Clio",
    "Clio",
    "Clive",
    "Clontarf",
    "Clopton",
    "Cloquet",
    "Closplint",
    "Closter",
    "Cloud",
    "Cloudcroft",
    "Clover",
    "Clover",
    "Cloverdale",
    "Cloverdale",
    "Cloverdale",
    "Cloverdale",
    "Cloverdale",
    "Cloverport",
    "Clovis",
    "Clovis",
    "Clute",
    "Clyde",
    "Clyde",
    "Clyde",
    "Clyde",
    "Clyde",
    "Clyde",
    "Clyde Park",
    "Clyman",
    "Clymer",
    "Clymer",
    "Clyo",
    "Coachella",
    "Coahoma",
    "Coal Center",
    "Coal City",
    "Coal City",
    "Coal City",
    "Coal Hill",
    "Coal Mountain",
    "Coal Township",
    "Coal Valley",
    "Coaldale",
    "Coalfield",
    "Coalgate",
    "Coalinga",
    "Coalmont",
    "Coalport",
    "Coalville",
    "Coarsegold",
    "Coatesville",
    "Coatesville",
    "Coats",
    "Coatsburg",
    "Cobalt",
    "Cobb",
    "Cobb",
    "Cobbs Creek",
    "Cobden",
    "Cobleskill",
    "Coburn",
    "Cochecton",
    "Cochise",
    "Cochiti Lake",
    "Cochiti Pueblo",
    "Cochran",
    "Cochrane",
    "Cochranton",
    "Cochranville",
    "Cockeysville",
    "Cocoa",
    "Cocoa Beach",
    "Cocolamus",
    "Coden",
    "Cody",
    "Cody",
    "Coeburn",
    "Coeymans",
    "Coeymans Hollow",
    "Coffee Springs",
    "Coffeen",
    "Coffeeville",
    "Coffey",
    "Coffeyville",
    "Coffman Cove",
    "Coggon",
    "Cogswell",
    "Cohasset",
    "Cohasset",
    "Cohocton",
    "Cohoes",
    "Cohutta",
    "Coin",
    "Coinjock",
    "Cokato",
    "Cokeburg",
    "Coker",
    "Cokeville",
    "Colbert",
    "Colbert",
    "Colbert",
    "Colby",
    "Colby",
    "Colchester",
    "Colchester",
    "Colchester",
    "Colcord",
    "Cold Brook",
    "Cold Spring",
    "Cold Spring",
    "Cold Spring Harbor",
    "Cold Springs",
    "Colden",
    "Coldspring",
    "Coldwater",
    "Coldwater",
    "Coldwater",
    "Coldwater",
    "Cole Camp",
    "Colebrook",
    "Coleman",
    "Coleman",
    "Coleman",
    "Coleman",
    "Colerain",
    "Coleraine",
    "Coleridge",
    "Colesburg",
    "Coleville",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colfax",
    "Colgate",
    "College Corner",
    "College Grove",
    "College Park",
    "College Place",
    "College Point",
    "College Station",
    "Collegedale",
    "Collegeport",
    "Collegeville",
    "Collegeville",
    "Colleyville",
    "Colliers",
    "Collierville",
    "Collingswood",
    "Collins",
    "Collins",
    "Collins",
    "Collins",
    "Collins",
    "Collins",
    "Collinston",
    "Collinston",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinsville",
    "Collinwood",
    "Collison",
    "Colman",
    "Colmar",
    "Colmesneil",
    "Colo",
    "Cologne",
    "Coloma",
    "Coloma",
    "Coloma",
    "Colon",
    "Colona",
    "Colonia",
    "Colonial Beach",
    "Colonial Heights",
    "Colony",
    "Colora",
    "Colorado City",
    "Colorado City",
    "Colorado City",
    "Colorado Springs",
    "Colquitt",
    "Colstrip",
    "Colt",
    "Colton",
    "Colton",
    "Colton",
    "Colton",
    "Colts Neck",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia",
    "Columbia City",
    "Columbia City",
    "Columbia Cross Roads",
    "Columbia Falls",
    "Columbia Station",
    "Columbiana",
    "Columbiana",
    "Columbiaville",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus",
    "Columbus Grove",
    "Columbus Junction",
    "Colusa",
    "Colver",
    "Colville",
    "Colwich",
    "Comanche",
    "Comanche",
    "Combined Locks",
    "Combs",
    "Combs",
    "Comer",
    "Comfort",
    "Comfrey",
    "Commack",
    "Commerce",
    "Commerce",
    "Commerce",
    "Commerce",
    "Commerce City",
    "Commercial Point",
    "Commiskey",
    "Como",
    "Comptche",
    "Compton",
    "Compton",
    "Comstock",
    "Comstock",
    "Comstock",
    "Comstock Park",
    "Conception Junction",
    "Concho",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concord",
    "Concordia",
    "Concordia",
    "Concordville",
    "Concrete",
    "Condon",
    "Conestoga",
    "Conesus",
    "Conesville",
    "Confluence",
    "Conger",
    "Congers",
    "Congerville",
    "Congress",
    "Conifer",
    "Conklin",
    "Conklin",
    "Conley",
    "Conneaut",
    "Conneaut Lake",
    "Conneautville",
    "Connell",
    "Connellsville",
    "Connelly Springs",
    "Connersville",
    "Conover",
    "Conover",
    "Conowingo",
    "Conrad",
    "Conrad",
    "Conrath",
    "Conroe",
    "Conroy",
    "Conshohocken",
    "Constableville",
    "Constantia",
    "Constantine",
    "Contact",
    "Content",
    "Continental",
    "Contoocook",
    "Convent",
    "Converse",
    "Converse",
    "Converse",
    "Convoy",
    "Conway",
    "Conway",
    "Conway",
    "Conway",
    "Conway",
    "Conway",
    "Conway",
    "Conway",
    "Conway Springs",
    "Conyers",
    "Conyngham",
    "Cook",
    "Cook",
    "Cookeville",
    "Cookstown",
    "Cooksville",
    "Coolidge",
    "Coolidge",
    "Coolin",
    "Coolville",
    "Coon Rapids",
    "Coon Valley",
    "Cooper",
    "Cooper Landing",
    "Coopers Plains",
    "Coopersburg",
    "Cooperstown",
    "Cooperstown",
    "Cooperstown",
    "Coopersville",
    "Coos Bay",
    "Copake",
    "Copake Falls",
    "Copalis Beach",
    "Copan",
    "Cope",
    "Copeland",
    "Copenhagen",
    "Copeville",
    "Copiague",
    "Coplay",
    "Coppell",
    "Copper Center",
    "Copper Harbor",
    "Copperas Cove",
    "Copperhill",
    "Copperopolis",
    "Coquille",
    "Coral",
    "Coral Gables",
    "Coralville",
    "Coram",
    "Coraopolis",
    "Corbett",
    "Corbin",
    "Corcoran",
    "Cordele",
    "Cordell",
    "Cordesville",
    "Cordova",
    "Cordova",
    "Cordova",
    "Cordova",
    "Cordova",
    "Cordova",
    "Corfu",
    "Corinna",
    "Corinne",
    "Corinth",
    "Corinth",
    "Corinth",
    "Corn",
    "Cornelia",
    "Cornelius",
    "Cornelius",
    "Cornell",
    "Cornell",
    "Cornersville",
    "Corning",
    "Corning",
    "Corning",
    "Corning",
    "Corning",
    "Corning",
    "Cornish",
    "Cornish",
    "Cornish Flat",
    "Cornville",
    "Cornwall",
    "Cornwall Bridge",
    "Cornwall-on-Hudson",
    "Cornwallville",
    "Corolla",
    "Corona",
    "Corona",
    "Corona",
    "Corona",
    "Corona del Mar",
    "Coronado",
    "Corpus Christi",
    "Corrales",
    "Correctionville",
    "Corrigan",
    "Corry",
    "Corryton",
    "Corsica",
    "Corsica",
    "Corsicana",
    "Cortaro",
    "Corte Madera",
    "Cortez",
    "Cortland",
    "Cortland",
    "Cortland",
    "Cortland",
    "Corunna",
    "Corunna",
    "Corvallis",
    "Corvallis",
    "Corwith",
    "Cory",
    "Cory",
    "Corydon",
    "Corydon",
    "Corydon",
    "Cos Cob",
    "Cosby",
    "Coshocton",
    "Cosmopolis",
    "Cosmos",
    "Costa Mesa",
    "Cotati",
    "Cottage Grove",
    "Cottage Grove",
    "Cottage Grove",
    "Cottage Hills",
    "Cottageville",
    "Cottageville",
    "Cottekill",
    "Cotton",
    "Cotton Valley",
    "Cottondale",
    "Cottondale",
    "Cottonport",
    "Cottontown",
    "Cottonwood",
    "Cottonwood",
    "Cottonwood",
    "Cottonwood",
    "Cottonwood",
    "Cotuit",
    "Cotulla",
    "Coudersport",
    "Coulee City",
    "Coulee Dam",
    "Coulterville",
    "Coulterville",
    "Counce",
    "Council",
    "Council",
    "Council Bluffs",
    "Council Grove",
    "Council Hill",
    "Country Club Hills",
    "Coupeville",
    "Coupland",
    "Courtland",
    "Courtland",
    "Courtland",
    "Courtland",
    "Coushatta",
    "Cove",
    "Cove",
    "Covelo",
    "Coventry",
    "Coventry",
    "Covert",
    "Covina",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Covington",
    "Cowan",
    "Cowansville",
    "Coward",
    "Cowden",
    "Cowen",
    "Coweta",
    "Cowiche",
    "Cowlesville",
    "Cowley",
    "Cowpens",
    "Coxs Creek",
    "Coxsackie",
    "Coyle",
    "Coyote",
    "Coyote",
    "Cozad",
    "Crab Orchard",
    "Crab Orchard",
    "Crab Orchard",
    "Crabtree",
    "Craftsbury",
    "Craig",
    "Craig",
    "Craig",
    "Craig",
    "Craigmont",
    "Craigsville",
    "Craigsville",
    "Craigville",
    "Cramerton",
    "Cranberry Township",
    "Cranbury",
    "Crandall",
    "Crandall",
    "Crandall",
    "Crandon",
    "Crane",
    "Crane",
    "Crane",
    "Crane Hill",
    "Crane Lake",
    "Cranesville",
    "Cranfills Gap",
    "Cranford",
    "Cranks",
    "Cranston",
    "Craryville",
    "Crawford",
    "Crawford",
    "Crawford",
    "Crawford",
    "Crawford",
    "Crawford",
    "Crawfordsville",
    "Crawfordsville",
    "Crawfordville",
    "Crawfordville",
    "Crawley",
    "Creal Springs",
    "Cream Ridge",
    "Creede",
    "Creedmoor",
    "Creekside",
    "Creighton",
    "Creighton",
    "Creola",
    "Creola",
    "Creole",
    "Cresbard",
    "Crescent",
    "Crescent",
    "Crescent",
    "Crescent City",
    "Crescent City",
    "Crescent Hills",
    "Crescent Mills",
    "Crescent Valley",
    "Cresco",
    "Cresco",
    "Cresskill",
    "Cresson",
    "Cresson",
    "Crest Hill",
    "Crested Butte",
    "Crestline",
    "Crestline",
    "Creston",
    "Creston",
    "Creston",
    "Creston",
    "Crestone",
    "Crestview",
    "Crestwood",
    "Creswell",
    "Crete",
    "Crete",
    "Creve Coeur",
    "Crewe",
    "Crimora",
    "Cripple Creek",
    "Crisfield",
    "Crittenden",
    "Critz",
    "Crivitz",
    "Crocker",
    "Crockett",
    "Crockett",
    "Crofton",
    "Crofton",
    "Crofton",
    "Croghan",
    "Crompond",
    "Cromwell",
    "Cromwell",
    "Cromwell",
    "Crooks",
    "Crookston",
    "Crookston",
    "Crooksville",
    "Cropseyville",
    "Crosby",
    "Crosby",
    "Crosby",
    "Crosbyton",
    "Cross",
    "Cross City",
    "Cross Junction",
    "Cross Lake",
    "Cross Plains",
    "Cross Plains",
    "Cross Plains",
    "Cross Plains",
    "Cross River",
    "Crossett",
    "Crossville",
    "Crossville",
    "Crossville",
    "Crosswicks",
    "Croswell",
    "Crothersville",
    "Croton",
    "Croton Falls",
    "Croton-on-Hudson",
    "Crow Agency",
    "Crowder",
    "Crowell",
    "Crowheart",
    "Crowley",
    "Crowley",
    "Crown City",
    "Crown Point",
    "Crown Point",
    "Crownpoint",
    "Crownsville",
    "Crows Landing",
    "Croydon",
    "Crozet",
    "Crucible",
    "Cruger",
    "Crum",
    "Crum Lynne",
    "Crump",
    "Crumpler",
    "Crystal",
    "Crystal City",
    "Crystal City",
    "Crystal Falls",
    "Crystal Lake",
    "Crystal River",
    "Crystal Springs",
    "Crystal Springs",
    "Cub Run",
    "Cuba",
    "Cuba",
    "Cuba",
    "Cuba",
    "Cuba",
    "Cuba City",
    "Cudahy",
    "Cuddebackville",
    "Cuddy Hill",
    "Cuero",
    "Culbertson",
    "Culbertson",
    "Culdesac",
    "Cullen",
    "Cullen",
    "Culleoka",
    "Cullman",
    "Culloden",
    "Culloden",
    "Cullom",
    "Cullowhee",
    "Culpeper",
    "Culver",
    "Culver",
    "Culver City",
    "Cumberland",
    "Cumberland",
    "Cumberland",
    "Cumberland",
    "Cumberland",
    "Cumberland",
    "Cumberland",
    "Cumberland Center",
    "Cumberland City",
    "Cumberland Foreside",
    "Cumberland Furnace",
    "Cumberland Gap",
    "Cumby",
    "Cumming",
    "Cumming",
    "Cummings",
    "Cummington",
    "Cunningham",
    "Cunningham",
    "Cupertino",
    "Currie",
    "Currituck",
    "Curryville",
    "Curtice",
    "Curtis",
    "Curtis",
    "Curtis Bay",
    "Curtiss",
    "Curwensville",
    "Cushing",
    "Cushing",
    "Cushing",
    "Cusick",
    "Cusseta",
    "Cusseta",
    "Custar",
    "Custer",
    "Custer",
    "Custer",
    "Custer",
    "Custer",
    "Custer City",
    "Cut Bank",
    "Cut Off",
    "Cutchogue",
    "Cuthbert",
    "Cutler",
    "Cutler",
    "Cuttingsville",
    "Cuyahoga Falls",
    "Cyclone",
    "Cygnet",
    "Cylinder",
    "Cynthiana",
    "Cynthiana",
    "Cypress",
    "Cypress",
    "Cypress",
    "Cypress Inn",
    "Cyril",
    "Dacoma",
    "Dacono",
    "Dacula",
    "Dade City",
    "Dadeville",
    "Daggett",
    "Dagsboro",
    "Dahlgren",
    "Dahlgren",
    "Dahlonega",
    "Daingerfield",
    "Daisetta",
    "Daisytown",
    "Dakota",
    "Dakota",
    "Dakota City",
    "Dakota City",
    "Dale",
    "Dale",
    "Dale",
    "Dale",
    "Daleville",
    "Daleville",
    "Daleville",
    "Dalhart",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas",
    "Dallas Center",
    "Dallas City",
    "Dallastown",
    "Dalmatia",
    "Dalton",
    "Dalton",
    "Dalton",
    "Dalton",
    "Dalton",
    "Dalton",
    "Dalton",
    "Dalton City",
    "Daly City",
    "Dalzell",
    "Dalzell",
    "Damariscotta",
    "Damascus",
    "Damascus",
    "Damascus",
    "Damascus",
    "Damascus",
    "Damon",
    "Dana",
    "Dana",
    "Dana",
    "Dana Point",
    "Danbury",
    "Danbury",
    "Danbury",
    "Danbury",
    "Danbury",
    "Danby",
    "Dandridge",
    "Dane",
    "Danese",
    "Danforth",
    "Danforth",
    "Dania Beach",
    "Daniels",
    "Danielson",
    "Danielsville",
    "Danielsville",
    "Dannemora",
    "Dansville",
    "Dansville",
    "Dante",
    "Danube",
    "Danvers",
    "Danvers",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Danville",
    "Daphne",
    "Darby",
    "Darby",
    "Dardanelle",
    "Darden",
    "Darien",
    "Darien",
    "Darien",
    "Darien",
    "Darlington",
    "Darlington",
    "Darlington",
    "Darlington",
    "Darlington",
    "Darmstadt",
    "Darrington",
    "Darrouzett",
    "Darrow",
    "Darwin",
    "Dassel",
    "Dateland",
    "Datil",
    "Dauphin",
    "Dauphin Island",
    "Davenport",
    "Davenport",
    "Davenport",
    "Davenport",
    "David City",
    "Davidson",
    "Davidsonville",
    "Davis",
    "Davis",
    "Davis",
    "Davis",
    "Davis City",
    "Davis Junction",
    "Davisboro",
    "Davisburg",
    "Davison",
    "Daviston",
    "Davisville",
    "Davisville",
    "Davy",
    "Dawes",
    "Dawn",
    "Dawson",
    "Dawson",
    "Dawson",
    "Dawson",
    "Dawson",
    "Dawson Springs",
    "Dawsonville",
    "Days Creek",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Dayton",
    "Daytona Beach",
    "Dayville",
    "Dazey",
    "De Beque",
    "De Borgia",
    "De Forest",
    "De Graff",
    "De Kalb",
    "De Kalb",
    "De Kalb",
    "De Kalb Junction",
    "De Leon",
    "De Leon Springs",
    "De Queen",
    "De Smet",
    "De Soto",
    "De Soto",
    "De Soto",
    "De Soto",
    "De Soto",
    "De Valls Bluff",
    "De Witt",
    "De Witt",
    "De Witt",
    "Deadwood",
    "Deal",
    "Deal Island",
    "Deale",
    "Deansboro",
    "Deanville",
    "Dearborn",
    "Dearborn",
    "Dearborn Heights",
    "Dearing",
    "Death Valley Junction",
    "Deatsville",
    "Deaver",
    "DeBary",
    "Debord",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decatur",
    "Decaturville",
    "Decherd",
    "Decker",
    "Deckerville",
    "Declo",
    "Decorah",
    "Dedham",
    "Deep River",
    "Deep River",
    "Deep Run",
    "Deep Water",
    "Deepwater",
    "Deer",
    "Deer Island",
    "Deer Isle",
    "Deer Lodge",
    "Deer Lodge",
    "Deer Park",
    "Deer Park",
    "Deer Park",
    "Deer Park",
    "Deer River",
    "Deer Trail",
    "Deerfield",
    "Deerfield",
    "Deerfield",
    "Deerfield",
    "Deerfield",
    "Deerfield",
    "Deerfield",
    "Deerfield Beach",
    "Deering",
    "Deerwood",
    "Defiance",
    "Defiance",
    "Defiance",
    "Defiance",
    "Deford",
    "DeFuniak Springs",
    "DeKalb",
    "Del Mar",
    "Del Norte",
    "Del Rey",
    "Del Rio",
    "Del Rio",
    "Del Valle",
    "Delafield",
    "DeLand",
    "Delano",
    "Delano",
    "Delano",
    "Delano",
    "Delanson",
    "Delaplaine",
    "Delavan",
    "Delavan",
    "Delavan",
    "Delaware",
    "Delaware City",
    "Delbarton",
    "Delcambre",
    "Delco",
    "Delevan",
    "Delhi",
    "Delhi",
    "Delhi",
    "Delhi",
    "Delight",
    "Dell",
    "Dell Rapids",
    "Delmar",
    "Delmar",
    "Delmar",
    "Delmar",
    "Delmont",
    "Delmont",
    "Deloit",
    "Delphi",
    "Delphos",
    "Delphos",
    "Delray",
    "Delray Beach",
    "Delta",
    "Delta",
    "Delta",
    "Delta",
    "Delta",
    "Deltana",
    "Deltaville",
    "Delton",
    "Deltona",
    "Dema",
    "Demarest",
    "Deming",
    "Deming",
    "Demopolis",
    "Demorest",
    "DeMossville",
    "DeMotte",
    "Denair",
    "Dendron",
    "Denham Springs",
    "Denison",
    "Denison",
    "Denison",
    "Denmark",
    "Denmark",
    "Denmark",
    "Denmark",
    "Denmark",
    "Dennis",
    "Dennis",
    "Dennis Port",
    "Dennison",
    "Dennison",
    "Dennison",
    "Denniston",
    "Dennysville",
    "Dent",
    "Denton",
    "Denton",
    "Denton",
    "Denton",
    "Denton",
    "Denton",
    "Denton",
    "Denver",
    "Denver",
    "Denver",
    "Denver",
    "Denver",
    "Denver",
    "Denver City",
    "Denville",
    "Depauw",
    "Depere",
    "Depew",
    "Depew",
    "Depoe Bay",
    "Deposit",
    "Depue",
    "Deputy",
    "DeQuincy",
    "Derby",
    "Derby",
    "Derby",
    "Derby",
    "Derby Center",
    "Derby Line",
    "DeRidder",
    "Derma",
    "Dermott",
    "Derry",
    "Derry",
    "DeRuyter",
    "Derwood",
    "Des Allemands",
    "Des Arc",
    "Des Arc",
    "Des Moines",
    "Des Moines",
    "Des Plaines",
    "Descanso",
    "Desert Hot Springs",
    "Deshler",
    "Deshler",
    "DeSoto",
    "Destin",
    "Destrehan",
    "Detroit",
    "Detroit",
    "Detroit Lakes",
    "Devens",
    "Deville",
    "Devils Lake",
    "Devine",
    "Devon",
    "Dewar",
    "Dewey",
    "Dewey",
    "Dewey",
    "DeWitt",
    "DeWitt",
    "DeWitt",
    "Dewy Rose",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter",
    "Dexter City",
    "Diablo",
    "Diagonal",
    "Diamond",
    "Diamond",
    "Diamond Bar",
    "Diamond Point",
    "Diamond Springs",
    "Diamondhead",
    "Diamondville",
    "Diana",
    "Diana",
    "Diboll",
    "Dickerson",
    "Dickeyville",
    "Dickinson",
    "Dickinson",
    "Dickinson Center",
    "Dickson",
    "Dickson City",
    "Dierks",
    "Dieterich",
    "Dietrich",
    "Diggs",
    "Dighton",
    "Dighton",
    "Dill City",
    "Dillard",
    "Dillard",
    "Diller",
    "Dilley",
    "Dillingham",
    "Dillon",
    "Dillon",
    "Dillon",
    "Dillon Beach",
    "Dillonvale",
    "Dillsboro",
    "Dillsboro",
    "Dillsburg",
    "Dillwyn",
    "Dime Box",
    "Dimmitt",
    "Dimock",
    "Dimondale",
    "Dingess",
    "Dingmans Ferry",
    "Dinuba",
    "Dinwiddie",
    "Discovery Bay",
    "Disputanta",
    "Distant",
    "District Heights",
    "Dittmer",
    "Divernon",
    "Divide",
    "Divide",
    "Dividing Creek",
    "Dix",
    "Dixfield",
    "Dixie",
    "Dixie",
    "Dixie",
    "Dixmont",
    "Dixon",
    "Dixon",
    "Dixon",
    "Dixon",
    "Dixon",
    "Dixon",
    "Dixon",
    "Dixon Springs",
    "Dixons Mills",
    "Dixonville",
    "Dobbs Ferry",
    "Dobson",
    "Docena",
    "Dodd City",
    "Doddridge",
    "Dodge",
    "Dodge",
    "Dodge Center",
    "Dodge City",
    "Dodgeville",
    "Dodgewood",
    "Doerun",
    "Dolan Springs",
    "Doland",
    "Dolgeville",
    "Dollar Bay",
    "Dolliver",
    "Dolomite",
    "Dolores",
    "Dolphin",
    "Dolton",
    "Donald",
    "Donalds",
    "Donaldson",
    "Donaldsonville",
    "Donalsonville",
    "Donegal",
    "Dongola",
    "Donie",
    "Doniphan",
    "Doniphan",
    "Donna",
    "Donnellson",
    "Donnelly",
    "Donnelsville",
    "Donner",
    "Donora",
    "Donovan",
    "Doon",
    "Dora",
    "Dorchester",
    "Dorchester",
    "Dorchester",
    "Dorena",
    "Dornsife",
    "Dorothy",
    "Dorr",
    "Dorrance",
    "Dorris",
    "Dorset",
    "Dorsey",
    "Dos Palos",
    "Doss",
    "Doswell",
    "Dothan",
    "Double Springs",
    "Dougherty",
    "Douglas",
    "Douglas",
    "Douglas",
    "Douglas",
    "Douglas",
    "Douglas",
    "Douglas",
    "Douglas City",
    "Douglass",
    "Douglass",
    "Douglassville",
    "Douglassville",
    "Douglasville",
    "Dousman",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover",
    "Dover Plains",
    "Dover-Foxcroft",
    "Dow",
    "Dowagiac",
    "Dowell",
    "Dowelltown",
    "Dowling",
    "Downers Grove",
    "Downey",
    "Downey",
    "Downieville",
    "Downingtown",
    "Downs",
    "Downs",
    "Downsville",
    "Downsville",
    "Downsville",
    "Dows",
    "Doyle",
    "Doyle",
    "Doylestown",
    "Doylestown",
    "Doyline",
    "Dozier",
    "Dracut",
    "Dragoon",
    "Drain",
    "Drakes Branch",
    "Drakesboro",
    "Draper",
    "Draper",
    "Drasco",
    "Dravosburg",
    "Drayden",
    "Dresden",
    "Dresden",
    "Dresher",
    "Dresser",
    "Drewryville",
    "Drexel",
    "Drexel",
    "Drexel Hill",
    "Drift",
    "Drifting",
    "Driftwood",
    "Driftwood",
    "Driggs",
    "Dripping Springs",
    "Driscoll",
    "Drummond",
    "Drummond",
    "Drummond",
    "Drummond",
    "Drummonds",
    "Drumore",
    "Drumright",
    "Drums",
    "Dry Branch",
    "Dry Creek",
    "Dry Fork",
    "Dry Prong",
    "Dry Ridge",
    "Dry Run",
    "Drybranch",
    "Dryden",
    "Dryden",
    "Dryden",
    "Dryden",
    "Dryfork",
    "Du Bois",
    "Du Quoin",
    "Duanesburg",
    "Duarte",
    "Dubach",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "Dublin",
    "DuBois",
    "Dubois",
    "Dubois",
    "Dubois",
    "Dubuque",
    "Duchesne",
    "Duck",
    "Duck Creek Village",
    "Duck Hill",
    "Ducktown",
    "Dudley",
    "Dudley",
    "Dudley",
    "Dudley",
    "Due West",
    "Duff",
    "Duffield",
    "Dufur",
    "Dugger",
    "Dugway",
    "Duke",
    "Duke Center",
    "Dukedom",
    "Dulac",
    "Dulce",
    "Dulles",
    "Duluth",
    "Duluth",
    "Dulzura",
    "Dumas",
    "Dumas",
    "Dumfries",
    "Dumont",
    "Dumont",
    "Dunbar",
    "Dunbar",
    "Dunbar",
    "Dunbar",
    "Dunbarton Center",
    "Duncan",
    "Duncan",
    "Duncan",
    "Duncannon",
    "Duncans Mills",
    "Duncansville",
    "Duncanville",
    "Duncanville",
    "Duncombe",
    "Dundalk",
    "Dundas",
    "Dundas",
    "Dundee",
    "Dundee",
    "Dundee",
    "Dundee",
    "Dundee",
    "Dundee",
    "Dunedin",
    "Dunellen",
    "Dungannon",
    "Dunkerton",
    "Dunkirk",
    "Dunkirk",
    "Dunkirk",
    "Dunlap",
    "Dunlap",
    "Dunlap",
    "Dunlap",
    "Dunlow",
    "Dunmor",
    "Dunmore",
    "Dunn",
    "Dunn Loring",
    "Dunnell",
    "Dunnellon",
    "Dunnsville",
    "Dunnville",
    "Dunseith",
    "Dunsmuir",
    "Dunstable",
    "Duplessis",
    "Dupo",
    "DuPont",
    "Dupont",
    "Dupuyer",
    "Duquesne",
    "Durand",
    "Durand",
    "Durand",
    "Durango",
    "Durango",
    "Durant",
    "Durant",
    "Durant",
    "Durbin",
    "Durham",
    "Durham",
    "Durham",
    "Durham",
    "Durham",
    "Durhamville",
    "Duryea",
    "Dushore",
    "Duson",
    "Dustin",
    "Dutch Flat",
    "Dutch Harbor",
    "Dutch John",
    "Dutton",
    "Dutton",
    "Duvall",
    "Duxbury",
    "Dwale",
    "Dwight",
    "Dwight",
    "Dwight",
    "Dyer",
    "Dyer",
    "Dyersburg",
    "Dyersville",
    "Dysart",
    "Dysart",
    "Eads",
    "Eads",
    "Eagar",
    "Eagle",
    "Eagle",
    "Eagle",
    "Eagle",
    "Eagle",
    "Eagle Bend",
    "Eagle Bridge",
    "Eagle Butte",
    "Eagle Creek",
    "Eagle Grove",
    "Eagle Lake",
    "Eagle Lake",
    "Eagle Lake",
    "Eagle Mountain",
    "Eagle Nest",
    "Eagle Pass",
    "Eagle Point",
    "Eagle River",
    "Eagle River",
    "Eagle Rock",
    "Eagle Springs",
    "Eagleville",
    "Eagleville",
    "Eagleville",
    "Earl Park",
    "Earle",
    "Earleville",
    "Earlham",
    "Earlimart",
    "Earling",
    "Earlsboro",
    "Earlton",
    "Earlville",
    "Earlville",
    "Earlville",
    "Early",
    "Early",
    "Earlysville",
    "Earth",
    "Earth City",
    "Easley",
    "East Alton",
    "East Amherst",
    "East Andover",
    "East Aurora",
    "East Bank",
    "East Barre",
    "East Bend",
    "East Berkshire",
    "East Berlin",
    "East Berlin",
    "East Bernard",
    "East Berne",
    "East Bernstadt",
    "East Blue Hill",
    "East Boothbay",
    "East Brady",
    "East Branch",
    "East Bridgewater",
    "East Brookfield",
    "East Brunswick",
    "East Burke",
    "East Butler",
    "East Calais",
    "East Canaan",
    "East Candia",
    "East Carbon City",
    "East Carondelet",
    "East Chatham",
    "East Chicago",
    "East China Township",
    "East Claridon",
    "East Corinth",
    "East Dennis",
    "East Dover",
    "East Dublin",
    "East Dubuque",
    "East Durham",
    "East Earl",
    "East Elmhurst",
    "East Fairfield",
    "East Fairview",
    "East Falmouth",
    "East Flat Rock",
    "East Freedom",
    "East Freetown",
    "East Galesburg",
    "East Granby",
    "East Grand Forks",
    "East Greenbush",
    "East Greenville",
    "East Greenwich",
    "East Haddam",
    "East Hampstead",
    "East Hampton",
    "East Hampton",
    "East Hanover",
    "East Hardwick",
    "East Hartford",
    "East Hartland",
    "East Haven",
    "East Helena",
    "East Irvine",
    "East Islip",
    "East Jordan",
    "East Kingston",
    "East Lake",
    "East Lansing",
    "East Leroy",
    "East Liberty",
    "East Liverpool",
    "East Longmeadow",
    "East Lynn",
    "East Lynn",
    "East Machias",
    "East Marion",
    "East McKeesport",
    "East Meadow",
    "East Meredith",
    "East Middlebury",
    "East Millinocket",
    "East Millsboro",
    "East Moline",
    "East Montpelier",
    "East Moriches",
    "East New Market",
    "East Northport",
    "East Norwich",
    "East Orange",
    "East Orleans",
    "East Otis",
    "East Palatka",
    "East Palestine",
    "East Peoria",
    "East Petersburg",
    "East Pharsalia",
    "East Pittsburgh",
    "East Point",
    "East Prairie",
    "East Prospect",
    "East Providence",
    "East Quogue",
    "East Randolph",
    "East Rochester",
    "East Rockaway",
    "East Rutherford",
    "East Saint Louis",
    "East Sandwich",
    "East Setauket",
    "East Sparta",
    "East Stone Gap",
    "East Stoneham",
    "East Stroudsburg",
    "East Syracuse",
    "East Taunton",
    "East Tawas",
    "East Thetford",
    "East Troy",
    "East Wakefield",
    "East Walpole",
    "East Wareham",
    "East Waterboro",
    "East Wenatchee",
    "East Weymouth",
    "East Windsor Hill",
    "Eastaboga",
    "Eastanollee",
    "Eastchester",
    "Eastern",
    "Eastford",
    "Eastham",
    "Easthampton",
    "Eastlake",
    "Eastlake",
    "Eastland",
    "Eastman",
    "Easton",
    "Easton",
    "Easton",
    "Easton",
    "Easton",
    "Easton",
    "Easton",
    "Easton",
    "Eastover",
    "Eastpoint",
    "Eastpointe",
    "Eastport",
    "Eastport",
    "Eastsound",
    "Eastview",
    "Eastville",
    "Eaton",
    "Eaton",
    "Eaton",
    "Eaton",
    "Eaton Rapids",
    "Eatonton",
    "Eatontown",
    "Eatonville",
    "Eau Claire",
    "Eau Claire",
    "Eau Galle",
    "Ebensburg",
    "Echo",
    "Echo",
    "Eckert",
    "Eckerty",
    "Eclectic",
    "Economy",
    "Ecorse",
    "Ecru",
    "Ector",
    "Edcouch",
    "Eddington",
    "Eddy",
    "Eddyville",
    "Eddyville",
    "Eddyville",
    "Edelstein",
    "Eden",
    "Eden",
    "Eden",
    "Eden",
    "Eden",
    "Eden",
    "Eden",
    "Eden",
    "Eden Prairie",
    "Eden Valley",
    "Edenton",
    "Edgar",
    "Edgar",
    "Edgard",
    "Edgartown",
    "Edgecomb",
    "Edgefield",
    "Edgeley",
    "Edgemont",
    "Edgemont",
    "Edgemoor",
    "Edgerton",
    "Edgerton",
    "Edgerton",
    "Edgerton",
    "Edgerton",
    "Edgewater",
    "Edgewater",
    "Edgewater",
    "Edgewood",
    "Edgewood",
    "Edgewood",
    "Edgewood",
    "Edgewood",
    "Edina",
    "Edinboro",
    "Edinburg",
    "Edinburg",
    "Edinburg",
    "Edinburg",
    "Edinburg",
    "Edinburgh",
    "Edison",
    "Edison",
    "Edison",
    "Edison",
    "Edisto Island",
    "Edmeston",
    "Edmond",
    "Edmonds",
    "Edmonton",
    "Edmore",
    "Edmore",
    "Edna",
    "Edna",
    "Edneyville",
    "Edon",
    "Edson",
    "Edwall",
    "Edwards",
    "Edwards",
    "Edwards",
    "Edwards",
    "Edwards",
    "Edwards Air Force Base",
    "Edwardsburg",
    "Edwardsport",
    "Edwardsville",
    "Effie",
    "Effingham",
    "Effingham",
    "Effingham",
    "Effingham",
    "Effort",
    "Efland",
    "Egan",
    "Egan",
    "Egg Harbor",
    "Egg Harbor",
    "Egg Harbor City",
    "Eglin",
    "Eglon",
    "Egypt",
    "Ehrenberg",
    "Eielson Air Force Base",
    "Eight Mile",
    "Eighty Four",
    "Ekalaka",
    "Ekron",
    "El Cajon",
    "El Campo",
    "El Centro",
    "El Cerrito",
    "El Dorado",
    "El Dorado",
    "El Dorado",
    "El Dorado Hills",
    "El Dorado Springs",
    "El Granada",
    "El Macero",
    "El Mirage",
    "El Monte",
    "El Paso",
    "El Paso",
    "El Paso",
    "El Prado",
    "El Reno",
    "El Segundo",
    "El Sobrante",
    "Elba",
    "Elba",
    "Elba",
    "Elberon",
    "Elbert",
    "Elbert",
    "Elberta",
    "Elberta",
    "Elberton",
    "Elbow Lake",
    "Elbridge",
    "Elburn",
    "Elcho",
    "Elco",
    "Elderton",
    "Eldon",
    "Eldon",
    "Eldora",
    "Eldorado",
    "Eldorado",
    "Eldorado",
    "Eldred",
    "Eldred",
    "Eldred",
    "Eldridge",
    "Eldridge",
    "Eleanor",
    "Electra",
    "Elephant Butte",
    "Eleva",
    "Elfrida",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Elgin",
    "Eliot",
    "Elizabeth",
    "Elizabeth",
    "Elizabeth",
    "Elizabeth",
    "Elizabeth",
    "Elizabeth",
    "Elizabeth City",
    "Elizabethton",
    "Elizabethtown",
    "Elizabethtown",
    "Elizabethtown",
    "Elizabethtown",
    "Elizabethtown",
    "Elizabethtown",
    "Elizabethville",
    "Elizaville",
    "Elk",
    "Elk City",
    "Elk Falls",
    "Elk Garden",
    "Elk Grove",
    "Elk Grove Village",
    "Elk Horn",
    "Elk Horn",
    "Elk Mills",
    "Elk Mound",
    "Elk Park",
    "Elk Point",
    "Elk Rapids",
    "Elk River",
    "Elk River",
    "Elkader",
    "Elkfork",
    "Elkhart",
    "Elkhart",
    "Elkhart",
    "Elkhart",
    "Elkhart",
    "Elkhart Lake",
    "Elkhorn",
    "Elkhorn",
    "Elkhorn",
    "Elkhorn City",
    "Elkin",
    "Elkins",
    "Elkins",
    "Elkins Park",
    "Elkland",
    "Elkland",
    "Elkmont",
    "Elko",
    "Elko",
    "Elko",
    "Elkridge",
    "Elkton",
    "Elkton",
    "Elkton",
    "Elkton",
    "Elkton",
    "Elkton",
    "Elkton",
    "Elkview",
    "Elkville",
    "Elkwood",
    "Ellabell",
    "Ellaville",
    "Ellenboro",
    "Ellenburg Center",
    "Ellenburg Depot",
    "Ellendale",
    "Ellendale",
    "Ellendale",
    "Ellendale",
    "Ellensburg",
    "Ellenton",
    "Ellenville",
    "Ellenwood",
    "Ellerbe",
    "Ellerslie",
    "Ellettsville",
    "Ellicott City",
    "Ellicottville",
    "Ellijay",
    "Ellington",
    "Ellington",
    "Ellinwood",
    "Elliott",
    "Elliottsburg",
    "Ellis",
    "Ellis Grove",
    "Elliston",
    "Ellisville",
    "Elloree",
    "Ellsinore",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellsworth",
    "Ellwood City",
    "Elm City",
    "Elm Creek",
    "Elm Grove",
    "Elm Grove",
    "Elma",
    "Elma",
    "Elma",
    "Elmdale",
    "Elmendorf",
    "Elmer",
    "Elmer",
    "Elmer City",
    "Elmhurst",
    "Elmhurst",
    "Elmhurst",
    "Elmira",
    "Elmira",
    "Elmira",
    "Elmira",
    "Elmo",
    "Elmont",
    "Elmora",
    "Elmore",
    "Elmore",
    "Elmore",
    "Elmore City",
    "Elmsford",
    "Elmwood",
    "Elmwood",
    "Elmwood",
    "Elmwood",
    "Elmwood Park",
    "Elmwood Park",
    "Elnora",
    "Elon",
    "Elora",
    "Eloy",
    "Elroy",
    "Elsa",
    "Elsah",
    "Elsberry",
    "Elsie",
    "Elsie",
    "Elsinore",
    "Elton",
    "Eltopia",
    "Elvaston",
    "Elverson",
    "Elverta",
    "Elwell",
    "Elwood",
    "Elwood",
    "Elwood",
    "Elwood",
    "Ely",
    "Ely",
    "Ely",
    "Elyria",
    "Elysburg",
    "Elysian",
    "Elysian Fields",
    "Embarrass",
    "Embudo",
    "Emden",
    "Emerado",
    "Emerald Isle",
    "Emerson",
    "Emerson",
    "Emerson",
    "Emerson",
    "Emerson",
    "Emerson",
    "Emery",
    "Emery",
    "Emeryville",
    "Emigrant",
    "Emily",
    "Eminence",
    "Eminence",
    "Emlenton",
    "Emmaus",
    "Emmet",
    "Emmetsburg",
    "Emmett",
    "Emmett",
    "Emmitsburg",
    "Emmons",
    "Emory",
    "Emory",
    "Empire",
    "Empire",
    "Empire",
    "Empire",
    "Empire",
    "Empire",
    "Emporia",
    "Emporia",
    "Emporium",
    "Encampment",
    "Encinitas",
    "Encino",
    "Endeavor",
    "Enderlin",
    "Endicott",
    "Endicott",
    "Endicott",
    "Energy",
    "Enfield",
    "Enfield",
    "Enfield",
    "Enfield",
    "Engelhard",
    "England",
    "Englewood",
    "Englewood",
    "Englewood",
    "Englewood",
    "Englewood",
    "Englewood Cliffs",
    "Englishtown",
    "Enid",
    "Enigma",
    "Enka",
    "Ennice",
    "Ennis",
    "Ennis",
    "Enola",
    "Enola",
    "Enon",
    "Enoree",
    "Enosburg Falls",
    "Enterprise",
    "Enterprise",
    "Enterprise",
    "Enterprise",
    "Enterprise",
    "Entiat",
    "Enumclaw",
    "Eolia",
    "Eolia",
    "Epes",
    "Ephraim",
    "Ephraim",
    "Ephrata",
    "Ephrata",
    "Epping",
    "Epping",
    "Epps",
    "Epsom",
    "Epworth",
    "Epworth",
    "Equality",
    "Equality",
    "Equinunk",
    "Erath",
    "Erbacon",
    "Erhard",
    "Erick",
    "Erie",
    "Erie",
    "Erie",
    "Erie",
    "Erie",
    "Erieville",
    "Erin",
    "Erin",
    "Erlanger",
    "Eros",
    "Errol",
    "Erskine",
    "Erving",
    "Ervings Location",
    "Erwin",
    "Erwin",
    "Erwinna",
    "Erwinville",
    "Esbon",
    "Escalante",
    "Escalon",
    "Escanaba",
    "Escondido",
    "Esko",
    "Eskridge",
    "Esmond",
    "Esmond",
    "Esmont",
    "Espanola",
    "Esparto",
    "Esperance",
    "Essex",
    "Essex",
    "Essex",
    "Essex",
    "Essex",
    "Essex Fells",
    "Essex Junction",
    "Essexville",
    "Essie",
    "Essington",
    "Estacada",
    "Estancia",
    "Estell Manor",
    "Estelline",
    "Estero",
    "Estes Park",
    "Estherville",
    "Estherwood",
    "Estill",
    "Estill Springs",
    "Estillfork",
    "Ethel",
    "Ethel",
    "Ethel",
    "Ethelsville",
    "Ethridge",
    "Etna",
    "Etna",
    "Etna",
    "Etna",
    "Etna",
    "Etna",
    "Etna Green",
    "Etoile",
    "Eton",
    "Etowah",
    "Etta",
    "Ettrick",
    "Eubank",
    "Eucha",
    "Euclid",
    "Euclid",
    "Eudora",
    "Eudora",
    "Eufaula",
    "Eufaula",
    "Eugene",
    "Eugene",
    "Euless",
    "Eunice",
    "Eunice",
    "Eupora",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka",
    "Eureka Springs",
    "Eustace",
    "Eustis",
    "Eustis",
    "Eutaw",
    "Eutawville",
    "Eva",
    "Evangeline",
    "Evans",
    "Evans",
    "Evans",
    "Evans",
    "Evans",
    "Evans City",
    "Evans Mills",
    "Evansdale",
    "Evanston",
    "Evanston",
    "Evanston",
    "Evansville",
    "Evansville",
    "Evansville",
    "Evansville",
    "Evansville",
    "Evansville",
    "Evant",
    "Evart",
    "Evarts",
    "Eveleth",
    "Evensville",
    "Everest",
    "Everett",
    "Everett",
    "Everett",
    "Everetts",
    "Everglades City",
    "Evergreen",
    "Evergreen",
    "Evergreen",
    "Evergreen",
    "Evergreen Park",
    "Everly",
    "Everson",
    "Everson",
    "Everton",
    "Everton",
    "Evington",
    "Ewan",
    "Ewen",
    "Ewing",
    "Ewing",
    "Ewing",
    "Ewing",
    "Ewing",
    "Excel",
    "Excello",
    "Excelsior",
    "Excelsior Springs",
    "Exeland",
    "Exeter",
    "Exeter",
    "Exeter",
    "Exeter",
    "Exeter",
    "Exira",
    "Exline",
    "Exmore",
    "Experiment",
    "Export",
    "Exton",
    "Eyota",
    "Ezel",
    "Fabens",
    "Faber",
    "Fabius",
    "Fackler",
    "Factoryville",
    "Fair Bluff",
    "Fair Grove",
    "Fair Haven",
    "Fair Haven",
    "Fair Haven",
    "Fair Lawn",
    "Fair Oaks",
    "Fair Oaks",
    "Fair Play",
    "Fair Play",
    "Fairacres",
    "Fairbank",
    "Fairbanks",
    "Fairbanks North Star Borough",
    "Fairborn",
    "Fairburn",
    "Fairburn",
    "Fairbury",
    "Fairbury",
    "Fairchance",
    "Fairchild",
    "Fairchild Air Force Base",
    "Fairdale",
    "Fairdale",
    "Fairdealing",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax",
    "Fairfax Station",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield",
    "Fairfield Bay",
    "Fairgrove",
    "Fairhaven",
    "Fairhope",
    "Fairhope",
    "Fairland",
    "Fairland",
    "Fairlee",
    "Fairless Hills",
    "Fairmont",
    "Fairmont",
    "Fairmont",
    "Fairmont",
    "Fairmont",
    "Fairmont City",
    "Fairmount",
    "Fairmount",
    "Fairmount",
    "Fairmount",
    "Fairplay",
    "Fairplay",
    "Fairport",
    "Fairton",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview",
    "Fairview Heights",
    "Fairview Village",
    "Faith",
    "Faith",
    "Falcon",
    "Falconer",
    "Falfurrias",
    "Falkland",
    "Falkner",
    "Falkville",
    "Fall Branch",
    "Fall City",
    "Fall Creek",
    "Fall Creek",
    "Fall River",
    "Fall River",
    "Fall River Mills",
    "Fallbrook",
    "Falling Rock",
    "Falling Waters",
    "Fallon",
    "Falls",
    "Falls Church",
    "Falls City",
    "Falls City",
    "Falls Creek",
    "Falls Mills",
    "Falls of Rough",
    "Falls Village",
    "Fallsburg",
    "Fallston",
    "Falmouth",
    "Falmouth",
    "Falmouth",
    "Fancy Farm",
    "Fannettsburg",
    "Fanwood",
    "Far Hills",
    "Far Rockaway",
    "Farber",
    "Fargo",
    "Faribault",
    "Farina",
    "Farley",
    "Farlington",
    "Farmdale",
    "Farmer City",
    "Farmersburg",
    "Farmersville",
    "Farmersville",
    "Farmersville",
    "Farmersville",
    "Farmerville",
    "Farmingdale",
    "Farmingdale",
    "Farmingdale",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington",
    "Farmington Hills",
    "Farmingville",
    "Farmland",
    "Farmville",
    "Farmville",
    "Farnham",
    "Farnhamville",
    "Farragut",
    "Farragut",
    "Farrell",
    "Farson",
    "Farwell",
    "Farwell",
    "Farwell",
    "Farwell",
    "Faulkner",
    "Faulkton",
    "Fawn Grove",
    "Fawnskin",
    "Faxon",
    "Fayette",
    "Fayette",
    "Fayette",
    "Fayette",
    "Fayette",
    "Fayette City",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayetteville",
    "Fayville",
    "Feasterville-Trevose",
    "Federal Way",
    "Federalsburg",
    "Feeding Hills",
    "Felda",
    "Felicity",
    "Fellsmere",
    "Felton",
    "Felton",
    "Felton",
    "Felton",
    "Fenelton",
    "Fennimore",
    "Fennville",
    "Fenton",
    "Fenton",
    "Fenton",
    "Fenton",
    "Fentress",
    "Fenwick",
    "Fenwick",
    "Fenwick Island",
    "Ferdinand",
    "Fergus Falls",
    "Ferguson",
    "Ferguson",
    "Fernandina Beach",
    "Ferndale",
    "Ferndale",
    "Ferndale",
    "Ferndale",
    "Fernley",
    "Fernwood",
    "Ferriday",
    "Ferris",
    "Ferrisburgh",
    "Ferron",
    "Ferrum",
    "Ferrysburg",
    "Fertile",
    "Fessenden",
    "Festus",
    "Feura Bush",
    "Fiddletown",
    "Fidelity",
    "Fieldale",
    "Fielding",
    "Fieldon",
    "Fife Lake",
    "Fifty Lakes",
    "Filer",
    "Filley",
    "Fillmore",
    "Fillmore",
    "Fillmore",
    "Fillmore",
    "Fincastle",
    "Finchville",
    "Findlay",
    "Findlay",
    "Finger",
    "Fingerville",
    "Finksburg",
    "Finlayson",
    "Finley",
    "Finleyville",
    "Firebaugh",
    "Firestone",
    "Firth",
    "Firth",
    "Fischer",
    "Fish Camp",
    "Fish Creek",
    "Fish Haven",
    "Fishel",
    "Fisher",
    "Fisher",
    "Fisher",
    "Fishers",
    "Fishers Island",
    "Fishersville",
    "Fisherville",
    "Fishkill",
    "Fishtail",
    "Fisk",
    "Fiskdale",
    "Fitchburg",
    "Fitzgerald",
    "Fitzhugh",
    "Fitzpatrick",
    "Fitzwilliam",
    "Five Points",
    "Five Points",
    "Flag Pond",
    "Flagler",
    "Flagler Beach",
    "Flagstaff",
    "Flanagan",
    "Flanders",
    "Flandreau",
    "Flasher",
    "Flat Lick",
    "Flat Rock",
    "Flat Rock",
    "Flat Rock",
    "Flat Rock",
    "Flat Rock",
    "Flatgap",
    "Flatwoods",
    "Flatwoods",
    "Flatwoods",
    "Flaxton",
    "Flaxville",
    "Fleetwood",
    "Fleetwood",
    "Fleischmanns",
    "Fleming",
    "Fleming",
    "Fleming",
    "Flemingsburg",
    "Flemington",
    "Flemington",
    "Flemington",
    "Flensburg",
    "Fletcher",
    "Fletcher",
    "Flint",
    "Flint",
    "Flinton",
    "Flintstone",
    "Flintstone",
    "Flintville",
    "Flippin",
    "Flomaton",
    "Floodwood",
    "Flora",
    "Flora",
    "Flora Vista",
    "Florahome",
    "Floral City",
    "Floral Park",
    "Florala",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Florence",
    "Floresville",
    "Florham Park",
    "Florida",
    "Florien",
    "Florissant",
    "Florissant",
    "Flossmoor",
    "Flourtown",
    "Flovilla",
    "Flower Mound",
    "Floweree",
    "Flowery Branch",
    "Flowood",
    "Floyd",
    "Floyd",
    "Floydada",
    "Floyds Knobs",
    "Fluker",
    "Flushing",
    "Flushing",
    "Flushing",
    "Fly Creek",
    "Fogelsville",
    "Folcroft",
    "Foley",
    "Foley",
    "Foley",
    "Folkston",
    "Follansbee",
    "Follett",
    "Folly Beach",
    "Folsom",
    "Folsom",
    "Folsom",
    "Folsom",
    "Fombell",
    "Fond du Lac",
    "Fonda",
    "Fonda",
    "Fontana",
    "Fontana",
    "Fontanelle",
    "Foothill Ranch",
    "Forbes",
    "Forbes Road",
    "Ford",
    "Ford",
    "Ford City",
    "Fordland",
    "Fordoche",
    "Fords",
    "Fordsville",
    "Fordville",
    "Fordyce",
    "Foreman",
    "Forest",
    "Forest",
    "Forest",
    "Forest",
    "Forest City",
    "Forest City",
    "Forest City",
    "Forest City",
    "Forest Falls",
    "Forest Grove",
    "Forest Hill",
    "Forest Hill",
    "Forest Hills",
    "Forest Home",
    "Forest Junction",
    "Forest Knolls",
    "Forest Lake",
    "Forest Park",
    "Forest Park",
    "Forest River",
    "Forestdale",
    "Foresthill",
    "Foreston",
    "Forestport",
    "Forestville",
    "Forestville",
    "Forestville",
    "Forgan",
    "Foristell",
    "Fork Union",
    "Forked River",
    "Forkland",
    "Forks",
    "Forksville",
    "Forman",
    "Formoso",
    "Forney",
    "Forrest",
    "Forrest City",
    "Forreston",
    "Forreston",
    "Forsyth",
    "Forsyth",
    "Forsyth",
    "Forsyth",
    "Fort Ann",
    "Fort Apache",
    "Fort Ashby",
    "Fort Atkinson",
    "Fort Atkinson",
    "Fort Belvoir",
    "Fort Benning",
    "Fort Benton",
    "Fort Blackmore",
    "Fort Bragg",
    "Fort Bragg",
    "Fort Branch",
    "Fort Bridger",
    "Fort Calhoun",
    "Fort Campbell North",
    "Fort Cobb",
    "Fort Collins",
    "Fort Davis",
    "Fort Defiance",
    "Fort Defiance",
    "Fort Deposit",
    "Fort Dodge",
    "Fort Drum",
    "Fort Duchesne",
    "Fort Edward",
    "Fort Fairfield",
    "Fort Gaines",
    "Fort Garland",
    "Fort Gay",
    "Fort George Meade",
    "Fort Gibson",
    "Fort Gratiot Township",
    "Fort Greely",
    "Fort Hall",
    "Fort Irwin",
    "Fort Jennings",
    "Fort Jones",
    "Fort Kent",
    "Fort Knox",
    "Fort Lauderdale",
    "Fort Lee",
    "Fort Lee",
    "Fort Leonard Wood",
    "Fort Loramie",
    "Fort Loudon",
    "Fort Lupton",
    "Fort Madison",
    "Fort McCoy",
    "Fort McDowell",
    "Fort Meade",
    "Fort Mill",
    "Fort Mitchell",
    "Fort Mitchell",
    "Fort Montgomery",
    "Fort Morgan",
    "Fort Myers",
    "Fort Myers Beach",
    "Fort Oglethorpe",
    "Fort Payne",
    "Fort Pierre",
    "Fort Plain",
    "Fort Polk",
    "Fort Ransom",
    "Fort Recovery",
    "Fort Richardson",
    "Fort Riley-Camp Whiteside",
    "Fort Ripley",
    "Fort Rucker",
    "Fort Scott",
    "Fort Sill Indian School Reservation (historical)",
    "Fort Smith",
    "Fort Stewart",
    "Fort Stockton",
    "Fort Sumner",
    "Fort Thomas",
    "Fort Thompson",
    "Fort Totten",
    "Fort Valley",
    "Fort Walton Beach",
    "Fort Washakie",
    "Fort Washington",
    "Fort Wayne",
    "Fort White",
    "Fort Worth",
    "Fort Yates",
    "Fortine",
    "Fortson",
    "Fortuna",
    "Fortuna",
    "Fortville",
    "Foss",
    "Fossil",
    "Fosston",
    "Foster",
    "Foster",
    "Foster",
    "Foster",
    "Foster",
    "Foster City",
    "Foster City",
    "Fosters",
    "Fostoria",
    "Fostoria",
    "Fouke",
    "Fountain",
    "Fountain",
    "Fountain",
    "Fountain",
    "Fountain City",
    "Fountain City",
    "Fountain Green",
    "Fountain Hills",
    "Fountain Inn",
    "Fountain Run",
    "Fountain Valley",
    "Fountaintown",
    "Fountainville",
    "Four Oaks",
    "Fowler",
    "Fowler",
    "Fowler",
    "Fowler",
    "Fowler",
    "Fowler",
    "Fowlerville",
    "Fox Island",
    "Fox Lake",
    "Fox Lake",
    "Fox River Grove",
    "Foxborough",
    "Foxburg",
    "Foxhome",
    "Foxworth",
    "Frackville",
    "Framingham",
    "Francestown",
    "Francesville",
    "Francisco",
    "Franconia",
    "Frankenmuth",
    "Frankewing",
    "Frankford",
    "Frankford",
    "Frankford",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Frankfort",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin",
    "Franklin Grove",
    "Franklin Lakes",
    "Franklin Park",
    "Franklin Park",
    "Franklin Springs",
    "Franklin Square",
    "Franklinton",
    "Franklinton",
    "Franklinville",
    "Franklinville",
    "Franklinville",
    "Frankston",
    "Franksville",
    "Frankton",
    "Franktown",
    "Frannie",
    "Fraser",
    "Fraser",
    "Frazee",
    "Frazer",
    "Frazeysburg",
    "Frazier Park",
    "Fraziers Bottom",
    "Fred",
    "Frederic",
    "Frederica",
    "Frederick",
    "Frederick",
    "Frederick",
    "Frederick",
    "Frederick",
    "Fredericksburg",
    "Fredericksburg",
    "Fredericksburg",
    "Fredericksburg",
    "Fredericktown",
    "Fredericktown",
    "Fredericktown",
    "Fredonia",
    "Fredonia",
    "Fredonia",
    "Fredonia",
    "Fredonia",
    "Fredonia",
    "Free Soil",
    "Free Union",
    "Freeburg",
    "Freeburg",
    "Freeburg",
    "Freeburn",
    "Freedom",
    "Freedom",
    "Freedom",
    "Freedom",
    "Freedom",
    "Freehold",
    "Freehold Borough",
    "Freeland",
    "Freeland",
    "Freeland",
    "Freeland",
    "Freeman",
    "Freeman",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freeport",
    "Freer",
    "Freetown",
    "Freeville",
    "Fremont",
    "Fremont",
    "Fremont",
    "Fremont",
    "Fremont",
    "Fremont",
    "Fremont",
    "Fremont",
    "French Camp",
    "French Camp",
    "French Lick",
    "French Settlement",
    "French Village",
    "Frenchburg",
    "Frenchtown",
    "Frenchtown",
    "Frenchville",
    "Fresh Meadows",
    "Fresno",
    "Fresno",
    "Fresno",
    "Frewsburg",
    "Friant",
    "Friars Point",
    "Friday Harbor",
    "Friedens",
    "Friend",
    "Friendly",
    "Friendship",
    "Friendship",
    "Friendsville",
    "Friendsville",
    "Friendsville",
    "Friendswood",
    "Fries",
    "Friona",
    "Frisco",
    "Frisco",
    "Frisco City",
    "Fritch",
    "Frohna",
    "Froid",
    "Front Royal",
    "Frontenac",
    "Frost",
    "Frostburg",
    "Frostproof",
    "Fruita",
    "Fruitdale",
    "Fruitland",
    "Fruitland",
    "Fruitland",
    "Fruitland",
    "Fruitland Park",
    "Fruitport",
    "Fruitvale",
    "Fryburg",
    "Fryeburg",
    "Ft. Pierce",
    "Ft. Washington",
    "Fulda",
    "Fullerton",
    "Fullerton",
    "Fullerton",
    "Fulshear",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fulton",
    "Fultondale",
    "Fultonham",
    "Fultonville",
    "Fults",
    "Funkstown",
    "Fuquay-Varina",
    "Furlong",
    "Fyffe",
    "Gable",
    "Gackle",
    "Gadsden",
    "Gadsden",
    "Gadsden",
    "Gadsden",
    "Gaffney",
    "Gagetown",
    "Gail",
    "Gaines",
    "Gaines",
    "Gainesboro",
    "Gainestown",
    "Gainesville",
    "Gainesville",
    "Gainesville",
    "Gainesville",
    "Gainesville",
    "Gainesville",
    "Gaithersburg",
    "Galatia",
    "Galax",
    "Galena",
    "Galena",
    "Galena",
    "Galena",
    "Galena",
    "Galena",
    "Galena Park",
    "Gales Ferry",
    "Galesburg",
    "Galesburg",
    "Galesburg",
    "Galesville",
    "Galesville",
    "Galien",
    "Galion",
    "Galivants Ferry",
    "Gallatin",
    "Gallatin",
    "Gallatin Gateway",
    "Galliano",
    "Gallion",
    "Gallipolis",
    "Gallipolis Ferry",
    "Gallitzin",
    "Galloway",
    "Gallup",
    "Galt",
    "Galt",
    "Galva",
    "Galva",
    "Galva",
    "Galveston",
    "Galveston",
    "Galway",
    "Gambier",
    "Gambrills",
    "Ganado",
    "Ganado",
    "Gans",
    "Gansevoort",
    "Gap",
    "Gap Mills",
    "Garards Fort",
    "Garber",
    "Garberville",
    "Garciasville",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden City",
    "Garden Grove",
    "Garden Grove",
    "Garden Plain",
    "Garden Prairie",
    "Garden Valley",
    "Garden Valley",
    "Gardena",
    "Gardendale",
    "Gardendale",
    "Gardiner",
    "Gardiner",
    "Gardiner",
    "Gardner",
    "Gardner",
    "Gardner",
    "Gardner",
    "Gardners",
    "Gardnerville",
    "Garfield",
    "Garfield",
    "Garfield",
    "Garfield",
    "Garfield",
    "Garland",
    "Garland",
    "Garland",
    "Garland",
    "Garnavillo",
    "Garner",
    "Garner",
    "Garner",
    "Garnerville",
    "Garnet Valley",
    "Garnett",
    "Garrard",
    "Garretson",
    "Garrett",
    "Garrett",
    "Garrett",
    "Garrettsville",
    "Garrison",
    "Garrison",
    "Garrison",
    "Garrison",
    "Garrison",
    "Garryowen",
    "Garvin",
    "Garwin",
    "Garwood",
    "Gary",
    "Gary",
    "Gary",
    "Gary",
    "Gary",
    "Garysburg",
    "Garyville",
    "Gas",
    "Gas City",
    "Gasport",
    "Gasquet",
    "Gassaway",
    "Gassville",
    "Gaston",
    "Gaston",
    "Gaston",
    "Gaston",
    "Gastonia",
    "Gastonville",
    "Gate City",
    "Gates",
    "Gates",
    "Gates Mills",
    "Gatesville",
    "Gateway",
    "Gatlinburg",
    "Gatzke",
    "Gauley Bridge",
    "Gause",
    "Gautier",
    "Gay",
    "Gay",
    "Gaylesville",
    "Gaylord",
    "Gaylord",
    "Gaylordsville",
    "Gays Creek",
    "Gays Mills",
    "Gazelle",
    "Geary",
    "Geff",
    "Geigertown",
    "Geismar",
    "Genesee",
    "Genesee",
    "Genesee",
    "Geneseo",
    "Geneseo",
    "Geneseo",
    "Geneva",
    "Geneva",
    "Geneva",
    "Geneva",
    "Geneva",
    "Geneva",
    "Geneva",
    "Geneva",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa",
    "Genoa City",
    "Gentry",
    "Gentry",
    "Gentryville",
    "George",
    "George West",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgetown",
    "Georgiana",
    "Gerald",
    "Geraldine",
    "Geraldine",
    "Gerber",
    "Gering",
    "Gerlach",
    "German Valley",
    "Germansville",
    "Germanton",
    "Germantown",
    "Germantown",
    "Germantown",
    "Germantown",
    "Germantown",
    "Germantown",
    "Germantown",
    "Geronimo",
    "Gerrardstown",
    "Gerry",
    "Gervais",
    "Gettysburg",
    "Gettysburg",
    "Getzville",
    "Geuda Springs",
    "Geyser",
    "Geyserville",
    "Gheens",
    "Ghent",
    "Ghent",
    "Ghent",
    "Ghent",
    "Gibbon",
    "Gibbon",
    "Gibbsboro",
    "Gibbstown",
    "Gibsland",
    "Gibson",
    "Gibson",
    "Gibson",
    "Gibson City",
    "Gibsonburg",
    "Gibsonia",
    "Gibsonton",
    "Gibsonville",
    "Giddings",
    "Gideon",
    "Gifford",
    "Gifford",
    "Gig Harbor",
    "Gila",
    "Gila Bend",
    "Gilbert",
    "Gilbert",
    "Gilbert",
    "Gilbert",
    "Gilbert",
    "Gilbert",
    "Gilbertown",
    "Gilberts",
    "Gilbertsville",
    "Gilbertsville",
    "Gilbertsville",
    "Gilbertville",
    "Gilbertville",
    "Gilboa",
    "Gilby",
    "Gilcrest",
    "Gilford",
    "Gill",
    "Gill",
    "Gillespie",
    "Gillett",
    "Gillett",
    "Gillett Grove",
    "Gillette",
    "Gillette",
    "Gillham",
    "Gillsville",
    "Gilman",
    "Gilman",
    "Gilman",
    "Gilman",
    "Gilman City",
    "Gilmanton",
    "Gilmanton",
    "Gilmanton Ironworks",
    "Gilmer",
    "Gilmore City",
    "Gilroy",
    "Gilson",
    "Gilsum",
    "Giltner",
    "Girard",
    "Girard",
    "Girard",
    "Girard",
    "Girardville",
    "Girdwood",
    "Girvin",
    "Gladbrook",
    "Glade Spring",
    "Glade Valley",
    "Gladehill",
    "Gladewater",
    "Gladstone",
    "Gladstone",
    "Gladstone",
    "Gladstone",
    "Gladwin",
    "Gladwyne",
    "Gladys",
    "Glasco",
    "Glasco",
    "Glasford",
    "Glasgow",
    "Glasgow",
    "Glasgow",
    "Glasgow",
    "Glassboro",
    "Glassport",
    "Glastonbury",
    "Gleason",
    "Gleason",
    "Glen Allan",
    "Glen Allen",
    "Glen Arbor",
    "Glen Arm",
    "Glen Burnie",
    "Glen Campbell",
    "Glen Carbon",
    "Glen Cove",
    "Glen Daniel",
    "Glen Easton",
    "Glen Elder",
    "Glen Ellen",
    "Glen Ellyn",
    "Glen Flora",
    "Glen Fork",
    "Glen Gardner",
    "Glen Head",
    "Glen Hope",
    "Glen Lyon",
    "Glen Mills",
    "Glen Oaks",
    "Glen Ridge",
    "Glen Rock",
    "Glen Rock",
    "Glen Rose",
    "Glen Saint Mary",
    "Glen Spey",
    "Glen Ullin",
    "Glenallen",
    "Glenbeulah",
    "Glenbrook",
    "Glenburn",
    "Glencoe",
    "Glencoe",
    "Glencoe",
    "Glencoe",
    "Glencoe",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale",
    "Glendale Heights",
    "Glendive",
    "Glendo",
    "Glendora",
    "Glendora",
    "Glenelg",
    "Glenfield",
    "Glenford",
    "Glengary",
    "Glenham",
    "Glenmont",
    "Glenmont",
    "Glenmoore",
    "Glenmora",
    "Glenn",
    "Glenn Dale",
    "Glenns Ferry",
    "Glennville",
    "Glennville",
    "Glenolden",
    "Glenpool",
    "Glenrock",
    "Glens",
    "Glens Falls",
    "Glens Fork",
    "Glenshaw",
    "Glenside",
    "Glenview",
    "Glenvil",
    "Glenville",
    "Glenville",
    "Glenville",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood",
    "Glenwood City",
    "Glenwood Landing",
    "Glenwood Springs",
    "Glidden",
    "Glidden",
    "Glidden",
    "Globe",
    "Glorieta",
    "Gloster",
    "Gloster",
    "Gloucester",
    "Gloucester",
    "Gloucester City",
    "Gloucester Point",
    "Glouster",
    "Glover",
    "Gloversville",
    "Glyndon",
    "Gobles",
    "Goddard",
    "Godfrey",
    "Godley",
    "Godwin",
    "Goehner",
    "Goessel",
    "Goff",
    "Goffstown",
    "Golconda",
    "Gold Bar",
    "Gold Beach",
    "Gold Canyon",
    "Gold Hill",
    "Gold Hill",
    "Golden",
    "Golden",
    "Golden",
    "Golden",
    "Golden City",
    "Golden Eagle",
    "Golden Meadow",
    "Golden Valley",
    "Golden Valley",
    "Goldendale",
    "Goldenrod",
    "Goldens Bridge",
    "Goldfield",
    "Goldonna",
    "Goldsboro",
    "Goldsboro",
    "Goldsmith",
    "Goldston",
    "Goldthwaite",
    "Goldvein",
    "Goleta",
    "Goliad",
    "Goltry",
    "Gonvick",
    "Gonzales",
    "Gonzales",
    "Gonzales",
    "Goochland",
    "Good Hope",
    "Good Hope",
    "Good Thunder",
    "Goode",
    "Goodells",
    "Goodfield",
    "Goodhue",
    "Gooding",
    "Goodland",
    "Goodland",
    "Goodland",
    "Goodland",
    "Goodlettsville",
    "Goodman",
    "Goodnews Bay",
    "Goodrich",
    "Goodrich",
    "Goodridge",
    "Goodview",
    "Goodwater",
    "Goodwell",
    "Goodyear",
    "Goose Creek",
    "Gordo",
    "Gordon",
    "Gordon",
    "Gordon",
    "Gordon",
    "Gordon",
    "Gordonsville",
    "Gordonsville",
    "Gordonville",
    "Gordonville",
    "Gore",
    "Gore",
    "Gore Springs",
    "Goreville",
    "Gorham",
    "Gorham",
    "Gorin",
    "Gorman",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Goshen",
    "Gosport",
    "Gotha",
    "Gotham",
    "Gothenburg",
    "Gould",
    "Gouldsboro",
    "Gouldsboro",
    "Gouverneur",
    "Gowanda",
    "Gowen",
    "Gower",
    "Gowrie",
    "Grabill",
    "Grace",
    "Grace City",
    "Gracemont",
    "Graceville",
    "Graceville",
    "Grady",
    "Grady",
    "Gradyville",
    "Graettinger",
    "Graford",
    "Grafton",
    "Grafton",
    "Grafton",
    "Grafton",
    "Grafton",
    "Grafton",
    "Grafton",
    "Grafton",
    "Graham",
    "Graham",
    "Graham",
    "Graham",
    "Grain Valley",
    "Grambling",
    "Gramercy",
    "Grammer",
    "Grampian",
    "Granada",
    "Granada Hills",
    "Granbury",
    "Granby",
    "Granby",
    "Granby",
    "Granby",
    "Grand Bay",
    "Grand Blanc",
    "Grand Cane",
    "Grand Canyon",
    "Grand Coulee",
    "Grand Forks",
    "Grand Haven",
    "Grand Island",
    "Grand Island",
    "Grand Island",
    "Grand Isle",
    "Grand Isle",
    "Grand Junction",
    "Grand Junction",
    "Grand Junction",
    "Grand Junction",
    "Grand Lake",
    "Grand Ledge",
    "Grand Marais",
    "Grand Marsh",
    "Grand Meadow",
    "Grand Mound",
    "Grand Portage",
    "Grand Prairie",
    "Grand Rapids",
    "Grand Rapids",
    "Grand Rapids",
    "Grand Ridge",
    "Grand River",
    "Grand River",
    "Grand Rivers",
    "Grand Ronde",
    "Grand Saline",
    "Grand Terrace",
    "Grand Tower",
    "Grand View",
    "Grand View",
    "Grandfalls",
    "Grandfield",
    "Grandin",
    "Grandview",
    "Grandview",
    "Grandview",
    "Grandview",
    "Grandville",
    "Grandy",
    "Granger",
    "Granger",
    "Granger",
    "Granger",
    "Grangeville",
    "Granite",
    "Granite Bay",
    "Granite City",
    "Granite Falls",
    "Granite Falls",
    "Granite Falls",
    "Granite Quarry",
    "Graniteville",
    "Graniteville",
    "Grant",
    "Grant",
    "Grant",
    "Grant",
    "Grant",
    "Grant City",
    "Grant Park",
    "Grantham",
    "Granton",
    "Grants",
    "Grants Pass",
    "Grantsboro",
    "Grantsburg",
    "Grantsville",
    "Grantsville",
    "Grantsville",
    "Grantville",
    "Grantville",
    "Granville",
    "Granville",
    "Granville",
    "Granville",
    "Granville",
    "Granville",
    "Granville",
    "Granville Summit",
    "Grapeland",
    "Grapeview",
    "Grapevine",
    "Grapevine",
    "Grasonville",
    "Grass Lake",
    "Grass Valley",
    "Grass Valley",
    "Grassflat",
    "Grassy Creek",
    "Grassy Meadows",
    "Gratiot",
    "Gratis",
    "Graton",
    "Gratz",
    "Gravel Switch",
    "Gravelly",
    "Gravette",
    "Gravity",
    "Gravois Mills",
    "Grawn",
    "Gray",
    "Gray",
    "Gray",
    "Gray",
    "Gray",
    "Gray Court",
    "Gray Summit",
    "Grayland",
    "Grayling",
    "Grayslake",
    "Grayson",
    "Grayson",
    "Grayson",
    "Graysville",
    "Graysville",
    "Graysville",
    "Graytown",
    "Grayville",
    "Great Barrington",
    "Great Bend",
    "Great Bend",
    "Great Cacapon",
    "Great Falls",
    "Great Falls",
    "Great Falls",
    "Great Lakes",
    "Great Meadows",
    "Great Mills",
    "Great Neck",
    "Great River",
    "Greater Capitol Heights",
    "Greeley",
    "Greeley",
    "Greeley",
    "Greeley",
    "Greeley",
    "Greeleyville",
    "Green",
    "Green",
    "Green Bank",
    "Green Bay",
    "Green Castle",
    "Green City",
    "Green Cove Springs",
    "Green Forest",
    "Green Isle",
    "Green Lake",
    "Green Lane",
    "Green Mountain",
    "Green Mountain Falls",
    "Green Pond",
    "Green River",
    "Green River",
    "Green Sea",
    "Green Springs",
    "Green Valley",
    "Green Valley",
    "Green Village",
    "Greenacres",
    "Greenback",
    "Greenbackville",
    "Greenbank",
    "Greenbelt",
    "Greenbrae",
    "Greenbrier",
    "Greenbrier",
    "Greenbush",
    "Greenbush",
    "Greenbush",
    "Greenbush",
    "Greencastle",
    "Greencastle",
    "Greendale",
    "Greene",
    "Greene",
    "Greene",
    "Greene",
    "Greeneville",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenfield",
    "Greenland",
    "Greenland",
    "Greenlawn",
    "Greenleaf",
    "Greenleaf",
    "Greenock",
    "Greenport",
    "Greens Farms",
    "Greensboro",
    "Greensboro",
    "Greensboro",
    "Greensboro",
    "Greensboro",
    "Greensburg",
    "Greensburg",
    "Greensburg",
    "Greensburg",
    "Greensburg",
    "Greentop",
    "Greentown",
    "Greentown",
    "Greenup",
    "Greenup",
    "Greenvale",
    "Greenview",
    "Greenview",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenville",
    "Greenway Downs",
    "Greenwell Springs",
    "Greenwich",
    "Greenwich",
    "Greenwich",
    "Greenwich",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood",
    "Greenwood Lake",
    "Greenwood Springs",
    "Greenwood Village",
    "Greer",
    "Gregory",
    "Gregory",
    "Gregory",
    "Grelton",
    "Grenada",
    "Grenloch",
    "Grenville",
    "Gresham",
    "Gresham",
    "Gresham",
    "Grethel",
    "Gretna",
    "Gretna",
    "Gretna",
    "Gretna",
    "Grey Eagle",
    "Greybull",
    "Greycliff",
    "Gridley",
    "Gridley",
    "Griffin",
    "Griffith",
    "Griffithsville",
    "Griffithville",
    "Grifton",
    "Griggsville",
    "Grimes",
    "Grimesland",
    "Grimsley",
    "Grindstone",
    "Grinnell",
    "Grinnell",
    "Griswold",
    "Groesbeck",
    "Groom",
    "Grosse Ile",
    "Grosse Pointe",
    "Grosse Tete",
    "Grosvenor Dale",
    "Groton",
    "Groton",
    "Groton",
    "Groton",
    "Groton",
    "Grottoes",
    "Grouse Creek",
    "Grove",
    "Grove City",
    "Grove City",
    "Grove Hill",
    "Grove Oak",
    "Groveland",
    "Groveland",
    "Groveland",
    "Groveland",
    "Groveport",
    "Grover",
    "Grover",
    "Grover",
    "Grover Beach",
    "Grover Hill",
    "Grovertown",
    "Groves",
    "Groveton",
    "Groveton",
    "Grovetown",
    "Gruetli-Laager",
    "Grundy",
    "Grundy Center",
    "Gruver",
    "Grygla",
    "Guadalupe",
    "Gualala",
    "Guatay",
    "Guerneville",
    "Guernsey",
    "Gueydan",
    "Guffey",
    "Guide Rock",
    "Guilderland",
    "Guilford",
    "Guilford",
    "Guilford",
    "Guilford",
    "Guin",
    "Guinda",
    "Gulf Breeze",
    "Gulf Shores",
    "Gulfport",
    "Gully",
    "Gum Spring",
    "Gunlock",
    "Gunlock",
    "Gunnison",
    "Gunnison",
    "Gunnison",
    "Gunpowder",
    "Gunter",
    "Guntersville",
    "Guntown",
    "Gurley",
    "Gurley",
    "Gurnee",
    "Gustine",
    "Gustine",
    "Guston",
    "Guthrie",
    "Guthrie",
    "Guthrie Center",
    "Guttenberg",
    "Guy",
    "Guy",
    "Guymon",
    "Guys",
    "Guys Mills",
    "Guysville",
    "Guyton",
    "Gwinn",
    "Gwinner",
    "Gwynedd",
    "Gwynedd Valley",
    "Gwynn Oak",
    "Gypsum",
    "Gypsum",
    "Gypsy",
    "Hacienda Heights",
    "Hackberry",
    "Hackensack",
    "Hackensack",
    "Hackett",
    "Hackettstown",
    "Haddam",
    "Haddam",
    "Haddock",
    "Haddon Heights",
    "Haddonfield",
    "Hadley",
    "Hadley",
    "Hadley",
    "Hadlyme",
    "Hagan",
    "Hager City",
    "Hagerhill",
    "Hagerman",
    "Hagerman",
    "Hagerstown",
    "Hagerstown",
    "Hahira",
    "Hahnville",
    "Haigler",
    "Hailey",
    "Haines",
    "Haines",
    "Haines City",
    "Hainesport",
    "Hakalau",
    "Halbur",
    "Hale",
    "Hale",
    "Haledon",
    "Hales Corners",
    "Halethorpe",
    "Haleyville",
    "Half Moon Bay",
    "Halfway",
    "Halfway",
    "Halifax",
    "Halifax",
    "Halifax",
    "Halifax",
    "Hall",
    "Hallam",
    "Hallandale",
    "Hallett",
    "Hallettsville",
    "Halliday",
    "Hallie",
    "Hallieford",
    "Hallock",
    "Hallowell",
    "Halls",
    "Hallstead",
    "Hallsville",
    "Hallsville",
    "Halltown",
    "Hallwood",
    "Halma",
    "Halsey",
    "Halstad",
    "Halstead",
    "Haltom City",
    "Hambleton",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamburg",
    "Hamden",
    "Hamden",
    "Hamel",
    "Hamel",
    "Hamer",
    "Hamer",
    "Hamersville",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamler",
    "Hamlet",
    "Hamlet",
    "Hamlin",
    "Hamlin",
    "Hamlin",
    "Hamlin",
    "Hamlin",
    "Hammon",
    "Hammond",
    "Hammond",
    "Hammond",
    "Hammond",
    "Hammond",
    "Hammondsport",
    "Hammonton",
    "Hampden",
    "Hampden",
    "Hampden",
    "Hampden Sydney",
    "Hampshire",
    "Hampstead",
    "Hampstead",
    "Hampstead",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton",
    "Hampton Bays",
    "Hampton Falls",
    "Hamptonville",
    "Hamtramck",
    "Hana",
    "Hanalei",
    "Hanapepe",
    "Hanceville",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancock",
    "Hancocks Bridge",
    "Hanford",
    "Hankamer",
    "Hankinson",
    "Hanksville",
    "Hanley Falls",
    "Hanlontown",
    "Hanna",
    "Hanna",
    "Hanna City",
    "Hannacroix",
    "Hannah",
    "Hannibal",
    "Hannibal",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover",
    "Hanover Park",
    "Hanoverton",
    "Hanska",
    "Hanson",
    "Hanson",
    "Hansville",
    "Happy",
    "Happy",
    "Happy Camp",
    "Happy Jack",
    "Happy Valley",
    "Harbeson",
    "Harbor Beach",
    "Harbor City",
    "Harbor Springs",
    "Harborcreek",
    "Harborside",
    "Harborton",
    "Hardeeville",
    "Hardin",
    "Hardin",
    "Hardin",
    "Hardin",
    "Hardin",
    "Hardinsburg",
    "Hardinsburg",
    "Hardtner",
    "Hardwick",
    "Hardwick",
    "Hardwick",
    "Hardy",
    "Hardy",
    "Hardy",
    "Hardy",
    "Hardyville",
    "Hardyville",
    "Harford",
    "Harker Heights",
    "Harlan",
    "Harlan",
    "Harlan",
    "Harlem",
    "Harlem",
    "Harleton",
    "Harleysville",
    "Harlingen",
    "Harlowton",
    "Harmans",
    "Harmon",
    "Harmonsburg",
    "Harmony",
    "Harmony",
    "Harmony",
    "Harmony",
    "Harned",
    "Harold",
    "Harper",
    "Harper",
    "Harper",
    "Harper",
    "Harper Woods",
    "Harpers Ferry",
    "Harpers Ferry",
    "Harpersville",
    "Harpster",
    "Harpswell",
    "Harpursville",
    "Harrah",
    "Harrah",
    "Harrells",
    "Harriet",
    "Harrietta",
    "Harriman",
    "Harriman",
    "Harrington",
    "Harrington",
    "Harrington",
    "Harrington Park",
    "Harris",
    "Harrisburg",
    "Harrisburg",
    "Harrisburg",
    "Harrisburg",
    "Harrisburg",
    "Harrisburg",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison",
    "Harrison City",
    "Harrison Valley",
    "Harrisonburg",
    "Harrisonburg",
    "Harrisonville",
    "Harrisville",
    "Harrisville",
    "Harrisville",
    "Harrisville",
    "Harrisville",
    "Harrisville",
    "Harrisville",
    "Harrod",
    "Harrodsburg",
    "Harrogate",
    "Harrold",
    "Harshaw",
    "Hart",
    "Hart",
    "Hartfield",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford",
    "Hartford City",
    "Hartington",
    "Hartland",
    "Hartland",
    "Hartland",
    "Hartland",
    "Hartland",
    "Hartley",
    "Hartley",
    "Hartline",
    "Hartly",
    "Hartman",
    "Harts",
    "Hartsburg",
    "Hartsdale",
    "Hartsel",
    "Hartselle",
    "Hartsfield",
    "Hartshorne",
    "Hartstown",
    "Hartsville",
    "Hartsville",
    "Hartsville",
    "Hartville",
    "Hartville",
    "Hartville",
    "Hartwell",
    "Hartwick",
    "Hartwood",
    "Harvard",
    "Harvard",
    "Harvard",
    "Harvest",
    "Harvey",
    "Harvey",
    "Harvey",
    "Harvey",
    "Harveys Lake",
    "Harveyville",
    "Harviell",
    "Harwich",
    "Harwich Port",
    "Harwick",
    "Harwinton",
    "Harwood",
    "Harwood",
    "Harwood Heights",
    "Hasbrouck Heights",
    "Haskell",
    "Haskell",
    "Haskell",
    "Haskins",
    "Haslet",
    "Haslett",
    "Hastings",
    "Hastings",
    "Hastings",
    "Hastings",
    "Hastings",
    "Hastings",
    "Hastings",
    "Hastings-on-Hudson",
    "Hasty",
    "Hat Creek",
    "Hatboro",
    "Hatch",
    "Hatfield",
    "Hatfield",
    "Hatfield",
    "Hatfield",
    "Hathaway Pines",
    "Hathorne",
    "Hatley",
    "Hattiesburg",
    "Hattieville",
    "Haubstadt",
    "Haughton",
    "Hauppauge",
    "Havana",
    "Havana",
    "Havana",
    "Havana",
    "Havelock",
    "Havelock",
    "Haven",
    "Havensville",
    "Haverford",
    "Haverhill",
    "Haverstraw",
    "Havertown",
    "Haviland",
    "Haviland",
    "Havre",
    "Havre de Grace",
    "Haw River",
    "Hawaiian Gardens",
    "Hawaiian Ocean View",
    "Hawarden",
    "Hawesville",
    "Hawkeye",
    "Hawkins",
    "Hawkinsville",
    "Hawley",
    "Hawley",
    "Hawley",
    "Haworth",
    "Haworth",
    "Hawthorn",
    "Hawthorne",
    "Hawthorne",
    "Hawthorne",
    "Hawthorne",
    "Hawthorne",
    "Haxtun",
    "Hay",
    "Hay Springs",
    "Hayden",
    "Hayden",
    "Hayden",
    "Haydenville",
    "Hayes",
    "Hayesville",
    "Hayfield",
    "Hayfork",
    "Haymarket",
    "Haynesville",
    "Haynesville",
    "Hayneville",
    "Hays",
    "Hays",
    "Hays",
    "Haysi",
    "Haysville",
    "Hayti",
    "Hayti",
    "Hayward",
    "Hayward",
    "Hazard",
    "Hazel",
    "Hazel",
    "Hazel Crest",
    "Hazel Green",
    "Hazel Green",
    "Hazel Green",
    "Hazel Park",
    "Hazelhurst",
    "Hazelton",
    "Hazelton",
    "Hazelwood",
    "Hazen",
    "Hazen",
    "Hazlehurst",
    "Hazlehurst",
    "Hazlet",
    "Hazleton",
    "Hazleton",
    "Hazleton",
    "Head of Westport",
    "Headland",
    "Healdsburg",
    "Healdton",
    "Healy",
    "Hearne",
    "Heath",
    "Heath",
    "Heath Springs",
    "Heathsville",
    "Heavener",
    "Hebbronville",
    "Heber",
    "Heber",
    "Heber",
    "Heber Springs",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hebron",
    "Hector",
    "Hedgesville",
    "Hedrick",
    "Heflin",
    "Hegins",
    "Heidelberg",
    "Heidrick",
    "Heiskell",
    "Helen",
    "Helen",
    "Helena",
    "Helena",
    "Helena",
    "Helena",
    "Helena",
    "Helena",
    "Helendale",
    "Helenwood",
    "Helix",
    "Hellertown",
    "Helmetta",
    "Helotes",
    "Helper",
    "Helvetia",
    "Hematite",
    "Hemet",
    "Hemingford",
    "Hemingway",
    "Hemlock",
    "Hemlock",
    "Hemphill",
    "Hempstead",
    "Hempstead",
    "Henagar",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Henderson",
    "Hendersonville",
    "Hendersonville",
    "Hendricks",
    "Hendrix",
    "Henefer",
    "Hennepin",
    "Hennessey",
    "Henniker",
    "Henning",
    "Henning",
    "Henrico",
    "Henrietta",
    "Henrietta",
    "Henrietta",
    "Henry",
    "Henry",
    "Henry",
    "Henryetta",
    "Henryville",
    "Henryville",
    "Hensley",
    "Hensley",
    "Hephzibah",
    "Hepler",
    "Heppner",
    "Hepzibah",
    "Herald",
    "Herculaneum",
    "Hercules",
    "Hereford",
    "Hereford",
    "Hereford",
    "Herington",
    "Herkimer",
    "Herlong",
    "Herman",
    "Hermann",
    "Hermansville",
    "Hermanville",
    "Herminie",
    "Hermiston",
    "Hermitage",
    "Hermitage",
    "Hermitage",
    "Hermon",
    "Hermosa",
    "Hermosa Beach",
    "Hernando",
    "Hernando",
    "Herndon",
    "Herndon",
    "Hernshaw",
    "Herod",
    "Heron",
    "Heron Lake",
    "Herrick",
    "Herrick",
    "Herriman",
    "Herrin",
    "Hershey",
    "Hershey",
    "Hertford",
    "Hesperia",
    "Hesperia",
    "Hesperus",
    "Hessmer",
    "Hesston",
    "Hesston",
    "Hettick",
    "Hettinger",
    "Heuvelton",
    "Hewett",
    "Hewitt",
    "Hewitt",
    "Hewitt",
    "Hewitt",
    "Hewlett",
    "Heyburn",
    "Heyworth",
    "Hialeah",
    "Hiawassee",
    "Hiawatha",
    "Hiawatha",
    "Hibbing",
    "Hibbs",
    "Hickman",
    "Hickman",
    "Hickman",
    "Hickman",
    "Hickory",
    "Hickory",
    "Hickory",
    "Hickory",
    "Hickory Corners",
    "Hickory Flat",
    "Hickory Hills",
    "Hickory Valley",
    "Hicksville",
    "Hicksville",
    "Hico",
    "Hico",
    "Hidalgo",
    "Hidalgo",
    "Hidden Valley Lake",
    "Hiddenite",
    "Higden",
    "Higdon",
    "Higganum",
    "Higgins",
    "Higginsville",
    "High Bridge",
    "High Falls",
    "High Island",
    "High Point",
    "High Ridge",
    "High Springs",
    "High View",
    "Highland",
    "Highland",
    "Highland",
    "Highland",
    "Highland",
    "Highland",
    "Highland",
    "Highland",
    "Highland Falls",
    "Highland Home",
    "Highland Lakes",
    "Highland Mills",
    "Highland Park",
    "Highland Park",
    "Highland Park",
    "Highland Springs",
    "Highlands",
    "Highlands",
    "Highlands",
    "Highlandville",
    "Highmore",
    "Highspire",
    "Hightstown",
    "Highwood",
    "Highwood",
    "Highwood",
    "Higley",
    "Hiko",
    "Hilbert",
    "Hildale",
    "Hildebran",
    "Hildreth",
    "Hilham",
    "Hill",
    "Hill City",
    "Hill City",
    "Hill City",
    "Hillburn",
    "Hilliard",
    "Hilliard",
    "Hillister",
    "Hillman",
    "Hills",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsboro",
    "Hillsborough",
    "Hillsborough",
    "Hillsborough",
    "Hillsdale",
    "Hillsdale",
    "Hillsdale",
    "Hillsdale",
    "Hillsdale",
    "Hillside",
    "Hillside",
    "Hillsville",
    "Hilltop",
    "Hillview",
    "Hilmar",
    "Hilo",
    "Hilton",
    "Hilton Head Island",
    "Himrod",
    "Hinckley",
    "Hinckley",
    "Hinckley",
    "Hinckley",
    "Hindman",
    "Hindsboro",
    "Hindsville",
    "Hines",
    "Hines",
    "Hinesburg",
    "Hinesville",
    "Hingham",
    "Hingham",
    "Hinkley",
    "Hinsdale",
    "Hinsdale",
    "Hinsdale",
    "Hinton",
    "Hinton",
    "Hinton",
    "Hinton",
    "Hiram",
    "Hiram",
    "Hiram",
    "Hitchcock",
    "Hitterdal",
    "Hiwasse",
    "Hixson",
    "Hixton",
    "Ho-Ho-Kus",
    "Hoagland",
    "Hobart",
    "Hobart",
    "Hobart",
    "Hobbs",
    "Hobbsville",
    "Hobe Sound",
    "Hoboken",
    "Hoboken",
    "Hobson",
    "Hobson",
    "Hockessin",
    "Hockley",
    "Hodge",
    "Hodgen",
    "Hodgenville",
    "Hodges",
    "Hoffman",
    "Hoffman",
    "Hoffman Estates",
    "Hogansburg",
    "Hogansville",
    "Hohenwald",
    "Hoisington",
    "Hokah",
    "Holabird",
    "Holbrook",
    "Holbrook",
    "Holbrook",
    "Holbrook",
    "Holcomb",
    "Holcomb",
    "Holcomb",
    "Holcombe",
    "Holden",
    "Holden",
    "Holden",
    "Holden",
    "Holden",
    "Holden",
    "Holdenville",
    "Holderness",
    "Holdingford",
    "Holdrege",
    "Holgate",
    "Holiday",
    "Holland",
    "Holland",
    "Holland",
    "Holland",
    "Holland",
    "Holland",
    "Holland",
    "Holland Patent",
    "Hollandale",
    "Hollandale",
    "Holley",
    "Holliday",
    "Holliday",
    "Hollidaysburg",
    "Hollis",
    "Hollis",
    "Hollis",
    "Hollis Center",
    "Hollister",
    "Hollister",
    "Holliston",
    "Holloman Air Force Base",
    "Hollow Rock",
    "Holloway",
    "Hollsopple",
    "Holly",
    "Holly",
    "Holly Grove",
    "Holly Hill",
    "Holly Pond",
    "Holly Ridge",
    "Holly Springs",
    "Holly Springs",
    "Holly Springs",
    "Hollywood",
    "Hollywood",
    "Hollywood",
    "Hollywood",
    "Holmdel",
    "Holmen",
    "Holmes",
    "Holmes",
    "Holmesville",
    "Holstein",
    "Holt",
    "Holt",
    "Holt",
    "Holton",
    "Holton",
    "Holton",
    "Holts Summit",
    "Holtsville",
    "Holtville",
    "Holtwood",
    "Holualoa",
    "Holy Cross",
    "Holyoke",
    "Holyoke",
    "Holyrood",
    "Home",
    "Home",
    "Homedale",
    "Homeland",
    "Homer",
    "Homer",
    "Homer",
    "Homer",
    "Homer",
    "Homer",
    "Homer City",
    "Homer Glen",
    "Homerville",
    "Homerville",
    "Homestead",
    "Homestead",
    "Homestead",
    "Homestead",
    "Hometown",
    "Homewood",
    "Homewood",
    "Homeworth",
    "Hominy",
    "Homosassa",
    "Honaker",
    "Honaunau",
    "Hondo",
    "Hondo",
    "Honea Path",
    "Honeoye",
    "Honeoye Falls",
    "Honesdale",
    "Honey Brook",
    "Honey Creek",
    "Honey Grove",
    "Honeydew",
    "Honeyville",
    "Honolulu",
    "Honor",
    "Hood River",
    "Hoodsport",
    "Hooker",
    "Hooks",
    "Hooksett",
    "Hookstown",
    "Hooper",
    "Hooper",
    "Hooper Bay",
    "Hoopeston",
    "Hoople",
    "Hoosick",
    "Hoosick Falls",
    "Hooversville",
    "Hop Bottom",
    "Hopatcong",
    "Hope",
    "Hope",
    "Hope",
    "Hope",
    "Hope",
    "Hope",
    "Hope",
    "Hope Hull",
    "Hope Mills",
    "Hope Valley",
    "Hopedale",
    "Hopedale",
    "Hopedale",
    "Hopewell",
    "Hopewell",
    "Hopewell",
    "Hopewell Junction",
    "Hopkins",
    "Hopkins",
    "Hopkins",
    "Hopkins",
    "Hopkinsville",
    "Hopkinton",
    "Hopkinton",
    "Hopkinton",
    "Hopland",
    "Hopwood",
    "Hoquiam",
    "Horatio",
    "Horicon",
    "Horn Lake",
    "Hornbeak",
    "Hornbeck",
    "Hornell",
    "Horner",
    "Hornick",
    "Horntown",
    "Horse Branch",
    "Horse Cave",
    "Horse Shoe",
    "Horseheads",
    "Horseshoe Bay",
    "Horseshoe Bend",
    "Horseshoe Bend",
    "Horsham",
    "Hortense",
    "Horton",
    "Horton",
    "Horton",
    "Hortonville",
    "Hoschton",
    "Hosford",
    "Hoskins",
    "Hoskinston",
    "Hosmer",
    "Hospers",
    "Hosston",
    "Hostetter",
    "Hot Springs",
    "Hot Springs",
    "Hot Springs",
    "Hot Springs National Park",
    "Hot Springs Village",
    "Hotchkiss",
    "Houghton",
    "Houghton",
    "Houghton",
    "Houghton Lake",
    "Houlka",
    "Houlton",
    "Houlton",
    "Houma",
    "House Springs",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houston",
    "Houtzdale",
    "Hoven",
    "Hovland",
    "Howard",
    "Howard",
    "Howard",
    "Howard",
    "Howard",
    "Howard Beach",
    "Howard City",
    "Howard Lake",
    "Howardsville",
    "Howe",
    "Howe",
    "Howe",
    "Howell",
    "Howell",
    "Howes Cave",
    "Howie In The Hills",
    "Howland",
    "Hoxie",
    "Hoxie",
    "Hoyleton",
    "Hoyt",
    "Hoyt Lakes",
    "Huachuca City",
    "Hubbard",
    "Hubbard",
    "Hubbard",
    "Hubbard",
    "Hubbard",
    "Hubbard Lake",
    "Hubbardston",
    "Hubbardsville",
    "Hubbell",
    "Hubert",
    "Hubertus",
    "Huddleston",
    "Huddy",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson",
    "Hudson Falls",
    "Hudsonville",
    "Huffman",
    "Huger",
    "Hughes",
    "Hughes Springs",
    "Hugheston",
    "Hughesville",
    "Hughesville",
    "Hughesville",
    "Hughson",
    "Hugo",
    "Hugo",
    "Hugo",
    "Hugoton",
    "Hulbert",
    "Hulett",
    "Hull",
    "Hull",
    "Hull",
    "Hull",
    "Hull",
    "Humansville",
    "Humarock",
    "Humbird",
    "Humble",
    "Humboldt",
    "Humboldt",
    "Humboldt",
    "Humboldt",
    "Humboldt",
    "Hume",
    "Humeston",
    "Hummelstown",
    "Humphrey",
    "Humphrey",
    "Hundred",
    "Hungerford",
    "Hunker",
    "Hunlock Creek",
    "Hunt",
    "Hunt",
    "Hunt Valley",
    "Hunter",
    "Huntersville",
    "Huntertown",
    "Huntingburg",
    "Huntingdon",
    "Huntingdon",
    "Huntingdon Valley",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington",
    "Huntington Beach",
    "Huntington Mills",
    "Huntington Park",
    "Huntington Station",
    "Huntington Woods",
    "Huntingtown",
    "Huntland",
    "Huntley",
    "Huntsburg",
    "Huntsville",
    "Huntsville",
    "Huntsville",
    "Huntsville",
    "Huntsville",
    "Huntsville",
    "Huntsville",
    "Hurdland",
    "Hurdle Mills",
    "Hurley",
    "Hurley",
    "Hurleyville",
    "Hurlock",
    "Huron",
    "Huron",
    "Huron",
    "Huron",
    "Hurricane",
    "Hurricane",
    "Hurst",
    "Hurst",
    "Hurt",
    "Hurtsboro",
    "Huson",
    "Hustisford",
    "Hustler",
    "Hustontown",
    "Hustonville",
    "Husum",
    "Hutchins",
    "Hutchinson",
    "Hutchinson",
    "Huttig",
    "Hutto",
    "Huttonsville",
    "Huxley",
    "Hyampom",
    "Hyannis",
    "Hyannis",
    "Hyattsville",
    "Hyde",
    "Hyde Park",
    "Hyde Park",
    "Hyde Park",
    "Hyde Park",
    "Hyden",
    "Hydes",
    "Hydesville",
    "Hydeville",
    "Hydro",
    "Hygiene",
    "Hymera",
    "Hyndman",
    "Hyrum",
    "Hysham",
    "Iaeger",
    "Iberia",
    "Ickesburg",
    "Ida",
    "Ida",
    "Ida Grove",
    "Idabel",
    "Idaho Falls",
    "Idaho Springs",
    "Idalia",
    "Idalou",
    "Idaville",
    "Ider",
    "Idledale",
    "Idyllwild",
    "Ignacio",
    "Igo",
    "Ijamsville",
    "Iliff",
    "Ilion",
    "Imlay City",
    "Imler",
    "Immaculata",
    "Immokalee",
    "Imogene",
    "Imperial",
    "Imperial",
    "Imperial",
    "Imperial",
    "Imperial",
    "Imperial Beach",
    "Ina",
    "Inchelium",
    "Incline Village",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "Independence",
    "India",
    "Indialantic",
    "Indian Head",
    "Indian Head",
    "Indian Hills",
    "Indian Lake",
    "Indian Mound",
    "Indian Orchard",
    "Indian River",
    "Indian Rocks Beach",
    "Indian Trail",
    "Indian Wells",
    "Indiana",
    "Indianapolis",
    "Indianola",
    "Indianola",
    "Indianola",
    "Indianola",
    "Indianola",
    "Indianola",
    "Indiantown",
    "Indio",
    "Industry",
    "Industry",
    "Industry",
    "Inez",
    "Inez",
    "Ingalls",
    "Ingleside",
    "Ingleside",
    "Inglewood",
    "Inglis",
    "Ingomar",
    "Ingraham",
    "Ingram",
    "Inkom",
    "Inkster",
    "Inland",
    "Inman",
    "Inman",
    "Inman",
    "Inola",
    "Institute",
    "Institute",
    "Intercession City",
    "Intercourse",
    "Interlachen",
    "Interlaken",
    "Interlochen",
    "International Falls",
    "Inver Grove Heights",
    "Inverness",
    "Inverness",
    "Inverness",
    "Inverness",
    "Inwood",
    "Inwood",
    "Inwood",
    "Inyokern",
    "Iola",
    "Iola",
    "Iola",
    "Ione",
    "Ione",
    "Ionia",
    "Ionia",
    "Ionia",
    "Iota",
    "Iowa",
    "Iowa City",
    "Iowa Falls",
    "Iowa Park",
    "Ipswich",
    "Ipswich",
    "Iraan",
    "Irasburg",
    "Iredell",
    "Ireland",
    "Irene",
    "Ireton",
    "Irma",
    "Irmo",
    "Iron City",
    "Iron Junction",
    "Iron Mountain",
    "Iron Ridge",
    "Iron River",
    "Iron River",
    "Iron Station",
    "Irons",
    "Ironton",
    "Ironton",
    "Ironton",
    "Ironwood",
    "Iroquois",
    "Iroquois",
    "Irrigon",
    "Irvine",
    "Irvine",
    "Irving",
    "Irving",
    "Irving",
    "Irvington",
    "Irvington",
    "Irvington",
    "Irvington",
    "Irvington",
    "Irvington",
    "Irwin",
    "Irwin",
    "Irwinton",
    "Isabel",
    "Isabel",
    "Isabella",
    "Isanti",
    "Iselin",
    "Ishpeming",
    "Islamorada",
    "Island",
    "Island Falls",
    "Island Heights",
    "Island Lake",
    "Island Park",
    "Islandia",
    "Islandton",
    "Isle",
    "Isle of Palms",
    "Isle of Wight",
    "Islesboro",
    "Islesford",
    "Isleta",
    "Isleton",
    "Islip",
    "Islip Terrace",
    "Ismay",
    "Issaquah",
    "Italia",
    "Italy",
    "Itasca",
    "Ithaca",
    "Ithaca",
    "Ithaca",
    "Itmann",
    "Itta Bena",
    "Iuka",
    "Iuka",
    "Iva",
    "Ivanhoe",
    "Ivanhoe",
    "Ivanhoe",
    "Ivanhoe",
    "Ivel",
    "Ivins",
    "Ivor",
    "Ivoryton",
    "Ivydale",
    "Ixonia",
    "Jack",
    "Jackpot",
    "Jacks Creek",
    "Jacksboro",
    "Jacksboro",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson",
    "Jackson Center",
    "Jackson Heights",
    "Jackson Township",
    "Jacksonboro",
    "Jacksonburg",
    "Jacksons Gap",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville",
    "Jacksonville Beach",
    "Jacobs Creek",
    "Jacobsburg",
    "Jacobson",
    "Jacumba Hot Springs",
    "Jaffrey",
    "Jal",
    "Jamaica",
    "Jamaica",
    "Jamaica Plain",
    "Jameson",
    "Jamesport",
    "Jamesport",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamestown",
    "Jamesville",
    "Jamison",
    "Jamul",
    "Jane Lew",
    "Janesville",
    "Janesville",
    "Janesville",
    "Janesville",
    "Jaroso",
    "Jarreau",
    "Jarrell",
    "Jarrettsville",
    "Jarvisburg",
    "Jasonville",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jasper",
    "Jay",
    "Jay",
    "Jay",
    "Jay",
    "Jayess",
    "Jayton",
    "Jean",
    "Jeanerette",
    "Jeannette",
    "Jeddo",
    "Jeff",
    "Jeffers",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson",
    "Jefferson City",
    "Jefferson City",
    "Jefferson City",
    "Jefferson Valley",
    "Jeffersonton",
    "Jeffersonville",
    "Jeffersonville",
    "Jeffersonville",
    "Jeffersonville",
    "Jeffersonville",
    "Jeffrey",
    "Jekyll Island",
    "Jellico",
    "Jemez Pueblo",
    "Jemez Springs",
    "Jemison",
    "Jena",
    "Jenison",
    "Jenkins",
    "Jenkinsburg",
    "Jenkinsville",
    "Jenkintown",
    "Jenks",
    "Jenner",
    "Jennerstown",
    "Jennings",
    "Jennings",
    "Jensen Beach",
    "Jeremiah",
    "Jericho",
    "Jericho",
    "Jerico Springs",
    "Jermyn",
    "Jerome",
    "Jerome",
    "Jersey",
    "Jersey City",
    "Jersey Shore",
    "Jerseyville",
    "Jerusalem",
    "Jessup",
    "Jessup",
    "Jesup",
    "Jesup",
    "Jet",
    "Jetersville",
    "Jetmore",
    "Jewell",
    "Jewell",
    "Jewell",
    "Jewett",
    "Jewett",
    "Jewett",
    "Jewett City",
    "Jim Falls",
    "Jim Thorpe",
    "Joanna",
    "Joaquin",
    "Jobstown",
    "Joelton",
    "Joes",
    "Johannesburg",
    "John Day",
    "Johns Island",
    "Johnson",
    "Johnson",
    "Johnson",
    "Johnson",
    "Johnson City",
    "Johnson City",
    "Johnson City",
    "Johnson Creek",
    "Johnsonburg",
    "Johnsonville",
    "Johnsonville",
    "Johnston",
    "Johnston",
    "Johnston",
    "Johnston City",
    "Johnstown",
    "Johnstown",
    "Johnstown",
    "Johnstown",
    "Johnstown",
    "Joice",
    "Joiner",
    "Joliet",
    "Joliet",
    "Jolon",
    "Jones",
    "Jones",
    "Jonesboro",
    "Jonesboro",
    "Jonesboro",
    "Jonesboro",
    "Jonesboro",
    "Jonesboro",
    "Jonesborough",
    "Jonesburg",
    "Jonesport",
    "Jonestown",
    "Jonestown",
    "Jonesville",
    "Jonesville",
    "Jonesville",
    "Jonesville",
    "Jonesville",
    "Jonesville",
    "Joplin",
    "Joplin",
    "Joppa",
    "Joppa",
    "Joppa",
    "Jordan",
    "Jordan",
    "Jordan",
    "Jordan Valley",
    "Joseph",
    "Joseph",
    "Josephine",
    "Josephine",
    "Joshua",
    "Joshua Tree",
    "Jourdanton",
    "Joy",
    "Jud",
    "Juda",
    "Judith Gap",
    "Judsonia",
    "Julesburg",
    "Juliaetta",
    "Julian",
    "Julian",
    "Julian",
    "Julian",
    "Juliette",
    "Jumping Branch",
    "Junction",
    "Junction",
    "Junction City",
    "Junction City",
    "Junction City",
    "Junction City",
    "Junction City",
    "June Lake",
    "Juneau",
    "Juneau",
    "Juniata",
    "Junior",
    "Juno Beach",
    "Jupiter",
    "Justice",
    "Justice",
    "Justin",
    "Kadoka",
    "Kahoka",
    "Kahuku",
    "Kahului",
    "Kailua",
    "Kailua",
    "Kaiser",
    "Kake",
    "Kaktovik",
    "Kalaheo",
    "Kalama",
    "Kalamazoo",
    "Kaleva",
    "Kalida",
    "Kalispell",
    "Kalkaska",
    "Kalona",
    "Kalskag",
    "Kamas",
    "Kamiah",
    "Kampsville",
    "Kanab",
    "Kanawha",
    "Kandiyohi",
    "Kane",
    "Kaneville",
    "Kankakee",
    "Kannapolis",
    "Kanopolis",
    "Kansas",
    "Kansas",
    "Kansas City",
    "Kansas City",
    "Kansasville",
    "Kapaau",
    "Kaplan",
    "Kapolei",
    "Karlstad",
    "Karnack",
    "Karnak",
    "Karnes City",
    "Karns City",
    "Karthaus",
    "Karval",
    "Kasota",
    "Kasson",
    "Kathleen",
    "Katonah",
    "Katy",
    "Kaufman",
    "Kaukauna",
    "Kaumakani",
    "Kaunakakai",
    "Kauneonga Lake",
    "Kaw City",
    "Kaweah",
    "Kawkawlin",
    "Kaycee",
    "Kayenta",
    "Kaysville",
    "Kealakekua",
    "Keams Canyon",
    "Keansburg",
    "Kearney",
    "Kearney",
    "Kearneysville",
    "Kearny",
    "Kearny",
    "Keasbey",
    "Keatchie",
    "Keauhou",
    "Keavy",
    "Kechi",
    "Keedysville",
    "Keego Harbor",
    "Keene",
    "Keene",
    "Keene",
    "Keene",
    "Keene Valley",
    "Keenesburg",
    "Keeseville",
    "Keewatin",
    "Keezletown",
    "Kegley",
    "Keithville",
    "Keizer",
    "Kekaha",
    "Kelayres",
    "Kelford",
    "Keller",
    "Keller",
    "Kellerton",
    "Kelley",
    "Kelliher",
    "Kellogg",
    "Kellogg",
    "Kelly",
    "Kellyton",
    "Kellyville",
    "Kelseyville",
    "Kelso",
    "Kelso",
    "Kelso",
    "Kemah",
    "Kemmerer",
    "Kemp",
    "Kempner",
    "Kempton",
    "Kempton",
    "Kempton",
    "Kenai",
    "Kenansville",
    "Kenbridge",
    "Kendalia",
    "Kendall",
    "Kendall Park",
    "Kendallville",
    "Kendrick",
    "Kenduskeag",
    "Kenedy",
    "Kenesaw",
    "Kenilworth",
    "Kenilworth",
    "Kenly",
    "Kenmare",
    "Kenmore",
    "Kenna",
    "Kennan",
    "Kennard",
    "Kennard",
    "Kennebec",
    "Kennebunk",
    "Kennebunkport",
    "Kennedale",
    "Kennedy",
    "Kennedy",
    "Kennedy",
    "Kennedyville",
    "Kenner",
    "Kennerdell",
    "Kennesaw",
    "Kennett",
    "Kennett Square",
    "Kennewick",
    "Kenney",
    "Kenney",
    "Keno",
    "Kenosha",
    "Kenova",
    "Kensett",
    "Kensington",
    "Kensington",
    "Kensington",
    "Kensington",
    "Kent",
    "Kent",
    "Kent",
    "Kent",
    "Kent",
    "Kent",
    "Kent City",
    "Kentfield",
    "Kentland",
    "Kenton",
    "Kenton",
    "Kents Hill",
    "Kents Store",
    "Kentwood",
    "Kenvil",
    "Kenyon",
    "Keokuk",
    "Keosauqua",
    "Keota",
    "Keota",
    "Kerby",
    "Kerens",
    "Kerhonkson",
    "Kerkhoven",
    "Kerman",
    "Kermit",
    "Kermit",
    "Kernersville",
    "Kernville",
    "Kerrick",
    "Kerrville",
    "Kersey",
    "Kersey",
    "Kershaw",
    "Keshena",
    "Keswick",
    "Keswick",
    "Ketchikan",
    "Ketchum",
    "Ketchum",
    "Ketchum",
    "Kettle Falls",
    "Kettle Island",
    "Kettleman City",
    "Keuka Park",
    "Kevil",
    "Kevin",
    "Kew Gardens",
    "Kewadin",
    "Kewanee",
    "Kewanna",
    "Kewaskum",
    "Kewaunee",
    "Key Biscayne",
    "Key Largo",
    "Key West",
    "Keyes",
    "Keyes",
    "Keymar",
    "Keyport",
    "Keyport",
    "Keyser",
    "Keystone",
    "Keystone",
    "Keystone",
    "Keystone Heights",
    "Keysville",
    "Keytesville",
    "Kiamesha Lake",
    "Kiana",
    "Kiefer",
    "Kiel",
    "Kieler",
    "Kiester",
    "Kihei",
    "Kila",
    "Kilauea",
    "Kilbourne",
    "Kilbourne",
    "Kilgore",
    "Kilgore",
    "Kilkenny",
    "Kilkenny Township",
    "Kill Devil Hills",
    "Killbuck",
    "Killdeer",
    "Killeen",
    "Killen",
    "Killington",
    "Killingworth",
    "Kilmarnock",
    "Kilmichael",
    "Kiln",
    "Kimball",
    "Kimball",
    "Kimball",
    "Kimball",
    "Kimballton",
    "Kimberling City",
    "Kimberly",
    "Kimberly",
    "Kimberly",
    "Kimberly",
    "Kimberton",
    "Kimbolton",
    "Kimmell",
    "Kimper",
    "Kincaid",
    "Kincaid",
    "Kincaid",
    "Kinde",
    "Kinder",
    "Kinderhook",
    "Kinderhook",
    "Kindred",
    "King",
    "King",
    "King and Queen Court House",
    "King City",
    "King City",
    "King Cove",
    "King George",
    "King of Prussia",
    "King Salmon",
    "King William",
    "Kingdom City",
    "Kingfield",
    "Kingfisher",
    "Kingman",
    "Kingman",
    "Kings Bay Base",
    "Kings Beach",
    "Kings Mills",
    "Kings Mountain",
    "Kings Park",
    "Kingsburg",
    "Kingsbury",
    "Kingsbury",
    "Kingsford",
    "Kingsland",
    "Kingsland",
    "Kingsley",
    "Kingsley",
    "Kingsley",
    "Kingsport",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston Springs",
    "Kingstree",
    "Kingsville",
    "Kingsville",
    "Kingsville",
    "Kingsville",
    "Kingwood",
    "Kinmundy",
    "Kinnear",
    "Kinsley",
    "Kinsman",
    "Kinston",
    "Kinston",
    "Kinta",
    "Kintnersville",
    "Kintyre",
    "Kinzers",
    "Kiowa",
    "Kiowa",
    "Kiowa",
    "Kipling",
    "Kipnuk",
    "Kirby",
    "Kirbyville",
    "Kirbyville",
    "Kirk",
    "Kirkland",
    "Kirkland",
    "Kirkland",
    "Kirklin",
    "Kirksey",
    "Kirksville",
    "Kirkville",
    "Kirkville",
    "Kirkwood",
    "Kirkwood",
    "Kirkwood",
    "Kirkwood",
    "Kiron",
    "Kirtland",
    "Kirwin",
    "Kismet",
    "Kissee Mills",
    "Kissimmee",
    "Kite",
    "Kite",
    "Kittanning",
    "Kittery",
    "Kittery Point",
    "Kitts Hill",
    "Kitty Hawk",
    "Klamath",
    "Klamath Falls",
    "Klamath River",
    "Klawock",
    "Klemme",
    "Knapp",
    "Knife River",
    "Knightdale",
    "Knights Landing",
    "Knightsen",
    "Knightstown",
    "Knightsville",
    "Knippa",
    "Knob Lick",
    "Knob Noster",
    "Knott",
    "Knox",
    "Knox",
    "Knox City",
    "Knox Dale",
    "Knoxville",
    "Knoxville",
    "Knoxville",
    "Knoxville",
    "Knoxville",
    "Knoxville",
    "Kodak",
    "Kodiak",
    "Kohler",
    "Kokomo",
    "Kokomo",
    "Koloa",
    "Konawa",
    "Kongiganak",
    "Kooskia",
    "Koppel",
    "Kopperl",
    "Kosciusko",
    "Kotlik",
    "Kountze",
    "Kouts",
    "Koyuk",
    "Krakow",
    "Kramer",
    "Krebs",
    "Kremmling",
    "Krum",
    "Kula",
    "Kulm",
    "Kulpmont",
    "Kuna",
    "Kunkletown",
    "Kure Beach",
    "Kurtistown",
    "Kuttawa",
    "Kutztown",
    "Kwethluk",
    "Kykotsmovi Village",
    "Kyle",
    "Kyle",
    "Kyles Ford",
    "La Barge",
    "La Belle",
    "La Belle",
    "La Blanca",
    "La Canada Flintridge",
    "La Center",
    "La Center",
    "La Conner",
    "La Crescent",
    "La Crescenta",
    "La Crosse",
    "La Crosse",
    "La Crosse",
    "La Crosse",
    "La Crosse",
    "La Crosse",
    "La Cygne",
    "La Farge",
    "La Fayette",
    "La Feria",
    "La Fontaine",
    "La Fox",
    "La Grande",
    "La Grange",
    "La Grange",
    "La Grange",
    "La Grange",
    "La Grange",
    "La Grange",
    "La Grange Park",
    "La Habra",
    "La Harpe",
    "La Harpe",
    "La Honda",
    "La Jara",
    "La Jolla",
    "La Joya",
    "La Junta",
    "La Luz",
    "La Marque",
    "La Mesa",
    "La Mesa",
    "La Mirada",
    "La Moille",
    "La Motte",
    "La Palma",
    "La Pine",
    "La Plata",
    "La Plata",
    "La Pointe",
    "La Porte",
    "La Porte",
    "La Porte City",
    "La Prairie",
    "La Pryor",
    "La Puente",
    "La Quinta",
    "La Rue",
    "La Sal",
    "La Salle",
    "La Salle",
    "La Valle",
    "La Vergne",
    "La Verne",
    "La Vernia",
    "La Veta",
    "La Vista",
    "Labadie",
    "Labadieville",
    "LaBelle",
    "LaBolt",
    "Lac du Flambeau",
    "Lacey",
    "Laceys Spring",
    "Laceyville",
    "Lachine",
    "Lackawaxen",
    "Lackey",
    "Lacombe",
    "Lacon",
    "Lacona",
    "Lacona",
    "Laconia",
    "Laconia",
    "Ladd",
    "Laddonia",
    "Ladera Ranch",
    "Ladoga",
    "Ladonia",
    "Ladora",
    "Ladson",
    "Lady Lake",
    "Ladysmith",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "LaFayette",
    "Lafayette",
    "LaFayette",
    "Lafayette",
    "Lafayette",
    "Lafayette",
    "Lafferty",
    "Lafitte",
    "LaFollette",
    "Lagrange",
    "Lagrange",
    "Lagrangeville",
    "Lagro",
    "Laguna",
    "Laguna Beach",
    "Laguna Hills",
    "Laguna Niguel",
    "Laguna Woods",
    "Lagunitas",
    "Lahaina",
    "Lahaska",
    "Laingsburg",
    "Lake",
    "Lake",
    "Lake",
    "Lake Alfred",
    "Lake Andes",
    "Lake Ann",
    "Lake Ariel",
    "Lake Arrowhead",
    "Lake Arthur",
    "Lake Arthur",
    "Lake Benton",
    "Lake Bluff",
    "Lake Bronson",
    "Lake Buena Vista",
    "Lake Butler",
    "Lake Charles",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake City",
    "Lake Cormorant",
    "Lake Crystal",
    "Lake Dallas",
    "Lake Delton",
    "Lake Elmo",
    "Lake Elsinore",
    "Lake Forest",
    "Lake Forest",
    "Lake Geneva",
    "Lake George",
    "Lake Grove",
    "Lake Hamilton",
    "Lake Harmony",
    "Lake Havasu City",
    "Lake Helen",
    "Lake Hiawatha",
    "Lake Hopatcong",
    "Lake Hughes",
    "Lake in the Hills",
    "Lake Isabella",
    "Lake Jackson",
    "Lake Junaluska",
    "Lake Katrine",
    "Lake Lillian",
    "Lake Linden",
    "Lake Lure",
    "Lake Luzerne",
    "Lake Lynn",
    "Lake Mary",
    "Lake Mills",
    "Lake Mills",
    "Lake Monroe",
    "Lake Nebagamon",
    "Lake Norden",
    "Lake Odessa",
    "Lake Orion",
    "Lake Oswego",
    "Lake Ozark",
    "Lake Panasoffkee",
    "Lake Park",
    "Lake Park",
    "Lake Park",
    "Lake Peekskill",
    "Lake Placid",
    "Lake Placid",
    "Lake Pleasant",
    "Lake Preston",
    "Lake Providence",
    "Lake Saint Louis",
    "Lake Shasta",
    "Lake Station",
    "Lake Stevens",
    "Lake Tomahawk",
    "Lake Toxaway",
    "Lake View",
    "Lake View",
    "Lake Villa",
    "Lake Village",
    "Lake Village",
    "Lake Waccamaw",
    "Lake Wales",
    "Lake Wilson",
    "Lake Winola",
    "Lake Worth",
    "Lake Zurich",
    "Lakebay",
    "Lakefield",
    "Lakehead",
    "Lakehurst",
    "Lakeland",
    "Lakeland",
    "Lakeland",
    "Lakemont",
    "Lakemore",
    "Lakeport",
    "Lakeside",
    "Lakeside",
    "Lakeside",
    "Lakeside",
    "Lakeside",
    "Lakeside",
    "Lakeside",
    "Laketown",
    "Lakeview",
    "Lakeview",
    "Lakeview",
    "Lakeview",
    "Lakeview",
    "Lakeville",
    "Lakeville",
    "Lakeville",
    "Lakeville",
    "Lakeville",
    "Lakeville",
    "Lakeville",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakewood",
    "Lakin",
    "Lakota",
    "Lakota",
    "Lamar",
    "Lamar",
    "Lamar",
    "Lamar",
    "Lamar",
    "Lamar",
    "Lambert",
    "Lamberton",
    "Lambertville",
    "Lambertville",
    "Lambsburg",
    "Lame Deer",
    "Lamesa",
    "Lamoni",
    "Lamont",
    "Lamont",
    "Lamont",
    "LaMoure",
    "Lampasas",
    "Lamy",
    "Lanai",
    "Lanark",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancaster",
    "Lancing",
    "Landenberg",
    "Lander",
    "Landing",
    "Landis",
    "Landisburg",
    "Landisville",
    "Landisville",
    "Landrum",
    "Lanesboro",
    "Lanesville",
    "Lanett",
    "Laneville",
    "Lanexa",
    "Langdon",
    "Langeloth",
    "Langford",
    "Langhorne",
    "Langley",
    "Langley",
    "Langley",
    "Langston",
    "Langsville",
    "Lanham",
    "Lanoka Harbor",
    "Lansdale",
    "Lansdowne",
    "Lanse",
    "Lansford",
    "Lansford",
    "Lansing",
    "Lansing",
    "Lansing",
    "Lansing",
    "Lansing",
    "Lansing",
    "Lansing",
    "Lantry",
    "Laona",
    "Laotto",
    "Lapeer",
    "Lapel",
    "Lapine",
    "Laplace",
    "Laporte",
    "Laporte",
    "Lapwai",
    "Laquey",
    "Laramie",
    "Larchmont",
    "Larchwood",
    "Laredo",
    "Largo",
    "Larimore",
    "Larkspur",
    "Larkspur",
    "Larned",
    "Larose",
    "Las Animas",
    "Las Cruces",
    "Las Vegas",
    "Las Vegas",
    "LaSalle",
    "Lascassas",
    "Latexo",
    "Latham",
    "Latham",
    "Latham",
    "Lathrop",
    "Lathrop",
    "Latimer",
    "Laton",
    "Latonia",
    "Latrobe",
    "Latta",
    "Lattimer",
    "Lattimore",
    "Lauderdale",
    "Laughlin",
    "Laughlintown",
    "Laura",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel",
    "Laurel Hill",
    "Laurel Hill",
    "Laurel Springs",
    "Laurelton",
    "Laurelville",
    "Laurens",
    "Laurens",
    "Laurens",
    "Laurinburg",
    "Laurys Station",
    "Lavaca",
    "Lavalette",
    "Lavallette",
    "Laveen",
    "LaVerkin",
    "Laverne",
    "Lavon",
    "Lavonia",
    "Lawler",
    "Lawn",
    "Lawndale",
    "Lawndale",
    "Lawnside",
    "Lawrence",
    "Lawrence",
    "Lawrence",
    "Lawrence",
    "Lawrence",
    "Lawrenceburg",
    "Lawrenceburg",
    "Lawrenceburg",
    "Lawrenceville",
    "Lawrenceville",
    "Lawrenceville",
    "Lawrenceville",
    "Lawson",
    "Lawton",
    "Lawton",
    "Lawton",
    "Lawton",
    "Layton",
    "Laytonville",
    "Le Center",
    "Le Grand",
    "Le Mars",
    "Le Raysville",
    "Le Roy",
    "Le Roy",
    "Le Roy",
    "Le Roy",
    "Le Sueur",
    "Lead",
    "Lead Hill",
    "Leadville",
    "Leadwood",
    "Leaf River",
    "League City",
    "Leakesville",
    "Leakey",
    "Leander",
    "Leary",
    "Leavenworth",
    "Leavenworth",
    "Leavenworth",
    "Leavittsburg",
    "Leawood",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon",
    "Lebanon Junction",
    "Lebec",
    "Lebo",
    "Leburn",
    "Lecanto",
    "LeClaire",
    "Lecompte",
    "Lecompton",
    "Ledbetter",
    "Ledbetter",
    "Lederach",
    "Ledgewood",
    "Ledyard",
    "Ledyard Center",
    "Lee",
    "Lee",
    "Lee",
    "Lee",
    "Lee Center",
    "Leechburg",
    "Leedey",
    "Leeds",
    "Leeds",
    "Leeds",
    "Leeds",
    "Leeds",
    "Leeds",
    "Leeper",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesburg",
    "Leesport",
    "Leesville",
    "Leesville",
    "Leeton",
    "Leetonia",
    "Leetsdale",
    "Lefor",
    "Lefors",
    "Lehi",
    "Lehigh",
    "Lehigh Acres",
    "Lehighton",
    "Lehman",
    "Lehr",
    "Leicester",
    "Leicester",
    "Leicester",
    "Leigh",
    "Leighton",
    "Leighton",
    "Leipsic",
    "Leitchfield",
    "Leland",
    "Leland",
    "Leland",
    "Leland",
    "Leland",
    "Lemasters",
    "Lemmon",
    "Lemon Grove",
    "Lemont",
    "Lemont",
    "Lemont Furnace",
    "Lemoore",
    "Lemoyne",
    "Lemoyne",
    "Lempster",
    "Lena",
    "Lena",
    "Lena",
    "Lena",
    "Lenapah",
    "Lenexa",
    "Lengby",
    "Lenhartsville",
    "Lenni",
    "Lennon",
    "Lennox",
    "Lenoir",
    "Lenoir City",
    "Lenora",
    "Lenore",
    "Lenore",
    "Lenox",
    "Lenox",
    "Lenox",
    "Leo",
    "Leola",
    "Leola",
    "Leoma",
    "Leominster",
    "Leon",
    "Leon",
    "Leon",
    "Leon",
    "Leona",
    "Leonard",
    "Leonard",
    "Leonard",
    "Leonard",
    "Leonardo",
    "Leonardsville",
    "Leonardtown",
    "Leonardville",
    "Leonia",
    "Leonidas",
    "Leonore",
    "Leonville",
    "Leopold",
    "Leopold",
    "Leopolis",
    "Leoti",
    "Lepanto",
    "Lerna",
    "LeRoy",
    "LeRoy",
    "Leroy",
    "Lesage",
    "Leslie",
    "Leslie",
    "Leslie",
    "Leslie",
    "Lester",
    "Lester",
    "Lester Prairie",
    "Lesterville",
    "Letart",
    "Letohatchee",
    "Letts",
    "Levan",
    "Levant",
    "Levelland",
    "Levelock",
    "Levels",
    "Leverett",
    "Levering",
    "Levittown",
    "Levittown",
    "Lewellen",
    "Lewes",
    "Lewis",
    "Lewis",
    "Lewis",
    "Lewis Center",
    "Lewis Run",
    "Lewisberry",
    "Lewisburg",
    "Lewisburg",
    "Lewisburg",
    "Lewisburg",
    "Lewisburg",
    "Lewisport",
    "Lewiston",
    "Lewiston",
    "Lewiston",
    "Lewiston",
    "Lewiston",
    "Lewiston",
    "Lewiston",
    "Lewiston Woodville",
    "Lewistown",
    "Lewistown",
    "Lewistown",
    "Lewistown",
    "Lewisville",
    "Lewisville",
    "Lewisville",
    "Lexa",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington",
    "Lexington Park",
    "Libby",
    "Liberal",
    "Liberal",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty",
    "Liberty Center",
    "Liberty Corner",
    "Liberty Hill",
    "Liberty Lake",
    "Libertytown",
    "Libertyville",
    "Lick Creek",
    "Licking",
    "Lidgerwood",
    "Liebenthal",
    "Lignum",
    "Ligonier",
    "Ligonier",
    "Lihue",
    "Likely",
    "Lilburn",
    "Lilesville",
    "Lillian",
    "Lillington",
    "Lilliwaup",
    "Lilly",
    "Lily Dale",
    "Lima",
    "Lima",
    "Lime Ridge",
    "Lime Springs",
    "Limerick",
    "Limestone",
    "Limestone",
    "Limestone",
    "Limington",
    "Limon",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln",
    "Lincoln City",
    "Lincoln Park",
    "Lincoln Park",
    "Lincolndale",
    "Lincolnshire",
    "Lincolnton",
    "Lincolnton",
    "Lincolnville",
    "Lincolnville",
    "Lincolnville Center",
    "Lincolnwood",
    "Lincroft",
    "Lindale",
    "Lindale",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Linden",
    "Lindenhurst",
    "Lindley",
    "Lindon",
    "Lindsay",
    "Lindsay",
    "Lindsay",
    "Lindsay",
    "Lindsborg",
    "Lindside",
    "Lindstrom",
    "Linesville",
    "Lineville",
    "Lineville",
    "Linkwood",
    "Linn",
    "Linn",
    "Linn Creek",
    "Linneus",
    "Linthicum Heights",
    "Linton",
    "Linton",
    "Linville",
    "Linville",
    "Linwood",
    "Linwood",
    "Linwood",
    "Linwood",
    "Linwood",
    "Linwood",
    "Lipan",
    "Lisbon",
    "Lisbon",
    "Lisbon",
    "Lisbon",
    "Lisbon",
    "Lisbon",
    "Lisbon",
    "Lisbon Falls",
    "Liscomb",
    "Lisle",
    "Lisle",
    "Lismore",
    "Listie",
    "Litchfield",
    "Litchfield",
    "Litchfield",
    "Litchfield",
    "Litchfield",
    "Litchfield",
    "Litchfield",
    "Litchfield Park",
    "Litchfield Plains",
    "Lithia",
    "Lithia Springs",
    "Lithonia",
    "Lithopolis",
    "Lititz",
    "Little America",
    "Little Chute",
    "Little Compton",
    "Little Elm",
    "Little Falls",
    "Little Falls",
    "Little Falls",
    "Little Ferry",
    "Little Hocking",
    "Little Meadows",
    "Little Mountain",
    "Little Neck",
    "Little River",
    "Little River",
    "Little River-Academy",
    "Little Rock",
    "Little Rock",
    "Little Rock",
    "Little Rock",
    "Little Rock Air Force Base",
    "Little Silver",
    "Little Suamico",
    "Little Valley",
    "Littlefield",
    "Littlefield",
    "Littlefork",
    "Littlerock",
    "Littlestown",
    "Littleton",
    "Littleton",
    "Littleton",
    "Littleton",
    "Littleton",
    "Live Oak",
    "Live Oak",
    "Livermore",
    "Livermore",
    "Livermore",
    "Livermore",
    "Livermore Falls",
    "Liverpool",
    "Liverpool",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston",
    "Livingston Manor",
    "Livonia",
    "Livonia",
    "Livonia",
    "Lizella",
    "Lizton",
    "Llano",
    "Llano",
    "Loa",
    "Loachapoka",
    "Loami",
    "Lobelville",
    "Local",
    "Loch Sheldrake",
    "Lock Haven",
    "Lockbourne",
    "Locke",
    "Lockeford",
    "Lockesburg",
    "Lockhart",
    "Lockney",
    "Lockport",
    "Lockport",
    "Lockport",
    "Lockwood",
    "Lockwood",
    "Loco",
    "Locust",
    "Locust Fork",
    "Locust Gap",
    "Locust Grove",
    "Locust Grove",
    "Locust Valley",
    "Loda",
    "Lodge",
    "Lodge Grass",
    "Lodi",
    "Lodi",
    "Lodi",
    "Lodi",
    "Lodi",
    "Log Lane Village",
    "Logan",
    "Logan",
    "Logan",
    "Logan",
    "Logan",
    "Logan",
    "Logan",
    "Logandale",
    "Logansport",
    "Logansport",
    "Loganton",
    "Loganville",
    "Loganville",
    "Lohman",
    "Lohn",
    "Lolita",
    "Lolo",
    "Loma",
    "Loma Linda",
    "Lomax",
    "Lombard",
    "Lomira",
    "Lomita",
    "Lompoc",
    "Lonaconing",
    "London",
    "London",
    "London",
    "London Mills",
    "Londonderry",
    "Londonderry",
    "Londonderry",
    "Lone Grove",
    "Lone Jack",
    "Lone Oak",
    "Lone Pine",
    "Lone Rock",
    "Lone Rock",
    "Lone Tree",
    "Lone Wolf",
    "Lonedell",
    "Long Beach",
    "Long Beach",
    "Long Beach",
    "Long Beach",
    "Long Branch",
    "Long Branch",
    "Long Grove",
    "Long Island",
    "Long Island City",
    "Long Lake",
    "Long Lake",
    "Long Lane",
    "Long Pine",
    "Long Pond",
    "Long Prairie",
    "Long Valley",
    "Longboat Key",
    "Longbranch",
    "Longdale",
    "Longmeadow",
    "Longmont",
    "Longport",
    "Longs",
    "Longton",
    "Longview",
    "Longview",
    "Longville",
    "Longwood",
    "Lonoke",
    "Lonsdale",
    "Loogootee",
    "Lookout",
    "Lookout Mountain",
    "Lookout Mountain",
    "Loomis",
    "Loomis",
    "Loon Lake",
    "Loop",
    "Loose Creek",
    "Lopez",
    "Lorain",
    "Loraine",
    "Loranger",
    "Lordsburg",
    "Lore City",
    "Lorena",
    "Lorenzo",
    "Loretto",
    "Loretto",
    "Loretto",
    "Loretto",
    "Lorimor",
    "Loris",
    "Lorman",
    "Lorraine",
    "Lorraine",
    "Lorton",
    "Los Alamitos",
    "Los Alamos",
    "Los Alamos",
    "Los Altos",
    "Los Altos Hills",
    "Los Angeles",
    "Los Banos",
    "Los Fresnos",
    "Los Gatos",
    "Los Lunas",
    "Los Molinos",
    "Los Olivos",
    "Los Osos",
    "Losantville",
    "Lost City",
    "Lost Creek",
    "Lost Creek",
    "Lost Nation",
    "Lost Springs",
    "Lostant",
    "Lostine",
    "Lothian",
    "Lott",
    "Lottie",
    "Lottsburg",
    "Lotus",
    "Loudon",
    "Loudon",
    "Loudonville",
    "Louin",
    "Louisa",
    "Louisa",
    "Louisburg",
    "Louisburg",
    "Louise",
    "Louisiana",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Louisville",
    "Loup City",
    "Lovejoy",
    "Lovelady",
    "Loveland",
    "Loveland",
    "Lovell",
    "Lovell",
    "Lovelock",
    "Lovely",
    "Loves Park",
    "Lovettsville",
    "Loveville",
    "Lovilia",
    "Loving",
    "Lovingston",
    "Lovington",
    "Lovington",
    "Low and Burbanks Grant",
    "Low Moor",
    "Lowber",
    "Lowden",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowell",
    "Lowellville",
    "Lower Brule",
    "Lower Lake",
    "Lower Salem",
    "Lowgap",
    "Lowman",
    "Lowmansville",
    "Lowndes",
    "Lowndesboro",
    "Lowry",
    "Lowry City",
    "Lowville",
    "Loxahatchee Groves",
    "Loxley",
    "Loyal",
    "Loyalhanna",
    "Loyall",
    "Loysville",
    "Lu Verne",
    "Lubbock",
    "Lubec",
    "Lucama",
    "Lucan",
    "Lucas",
    "Lucas",
    "Lucas",
    "Lucasville",
    "Lucedale",
    "Lucerne",
    "Lucerne",
    "Lucerne Valley",
    "Lucinda",
    "Luck",
    "Luckey",
    "Ludington",
    "Ludlow",
    "Ludlow",
    "Ludlow",
    "Ludlow",
    "Ludlow",
    "Ludlow",
    "Ludlow",
    "Ludlow Falls",
    "Ludowici",
    "Lueders",
    "Lufkin",
    "Lugoff",
    "Lula",
    "Luling",
    "Luling",
    "Lumberport",
    "Lumberton",
    "Lumberton",
    "Lumberton",
    "Lumberton",
    "Lumberville",
    "Lummi Island",
    "Lumpkin",
    "Luna Pier",
    "Lund",
    "Lunenburg",
    "Lupton City",
    "Luray",
    "Luray",
    "Luray",
    "Lusby",
    "Lusk",
    "Lutcher",
    "Luther",
    "Luthersburg",
    "Luthersville",
    "Lutherville-Timonium",
    "Lutsen",
    "Luttrell",
    "Lutz",
    "Luverne",
    "Luverne",
    "Luxemburg",
    "Luxor",
    "Luxora",
    "Luzerne",
    "Lydia",
    "Lyerly",
    "Lykens",
    "Lyle",
    "Lyle",
    "Lyles",
    "Lyman",
    "Lyman",
    "Lyman",
    "Lyman",
    "Lyme",
    "Lynbrook",
    "Lynch",
    "Lynch",
    "Lynch Station",
    "Lynchburg",
    "Lynchburg",
    "Lynchburg",
    "Lynchburg",
    "Lynco",
    "Lynd",
    "Lyndeborough",
    "Lynden",
    "Lyndhurst",
    "Lyndhurst",
    "Lyndon",
    "Lyndon",
    "Lyndon Center",
    "Lyndon Station",
    "Lyndonville",
    "Lyndonville",
    "Lynn",
    "Lynn",
    "Lynn",
    "Lynn",
    "Lynn Center",
    "Lynn Haven",
    "Lynnfield",
    "Lynnville",
    "Lynnville",
    "Lynnville",
    "Lynnwood",
    "Lynwood",
    "Lyon",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lyons",
    "Lytle",
    "Lytle Creek",
    "Mabank",
    "Mabel",
    "Mabelvale",
    "Maben",
    "Mableton",
    "Mabton",
    "Macclenny",
    "Macclesfield",
    "Macdoel",
    "Macedon",
    "Macedonia",
    "Macfarlan",
    "Machesney Park",
    "Machias",
    "Machias",
    "Machiasport",
    "Mackay",
    "Mackinac Island",
    "Mackinaw",
    "Mackinaw City",
    "Macksburg",
    "Macomb",
    "Macomb",
    "Macomb",
    "Macomb",
    "Macon",
    "Macon",
    "Macon",
    "Macon",
    "Macungie",
    "Mad River",
    "Madawaska",
    "Madbury",
    "Maddock",
    "Madelia",
    "Madera",
    "Madera",
    "Madill",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison",
    "Madison Heights",
    "Madison Heights",
    "Madison Lake",
    "Madisonville",
    "Madisonville",
    "Madisonville",
    "Madisonville",
    "Madras",
    "Madrid",
    "Madrid",
    "Magalia",
    "Magazine",
    "Magdalena",
    "Magee",
    "Maggie Valley",
    "Magna",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia",
    "Magnolia Springs",
    "Mahaffey",
    "Mahanoy City",
    "Mahaska",
    "Mahnomen",
    "Mahomet",
    "Mahopac",
    "Mahwah",
    "Maiden",
    "Maiden Rock",
    "Maidens",
    "Maidsville",
    "Mainesburg",
    "Maineville",
    "Maitland",
    "Maize",
    "Makanda",
    "Makawao",
    "Makinen",
    "Malabar",
    "Malad City",
    "Malaga",
    "Malaga",
    "Malakoff",
    "Malcom",
    "Malden",
    "Malden",
    "Malden",
    "Malibu",
    "Malin",
    "Malinta",
    "Mallory",
    "Malmo",
    "Malone",
    "Malone",
    "Malone",
    "Malott",
    "Malta",
    "Malta",
    "Malta",
    "Malta",
    "Malvern",
    "Malvern",
    "Malvern",
    "Malvern",
    "Malverne",
    "Mamaroneck",
    "Mammoth",
    "Mammoth Lakes",
    "Mamou",
    "Man",
    "Manahawkin",
    "Manasquan",
    "Manassas",
    "Manassas Park",
    "Manawa",
    "Mancelona",
    "Manchaca",
    "Manchaug",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester",
    "Manchester Center",
    "Manchester Township",
    "Manchester-by-the-Sea",
    "Mancos",
    "Mandan",
    "Mandaree",
    "Manderson",
    "Manderson",
    "Mandeville",
    "Mangum",
    "Manhasset",
    "Manhattan",
    "Manhattan",
    "Manhattan",
    "Manhattan Beach",
    "Manheim",
    "Manila",
    "Manila",
    "Manistee",
    "Manistique",
    "Manito",
    "Manitou",
    "Manitou Springs",
    "Manitowoc",
    "Mankato",
    "Mankato",
    "Manlius",
    "Manlius",
    "Manly",
    "Mannford",
    "Manning",
    "Manning",
    "Manning",
    "Mannington",
    "Manns Choice",
    "Mannsville",
    "Manokotak",
    "Manomet",
    "Manor",
    "Manor",
    "Manorville",
    "Manorville",
    "Manquin",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield",
    "Mansfield Center",
    "Mansfield Depot",
    "Manson",
    "Manson",
    "Mansura",
    "Mantachie",
    "Manteca",
    "Mantee",
    "Manteno",
    "Manteo",
    "Manti",
    "Mantoloking",
    "Manton",
    "Mantorville",
    "Mantua",
    "Mantua",
    "Mantua",
    "Manvel",
    "Manvel",
    "Manville",
    "Manville",
    "Many",
    "Many Farms",
    "Manzanita",
    "Manzanola",
    "Maple City",
    "Maple Falls",
    "Maple Grove",
    "Maple Heights",
    "Maple Hill",
    "Maple Lake",
    "Maple Park",
    "Maple Plain",
    "Maple Rapids",
    "Maple Shade",
    "Maple Valley",
    "Maplesville",
    "Mapleton",
    "Mapleton",
    "Mapleton",
    "Mapleton",
    "Mapleton",
    "Mapleville",
    "Maplewood",
    "Mappsville",
    "Maquoketa",
    "Marana",
    "Marathon",
    "Marathon",
    "Marathon",
    "Marathon",
    "Marble",
    "Marble Falls",
    "Marble Hill",
    "Marble Rock",
    "Marblehead",
    "Marblehill",
    "Marblemount",
    "Marbury",
    "Marbury",
    "Marceline",
    "Marcell",
    "Marcellus",
    "Marcellus",
    "March Air Force Base",
    "Marco Island",
    "Marcus",
    "Marcus Hook",
    "Marcy",
    "Mardela Springs",
    "Marengo",
    "Marengo",
    "Marengo",
    "Marengo",
    "Marengo",
    "Marenisco",
    "Marfa",
    "Margaret",
    "Margaretville",
    "Margate City",
    "Maria Stein",
    "Marianna",
    "Marianna",
    "Marianna",
    "Maribel",
    "Maricopa",
    "Marienthal",
    "Marienville",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marietta",
    "Marina",
    "Marina del Rey",
    "Marine",
    "Marine City",
    "Marine on Saint Croix",
    "Marinette",
    "Maringouin",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion",
    "Marion Center",
    "Marion Heights",
    "Marion Junction",
    "Marionville",
    "Marionville",
    "Mariposa",
    "Marissa",
    "Mark Center",
    "Marked Tree",
    "Markesan",
    "Markham",
    "Markham",
    "Markham",
    "Markle",
    "Markleeville",
    "Markleville",
    "Markleysburg",
    "Marks",
    "Marksville",
    "Marlboro",
    "Marlboro",
    "Marlboro",
    "Marlborough",
    "Marlborough",
    "Marlborough",
    "Marlette",
    "Marlin",
    "Marlinton",
    "Marlow",
    "Marlton",
    "Marmora",
    "Marne",
    "Maroa",
    "Marquand",
    "Marquette",
    "Marquette",
    "Marquette",
    "Marquez",
    "Marrero",
    "Marriottsville",
    "Mars",
    "Mars Hill",
    "Mars Hill",
    "Marseilles",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshall",
    "Marshalls Creek",
    "Marshalltown",
    "Marshallville",
    "Marshallville",
    "Marshfield",
    "Marshfield",
    "Marshfield",
    "Marshfield",
    "Marshfield Hills",
    "Marshville",
    "Marsing",
    "Marsteller",
    "Marstons Mills",
    "Mart",
    "Martell",
    "Martelle",
    "Martha",
    "Marthasville",
    "Marthaville",
    "Martin",
    "Martin",
    "Martin",
    "Martin",
    "Martin",
    "Martin",
    "Martindale",
    "Martindale",
    "Martinez",
    "Martins Creek",
    "Martins Ferry",
    "Martinsburg",
    "Martinsburg",
    "Martinsdale",
    "Martinsville",
    "Martinsville",
    "Martinsville",
    "Martinsville",
    "Martinsville",
    "Martinsville",
    "Martville",
    "Mary Esther",
    "Marydel",
    "Marydel",
    "Maryknoll",
    "Maryland",
    "Maryland Heights",
    "Marylhurst",
    "Maryneal",
    "Marysvale",
    "Marysville",
    "Marysville",
    "Marysville",
    "Marysville",
    "Marysville",
    "Marysville",
    "Marysville",
    "Maryville",
    "Maryville",
    "Maryville",
    "Mascot",
    "Mascotte",
    "Mascoutah",
    "Mashantucket",
    "Mashpee",
    "Maskell",
    "Mason",
    "Mason",
    "Mason",
    "Mason",
    "Mason",
    "Mason",
    "Mason",
    "Mason City",
    "Mason City",
    "Masontown",
    "Masontown",
    "Masonville",
    "Masonville",
    "Maspeth",
    "Mass City",
    "Massapequa",
    "Massapequa Park",
    "Massena",
    "Massena",
    "Massey",
    "Massillon",
    "Mastic",
    "Mastic Beach",
    "Masury",
    "Matamoras",
    "Matawan",
    "Matewan",
    "Matfield Green",
    "Mather Field",
    "Mathews",
    "Mathias",
    "Mathis",
    "Mathiston",
    "Matinicus",
    "Matoaka",
    "Mattapan",
    "Mattapoisett",
    "Mattaponi",
    "Mattawa",
    "Mattawamkeag",
    "Mattawan",
    "Mattawana",
    "Matteson",
    "Matthews",
    "Matthews",
    "Matthews",
    "Mattituck",
    "Mattoon",
    "Mauckport",
    "Maud",
    "Maud",
    "Mauk",
    "Mauldin",
    "Maumee",
    "Maumelle",
    "Maunaloa",
    "Maupin",
    "Maurepas",
    "Maurertown",
    "Maurice",
    "Maurice",
    "Mauriceville",
    "Maury",
    "Maury City",
    "Mauston",
    "Max",
    "Max Meadows",
    "Maxatawny",
    "Maxbass",
    "Maxton",
    "Maxwell",
    "Maxwell",
    "Maxwell",
    "Maxwell",
    "May",
    "May Creek",
    "Maybee",
    "Maybrook",
    "Mayer",
    "Mayer",
    "Mayesville",
    "Mayetta",
    "Mayfield",
    "Mayfield",
    "Mayfield",
    "Mayfield",
    "Mayflower",
    "Maylene",
    "Maynard",
    "Maynard",
    "Maynard",
    "Maynardville",
    "Mayo",
    "Mayo",
    "Mayo",
    "Mayodan",
    "Maypearl",
    "Mayport",
    "Mays Landing",
    "Maysel",
    "Maysville",
    "Maysville",
    "Maysville",
    "Maysville",
    "Maysville",
    "Mayville",
    "Mayville",
    "Mayville",
    "Mayville",
    "Maywood",
    "Maywood",
    "Maywood",
    "Maywood",
    "Mazeppa",
    "Mazomanie",
    "Mazon",
    "McAdoo",
    "McAlester",
    "McAlister",
    "McAlisterville",
    "McAllen",
    "McAlpin",
    "McArthur",
    "McArthur",
    "McBain",
    "McBee",
    "McCall",
    "McCalla",
    "McCamey",
    "McCammon",
    "McCaysville",
    "McCleary",
    "McClellan Park Census Designated Place",
    "McClelland",
    "McClellandtown",
    "McClellanville",
    "McCloud",
    "McClure",
    "McClure",
    "McClusky",
    "McColl",
    "McComb",
    "McComb",
    "McConnells",
    "McConnellsburg",
    "McConnelsville",
    "McCook",
    "McCool",
    "McCool Junction",
    "McCordsville",
    "McCormick",
    "McCoy",
    "McCune",
    "McCurtain",
    "McCutchenville",
    "McDaniels",
    "McDavid",
    "McDermitt",
    "McDermott",
    "McDonald",
    "McDonald",
    "McDonald",
    "McDonough",
    "McDonough",
    "McDowell",
    "McElhattan",
    "McEwen",
    "McFall",
    "McFarland",
    "McFarland",
    "McGaheysville",
    "McGehee",
    "McGraw",
    "McGregor",
    "McGregor",
    "McGregor",
    "McHenry",
    "McHenry",
    "McHenry",
    "McHenry",
    "McIntosh",
    "McKean Township",
    "McKee",
    "McKees Rocks",
    "McKeesport",
    "McKenna",
    "McKenzie",
    "McKinleyville",
    "McKinney",
    "McLain",
    "McLaughlin",
    "McLean",
    "McLean",
    "McLean",
    "McLeansboro",
    "McLeansville",
    "McLoud",
    "McMechen",
    "McMillan",
    "McMinnville",
    "McMinnville",
    "McNabb",
    "McNary",
    "McNeal",
    "McPherson",
    "McQueeney",
    "McRae",
    "McSherrystown",
    "McVeytown",
    "McVille",
    "Meacham",
    "Mead",
    "Mead",
    "Mead",
    "Meade",
    "Meadow",
    "Meadow",
    "Meadow Bridge",
    "Meadow Grove",
    "Meadow Lands",
    "Meadow Valley",
    "Meadow Vista",
    "Meadowlands",
    "Meadows of Dan",
    "Meadowview",
    "Meadview",
    "Meadville",
    "Meadville",
    "Meadville",
    "Meally",
    "Meansville",
    "Mears",
    "Mebane",
    "Mecca",
    "Mechanic Falls",
    "Mechanicsburg",
    "Mechanicsburg",
    "Mechanicsburg",
    "Mechanicsville",
    "Mechanicsville",
    "Mechanicsville",
    "Mechanicsville",
    "Mechanicville",
    "Mecosta",
    "Medanales",
    "Medaryville",
    "Medfield",
    "Medford",
    "Medford",
    "Medford",
    "Medford",
    "Medford",
    "Medford",
    "Medford",
    "Media",
    "Mediapolis",
    "Medical Lake",
    "Medicine Lake",
    "Medicine Lodge",
    "Medicine Park",
    "Medina",
    "Medina",
    "Medina",
    "Medina",
    "Medina",
    "Medina",
    "Medinah",
    "Medon",
    "Medora",
    "Medora",
    "Medora",
    "Medway",
    "Medway",
    "Medway",
    "Meeker",
    "Meeker",
    "Meeteetse",
    "Meherrin",
    "Mehoopany",
    "Mekoryuk",
    "Melba",
    "Melbourne",
    "Melbourne",
    "Melbourne",
    "Melbourne",
    "Melbourne Beach",
    "Melcher-Dallas",
    "Melcroft",
    "Melfa",
    "Melissa",
    "Mellen",
    "Mellenville",
    "Melrose",
    "Melrose",
    "Melrose",
    "Melrose",
    "Melrose",
    "Melrose",
    "Melrose Park",
    "Melstone",
    "Melville",
    "Melville",
    "Melvin",
    "Melvin",
    "Melvin",
    "Melvindale",
    "Memphis",
    "Memphis",
    "Memphis",
    "Memphis",
    "Memphis",
    "Memphis",
    "Mena",
    "Menahga",
    "Menan",
    "Menard",
    "Menasha",
    "Mendenhall",
    "Mendham",
    "Mendocino",
    "Mendon",
    "Mendon",
    "Mendon",
    "Mendon",
    "Mendon",
    "Mendon",
    "Mendota",
    "Mendota",
    "Menifee",
    "Menlo",
    "Menlo",
    "Menlo",
    "Menlo Park",
    "Menno",
    "Meno",
    "Menominee",
    "Menominee",
    "Menomonee Falls",
    "Mentone",
    "Mentone",
    "Mentor",
    "Mentor",
    "Mequon",
    "Mer Rouge",
    "Meraux",
    "Merced",
    "Mercedes",
    "Mercer",
    "Mercer",
    "Mercer",
    "Mercer Island",
    "Mercersburg",
    "Merchantville",
    "Meredith",
    "Mereta",
    "Meriden",
    "Meriden",
    "Meriden",
    "Meriden",
    "Meridian",
    "Meridian",
    "Meridian",
    "Meridian",
    "Meridianville",
    "Merino",
    "Merion",
    "Merit",
    "Merkel",
    "Merlin",
    "Mermentau",
    "Merna",
    "Merrick",
    "Merrifield",
    "Merrifield",
    "Merrill",
    "Merrill",
    "Merrill",
    "Merrillan",
    "Merrillville",
    "Merrimac",
    "Merrimac",
    "Merrimack",
    "Merriman",
    "Merritt",
    "Merritt Island",
    "Merry Hill",
    "Merry Point",
    "Merryville",
    "Merton",
    "Mertzon",
    "Mertztown",
    "Mesa",
    "Mesa",
    "Mescalero",
    "Meshoppen",
    "Mesick",
    "Mesilla",
    "Mesilla Park",
    "Mesquite",
    "Mesquite",
    "Metairie",
    "Metaline Falls",
    "Metamora",
    "Metamora",
    "Metamora",
    "Metcalf",
    "Methow",
    "Methuen",
    "Metlakatla",
    "Metropolis",
    "Metropolitan",
    "Metter",
    "Metuchen",
    "Metz",
    "Mexia",
    "Mexico",
    "Mexico",
    "Mexico",
    "Meyersdale",
    "Mi-Wuk Village",
    "Miami",
    "Miami",
    "Miami",
    "Miami",
    "Miami Beach",
    "Miamisburg",
    "Miamitown",
    "Miamiville",
    "Micanopy",
    "Michie",
    "Michigan Center",
    "Michigan City",
    "Michigantown",
    "Mickleton",
    "Mico",
    "Micro",
    "Middle Bass",
    "Middle Granville",
    "Middle Grove",
    "Middle Haddam",
    "Middle Island",
    "Middle Point",
    "Middle River",
    "Middle River",
    "Middle Village",
    "Middleboro",
    "Middlebourne",
    "Middleburg",
    "Middleburg",
    "Middleburg",
    "Middleburg",
    "Middleburgh",
    "Middlebury",
    "Middlebury",
    "Middlebury",
    "Middlefield",
    "Middlefield",
    "Middleport",
    "Middleport",
    "Middleport",
    "Middlesboro",
    "Middlesex",
    "Middlesex",
    "Middlesex",
    "Middleton",
    "Middleton",
    "Middleton",
    "Middleton",
    "Middleton",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown",
    "Middletown Springs",
    "Middleville",
    "Midfield",
    "Midkiff",
    "Midland",
    "Midland",
    "Midland",
    "Midland",
    "Midland",
    "Midland",
    "Midland",
    "Midland City",
    "Midland Park",
    "Midlothian",
    "Midlothian",
    "Midlothian",
    "Midvale",
    "Midvale",
    "Midvale",
    "Midville",
    "Midway",
    "Midway",
    "Midway",
    "Midway",
    "Midway",
    "Midway",
    "Midway",
    "Midway",
    "Midway City",
    "Midway Park",
    "Midwest",
    "Mifflin",
    "Mifflinburg",
    "Mifflintown",
    "Mifflinville",
    "Mikado",
    "Milaca",
    "Milam",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milan",
    "Milano",
    "Milbank",
    "Milburn",
    "Miles",
    "Miles",
    "Miles City",
    "Milesburg",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford",
    "Milford Center",
    "Mililani Town",
    "Mill City",
    "Mill Creek",
    "Mill Creek",
    "Mill Hall",
    "Mill Neck",
    "Mill River",
    "Mill Shoals",
    "Mill Spring",
    "Mill Valley",
    "Milladore",
    "Millboro",
    "Millbrae",
    "Millbrook",
    "Millbrook",
    "Millburn",
    "Millbury",
    "Millbury",
    "Milldale",
    "Milledgeville",
    "Milledgeville",
    "Millen",
    "Miller",
    "Miller",
    "Miller Place",
    "Millers Creek",
    "Millersburg",
    "Millersburg",
    "Millersburg",
    "Millersport",
    "Millerstown",
    "Millersview",
    "Millersville",
    "Millersville",
    "Millerton",
    "Millerton",
    "Millfield",
    "Millheim",
    "Millican",
    "Milligan",
    "Milliken",
    "Millington",
    "Millington",
    "Millington",
    "Millington",
    "Millington",
    "Millinocket",
    "Millis",
    "Millmont",
    "Millport",
    "Millry",
    "Mills",
    "Mills River",
    "Millsap",
    "Millsboro",
    "Millsboro",
    "Millstadt",
    "Millstone",
    "Milltown",
    "Milltown",
    "Milltown",
    "Millville",
    "Millville",
    "Millville",
    "Millville",
    "Millville",
    "Millville",
    "Millwood",
    "Millwood",
    "Millwood",
    "Millwood",
    "Millwood",
    "Milmay",
    "Milner",
    "Milnesville",
    "Milnor",
    "Milo",
    "Milo",
    "Milpitas",
    "Milroy",
    "Milroy",
    "Milroy",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton",
    "Milton Mills",
    "Milton-Freewater",
    "Miltona",
    "Miltonvale",
    "Milwaukee",
    "Milwaukie",
    "Mimbres",
    "Mims",
    "Minburn",
    "Minco",
    "Minden",
    "Minden",
    "Minden",
    "Minden",
    "Minden City",
    "Mine Hill",
    "Mineola",
    "Mineola",
    "Mineral",
    "Mineral",
    "Mineral Bluff",
    "Mineral City",
    "Mineral Point",
    "Mineral Ridge",
    "Mineral Springs",
    "Mineral Wells",
    "Mineral Wells",
    "Minersville",
    "Minersville",
    "Minerva",
    "Minford",
    "Mingo Junction",
    "Minier",
    "Minneapolis",
    "Minneapolis",
    "Minneola",
    "Minneota",
    "Minnesota Lake",
    "Minnetonka",
    "Minnewaukan",
    "Minnie",
    "Minoa",
    "Minocqua",
    "Minonk",
    "Minooka",
    "Minot",
    "Minot",
    "Minotola",
    "Minster",
    "Minto",
    "Minturn",
    "Mio",
    "Mira Loma",
    "Miramar",
    "Miramar Beach",
    "Miranda",
    "Mishawaka",
    "Mishicot",
    "Mission",
    "Mission",
    "Mission",
    "Mission Hill",
    "Mission Hills",
    "Mission Hills",
    "Mission Viejo",
    "Missoula",
    "Missouri City",
    "Missouri Valley",
    "Mitchell",
    "Mitchell",
    "Mitchell",
    "Mitchell",
    "Mitchellville",
    "Mize",
    "Moab",
    "Moapa",
    "Moatsville",
    "Moberly",
    "Mobile",
    "Mobridge",
    "Moccasin",
    "Mocksville",
    "Modale",
    "Modena",
    "Modena",
    "Modesto",
    "Modoc",
    "Modoc",
    "Modoc",
    "Mogadore",
    "Mohave Valley",
    "Mohawk",
    "Mohawk",
    "Mohawk",
    "Mohegan Lake",
    "Mohnton",
    "Mohrsville",
    "Mojave",
    "Mokelumne Hill",
    "Mokena",
    "Molalla",
    "Molena",
    "Moline",
    "Moline",
    "Moline",
    "Molino",
    "Mollusk",
    "Molt",
    "Momence",
    "Mona",
    "Monaca",
    "Monahans",
    "Moncks Corner",
    "Moncure",
    "Mondamin",
    "Mondovi",
    "Monee",
    "Monessen",
    "Moneta",
    "Monett",
    "Monette",
    "Monkton",
    "Monmouth",
    "Monmouth",
    "Monmouth",
    "Monmouth Beach",
    "Monmouth Junction",
    "Monon",
    "Monona",
    "Monongahela",
    "Monponsett",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe",
    "Monroe Center",
    "Monroe City",
    "Monroe City",
    "Monroe Township",
    "Monroeton",
    "Monroeville",
    "Monroeville",
    "Monroeville",
    "Monroeville",
    "Monroeville",
    "Monrovia",
    "Monrovia",
    "Monrovia",
    "Monsey",
    "Monson",
    "Monson",
    "Mont Belvieu",
    "Mont Clare",
    "Mont Vernon",
    "Montague",
    "Montague",
    "Montague",
    "Montague",
    "Montandon",
    "Montara",
    "Montauk",
    "Montclair",
    "Montclair",
    "Montclair",
    "Monte Rio",
    "Monte Vista",
    "Monteagle",
    "Montebello",
    "Montegut",
    "Montello",
    "Monterey",
    "Monterey",
    "Monterey",
    "Monterey",
    "Monterey",
    "Monterey County",
    "Monterey Park",
    "Monterville",
    "Montesano",
    "Montevallo",
    "Montevideo",
    "Monteview",
    "Montezuma",
    "Montezuma",
    "Montezuma",
    "Montezuma",
    "Montezuma",
    "Montezuma",
    "Montfort",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery",
    "Montgomery Center",
    "Montgomery City",
    "Montgomery Creek",
    "Montgomery Village",
    "Montgomeryville",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Monticello",
    "Montour",
    "Montour Falls",
    "Montoursville",
    "Montpelier",
    "Montpelier",
    "Montpelier",
    "Montpelier",
    "Montpelier",
    "Montpelier",
    "Montreal",
    "Montreat",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montrose",
    "Montross",
    "Montvale",
    "Montvale",
    "Montverde",
    "Montville",
    "Monument",
    "Monument Beach",
    "Moodus",
    "Moody",
    "Moody",
    "Moody",
    "Mooers",
    "Mooers Forks",
    "Moon",
    "Moonachie",
    "Moorcroft",
    "Moore",
    "Moore",
    "Moore",
    "Moore Haven",
    "Moorefield",
    "Mooreland",
    "Mooreland",
    "Moores Hill",
    "Mooresboro",
    "Mooresburg",
    "Moorestown Township",
    "Mooresville",
    "Mooresville",
    "Mooresville",
    "Mooreton",
    "Mooreville",
    "Moorhead",
    "Moorhead",
    "Mooringsport",
    "Moorland",
    "Moorpark",
    "Moose",
    "Moose Lake",
    "Moosic",
    "Moosup",
    "Mora",
    "Mora",
    "Moraga",
    "Moran",
    "Moran",
    "Moravia",
    "Moravia",
    "Moravian Falls",
    "Moreauville",
    "Morehead",
    "Morehead City",
    "Moreland",
    "Morenci",
    "Morenci",
    "Moreno Valley",
    "Moretown",
    "Morgan",
    "Morgan",
    "Morgan",
    "Morgan",
    "Morgan",
    "Morgan City",
    "Morgan Hill",
    "Morganfield",
    "Morganton",
    "Morganton",
    "Morgantown",
    "Morgantown",
    "Morgantown",
    "Morgantown",
    "Morganville",
    "Morganville",
    "Moriah",
    "Moriarty",
    "Moriches",
    "Morley",
    "Morning Sun",
    "Morning View",
    "Moro",
    "Moro",
    "Moro",
    "Morocco",
    "Morongo Valley",
    "Moroni",
    "Morral",
    "Morrice",
    "Morrill",
    "Morrill",
    "Morrill",
    "Morrilton",
    "Morris",
    "Morris",
    "Morris",
    "Morris",
    "Morris",
    "Morris",
    "Morris Chapel",
    "Morris Plains",
    "Morrisdale",
    "Morrison",
    "Morrison",
    "Morrison",
    "Morrison",
    "Morrisonville",
    "Morrisonville",
    "Morriston",
    "Morristown",
    "Morristown",
    "Morristown",
    "Morristown",
    "Morristown",
    "Morristown",
    "Morristown",
    "Morrisville",
    "Morrisville",
    "Morrisville",
    "Morrisville",
    "Morrisville",
    "Morro Bay",
    "Morrow",
    "Morrow",
    "Morrow",
    "Morse",
    "Morse",
    "Morse Bluff",
    "Morton",
    "Morton",
    "Morton",
    "Morton",
    "Morton",
    "Morton",
    "Morton Grove",
    "Mortons Gap",
    "Morven",
    "Mosca",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow",
    "Moscow Mills",
    "Moseley",
    "Moses Lake",
    "Mosheim",
    "Mosier",
    "Mosinee",
    "Moss",
    "Moss Beach",
    "Moss Landing",
    "Moss Point",
    "Mossyrock",
    "Motley",
    "Mott",
    "Moulton",
    "Moulton",
    "Moulton",
    "Moultonborough",
    "Moultrie",
    "Mound",
    "Mound Bayou",
    "Mound City",
    "Mound City",
    "Moundridge",
    "Mounds",
    "Mounds",
    "Moundsville",
    "Moundville",
    "Mount Airy",
    "Mount Airy",
    "Mount Airy",
    "Mount Angel",
    "Mount Arlington",
    "Mount Auburn",
    "Mount Ayr",
    "Mount Berry",
    "Mount Bethel",
    "Mount Blanchard",
    "Mount Calvary",
    "Mount Carbon",
    "Mount Carmel",
    "Mount Carmel",
    "Mount Carmel",
    "Mount Carroll",
    "Mount Clemens",
    "Mount Cory",
    "Mount Crawford",
    "Mount Croghan",
    "Mount Eaton",
    "Mount Eden",
    "Mount Enterprise",
    "Mount Ephraim",
    "Mount Erie",
    "Mount Gay",
    "Mount Gilead",
    "Mount Gilead",
    "Mount Gretna",
    "Mount Hermon",
    "Mount Hermon",
    "Mount Holly",
    "Mount Holly",
    "Mount Holly",
    "Mount Holly",
    "Mount Holly Springs",
    "Mount Hope",
    "Mount Hope",
    "Mount Hope",
    "Mount Hope",
    "Mount Horeb",
    "Mount Ida",
    "Mount Jackson",
    "Mount Jewett",
    "Mount Joy",
    "Mount Juliet",
    "Mount Kisco",
    "Mount Laurel",
    "Mount Lookout",
    "Mount Morris",
    "Mount Morris",
    "Mount Morris",
    "Mount Nebo",
    "Mount Olive",
    "Mount Olive",
    "Mount Olive",
    "Mount Olive",
    "Mount Olivet",
    "Mount Orab",
    "Mount Pleasant",
    "Mount Pleasant",
    "Mount Pleasant",
    "Mount Pleasant",
    "Mount Pleasant",
    "Mount Pleasant",
    "Mount Pleasant Mills",
    "Mount Pocono",
    "Mount Prospect",
    "Mount Pulaski",
    "Mount Rainier",
    "Mount Royal",
    "Mount Saint Joseph",
    "Mount Savage",
    "Mount Shasta",
    "Mount Sidney",
    "Mount Sinai",
    "Mount Sterling",
    "Mount Sterling",
    "Mount Sterling",
    "Mount Sterling",
    "Mount Storm",
    "Mount Tremper",
    "Mount Ulla",
    "Mount Union",
    "Mount Union",
    "Mount Upton",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Vernon",
    "Mount Victoria",
    "Mount Victory",
    "Mount Vision",
    "Mount Washington",
    "Mount Wilson",
    "Mount Wolf",
    "Mount Zion",
    "Mount Zion",
    "Mountain",
    "Mountain City",
    "Mountain City",
    "Mountain Grove",
    "Mountain Home",
    "Mountain Home",
    "Mountain Home",
    "Mountain Home",
    "Mountain Iron",
    "Mountain Lake",
    "Mountain Lakes",
    "Mountain Pine",
    "Mountain Ranch",
    "Mountain Top",
    "Mountain View",
    "Mountain View",
    "Mountain View",
    "Mountain View",
    "Mountain View",
    "Mountain View",
    "Mountain Village",
    "Mountainair",
    "Mountainburg",
    "Mountainside",
    "Mountainville",
    "Mountlake Terrace",
    "Mountville",
    "Mousie",
    "Mouth of Wilson",
    "Mouthcard",
    "Moville",
    "Moweaqua",
    "Moxee City",
    "Moyie Springs",
    "Moyock",
    "Mt Pleasant",
    "Mt. Dora",
    "Mt. Morris",
    "Mt. Pleasant",
    "Mt. Vernon",
    "Muenster",
    "Muir",
    "Muir",
    "Mukilteo",
    "Mukwonago",
    "Mulberry",
    "Mulberry",
    "Mulberry",
    "Mulberry",
    "Mulberry Grove",
    "Muldoon",
    "Muldraugh",
    "Muldrow",
    "Muleshoe",
    "Mulga",
    "Mulino",
    "Mulkeytown",
    "Mullan",
    "Mullen",
    "Mullica Hill",
    "Mulliken",
    "Mullin",
    "Mullins",
    "Mullinville",
    "Mulvane",
    "Muncie",
    "Muncy",
    "Munday",
    "Mundelein",
    "Munford",
    "Munford",
    "Munfordville",
    "Munger",
    "Munich",
    "Munising",
    "Munith",
    "Munnsville",
    "Munroe Falls",
    "Munster",
    "Murchison",
    "Murdock",
    "Murdock",
    "Murfreesboro",
    "Murfreesboro",
    "Murfreesboro",
    "Murphy",
    "Murphy",
    "Murphys",
    "Murphysboro",
    "Murray",
    "Murray",
    "Murray",
    "Murrayville",
    "Murrells Inlet",
    "Murrieta",
    "Murrysville",
    "Muscatine",
    "Muscle Shoals",
    "Muscoda",
    "Muse",
    "Musella",
    "Muskego",
    "Muskegon",
    "Muskogee",
    "Mustang",
    "Mutual",
    "Myakka City",
    "Myerstown",
    "Myersville",
    "Mylo",
    "Myrtle",
    "Myrtle Beach",
    "Myrtle Creek",
    "Myrtle Point",
    "Mystic",
    "Mystic",
    "Myton",
    "N. Lawrence",
    "Nabb",
    "Nabesna",
    "Naco",
    "Nacogdoches",
    "Nacoochee",
    "Nageezi",
    "Nags Head",
    "Nahant",
    "Nahunta",
    "Naknek",
    "Nampa",
    "Nancy",
    "Nanjemoy",
    "Nanticoke",
    "Nantucket",
    "Nanty Glo",
    "Nanuet",
    "Naoma",
    "Napa",
    "Napavine",
    "Naper",
    "Naperville",
    "Napier",
    "Naples",
    "Naples",
    "Naples",
    "Naples",
    "Naples",
    "Napoleon",
    "Napoleon",
    "Napoleonville",
    "Nappanee",
    "Nara Visa",
    "Narberth",
    "Narka",
    "Narragansett",
    "Narrows",
    "Narrowsburg",
    "Narvon",
    "Naselle",
    "Nash",
    "Nash",
    "Nashoba",
    "Nashotah",
    "Nashport",
    "Nashua",
    "Nashua",
    "Nashua",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashville",
    "Nashwauk",
    "Nassau",
    "Nassawadox",
    "Natalbany",
    "Natalia",
    "Natchez",
    "Natchitoches",
    "Natick",
    "National City",
    "National Park",
    "Natrona Heights",
    "Natural Bridge",
    "Natural Bridge",
    "Natural Bridge Station",
    "Naturita",
    "Naubinway",
    "Naugatuck",
    "Nauvoo",
    "Nauvoo",
    "Navajo",
    "Navarre",
    "Navarre",
    "Navasota",
    "Naylor",
    "Naylor",
    "Nazan Village",
    "Nazareth",
    "Nazareth",
    "Neah Bay",
    "Nebo",
    "Nebo",
    "Nebraska City",
    "Necedah",
    "Neche",
    "Nederland",
    "Nederland",
    "Nedrow",
    "Needham",
    "Needham",
    "Needham Heights",
    "Needles",
    "Needmore",
    "Needville",
    "Neely",
    "Neelyville",
    "Neenah",
    "Neeses",
    "Neffs",
    "Negaunee",
    "Negley",
    "Nehalem",
    "Neillsville",
    "Nekoosa",
    "Neligh",
    "Nellis",
    "Nellysford",
    "Nelson",
    "Nelson",
    "Nelsonville",
    "Nemacolin",
    "Nemaha",
    "Nemo",
    "Nenana",
    "Neodesha",
    "Neoga",
    "Neola",
    "Neosho",
    "Neosho",
    "Neosho Falls",
    "Neosho Rapids",
    "Neotsu",
    "Nephi",
    "Neponset",
    "Neptune Beach",
    "Neptune City",
    "Nerstrand",
    "Nesbit",
    "Nesconset",
    "Nescopeck",
    "Neshanic Station",
    "Neshkoro",
    "Nesquehoning",
    "Ness City",
    "Nester",
    "Netawaka",
    "Netcong",
    "Nettleton",
    "Nevada",
    "Nevada",
    "Nevada",
    "Nevada City",
    "Neversink",
    "Nevis",
    "New Albany",
    "New Albany",
    "New Albany",
    "New Albany",
    "New Albin",
    "New Alexandria",
    "New Almaden",
    "New Athens",
    "New Auburn",
    "New Baden",
    "New Baltimore",
    "New Bavaria",
    "New Bedford",
    "New Berlin",
    "New Berlin",
    "New Berlin",
    "New Berlin",
    "New Berlinville",
    "New Bern",
    "New Bethlehem",
    "New Blaine",
    "New Bloomfield",
    "New Boston",
    "New Boston",
    "New Boston",
    "New Boston",
    "New Braintree",
    "New Braunfels",
    "New Bremen",
    "New Brighton",
    "New Britain",
    "New Brockton",
    "New Brunswick",
    "New Buffalo",
    "New Burnside",
    "New Cambria",
    "New Cambria",
    "New Canaan",
    "New Caney",
    "New Canton",
    "New Carlisle",
    "New Carlisle",
    "New Castle",
    "New Castle",
    "New Castle",
    "New Castle",
    "New Castle",
    "New Castle",
    "New Castle",
    "New Church",
    "New City",
    "New Columbia",
    "New Concord",
    "New Concord",
    "New Creek",
    "New Cumberland",
    "New Cumberland",
    "New Cuyama",
    "New Deal",
    "New Derry",
    "New Douglas",
    "New Durham",
    "New Eagle",
    "New Effington",
    "New Egypt",
    "New England",
    "New Enterprise",
    "New Era",
    "New Fairfield",
    "New Florence",
    "New Florence",
    "New Franken",
    "New Franklin",
    "New Freedom",
    "New Galilee",
    "New Germany",
    "New Glarus",
    "New Gloucester",
    "New Goshen",
    "New Hampton",
    "New Hampton",
    "New Hampton",
    "New Hampton",
    "New Harbor",
    "New Harmony",
    "New Harmony",
    "New Hartford",
    "New Hartford",
    "New Haven",
    "New Haven",
    "New Haven",
    "New Haven",
    "New Haven",
    "New Haven",
    "New Haven",
    "New Hebron",
    "New Hill",
    "New Holland",
    "New Holland",
    "New Holstein",
    "New Hope",
    "New Hudson",
    "New Hyde Park",
    "New Iberia",
    "New Ipswich",
    "New Johnsonville",
    "New Kensington",
    "New Kent",
    "New Knoxville",
    "New Laguna",
    "New Lebanon",
    "New Lebanon",
    "New Lenox",
    "New Lexington",
    "New Liberty",
    "New Lisbon",
    "New Lisbon",
    "New Llano",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New London",
    "New Lothrop",
    "New Madrid",
    "New Market",
    "New Market",
    "New Market",
    "New Market",
    "New Market",
    "New Market",
    "New Marshfield",
    "New Martinsville",
    "New Meadows",
    "New Memphis",
    "New Middletown",
    "New Milford",
    "New Milford",
    "New Milford",
    "New Orleans",
    "New Oxford",
    "New Palestine",
    "New Paltz",
    "New Paris",
    "New Paris",
    "New Paris",
    "New Park",
    "New Philadelphia",
    "New Philadelphia",
    "New Plymouth",
    "New Plymouth",
    "New Point",
    "New Port Richey",
    "New Portland",
    "New Prague",
    "New Preston",
    "New Providence",
    "New Providence",
    "New Providence",
    "New Raymer",
    "New Richland",
    "New Richmond",
    "New Richmond",
    "New Richmond",
    "New Riegel",
    "New Ringgold",
    "New River",
    "New Roads",
    "New Rochelle",
    "New Rockford",
    "New Ross",
    "New Salem",
    "New Salem",
    "New Salem",
    "New Salisbury",
    "New Sharon",
    "New Sharon",
    "New Smyrna Beach",
    "New Springfield",
    "New Stanton",
    "New Straitsville",
    "New Stuyahok",
    "New Tazewell",
    "New Town",
    "New Tripoli",
    "New Troy",
    "New Ulm",
    "New Ulm",
    "New Underwood",
    "New Vernon",
    "New Vienna",
    "New Vienna",
    "New Vineyard",
    "New Virginia",
    "New Washington",
    "New Waterford",
    "New Waverly",
    "New Weston",
    "New Wilmington",
    "New Windsor",
    "New Windsor",
    "New Windsor",
    "New Woodstock",
    "New York",
    "New York Mills",
    "New York Mills",
    "Newalla",
    "Newark",
    "Newark",
    "Newark",
    "Newark",
    "Newark",
    "Newark",
    "Newark",
    "Newark",
    "Newark Valley",
    "Newaygo",
    "Newberg",
    "Newbern",
    "Newberry",
    "Newberry",
    "Newberry",
    "Newberry",
    "Newberry Springs",
    "Newborn",
    "Newburg",
    "Newburg",
    "Newburg",
    "Newburgh",
    "Newburgh",
    "Newbury",
    "Newbury",
    "Newbury Center",
    "Newbury Old Town",
    "Newbury Park",
    "Newburyport",
    "Newcastle",
    "Newcastle",
    "Newcastle",
    "Newcastle",
    "Newcastle",
    "Newcastle",
    "Newcomb",
    "Newcomerstown",
    "Newell",
    "Newell",
    "Newell",
    "Newell",
    "Newellton",
    "Newfane",
    "Newfane",
    "Newfield",
    "Newfield",
    "Newfields",
    "Newfolden",
    "Newfoundland",
    "Newfoundland",
    "Newhall",
    "Newhall",
    "Newhope",
    "Newington",
    "Newington",
    "Newington",
    "Newkirk",
    "Newland",
    "Newman",
    "Newman",
    "Newman Grove",
    "Newman Lake",
    "Newmanstown",
    "Newmarket",
    "Newnan",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport",
    "Newport Beach",
    "Newport Center",
    "Newport Coast",
    "Newport News",
    "Newry",
    "Newsoms",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton",
    "Newton Center",
    "Newton Falls",
    "Newton Grove",
    "Newton Highlands",
    "Newton Lower Falls",
    "Newton Upper Falls",
    "Newtonsville",
    "Newtonville",
    "Newtown",
    "Newtown",
    "Newtown",
    "Newtown",
    "Newtown Square",
    "Newville",
    "Newville",
    "Nezperce",
    "Niagara",
    "Niagara Falls",
    "Niangua",
    "Niantic",
    "Niantic",
    "Nicasio",
    "Niceville",
    "Nicholasville",
    "Nicholls",
    "Nichols",
    "Nichols",
    "Nicholson",
    "Nicholson",
    "Nicholville",
    "Nickelsville",
    "Nickerson",
    "Nickerson",
    "Nicktown",
    "Nicollet",
    "Nicoma Park",
    "Nielsville",
    "Nikiski",
    "Niland",
    "Niles",
    "Niles",
    "Niles",
    "Nilwood",
    "Nine Mile Falls",
    "Ninety Six",
    "Nineveh",
    "Nineveh",
    "Ninilchik",
    "Ninnekah",
    "Ninole",
    "Niobe",
    "Niobrara",
    "Niota",
    "Niota",
    "Nipomo",
    "Nisswa",
    "Nitro",
    "Niwot",
    "Nixa",
    "Nixon",
    "Nixon",
    "Noatak",
    "Noble",
    "Noble",
    "Nobleboro",
    "Noblesville",
    "Nocona",
    "Noel",
    "Nogal",
    "Nogales",
    "Nokesville",
    "Nokomis",
    "Nokomis",
    "Nolanville",
    "Nolensville",
    "Nome",
    "Nondalton",
    "Nooksack",
    "Noonan",
    "Noorvik",
    "Nora Springs",
    "Norborne",
    "Norco",
    "Norco",
    "Norcross",
    "Nordland",
    "Norfolk",
    "Norfolk",
    "Norfolk",
    "Norfolk",
    "Norlina",
    "Norma",
    "Normal",
    "Normal",
    "Normalville",
    "Norman",
    "Norman",
    "Norman",
    "Norman Park",
    "Normandy",
    "Normandy Beach",
    "Normangee",
    "Normantown",
    "Norridgewock",
    "Norris",
    "Norris",
    "Norris City",
    "Norristown",
    "North",
    "North Adams",
    "North Adams",
    "North Amherst",
    "North Andover",
    "North Anson",
    "North Apollo",
    "North Arlington",
    "North Attleboro",
    "North Augusta",
    "North Aurora",
    "North Babylon",
    "North Baltimore",
    "North Bangor",
    "North Beach",
    "North Bend",
    "North Bend",
    "North Bend",
    "North Bend",
    "North Bend",
    "North Bennington",
    "North Bergen",
    "North Berwick",
    "North Billerica",
    "North Bloomfield",
    "North Bonneville",
    "North Branch",
    "North Branch",
    "North Branch",
    "North Branford",
    "North Bridgton",
    "North Brookfield",
    "North Brookfield",
    "North Brunswick",
    "North Carrollton",
    "North Carver",
    "North Charleston",
    "North Chatham",
    "North Chelmsford",
    "North Chicago",
    "North Chili",
    "North Clarendon",
    "North Collins",
    "North Conway",
    "North Creek",
    "North Dartmouth",
    "North Dighton",
    "North East",
    "North East",
    "North Eastham",
    "North Easton",
    "North English",
    "North Fairfield",
    "North Falmouth",
    "North Falmouth",
    "North Fork",
    "North Fort Myers",
    "North Franklin",
    "North Freedom",
    "North Garden",
    "North Grafton",
    "North Granby",
    "North Greece",
    "North Grosvenor Dale",
    "North Hampton",
    "North Hatfield",
    "North Haven",
    "North Haven",
    "North Haverhill",
    "North Henderson",
    "North Hero",
    "North Highlands",
    "North Hills",
    "North Hollywood",
    "North Jackson",
    "North Judson",
    "North Kingstown",
    "North Kingsville",
    "North Las Vegas",
    "North Lemmon",
    "North Lewisburg",
    "North Liberty",
    "North Liberty",
    "North Lima",
    "North Little Rock",
    "North Loup",
    "North Manchester",
    "North Matewan",
    "North Miami Beach",
    "North Monmouth",
    "North Myrtle Beach",
    "North Newton",
    "North Olmsted",
    "North Oxford",
    "North Palm Beach",
    "North Pembroke",
    "North Plains",
    "North Platte",
    "North Point",
    "North Pole",
    "North Pomfret",
    "North Port",
    "North Powder",
    "North Prairie",
    "North Providence",
    "North Reading",
    "North Richland Hills",
    "North Ridgeville",
    "North Rose",
    "North Royalton",
    "North Salem",
    "North Salem",
    "North Salem",
    "North Salt Lake",
    "North San Juan",
    "North Sandwich",
    "North Scituate",
    "North Sioux City",
    "North Smithfield",
    "North Springfield",
    "North Stonington",
    "North Sutton",
    "North Tazewell",
    "North Tonawanda",
    "North Troy",
    "North Truro",
    "North Vernon",
    "North Versailles",
    "North Wales",
    "North Walpole",
    "North Waterboro",
    "North Webster",
    "North Weymouth",
    "North Wilkesboro",
    "North Windham",
    "North Woodstock",
    "North Zulch",
    "Northampton",
    "Northampton",
    "Northboro",
    "Northborough",
    "Northbridge",
    "Northbrook",
    "Northeast Harbor",
    "Northern Cambria",
    "Northfield",
    "Northfield",
    "Northfield",
    "Northfield",
    "Northfield",
    "Northfield",
    "Northfield Falls",
    "Northford",
    "Northfork",
    "Northome",
    "Northport",
    "Northport",
    "Northport",
    "Northridge",
    "Northumberland",
    "Northvale",
    "Northville",
    "Northville",
    "Northway",
    "Northwood",
    "Northwood",
    "Northwood",
    "Northwood",
    "Norton",
    "Norton",
    "Norton",
    "Nortonville",
    "Nortonville",
    "Norwalk",
    "Norwalk",
    "Norwalk",
    "Norwalk",
    "Norwalk",
    "Norway",
    "Norway",
    "Norway",
    "Norway",
    "Norwell",
    "Norwich",
    "Norwich",
    "Norwich",
    "Norwich",
    "Norwich",
    "Norwood",
    "Norwood",
    "Norwood",
    "Norwood",
    "Norwood",
    "Norwood",
    "Norwood",
    "Norwood Young America",
    "Notasulga",
    "Notre Dame",
    "Nottingham",
    "Nottingham",
    "Nottingham",
    "Nova",
    "Novato",
    "Novelty",
    "Novelty",
    "Novi",
    "Novinger",
    "Nowata",
    "Noxapater",
    "Noxen",
    "Noxon",
    "Nucla",
    "Nuevo",
    "NuMine",
    "Nunda",
    "Nunica",
    "Nunn",
    "Nunnelly",
    "Nuremberg",
    "Nutley",
    "Nutrioso",
    "Nyack",
    "Nye",
    "Nyssa",
    "Oacoma",
    "Oak Bluffs",
    "Oak Brook",
    "Oak City",
    "Oak City",
    "Oak Creek",
    "Oak Creek",
    "Oak Forest",
    "Oak Grove",
    "Oak Grove",
    "Oak Grove",
    "Oak Harbor",
    "Oak Harbor",
    "Oak Hill",
    "Oak Hill",
    "Oak Hill",
    "Oak Hill",
    "Oak Hill",
    "Oak Island",
    "Oak Lawn",
    "Oak Park",
    "Oak Park",
    "Oak Park",
    "Oak Park",
    "Oak Ridge",
    "Oak Ridge",
    "Oak Ridge",
    "Oak Ridge",
    "Oak Run",
    "Oak View",
    "Oakboro",
    "Oakdale",
    "Oakdale",
    "Oakdale",
    "Oakdale",
    "Oakdale",
    "Oakdale",
    "Oakdale",
    "Oakes",
    "Oakesdale",
    "Oakfield",
    "Oakfield",
    "Oakfield",
    "Oakfield",
    "Oakfield",
    "Oakham",
    "Oakhurst",
    "Oakhurst",
    "Oakhurst",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland",
    "Oakland City",
    "Oakland Estates Mobile Home Park",
    "Oakland Gardens",
    "Oakley",
    "Oakley",
    "Oakley",
    "Oakley",
    "Oakley",
    "Oaklyn",
    "Oakman",
    "Oakmont",
    "Oaks",
    "Oakton",
    "Oakvale",
    "Oakville",
    "Oakville",
    "Oakville",
    "Oakwood",
    "Oakwood",
    "Oakwood",
    "Oakwood",
    "Oakwood",
    "Oakwood",
    "Oberlin",
    "Oberlin",
    "Oberlin",
    "Obion",
    "Oblong",
    "Ocala",
    "Ocate",
    "Occidental",
    "Occoquan",
    "Ocean City",
    "Ocean City",
    "Ocean Gate",
    "Ocean Grove",
    "Ocean Isle Beach",
    "Ocean Park",
    "Ocean Park",
    "Ocean Shores",
    "Ocean Springs",
    "Ocean View",
    "Ocean View",
    "Oceana",
    "Oceano",
    "Oceanport",
    "Oceanside",
    "Oceanside",
    "Oceanville",
    "Ochelata",
    "Ocheyedan",
    "Ochlocknee",
    "Ocilla",
    "Ocklawaha",
    "Ocoee",
    "Ocoee",
    "Oconomowoc",
    "Oconto",
    "Oconto Falls",
    "Ocotillo",
    "Odanah",
    "Odebolt",
    "Odell",
    "Odell",
    "Odem",
    "Oden",
    "Odenton",
    "Odenville",
    "Odessa",
    "Odessa",
    "Odessa",
    "Odessa",
    "Odessa",
    "Odessa",
    "Odessa",
    "Odin",
    "Odin",
    "Odon",
    "Odum",
    "Oelrichs",
    "Oelwein",
    "Ogallah",
    "Ogallala",
    "Ogden",
    "Ogden",
    "Ogden",
    "Ogdensburg",
    "Ogdensburg",
    "Ogdensburg",
    "Ogema",
    "Ogema",
    "Ogilvie",
    "Oglesby",
    "Oglethorpe",
    "Ogunquit",
    "Ohatchee",
    "Ohio",
    "Ohio City",
    "Ohiopyle",
    "Oil City",
    "Oil City",
    "Oil Springs",
    "Oilton",
    "Oilton",
    "Ojai",
    "Ojo Caliente",
    "Okahumpka",
    "Okanogan",
    "Okarche",
    "Okatie",
    "Okay",
    "Okeana",
    "Okeechobee",
    "Okeene",
    "Okemah",
    "Okemos",
    "Oketo",
    "Oklahoma City",
    "Oklaunion",
    "Oklee",
    "Okmulgee",
    "Okoboji",
    "Okolona",
    "Okolona",
    "Oktaha",
    "Ola",
    "Olalla",
    "Olancha",
    "Olanta",
    "Olanta",
    "Olathe",
    "Olathe",
    "Olaton",
    "Old Bethpage",
    "Old Bridge",
    "Old Chatham",
    "Old Fields",
    "Old Forge",
    "Old Forge",
    "Old Fort",
    "Old Greenwich",
    "Old Hickory",
    "Old Kotzebue",
    "Old Lyme",
    "Old Ocean",
    "Old Orchard Beach",
    "Old Pekin",
    "Old Saybrook",
    "Old Town",
    "Old Town",
    "Old Westbury",
    "Oldfort",
    "Oldsmar",
    "Oldtown",
    "Oldtown",
    "Oldwick",
    "Olean",
    "Oley",
    "Olin",
    "Olive Branch",
    "Olive Branch",
    "Olive Hill",
    "Olivebridge",
    "Oliveburg",
    "Olivehurst",
    "Oliver Springs",
    "Olivet",
    "Olivette",
    "Olivia",
    "Olla",
    "Ollie",
    "Olmito",
    "Olmsted",
    "Olmsted Falls",
    "Olmstedville",
    "Olney",
    "Olney",
    "Olney",
    "Olney Springs",
    "Olpe",
    "Olsburg",
    "Olton",
    "Olympia",
    "Olympia Fields",
    "Olyphant",
    "Omaha",
    "Omaha",
    "Omaha",
    "Omaha",
    "Omak",
    "Omega",
    "Omega",
    "Omena",
    "Omro",
    "Ona",
    "Ona",
    "Onaga",
    "Onalaska",
    "Onalaska",
    "Onalaska",
    "Onamia",
    "Onancock",
    "Onarga",
    "Onawa",
    "Onaway",
    "Oneco",
    "Oneco",
    "Oneida",
    "Oneida",
    "Oneida",
    "Oneida",
    "Onekama",
    "Oneonta",
    "Oneonta",
    "Onia",
    "Onley",
    "Onondaga",
    "Onset",
    "Onslow",
    "Onsted",
    "Ontario",
    "Ontario",
    "Ontario",
    "Ontario",
    "Ontonagon",
    "Onyx",
    "Oologah",
    "Ooltewah",
    "Oostburg",
    "Opa-locka",
    "Opdyke",
    "Opelika",
    "Opelousas",
    "Opheim",
    "Ophiem",
    "Opolis",
    "Opp",
    "Oquawka",
    "Oracle",
    "Oradell",
    "Oran",
    "Oran",
    "Orange",
    "Orange",
    "Orange",
    "Orange",
    "Orange",
    "Orange",
    "Orange",
    "Orange Beach",
    "Orange City",
    "Orange City",
    "Orange Cove",
    "Orange Grove",
    "Orange Park",
    "Orange Springs",
    "Orangeburg",
    "Orangeburg",
    "Orangevale",
    "Orangeville",
    "Orangeville",
    "Orangeville",
    "Orchard",
    "Orchard",
    "Orchard",
    "Orchard Hill",
    "Orchard Park",
    "Ord",
    "Orderville",
    "Ordway",
    "Ore City",
    "Oreana",
    "Orefield",
    "Oregon",
    "Oregon",
    "Oregon",
    "Oregon",
    "Oregon City",
    "Oregon House",
    "Oregonia",
    "Oreland",
    "Orem",
    "Orford",
    "Orfordville",
    "Orient",
    "Orient",
    "Orient",
    "Orient",
    "Oriental",
    "Orinda",
    "Orion",
    "Oriska",
    "Oriskany",
    "Oriskany Falls",
    "Orland",
    "Orland",
    "Orland",
    "Orland Park",
    "Orlando",
    "Orlando",
    "Orlando",
    "Orleans",
    "Orleans",
    "Orleans",
    "Orleans",
    "Orleans",
    "Orlinda",
    "Orma",
    "Ormond Beach",
    "Orofino",
    "Orondo",
    "Orono",
    "Oronoco",
    "Oronogo",
    "Orosi",
    "Oroville",
    "Oroville",
    "Orrington",
    "Orrs Island",
    "Orrstown",
    "Orrtanna",
    "Orrville",
    "Orrville",
    "Orting",
    "Ortonville",
    "Orwell",
    "Orwell",
    "Orwigsburg",
    "Osage",
    "Osage",
    "Osage",
    "Osage Beach",
    "Osage City",
    "Osakis",
    "Osawatomie",
    "Osborn",
    "Osborne",
    "Osburn",
    "Osceola",
    "Osceola",
    "Osceola",
    "Osceola",
    "Osceola",
    "Osceola",
    "Osceola",
    "Osceola Mills",
    "Osco",
    "Oscoda",
    "Osgood",
    "Oshkosh",
    "Oshkosh",
    "Oshtemo",
    "Oskaloosa",
    "Oskaloosa",
    "Oslo",
    "Osmond",
    "Osprey",
    "Osseo",
    "Osseo",
    "Ossian",
    "Ossian",
    "Ossineke",
    "Ossining",
    "Ossipee",
    "Osteen",
    "Osterville",
    "Ostrander",
    "Ostrander",
    "Oswego",
    "Oswego",
    "Oswego",
    "Osyka",
    "Otego",
    "Othello",
    "Otho",
    "Otis",
    "Otis",
    "Otis",
    "Otis Orchards",
    "Otisco",
    "Otisville",
    "Otisville",
    "Otoe",
    "Otsego",
    "Ottawa",
    "Ottawa",
    "Ottawa",
    "Otter",
    "Otter Lake",
    "Otterbein",
    "Ottertail",
    "Otto",
    "Ottosen",
    "Ottoville",
    "Ottsville",
    "Ottumwa",
    "Otway",
    "Ouray",
    "Outing",
    "Outlook",
    "Overbrook",
    "Overbrook",
    "Overgaard",
    "Overland Park",
    "Overpeck",
    "Overton",
    "Overton",
    "Ovid",
    "Ovid",
    "Ovid",
    "Oviedo",
    "Owaneco",
    "Owasso",
    "Owatonna",
    "Owego",
    "Owen",
    "Owens Cross Roads",
    "Owensboro",
    "Owensburg",
    "Owensville",
    "Owensville",
    "Owensville",
    "Owenton",
    "Owings",
    "Owings Mills",
    "Owingsville",
    "Owosso",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford",
    "Oxford Junction",
    "Oxnard",
    "Oxon Hill",
    "Oyster Bay",
    "Ozan",
    "Ozark",
    "Ozark",
    "Ozark",
    "Ozark",
    "Ozawkie",
    "Ozona",
    "Ozone",
    "Ozone Park",
    "Pablo",
    "Pacific",
    "Pacific",
    "Pacific City",
    "Pacific Grove",
    "Pacific Junction",
    "Pacific Palisades",
    "Pacifica",
    "Packwood",
    "Packwood",
    "Pacoima",
    "Pacolet",
    "Paden",
    "Paden City",
    "Paducah",
    "Paducah",
    "Paeonian Springs",
    "Page",
    "Page",
    "Pageland",
    "Pageton",
    "Pagosa Springs",
    "Pahala",
    "Pahoa",
    "Pahokee",
    "Pahrump",
    "Paia",
    "Paige",
    "Painesdale",
    "Painesville",
    "Paint Lick",
    "Paint Rock",
    "Painted Post",
    "Painter",
    "Paintsville",
    "Pala",
    "Palacios",
    "Palatine",
    "Palatka",
    "Palermo",
    "Palermo",
    "Palermo",
    "Palestine",
    "Palestine",
    "Palestine",
    "Palisade",
    "Palisade",
    "Palisade",
    "Palisades",
    "Palisades Park",
    "Pall Mall",
    "Palm",
    "Palm Bay",
    "Palm Beach",
    "Palm Beach Gardens",
    "Palm City",
    "Palm Coast",
    "Palm Desert",
    "Palm Harbor",
    "Palm Springs",
    "Palmdale",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer",
    "Palmer Lake",
    "Palmerdale",
    "Palmersville",
    "Palmerton",
    "Palmetto",
    "Palmetto",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palmyra",
    "Palo",
    "Palo Alto",
    "Palo Cedro",
    "Palo Verde",
    "Paloma",
    "Palos Heights",
    "Palos Hills",
    "Palos Park",
    "Palos Verdes Peninsula",
    "Palouse",
    "Pampa",
    "Pamplico",
    "Pamplin",
    "Pana",
    "Panaca",
    "Panacea",
    "Panama",
    "Panama",
    "Panama",
    "Panama",
    "Panama City",
    "Panama City Beach",
    "Pandora",
    "Pangburn",
    "Panguitch",
    "Panhandle",
    "Panola",
    "Panora",
    "Panorama City",
    "Pansey",
    "Pantego",
    "Paola",
    "Paoli",
    "Paoli",
    "Paoli",
    "Paoli",
    "Paonia",
    "Papillion",
    "Parachute",
    "Paradis",
    "Paradise",
    "Paradise",
    "Paradise",
    "Paradise",
    "Paradise",
    "Paradise Valley",
    "Paradise Valley",
    "Paradox",
    "Paragon",
    "Paragould",
    "Paramount",
    "Paramus",
    "Pardeeville",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Paris",
    "Parish",
    "Park",
    "Park",
    "Park City",
    "Park City",
    "Park Falls",
    "Park Forest",
    "Park Hill",
    "Park Hills",
    "Park Rapids",
    "Park Ridge",
    "Park Ridge",
    "Park River",
    "Parker",
    "Parker",
    "Parker",
    "Parker",
    "Parker",
    "Parker",
    "Parker City",
    "Parker Ford",
    "Parkers Lake",
    "Parkers Prairie",
    "Parkersburg",
    "Parkersburg",
    "Parkersburg",
    "Parkesburg",
    "Parkman",
    "Parksley",
    "Parkston",
    "Parkton",
    "Parkton",
    "Parkville",
    "Parlier",
    "Parlin",
    "Parma",
    "Parma",
    "Parma",
    "Parnell",
    "Parowan",
    "Parrish",
    "Parrish",
    "Parrottsville",
    "Parshall",
    "Parsippany",
    "Parsons",
    "Parsons",
    "Parsons",
    "Parsonsburg",
    "Parsonsfield",
    "Partlow",
    "Partridge",
    "Partridge",
    "Pasadena",
    "Pasadena",
    "Pasadena",
    "Pascagoula",
    "Pasco",
    "Pascoag",
    "Paso Robles",
    "Pass Christian",
    "Passaic",
    "Passumpsic",
    "Pataskala",
    "Patchogue",
    "Paterson",
    "Patoka",
    "Patoka",
    "Paton",
    "Patrick",
    "Patrick Springs",
    "Patriot",
    "Patten",
    "Patterson",
    "Patterson",
    "Patterson",
    "Patterson",
    "Patterson",
    "Pattersonville",
    "Pattison",
    "Pattison",
    "Patton",
    "Patton",
    "Patton",
    "Pattonsburg",
    "Pattonville",
    "Paul",
    "Paul Smiths",
    "Paulden",
    "Paulding",
    "Paulina",
    "Pauline",
    "Paullina",
    "Pauls Valley",
    "Paulsboro",
    "Pauma Valley",
    "Pavilion",
    "Pavillion",
    "Pavo",
    "Paw Paw",
    "Paw Paw",
    "Paw Paw",
    "Pawcatuck",
    "Pawhuska",
    "Pawlet",
    "Pawleys Island",
    "Pawling",
    "Pawnee",
    "Pawnee",
    "Pawnee City",
    "Pawtucket",
    "Paxico",
    "Paxinos",
    "Paxton",
    "Paxton",
    "Paxton",
    "Paxton",
    "Payette",
    "Payne",
    "Paynesville",
    "Payneville",
    "Payson",
    "Payson",
    "Payson",
    "Pea Ridge",
    "Peabody",
    "Peabody",
    "Peace Dale",
    "Peach Bottom",
    "Peach Creek",
    "Peach Orchard",
    "Peach Springs",
    "Peachland",
    "Peachtree City",
    "Peak",
    "Peaks Island",
    "Peapack",
    "Pearblossom",
    "Pearce",
    "Pearcy",
    "Pearisburg",
    "Pearl",
    "Pearl City",
    "Pearl City, Manana",
    "Pearl River",
    "Pearl River",
    "Pearland",
    "Pearlington",
    "Pearsall",
    "Pearson",
    "Pearson",
    "Pease",
    "Pebble Beach",
    "Pecatonica",
    "Peck",
    "Peck",
    "Peck",
    "Pecks Mill",
    "Peckville",
    "Peconic",
    "Pecos",
    "Pecos",
    "Peculiar",
    "Pedricktown",
    "Pedro Bay",
    "Peebles",
    "Peekskill",
    "Peel",
    "Peetz",
    "Peever",
    "Peggs",
    "Pegram",
    "Pekin",
    "Pelahatchie",
    "Pelham",
    "Pelham",
    "Pelham",
    "Pelham",
    "Pelham",
    "Pelham",
    "Pelican",
    "Pelican Lake",
    "Pelican Rapids",
    "Pelion",
    "Pelkie",
    "Pell City",
    "Pella",
    "Pellston",
    "Pelzer",
    "Pemberton",
    "Pemberville",
    "Pembina",
    "Pembine",
    "Pembroke",
    "Pembroke",
    "Pembroke",
    "Pembroke",
    "Pembroke",
    "Pembroke",
    "Pembroke Pines",
    "Pen Argyl",
    "Penasco",
    "Pender",
    "Pendergrass",
    "Pendleton",
    "Pendleton",
    "Pendleton",
    "Pendleton",
    "Penelope",
    "Penfield",
    "Penfield",
    "Pengilly",
    "Penhook",
    "Peninsula",
    "Penitas",
    "Penn",
    "Penn Laird",
    "Penn Run",
    "Penn Valley",
    "Penn Yan",
    "Pennellville",
    "Penney Farms",
    "Penngrove",
    "Pennington",
    "Pennington",
    "Pennington Gap",
    "Pennock",
    "Penns Grove",
    "Penns Park",
    "Pennsauken Township",
    "Pennsboro",
    "Pennsburg",
    "Pennsville Township",
    "Pennsylvania Furnace",
    "Pennville",
    "Penokee",
    "Penrose",
    "Penrose",
    "Penryn",
    "Pensacola",
    "Pentress",
    "Pentwater",
    "Peoria",
    "Peoria",
    "Peoria Heights",
    "Peosta",
    "Peotone",
    "Pepperell",
    "Pequannock",
    "Pequea",
    "Pequot Lakes",
    "Peralta",
    "Percy",
    "Perdido",
    "Perham",
    "Peridot",
    "Perkasie",
    "Perkins",
    "Perkinston",
    "Perkinsville",
    "Perkiomenville",
    "Perley",
    "Perrineville",
    "Perrinton",
    "Perris",
    "Perronville",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry",
    "Perry Hall",
    "Perry Point",
    "Perryman",
    "Perryopolis",
    "Perrysburg",
    "Perryton",
    "Perryville",
    "Perryville",
    "Perryville",
    "Perryville",
    "Pershing",
    "Persia",
    "Perth Amboy",
    "Peru",
    "Peru",
    "Peru",
    "Peru",
    "Peru",
    "Pescadero",
    "Peshastin",
    "Peshtigo",
    "Pesotum",
    "Petal",
    "Petaluma",
    "Peterborough",
    "Peterman",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersburg",
    "Petersham",
    "Peterson",
    "Peterstown",
    "Petoskey",
    "Petroleum",
    "Petrolia",
    "Petrolia",
    "Petros",
    "Pettigrew",
    "Pevely",
    "Pewamo",
    "Pewaukee",
    "Pewee Valley",
    "Peyton",
    "Pfafftown",
    "Pflugerville",
    "Pharr",
    "Phelan",
    "Phelps",
    "Phelps",
    "Phenix",
    "Phenix City",
    "Phil Campbell",
    "Philadelphia",
    "Philadelphia",
    "Philadelphia",
    "Philadelphia",
    "Philadelphia",
    "Philip",
    "Philipp",
    "Philippi",
    "Philipsburg",
    "Philipsburg",
    "Phillips",
    "Phillips",
    "Phillips",
    "Phillipsburg",
    "Phillipsburg",
    "Phillipsburg",
    "Philo",
    "Philo",
    "Philo",
    "Philomath",
    "Philpot",
    "Phippsburg",
    "Phoenicia",
    "Phoenix",
    "Phoenix",
    "Phoenix",
    "Phoenix",
    "Phoenixville",
    "Piasa",
    "Picabo",
    "Picayune",
    "Pickens",
    "Pickens",
    "Pickerington",
    "Pickett",
    "Pickstown",
    "Pickton",
    "Pico Rivera",
    "Picture Rocks",
    "Piedmont",
    "Piedmont",
    "Piedmont",
    "Piedmont",
    "Piedmont",
    "Piedmont",
    "Pierce",
    "Pierce",
    "Pierce",
    "Pierce City",
    "Pierceton",
    "Piercy",
    "Piermont",
    "Pierpont",
    "Pierpont",
    "Pierre",
    "Pierre Part",
    "Pierron",
    "Pierson",
    "Pierson",
    "Pierson",
    "Pierz",
    "Piffard",
    "Pigeon",
    "Pigeon Forge",
    "Piggott",
    "Pike Road",
    "Pikesville",
    "Piketon",
    "Pikeville",
    "Pikeville",
    "Pikeville",
    "Pillager",
    "Pilot",
    "Pilot Grove",
    "Pilot Hill",
    "Pilot Knob",
    "Pilot Mound",
    "Pilot Mountain",
    "Pilot Point",
    "Pilot Rock",
    "Pilot Station",
    "Pima",
    "Pima County",
    "Pimento",
    "Pinckney",
    "Pinckneyville",
    "Pinconning",
    "Pine",
    "Pine Beach",
    "Pine Bluff",
    "Pine Bluffs",
    "Pine Brook",
    "Pine Bush",
    "Pine City",
    "Pine City",
    "Pine Forge",
    "Pine Grove",
    "Pine Grove",
    "Pine Grove",
    "Pine Grove Mills",
    "Pine Hill",
    "Pine Hill",
    "Pine Island",
    "Pine Island",
    "Pine Knot",
    "Pine Lake",
    "Pine Mountain",
    "Pine Mountain Club",
    "Pine Plains",
    "Pine Ridge",
    "Pine River",
    "Pine Top",
    "Pine Valley",
    "Pine Valley",
    "Pinebluff",
    "Pinecrest",
    "Pinedale",
    "Pinedale",
    "Pinehill",
    "Pinehurst",
    "Pinehurst",
    "Pinehurst",
    "Pinellas Park",
    "Pineola",
    "Pinesdale",
    "Pinetop-Lakeside",
    "Pinetops",
    "Pinetown",
    "Pineville",
    "Pineville",
    "Pineville",
    "Pineville",
    "Pineville",
    "Pineville",
    "Pinewood",
    "Piney Creek",
    "Piney Flats",
    "Piney View",
    "Piney Woods",
    "Pingree",
    "Pink Hill",
    "Pinnacle",
    "Pinola",
    "Pinole",
    "Pinon",
    "Pinon Hills",
    "Pinson",
    "Pinson",
    "Pioche",
    "Pioneer",
    "Pioneer",
    "Pioneer",
    "Pioneertown",
    "Pipe Creek",
    "Piper City",
    "Pipersville",
    "Pipestone",
    "Piqua",
    "Piqua",
    "Pirtleville",
    "Piscataway",
    "Piseco",
    "Pisgah",
    "Pisgah",
    "Pisgah Forest",
    "Pismo Beach",
    "Pitcairn",
    "Pitcher",
    "Pitkin",
    "Pitman",
    "Pitman",
    "Pittsboro",
    "Pittsboro",
    "Pittsboro",
    "Pittsburg",
    "Pittsburg",
    "Pittsburg",
    "Pittsburg",
    "Pittsburgh",
    "Pittsfield",
    "Pittsfield",
    "Pittsfield",
    "Pittsfield",
    "Pittsfield",
    "Pittsfield",
    "Pittsford",
    "Pittsford",
    "Pittsford",
    "Pittston",
    "Pittstown",
    "Pittsview",
    "Pittsville",
    "Pittsville",
    "Pixley",
    "Placedo",
    "Placentia",
    "Placerville",
    "Placida",
    "Placitas",
    "Plain",
    "Plain City",
    "Plain Dealing",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plainfield",
    "Plains",
    "Plains",
    "Plains",
    "Plains",
    "Plainsboro",
    "Plainview",
    "Plainview",
    "Plainview",
    "Plainview",
    "Plainview",
    "Plainville",
    "Plainville",
    "Plainville",
    "Plainville",
    "Plainville",
    "Plainville",
    "Plainwell",
    "Plaistow",
    "Planada",
    "Plankinton",
    "Plano",
    "Plano",
    "Plant City",
    "Plantation",
    "Plantersville",
    "Plantersville",
    "Plantsville",
    "Plaquemine",
    "Plato",
    "Platte",
    "Platte City",
    "Plattekill",
    "Plattenville",
    "Platteville",
    "Platteville",
    "Plattsburg",
    "Plattsburgh",
    "Plattsmouth",
    "Playa del Rey",
    "Plaza",
    "Pleasant City",
    "Pleasant Dale",
    "Pleasant Garden",
    "Pleasant Grove",
    "Pleasant Grove",
    "Pleasant Grove",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hill",
    "Pleasant Hope",
    "Pleasant Lake",
    "Pleasant Lake",
    "Pleasant Mount",
    "Pleasant Plain",
    "Pleasant Plains",
    "Pleasant Prairie",
    "Pleasant Ridge",
    "Pleasant Shade",
    "Pleasant Unity",
    "Pleasant Valley",
    "Pleasant View",
    "Pleasant View",
    "Pleasanton",
    "Pleasanton",
    "Pleasanton",
    "Pleasantville",
    "Pleasantville",
    "Pleasantville",
    "Pleasantville",
    "Pleasantville",
    "Pleasureville",
    "Plentywood",
    "Plover",
    "Plum Branch",
    "Plum City",
    "Plumerville",
    "Plummer",
    "Plummer",
    "Plummers Landing",
    "Plumsteadville",
    "Plumville",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth",
    "Plymouth Meeting",
    "Plympton",
    "Poca",
    "Pocahontas",
    "Pocahontas",
    "Pocahontas",
    "Pocasset",
    "Pocatello",
    "Pocola",
    "Pocomoke City",
    "Pocono Pines",
    "Pocono Summit",
    "Poestenkill",
    "Point",
    "Point Arena",
    "Point Comfort",
    "Point Harbor",
    "Point Hope",
    "Point Lay",
    "Point Lookout",
    "Point of Rocks",
    "Point of Rocks",
    "Point Pleasant",
    "Point Pleasant",
    "Point Pleasant Beach",
    "Point Reyes Station",
    "Point Roberts",
    "Pointblank",
    "Polacca",
    "Poland",
    "Poland",
    "Poland",
    "Polk",
    "Polk",
    "Polk City",
    "Polk City",
    "Polkton",
    "Polkville",
    "Pollard",
    "Pollock",
    "Pollock",
    "Pollock",
    "Pollock Pines",
    "Pollocksville",
    "Pollok",
    "Polo",
    "Polo",
    "Polson",
    "Pomaria",
    "Pomerene",
    "Pomeroy",
    "Pomeroy",
    "Pomeroy",
    "Pomfret",
    "Pomfret",
    "Pomfret Center",
    "Pomona",
    "Pomona",
    "Pomona",
    "Pomona",
    "Pomona",
    "Pomona Park",
    "Pompano Beach",
    "Pompey",
    "Pompton Lakes",
    "Pompton Plains",
    "Ponca",
    "Ponca",
    "Ponca City",
    "Ponce de Leon",
    "Ponchatoula",
    "Pond Creek",
    "Ponder",
    "Ponderay",
    "Ponemah",
    "Poneto",
    "Ponsford",
    "Ponte Vedra",
    "Ponte Vedra Beach",
    "Pontiac",
    "Pontiac",
    "Pontotoc",
    "Pooler",
    "Poolesville",
    "Poolville",
    "Pope",
    "Pope Valley",
    "Poplar",
    "Poplar Bluff",
    "Poplar Branch",
    "Poplar Grove",
    "Poplar Grove",
    "Poplarville",
    "Poquoson",
    "Porcupine",
    "Port Allegany",
    "Port Allen",
    "Port Angeles",
    "Port Aransas",
    "Port Arthur",
    "Port Austin",
    "Port Barre",
    "Port Bolivar",
    "Port Byron",
    "Port Byron",
    "Port Carbon",
    "Port Charlotte",
    "Port Chester",
    "Port Clinton",
    "Port Crane",
    "Port Deposit",
    "Port Edwards",
    "Port Elizabeth",
    "Port Gibson",
    "Port Hadlock",
    "Port Henry",
    "Port Hope",
    "Port Hueneme",
    "Port Huron",
    "Port Isabel",
    "Port Jefferson",
    "Port Jefferson Station",
    "Port Jervis",
    "Port Lavaca",
    "Port Leyden",
    "Port Ludlow",
    "Port Mansfield",
    "Port Matilda",
    "Port Monmouth",
    "Port Murray",
    "Port Neches",
    "Port Norris",
    "Port Orange",
    "Port Orchard",
    "Port Orford",
    "Port Penn",
    "Port Reading",
    "Port Republic",
    "Port Republic",
    "Port Republic",
    "Port Richey",
    "Port Royal",
    "Port Royal",
    "Port Royal",
    "Port Saint Joe",
    "Port Saint Lucie",
    "Port Salerno",
    "Port Sanilac",
    "Port Tobacco",
    "Port Townsend",
    "Port Trevorton",
    "Port Washington",
    "Port Washington",
    "Port Washington",
    "Portage",
    "Portage",
    "Portage",
    "Portage",
    "Portage",
    "Portageville",
    "Portal",
    "Portal",
    "Portales",
    "Porter",
    "Porter",
    "Porter",
    "Porter Corners",
    "Portersville",
    "Porterville",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portland",
    "Portola",
    "Portola Valley",
    "Portsmouth",
    "Portsmouth",
    "Portsmouth",
    "Portsmouth",
    "Portsmouth",
    "Portville",
    "Porum",
    "Posen",
    "Posen",
    "Poseyville",
    "Post",
    "Post Falls",
    "Post Mills",
    "Poston",
    "Postville",
    "Poteau",
    "Poteet",
    "Poth",
    "Potlatch",
    "Potomac",
    "Potomac",
    "Potomac",
    "Potosi",
    "Potosi",
    "Potsdam",
    "Potter",
    "Potter Valley",
    "Pottersville",
    "Pottersville",
    "Potterville",
    "Potts Camp",
    "Pottsboro",
    "Pottstown",
    "Pottsville",
    "Pottsville",
    "Potwin",
    "Poughkeepsie",
    "Poughquag",
    "Poulan",
    "Poulsbo",
    "Poultney",
    "Pound",
    "Pound",
    "Pound Ridge",
    "Pounding Mill",
    "Poway",
    "Powder Springs",
    "Powder Springs",
    "Powderly",
    "Powell",
    "Powell",
    "Powell",
    "Powell",
    "Powell Butte",
    "Powellsville",
    "Powellton",
    "Powers",
    "Powers Lake",
    "Powhatan",
    "Powhatan",
    "Powhatan Point",
    "Pownal",
    "Poyen",
    "Poynette",
    "Poynor",
    "Poyntelle",
    "Prague",
    "Prague",
    "Prairie",
    "Prairie City",
    "Prairie City",
    "Prairie du Chien",
    "Prairie du Rocher",
    "Prairie du Sac",
    "Prairie Farm",
    "Prairie Grove",
    "Prairie View",
    "Prairie View",
    "Prairie Village",
    "Prairieton",
    "Prairieville",
    "Prather",
    "Pratt",
    "Pratt",
    "Pratts",
    "Prattsburgh",
    "Prattsville",
    "Prattville",
    "Preble",
    "Preemption",
    "Premier",
    "Prentiss",
    "Prescott",
    "Prescott",
    "Prescott",
    "Prescott",
    "Prescott",
    "Prescott",
    "Prescott",
    "Prescott Valley",
    "Presho",
    "Presidio",
    "Presque Isle",
    "Presque Isle",
    "Presto",
    "Preston",
    "Preston",
    "Preston",
    "Preston",
    "Preston",
    "Preston",
    "Preston City",
    "Preston Hollow",
    "Prestonsburg",
    "Prewitt",
    "Price",
    "Prichard",
    "Pride",
    "Priest River",
    "Primghar",
    "Primm Springs",
    "Primus",
    "Prince Frederick",
    "Prince George",
    "Princess Anne",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton",
    "Princeton Junction",
    "Princeville",
    "Princeville",
    "Princewick",
    "Prineville",
    "Prinsburg",
    "Printer",
    "Prior Lake",
    "Pritchett",
    "Procious",
    "Proctor",
    "Proctor",
    "Proctor",
    "Proctor",
    "Proctor",
    "Proctorville",
    "Progreso",
    "Prompton",
    "Prophetstown",
    "Prospect",
    "Prospect",
    "Prospect",
    "Prospect",
    "Prospect",
    "Prospect",
    "Prospect",
    "Prospect Harbor",
    "Prospect Heights",
    "Prospect Park",
    "Prosper",
    "Prosperity",
    "Prosperity",
    "Prosser",
    "Protection",
    "Protivin",
    "Providence",
    "Providence",
    "Providence",
    "Providence",
    "Providence Forge",
    "Provincetown",
    "Provo",
    "Prudenville",
    "Prudhoe Bay",
    "Pryor",
    "Pueblo",
    "Puerto Rico",
    "Pukalani",
    "Pukwana",
    "Pulaski",
    "Pulaski",
    "Pulaski",
    "Pulaski",
    "Pulaski",
    "Pulaski",
    "Pullman",
    "Pullman",
    "Pullman",
    "Pungoteague",
    "Punta Gorda",
    "Punxsutawney",
    "Purcell",
    "Purcellville",
    "Purchase",
    "Purdin",
    "Purdon",
    "Purdy",
    "Purdys",
    "Purgitsville",
    "Purlear",
    "Purling",
    "Purvis",
    "Puryear",
    "Put-in-Bay",
    "Putnam",
    "Putnam",
    "Putnam Station",
    "Putnam Valley",
    "Putney",
    "Puyallup",
    "Pylesville",
    "Quail",
    "Quakake",
    "Quaker City",
    "Quaker Hill",
    "Quakertown",
    "Quakertown",
    "Quality",
    "Quanah",
    "Quantico",
    "Quantico",
    "Quapaw",
    "Quarryville",
    "Quartzsite",
    "Quasqueton",
    "Quebeck",
    "Quechee",
    "Queen Anne",
    "Queen City",
    "Queen City",
    "Queen Creek",
    "Queens Village",
    "Queensbury",
    "Queenstown",
    "Quemado",
    "Questa",
    "Quilcene",
    "Quimby",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quincy",
    "Quinebaug",
    "Quinlan",
    "Quinnesec",
    "Quinter",
    "Quinton",
    "Quinton",
    "Quinton",
    "Quinwood",
    "Quitman",
    "Quitman",
    "Quitman",
    "Quitman",
    "Quitman",
    "Qulin",
    "Quogue",
    "Raccoon",
    "Raceland",
    "Racine",
    "Racine",
    "Racine",
    "Racine",
    "Radcliff",
    "Radcliffe",
    "Radford",
    "Radisson",
    "Radnor",
    "Raeford",
    "Ragland",
    "Rahway",
    "Rainbow",
    "Rainbow City",
    "Rainelle",
    "Rainier",
    "Rainier",
    "Rainsville",
    "Raleigh",
    "Raleigh",
    "Raleigh",
    "Ralls",
    "Ralph",
    "Ralston",
    "Ramah",
    "Ramah",
    "Ramer",
    "Ramey",
    "Ramona",
    "Ramona",
    "Ramona",
    "Ramseur",
    "Ramsey",
    "Ramsey",
    "Ramsey",
    "Ranburne",
    "Ranchita",
    "Rancho Cordova",
    "Rancho Cucamonga",
    "Rancho Mirage",
    "Rancho Palos Verdes",
    "Rancho Santa Fe",
    "Rancho Santa Margarita",
    "Ranchos de Taos",
    "Rancocas",
    "Randall",
    "Randallstown",
    "Randle",
    "Randleman",
    "Randlett",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph",
    "Randolph Center",
    "Randolph Township",
    "Random Lake",
    "Rangely",
    "Ranger",
    "Ranger",
    "Ranger",
    "Rankin",
    "Rankin",
    "Ransom",
    "Ransom",
    "Ransom",
    "Ransom Canyon",
    "Ransomville",
    "Ranson",
    "Rantoul",
    "Rantoul",
    "Raphine",
    "Rapid City",
    "Rapid City",
    "Rapid River",
    "Rapidan",
    "Raquette Lake",
    "Raritan",
    "Rathdrum",
    "Ratliff City",
    "Raton",
    "Rattan",
    "Ravalli",
    "Raven",
    "Ravena",
    "Ravencliff",
    "Ravenden",
    "Ravenel",
    "Ravenna",
    "Ravenna",
    "Ravenna",
    "Ravenna",
    "Ravenna",
    "Ravensdale",
    "Ravenswood",
    "Ravenwood",
    "Ravia",
    "Rawlings",
    "Rawlings",
    "Rawlins",
    "Rawson",
    "Ray",
    "Ray",
    "Ray Center",
    "Ray City",
    "Rayland",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymond",
    "Raymondville",
    "Raymondville",
    "Raymore",
    "Rayne",
    "Raynham",
    "Rayville",
    "Rayville",
    "Raywick",
    "Readfield",
    "Reading",
    "Reading",
    "Reading",
    "Reading",
    "Reading",
    "Readlyn",
    "Readsboro",
    "Readyville",
    "Reamstown",
    "Reardan",
    "Rebecca",
    "Rebersburg",
    "Rector",
    "Rector",
    "Red Bank",
    "Red Banks",
    "Red Bay",
    "Red Bluff",
    "Red Boiling Springs",
    "Red Bud",
    "Red Cloud",
    "Red Creek",
    "Red Hill",
    "Red Hook",
    "Red House",
    "Red Jacket",
    "Red Lake Falls",
    "Red Level",
    "Red Lion",
    "Red Lodge",
    "Red Oak",
    "Red Oak",
    "Red Oak",
    "Red River",
    "Red Rock",
    "Red Rock",
    "Red Springs",
    "Red Wing",
    "Redan",
    "Redby",
    "Reddick",
    "Reddick",
    "Redding",
    "Redding",
    "Redding Ridge",
    "Redfield",
    "Redfield",
    "Redfield",
    "Redfield",
    "Redfield",
    "Redford",
    "Redford",
    "Redfox",
    "Redkey",
    "Redlands",
    "Redmon",
    "Redmond",
    "Redmond",
    "Redmond",
    "Redondo Beach",
    "Redwater",
    "Redway",
    "Redwood",
    "Redwood",
    "Redwood",
    "Redwood City",
    "Redwood Estates",
    "Redwood Falls",
    "Redwood Valley",
    "Reed City",
    "Reed Point",
    "Reedley",
    "Reeds",
    "Reeds Spring",
    "Reedsburg",
    "Reedsport",
    "Reedsville",
    "Reedsville",
    "Reedsville",
    "Reedville",
    "Reedy",
    "Reelsville",
    "Reese",
    "Reeseville",
    "Reevesville",
    "Reform",
    "Refugio",
    "Regent",
    "Register",
    "Rego Park",
    "Rehoboth",
    "Rehoboth Beach",
    "Reidsville",
    "Reidsville",
    "Reidville",
    "Reinbeck",
    "Reinholds",
    "Reisterstown",
    "Reklaw",
    "Reliance",
    "Rembert",
    "Rembrandt",
    "Remer",
    "Remington",
    "Remlap",
    "Remote",
    "Remsen",
    "Remsen",
    "Remsenburg",
    "Remus",
    "Renfrew",
    "Renner",
    "Reno",
    "Reno",
    "Renovo",
    "Rensselaer",
    "Rensselaer",
    "Rensselaer Falls",
    "Renton",
    "Rentz",
    "Renville",
    "Renwick",
    "Repton",
    "Republic",
    "Republic",
    "Republic",
    "Republic",
    "Republic",
    "Resaca",
    "Reseda",
    "Reserve",
    "Reserve",
    "Reston",
    "Revere",
    "Revere",
    "Revillo",
    "Rex",
    "Rex",
    "Rexburg",
    "Rexford",
    "Rexmont",
    "Rexville",
    "Reydell",
    "Reydon",
    "Reynolds",
    "Reynolds",
    "Reynolds",
    "Reynoldsburg",
    "Reynoldsville",
    "Rhame",
    "Rheems",
    "Rhine",
    "Rhinebeck",
    "Rhineland",
    "Rhinelander",
    "Rhoadesville",
    "Rhodelia",
    "Rhodell",
    "Rhodes",
    "Rhodesdale",
    "Rhodhiss",
    "Rhome",
    "Rialto",
    "Rib Lake",
    "Ribera",
    "Rice",
    "Rice",
    "Rice Lake",
    "Rices Landing",
    "Riceville",
    "Riceville",
    "Rich Creek",
    "Rich Hill",
    "Rich Square",
    "Richards",
    "Richardson",
    "Richardton",
    "Richboro",
    "Richburg",
    "Richeyville",
    "Richfield",
    "Richfield",
    "Richfield",
    "Richfield",
    "Richfield",
    "Richfield",
    "Richfield Springs",
    "Richford",
    "Richford",
    "Richgrove",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland",
    "Richland Center",
    "Richlands",
    "Richlands",
    "Richlandtown",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond Hill",
    "Richmond Hill",
    "Richton",
    "Richton Park",
    "Richvale",
    "Richview",
    "Richwood",
    "Richwood",
    "Rickman",
    "Rickreall",
    "Riddle",
    "Riddleton",
    "Riderwood",
    "Ridge",
    "Ridge",
    "Ridge Farm",
    "Ridge Spring",
    "Ridgecrest",
    "Ridgecrest",
    "Ridgedale",
    "Ridgefield",
    "Ridgefield",
    "Ridgefield",
    "Ridgefield Park",
    "Ridgeland",
    "Ridgeland",
    "Ridgeland",
    "Ridgeley",
    "Ridgely",
    "Ridgely",
    "Ridgeview",
    "Ridgeville",
    "Ridgeville",
    "Ridgeway",
    "Ridgeway",
    "Ridgeway",
    "Ridgeway",
    "Ridgeway",
    "Ridgewood",
    "Ridgway",
    "Ridgway",
    "Ridgway",
    "Ridley Park",
    "Ridott",
    "Riegelsville",
    "Riegelwood",
    "Rienzi",
    "Riesel",
    "Rifle",
    "Riga",
    "Rigby",
    "Riggins",
    "Riley",
    "Rillton",
    "Rimersburg",
    "Rimforest",
    "Rimrock",
    "Rinard",
    "Rincon",
    "Rindge",
    "Riner",
    "Rineyville",
    "Ringgold",
    "Ringgold",
    "Ringgold",
    "Ringgold",
    "Ringle",
    "Ringling",
    "Ringoes",
    "Ringsted",
    "Ringtown",
    "Ringwood",
    "Ringwood",
    "Ringwood",
    "Rio",
    "Rio",
    "Rio",
    "Rio Dell",
    "Rio Grande",
    "Rio Grande",
    "Rio Grande City",
    "Rio Hondo",
    "Rio Linda",
    "Rio Nido",
    "Rio Rancho",
    "Rio Rico",
    "Rio Verde",
    "Rio Vista",
    "Rio Vista",
    "Ripley",
    "Ripley",
    "Ripley",
    "Ripley",
    "Ripley",
    "Ripon",
    "Ripon",
    "Rippey",
    "Ripplemead",
    "Ripton",
    "Rising City",
    "Rising Fawn",
    "Rising Star",
    "Rising Sun",
    "Rising Sun",
    "Risingsun",
    "Rison",
    "Rittman",
    "Ritzville",
    "Riva",
    "River Edge",
    "River Falls",
    "River Forest",
    "River Grove",
    "River Pines",
    "River Rouge",
    "Riverbank",
    "Riverdale",
    "Riverdale",
    "Riverdale",
    "Riverdale",
    "Riverdale",
    "Riverdale",
    "Riverdale",
    "Riverdale Hills",
    "Riverhead",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside",
    "Riverside County",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverton",
    "Riverview",
    "Riverview",
    "Rives",
    "Rives Junction",
    "Rivesville",
    "Riviera",
    "Rixeyville",
    "Roach",
    "Roachdale",
    "Roan Mountain",
    "Roann",
    "Roanoke",
    "Roanoke",
    "Roanoke",
    "Roanoke",
    "Roanoke",
    "Roanoke Rapids",
    "Roaring Branch",
    "Roaring River",
    "Roaring Spring",
    "Robards",
    "Robbins",
    "Robbins",
    "Robbins",
    "Robbinsville",
    "Robersonville",
    "Robert",
    "Robert Lee",
    "Roberta",
    "Roberts",
    "Roberts",
    "Robertsdale",
    "Robertsdale",
    "Robertson",
    "Robertsville",
    "Robesonia",
    "Robins",
    "Robinson",
    "Robinson",
    "Robinson Creek",
    "Robinsonville",
    "Robstown",
    "Roby",
    "Roca",
    "Rochdale",
    "Rochelle",
    "Rochelle",
    "Rochelle",
    "Rochelle Park",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rochester",
    "Rociada",
    "Rock",
    "Rock",
    "Rock Creek",
    "Rock Falls",
    "Rock Hall",
    "Rock Hill",
    "Rock Hill",
    "Rock Island",
    "Rock Island",
    "Rock Port",
    "Rock Rapids",
    "Rock River",
    "Rock Spring",
    "Rock Springs",
    "Rock Springs",
    "Rock Tavern",
    "Rock Valley",
    "Rock View",
    "Rockaway",
    "Rockaway Beach",
    "Rockaway Beach",
    "Rockaway Park",
    "Rockbridge",
    "Rockdale",
    "Rockfield",
    "Rockford",
    "Rockford",
    "Rockford",
    "Rockford",
    "Rockford",
    "Rockford",
    "Rockford",
    "Rockholds",
    "Rockhouse",
    "Rockingham",
    "Rockland",
    "Rockland",
    "Rockland",
    "Rockland",
    "Rockland",
    "Rockledge",
    "Rocklin",
    "Rockmart",
    "Rockport",
    "Rockport",
    "Rockport",
    "Rockport",
    "Rockport",
    "Rockport",
    "Rockport",
    "Rocksprings",
    "Rockton",
    "Rockton",
    "Rockvale",
    "Rockvale",
    "Rockville",
    "Rockville",
    "Rockville",
    "Rockville",
    "Rockville",
    "Rockville",
    "Rockville Centre",
    "Rockwall",
    "Rockwell",
    "Rockwell",
    "Rockwell City",
    "Rockwood",
    "Rockwood",
    "Rockwood",
    "Rockwood",
    "Rocky",
    "Rocky Face",
    "Rocky Ford",
    "Rocky Gap",
    "Rocky Hill",
    "Rocky Mount",
    "Rocky Mount",
    "Rocky Point",
    "Rocky Point",
    "Rocky River",
    "Rodanthe",
    "Rodeo",
    "Rodeo",
    "Roderfield",
    "Rodman",
    "Rodney",
    "Roebling",
    "Roebuck",
    "Rogers",
    "Rogers",
    "Rogers",
    "Rogers",
    "Rogers City",
    "Rogersville",
    "Rogersville",
    "Rogersville",
    "Roggen",
    "Rogue River",
    "Rohnert Park",
    "Rohrersville",
    "Roland",
    "Roland",
    "Roland",
    "Rolesville",
    "Rolfe",
    "Roll",
    "Rolla",
    "Rolla",
    "Rolla",
    "Rolling Fork",
    "Rolling Meadows",
    "Rolling Prairie",
    "Rollingbay",
    "Rollingstone",
    "Rollinsford",
    "Roma",
    "Rome",
    "Rome",
    "Rome",
    "Rome",
    "Rome City",
    "Romeo",
    "Romeoville",
    "Romney",
    "Romney",
    "Romulus",
    "Romulus",
    "Ronald",
    "Ronan",
    "Ronceverte",
    "Ronda",
    "Ronkonkoma",
    "Ronks",
    "Roodhouse",
    "Roosevelt",
    "Roosevelt",
    "Roosevelt",
    "Roosevelt",
    "Rootstown",
    "Roper",
    "Ropesville",
    "Rosalia",
    "Rosalia",
    "Rosamond",
    "Rosburg",
    "Roscoe",
    "Roscoe",
    "Roscoe",
    "Roscommon",
    "Rose Bud",
    "Rose City",
    "Rose Creek",
    "Rose Hill",
    "Rose Hill",
    "Rose Hill",
    "Rose Hill",
    "Roseau",
    "Roseboro",
    "Rosebud",
    "Rosebud",
    "Rosebud",
    "Rosebud",
    "Roseburg",
    "Rosebush",
    "Rosedale",
    "Rosedale",
    "Rosedale",
    "Rosedale",
    "Rosedale",
    "Rosedale",
    "Roseland",
    "Roseland",
    "Roseland",
    "Roseland",
    "Roselle",
    "Roselle",
    "Roselle Park",
    "Rosemary Beach",
    "Rosemead",
    "Rosemont",
    "Rosemont",
    "Rosemount",
    "Rosenberg",
    "Rosendale",
    "Rosendale",
    "Rosenhayn",
    "Rosepine",
    "Roseville",
    "Roseville",
    "Roseville",
    "Roseville",
    "Roseville",
    "Rosewood",
    "Rosharon",
    "Rosholt",
    "Rosiclare",
    "Rosine",
    "Roslindale",
    "Roslyn",
    "Roslyn",
    "Roslyn",
    "Roslyn Heights",
    "Rosman",
    "Ross",
    "Rosser",
    "Rossford",
    "Rossiter",
    "Rossville",
    "Rossville",
    "Rossville",
    "Rossville",
    "Roswell",
    "Roswell",
    "Rotan",
    "Rothbury",
    "Rothsay",
    "Rothschild",
    "Rotonda",
    "Rotterdam Junction",
    "Rougemont",
    "Rough and Ready",
    "Roulette",
    "Round Hill",
    "Round Lake",
    "Round Lake",
    "Round Mountain",
    "Round Mountain",
    "Round Mountain",
    "Round O",
    "Round Rock",
    "Round Top",
    "Roundhill",
    "Roundup",
    "Rouses Point",
    "Rousseau",
    "Rowan",
    "Rowe",
    "Rowena",
    "Rowesville",
    "Rowland",
    "Rowland",
    "Rowland Heights",
    "Rowlesburg",
    "Rowlett",
    "Rowley",
    "Rowley",
    "Roxana",
    "Roxboro",
    "Roxbury",
    "Roxbury",
    "Roxbury",
    "Roxie",
    "Roy",
    "Roy",
    "Roy",
    "Roy",
    "Roy-Winifred Junction",
    "Royal",
    "Royal",
    "Royal",
    "Royal Center",
    "Royal City",
    "Royal Oak",
    "Royal Palm Beach",
    "Royalton",
    "Royalton",
    "Royersford",
    "Royse City",
    "Royston",
    "Rozet",
    "Rubicon",
    "Ruby",
    "Ruby",
    "Ruckersville",
    "Rudd",
    "Rudolph",
    "Rudyard",
    "Ruffin",
    "Ruffin",
    "Ruffs Dale",
    "Rugby",
    "Ruidoso",
    "Ruidoso Downs",
    "Rule",
    "Ruleville",
    "Rulo",
    "Rumford",
    "Rumford",
    "Rumney",
    "Rumsey",
    "Rumson",
    "Runge",
    "Runnells",
    "Runnemede",
    "Running Springs",
    "Rupert",
    "Rupert",
    "Rural Hall",
    "Rural Retreat",
    "Rural Ridge",
    "Rural Valley",
    "Rush",
    "Rush",
    "Rush Center",
    "Rush City",
    "Rush Hill",
    "Rush Valley",
    "Rushford",
    "Rushford",
    "Rushmore",
    "Rushville",
    "Rushville",
    "Rushville",
    "Rushville",
    "Rushville",
    "Rushville",
    "Rusk",
    "Ruskin",
    "Russell",
    "Russell",
    "Russell",
    "Russell",
    "Russell",
    "Russell",
    "Russell",
    "Russell Springs",
    "Russells Point",
    "Russellton",
    "Russellville",
    "Russellville",
    "Russellville",
    "Russellville",
    "Russellville",
    "Russellville",
    "Russellville",
    "Russia",
    "Russian Mission",
    "Russiaville",
    "Rustburg",
    "Ruston",
    "Ruth",
    "Ruther Glen",
    "Rutherford",
    "Rutherford",
    "Rutherford",
    "Rutherfordton",
    "Ruthton",
    "Ruthven",
    "Rutland",
    "Rutland",
    "Rutland",
    "Rutland",
    "Rutland",
    "Rutledge",
    "Rutledge",
    "Rutledge",
    "Ryan",
    "Rydal",
    "Ryde",
    "Rye",
    "Rye",
    "Rye",
    "Rye",
    "Sabattus",
    "Sabetha",
    "Sabillasville",
    "Sabin",
    "Sabina",
    "Sabinal",
    "Sabot",
    "Sabula",
    "Sac City",
    "Sacaton",
    "Sachse",
    "Sackets Harbor",
    "Saco",
    "Sacramento",
    "Sacramento",
    "Sacred Heart",
    "Saddle Brook",
    "Saddle River",
    "Sadieville",
    "Sadler",
    "Saegertown",
    "Safety Harbor",
    "Safford",
    "Sag Harbor",
    "Sagamore",
    "Sagamore Beach",
    "Sagaponack",
    "Saginaw",
    "Saginaw",
    "Sagle",
    "Saguache",
    "Sahuarita",
    "Sailor Springs",
    "Saint Agatha",
    "Saint Albans",
    "Saint Albans",
    "Saint Albans",
    "Saint Albans",
    "Saint Albans",
    "Saint Amant",
    "Saint Ann",
    "Saint Anne",
    "Saint Ansgar",
    "Saint Anthony",
    "Saint Anthony",
    "Saint Anthony",
    "Saint Bonaventure",
    "Saint Boniface",
    "Saint Bonifacius",
    "Saint Catharine",
    "Saint Charles",
    "Saint Charles",
    "Saint Charles",
    "Saint Charles",
    "Saint Charles",
    "Saint Clair",
    "Saint Clair",
    "Saint Clair",
    "Saint Clair",
    "Saint Clair Shores",
    "Saint Clairsville",
    "Saint Cloud",
    "Saint Cloud",
    "Saint Cloud",
    "Saint Croix Falls",
    "Saint David",
    "Saint David",
    "Saint Edward",
    "Saint Elizabeth",
    "Saint Elmo",
    "Saint Francis",
    "Saint Francis",
    "Saint Francis",
    "Saint Francis",
    "Saint Francisville",
    "Saint Francisville",
    "Saint Gabriel",
    "Saint George",
    "Saint George",
    "Saint George",
    "Saint George",
    "Saint Germain",
    "Saint Hedwig",
    "Saint Helen",
    "Saint Helena",
    "Saint Helens",
    "Saint Henry",
    "Saint Hilaire",
    "Saint Ignace",
    "Saint Ignatius",
    "Saint Jacob",
    "Saint James",
    "Saint James",
    "Saint James",
    "Saint James",
    "Saint James",
    "Saint James City",
    "Saint Joe",
    "Saint Joe",
    "Saint John",
    "Saint John",
    "Saint John",
    "Saint John",
    "Saint Johns",
    "Saint Johns",
    "Saint Johnsbury",
    "Saint Johnsville",
    "Saint Joseph",
    "Saint Joseph",
    "Saint Joseph",
    "Saint Joseph",
    "Saint Leo",
    "Saint Leonard",
    "Saint Libory",
    "Saint Libory",
    "Saint Louis",
    "Saint Louis Park",
    "Saint Louisville",
    "Saint Marie",
    "Saint Maries",
    "Saint Marks",
    "Saint Martin",
    "Saint Martinville",
    "Saint Mary",
    "Saint Marys",
    "Saint Marys",
    "Saint Marys",
    "Saint Marys",
    "Saint Marys",
    "Saint Matthews",
    "Saint Matthews",
    "Saint Meinrad",
    "Saint Michael",
    "Saint Michael",
    "Saint Michael",
    "Saint Michaels",
    "Saint Michaels",
    "Saint Nazianz",
    "Saint Onge",
    "Saint Paris",
    "Saint Paul",
    "Saint Paul",
    "Saint Paul",
    "Saint Paul",
    "Saint Paul",
    "Saint Paul",
    "Saint Paul Park",
    "Saint Pauls",
    "Saint Peter",
    "Saint Peter",
    "Saint Peters",
    "Saint Petersburg",
    "Saint Regis",
    "Saint Regis Falls",
    "Saint Robert",
    "Saint Simons Island",
    "Saint Stephen",
    "Saint Stephens",
    "Saint Thomas",
    "Saint Thomas",
    "Sainte Marie",
    "Salado",
    "Salamanca",
    "Sale City",
    "Sale Creek",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salem",
    "Salemburg",
    "Salesville",
    "Salida",
    "Salida",
    "Salina",
    "Salina",
    "Salina",
    "Salinas",
    "Saline",
    "Saline",
    "Salineville",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury",
    "Salisbury Mills",
    "Salix",
    "Salix",
    "Salkum",
    "Sallis",
    "Sallisaw",
    "Salmon",
    "Salome",
    "Salt Lake City",
    "Salt Lick",
    "Salt Point",
    "Salt Rock",
    "Salters",
    "Saltillo",
    "Saltillo",
    "Saltillo",
    "Salton City",
    "Saltsburg",
    "Saltville",
    "Saluda",
    "Saluda",
    "Saluda",
    "Salvisa",
    "Salyer",
    "Salyersville",
    "Sammamish",
    "Samoa",
    "Samson",
    "San Andreas",
    "San Angelo",
    "San Anselmo",
    "San Antonio",
    "San Antonio",
    "San Antonio",
    "San Augustine",
    "San Benito",
    "San Bernardino",
    "San Bernardino County",
    "San Bruno",
    "San Carlos",
    "San Carlos",
    "San Clemente",
    "San Cristobal",
    "San Diego",
    "San Diego",
    "San Dimas",
    "San Elizario",
    "San Felipe",
    "San Fernando",
    "San Fidel",
    "San Francisco",
    "San Gabriel",
    "San Geronimo",
    "San Gregorio",
    "San Jacinto",
    "San Joaquin",
    "San Jon",
    "San Jose",
    "San Jose",
    "San Jose",
    "San Juan",
    "San Juan Bautista",
    "San Juan Capistrano",
    "San Leandro",
    "San Lorenzo",
    "San Luis",
    "San Luis",
    "San Luis Obispo",
    "San Luis Rey Heights",
    "San Manuel",
    "San Marcos",
    "San Marcos",
    "San Marino",
    "San Martin",
    "San Mateo",
    "San Mateo",
    "San Miguel",
    "San Pablo",
    "San Pedro",
    "San Pierre",
    "San Rafael",
    "San Rafael",
    "San Ramon",
    "San Saba",
    "San Simeon",
    "San Simon",
    "San Ysidro",
    "Sanborn",
    "Sanborn",
    "Sanborn",
    "Sanbornton",
    "Sanbornville",
    "Sand Coulee",
    "Sand Creek",
    "Sand Creek",
    "Sand Fork",
    "Sand Lake",
    "Sand Lake",
    "Sand Point",
    "Sand Springs",
    "Sandborn",
    "Sanders",
    "Sanders",
    "Sanderson",
    "Sanderson",
    "Sandersville",
    "Sandia",
    "Sandia Park",
    "Sandoval",
    "Sandoval County",
    "Sandown",
    "Sandpoint",
    "Sandston",
    "Sandstone",
    "Sandusky",
    "Sandusky",
    "Sandwich",
    "Sandwich",
    "Sandy",
    "Sandy",
    "Sandy City",
    "Sandy Creek",
    "Sandy Hook",
    "Sandy Hook",
    "Sandy Hook",
    "Sandy Lake",
    "Sandy Ridge",
    "Sandy Ridge",
    "Sandy Spring",
    "Sandyville",
    "Sanford",
    "Sanford",
    "Sanford",
    "Sanford",
    "Sanger",
    "Sanger",
    "Sangerville",
    "Sanibel",
    "Santa Ana",
    "Santa Ana Heights",
    "Santa Anna",
    "Santa Barbara",
    "Santa Clara",
    "Santa Clara",
    "Santa Clara",
    "Santa Clarita",
    "Santa Claus",
    "Santa Cruz",
    "Santa Cruz",
    "Santa Fe",
    "Santa Fe",
    "Santa Fe",
    "Santa Fe Springs",
    "Santa Margarita",
    "Santa Maria",
    "Santa Monica",
    "Santa Paula",
    "Santa Rosa",
    "Santa Rosa",
    "Santa Rosa Beach",
    "Santa Teresa",
    "Santa Ynez",
    "Santa Ysabel",
    "Santaquin",
    "Santee",
    "Santee",
    "Santo",
    "Sapphire",
    "Sapulpa",
    "Sarah",
    "Sarah Ann",
    "Sarahsville",
    "Saraland",
    "Saranac",
    "Saranac Lake",
    "Sarasota",
    "Saratoga",
    "Saratoga",
    "Saratoga",
    "Saratoga Springs",
    "Saratoga Springs",
    "Sarcoxie",
    "Sardinia",
    "Sardis",
    "Sardis",
    "Sardis",
    "Sardis",
    "Sarepta",
    "Sargent",
    "Sarita",
    "Sarona",
    "Saronville",
    "Sartell",
    "Sarver",
    "Sasakwa",
    "Satanta",
    "Satartia",
    "Satellite Beach",
    "Satsop",
    "Satsuma",
    "Satsuma",
    "Saucier",
    "Saugatuck",
    "Saugerties",
    "Saugus",
    "Sauk Centre",
    "Sauk City",
    "Sauk Rapids",
    "Saukville",
    "Saulsbury",
    "Saulsville",
    "Sault Ste. Marie",
    "Saunderstown",
    "Saunemin",
    "Sauquoit",
    "Sausalito",
    "Savage",
    "Savage",
    "Savage",
    "Savanna",
    "Savanna",
    "Savannah",
    "Savannah",
    "Savannah",
    "Savannah",
    "Savonburg",
    "Savoy",
    "Savoy",
    "Savoy",
    "Sawyer",
    "Sawyer",
    "Sawyer",
    "Sawyer",
    "Saxon",
    "Saxonburg",
    "Saxton",
    "Saxtons River",
    "Saybrook",
    "Saylorsburg",
    "Sayre",
    "Sayre",
    "Sayreville",
    "Sayville",
    "Scales Mound",
    "Scammon Bay",
    "Scandia",
    "Scandia",
    "Scandinavia",
    "Scappoose",
    "Scarborough",
    "Scarbro",
    "Scarsdale",
    "Scarville",
    "Scenery Hill",
    "Schaefferstown",
    "Schaghticoke",
    "Schaller",
    "Schaumburg",
    "Schellsburg",
    "Schenectady",
    "Schenevus",
    "Schenley",
    "Schererville",
    "Schertz",
    "Schiller Park",
    "Schlater",
    "Schleswig",
    "Schnecksville",
    "Schneider",
    "Schodack Landing",
    "Schoenchen",
    "Schofield",
    "Schoharie",
    "Schoolcraft",
    "Schroon Lake",
    "Schulenburg",
    "Schulter",
    "Schurz",
    "Schuyler",
    "Schuyler",
    "Schuylerville",
    "Schuylkill Haven",
    "Schwenksville",
    "Science Hill",
    "Scio",
    "Scio",
    "Sciota",
    "Sciota",
    "Scipio",
    "Scipio Center",
    "Scituate",
    "Scobey",
    "Scotch Plains",
    "Scotia",
    "Scotland",
    "Scotland",
    "Scotland",
    "Scotland Neck",
    "Scotrun",
    "Scott",
    "Scott",
    "Scott",
    "Scott Air Force Base",
    "Scott City",
    "Scott City",
    "Scott Depot",
    "Scottdale",
    "Scottdale",
    "Scottown",
    "Scotts",
    "Scotts Hill",
    "Scotts Mills",
    "Scotts Valley",
    "Scottsbluff",
    "Scottsboro",
    "Scottsburg",
    "Scottsburg",
    "Scottsburg",
    "Scottsdale",
    "Scottsville",
    "Scottsville",
    "Scottsville",
    "Scottville",
    "Scranton",
    "Scranton",
    "Scranton",
    "Scranton",
    "Scranton",
    "Scranton",
    "Scribner",
    "Scroggins",
    "Scurry",
    "Sea Cliff",
    "Sea Girt",
    "Sea Island",
    "Sea Isle City",
    "Sea Ranch",
    "Seabeck",
    "Seabrook",
    "Seabrook",
    "Seabrook",
    "Seadrift",
    "Seaford",
    "Seaford",
    "Seaford",
    "Seagoville",
    "Seagraves",
    "Seagrove",
    "Seahurst",
    "Seal",
    "Seal Beach",
    "Seal Cove",
    "Seal Rock",
    "Seale",
    "Sealevel",
    "Sealy",
    "Seaman",
    "Searcy",
    "Searsboro",
    "Searsmont",
    "Seaside",
    "Seaside",
    "Seaside Heights",
    "Seaside Park",
    "Seaton",
    "Seatonville",
    "Seattle",
    "Sebastian",
    "Sebastian",
    "Sebastopol",
    "Sebec",
    "Sebeka",
    "Sebewaing",
    "Sebree",
    "Sebring",
    "Sebring",
    "Secaucus",
    "Second Mesa",
    "Secor",
    "Section",
    "Sedalia",
    "Sedalia",
    "Sedalia",
    "Sedan",
    "Sedgwick",
    "Sedgwick",
    "Sedona",
    "Sedro-Woolley",
    "Seekonk",
    "Seeley",
    "Seeley Lake",
    "Seffner",
    "Seguin",
    "Seiling",
    "Selah",
    "Selawik",
    "Selby",
    "Selbyville",
    "Selden",
    "Selden",
    "Seligman",
    "Selinsgrove",
    "Selkirk",
    "Sellersburg",
    "Sellersville",
    "Sells",
    "Selma",
    "Selma",
    "Selma",
    "Selma",
    "Selma",
    "Selmer",
    "Seminary",
    "Seminole",
    "Seminole",
    "Seminole",
    "Semmes",
    "Senath",
    "Senatobia",
    "Seneca",
    "Seneca",
    "Seneca",
    "Seneca",
    "Seneca",
    "Seneca Falls",
    "Senecaville",
    "Senoia",
    "Sentinel Butte",
    "Sequim",
    "Sequoia",
    "Serafina",
    "Serena",
    "Sergeant Bluff",
    "Sergeantsville",
    "Sesser",
    "Seth",
    "Seven Mile",
    "Seven Springs",
    "Seven Valleys",
    "Severance",
    "Severn",
    "Severna Park",
    "Severy",
    "Sevierville",
    "Seville",
    "Seville",
    "Sewanee",
    "Seward",
    "Seward",
    "Seward",
    "Sewaren",
    "Sewell",
    "Sewickley",
    "Seymour",
    "Seymour",
    "Seymour",
    "Seymour",
    "Seymour",
    "Seymour",
    "Seymour",
    "Shabbona",
    "Shacklefords",
    "Shade Gap",
    "Shady Cove",
    "Shady Dale",
    "Shady Point",
    "Shady Side",
    "Shady Spring",
    "Shady Valley",
    "Shadyside",
    "Shafer",
    "Shafter",
    "Shaftsbury",
    "Shakopee",
    "Shalimar",
    "Shallotte",
    "Shallowater",
    "Shamokin",
    "Shamokin Dam",
    "Shamrock",
    "Shandaken",
    "Shandon",
    "Shanks",
    "Shanksville",
    "Shannon",
    "Shannon",
    "Shannon",
    "Shannon City",
    "Shapleigh",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon",
    "Sharon Center",
    "Sharon Hill",
    "Sharon Springs",
    "Sharon Springs",
    "Sharpsburg",
    "Sharpsburg",
    "Sharpsburg",
    "Sharpsville",
    "Sharpsville",
    "Sharptown",
    "Shartlesville",
    "Shasta",
    "Shattuck",
    "Shavertown",
    "Shaw",
    "Shaw Island",
    "Shawano",
    "Shawboro",
    "Shawnee",
    "Shawnee",
    "Shawnee",
    "Shawnee on Delaware",
    "Shawneetown",
    "Shawsville",
    "Sheboygan",
    "Sheboygan Falls",
    "Shedd",
    "Sheep Springs",
    "Sheffield",
    "Sheffield",
    "Sheffield",
    "Sheffield",
    "Sheffield",
    "Sheffield",
    "Sheffield Lake",
    "Shelbiana",
    "Shelburn",
    "Shelburne",
    "Shelburne Falls",
    "Shelby",
    "Shelby",
    "Shelby",
    "Shelby",
    "Shelby",
    "Shelby",
    "Shelby",
    "Shelbyville",
    "Shelbyville",
    "Shelbyville",
    "Shelbyville",
    "Shelbyville",
    "Shelbyville",
    "Sheldahl",
    "Sheldon",
    "Sheldon",
    "Sheldon",
    "Sheldon",
    "Sheldon",
    "Sheldon Springs",
    "Shell",
    "Shell Knob",
    "Shell Lake",
    "Shell Rock",
    "Shelley",
    "Shellman",
    "Shellsburg",
    "Shelly",
    "Shelocta",
    "Shelter Island",
    "Shelter Island Heights",
    "Shelton",
    "Shelton",
    "Shelton",
    "Shenandoah",
    "Shenandoah",
    "Shenandoah",
    "Shenandoah Junction",
    "Shepherd",
    "Shepherd",
    "Shepherd",
    "Shepherdstown",
    "Shepherdsville",
    "Sherborn",
    "Sherburn",
    "Sherburne",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sheridan",
    "Sherman",
    "Sherman",
    "Sherman",
    "Sherman",
    "Sherman Oaks",
    "Shermans Dale",
    "Sherrard",
    "Sherrill",
    "Sherrill",
    "Sherrill",
    "Sherrills Ford",
    "Sherrodsville",
    "Sherwood",
    "Sherwood",
    "Sherwood",
    "Sherwood",
    "Sherwood",
    "Shevlin",
    "Sheyenne",
    "Shickley",
    "Shickshinny",
    "Shidler",
    "Shiloh",
    "Shiloh",
    "Shiloh",
    "Shiner",
    "Shingle Springs",
    "Shinglehouse",
    "Shingleton",
    "Shingletown",
    "Shinnston",
    "Shiocton",
    "Shipman",
    "Shipman",
    "Shippensburg",
    "Shippenville",
    "Shippingport",
    "Shiprock",
    "Shipshewana",
    "Shirley",
    "Shirley",
    "Shirley",
    "Shirley",
    "Shirley",
    "Shirley Mills",
    "Shirleysburg",
    "Shoals",
    "Shobonier",
    "Shoemakersville",
    "Shohola",
    "Shokan",
    "Shongaloo",
    "Shoreham",
    "Shoreham",
    "Shorewood",
    "Short Hills",
    "Shoshone",
    "Shoshoni",
    "Show Low",
    "Shreve",
    "Shreveport",
    "Shrewsbury",
    "Shrewsbury",
    "Shrewsbury",
    "Shrub Oak",
    "Shubuta",
    "Shumway",
    "Shungnak",
    "Shutesbury",
    "Sibley",
    "Sibley",
    "Sibley",
    "Sibley",
    "Sicily Island",
    "Sicklerville",
    "Side Lake",
    "Sidell",
    "Sidman",
    "Sidney",
    "Sidney",
    "Sidney",
    "Sidney",
    "Sidney",
    "Sidney",
    "Sidney",
    "Sidney Center",
    "Sidon",
    "Siemens",
    "Sierra Madre",
    "Sierra Vista",
    "Signal Hill",
    "Signal Mountain",
    "Sigourney",
    "Sikes",
    "Sikeston",
    "Silas",
    "Siler City",
    "Silex",
    "Siloam",
    "Siloam Springs",
    "Silsbee",
    "Silt",
    "Silva",
    "Silvana",
    "Silver Bay",
    "Silver Bay",
    "Silver City",
    "Silver City",
    "Silver Creek",
    "Silver Creek",
    "Silver Creek",
    "Silver Grove",
    "Silver Lake",
    "Silver Lake",
    "Silver Lake",
    "Silver Lake",
    "Silver Spring",
    "Silver Springs",
    "Silver Springs",
    "Silver Springs",
    "Silverado",
    "Silverdale",
    "Silverdale",
    "Silverhill",
    "Silverstreet",
    "Silverthorne",
    "Silverton",
    "Silverton",
    "Silvis",
    "Simi Valley",
    "Simla",
    "Simmesport",
    "Simms",
    "Simms",
    "Simon",
    "Simonton",
    "Simpson",
    "Simpson",
    "Simpsonville",
    "Simpsonville",
    "Simpsonville",
    "Sims",
    "Sims",
    "Simsboro",
    "Simsbury",
    "Sinclair",
    "Sinclairville",
    "Sinks Grove",
    "Sinton",
    "Sioux Center",
    "Sioux City",
    "Sioux Falls",
    "Sioux Rapids",
    "Sipesville",
    "Sipsey",
    "Siren",
    "Sisseton",
    "Sister Bay",
    "Sisters",
    "Sistersville",
    "Sitka",
    "Sitka",
    "Six Lakes",
    "Six Mile",
    "Skaguay",
    "Skamokawa",
    "Skandia",
    "Skaneateles",
    "Skaneateles Falls",
    "Skanee",
    "Skellytown",
    "Skiatook",
    "Skidmore",
    "Skidmore",
    "Skillman",
    "Skippack",
    "Skokie",
    "Skowhegan",
    "Skyforest",
    "Skykomish",
    "Skyland",
    "Slagle",
    "Slanesville",
    "Slate Hill",
    "Slater",
    "Slater",
    "Slatersville",
    "Slatington",
    "Slaton",
    "Slaty Fork",
    "Slaughter",
    "Slaughters",
    "Slayton",
    "Sleepy Eye",
    "Slidell",
    "Sligo",
    "Slinger",
    "Slingerlands",
    "Slippery Rock",
    "Sloan",
    "Sloansville",
    "Sloatsburg",
    "Slocomb",
    "Sloughhouse",
    "Slovan",
    "Smackover",
    "Smarr",
    "Smartt",
    "Smethport",
    "Smilax",
    "Smiley",
    "Smith Center",
    "Smith River",
    "Smithers",
    "Smithfield",
    "Smithfield",
    "Smithfield",
    "Smithfield",
    "Smithfield",
    "Smithfield",
    "Smithfield",
    "Smithland",
    "Smiths",
    "Smiths Creek",
    "Smiths Grove",
    "Smithsburg",
    "Smithshire",
    "Smithton",
    "Smithton",
    "Smithton",
    "Smithtown",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville",
    "Smithville Flats",
    "Smoaks",
    "Smock",
    "Smoke Run",
    "Smoketown",
    "Smyer",
    "Smyrna",
    "Smyrna",
    "Smyrna",
    "Smyrna",
    "Smyrna",
    "Smyrna Mills",
    "Sneads",
    "Sneads Ferry",
    "Sneedville",
    "Snelling",
    "Snellville",
    "Snohomish",
    "Snoqualmie",
    "Snover",
    "Snow Camp",
    "Snow Hill",
    "Snow Hill",
    "Snow Shoe",
    "Snowflake",
    "Snowmass",
    "Snowmass Village",
    "Snyder",
    "Snyder",
    "Snyder",
    "Soap Lake",
    "Sobieski",
    "Social Circle",
    "Society Hill",
    "Socorro",
    "Sod",
    "Soda Springs",
    "Soda Springs",
    "Soddy-Daisy",
    "Sodus",
    "Solana Beach",
    "Soldier",
    "Soldier",
    "Soldiers Grove",
    "Soldotna",
    "Solebury",
    "Soledad",
    "Solen",
    "Solomons",
    "Solon",
    "Solon",
    "Solon Springs",
    "Solsberry",
    "Solvang",
    "Solway",
    "Somerdale",
    "Somers",
    "Somers",
    "Somers",
    "Somers",
    "Somers Point",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset",
    "Somerset Center",
    "Somersworth",
    "Somerton",
    "Somerville",
    "Somerville",
    "Somerville",
    "Somerville",
    "Somerville",
    "Somerville",
    "Somis",
    "Somonauk",
    "Sonoita",
    "Sonoma",
    "Sonora",
    "Sonora",
    "Sonora",
    "Sopchoppy",
    "Soper",
    "Soperton",
    "Sophia",
    "Sophia",
    "Soquel",
    "Sorento",
    "Sorrento",
    "Sorrento",
    "Soso",
    "Soudan",
    "Souderton",
    "Soulsbyville",
    "Sound Beach",
    "Sour Lake",
    "South Amboy",
    "South Barre",
    "South Bay",
    "South Beach",
    "South Beloit",
    "South Bend",
    "South Bend",
    "South Bend",
    "South Berwick",
    "South Boardman",
    "South Boston",
    "South Bound Brook",
    "South Bristol",
    "South Burlington",
    "South Cairo",
    "South Canaan",
    "South Casco",
    "South Charleston",
    "South China",
    "South Dartmouth",
    "South Deerfield",
    "South Dennis",
    "South Easton",
    "South Egremont",
    "South El Monte",
    "South Elgin",
    "South English",
    "South Fallsburg",
    "South Fork",
    "South Fork",
    "South Freeport",
    "South Fulton",
    "South Gate",
    "South Gibson",
    "South Glastonbury",
    "South Glens Falls",
    "South Hackensack",
    "South Hadley",
    "South Hamilton",
    "South Harwich",
    "South Haven",
    "South Haven",
    "South Haven",
    "South Hero",
    "South Hill",
    "South Holland",
    "South Houston",
    "South Hutchinson",
    "South Jamesport",
    "South Jordan",
    "South Kent",
    "South Lake Tahoe",
    "South Lancaster",
    "South Lebanon",
    "South Londonderry",
    "South Lyon",
    "South Mills",
    "South Milwaukee",
    "South New Berlin",
    "South Newfane",
    "South Orange",
    "South Otselic",
    "South Ozone Park",
    "South Padre Island",
    "South Paris",
    "South Park Township",
    "South Pasadena",
    "South Pekin",
    "South Pittsburg",
    "South Plainfield",
    "South Plymouth",
    "South Point",
    "South Pomfret",
    "South Portland",
    "South Prairie",
    "South Range",
    "South River",
    "South Rockwood",
    "South Roxana",
    "South Royalton",
    "South Ryegate",
    "South Saint Paul",
    "South Salem",
    "South Salem",
    "South Sanford",
    "South Seaville",
    "South Shore",
    "South Sioux City",
    "South Solon",
    "South Sterling",
    "South Strafford",
    "South Tamworth",
    "South Vienna",
    "South Wales",
    "South Wayne",
    "South Webster",
    "South Wellfleet",
    "South West City",
    "South Weymouth",
    "South Whitley",
    "South Williamson",
    "South Willington",
    "South Wilmington",
    "South Windsor",
    "South Woodstock",
    "South Woodstock",
    "South Yarmouth",
    "Southampton",
    "Southampton",
    "Southampton",
    "Southaven",
    "Southborough",
    "Southbridge",
    "Southbury",
    "Southern Pines",
    "Southfield",
    "Southfields",
    "Southgate",
    "Southington",
    "Southington",
    "Southlake",
    "Southmayd",
    "Southold",
    "Southport",
    "Southport",
    "Southport",
    "Southside",
    "Southview",
    "Southwest Harbor",
    "Southwick",
    "Spain",
    "Spalding",
    "Spanaway",
    "Spanish Fork",
    "Spanish Fort",
    "Spanishburg",
    "Sparkill",
    "Sparkman",
    "Sparks",
    "Sparks",
    "Sparks",
    "Sparks",
    "Sparland",
    "Sparrow Bush",
    "Sparrows Point",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Sparta",
    "Spartanburg",
    "Spartansburg",
    "Spavinaw",
    "Spearfish",
    "Spearman",
    "Spearsville",
    "Spearville",
    "Speculator",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencer",
    "Spencerport",
    "Spencertown",
    "Spencerville",
    "Spencerville",
    "Spencerville",
    "Sperry",
    "Sperry",
    "Sperryville",
    "Spiceland",
    "Spicer",
    "Spicewood",
    "Spickard",
    "Spindale",
    "Spinnerstown",
    "Spirit Lake",
    "Spirit Lake",
    "Spiro",
    "Spivey",
    "Splendora",
    "Spofford",
    "Spokane",
    "Spooner",
    "Spotswood",
    "Spotsylvania",
    "Spout Spring",
    "Spraggs",
    "Sprakers",
    "Spreckels",
    "Spring",
    "Spring Arbor",
    "Spring Branch",
    "Spring Church",
    "Spring City",
    "Spring City",
    "Spring City",
    "Spring Creek",
    "Spring Creek",
    "Spring Glen",
    "Spring Green",
    "Spring Grove",
    "Spring Grove",
    "Spring Grove",
    "Spring Grove",
    "Spring Hill",
    "Spring Hill",
    "Spring Hill",
    "Spring Hope",
    "Spring House",
    "Spring Lake",
    "Spring Lake",
    "Spring Lake",
    "Spring Lake Park",
    "Spring Mills",
    "Spring Park",
    "Spring Run",
    "Spring Valley",
    "Spring Valley",
    "Spring Valley",
    "Spring Valley",
    "Spring Valley",
    "Spring Valley",
    "Springboro",
    "Springboro",
    "Springdale",
    "Springdale",
    "Springdale",
    "Springdale",
    "Springer",
    "Springerville",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield",
    "Springfield Gardens",
    "Springfield Township",
    "Springfield Township",
    "Springhill",
    "Springport",
    "Springport",
    "Springtown",
    "Springtown",
    "Springvale",
    "Springview",
    "Springville",
    "Springville",
    "Springville",
    "Springville",
    "Springville",
    "Springville",
    "Springville",
    "Springville",
    "Springwater",
    "Spruce",
    "Spruce Pine",
    "Spruce Pine",
    "Spurlockville",
    "Squaw Lake",
    "Squaw Valley",
    "Squires",
    "St Louis",
    "St. Augustine",
    "St. Charles",
    "St. Genevieve",
    "St. Helena",
    "St. Joseph",
    "St. Rose",
    "Staatsburg",
    "Stacy",
    "Stacyville",
    "Stacyville",
    "Stafford",
    "Stafford",
    "Stafford",
    "Stafford Springs",
    "Staffordsville",
    "Stahlstown",
    "Staley",
    "Stamford",
    "Stamford",
    "Stamford",
    "Stamford",
    "Stamford",
    "Stanardsville",
    "Stanberry",
    "Stanchfield",
    "Standard",
    "Standish",
    "Standish",
    "Stanfield",
    "Stanfield",
    "Stanfield",
    "Stanford",
    "Stanford",
    "Stanford",
    "Stanford",
    "Stanfordville",
    "Stanhope",
    "Stanhope",
    "Stanley",
    "Stanley",
    "Stanley",
    "Stanley",
    "Stanley",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stanton",
    "Stantonsburg",
    "Stanville",
    "Stanwood",
    "Stanwood",
    "Staples",
    "Staples",
    "Stapleton",
    "Stapleton",
    "Stapleton",
    "Star",
    "Star",
    "Star City",
    "Star City",
    "Star Lake",
    "Star Prairie",
    "Starbuck",
    "Starford",
    "Stark",
    "Stark City",
    "Starke",
    "Starksboro",
    "Starkville",
    "Starkweather",
    "Starr",
    "Startex",
    "State Center",
    "State College",
    "State Line",
    "State Line",
    "State Road",
    "State University",
    "Stateline",
    "Staten Island",
    "Statenville",
    "Statesboro",
    "Statesville",
    "Statham",
    "Staunton",
    "Staunton",
    "Stayton",
    "Steamboat Rock",
    "Steamboat Springs",
    "Steamburg",
    "Stearns",
    "Stebbins",
    "Stedman",
    "Steedman",
    "Steele",
    "Steele",
    "Steele",
    "Steele City",
    "Steeleville",
    "Steelville",
    "Steen",
    "Steens",
    "Steep Falls",
    "Steger",
    "Steilacoom",
    "Steinauer",
    "Steinhatchee",
    "Stella",
    "Stella",
    "Stem",
    "Stephen",
    "Stephens",
    "Stephens City",
    "Stephenson",
    "Stephenson",
    "Stephensport",
    "Stephentown",
    "Stephenville",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling",
    "Sterling Forest",
    "Sterling Heights",
    "Sterlington",
    "Sterrett",
    "Stetson",
    "Stetsonville",
    "Steuben",
    "Steubenville",
    "Stevens",
    "Stevens Point",
    "Stevenson",
    "Stevenson",
    "Stevenson",
    "Stevenson Ranch",
    "Stevensville",
    "Stevensville",
    "Stevensville",
    "Stevinson",
    "Steward",
    "Stewardson",
    "Stewart",
    "Stewart",
    "Stewart",
    "Stewartstown",
    "Stewartsville",
    "Stewartsville",
    "Stewartville",
    "Stickney",
    "Stigler",
    "Stilesville",
    "Still Pond",
    "Stillman Valley",
    "Stillmore",
    "Stillwater",
    "Stillwater",
    "Stillwater",
    "Stillwater",
    "Stilwell",
    "Stilwell",
    "Stinesville",
    "Stinnett",
    "Stinson Beach",
    "Stirling",
    "Stites",
    "Stittville",
    "Stockbridge",
    "Stockbridge",
    "Stockdale",
    "Stockertown",
    "Stockholm",
    "Stockport",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton",
    "Stockton Springs",
    "Stoddard",
    "Stoddard",
    "Stokesdale",
    "Stollings",
    "Stone",
    "Stone Creek",
    "Stone Harbor",
    "Stone Lake",
    "Stone Mountain",
    "Stone Park",
    "Stone Ridge",
    "Stoneboro",
    "Stonefort",
    "Stoneham",
    "Stoneville",
    "Stoneville",
    "Stonewall",
    "Stonewall",
    "Stonewall",
    "Stonewall",
    "Stonington",
    "Stonington",
    "Stony Brook",
    "Stony Creek",
    "Stony Creek",
    "Stony Point",
    "Stony Point",
    "Stonyford",
    "Storden",
    "Storm Lake",
    "Stormville",
    "Storrs",
    "Story",
    "Story",
    "Story City",
    "Stotts City",
    "Stoughton",
    "Stoughton",
    "Stout",
    "Stoutland",
    "Stover",
    "Stow",
    "Stow",
    "Stowe",
    "Stoystown",
    "Strafford",
    "Strafford",
    "Strafford",
    "Strasburg",
    "Strasburg",
    "Strasburg",
    "Strasburg",
    "Strasburg",
    "Strasburg",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratford",
    "Stratham",
    "Strathmore",
    "Strattanville",
    "Stratton",
    "Stratton",
    "Straughn",
    "Strawberry",
    "Strawberry Plains",
    "Strawberry Point",
    "Strawn",
    "Strawn",
    "Streamwood",
    "Streator",
    "Street",
    "Streeter",
    "Streetman",
    "Streetsboro",
    "Stringer",
    "Stromsburg",
    "Strong",
    "Strong",
    "Stronghurst",
    "Strongstown",
    "Strongsville",
    "Stroud",
    "Stroudsburg",
    "Strum",
    "Strunk",
    "Struthers",
    "Stryker",
    "Stuart",
    "Stuart",
    "Stuart",
    "Stuart",
    "Stuart",
    "Stuarts Draft",
    "Studio City",
    "Stump Creek",
    "Sturbridge",
    "Sturgeon",
    "Sturgeon",
    "Sturgeon Bay",
    "Sturgeon Lake",
    "Sturgis",
    "Sturgis",
    "Sturgis",
    "Sturgis",
    "Sturtevant",
    "Stuttgart",
    "Stuyvesant",
    "Suamico",
    "Sublette",
    "Sublette",
    "Sublimity",
    "Succasunna",
    "Suches",
    "Sudan",
    "Sudbury",
    "Sudlersville",
    "Suffern",
    "Suffield",
    "Suffolk",
    "Sugar City",
    "Sugar Grove",
    "Sugar Grove",
    "Sugar Grove",
    "Sugar Grove",
    "Sugar Grove",
    "Sugar Grove",
    "Sugar Hill",
    "Sugar Land",
    "Sugar Loaf",
    "Sugar Run",
    "Sugar Tree",
    "Sugar Valley",
    "Sugarcreek",
    "Sugarloaf",
    "Sugarloaf Township",
    "Sugartown",
    "Suisun",
    "Suitland",
    "Sula",
    "Sulligent",
    "Sullivan",
    "Sullivan",
    "Sullivan",
    "Sullivan",
    "Sullivan",
    "Sullivan",
    "Sullivan",
    "Sully",
    "Sulphur",
    "Sulphur",
    "Sulphur Bluff",
    "Sulphur Springs",
    "Sulphur Springs",
    "Sulphur Springs",
    "Sultan",
    "Sumas",
    "Sumerduck",
    "Sumiton",
    "Summer Shade",
    "Summerdale",
    "Summerdale",
    "Summerfield",
    "Summerfield",
    "Summerfield",
    "Summerfield",
    "Summerfield",
    "Summerfield",
    "Summerhill",
    "Summerland",
    "Summerland Key",
    "Summers",
    "Summersville",
    "Summersville",
    "Summerton",
    "Summertown",
    "Summerville",
    "Summerville",
    "Summerville",
    "Summerville",
    "Summit",
    "Summit",
    "Summit",
    "Summit",
    "Summit Hill",
    "Summit Lake",
    "Summit Station",
    "Summitville",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumner",
    "Sumrall",
    "Sumter",
    "Sun",
    "Sun City",
    "Sun City",
    "Sun City",
    "Sun City Center",
    "Sun City West",
    "Sun Prairie",
    "Sun River",
    "Sun Valley",
    "Sun Valley",
    "Sun Valley",
    "Sunapee",
    "Sunbright",
    "Sunburg",
    "Sunburst",
    "Sunbury",
    "Sunbury",
    "Suncook",
    "Sundance",
    "Sunderland",
    "Sunderland",
    "Sundown",
    "Sunflower",
    "Sunland",
    "Sunman",
    "Sunnyside",
    "Sunnyside",
    "Sunnyside",
    "Sunnyvale",
    "Sunnyvale",
    "Sunol",
    "Sunray",
    "Sunrise",
    "Sunrise Beach",
    "Sunset",
    "Sunset",
    "Sunset",
    "Sunset Beach",
    "Sunset Beach",
    "Sunshine",
    "Superior",
    "Superior",
    "Superior",
    "Superior",
    "Supply",
    "Suquamish",
    "Surgoinsville",
    "Suring",
    "Surprise",
    "Surrency",
    "Surry",
    "Surry",
    "Susanville",
    "Susquehanna",
    "Sussex",
    "Sussex",
    "Sutersville",
    "Sutherland",
    "Sutherland",
    "Sutherland",
    "Sutherland Springs",
    "Sutherlin",
    "Sutter",
    "Sutter",
    "Sutter Creek",
    "Sutton",
    "Sutton",
    "Sutton",
    "Sutton",
    "Suttons Bay",
    "Suwanee",
    "Suwannee",
    "Swainsboro",
    "Swaledale",
    "Swampscott",
    "Swan",
    "Swannanoa",
    "Swans Island",
    "Swansboro",
    "Swansea",
    "Swansea",
    "Swanton",
    "Swanton",
    "Swanton",
    "Swanville",
    "Swanzey",
    "Swarthmore",
    "Swartswood",
    "Swartz",
    "Swartz Creek",
    "Swayzee",
    "Swedesboro",
    "Sweeny",
    "Sweet",
    "Sweet Home",
    "Sweet Springs",
    "Sweet Valley",
    "Sweet Water",
    "Sweetser",
    "Sweetwater",
    "Sweetwater",
    "Swiftwater",
    "Swink",
    "Swisher",
    "Swisshome",
    "Switz City",
    "Switzer",
    "Swoope",
    "Sybertsville",
    "Sycamore",
    "Sycamore",
    "Sycamore",
    "Sycamore",
    "Sycamore",
    "Sykeston",
    "Sykesville",
    "Sykesville",
    "Sylacauga",
    "Sylmar",
    "Sylva",
    "Sylvan Beach",
    "Sylvan Grove",
    "Sylvania",
    "Sylvania",
    "Sylvania",
    "Sylvester",
    "Sylvester",
    "Symsonia",
    "Syosset",
    "Syracuse",
    "Syracuse",
    "Syracuse",
    "Syracuse",
    "Syracuse",
    "Syracuse",
    "Taberg",
    "Tabernash",
    "Table Grove",
    "Tabor",
    "Tabor",
    "Tabor City",
    "Tacoma",
    "Taft",
    "Taft",
    "Taft",
    "Tafton",
    "Taftville",
    "Tahlequah",
    "Tahoe City",
    "Tahoe Valley",
    "Tahoe Vista",
    "Tahoka",
    "Taholah",
    "Tahoma",
    "Tahuya",
    "Takoma Park",
    "Talala",
    "Talbott",
    "Talbotton",
    "Talco",
    "Talcott",
    "Talent",
    "Talihina",
    "Talkeetna",
    "Talking Rock",
    "Tall Timbers",
    "Talladega",
    "Tallahassee",
    "Tallapoosa",
    "Tallassee",
    "Tallmadge",
    "Tallulah",
    "Talmo",
    "Talmoon",
    "Taloga",
    "Tama",
    "Tamaqua",
    "Tamassee",
    "Tamms",
    "Tampa",
    "Tampico",
    "Tamworth",
    "Taneytown",
    "Taneyville",
    "Tangent",
    "Tangerine",
    "Tangier",
    "Tangipahoa",
    "Tanner",
    "Tannersville",
    "Tannersville",
    "Taos",
    "Taos Ski Valley",
    "Tappahannock",
    "Tappan",
    "Tappen",
    "Tar Heel",
    "Tarawa Terrace I",
    "Tarboro",
    "Tarentum",
    "Tariffville",
    "Tarkio",
    "Tarpon Springs",
    "Tarrs",
    "Tarrytown",
    "Tarzana",
    "Tasley",
    "Tatamy",
    "Tate",
    "Tatum",
    "Tatum",
    "Taunton",
    "Taunton",
    "Tavares",
    "Tavernier",
    "Tawas City",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor",
    "Taylor Springs",
    "Taylors",
    "Taylors Falls",
    "Taylorsville",
    "Taylorsville",
    "Taylorsville",
    "Taylorsville",
    "Taylorsville",
    "Taylorsville",
    "Taylorville",
    "Tazewell",
    "Tazewell",
    "Tea",
    "Teachey",
    "Teague",
    "Teaneck",
    "Teasdale",
    "Tebbetts",
    "Tecumseh",
    "Tecumseh",
    "Tecumseh",
    "Tecumseh",
    "Teec Nos Pos",
    "Tehachapi",
    "Tehama",
    "Tekamah",
    "Tekoa",
    "Tekonsha",
    "Telephone",
    "Telford",
    "Telford",
    "Tell City",
    "Teller",
    "Tellico Plains",
    "Telluride",
    "Telogia",
    "Temecula",
    "Tempe",
    "Temperance",
    "Temple",
    "Temple",
    "Temple",
    "Temple",
    "Temple",
    "Temple City",
    "Temple Hills",
    "Templeton",
    "Templeton",
    "Templeton",
    "Templeton",
    "Ten Mile",
    "Ten Sleep",
    "Tenafly",
    "Tenaha",
    "Tenants Harbor",
    "Tenino",
    "Tennent",
    "Tennessee",
    "Tennessee Colony",
    "Tennessee Ridge",
    "Tennille",
    "Tensed",
    "Tenstrike",
    "Terlingua",
    "Terlton",
    "Terra Alta",
    "Terra Bella",
    "Terrace Park",
    "Terre Haute",
    "Terre Hill",
    "Terrebonne",
    "Terrell",
    "Terrell",
    "Terreton",
    "Terril",
    "Terry",
    "Terry",
    "Terryville",
    "Tescott",
    "Tesuque",
    "Teterboro",
    "Teton",
    "Teton Village",
    "Tetonia",
    "Teutopolis",
    "Tewksbury",
    "Texarkana",
    "Texarkana",
    "Texas City",
    "Texhoma",
    "Texico",
    "Texline",
    "Thackerville",
    "Thatcher",
    "Thaxton",
    "Thayer",
    "Thayer",
    "Thayne",
    "The Bronx",
    "The Colony",
    "The Dalles",
    "The Plains",
    "The Plains",
    "The Rock",
    "The Villages",
    "Thelma",
    "Thendara",
    "Theodore",
    "Theresa",
    "Thermal",
    "Thermopolis",
    "Thetford Center",
    "Thibodaux",
    "Thief River Falls",
    "Thiells",
    "Thiensville",
    "Thomas",
    "Thomas",
    "Thomasboro",
    "Thomaston",
    "Thomaston",
    "Thomaston",
    "Thomaston",
    "Thomasville",
    "Thomasville",
    "Thomasville",
    "Thomasville",
    "Thompson",
    "Thompson",
    "Thompson",
    "Thompson",
    "Thompson",
    "Thompson Falls",
    "Thompsontown",
    "Thompsonville",
    "Thompsonville",
    "Thomson",
    "Thomson",
    "Thonotosassa",
    "Thoreau",
    "Thorn Hill",
    "Thornburg",
    "Thorndale",
    "Thorndike",
    "Thorne Bay",
    "Thornton",
    "Thornton",
    "Thornton",
    "Thornton",
    "Thornton",
    "Thornton",
    "Thorntown",
    "Thornville",
    "Thornwood",
    "Thorofare",
    "Thorp",
    "Thousand Oaks",
    "Thousand Palms",
    "Thrall",
    "Three Bridges",
    "Three Forks",
    "Three Lakes",
    "Three Mile Bay",
    "Three Oaks",
    "Three Rivers",
    "Three Rivers",
    "Three Rivers",
    "Three Rivers",
    "Three Springs",
    "Throckmorton",
    "Thurmond",
    "Thurmont",
    "Thurston",
    "Thurston",
    "Tiburon",
    "Tickfaw",
    "Ticonderoga",
    "Tidioute",
    "Tierra Amarilla",
    "Tieton",
    "Tiffin",
    "Tiffin",
    "Tifton",
    "Tigard",
    "Tiger",
    "Tigerton",
    "Tigerville",
    "Tignall",
    "Tijeras",
    "Tilden",
    "Tilden",
    "Tilden",
    "Tilghman",
    "Tiline",
    "Tillamook",
    "Tillar",
    "Tilleda",
    "Tillson",
    "Tilton",
    "Tilton",
    "Tiltonsville",
    "Timber Lake",
    "Timberlake",
    "Timberon",
    "Timberville",
    "Timbo",
    "Timewell",
    "Timmonsville",
    "Timpson",
    "Tingley",
    "Tinley Park",
    "Tioga",
    "Tioga",
    "Tioga",
    "Tiona",
    "Tionesta",
    "Tipp City",
    "Tipton",
    "Tipton",
    "Tipton",
    "Tipton",
    "Tipton",
    "Tipton",
    "Tiptonville",
    "Tire Hill",
    "Tiro",
    "Tishomingo",
    "Tishomingo",
    "Tiskilwa",
    "Titonka",
    "Titus",
    "Titusville",
    "Titusville",
    "Titusville",
    "Tiverton",
    "Tivoli",
    "Tivoli",
    "Toano",
    "Tobaccoville",
    "Tobyhanna",
    "Toccoa",
    "Toccoa Falls",
    "Todd",
    "Toddville",
    "Tofte",
    "Togiak",
    "Toivola",
    "Tok",
    "Toksook Bay",
    "Tolar",
    "Toledo",
    "Toledo",
    "Toledo",
    "Toledo",
    "Toledo",
    "Tolland",
    "Tollesboro",
    "Tolleson",
    "Tollhouse",
    "Tolna",
    "Tolono",
    "Toluca",
    "Tom Bean",
    "Tomah",
    "Tomahawk",
    "Tomahawk",
    "Tomball",
    "Tombstone",
    "Tomkins Cove",
    "Tompkinsville",
    "Toms Brook",
    "Toms River",
    "Tonasket",
    "Tonawanda",
    "Tonawanda",
    "Toney",
    "Tonganoxie",
    "Tonica",
    "Tonkawa",
    "Tonopah",
    "Tontitown",
    "Tonto Basin",
    "Tony",
    "Tooele",
    "Toomsboro",
    "Toomsuba",
    "Toone",
    "Topanga",
    "Topeka",
    "Topeka",
    "Topock",
    "Toppenish",
    "Topping",
    "Topsfield",
    "Topsham",
    "Topton",
    "Topton",
    "Tornado",
    "Tornillo",
    "Toronto",
    "Toronto",
    "Toronto",
    "Torrance",
    "Torrance",
    "Torreon",
    "Torrey",
    "Torrington",
    "Torrington",
    "Totowa",
    "Totz",
    "Tougaloo",
    "Toughkenamon",
    "Toulon",
    "Toutle",
    "Tovey",
    "Towaco",
    "Towanda",
    "Towanda",
    "Towanda",
    "Tower",
    "Tower City",
    "Tower City",
    "Tower Hill",
    "Town Creek",
    "Town of Boxborough",
    "Town of Bozrah",
    "Town of East Lyme",
    "Town of Mount Desert",
    "Towner",
    "Townsend",
    "Townsend",
    "Townsend",
    "Townsend",
    "Townsend",
    "Townshend",
    "Townville",
    "Townville",
    "Towson",
    "Toxey",
    "Trabuco Canyon",
    "Tracy",
    "Tracy",
    "Tracy City",
    "Tracys Landing",
    "Tracyton",
    "Trade",
    "Traer",
    "Trafalgar",
    "Trafford",
    "Trafford",
    "Trail",
    "Trail",
    "Transfer",
    "Traphill",
    "Trappe",
    "Travelers Rest",
    "Traverse City",
    "Treadwell",
    "Trego",
    "Treichlers",
    "Trementina",
    "Tremont",
    "Tremont",
    "Tremont",
    "Tremonton",
    "Trempealeau",
    "Trenary",
    "Trent",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Trenton",
    "Tres Pinos",
    "Tresckow",
    "Trevett",
    "Trevor",
    "Trevorton",
    "Trexlertown",
    "Treynor",
    "Trezevant",
    "Triadelphia",
    "Triangle",
    "Tribes Hill",
    "Tribune",
    "Trimble",
    "Trimont",
    "Trinidad",
    "Trinidad",
    "Trinidad",
    "Trinity",
    "Trinity",
    "Trinity",
    "Trion",
    "Tripoli",
    "Tripp",
    "Triumph",
    "Trivoli",
    "Trona",
    "Tropic",
    "Trosper",
    "Troup",
    "Troupsburg",
    "Trout Creek",
    "Trout Creek",
    "Trout Creek",
    "Trout Run",
    "Troutdale",
    "Troutman",
    "Troutville",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Troy",
    "Truchas",
    "Truckee",
    "Trufant",
    "Truman",
    "Trumann",
    "Trumansburg",
    "Trumbauersville",
    "Trumbull",
    "Trumbull",
    "Truro",
    "Truro",
    "Trussville",
    "Truth or Consequences",
    "Truxton",
    "Tryon",
    "Tryon",
    "Tryon",
    "Tsaile",
    "Tualatin",
    "Tuba City",
    "Tubac",
    "Tuckahoe",
    "Tuckahoe",
    "Tuckasegee",
    "Tucker",
    "Tucker",
    "Tuckerman",
    "Tuckerton",
    "Tucson",
    "Tucumcari",
    "Tujunga",
    "Tulare",
    "Tulare County",
    "Tularosa",
    "Tulelake",
    "Tulia",
    "Tullahoma",
    "Tully",
    "Tulsa",
    "Tumacacori",
    "Tumtum",
    "Tumwater",
    "Tunas",
    "Tunbridge",
    "Tunica",
    "Tunkhannock",
    "Tunnel Hill",
    "Tunnelton",
    "Tuntutuliak",
    "Tuolumne",
    "Tupelo",
    "Tupelo",
    "Tupelo",
    "Tupper Lake",
    "Turbeville",
    "Turbotville",
    "Turin",
    "Turkey",
    "Turkey Creek",
    "Turlock",
    "Turner",
    "Turner",
    "Turner",
    "Turner",
    "Turners Falls",
    "Turners Station",
    "Turnerville",
    "Turpin",
    "Turtle Creek",
    "Turtle Creek",
    "Turtle Lake",
    "Tuscaloosa",
    "Tuscarawas",
    "Tuscola",
    "Tuscola",
    "Tuscumbia",
    "Tuscumbia",
    "Tuskegee",
    "Tustin",
    "Tustin",
    "Tutor Key",
    "Tuttle",
    "Tutwiler",
    "Tuxedo Park",
    "Twain Harte",
    "Twelve Mile",
    "Twentynine Palms",
    "Twin Bridges",
    "Twin City",
    "Twin Falls",
    "Twin Lake",
    "Twin Lakes",
    "Twin Peaks",
    "Twin Valley",
    "Twining",
    "Twinsburg",
    "Twisp",
    "Two Buttes",
    "Two Harbors",
    "Two Rivers",
    "Two Rivers",
    "Tyaskin",
    "Tybee Island",
    "Tybo",
    "Tygh Valley",
    "Tyler",
    "Tyler",
    "Tyler",
    "Tylertown",
    "Tyndall",
    "Tyner",
    "Tyngsboro",
    "Tyringham",
    "Tyrone",
    "Tyrone",
    "Tyrone",
    "Tyronza",
    "Ubly",
    "Udall",
    "Uhrichsville",
    "Ukiah",
    "Uledi",
    "Ulen",
    "Ullin",
    "Ulm",
    "Ulster",
    "Ulster Park",
    "Ulysses",
    "Ulysses",
    "Ulysses",
    "Umatilla",
    "Umatilla",
    "Umpire",
    "Unadilla",
    "Unadilla",
    "Unadilla",
    "Unalakleet",
    "Unalaska",
    "Uncasville",
    "Underhill",
    "Underwood",
    "Underwood",
    "Underwood",
    "Underwood",
    "Underwood",
    "Unicoi",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union",
    "Union Bridge",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union City",
    "Union Dale",
    "Union Grove",
    "Union Grove",
    "Union Hall",
    "Union Hill",
    "Union Lake",
    "Union Mills",
    "Union Mills",
    "Union Point",
    "Union Springs",
    "Union Springs",
    "Union Star",
    "Union Township",
    "Uniondale",
    "Uniondale",
    "Uniontown",
    "Uniontown",
    "Uniontown",
    "Uniontown",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unionville",
    "Unity",
    "Unity",
    "Universal City",
    "Universal City",
    "University",
    "University",
    "University Park",
    "University Park",
    "University Place",
    "Unorganized Territory of Camp Lejeune",
    "Upatoi",
    "Upland",
    "Upland",
    "Upper Abbot",
    "Upper Black Eddy",
    "Upper Darby",
    "Upper Falls",
    "Upper Jay",
    "Upper Marlboro",
    "Upper Saddle River",
    "Upper Sandusky",
    "Upperco",
    "Upperville",
    "Upsala",
    "Upton",
    "Upton",
    "Upton",
    "Upton",
    "Urbana",
    "Urbana",
    "Urbana",
    "Urbana",
    "Urbana",
    "Urbandale",
    "Urbanna",
    "Uriah",
    "Urich",
    "Ursa",
    "Ute",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utica",
    "Utopia",
    "Uvalda",
    "Uvalde",
    "Uxbridge",
    "Vacaville",
    "Vacherie",
    "Vader",
    "Vail",
    "Vail",
    "Vails Gate",
    "Valatie",
    "Valders",
    "Valdese",
    "Valdez",
    "Valdosta",
    "Vale",
    "Vale",
    "Valencia",
    "Valencia",
    "Valentine",
    "Valentines",
    "Valera",
    "Valhalla",
    "Valhermoso Springs",
    "Valier",
    "Valier",
    "Valle",
    "Vallecito",
    "Vallejo",
    "Valles Mines",
    "Valley",
    "Valley",
    "Valley",
    "Valley",
    "Valley Bend",
    "Valley Center",
    "Valley Center",
    "Valley City",
    "Valley City",
    "Valley Cottage",
    "Valley Falls",
    "Valley Falls",
    "Valley Farms",
    "Valley Forge",
    "Valley Green",
    "Valley Head",
    "Valley Head",
    "Valley Mills",
    "Valley Park",
    "Valley Springs",
    "Valley Springs",
    "Valley Springs",
    "Valley Stream",
    "Valley View",
    "Valley View",
    "Valley Village",
    "Valleyford",
    "Valliant",
    "Vallonia",
    "Valmeyer",
    "Valparaiso",
    "Valparaiso",
    "Valparaiso",
    "Valrico",
    "Van",
    "Van",
    "Van Alstyne",
    "Van Buren",
    "Van Buren",
    "Van Buren",
    "Van Dyne",
    "Van Etten",
    "Van Horn",
    "Van Horne",
    "Van Lear",
    "Van Meter",
    "Van Nuys",
    "Van Vleck",
    "Van Vleet",
    "Van Wert",
    "Van Wert",
    "Van Wyck",
    "Vance",
    "Vanceburg",
    "Vancleave",
    "Vancouver",
    "Vandalia",
    "Vandalia",
    "Vandalia",
    "Vandalia",
    "Vanderbilt",
    "Vanderbilt",
    "Vandergrift",
    "Vandiver",
    "Vanleer",
    "Vanlue",
    "Vansant",
    "Vanzant",
    "Vardaman",
    "Varna",
    "Varnville",
    "Vashon",
    "Vass",
    "Vassalboro",
    "Vassar",
    "Vassar",
    "Vaughan",
    "Vaughn",
    "Vauxhall",
    "Veblen",
    "Veedersburg",
    "Vega",
    "Velarde",
    "Velva",
    "Vendor",
    "Venedocia",
    "Veneta",
    "Venetia",
    "Venice",
    "Venice",
    "Ventnor City",
    "Ventress",
    "Ventura",
    "Ventura",
    "Venus",
    "Venus",
    "Veradale",
    "Verbank",
    "Verdi",
    "Verdigre",
    "Verdon",
    "Verdugo City",
    "Verdunville",
    "Vergas",
    "Vergennes",
    "Vergennes",
    "Vermilion",
    "Vermillion",
    "Vermillion",
    "Vermillion",
    "Vermont",
    "Vermontville",
    "Vernal",
    "Verndale",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon",
    "Vernon Center",
    "Vernon Hill",
    "Vernon Hills",
    "Vernonia",
    "Vero Beach",
    "Verona",
    "Verona",
    "Verona",
    "Verona",
    "Verona",
    "Verona",
    "Verona",
    "Verona",
    "Verplanck",
    "Versailles",
    "Versailles",
    "Versailles",
    "Versailles",
    "Versailles",
    "Versailles",
    "Vershire",
    "Vesper",
    "Vesta",
    "Vestaburg",
    "Vestaburg",
    "Vestal",
    "Vesuvius",
    "Vevay",
    "Veyo",
    "Vian",
    "Viborg",
    "Viburnum",
    "Vicco",
    "Vici",
    "Vicksburg",
    "Vicksburg",
    "Victor",
    "Victor",
    "Victor",
    "Victor",
    "Victoria",
    "Victoria",
    "Victoria",
    "Victorville",
    "Vida",
    "Vida",
    "Vidalia",
    "Vidalia",
    "Vidor",
    "Vienna",
    "Vienna",
    "Vienna",
    "Vienna",
    "Vienna",
    "Vienna",
    "Vilas",
    "Vilas",
    "Villa Grove",
    "Villa Park",
    "Villa Park",
    "Villa Rica",
    "Villa Ridge",
    "Villa Ridge",
    "Village",
    "Village Mills",
    "Villanova",
    "Villanueva",
    "Villard",
    "Villas",
    "Ville Platte",
    "Villisca",
    "Vilonia",
    "Vina",
    "Vina",
    "Vinalhaven",
    "Vincennes",
    "Vincent",
    "Vincent",
    "Vincentown",
    "Vine Grove",
    "Vineland",
    "Vinemont",
    "Vineyard Haven",
    "Vining",
    "Vinita",
    "Vinton",
    "Vinton",
    "Vinton",
    "Vinton",
    "Vintondale",
    "Viola",
    "Viola",
    "Viola",
    "Viola",
    "Viola",
    "Viola",
    "Violet",
    "Violet Hill",
    "Viper",
    "Virden",
    "Virgie",
    "Virgilina",
    "Virgin",
    "Virginia",
    "Virginia",
    "Virginia Beach",
    "Viroqua",
    "Visalia",
    "Vista",
    "Vivian",
    "Volborg",
    "Volcano",
    "Volga",
    "Volga",
    "Volga",
    "Voluntown",
    "Von Ormy",
    "Vona",
    "Vonore",
    "Voorhees Township",
    "Voorheesville",
    "Vulcan",
    "Waban",
    "Wabash",
    "Wabasha",
    "Wabasso",
    "Waccabuc",
    "Wachapreague",
    "Waco",
    "Waco",
    "Waco",
    "Waco",
    "Waconia",
    "Waddell",
    "Waddy",
    "Wade",
    "Wadena",
    "Wadesboro",
    "Wadesville",
    "Wading River",
    "Wadley",
    "Wadmalaw Island",
    "Wadsworth",
    "Wadsworth",
    "Waelder",
    "Wagener",
    "Wagner",
    "Wagoner",
    "Wahiawa",
    "Wahkon",
    "Wahoo",
    "Wahpeton",
    "Waialua",
    "Waikoloa",
    "Wailuku",
    "Waimanalo",
    "Wainscott",
    "Wainwright",
    "Wainwright",
    "Waipahu",
    "Waite Park",
    "Waitsburg",
    "Waitsfield",
    "Wakarusa",
    "Wake",
    "Wake Forest",
    "WaKeeney",
    "Wakefield",
    "Wakefield",
    "Wakefield",
    "Wakefield",
    "Wakefield",
    "Wakefield",
    "Wakefield",
    "Wakeman",
    "Wakita",
    "Wakonda",
    "Walbridge",
    "Walcott",
    "Walden",
    "Waldo",
    "Waldo",
    "Waldo",
    "Waldo",
    "Waldoboro",
    "Waldorf",
    "Waldorf",
    "Waldport",
    "Waldron",
    "Waldron",
    "Waldron",
    "Waldwick",
    "Wales",
    "Wales",
    "Waleska",
    "Walford",
    "Walhalla",
    "Walhalla",
    "Walhalla",
    "Walhonding",
    "Walker",
    "Walker",
    "Walker",
    "Walker",
    "Walker Valley",
    "Walkersville",
    "Walkersville",
    "Walkerton",
    "Walkertown",
    "Walkerville",
    "Wall",
    "Wall Lake",
    "Walla Walla",
    "Wallace",
    "Wallace",
    "Wallace",
    "Wallace",
    "Wallace",
    "Wallace",
    "Wallace",
    "Wallaceton",
    "Walland",
    "Wallback",
    "Wallburg",
    "Walled Lake",
    "Waller",
    "Wallingford",
    "Wallingford",
    "Wallingford",
    "Wallingford",
    "Wallingford",
    "Wallington",
    "Wallins Creek",
    "Wallis",
    "Wallkill",
    "Wallowa",
    "Walls",
    "Wallsburg",
    "Walnut",
    "Walnut",
    "Walnut",
    "Walnut",
    "Walnut",
    "Walnut Bottom",
    "Walnut Cove",
    "Walnut Creek",
    "Walnut Grove",
    "Walnut Grove",
    "Walnut Grove",
    "Walnut Grove",
    "Walnut Ridge",
    "Walnut Shade",
    "Walnut Springs",
    "Walnutport",
    "Walpole",
    "Walpole",
    "Walpole",
    "Walsenburg",
    "Walsh",
    "Walshville",
    "Walstonburg",
    "Walterboro",
    "Walters",
    "Waltham",
    "Walthill",
    "Walton",
    "Walton",
    "Walton",
    "Walton",
    "Walton",
    "Walton",
    "Waltonville",
    "Walworth",
    "Walworth",
    "Wamego",
    "Wampsville",
    "Wampum",
    "Wamsutter",
    "Wana",
    "Wanakena",
    "Wanamingo",
    "Wanaque",
    "Wanatah",
    "Wanchese",
    "Waneta",
    "Wanette",
    "Wannaska",
    "Wantagh",
    "Wapakoneta",
    "Wapato",
    "Wapella",
    "Wapello",
    "Wappapello",
    "Wappingers Falls",
    "Wapwallopen",
    "War",
    "Warba",
    "Ward",
    "Ward",
    "Warden",
    "Wardensville",
    "Ware",
    "Ware Neck",
    "Ware Shoals",
    "Wareham",
    "Waretown",
    "Warfordsburg",
    "Warm Springs",
    "Warm Springs",
    "Warminster",
    "Warne",
    "Warner",
    "Warner",
    "Warner Robins",
    "Warner Springs",
    "Warners",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren",
    "Warren Center",
    "Warren Township",
    "Warrendale",
    "Warrens",
    "Warrensburg",
    "Warrensburg",
    "Warrensburg",
    "Warrensville",
    "Warrenton",
    "Warrenton",
    "Warrenton",
    "Warrenton",
    "Warrenton",
    "Warrenville",
    "Warrenville",
    "Warrington",
    "Warrior",
    "Warriors Mark",
    "Warroad",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Warsaw",
    "Wartburg",
    "Wartrace",
    "Warwick",
    "Warwick",
    "Warwick",
    "Warwick",
    "Warwick",
    "Wasco",
    "Waseca",
    "Washburn",
    "Washburn",
    "Washburn",
    "Washburn",
    "Washburn",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington",
    "Washington Boro",
    "Washington Court House",
    "Washington Crossing",
    "Washington Grove",
    "Washington Township",
    "Washingtonville",
    "Washingtonville",
    "Washougal",
    "Wasilla",
    "Waskom",
    "Wassaic",
    "Watauga",
    "Watchung",
    "Water Mill",
    "Water Valley",
    "Waterboro",
    "Waterbury",
    "Waterbury",
    "Waterbury Center",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford",
    "Waterford Flat",
    "Waterford Works",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterman",
    "Waterport",
    "Waterproof",
    "Waters",
    "Watersmeet",
    "Watertown",
    "Watertown",
    "Watertown",
    "Watertown",
    "Watertown",
    "Watertown",
    "Watertown",
    "Waterville",
    "Waterville",
    "Waterville",
    "Waterville",
    "Waterville",
    "Waterville",
    "Waterville Valley",
    "Watervliet",
    "Watervliet",
    "Watford City",
    "Wathena",
    "Watkins",
    "Watkins Glen",
    "Watkinsville",
    "Watonga",
    "Watseka",
    "Watson",
    "Watson",
    "Watsontown",
    "Watsonville",
    "Watts",
    "Wattsburg",
    "Wattsville",
    "Waubun",
    "Wauchula",
    "Waucoma",
    "Wauconda",
    "Waukee",
    "Waukegan",
    "Waukesha",
    "Waukomis",
    "Waukon",
    "Waunakee",
    "Wauneta",
    "Waupaca",
    "Waupun",
    "Wauregan",
    "Waurika",
    "Wausa",
    "Wausau",
    "Wauseon",
    "Wautoma",
    "Waveland",
    "Waveland",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly",
    "Waverly Hall",
    "Wawaka",
    "Waxahachie",
    "Waxhaw",
    "Waycross",
    "Wayland",
    "Wayland",
    "Wayland",
    "Wayland",
    "Wayland",
    "Waymart",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne",
    "Wayne City",
    "Waynesboro",
    "Waynesboro",
    "Waynesboro",
    "Waynesboro",
    "Waynesboro",
    "Waynesburg",
    "Waynesburg",
    "Waynesburg",
    "Waynesfield",
    "Waynesville",
    "Waynesville",
    "Waynesville",
    "Waynesville",
    "Waynetown",
    "Waynoka",
    "Wayzata",
    "Weare",
    "Weatherford",
    "Weatherford",
    "Weatherly",
    "Weatogue",
    "Weaubleau",
    "Weaver",
    "Weaverville",
    "Weaverville",
    "Webb",
    "Webb",
    "Webb City",
    "Webbers Falls",
    "Webberville",
    "Weber City",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster",
    "Webster City",
    "Webster Springs",
    "Wedowee",
    "Weed",
    "Weedsport",
    "Weedville",
    "Weehawken",
    "Weeksbury",
    "Weems",
    "Weeping Water",
    "Weidman",
    "Weimar",
    "Weimar",
    "Weiner",
    "Weippe",
    "Weir",
    "Weir",
    "Weirsdale",
    "Weirton",
    "Weiser",
    "Welaka",
    "Welch",
    "Welch",
    "Welch",
    "Welches",
    "Welcome",
    "Welcome",
    "Welcome",
    "Weldon",
    "Weldon",
    "Weldon",
    "Weleetka",
    "Wellborn",
    "Wellborn",
    "Wellesley",
    "Wellesley Hills",
    "Wellfleet",
    "Wellford",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellington",
    "Wellman",
    "Wellman",
    "Wellpinit",
    "Wells",
    "Wells",
    "Wells",
    "Wells",
    "Wells",
    "Wells",
    "Wellsboro",
    "Wellsburg",
    "Wellsburg",
    "Wellsley",
    "Wellston",
    "Wellston",
    "Wellston",
    "Wellsville",
    "Wellsville",
    "Wellsville",
    "Wellsville",
    "Wellsville",
    "Wellsville",
    "Wellton",
    "Welsh",
    "Wenatchee",
    "Wendel",
    "Wendell",
    "Wendell",
    "Wendell",
    "Wendell",
    "Wenden",
    "Wendover",
    "Wenham",
    "Wenona",
    "Wenonah",
    "Wentworth",
    "Wentworth",
    "Wentzville",
    "Wernersville",
    "Weskan",
    "Weslaco",
    "Wesley",
    "Wesley Chapel",
    "Wessington",
    "Wessington Springs",
    "Wesson",
    "West",
    "West",
    "West Alexander",
    "West Alexandria",
    "West Alton",
    "West Babylon",
    "West Baldwin",
    "West Barnstable",
    "West Bend",
    "West Bend",
    "West Berlin",
    "West Blocton",
    "West Bloomfield",
    "West Boylston",
    "West Branch",
    "West Branch",
    "West Briar",
    "West Bridgewater",
    "West Brookfield",
    "West Burke",
    "West Burlington",
    "West Chatham",
    "West Chazy",
    "West Chester",
    "West Chester",
    "West Chester",
    "West Chesterfield",
    "West Chicago",
    "West Columbia",
    "West Columbia",
    "West Concord",
    "West Corinth",
    "West Cornwall",
    "West Cornwall",
    "West Covina",
    "West Coxsackie",
    "West Creek",
    "West Danville",
    "West Decatur",
    "West Dennis",
    "West Des Moines",
    "West Dover",
    "West Dummerston",
    "West Dundee",
    "West Durham",
    "West Edmeston",
    "West End",
    "West Enfield",
    "West Falls",
    "West Falmouth",
    "West Fargo",
    "West Farmington",
    "West Finley",
    "West Fork",
    "West Frankfort",
    "West Friendship",
    "West Glover",
    "West Granby",
    "West Green",
    "West Greenwich",
    "West Groton",
    "West Grove",
    "West Hamlin",
    "West Harrison",
    "West Hartford",
    "West Hartland",
    "West Harwich",
    "West Haven",
    "West Haverstraw",
    "West Helena",
    "West Hempstead",
    "West Henrietta",
    "West Hills",
    "West Hollywood",
    "West Islip",
    "West Jefferson",
    "West Jefferson",
    "West Jordan",
    "West Kingston",
    "West Lafayette",
    "West Lafayette",
    "West Lebanon",
    "West Lebanon",
    "West Leyden",
    "West Liberty",
    "West Liberty",
    "West Liberty",
    "West Linn",
    "West Long Branch",
    "West Louisville",
    "West Manchester",
    "West Mansfield",
    "West McLean",
    "West Memphis",
    "West Middlesex",
    "West Middleton",
    "West Mifflin",
    "West Milford",
    "West Milton",
    "West Mineral",
    "West Minot",
    "West Monroe",
    "West Monroe",
    "West New York",
    "West Newbury",
    "West Newbury",
    "West Newton",
    "West Newton",
    "West Newton",
    "West Nyack",
    "West Olive",
    "West Orange",
    "West Paducah",
    "West Palm Beach",
    "West Paris",
    "West Park",
    "West Pawlet",
    "West Pittsburg",
    "West Plains",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Point",
    "West Portsmouth",
    "West Richland",
    "West River",
    "West Rockport",
    "West Roxbury",
    "West Rutland",
    "West Sacramento",
    "West Salem",
    "West Salem",
    "West Salem",
    "West Sand Lake",
    "West Sayville",
    "West Shokan",
    "West Simsbury",
    "West Springfield",
    "West Springfield",
    "West Stockbridge",
    "West Stockholm",
    "West Suffield",
    "West Sunbury",
    "West Swanzey",
    "West Terre Haute",
    "West Tisbury",
    "West Topsham",
    "West Townsend",
    "West Union",
    "West Union",
    "West Union",
    "West Union",
    "West Union",
    "West Unity",
    "West Valley",
    "West Van Lear",
    "West Wardsboro",
    "West Wareham",
    "West Warwick",
    "West Wendover",
    "West Winfield",
    "West Yarmouth",
    "West Yellowstone",
    "Westboro",
    "Westboro",
    "Westborough",
    "Westbrook",
    "Westbrook",
    "Westbrook",
    "Westbrookville",
    "Westbury",
    "Westby",
    "Westby",
    "Westchester",
    "Westcliffe",
    "Westerlo",
    "Westerly",
    "Western Grove",
    "Western Springs",
    "Westernport",
    "Westernville",
    "Westerville",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield",
    "Westfield Center",
    "Westford",
    "Westford",
    "Westford",
    "Westgate",
    "Westhampton",
    "Westhampton Beach",
    "Westhope",
    "Westlake",
    "Westlake",
    "Westlake Village",
    "Westland",
    "Westland",
    "Westley",
    "Westminster",
    "Westminster",
    "Westminster",
    "Westminster",
    "Westminster",
    "Westminster",
    "Westmont",
    "Westmoreland",
    "Westmoreland",
    "Westmoreland",
    "Westmoreland",
    "Westmoreland City",
    "Weston",
    "Weston",
    "Weston",
    "Weston",
    "Weston",
    "Weston",
    "Weston",
    "Weston",
    "Westover",
    "Westphalia",
    "Westphalia",
    "Westphalia",
    "Westpoint",
    "Westport",
    "Westport",
    "Westport",
    "Westport",
    "Westport",
    "Westport",
    "Westside",
    "Westtown",
    "Westtown",
    "Westview",
    "Westville",
    "Westville",
    "Westville",
    "Westville",
    "Westville",
    "Westville",
    "Westwego",
    "Westwood",
    "Westwood",
    "Westwood",
    "Wethersfield",
    "Wetmore",
    "Wetumka",
    "Wetumpka",
    "Wewahitchka",
    "Wewoka",
    "Wexford",
    "Weyauwega",
    "Weyerhaeuser",
    "Weyers Cave",
    "Weymouth",
    "Whaleyville",
    "Wharncliffe",
    "Wharton",
    "Wharton",
    "Wharton",
    "Wharton",
    "What Cheer",
    "Whately",
    "Whatley",
    "Wheat Ridge",
    "Wheatfield",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheatland",
    "Wheaton",
    "Wheaton",
    "Wheeler",
    "Wheeler",
    "Wheeler",
    "Wheeler",
    "Wheelersburg",
    "Wheeling",
    "Wheeling",
    "Wheeling",
    "Wheelwright",
    "Whigham",
    "Whippany",
    "Whipple",
    "Whitakers",
    "White",
    "White",
    "White Bear Lake",
    "White Bird",
    "White Bluff",
    "White Castle",
    "White City",
    "White City",
    "White Cloud",
    "White Cloud",
    "White Deer",
    "White Hall",
    "White Hall",
    "White Hall",
    "White Haven",
    "White Heath",
    "White House",
    "White Lake",
    "White Lake",
    "White Lake",
    "White Marsh",
    "White Mountain Lake",
    "White Oak",
    "White Oak",
    "White Oak",
    "White Pigeon",
    "White Pine",
    "White Plains",
    "White Plains",
    "White Plains",
    "White Plains",
    "White Post",
    "White River Junction",
    "White Salmon",
    "White Sands",
    "White Springs",
    "White Stone",
    "White Sulphur Springs",
    "White Sulphur Springs",
    "White Swan",
    "White Water",
    "Whiteface",
    "Whitefield",
    "Whitefield",
    "Whitefield",
    "Whitefish",
    "Whiteford",
    "Whitehall",
    "Whitehall",
    "Whitehall",
    "Whitehall",
    "Whitehall Township",
    "Whitehouse",
    "Whitehouse",
    "Whitehouse",
    "Whitehouse Station",
    "Whiteland",
    "Whiteman Air Force Base",
    "Whiteriver",
    "Whites Creek",
    "Whitesboro",
    "Whitesboro",
    "Whitesburg",
    "Whitesburg",
    "Whitesburg",
    "Whiteside",
    "Whitestone",
    "Whitestown",
    "Whitesville",
    "Whitesville",
    "Whitesville",
    "Whitethorn",
    "Whiteville",
    "Whitewater",
    "Whitewater",
    "Whitewater",
    "Whitewright",
    "Whiting",
    "Whiting",
    "Whiting",
    "Whiting",
    "Whiting",
    "Whitingham",
    "Whitinsville",
    "Whitley City",
    "Whitman",
    "Whitman",
    "Whitman",
    "Whitmire",
    "Whitmore",
    "Whitmore Lake",
    "Whitney",
    "Whitney Point",
    "Whitsett",
    "Whittemore",
    "Whittemore",
    "Whittier",
    "Whittier",
    "Whittington",
    "Whitwell",
    "Wibaux",
    "Wichita",
    "Wichita Falls",
    "Wickenburg",
    "Wickes",
    "Wickliffe",
    "Wickliffe",
    "Wiconisco",
    "Widener",
    "Wiergate",
    "Wiggins",
    "Wiggins",
    "Wilber",
    "Wilberforce",
    "Wilbraham",
    "Wilbur",
    "Wilburton",
    "Wilburton",
    "Wilcox",
    "Wilcox",
    "Wild Rose",
    "Wilder",
    "Wilder",
    "Wilderville",
    "Wildomar",
    "Wildorado",
    "Wildwood",
    "Wildwood",
    "Wildwood",
    "Wildwood",
    "Wiley",
    "Wilkes Barre",
    "Wilkesboro",
    "Wilkinson",
    "Willacoochee",
    "Willamina",
    "Willapa",
    "Willard",
    "Willard",
    "Willard",
    "Willard",
    "Willcox",
    "Willet",
    "Williams",
    "Williams",
    "Williams",
    "Williams",
    "Williams",
    "Williams Bay",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsburg",
    "Williamsfield",
    "Williamson",
    "Williamson",
    "Williamson",
    "Williamsport",
    "Williamsport",
    "Williamsport",
    "Williamsport",
    "Williamsport",
    "Williamsport",
    "Williamston",
    "Williamston",
    "Williamston",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamstown",
    "Williamsville",
    "Williamsville",
    "Williamsville",
    "Willimantic",
    "Willingboro",
    "Willis",
    "Willis",
    "Willis",
    "Willis Wharf",
    "Willisburg",
    "Williston",
    "Williston",
    "Williston",
    "Williston",
    "Williston",
    "Williston",
    "Williston Park",
    "Willisville",
    "Willits",
    "Willmar",
    "Willoughby",
    "Willow Creek",
    "Willow Grove",
    "Willow Hill",
    "Willow Lake",
    "Willow River",
    "Willow Springs",
    "Willow Springs",
    "Willow Springs",
    "Willow Street",
    "Willowbrook",
    "Willows",
    "Wills Point",
    "Willsboro",
    "Willseyville",
    "Willshire",
    "Wilmar",
    "Wilmer",
    "Wilmer",
    "Wilmerding",
    "Wilmette",
    "Wilmington",
    "Wilmington",
    "Wilmington",
    "Wilmington",
    "Wilmington",
    "Wilmington",
    "Wilmington",
    "Wilmont",
    "Wilmore",
    "Wilmore",
    "Wilmot",
    "Wilmot",
    "Wilmot",
    "Wilsall",
    "Wilsey",
    "Wilseyville",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilson",
    "Wilsonville",
    "Wilsonville",
    "Wilton",
    "Wilton",
    "Wilton",
    "Wilton",
    "Wilton",
    "Wilton",
    "Wilton",
    "Wilton Manors",
    "Wimauma",
    "Wimberley",
    "Wimbledon",
    "Winamac",
    "Winchendon",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester",
    "Winchester Center",
    "Wind Gap",
    "Wind Ridge",
    "Windber",
    "Winder",
    "Windermere",
    "Windfall",
    "Windham",
    "Windham",
    "Windham",
    "Windham",
    "Windham Center",
    "Windom",
    "Windom",
    "Window Rock",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor Heights",
    "Windsor Locks",
    "Windsor Mill",
    "Windthorst",
    "Winesburg",
    "Winfield",
    "Winfield",
    "Winfield",
    "Winfield",
    "Winfield",
    "Winfield",
    "Winfield",
    "Winfield",
    "Wing",
    "Wing",
    "Wingate",
    "Wingate",
    "Wingdale",
    "Winger",
    "Wingina",
    "Wingo",
    "Winifrede",
    "Wink",
    "Winlock",
    "Winnebago",
    "Winnebago",
    "Winnebago",
    "Winneconne",
    "Winnemucca",
    "Winner",
    "Winnetka",
    "Winnetka",
    "Winnett",
    "Winnfield",
    "Winnie",
    "Winnsboro",
    "Winnsboro",
    "Winnsboro",
    "Winona",
    "Winona",
    "Winona",
    "Winona",
    "Winona Lake",
    "Winooski",
    "Winside",
    "Winslow",
    "Winslow",
    "Winslow",
    "Winslow",
    "Winslow Township",
    "Winsted",
    "Winsted",
    "Winston",
    "Winston",
    "Winston",
    "Winston",
    "Winston-Salem",
    "Winter",
    "Winter Garden",
    "Winter Harbor",
    "Winter Haven",
    "Winter Park",
    "Winter Park",
    "Winter Springs",
    "Winterhaven",
    "Winterport",
    "Winters",
    "Winters",
    "Winterset",
    "Winterthur",
    "Winterville",
    "Winterville",
    "Winthrop",
    "Winthrop",
    "Winthrop",
    "Winthrop",
    "Winthrop",
    "Winthrop",
    "Winthrop",
    "Winthrop Harbor",
    "Winton",
    "Winton",
    "Wiota",
    "Wirtz",
    "Wiscasset",
    "Wisconsin Dells",
    "Wisconsin Rapids",
    "Wisdom",
    "Wise",
    "Wishek",
    "Wisner",
    "Wisner",
    "Wister",
    "Withams",
    "Withee",
    "Witt",
    "Witten",
    "Wittenberg",
    "Wittensville",
    "Wittmann",
    "Wixom",
    "Woburn",
    "Woden",
    "Wofford Heights",
    "Wolbach",
    "Wolcott",
    "Wolcott",
    "Wolcott",
    "Wolcott",
    "Wolcottville",
    "Wolf Point",
    "Wolfe City",
    "Wolfeboro",
    "Wolfeboro Falls",
    "Wolfforth",
    "Wolford",
    "Wolverton",
    "Womelsdorf",
    "Wonder Lake",
    "Wonewoc",
    "Wood Dale",
    "Wood Lake",
    "Wood River",
    "Wood River",
    "Wood River Junction",
    "Wood-Ridge",
    "Woodacre",
    "Woodberry Forest",
    "Woodbine",
    "Woodbine",
    "Woodbine",
    "Woodbine",
    "Woodbine",
    "Woodbine",
    "Woodbourne",
    "Woodbridge",
    "Woodbridge",
    "Woodbridge",
    "Woodbridge",
    "Woodburn",
    "Woodburn",
    "Woodburn",
    "Woodburn",
    "Woodbury",
    "Woodbury",
    "Woodbury",
    "Woodbury",
    "Woodbury",
    "Woodbury Heights",
    "Woodcliff Lake",
    "Woodford",
    "Woodgate",
    "Woodhaven",
    "Woodhull",
    "Woodhull",
    "Woodinville",
    "Woodlake",
    "Woodlake",
    "Woodland",
    "Woodland",
    "Woodland",
    "Woodland",
    "Woodland",
    "Woodland",
    "Woodland",
    "Woodland Hills",
    "Woodland Mills",
    "Woodland Park",
    "Woodlawn",
    "Woodlawn",
    "Woodlawn",
    "Woodlawn",
    "Woodleaf",
    "Woodlyn",
    "Woodmere",
    "Woodridge",
    "Woodridge",
    "Woodruff",
    "Woodruff",
    "Woodruff",
    "Woodruff",
    "Woods Cross",
    "Woods Hole",
    "Woodsboro",
    "Woodsboro",
    "Woodsfield",
    "Woodside",
    "Woodside",
    "Woodson",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstock",
    "Woodstown",
    "Woodsville",
    "Woodville",
    "Woodville",
    "Woodville",
    "Woodville",
    "Woodville",
    "Woodward",
    "Woodward",
    "Woodward",
    "Woodway",
    "Woodworth",
    "Woodworth",
    "Woolstock",
    "Woolwich",
    "Woonsocket",
    "Woonsocket",
    "Wooster",
    "Wooton",
    "Worcester",
    "Worcester",
    "Worcester",
    "Worden",
    "Worden",
    "Worland",
    "Worley",
    "Worth",
    "Wortham",
    "Worthing",
    "Worthington",
    "Worthington",
    "Worthington",
    "Worthington",
    "Worthington",
    "Worthington",
    "Worthington Center",
    "Worthington Springs",
    "Worthville",
    "Worton",
    "Wray",
    "Wray",
    "Wrens",
    "Wrenshall",
    "Wrentham",
    "Wright",
    "Wright",
    "Wright City",
    "Wright City",
    "Wrightstown",
    "Wrightstown",
    "Wrightsville",
    "Wrightsville",
    "Wrightsville Beach",
    "Wrightwood",
    "Wurtsboro",
    "Wyaconda",
    "Wyalusing",
    "Wyandanch",
    "Wyandotte",
    "Wyandotte",
    "Wyanet",
    "Wyckoff",
    "Wyco",
    "Wye Mills",
    "Wykoff",
    "Wylie",
    "Wylliesburg",
    "Wymore",
    "Wynantskill",
    "Wyncote",
    "Wyndmere",
    "Wynne",
    "Wynnewood",
    "Wynnewood",
    "Wynona",
    "Wynot",
    "Wyocena",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wyoming",
    "Wysox",
    "Wytheville",
    "Xenia",
    "Xenia",
    "Yachats",
    "Yacolt",
    "Yadkinville",
    "Yakima",
    "Yalaha",
    "Yale",
    "Yale",
    "Yale",
    "Yale",
    "Yamhill",
    "Yampa",
    "Yanceyville",
    "Yankeetown",
    "Yankton",
    "Yantis",
    "Yaphank",
    "Yarmouth",
    "Yarmouth",
    "Yarmouth Port",
    "Yates Center",
    "Yates City",
    "Yatesboro",
    "Yatesville",
    "Yazoo City",
    "Yeaddiss",
    "Yellow Spring",
    "Yellow Springs",
    "Yellville",
    "Yelm",
    "Yemassee",
    "Yerington",
    "Yermo",
    "Yoakum",
    "Yoder",
    "Yoder",
    "Yolyn",
    "Yoncalla",
    "Yonkers",
    "Yorba Linda",
    "York",
    "York",
    "York",
    "York",
    "York",
    "York Beach",
    "York Corner",
    "York Harbor",
    "York Haven",
    "York Springs",
    "Yorkshire",
    "Yorktown",
    "Yorktown",
    "Yorktown",
    "Yorktown Heights",
    "Yorkville",
    "Yorkville",
    "Yorkville",
    "Yosemite Valley",
    "Young",
    "Young America",
    "Young Harris",
    "Youngstown",
    "Youngstown",
    "Youngstown",
    "Youngstown",
    "Youngsville",
    "Youngsville",
    "Youngsville",
    "Youngtown",
    "Youngwood",
    "Yountville",
    "Ypsilanti",
    "Ypsilanti",
    "Yreka",
    "Yuba City",
    "Yucaipa",
    "Yucca",
    "Yucca Valley",
    "Yukon",
    "Yukon",
    "Yulan",
    "Yulee",
    "Yuma",
    "Yuma",
    "Yuma",
    "Yutan",
    "Zachary",
    "Zaleski",
    "Zalma",
    "Zanesfield",
    "Zanesville",
    "Zanesville",
    "Zapata",
    "Zarephath",
    "Zavalla",
    "Zearing",
    "Zebulon",
    "Zebulon",
    "Zeeland",
    "Zeeland",
    "Zeigler",
    "Zelienople",
    "Zellwood",
    "Zenda",
    "Zephyr",
    "Zephyr Cove",
    "Zephyrhills",
    "Zieglersville",
    "Zillah",
    "Zimmerman",
    "Zion",
    "Zion Grove",
    "Zionhill",
    "Zionsville",
    "Zionsville",
    "Zionville",
    "Zirconia",
    "Zolfo Springs",
    "Zortman",
    "Zullinger",
    "Zumbrota",
    "Zuni",
    "Zurich",
    "Zwingle",
  ],
  Uruguay: [
    "Barra de Carrasco",
    "Canelones",
    "Colonia del Sacramento",
    "Durazno",
    "Florida",
    "La Floresta",
    "La Paz",
    "Las Piedras",
    "Maldonado",
    "Mercedes",
    "Montevideo",
    "Punta del Este",
    "Salto",
    "San Carlos",
    "Toledo",
    "Union",
  ],
  Venezuela: [
    "Acarigua",
    "Anaco",
    "Araure",
    "Bachaquero",
    "Barcelona",
    "Barinas",
    "Barquisimeto",
    "Bejuma",
    "Bolivar",
    "Cabimas",
    "Cabudare",
    "Cagua",
    "Caja de Agua",
    "Calabozo",
    "Campo de Carabobo",
    "Caraballeda",
    "Caracas",
    "Caripe",
    "Carrizal",
    "Catia La Mar",
    "Charallave",
    "Chivacoa",
    "Ciudad Guayana",
    "Ciudad Ojeda",
    "Ciudad Piar",
    "Colonia Tovar",
    "Coro",
    "Cua",
    "Curazaito",
    "El Chorro",
    "El Recreo",
    "El Roque",
    "El Rosal",
    "El Tigre",
    "El Vigia",
    "Guachara",
    "Guanare",
    "Guarenas",
    "Guatire",
    "Guayana",
    "Gueiria",
    "La Castellana",
    "La Cruz",
    "La Guaira",
    "La Urbina",
    "La Verdad",
    "La Victoria",
    "La Villa del Rosario",
    "Londres",
    "Los Chorros",
    "Los Teques",
    "Macuto",
    "Maiquetia",
    "Maracaibo",
    "Maracay",
    "Maria",
    "Mariguitar",
    "Matanzas",
    "Miranda",
    "Monte Cristo",
    "Naguanagua",
    "Ocumare",
    "Pampatar",
    "Porlamar",
    "Puerto Cabello",
    "Puerto La Cruz",
    "Puerto Ordaz and San Felix",
    "Punto Fijo",
    "Sabaneta",
    "San Antonio",
    "San Antonio de Los Altos",
    "San Diego",
    "San Felipe",
    "San Felix",
    "San Francisco",
    "San Joaquin",
    "San Jose de Guanipa",
    "Santa Cruz del Zulia",
    "Santa Fe",
    "Santa Teresa",
    "Solano",
    "Tariba",
    "Torre",
    "Trujillo",
    "Tucupita",
    "Turmero",
    "Valencia",
    "Valera",
    "Valle",
    "Vargas",
  ],
  Vietnam: [
    "An Dinh",
    "An Giang",
    "An Nhon",
    "An Tam",
    "An Thanh",
    "Ap Sai Gon",
    "Ap Trung",
    "Bac Giang",
    "Bac Kan",
    "Bac Ninh",
    "Bach Ma",
    "Bang",
    "Ben Nghe",
    "Ben Than",
    "Bien Hoa",
    "Binh Phuoc",
    "Binh Thanh",
    "Binh Thoai",
    "Buon Ma Thuot",
    "Ca Mau",
    "Cam Pha",
    "Can Tho",
    "Cho Ha",
    "Chuong Duong",
    "Co Giang",
    "Da Lat",
    "Da Nang",
    "Dac Loc",
    "Di An",
    "Dien Bien Phu",
    "Dinh Long",
    "Dong",
    "Dong Nai",
    "Dong Thap",
    "Duc Thang",
    "Giong Ngai",
    "Giong Truong",
    "Go Vap",
    "Ha Dong",
    "Ha Long",
    "Ha Nam",
    "Ha Tinh",
    "Haiphong",
    "Hanoi",
    "Ho Chi Minh City",
    "Hoang Mai",
    "Hung Yen",
    "Huong Tinh",
    "Khanh Hoa",
    "Kon Tum",
    "Lang Dung",
    "Lang Hong",
    "Lang Son",
    "Lao Cai",
    "Le Loi",
    "Long An",
    "Long Tan",
    "Long Van",
    "Luong Hoi",
    "Minh Khai",
    "Nghi Loc",
    "Nha Be",
    "Nha Trang",
    "Phan Thon",
    "Phu Nhuan",
    "Phu Tho",
    "Phu Xuyen",
    "Phu Yen",
    "Phuong Tan",
    "Pleiku",
    "Quan Ngoc",
    "Quan Trang",
    "Quang Nam",
    "Quang Tri",
    "Quang Trung",
    "Qui Nhon",
    "Soc Trang",
    "Tam Ky",
    "Tan Binh",
    "Tan Thuan Dong",
    "Tang",
    "Tay Ninh",
    "Thach That",
    "Thanh Nguyen",
    "Thanh Pho Ha Giang",
    "Thanh Pho Hai Duong",
    "Thanh Pho Hoa Binh",
    "Thanh Pho Ninh Binh",
    "Thanh Pho Thai Binh",
    "Thanh Pho Thai Nguyen",
    "Thanh Pho Tuyen Quang",
    "Thon Bao An",
    "Thong Nhat",
    "Thu Dau Mot",
    "Thu Duc",
    "Thuan An",
    "Thuan Cong",
    "Tra Vinh",
    "Trao",
    "Truong Lam",
    "Truong Son",
    "Van Hai",
    "Van Tan",
    "Vi Thanh",
    "Vinh",
    "Vinh Long",
    "Vinh Yen",
    "Xom Phuoc My",
    "Xom Thang",
    "Xom Truong Dinh",
    "Yen Bai",
    "Yen Thanh",
  ],
  Zambia: [
    "Kalomo",
    "Kitwe",
    "Livingstone",
    "Lusaka",
    "Macha",
    "Mumbwa",
    "Ndola",
    "Siavonga",
    "Solwezi",
  ],
  Zimbabwe: [
    "Bulawayo",
    "Chinhoyi",
    "Greendale",
    "Gwanda",
    "Harare",
    "Kwekwe",
    "Mufakose",
    "Mutare",
    "Victoria Falls",
  ],
};
