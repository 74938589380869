import React, { useState, useEffect } from "react";
import { iosArrowDown } from "../util/ui_util";
import colors from "../util/colors";

export const DropdownWithSearch = ({ items, onItemSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [search, setSearch] = useState("");
  const [displayedItems, setDisplayedItems] = useState(items);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    setSearch("");
    onItemSelected(item);
  };

  useEffect(() => {
    setSelectedItem(items[0]);
  }, [items]);

  useEffect(() => {
    const newItems = items.filter((i) =>
      i.toLowerCase().includes(search.toLowerCase())
    );
    setDisplayedItems(newItems);
  }, [search, items]);

  return (
    <div
      className="relative inline-block text-left w-full"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <button
        onClick={toggleDropdown}
        className={`inline-flex justify-center items-center w-full h-12 shadow-sm px-4 py-2 bg-white text-s focus:outline-none ${
          isOpen
            ? "rounded-t-md border-t border-r border-l"
            : "rounded-md border"
        } ${isHovered ? "border-gray-400" : "border-gray-300"}`}
      >
        <div className="flex flex-row w-full justify-between items-center font-medium text-text">
          {selectedItem}
          {iosArrowDown({ fillColor: colors.text })}
        </div>
      </button>
      <div
        className={`absolute flex flex-col right-0 w-full rounded-b-md shadow-lg bg-white z-30 transition-max-height duration-300 ease-in-out ${
          isHovered ? "border-gray-400" : "border-gray-300"
        }  ${isOpen ? "h-48 border-l border-b border-r" : "h-0"}`}
      >
        {isOpen && (
          <div className="flex flex-row px-3">
            <input
              type={"text"}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder={"Search"}
              className="bg-gray-100 lg:px-4 px-2 py-2 sm:text-sm w-full focus:outline-none"
            />
          </div>
        )}
        <ul className="overflow-y-auto mt-2">
          {displayedItems.map((item, index) => (
            <li
              key={index}
              className="px-4 py-2 text-sm text-text hover:bg-gray-200 cursor-pointer"
              onClick={() => {
                handleItemClick(item);
              }}
            >
              {selectedItem === item ? `✓ ${item}` : item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
