import React, { useEffect, useContext } from "react";
import { HomeCategory } from "./HomeCategory";
import { Layout } from "../../components/Layout";
import { HomeSlider } from "./HomeSlider";
import { HomeContext } from "../../context/home/HomeState";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { ItemHomeAd } from "./items/ItemHomeAd";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";
import { ItemHomeCategory } from "./items/ItemHomeCategory";

export const HomePage = () => {
  const {
    ads,
    newArrivals,
    getNewArrivals,
    getAds,
    featuredCategories,
    getFeaturedCategories,
  } = useContext(HomeContext);

  const { categories, language } = useContext(AppContext);
  const isRtl = language === "ar";

  const { showError } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    getAds();
    getFeaturedCategories();
    getNewArrivals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTopCategories = () => {
    return (
      <div
        className="flex flex-row space-x-2 rtl:space-x-reverse mt-16 overflow-x-scroll"
        dir={isRtl ? "rtl" : "ltr"}
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {categories.data.map((item, index) => (
          <ItemHomeCategory category={item} key={index} />
        ))}
      </div>
    );
  };

  const renderNewArrivals = () => {
    const category = {
      category: {
        name: t("newArrivals"),
      },
      products: newArrivals.data,
    };
    return (
      <div className="mt-16">
        <HomeCategory category={category} />
      </div>
    );
  };

  const renderCategoriesAndAds = () => {
    const mAds = ads.data;
    const categories = featuredCategories.data;
    const result = [];
    const maxAdsIndex = mAds.length - 1;
    const maxCategoriesIndex = categories.length - 1;
    let adIndex = 0;
    let categoryIndex = 0;

    while (adIndex <= maxAdsIndex || categoryIndex <= maxCategoriesIndex) {
      // Add up to 2 ads in a row
      const adRow = [];
      for (let i = 0; i < 2 && adIndex <= maxAdsIndex; i++) {
        adRow.push(<ItemHomeAd ad={mAds[adIndex]} key={`ad-${adIndex}`} />);
        adIndex++;
      }
      if (adRow.length > 0) {
        result.push(
          <div
            className="flex flex-col lg:flex-row items-center space-y-2 lg:space-y-0 lg:space-x-4 rtl:space-x-reverse"
            key={`ad-row-${adIndex}`}
          >
            {adRow}
          </div>
        );
      }

      // Add one category
      if (categoryIndex <= maxCategoriesIndex) {
        result.push(
          <HomeCategory
            category={categories[categoryIndex]}
            key={`category-${categoryIndex}`}
          />
        );
        categoryIndex++;
      }
    }

    return <div className="flex flex-col space-y-16 mt-16">{result}</div>;
  };

  useEffect(() => {
    if (newArrivals.error) {
      showError(newArrivals.error);
    }
    if (featuredCategories.error) {
      showError(featuredCategories.error);
    }
  }, [newArrivals.error, featuredCategories.error, showError]);

  return (
    <Layout>
      <div className="flex flex-col mt-4">
        <HomeSlider />
        {categories.loading || categories.error ? <></> : renderTopCategories()}
        {newArrivals.loading || newArrivals.error ? <></> : renderNewArrivals()}
        {ads.loading ||
        ads.error ||
        featuredCategories.loading ||
        featuredCategories.error ? (
          <></>
        ) : (
          renderCategoriesAndAds()
        )}
      </div>
    </Layout>
  );
};
