import React, { useContext } from "react";
import { CheckoutContext } from "../../../context/checkout/CheckoutState";
import { iconRadioChecked, iconRadioUnchecked } from "../../../util/ui_util";
import { AppContext } from "../../../context/app/AppState";
import colors from "../../../util/colors";

export const ItemCheckoutPaymentMethod = ({ paymentMethod }) => {
  const { config, selectedPaymentMethod, setSelectedPaymentMethod } =
    useContext(CheckoutContext);
  const isSelected = selectedPaymentMethod?.gateway === paymentMethod.gateway;
  const { primary } = useContext(AppContext);

  return (
    <div
      className={`flex flex-row justify-between items-center rounded-md p-2 border cursor-pointer`}
      style={{ borderColor: isSelected ? primary : colors.text }}
      onClick={() => {
        setSelectedPaymentMethod(paymentMethod);
      }}
    >
      <div className="flex flex-row space-x-2 min-h-8 rtl:space-x-reverse items-center">
        {isSelected
          ? iconRadioChecked({ fillColor: primary, width: "2em" })
          : iconRadioUnchecked({ fillColor: colors.text, width: "2em" })}
        {paymentMethod.gateway_image && (
          <img
            src={`${config.data.base_urls.gateway_image_url}/${paymentMethod.gateway_image}`}
            alt=""
            className="h-8 object-scale-down"
          />
        )}
        <div className="text-text pt-1">{paymentMethod.gateway_title}</div>
      </div>
    </div>
  );
};
