const ChatReducer = (state, action) => {
  switch (action.type) {
    case "GET_CHAT_REQUEST":
      return {
        ...state,
        chat: {
          ...state.chat,
          loading: true,
          error: null,
        },
      };

    case "GET_CHAT_SUCCESS":
      return {
        ...state,
        chat: {
          ...state.chat,
          loading: false,
          data: action.append
            ? [...action.payload.chat, ...state.chat.data]
            : action.payload.chat,
          error: null,
          page: action.payload.page,
          hasMore: action.payload.hasMore,
        },
      };

    case "GET_CHAT_FAILURE":
      return {
        ...state,
        chat: {
          ...state.chat,
          loading: false,
          error: action.payload,
          hasMore: false,
        },
      };

    case "SEND_MESSAGE_REQUEST":
      return {
        ...state,
        sentMessage: {
          ...state.sentMessage,
          loading: true,
          error: null,
        },
      };

    case "SEND_MESSAGE_SUCCESS":
      return {
        ...state,
        sentMessage: {
          ...state.sentMessage,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "SEND_MESSAGE_FAILURE":
      return {
        ...state,
        sentMessage: {
          ...state.sentMessage,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ChatReducer;
