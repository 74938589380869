const AppReducer = (state, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };

    case "SET_STOP_SCROLL":
      return {
        ...state,
        stopScroll: action.payload,
      };

    case "SET_SHOW_LOGIN_DIALOG":
      return {
        ...state,
        showLoginDialog: action.payload,
        stopScroll: action.payload,
      };

    case "SET_SHOW_SEARCH_DIALOG":
      return {
        ...state,
        showSearchDialog: action.payload,
        stopScroll: action.payload,
      };

    case "SET_SHOW_PRODUCT_QUICK_VIEW_DIALOG":
      return {
        ...state,
        showProductQuickViewDialog: action.payload,
        stopScroll: action.payload.show,
      };

    case "SET_SHOW_ACCOUNT_NAVIGATION":
      return {
        ...state,
        showAccountNavigation: action.payload,
      };

    case "GET_CONFIG_REQUEST":
      return {
        ...state,
        config: {
          ...state.config,
          loading: true,
          error: null,
        },
      };

    case "GET_CONFIG_SUCCESS":
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          data: action.payload,
          error: null,
        },
        // primary: "#BB8713",
        // primaryDark: "#9a7010",
        primary: action.payload.primary_color,
        primaryDark: action.payload.primary_color_dark,
      };

    case "GET_CONFIG_FAILURE":
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_EXTRA_PAGES_REQUEST":
      return {
        ...state,
        extraPages: {
          ...state.extraPages,
          loading: true,
          error: null,
        },
      };

    case "GET_EXTRA_PAGES_SUCCESS":
      return {
        ...state,
        extraPages: {
          ...state.extraPages,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_EXTRA_PAGES_FAILURE":
      return {
        ...state,
        extraPages: {
          ...state.extraPages,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_USER_REQUEST":
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          error: null,
        },
      };

    case "GET_USER_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_USER_FAILURE":
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: action.payload,
        },
      };

    case "REGISTER_REQUEST":
      return {
        ...state,
        token: {
          ...state.token,
          loading: true,
          error: null,
        },
      };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        token: {
          ...state.token,
          loading: false,
          data: action.payload,
          error: null,
        },
        isAuthenticated: true,
        showLoginDialog: false,
        stopScroll: false,
      };

    case "REGISTER_FAILURE":
      return {
        ...state,
        token: {
          ...state.token,
          loading: false,
          error: action.payload,
        },
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        token: {
          ...state.token,
          data: null,
          loading: false,
          error: null,
        },
      };

    case "GET_NEWS_BANNER_REQUEST":
      return {
        ...state,
        newsBanner: {
          ...state.newsBanner,
          loading: true,
          error: null,
        },
      };

    case "GET_NEWS_BANNER_SUCCESS":
      return {
        ...state,
        newsBanner: {
          ...state.newsBanner,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_NEWS_BANNER_FAILURE":
      return {
        ...state,
        newsBanner: {
          ...state.newsBanner,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_CATEGORIES_REQUEST":
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          error: null,
        },
      };

    case "GET_CATEGORIES_SUCCESS":
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_CATEGORIES_FAILURE":
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          error: action.payload,
        },
      };

    case "UPDATE_PROFILE_REQUEST":
      return {
        ...state,
        updatedProfile: {
          ...state.updatedProfile,
          loading: true,
          error: null,
        },
      };

    case "UPDATE_PROFILE_SUCCESS":
      return {
        ...state,
        updatedProfile: {
          ...state.updatedProfile,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "UPDATE_PROFILE_FAILURE":
      return {
        ...state,
        updatedProfile: {
          ...state.updatedProfile,
          loading: false,
          error: action.payload,
        },
      };

    case "REMOVE_ACCOUNT_REQUEST":
      return {
        ...state,
        removedAccount: {
          ...state.removedAccount,
          loading: true,
          error: null,
        },
      };

    case "REMOVE_ACCOUNT_SUCCESS":
      return {
        ...state,
        removedAccount: {
          ...state.removedAccount,
          loading: false,
          data: action.payload,
          error: null,
        },
        isAuthenticated: false,
        token: {
          ...state.token,
          data: null,
          loading: false,
          error: null,
        },
      };

    case "REMOVE_ACCOUNT_FAILURE":
      return {
        ...state,
        removedAccount: {
          ...state.removedAccount,
          loading: false,
          error: action.payload,
        },
      };

    case "SEARCH_REQUEST":
      return {
        ...state,
        searchProducts: {
          ...state.searchProducts,
          loading: true,
          error: null,
        },
      };

    case "SEARCH_SUCCESS":
      return {
        ...state,
        searchProducts: {
          ...state.searchProducts,
          loading: false,
          data: action.append
            ? [...state.searchProducts.data, ...action.payload.searchProducts]
            : action.payload.searchProducts,
          error: null,
          page: action.payload.page,
          hasMore: action.payload.hasMore,
        },
      };

    case "SEARCH_FAILURE":
      return {
        ...state,
        searchProducts: {
          ...state.searchProducts,
          loading: false,
          error: action.payload,
          hasMore: false,
        },
      };

    case "SUBSCRIBE_TO_NEWSLETTER_REQUEST":
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          loading: true,
          error: null,
        },
      };

    case "SUBSCRIBE_TO_NEWSLETTER_SUCCESS":
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "SUBSCRIBE_TO_NEWSLETTER_FAILURE":
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AppReducer;
