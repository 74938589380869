import React, { useContext } from "react";
import { getFormattedPrice } from "../util/ui_util";
import { AppContext } from "../context/app/AppState";
import { getDiscountedPrice } from "../util/util";
import { useNavigate } from "react-router-dom";

export const ItemSearch = ({ product }) => {
  const { language, setShowSearchDialog } = useContext(AppContext);
  const navigate = useNavigate();
  const discountedPrice = getDiscountedPrice(product);
  const hasDiscount = product.discount !== 0;

  const handleClick = () => {
    navigate(`/product/${product.id}`);
    setShowSearchDialog(false);
  };

  return (
    <div
      className="flex flex-row w-full space-x-2 rtl:space-x-reverse mt-4 cursor-pointer select-none"
      onClick={handleClick}
    >
      <img
        src={product.image_fullpath[0]}
        alt=""
        className="w-[96px] rounded-md"
      />
      <div className="flex flex-col">
        <div className="text-text font-bold">{product.name}</div>
        <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
          <div
            className={`text-text ${hasDiscount ? "font-bold" : ""} text-sm`}
          >
            {getFormattedPrice(discountedPrice, language)}
          </div>
          <div
            className={`${hasDiscount ? "block" : "hidden"} ${
              hasDiscount ? "line-through" : ""
            } text-sm text-gray-300`}
          >
            {getFormattedPrice(product.price, language)}
          </div>
        </div>
      </div>
    </div>
  );
};
