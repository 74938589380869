import React, { useContext } from "react";
import { iconNotifications } from "../../../util/ui_util";
import colors from "../../../util/colors";
import { timeAgo } from "../../../util/util";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";

export const ItemNotification = ({ notification }) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  return (
    <div>
      <div className="w-full hidden md:flex flex-row justify-between items-center cursor-pointer p-8 relative">
        <div className="absolute inset-0 bg-white shadow-lg transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100"></div>
        <div className="flex flex-row space-x-2 relative z-10">
          {iconNotifications({
            fillColor: colors.danger,
            width: "24px",
            height: "24px",
          })}
          <div className="flex flex-col">
            <div className="text-text font-bold">{notification.title}</div>
            <div className="text-text text-sm">{notification.description}</div>
          </div>
        </div>
        <div className="text-text relative z-10">
          {timeAgo(notification.created_at, t, language)}
        </div>
      </div>
      {/* Phone layout */}
      <div className="w-full bg-white flex md:hidden flex-row p-4">
        {iconNotifications({
          fillColor: colors.danger,
          width: "24px",
          height: "24px",
        })}
        <div className="flex flex-col">
          <div className="text-text font-bold">{notification.title}</div>
          <div className="text-text text-sm">{notification.description}</div>
          <div className="text-text">
            {timeAgo(notification.created_at, t, language)}
          </div>
        </div>
      </div>
    </div>
  );
};
