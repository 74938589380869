import React, { createContext, useContext, useState, useCallback } from "react";
import "../../assets/styles/snackbar.css";

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: "",
    isVisible: false,
    type: "",
  });

  const showSnackbar = useCallback((message, type) => {
    setSnackbar({ message, isVisible: true, type });
    setTimeout(() => {
      setSnackbar({ message: "", isVisible: false, type: "" });
    }, 3000);
  }, []);

  const showSuccess = useCallback(
    (message) => showSnackbar(message, "success"),
    [showSnackbar]
  );
  const showError = useCallback(
    (message) => showSnackbar(message, "error"),
    [showSnackbar]
  );

  return (
    <SnackbarContext.Provider value={{ showSuccess, showError }}>
      {children}
      {snackbar.isVisible && (
        <div
          className={`fixed top-0 left-0 m-4 p-2 rounded-md shadow ${
            snackbar.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white font-bold z-50`}
        >
          {snackbar.message}
          <div className="w-full h-1 bg-gray-300 mt-2">
            <div className="h-full bg-white animate-timer"></div>
          </div>
        </div>
      )}
    </SnackbarContext.Provider>
  );
};
