import React, { useContext, useEffect } from "react";
import { ProductContext } from "../../context/product/ProductState";
import { ItemProductOption } from "./items/ItemProductOption";

export const ProductOptions = ({ setQuantity }) => {
  const { product, setSelectedVariation } = useContext(ProductContext);
  let selectedOptions = product.data.product.choice_options.reduce(
    (acc, choice) => {
      acc[choice.name] = choice.options[0];
      return acc;
    },
    {}
  );
  const variations = product.data.product.variations;

  useEffect(() => {
    setSelectedVariation(variations[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionChange = (choiceName, selectedOption) => {
    selectedOptions = {
      ...selectedOptions,
      [choiceName]: selectedOption,
    };
    const selectedOptionsString = Object.values(selectedOptions)
      .map((option) => option.replace(/\s+/g, ""))
      .join("-");
    const selectedVariation = variations.find((variation) => {
      return selectedOptionsString === variation.type;
    });
    setSelectedVariation(selectedVariation);
    setQuantity(1);
  };

  return (
    <div className="flex flex-col space-y-2">
      {product.data.product.choice_options.map((option, index) => (
        <ItemProductOption
          option={option}
          onOptionChange={(selectedOption) =>
            handleOptionChange(option.name, selectedOption)
          }
          key={index}
        />
      ))}
    </div>
  );
};
