import React, { useContext } from "react";
import { AppContext } from "../context/app/AppState";

export const AppTextField = ({
  placeholder,
  type,
  onChange,
  value,
  error,
  pattern,
  disabled = false,
}) => {
  const { language } = useContext(AppContext);
  const isRtl = language === "ar";
  return (
    <div className="w-full">
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="border border-gray-300 rounded-md lg:px-4 px-2 py-2  focus:outline-none w-full h-12 sm:text-sm focus-border-primary"
        placeholder={placeholder}
        pattern={pattern}
        disabled={disabled}
        dir={isRtl ? "rtl" : "ltr"}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
