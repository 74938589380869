import { v4 as uuidv4 } from "uuid";
import { getDiscountedPrice } from "./util";

export const getCart = () => {
  const cart = localStorage.getItem("cart");
  return cart ? JSON.parse(cart) : [];
};

export const saveCart = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
  window.dispatchEvent(new StorageEvent("custom-storage-event-name"));
};

export const clearCartUtil = () => {
  saveCart([]);
};

export const addToCartUtil = (product, quantity) => {
  const cart = getCart();
  const cartItem = {
    id: uuidv4(),
    product,
    quantity,
  };
  cart.push(cartItem);
  saveCart(cart);
  return cartItem;
};

export const addToCartWithVariationUtil = (product, quantity, variation) => {
  const cart = getCart();
  const cartItem = {
    id: uuidv4(),
    product,
    quantity,
    variation,
  };
  cart.push(cartItem);
  saveCart(cart);
  return cartItem;
};

export const removeFromCartUtil = (itemId) => {
  const cart = getCart();
  const updatedCart = cart.filter((item) => item.id !== itemId);
  saveCart(updatedCart);
};

export const editProductQuantityUtil = (itemId, quantity) => {
  const cart = getCart();
  const itemIndex = cart.findIndex((item) => item.id === itemId);
  if (itemIndex !== -1) {
    cart[itemIndex].quantity = quantity;
    saveCart(cart);
  }
};

export const getProductCartQuantityUtil = (productId) => {
  const cart = getCart();
  const quantity = cart
    .filter((item) => item.product.id === productId)
    .reduce((sum, item) => sum + item.quantity, 0);
  return quantity;
};

export const getCartSubtotalUtil = () => {
  const cart = getCart();
  const subtotal = cart.reduce((total, item) => {
    const oldPrice = item.variation ? item.variation.price : item.product.price;
    const price = getDiscountedPrice({
      price: oldPrice,
      discount_type: item.product.discount_type,
      discount: item.product.discount,
    });
    return total + price * item.quantity;
  }, 0);
  return subtotal;
};

export const getCartTaxUtil = () => {
  const cart = getCart();
  const tax = cart.reduce((total, item) => {
    const oldPrice = item.variation ? item.variation.price : item.product.price;
    const price = getDiscountedPrice({
      price: oldPrice,
      discount_type: item.product.discount_type,
      discount: item.product.discount,
    });
    const productTax = (price * item.product.tax) / 100;
    return total + productTax * item.quantity;
  }, 0);
  return Math.round(tax);
};
