import React, { useState, useEffect, useContext } from "react";
import { Banner } from "./Banner";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Drawer } from "./Drawer";
import { DialogLogin } from "./DialogLogin";
import { AppContext } from "../context/app/AppState";
import { DialogSearch } from "./DialogSearch";
import { DialogProductQuickView } from "./DialogProductQuickView";

export const Layout = ({ children }) => {
  const isDesktop = window.innerWidth >= 1024;

  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    showLoginDialog,
    showSearchDialog,
    showProductQuickViewDialog,
    setShowProductQuickViewDialog,
    stopScroll,
    language,
    categories,
    getCategories,
    getConfig,
    getExtraPages,
  } = useContext(AppContext);
  const isRtl = language === "ar";

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    getCategories();
    getConfig();
    getExtraPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    if (stopScroll) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [stopScroll]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsOpen(false);
        setShowProductQuickViewDialog({ show: false, product: null });
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex flex-col flex-grow">
        <Banner />
        {categories && !categories.loading ? (
          <NavigationBar
            isDesktop={isDesktop}
            isRtl={isRtl}
            isOpen={isOpen}
            categories={categories.data}
            onBurgerMenuClick={() => {
              setIsOpen(!isOpen);
            }}
            showAccountNavigationScroll={!isSticky}
          />
        ) : (
          <div className="h-12"></div>
        )}
        <div className="container mx-auto">{children}</div>
      </div>
      <Footer isRtl={isRtl} />
      <div
        className={`fixed top-0 left-0 w-full bg-white shadow-md z-30 transition-transform duration-300 transform ${
          isSticky ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <Banner />
        {categories && !categories.loading ? (
          <NavigationBar
            isDesktop={isDesktop}
            isRtl={isRtl}
            isOpen={isOpen}
            categories={categories.data}
            onBurgerMenuClick={() => {
              setIsOpen(!isOpen);
            }}
            showAccountNavigationScroll={isSticky}
          />
        ) : (
          <></>
        )}
      </div>
      {categories && !categories.loading ? (
        <Drawer
          isOpen={isOpen}
          isRtl={isRtl}
          allCategories={categories.data}
          onCloseClick={() => setIsOpen(false)}
        />
      ) : (
        <></>
      )}
      {showProductQuickViewDialog?.show && <DialogProductQuickView />}
      {showSearchDialog && <DialogSearch />}
      {showLoginDialog && <DialogLogin />}
    </div>
  );
};
