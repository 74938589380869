import React from "react";
import { Link } from "react-router-dom";

export const ItemHomeAd = ({ ad }) => {
  return (
    <Link
      to={
        ad.category_id
          ? `/category/${ad.category_id}`
          : ad.product_id
          ? `/product/${ad.product_id}`
          : ""
      }
    >
      <div className="px-4 mt-4">
        <img
          src={ad.image_fullpath}
          alt=""
          className="rounded-2xl object-cover w-full"
        />
      </div>
    </Link>
  );
};
