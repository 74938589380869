import React, { useContext, useEffect } from "react";
import { Layout } from "../../components/Layout";
import { AppContext } from "../../context/app/AppState";
import { defaultLoader } from "../../util/ui_util";
import { useSnackbar } from "../../context/general/SnackbarContext";

export const ReturnPolicyPage = () => {
  const { extraPages, language } = useContext(AppContext);
  const { showError } = useSnackbar();
  const isRtl = language === "ar";

  useEffect(() => {
    if (extraPages?.error) {
      showError(extraPages.error);
    }
  }, [extraPages, showError]);

  return (
    <Layout>
      {extraPages?.loading ? (
        defaultLoader()
      ) : (
        <div
          dir={isRtl ? "rtl" : "ltr"}
          dangerouslySetInnerHTML={{
            __html: extraPages?.data.return_page?.content,
          }}
        ></div>
      )}
    </Layout>
  );
};
