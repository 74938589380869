import React, { useContext } from "react";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { CheckoutAddressStage1 } from "./CheckoutAddressStage1";
import { getAddressString } from "./items/ItemCheckoutAddress";
import { CheckoutAddressStage2 } from "./CheckoutAddressStage2";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";

export const CheckoutAddress = () => {
  const {
    stage,
    setStage,
    isPickup,
    selectedAddressId,
    selectedBranch,
    addresses,
    selectedPaymentMethod,
  } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const { primary } = useContext(AppContext);

  const stage1Folded = () => {
    return (
      <div className="flex flex-row px-4 justify-between items-center">
        <div className="flex flex-row space-x-2 rtl:space-x-reverse">
          <div
            className="flex flex-row items-center justify-center w-[60px] h-[60px] rounded-full border"
            style={{ borderColor: primary }}
          >
            <div className="font-bold text-xl" style={{ color: primary }}>
              1
            </div>
          </div>
          <div className="flex flex-col mt-1">
            <p className="text-text font-bold text-lg">{t("address")}</p>
            <div className="text-text text-sm">
              {isPickup
                ? selectedBranch
                  ? selectedBranch.name
                  : t("selectPickupBranch")
                : selectedAddressId
                ? getAddressString(
                    addresses.data.find((a) => a.id === selectedAddressId)
                  )
                : t("pleasePickAddressFromMap")}
            </div>
          </div>
        </div>
        <div
          className="flex flex-row px-2 pt-3 pb-1 border border-text rounded-md text-text select-none cursor-pointer"
          onClick={() => {
            setStage(1);
          }}
        >
          {t("edit")}
        </div>
      </div>
    );
  };

  const stage2Folded = () => {
    return (
      <div className="flex flex-row px-4 justify-between items-center">
        <div className="flex flex-row space-x-2 rtl:space-x-reverse">
          <div
            className="flex flex-row items-center justify-center w-[60px] h-[60px] rounded-full border"
            style={{ borderColor: primary }}
          >
            <div className="font-bold text-xl" style={{ color: primary }}>
              2
            </div>
          </div>
          <div className="flex flex-col mt-1">
            <p className="text-text font-bold text-lg">{t("paymentMethod")}</p>
            <div className="text-text text-sm">
              {selectedPaymentMethod
                ? selectedPaymentMethod.gateway_title
                : t("pleaseSelectPaymentMethod")}
            </div>
          </div>
        </div>
        {selectedPaymentMethod && (
          <div
            className="flex flex-row px-2 pt-3 pb-1 border border-text rounded-md text-text select-none cursor-pointer"
            onClick={() => {
              setStage(2);
            }}
          >
            {t("edit")}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full md:w-[699px] lg:w-[929px] bg-white shadow-md rounded-md py-4 flex flex-col space-y-8">
      {stage === 1 ? <CheckoutAddressStage1 /> : stage1Folded()}
      {stage === 2 ? <CheckoutAddressStage2 /> : stage2Folded()}
    </div>
  );
};
