import React, { createContext, useReducer } from "react";
import { getCategoryProductsApi } from "../../api/api";
import CategoryReducer from "./CategoryReducer";

// Initial state
const initialState = {
  products: {
    data: [],
    loading: true,
    error: null,
    page: 1,
    hasMore: true,
  },
};

// Create context
export const CategoryContext = createContext(initialState);

// Provider component
export const CategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CategoryReducer, initialState);

  // Actions
  async function getCategoryProducts({ categoryId, page = 1 }) {
    dispatch({
      type: "GET_CATEGORY_PRODUCTS_REQUEST",
    });
    try {
      const res = await getCategoryProductsApi({ categoryId, page });
      dispatch({
        type: "GET_CATEGORY_PRODUCTS_SUCCESS",
        payload: {
          products: res.data,
          hasMore: res.next_page_url !== null,
          page: res.current_page,
        },
        append: page > 1,
      });
    } catch (error) {
      dispatch({
        type: "GET_CATEGORY_PRODUCTS_FAILURE",
        payload: error.message || "Failed to fetch products",
      });
    }
  }

  return (
    <CategoryContext.Provider
      value={{
        products: state.products,
        getCategoryProducts,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
