import React, { useContext } from "react";
import { getFormattedPrice } from "../../util/ui_util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../context/general/SnackbarContext";
import useCart from "../../hooks/useCart";

export const CartOrderReview = ({ cart }) => {
  const navigate = useNavigate();
  const { language, isAuthenticated, setShowLoginDialog, primary, primaryDark } =
    useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();
  const { showError } = useSnackbar();
  const { getCartSubtotal } = useCart();

  const subtotal = getCartSubtotal();

  return (
    <div
      className="flex flex-col space-y-2 bg-white p-4 md:w-full lg:w-[370px] shadow-lg"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div className="text-text font-bold">{t("orderReview")}</div>
      <div className="hidden flex-row justify-between">
        <div className="text-text text-sm">{t("subtotal")}</div>
        <div className="text-text text-sm font-bold">
          {getFormattedPrice(subtotal, language)}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-text">{t("total")}</div>
        <div className="text-text font-bold">
          {getFormattedPrice(subtotal, language)}
        </div>
      </div>
      <div className="text-text text-xs hidden">{t("priceIncludingTax")}</div>
      <ButtonWithIcon
        text={t("checkout")}
        textColor="#ffffff"
        textHoverColor="#ffffff"
        bgColor={primary}
        bgHoverColor={primaryDark}
        useBorder={false}
        onClick={() => {
          if (cart.length === 0) {
            showError(t("emptyCartError"));
            return;
          }
          if (!isAuthenticated) {
            setShowLoginDialog(true);
            return;
          }
          navigate("/checkout");
        }}
      />
    </div>
  );
};
