import React, { createContext, useContext, useReducer } from "react";
import WishlistReducer from "./WishlistReducer";
import { AppContext } from "../app/AppState";
import { getWishlistApi, removeFromWishlistApi } from "../../api/api";
import { useSnackbar } from "../general/SnackbarContext";

// Initial state
const initialState = {
  wishlist: {
    data: [],
    loading: true,
    error: null,
    page: 1,
    hasMore: true,
  },
  removeFromWishlistProduct: {
    data: null,
    loading: false,
    error: null,
  },
};

// Create context
export const WishlistContext = createContext(initialState);

// Provider component
export const WishlistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WishlistReducer, initialState);
  const { token } = useContext(AppContext);
  const { showSuccess, showError } = useSnackbar();

  // Actions
  async function getWishlist({ page = 1 }) {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_WISHLIST_REQUEST",
    });
    try {
      const res = await getWishlistApi({ token: tokenString, page });
      dispatch({
        type: "GET_WISHLIST_SUCCESS",
        payload: {
          wishlist: res.products,
          hasMore:
            state.wishlist.data.length + res.products.length < res.total_size,
          page: page,
        },
        append: page > 1,
      });
    } catch (error) {
      dispatch({
        type: "GET_WISHLIST_FAILURE",
        payload: error.message || "Failed to fetch wishlist",
      });
    }
  }

  async function removeProductFromWishlist({ productId }) {
    const tokenString = token.data.token;
    dispatch({
      type: "REMOVE_FROM_WISHLIST_REQUEST",
    });
    try {
      const data = await removeFromWishlistApi({
        token: tokenString,
        productIds: [productId],
      });
      dispatch({
        type: "REMOVE_FROM_WISHLIST_SUCCESS",
        payload: data,
      });
      showSuccess(data.message);
      getWishlist({ page: 1 });
    } catch (error) {
      dispatch({
        type: "REMOVE_FROM_WISHLIST_FAILURE",
        payload: error.message || "Failed to remove product from wishlist",
      });
      showError(error.message || "Failed to remove product from wishlist");
    }
  }

  return (
    <WishlistContext.Provider
      value={{
        wishlist: state.wishlist,
        removeFromWishlistProduct: state.removeFromWishlistProduct,
        getWishlist,
        removeProductFromWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
