import React, { useContext, useState } from "react";
import { AppTextField } from "../../components/AppTextField";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";
import { emailPattern } from "../../util/util";

export const ProfileForm = () => {
  const { user, updatedProfile, updateProfile, primary, primaryDark } =
    useContext(AppContext);

  const [firstName, setFirstName] = useState(user.data.f_name);
  const [lastName, setLastName] = useState(user.data.l_name);
  const [email, setEmail] = useState(user.data.email);
  const [phone, setPhone] = useState(user.data.phone);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  const handleButtonClick = () => {
    setErrors({});
    let formErrors = {};

    if (phone.length === 0) {
      formErrors.phone = t("phoneRequired");
    }
    if (password.length === 0) {
      formErrors.password = t("passwordRequired");
    }
    if (password.length < 6) {
      formErrors.password = t("passwordInvalid");
    }
    if (firstName.length === 0) {
      formErrors.firstName = t("firstNameRequired");
    }
    if (lastName.length === 0) {
      formErrors.lastName = t("lastNameRequired");
    }
    if (!emailPattern.test(email)) {
      formErrors.email = t("emailInvalid");
    }
    if (passwordConfirmation.length === 0) {
      formErrors.passwordConfirmation = t("passwordConfirmationRequired");
    }
    if (password !== passwordConfirmation) {
      formErrors.passwordConfirmation = t("passwordConfirmationInvalid");
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    updateProfile({
      firstName,
      lastName,
      email,
      password,
      phone,
    });
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 rtl:space-x-reverse w-full md:w-[600px]">
        <div className="w-full">
          <div className="text-text font-bold">{t("firstName")}</div>
          <AppTextField
            placeholder={t("firstName")}
            type={"text"}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            error={errors.firstName}
          />
        </div>
        <div className="w-full">
          <div className="text-text font-bold">{t("lastName")}</div>
          <AppTextField
            placeholder={t("lastName")}
            type={"text"}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            error={errors.lastName}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 rtl:space-x-reverse w-full md:w-[600px]">
        <div className="w-full">
          <div className="text-text font-bold">{t("email")}</div>
          <AppTextField
            placeholder={t("email")}
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={errors.email}
          />
        </div>
        <div className="w-full">
          <div className="text-text font-bold">{t("phone")}</div>
          <div className="flex flex-row rtl:space-x-reverse">
            <AppTextField
              placeholder={t("phone")}
              type={"tel"}
              pattern={"[0-9]*"}
              value={phone}
              onChange={(e) => {
                const phone = e.target.value.replace(/\D/g, "");
                setPhone(phone);
              }}
              error={errors.phone}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 rtl:space-x-reverse w-full md:w-[600px]">
        <div className="w-full">
          <div className="text-text font-bold">{t("password")}</div>
          <AppTextField
            placeholder={t("password")}
            type={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={errors.password}
          />
        </div>
        <div className="w-full">
          <div className="text-text font-bold">{t("passwordConfirmation")}</div>
          <AppTextField
            placeholder={t("passwordConfirmation")}
            type={"password"}
            value={passwordConfirmation}
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
            }}
            error={errors.passwordConfirmation}
          />
        </div>
      </div>
      <ButtonWithIcon
        text={t("save")}
        textColor="#ffffff"
        textHoverColor="#ffffff"
        bgColor={primary}
        bgHoverColor={primaryDark}
        useBorder={false}
        isLoading={updatedProfile?.loading}
        onClick={handleButtonClick}
      />
    </div>
  );
};
