import React, { useContext, useEffect, useState } from "react";
import { CheckoutTop } from "./CheckoutTop";
import { iconClose, iconCopy } from "../../util/ui_util";
import { CheckoutAddress } from "./CheckoutAddress";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/general/SnackbarContext";
import {
  applyCoupon,
  copyToClipboard,
  getCouponDiscountAmount,
  getDiscountedPrice,
} from "../../util/util";
import useCart from "../../hooks/useCart";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";

export const CheckoutPage = () => {
  const [showSafariBanner, setShowSafariBanner] = useState(true);
  const {
    stage,
    config,
    getConfig,
    user,
    getUser,
    getAddresses,
    couponCode,
    isPickup,
    selectedAddressId,
    selectedPaymentMethod,
    selectedBranch,
    order,
    placeOrder,
    paymentInProgress,
  } = useContext(CheckoutContext);
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnackbar();
  const { cart, getCartSubtotal, getCartTax } = useCart();
  const { t } = useTranslation();
  const { language, isAuthenticated, primary, primaryDark } = useContext(AppContext);
  const isRtl = language === "ar";

  useEffect(() => {
    if (cart.length === 0 && !paymentInProgress) {
      showError(t("emptyCartError"));
      navigate("/");
    }
    if (!isAuthenticated) {
      showError(t("notLoggedInError"));
      navigate("/");
    }
  }, [cart, navigate, t, showError, isAuthenticated, paymentInProgress]);

  useEffect(() => {
    getConfig();
    getUser();
    getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (config?.error) {
      showError(config.error);
    }
    if (user?.error) {
      showError(user.error);
    }
  }, [config, user, showError]);

  const calculateTotal = () => {
    let total = getCartSubtotal() + getCartTax();
    if (couponCode.data) {
      total = applyCoupon(total, couponCode.data);
    }
    if (!isPickup) {
      total += config.data.delivery_charge;
    }
    return total;
  };

  const handlePlaceOrder = () => {
    const orderData = {
      cart: cart.map((item) => {
        return {
          product_id: `${item.product.id}`,
          price: `${item.product.price}`,
          variant: "",
          variation: [item.variation],
          discount_amount:
            item.product.price - getDiscountedPrice(item.product),
          tax_amount: (item.product.price * item.product.tax) / 100,
          quantity: item.quantity,
        };
      }),
      couponDiscountAmount: couponCode.data
        ? getCouponDiscountAmount(
            getCartSubtotal() + getCartTax(),
            couponCode.data
          )
        : 0,
      couponTitle: couponCode.data ? couponCode.data.title : "",
      couponCode: couponCode.data ? couponCode.data.code : null,
      orderAmount: calculateTotal(),
      isPickup,
      addressId: selectedAddressId,
      paymentMethod: selectedPaymentMethod.gateway,
      branchId: selectedBranch?.id,
    };
    placeOrder(orderData);
  };

  return (
    <div
      className="w-full min-h-screen bg-background py-20"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div
        className={`fixed flex flex-row justify-between items-center top-0 w-full z-20 p-2 ${
          showSafariBanner ? "display-block" : "hidden"
        }`}
        style={{ backgroundColor: primary }}
      >
        <div></div>
        <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
          <div className="text-white">{t("useApplePayMessage")}</div>
          <div
            className="flex flex-row items-center border border-white rounded-md px-1 py-[2px] cursor-pointer select-none space-x-1 rtl:space-x-reverse"
            onClick={async () => {
              copyToClipboard(
                window.location.href,
                () => {
                  showSuccess(t("urlCopied"));
                },
                (e) => {
                  showError(e);
                }
              );
            }}
          >
            <div className="text-white text-sm mt-1">{t("copy")}</div>
            {iconCopy({ fillColor: "#ffffff" })}
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowSafariBanner(false);
          }}
        >
          {iconClose({ fillColor: "#ffffff" })}
        </div>
      </div>
      <div className="container mx-auto">
        {!user?.data ||
        user.loading ||
        user.error ||
        !config.data ||
        config.loading ||
        config.error ? (
          <></>
        ) : (
          <div className="flex flex-col items-center px-4 space-y-20 md:px-0">
            <CheckoutTop />
            <CheckoutAddress />
            <div className="w-full md:w-[699px] lg:w-[929px] bg-white shadow-md rounded-md p-4 flex flex-col space-y-2">
              <div className="text-text font-bold">
                {t("completeYourOrder")}
              </div>
              <ButtonWithIcon
                text={t("placeOrder")}
                textColor="#ffffff"
                textHoverColor="#ffffff"
                bgColor={primary}
                bgHoverColor={primaryDark}
                useBorder={false}
                isDisabled={stage !== 3}
                isLoading={order.loading}
                onClick={handlePlaceOrder}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
