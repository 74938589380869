const WishlistReducer = (state, action) => {
  switch (action.type) {
    case "GET_WISHLIST_REQUEST":
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          loading: true,
          error: null,
        },
      };

    case "GET_WISHLIST_SUCCESS":
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          loading: false,
          data: action.append
            ? [...state.wishlist.data, ...action.payload.wishlist]
            : action.payload.wishlist,
          error: null,
          page: action.payload.page,
          hasMore: action.payload.hasMore,
        },
      };

    case "GET_WISHLIST_FAILURE":
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          loading: false,
          error: action.payload,
          hasMore: false,
        },
      };

    case "REMOVE_FROM_WISHLIST_REQUEST":
      return {
        ...state,
        removeFromWishlistProduct: {
          ...state.removeFromWishlistProduct,
          loading: true,
          error: null,
        },
      };

    case "REMOVE_FROM_WISHLIST_SUCCESS":
      return {
        ...state,
        removeFromWishlistProduct: {
          ...state.removeFromWishlistProduct,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "REMOVE_FROM_WISHLIST_FAILURE":
      return {
        ...state,
        removeFromWishlistProduct: {
          ...state.removeFromWishlistProduct,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WishlistReducer;
