import React, { useContext } from "react";
import { CheckoutContext } from "../../../context/checkout/CheckoutState";
import { iconRadioChecked, iconRadioUnchecked } from "../../../util/ui_util";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import colors from "../../../util/colors";

export const ItemCheckoutBranch = ({ branch }) => {
  const { selectedBranch, setSelectedBranch } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const { primary } = useContext(AppContext);

  const isSelected = selectedBranch?.id === branch.id;
  return (
    <div
      className={`flex flex-row justify-between items-center rounded-md p-2 border cursor-pointer select-none`}
      style={{ borderColor: isSelected ? primary : colors.text }}
      onClick={() => {
        setSelectedBranch(branch);
      }}
    >
      <div className="flex flex-row">
        {isSelected
          ? iconRadioChecked({ fillColor: primary, width: "2em" })
          : iconRadioUnchecked({ fillColor: colors.text, width: "2em" })}
        <div className="flex flex-col">
          <div className="text-text">{`${t("name")}: ${branch.name}`}</div>
          <div className="text-text">{`${t("email")}: ${branch.email}`}</div>
          <div className="text-text">{`${t("address")}: ${
            branch.address
          }`}</div>
        </div>
      </div>
    </div>
  );
};
