import React, { useState, useEffect, useRef, useContext } from "react";
import { ItemHomeProduct } from "./items/ItemHomeProduct";
import { useDraggable } from "react-use-draggable-scroll";
import { AppContext } from "../../context/app/AppState";

export const HomeCategory = ({ category }) => {
  const ref = useRef(null);
  const { events } = useDraggable(ref);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const isMobile = window.innerWidth < 768;
  const { language, primary } = useContext(AppContext);
  const isRtl = language === "ar";

  useEffect(() => {
    if (isMobile) return;
    const interval = setInterval(() => {
      if (!isPaused && ref.current) {
        const maxScroll = isRtl
          ? ref.current.scrollWidth
          : ref.current.scrollWidth - ref.current.clientWidth;
        const itemWidth = ref.current.firstChild?.clientWidth || 0;
        setScrollPosition((prev) => {
          const newScrollPosition = isRtl ? prev - itemWidth : prev + itemWidth;
          if (
            (isRtl && newScrollPosition <= 0) ||
            (!isRtl && newScrollPosition >= maxScroll)
          ) {
            return isRtl ? maxScroll : 0;
          }
          return newScrollPosition;
        });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [isPaused, isMobile, isRtl]);

  useEffect(() => {
    if (ref.current && !isMobile) {
      ref.current.scrollTo({
        left: isRtl ? -scrollPosition : scrollPosition,
        behavior: "smooth",
      });
    }
  }, [scrollPosition, isMobile, isRtl]);

  const handleHoverState = (isHovering) => {
    setIsPaused(isHovering);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center space-y-2">
      <div className="flex flex-col items-center justify-center">
        <div className="font-bold text-3xl text-[#555555]">
          {category.category.name}
        </div>
        <div className="flex flex-row items-center space-x-4">
          <div
            className="h-[2px] w-[110px]"
            style={{ backgroundColor: primary }}
          ></div>
          <div
            className="border-2 rounded-full h-[14px] w-[14px]"
            style={{ borderColor: primary }}
          ></div>
          <div
            className="h-[2px] w-[110px]"
            style={{ backgroundColor: primary }}
          ></div>
        </div>
      </div>
      <div
        className="flex w-full overflow-x-scroll space-x-4 p-4"
        {...events}
        ref={ref}
        dir={isRtl ? "rtl" : "ltr"}
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {category.products.map((product, index) => (
          <ItemHomeProduct
            key={index}
            product={product}
            onHoverChange={handleHoverState}
          />
        ))}
      </div>
    </div>
  );
};
