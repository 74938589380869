import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/app/AppState";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { getLatestConfig } from "../../util/util";

export const ThankYouPage = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const { language, primary, primaryDark } = useContext(AppContext);
  const isRtl = language === "ar";
  const navigate = useNavigate();
  const latestConfig = getLatestConfig();

  return (
    <div
      className="w-full min-h-screen bg-background flex flex-col justify-center items-center py-20"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div className="text-2xl font-bold text-text">
        {language === "en"
          ? `${t("order")} #${orderId} ${t("hasBeenPlacesSuccessfully")}`
          : `${t("hasBeenReceived")} ${t("order")} #${orderId} ${t(
              "successfully"
            )}`}
      </div>
      <div className="text-gray-300">{`${t("thankYouForUsing")} ${
        latestConfig?.ecommerce_name
      }`}</div>

      <div className="mt-10 w-full md:w-[300px]">
        <ButtonWithIcon
          text={t("goBackHome")}
          textColor={"#ffffff"}
          textHoverColor="#ffffff"
          bgColor={primary}
          bgHoverColor={primaryDark}
          useBorder={false}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};
