const OrderReducer = (state, action) => {
  switch (action.type) {
    case "TRACK_ORDER_REQUEST":
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
          error: null,
        },
      };

    case "TRACK_ORDER_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "TRACK_ORDER_FAILURE":
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error: action.payload,
        },
      };

      case "GET_ORDER_DETAILS_REQUEST":
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
          error: null,
        },
      };

    case "GET_ORDER_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_ORDER_DETAILS_FAILURE":
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OrderReducer;
