import React, { useContext } from "react";
import { CheckoutContext } from "../../context/checkout/CheckoutState";
import { CheckoutAddAddress } from "./CheckoutAddAddress";
import {
  getAddressString,
  ItemCheckoutAddress,
} from "./items/ItemCheckoutAddress";
import { iconClose } from "../../util/ui_util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { CheckoutAddressDeliveryOrPickup } from "./CheckoutAddressDeliveryOrPickup";
import { AppContext } from "../../context/app/AppState";

export const CheckoutAddressStage1 = () => {
  const { showError } = useSnackbar();

  const {
    config,
    isPickup,
    selectedBranch,
    setStage,
    showAddAddress,
    setShowAddAddress,
    addresses,
    selectedAddressId,
  } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const { primary, primaryDark } = useContext(AppContext);

  const handleAddressConfirmation = () => {
    if (isPickup) {
      setStage(2);
    } else {
      if (addresses.data.length === 0) {
        showError(t("noAddressesError"));
        return;
      }
      if (!selectedAddressId) {
        showError(t("noAddressSelectedError"));
        return;
      }
      setStage(2);
    }
  };

  return (
    <div>
      <div className="flex flex-row px-4 space-x-2 rtl:space-x-reverse">
        <div
          className="flex flex-row items-center justify-center w-[60px] h-[60px] rounded-full"
          style={{ backgroundColor: primary }}
        >
          <div className="text-white font-bold text-xl">1</div>
        </div>
        <div className="flex flex-col mt-1">
          <p className="text-text font-bold text-lg">{t("address")}</p>
          <div className="text-text text-sm">
            {isPickup
              ? selectedBranch
                ? selectedBranch.name
                : t("selectPickupBranch")
              : selectedAddressId
              ? getAddressString(
                  addresses.data.find((a) => a.id === selectedAddressId)
                )
              : t("pleasePickAddressFromMap")}
          </div>
        </div>
      </div>
      {config.data.self_pickup === 1 ? (
        <CheckoutAddressDeliveryOrPickup />
      ) : (
        <></>
      )}
      {isPickup || addresses.loading ? (
        <></>
      ) : addresses.data && addresses.data.length > 0 ? (
        <div className="flex flex-col space-y-2 p-4">
          {addresses.data.map((address, index) => (
            <ItemCheckoutAddress address={address} key={index} />
          ))}
          <div className="flex flex-col border border-text border-dashed rounded-md py-2">
            <div className="flex flex-row justify-between items-center px-2">
              <div
                className="text-text font-bold cursor-pointer pt-1"
                onClick={() => setShowAddAddress(true)}
              >
                {t("addNewAddress")}
              </div>
              {showAddAddress && (
                <div
                  className="cursor-pointer"
                  onClick={() => setShowAddAddress(false)}
                >
                  {iconClose({ fillColor: "#ff0000" })}
                </div>
              )}
            </div>
            {showAddAddress && <CheckoutAddAddress />}
          </div>
        </div>
      ) : (
        <CheckoutAddAddress />
      )}

      <div className="px-4 pt-4">
        <ButtonWithIcon
          text={t("confirmAddress")}
          textColor="#ffffff"
          textHoverColor="#ffffff"
          bgColor={primary}
          bgHoverColor={primaryDark}
          useBorder={false}
          onClick={handleAddressConfirmation}
        />
      </div>
    </div>
  );
};
