import React, { useContext } from "react";
import { Layout } from "../../components/Layout";
import { ItemCart } from "./ItemCart";
import { CartOrderReview } from "./CartOrderReview";
import useCart from "../../hooks/useCart";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";
import iconCart from "../../assets/images/icon_cart.svg";

export const CartPage = () => {
  const { cart, removeCartItem } = useCart();
  const { showSuccess } = useSnackbar();
  const { language } = useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();

  const handleItemDeleted = (item) => {
    removeCartItem(item.id);
    showSuccess(t("deletedCartItemMessage"));
  };

  return (
    <Layout>
      <div
        className="flex flex-col px-4 py-8 space-y-4"
        dir={isRtl ? "rtl" : "ltr"}
      >
        <div className="flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0 rtl:space-x-reverse">
          {cart.length > 0 ? (
            <div className="flex flex-col space-y-4 w-full">
              {cart.map((item, index) => (
                <ItemCart
                  item={item}
                  key={index}
                  onDelete={() => {
                    handleItemDeleted(item);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="container">
              <div className="flex flex-col items-center space-y-2 mt-8">
                <div className="flex flex-row w-[100px] h-[100px] justify-center items-center bg-gray-300 rounded-full">
                  <img src={iconCart} alt="" className="w-[50px]" />
                </div>
                <div>{t("emptyCart")}</div>
              </div>
            </div>
          )}
          <div className="flex flex-col">
            <CartOrderReview cart={cart} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
