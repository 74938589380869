import React, { createContext, useContext, useReducer } from "react";
import { getOrderDetailsApi, trackOrderApi } from "../../api/api";
import { AppContext } from "../app/AppState";
import { useSnackbar } from "../general/SnackbarContext";
import OrderReducer from "./OrderReducer";

// Initial state
const initialState = {
  order: {
    data: null,
    loading: false,
    error: null,
  },
  details: {
    data: [],
    loading: false,
    error: null,
  },
};

// Create context
export const OrderContext = createContext(initialState);

// Provider component
export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrderReducer, initialState);
  const { token } = useContext(AppContext);
  const { showError } = useSnackbar();

  // Actions
  async function trackOrder({ orderId }) {
    const tokenString = token.data.token;
    dispatch({
      type: "TRACK_ORDER_REQUEST",
    });
    try {
      const res = await trackOrderApi({ token: tokenString, orderId });
      dispatch({
        type: "TRACK_ORDER_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "TRACK_ORDER_FAILURE",
        payload: error.message || "Failed to fetch order",
      });
      showError(error.message || "Failed to fetch order");
    }
  }

  async function getOrderDetails({ orderId }) {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_ORDER_DETAILS_REQUEST",
    });
    try {
      const res = await getOrderDetailsApi({ token: tokenString, orderId });
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: "GET_ORDER_DETAILS_FAILURE",
        payload: error.message || "Failed to fetch order details",
      });
      showError(error.message || "Failed to fetch order details");
    }
  }

  return (
    <OrderContext.Provider
      value={{
        order: state.order,
        details: state.details,
        trackOrder,
        getOrderDetails,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
