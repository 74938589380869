const ProductReducer = (state, action) => {
  switch (action.type) {
    case "GET_PRODUCT_REQUEST":
      return {
        ...state,
        product: {
          ...state.product,
          loading: true,
          error: null,
        },
      };

    case "GET_PRODUCT_SUCCESS":
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_PRODUCT_FAILURE":
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: action.payload,
        },
      };

    case "GET_PRODUCT_REVIEWS_REQUEST":
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true,
          error: null,
        },
      };

    case "GET_PRODUCT_REVIEWS_SUCCESS":
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_PRODUCT_REVIEWS_FAILURE":
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: false,
          error: action.payload,
        },
      };

    case "ADD_REVIEW_REQUEST":
      return {
        ...state,
        addedReview: {
          ...state.addedReview,
          loading: true,
          error: null,
        },
      };

    case "ADD_REVIEW_SUCCESS":
      return {
        ...state,
        addedReview: {
          ...state.addedReview,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "ADD_REVIEW_FAILURE":
      return {
        ...state,
        addedReview: {
          ...state.addedReview,
          loading: false,
          error: action.payload,
        },
      };

    case "ADD_TO_WISHLIST_REQUEST":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: true,
          error: null,
        },
      };

    case "ADD_TO_WISHLIST_SUCCESS":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "ADD_TO_WISHLIST_FAILURE":
      return {
        ...state,
        addToWishlistProduct: {
          ...state.addToWishlistProduct,
          loading: false,
          error: action.payload,
        },
      };

    case "SET_SELECTED_VARIATION":
      return {
        ...state,
        selectedVariation: action.payload,
      };

    default:
      return state;
  }
};

export default ProductReducer;
