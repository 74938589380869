import React, { useContext, useState } from "react";
import { Layout } from "../../components/Layout";
import { AccountTop } from "../../components/AccountTop";
import { ProfileForm } from "./ProfileForm";
import {
  iconDeleteAccount,
  iconNotifications,
  spinner,
} from "../../util/ui_util";
import colors from "../../util/colors";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";

export const ProfilePage = () => {
  const [promotionalMessages, setPromotionalMessages] = useState(true);
  const { language, user, removedAccount, removeAccount, primary } =
    useContext(AppContext);
  const isRtl = language === "ar";
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="flex flex-col mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {t("myProfile")}
        </div>
        {user?.data ? (
          <div className="flex flex-col px-4 md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 rtl:space-x-reverse my-8">
            <ProfileForm />
            <div className="flex flex-col space-y-4">
              <div className="text-text font-bold text-xl">
                {t("myAccount")}
              </div>
              <div className="hidden flex-row space-x-2 rtl:space-x-reverse">
                {iconNotifications({
                  fillColor: colors.text,
                  width: "24px",
                  height: "24px",
                })}
                <div className="flex flex-col">
                  <div className="text-text font-bold">
                    {t("promotionalMessages")}
                  </div>
                  <div className="text-text text-sm w-2/3">
                    {t("promotionalMessagesText")}
                  </div>
                </div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={promotionalMessages}
                    onChange={() => {
                      setPromotionalMessages((old) => {
                        return !old;
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="relative w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-400"></div>
                </label>
              </div>
              <div className="w-full h-[1px] bg-gray-300"></div>
              <div className="flex flex-row space-x-2 rtl:space-x-reverse">
                {iconDeleteAccount({
                  fillColor: colors.text,
                  width: "24px",
                  height: "24px",
                })}
                <div className="flex flex-col w-2/3">
                  <div className="text-text font-bold">
                    {t("deleteAccount")}
                  </div>
                  <div className="text-text text-sm">
                    {t("deleteAccountText")}
                  </div>
                </div>
                <div className="w-1/3">
                  <ButtonWithIcon
                    text={t("deleteAccount")}
                    textColor={colors.danger}
                    textHoverColor="#ffffff"
                    bgColor={"#ffffff"}
                    bgHoverColor={colors.danger}
                    isLoading={removedAccount?.loading}
                    onClick={() => {
                      removeAccount();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row w-full justify-center">{spinner(primary)}</div>
        )}
      </div>
    </Layout>
  );
};
