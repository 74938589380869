import React, { useState } from "react";
import { iosArrowRight } from "../util/ui_util";
import { Link } from "react-router-dom";

export const ItemNavigationSubcategory = ({ category, isRtl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubcategories =
    category?.categories && category.categories.length > 0;

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <Link to={`/category/${category.id}`} draggable={false}>
      <div
        className="relative cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`flex items-center w-full justify-between ${
            isOpen ? "bg-gray-100" : "bg-transparent"
          } py-1 ps-2`}
        >
          {category?.name}

          {hasSubcategories && (
            <div className="flex flex-row">
              <div
                className={`transition-transform ${isRtl ? "rotate-180" : ""}`}
              >
                {iosArrowRight()}
              </div>
              <div className="w-[10px]"></div>
            </div>
          )}
        </div>
        {isOpen && hasSubcategories && (
          <div
            className={`absolute w-[250px] bg-white shadow-lg z-50 top-0 ${
              isRtl ? "right-[250px]" : "left-[250px]"
            }`}
          >
            {category.categories?.map((sub, index) => (
              <div key={index} className="py-1 px-2 hover:bg-gray-100">
                <Link to={`/category/${sub.id}`} draggable={false}>
                  {sub.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};
