import React, { createContext, useContext, useReducer } from "react";
import HomeReducer from "./HomeReducer";
import {
  addToWishlistApi,
  getAdsApi,
  getBannersApi,
  getFeaturedCategoriesApi,
  getNewArrivalsApi,
} from "../../api/api";
import { AppContext } from "../app/AppState";
import { useSnackbar } from "../general/SnackbarContext";

// Initial state
const initialState = {
  banners: {
    data: [],
    loading: false,
    error: null,
  },
  ads: {
    data: [],
    loading: false,
    error: null,
  },
  newArrivals: {
    data: [],
    loading: false,
    error: null,
  },
  featuredCategories: {
    data: [],
    loading: false,
    error: null,
  },
  addToWishlistProduct: {
    data: null,
    loading: false,
    error: null,
  },
};

// Create context
export const HomeContext = createContext(initialState);

// Provider component
export const HomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HomeReducer, initialState);
  const { isAuthenticated, token, setShowLoginDialog } = useContext(AppContext);
  const { showSuccess, showError } = useSnackbar();

  // Actions
  async function getBanners() {
    dispatch({
      type: "GET_BANNERS_REQUEST",
    });
    try {
      const banners = await getBannersApi();
      dispatch({
        type: "GET_BANNERS_SUCCESS",
        payload: banners,
      });
    } catch (error) {
      dispatch({
        type: "GET_BANNERS_FAILURE",
        payload: error.message || "Failed to fetch banners",
      });
    }
  }

  async function getAds() {
    dispatch({
      type: "GET_ADS_REQUEST",
    });
    try {
      const ads = await getAdsApi();
      dispatch({
        type: "GET_ADS_SUCCESS",
        payload: ads,
      });
    } catch (error) {
      dispatch({
        type: "GET_ADS_FAILURE",
        payload: error.message || "Failed to fetch ads",
      });
    }
  }

  async function getNewArrivals() {
    dispatch({
      type: "GET_NEW_ARRIVALS_REQUEST",
    });
    try {
      const response = await getNewArrivalsApi();
      dispatch({
        type: "GET_NEW_ARRIVALS_SUCCESS",
        payload: response.products,
      });
    } catch (error) {
      dispatch({
        type: "GET_NEW_ARRIVALS_FAILURE",
        payload: error.message || "Failed to fetch new arrivals",
      });
    }
  }

  async function getFeaturedCategories() {
    dispatch({
      type: "GET_FEATURED_CATEGORIES_REQUEST",
    });
    try {
      const response = await getFeaturedCategoriesApi();
      dispatch({
        type: "GET_FEATURED_CATEGORIES_SUCCESS",
        payload: response.featured_data,
      });
    } catch (error) {
      dispatch({
        type: "GET_FEATURED_CATEGORIES_FAILURE",
        payload: error.message || "Failed to fetch featured categories",
      });
    }
  }

  async function addProductToWishlist({ productId }) {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
      return;
    }
    const tokenString = token.data.token;
    dispatch({
      type: "ADD_TO_WISHLIST_REQUEST",
    });
    try {
      const data = await addToWishlistApi({
        token: tokenString,
        productIds: [productId],
      });
      dispatch({
        type: "ADD_TO_WISHLIST_SUCCESS",
        payload: data,
      });
      showSuccess(data.message);
    } catch (error) {
      dispatch({
        type: "ADD_TO_WISHLIST_FAILURE",
        payload: error.message || "Failed to add product to wishlist",
      });
      showError(error.message || "Failed to add product to wishlist");
    }
  }

  return (
    <HomeContext.Provider
      value={{
        banners: state.banners,
        ads: state.ads,
        newArrivals: state.newArrivals,
        featuredCategories: state.featuredCategories,
        addToWishlistProduct: state.addToWishlistProduct,
        getBanners,
        getAds,
        getNewArrivals,
        getFeaturedCategories,
        addProductToWishlist,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
