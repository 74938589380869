import React, { useContext, useEffect } from "react";
import { Layout } from "../../components/Layout";
import { defaultLoader } from "../../util/ui_util";
import { AppContext } from "../../context/app/AppState";
import { useSnackbar } from "../../context/general/SnackbarContext";

export const TermsConditionsPage = () => {
  const { config, language } = useContext(AppContext);
  const { showError } = useSnackbar();
  const isRtl = language === "ar";

  useEffect(() => {
    if (config?.error) {
      showError(config.error);
    }
  }, [config, showError]);

  return (
    <Layout>
      {config?.loading ? (
        defaultLoader()
      ) : (
        <div
          dir={isRtl ? "rtl" : "ltr"}
          dangerouslySetInnerHTML={{
            __html: config?.data.terms_and_conditions,
          }}
        ></div>
      )}
    </Layout>
  );
};
