import React, { useContext } from "react";
import { getFormattedPrice, iconClose, spinner } from "../../../util/ui_util";
import { ButtonWithIcon } from "../../../components/ButtonWithIcon";
import { useTranslation } from "react-i18next";
import { getDiscountedPrice } from "../../../util/util";
import useCart from "../../../hooks/useCart";
import { useSnackbar } from "../../../context/general/SnackbarContext";
import { WishlistContext } from "../../../context/wishlist/WishlistState";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/app/AppState";

export const ItemWishlist = ({ product }) => {
  const { t } = useTranslation();
  const { addProductToCart, getProductCartQuantity } = useCart();
  const { showSuccess, showError } = useSnackbar();
  const { removeFromWishlistProduct, removeProductFromWishlist } =
    useContext(WishlistContext);
  const navigate = useNavigate();
  const { primary, primaryDark } = useContext(AppContext);

  const discountedPrice = getDiscountedPrice(product);
  const hasDiscount = product.discount !== 0;

  const handleAddToCartClick = (e) => {
    e.preventDefault();
    if (product.choice_options.length > 0) {
      navigate(`/product/${product.id}`);
      return;
    }
    const cartQuantity = getProductCartQuantity(product.id);
    if (cartQuantity >= product.total_stock) {
      showError(t("productMaxQuantityMessage"));
      return;
    }
    addProductToCart(product, 1);
    showSuccess(t("productAddedToCartMessage"));
  };

  const handleRemoveFromWishlistClick = (e) => {
    e.preventDefault();
    removeProductFromWishlist({ productId: product.id });
  };

  return (
    <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center w-full bg-white shadow-sm py-2 px-4">
      <div className="flex flex-row space-x-2">
        <img
          src={product.image_fullpath[0]}
          alt=""
          className={`h-[100px] object-cover`}
          draggable={false}
        />
        <div className="flex flex-col">
          <div className="font-bold  text-text">{product.name}</div>
          <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
            <div
              className={`${hasDiscount ? "text-red-500" : "text-black"} ${
                hasDiscount ? "font-bold" : ""
              } text-sm`}
            >
              {getFormattedPrice(discountedPrice)}
            </div>
            <div
              className={`${hasDiscount ? "block" : "hidden"} ${
                hasDiscount ? "line-through" : ""
              } text-sm`}
            >
              {getFormattedPrice(product.price)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
        <ButtonWithIcon
          text={t("addToCart")}
          textColor="#ffffff"
          textHoverColor="#ffffff"
          bgColor={primary}
          bgHoverColor={primaryDark}
          useBorder={false}
          onClick={handleAddToCartClick}
        />
        {removeFromWishlistProduct.loading ? (
          spinner(primary)
        ) : (
          <div
            className="p-1 rounded-full bg-red-500 cursor-pointer"
            onClick={handleRemoveFromWishlistClick}
          >
            {iconClose({ fillColor: "#ffffff", width: "20px", height: "20px" })}
          </div>
        )}
      </div>
    </div>
  );
};
