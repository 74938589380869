const NotificationsReducer = (state, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS_REQUEST":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
          error: null,
        },
      };

    case "GET_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case "GET_NOTIFICATIONS_FAILURE":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default NotificationsReducer;
