import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/app/AppState";
import colors from "../../../util/colors";

export const ItemProductOption = ({ option, onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(option.options[0]);
  const { primary } = useContext(AppContext);

  return (
    <div className="flex flex-col px-2 space-y-2">
      <div className="font-bold text-xl text-black">{option.title}</div>
      {option.options.map((op, index) => (
        <div
          key={index}
          className={`px-4 py-2 rounded-md border cursor-pointer select-none ${
            selectedOption === op ? "font-bold" : ""
          }`}
          style={{
            borderColor: selectedOption === op ? primary : colors.text,
            color: selectedOption === op ? primary : colors.text,
          }}
          onClick={() => {
            setSelectedOption(op);
            onOptionChange(op);
          }}
        >
          {op}
        </div>
      ))}
    </div>
  );
};
