import React, { useContext, useEffect } from "react";
import { Layout } from "../../components/Layout";
import { AccountTop } from "../../components/AccountTop";
import { iconNotifications, spinner } from "../../util/ui_util";
import colors from "../../util/colors";
import { ItemNotification } from "./items/ItemNotification";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../../context/notifications/NotificationsState";
import { AppContext } from "../../context/app/AppState";

export const NotificationsPage = () => {
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { t } = useTranslation();
  const { notifications, getNotifications } = useContext(NotificationsContext);
  const { isAuthenticated, language, primary } = useContext(AppContext);
  const isRtl = language === "ar";

  useEffect(() => {
    if (!isAuthenticated) {
      showError(t("notLoggedInError"));
      navigate("/");
    }
  }, [showError, t, navigate, isAuthenticated]);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notifications?.error) {
      showError(notifications.error);
    }
  }, [notifications, showError]);

  return (
    <Layout>
      <div className="flex flex-col mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {t("notifications")}
        </div>
        {notifications.loading ? (
          <div className="flex flex-row w-full justify-center">
            {spinner(primary)}
          </div>
        ) : notifications.data.length > 0 ? (
          <ul className="space-y-4 mt-10">
            {notifications.data.map((item, index) => (
              <li>
                <ItemNotification notification={item} key={index} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex flex-col items-center space-y-2 mt-8">
            <div className="flex flex-row w-[100px] h-[100px] justify-center items-center bg-gray-300 rounded-full">
              {iconNotifications({
                fillColor: colors.text,
                width: "40px",
                height: "40px",
              })}
            </div>
            <div>{t("noNotificationsMessage")}</div>
          </div>
        )}
      </div>
    </Layout>
  );
};
