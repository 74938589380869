import React, { useContext, useState } from "react";
import { iconClose } from "../util/ui_util";
import iconUser from "../assets/images/icon_user.svg";
import { AppTextField } from "./AppTextField";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { AppContext } from "../context/app/AppState";
import ReactFlagsSelect from "react-flags-select";
import { customLabels, emailPattern } from "../util/util";
import { useTranslation } from "react-i18next";

export const DialogLogin = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [selected, setSelected] = useState("SA");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+966");

  const {
    token,
    register,
    login,
    setShowLoginDialog,
    language,
    primary,
    primaryDark,
  } = useContext(AppContext);

  const isRtl = language === "ar";

  const [errors, setErrors] = useState({});
  const [showSignup, setShowSignup] = useState(false);
  const { t } = useTranslation();

  const handleCountrySelection = (code) => {
    setSelected(code);
    setCountryCode(customLabels[code].secondary);
  };

  const handleButtonClick = () => {
    setErrors({});
    let formErrors = {};

    if (phone.length === 0) {
      formErrors.phone = t("phoneRequired");
    }

    if (password.length === 0) {
      formErrors.password = t("passwordRequired");
    }

    if (password.length < 6) {
      formErrors.password = t("passwordInvalid");
    }

    if (showSignup) {
      if (firstName.length === 0) {
        formErrors.firstName = t("firstNameRequired");
      }
      if (lastName.length === 0) {
        formErrors.lastName = t("lastNameRequired");
      }
      if (!emailPattern.test(email)) {
        formErrors.email = t("emailInvalid");
      }
      if (passwordConfirmation.length === 0) {
        formErrors.passwordConfirmation = t("passwordConfirmationRequired");
      }
      if (password !== passwordConfirmation) {
        formErrors.passwordConfirmation = t("passwordConfirmationInvalid");
      }
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (showSignup) {
      register({
        firstName,
        lastName,
        email,
        password,
        phone: `${countryCode}${phone}`,
      });
    } else {
      login({ phone: `${countryCode}${phone}`, password });
    }
  };

  return (
    <div
      className="w-screen h-screen flex justify-center items-end md:items-center fixed bg-black bg-opacity-50 z-40"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div className="flex flex-col w-screen items-center md:w-[400px] bg-white rounded-md shadow-md p-4">
        <div className="flex flex-row w-full justify-end">
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowLoginDialog(false);
            }}
          >
            {iconClose({ fillColor: "#ff0000" })}
          </div>
        </div>
        {!showSignup && (
          <div className="flex items-center justify-center w-[100px] h-[100px] rounded-full border border-gray-300">
            <img src={iconUser} alt="" className="w-[40px] h-[40px]" />
          </div>
        )}
        <div className="text-black font-bold mt-2">
          {showSignup ? t("signup") : t("login")}
        </div>
        <div className="flex flex-col space-y-2 w-full">
          <div>
            <div className="text-text">{t("phone")}</div>
            <div className="flex flex-row space-x-1 rtl:space-x-reverse">
              <ReactFlagsSelect
                selected={selected}
                onSelect={handleCountrySelection}
                customLabels={customLabels}
                searchable={true}
              />
              <AppTextField
                placeholder={t("phone")}
                type={"tel"}
                pattern={"[0-9]*"}
                value={phone}
                onChange={(e) => {
                  const phone = e.target.value.replace(/\D/g, "");
                  setPhone(phone);
                }}
                error={errors.phone}
              />
            </div>
          </div>
          {showSignup && (
            <div className="flex flex-col space-y-2">
              <div>
                <div className="text-text">{t("firstName")}</div>
                <AppTextField
                  placeholder={t("firstName")}
                  type={"text"}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  error={errors.firstName}
                />
              </div>
              <div>
                <div className="text-text">{t("lastName")}</div>
                <AppTextField
                  placeholder={t("lastName")}
                  type={"text"}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  error={errors.lastName}
                />
              </div>
              <div>
                <div className="text-text">{t("email")}</div>
                <AppTextField
                  placeholder={"your@email.com"}
                  type={"email"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={errors.email}
                />
              </div>
            </div>
          )}
          <div>
            <div className="text-text">{t("password")}</div>
            <AppTextField
              placeholder={t("passwordHint")}
              type={"password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              error={errors.password}
            />
          </div>
          {showSignup && (
            <div>
              <div className="text-text">{t("passwordConfirmation")}</div>
              <AppTextField
                placeholder={t("passwordConfirmation")}
                type={"password"}
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                }}
                error={errors.passwordConfirmation}
              />
            </div>
          )}
          {token && (
            <ButtonWithIcon
              text={showSignup ? t("signup") : t("login")}
              textColor="#ffffff"
              textHoverColor="#ffffff"
              bgColor={primary}
              bgHoverColor={primaryDark}
              useBorder={false}
              isLoading={token.loading}
              onClick={() => {
                handleButtonClick();
              }}
            />
          )}
          <div className="flex flex-row justify-center space-x-2 rtl:space-x-reverse">
            <div className="text-text">
              {showSignup ? t("alreadyHaveAnAccount") : t("dontHaveAnAccount")}
            </div>
            {showSignup ? (
              <div
                className="font-bold cursor-pointer"
                onClick={() => {
                  setShowSignup(false);
                }}
                style={{ color: primary }}
              >
                {t("login")}
              </div>
            ) : (
              <div
                className="font-bold cursor-pointer"
                onClick={() => {
                  setShowSignup(true);
                }}
                style={{ color: primary }}
              >
                {t("signup")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
