import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app/AppState";
import { ChatContext } from "../../context/chat/ChatState";
import { Layout } from "../../components/Layout";
import { AccountTop } from "../../components/AccountTop";
import { iconMessages, spinner } from "../../util/ui_util";
import { ItemMessage } from "./items/ItemMessage";
import colors from "../../util/colors";
import iconChatUser from "../../assets/images/icon_chat_user.svg";
import { AppTextField } from "../../components/AppTextField";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";

export const ChatPage = () => {
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { t } = useTranslation();
  const { chat, getChat, sentMessage, sendMessage } = useContext(ChatContext);
  const { isAuthenticated, language, primary, primaryDark } =
    useContext(AppContext);
  const isRtl = language === "ar";
  const loadMoreRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      showError(t("notLoggedInError"));
      navigate("/");
    }
  }, [showError, t, navigate, isAuthenticated]);

  useEffect(() => {
    getChat({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObserver = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && chat.hasMore && !chat.loading) {
        setLoadingMore(true);
        getChat({ page: chat.page + 1 }).finally(() => setLoadingMore(false));
      }
    },
    [chat.hasMore, chat.loading, chat.page, getChat]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    if (chatContainerRef.current && chat.page === 1) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat.data, chat.page]);

  useEffect(() => {
    if (chatContainerRef.current && loadingMore) {
      chatContainerRef.current.scrollTop = 100;
    }
  }, [loadingMore]);

  const handleSendClick = () => {
    if (message.length === 0) {
      setMessageError(t("messageEmptyError"));
      return;
    }
    sendMessage({ message, setMessage });
  };

  return (
    <Layout>
      <div className="flex flex-col mt-4" dir={isRtl ? "rtl" : "ltr"}>
        <AccountTop />
        <div className="flex flex-row justify-center text-text font-bold text-xl mt-2">
          {t("messages")}
        </div>

        {chat.data && chat.data.length > 0 ? (
          <div
            className="h-[400px] w-full overflow-scroll px-4 md:px-0"
            ref={chatContainerRef}
          >
            <div ref={loadMoreRef} className="h-1" />
            {chat.loading ? (
              <div className="flex flex-row justify-center">{spinner()}</div>
            ) : (
              <></>
            )}
            <ul className="space-y-4 mt-10">
              {chat.data.map((message) => (
                <li key={message.id}>
                  <ItemMessage
                    isMe={!message.is_reply}
                    name={message.is_reply ? t("admin") : t("me")}
                    message={message.message}
                    image={iconChatUser}
                    created_at={message.created_at}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-2 mt-8">
            <div className="flex flex-row w-[100px] h-[100px] justify-center items-center bg-gray-300 rounded-full">
              {iconMessages({
                fillColor: colors.text,
                width: "40px",
                height: "40px",
              })}
            </div>
            <div>{t("emptyMessagesMessage")}</div>
          </div>
        )}

        <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse mt-4 md:p-0 px-4">
          <AppTextField
            placeholder={t("message")}
            type={"text"}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            error={messageError}
          />
          <div className="w-[300px]">
            <ButtonWithIcon
              text={t("send")}
              textColor="#ffffff"
              textHoverColor="#ffffff"
              bgColor={primary}
              bgHoverColor={primaryDark}
              useBorder={false}
              isLoading={sentMessage.loading}
              onClick={handleSendClick}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
