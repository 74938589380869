import React, { useContext, useState } from "react";
import { ProductContext } from "../../context/product/ProductState";
import { ItemProductReview } from "./items/ItemProductReview";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import { AppContext } from "../../context/app/AppState";

export const ProductReviews = () => {
  const { product, reviews, addedReview, addReview } =
    useContext(ProductContext);
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(null);
  const { t } = useTranslation();
  const { language, primary, primaryDark } = useContext(AppContext);
  const isRtl = language === "ar";

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleChange = (e) => {
    setComment(e.target.value);
    setCommentError(null);
  };

  const handleSend = () => {
    if (comment.length === 0) {
      setCommentError(t("commentEmptyError"));
      return;
    }
    addReview({
      productId: product.data.product.id,
      comment,
      rating,
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-0 items-center w-full">
        <div
          className="text-2xl font-bold border-b-2 pb-3 px-3"
          style={{ color: primary, borderColor: primary }}
        >
          {t("productReviews")}
        </div>
        <div className="w-full h-[1px] bg-gray-300"></div>
      </div>
      <div className="flex flex-col items-end space-y-2 pt-4">
        <textarea
          value={comment}
          onChange={handleChange}
          placeholder={t("dropYourReviewOrQuestion")}
          rows={5}
          cols={50}
          className="w-full border p-2 rounded-md border-gray-300 focus:outline-none focus:border-2 focus-border-primary"
        />
        {commentError && <div className="text-red-500">{commentError}</div>}
        <div className="w-full flex flex-row justify-between">
          <Rating
            initialValue={rating}
            SVGclassName={"inline-block"}
            rtl={isRtl}
            size={30}
            onClick={handleRating}
          />
          <div className="w-[200px]">
            <ButtonWithIcon
              text={t("send")}
              textColor="#ffffff"
              textHoverColor="#ffffff"
              bgColor={primary}
              bgHoverColor={primaryDark}
              useBorder={false}
              onClick={handleSend}
              isLoading={addedReview?.loading}
            />
          </div>
        </div>
      </div>
      <div className="font-bold text-text text-lg lg:text-2xl">
        {`${reviews.data?.length} ${t("reviews")}`}
      </div>
      {reviews.data?.map((r, index) => (
        <ItemProductReview review={r} key={index} />
      ))}
    </div>
  );
};
