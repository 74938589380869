import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/app/AppState";
import { AppTextField } from "./AppTextField";
import { useTranslation } from "react-i18next";
import { ItemSearch } from "./ItemSearch";
import { defaultLoader } from "../util/ui_util";

export const DialogSearch = () => {
  const { t } = useTranslation();
  const { language, searchProducts, search, setShowSearchDialog } =
    useContext(AppContext);
  const isRtl = language === "ar";
  const [query, setQuery] = useState("");
  const dialogRef = useRef(null);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (query.length > 1) {
      search({ query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setShowSearchDialog(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowSearchDialog]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        searchProducts.hasMore &&
        !searchProducts.loading &&
        searchProducts?.data.length > 0
      ) {
        search({ query, page: searchProducts.page + 1 });
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchProducts.currentPage,
    searchProducts.hasMore,
    searchProducts.loading,
  ]);

  return (
    <div
      className="w-screen h-screen flex justify-center items-start fixed bg-black bg-opacity-50 z-40 px-4 md:px-0"
      dir={isRtl ? "rtl" : "ltr"}
    >
      <div
        className="flex flex-col bg-white min-w-full md:min-w-[500px] rounded-md p-2 mt-32"
        ref={dialogRef}
      >
        <AppTextField
          value={query}
          placeholder={t("search")}
          type={"text"}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <div className="max-h-[400px] overflow-y-scroll mt-4">
          {searchProducts?.data &&
            searchProducts?.data.map((product, index) => (
              <ItemSearch product={product} key={index} />
            ))}
          <div ref={loadMoreRef} className="h-1" />
          {searchProducts.loading ? defaultLoader() : <></>}
        </div>
      </div>
    </div>
  );
};
