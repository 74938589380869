import React, { createContext, useContext, useReducer } from "react";
import OrdersReducer from "./OrdersReducer";
import { getOrdersApi } from "../../api/api";
import { AppContext } from "../app/AppState";

// Initial state
const initialState = {
  orders: {
    data: [],
    loading: false,
    error: null,
  },
};

// Create context
export const OrdersContext = createContext(initialState);

// Provider component
export const OrdersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdersReducer, initialState);
  const { token } = useContext(AppContext);

  // Actions
  async function getOrders() {
    const tokenString = token.data.token;
    dispatch({
      type: "GET_ORDERS_REQUEST",
    });
    try {
      const data = await getOrdersApi({ token: tokenString });
      dispatch({
        type: "GET_ORDERS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ORDERS_FAILURE",
        payload: error.message || "Failed to fetch orders",
      });
    }
  }

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        getOrders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
